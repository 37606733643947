import React from 'react';
import { Table } from '@8base/boost';
import PropTypes from 'prop-types';
import moment from 'moment';

class AuditLogTable extends React.Component {
  render() {
    const { loading, audiLogList } = this.props;
    return (
      <div>
        <Table>
          <Table.Header className="justify-center-column" columns="250px 250px 230px 150px 150px">
            <Table.HeaderCell>First Name</Table.HeaderCell>
            <Table.HeaderCell>Last Name</Table.HeaderCell>
            <Table.HeaderCell>Created At</Table.HeaderCell>
            <Table.HeaderCell>Object Type</Table.HeaderCell>
            <Table.HeaderCell>Action Type</Table.HeaderCell>
          </Table.Header>
          <Table.Body loading={loading} data={audiLogList} className="card-body-list">
            {(auditLog, index) => {
              const date = moment(auditLog.createdAt).format('MM-DD-YYYY LTS');

              return (
                <Table.BodyRow columns="250px 250px 230px 150px 150px" key={auditLog.id}>
                  <Table.BodyCell className="deal-column">
                    {auditLog.createdBy ? auditLog.createdBy.firstName : ''}
                  </Table.BodyCell>
                  <Table.BodyCell className="deal-column">
                    {auditLog.createdBy ? auditLog.createdBy.lastName : ''}
                  </Table.BodyCell>
                  <Table.BodyCell className="deal-column" style={{ justifyContent: 'center' }}>
                    {date}
                  </Table.BodyCell>
                  <Table.BodyCell className="deal-column" style={{ justifyContent: 'center' }}>
                    {auditLog.objectType}
                  </Table.BodyCell>
                  <Table.BodyCell className="deal-column" style={{ justifyContent: 'center' }}>
                    {auditLog.actionType}
                  </Table.BodyCell>
                </Table.BodyRow>
              );
            }}
          </Table.Body>
        </Table>
      </div>
    );
  }
}

AuditLogTable.propTypes = {
  loading: PropTypes.bool.isRequired,
  audiLogList: PropTypes.object.isRequired,
};

export default AuditLogTable;
