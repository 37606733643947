import React from 'react';
import { Grid, Heading, Text, ButtonGroup, Button, Icon, Dropdown, Menu } from '@8base/boost';
import PropTypes from 'prop-types';
import { ColorRelatedItems } from '../../shared/components/ColorRelatedItems';
import { Link, withRouter } from 'react-router-dom';
import UserSmallAvatar from '../../components/user/UserSmallAvatar';
import styled from 'styled-components';
import Moment from 'react-moment';
import { getTextUser, getUsername } from './active-items-utils';

const BUTTONS_HEIGHT = 70;
const ButtonLeft = styled(Button)`
  cursor: default !important;
  height: ${BUTTONS_HEIGHT}px !important;
  padding: 0 20px;
`;
const ButtonRight = styled(Button)`
  cursor: default !important;
  background-color: #edf0f2 !important;
  height: ${BUTTONS_HEIGHT}px !important;
  min-width: 200px !important;
  max-width: 200px !important;
  width: 200px !important;
`;

const ActiveItem = ({ item, onClickComplete, history }) => {
  const revisedDueDate = item.revisedDueDate ? (
    <Moment format="MMMM Do, YYYY">{item.revisedDueDate}</Moment>
  ) : (
    <Text disabled>Not set</Text>
  );

  const username = getUsername(item);
  const text = getTextUser(item.type);
  const onClickDetails = item.detailsUrl ? () => history.push(item.detailsUrl) : null;
  return (
    <Grid.Box stretch style={{ margin: 10, width: '100%' }}>
      <ButtonGroup>
        <ButtonLeft stretch color="neutral" variant="outlined">
          <ColorRelatedItems value={item} />
          <Grid.Layout stretch columns="auto 30px">
            <Grid.Box alignItems="start">
              <Heading type="h6">
                <Text weight={'bold'}>{item.title}</Text>
              </Heading>
              <Link className="item-name" to={item.detailsUrl}>
                {item.name}
              </Link>
              <UserSmallAvatar owner={username} text={text} />
            </Grid.Box>
            <Grid.Box alignItems="center" justifyContent="center">
              <Dropdown defaultOpen={false}>
                <Dropdown.Head>
                  <Icon name="More" className="more-icon" />
                </Dropdown.Head>
                <Dropdown.Body>
                  {({ closeDropdown }) => (
                    <Menu>
                      <Menu.Item onClick={(closeDropdown, onClickDetails)}>Details</Menu.Item>
                      {item.completeFunction ? (
                        <Menu.Item
                          onClick={() => {
                            closeDropdown();
                            onClickComplete(item.completeFunction, item);
                          }}>
                          Mark Completed
                        </Menu.Item>
                      ) : null}
                    </Menu>
                  )}
                </Dropdown.Body>
              </Dropdown>
            </Grid.Box>
          </Grid.Layout>
        </ButtonLeft>
        <ButtonRight color="neutral" variant="outlined">
          {revisedDueDate}
        </ButtonRight>
      </ButtonGroup>
    </Grid.Box>
  );
};

ActiveItem.propTypes = {
  item: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  onClickComplete: PropTypes.func,
};

ActiveItem.defaultProps = {
  onClickComplete: null,
};

export default withRouter(ActiveItem);
