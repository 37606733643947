import React from 'react';
import { View } from '@cobuildlab/react-simple-state';
import { Card, Heading, Loader, Row } from '@8base/boost';
import ActionModel from './action-model';
import { onErrorMixin } from '../../../shared/mixins';
import * as R from 'ramda';
import sessionStore, { NEW_SESSION_EVENT } from '../../../shared/SessionStore';
import ActionDetailTable from './components/ActionDetailTable';
import {
  approveAction,
  rejectAction,
  openComments,
  completedAction,
  requestApprovalForAction,
  fetchActionDetail,
  restoreAction,
  fetchActionApprovalsList,
} from './action-actions';
import * as toast from '../../../components/toast/Toast';
import PropTypes from 'prop-types';
import withAlliance from '../../../components/hoc/withAlliance';
import { withRouter } from 'react-router-dom';
import BusinessCaseModel from '../../document-management/business-case/BusinessCase.model';
import BusinessCaseDetailTable from '../../document-management/business-case/components/BusinessCaseDetailTable';
import {
  canApproveAction,
  canRejectAction,
  canCompletedAction,
  canRestoreAction,
} from '@cobuildlab/collabtogrow-permissions';
import YesNoDialog from '../../../components/dialogs/YesNoDialog';
import { DangerButton } from '../../../components/buttons/DangerButton';
import {
  fetchRelatedItems,
  fetchRelatedItemsByItemId,
} from '../../related-item/related-item-actions';
import { getCurrencyOnSession } from '../../../shared/alliance-utils';
import { DetailViewCardBody } from '../../../components/card/DetailViewCardBody';
import { ActionButton } from '../../../components/buttons/ActionButton';
import AppovalDetailTable from '../../../components/tables/approvalTable/ApprovalDetailTable';
import { RelatedItemsDetailTable } from '../../related-item/components/RelatedItemsDetailTable';
import { getItemByType } from '../../../shared/items-util';
import { TopButtons } from '../../../components/buttons/TopButtons';
import { RelatedItemsByItemDetailTable } from '../../related-item/components/RelatedItemsByItemDetailTable';
import { InitiativeListTable } from '../initiative/components/InitiativeListTable';
import { canSubmitForApprovalAMOItem } from '../amo-item/amo-item-permissions';
import { CardFooter } from '../../../components/new-ui/card/CardFooter';
import { NoteSubmitForApprovalText } from '../../../components/NoteSubmitForApprovalText';
import { AMO_ITEM_IN_PROGRESS } from '../../../shared/status';
import {
  OnActionError,
  OnActionDetail,
  OnActionUpdate,
  OnActionSubmitForApproval,
  OnActionReject,
  OnActionCompleted,
  OnActionRestore,
  OnActionApprovalList,
} from './action-events';
import { OnRelatedItems, OnRelatedItemsByItem } from '../../related-item/related-item-events';

// eslint-disable-next-line
/**
 * Action Detail View.
 */
class ActionDetailView extends View {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        actionData: R.clone(ActionModel),
        businessCaseData: R.clone(BusinessCaseModel),
        relatedItems: [],
        initiatives: [],
        approvalData: [],
        relatedItemsByItem: [],
      },
      loading: true,
      approvalModalIsOpen: false,
      rejectModalIsOpen: false,
      completedModalIsOpen: false,
      submitForApprovalModelIsOpen: false,
      restoreModalIsOpen: false,
      approvalDataList: [],
    };
    this.user = sessionStore.getState(NEW_SESSION_EVENT).user;
    this.onError = onErrorMixin.bind(this);
    this.selectedAlliance = sessionStore.getState(NEW_SESSION_EVENT).selectedAlliance;
    this.queryOptions = { isCacheFirst: true };
  }

  componentDidMount = () => {
    const { match } = this.props;
    if (!match.params.id) return toast.error('Action ID missing');

    this.subscribe(OnActionError, this.onError);

    this.subscribe(OnActionDetail, (state) => {
      const { action } = state;
      const businessCaseData = R.clone(action.businessCase);
      const { data } = this.state;
      businessCaseData.recommendedSolutions = businessCaseData.recommendedSolutionsRelation.items.map(
        (solution) => {
          delete solution.__typename;
          return solution;
        },
      );
      this.originalRecommendedSolutions = businessCaseData.recommendedSolutions.concat();
      const actionData = R.clone(action);
      actionData.itemId = action.itemActionRelation.id;
      data.actionData = actionData;
      data.businessCaseData = businessCaseData;
      data.initiatives = R.clone(actionData.initiatives.items);

      this.setState(
        {
          data,
        },
        () => fetchRelatedItems(actionData.itemId),
        fetchRelatedItemsByItemId(actionData.itemId),
        fetchActionApprovalsList(match.params.id),
      );
    });

    this.subscribe(OnActionUpdate, () => {
      fetchActionDetail(match.params.id);
      toast.success('Action Successfully Approved');
    });

    this.subscribe(OnActionSubmitForApproval, (action) => {
      fetchActionDetail(match.params.id);
      toast.success('Action Submitted For Approval!');
    });

    this.subscribe(OnActionReject, () => {
      fetchActionDetail(match.params.id);
      toast.success('Action Successfully Rejected');
    });

    this.subscribe(OnRelatedItems, (state) => {
      const items = state.item.itemsRelated.items.map((item) => getItemByType(item));
      const { data } = this.state;
      data.relatedItems = items;
      this.setState({
        data,
      });
    });

    this.subscribe(OnActionCompleted, (state) => {
      fetchActionDetail(match.params.id);
      toast.success('Action Successfully Completed');
    });

    this.subscribe(OnActionRestore, (state) => {
      fetchActionDetail(match.params.id);
      toast.success('Action Successfully Restored');
    });

    this.subscribe(OnActionApprovalList, (state) => {
      const { actionApprovalsList } = state;
      const { data } = this.state;
      const approvalData = actionApprovalsList.items.slice(0, 2);
      const approvalDataList = R.clone(actionApprovalsList.items);
      const submitForApprovalAlliance = actionApprovalsList.items.slice(0, 2);
      data.approvalData = approvalData;
      this.setState({
        data,
        approvalDataList,
        submitForApprovalAlliance,
        loading: false,
      });
    });

    this.subscribe(OnRelatedItemsByItem, (state) => {
      const {
        itemsList: { items: itemsRelated },
      } = state;
      const relatedItemsByItem = itemsRelated.map((item) => getItemByType(item));
      const { data } = this.state;

      data.relatedItemsByItem = relatedItemsByItem;
      this.setState({ data });

      console.log('relatedItemsByItem', relatedItemsByItem);
    });

    fetchActionDetail(match.params.id, this.queryOptions);
  };

  approve = () => {
    this.setState({
      approvalModalIsOpen: true,
    });
  };

  onYes = () => {
    this.setState(
      {
        approvalModalIsOpen: false,
        loading: true,
      },
      () => {
        approveAction({ ...this.state.data.actionData });
      },
    );
  };

  onClose = () => {
    this.setState({
      approvalModalIsOpen: false,
    });
  };

  reject = () => {
    this.setState({
      rejectModalIsOpen: true,
    });
  };

  onYesReject = () => {
    this.setState(
      {
        rejectModalIsOpen: false,
        loading: true,
      },
      () => {
        rejectAction({ ...this.state.data.actionData });
      },
    );
  };

  onCloseReject = () => {
    this.setState({
      rejectModalIsOpen: false,
    });
  };

  onCloseSubmitForApprovalModal = () => {
    this.setState({
      submitForApprovalModalIsOpen: false,
    });
  };

  onSelectSubmitForApprovalModal = (actionData) => {
    const [canSubmitForApproval, errors] = canSubmitForApprovalAMOItem(
      this.user,
      actionData,
      this.selectedAlliance,
    );
    if (canSubmitForApproval) {
      this.setState({
        action: actionData,
        submitForApprovalModalIsOpen: true,
      });
    } else {
      toast.errors(errors);
    }
  };

  onYesSubmitForApprovalModal = () => {
    this.setState(
      {
        submitForApprovalModalIsOpen: false,
        loading: true,
      },
      () => {
        const action = R.clone(this.state.data.actionData);
        requestApprovalForAction({ action }, this.queryOptions);
      },
    );
  };

  completedModal = () => {
    this.setState({
      completedModalIsOpen: true,
    });
  };

  restoreModal = () => {
    this.setState({
      restoreModalIsOpen: true,
    });
  };

  completedModalClose = () => {
    this.setState({
      completedModalIsOpen: false,
    });
  };

  restoredModalClose = () => {
    this.setState({
      restoreModalIsOpen: false,
    });
  };

  onYesModal = () => {
    this.setState(
      {
        completedModalIsOpen: false,
        loading: true,
      },
      () => {
        const actionData = R.clone(this.state.data.actionData);
        completedAction(actionData, this.queryOptions);
      },
    );
  };

  onYesRestore = () => {
    this.setState(
      {
        restoreModalIsOpen: false,
        loading: true,
      },
      () => {
        const actionData = R.clone(this.state.data.actionData);
        restoreAction(actionData, this.queryOptions);
      },
    );
  };

  render() {
    const {
      loading,
      approvalModalIsOpen,
      rejectModalIsOpen,
      completedModalIsOpen,
      submitForApprovalModalIsOpen,
      restoreModalIsOpen,
      approvalDataList,
    } = this.state;

    const {
      actionData,
      businessCaseData,
      approvalData,
      relatedItems,
      initiatives,
      relatedItemsByItem,
    } = this.state.data;
    const currency = getCurrencyOnSession();
    const { history } = this.props;
    let content = <Loader stretch />;
    let buttonsBottom = '';
    let buttonsTop = '';
    const alliance = this.selectedAlliance;

    if (!loading) {
      content = (
        <div id={'c2gScreen'}>
          <ActionDetailTable
            currency={currency}
            data={actionData}
            onClickEdit={() => history.push(`/management/action/edit/${actionData.id}`)}
          />
          <BusinessCaseDetailTable
            hideExpectedCostAvoidance
            data={businessCaseData}
            currency={currency}
          />
          <AppovalDetailTable data={approvalData} />
          <AppovalDetailTable data={approvalDataList} title={'APPROVAL HISTORY'} />
          <InitiativeListTable initiatives={initiatives} />
          <RelatedItemsDetailTable relatedItems={relatedItems} />
          <RelatedItemsByItemDetailTable relatedItemsByItem={relatedItemsByItem} />
        </div>
      );

      buttonsTop = (
        <>
          <Heading type="h4" text={actionData.name} />

          <TopButtons
            onClickClosed={history.goBack}
            onClickCollaborated={() => openComments(actionData)}
          />
        </>
      );

      buttonsBottom = (
        <Row justifyContent="end">
          {actionData.status === AMO_ITEM_IN_PROGRESS ? (
            <ActionButton
              text="Submit For Approval"
              fontAwesomeIcon="check"
              onClick={() => {
                this.onSelectSubmitForApprovalModal(actionData);
              }}
            />
          ) : null}
          <NoteSubmitForApprovalText item={actionData} />
          <DangerButton
            text={'Reject'}
            fontAwesomeIcon={'times'}
            onClick={this.reject}
            disabled={
              !canRejectAction(this.user, actionData, {
                id: this.props.allianceId,
              })
            }
          />
          <ActionButton
            fontAwesomeIcon={'check'}
            onClick={this.approve}
            text={'Approve'}
            disabled={
              !canApproveAction(this.user, actionData, {
                id: this.props.allianceId,
              })
            }
          />
          {canCompletedAction(this.user, actionData, alliance) ? (
            <ActionButton
              text="Mark Completed"
              fontAwesomeIcon="clipboard-list"
              onClick={() => {
                this.completedModal();
              }}
            />
          ) : null}
          {canRestoreAction(this.user, actionData, alliance) ? (
            <ActionButton
              text="Restore"
              fontAwesomeIcon="clipboard-list"
              onClick={() => {
                this.restoreModal();
              }}
            />
          ) : null}
        </Row>
      );
    }

    return (
      <React.Fragment>
        <Card.Header>{buttonsTop}</Card.Header>
        <DetailViewCardBody>{content}</DetailViewCardBody>
        <CardFooter>{buttonsBottom}</CardFooter>
        <YesNoDialog
          title={'Approve Action'}
          onYes={this.onYes}
          onClose={this.onClose}
          onNo={this.onClose}
          text={'Are you sure you want to Approve the Action?'}
          isOpen={approvalModalIsOpen}
        />
        <YesNoDialog
          title={'Reject Action'}
          onYes={this.onYesReject}
          onClose={this.onCloseReject}
          onNo={this.onCloseReject}
          text={'Are you sure you want to Reject the Action?'}
          isOpen={rejectModalIsOpen}
        />
        <YesNoDialog
          title={'Complete Action'}
          onYes={this.onYesModal}
          onClose={this.completedModalClose}
          onNo={this.completedModalClose}
          text={'Are you sure you want to Mark the Action as Completed?'}
          isOpen={completedModalIsOpen}
        />

        <YesNoDialog
          title={'Restore Action'}
          onYes={this.onYesRestore}
          onClose={this.restoredModalClose}
          onNo={this.restoredModalClose}
          text={'Are you sure you want to Mark the Action as Restored?'}
          isOpen={restoreModalIsOpen}
        />
        <YesNoDialog
          title={'Submit For Approval'}
          onYes={this.onYesSubmitForApprovalModal}
          onClose={this.onCloseSubmitForApprovalModal}
          onNo={this.onCloseSubmitForApprovalModal}
          text={'Are you sure you want to Submit this Action For Approval?'}
          isOpen={submitForApprovalModalIsOpen}
        />
      </React.Fragment>
    );
  }
}

ActionDetailView.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default withRouter(withAlliance(ActionDetailView));
