import React from 'react';
import PropTypes from 'prop-types';
import { InputField } from '@8base/boost';

class DealDataInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        value: this.props.value,
      },
    };
  }

  onChange = (indexRow, indexColumn, val) => {
    const { data } = this.state;
    data.value = val;

    let dataCvsObj = this.props.dataCvs[indexRow];
    let dataCvsObjKey = Object.keys(dataCvsObj)[indexColumn];

    this.setState({ data }, () => {
      this.props.dataCvs[indexRow][dataCvsObjKey] = val;
    });
  };

  render() {
    const { value } = this.state.data;
    const { index, indexRow, addClassError } = this.props;
    let classError = '';

    if (addClassError) {
      classError = 'cvs-error';
    }

    return (
      <div className={'cvs-input'}>
        <InputField
          hideErrorLabel
          className={classError}
          stretch
          input={{
            value: value,
            onChange: (value) => this.onChange(indexRow, index, value),
          }}
        />
      </div>
    );
  }
}

DealDataInput.propTypes = {
  index: PropTypes.number.isRequired,
  indexRow: PropTypes.number.isRequired,
  value: PropTypes.string.isRequired,
  dataCvs: PropTypes.array.isRequired,
  addClassError: PropTypes.bool.isRequired,
};

export default DealDataInput;
