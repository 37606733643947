import Flux from '@cobuildlab/flux-state';
import { BUDGET_VS_FORECAST_FILTERS_EVENT } from './forecasting-store';
import sessionStore, { NEW_SESSION_EVENT, APOLLO_CLIENT } from '../../../shared/SessionStore';
import { sanitize8BaseBigInts } from '../../../shared/utils';
import { fetchAlliance } from '../../settings/alliance-management/alliance-actions';
import { fetchInvestmentItems } from '../../management/investment-item/investment-item-actions';
import { fetchAllDealsData } from '../../management/deal/deal-actions';
import { ALLIANCE_UPDATE_MUTATION } from '../../settings/alliance-management/alliance-queries';
import {
  FORECASTING_DATA_EVENT,
  FORECASTING_ERROR_EVENT,
  FORECASTING_SAVED_EVENT,
} from './forecasting-store';
import {
  FORECASTING_YEAR_UPDATE_MUTATION,
  FORECASTING_YEAR_DELETE_MUTATION,
} from './forecasting-queries';
import * as R from 'ramda';
import { log, error } from '@cobuildlab/pure-logger';
import { setDefaultKPIs } from '../balanced-scorecard/balanced-scorecard-utils';
import { OnForecastingError, OnForecastingSaved, OnForecastingData } from './forecasting-events';

export const saveBudgetVsForecastFilters = (data) => {
  Flux.dispatchEvent(BUDGET_VS_FORECAST_FILTERS_EVENT, data);
};

/**
 * FetchForecastingData for the selected year.
 *
 * @param  {number}  year - Number.
 * @returns {Promise} - Forecasting data.
 */
export const fetchForecastingData = async (year) => {
  const { selectedAlliance } = sessionStore.getState(NEW_SESSION_EVENT);
  const allianceId = selectedAlliance.id;

  const yearDealsFilter = {
    year,
    fieldName: 'closeDate',
    isDateTime: false,
  };

  const yearContributionsFilter = {
    year,
    fieldName: 'contributionDate',
    isDateTime: false,
  };

  let responses;
  try {
    responses = await Promise.all([
      fetchAlliance(allianceId),
      fetchInvestmentItems('', 1, 1000, null, null, yearContributionsFilter),
      fetchAllDealsData('', yearDealsFilter),
    ]);
  } catch (e) {
    OnForecastingError.dispatch(e);
    Flux.dispatchEvent(FORECASTING_ERROR_EVENT, e);
    throw e;
  }

  const [
    { alliance },
    {
      itemsList: { items: investmentItems },
    },
    {
      dealDataList: { items: deals },
    },
  ] = responses;

  const {
    allianceKPIAllianceRelation: { items: kpis },
  } = alliance;
  const contributions = investmentItems.filter((item) => item.contribution);

  setDefaultKPIs(kpis, year);
  alliance.forecastingYears = alliance.allianceForecastAllianceRelation.items;

  const data = {
    kpis,
    contributions,
    deals,
    alliance,
  };

  OnForecastingData.dispatch(data);
  Flux.dispatchEvent(FORECASTING_DATA_EVENT, data);
  return data;
};

/**
 * To get the ForecastingYears To Be deleted.
 *
 * @param {Array} forecastingYears - Forecasting years.
 * @param {Array} originalForecastingYears - Original forecasting years.
 * @returns {Array} - ForecastingYearsToBeDeleted.
 */
const forecastingYearsToBeDeleted = (forecastingYears, originalForecastingYears) => {
  const toBeDeleted = [];

  originalForecastingYears.forEach((original) => {
    const _original = forecastingYears.find(
      (forecastingYear) => original.id === forecastingYear.id,
    );
    if (_original === undefined) toBeDeleted.push(original);
  });

  log('forecastingYearsToBeDeleted', toBeDeleted);
  return toBeDeleted;
};

/**
 * To delete forecastingYears on the Alliance Update actions.
 *
 * @param  {Array} forecastingYears - ForecastingYears.
 * @param  {Array} originalForecastingYears - OriginalForecastingYears.
 */
export const deleteForecastingYears = async (forecastingYears, originalForecastingYears) => {
  log(`deleteForecastingYears:`, forecastingYears, originalForecastingYears);
  const client = sessionStore.getState(APOLLO_CLIENT);
  const toBeDeleted = forecastingYearsToBeDeleted(forecastingYears, originalForecastingYears);

  const deleteForecastingYearPromise = async (forecastingYear) => {
    const data = { id: forecastingYear.id };

    let response;
    try {
      response = await client.mutate({
        mutation: FORECASTING_YEAR_DELETE_MUTATION,
        variables: { data },
      });
    } catch (e) {
      error('deleteForecastingYears', e, forecastingYear);
      throw e;
    }

    return response;
  };

  const responses = await Promise.all(toBeDeleted.map(deleteForecastingYearPromise));

  return responses;
};

/**
 * To get the ForecastingYears To Be updated.
 *
 * @param  {Array} forecastingYears - ForecastingYears.
 * @param  {Array} originalForecastingYears - OriginalForecastingYears.
 * @returns {Array} - ForecastingYearsToBeUpdated.
 */
const forecastingYearsToBeUpdated = (forecastingYears, originalForecastingYears) => {
  const toBeUpdated = [];

  forecastingYears.forEach((forecastingYear) => {
    if (forecastingYear.id) {
      const original = originalForecastingYears.find(
        (original) => original.id === forecastingYear.id,
      );
      if (!R.equals(original, forecastingYear)) {
        toBeUpdated.push(forecastingYear);
      }
    }
  });

  log('forecastingYearsTobeUpdated', toBeUpdated);
  return toBeUpdated;
};

/**
 * To Update forecastingYears on alliance update action.
 *
 * @param  {Array} forecastingYears - ForecastingYears.
 * @param  {Array} originalForecastingYears - OriginalForecastingYears.
 */
export const updateForecastingYears = async (forecastingYears, originalForecastingYears) => {
  log(`updateForecastingYears:`, forecastingYears, originalForecastingYears);
  const client = sessionStore.getState(APOLLO_CLIENT);
  const toBeUpdated = forecastingYearsToBeUpdated(forecastingYears, originalForecastingYears);

  const updateForecastingYearPromise = async (data) => {
    let response;
    try {
      response = await client.mutate({
        mutation: FORECASTING_YEAR_UPDATE_MUTATION,
        variables: { data },
      });
    } catch (e) {
      error('updateForecastingYears', e, data);
      throw e;
    }

    return response;
  };

  const responses = await Promise.all(toBeUpdated.map(updateForecastingYearPromise));

  log('updateForecastingYears', responses);
  return responses;
};

/**
 * To get the forecastingYears To Be created on the alliance update action.
 *
 * @param  {Array} forecastingYears - ForecastingYears.
 * @returns {Array} - ForecastingYearsToBeCreated.
 */
export const forecastingYearsToBeCreated = (forecastingYears) => {
  const toBeCreated = [];

  forecastingYears.forEach((forecastingYear) => {
    if (forecastingYear.id === undefined) toBeCreated.push(forecastingYear);
  });

  log('forecastingYearsToBeCreated', toBeCreated);
  return toBeCreated;
};

/**
 * To Update forecastingYears on alliance.
 *
 
 * @param { object } alliance - Alliance to create forecasting years.
 */
export const createForecastingYears = async (alliance) => {
  const { forecastingYears, id } = alliance;

  log(`createForecastingYears:`, forecastingYears);
  const client = sessionStore.getState(APOLLO_CLIENT);
  const toBeCreated = forecastingYearsToBeCreated(forecastingYears);

  const data = {
    id,
    allianceForecastAllianceRelation: { create: toBeCreated },
  };

  let response;
  try {
    response = await client.mutate({
      mutation: ALLIANCE_UPDATE_MUTATION,
      variables: { data },
    });
  } catch (e) {
    error('createForecastingYears', e, data);
    throw e;
  }

  log('createForecastingYears', response);
  return response;
};

/**
 * Save Alliance Forecasting.
 *
 * @param  {object} alliance - Alliance to save corecasting.
 * @param  {object} originalForecastingYears - Original forecasting years.
 * @returns {Promise} - Promise.
 */
export const saveAllianceForecasting = async (alliance, originalForecastingYears) => {
  sanitizeForecastingYears(alliance);
  const { forecastingYears } = alliance;

  let createdResponse;
  let updatedResponses;
  let deletedResponses;
  try {
    createdResponse = await createForecastingYears(alliance);
    updatedResponses = await updateForecastingYears(forecastingYears, originalForecastingYears);
    deletedResponses = await deleteForecastingYears(forecastingYears, originalForecastingYears);
  } catch (e) {
    OnForecastingError.dispatch(e);
    return Flux.dispatchEvent(FORECASTING_ERROR_EVENT, e);
  }

  console.log(createdResponse, updatedResponses, deletedResponses);
  OnForecastingSaved.dispatch(createdResponse);
  return Flux.dispatchEvent(FORECASTING_SAVED_EVENT, createdResponse);
};

/**
 * Sanitize Forecast Data, removes the readonly fields of the data.
 *
 * @param  {object} alliance - Alliance.
 */
export const sanitizeForecastingYears = (alliance) => {
  alliance.forecastingYears = alliance.forecastingYears.map((forecast) => {
    sanitize8BaseBigInts(forecast, 'clientContributionsForecast');
    sanitize8BaseBigInts(forecast, 'partnerContributionsForecast');
    sanitize8BaseBigInts(forecast, 'clientBookingsForecast');
    sanitize8BaseBigInts(forecast, 'partnerBookingsForecast');
    const {
      id,
      year,
      clientContributionsForecast,
      partnerContributionsForecast,
      clientBookingsForecast,
      partnerBookingsForecast,
    } = forecast;

    const forecastingYear = {
      id,
      year,
      clientContributionsForecast,
      partnerContributionsForecast,
      clientBookingsForecast,
      partnerBookingsForecast,
    };
    // remove id if null for create
    if (!forecastingYear.id) delete forecastingYear.id;

    return forecastingYear;
  });
};
