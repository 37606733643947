import { ValidationError } from '../../../shared/errors';
import {
  isValidString,
  setErrorIfDateIsBeforeAllianceStart,
  isValidBigInt,
} from '../../../shared/validators';
import moment from 'moment';
import { nextStepsValidator } from '../../../modules/next-step/next-step-validators';
import { businessCaseValidator } from '../../document-management/business-case/business-case-validators';
import { initiativesItemValidator } from '../initiative/initiative-validators';

/**
 * Validate that the Action has all the information necessary to create it.
 *
 * @param {object} data - The Action to be reviewed.
 * @param {object}allianceDetails - Alliance Details.
 */
export const actionValidator = (data, allianceDetails) => {
  let errorMessages = [];

  if (!isValidString(data.name)) errorMessages.push(' The Action must have a valid Name');

  if (!isValidString(data.description))
    errorMessages.push('The Action must have a valid Description');

  if (Array.isArray(data.nextSteps) && data.nextSteps.length > 0)
    try {
      nextStepsValidator(data.nextSteps);
    } catch (e) {
      errorMessages.push(e.message);
    }
  else errorMessages.push('The Action must have valid Next Steps');

  if (data.requestedDate !== null) {
    if (!moment(data.requestedDate).isValid()) {
      errorMessages.push('The Action must have a valid Requested Date');
    } else {
      setErrorIfDateIsBeforeAllianceStart(
        data.requestedDate,
        allianceDetails,
        'Requested Date',
        errorMessages,
      );
    }
  }

  if (!data.assignedTo) errorMessages.push('The Action must have a valid Assignee');

  if (!moment(data.assignedDate).isValid()) {
    errorMessages.push('The Action must have a valid Assigned Date');
  } else {
    setErrorIfDateIsBeforeAllianceStart(
      data.assignedDate,
      allianceDetails,
      'Assigned Date',
      errorMessages,
    );
  }

  if (!moment(data.originalDueDate).isValid()) {
    errorMessages.push('The Action must have a valid Original Due Date');
  } else {
    setErrorIfDateIsBeforeAllianceStart(
      data.originalDueDate,
      allianceDetails,
      'Due Date',
      errorMessages,
    );
  }

  if (moment(data.revisedDueDate).isValid()) {
    setErrorIfDateIsBeforeAllianceStart(
      data.revisedDueDate,
      allianceDetails,
      'Revised Due Date',
      errorMessages,
    );
  }

  if (!isValidString(data.unitType, false, true))
    errorMessages.push('The Action must have a valid Unit Type');

  if (!(parseFloat(data.unitQuantity) >= 0) && data.unitQuantity !== null)
    errorMessages.push('The Action must have a valid Unit');

  if (!isValidString(data.unitValueDescription, true, true))
    errorMessages.push('The Action must have a valid Unit Description');

  if (!isValidBigInt(data.unitMonetizationFactor))
    errorMessages.push('The Action must have a valid Unit Monetization Factor');

  if (errorMessages.length !== 0) throw new ValidationError(errorMessages);
};

export const validateActionData = (
  actionData,
  initiativesData,
  businessCaseData,
  hasBusinessCase = false,
  allianceDetails,
) => {
  actionValidator(actionData, allianceDetails);
  if (hasBusinessCase) {
    businessCaseValidator(businessCaseData);
  }
  initiativesItemValidator(initiativesData);
};
