import React from 'react';
import { Button } from '@8base/boost';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from '@emotion/styled';

const StyledButtomTopTransparent = styled(Button)({
  color: '#878C93',
  fontWeight: 'normal',
  'border-color': 'transparent',
});

const TransparentButtonFontAwesome = ({ onClick, text, fontAwesomeIcon, ...rest }) => {
  const icon = fontAwesomeIcon ? <FontAwesomeIcon icon={fontAwesomeIcon} /> : null;

  return (
    <StyledButtomTopTransparent
      className="collaborate-button"
      onClick={onClick}
      variant={'outlined'}
      {...rest}>
      {icon}
      <span
        style={{
          color: '#323C47',
          fontFamily: 'Poppins',
          fontSize: '14px',
          lineHeight: '21px',
        }}>
        {text}
      </span>
    </StyledButtomTopTransparent>
  );
};

TransparentButtonFontAwesome.propTypes = {
  onClick: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
  fontAwesomeIcon: PropTypes.string.isRequired,
};

export { TransparentButtonFontAwesome };
