import Flux from '@cobuildlab/flux-state';

/**
 * Event that triggers a Alliance Management error.
 *
 * @type {string}
 */
export const ALLIANCE_ERROR_EVENT = 'onAllianceError';

/**
 * Event that triggers a Alliance Files Size.
 *
 * @type {string}
 */
export const ALLIANCE_FILES_SIZE_EVENT = 'ALLIANCE_FILES_SIZE_EVENT';

/**
 * Event that triggers a Alliance Management List event.
 *
 * @type {string}
 */
export const ALLIANCE_LIST_EVENT = 'onAllianceList';

/**
 * Event that triggers a Alliance Management List event.
 *
 * @type {string}
 * @deprecated User NEW_SESSION_EVENT from SessionStore instead.
 */
export const ALLIANCE_APPROVED_EVENT = 'onAllianceApprovedEvent';

/**
 * Event that triggers a Alliance Management Detail event.
 *
 * @type {string}
 */
export const ALLIANCE_DETAIL_EVENT = 'onAllianceDetail';

/**
 * Event that triggers a Alliance Management Create event.
 *
 * @type {string}
 */
export const ALLIANCE_CREATE_EVENT = 'onAllianceCreate';

/**
 * Event that triggers a Alliance Management Update event.
 *
 * @type {string}
 */
export const ALLIANCE_UPDATE_EVENT = 'onAllianceUpdate';

/**
 * Event that triggers a Alliance Management Update event.
 *
 * @type {string}
 */
export const ALLIANCE_AUTO_UPDATE_EVENT = 'ALLIANCE_AUTO_UPDATE_EVENT';

/**
 * Event that triggers a Alliance Auto Update fails event.
 *
 * @type {string}
 */
export const ALLIANCE_AUTO_UPDATE_ERROR_EVENT = 'ALLIANCE_AUTO_UPDATE_ERROR_EVENT';

/**
 * Event that triggers a Alliance Management Reject event.
 *
 * @type {string}
 */
export const ALLIANCE_REJECT_EVENT = 'onAllianceReject';

/**
 * Event that triggers a Alliance Management Delete event.
 *
 * @type {string}
 */
export const ALLIANCE_DELETE_EVENT = 'onAllianceDelete';

/**
 * Event that triggers the fetch of the Alliance Form Dialog.
 *
 * @type {string}
 */
export const ALLIANCE_FORM_DATA_EVENT = 'onAllianceFormData';

/**
 * Event that triggers the fetch of the current Alliance Members.
 *
 * @type {string}
 */
export const ALLIANCE_LIST_MEMBERS_EVENT = 'onAllianceListMembers';

/**
 * Event that triggers the fetch of the Alliance Members.
 *
 * @type {string}
 */
export const ALLIANCE_DETAIL_LIST_MEMBERS_EVENT = 'onAllianceDetailListMembers';

/**
 * Event that triggers the fetch of the Alliance Members with Roles.
 *
 * @type {string}
 */
export const ALLIANCE_LIST_MEMBERS_WITH_ROLES_EVENT = 'onAllianceListMembersWithRoles';

/**
 * Event that triggers the update of a member role.
 *
 * @type {string}
 */
export const ALLIANCE_ROLE_CHANGE_EVENT = 'onAllianceRoleChange';

/**
 * Event that triggers the creation of an alliance member.
 *
 * @type {string}
 */
export const CREATE_ALLIANCE_MEMBER_EVENT = 'onCreateAllianceMember';

/**
 * Event that triggers the invitation of an alliance member.
 *
 * @type {string}
 */
export const INVITE_ALLIANCE_MEMBER_EVENT = 'onInviteAllianceMember';

/**
 * Event that triggers the alliance user delete.
 *
 * @type {string}
 */
export const DELETE_ALLIANCE_USER_EVENT = 'onDeleteAllianceUser';

/**
 * Event that triggers the alliance user completed.
 *
 * @type {string}
 */
export const ALLIANCE_COMPLETED_EVENT = 'onAllianceCompleted';

/**
 * Evet that triggers the alliance reactive by user SER.
 *
 * @type {string}
 */
export const ALLIANCE_REACTIVATE_EVENT = 'onAllianceReactivate';

/**
 * Event that triggers the alliance deleted by user SER.
 *
 * @type {string}
 */
export const ALLIANCE_DELETED_EVENT = 'onAllianceDeleted';
/**
 * Event that triggers the alliance deleted list.
 *
 * @type {string}
 */
export const ALLIANCE_DELETED_LIST_EVENT = 'onAllianceDeletedList';

/**
 * Event that triggers the cancel alliance deleted.
 *
 * @type {string}
 */
export const CANCEL_ALLIANCE_DELETED_EVENT = 'onCancelAllianceDeletedList';

export const ALLIANCE_SUBMIT_FOR_APPROVAL_EVENT = 'onAllianceSubmitForApproval';

export const ALLIANCE_MEMBER_INVITATIONS_LIST_EVENT = 'onAllianceMemberInvitationsList';

/**
 * Event that triggers the alliance user completed.
 *
 * @type {string}
 */
export const ALLIANCE_RESTORE_EVENT = 'onAllianceRestore';
export const ALLIANCE_EXCEL_LIST_EVENT = 'onAllianceExcelList';
export const ALLIANCE_APPROVAL_LIST_EVENT = 'onAllianceApprovalList';
export const REMAINING_ALLIANCES_EVENT = 'onRemainingAlliances';

export const ALLIANCE_AUTOSAVE_EVENT = 'onAllianceAutosaveEvent';
export const ALLIANCE_AUTOSAVE_ERROR_EVENT = 'onAllianceAutosaveErrorEvent';

/* Hold the Alliance Data.
 */

class AllianceStore extends Flux.DashStore {
  constructor() {
    super();
    this.addEvent(ALLIANCE_FILES_SIZE_EVENT);
    this.addEvent(REMAINING_ALLIANCES_EVENT);
    this.addEvent(ALLIANCE_ERROR_EVENT);
    this.addEvent(ALLIANCE_LIST_EVENT);
    this.addEvent(ALLIANCE_CREATE_EVENT);
    this.addEvent(ALLIANCE_DETAIL_EVENT);
    this.addEvent(ALLIANCE_UPDATE_EVENT);
    this.addEvent(ALLIANCE_REJECT_EVENT);
    this.addEvent(ALLIANCE_DELETE_EVENT);
    this.addEvent(ALLIANCE_FORM_DATA_EVENT);
    this.addEvent(ALLIANCE_LIST_MEMBERS_EVENT);
    this.addEvent(ALLIANCE_ROLE_CHANGE_EVENT);
    this.addEvent(CREATE_ALLIANCE_MEMBER_EVENT);
    this.addEvent(INVITE_ALLIANCE_MEMBER_EVENT);
    this.addEvent(ALLIANCE_APPROVED_EVENT);
    this.addEvent(ALLIANCE_LIST_MEMBERS_WITH_ROLES_EVENT);
    this.addEvent(ALLIANCE_DETAIL_LIST_MEMBERS_EVENT);
    this.addEvent(DELETE_ALLIANCE_USER_EVENT);
    this.addEvent(ALLIANCE_COMPLETED_EVENT);
    this.addEvent(ALLIANCE_REACTIVATE_EVENT);
    this.addEvent(ALLIANCE_DELETED_EVENT);
    this.addEvent(ALLIANCE_DELETED_LIST_EVENT);
    this.addEvent(CANCEL_ALLIANCE_DELETED_EVENT);
    this.addEvent(ALLIANCE_SUBMIT_FOR_APPROVAL_EVENT);
    this.addEvent(ALLIANCE_RESTORE_EVENT);
    this.addEvent(ALLIANCE_MEMBER_INVITATIONS_LIST_EVENT);
    this.addEvent(ALLIANCE_APPROVAL_LIST_EVENT);
    this.addEvent(ALLIANCE_EXCEL_LIST_EVENT);
    this.addEvent(ALLIANCE_AUTO_UPDATE_EVENT);
    this.addEvent(ALLIANCE_AUTO_UPDATE_ERROR_EVENT);
    this.addEvent(ALLIANCE_AUTOSAVE_EVENT);
    this.addEvent(ALLIANCE_AUTOSAVE_ERROR_EVENT);
  }
}

const allianceStore = new AllianceStore();
export default allianceStore;
export { allianceStore };
