import { DateTime } from 'luxon';
import {
  getCurrentDayStart,
  getFirstYearDay,
  getLastYearDay,
  getFirstCurrentMonthDay,
  getLastCurrentMonthDay,
  getFirstQuarterMonth,
  getLastQuarterMonth,
  getFirstMonthDay,
  getLastMonthDay,
} from '../../../shared/date-utils';
import { currentDay } from '../../../shared/date-utils.js';

const defaultValue = {
  rule: 'thisYear',
};

const generateRequestMonthsInfo = (dateFilter) => {
  const { rule, cond } = dateFilter || defaultValue;
  const rangeDates = rangeDatesDict[rule](cond);

  let startRange = DateTime.fromObject(rangeDates[0].toObject());
  const endRange = DateTime.fromObject(rangeDates[1].toObject());

  let monthNumberDict = {};
  let monthsCount = 0;

  while (!startRange.equals(endRange)) {
    monthsCount++;

    if (!monthNumberDict[startRange.year]) {
      monthNumberDict[startRange.year] = {};
    }
    monthNumberDict[startRange.year][startRange.month] = monthsCount;
    startRange = startRange.plus({ month: 1 });
  }

  return {
    monthsCount,
    monthNumberDict,
  };
};

const rangeDatesDict = {
  thisYear: () => [getFirstYearDay(), getLastYearDay()],
  year: (cond) => [getFirstYearDay(cond), getLastYearDay(cond)],
  quarter: ([year, quarterNumber]) => [
    getFirstQuarterMonth(year, quarterNumber),
    getLastQuarterMonth(year, quarterNumber).plus({ month: 1 }),
  ],
  lastMonth: () => [
    getFirstCurrentMonthDay().minus({ month: 1 }),
    getLastCurrentMonthDay().minus({ month: 1 }),
  ],
  rollingSixMonth: () => [
    getCurrentDayStart()
      .minus({ month: 5 })
      .set({ day: 1 }),
    getLastCurrentMonthDay(),
  ],
  month: (cond) => {
    const requestDate = DateTime.fromSQL(cond);
    return [getFirstMonthDay(requestDate), getLastMonthDay(requestDate)];
  },
  monthSet: (cond) => {
    const startDate = DateTime.fromSQL(cond[0]);
    const endDate = DateTime.fromSQL(cond[1]);
    return [getFirstMonthDay(startDate), getLastMonthDay(endDate)];
  },
  ytd: () => [
    getCurrentDayStart()
      .minus({ month: 11 })
      .set({ day: 1 }),
    getLastMonthDay(currentDay),
  ],
};

export { generateRequestMonthsInfo };
