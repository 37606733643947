import React from 'react';
import { View } from '@cobuildlab/react-simple-state';
import { Row, Card, Heading, Loader } from '@8base/boost';
import ActionModel from './action-model';
import { onErrorMixin } from '../../../shared/mixins';
import * as R from 'ramda';
import sessionStore, { NEW_SESSION_EVENT } from '../../../shared/SessionStore';
import ActionDetailTable from './components/ActionDetailTable';
import {
  openComments,
  completedAction,
  fetchActionDetail,
  restoreActionWithOutBusinessCase,
} from './action-actions';
import * as toast from '../../../components/toast/Toast';
import PropTypes from 'prop-types';
import withAlliance from '../../../components/hoc/withAlliance';
import { withRouter } from 'react-router-dom';
import { canCompletedAction, canRestoreAction } from '@cobuildlab/collabtogrow-permissions';
import {
  fetchRelatedItems,
  fetchRelatedItemsByItemId,
} from '../../related-item/related-item-actions';
import YesNoDialog from '../../../components/dialogs/YesNoDialog';
import { DetailViewCardBody } from '../../../components/card/DetailViewCardBody';
import { ActionButton } from '../../../components/buttons/ActionButton';
import { getCurrencyOnSession } from '../../../shared/alliance-utils';
import { TopButtons } from '../../../components/buttons/TopButtons';
import { RelatedItemsDetailTable } from '../../related-item/components/RelatedItemsDetailTable';
import { getItemByType } from '../../../shared/items-util';
import { RelatedItemsByItemDetailTable } from '../../related-item/components/RelatedItemsByItemDetailTable';
import { InitiativeListTable } from '../initiative/components/InitiativeListTable';
import { CardFooter } from '../../../components/new-ui/card/CardFooter';
import { OnActionError, OnActionDetail, OnActionCompleted, OnActionRestore } from './action-events';
import { OnRelatedItems, OnRelatedItemsByItem } from '../../related-item/related-item-events';

class ActionWithOutBusinessCaseDetailView extends View {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        actionData: R.clone(ActionModel),
        relatedItems: [],
        initiatives: [],
        relatedItemsByItem: [],
      },
      completedModalIsOpen: false,
      restoreModalIsOpen: false,
      loading: true,
    };
    this.user = sessionStore.getState(NEW_SESSION_EVENT).user;
    this.onError = onErrorMixin.bind(this);
    this.selectedAlliance = sessionStore.getState(NEW_SESSION_EVENT).selectedAlliance;
    this.queryOptions = { isCacheFirst: true };
  }

  componentDidMount = () => {
    const { match } = this.props;
    if (!match.params.id) return toast.error('Action ID missing');

    this.subscribe(OnActionError, this.onError);

    this.subscribe(OnActionDetail, (state) => {
      const actionData = R.clone(state.action);
      const { data } = this.state;
      actionData.itemId = actionData.itemActionRelation.id;
      data.actionData = actionData;
      data.initiatives = R.clone(actionData.initiatives.items);

      this.setState(
        {
          data,
        },
        () => fetchRelatedItems(actionData.itemId),
        fetchRelatedItemsByItemId(actionData.itemId),
      );
    });

    this.subscribe(OnActionCompleted, (state) => {
      fetchActionDetail(match.params.id);
      toast.success('Action Successfully Completed');
    });

    this.subscribe(OnActionRestore, (state) => {
      fetchActionDetail(match.params.id);
      toast.success('Action Successfully Restored');
    });

    this.subscribe(OnRelatedItems, (state) => {
      const items = state.item.itemsRelated.items.map((item) => getItemByType(item));
      const { data } = this.state;
      data.relatedItems = items;
      this.setState({
        data,
      });
    });

    this.subscribe(OnRelatedItemsByItem, (state) => {
      const {
        itemsList: { items: itemsRelated },
      } = state;
      const relatedItemsByItem = itemsRelated.map((item) => getItemByType(item));
      const { data } = this.state;

      data.relatedItemsByItem = relatedItemsByItem;
      this.setState({ data, loading: false });

      console.log('relatedItemsByItem', relatedItemsByItem);
    });

    fetchActionDetail(match.params.id, this.queryOptions);
  };

  onClose = () => {
    this.setState({
      approvalModalIsOpen: false,
    });
  };

  completedModal = () => {
    this.setState({
      completedModalIsOpen: true,
    });
  };

  restoreModal = () => {
    this.setState({
      restoreModalIsOpen: true,
    });
  };

  onYesModal = () => {
    this.setState(
      {
        completedModalIsOpen: false,
        loading: true,
      },
      () => {
        const actionData = R.clone(this.state.data.actionData);
        completedAction(actionData, this.queryOptions);
      },
    );
  };

  onYesRestore = () => {
    this.setState(
      {
        restoreModalIsOpen: false,
        loading: true,
      },
      () => {
        const actionData = R.clone(this.state.data.actionData);
        restoreActionWithOutBusinessCase(actionData, this.queryOptions);
      },
    );
  };

  onCloseCompletedModal = () => {
    this.setState({
      completedModalIsOpen: false,
    });
  };

  onCloseRestoreModal = () => {
    this.setState({
      restoreModalIsOpen: false,
    });
  };

  render() {
    const { loading, completedModalIsOpen, restoreModalIsOpen } = this.state;
    const { actionData, relatedItems, initiatives, relatedItemsByItem } = this.state.data;
    const { history } = this.props;
    const currency = getCurrencyOnSession();

    let content = <Loader stretch />;
    let buttonsBottom = '';
    let buttonsTop = '';
    const alliance = this.selectedAlliance;

    if (!loading) {
      content = (
        <div id={'c2gScreen'}>
          <ActionDetailTable
            data={actionData}
            currency={currency}
            onClickEdit={() =>
              history.push(`/management/action-without-business-case/edit/${actionData.id}`)
            }
          />
          <InitiativeListTable initiatives={initiatives} />
          <RelatedItemsDetailTable relatedItems={relatedItems} />
          <RelatedItemsByItemDetailTable relatedItemsByItem={relatedItemsByItem} />
        </div>
      );

      buttonsTop = (
        <>
          <Heading type="h4" text={actionData.name} />
          <TopButtons
            onClickClosed={history.goBack}
            onClickCollaborated={() => openComments(actionData)}
          />
        </>
      );

      buttonsBottom = (
        <Row justifyContent="end">
          {canCompletedAction(this.user, actionData, alliance) ? (
            <ActionButton
              text="Mark Completed"
              fontAwesomeIcon="clipboard-list"
              onClick={() => {
                this.completedModal();
              }}
            />
          ) : null}
          {canRestoreAction(this.user, actionData, alliance) ? (
            <ActionButton
              text="Restore"
              fontAwesomeIcon="clipboard-list"
              onClick={() => {
                this.restoreModal();
              }}
            />
          ) : null}
        </Row>
      );
    }

    return (
      <React.Fragment>
        <Card.Header>
          <div style={{ marginLeft: '1%' }} />
          {buttonsTop}
        </Card.Header>
        <DetailViewCardBody>{content}</DetailViewCardBody>
        <CardFooter>{buttonsBottom}</CardFooter>
        <YesNoDialog
          title={'Complete Action'}
          onYes={this.onYesModal}
          onClose={this.onCloseCompletedModal}
          onNo={this.onCloseCompletedModal}
          text={'Are you sure you want to Mark the Action as Completed?'}
          isOpen={completedModalIsOpen}
        />
        <YesNoDialog
          title={'Restore Action'}
          onYes={this.onYesRestore}
          onClose={this.onCloseRestoreModal}
          onNo={this.onCloseRestoreModal}
          text={'Are you sure you want to Mark the Action as Completed?'}
          isOpen={restoreModalIsOpen}
        />
      </React.Fragment>
    );
  }
}

ActionWithOutBusinessCaseDetailView.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default withRouter(withAlliance(ActionWithOutBusinessCaseDetailView));
