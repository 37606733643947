import { createEvent } from '@cobuildlab/react-simple-state';

export const OnProcurerError = createEvent();
export const OnInviteCompanyPortfolioOwner = createEvent();
export const OnProcurerChangeSubscriptionPlanError = createEvent();
export const OnProcurerChangeSubscriptionPlan = createEvent();
export const OnCompanyPortfolioOwnerInvitations = createEvent();
export const OnUpdatePayment = createEvent();
export const OnProcurerCancelSubscription = createEvent();
export const OnProcurerReactiveSubscription = createEvent();
export const OnSubscriptionInvoiceRetry = createEvent();
export const OnSubscriptionInvoiceRetryError = createEvent();
export const OnSubscriptionInvoiceRetryLoading = createEvent();
export const OnCompanySubscriptionCreate = createEvent();
