import React from 'react';
import { Row, Column, Loader, Card, SelectField, Grid, Heading } from '@8base/boost';
import { getCurrencyOnSession } from '../../../shared/alliance-utils';
import { View } from '@cobuildlab/react-simple-state';
import { Scorecard } from './components';
import { onErrorMixin } from '../../../shared/mixins';
import { fetchBalancedScorecardData } from './balanced-scorecard-actions';
import { getBalancedScorecardData } from './balanced-scorecard-utils';
import moment from 'moment';
import * as R from 'ramda';
import { MONTHS_RANGES } from './balanced-scorecard-model';
import { OnBalancedScorecardData, OnBalancedScorecardError } from './balanced-scorecard-events';

class BalancedScorecardReportView extends View {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      financialKPIs: [],
      selectedFinancialKPI: null,
      operationalKPIs: [],
      selectedOperationalKPI: null,
      relationshipKPIs: [],
      selectedRelationshipKPI: null,
      strategyKPIs: [],
      selectedStrategyKPI: null,
      selectedKPIYear: moment().year(),
      kpiYears: [],
      selectedMonthsRange: MONTHS_RANGES[0].value,
    };

    this.onError = onErrorMixin.bind(this);
  }

  componentDidMount() {
    this.subscribe(OnBalancedScorecardError, this.onError);

    this.subscribe(OnBalancedScorecardData, this.setBalancedScorecardData);

    fetchBalancedScorecardData(R.clone(this.state.selectedKPIYear));
  }

  setBalancedScorecardData = async (data) => {
    const year = R.clone(this.state.selectedKPIYear);
    const selectedMonthsRange = JSON.parse(this.state.selectedMonthsRange);
    const ideas = data.ideas.map((item) => ({ ...item }));

    const initiatives = data.initiatives.map((initiative) => ({
      ...initiative,
    }));

    const kpis = data.kpis.map((kpi) => ({
      ...kpi,
    }));

    const contributions = data.contributions.map((contribution) => ({
      ...contribution,
    }));

    const deals = data.deals.map((deal) => ({
      ...deal,
    }));

    const alliance = R.clone(data.alliance);

    const {
      strategyKPIs,
      relationshipKPIs,
      operationalKPIs,
      financialKPIs,
      kpiYears,
    } = getBalancedScorecardData(
      kpis,
      initiatives,
      ideas,
      contributions,
      deals,
      alliance,
      year,
      selectedMonthsRange,
    );

    const kpisToFilterBy = kpis.filter((kpi) =>
      year ? kpi.year === year : kpi.year === moment().year(),
    );
    const kpiFilter = (kpiData) => {
      const kpi = kpisToFilterBy.find((kpi) => kpi.type === kpiData.type);
      if (!kpi) return 0;
      return +kpi.target > 0;
    };

    const filteredStrategyKPIs = strategyKPIs.filter(kpiFilter);
    const filteredRelationshipKPIs = relationshipKPIs.filter(kpiFilter);
    const filteredOperationalKPIs = operationalKPIs.filter(kpiFilter);
    const filteredFinancialKPIs = financialKPIs.filter(kpiFilter);

    const selectedStrategyKPI = filteredStrategyKPIs.length ? filteredStrategyKPIs[0] : null;
    const selectedRelationshipKPI = filteredRelationshipKPIs.length
      ? filteredRelationshipKPIs[0]
      : null;
    const selectedOperationalKPI = filteredOperationalKPIs.length
      ? filteredOperationalKPIs[0]
      : null;
    const selectedFinancialKPI = filteredFinancialKPIs.length ? filteredFinancialKPIs[0] : null;

    this.setState({
      strategyKPIs: filteredStrategyKPIs,
      selectedStrategyKPI,
      relationshipKPIs: filteredRelationshipKPIs,
      selectedRelationshipKPI,
      selectedOperationalKPI,
      operationalKPIs: filteredOperationalKPIs,
      financialKPIs: filteredFinancialKPIs,
      selectedFinancialKPI,
      kpiYears,
      loading: false,
    });
  };

  onSelectedYearChange = (year) => {
    this.setState({ selectedKPIYear: year, loading: true }, () => {
      fetchBalancedScorecardData(R.clone(this.state.selectedKPIYear));
    });
  };

  onSelectedFinancialKPI = (selectedFinancialKPI) => {
    this.setState({ selectedFinancialKPI });
  };

  onSelectedOperationalKPI = (selectedOperationalKPI) => {
    this.setState({ selectedOperationalKPI });
  };

  onSelectedRelationshipKPI = (selectedRelationshipKPI) => {
    this.setState({ selectedRelationshipKPI });
  };

  onSelectedStrategyKPI = (selectedStrategyKPI) => {
    this.setState({ selectedStrategyKPI });
  };

  onDetailClick = (routeTab) => {
    this.props.history.push(`/reports/balanced-scorecard/${routeTab}`);
  };

  onSelectedMonthsRange = (selectedMonthsRange) => {
    this.setState({ selectedMonthsRange, loading: true }, () => {
      const data = OnBalancedScorecardData.get();
      if (!data) {
        return fetchBalancedScorecardData(R.clone(this.state.selectedKPIYear));
      }
      this.setBalancedScorecardData(data);
    });
  };

  render() {
    const {
      selectedFinancialKPI,
      selectedOperationalKPI,
      selectedRelationshipKPI,
      selectedStrategyKPI,
      relationshipKPIs,
      operationalKPIs,
      financialKPIs,
      strategyKPIs,
      loading,
      kpiYears,
      selectedKPIYear,
      selectedMonthsRange,
    } = this.state;
    const { onSelectedMonthsRange } = this;
    const currency = getCurrencyOnSession();
    const monthsRange = JSON.parse(selectedMonthsRange);

    if (loading)
      return (
        <Row growChildren gap="lg" offsetY="lg" offsetX="lg">
          <Loader stretch />
        </Row>
      );

    return (
      <>
        <Card>
          <Card.Header>
            <Grid.Layout
              columns="300px auto 350px"
              areas={[['left', 'center', 'right']]}
              style={{ width: '100%' }}>
              <Grid.Box area="left" justifyContent="center">
                <Heading type="h4" text="Balanced Scorecard" />
              </Grid.Box>
              <Grid.Box area="center" />
              <Grid.Box area="right" direction="row" justifySelf="end" alignSelf="center">
                <SelectField
                  style={{ width: '150px', alignSelf: 'end', marginRight: '15px' }}
                  input={{
                    name: 'monthsRangeSelector',
                    value: selectedMonthsRange,
                    onChange: (value) => onSelectedMonthsRange(value),
                  }}
                  placeholder={'Months Range'}
                  options={MONTHS_RANGES}
                />
                {kpiYears.length ? (
                  <SelectField
                    style={{ width: '150px', alignSelf: 'end', marginRight: '15px' }}
                    input={{
                      name: 'yearFilter',
                      value: selectedKPIYear,
                      onChange: (year) => this.onSelectedYearChange(year),
                    }}
                    placeholder={'Select a Year'}
                    options={kpiYears.map((year) => {
                      return { label: year, value: year };
                    })}
                  />
                ) : null}
              </Grid.Box>
            </Grid.Layout>
          </Card.Header>
        </Card>

        <Row growChildren gap="lg" offsetY="lg">
          <Column style={{ width: '50%' }} alignItems="stretch">
            <Scorecard
              year={selectedKPIYear}
              monthsRange={monthsRange}
              currency={currency}
              header="Financial"
              kpis={financialKPIs}
              selectedKPI={selectedFinancialKPI}
              onSectionClick={this.onSelectedFinancialKPI}
              onDetailClick={() => this.onDetailClick('financial')}
            />
          </Column>
          <Column style={{ width: '50%' }} alignItems="stretch">
            <Scorecard
              year={selectedKPIYear}
              monthsRange={monthsRange}
              currency={currency}
              header="Operational"
              kpis={operationalKPIs}
              selectedKPI={selectedOperationalKPI}
              onSectionClick={this.onSelectedOperationalKPI}
              onDetailClick={() => this.onDetailClick('operational')}
            />
          </Column>
        </Row>
        <Row growChildren gap="lg" offsetBottom="lg">
          <Column style={{ width: '50%' }} alignItems="stretch">
            <Scorecard
              year={selectedKPIYear}
              monthsRange={monthsRange}
              currency={currency}
              header="Relationship"
              kpis={relationshipKPIs}
              selectedKPI={selectedRelationshipKPI}
              onSectionClick={this.onSelectedRelationshipKPI}
              onDetailClick={() => this.onDetailClick('relationship')}
            />
          </Column>
          <Column style={{ width: '50%' }} alignItems="stretch">
            <Scorecard
              year={selectedKPIYear}
              monthsRange={monthsRange}
              header="Strategy"
              kpis={strategyKPIs}
              selectedKPI={selectedStrategyKPI}
              onSectionClick={this.onSelectedStrategyKPI}
              onDetailClick={() => this.onDetailClick('strategy')}
            />
          </Column>
        </Row>
      </>
    );
  }
}

export { BalancedScorecardReportView };
