import styled from '@emotion/styled';

const ReportGroupTitle = styled('h3')`
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.5px;
  line-height: 21px;
  margin: 13px 0 12px 25px;
  text-align: left;
`;

export { ReportGroupTitle };
