import React from 'react';
import PropTypes from 'prop-types';
import { FIELD_TYPE } from '@8base/utils';

import { CurrencyFormat } from '../../../../../../shared/components/CurrencyFormat';
import { getCurrencyOnSession } from '../../../../../../shared/alliance-utils';
import { TotalCellWrap, TotalTD } from '../Components';

const TotalCell = ({ cell: { value, name, fieldType }, currencyFields }) => {
  if (fieldType === FIELD_TYPE.NUMBER) {
    const isCurrency = currencyFields.find((fieldName) => fieldName === name);
    if (isCurrency) {
      const currency = getCurrencyOnSession();
      return (
        <TotalTD>
          <TotalCellWrap textAlign={fieldType === FIELD_TYPE.NUMBER ? 'right' : 'left'}>
            <CurrencyFormat
              {...currency}
              value={value ? value : 0}
              displayType="text"
              decimalScale={2}
            />
          </TotalCellWrap>
        </TotalTD>
      );
    }
    return (
      <TotalTD>
        <TotalCellWrap textAlign={'right'}>{value}</TotalCellWrap>
      </TotalTD>
    );
  }
  return (
    <TotalTD>
      <TotalCellWrap textAlign={'left'}>{value}</TotalCellWrap>
    </TotalTD>
  );
};

TotalCell.propTypes = {
  cell: PropTypes.object.isRequired,
  currencyFields: PropTypes.array.isRequired,
};

export { TotalCell };
