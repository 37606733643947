import React from 'react';
import { withApollo } from 'react-apollo';
import { Redirect, withRouter } from 'react-router-dom';
import sessionStore, { APOLLO_CLIENT, NEW_SESSION_EVENT } from '../SessionStore';
import { fetchSession, logout } from '../../modules/auth/auth.actions';
import Flux from '@cobuildlab/flux-state';
import PropTypes from 'prop-types';
import {
  handleAllianceInvitationLink,
  handleAllianceMemberInvitationLink,
  handleCompanyInvitationLink,
} from '../session-utils';
import { onErrorMixin } from '../mixins';
import { log } from '@cobuildlab/pure-logger';
import { withAuth } from '8base-react-sdk';
import { View } from '@cobuildlab/react-simple-state';
import { auditLogUserLogin } from '../../modules/reports/audit-log/audit-log-actions';
import { isCompanyActive, isProcurer, isProcurerOnly } from '../../modules/procurer/procurer-utils';
import * as toast from '../../components/toast/Toast';
import { COMPANY_INVITATION_PENDING } from '../status';
import MainLoader from './MainLoader';
import CreateCompanyView from '../../modules/wizard/onboarding/CreateCompanyView';
import CompanyInvitationView from '../../modules/wizard/onboarding/CompanyInvitationView';
import { updateUser } from '../../modules/wizard/wizard.actions';
import { reportError } from '../rollbar';
import { OnSessionError } from '../session-events';
import {
  OnAllianceInvitationError,
  OnAllianceInvitationUpdated,
  OnAllianceInvitationMemberUpdated,
  OnCompanyInvitationAccepted,
} from '../../modules/settings/invitations/invitations-events';
import { OnUserUpdate } from '../../modules/wizard/wizard-events';

/**
 * User Session component.
 */
class Session extends View {
  constructor(props) {
    super(props);
    this.state = {
      apolloAcquired: false,
      session: null,
    };

    this.onError = onErrorMixin.bind(this);
  }

  async componentDidMount() {
    this.subscribe(OnSessionError, async (e) => {
      log('SESSION_ERROR:message', e.message);
      setTimeout(async () => {
        logout(this.props);
      }, 100);
    });

    this.subscribe(sessionStore, APOLLO_CLIENT, async () => {
      const { auth } = this.props;

      this.setState({ apolloAcquired: true }, () => {
        fetchSession({ auth });
      });
    });

    this.subscribe(sessionStore, NEW_SESSION_EVENT, async (session) => {
      log('NEW_SESSION_EVENT', session);
      auditLogUserLogin('LOGIN');
      this.setState({ session });
    });

    this.subscribe(OnAllianceInvitationError, (data) => {
      toast.error(data.message);
      this.props.history.push('/');
    });

    this.subscribe(OnAllianceInvitationUpdated, async ([_, allianceId]) => {
      await updateUser({
        selectedAllianceId: allianceId,
        selectedAlliance: {
          connect: {
            id: allianceId,
          },
        },
      });
    });
    this.subscribe(OnAllianceInvitationMemberUpdated, () => {
      log('Session:this.subscribe(invitationStore, ALLIANCE_MEMBER_INVITATION_UPDATED_EVENT)');
      fetchSession();
    });
    this.subscribe(OnCompanyInvitationAccepted, () => {
      log('Session:this.subscribe(invitationStore, COMPANY_INVITATION_ACCEPTED_EVENT)');
      fetchSession();
    });
    this.subscribe(OnUserUpdate, () => {
      log('Session:this.subscribe(invitationStore, USER_UPDATE_EVENT)');
      fetchSession();
    });
    console.log('APOLLO CLIENT', this.props.client);
    Flux.dispatchEvent(APOLLO_CLIENT, this.props.client);
  }

  componentDidCatch(error, errorInfo) {
    toast.error(`${error}: ${errorInfo}`);
    console.log('reportError', reportError(error, errorInfo));
  }

  render() {
    const { apolloAcquired, session } = this.state;
    if (!apolloAcquired || !session) return <MainLoader />;

    const { history } = this.props;
    const {
      user,
      allianceInvitationsList,
      allianceMemberInvitationsList,
      selectedAlliance,
      companyInvitationsList,
    } = session;
    const companyUsers = user.companyUserRelation.items;
    const companyUser = companyUsers.length ? companyUsers[0] : {};
    const { company } = companyUser;
    const { didCompleteWizard } = user;

    log('Session:render:user:', user);
    const {
      history: {
        location: { pathname },
      },
    } = this.props;

    // the user clicked accept-company email link
    // This may not be necessary as now the Wizard handles the company Invitations.
    if (pathname.includes('accept-company-invitation')) {
      log('Session:render:accept-company email link');
      handleCompanyInvitationLink(user, history);
      return <MainLoader />;
    }

    // the user clicked accept-alliance-invitation email link
    if (pathname.includes('accept-alliance-invitation')) {
      log('Session:render:accept-alliance-invitation email link');
      if (company) {
        handleAllianceInvitationLink(user, companyUser, history);
        return <MainLoader />;
      }
    }

    // the user clicked accept-alliance-member-invitation email link
    if (pathname.includes('accept-alliance-member-invitation')) {
      log('Session:render:accept-alliance-member-invitation email link');
      handleAllianceMemberInvitationLink(user, history);
      return <MainLoader />;
    }

    // If We have pending invitations, show those first
    const pendingCompanyInvitations = companyInvitationsList.items.filter(
      ({ status }) => status === COMPANY_INVITATION_PENDING,
    );
    if (pendingCompanyInvitations.length) {
      return <CompanyInvitationView />;
    }

    if (
      allianceInvitationsList.count === 0 &&
      allianceMemberInvitationsList.count === 0 &&
      companyUsers.length === 0
    ) {
      return <CreateCompanyView />;
    }

    let redirectUri = '';
    // Is an Existing User:
    // If is over the Wizard, we don't do anything
    // If not, we check for invitations to see if we need to Skip the Wizard
    if (!didCompleteWizard) {
      log('Session:render:not done with the Wizard');
      redirectUri = `/wizard`;
    }

    log('Session:render:DEBUG:', redirectUri);

    // No Company
    if (companyUsers.length === 0) {
      log('Session:render: No Company');
      redirectUri = `/wizard`;
    }

    // Inactive Company Rule
    // We also check the Alliance, because this user can belong to the Partner Company
    if (!isCompanyActive(company) && !selectedAlliance) {
      log('Session:render:DEBUG:Inactive Company:', !isCompanyActive(company), redirectUri);
      // redirectUri = isProcurer(user) ? '/procurer/billing' : '/company-expired';
      if (isProcurer(user)) {
        redirectUri = '/procurer/billing';
      } else if (session.allianceInvitationsList.count) {
        redirectUri = '/wizard';
      } else {
        redirectUri = '/company-expired';
      }
    }

    if (isProcurerOnly()) {
      redirectUri = '/procurer/billing';
      log('Session:render: isProcurerOnly', redirectUri);
    }

    log('Session:render:DEBUG:2', redirectUri, history.location.pathname);

    // redirect to redirectUri avoiding infinite cycle
    if (redirectUri && redirectUri !== history.location.pathname) {
      log('Session:render: Some Redirect to', redirectUri, history.location.pathname);
      return <Redirect to={redirectUri} />;
    }

    return this.props.children;
  }
}

Session.propTypes = {
  client: PropTypes.any,
  children: PropTypes.any,
};

export default withRouter(withAuth(withApollo(Session)));
