import React from 'react';
import Sidebar from 'react-sidebar';
import { View } from '@cobuildlab/react-simple-state';
import { onErrorMixin } from '../../shared/mixins';
import { Loader, Card } from '@8base/boost';
import { CommentsHeader } from './CommentsHeader';
import { DEAL_TYPE, FUNDING_REQUEST_TYPE } from '../../shared/item-types';
import {
  createFundingRequestComment,
  fetchFundingRequestComments,
} from '../management/funding-request/funding-request-action';
import { Comment } from './Comment';
import {
  createContributionComment,
  fetchContributionComments,
} from '../management/contribution/contribution-action';
import { createActionComment, fetchActionComments } from '../management/action/action-actions';
import {
  createDecisionComment,
  fetchDecisionComments,
} from '../management/decision/decision-actions';
import {
  createInitiativeComment,
  fetchInitiativeComments,
} from '../management/initiative/initiative-actions';
import {
  createAllianceComment,
  fetchAllianceComments,
} from '../settings/alliance-management/alliance-actions';
import { createRiskComment, fetchRiskComments } from '../management/risk/risk-action';
import TextArea from 'react-mention-plugin';
import 'react-mention-plugin/lib/TextArea.css';
import { fetchCurrentAllianceMembersAction } from '../settings/alliance-management/alliance-actions';
import { createIdeaComment, fetchIdeaComments } from '../management/idea/idea-actions';
import {
  ACTION_TYPE,
  CONTRIBUTION_TYPE,
  DECISION_TYPE,
  IDEA_TYPE,
  RISK_TYPE,
  ISSUE_TYPE,
  INITIATIVE_TYPE,
  ALLIANCE_TYPE,
} from '../../shared/item-types';
import { createDealComment, fetchDealComments } from '../management/deal/deal-actions';
import { ActionButton } from '../../components/buttons/ActionButton';
import CloseIcon from '../../components/CloseIcon';
import { fetchIssueComments, createIssueComment } from '../management/issue/issue-actions';
import { concatClientAndPartnerUsers } from '../../shared/alliance-utils';
import { OnAllianceMemberList } from '../../modules/settings/alliance-management/alliance-events';
import { OnCommentRequest, OnCommentError, OnComment } from '../comment/comment-events';
import * as R from 'ramda';
import { OnNewSession } from '../../shared/session-events';

/**
 * Comments Side Bar.
 */
class CommentsSideBar extends View {
  constructor(props) {
    super(props);
    this.state = {
      sidebarOpen: false,
      loading: false,
      loadingComment: false,
      comments: [],
      comment: '',
      users: [],
      id: null,
      type: null,
    };
    this.onError = onErrorMixin.bind(this);
  }

  async componentDidMount() {
    this.subscribe(OnCommentError, this.onError);

    this.subscribe(OnAllianceMemberList, (state) => {
      const { clientCompany, partnerCompany } = state;
      const users = concatClientAndPartnerUsers(clientCompany, partnerCompany);
      const suggestions = users.map((user) => {
        const { avatar } = user;
        return {
          id: user.id,
          avatar: avatar
            ? R.path(['downloadUrl'], avatar)
            : 'https://randomuser.me/api/portraits/men/33.jpg',
          value: user.email,
          label: `${user.firstName} ${user.lastName}`,
        };
      });
      this.setState({
        users: suggestions,
      });
    });

    this.subscribe(OnCommentRequest, (state) => {
      fetchCurrentAllianceMembersAction();
      const { type, id } = state;
      this.setState(
        {
          sidebarOpen: true,
          loading: true,
          comments: [],
          type,
          id,
          loadingComment: false,
          comment: '',
        },
        () => {
          if (type === INITIATIVE_TYPE) {
            fetchInitiativeComments(id);
          }
          if (type === ALLIANCE_TYPE) {
            fetchAllianceComments(id);
          }
          if (type === FUNDING_REQUEST_TYPE) {
            return fetchFundingRequestComments(id);
          }
          if (type === CONTRIBUTION_TYPE) {
            return fetchContributionComments(id);
          }
          if (type === ACTION_TYPE) {
            return fetchActionComments(id);
          }
          if (type === DECISION_TYPE) {
            return fetchDecisionComments(id);
          }
          if (type === RISK_TYPE) {
            return fetchRiskComments(id);
          }
          if (type === IDEA_TYPE) {
            return fetchIdeaComments(id);
          }
          if (type === DEAL_TYPE) {
            return fetchDealComments(id);
          }
          if (type === ISSUE_TYPE) {
            return fetchIssueComments(id);
          }
        },
      );
    });

    this.subscribe(OnComment, (state) => {
      const comments = state.items.map((item) => {
        const comment = R.clone(item);
        comment.commentOwner = item.createdBy.id === state.userId;
        comment.success = true;

        return comment;
      });
      this.setState(
        {
          loading: false,
          comments,
          loadingComment: false,
        },
        () => {},
      );
    });
  }

  onSetSidebarOpen = () => {
    this.setState((prevState) => ({
      sidebarOpen: !prevState.sidebarOpen,
    }));
  };

  onComment = () => {
    const { type, id, comment } = this.state;
    const createdBy = R.clone(OnNewSession.get().user);
    const createdAt = new Date();
    const commentOwner = true;
    const success = false;
    let index;
    let comments = this.state.comments.map((comment) => ({ ...comment }));

    if (type === INITIATIVE_TYPE) {
      const newComment = { id, comment, createdBy, createdAt, commentOwner, success };
      console.log('*** create initiative comment ***');
      comments.push(newComment);
      this.setState(
        {
          comments,
          comment: '',
        },
        () =>
          createInitiativeComment(id, comment).then(() => {
            index = comments.indexOf(newComment);
            newComment.success = true;
            comments.splice(index, 1, newComment);
            this.setState({
              comments,
            });
          }),
      );
    }
    if (type === ALLIANCE_TYPE) {
      const newComment = { id, comment, createdBy, createdAt, commentOwner };

      comments.push(newComment);

      this.setState(
        {
          comments,
          comment: '',
        },
        () =>
          createAllianceComment(id, comment).then(() => {
            index = comments.indexOf(newComment);
            newComment.success = true;
            comments.splice(index, 1, newComment);
            this.setState({
              comments,
            });
          }),
      );
    }
    if (type === FUNDING_REQUEST_TYPE) {
      const newComment = { id, comment, createdBy, createdAt, commentOwner };

      comments.push(newComment);

      this.setState(
        {
          comments,
          comment: '',
        },
        () =>
          createFundingRequestComment(id, comment).then(() => {
            index = comments.indexOf(newComment);
            newComment.success = true;
            comments.splice(index, 1, newComment);
            this.setState({
              comments,
            });
          }),
      );
    }
    if (type === CONTRIBUTION_TYPE) {
      const newComment = { id, comment, createdBy, createdAt, commentOwner };

      comments.push(newComment);

      this.setState(
        {
          comments,
          comment: '',
        },
        () =>
          createContributionComment(id, comment).then(() => {
            index = comments.indexOf(newComment);
            newComment.success = true;
            comments.splice(index, 1, newComment);
            this.setState({
              comments,
            });
          }),
      );
    }
    if (type === ACTION_TYPE) {
      const newComment = { id, comment, createdBy, createdAt, commentOwner };

      comments.push(newComment);

      this.setState(
        {
          comments,
          comment: '',
        },
        () =>
          createActionComment(id, comment).then(() => {
            index = comments.indexOf(newComment);
            newComment.success = true;
            comments.splice(index, 1, newComment);
            this.setState({
              comments,
            });
          }),
      );
    }
    if (type === DECISION_TYPE) {
      const newComment = { id, comment, createdBy, createdAt, commentOwner };

      comments.push(newComment);

      this.setState(
        {
          comments,
          comment: '',
        },
        () =>
          createDecisionComment(id, comment).then(() => {
            index = comments.indexOf(newComment);
            newComment.success = true;
            comments.splice(index, 1, newComment);
            this.setState({
              comments,
            });
          }),
      );
    }
    if (type === RISK_TYPE) {
      const newComment = { id, comment, createdBy, createdAt, commentOwner };

      comments.push(newComment);

      this.setState(
        {
          comments,
          comment: '',
        },
        () =>
          createRiskComment(id, comment).then(() => {
            index = comments.indexOf(newComment);
            newComment.success = true;
            comments.splice(index, 1, newComment);
            this.setState({
              comments,
            });
          }),
      );
    }
    if (type === IDEA_TYPE) {
      const newComment = { id, comment, createdBy, createdAt, commentOwner };

      comments.push(newComment);

      this.setState(
        {
          comments,
          comment: '',
        },
        () =>
          createIdeaComment(id, comment).then(() => {
            index = comments.indexOf(newComment);
            newComment.success = true;
            comments.splice(index, 1, newComment);
            this.setState({
              comments,
            });
          }),
      );
    }
    if (type === DEAL_TYPE) {
      const newComment = { id, comment, createdBy, createdAt, commentOwner };

      comments.push(newComment);

      this.setState(
        {
          comments,
          comment: '',
        },
        () =>
          createDealComment(id, comment).then(() => {
            index = comments.indexOf(newComment);
            newComment.success = true;
            comments.splice(index, 1, newComment);
            this.setState({
              comments,
            });
          }),
      );
    }
    if (type === ISSUE_TYPE) {
      const newComment = { id, comment, createdBy, createdAt, commentOwner };

      comments.push(newComment);

      this.setState(
        {
          comments,
          comment: '',
        },
        () =>
          createIssueComment(id, comment).then(() => {
            index = comments.indexOf(newComment);
            newComment.success = true;
            comments.splice(index, 1, newComment);
            this.setState({
              comments,
            });
          }),
      );
    }
  };

  render() {
    const { loading, sidebarOpen, comments, loadingComment, users } = this.state;
    const closeStyles = {
      position: 'absolute',
      top: 15,
      right: 20,
      width: 30,
      height: 30,
    };

    let content = (
      <>
        <CommentsHeader text={'Comments'} />
        <CloseIcon onClick={this.onSetSidebarOpen} style={closeStyles} />
        <div className="loaderSideBar">
          <Loader stretch />;
        </div>
      </>
    );
    if (!loading) {
      let loaderComment = null;
      if (loadingComment) loaderComment = <Loader stretch />;
      content = (
        <>
          <CommentsHeader text={'Comments'} />
          <CloseIcon onClick={this.onSetSidebarOpen} style={closeStyles} />
          <Card.Body padding="none" style={{ margin: '0', width: '315px' }}>
            {comments.map((comment) => {
              const {
                createdAt,
                createdBy: { firstName, lastName, avatar },
                comment: text,
                commentOwner,
                success,
              } = comment;
              return (
                <Comment
                  key={comment.id}
                  text={text}
                  firstName={firstName}
                  lastName={lastName}
                  date={createdAt}
                  avatar={avatar}
                  commentOwner={commentOwner}
                  success={success}
                />
              );
            })}
            {loaderComment}
          </Card.Body>
          <Card.Footer>
            <div style={{ display: this.state.sidebarOpen ? 'block' : 'none' }}>
              <TextArea
                suggestions={users}
                value={this.state.comment}
                onChange={(e) => {
                  this.setState({ comment: e });
                }}
              />
            </div>
            <br />
            <ActionButton onClick={this.onComment} text="Submit" />
          </Card.Footer>
        </>
      );
    }
    return (
      <Sidebar
        styles={{
          sidebar: {
            top: 60,
            background: 'white',
          },
        }}
        sidebar={content}
        defaultSidebarWidth={500}
        open={sidebarOpen}
        onSetOpen={this.onSetSidebarOpen}
        pullRight={true}>
        {this.props.children}
      </Sidebar>
    );
  }
}

export default CommentsSideBar;
