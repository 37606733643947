import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Card, Heading, Table } from '@8base/boost';
import { ListCardBody } from '../../../../components/card/ListCardBody';
import { Link } from 'react-router-dom';
import { CurrencyTextField } from '../../../../shared/components/CurrencyTextField';
import {
  PROFESSIONALS_TRAINED_TYPE,
  PROFESSIONALS_CERTIFIED_TYPE,
  CUSTOMER_SATISFACTION_SCORE_TYPE,
} from '../../../settings/alliance-management/allianceKPIs/allianceKPIs-model';

const StyledHeading = styled(Heading)`
  height: 28px;
  color: #323c47;
  font-family: Poppins;
  font-size: 20px;
  line-height: 30px;
`;

const ContributionList = ({ selectedKPI }) => {
  const { name: KPIname, contributions, type: KPIType } = selectedKPI;
  const professionalsCertifiedAndTrainedKPIs = [
    PROFESSIONALS_TRAINED_TYPE,
    PROFESSIONALS_CERTIFIED_TYPE,
  ];

  const showUnitValue = () => {
    const isSatisfactionScore = KPIType === CUSTOMER_SATISFACTION_SCORE_TYPE;
    const isProfessionaTrained = professionalsCertifiedAndTrainedKPIs.includes(KPIType);

    return !isSatisfactionScore && !isProfessionaTrained;
  };

  const columns = `4fr 4fr 4fr 4fr 4fr 4fr ${showUnitValue() ? '4fr' : ''}`;

  return (
    <>
      <Card style={{ marginTop: '35px', marginBottom: '35px' }}>
        <Card.Header>
          <StyledHeading type="h4" text={KPIname} />
        </Card.Header>
        <Card.Body>
          <ListCardBody>
            <Table>
              <Table.Header className="justify-center-column" columns={columns}>
                <Table.HeaderCell className="name-column">Name</Table.HeaderCell>
                <Table.HeaderCell>Description</Table.HeaderCell>
                <Table.HeaderCell>Unit Type</Table.HeaderCell>
                {KPIType === CUSTOMER_SATISFACTION_SCORE_TYPE && (
                  <>
                    <Table.HeaderCell>Customer Satisfaction</Table.HeaderCell>
                  </>
                )}
                {showUnitValue() && <Table.HeaderCell>Unit Value</Table.HeaderCell>}

                {professionalsCertifiedAndTrainedKPIs.includes(KPIType) ? (
                  <>
                    <Table.HeaderCell>Professionals Trained</Table.HeaderCell>
                    <Table.HeaderCell>Professionals Certified</Table.HeaderCell>
                  </>
                ) : (
                  <>
                    <Table.HeaderCell>Unit Monetization Factor</Table.HeaderCell>
                    <Table.HeaderCell>Calculated Value</Table.HeaderCell>
                  </>
                )}
                <Table.HeaderCell>Unit Description</Table.HeaderCell>
              </Table.Header>
              <Table.Body data={contributions} className="card-body-list">
                {(item, index) => {
                  const { contribution } = item;
                  const {
                    id,
                    description,
                    name,
                    unitType,
                    unitQuantity,
                    unitValueDescription,
                    calculatedValue,
                    unitMonetizationFactor,
                    professionalsTrained,
                    professionalsCertified,
                    customerSatisfaction,
                  } = contribution;

                  return (
                    <>
                      <Table.BodyRow columns={columns} key={index}>
                        <Table.BodyCell className="justify-center-row">
                          <Link className="item-name" to={`/management/contribution/${id}/`}>
                            {name}
                          </Link>
                        </Table.BodyCell>
                        <Table.BodyCell className="justify-center-row">
                          {description}
                        </Table.BodyCell>
                        <Table.BodyCell className="justify-center-row">{unitType}</Table.BodyCell>
                        {KPIType === CUSTOMER_SATISFACTION_SCORE_TYPE && (
                          <>
                            <Table.BodyCell className="justify-center-row">
                              {customerSatisfaction}
                            </Table.BodyCell>
                          </>
                        )}
                        {showUnitValue() && (
                          <Table.BodyCell className="justify-center-row">
                            {unitQuantity}
                          </Table.BodyCell>
                        )}
                        {professionalsCertifiedAndTrainedKPIs.includes(KPIType) ? (
                          <>
                            <Table.BodyCell className="justify-center-row">
                              {professionalsTrained}
                            </Table.BodyCell>
                            <Table.BodyCell className="justify-center-row">
                              {professionalsCertified}
                            </Table.BodyCell>
                          </>
                        ) : (
                          <>
                            <Table.BodyCell className="justify-center-row">
                              <CurrencyTextField value={unitMonetizationFactor} />
                            </Table.BodyCell>
                            <Table.BodyCell className="justify-center-row">
                              <CurrencyTextField value={calculatedValue} />
                            </Table.BodyCell>
                          </>
                        )}
                        <Table.BodyCell className="justify-center-row">
                          {unitValueDescription}
                        </Table.BodyCell>
                      </Table.BodyRow>
                    </>
                  );
                }}
              </Table.Body>
            </Table>
          </ListCardBody>
        </Card.Body>
      </Card>
    </>
  );
};

ContributionList.propTypes = {
  selectedKPI: PropTypes.object.isRequired,
};

export { ContributionList };
