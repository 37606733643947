export const ContributionModel = {
  id: null,
  name: '',
  description: '',
  documents: [],
  initiatives: [],
  unitType: null,
  unitQuantity: 0,
  contributionDate: null,
  unitValueDescription: '',
  unitMonetizationFactor: '0',
  source: null,
  professionalsTrained: 0,
  professionalsCertified: 0,
  customerSatisfaction: 0,
};
export const CONTRIBUTION_DOCUMENTS = 'contributionDocuments';
