import { createEvent } from '@cobuildlab/react-simple-state';

export const OnSalesforceConnected = createEvent();
export const OnSalesforceConnectedError = createEvent();
export const OnSalesforceConnectionInvalid = createEvent();
export const OnSalesforceConnectionExpired = createEvent();
export const OnSalesforceDisconnected = createEvent();
export const OnSalesforceConnectionUpdate = createEvent();
export const OnSalesforceConnectionUpdateError = createEvent();
export const OnSalesforceUpdateNow = createEvent();
export const OnSalesforceUpdateNowError = createEvent();
export const OnSalesforceLogs = createEvent();
export const OnSalesforceLogsError = createEvent();
export const OnSalesforceFieldMappings = createEvent();
export const OnSalesforceFieldMappingsError = createEvent();
export const OnSalesforceObjectFields = createEvent();
export const OnSalesforceObjectFieldsError = createEvent();
export const OnSalesforceFieldMappingDelete = createEvent();
export const OnSalesforceFieldMappingDeleteError = createEvent();
export const OnSalesforceFieldMappingUpdate = createEvent();
export const OnSalesforceFieldMappingUpdateError = createEvent();
export const OnSalesforceFieldMappingCreate = createEvent();
export const OnSalesforceFieldMappingCreateError = createEvent();
export const OnSalesforceMissingsFields = createEvent();
export const OnSalesforceConnectionCreatedError = createEvent();
export const OnSalesforceConnectionCreated = createEvent();
export const OnSalesforceDisconnectedError = createEvent();
export const OnSalesforceConnectionLoading = createEvent();
export const OnSalesforceConnectionFieldUpdate = createEvent();
export const OnSalesforceConnectionFieldUpdateError = createEvent();
