import GearsIcon from '../../../../images/icons/gears.svg';
import TableIcon from '../../../../images/icons/table.svg';

const TABS_LIST = [
  {
    to: '/reports/pivot-table/configuration',
    label: 'Configuration',
    icon: GearsIcon,
  },
  {
    to: '/reports/pivot-table/report',
    label: 'Report',
    icon: TableIcon,
  },
];

export { TABS_LIST };
