import React from 'react';
import PropTypes from 'prop-types';

import { DataRows, TotalRow, HeaderRow, Table } from '../Components';

const SingleTable = ({ tableData, currencyFields }) => {
  const { title, headerRow, dataRows, totalRow } = tableData;
  return (
    <Table>
      <HeaderRow fixed={!title} row={headerRow} />
      <DataRows rowsList={dataRows} currencyFields={currencyFields} />
      <TotalRow row={totalRow} currencyFields={currencyFields} />
    </Table>
  );
};

SingleTable.propTypes = {
  tableData: PropTypes.object.isRequired,
  currencyFields: PropTypes.isRequired,
};

export { SingleTable };
