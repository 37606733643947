import React, { Fragment } from 'react';
import { Card, Heading, Row, Loader, Column, Text, Grid } from '@8base/boost';
import sessionStore, { NEW_SESSION_EVENT } from '../../../shared/SessionStore';
import { withRouter } from 'react-router-dom';
import { ViewCardBody } from '../../../components/card/ViewCardBody';
import PropTypes from 'prop-types';
import { View } from '@cobuildlab/react-simple-state';
import { onErrorMixin } from '../../../shared/mixins';
import * as toast from '../../../components/toast/Toast';
import { fetchInitiativeActiveItems } from './initiative-active-items-actions';
import { getInitiativeActiveItems, filterActiveItemsByDueDate } from '../active-items-utils';
import UserSmallAvatar from '../../../components/user/UserSmallAvatar';
import HorizontalLineText from '../work-queue/components/HorizontalLineText';
import Hr from '../../../components/text/Hr';
import ActiveItem from '../ActiveItem';
import YesNoDialog from '../../../components/dialogs/YesNoDialog';
import { fetchInitiative } from '../../management/initiative/initiative-actions';
import { getChartOptions } from '../../dashboard/dashboard-actions';
import PieChart from '../../../components/PieChart';
import Status from '../../../components/Status';
import { Small1 } from '../../../components/new-ui/font-style/Small1';
import { onActiveItemsError, onInitiativeActiveItems } from './initiative-active-items-event';
import {
  OnInitiativeError,
  OnInitiativeDetail,
} from '../../management/initiative/initiative-events';
import { nextStepErrorEvent, nextStepCompletedEvent } from '../../next-step/next-step-event';
import { OnIdeaError, OnIdeaCompleted } from '../../management/idea/idea-events';
import { OnIssueError, OnIssueCompleted } from '../../management/issue/issue-events';
import { OnRiskError, OnRiskCompleted } from '../../management/risk/risk-events';
import { OnActionError, OnActionCompleted } from '../../management/action/action-events';
import {
  onFundingRequestError,
  onFundingRequestCompleted,
} from '../../management/funding-request/funding-request-event';

/**
 * The items to show on the view, to show the state items acording to the
 * selected item to show.
 *
 * @type {Array}
 */
const ITEMS_TO_SHOW = [
  { stateName: 'allItems', text: 'All Items' },
  { stateName: 'inProgresItems', text: 'In Progress' },
  { stateName: 'completedItems', text: 'Completed' },
];

/**
 * The filtered items to collapse on the view, other filtered items will remain
 * hidden.
 *
 * @type {Array}
 */
const FILTERED_ITEMS_TO_SHOW = [
  { stateName: 'overDueItems', text: 'Over Due' },
  { stateName: 'dueThisWeekItems', text: 'Due This Week' },
  { stateName: 'notDueItems', text: 'Not Due' },
  { stateName: 'completedDueItems', text: 'Completed' },
];

class InitiativeActiveItemsView extends View {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      completeFunction: null,
      selectedItemToComplete: null,
      completeModalIsOpen: false,
      itemStateToShow: ITEMS_TO_SHOW[0].stateName,
      initiative: null,
      allItems: [],
      inProgresItems: [],
      completedItems: [],
      /*
      activeItems filtered by due date
       */
      overDueItems: [],
      overDueItemsIsOpen: true,
      dueThisWeekItems: [],
      dueThisWeekItemsIsOpen: false,
      notDueItems: [],
      notDueItemsIsOpen: false,
      completedDueItems: [],
      completedDueItemsIsOpen: false,
      initiativeId: null,
      /*
      activeItems filtered by due date
       */
    };
    this.onError = onErrorMixin.bind(this);
    this.user = sessionStore.getState(NEW_SESSION_EVENT).user;
    this.selectedAlliance = sessionStore.getState(NEW_SESSION_EVENT).selectedAlliance;
  }

  componentDidMount() {
    const { match } = this.props;

    this.subscribe(onActiveItemsError, this.onError);
    this.subscribe(OnInitiativeError, this.onError);
    this.subscribe(nextStepErrorEvent, this.onError);
    this.subscribe(OnIdeaError, this.onError);
    this.subscribe(OnIssueError, this.onError);
    this.subscribe(OnRiskError, this.onError);
    this.subscribe(OnActionError, this.onError);
    this.subscribe(onFundingRequestError, this.onError);

    this.subscribe(OnInitiativeDetail, ({ initiative }) => {
      this.setState({ initiative });
    });
    this.subscribe(nextStepCompletedEvent, () => {
      toast.success('Next Step Successfully Completed');
      fetchInitiativeActiveItems(match.params.id);
    });
    this.subscribe(OnIdeaCompleted, () => {
      toast.success('Idea Successfully Completed');
      fetchInitiativeActiveItems(match.params.id);
    });
    this.subscribe(OnIssueCompleted, () => {
      toast.success('Issue Successfully Completed');
      fetchInitiativeActiveItems(match.params.id);
    });
    this.subscribe(OnRiskCompleted, () => {
      toast.success('Risk Successfully Completed');
      fetchInitiativeActiveItems(match.params.id);
    });
    this.subscribe(OnActionCompleted, () => {
      toast.success('Action Successfully Completed');
      fetchInitiativeActiveItems(match.params.id);
    });
    this.subscribe(onFundingRequestCompleted, () => {
      toast.success('Funding Request Successfully Completed');
      fetchInitiativeActiveItems(match.params.id);
    });

    this.subscribe(onInitiativeActiveItems, (state) => {
      const { items } = state.itemsList;
      const {
        allActiveItems: allItems,
        inProgresActiveItems: inProgresItems,
        completedActiveItems: completedItems,
      } = getInitiativeActiveItems(items, []);

      const {
        overDueItems,
        dueThisWeekItems,
        notDueItems,
        completedDueItems,
      } = filterActiveItemsByDueDate(allItems);

      this.setState({
        loading: false,
        initiativeId: match.params.id,
        allItems,
        inProgresItems,
        completedItems,
        overDueItems,
        dueThisWeekItems,
        notDueItems,
        completedDueItems,
        itemStateToShow: ITEMS_TO_SHOW[0].stateName,
      });
    });

    if (!match.params.id) return toast.error('Initiative ID missing');
    fetchInitiativeActiveItems(match.params.id);
    fetchInitiative(match.params.id);
  }

  setItemsToShow = (itemStateToShow) => {
    if (itemStateToShow === this.state.itemStateToShow) return;

    const {
      overDueItems,
      dueThisWeekItems,
      notDueItems,
      completedDueItems,
    } = filterActiveItemsByDueDate(this.state[itemStateToShow]);

    this.setState({
      itemStateToShow: itemStateToShow,
      overDueItems,
      dueThisWeekItems,
      notDueItems,
      completedDueItems,
    });
  };

  toggleFilteredItems = (filteredItemsStateName) => {
    const stateName = `${filteredItemsStateName}IsOpen`;
    this.setState({ [stateName]: !this.state[stateName] });
  };

  onClickComplete = (completeFunction, selectedItemToComplete) => {
    this.setState({
      completeModalIsOpen: true,
      completeFunction,
      selectedItemToComplete,
    });
  };

  onYesComplete = () => {
    const { completeFunction, selectedItemToComplete } = this.state;
    this.setState(
      {
        completeModalIsOpen: false,
        loading: true,
      },
      () => {
        completeFunction(selectedItemToComplete);
      },
    );
  };

  onCloseComplete = () => {
    this.setState({
      completeModalIsOpen: false,
    });
  };

  onClickChart = (event) => {
    let due = event.data.type;
    let item = event.data.itemType;
    const { initiativeId } = this.state;
    this.props.history.push(
      `/management/amo-item?due=${due}&item=${item}&initiative=${initiativeId}`,
    );
  };

  onClickDue = (due, item) => {
    const { initiativeId } = this.state;
    this.props.history.push(
      `/management/amo-item?due=${due}&item=${item}&initiative=${initiativeId}`,
    );
  };

  render() {
    const { loading, allItems, completeModalIsOpen, initiative } = this.state;
    const onEvents = {
      click: this.onClickChart,
    };
    const { risksOptions, issuesOptions, actionsOptions } = getChartOptions(allItems);

    let content = <Loader stretch />;

    if (!loading) {
      content = (
        <div id={'c2gScreen'}>
          <Row growChildren gap="md" alignItems="center">
            <Column style={{ width: '100%' }}>
              {initiative ? (
                <>
                  <Text weight="bold">{initiative.name}</Text>
                  {/* <Progress color={initiative.ragStatus} size="sm" value={progressValue} /> */}
                  <Status status={initiative.ragStatus} />
                  <UserSmallAvatar
                    owner={initiative.owner}
                    text="is the owner of this initiative"
                  />
                </>
              ) : null}
            </Column>
          </Row>
          <Row growChildren gap="md" alignItems="center" style={{ marginTop: '30px' }}>
            <Column>
              <PieChart
                title="Risks"
                itemName="RISK"
                paperPadding="xs"
                onEvents={onEvents}
                data={risksOptions}
                onClickLegend={this.onClickDue}
              />
            </Column>
            <Column>
              <PieChart
                title="Issues"
                itemName="ISSUE"
                paperPadding="xs"
                onEvents={onEvents}
                data={issuesOptions}
                onClickLegend={this.onClickDue}
              />
            </Column>
            <Column>
              <PieChart
                title="Actions"
                itemName="ACTION"
                paperPadding="xs"
                onEvents={onEvents}
                data={actionsOptions}
                onClickLegend={this.onClickDue}
              />
            </Column>
          </Row>

          <Hr marginHorizontal={-24} />

          {FILTERED_ITEMS_TO_SHOW.map(({ stateName, text }, i) => {
            const filteredItems = this.state[stateName];
            const active = this.state[`${stateName}IsOpen`];
            const count = filteredItems.length;

            return (
              <Fragment key={i}>
                <HorizontalLineText
                  onClick={() => this.toggleFilteredItems(stateName)}
                  text={text}
                  active={active}
                  count={count}
                />
                {/* show the filtered items if isOpen */}
                {active && count ? (
                  <Grid.Layout padding="xl">
                    {filteredItems.map((activeItem, i) => {
                      return (
                        <ActiveItem
                          item={activeItem}
                          key={i}
                          onClickComplete={this.onClickComplete}
                          showNextSteps={true}
                        />
                      );
                    })}
                  </Grid.Layout>
                ) : null}
              </Fragment>
            );
          })}
        </div>
      );
    }

    return (
      <Fragment>
        <Card.Header>
          <Heading type="h4" text={'Initiative Dashboard'} />
        </Card.Header>
        <ViewCardBody>{content}</ViewCardBody>
        <Card.Footer>
          <Row justifyContent={'left'}>
            <Small1>
              <u>NOTE:</u> Items with status REJECT or CLOSE will not be show
            </Small1>
          </Row>
        </Card.Footer>
        <YesNoDialog
          isOpen={completeModalIsOpen}
          onYes={this.onYesComplete}
          onNo={this.onCloseComplete}
          onClose={this.onCloseComplete}
          text={'Are you sure you want to Mark this Item as Completed?'}
          title={'Mark Completed'}
        />
      </Fragment>
    );
  }
}

InitiativeActiveItemsView.propTypes = {
  history: PropTypes.object.isRequired,
};

export default withRouter(InitiativeActiveItemsView);
