import Flux from '@cobuildlab/flux-state';
import sessionStore, { APOLLO_CLIENT, NEW_SESSION_EVENT } from '../../../shared/SessionStore';
import { IntegrityError } from '../../../shared/errors';
import { error, log } from '@cobuildlab/pure-logger';
import {
  INVESTMENT_ITEM_DELETE_EVENT,
  INVESTMENT_ITEM_ERROR_EVENT,
  INVESTMENT_ITEM_LIST_EVENT,
  INVESTMENT_ALL_ITEM_LIST_EVENT,
} from './investment-item-store';
import {
  INVESTMENT_ITEM_DELETE_MUTATION,
  INVESTMENT_ITEM_LIST_QUERY,
} from './investment-item-queries';
import { getActiveAllianceId } from '../../../shared/alliance-utils';
import { businessCaseSearchFilterOR } from '../../document-management/business-case/businessCases.actions';
import {
  INVESTMENT_ITEM_OVER_DUE,
  INVESTMENT_ITEM_DUE_THIS_WEEK,
  INVESTMENT_ITEM_ON_TIME,
} from '../../../shared/status';
import moment from 'moment';
import { ITEM_FUNDING_REQUEST, ITEM_CONTRIBUTION } from '../../../shared/items-util';
import { filterForYear } from '../../../shared/utils';
import {
  OnInvestmentItemError,
  OnInvestmentItemList,
  OnInvestmentItemDelete,
  OnInvestmentItemAllList,
} from './investmen-item-events';

/**
 * Creates a filter object and search by a string
on string properties of the InvestmentItems.
 *
 * @param {string} data -  Data to filter investment items.
 
 * @returns {object}             The filter object.
 */
export const investmentItemsFilter = ({
  allianceId,
  search = '',
  filterParam,
  itemFilter,
  filterYear = null,
}) => {
  const contributionFilter = {
    contribution: {
      id: { not_equals: null },
      OR: [
        {
          name: {
            contains: search,
          },
        },
        {
          description: {
            contains: search,
          },
        },
        {
          owner: {
            firstName: { contains: search },
          },
        },
        {
          owner: {
            lastName: { contains: search },
          },
        },
        {
          owner: {
            email: { contains: search },
          },
        },
      ].concat(businessCaseSearchFilterOR(search)),
    },
  };

  const fundingRequestFilter = {
    fundingRequest: {
      id: { not_equals: null },
      OR: [
        {
          name: {
            contains: search,
          },
        },
        {
          description: {
            contains: search,
          },
        },
        {
          owner: {
            firstName: { contains: search },
          },
        },
        {
          owner: {
            lastName: { contains: search },
          },
        },
        {
          owner: {
            email: { contains: search },
          },
        },
        {
          requestedBy: {
            firstName: { contains: search },
          },
        },
        {
          requestedBy: {
            lastName: { contains: search },
          },
        },
        {
          requestedBy: {
            email: { contains: search },
          },
        },
        {
          assignedTo: {
            firstName: { contains: search },
          },
        },
        {
          assignedTo: {
            lastName: { contains: search },
          },
        },
        {
          assignedTo: {
            email: { contains: search },
          },
        },
      ].concat(businessCaseSearchFilterOR(search)),
    },
  };

  if (filterYear) {
    const filterForYearData = filterForYear(
      filterYear.year,
      filterYear.fieldName,
      filterYear.isDateTime,
    );

    if (filterYear.fieldName === 'contributionDate') {
      contributionFilter.contribution.AND = filterForYearData.AND;
    } else {
      contributionFilter.contribution.AND = filterForYearData.AND;
      fundingRequestFilter.fundingRequest.AND = filterForYearData.AND;
    }
  }

  let filter = {
    alliance: { id: { equals: allianceId } },
    OR: [contributionFilter, fundingRequestFilter],
  };

  if (filterParam === INVESTMENT_ITEM_OVER_DUE) {
    fundingRequestFilter.fundingRequest.revisedDueDate = { lt: moment().format('YYYY-MM-DD') };

    filter = {
      alliance: { id: { equals: allianceId } },
      OR: [fundingRequestFilter],
    };
  } else if (filterParam === INVESTMENT_ITEM_DUE_THIS_WEEK) {
    fundingRequestFilter.fundingRequest.AND = [
      {
        revisedDueDate: {
          lte: moment()
            .day(7)
            .format('YYYY-MM-DD'),
        },
      },
      {
        revisedDueDate: { gte: moment().format('YYYY-MM-DD') },
      },
    ];
    filter = {
      alliance: { id: { equals: allianceId } },
      OR: [fundingRequestFilter],
    };
  } else if (filterParam === INVESTMENT_ITEM_ON_TIME) {
    fundingRequestFilter.fundingRequest.revisedDueDate = {
      gte: moment()
        .day(8)
        .format('YYYY-MM-DD'),
    };
    filter = {
      alliance: { id: { equals: allianceId } },
      OR: [fundingRequestFilter],
    };
  } else if (filterParam != null) {
    contributionFilter.contribution.status = { equals: filterParam };
    fundingRequestFilter.fundingRequest.status = { equals: filterParam };
  }

  if (itemFilter === ITEM_FUNDING_REQUEST) {
    filter = {
      alliance: { id: { equals: allianceId } },
      OR: [fundingRequestFilter],
    };
  } else if (itemFilter === ITEM_CONTRIBUTION) {
    filter = {
      alliance: { id: { equals: allianceId } },
      OR: [contributionFilter],
    };
  }

  return filter;
};

/**
 * Fetches the Contribution Items.
Fetches the Contribution Items of the Current Alliance.
 *
 * @param {string}search - Search.
 * @param {number}page - Page.
 * @param {number}first - First.
 * @param {string}filterParam - Filter Param.
 * @param {string}itemFilter - Item filter.
 * @param {object}filterYear - Filter Year.
 * @returns {Promise<void>} Promise.
 */
export const fetchInvestmentItems = async (
  search = '',
  page = 1,
  first = 20,
  filterParam,
  itemFilter,
  filterYear = null,
) => {
  const client = sessionStore.getState(APOLLO_CLIENT);
  const user = sessionStore.getState(NEW_SESSION_EVENT).user;
  const allianceId = getActiveAllianceId(user);
  const skip = (page - 1) * first;

  if (!allianceId) {
    OnInvestmentItemError.dispatch(new IntegrityError('An Alliance must be selected'));

    return Flux.dispatchEvent(
      INVESTMENT_ITEM_ERROR_EVENT,
      new IntegrityError('An Alliance must be selected'),
    );
  }

  const filter = investmentItemsFilter({ allianceId, search, filterParam, itemFilter, filterYear });
  let response;
  try {
    response = await client.query({
      query: INVESTMENT_ITEM_LIST_QUERY,
      variables: { data: filter, skip, first },
      fetchPolicy: 'network-only',
    });
  } catch (e) {
    error('fetchInvestmentItems', e);
    OnInvestmentItemError.dispatch(e);
    Flux.dispatchEvent(INVESTMENT_ITEM_ERROR_EVENT, e);
    throw e;
  }
  log('fetchInvestmentItems', response);

  OnInvestmentItemList.dispatch(response.data);
  Flux.dispatchEvent(INVESTMENT_ITEM_LIST_EVENT, response.data);

  return response.data;
};

/**
 * Delete an Investment Item.
 *
 * @param {object}investmentItem - Investment Item.
 * @returns {Promise<*>} Promise.
 */
export const deleteInvestmentItem = async (investmentItem) => {
  const client = sessionStore.getState(APOLLO_CLIENT);

  let response;
  try {
    response = await client.mutate({
      mutation: INVESTMENT_ITEM_DELETE_MUTATION,
      variables: { data: { id: investmentItem.id, force: true } },
    });
  } catch (e) {
    error('deleteInvestmentItem', e);
    OnInvestmentItemError.dispatch(e);
    return Flux.dispatchEvent(INVESTMENT_ITEM_ERROR_EVENT, e);
  }
  log('deleteInvestmentItem', response);
  OnInvestmentItemDelete.dispatch(response.data);
  Flux.dispatchEvent(INVESTMENT_ITEM_DELETE_EVENT, response.data);
  return response.data;
};

/**
 * Fetches all invesment Items.
 *
 * @param {string}filterParam - Filter Param.
 * @param {string}itemFilter - Item Filter.
 * @param {object}filterYear - Filter Year.
 * @returns {Promise<void>} Promise.
 */
export const fetchAllInvestmentItems = async (filterParam, itemFilter, filterYear = null) => {
  const client = sessionStore.getState(APOLLO_CLIENT);
  const user = sessionStore.getState(NEW_SESSION_EVENT).user;
  const allianceId = getActiveAllianceId(user);

  if (!allianceId) {
    OnInvestmentItemError.dispatch(new IntegrityError('An Alliance must be selected'));

    return Flux.dispatchEvent(
      INVESTMENT_ITEM_ERROR_EVENT,
      new IntegrityError('An Alliance must be selected'),
    );
  }

  const filter = investmentItemsFilter({ allianceId, filterParam, itemFilter, filterYear });
  let response;
  try {
    response = await client.query({
      query: INVESTMENT_ITEM_LIST_QUERY,
      variables: { data: filter },
      fetchPolicy: 'network-only',
    });
  } catch (e) {
    error('fetchInvestmentItems', e);
    OnInvestmentItemError.dispatch(e);
    Flux.dispatchEvent(INVESTMENT_ITEM_ERROR_EVENT, e);
    throw e;
  }
  log('fetchInvestmentItems', response);

  OnInvestmentItemAllList.dispatch(response.data);
  Flux.dispatchEvent(INVESTMENT_ALL_ITEM_LIST_EVENT, response.data);

  return response.data;
};
