import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import { Tabs } from '../../../components/tabs/Tabs';
// import { ForecastingConfigView } from './ForecastingConfigView';
// import { ForecastingReportView } from './ForecastingReportView';
import { ForecastingFormView } from './ForecastingFormView';
import { TABS_LIST } from './forecasting-model';
import { ForecastingReportViewContributions } from './ForecastingReportViewContributions';
import { ForecastingReportViewBookings } from './ForecastingReportViewBookings';

const ForecastingView = () => {
  return (
    <div id={'c2gScreen'}>
      <Tabs tabsList={TABS_LIST} />
      <Switch>
        <Route path="/reports/forecasting/forecasting" component={ForecastingFormView} />
        <Route
          path="/reports/forecasting/contributions"
          component={ForecastingReportViewContributions}
        />
        <Route path="/reports/forecasting/bookings" component={ForecastingReportViewBookings} />
        <Redirect to="/reports/forecasting/forecasting" />
      </Switch>
    </div>
  );
};

export { ForecastingView };
