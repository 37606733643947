/**
 * Company Invitation Status.
 */
export const COMPANY_INVITATION_PENDING = 'PENDING';
export const COMPANY_INVITATION_ACCEPTED = 'ACCEPTED';
export const COMPANY_INVITATION_APPROVED = 'APPROVED';
export const COMPANY_INVITATION_REJECTED = 'REJECTED';

/**
 * Alliance Invitation Status.
 */
export const ALLIANCE_INVITATION_ACCEPTED = 'ACCEPTED';
export const ALLIANCE_INVITATION_PENDING = 'PENDING';
export const ALLIANCE_INVITATION_APPROVED = 'APPROVED';
export const ALLIANCE_INVITATION_REJECTED = 'REJECTED';
export const ALLIANCE_INVITATION_CANCELLED = 'CANCELLED';
export const ALLIANCE_INVITATION_SUBMITTED_FOR_APPROVAL = 'SUBMITTED FOR APPROVAL';
export const ALLIANCE_INVITATION_STATUS = [
  ALLIANCE_INVITATION_ACCEPTED,
  ALLIANCE_INVITATION_APPROVED,
  ALLIANCE_INVITATION_REJECTED,
  ALLIANCE_INVITATION_SUBMITTED_FOR_APPROVAL,
  ALLIANCE_INVITATION_CANCELLED,
];

/**
 * Alliance Status.
 */
export const ALLIANCE_APPROVED = 'APPROVED';
export const ALLIANCE_REJECTED = 'REJECTED';
export const ALLIANCE_SUBMITTED_FOR_APPROVAL = 'SUBMITTED FOR APPROVAL';
export const ALLIANCE_IN_PROGRESS = 'IN PROGRESS';
export const ALLIANCE_COMPLETED = 'COMPLETED';

export const ALLIANCE_STATUS = [
  ALLIANCE_APPROVED,
  ALLIANCE_SUBMITTED_FOR_APPROVAL,
  ALLIANCE_IN_PROGRESS,
  ALLIANCE_COMPLETED,
];
export const ALLIANCE_STATUS_LABELS = [
  { label: 'None' },
  { label: 'In Progress', value: ALLIANCE_IN_PROGRESS },
  { label: 'Approved', value: ALLIANCE_APPROVED },
  { label: 'Rejected', value: ALLIANCE_REJECTED },
  { label: 'Submitted For Approval', value: ALLIANCE_SUBMITTED_FOR_APPROVAL },
  { label: 'Completed', value: ALLIANCE_COMPLETED },
];

/**
 * Alliance Member Invitation Status.
 */
export const ALLIANCE_MEMBER_INVITATION_ACCEPTED = 'ACCEPTED';
export const ALLIANCE_MEMBER_INVITATION_PENDING = 'PENDING';
export const ALLIANCE_MEMBER_INVITATION_REJECTED = 'REJECTED';
export const ALLIANCE_MEMBER_INVITATION_CANCELLED = 'CANCELLED';

/**
 * Alliance Approvals.
 */
export const ALLIANCE_APPROVAL_APPROVED = 'APPROVED';
export const ALLIANCE_APPROVAL_REJECTED = 'REJECTED';
export const ALLIANCE_APPROVAL_PENDING = 'PENDING';

/**
 * Investment Item Status.
 */
export const INVESTMENT_ITEM_IN_PROGRESS = 'IN PROGRESS';
export const INVESTMENT_ITEM_APPROVED = 'APPROVED';
export const INVESTMENT_ITEM_REJECTED = 'REJECTED';
export const INVESTMENT_ITEM_SUBMITTED_FOR_APPROVAL = 'SUBMITTED FOR APPROVAL';
export const INVESTMENT_ITEM_OPEN = 'OPEN';
export const INVESTMENT_ITEM_COMPLETED = 'COMPLETED';
export const INVESTMENT_ITEM_OVER_DUE = 'OVER_DUE';
export const INVESTMENT_ITEM_DUE_THIS_WEEK = 'DUE_THIS_WEEK';
export const INVESTMENT_ITEM_ON_TIME = 'ON_TIME';
/**
 * Investment Items Status Labels.
 */
export const INVESTMENT_ITEM_STATUS_LABELS = [
  { label: 'None' },
  { label: 'In Progress', value: INVESTMENT_ITEM_IN_PROGRESS },
  { label: 'Approved', value: INVESTMENT_ITEM_APPROVED },
  { label: 'Rejected', value: INVESTMENT_ITEM_REJECTED },
  { label: 'Submitted For Approval', value: INVESTMENT_ITEM_SUBMITTED_FOR_APPROVAL },
  { label: 'Open', value: INVESTMENT_ITEM_OPEN },
  { label: 'Completed', value: INVESTMENT_ITEM_COMPLETED },
  { label: 'Over Due', value: INVESTMENT_ITEM_OVER_DUE },
  { label: 'Due This Week', value: INVESTMENT_ITEM_DUE_THIS_WEEK },
  { label: 'On Time', value: INVESTMENT_ITEM_ON_TIME },
];

/**
 * AMO Item Status.
 */
export const AMO_ITEM_IN_PROGRESS = 'IN PROGRESS';
export const AMO_ITEM_APPROVED = 'APPROVED';
export const AMO_ITEM_REJECTED = 'REJECTED';
export const AMO_ITEM_SUBMITTED_FOR_APPROVAL = 'SUBMITTED FOR APPROVAL';
export const AMO_ITEM_OPEN = 'OPEN';
export const AMO_ITEM_CLOSED = 'CLOSED';
export const AMO_ITEM_COMPLETED = 'COMPLETED';
export const AMO_ITEM_OVER_DUE = 'OVER_DUE';
export const AMO_ITEM_DUE_THIS_WEEK = 'DUE_THIS_WEEK';
export const AMO_ITEM_ON_TIME = 'ON_TIME';
export const AMO_ITEMS_STATUS_LABELS = [
  { label: 'None' },
  { label: 'In Progress', value: AMO_ITEM_IN_PROGRESS },
  { label: 'Approved', value: AMO_ITEM_APPROVED },
  { label: 'Rejected', value: AMO_ITEM_REJECTED },
  { label: 'Submitted For Approval', value: AMO_ITEM_SUBMITTED_FOR_APPROVAL },
  { label: 'Open', value: AMO_ITEM_OPEN },
  { label: 'Closed', value: AMO_ITEM_CLOSED },
  { label: 'Completed', value: AMO_ITEM_COMPLETED },
  { label: 'Over Due', value: AMO_ITEM_OVER_DUE },
  { label: 'Due This Week', value: AMO_ITEM_DUE_THIS_WEEK },
  { label: 'On Time', value: AMO_ITEM_ON_TIME },
];

/**
 * Funding Request Status.
 */
export const FUNDING_REQUEST_IN_PROGRESS = 'IN PROGRESS';
export const FUNDING_REQUEST_APPROVED = 'APPROVED';
export const FUNDING_REQUEST_REJECTED = 'REJECTED';
export const FUNDING_REQUEST_COMPLETED = 'COMPLETED';
export const FUNDING_REQUEST_SUBMITTED_FOR_APPROVAL = 'SUBMITTED FOR APPROVAL';
/**
 * Funding Request Approval Status.
 */
export const FUNDING_REQUEST_APPROVAL_PENDING = 'PENDING';
export const FUNDING_REQUEST_APPROVAL_APPROVED = 'APPROVED';
export const FUNDING_REQUEST_APPROVAL_REJECTED = 'REJECTED';
export const FUNDING_REQUEST_APPROVAL_STATUS = [
  FUNDING_REQUEST_APPROVAL_PENDING,
  FUNDING_REQUEST_APPROVAL_APPROVED,
  FUNDING_REQUEST_APPROVAL_REJECTED,
];

/**
 * Contribution Status.
 */
export const CONTRIBUTION_OPEN = 'OPEN';
export const CONTRIBUTION_COMPLETED = 'COMPLETED';
/**
 * Initiative Status.
 */
export const INITIATIVE_IN_PROGRESS = 'IN PROGRESS';
export const INITIATIVE_APPROVED = 'APPROVED';
export const INITIATIVE_REJECTED = 'REJECTED';
export const INITIATIVE_OPEN = 'OPEN';
export const INITIATIVE_CLOSED = 'CLOSED';
export const INITIATIVE_COMPLETED = 'COMPLETED';
export const INITIATIVE_SUBMITTED_FOR_APPROVAL = 'SUBMITTED FOR APPROVAL';
export const RAG_RED = 'RED';
export const RAG_AMBER = 'AMBER';
export const RAG_GREEN = 'GREEN';
export const INITIATIVE_STATUS = [
  INITIATIVE_IN_PROGRESS,
  INITIATIVE_APPROVED,
  INITIATIVE_REJECTED,
  INITIATIVE_SUBMITTED_FOR_APPROVAL,
];
export const INITIATIVE_STATUS_LABELS = [
  { label: 'None' },
  { label: 'In Progress', value: INITIATIVE_IN_PROGRESS },
  { label: 'Approved', value: INITIATIVE_APPROVED },
  { label: 'Rejected', value: INITIATIVE_REJECTED },
  { label: 'Submitted For Approval', value: INITIATIVE_SUBMITTED_FOR_APPROVAL },
  { label: 'Open', value: INITIATIVE_OPEN },
  { label: 'Closed', value: INITIATIVE_CLOSED },
  { label: 'Completed', value: INITIATIVE_COMPLETED },
];
export const INITIATIVE_RAG_STATUS_LABELS = [
  { label: 'Red', value: RAG_RED },
  { label: 'Amber', value: RAG_AMBER },
  { label: 'Green', value: RAG_GREEN },
];

/**
 * Initiative Approval Status.
 */
export const INITIATIVE_APPROVAL_PENDING = 'PENDING';
export const INITIATIVE_APPROVAL_APPROVED = 'APPROVED';
export const INITIATIVE_APPROVAL_REJECTED = 'REJECTED';
export const INITIATIVE_APPROVAL_STATUS = [
  INITIATIVE_APPROVAL_PENDING,
  INITIATIVE_APPROVAL_APPROVED,
  INITIATIVE_APPROVAL_REJECTED,
];

/**
 * Issues Status.
 */
export const ISSUE_OPEN = 'OPEN';
export const ISSUE_CLOSED = 'CLOSED';
export const ISSUE_COMPLETED = 'COMPLETED';
export const ISSUE_STATUS = [ISSUE_OPEN, ISSUE_CLOSED];

/**
 * Risk Status.
 */
export const RISK_OPEN = 'OPEN';
export const RISK_CLOSED = 'CLOSED';
export const RISK_COMPLETED = 'COMPLETED';
/**
 * Action Status.
 */
export const ACTION_OPEN = 'OPEN';
export const ACTION_CLOSED = 'CLOSED';
export const ACTION_COMPLETED = 'COMPLETED';
export const ACTION_IN_PROGRESS = 'IN PROGRESS';
export const ACTION_APPROVED = 'APPROVED';
export const ACTION_REJECTED = 'REJECTED';
export const ACTION_SUBMITTED_FOR_APPROVAL = 'SUBMITTED FOR APPROVAL';
/**
 * Action Approval Status.
 */
export const ACTION_APPROVAL_PENDING = 'PENDING';
export const ACTION_APPROVAL_APPROVED = 'APPROVED';
export const ACTION_APPROVAL_REJECTED = 'REJECTED';
/**
 * Idea Status.
 */
export const IDEA_IN_PROGRESS = 'IN PROGRESS';
export const IDEA_APPROVED = 'APPROVED';
export const IDEA_REJECTED = 'REJECTED';
export const IDEA_SUBMITTED_FOR_APPROVAL = 'SUBMITTED FOR APPROVAL';
export const IDEA_COMPLETED = 'COMPLETED';
export const IDEA_STATUS_LABELS = [
  { label: 'None' },
  { label: 'In Progress', value: IDEA_IN_PROGRESS },
  { label: 'Approved', value: IDEA_APPROVED },
  { label: 'Rejected', value: IDEA_REJECTED },
  { label: 'Submitted For Approval', value: IDEA_SUBMITTED_FOR_APPROVAL },
  { label: 'Completed', value: INITIATIVE_COMPLETED },
];

/**
 * Idea Approval Status.
 */
export const IDEA_APPROVAL_PENDING = 'PENDING';
export const IDEA_APPROVAL_APPROVED = 'APPROVED';
export const IDEA_APPROVAL_REJECTED = 'REJECTED';
/**
 * Decision Status.
 */
export const DECISION_OPEN = 'OPEN';
export const DECISION_CLOSED = 'CLOSED';
export const DECISION_COMPLETED = 'COMPLETED';
/**
 * Deal Status.
 */
export const DEAL_OPEN = 'OPEN';
export const DEAL_CLOSED = 'CLOSED';
export const DEAL_COMPLETED = 'COMPLETED';
/**
 * NextStep Status.
 */
export const NEXT_STEP_OPEN = 'OPEN';
export const NEXT_STEP_COMPLETED = 'COMPLETED';

/**
 * Active Items status, for frontend only, not 8base model.
 */
export const ACTIVE_ITEM_COMPLETED = 'COMPLETED';

/**
 * CompanySubscription statuses.
 *
 * @type {string}
 */
export const COMPANY_SUBSCRIPTION_ACTIVE = 'active';
export const COMPANY_SUBSCRIPTION_INCOMPLETE = 'incomplete';
export const COMPANY_SUBSCRIPTION_INCOMPLETE_EXPIRED = 'incomplete_expired';
export const COMPANY_SUBSCRIPTION_TRIALING = 'trialing';
export const COMPANY_SUBSCRIPTION_PAST_DUE = 'past_due';
export const COMPANY_SUBSCRIPTION_CANCELED = 'canceled';
export const COMPANY_SUBSCRIPTION_UNPAID = 'unpaid';
