import GearsIcon from '../../../images/icons/gears.svg';
import TableIcon from '../../../images/icons/table.svg';
import PieChartIcon from '../../../images/icons/pie-chart.svg';

const FILTER_FIELDS_LIST = [
  {
    id: 'id-2',
    displayName: 'Date',
    fieldType: 'YEAR_MONTH',
    name: 'yearMonth',
  },
];

const GROUPING_FIELDS_LIST = [
  {
    id: 'id-1',
    displayName: 'Stage',
    fieldType: 'STRING',
    name: 'stage',
  },
  {
    id: 'id-3',
    displayName: 'Alliance deal owner',
    fieldType: 'RELATION',
    name: 'owner.id',
  },
  {
    id: 'id-4',
    displayName: 'Sales account rep',
    fieldType: 'STRING',
    name: 'salesPerson',
  },
];

const SALES_PIPELINE_TRENDS_CONFIGURATION_TAB = '/reports/sales-pipeline-trends/configuration';
const SALES_PIPELINE_TRENDS_REPORT_TAB = '/reports/sales-pipeline-trends/report';
const SALES_PIPELINE_TRENDS_CHARTS_TAB = '/reports/sales-pipeline-trends/charts';

const TABS_LIST = [
  {
    to: SALES_PIPELINE_TRENDS_CONFIGURATION_TAB,
    label: 'Configuration',
    icon: GearsIcon,
  },
  {
    to: SALES_PIPELINE_TRENDS_REPORT_TAB,
    label: 'Report',
    icon: TableIcon,
  },
  {
    to: SALES_PIPELINE_TRENDS_CHARTS_TAB,
    label: 'Charts',
    icon: PieChartIcon,
  },
];

const GROUP_TITLE = {
  stage: 'STAGES',
  'owner.id': 'ALLIANCE DEAL OWNER',
  salesPerson: 'SALES ACCOUNT REP',
};

const INITIAL_STATE = {
  filters: [],
  groups: [{ key: 'stage', sort: 'ASC' }],
};

export {
  TABS_LIST,
  FILTER_FIELDS_LIST,
  GROUPING_FIELDS_LIST,
  SALES_PIPELINE_TRENDS_CONFIGURATION_TAB,
  SALES_PIPELINE_TRENDS_REPORT_TAB,
  SALES_PIPELINE_TRENDS_CHARTS_TAB,
  GROUP_TITLE,
  INITIAL_STATE,
};
