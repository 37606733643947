import {
  INVESTMENT_ITEM_APPROVED,
  INVESTMENT_ITEM_SUBMITTED_FOR_APPROVAL,
  ALLIANCE_APPROVED,
  INVESTMENT_ITEM_COMPLETED,
  CONTRIBUTION_COMPLETED,
  FUNDING_REQUEST_APPROVED,
  FUNDING_REQUEST_COMPLETED,
  FUNDING_REQUEST_SUBMITTED_FOR_APPROVAL,
} from '../../../shared/status';
import {
  isUserAdminOrSERInAlliance,
  isUserCreatorInAlliance,
} from '../../../shared/alliance-utils';
import { initiativesApprovedValidator } from '../initiative/initiative-validators';
import { isAllianceCompleted } from '@cobuildlab/collabtogrow-permissions';

/**
 * Checks if a User can Edit An Investment Item.
 *
 * @param {object} user - The user.
 * @param {object} item - The item.
 * @param {object} alliance - The Alliance.
 *
 * @returns {boolean} - A boolean if we can edit.
 */
export const canEditInvestmentItem = (user, item, alliance) => {
  if (isAllianceCompleted(alliance)) return false;

  if (isUserAdminOrSERInAlliance(user, alliance)) return true;

  if (
    [
      CONTRIBUTION_COMPLETED,
      FUNDING_REQUEST_APPROVED,
      FUNDING_REQUEST_COMPLETED,
      FUNDING_REQUEST_SUBMITTED_FOR_APPROVAL,
    ].includes(item.status)
  )
    return false;

  if (isUserCreatorInAlliance(user, alliance)) return true;

  return false;
};

/**
 * Checks if a User can Delete A Investment Item.
 *
 * @param {object} user - The user.
 * @param {object} item - The item.
 * @param {object} alliance - The Alliance.
 *
 * @returns {[boolean, [string]]} - A boolean and an array of errors.
 */
export const canDeleteInvestmentItem = (user, item, alliance) => {
  const errors = [];
  if (isAllianceCompleted(alliance))
    errors.push('Cannot delete an item if the alliance is completed');

  if (item.status === INVESTMENT_ITEM_APPROVED)
    errors.push('Cannot delete an approved item. Please mark as completed first');

  if (!isUserAdminOrSERInAlliance(user, alliance))
    errors.push('You must be Admin or SER in the alliance to delete');

  return [!errors.length, errors];
};

/**
 * Checks if a User can Create Investment Items on an Alliance Alliance.
 *
 * @param {object} user - The user.
 * @param {object} alliance - The Alliance.
 *
 * @returns {boolean} - A boolean if we can create investment.
 */
export const canCreateInvestmentItem = (user, alliance) => {
  if (isAllianceCompleted(alliance)) return false;

  return isUserAdminOrSERInAlliance(user, alliance) || isUserCreatorInAlliance(user, alliance);
};

/**
 * Checks if a User can Submit For approval a InvestmentItem.
 *
 * @param {object} user - The user.
 * @param {object} investmentItem - InvestimentItem.
 * @param {object} alliance - The Alliance.
 *
 * @returns {boolean} - A boolean if we can submit for approval.
 */
export const canSubmitForApprovalInvestmentItem = (user, investmentItem, alliance) => {
  const errors = [];
  if (isAllianceCompleted(alliance))
    errors.push('Cannot submit for approval a funding request if the alliance is completed');

  if (alliance.status !== ALLIANCE_APPROVED)
    errors.push('Can not submit a funding request if the alliance is not approved');
  if (
    investmentItem.status === INVESTMENT_ITEM_APPROVED ||
    investmentItem.status === INVESTMENT_ITEM_COMPLETED ||
    investmentItem.status === INVESTMENT_ITEM_SUBMITTED_FOR_APPROVAL
  )
    errors.push('The funding request must be ‘IN PROGRESS” to submit for approval');

  try {
    initiativesApprovedValidator(investmentItem.initiatives);
  } catch (e) {
    errors.push(e.message);
  }

  if (!isUserAdminOrSERInAlliance(user, alliance))
    errors.push('You must be an Admin or SER in the alliance');

  return [!errors.length, errors];
};
