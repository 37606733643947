import React from 'react';
import PropTypes from 'prop-types';

import { TotalCell } from '../Components';

const TotalRow = ({ row, currencyFields }) => {
  return (
    <tfoot>
      <tr>
        {row.map((cell, index) => (
          <TotalCell cell={cell} currencyFields={currencyFields} key={index} />
        ))}
      </tr>
    </tfoot>
  );
};

TotalRow.propTypes = {
  row: PropTypes.array.isRequired,
  currencyFields: PropTypes.array.isRequired,
};

export { TotalRow };
