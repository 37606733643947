import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Card, Grid, Heading, Loader, SelectField, Table } from '@8base/boost';
import { useSubscription } from '@cobuildlab/react-simple-state';
import * as toast from '../../../components/toast/Toast';
import {
  createFieldMapping,
  deleteFieldMapping,
  fetchSalesforceFieldMappings,
  fetchSalesforceObjectField,
  updateFieldMappings,
} from '../../salesforce/salesforce-actions';
import { SALESFORCE_DEAL_FIELDS } from '../../management/deal/deal-model';
import { SALESFORCE_FIELD_MAPPING_OBJECTS } from '../../salesforce/salesforce-models';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import YesNoDialog from '../../../components/dialogs/YesNoDialog';
import { ActionButton } from '../../../components/buttons/ActionButton';
import {
  OnSalesforceFieldMappings,
  OnSalesforceFieldMappingsError,
  OnSalesforceObjectFields,
  OnSalesforceObjectFieldsError,
  OnSalesforceFieldMappingDelete,
  OnSalesforceFieldMappingDeleteError,
  OnSalesforceFieldMappingUpdate,
  OnSalesforceFieldMappingUpdateError,
  OnSalesforceFieldMappingCreate,
  OnSalesforceFieldMappingCreateError,
  OnSalesforceMissingsFields,
} from '../../salesforce/salesforce-events';

export const SalesforceFieldMappingSettingsCard = () => {
  const [loadingMappings, setLoadingMappings] = useState(true);
  const [loadingFields, setLoadingFields] = useState(true);
  const [loadingDeletion, setLoadingDeletion] = useState(false);
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const [loadingCreating, setLoadingCreating] = useState(false);

  const [deleteMappingModalOpen, setDeleteMappingModalOpen] = useState(false);

  const [modifiedMappings, setModifiedMappings] = useState([]);

  const [fieldMappings, setFieldMappings] = useState([]);
  const [objectFields, setObjectFields] = useState([]);
  const [mappingToDelete, setMappingToDelete] = useState(null);
  const [newFieldMapping, setNewFieldMapping] = useState({});

  const loading = loadingFields || loadingMappings;

  useSubscription(OnSalesforceFieldMappings, (data) => {
    setLoadingMappings(false);
    setFieldMappings(data);
  });

  useSubscription(OnSalesforceFieldMappingsError, (error) => {
    toast.error(error.message);
    setLoadingMappings(false);
  });

  useSubscription(OnSalesforceObjectFields, (objectFields) => {
    setLoadingFields(false);

    const optionFields = Object.fromEntries(
      Object.entries(objectFields).map(([objectName, fields]) => {
        const optionsFields = fields.map((field) => ({ label: field, value: field }));
        return [objectName, optionsFields];
      }),
    );

    setObjectFields(optionFields);
  });

  useSubscription(OnSalesforceObjectFieldsError, (error) => {
    toast.error(error.message);
    setLoadingFields(false);
  });

  useSubscription(OnSalesforceFieldMappingDelete, () => {
    setLoadingDeletion(false);
  });

  useSubscription(OnSalesforceFieldMappingDeleteError, (error) => {
    toast.error(error.message);
    setLoadingDeletion(false);
  });

  useSubscription(OnSalesforceFieldMappingUpdate, () => {
    setLoadingUpdate(false);
    setLoadingMappings(true);
    setModifiedMappings([]);
    fetchSalesforceFieldMappings();
  });

  useSubscription(OnSalesforceFieldMappingUpdateError, (error) => {
    toast.error(error.message);
    setLoadingUpdate(false);
  });

  useSubscription(OnSalesforceFieldMappingCreate, () => {
    setLoadingCreating(false);
    setNewFieldMapping({});
  });

  useSubscription(OnSalesforceFieldMappingCreateError, (error) => {
    toast.error(error.message);
    setLoadingCreating(false);
  });

  useSubscription(OnSalesforceMissingsFields, (message) => {
    toast.warn(message);
  });

  useEffect(() => {
    fetchSalesforceFieldMappings();
    fetchSalesforceObjectField();
  }, []);

  const dealOptions = useMemo(
    () => SALESFORCE_DEAL_FIELDS.map((option) => ({ label: option.label, value: option.id })),
    [],
  );

  const handleFieldMappingChange = useCallback(
    (fieldMapping, field, value) => {
      const modifiedMappingIndex = modifiedMappings.findIndex(({ id }) => id === fieldMapping.id);

      const hasChanged = fieldMapping[field] !== value;
      const hasBeenModified = modifiedMappingIndex >= 0;

      if (hasChanged && !hasBeenModified) {
        // Create Modified Mapping
        const modifiedMapping = { id: fieldMapping.id, [field]: value };
        modifiedMappings.push(modifiedMapping);
        setModifiedMappings([...modifiedMappings]);
      } else if (hasChanged && hasBeenModified) {
        // Update Modified Mapping
        const modifiedMapping = modifiedMappings[modifiedMappingIndex];
        modifiedMapping[field] = value;
        setModifiedMappings([...modifiedMappings]);
      } else if (!hasChanged && hasBeenModified) {
        // Check others modification and delete accordingly
        const modifiedMapping = modifiedMappings[modifiedMappingIndex];
        modifiedMapping[field] = value;
        delete modifiedMapping[field];

        // Delete modified field mapping if the object only has the id property
        if (Object.keys(modifiedMapping).length === 1) {
          const updateModifiedMappings = modifiedMappings.filter(
            (oldModifiedMappings) => oldModifiedMappings !== modifiedMapping,
          );
          setModifiedMappings(updateModifiedMappings);
        } else {
          setModifiedMappings([...modifiedMappings]);
        }
      }
    },
    [modifiedMappings, setModifiedMappings],
  );

  const handleFieldMappingDelete = useCallback(() => {
    setDeleteMappingModalOpen(false);
    setLoadingDeletion(true);
    deleteFieldMapping(mappingToDelete);
  }, [mappingToDelete, setDeleteMappingModalOpen, setLoadingDeletion]);

  const handleConfirmMappingDeletion = useCallback(
    (fieldMapping) => {
      console.log('handleConfirmMappingDeletion', fieldMapping);
      setMappingToDelete(fieldMapping);
      setDeleteMappingModalOpen(true);
    },
    [setMappingToDelete, setDeleteMappingModalOpen],
  );

  const handleUpdateFieldMappings = useCallback(() => {
    setLoadingUpdate(true);
    updateFieldMappings(modifiedMappings);
  }, []);

  const handleCreateFieldMappingChange = useCallback(
    (field, value) => {
      setNewFieldMapping({
        ...newFieldMapping,
        [field]: value,
      });
    },
    [newFieldMapping, setNewFieldMapping],
  );

  const handleCreateFieldMappings = useCallback(() => {
    setLoadingCreating(true);
    createFieldMapping(newFieldMapping);
  }, [newFieldMapping, setLoadingCreating]);

  return (
    <>
      <Card stretch style={{ overflow: 'initial' }}>
        <Card.Header>
          <Grid.Layout inline columns="1fr auto" style={{ width: '100%' }}>
            <Grid.Box alignSelf="center">
              <Heading type="h4" text="Salesforce Field Mappings" />
            </Grid.Box>
            <Grid.Box justifySelf="end">
              {modifiedMappings.length ? (
                <ActionButton
                  alignSelf="end"
                  text={'Update Field Mappings'}
                  disabled={loadingDeletion}
                  loading={loadingUpdate}
                  onClick={handleUpdateFieldMappings}
                />
              ) : null}
            </Grid.Box>
          </Grid.Layout>
        </Card.Header>
        {loading ? (
          <Card.Body>
            <Loader stretch />
          </Card.Body>
        ) : (
          <div style={{ display: 'flex', height: '600px' }}>
            <Table>
              <Table.Header columns="repeat(3, 1fr) 200px">
                <Table.HeaderCell>Object</Table.HeaderCell>
                <Table.HeaderCell>Deal Field</Table.HeaderCell>
                <Table.HeaderCell>Salesforce Field</Table.HeaderCell>
                <Table.HeaderCell />
              </Table.Header>

              <Table.Body data={fieldMappings}>
                {(fieldMapping) => {
                  const { id, dealField, salesforceField, object } = fieldMapping;
                  const modifiedMapping =
                    modifiedMappings.find(({ id }) => id === fieldMapping.id) || {};

                  const currentObject =
                    modifiedMapping.object !== undefined ? modifiedMapping.object : object;

                  return (
                    <Table.BodyRow columns="repeat(3, 1fr) 200px" key={id}>
                      <Table.BodyCell>
                        <SelectField
                          input={{
                            value: currentObject,
                            onChange: (value) =>
                              handleFieldMappingChange(fieldMapping, 'object', value),
                          }}
                          placeholder="Salesforce Object"
                          options={SALESFORCE_FIELD_MAPPING_OBJECTS}
                        />
                      </Table.BodyCell>
                      <Table.BodyCell>
                        <SelectField
                          input={{
                            name: 'dealField',
                            value:
                              modifiedMapping.dealField !== undefined
                                ? modifiedMapping.dealField
                                : dealField,
                            onChange: (value) =>
                              handleFieldMappingChange(fieldMapping, 'dealField', value),
                          }}
                          placeholder="Deal Field"
                          options={dealOptions}
                        />
                      </Table.BodyCell>
                      <Table.BodyCell>
                        <SelectField
                          input={{
                            name: 'salesforceField',
                            value:
                              modifiedMapping.salesforceField !== undefined
                                ? modifiedMapping.salesforceField
                                : salesforceField,
                            onChange: (value) =>
                              handleFieldMappingChange(fieldMapping, 'salesforceField', value),
                          }}
                          placeholder="Salesforce Field"
                          options={objectFields[currentObject] || []}
                        />
                      </Table.BodyCell>
                      <Table.BodyCell>
                        <Button
                          color="neutral"
                          squared
                          onClick={() => handleConfirmMappingDeletion(fieldMapping)}
                          loading={loadingDeletion && fieldMapping === mappingToDelete}
                          disabled={
                            (loadingDeletion && fieldMapping !== mappingToDelete) || loadingUpdate
                          }>
                          <FontAwesomeIcon icon={'times'} />
                        </Button>
                      </Table.BodyCell>
                    </Table.BodyRow>
                  );
                }}
              </Table.Body>
              <Table.Footer>
                <Table.BodyRow columns="repeat(3, 1fr) 200px">
                  <Table.BodyCell>
                    <SelectField
                      input={{
                        value: newFieldMapping.object,
                        onChange: (value) => handleCreateFieldMappingChange('object', value),
                      }}
                      placeholder="Salesforce Object"
                      options={SALESFORCE_FIELD_MAPPING_OBJECTS}
                    />
                  </Table.BodyCell>
                  <Table.BodyCell>
                    <SelectField
                      input={{
                        name: 'dealField',
                        value: newFieldMapping.dealField,
                        onChange: (value) => handleCreateFieldMappingChange('dealField', value),
                      }}
                      placeholder="Deal Field"
                      options={dealOptions}
                    />
                  </Table.BodyCell>
                  <Table.BodyCell>
                    <SelectField
                      disabled={!(newFieldMapping && newFieldMapping.object)}
                      input={{
                        name: 'salesforceField',
                        value: newFieldMapping.salesforceField,
                        onChange: (value) =>
                          handleCreateFieldMappingChange('salesforceField', value),
                      }}
                      placeholder="Salesforce Field"
                      options={objectFields[newFieldMapping.object] || []}
                    />
                  </Table.BodyCell>
                  <Table.BodyCell>
                    <ActionButton
                      alignSelf="end"
                      text={'Create'}
                      loading={loadingCreating}
                      onClick={handleCreateFieldMappings}
                    />
                  </Table.BodyCell>
                </Table.BodyRow>
              </Table.Footer>
            </Table>
          </div>
        )}
      </Card>

      <YesNoDialog
        title={'Delete Field Mapping'}
        onYes={handleFieldMappingDelete}
        onNo={() => setDeleteMappingModalOpen(false)}
        text={'Are you sure you want to delete this salesforce field mapping?'}
        isOpen={deleteMappingModalOpen}
      />
    </>
  );
};
