import { Bar, BarChart, Tooltip, XAxis, YAxis } from 'recharts';
import React from 'react';
import PropTypes from 'prop-types';
import { Text } from '@8base/boost';
import styled from '@emotion/styled';
import { createChartData, getChartKeys } from '../balanced-scorecard-actions';
import { numberFormatter } from '../../utils';
import BigInt from 'big-integer';
import { KPIChartToolTip } from './';
import { getStartEndAndTotalMonths } from '../balanced-scorecard-utils';
import { getAllowedDecimalsForKPI } from '../../../../shared/utils';

const StyleTitle = styled(Text)`
  opacity: 0.9;
  color: #323c47;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  margin: 20px 0px 20px 10px !important;
`;

const KPICharts = ({ selectedKPI, currency, monthsRange }) => {
  const { name, monthByMonthCurrent, type } = selectedKPI;
  let { monthByMonthCumulativeTarget: monthByMonthAccumulator } = selectedKPI;
  const { totalMonths } = getStartEndAndTotalMonths(monthsRange);
  const lastMonthIndex = totalMonths - 1;
  monthByMonthAccumulator = monthByMonthAccumulator
    ? monthByMonthAccumulator
    : new Array(totalMonths).fill(0);

  const data = createChartData(monthByMonthCurrent, monthByMonthAccumulator, type, monthsRange);
  const { dataKey, dataKeyAccumulator } = getChartKeys(type);

  let useDataKey;

  if (getAllowedDecimalsForKPI(type)) {
    useDataKey = data[lastMonthIndex][dataKey] > data[lastMonthIndex][dataKeyAccumulator];
  } else {
    useDataKey = BigInt(data[lastMonthIndex][dataKey]).greater(
      data[lastMonthIndex][dataKeyAccumulator],
    );
  }

  return (
    <>
      <StyleTitle>{name}</StyleTitle>

      <BarChart width={525} height={350} data={data}>
        <XAxis dataKey="name" stroke="#8884d8" />
        <YAxis
          domain={[
            0,
            () =>
              useDataKey ? data[lastMonthIndex][dataKey] : data[lastMonthIndex][dataKeyAccumulator],
          ]}
          allowDecimals={!!getAllowedDecimalsForKPI(type)}
          tickFormatter={(value) =>
            currency ? `${currency.symbol} ${numberFormatter(value, 1)}` : value
          }
        />
        <Tooltip content={<KPIChartToolTip currency={currency} />} />

        <Bar type="monotone" dataKey={dataKey} fill="#3EB7F9" barSize={30} />
        <Bar type="monotone" dataKey={dataKeyAccumulator} fill="#70D34C" barSize={30} />
      </BarChart>
    </>
  );
};

KPICharts.propTypes = {
  monthsRange: PropTypes.array.isRequired,
  selectedKPI: PropTypes.object.isRequired,
  currency: PropTypes.object.isRequired,
};

export { KPICharts };
