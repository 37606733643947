import React from 'react';
import { Card, Grid, Heading } from '@8base/boost';
import { CreateViewCardBody } from '../../../components/new-ui/card/CreateViewCardBody';
import AllianceForm from './components/AllianceForm';
import AllianceKPIsForm from './allianceKPIs/components/AllianceKPIsForm';
import * as toast from '../../../components/toast/Toast';
import { fetchAllianceFormData, autoSaveAlliance } from './alliance-actions';
import { fetchSession } from '../../auth/auth.actions';
import { Loader } from '@8base/boost';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import AllianceModel, {
  ALLIANCE_ORGANIZATIONAL_CHART_FILE,
  ALLIANCE_POLICY_GUIDELINES_FILE,
  ALLIANCE_DOCUMENTS_FILE,
} from './Alliance.model';
import { View } from '@cobuildlab/react-simple-state';
import { onErrorMixin } from '../../../shared/mixins';
import * as R from 'ramda';
import BusinessCaseForm from '../../document-management/business-case/components/BusinessCaseForm';
import BusinessCaseModel from '../../document-management/business-case/BusinessCase.model';
import AllianceDetailTable from './components/AllianceDetailTable';
import SubHeader from '../../../components/SubHeader';
import BusinessCaseDetailTable from '../../document-management/business-case/components/BusinessCaseDetailTable';
import { ActionButton } from '../../../components/buttons/ActionButton';
import { TransparentButton } from '../../../components/buttons/TransparentButton';
import { FormSteps } from '../../../components/dots/FormSteps';
import { ActionButtonClose } from '../../../components/buttons/ActionButtonClose';
import { createAllianceValidator } from './alliance-validators';
import { businessCaseValidator } from '../../document-management/business-case/business-case-validators';
import { getCompanyUserAdminAndPortfolio } from '../company-management/company-actions';
import moment from 'moment';
import {
  checkActiveYearsAndSetStateMixin,
  getBusinessCaseAnticipatedCosts,
  popBusinessCaseKPIYear,
  pushBusinessCaseKPIYear,
} from './allianceKPIs/allianceKPIs-actions';
import { allianceKPIsValidator } from './allianceKPIs/allianceKPIs-validators';
import { LeftProgressSection } from '../../../components/new-ui/LeftProgressSection';
import { SCREENS_ALLIANCE } from '../../management/screenView';
import { BoxCard } from '../../../components/new-ui/div/BoxCard';
import { CardFooter } from '../../../components/new-ui/card/CardFooter';
import { TextDateAgo } from '../../../components/text/TextDateAgo';
import {
  OnAllianceError,
  OnAllianceFormData,
  OnAllianceCreate,
  OnAllianceAutoSave,
  OnAllianceAutoSaveError,
} from './alliance-events';

// Create Alliance
class AllianceCreateView extends View {
  constructor(props) {
    super(props);
    this.state = {
      allianceData: R.clone(AllianceModel),
      businessCaseData: R.clone(BusinessCaseModel),
      selectedKPIYear: moment().year(),
      kpiYears: [moment().year()],
      loading: true,
      currencies: [],
      step: 0,
      savedAt: null,
      autoSaving: false,
    };
    this.companyUsers = getCompanyUserAdminAndPortfolio();
    this.onError = onErrorMixin.bind(this);
    this.checkActiveYearsAndSetStateMixin = checkActiveYearsAndSetStateMixin.bind(this);

    this.savedAlliance = null;
    this.autoSaveTimer = null;
  }

  autoSave = () => {
    if (this.state.autoSaving) return;
    const waitTime = 10000; // 10s
    clearTimeout(this.autoSaveTimer);
    this.autoSaveTimer = setTimeout(() => {
      try {
        allianceKPIsValidator(this.state.allianceData);
      } catch (e) {
        console.warn(e);
        return;
      }
      this.setState({ autoSaving: true }, () => {
        const allianceData = R.clone(this.state.allianceData);
        const businessCaseData = R.clone(this.state.businessCaseData);
        autoSaveAlliance(allianceData, businessCaseData, this.savedAlliance, false);
      });
    }, waitTime);
  };

  onChangeAllianceData = (name, value) => {
    const { allianceData, businessCaseData } = this.state;
    allianceData[name] = value;
    this.checkActiveYearsAndSetStateMixin(allianceData, businessCaseData);
    this.autoSave();
  };

  onChangeBusinessCaseData = (name, value) => {
    const { businessCaseData, allianceData } = this.state;
    businessCaseData[name] = value;
    this.checkActiveYearsAndSetStateMixin(allianceData, businessCaseData);
    this.autoSave();
  };

  onChangeKPIsData = (name, value) => {
    const allianceData = { ...this.state.allianceData };
    const businessCaseData = { ...this.state.businessCaseData };
    allianceData[name] = value;
    // this updates the alliance, kpiYears and selectedKPIYears on the state
    this.checkActiveYearsAndSetStateMixin(allianceData, businessCaseData);
    const { expectedRevenues, anticipatedCosts } = getBusinessCaseAnticipatedCosts(
      allianceData,
      businessCaseData,
    );
    businessCaseData.expectedRevenues = expectedRevenues;
    businessCaseData.anticipatedCosts = anticipatedCosts;
    this.setState({ businessCaseData });
    this.autoSave();
  };

  componentDidMount() {
    this.subscribe(OnAllianceError, this.onError);
    this.subscribe(OnAllianceFormData, (state) => {
      const currencies = state.currenciesList.items.map((currency) => ({
        ...currency,
      }));

      this.setState({
        loading: false,
        currencies,
      });
    });
    this.subscribe(OnAllianceCreate, async (state) => {
      await fetchSession();
      toast.success('Alliance Successfully Created');
      this.props.history.goBack();
    });

    this.subscribe(OnAllianceAutoSave, (alliance) => {
      // If the alliance was created, we need to update session info
      if (!this.savedAlliance) {
        fetchSession();
      }
      this.setState({ savedAt: new Date(), autoSaving: false });
      this.savedAlliance = R.clone(alliance.allianceCreate) || R.clone(alliance.allianceUpdate);
    });

    this.subscribe(OnAllianceAutoSaveError, (error) => {
      this.setState({ autoSaving: false });
      console.log('autoSaveError', error);
    });

    if (this.companyUsers.length) {
      const { allianceData } = this.state;
      allianceData.clientCompany = this.companyUsers[0].company;
    }

    fetchAllianceFormData();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
    clearTimeout(this.autoSaveTimer);
    localStorage.removeItem(ALLIANCE_POLICY_GUIDELINES_FILE);
    localStorage.removeItem(ALLIANCE_ORGANIZATIONAL_CHART_FILE);
    localStorage.removeItem(ALLIANCE_DOCUMENTS_FILE);
  }

  onKPIYearChange = (selectedKPIYear) => {
    this.setState({ selectedKPIYear });
  };

  onYearsQuantityChange = (quantity) => {
    const businessCaseData = { ...this.state.businessCaseData };
    const allianceData = { ...this.state.allianceData };
    if (quantity > 0) {
      pushBusinessCaseKPIYear(businessCaseData);
      this.checkActiveYearsAndSetStateMixin(allianceData, businessCaseData);
    } else {
      const kpiYears = [...this.state.kpiYears];
      // Check that there are more than 1 year
      if (kpiYears.length <= 1) return;
      // If we are currently on the last year, change it to the previous one
      if (this.state.selectedKPIYear === kpiYears[kpiYears.length - 1]) {
        const selectedKPIYear = kpiYears[kpiYears.length - 2];
        this.setState({ selectedKPIYear }, () => {
          popBusinessCaseKPIYear(businessCaseData);
          this.checkActiveYearsAndSetStateMixin(allianceData, businessCaseData);
        });
      } else {
        popBusinessCaseKPIYear(businessCaseData);
        this.checkActiveYearsAndSetStateMixin(allianceData, businessCaseData);
      }
    }
  };

  onSubmit = () => {
    this.setState({ loading: true }, () => {
      clearTimeout(this.autoSaveTimer);
      const allianceData = R.clone(this.state.allianceData);
      const businessCaseData = R.clone(this.state.businessCaseData);
      autoSaveAlliance(allianceData, businessCaseData, this.savedAlliance, true);
    });
  };

  onAllianceStepChange = (step) => {
    const allianceData = R.clone(this.state.allianceData);
    try {
      createAllianceValidator(allianceData);
    } catch (e) {
      return this.onError(e);
    }
    this.onScreen(step);
  };

  onBusinessCaseStepChange = (step) => {
    const businessCaseData = R.clone(this.state.businessCaseData);
    try {
      businessCaseValidator(businessCaseData);
    } catch (e) {
      return this.onError(e);
    }
    this.onScreen(step);
  };

  onKPIsStepChange = (step) => {
    const alliance = { ...this.state.allianceData };
    try {
      allianceKPIsValidator(alliance);
    } catch (e) {
      return this.onError(e);
    }
    this.onScreen(step);
  };

  onScreen = (step) => {
    this.setState({ step });
  };

  render() {
    const {
      step,
      loading,
      currencies,
      allianceData,
      businessCaseData,
      selectedKPIYear,
      kpiYears,
      savedAt,
      autoSaving,
    } = this.state;
    const { currency } = allianceData;
    const { history } = this.props;

    let content = <Loader stretch />;
    let footer = <></>;

    if (!loading && step === 0) {
      content = (
        <div id={'c2gScreen'}>
          <AllianceForm
            data={allianceData}
            onChange={this.onChangeAllianceData}
            onChangeCompany={this.onChangeCompany}
            currencies={currencies}
            companyUsers={this.companyUsers}
          />
        </div>
      );
      footer = (
        <CardFooter>
          <ActionButton onClick={() => this.onAllianceStepChange(1)} text="Next" />
        </CardFooter>
      );
    }

    if (!loading && step === 1) {
      content = (
        <div id={'c2gScreen'}>
          <BusinessCaseForm
            hideExpectCostAvoidance
            data={businessCaseData}
            onChange={this.onChangeBusinessCaseData}
            currency={currency}
          />
        </div>
      );
      footer = (
        <CardFooter>
          <ActionButton onClick={() => this.onBusinessCaseStepChange(2)} text="Next" />
          <TransparentButton onClick={() => this.onScreen(0)} text={'Previous'} />
        </CardFooter>
      );
    }

    if (!loading && step === 2) {
      // headerText = 'Key Performance Indicator';
      content = (
        <div id={'c2gScreen'}>
          <AllianceKPIsForm
            alliance={allianceData}
            onChange={this.onChangeKPIsData}
            onYearChange={this.onKPIYearChange}
            onYearsChange={this.onYearsQuantityChange}
            selectedYear={selectedKPIYear}
            kpiYears={kpiYears}
          />
        </div>
      );
      footer = (
        <CardFooter>
          <ActionButton onClick={() => this.onKPIsStepChange(3)} text="Next" />
          <TransparentButton onClick={() => this.onScreen(1)} text={'Previous'} />
        </CardFooter>
      );
    }

    if (!loading && step === 3) {
      const allianceDetail = R.clone(allianceData);
      allianceDetail.allianceKPIAllianceRelation = {
        items: allianceDetail.allianceKPIs,
      };

      content = (
        <div id={'c2gScreen'}>
          <AllianceDetailTable data={allianceDetail} />
          <SubHeader text="Business Case" status={allianceDetail.status} />
          <BusinessCaseDetailTable hideExpectedCostAvoidance data={businessCaseData} />
        </div>
      );
      footer = (
        <CardFooter>
          <ActionButton disabled={autoSaving} onClick={this.onSubmit} text="Create Alliance" />
          <TransparentButton onClick={() => this.onScreen(2)} text={'Previous'} />
        </CardFooter>
      );
    }

    return (
      <React.Fragment>
        <Card.Header>
          <Grid.Layout
            columns="200px auto 200px"
            areas={[['left', 'center', 'right']]}
            style={{ width: '100%' }}>
            <Grid.Box area="left">
              <Heading type="h4" text="Create Alliance" />
              <TextDateAgo from={savedAt} />
            </Grid.Box>
            <Grid.Box area="center">
              <FormSteps totalSteps={4} step={step} />
            </Grid.Box>
            <Grid.Box area="right" />
          </Grid.Layout>
          <ActionButtonClose onClick={history.goBack} />
        </Card.Header>
        <CreateViewCardBody>
          <Grid.Layout columns="30% 70%" areas={[['left', 'right']]} style={{ width: '100%' }}>
            <Grid.Box area="left">
              <LeftProgressSection sections={SCREENS_ALLIANCE} currentScreen={step} />
            </Grid.Box>
            <BoxCard>
              <Grid.Box area="right">{content}</Grid.Box>
            </BoxCard>
          </Grid.Layout>
        </CreateViewCardBody>
        {footer}
      </React.Fragment>
    );
  }
}

AllianceCreateView.propTypes = {
  history: PropTypes.object.isRequired,
};

export default withRouter(AllianceCreateView);
