import { TextAreaField } from '@8base/boost';
import React from 'react';
import PropTypes from 'prop-types';

const TextArea = ({ maxlength = 500, input, ...rest }) => {
  const { onChange } = input;
  const { label } = rest;

  return (
    <TextAreaField
      {...rest}
      label={`${label} (${maxlength})`}
      input={{
        ...input,
        onChange: (value, e) => {
          if (value.length <= maxlength) onChange(value, e);
          else onChange(String(value).substring(0, maxlength - 1), e);
        },
      }}
    />
  );
};

TextArea.propTypes = {
  maxlength: PropTypes.number,
  input: PropTypes.object.isRequired,
};

TextArea.defaultProps = {
  maxlength: 500,
};

export { TextArea };
