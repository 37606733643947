import {
  BLUE_COLOR,
  GRAY_COLOR,
  GREEN_COLOR,
  LIGHT_BLUE_COLOR,
  LIGHT_GREEN_COLOR,
  RED_COLOR,
  YELLOW_COLOR,
} from '../../shared/colors';

export const PIE_OPTIONS = {
  series: [
    {
      type: 'pie',
      radius: '75%',
      center: ['50%', '50%'],
      selectedMode: 'single',
      data: [],
      itemStyle: {
        emphasis: {
          shadowBlur: 10,
          shadowOffsetX: 0,
          shadowColor: 'rgba(0, 0, 0, 0.5)',
        },
      },
    },
  ],
};

export const PIE_DATE_ITEM = {
  value: 1548,
  name: '65%',
  label: {
    fontSize: 12,
    fontFamily: 'Poppins',
  },
  itemStyle: {
    color: '#70D44B',
    borderWidth: 4,
    borderColor: '#fff',
  },
};

export const FUNNEL_COLORS = [
  GRAY_COLOR,
  RED_COLOR,
  YELLOW_COLOR,
  GREEN_COLOR,
  LIGHT_BLUE_COLOR,
  BLUE_COLOR,
  LIGHT_GREEN_COLOR,
];

export const FUNNEL_OPTIONS = {
  tooltip: {
    trigger: 'item',
    formatter: '{b} : {c}',
  },
  toolbox: {
    feature: {
      dataView: { readOnly: false },
      restore: {},
      saveAsImage: {},
    },
  },
  legend: {
    data: [],
  },
  calculable: true,
  series: [
    {
      name: '',
      type: 'funnel',
      left: '10%',
      top: 60,
      //x2: 80,
      bottom: 60,
      width: '80%',
      // height: {totalHeight} - y - y2,
      min: 0,
      max: 100,
      minSize: '0%',
      maxSize: '100%',
      sort: 'descending',
      gap: 2,
      label: {
        show: true,
        position: 'right',
      },
      labelLine: {
        length: 10,
        lineStyle: {
          width: 1,
          type: 'solid',
        },
      },
      itemStyle: {
        borderColor: '#fff',
        borderWidth: 1,
      },
      emphasis: {
        label: {
          fontSize: 20,
        },
      },
      data: [],
    },
  ],
};
