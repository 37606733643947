import sessionStore, { APOLLO_CLIENT, NEW_SESSION_EVENT } from '../../shared/SessionStore';
import { DELETE_COMPANY_INVITATION_MUTATION } from '../settings/invitations/invitations.queries';
import {
  COMPANY_INVITATIONS_QUERY,
  EDIT_COMPANY_MUTATION,
} from '../settings/company-management/Company.queries';
import { IntegrityError } from '../../shared/errors';
import {
  createCompanySubscriptionValidator,
  inviteCompanyPortfolioOwnerValidator,
  paymentValidator,
} from './procurer-validators';
import { postData } from '../../shared/fetch';
import { trimEmail } from '../../shared/utils';
import {
  COMPANY_PORTFOLIO_OWNER_INVITATIONS_EVENT,
  COMPANY_SUBSCRIPTION_CREATE_EVENT,
  INVITE_COMPANY_PORTFOLIO_OWNER_EVENT,
  PROCURER_CANCEL_SUBSCRIPTION,
  PROCURER_CHANGE_SUBSCRIPTION_PLAN,
  PROCURER_CHANGE_SUBSCRIPTION_PLAN_ERROR,
  PROCURER_ERROR_EVENT,
  PROCURER_REACTIVATE_SUBSCRIPTION,
  SUBSCRIPTION_INVOICE_RETRY_ERROR_EVENT,
  SUBSCRIPTION_INVOICE_RETRY_EVENT,
  SUBSCRIPTION_INVOICE_RETRY_LOADING_EVENT,
  UPDATE_PAYMENT_EVENT,
} from './procurer-store';
import {
  canCancelSubscription,
  canChangeCompanySubscriptionPaymentMethod,
  canChangeCompanySubscriptionPlan,
  canCreateCompanySubscription,
} from './procurer-permissions';
import Flux from '@cobuildlab/flux-state';
import { COMPANY_PORTFOLIO_OWNER } from '../../shared/roles';
import { STRIPE_PAYMENT_DATA_QUERY } from './procurer-queries';
import {
  OnProcurerError,
  OnProcurerChangeSubscriptionPlanError,
  OnProcurerChangeSubscriptionPlan,
  OnInviteCompanyPortfolioOwner,
  OnCompanyPortfolioOwnerInvitations,
  OnUpdatePayment,
  OnProcurerCancelSubscription,
  OnProcurerReactiveSubscription,
  OnSubscriptionInvoiceRetry,
  OnSubscriptionInvoiceRetryError,
  OnSubscriptionInvoiceRetryLoading,
  OnCompanySubscriptionCreate,
} from './procurer-events';

/**
 * Update company subscription credit card.
 *
 * @param  {object} companySubscription - The subscription to cancel.
 * @param  {object} creditCard - Credit card data.
 * @param  {object} paymentMethod - Credit card data.
 * @param  {object} stripeError - Credit card data.
 * @param  {object} company - The compeny to check permissions.
 * @returns {Promise} The updated CompanySubscription.
 */
export const updatePayment = async (
  companySubscription,
  creditCard,
  paymentMethod,
  stripeError,
  company,
) => {
  const { user } = sessionStore.getState(NEW_SESSION_EVENT);

  if (!canChangeCompanySubscriptionPaymentMethod(user, company)) {
    OnProcurerError.dispatch(
      new IntegrityError('You do not have permission to change the Subscription Payment Method'),
    );

    return Flux.dispatchEvent(
      PROCURER_ERROR_EVENT,
      new IntegrityError('You do not have permission to change the Subscription Payment Method'),
    );
  }

  try {
    paymentValidator(creditCard, paymentMethod, stripeError);
  } catch (err) {
    console.error('updatePayment Validator', err);
    OnProcurerError.dispatch(err);
    return Flux.dispatchEvent(PROCURER_ERROR_EVENT, err);
  }

  const { stripeSubscriptionId } = companySubscription;
  const { id: stripePaymentMethodId } = paymentMethod;
  const postal_code = paymentMethod.billing_details.address.postal_code;

  const url = `${stripeSubscriptionId}/${stripePaymentMethodId}/changePaymentMethodWebhook`;

  let address = null;
  if (creditCard) {
    const { addressLine1: line1, addressLine2: line2, city, country, state } = creditCard;
    address = { line1, line2, city, country, state, postal_code };
  }

  let response;
  try {
    response = await postData(url, { address });
  } catch (e) {
    console.error('updatePayment', e);
    OnProcurerError.dispatch(e);
    return Flux.dispatchEvent(PROCURER_ERROR_EVENT, e);
  }

  console.log('updatePayment', response);
  OnUpdatePayment.dispatch(response);
  return Flux.dispatchEvent(UPDATE_PAYMENT_EVENT, response);
};

/**
 * Reactivates a companySubscription.
 *
 * @param  {object} companySubscription - The subscription to reactivate.
 * @param  {object} company - The company.
 * @returns {Promise} The response parsed to json.
 */
export const reactivateSubscription = async (companySubscription, company) => {
  const { user } = sessionStore.getState(NEW_SESSION_EVENT);
  const { stripeSubscriptionId } = companySubscription;
  const url = `${stripeSubscriptionId}/reactivateSubscriptionWebhook`;

  console.log('reactivateSubscription companySubscription: ', companySubscription);

  if (!canCancelSubscription(user, company)) {
    OnProcurerError.dispatch(
      new IntegrityError('You do not have permission to reactivate the Subscription'),
    );

    return Flux.dispatchEvent(
      PROCURER_ERROR_EVENT,
      new IntegrityError('You do not have permission to reactivate the Subscription'),
    );
  }

  let response;
  try {
    response = await postData(url);
  } catch (e) {
    console.log('reactivateSubscription Error:', e);
    OnProcurerError.dispatch(e);
    return Flux.dispatchEvent(PROCURER_ERROR_EVENT, e);
  }

  console.log('reactivateSubscription Response: ', response);
  OnProcurerReactiveSubscription.dispatch(response);
  Flux.dispatchEvent(PROCURER_REACTIVATE_SUBSCRIPTION, response);
  return response;
};

/**
 * Cancels a companySubscription.
 *
 * @param  {object} companySubscription - The subscription to cancel.
 * @param  {object} company - The company.
 * @returns {Promise} The response parsed to json.
 */
export const cancelSubscription = async (companySubscription, company) => {
  const { user } = sessionStore.getState(NEW_SESSION_EVENT);
  const { stripeSubscriptionId } = companySubscription;
  const url = `${stripeSubscriptionId}/cancelSubscriptionWebhook`;

  console.log('cancelSubscription companySubscription: ', companySubscription);

  if (!canCancelSubscription(user, company)) {
    OnProcurerError.dispatch(
      new IntegrityError('You do not have permission to cancel the Subscription'),
    );

    return Flux.dispatchEvent(
      PROCURER_ERROR_EVENT,
      new IntegrityError('You do not have permission to cancel the Subscription'),
    );
  }

  let response;
  try {
    response = await postData(url);
  } catch (e) {
    console.log('cancelSubscription Error:', e);
    OnProcurerError.dispatch(e);
    return Flux.dispatchEvent(PROCURER_ERROR_EVENT, e);
  }

  console.log('cancelSubscription Response: ', response);
  OnProcurerCancelSubscription.dispatch(response);
  Flux.dispatchEvent(PROCURER_CANCEL_SUBSCRIPTION, response);
  return response;
};

/**
 * Changes a companySubscription plan.
 *
 * @param  {object} subscriptionPlan - The new stripe plan.
 * @param  {boolean} isAnnualPlan - If the plan is annual.
 * @param  {object} companySubscription - The subscription to cancel.
 * @param  {object} company - The company.
 * @returns {Promise} The response parsed to json.
 */
export const changeCompanySubscriptionPlan = async (
  subscriptionPlan,
  isAnnualPlan,
  companySubscription,
  company,
) => {
  const { user } = sessionStore.getState(NEW_SESSION_EVENT);
  const { stripeSubscriptionId } = companySubscription;
  const { monthlyStripePlanId, annualStripePlanId } = subscriptionPlan;
  const stripePlanId = isAnnualPlan ? annualStripePlanId : monthlyStripePlanId;
  const url = `${stripeSubscriptionId}/${stripePlanId}/changeSubscriptionPlanWebhook`;

  console.log(
    'changeCompanySubscriptionPlan: ',
    companySubscription,
    isAnnualPlan,
    subscriptionPlan,
  );

  if (!canChangeCompanySubscriptionPlan(user, company, subscriptionPlan)) {
    OnProcurerError.dispatch(
      new IntegrityError('You do not have permission to change the Subscription Plan'),
    );

    return Flux.dispatchEvent(
      PROCURER_ERROR_EVENT,
      new IntegrityError('You do not have permission to change the Subscription Plan'),
    );
  }

  let response;
  try {
    response = await postData(url);
  } catch (e) {
    console.log('changeCompanySubscriptionPlan Error:', e);
    OnProcurerChangeSubscriptionPlanError.dispatch(e);
    return Flux.dispatchEvent(PROCURER_CHANGE_SUBSCRIPTION_PLAN_ERROR, e);
  }

  console.log('changeCompanySubscriptionPlan Response: ', response);
  OnProcurerChangeSubscriptionPlan.dispatch(response);
  Flux.dispatchEvent(PROCURER_CHANGE_SUBSCRIPTION_PLAN, response);
  return response;
};

/**
 * Creates a companySubscription.
 *
 * @param  {object} companySubscriptionData - The companySubscription data.
 * @param  {boolean} isAnnualPlan - If the plan is annual.
 * @param  {object} company - The company.
 * @param  {object} companySubscription - The old company subscription.
 * @param  {object} stripePaymentMethod - The stripe paymentMethod.
 * @param  {object} stripeError - Credit card data.
 * @returns {Promise} The response parsed to json.
 */
export const createCompanySubscription = async (
  companySubscriptionData,
  isAnnualPlan,
  company,
  companySubscription,
  stripePaymentMethod,
  stripeError,
) => {
  const { user } = sessionStore.getState(NEW_SESSION_EVENT);

  console.log('createCompanySubscription companySubscriptionData: ', companySubscriptionData);

  if (!canCreateCompanySubscription(user, company, companySubscription)) {
    OnProcurerError.dispatch(
      new IntegrityError('You do not have permission to create the Subscription'),
    );

    return Flux.dispatchEvent(
      PROCURER_ERROR_EVENT,
      new IntegrityError('You do not have permission to create the Subscription'),
    );
  }

  try {
    createCompanySubscriptionValidator(
      companySubscriptionData,
      stripePaymentMethod,
      stripeError,
      isAnnualPlan,
    );
  } catch (err) {
    console.error('createCompanySubscription updatePayment Validator', err);
    OnProcurerError.dispatch(err);
    return Flux.dispatchEvent(PROCURER_ERROR_EVENT, err);
  }

  const { id: companyId } = company;
  const { id: stripePaymentMethodId } = stripePaymentMethod || {};
  const postal_code =
    stripePaymentMethod && stripePaymentMethod.billing_details.address.postal_code;
  const { monthlyStripePlanId, annualStripePlanId } = companySubscriptionData.subscriptionPlan;
  const stripePlanId = isAnnualPlan ? annualStripePlanId : monthlyStripePlanId;
  const url = `${companyId}/${stripePlanId}/${stripePaymentMethodId ||
    'unassigned'}/createSubscriptionWebhook`;

  let address = null;
  const { creditCard } = companySubscriptionData;
  if (creditCard) {
    const { addressLine1: line1, addressLine2: line2, city, country, state } = creditCard;
    address = { line1, line2, city, country, state, postal_code };
  }

  let response;
  try {
    response = await postData(url, { address });
  } catch (e) {
    console.log('createCompanySubscription Error:', e);
    OnProcurerError.dispatch(e);
    return Flux.dispatchEvent(PROCURER_ERROR_EVENT, e);
  }

  console.log('createCompanySubscription Response: ', response);
  OnCompanySubscriptionCreate.dispatch(response);
  Flux.dispatchEvent(COMPANY_SUBSCRIPTION_CREATE_EVENT, response);
  return response;
};

/**
 * Fetch company invitations by role.
 *
 * @param {string} companyId - Company Id.
 * @param {string} roleName - Role Id to filter.
 * @returns {Promise} Promise.
 */
export const fetchCompanyInvitationsByRole = async (companyId, roleName) => {
  const client = sessionStore.getState(APOLLO_CLIENT);
  console.log('fetchCompanyInvitationsByRole companyId: ', companyId);
  console.log('fetchCompanyInvitationsByRole roleName: ', roleName);

  if (!companyId) throw new IntegrityError('Invalid Company');

  const filter = {
    company: {
      id: {
        equals: companyId,
      },
    },
  };

  if (roleName) {
    filter.role = {
      name: {
        equals: roleName,
      },
    };
  }

  let response;
  try {
    response = await client.mutate({
      mutation: COMPANY_INVITATIONS_QUERY,
      variables: { filter },
    });
  } catch (e) {
    console.error('fetchCompanyInvitationsByRole', e);
    throw e;
  }

  console.log('fetchCompanyInvitationsByRole response: ', response);
  return response.data;
};

/**
 * Fetch portfolio owner company invitations by.
 *
 * @param {string} companyId - Company Id.
 * @returns {Promise} Promise.
 */
export const fetchPortfolioOwnersCompanyInvitations = async (companyId) => {
  console.log('fetchPortfolioOwnersCompanyInvitations companyId: ', companyId);

  if (!companyId) throw new IntegrityError('Invalid Company');

  let response;
  try {
    response = await fetchCompanyInvitationsByRole(companyId, COMPANY_PORTFOLIO_OWNER);
  } catch (e) {
    console.error('fetchPortfolioOwnersCompanyInvitations', e);
    OnProcurerError.dispatch(e);
    return Flux.dispatchEvent(PROCURER_ERROR_EVENT, e);
  }

  console.log('fetchPortfolioOwnersCompanyInvitations response: ', response);
  OnCompanyPortfolioOwnerInvitations.dispatch(response);
  Flux.dispatchEvent(COMPANY_PORTFOLIO_OWNER_INVITATIONS_EVENT, response);
  return response;
};

/**
 * Invite company portfolio owner & remove previous portfolio owner invitations.
 *
 * @param  {string} companyId - The company id.
 * @param  {Array} email - Email to invite the portfolio owner.
 * @returns {Promise} The invited admins.
 */
export const inviteCompanyPortfolioOwner = async (companyId, email) => {
  const client = sessionStore.getState(APOLLO_CLIENT);
  email = trimEmail(email);

  try {
    inviteCompanyPortfolioOwnerValidator(companyId, email);
  } catch (e) {
    OnProcurerError.dispatch(e);
    return Flux.dispatchEvent(PROCURER_ERROR_EVENT, e);
  }

  const {
    companyInvitationsList: { items: portfolioOwnerInvitations },
  } = await fetchCompanyInvitationsByRole(companyId, COMPANY_PORTFOLIO_OWNER);

  const deleteInvitationPromise = async ({ id }) => {
    let _response;
    try {
      _response = await client.mutate({
        mutation: DELETE_COMPANY_INVITATION_MUTATION,
        variables: { data: { id } },
      });
      console.log('deleteInvitationPromise', _response);
    } catch (e) {
      console.error('deleteInvitationPromise', e);
      throw e;
    }

    return _response;
  };

  let deleteInvitationsResponses;
  try {
    deleteInvitationsResponses = await Promise.all(
      portfolioOwnerInvitations.map(deleteInvitationPromise),
    );
  } catch (e) {
    console.error('inviteCompanyPortfolioOwner deleteInvitations', e);
    OnProcurerError.dispatch(e);
    return Flux.dispatchEvent(PROCURER_ERROR_EVENT, e);
  }
  console.log('inviteCompanyPortfolioOwner deleteInvitationsResponses', deleteInvitationsResponses);

  const data = {
    id: companyId,
    companyInvitationRelation: {
      create: [
        {
          email,
          role: { connect: { name: COMPANY_PORTFOLIO_OWNER } },
        },
      ],
    },
  };

  let response;
  try {
    response = await client.mutate({
      mutation: EDIT_COMPANY_MUTATION,
      variables: { data },
    });
  } catch (e) {
    console.error('inviteCompanyPortfolioOwner', e);
    OnProcurerError.dispatch(e);
    return Flux.dispatchEvent(PROCURER_ERROR_EVENT, e);
  }

  OnInviteCompanyPortfolioOwner.dispatch(response.data);
  Flux.dispatchEvent(INVITE_COMPANY_PORTFOLIO_OWNER_EVENT, response.data);
  return response.data;
};

/**
 * Retries an invoice's payment.
 *
 * @param {object} stripe - Stripe.
 * @param {object} invoice - Invoice.
 * @param {object|null} [paymentData = null] - Payment Data.
 * @returns {Promise<void>} - Void.
 */
export const retryInvoicePayment = async (stripe, invoice, paymentData = null) => {
  OnSubscriptionInvoiceRetryLoading.dispatch(invoice);
  Flux.dispatchEvent(SUBSCRIPTION_INVOICE_RETRY_LOADING_EVENT, invoice);
  try {
    if (!paymentData) paymentData = await fetchPaymentData(invoice);

    const { client_secret, payment_method } = paymentData;

    const { paymentIntent, error } = await stripe.confirmCardPayment(client_secret, {
      payment_method,
    });

    if (error) {
      OnSubscriptionInvoiceRetryError.dispatch(error);
      Flux.dispatchEvent(SUBSCRIPTION_INVOICE_RETRY_ERROR_EVENT, error);
    } else {
      OnSubscriptionInvoiceRetry.dispatch(paymentIntent);
      Flux.dispatchEvent(SUBSCRIPTION_INVOICE_RETRY_EVENT, paymentIntent);
    }
  } catch (error) {
    console.log('stripePaymentData:error:', JSON.stringify(error));
    OnSubscriptionInvoiceRetryError.dispatch(error);
    Flux.dispatchEvent(SUBSCRIPTION_INVOICE_RETRY_ERROR_EVENT, error);
    return;
  }
};

export const fetchPaymentData = async (invoice) => {
  const client = sessionStore.getState(APOLLO_CLIENT);

  const response = await client.query({
    query: STRIPE_PAYMENT_DATA_QUERY,
    fetchPolicy: 'network-only',
    variables: { invoice: invoice.id },
  });

  console.log('stripePaymentData:response', response);

  return response.data.stripePaymentData;
};
