import React from 'react';
import { Table, NoData, Loader } from '@8base/boost';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

/**
 * Table grid columns.
 *
 * @type {string}
 */
const columns = '1fr 155px';

const LoaderContainer = styled.div`
  height: 100px;
`;

/**
 * CompanyInvitations  list.
 *
 * @param {Array} props - The component props.
 * @param {Array} props.companyInvitations - The list of companyInvitations.
 * @param {object} props.loading - If the list is loading.
 * @returns {object} The component.
 */
const CompanyInvitationsList = ({ companyInvitations, loading }) => {
  let content = (
    <LoaderContainer>
      {' '}
      <Loader stretch />
    </LoaderContainer>
  );

  if (!loading) {
    content = companyInvitations.length ? (
      <>
        {companyInvitations.map((invitation, i) => {
          const { email, status } = invitation;

          return (
            <Table.BodyRow key={i} columns={columns}>
              <Table.BodyCell justifyContent="start">{email}</Table.BodyCell>
              <Table.BodyCell justifyContent="start">{status}</Table.BodyCell>
            </Table.BodyRow>
          );
        })}
      </>
    ) : (
      <NoData />
    );
  }

  return (
    <Table>
      <Table.Header columns={columns}>
        <Table.HeaderCell justifyContent="start">Email</Table.HeaderCell>
        <Table.HeaderCell justifyContent="start">Status</Table.HeaderCell>
      </Table.Header>
      <Table.Body>{content}</Table.Body>
    </Table>
  );
};

CompanyInvitationsList.propTypes = {
  loading: PropTypes.bool.isRequired,
  companyInvitations: PropTypes.array.isRequired,
};

export { CompanyInvitationsList };
