import React from 'react';
import { Card, Dropdown, Grid, Heading, Icon, Loader, Menu, Row } from '@8base/boost';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import AllianceForm from './components/AllianceForm';
import * as toast from '../../../components/toast/Toast';
import {
  autoSaveAlliance,
  fetchAlliance,
  fetchAllianceFormData,
  openComments,
  updateAlliance,
} from './alliance-actions';
import {
  deleteAllianceInvitation,
  resendAlliancePartnerInvitation,
} from '../invitations/invitations.actions';
import AllianceModel, {
  ALLIANCE_ORGANIZATIONAL_CHART_FILE,
  ALLIANCE_POLICY_GUIDELINES_FILE,
} from './Alliance.model';
import * as R from 'ramda';
import { View } from '@cobuildlab/react-simple-state';
import { onErrorMixin } from '../../../shared/mixins';
import BusinessCaseForm from '../../document-management/business-case/components/BusinessCaseForm';
import BusinessCaseModel, {
  BUSINESS_CASE_DOCUMENT,
} from '../../document-management/business-case/BusinessCase.model';
import AllianceInvitationTable from './components/AllianceInvitationTable';
import YesNoDialog from '../../../components/dialogs/YesNoDialog';
import AllianceDetailTable from './components/AllianceDetailTable';
import BusinessCaseDetailTable from '../../document-management/business-case/components/BusinessCaseDetailTable';
import { TransparentButtonSvg } from '../../../components/buttons/TransparentButtonSvg';
import { ActionButtonClose } from '../../../components/buttons/ActionButtonClose';
import { ActionButton } from '../../../components/buttons/ActionButton';
import { TransparentButton } from '../../../components/buttons/TransparentButton';
import { FormSteps } from '../../../components/dots/FormSteps';
import { createAllianceValidator } from './alliance-validators';
import { businessCaseValidator } from '../../document-management/business-case/business-case-validators';
import collaborateIcon from '../../../images/icons/collab-chat-icon.svg';
import { fetchSession } from '../../auth/auth.actions';
import {
  checkActiveYearsAndSetStateMixin,
  getBusinessCaseAnticipatedCosts,
  popBusinessCaseKPIYear,
  pushBusinessCaseKPIYear,
  sanitizeAllianceKPIsToEdit,
} from './allianceKPIs/allianceKPIs-actions';
import AllianceKPIsForm from './allianceKPIs/components/AllianceKPIsForm';
import moment from 'moment';
import { allianceKPIsValidator } from './allianceKPIs/allianceKPIs-validators';
import { LeftProgressSection } from '../../../components/new-ui/LeftProgressSection';
import { SCREENS_ALLIANCE } from '../../management/screenView';
import { BoxCard } from '../../../components/new-ui/div/BoxCard';
import { CreateViewCardBody } from '../../../components/new-ui/card/CreateViewCardBody';
import { CardFooter } from '../../../components/new-ui/card/CardFooter';
import { ALLIANCE_COMPLETED } from '../../../shared/status';
import { sanitizeRecommendedSolutionsToEdit } from '../../document-management/business-case/businessCases.actions';
import { TextDateAgo } from '../../../components/text/TextDateAgo';
import { formatAllianceToUpdate, formatBusinessCaseToUpdate } from '../../../shared/alliance-utils';
import { canResendAlliancePartnerInvitation } from '../invitations/invitations-validators';
import {
  OnAllianceFormData,
  OnAllianceDetail,
  OnAllianceError,
  OnAllianceAutoSave,
  OnAllianceAutoSaveError,
  OnAllianceAutoUpdate,
  OnAllianceUpdate,
} from './alliance-events';

import {
  OnAllianceInvitationDelete,
  OnAllianceInvitationDeleteError,
  OnAllianceInvitationError,
  OnAllianceResendInvitation,
} from '../invitations/invitations-events';

// Edit Alliance
class AllianceEditView extends View {
  constructor(props) {
    super(props);
    this.state = {
      allianceData: R.clone(AllianceModel),
      businessCaseData: R.clone(BusinessCaseModel),
      selectedKPIYear: moment().year(),
      kpiYears: [moment().year()],
      loading: true,
      companies: [],
      invitations: [],
      currencies: [],
      step: 0,
      yesNoDialogIsOpen: false,
      yesNoDialogTitle: '',
      yesNoDialogDescription: '',
      savedAt: null,
      autoSaving: false,
      resendInvitationModalIsOpen: false,
      resendInvitation: null,
    };

    this.onError = onErrorMixin.bind(this);
    this.checkActiveYearsAndSetStateMixin = checkActiveYearsAndSetStateMixin.bind(this);
    this.originalRecommendedSolutions = [];
    this.originalData = null;
    this.originalAllianceKPIs = [];
    this.autoSaveTimer = null;
    this.savedAlliance = null;
  }

  autoSave = () => {
    if (this.state.autoSaving) return;
    const waitTime = 10000; // Wait 10s
    clearTimeout(this.autoSaveTimer);
    this.autoSaveTimer = setTimeout(() => {
      try {
        allianceKPIsValidator(this.state.allianceData);
      } catch (e) {
        console.warn(e);
        return;
      }
      this.setState({ autoSaving: true }, () => {
        const allianceData = R.clone(this.state.allianceData);
        const businessCaseData = R.clone(this.state.businessCaseData);
        autoSaveAlliance(allianceData, businessCaseData, this.savedAlliance, false);
      });
    }, waitTime);
  };

  onChangeAllianceData = (name, value) => {
    const { allianceData, businessCaseData } = this.state;
    allianceData[name] = value;
    this.checkActiveYearsAndSetStateMixin(allianceData, businessCaseData);

    this.autoSave();
  };

  onChangeBusinessCaseData = (name, value) => {
    const { businessCaseData, allianceData } = this.state;
    businessCaseData[name] = value;
    this.checkActiveYearsAndSetStateMixin(allianceData, businessCaseData);

    this.autoSave();
  };

  onChangeKPisData = (name, value) => {
    const allianceData = { ...this.state.allianceData };
    const businessCaseData = { ...this.state.businessCaseData };
    allianceData[name] = value;
    // this updates the alliance, kpiYears and selectedKPIYears on the state
    this.checkActiveYearsAndSetStateMixin(allianceData, businessCaseData);
    const { expectedRevenues, anticipatedCosts } = getBusinessCaseAnticipatedCosts(
      allianceData,
      businessCaseData,
    );
    businessCaseData.expectedRevenues = expectedRevenues;
    businessCaseData.anticipatedCosts = anticipatedCosts;
    this.setState({ businessCaseData });
    this.autoSave();
  };

  componentDidMount() {
    this.subscribe(OnAllianceAutoSave, (alliance) => {
      this.setState({ savedAt: new Date(), autoSaving: false });
      this.savedAlliance = R.clone(alliance.allianceUpdate);
      const businessCaseData = R.clone(this.savedAlliance.businessCase);
      this.originalRecommendedSolutions = sanitizeRecommendedSolutionsToEdit(businessCaseData);

      this.savedAlliance.allianceKPIs = {
        ...this.savedAlliance.allianceKPIAllianceRelation,
        items: this.savedAlliance.allianceKPIAllianceRelation.items.map((allianceKpi) => ({
          ...allianceKpi,
        })),
      };

      this.savedAlliance.documents = this.savedAlliance.documents.items.map((document) => ({
        ...document,
      }));

      this.originalAllianceKPIs = sanitizeAllianceKPIsToEdit(this.savedAlliance);
      this.originalData = R.clone(this.savedAlliance);
      this.originalBusinessCaseData = R.clone(this.savedAlliance.businessCase);
    });

    this.subscribe(OnAllianceAutoSaveError, (e) => {
      this.setState({ autoSaving: false });
      console.log(e);
    });

    this.subscribe(OnAllianceError, this.onError);
    this.subscribe(OnAllianceInvitationError, this.onError);
    this.subscribe(OnAllianceInvitationDeleteError, this.onError);

    this.subscribe(OnAllianceInvitationDelete, () => {
      const newInvitations = this.state.invitations.filter(
        (invitation) => invitation.id !== this.state.invitation.id,
      );
      this.setState({ invitations: newInvitations });
      this.savedAlliance.allianceInvitationRelation.items = newInvitations;
      toast.success('The invitation has been deleted');
    });
    this.subscribe(OnAllianceFormData, (state) => {
      const currencies = state.currenciesList.items.map((currency) => ({
        ...currency,
      }));

      this.setState(
        {
          currencies,
        },
        () => {
          const { match } = this.props;
          if (!match.params.id) return toast.error('Alliance ID missing');
          fetchAlliance(match.params.id);
        },
      );
    });

    this.subscribe(OnAllianceDetail, (state) => {
      const businessCaseData = R.clone(state.alliance.businessCase);
      const allianceData = R.clone(state.alliance);
      this.savedAlliance = R.clone(allianceData);

      const invitations = state.alliance.allianceInvitationRelation.items.map((invitation) => ({
        ...invitation,
      }));

      this.originalRecommendedSolutions = sanitizeRecommendedSolutionsToEdit(businessCaseData);

      allianceData.allianceKPIs = {
        ...this.savedAlliance.allianceKPIAllianceRelation,
        items: this.savedAlliance.allianceKPIAllianceRelation.items.map((allianceKpi) => ({
          ...allianceKpi,
        })),
      };

      allianceData.documents = allianceData.documents.items.map((document) => ({
        ...document,
      }));

      this.originalAllianceKPIs = sanitizeAllianceKPIsToEdit(allianceData);
      this.originalData = R.clone(allianceData);
      this.originalBusinessCaseData = R.clone(allianceData.businessCase);
      this.checkActiveYearsAndSetStateMixin(allianceData, businessCaseData);
      formatAllianceToUpdate(allianceData);
      formatBusinessCaseToUpdate(businessCaseData);
      allianceData.partnerCompanyName = allianceData.partnerCompany
        ? allianceData.partnerCompany.name
        : '';

      this.setState({
        allianceData,
        businessCaseData,
        invitations,
        loading: false,
      });
    });

    this.subscribe(OnAllianceUpdate, async () => {
      await fetchSession();
      toast.success('Alliance Successfully Updated');
      this.props.history.goBack();
    });

    this.subscribe(OnAllianceAutoUpdate, async (alliance) => {
      this.setState({ savedAt: new Date() });
      this.savedAlliance = R.clone(alliance);
    });

    this.subscribe(OnAllianceResendInvitation, async () => {
      toast.success('Resend alliance Membership invitation successfully');
      this.setState({ loading: false });
    });

    fetchAllianceFormData();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
    localStorage.removeItem(ALLIANCE_POLICY_GUIDELINES_FILE);
    localStorage.removeItem(ALLIANCE_ORGANIZATIONAL_CHART_FILE);
    localStorage.removeItem(BUSINESS_CASE_DOCUMENT);
  }

  onKPIYearChange = (selectedKPIYear) => {
    this.setState({ selectedKPIYear });
  };

  onYearsQuantityChange = (quantity) => {
    const businessCaseData = { ...this.state.businessCaseData };
    const allianceData = { ...this.state.allianceData };
    if (quantity > 0) {
      pushBusinessCaseKPIYear(businessCaseData);
      this.checkActiveYearsAndSetStateMixin(allianceData, businessCaseData);
    } else {
      const kpiYears = [...this.state.kpiYears];
      // Check that there are more than 1 year
      if (kpiYears.length <= 1) return;
      // If we are currently on the last year, change it to the previous one
      if (this.state.selectedKPIYear === kpiYears[kpiYears.length - 1]) {
        const selectedKPIYear = kpiYears[kpiYears.length - 2];
        this.setState({ selectedKPIYear }, () => {
          popBusinessCaseKPIYear(businessCaseData);
          this.checkActiveYearsAndSetStateMixin(allianceData, businessCaseData);
        });
      } else {
        popBusinessCaseKPIYear(businessCaseData);
        this.checkActiveYearsAndSetStateMixin(allianceData, businessCaseData);
      }
    }
  };

  onSubmit = () => {
    this.setState({ loading: true }, () => {
      clearTimeout(this.autoSaveTimer);
      const allianceData = R.clone(this.state.allianceData);
      const businessCaseData = R.clone(this.state.businessCaseData);
      updateAlliance(
        allianceData,
        businessCaseData,
        this.originalRecommendedSolutions,
        this.originalAllianceKPIs,
        this.originalData,
        this.originalBusinessCaseData,
      );
    });
  };

  onAllianceStepChange = (step) => {
    const allianceData = R.clone(this.state.allianceData);
    try {
      createAllianceValidator(allianceData);
    } catch (e) {
      return this.onError(e);
    }
    this.onScreen(step);
  };

  onBusinessCaseStepChange = (step) => {
    const businessCaseData = R.clone(this.state.businessCaseData);
    try {
      businessCaseValidator(businessCaseData);
    } catch (e) {
      return this.onError(e);
    }
    this.onScreen(step);
  };

  onKPIsStepChange = (step) => {
    const alliance = R.clone(this.state.allianceData);
    try {
      allianceKPIsValidator(alliance);
    } catch (e) {
      return this.onError(e);
    }
    this.onScreen(step);
  };

  onScreen = (step) => {
    this.setState({ step });
  };

  onSelectForDelete = (invitation, name) => {
    this.setState({
      invitation,
      yesNoDialogIsOpen: true,
      yesNoDialogTitle: name,
      yesNoDialogDescription: 'Are you sure you want Delete this Invitation?',
    });
  };

  onYes = () => {
    this.setState({ yesNoDialogIsOpen: false }, () =>
      deleteAllianceInvitation(R.clone(this.state.invitation)),
    );
  };

  onClose = () => {
    this.setState({
      yesNoDialogIsOpen: false,
    });
  };

  onResendInvitation = (invitation) => {
    this.setState({
      resendInvitation: invitation,
      resendInvitationModalIsOpen: true,
    });
  };

  onCloseResendInvitation = () => {
    this.setState({
      resendInvitationModalIsOpen: false,
    });
  };

  onYesResendInvitation = () => {
    this.setState(
      {
        resendInvitationModalIsOpen: false,
        loading: true,
      },
      () => {
        console.log(`Resend alliance invitation`, this.state.resendInvitation);
        resendAlliancePartnerInvitation(R.clone(this.state.resendInvitation));
      },
    );
  };

  render() {
    const {
      currencies,
      loading,
      step,
      allianceData,
      invitations,
      businessCaseData,
      selectedKPIYear,
      kpiYears,
      savedAt,
      resendInvitationModalIsOpen,
    } = this.state;
    const { yesNoDialogIsOpen, yesNoDialogTitle, yesNoDialogDescription } = this.state;
    const { history } = this.props;
    let content = <Loader stretch />;
    let footer = <></>;
    let buttonsTop = <></>;
    const { currency } = allianceData;

    if (allianceData.status === ALLIANCE_COMPLETED) history.push('/settings/alliance-management');
    if (!loading && step === 0) {
      content = (
        <div id={'c2gScreen'}>
          <AllianceForm
            data={allianceData}
            invitations={invitations}
            onChange={this.onChangeAllianceData}
            currencies={currencies}
            edit
          />
          <Heading
            type="h3"
            text="Sent Invitations"
            style={{
              margin: 'auto',
              fontSize: 17,
              position: 'relative',
              bottom: '-6px',
              backgroundColor: 'white',
              display: 'inline',
              padding: '0px 10px',
            }}
          />
          <div
            style={{
              border: `1px 0 0 0 solid #d0d7dd`,
              borderRadius: '5px',
              padding: 20,
              marginBottom: 10,
            }}>
            <AllianceInvitationTable invitations={invitations} loading={loading}>
              {(invitation) => {
                const [canResendInvitation] = canResendAlliancePartnerInvitation(invitation);

                return (
                  <Dropdown defaultOpen={false}>
                    <Dropdown.Head>
                      <Icon name="More" className="more-icon" />
                    </Dropdown.Head>
                    {/* TODO: Rejected in red text */}
                    <Dropdown.Body pin="right">
                      {({ closeDropdown }) => (
                        <Menu>
                          <Menu.Item
                            onClick={() => {
                              closeDropdown();
                              const name = R.pathOr('Unititled', ['alliance', 'name'], invitation);
                              this.onSelectForDelete(invitation, name);
                            }}>
                            Delete
                          </Menu.Item>
                          {canResendInvitation && (
                            <Menu.Item
                              onClick={() => {
                                closeDropdown();
                                this.onResendInvitation(invitation);
                              }}>
                              Resend invitation
                            </Menu.Item>
                          )}
                        </Menu>
                      )}
                    </Dropdown.Body>
                  </Dropdown>
                );
              }}
            </AllianceInvitationTable>
          </div>
        </div>
      );
      footer = (
        <CardFooter>
          <ActionButton type="button" onClick={() => this.onAllianceStepChange(1)} text="Next" />
        </CardFooter>
      );
    }

    if (!loading && step === 1) {
      content = (
        <div id={'c2gScreen'}>
          <BusinessCaseForm
            hideExpectCostAvoidance
            data={businessCaseData}
            onChange={this.onChangeBusinessCaseData}
            currency={currency}
          />
        </div>
      );
      footer = (
        <CardFooter>
          <ActionButton onClick={() => this.onBusinessCaseStepChange(2)} text="Next" />
          <TransparentButton onClick={() => this.onScreen(0)} text={'Previous'} />
        </CardFooter>
      );
    }

    if (!loading && step === 2) {
      // headerText = 'Key Performance Indicator';
      content = (
        <div id={'c2gScreen'}>
          <AllianceKPIsForm
            alliance={allianceData}
            onChange={this.onChangeKPisData}
            onYearChange={this.onKPIYearChange}
            onYearsChange={this.onYearsQuantityChange}
            selectedYear={selectedKPIYear}
            kpiYears={kpiYears}
          />
        </div>
      );
      footer = (
        <CardFooter>
          <ActionButton onClick={() => this.onKPIsStepChange(3)} text="Next" />
          <TransparentButton onClick={() => this.onScreen(1)} text={'Previous'} />
        </CardFooter>
      );
    }

    if (!loading && step === 3) {
      const allianceDetail = R.clone(allianceData);
      allianceDetail.allianceKPIAllianceRelation = {
        items: allianceDetail.allianceKPIs,
      };

      content = (
        <div id={'c2gScreen'}>
          <AllianceDetailTable data={allianceDetail} currency={currency} />
          <BusinessCaseDetailTable
            hideExpectedCostAvoidance
            data={businessCaseData}
            currency={currency}
          />
        </div>
      );
      footer = (
        <CardFooter>
          <ActionButton disabled={this.autoSaving} onClick={this.onSubmit} text="Update Alliance" />
          <TransparentButton onClick={() => this.onScreen(2)} text={'Previous'} />
        </CardFooter>
      );
    }

    buttonsTop = (
      <div className={'button-top company-icons '}>
        <TransparentButtonSvg
          iconSvg={collaborateIcon}
          onClick={() => openComments(allianceData)}
        />
        <ActionButtonClose onClick={history.goBack} />
      </div>
    );

    return (
      <React.Fragment>
        <Card.Header>
          <Grid.Layout
            columns="400px auto 400px"
            areas={[['left', 'center', 'right']]}
            style={{ width: '100%' }}>
            <Grid.Box area="left">
              <Row>
                <Heading type="h4" text="Edit Alliance" />
                <TextDateAgo from={savedAt} />
              </Row>
            </Grid.Box>
            <Grid.Box area="center">
              <FormSteps totalSteps={4} step={step} />
            </Grid.Box>
            <Grid.Box area="right" />
            {buttonsTop}
          </Grid.Layout>
        </Card.Header>
        <CreateViewCardBody>
          <Grid.Layout columns="30% 70%" areas={[['left', 'right']]} style={{ width: '100%' }}>
            <Grid.Box area="left">
              <LeftProgressSection sections={SCREENS_ALLIANCE} currentScreen={step} />
            </Grid.Box>
            <BoxCard>
              <Grid.Box area="right">{content}</Grid.Box>
            </BoxCard>
          </Grid.Layout>
        </CreateViewCardBody>
        {footer}
        <YesNoDialog
          isOpen={yesNoDialogIsOpen}
          onYes={this.onYes}
          onNo={this.onClose}
          onClose={this.onClose}
          text={yesNoDialogDescription}
          title={yesNoDialogTitle}
        />
        <YesNoDialog
          isOpen={resendInvitationModalIsOpen}
          onYes={this.onYesResendInvitation}
          onNo={this.onCloseResendInvitation}
          onClose={this.onCloseResendInvitation}
          text={'Are you sure you want to resend Alliance Partner Invitation?'}
          title={'Resend Invitation'}
        />
      </React.Fragment>
    );
  }
}

AllianceEditView.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default withRouter(AllianceEditView);
