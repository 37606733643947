import { ValidationError } from '../../../shared/errors';
import { isValidString, setErrorIfDateIsBeforeAllianceStart } from '../../../shared/validators';
import currency from 'currency.js';
import moment from 'moment';

// import { deleteBusinessCase } from '../../document-management/business-case/businessCases.actions';

/**
 * Validate that the Deal has all the information necessary to create.
 *
 * @param {object} deal - The Deal to be reviewed.
 */
export const validateDealForCreate = (deal) => {
  let errorMessages = [];
  if (!isValidString(deal.name)) errorMessages.push('The Deal must have a valid Name');

  if (!isValidString(deal.owner)) errorMessages.push('The Deal must have a valid Owner');

  if (deal.alliance === null) errorMessages.push('The Deal must have a valid Alliance');
  if (errorMessages.length !== 0) throw new ValidationError(errorMessages);
};

/**
 * Validator for the Deal Form.
 *
 * @param {object}deal - Deal.
 * @param {object}allianceDetails - Alliance.
 */
export const dealValidator = (deal, allianceDetails) => {
  let errorMessages = [];

  if (!isValidString(deal.name)) errorMessages.push('The Deal must have a valid Name');

  if (!moment(deal.closeDate, 'YYYY-MM-DD', true).isValid()) {
    errorMessages.push('The Deal must have a valid Close Date');
  } else {
    setErrorIfDateIsBeforeAllianceStart(
      deal.closeDate,
      allianceDetails,
      'Close Date',
      errorMessages,
    );
  }

  if (!(parseFloat(deal.amount) >= 0)) {
    errorMessages.push('The Deal Imported must have a valid Amount');
  }

  if (!isValidString(deal.customerName))
    errorMessages.push('The Deal must have a valid Customer Name');

  if (!isValidString(deal.stage)) errorMessages.push('The Deal must have a valid Deal Stage');

  if (!isValidString(deal.dealReferencable)) {
    errorMessages.push('The Deal must have a valid Deal Referencable');
  } else {
    const dealReferencableValues = ['N', 'Y'];
    const result = dealReferencableValues.find((value) => value === deal.dealReferencable);
    if (!result) errorMessages.push('The Deal must have a valid Deal Referencable Y / N');
  }
  // throw new IntegrityError('The Deal must have a valid Name');
  if (errorMessages.length !== 0) throw new ValidationError(errorMessages);
};

const isValidDate = (date) => {
  return (
    moment(date, 'MM/DD/YYYY', true).isValid() ||
    moment(date, 'M/D/YYYY', true).isValid() ||
    moment(date, 'M/DD/YYYY', true).isValid() ||
    moment(date, 'YYYY-MM-DD', true).isValid() ||
    moment.utc(date, undefined, true).isValid() ||
    moment(date, 'MM/D/YYYY', true).isValid()
  );
};

/**
 * Validate all data CVS.
 *
 * @param {string}key - : key from data CVS Obj.
 * @param {string} value - : value from data CVS Obj.
 * @param {Array} availableDealsSourceIds - : Array with record deals already stored in the database for this company.
 * @param {Array}availableDealsSourceIdsFromOtherCompany - : Array with record deals already stored in the database for the other company company.
 * @param {Array}dealsImportedList - : Array with deals imported.
 * @param {Array}associatedDealIdLabel - : object with label associatedDealId.
 * @param {Array}dealSourceIdLabel -  : object with label dealSourceId.
 * @param {Array}currencyOnAlliance - : the currency of the alliance.
 * @param {Array}mappingList - : mappingList.
 *
 * @returns {Array} - ErrorMessages.
 */
export const dealImportedValidator = (
  key,
  value,
  availableDealsSourceIds,
  availableDealsSourceIdsFromOtherCompany,
  dealsImportedList,
  associatedDealIdLabel,
  dealSourceIdLabel,
  currencyOnAlliance,
  mappingList,
) => {
  let errorMessages = [];
  if (key === 'amount') {
    const currencyOptions = {
      symbol: currencyOnAlliance.symbol,
      decimal: currencyOnAlliance.decimalSeparator,
      separator: currencyOnAlliance.decimalSeparator,
      precision: 0,
    };
    const amount = currency(value, currencyOptions).intValue; // if value is string amount = 0

    // if (value.indexOf('-') >= 0 || value.indexOf('/') >= 0 || value.indexOf(' ') >= 0)
    //   // value.indexOf('-') and value.indexOf('/') is for validate if value is date
    //   // value.indexOf(' ') is for validate if value is text
    //   errorMessages.push('The Deal Imported must have a valid Amount');

    if (!(amount >= 0)) {
      //if amount is less than -1
      errorMessages.push(
        'The Deal Imported must have a valid Amount. The Amount must be greater than 0.',
      );
    }

    if (!amount) {
      //if the string has a single letter
      errorMessages.push(
        `The Deal Imported must have a valid Amount. The format was not recognized. The format of the string must be compatible with the currency on your alliance: ${currencyOptions}`,
      );
    }
  }

  if (key === 'closeDate' && !isValidDate(value)) {
    errorMessages.push('The Deal Imported must have a valid Close Date');
  }

  if (key === 'createdDate' && !isValidDate(value)) {
    errorMessages.push('The Deal Imported must have a valid Created Date');
  }

  if (key === 'lastActivityDate' && !isValidDate(value)) {
    errorMessages.push('The Deal Imported must have a valid Last Activity Date');
  }

  if (key === 'lastContactedDate' && !isValidDate(value)) {
    errorMessages.push('The Deal Imported must have a valid Last Contacted Date');
  }

  if (key === 'lastModifiedDate' && !isValidDate(value)) {
    errorMessages.push('The Deal Imported must have a valid Last Modified Date');
  }
  if (key === 'nextActivityDate' && !isValidDate(value)) {
    errorMessages.push('The Deal Imported must have a valid Next Activity Date');
  }

  if (key === 'associatedDealId') {
    if (value.length !== 0) {
      // Checking if the associatedDealId is not on the dealSourceId List
      // You can't associate your self or some other deal from your same company
      if (dealSourceIdLabel) {
        const _dealSourceId = dealsImportedList.find(
          (item) => item[dealSourceIdLabel.header] === value,
        );
        if (_dealSourceId !== undefined)
          errorMessages.push(
            `There is a Deal on your Dataset with this value: ${value} as Deal Source Id. You can't associate a Deal with another Deal of the same company.`,
          );
      }

      // Checking if the Value exist as Deal Source Id on the Database
      // This is done from the DealSourceIds from the other Company
      const _dealOnDatabase = availableDealsSourceIdsFromOtherCompany.find(
        (item) => item.dealSourceId === value,
      );
      if (!_dealOnDatabase)
        errorMessages.push(`The Associated Deal Id: ${value} does not exists on the Database.`);

      // Finding duplicates on the current set of deals to be imported
      const _associatedDealsIdList = dealsImportedList.filter(
        (item) => item[associatedDealIdLabel.header] === value,
      );
      if (_associatedDealsIdList.length > 1)
        errorMessages.push(
          `There is more than one deal on the current data set with this Associated Deal Id: ${value}`,
        );
    }
  }

  if (key === 'dealSourceId') {
    if (value.length !== 0) {
      // NOTE: This was removed intentionally, Deal Source Id can already exists for Updates
      // const _dealSourceList = availableDealsSourceIds.find(
      //   (item) => item.dealSourceId === value,
      // );
      // if (_dealSourceList)
      //   errorMessages.push(`The Deal Source Id: ${value} already exists in the system.`);

      // Finding Duplicates
      const _dealsImportedList = dealsImportedList.filter(
        (item) => item[dealSourceIdLabel.header] === value,
      );

      if (_dealsImportedList.length > 1)
        errorMessages.push(`There are two deals with the same Deal Source Id: ${value}`);
    }
  }

  if (key === 'owner' && value.length === 0) errorMessages.push('Invalid Owner');

  if (key === 'stage' && !dealMappingValidator(mappingList, value))
    errorMessages.push(`The '${value}' value is not mapped`);

  return errorMessages;
};

export const dealImportedHeaderValidate = (headers) => {
  const headersRequired = [
    { id: 'customerName', label: 'Customer Name' },
    { id: 'amount', label: 'Amount' },
    // { id: 'dealSourceId', label: 'Deal Source ID' },
    { id: 'closeDate', label: 'Close Date' },
    { id: 'name', label: 'Deal Name' },
    { id: 'stage', label: 'Deal Stage' },
    { id: 'dealReferencable', label: 'Deal Referencable (Y / N)' },
  ];

  return headersRequired
    .filter((requiredHeader) => !headers.some((header) => header.value === requiredHeader.id))
    .map((header) => `The Deal Imported must have a valid ${header.label}`);
};

/**
 * Validates the associated Deal Id
 * the Associated Deal Id must exists for the other Company, and it shouldn't be already on use.
 *
 * @param {string}associatedDealId - Associated deal Id.
 * @param {object}dealSourceIdList - Deal source id List.
 * @param {object}otherCompanyDealSourceIds - Other company.
 */
export const createAssociatedDealIdValidator = (
  associatedDealId,
  dealSourceIdList,
  otherCompanyDealSourceIds,
) => {
  const deal = otherCompanyDealSourceIds.find((item) => item.dealSourceId === associatedDealId);

  if (!deal)
    throw new ValidationError(
      'The Associated Deal Id does not exists as a Deal Source Id for your other Company.',
    );

  const _deal = dealSourceIdList.find((item) => item.associatedDealId === associatedDealId);
  if (_deal)
    throw new ValidationError(
      'The Associated Deal Id has already been used to associated another deal on your Company.',
    );
};

/**
 * Validate the Association deal ID for UPDATE DEALS :
 * Validate if Deals not previously associated
 * the Associated Deal Id must exists for the other Company, and it shouldn't be already on use.
 *
 * @param {string}dealSourceId - DealSourceId.
 * @param {string}associatedDealId - Associated Deals Id.
 * @param {Array}dealSourceIdList - Deal Source Id List.
 * @param {Array}otherCompanyDealSourceIds - Other Company.
 */
export const updateAssociatedDealIdValidator = (
  dealSourceId,
  associatedDealId,
  dealSourceIdList,
  otherCompanyDealSourceIds,
) => {
  const deal = otherCompanyDealSourceIds.find((item) => item.dealSourceId === associatedDealId);

  if (!deal)
    throw new ValidationError(
      'The Associated Deal Id does not exists as a Deal Source Id for your other Company.',
    );

  const _deal = dealSourceIdList.find(
    (item) => item.associatedDealId === associatedDealId && item.dealSourceId !== dealSourceId,
  );
  if (_deal)
    throw new ValidationError(
      'The Associated Deal Id has already been used to associated another deal on your Company.',
    );
};

/**
 * Validates that a dealSourceId doesn't exist for a Company already.
 *
 * @param {string}dealSourceId - Deal Source Id.
 * @param {Array}dealSourceIdList - Deal Source Id List.
 */
export const dealSourceIdValidator = (dealSourceId, dealSourceIdList) => {
  const _dealSourceId = dealSourceIdList.filter((item) => item.dealSourceId === dealSourceId);
  if (_dealSourceId.length > 0) throw new ValidationError('The Deal Source Id already exists');
};
/**
 * Validate is exists stage.
 *
 * @param {Array}dealMappingList - Deal Stage Mapping List.
 * @param {string}value - Deal Stage value.
 * @returns {any} Deal mapping validator.
 */
export const dealMappingValidator = (dealMappingList, value) => {
  const { dealStagesList } = dealMappingList;

  console.log('dealMappingValidator:dealMappingList', dealMappingList);
  console.log('dealMappingValidator:value', value);

  return dealStagesList.items.reduce((result, stage) => {
    console.log('dealMappingValidator:stage', stage);
    return result || stage.values.reduce((r, vl) => r || vl.value === value, false);
  }, false);
};
