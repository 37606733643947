import Flux from '@cobuildlab/flux-state';

/**
 * Event that triggers a INITIATIVE ACTIVE ITEMS error.
 *
 * @type {string}
 */
export const INITIATIVE_ACTIVE_ITEMS_ERROR_EVENT = 'onInitiativeActiveItemsError';

/**
 * Event that triggers a INITIATIVE ACTIVE ITEMS list.
 *
 * @type {string}
 */
export const INITIATIVE_ACTIVE_ITEMS_EVENT = 'onInitiativeActiveItems';

/**
 * Hold the CommentsData.
 */
class InitiativeActiveItemsStore extends Flux.DashStore {
  constructor() {
    super();
    this.addEvent(INITIATIVE_ACTIVE_ITEMS_ERROR_EVENT);
    this.addEvent(INITIATIVE_ACTIVE_ITEMS_EVENT);
  }
}

export default new InitiativeActiveItemsStore();
