import React from 'react';
import { Card, Column, Text } from '@8base/boost';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import moment from 'moment';

const PointerCard = styled(Card)`
  margin: '10px';
  cursor: pointer;
  width: 60px;
  height: 108px;
  border: 1px solid ${({ active }) => (active ? '#3EB7F9' : '#EAEFF3')};
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.06);
`;

const Bar = styled.div`
  width: ${({ width }) => width};
  height: ${({ width }) => width};
  border-radius: 15px;
  background-color: ${({ active }) => (active ? '#3eb7f9' : '')};
  margin: 0 0 7px 0 !important;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const DayComponent = ({ points, active, weekday, date, day, width, onClick }) => {
  const pointsArray = Array.apply(null, { length: points });
  const dateToSelect = moment(date).format('YYYY-MM-DD');

  return (
    <PointerCard active={active} onClick={() => onClick(dateToSelect)}>
      <Card.Body style={{ padding: '5px' }}>
        <Column alignItems="center">
          <Text
            width="bold"
            style={{
              color: active ? '#3eb7f9' : '#A5A4A5',
              fontWeight: 600,
              marginTop: '6px',
              marginBottom: '2px',
            }}>
            {weekday}
          </Text>
          <Bar width={width} active={active}>
            <Text
              style={{
                fontSize: '14px',
                color: active ? '#FFFFFF' : '#384A59',
                fontWeight: 600,
              }}>
              {day}
            </Text>
          </Bar>
          {pointsArray.length > 0
            ? pointsArray.map((_, i) => (
              <div
                key={i}
                style={{
                  height: '5px',
                  width: '5px',
                  borderRadius: '2.5px',
                  marginBottom: '5px',
                  backgroundColor: pointsArray.length >= 3 ? '#FCBD00' : '#9B9B9B',
                }}
              />
            ))
            : null}
        </Column>
      </Card.Body>
    </PointerCard>
  );
};

DayComponent.defaultProps = {
  width: '30px',
  points: 0,
};

DayComponent.propTypes = {
  points: PropTypes.number,
  width: PropTypes.string,
  date: PropTypes.string.isRequired,
  weekday: PropTypes.string.isRequired,
  day: PropTypes.number.isRequired,
  active: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default DayComponent;
