import React from 'react';
import PropTypes from 'prop-types';
import { Select } from '@8base/boost';
import DealDataInput from './DealDataInput';

class DealDataCSVRow extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      dealValue: null,
      ownerValue: null,
    };
  }

  onChangeOwner = (value) => {
    this.setState({ ownerValue: value }, () => {
      this.props.dealOwner[this.props.index] = value;
    });
  };

  render() {
    const { columns, index, dealImportedError } = this.props;
    const indexError = dealImportedError.indexRow === index ? dealImportedError.indexColumn : null;

    return (
      <tr>
        <td>
          <Select
            style={{ width: '250px', margin: '0.5em' }}
            placeholder="Select a Owner"
            options={this.props.allianceMembersOptions}
            clearable
            value={this.state.ownerValue}
            onChange={this.onChangeOwner}
          />
        </td>
        {columns.map((col, i) => {
          let addClassError = false;
          if (indexError === i) {
            addClassError = true;
          }

          return (
            <td key={i}>
              <DealDataInput
                indexRow={this.props.index}
                index={i}
                columns={this.props.columns}
                value={col}
                dataCvs={this.props.dataCvs}
                addClassError={addClassError}
              />
            </td>
          );
        })}
      </tr>
    );
  }
}

DealDataCSVRow.propTypes = {
  index: PropTypes.number.isRequired,
  columns: PropTypes.array.isRequired,
  dataCvs: PropTypes.array.isRequired,
  dealImportedError: PropTypes.object.isRequired,
  allianceMembersOptions: PropTypes.array.isRequired,
  dealOwner: PropTypes.array.isRequired,
};

export { DealDataCSVRow };
