import React from 'react';
import PropTypes from 'prop-types';

//Component returns thet given text prop with a style of 'color: lightgrey'
const LightGreyText = ({ text, fontSize }) => {
  return (
    <span style={fontSize ? { fontSize: fontSize, color: 'lightgrey' } : { color: 'lightgrey' }}>
      {text}
    </span>
  );
};

LightGreyText.propTypes = {
  text: PropTypes.string.isRequired,
  fontSize: PropTypes.number,
};

LightGreyText.defaultProps = {
  fontSize: 18,
};

export default LightGreyText;
