import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import { Tabs } from '../../../components/tabs/Tabs';
import {
  PivotTableConfigView as ConfigView,
  PivotTableReportView as ReportView,
} from './components';
import { TABS_LIST } from './model';

const PivotTableView = () => {
  return (
    <div id={'c2gScreen'}>
      <Tabs tabsList={TABS_LIST} />
      <Switch>
        <Route path="/reports/pivot-table/configuration" component={ConfigView} />
        <Route path="/reports/pivot-table/report" component={ReportView} />
        <Redirect to="/reports/pivot-table/configuration" />
      </Switch>
    </div>
  );
};

export { PivotTableView };
