import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import '../../css/alliance.css';
import { HorizontalLine } from '../../../../components/new-ui/text/HorizontalLine';
import { HorizontalLineText } from '../../../../components/text/HorizontalLineText';
import { TablePosition } from '../../../../components/new-ui/div/TablePosition';
import { TableDetail } from '../../../../components/new-ui/table/TableDetail';
import { ThTitlePosition } from '../../../../components/new-ui/div/ThTitlePosition';

const WarningRow = styled('td')`
  background-color: rgba(241, 212, 6, 0.45);
  text-align: center;
`;

const CompanyCurrentPlanDetailTable = (props) => {
  const { currentPlan } = props;

  if (!currentPlan) {
    return (
      <>
        <HorizontalLine>
          <HorizontalLineText>Current Plan</HorizontalLineText>
        </HorizontalLine>
        <TablePosition>
          <TableDetail>
            <tbody>
              <tr>
                <WarningRow>This company does not have a plan yet</WarningRow>
              </tr>
            </tbody>
          </TableDetail>
        </TablePosition>
      </>
    );
  }

  const {
    name,
    price,
    anualSavings,
    order,
    description,
    monthlyPrice,
    annualPrice,
    allianceLimit,
  } = currentPlan;

  return (
    <>
      <HorizontalLine>
        <HorizontalLineText>Current Plan</HorizontalLineText>
      </HorizontalLine>
      <>
        <TablePosition>
          <TableDetail>
            <tbody>
              <tr>
                <th>
                  <ThTitlePosition>
                    <span>NAME</span>
                  </ThTitlePosition>
                </th>
                <td>{name}</td>
              </tr>
              <tr>
                <th>
                  <ThTitlePosition>
                    <span>PRICE</span>
                  </ThTitlePosition>
                </th>
                <td>{price}</td>
              </tr>
              <tr>
                <th>
                  <ThTitlePosition>
                    <span>ANUAL SAVINGS</span>
                  </ThTitlePosition>
                </th>
                <td>{anualSavings}</td>
              </tr>
              <tr>
                <th>
                  <ThTitlePosition>
                    <span>ORDER</span>
                  </ThTitlePosition>
                </th>
                <td>{order}</td>
              </tr>
              <tr>
                <th>
                  <ThTitlePosition>
                    <span>MONTHLY PRICE</span>
                  </ThTitlePosition>
                </th>
                <td>{monthlyPrice}</td>
              </tr>
              <tr>
                <th>
                  <ThTitlePosition>
                    <span>DESCRIPTION</span>
                  </ThTitlePosition>
                </th>
                <td>{description}</td>
              </tr>
              <tr>
                <th>
                  <ThTitlePosition>
                    <span>ANNUAL PRICE</span>
                  </ThTitlePosition>
                </th>
                <td>{annualPrice}</td>
              </tr>
              <tr>
                <th>
                  <ThTitlePosition>
                    <span>ALLIANCE LIMIT</span>
                  </ThTitlePosition>
                </th>
                <td>{allianceLimit}</td>
              </tr>
            </tbody>
          </TableDetail>
        </TablePosition>
      </>
    </>
  );
};

CompanyCurrentPlanDetailTable.propTypes = {
  currentPlan: PropTypes.object,
};

CompanyCurrentPlanDetailTable.defaultProps = {
  currentPlan: null,
};

export default CompanyCurrentPlanDetailTable;
