import sessionStore, { NEW_SESSION_EVENT } from './SessionStore';
import Flux from '@cobuildlab/flux-state';
import { IntegrityError } from './errors';
import { isValidString } from './validators';

const ACTIVE_ALLIANCE_ERROR = new IntegrityError('Must have an Active Alliance');

/**
 * Decorator function to check if an Active Alliance Exists on the current session.
 * DANGER: Not properly tested. Let's see what happen!.
 *
 * @param {Function} func - The function to decorate.
 * @param {string} errorEvent - The error event to dispatch.
 * @returns {function(...[*]): *} - The decorated function.
 */
export const hasActiveAllianceDecorator = (func, errorEvent) => {
  return (...args) => {
    const { selectedAlliance } = sessionStore.getState(NEW_SESSION_EVENT);

    if (selectedAlliance === null || selectedAlliance === undefined) {
      Flux.dispatchEvent(errorEvent, ACTIVE_ALLIANCE_ERROR);
      throw ACTIVE_ALLIANCE_ERROR;
    }

    const allianceId = selectedAlliance.id;

    if (!isValidString(allianceId)) {
      Flux.dispatchEvent(errorEvent, ACTIVE_ALLIANCE_ERROR);
      throw ACTIVE_ALLIANCE_ERROR;
    }
    return func(...args);
  };
};
