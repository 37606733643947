import sessionStore, { APOLLO_CLIENT, NEW_SESSION_EVENT } from '../../../shared/SessionStore';
import {
  sanitize8BaseBigInt,
  sanitize8BaseReconnectsFromObjects,
  sanitize8BaseReferenceFromObject,
  sanitize8BaseReferencesFromObjects,
} from '../../../shared/utils';
import Flux from '@cobuildlab/flux-state';
import { IntegrityError } from '../../../shared/errors';
import { validateContributionData } from './contribution-validators';
import { error, log } from '@cobuildlab/pure-logger';
import {
  CONTRIBUTION_AUTO_SAVE_ERROR_EVENT,
  CONTRIBUTION_AUTO_SAVE_EVENT,
  CONTRIBUTION_COMPLETED_EVENT,
  CONTRIBUTION_CREATE_EVENT,
  CONTRIBUTION_DETAIL_EVENT,
  CONTRIBUTION_ERROR_EVENT,
  CONTRIBUTION_RESTORE_EVENT,
  CONTRIBUTION_UPDATE_EVENT,
} from './contribution-store';
import {
  CONTRIBUTION_CLOSE_MUTATION,
  CONTRIBUTION_COMMENTS_QUERY,
  CONTRIBUTION_CREATE_MUTATION,
  CONTRIBUTION_DETAIL_QUERY,
  CONTRIBUTION_UPDATE_QUERY,
} from './contribution-queries';
import { CONTRIBUTION_COMPLETED, CONTRIBUTION_OPEN } from '../../../shared/status';
import {
  COMMENT_CREATE_EVENT,
  COMMENT_ERROR_EVENT,
  COMMENT_REQUEST_EVENT,
  COMMENTS_EVENT,
} from '../../comment/comment-store';
import { CONTRIBUTION_TYPE } from '../../../shared/item-types';
import { COMMENTS_CREATE_MUTATION } from '../../comment/comment-queries';
import { canCompletedContribution, canRestoreContribution } from './contribution-permissions';
import { RELATED_ITEM_UPDATE_MUTATION } from '../../related-item/related-item-queries';
import {
  normalize8baseDocumentsCreate,
  normalize8baseDocumentsDeleteAndUpdate,
} from '@cobuildlab/8base-utils';
import * as R from 'ramda';
import {
  OnContributionCreate,
  OnContributionError,
  OnContributionAutoSave,
  OnContributionAutoSaveError,
  OnContributionDetail,
  OnContributionUpdate,
  OnContributionCompleted,
  OnContributionRestore,
} from './contribution-events';
import {
  OnCommentError,
  OnCommentRequest,
  OnCommentCreate,
  OnComment,
} from '../../comment/comment-events';

/**
 * Notifies a Request for Comments for a Contribution.
 */
export const openComments = ({ id }) => {
  OnCommentRequest.dispatch({ type: CONTRIBUTION_TYPE, id: id });
  Flux.dispatchEvent(COMMENT_REQUEST_EVENT, { type: CONTRIBUTION_TYPE, id: id });
};

/**
 * Create a Contribution.
 *
 * @param {object}contribution - Contribution.
 * @param {Array}relatedItems - Related Items.
 * @param {object}initiatives - Initiatives.
 * @returns {Promise<void>} Promise.
 */

export const createContribution = async (
  contribution,
  relatedItems,
  initiatives,
  savedContribution,
) => {
  log('createContribution', contribution, relatedItems, initiatives);

  const {
    selectedAlliance: { id: allianceId },
  } = sessionStore.getState(NEW_SESSION_EVENT);

  if (!allianceId) {
    OnContributionError.dispatch(new IntegrityError('Must have an Active Alliance'));

    return Flux.dispatchEvent(
      CONTRIBUTION_ERROR_EVENT,
      new IntegrityError('Must have an Active Alliance'),
    );
  }

  try {
    validateContributionData(contribution, initiatives);
  } catch (err) {
    error('createContribution', err);
    OnContributionError.dispatch(err);
    return Flux.dispatchEvent(CONTRIBUTION_ERROR_EVENT, err);
  }

  log('createContributionWithOutBusinessCase', contribution);
  try {
    const _contribution = await createOrUpdateContribution(
      contribution,
      relatedItems,
      initiatives,
      savedContribution,
    );
    log('createContribution', _contribution);
    OnContributionCreate.dispatch(_contribution);
    return Flux.dispatchEvent(CONTRIBUTION_CREATE_EVENT, _contribution);
  } catch (e) {
    error('createContribution', e);
    OnContributionError.dispatch(e);
    return Flux.dispatchEvent(CONTRIBUTION_ERROR_EVENT, e);
  }
};

/**
 * Create a Contribution.
 *
 * @param {object}contribution - Contribution.
 * @param {Array}relatedItems - Related Items.
 * @param {object}initiatives - Initiatives.
 * @returns {Promise<void>} Promise.
 */
export const _createContribution = async (contribution, relatedItems, initiatives) => {
  log('createContribution', contribution, relatedItems, initiatives);
  const client = sessionStore.getState(APOLLO_CLIENT);

  delete contribution.id;

  const {
    selectedAlliance: { id: allianceId },
  } = sessionStore.getState(NEW_SESSION_EVENT);

  if (!allianceId) throw new IntegrityError('Must have an Active Alliance');

  contribution.initiatives = initiatives;
  // Transforming into 8base 'connect' relationships
  normalize8baseDocumentsCreate(contribution, 'documents');
  sanitize8BaseReferencesFromObjects(contribution, 'initiatives');
  sanitize8BaseReferenceFromObject(contribution, 'source');
  sanitize8BaseBigInt(contribution, 'unitMonetizationFactor');

  contribution.status = CONTRIBUTION_OPEN;
  contribution.itemContributionRelation = {
    create: {
      alliance: { connect: { id: allianceId } },
      itemsRelated: {
        connect: relatedItems.map((item) => ({ id: item.itemId })),
      },
    },
  };

  const {
    data: { contributionCreate },
  } = await client.mutate({
    mutation: CONTRIBUTION_CREATE_MUTATION,
    variables: { data: contribution },
  });

  return contributionCreate;
};

/**
 * Auto-Save Creates a New Contribution.
 *
 * @param {object} contribution - Contribution.
 * @param {object} relatedItems - RelatedItems.
 * @param {Array} initiatives - Initiatives.
 * @param {object}savedContribution - Saved contribution.
 * @returns {Promise<void>} Return promise.
 */
export const autoSaveCreateContribution = async (
  contribution,
  relatedItems,
  initiatives,
  savedContribution,
) => {
  console.log('Auto-Save: ', {
    contribution,
    relatedItems,
    initiatives,
    savedContribution,
  });

  try {
    const _contribution = await createOrUpdateContribution(
      contribution,
      relatedItems,
      initiatives,
      savedContribution,
    );

    OnContributionAutoSave.dispatch(_contribution);
    Flux.dispatchEvent(CONTRIBUTION_AUTO_SAVE_EVENT, _contribution);
    console.log('autoSaveCreateContribution:response', _contribution);
    return contribution;
  } catch (e) {
    OnContributionAutoSaveError.dispatch(e);
    Flux.dispatchEvent(CONTRIBUTION_AUTO_SAVE_ERROR_EVENT, e);
    console.error('autoSaveCreateContribution', e);
  }
};

/**
 * Auto-Save Update a Contribution.
 *
 * @param {object}contributionData - Contribution Data.
 * @param {Array}relatedItems - Related Items.
 * @param {object}initiatives - Initiatives.
 * @param {object}originalContributionData - Original Contribution Data.
 * @returns {Promise<void|*>} Promise.
 */
export const autoSaveUpdateContribution = async (
  contributionData,
  relatedItems,
  initiatives,
  originalContributionData,
) => {
  try {
    const contribution = await _updateContribution(
      contributionData,
      relatedItems,
      initiatives,
      originalContributionData,
    );

    OnContributionAutoSave.dispatch(contribution);
    Flux.dispatchEvent(CONTRIBUTION_AUTO_SAVE_EVENT, contribution);
    console.log('autoSaveUpdateContribution:response', contribution);
    return contribution;
  } catch (e) {
    console.error('autoSaveUpdateContribution:error', e);
  }
};

export const createOrUpdateContribution = (
  contribution,
  relatedItems,
  initiatives,
  savedContribution,
) => {
  if (!savedContribution) {
    return _createContribution(contribution, relatedItems, initiatives);
  } else {
    const contributionData = R.clone(contribution);
    const originalContributionData = R.clone(savedContribution);

    contributionData.id = originalContributionData.id;
    contributionData.itemId = originalContributionData.itemContributionRelation.id;

    originalContributionData.documents = originalContributionData.documents.items;

    return _updateContribution(
      contributionData,
      relatedItems,
      initiatives,
      originalContributionData,
    );
  }
};

/**
 * Create a comment on a Contribution.
 *
 * @param {string}contributionId - Contribution id.
 * @param {object}comment - Comment.
 * @returns {Promise<*>} Promise.
 */
export const createContributionComment = async (contributionId, comment) => {
  const client = sessionStore.getState(APOLLO_CLIENT);
  const data = {
    comment,
    contributionCommentsRelation: { connect: { id: contributionId } },
  };

  let response;
  try {
    response = await client.mutate({
      mutation: COMMENTS_CREATE_MUTATION,
      variables: { data },
    });
  } catch (e) {
    error('createContributionComment', e);
    OnCommentError.dispatch(e);
    return Flux.dispatchEvent(COMMENT_ERROR_EVENT, e);
  }
  log('createContributionComment', response);
  OnCommentCreate.dispatch(response.data);
  Flux.dispatchEvent(COMMENT_CREATE_EVENT, response.data);
  return response.data;
};

/**
 * Fetches the Contribution Item Comments.
 *
 * @param {string}contributionId - Contribution Id.
 * @returns {Promise<void>} Promise.
 */
export const fetchContributionComments = async (contributionId) => {
  const client = sessionStore.getState(APOLLO_CLIENT);
  const data = { id: contributionId };
  const { user } = sessionStore.getState(NEW_SESSION_EVENT);

  let response;
  try {
    response = await client.query({
      query: CONTRIBUTION_COMMENTS_QUERY,
      variables: data,
      fetchPolicy: 'network-only',
    });
  } catch (e) {
    error('fetchContributionComments', e);
    OnCommentError.dispatch(e);
    return Flux.dispatchEvent(COMMENT_ERROR_EVENT, e);
  }
  log('fetchContributionComments', response);

  const comments = R.clone(response.data.contribution.comments);
  comments.items = response.data.contribution.comments.items.map((contribution) => ({
    ...contribution,
  }));

  comments.userId = user.id;

  OnComment.dispatch(comments);
  Flux.dispatchEvent(COMMENTS_EVENT, comments);
  return comments;
};

/**
 * Fetches the Contribution Item.
 *
 * @returns {Promise<void>} Promise.
 * @param {string}contributionId - Contribution Id.
 */
export const fetchContribution = async (contributionId) => {
  const client = sessionStore.getState(APOLLO_CLIENT);
  const data = { id: contributionId };

  let response;
  try {
    response = await client.query({
      query: CONTRIBUTION_DETAIL_QUERY,
      variables: data,
      fetchPolicy: 'network-only',
    });
  } catch (e) {
    error('fetchContribution', e);
    OnContributionError.dispatch(e);
    return Flux.dispatchEvent(CONTRIBUTION_ERROR_EVENT, e);
  }
  log('fetchContribution', response);
  OnContributionDetail.dispatch(response.data);
  Flux.dispatchEvent(CONTRIBUTION_DETAIL_EVENT, response.data);
  return response.data;
};

/**
 * Update a Contribution.
 *
 * @param {object}contributionData - Contribution Data.
 * @param {Array}relatedItems - Related Items.
 * @param {object}initiatives - Initiatives.
 * @param {object}originalContributionData - Original Contribution Data.
 * @returns {Promise<void|*>} Promise.
 */
export const updateContribution = async (
  contributionData,
  relatedItems,
  initiatives,
  originalContributionData,
) => {
  console.log('updateContribution', contributionData, initiatives, relatedItems);

  try {
    validateContributionData(contributionData, initiatives);
  } catch (e) {
    error('updateContributionWithOutBusinessCase Validator', e);
    OnContributionError.dispatch(e);
    return Flux.dispatchEvent(CONTRIBUTION_ERROR_EVENT, e);
  }

  try {
    const _contribution = await _updateContribution(
      contributionData,
      relatedItems,
      initiatives,
      originalContributionData,
    );

    OnContributionUpdate.dispatch(_contribution);
    Flux.dispatchEvent(CONTRIBUTION_UPDATE_EVENT, _contribution);
    return _contribution;
  } catch (e) {
    error('updateContributionWithOutBusinessCase', e, contributionData);
    OnContributionError.dispatch(e);
    Flux.dispatchEvent(CONTRIBUTION_ERROR_EVENT, e);
  }
};

/**
 * Update a Contribution.
 *
 * @param {object}contributionData - Contribution Data.
 * @param {Array}relatedItems - Related Items.
 * @param {object}initiatives - Initiatives.
 * @param {object}originalContributionData - Original Contribution Data.
 * @returns {Promise<void|*>} Promise.
 */
export const _updateContribution = async (
  contributionData,
  relatedItems,
  initiatives,
  originalContributionData,
) => {
  const client = sessionStore.getState(APOLLO_CLIENT);
  const itemId = contributionData.itemId;
  console.log('updateContribution', contributionData, initiatives, relatedItems);

  delete contributionData.itemId;
  delete contributionData.owner;
  delete contributionData.itemContributionRelation;
  delete contributionData.contributionApprovalContributionRelation;
  delete contributionData.__typename;

  contributionData.initiatives = initiatives;

  sanitize8BaseReconnectsFromObjects(contributionData, 'initiatives');
  sanitize8BaseReferenceFromObject(contributionData, 'source');
  normalize8baseDocumentsDeleteAndUpdate(contributionData, 'documents', originalContributionData);
  sanitize8BaseBigInt(contributionData, 'unitMonetizationFactor');

  const {
    data: { contributionUpdate },
  } = await client.mutate({
    mutation: CONTRIBUTION_UPDATE_QUERY,
    variables: { data: contributionData },
  });

  // Update Items
  const data = {
    id: itemId,
    itemsRelated: {
      reconnect: relatedItems.map((i) => ({ id: i.itemId })),
    },
  };
  console.log('fundingRequestData:update:relatedItems:', data);
  await client.mutate({
    mutation: RELATED_ITEM_UPDATE_MUTATION,
    variables: { data },
  });

  return contributionUpdate;
};

/**
 * Close a Contribution.
 *
 * @param {object}contributionData - Contribution Data.
 * @returns {Promise<void|*>} Promise.
 */
export const completedContribution = async (contributionData) => {
  const client = sessionStore.getState(APOLLO_CLIENT);
  const { selectedAlliance, user } = sessionStore.getState(NEW_SESSION_EVENT);

  if (!canCompletedContribution(user, contributionData, selectedAlliance)) {
    OnContributionError.dispatch(
      new IntegrityError('You do not have permission to perform this action'),
    );

    return Flux.dispatchEvent(
      CONTRIBUTION_ERROR_EVENT,
      new IntegrityError('You do not have permission to perform this action'),
    );
  }

  let response;

  try {
    response = await client.mutate({
      mutation: CONTRIBUTION_CLOSE_MUTATION,
      variables: {
        data: {
          id: contributionData.id,
          status: CONTRIBUTION_COMPLETED,
        },
      },
    });
  } catch (e) {
    console.error('updateContribution', e, contributionData);
    OnContributionError.dispatch(e);
    return Flux.dispatchEvent(CONTRIBUTION_ERROR_EVENT, e);
  }

  OnContributionCompleted.dispatch(response.data);
  Flux.dispatchEvent(CONTRIBUTION_COMPLETED_EVENT, response.data);
  return response.data;
};

/**
 * Restore contribution.
 *
 * @param {object}contributionData - Contribution.
 * @returns {Promise<void|*>} Return promise.
 */
export const restoreContribution = async (contributionData) => {
  const client = sessionStore.getState(APOLLO_CLIENT);
  const { selectedAlliance, user } = sessionStore.getState(NEW_SESSION_EVENT);

  if (!canRestoreContribution(user, contributionData, selectedAlliance)) {
    OnContributionError.dispatch(
      new IntegrityError('You do not have permission to perform this action'),
    );

    return Flux.dispatchEvent(
      CONTRIBUTION_ERROR_EVENT,
      new IntegrityError('You do not have permission to perform this action'),
    );
  }

  let response;

  try {
    response = await client.mutate({
      mutation: CONTRIBUTION_CLOSE_MUTATION,
      variables: {
        data: {
          id: contributionData.id,
          status: CONTRIBUTION_OPEN,
        },
      },
    });
  } catch (e) {
    console.error('updateContribution', e, contributionData);
    OnContributionError.dispatch(e);
    return Flux.dispatchEvent(CONTRIBUTION_ERROR_EVENT, e);
  }

  OnContributionRestore.dispatch(response.data);
  Flux.dispatchEvent(CONTRIBUTION_RESTORE_EVENT, response.data);
  return response.data;
};
