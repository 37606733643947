import Flux from '@cobuildlab/flux-state';

/**
 * Event that saves filters for Sales Pipeline Trends.
 */
export const BUDGET_VS_FORECAST_FILTERS_EVENT = 'budgetVsForecastFilters';

/**
 * Event that sends the forecasting data for the form.
 */
export const FORECASTING_DATA_EVENT = 'onForecastingDataEvent';

/**
 * FORECASTING SAVED EVENT.
 *
 * @type {string}
 */
export const FORECASTING_SAVED_EVENT = 'onForecastingSavedEvent';

const INITIAL_STATE = {
  filters: [],
};

export const FORECASTING_EVENT = 'onForecastingEvent';

export const FORECASTING_ERROR_EVENT = 'onForecastingErrorEvent';

/**W
 * Hold the Active Sales Pipeline data.
 */
class ForecastingStore extends Flux.DashStore {
  constructor() {
    super();
    this.addEvent(BUDGET_VS_FORECAST_FILTERS_EVENT);
    this.addEvent(FORECASTING_EVENT);
    this.addEvent(FORECASTING_DATA_EVENT);
    this.addEvent(FORECASTING_ERROR_EVENT);
    this.addEvent(FORECASTING_SAVED_EVENT);
    Flux.dispatchEvent(BUDGET_VS_FORECAST_FILTERS_EVENT, INITIAL_STATE);
  }
}

const forecastingStore = new ForecastingStore();
export default forecastingStore;
export { forecastingStore };
