import React from 'react';
import PropTypes from 'prop-types';
import { Text } from '@8base/boost';

const DropdownUserText = ({ text, fontSize, color, fontWeight }) => {
  return (
    <div>
      <Text style={{ fontSize, color, fontWeight }}>{text}</Text>
    </div>
  );
};

DropdownUserText.propTypes = {
  text: PropTypes.string,
  fontSize: PropTypes.number,
  color: PropTypes.string,
  fontWeight: PropTypes.string,
};

DropdownUserText.defaultProps = {
  text: '',
  fontSize: 18,
  color: '#878C93',
  fontWeight: 400,
};

export default DropdownUserText;
