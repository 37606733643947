import React from 'react';
import { Button, Icon, Dropdown, Menu } from '@8base/boost';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

const StyledButton = styled(Button)`
  width: 46px;
  border: solid 1px #e3e9ef;
`;

const ScorecardOptionsButton = ({ onDetailClick }) => {
  return (
    <Dropdown defaultOpen={false}>
      <Dropdown.Head>
        <StyledButton color="neutral" variant="outlined" squared>
          <Icon color="GRAY5" name="More" />
        </StyledButton>
      </Dropdown.Head>
      <Dropdown.Body withPortal placement="bottom" pin="right">
        {({ closeDropdown }) => (
          <Menu>
            <Menu.Item
              onClick={() => {
                closeDropdown();
                onDetailClick();
              }}>
              Details
            </Menu.Item>
          </Menu>
        )}
      </Dropdown.Body>
    </Dropdown>
  );
};

ScorecardOptionsButton.defaultProps = {
  onDetailClick: () => {},
};

ScorecardOptionsButton.propTypes = {
  onDetailClick: PropTypes.func,
};

export { ScorecardOptionsButton };
