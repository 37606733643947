import sessionStore, { APOLLO_CLIENT, NEW_SESSION_EVENT } from '../../../shared/SessionStore';
import { initiativeFilter } from '../../management/initiative/initiative-actions';
import {
  BALANCED_SCORECARD_DATA_EVENT,
  BALANCED_SCORECARD_ERROR_EVENT,
  FINANCIAL_SCORECARD_DATA_EVENT,
  STRATEGY_SCORECARD_DATA_EVENT,
  RELATIONSHIP_SCORECARD_DATA_EVENT,
  OPERATIONAL_SCORECARD_DATA_EVENT,
} from './balanced-scorecard-store';
import { setDefaultKPIs } from './balanced-scorecard-utils';
import { ideaFilter } from '../../management/idea/idea-actions';
import { investmentItemsFilter } from '../../management/investment-item/investment-item-actions';
import { dealFilter } from '../../management/deal/deal-actions';
import Flux from '@cobuildlab/flux-state';
import { chartsMonths } from '../utils';
import {
  BOOKINGS_CLIENT_TYPE,
  BOOKINGS_PARTNER_TYPE,
  CONTRIBUTIONS_CLIENT_TYPE,
  CONTRIBUTIONS_PARTNER_TYPE,
  JOINT_INITIATIVES_TYPE,
  JOINT_INNOVATION_IDEAS_TYPE,
  JOINT_PROPOSALS_ISSUED_TYPE,
  JOINT_PROPOSALS_WON_TYPE,
  JOINT_REFERENCEABLE_CLIENTS_TYPE,
  JOINT_TRAINING_INVESTMENT_TYPE,
  PIPELINE_CLIENT_TYPE,
  PIPELINE_PARTNER_TYPE,
} from '../../settings/alliance-management/allianceKPIs/allianceKPIs-model';
import moment from 'moment';
import { DEFAULT_MONTHS_RANGE } from './balanced-scorecard-model';
import {
  SCORECARD_FINANCIAL_DETAIL_QUERY,
  SCORECARD_STRATEGY_DETAIL_QUERY,
  SCORECARD_RELATIONSHIP_DETAIL_QUERY,
  SCORECARD_OPERATIONAL_DETAIL_QUERY,
  BALANCED_SCORECARD_QUERY,
} from './balanced-scorecard-queries';
import { error } from '@cobuildlab/pure-logger';
import { OnBalancedScorecardData, OnBalancedScorecardError } from './balanced-scorecard-events';

/**
 * Fetches the Initiative & ideas list.
 *
 * @param {number}year - Year.
 * @returns {Promise}Promise.
 */
export const fetchBalancedScorecardData = async (year = moment().year()) => {
  const client = sessionStore.getState(APOLLO_CLIENT);
  const { selectedAlliance } = sessionStore.getState(NEW_SESSION_EVENT);

  if (!selectedAlliance) return;
  const allianceId = selectedAlliance.id;

  const yearContributionsFilter = { year, fieldName: 'contributionDate', isDateTime: false };
  const yearApprovedFilter = { year, fieldName: 'dateOfResponse', isDateTime: true };

  const initiativesFilter = initiativeFilter({ allianceId, filterYear: yearApprovedFilter });

  // Items Filters
  const ideasFilter = ideaFilter({ allianceId, filterYear: yearApprovedFilter });
  const investmentsFilter = investmentItemsFilter({
    allianceId,
    filterYear: yearContributionsFilter,
  });

  const contributionsFilter = investmentsFilter.OR[0];
  const dealsFilter = dealFilter({ allianceId });

  const itemsFilter = {
    alliance: { id: { equals: allianceId } },
    OR: [{ idea: ideasFilter.idea }, contributionsFilter],
  };

  let responses;
  try {
    responses = await client.query({
      query: BALANCED_SCORECARD_QUERY,
      fetchPolicy: 'network-only',
      variables: {
        allianceId,
        initiativesFilter,
        itemsFilter,
        dealsFilter,
        first: 1000,
      },
    });
  } catch (e) {
    OnBalancedScorecardError.dispatch(e);
    Flux.dispatchEvent(BALANCED_SCORECARD_ERROR_EVENT, e);
    throw e;
  }

  const {
    alliance,
    initiativesList: { items: initiatives },
    itemsList: { items },
    dealDataList: { items: deals },
  } = responses.data;

  const {
    allianceKPIAllianceRelation: { items: kpis },
  } = alliance;

  const contributions = items.filter((item) => item.contribution);
  const ideas = items.filter((item) => item.ideas);

  setDefaultKPIs(kpis, year);
  const data = {
    kpis,
    initiatives,
    ideas,
    contributions,
    deals,
    alliance,
  };
  OnBalancedScorecardData.dispatch(data);
  Flux.dispatchEvent(BALANCED_SCORECARD_DATA_EVENT, data);
  return data;
};

/**
 * Fetches data for the ScorecardFinancialDetail component.
 *
 * @param {number}year - Year.
 * @returns {Promise}Promise.
 */
export const fetchFinancialDetailScorecardData = async (year = moment().year()) => {
  const { selectedAlliance } = sessionStore.getState(NEW_SESSION_EVENT);

  if (!selectedAlliance) return;
  const allianceId = selectedAlliance.id;

  const yearContributionsFilter = {
    year,
    fieldName: 'contributionDate',
    isDateTime: false,
  };

  const contFilter = investmentItemsFilter({
    allianceId,
    search: '',
    filterParam: null,
    itemFilter: null,
    filterYear: yearContributionsFilter,
  });
  const _dealFilter = dealFilter({
    allianceId,
    stage: null,
    companyId: null,
  });

  const client = sessionStore.getState(APOLLO_CLIENT);
  let response;
  try {
    response = await client.query({
      query: SCORECARD_FINANCIAL_DETAIL_QUERY,
      variables: { id: allianceId, contFilter, dealFilter: _dealFilter, skip: 0, first: 1000 },
      fetchPolicy: 'network-only',
    });
  } catch (e) {
    error('fetchFinancialDetailScorecardData', e);
    Flux.dispatchEvent(BALANCED_SCORECARD_ERROR_EVENT, e);
    throw e;
  }

  const {
    data: {
      alliance,
      itemsList: { items: investmentItems },
      dealDataList: { items: deals },
    },
  } = response;

  const {
    allianceKPIAllianceRelation: { items: kpis },
  } = alliance;
  const contributions = investmentItems.filter((item) => item.contribution);

  setDefaultKPIs(kpis, year);
  const data = {
    kpis,
    contributions,
    deals,
    alliance,
  };
  Flux.dispatchEvent(FINANCIAL_SCORECARD_DATA_EVENT, data);
  return data;
};

/**
 * Fetches data for the ScorecardStrategyDetail component.
 *
 * @param {number}year - Year.
 * @returns {Promise}Promise.
 */
export const fetchStrategyDetailScorecardData = async (year = moment().year()) => {
  const { selectedAlliance } = sessionStore.getState(NEW_SESSION_EVENT);
  if (!selectedAlliance) return;
  const allianceId = selectedAlliance.id;

  const yearApprovedFilter = {
    year,
    fieldName: 'dateOfResponse',
    isDateTime: true,
  };

  const initFilter = initiativeFilter({
    allianceId,
    search: '',
    status: null,
    filterYear: yearApprovedFilter,
  });
  const _ideaFilter = ideaFilter(allianceId, '', null, yearApprovedFilter);

  const client = sessionStore.getState(APOLLO_CLIENT);
  let response;
  try {
    response = await client.query({
      query: SCORECARD_STRATEGY_DETAIL_QUERY,
      variables: { id: allianceId, initFilter, ideaFilter: _ideaFilter, skip: 0, first: 1000 },
      fetchPolicy: 'network-only',
    });
  } catch (e) {
    error('fetchStrategyDetailScorecardData', e);
    Flux.dispatchEvent(BALANCED_SCORECARD_ERROR_EVENT, e);
    throw e;
  }

  const {
    data: {
      alliance,
      initiativesList: { items: initiatives },
      itemsList: { items: ideas },
    },
  } = response;

  const {
    allianceKPIAllianceRelation: { items: kpis },
  } = alliance;

  setDefaultKPIs(kpis, year);
  const data = {
    kpis,
    initiatives,
    ideas,
    alliance,
  };
  Flux.dispatchEvent(STRATEGY_SCORECARD_DATA_EVENT, data);
  return data;
};

/**
 * Fetches data for the ScorecardRelationshipDetail component.
 *
 * @param {number}year - Year.
 * @returns {Promise}Promise.
 */
export const fetchRelationshipDetailScorecardData = async (year = moment().year()) => {
  const { selectedAlliance } = sessionStore.getState(NEW_SESSION_EVENT);

  if (!selectedAlliance) return;
  const allianceId = selectedAlliance.id;

  const yearContributions = {
    year,
    fieldName: 'contributionDate',
    isDateTime: false,
  };

  const contFilter = investmentItemsFilter({
    allianceId,
    search: '',
    filterParam: null,
    itemFilter: null,
    filterYear: yearContributions,
  });

  const _dealFilter = dealFilter({
    allianceId,
    stage: null,
    companyId: null,
  });

  const client = sessionStore.getState(APOLLO_CLIENT);
  let response;
  try {
    response = await client.query({
      query: SCORECARD_RELATIONSHIP_DETAIL_QUERY,
      variables: { id: allianceId, contFilter, dealFilter: _dealFilter, skip: 0, first: 1000 },
      fetchPolicy: 'network-only',
    });
  } catch (e) {
    error('fetchRelationshipDetailScorecardData', e);
    Flux.dispatchEvent(BALANCED_SCORECARD_ERROR_EVENT, e);
    throw e;
  }

  const {
    data: {
      alliance,
      itemsList: { items: investmentItems },
      dealDataList: { items: deals },
    },
  } = response;

  const {
    allianceKPIAllianceRelation: { items: kpis },
  } = alliance;
  const contributions = investmentItems.filter((item) => item.contribution);

  setDefaultKPIs(kpis, year);
  const data = {
    kpis,
    contributions,
    deals,
    alliance,
  };
  Flux.dispatchEvent(RELATIONSHIP_SCORECARD_DATA_EVENT, data);
  return data;
};

/**
 * Fetches data for the ScorecardOperationalDetail component.
 *
 * @param {number}year - Year.
 * @returns {Promise}Promise.
 */
export const fetchOperationalDetailScorecardData = async (year = moment().year()) => {
  const { selectedAlliance } = sessionStore.getState(NEW_SESSION_EVENT);

  if (!selectedAlliance) return;
  const allianceId = selectedAlliance.id;

  const _dealFilter = dealFilter({
    allianceId,
    stage: null,
    companyId: null,
  });

  const client = sessionStore.getState(APOLLO_CLIENT);
  let response;
  try {
    response = await client.query({
      query: SCORECARD_OPERATIONAL_DETAIL_QUERY,
      variables: { id: allianceId, dealFilter: _dealFilter },
      fetchPolicy: 'network-only',
    });
  } catch (e) {
    error('fetchOperationalDetailScorecardData', e);
    Flux.dispatchEvent(BALANCED_SCORECARD_ERROR_EVENT, e);
    throw e;
  }

  const {
    data: {
      alliance,
      dealDataList: { items: deals },
    },
  } = response;

  const {
    allianceKPIAllianceRelation: { items: kpis },
  } = alliance;

  setDefaultKPIs(kpis, year);
  const data = {
    kpis,
    deals,
    alliance,
  };
  Flux.dispatchEvent(OPERATIONAL_SCORECARD_DATA_EVENT, data);
  return data;
};

export const getChartKeys = (kpiType) => {
  let dataKey = 'data';
  let dataKeyAccumulator = 'accumulator';
  if (kpiType === JOINT_INNOVATION_IDEAS_TYPE) {
    dataKey = 'ideas';
    dataKeyAccumulator = 'accumulatorIdeas';
  } else if (kpiType === JOINT_INITIATIVES_TYPE) {
    dataKey = 'initiatives';
    dataKeyAccumulator = 'accumulatorInitiatives';
  } else if (kpiType === JOINT_TRAINING_INVESTMENT_TYPE) {
    dataKey = 'investment';
    dataKeyAccumulator = 'accumulatorInvestment';
  } else if (kpiType === JOINT_REFERENCEABLE_CLIENTS_TYPE) {
    dataKey = 'referenceable';
    dataKeyAccumulator = 'accumulatorReferenceable';
  } else if (kpiType === PIPELINE_PARTNER_TYPE || kpiType === PIPELINE_CLIENT_TYPE) {
    dataKey = 'pipeline';
    dataKeyAccumulator = 'accumulatorPipeline';
  } else if (kpiType === JOINT_PROPOSALS_ISSUED_TYPE) {
    dataKey = 'issued';
    dataKeyAccumulator = 'accumulatorIssued';
  } else if (kpiType === JOINT_PROPOSALS_WON_TYPE) {
    dataKey = 'won';
    dataKeyAccumulator = 'accumulatorWon';
  } else if (kpiType === BOOKINGS_CLIENT_TYPE || kpiType === BOOKINGS_PARTNER_TYPE) {
    dataKey = 'booked';
    dataKeyAccumulator = 'accumulatorBooked';
  } else if (kpiType === CONTRIBUTIONS_CLIENT_TYPE || kpiType === CONTRIBUTIONS_PARTNER_TYPE) {
    dataKey = 'contribution';
    dataKeyAccumulator = 'accumulatorContribution';
  }
  return { dataKey, dataKeyAccumulator };
};

export const createChartData = (
  monthByMonthCurrent,
  monthByMonthAccumulator,
  type,
  monthsRange = DEFAULT_MONTHS_RANGE,
) => {
  const { dataKey, dataKeyAccumulator } = getChartKeys(type);

  return chartsMonths(monthsRange).map((opt, index) => {
    return {
      ...opt,
      [dataKey]: monthByMonthCurrent[index].toString(),
      [dataKeyAccumulator]: monthByMonthAccumulator[index],
    };
  });
};
