import React from 'react';
import PropTypes from 'prop-types';
import Moment from 'react-moment';

const DateText = (props) => {
  const { date, text } = props;

  return (
    <>
      {date ? (
        <Moment format="MMMM Do, YYYY">{date}</Moment>
      ) : (
        <span style={{ color: 'lightgrey' }}>{text}</span>
      )}
    </>
  );
};

DateText.propTypes = {
  date: PropTypes.string,
  text: PropTypes.string,
};

DateText.defaultProps = {
  date: null,
  text: 'Not Available',
};

export { DateText };
