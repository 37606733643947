import * as R from 'ramda';
import { SchemaNameGenerator } from '@8base/schema-name-generator';
import { getFullNameWithEmail } from '../../utils';
import { getFullCompanyName } from '../../utils';

const getRelationConditionalOprions = (data, itemType, fieldName) => {
  console.log('getRelationConditionalOprions:data', data);
  console.log('getRelationConditionalOprions:itemType', itemType);
  console.log('getRelationConditionalOprions:fieldName', fieldName);
  const listName = SchemaNameGenerator.getTableListFieldName(itemType);
  switch (fieldName) {
  case 'owner':
  case 'assignedTo':
  case 'requestedBy':
  case 'createdBy': {
    return sortSelectOption(getUserOptions(data, listName, fieldName));
  }
  case 'initiatives': {
    return sortSelectOption(getInitiativesOptions(data, listName));
  }
  case 'company':
  case 'source': {
    return sortSelectOption(getSourcesOptions(data, listName, fieldName));
  }
  case 'dealSourceId':
  case 'stage':
  case 'description':
  case 'associatedDealId':
  case 'customerName':
  case 'type':
  case 'unitValueDescription':
  case 'salesPerson':
  case 'nextActivityDescription':
  case 'line':
  case 'partnerRegistrationId':
  case 'dealReferencable':
  case 'numberOfSalesActivities':
  case 'numberOfTimesContacted':
  case 'wonReason':
  case 'lostReason':
  case 'name': {
    return sortSelectOption(getFieldOptions(data, listName, fieldName));
  }
  default: {
    return [];
  }
  }
};

const getFieldDict = (data, listName, fieldName) => {
  const optionSet = new Set();
  return R.pathOr([], [listName, 'items'], data).reduce((dict, item, index) => {
    if (!optionSet.has(item[fieldName]) && item[fieldName]) {
      dict[index] = item[fieldName];
      optionSet.add(item[fieldName]);
    }
    return dict;
  }, {});
};

const getFieldOptions = (data, listName, fieldName) => {
  const fieldDict = getFieldDict(data, listName, fieldName);
  // convert the dictionary to an array of options for the Select Field
  return Object.keys(fieldDict).map((i) => ({
    label: fieldDict[i],
    value: fieldDict[i],
  }));
};
const getCompaniesDict = (data, listName, fieldName) => {
  // creata a dictionary with companies to remove duplicate companies
  return R.pathOr([], [listName, 'items'], data).reduce((dict, item) => {
    const itemId = R.path([fieldName, 'id'], item);
    if (itemId) {
      dict[item[fieldName].id] = item[fieldName];
    }
    return dict;
  }, {});
};

const getInititativesDict = (data, listName) => {
  // creata a dictionary with initiatives to remove duplicate initiatives
  return R.pathOr([], [listName, 'items'], data)
    .reduce((array, item) => {
      return [...array, ...item.initiatives.items];
    }, [])
    .reduce((dict, initiative) => {
      dict[initiative.id] = initiative;
      return dict;
    }, {});
};

const getUsersDict = (data, listName, fieldName) => {
  // creata a dictionary with users to remove duplicate users
  return R.pathOr([], [listName, 'items'], data).reduce((dict, item) => {
    const itemId = R.path([fieldName, 'id'], item);
    if (itemId) {
      dict[item[fieldName].id] = item[fieldName];
    }
    return dict;
  }, {});
};

const getInitiativesOptions = (data, listName) => {
  // creata a dictionary to remove duplicate
  const initiativesDict = getInititativesDict(data, listName);
  // convert the dictionary to an array of options for the Select Field
  return Object.keys(initiativesDict).map((initiativeId) => ({
    label: initiativesDict[initiativeId].name,
    value: initiativeId,
  }));
};

const getUserOptions = (data, listName, fieldName) => {
  // creata a dictionary to remove duplicate
  const usersDict = getUsersDict(data, listName, fieldName);
  // convert the dictionary to an array of options for the Select Field
  return Object.keys(usersDict).map((userId) => ({
    label: getFullNameWithEmail(usersDict[userId]),
    value: userId,
  }));
};

const getSourcesOptions = (data, listName, fieldName) => {
  // creata a dictionary to remove duplicate
  const sourcesDict = getCompaniesDict(data, listName, fieldName);
  // convert the dictionary to an array of options for the Select Field
  return Object.keys(sourcesDict).map((sourceId) => ({
    label: getFullCompanyName(sourcesDict[sourceId]),
    value: sourceId,
  }));
};

const sortSelectOption = (options) => {
  // Sort options by Alphaben
  return options.sort((a, b) => {
    if (a.label > b.label) {
      return 1;
    }
    if (a.label < b.label) {
      return -1;
    }
    return 0;
  });
};

export { getRelationConditionalOprions };
