import PropTypes from 'prop-types';
import React from 'react';
import { Small1 } from './new-ui/font-style/Small1';
import { validateSubmitForApprovalItem } from '../shared/utils';
import styled from '@emotion/styled';

const Container = styled('div')`
  width: 600px;
  text-align: left;
  position: relative;
  top: -15px;
`;

const NoteSubmitForApprovalText = ({ item }) => {
  const validations = validateSubmitForApprovalItem(item);

  let allianceValidatorText = '';
  let initiativeUnapprovedText = '';
  let userAdminOrSERValidatorText = '';

  if (validations.allianceCompleted) {
    allianceValidatorText = <Small1>{`- The Alliance can't be completed.`}</Small1>;
  }

  if (validations.initiativesUnapproved) {
    initiativeUnapprovedText = (
      <Small1>{` - The Item can't be submitted for approval because it's related to unapproved initiatives.`}</Small1>
    );
  }
  if (!validations.userAdminOrSER) {
    userAdminOrSERValidatorText = (
      <Small1>{` - Only Alliance Administrator or Alliance SER.`}</Small1>
    );
  }

  return (
    <Container>
      <div>{allianceValidatorText}</div>
      <div>{initiativeUnapprovedText}</div>
      <div>{userAdminOrSERValidatorText}</div>
    </Container>
  );
};

NoteSubmitForApprovalText.propTypes = {
  item: PropTypes.object.isRequired,
};

export { NoteSubmitForApprovalText };
