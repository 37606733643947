import React, { useCallback } from 'react';
import { Dropdown, Menu, Text } from '@8base/boost';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AllianceDropdownText } from './AllianceDropdownText';
import { Loader } from '@8base/boost';

const DropDownText = () => {
  return (
    <span style={{ transform: 'translateX(-25px)' }}>
      <Text>
        Select an Alliance <FontAwesomeIcon icon="caret-down" />
      </Text>
    </span>
  );
};

const AllianceDropdown = (props) => {
  const onSelectAlliance = useCallback((alliance) => {
    props.onSelect(alliance);
  });

  const { selectedAlliance, items } = props;
  let selectedText = <Text style={{ color: 'lightgrey' }}>No Alliances to Select</Text>;
  if (items.length > 0) selectedText = <DropDownText />;

  if (selectedAlliance != null)
    selectedText = (
      <>
        {selectedAlliance.name} &nbsp; <FontAwesomeIcon icon="caret-down" />
      </>
    );

  return (
    <>
      {props.loading ? (
        <Loader size={'sm'} />
      ) : (
        <Dropdown defaultOpen={false}>
          <Dropdown.Head>{selectedText}</Dropdown.Head>
          <Dropdown.Body pin="center">
            {({ closeDropdown }) => (
              <Menu style={{ maxHeight: '400px', overflowY: 'auto' }}>
                {items.map((item, index) => (
                  <Menu.Item
                    key={index}
                    onClick={() => {
                      onSelectAlliance(item);
                      closeDropdown();
                    }}>
                    <AllianceDropdownText alliance={item} />
                  </Menu.Item>
                ))}
              </Menu>
            )}
          </Dropdown.Body>
        </Dropdown>
      )}
    </>
  );
};

AllianceDropdown.propTypes = {
  onSelect: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  selectedAlliance: PropTypes.object,
  items: PropTypes.array.isRequired,
};

AllianceDropdown.defaultProps = {
  selectedAlliance: null,
  loading: false,
};

export default AllianceDropdown;
