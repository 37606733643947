import styled, { css } from 'styled-components';

const Table = styled.table`
  width: 100%;
  text-align: left;
  ${({ borderTop }) =>
    borderTop &&
    css`
      border-top: 1px solid #e9eff4;
    `}
`;

const thStyles = css`
  color: #7e828b;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0.5px;
  line-height: 18px;
  text-transform: uppercase;
  background-color: #f4f7f9;
  ${({ rightBorder }) =>
    rightBorder &&
    css`
      border-right: 1px solid #e9eff4;
    `}
`;

const TD = styled.td`
  color: #323c47;
  font-size: 13px;
  line-height: 20px;
  vertical-align: middle;
  ${({ rightBorder }) =>
    rightBorder &&
    css`
      border-right: 1px solid #e9eff4;
    `};
`;

const CellWrap = styled.div`
  padding: 14px 26px;
  ${({ textAlign = 'left' }) => css`
    text-align: ${textAlign};
  `}
`;

const TotalCellWrap = styled.div`
  padding: 0 26px;
  ${({ textAlign = 'left' }) => css`
    text-align: ${textAlign};
  `}
`;

const InitiativesCellWrap = styled(CellWrap)`
  display: flex;
  flex-wrap: wrap;
  padding: 14px 26px 4px 26px;
  & > span {
    margin-bottom: 10px;
    margin-right: 10px;
  }
`;

const TableHeader = styled.thead`
  ${({ fixed }) =>
    fixed &&
    css`
      th {
        position: sticky;
        top: 0;
        padding: 0;
        z-index: 1;
        div {
          padding-top: 15px;
          padding-bottom: 15px;
          border-bottom: 1px solid #e9eff4;
        }
      }
    `}
`;

const TR = styled.tr`
  border-bottom: 1px solid #e9eff4;
`;

const TotalTD = styled.td`
  padding: 3px 0;
  ${thStyles}
  color: #323c47;
  font-size: 13px;
  font-weight: 600;
  line-height: 18px;
  padding: 4px 0 2px 0;
`;

const TH = styled.th`
  white-space: nowrap;
  ${thStyles}
`;

const GroupSection = styled.div`
  box-sizing: border-box;
  border: 1px solid #e9eff4;
  border-radius: 5px;
  background-color: #ffffff;
  margin: 24px 24px 33px 24px;
  overflow: auto;
`;

const GroupTitle = styled('h3')`
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.5px;
  line-height: 21px;
  margin: 13px 0 12px 25px;
  text-align: left;
`;

export {
  Table,
  CellWrap,
  TD,
  TotalTD,
  TH,
  TR,
  TableHeader,
  GroupSection,
  GroupTitle,
  InitiativesCellWrap,
  TotalCellWrap,
};
