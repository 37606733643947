import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { NavLink as RouterNavLink } from 'react-router-dom';
import { withRouter } from 'react-router';

const NavLink = styled(RouterNavLink)`
  padding: 0 12px;
  margin-right: 4px;
  background: #fbfcfc;
  font-size: 14px;
  height: 32px;
  color: #939ea7;
  cursor: pointer;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  text-decoration: none;

  ${({ isActiveTab }) => {
    return (
      isActiveTab &&
      css`
        position: relative;
        color: #323c47 !important;
        background: #fff;
        box-shadow: 0 1px 3px 0 rgba(50, 50, 93, 0.14), 0 4px 6px 0 rgba(112, 157, 199, 0.08);
        cursor: default;

        &:after {
          content: '';
          display: block;
          height: 1px;
          background: #fff;
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          z-index: 10;
        }
      `
    );
  }}
`;

const Icon = styled.img`
  width: 25px;
  height: 25px;
  margin-right: 5px;
  opacity: ${({ isActiveTab }) => (isActiveTab ? 1 : 0.5)};
`;

class TabComponent extends React.Component {
  render() {
    const {
      to,
      label,
      icon,
      history: {
        location: { pathname },
      },
    } = this.props;
    const isActiveTab = pathname === to;

    return (
      <NavLink to={to} isActiveTab={isActiveTab}>
        <Icon src={icon} isActiveTab={isActiveTab} />
        {label}
      </NavLink>
    );
  }
}

const Tab = withRouter(TabComponent);

TabComponent.propTypes = {
  to: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  history: PropTypes.any.isRequired,
};

export { Tab };
