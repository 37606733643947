import React, { Fragment } from 'react';
import {
  Button,
  Card,
  Column,
  Grid,
  InputField,
  Loader,
  Paragraph,
  Row,
  SelectField,
  Text,
  TextAreaField,
} from '@8base/boost';
import { CardFooter } from '../components/CardFooter';
import PropTypes from 'prop-types';
import sessionStore, { NEW_SESSION_EVENT } from '../../../shared/SessionStore';
import {
  createAllianceWizard,
  fetchRemainingAlliancesOnPlan,
} from '../../alliance/alliance-actions';
import { View } from '@cobuildlab/react-simple-state';
import CenterParagraph from '../../../components/CenterParagraph';
import { ActionButton } from '../../../components/buttons/ActionButton';
import * as R from 'ramda';
import { onErrorMixin } from '../../../shared/mixins';
import { fetchSession } from '../../auth/auth.actions';
import { ACCEPT_ALLIANCE_INVITATION } from '../../settings/invitations';
import { acceptAllianceInvitation } from '../../settings/invitations/invitations.actions';
import ErrorDialog from '../../../components/dialogs/ErrorDialog';
import YesNoDialog from '../../../components/dialogs/YesNoDialog';
import Status from '../../../components/Status';
import { DetailViewCardBody } from '../../../components/card/DetailViewCardBody';
import DetailValue from '../../../components/DetailValue';
import { withRouter } from 'react-router-dom';
import { fetchAllianceFormData } from '../../settings/alliance-management/alliance-actions';
import { getMyAllCompanyUsers } from '../../settings/company-management/company-actions';
import * as toast from '../../../components/toast/Toast';
import { ALLIANCE_INVITATION_PENDING } from '../../../shared/status';
import { updateUser } from '../wizard.actions';
import { isCompanyActive } from '../../procurer/procurer-utils';
import { SwitchField } from '../../../components/switch/SwitchField';
import { HorizontalLine } from '../../../components/new-ui/text/HorizontalLine';
import { OnWizardError, OnAllianceInformationCreate } from '../wizard-events';
import { OnSessionError } from '../../../shared/session-events';
import {
  OnRemainingAlliances,
  OnAllianceFormData,
} from '../../settings/alliance-management/alliance-events';
import {
  OnAllianceInvitationNoCompanyError,
  OnAllianceInvitationUpdated,
} from '../../settings/invitations/invitations-events';

class AllianceInformation extends View {
  constructor(props) {
    super(props);
    this.state = {
      data: { name: '', description: '', invitationEmail: '', currency: '' },
      invitation: {},
      yesNoDialogIsOpen: false,
      yesNoDialogTitle: false,
      yesNoDialogDescription: false,
      noCompanyErrorDialogIsOpen: false,
      loading: true,
      currencies: [],
      remainingAlliances: null,
    };

    this.onError = onErrorMixin.bind(this);
    this.companyUsers = getMyAllCompanyUsers();
  }

  componentDidMount() {
    this.subscribe(OnWizardError, this.onError);
    this.subscribe(OnSessionError, this.onError);

    this.subscribe(OnRemainingAlliances, (remainingAlliances) =>
      this.setState({ remainingAlliances }),
    );

    this.subscribe(OnAllianceInvitationNoCompanyError, (data) => {
      this.setState({ noCompanyErrorDialogIsOpen: true, loading: false });
    });

    this.subscribe(OnAllianceFormData, (state) => {
      this.setState({
        currencies: state.currenciesList.items,
        loading: false,
      });
    });

    this.subscribe(OnAllianceInvitationUpdated, async () => {
      await updateUser({ didCompleteWizard: true, didInviteAdmins: true });
      await fetchSession();
      this.props.history.push('/welcome');
    });

    this.subscribe(OnAllianceInformationCreate, async (result) => {
      console.log('DEBUG', result.data.allianceCreate.id);
      toast.success('Alliance Successfully Created');
      await updateUser({
        selectedAllianceId: result.data.allianceCreate.id,
        selectedAlliance: {
          connect: {
            id: result.data.allianceCreate.id,
          },
        },
      });
      this.props.nextScreen();
    });

    fetchAllianceFormData();
    fetchRemainingAlliancesOnPlan();
    this.checkAllianceInvitations();
  }

  // Checks that there is a pending invitation
  checkAllianceInvitations = async () => {
    const {
      allianceInvitationsList: { items: allianceInvitations },
    } = sessionStore.getState(NEW_SESSION_EVENT);

    let answeredAll = allianceInvitations.every(
      (invitation) => invitation.status !== ALLIANCE_INVITATION_PENDING,
    );
    // If there were invitations, and we answered them all, then the wizard is complete
    if (answeredAll && allianceInvitations.length > 0) {
      await updateUser({ didCompleteWizard: true, didInviteAdmins: true });
      await fetchSession();
      console.log(
        'Debbug: redirecting because have not allianceInvitations',
        allianceInvitations.length,
      );
      this.props.history.push('/welcome');
    }
  };

  handleChange = (name, value) => {
    const data = { ...this.state.data };
    data[name] = value;
    this.setState({ data });
  };

  handleSubmit = async () => {
    if (this.state.loading) return;
    const { data } = this.state;
    if (this.companyUsers.length > 0) {
      if (!isCompanyActive(this.companyUsers[0].company)) {
        this.skip();
      } else {
        this.setState({ loading: true });
        const allianceData = R.clone(data);
        createAllianceWizard(allianceData);
      }
    }
  };

  skip = () => {
    this.setState({ loading: true }, () => this.props.nextScreen());
  };

  onSelectForAccept = (invitation, name) => {
    this.setState({
      invitation,
      yesNoDialogIsOpen: true,
      yesNoDialogTitle: name,
      yesNoDialogDescription: ACCEPT_ALLIANCE_INVITATION,
    });
  };

  onAccept = () => {
    this.setState(
      {
        yesNoDialogIsOpen: false,
        loading: true,
      },
      () => {
        const invitation = R.clone(this.state.invitation);
        acceptAllianceInvitation(invitation, this.companyUsers[0]);
      },
    );
  };

  onClose = () => {
    this.setState({
      yesNoDialogIsOpen: false,
    });
  };

  onNoCompanyErrorDialogOk = () => {
    this.setState({ noCompanyErrorDialogIsOpen: false });
  };

  canContinue = () => {
    const { alliancesList } = sessionStore.getState(NEW_SESSION_EVENT);
    return isCompanyActive(this.companyUsers[0].company) || alliancesList.count;
  };

  render() {
    const { layout, prevScreen } = this.props;
    const {
      data,
      loading,
      yesNoDialogIsOpen,
      yesNoDialogTitle,
      yesNoDialogDescription,
      noCompanyErrorDialogIsOpen,
      currencies,
      remainingAlliances,
    } = this.state;
    const {
      name,
      description,
      invitationEmail,
      allianceInvitationRelation,
      currency,
      oneSided,
      partnerCompanyName,
    } = data;
    const isInvitationDisabled = allianceInvitationRelation && allianceInvitationRelation.length;
    const { items: allianceInvitations } = sessionStore.getState(
      NEW_SESSION_EVENT,
    ).allianceInvitationsList;

    let content = null;
    if (loading) return <Loader stretch />;

    if (allianceInvitations.length) {
      content = (
        <Grid.Box>
          <DetailViewCardBody>
            {allianceInvitations.map((invitation, index) => {
              const name = invitation.alliance.name;
              const status = invitation.alliance.status;
              const ownerName = invitation.alliance.owner
                ? `${invitation.alliance.owner.firstName} ${invitation.alliance.owner.lastName}`
                : '';
              const clientCompanyName = invitation.alliance.clientCompany
                ? invitation.alliance.clientCompany.name
                : '';
              const invitationFooter =
                invitation.status !== ALLIANCE_INVITATION_PENDING ? (
                  <Status status={invitation.status} />
                ) : (
                  <ActionButton
                    text={'Accept invitation'}
                    onClick={() => this.onSelectForAccept(invitation, invitation.alliance.name)}
                  />
                );

              return (
                <Fragment key={index}>
                  <table className="details">
                    <tbody>
                      <tr>
                        <th>Name</th>
                        <td>
                          <DetailValue text={name} />
                        </td>
                      </tr>
                      <tr>
                        <th>Status</th>
                        <td>
                          <Status status={status} />
                        </td>
                      </tr>
                      <tr>
                        <th>Owner</th>
                        <td>
                          <DetailValue text={ownerName} />
                        </td>
                      </tr>
                      <tr>
                        <th>Client Company</th>
                        <td>
                          <DetailValue text={clientCompanyName} />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <Row offsetY={'md'} justifyContent={'center'}>
                    {invitationFooter}
                  </Row>
                </Fragment>
              );
            })}
          </DetailViewCardBody>
        </Grid.Box>
      );
    } else {
      content = (
        <Card.Body style={{ paddingRight: '35px' }}>
          {remainingAlliances !== null && remainingAlliances <= 0 && (
            <>
              <p>
                Maximum number of alliances created. If you want to create more, please contact your
                Procurement Role.
              </p>
              <br />
            </>
          )}
          <Grid.Layout columns={layout}>
            <Grid.Box>
              <Column>
                <InputField
                  stretch
                  label="Alliance"
                  placeholder="Alliance Name"
                  input={{
                    name: 'name',
                    value: name,
                    onChange: (value, e) => this.handleChange(e.target.name, value),
                  }}
                />
                <TextAreaField
                  style={{ width: '100%', height: 152 }}
                  placeholder="Alliance Description"
                  label="Description"
                  input={{
                    name: 'description',
                    value: description,
                    onChange: (value, e) => this.handleChange(e.target.name, value),
                  }}
                />
                <SelectField
                  label="Currency"
                  input={{
                    name: 'currency',
                    value: currency,
                    onChange: (value, e) => this.handleChange('currency', value),
                  }}
                  meta={{}}
                  placeholder="Currency of your Alliance"
                  options={currencies.map((currency) => {
                    return { label: currency.shortName, value: currency.id };
                  })}
                />
              </Column>
            </Grid.Box>
            <Grid.Box />
            <Grid.Box>
              <CenterParagraph>Pre-Alliance</CenterParagraph>
              <Paragraph>
                Pre-alliances can perform all of Succeed&#39;s functions without having to
                immediately invite their alliance partner
              </Paragraph>
              <div>
                <SwitchField
                  label="Active"
                  id="switchPreAlliance"
                  input={{
                    name: 'pre-alliance',
                    value: oneSided,
                    onChange: (value) => this.handleChange('oneSided', value),
                  }}
                />
              </div>

              <HorizontalLine />

              {oneSided ? (
                <>
                  <CenterParagraph>Partner Company</CenterParagraph>
                  <Text>Enter the company name for partner of this alliance:</Text>
                  <br />
                  <InputField
                    disabled={isInvitationDisabled}
                    stretch
                    key={'Partner Company Name'}
                    label="Partner Company Name"
                    placeholder="Partner Company Name"
                    input={{
                      name: 'partnerCompanyName',
                      value: partnerCompanyName,
                      onChange: (value, e) => this.handleChange(e.target.name, value),
                    }}
                  />
                </>
              ) : (
                <>
                  <CenterParagraph>
                    Invite your Alliance Partner to the <strong>Succeed</strong> platform.
                  </CenterParagraph>
                  <Text>
                    Enter the email address for the main contact person for your Alliance Partner:
                  </Text>
                  <br />
                  <InputField
                    key={'Email Address'}
                    disabled={isInvitationDisabled}
                    stretch
                    label="Email Address"
                    placeholder="Email Address"
                    input={{
                      name: 'invitationEmail',
                      type: 'email',
                      value: invitationEmail,
                      onChange: (value, e) => this.handleChange(e.target.name, value),
                    }}
                  />
                </>
              )}
            </Grid.Box>
          </Grid.Layout>
        </Card.Body>
      );
    }

    return (
      <React.Fragment>
        {content}
        <CardFooter padding={'sm'}>
          <ActionButton
            disabled={!this.canContinue()}
            onClick={() => this.handleSubmit()}
            text="Continue"
            className={'space-around'}
          />
          <Button
            type="button"
            onClick={() => prevScreen()}
            color="neutral"
            variant="outlined"
            className={'space-around'}>
            {'Previous'}
          </Button>
          {this.canContinue() ? (
            <Button
              type="button"
              name="skip"
              onClick={this.skip}
              color="neutral"
              variant="outlined"
              className={'space-around'}>
              {'Skip'}
            </Button>
          ) : null}
        </CardFooter>
        <YesNoDialog
          isOpen={yesNoDialogIsOpen}
          onYes={this.onAccept}
          onNo={this.onClose}
          onClose={this.onClose}
          text={yesNoDialogDescription}
          title={yesNoDialogTitle}
        />
        <ErrorDialog
          isOpen={noCompanyErrorDialogIsOpen}
          onOk={this.onNoCompanyErrorDialogOk}
          text={'You must create a Company in order to accept Alliance Invitations'}
          okText={'Continue'}
          title={'Error accepting the Alliance Invitation'}
        />
      </React.Fragment>
    );
  }
}

AllianceInformation.propTypes = {
  layout: PropTypes.string.isRequired,
  currentScreen: PropTypes.number.isRequired,
  nextScreen: PropTypes.func.isRequired,
  prevScreen: PropTypes.func.isRequired,
};

export default withRouter(AllianceInformation);
