import {
  CREATE_META_MUTATION,
  UPDATE_USER_MUTATION,
  META_QUERY,
  ROLES_QUERY,
  UPDATE_META_MUTATION,
} from './queries';
import * as R from 'ramda';
import Flux from '@cobuildlab/flux-state';
import { META_ALLIANCE_SELECTED, META_SHOW_ON_BOARDING, META_STEP_NAME } from '../../shared';
import sessionStore, {
  APOLLO_CLIENT,
  META_EVENT,
  NEW_SESSION_EVENT,
  ROLES_EVENT,
  SESSION_ERROR,
  SESSION_META_EVENT,
  UPDATE_META_EVENT,
  USER_CREATED_EVENT,
} from '../../shared/SessionStore';
import { fetchAllAMOItems } from '../management/amo-item/amo-item-actions';
import { DASHBOARD_ERROR, DASHBOARD_ITEMS } from './dashboard-store';
import { pct } from '../../shared/utils';
import { PIE_OPTIONS } from './dashboard-models';
import { GREEN_COLOR, RED_COLOR, YELLOW_COLOR } from '../../shared/colors';
import moment from 'moment';
import { error, log } from '@cobuildlab/pure-logger';
import {
  AMO_ITEM_COMPLETED,
  AMO_ITEM_DUE_THIS_WEEK,
  AMO_ITEM_ON_TIME,
  AMO_ITEM_OVER_DUE,
} from '../../shared/status';
import { ITEM_ACTION, ITEM_ISSUE, ITEM_RISK } from '../../shared/items-util';
import { getDataItem } from '../reports/deals-funnel/deals-funnel-actions';
import { onDashboardError, onDashboardItems } from './dashboard-events';
import { OnSessionError, OnRoles } from '../../shared/session-events';

/**
 * Fetches the roles available in the System.
 *
 * @returns {Promise<void>}Promise.
 */
export const fetchRoles = async () => {
  const client = sessionStore.getState(APOLLO_CLIENT);
  let response;
  try {
    response = await client.query({ query: ROLES_QUERY, fetchPolicy: 'cache-first' });
  } catch (e) {
    console.error('fetchRoles', e);
    OnSessionError.dispatch(e);
    return Flux.dispatchEvent(SESSION_ERROR, e);
  }
  console.log('fetchRoles', response.data);
  OnRoles.dispatch(response.data.rolesList.items);
  Flux.dispatchEvent(ROLES_EVENT, response.data.rolesList.items);
  return response.data.rolesList.items;
};

/**
 * Fetches the current user meta information.
 *
 * @returns {Promise<void>}Promise.
 */
export const fetchMeta = async () => {
  const client = sessionStore.getState(APOLLO_CLIENT);
  const user = sessionStore.getState(NEW_SESSION_EVENT).user;
  let response;
  try {
    response = await client.query({
      query: META_QUERY,
      fetchPolicy: 'network-only',
      variables: { userId: user.id },
    });
  } catch (e) {
    OnSessionError.dispatch(e);
    Flux.dispatchEvent(SESSION_ERROR, e);
  }
  console.log('fetchMeta', response.data);
  Flux.dispatchEvent(SESSION_META_EVENT, response.data.metasList.items);
  return response.data.metasList.items;
};

/**
 * Creates a meta value in the API for the current User.
 *
 * @param {string}name - The name of the meta.
 * @param {string}value - The value of the meta.
 * @returns {Promise<void>}Promise.
 */
export const createMeta = async (name, value) => {
  const client = sessionStore.getState(APOLLO_CLIENT);
  const user = sessionStore.getState(NEW_SESSION_EVENT).user;
  const data = { name, value, user: { connect: { id: user.id } } };
  let response;
  try {
    response = await client.mutate({
      mutation: CREATE_META_MUTATION,
      variables: { data },
    });
  } catch (e) {
    OnSessionError.dispatch(e);
    return Flux.dispatchEvent(SESSION_ERROR, e);
  }
  log(`createMeta:`, response.data.metaCreate);
  Flux.dispatchEvent(META_EVENT, response.data.metaCreate);
  return response.data.metaCreate;
};

/**
 * Updates a meta value in the API for the current User.
 *
 *  @deprecated Use fields on User model better.
 * @param {string}name - The name of the meta.
 * @param {string}value - The value of the meta.
 * @param {boolean}dispatchEvents - If it should dispatch events.
 * @returns {Promise<void>}Promise.
 */
export const updateMeta = async (name, value, dispatchEvents = true) => {
  const metas = await fetchMeta();
  let metaToBeUpdated = metas.find((meta) => meta.name === name);
  console.log('updateMeta:', name, value, metas, metaToBeUpdated);

  if (!metaToBeUpdated) {
    log(`updateMeta: Non existing meta value, creating...`);
    metaToBeUpdated = await createMeta(name, value);
  }

  const client = sessionStore.getState(APOLLO_CLIENT);
  const data = { id: metaToBeUpdated.id, value: String(value) };
  let response;

  try {
    response = await client.mutate({
      mutation: UPDATE_META_MUTATION,
      variables: { data },
    });
  } catch (e) {
    console.error('updateMeta', e);
    if (dispatchEvents) {
      OnSessionError.dispatch(e);
      Flux.dispatchEvent(SESSION_ERROR, e);
    }

    return;
  }
  console.log('updateMeta', response.data);
  dispatchEvents && Flux.dispatchEvent(UPDATE_META_EVENT, response.data.metaUpdate);
  return response.data.metaUpdate;
};

/**
 * Creates The first data necessary for the User when it starts the session.
 *
 * @deprecated
 *
 * @param {number}step - The step in which the User should begin.
 * @returns {Promise<void>}Promise.
 */
export const initializeUser = async (step = 0) => {
  const client = sessionStore.getState(APOLLO_CLIENT);
  const { user } = sessionStore.getState(NEW_SESSION_EVENT);
  const data = {
    id: user.id,
    metaRelation: {
      create: [
        {
          name: META_STEP_NAME,
          value: String(step),
        },
        {
          name: META_ALLIANCE_SELECTED,
          value: '',
        },
        {
          name: META_SHOW_ON_BOARDING,
          value: '',
        },
      ],
    },
    userInformationRelation: {
      create: {},
    },
  };
  let response;
  try {
    response = await client.mutate({ mutation: UPDATE_USER_MUTATION, variables: { data } });
  } catch (e) {
    error('initializeUser', e);
    OnSessionError.dispatch(e);
    return Flux.dispatchEvent(SESSION_ERROR, e);
  }
  log('initializeUser', response);
  return Flux.dispatchEvent(USER_CREATED_EVENT, response.data.metaCreate);
};

/**
 * Fetches the Items for the Dashboard.
 *
 * @returns {Promise<void>}Promise.
 */
export const fetchDashboardData = async () => {
  const { selectedAlliance } = sessionStore.getState(NEW_SESSION_EVENT);

  if (!selectedAlliance || !selectedAlliance.id) {
    return;
  }

  const response = {};
  try {
    response.amoItems = await fetchAllAMOItems();
  } catch (e) {
    error('fetchDashboardData', e);
    onDashboardError.dispatch(e);
    Flux.dispatchEvent(DASHBOARD_ERROR, e);
    return;
  }
  log('fetchDashboardData', response);
  onDashboardItems.dispatch(response);
  Flux.dispatchEvent(DASHBOARD_ITEMS, response);
  return response;
};

export const getChartOptions = (items) => {
  let risksOverDue = 0,
    risks1Week = 0,
    risksRemainder = 0;
  let issuesOverDue = 0,
    issues1Week = 0,
    issuesRemainder = 0;
  let actionsOverDue = 0,
    actions1Week = 0,
    actionsRemainder = 0;

  items.forEach((item) => {
    if (item.type === `Risk`) {
      if (
        moment().isSameOrAfter(moment(item.revisedDueDate), 'day') &&
        item.status !== AMO_ITEM_COMPLETED
      ) {
        return risksOverDue++;
      }
      if (
        moment()
          .add(6, 'days')
          .isSameOrAfter(moment(item.revisedDueDate), 'day') &&
        item.status !== AMO_ITEM_COMPLETED
      ) {
        return risks1Week++;
      }
      if (
        moment()
          .add(7, 'days')
          .isSameOrBefore(moment(item.revisedDueDate), 'day') &&
        item.status !== AMO_ITEM_COMPLETED
      ) {
        return risksRemainder++;
      }
    }
    if (item.type === `Issue`) {
      if (
        moment().isSameOrAfter(moment(item.revisedDueDate), 'day') &&
        item.status !== AMO_ITEM_COMPLETED
      ) {
        return issuesOverDue++;
      }
      if (
        moment()
          .add(6, 'days')
          .isSameOrAfter(moment(item.revisedDueDate), 'day') &&
        item.status !== AMO_ITEM_COMPLETED
      ) {
        return issues1Week++;
      }
      if (
        moment()
          .add(7, 'days')
          .isSameOrBefore(moment(item.revisedDueDate), 'day') &&
        item.status !== AMO_ITEM_COMPLETED
      ) {
        return issuesRemainder++;
      }
    }
    if (item.type === `Action`) {
      if (
        moment().isSameOrAfter(moment(item.revisedDueDate), 'day') &&
        item.status !== AMO_ITEM_COMPLETED
      ) {
        return actionsOverDue++;
      }
      if (
        moment()
          .add(6, 'days')
          .isSameOrAfter(moment(item.revisedDueDate), 'day') &&
        item.status !== AMO_ITEM_COMPLETED
      ) {
        return actions1Week++;
      }
      if (
        moment()
          .add(7, 'days')
          .isSameOrBefore(moment(item.revisedDueDate), 'day') &&
        item.status !== AMO_ITEM_COMPLETED
      ) {
        return actionsRemainder++;
      }
    }
  });

  const risksTotal = risksRemainder + risksOverDue + risks1Week;
  const [risksRemainderPct, risksOverDuePct, risks1WeekPct] = [
    pct(risksTotal, risksRemainder),
    pct(risksTotal, risksOverDue),
    pct(risksTotal, risks1Week),
  ];

  const actionsTotal = actionsRemainder + actionsOverDue + actions1Week;
  const [actionsRemainderPct, actionsOverDuePct, actions1WeekPct] = [
    pct(actionsTotal, actionsRemainder),
    pct(actionsTotal, actionsOverDue),
    pct(actionsTotal, actions1Week),
  ];

  const issuesTotal = issuesRemainder + issuesOverDue + issues1Week;
  const [issuesRemainderPct, issuesOverDuePct, issues1WeekPct] = [
    pct(issuesTotal, issuesRemainder),
    pct(issuesTotal, issuesOverDue),
    pct(issuesTotal, issues1Week),
  ];

  const risksData = [];
  if (risksRemainder > 0)
    risksData.push(
      getDataItem(
        risksRemainder,
        `${risksRemainderPct}% (${risksRemainder})`,
        GREEN_COLOR,
        null,
        AMO_ITEM_ON_TIME,
        ITEM_RISK,
      ),
    );
  if (risksOverDue > 0)
    risksData.push(
      getDataItem(
        risksOverDue,
        `${risksOverDuePct}% (${risksOverDue})`,
        RED_COLOR,
        null,
        AMO_ITEM_OVER_DUE,
        ITEM_RISK,
      ),
    );
  if (risks1Week > 0)
    risksData.push(
      getDataItem(
        risks1Week,
        `${risks1WeekPct}% (${risks1Week})`,
        YELLOW_COLOR,
        null,
        AMO_ITEM_DUE_THIS_WEEK,
        ITEM_RISK,
      ),
    );

  const issuesData = [];
  if (issuesRemainder > 0)
    issuesData.push(
      getDataItem(
        issuesRemainder,
        `${issuesRemainderPct}% (${issuesRemainder})`,
        GREEN_COLOR,
        null,
        AMO_ITEM_ON_TIME,
        ITEM_ISSUE,
      ),
    );
  if (issuesOverDue > 0)
    issuesData.push(
      getDataItem(
        issuesOverDue,
        `${issuesOverDuePct}% (${issuesOverDue})`,
        RED_COLOR,
        null,
        AMO_ITEM_OVER_DUE,
        ITEM_ISSUE,
      ),
    );
  if (issues1Week > 0)
    issuesData.push(
      getDataItem(
        issues1Week,
        `${issues1WeekPct}% (${issues1Week})`,
        YELLOW_COLOR,
        null,
        AMO_ITEM_DUE_THIS_WEEK,
        ITEM_ISSUE,
      ),
    );

  const actionsData = [];
  if (actionsRemainder > 0)
    actionsData.push(
      getDataItem(
        actionsRemainder,
        `${actionsRemainderPct}% (${actionsRemainder})`,
        GREEN_COLOR,
        null,
        AMO_ITEM_ON_TIME,
        ITEM_ACTION,
      ),
    );
  if (actionsOverDue > 0)
    actionsData.push(
      getDataItem(
        actionsOverDue,
        `${actionsOverDuePct}% (${actionsOverDue})`,
        RED_COLOR,
        null,
        AMO_ITEM_OVER_DUE,
        ITEM_ACTION,
      ),
    );
  if (actions1Week > 0)
    actionsData.push(
      getDataItem(
        actions1Week,
        `${actions1WeekPct}% (${actions1Week})`,
        YELLOW_COLOR,
        null,
        AMO_ITEM_DUE_THIS_WEEK,
        ITEM_ACTION,
      ),
    );

  const risksOptions = getPieOptions(risksData);
  const actionsOptions = getPieOptions(actionsData);
  const issuesOptions = getPieOptions(issuesData);
  return { risksOptions, issuesOptions, actionsOptions };
};

const getPieOptions = (data = []) => {
  const options = R.clone(PIE_OPTIONS);
  options.series[0].data = data;
  return options;
};

/*
 * option = {
    title: {
        text: '漏斗图',
        subtext: '纯属虚构',
        left: 'left',
        top: 'bottom'
    },
    tooltip: {
        trigger: 'item',
        formatter: "{a} <br/>{b} : {c}%"
    },
    toolbox: {
        orient: 'vertical',
        top: 'center',
        feature: {
            dataView: {readOnly: false},
            restore: {},
            saveAsImage: {}
        }
    },
    legend: {
        orient: 'vertical',
        left: 'left',
        data: ['$1M','$1.5M','$4M']
    },
    calculable: true,
    series: [
        {
            name: '漏斗图',
            type: 'funnel',
            width: '80%',
            height: '80%',
            left: '5%',
            top: '5%',
            data:[
                {value: 160, name:'$8M', itemStyle:{height:250 }},
                {value: 100, name:'$100M'},
                {value: 80, name:'$30M'},
                {value: 70, name:'$4M'},
                {value: 60, name:'$4M'},
                {value: 50, name:'$2.5'},
                {value: 40, name:'$1'},
                {value: 30, name:'$1'},
                {value: 20, name:'$1'},
                {value: 10, name:'$1'}
            ]
        }
    ]
};.
 
 */
/**
 * Create data week , day, weekday and full date.
 *
 * @param {string}date - Date.
 * @returns {Array}Array.
 */
export const getWeekForCalendar = (date) => {
  const week = [];
  for (let i = 0, j = 7; i < j; i++) {
    const dataWeek = {};
    const _date = moment(date).weekday(i)._d;
    dataWeek.day = moment(_date).format('DD');
    dataWeek.weekday = moment(_date)
      .weekday(i)
      .format('dd')
      .substr(0, 1);
    dataWeek.fullDate = moment(_date).format('YYYY-MM-DD');
    week.push(dataWeek);
  }

  return week;
};
