import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Avatar, Row as BoostRow } from '@8base/boost';
import * as R from 'ramda';

const Row = styled(BoostRow)`
  width: max-content;
`;

const UserCell = ({ owner, src, size = 'sm' }) => {
  if (!src) {
    src = R.path(['avatar', 'downloadUrl'], owner);
  }

  const username = owner ? `${owner.firstName} ${owner.lastName}` : '';

  return (
    <Row alignItems="center">
      <Avatar
        size={size}
        src={src}
        firstName={R.path(['firstName'], owner)}
        lastName={R.path(['lastName'], owner)}
      />
      <span>{username}</span>
    </Row>
  );
};

UserCell.propTypes = {
  owner: PropTypes.object.isRequired,
  src: PropTypes.string.isRequired,
  size: PropTypes.string.isRequired,
};

export { UserCell };
