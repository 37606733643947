import React from 'react';
import PropTypes from 'prop-types';
import { Card, Grid, Heading, Loader, Row } from '@8base/boost';
import { CreateViewCardBody } from '../../../components/new-ui/card/CreateViewCardBody';
import IdeaForm from './components/IdeaForm';
import {
  onChangeBusinessCaseDataMixin,
  onChangeDataMixin,
  onErrorMixin,
} from '../../../shared/mixins';
import RelatedItemForm from '../../related-item/components/RelatedItemForm';
import { initiativesItemValidator } from '../initiative/initiative-validators';
import { RelatedItemsDetailTable } from '../../related-item/components/RelatedItemsDetailTable';
import * as R from 'ramda';
import IdeaModel, { IDEA_DOCUMENTS } from './idea-model';
import { withRouter } from 'react-router-dom';
import * as toast from '../../../components/toast/Toast';
import { View } from '@cobuildlab/react-simple-state';
import { autoSaveUpdateIdea, fetchIdeaDetail, openComments, updateIdea } from './idea-actions';
import { fetchCurrentAllianceMembersAction } from '../../settings/alliance-management/alliance-actions';
import { fetchInitiativeList } from '../initiative/initiative-actions';
import BusinessCaseModel, {
  BUSINESS_CASE_DOCUMENT,
} from '../../document-management/business-case/BusinessCase.model';
import BusinessCaseForm from '../../document-management/business-case/components/BusinessCaseForm';
import IdeaDetailTable from './components/IdeaDetailTable';
import BusinessCaseDetailTable from '../../document-management/business-case/components/BusinessCaseDetailTable';
import { getCurrencyOnSession } from '../../../shared/alliance-utils';
import { ActionButton } from '../../../components/buttons/ActionButton';
import { FormSteps } from '../../../components/dots/FormSteps';
import { ideaValidator } from './idea-validators';
import { businessCaseValidator } from '../../document-management/business-case/business-case-validators';
import { sanitizeRecommendedSolutionsToEdit } from '../../document-management/business-case/businessCases.actions';
import { TopButtons } from '../../../components/buttons/TopButtons';
import { TransparentButton } from '../../../components/buttons/TransparentButton';
import { sanitizeNextStepsToEdit } from '../../next-step/next-step-actions';
import sessionStore, { NEW_SESSION_EVENT } from '../../../shared/SessionStore';
import { fetchRelatedItems } from '../../related-item/related-item-actions';
import { getItemByType } from '../../../shared/items-util';
import { InitiativeListTable } from '../initiative/components/InitiativeListTable';
import { LeftProgressSection } from '../../../components/new-ui/LeftProgressSection';
import { SCREENS_IDEA } from '../screenView';
import { BoxCard } from '../../../components/new-ui/div/BoxCard';
import { CardFooter } from '../../../components/new-ui/card/CardFooter';
import { IDEA_COMPLETED } from '../../../shared/status';
import { TextDateAgo } from '../../../components/text/TextDateAgo';
import { OnRelatedItems, OnRelatedItemError } from '../../related-item/related-item-events';
import { OnIdeaError, OnIdeaDetail, OnIdeaUpdate, OnIdeaAutoSaveCreate } from './idea-events';
import { OnInitiativeList } from '../initiative/initiative-events';
import { OnAllianceMemberList } from '../../settings/alliance-management/alliance-events';

// Edit Idea
class IdeaEditView extends View {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        ideaData: R.clone(IdeaModel),
        businessCaseData: R.clone(BusinessCaseModel),
        relatedItems: [],
        initiatives: [],
      },
      initiativesList: [],
      clientCompany: null,
      partnerCompany: null,
      step: 0,
      loading: true,
      savedAt: null,
    };
    this.onError = onErrorMixin.bind(this);
    this.onChangeData = onChangeDataMixin.bind(this);
    this.onChangeBusinessCaseData = (name, value) => {
      this.autoSave();
      onChangeBusinessCaseDataMixin.call(this, name, value);
    };
    this.originalRecommendedSolutions = [];
    this.originalNextSteps = [];
    this.originalIdeaData = null;
    this.autoSaveTimer = null;
    this.queryOptions = { isCacheFirst: true };
  }

  onChangeIdeaData = (name, value) => {
    const { data } = this.state;
    data.ideaData[name] = value;
    this.autoSave();
    this.setState({ data });
  };

  componentDidMount = () => {
    const { match } = this.props;
    if (!match.params.id) return toast.error('Idea ID missing');

    this.subscribe(OnIdeaError, this.onError);
    this.subscribe(OnRelatedItemError, this.onError);

    this.subscribe(OnAllianceMemberList, (state) => {
      this.setState({
        clientCompany: R.clone(state.clientCompany),
        partnerCompany: R.clone(state.partnerCompany),
      });
    });
    this.subscribe(OnIdeaDetail, (state) => {
      const ideaData = R.clone(state.idea);
      const businessCaseData = R.clone(ideaData.businessCase);
      const initiatives = ideaData.initiatives.items.map((item) => ({
        ...item,
      }));

      const { data } = this.state;

      ideaData.documents = ideaData.documents.items.map((document) => ({
        ...document,
      }));

      this.originalIdeaData = R.clone(ideaData);

      this.originalNextSteps = sanitizeNextStepsToEdit(ideaData);
      this.originalRecommendedSolutions = sanitizeRecommendedSolutionsToEdit(businessCaseData);

      data.ideaData = ideaData;
      data.businessCaseData = businessCaseData;
      data.initiatives = initiatives;

      this.setState({ data }, () => fetchRelatedItems(ideaData.itemIdeaRelation.id));
    });

    this.subscribe(OnRelatedItems, (state) => {
      const items = state.item.itemsRelated.items.map((item) => getItemByType(item));
      const { data } = this.state;

      data.relatedItems = items;
      this.setState({ data, loading: false });
    });

    this.subscribe(OnInitiativeList, (state) => {
      this.setState({
        initiativesList: state.initiativesList.items.map((item) => ({
          ...item,
        })),
      });
    });
    this.subscribe(OnIdeaUpdate, (state) => {
      toast.success(`Idea Successfully Updated`);
      this.props.history.goBack();
    });
    this.subscribe(OnIdeaAutoSaveCreate, (idea) => {
      this.setState({ savedAt: new Date() });
    });

    fetchIdeaDetail(match.params.id, this.queryOptions);
    fetchInitiativeList('', 1, 1000, null, null, this.queryOptions);
    fetchCurrentAllianceMembersAction();
  };

  componentWillUnmount() {
    super.componentWillUnmount();
    localStorage.removeItem(IDEA_DOCUMENTS);
    localStorage.removeItem(BUSINESS_CASE_DOCUMENT);
  }

  autoSave = () => {
    const waitTime = 10000; // Wait 10s
    clearTimeout(this.autoSaveTimer);
    this.autoSaveTimer = setTimeout(() => {
      const ideaData = R.clone(this.state.data.ideaData);
      const businessCaseData = R.clone(this.state.data.businessCaseData);
      const relatedItems = R.clone(this.state.data.relatedItems);
      const initiatives = R.clone(this.state.data.initiatives);

      autoSaveUpdateIdea(
        ideaData,
        businessCaseData,
        relatedItems,
        initiatives,
        this.originalRecommendedSolutions,
        this.originalNextSteps,
        this.originalIdeaData,
      );
    }, waitTime);
  };

  onSubmit = () => {
    this.setState({ loading: true }, () => {
      clearTimeout(this.autoSaveTimer);

      const ideaData = R.clone(this.state.data.ideaData);
      const businessCaseData = R.clone(this.state.data.businessCaseData);
      const relatedItems = R.clone(this.state.data.relatedItems);
      const initiatives = R.clone(this.state.data.initiatives);

      updateIdea(
        ideaData,
        businessCaseData,
        relatedItems,
        initiatives,
        this.originalRecommendedSolutions,
        this.originalNextSteps,
        this.originalIdeaData,
      );
    });
  };

  onIdeaStepChange = (step) => {
    const { selectedAlliance } = sessionStore.getState(NEW_SESSION_EVENT);
    const ideaData = R.clone(this.state.data.ideaData);
    try {
      ideaValidator(ideaData, selectedAlliance);
    } catch (e) {
      return this.onError(e);
    }
    this.onScreen(step);
  };

  onBusinessCaseStepChange = (step) => {
    const businessCaseData = R.clone(this.state.data.businessCaseData);
    try {
      businessCaseValidator(businessCaseData);
    } catch (e) {
      return this.onError(e);
    }
    this.onScreen(step);
  };

  onRelatedItemsStepChange = (step) => {
    const initiatives = R.clone(this.state.data.initiatives);
    try {
      initiativesItemValidator(initiatives);
    } catch (e) {
      return this.onError(e);
    }
    this.onScreen(step);
  };

  onScreen = (step) => {
    this.setState({ step });
  };

  render() {
    const {
      data,
      step,
      loading,
      clientCompany,
      partnerCompany,
      initiativesList,
      savedAt,
    } = this.state;
    const { ideaData, businessCaseData, relatedItems, initiatives } = data;
    const { history } = this.props;
    const currency = getCurrencyOnSession();
    const { user, selectedAlliance } = sessionStore.getState(NEW_SESSION_EVENT);

    let content = <Loader stretch />;
    let footer = <></>;
    let buttonsTop = <></>;
    if (ideaData.status === IDEA_COMPLETED) history.push(`/management/idea/`);

    if (!loading && step === 0) {
      content = (
        <div id={'c2gScreen'}>
          <IdeaForm
            data={ideaData}
            onChange={this.onChangeIdeaData}
            clientCompany={clientCompany}
            partnerCompany={partnerCompany}
            currency={currency}
            user={user}
            selectedAlliance={selectedAlliance}
          />
        </div>
      );
      footer = (
        <CardFooter>
          <ActionButton onClick={() => this.onIdeaStepChange(1)} text="Next" />
        </CardFooter>
      );
    }

    if (!loading && step === 1) {
      content = (
        <div id={'c2gScreen'}>
          <BusinessCaseForm
            data={businessCaseData}
            onChange={this.onChangeBusinessCaseData}
            currency={currency}
            showBusinessFields
            hideExpectCostAvoidance
          />
        </div>
      );
      footer = (
        <CardFooter>
          <ActionButton onClick={() => this.onBusinessCaseStepChange(2)} text="Next" />
          <TransparentButton onClick={() => this.onScreen(0)} text="Previous" />
        </CardFooter>
      );
    }

    if (!loading && step === 2) {
      const itemData = { id: ideaData.id, type: ideaData.__typename };

      content = (
        <div id={'c2gScreen'}>
          <RelatedItemForm
            relatedItems={relatedItems}
            selectedInitiatives={initiatives}
            initiatives={initiativesList}
            onChange={(key, value) => {
              this.autoSave();
              this.onChangeData(key, value);
            }}
            itemData={itemData}
          />
        </div>
      );

      footer = (
        <CardFooter>
          <ActionButton onClick={() => this.onRelatedItemsStepChange(3)} text={'Next'} />
          <TransparentButton onClick={() => this.onScreen(1)} text={'Previous'} />
        </CardFooter>
      );
    }

    if (!loading && step === 3) {
      const ideaDetail = R.clone(ideaData);
      ideaDetail.initiatives = { items: initiatives };
      ideaDetail.documents = { items: ideaDetail.documents };
      ideaDetail.nextSteps = { items: ideaDetail.nextSteps };

      content = (
        <div id={'c2gScreen'}>
          <IdeaDetailTable data={ideaDetail} currency={currency} />
          <BusinessCaseDetailTable
            hideExpectedCostAvoidance
            data={businessCaseData}
            currency={currency}
          />
          <InitiativeListTable initiatives={initiatives} />

          <RelatedItemsDetailTable relatedItems={relatedItems} />
        </div>
      );

      footer = (
        <CardFooter>
          <ActionButton onClick={this.onSubmit} text="Update Idea" />
          <TransparentButton onClick={() => this.onScreen(2)} text="Previous" />
        </CardFooter>
      );
    }

    if (!loading)
      buttonsTop = (
        <TopButtons
          onClickClosed={history.goBack}
          onClickCollaborated={() => openComments(ideaData)}
        />
      );

    return (
      <React.Fragment>
        <Card.Header>
          <Grid.Layout
            columns="400px auto 400px"
            areas={[['left', 'center', 'right']]}
            style={{ width: '100%' }}>
            <Grid.Box area="left">
              <Row>
                <Heading type="h4" text="Edit Idea" />
                <TextDateAgo from={savedAt} />
              </Row>
            </Grid.Box>
            <Grid.Box area="center">
              <FormSteps totalSteps={4} step={step} />
            </Grid.Box>
            <Grid.Box area="right" />
          </Grid.Layout>
          {buttonsTop}
        </Card.Header>
        <CreateViewCardBody>
          <Grid.Layout columns="30% 70%" areas={[['left', 'right']]} style={{ width: '100%' }}>
            <Grid.Box area="left">
              <LeftProgressSection sections={SCREENS_IDEA} currentScreen={step} />
            </Grid.Box>
            <BoxCard>
              <Grid.Box area="right">{content}</Grid.Box>
            </BoxCard>
          </Grid.Layout>
        </CreateViewCardBody>
        {footer}
      </React.Fragment>
    );
  }
}

IdeaEditView.propTypes = {
  history: PropTypes.object.isRequired,
};

export default withRouter(IdeaEditView);
