import React from 'react';
import { Row, Column, Text } from '@8base/boost';
import { PlanName } from './PlanName';
import { PlanPaper } from './PlanPaper';

const NoSubscriptionPlan = (props) => {
  return (
    <PlanPaper padding={'md'}>
      <Row>
        <Column stretch>
          <PlanName weight="bold">{'Not Subscribed'}</PlanName>
        </Column>
        <Column stretch />
        <Column stretch alignItems="end">
          <Text weight="bold">{'Partner Company'}</Text>
        </Column>
      </Row>
    </PlanPaper>
  );
};

export { NoSubscriptionPlan };
