import React from 'react';
import { GraphsDashboard } from './components/GraphsDashboard';
import Content from '../../components/Content';
import { View } from '@cobuildlab/react-simple-state';

class DashboardView extends View {
  render() {
    return (
      <div id={'c2gScreen'}>
        <Content style={{ marginTop: '0px', paddingTop: '0px' }}>
          <GraphsDashboard />
        </Content>
      </div>
    );
  }
}

export default DashboardView;
