import React from 'react';
import { Card, Heading, Loader, Row } from '@8base/boost';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import * as toast from '../../../components/toast/Toast';
import * as R from 'ramda';
import { View } from '@cobuildlab/react-simple-state';
import { onErrorMixin } from '../../../shared/mixins';
import { ContributionModel } from './contribution-model';
import { fetchContribution } from './contribution-action';
import sessionStore, { NEW_SESSION_EVENT } from '../../../shared/SessionStore';
import { onChangeMixin } from '../../../shared/mixins';
import withAlliance from '../../../components/hoc/withAlliance';
import ContributionDetailTable from './components/ContributionDetailTable';
import { openComments, completedContribution, restoreContribution } from './contribution-action';
import { canCompletedContribution, canRestoreContribution } from './contribution-permissions';
import {
  fetchRelatedItems,
  fetchRelatedItemsByItemId,
} from '../../related-item/related-item-actions';
import YesNoDialog from '../../../components/dialogs/YesNoDialog';
import { DetailViewCardBody } from '../../../components/card/DetailViewCardBody';
import { ActionButton } from '../../../components/buttons/ActionButton';
import { getCurrencyOnSession } from '../../../shared/alliance-utils';
import { RelatedItemsDetailTable } from '../../related-item/components/RelatedItemsDetailTable';
import { getItemByType } from '../../../shared/items-util';
import { TopButtons } from '../../../components/buttons/TopButtons';
import { RelatedItemsByItemDetailTable } from '../../related-item/components/RelatedItemsByItemDetailTable';
import { InitiativeListTable } from '../initiative/components/InitiativeListTable';
import { CardFooter } from '../../../components/new-ui/card/CardFooter';
import { OnRelatedItems, OnRelatedItemsByItem } from '../../related-item/related-item-events';
import {
  OnContributionDetail,
  OnContributionError,
  OnContributionCompleted,
  OnContributionRestore,
} from './contribution-events';

class ContributionDetailView extends View {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        contributionData: R.clone(ContributionModel),
        relatedItems: [],
        initiatives: [],
        relatedItemsByItem: [],
      },
      loading: true,
      completedModalIsOpen: false,
      restoreModalIsOpen: false,
    };
    this.onChange = onChangeMixin.bind(this);
    this.onError = onErrorMixin.bind(this);
    this.user = sessionStore.getState(NEW_SESSION_EVENT).user;
    this.selectedAlliance = sessionStore.getState(NEW_SESSION_EVENT).selectedAlliance;
  }

  componentDidMount() {
    this.subscribe(OnContributionError, this.onError);
    this.subscribe(OnContributionDetail, (state) => {
      const contribution = R.clone(state.contribution);
      const { data } = this.state;
      contribution.itemId = contribution.itemContributionRelation.id;
      data.contributionData = contribution;
      data.initiatives = contribution.initiatives.items.map((initiative) => ({
        ...initiative,
      }));

      this.setState(
        {
          data,
        },
        () => fetchRelatedItems(contribution.itemContributionRelation.id),
        fetchRelatedItemsByItemId(contribution.itemContributionRelation.id),
      );
    });

    this.subscribe(OnRelatedItems, (state) => {
      const items = state.item.itemsRelated.items.map((item) => getItemByType(item));
      const { data } = this.state;
      data.relatedItems = items;
      this.setState({
        data,
      });
    });

    this.subscribe(OnRelatedItemsByItem, (state) => {
      const {
        itemsList: { items: itemsRelated },
      } = state;
      const relatedItemsByItem = itemsRelated.map((item) => getItemByType(item));
      const { data } = this.state;

      data.relatedItemsByItem = relatedItemsByItem;
      this.setState({ data, loading: false });

      console.log('relatedItemsByItem', relatedItemsByItem);
    });

    this.subscribe(OnContributionCompleted, (state) => {
      fetchContribution(match.params.id);
      toast.success('Contribution Successfully Completed');
    });

    this.subscribe(OnContributionRestore, (state) => {
      fetchContribution(match.params.id);
      toast.success('Contribution Successfully Restored');
    });

    const { match } = this.props;
    if (!match.params.id) return toast.error('Contribution ID missing');
    fetchContribution(match.params.id);
  }

  completedModal = () => {
    this.setState({
      completedModalIsOpen: true,
    });
  };

  restoreModal = () => {
    this.setState({
      restoreModalIsOpen: true,
    });
  };

  onYesModal = () => {
    this.setState(
      {
        completedModalIsOpen: false,
        loading: true,
      },
      () => {
        const contributionData = R.clone(this.state.data.contributionData);
        completedContribution(contributionData);
      },
    );
  };

  onYesRestore = () => {
    this.setState(
      {
        restoreModalIsOpen: false,
        loading: true,
      },
      () => {
        const contributionData = R.clone(this.state.data.contributionData);
        restoreContribution(contributionData);
      },
    );
  };

  onCompletedModal = () => {
    this.setState({
      completedModalIsOpen: false,
    });
  };

  onRestoreModal = () => {
    this.setState({
      restoreModalIsOpen: false,
    });
  };

  render() {
    const { loading, completedModalIsOpen, data, restoreModalIsOpen } = this.state;
    const { contributionData, relatedItemsByItem, initiatives, relatedItems } = data;
    const currency = getCurrencyOnSession();
    let content = <Loader stretch />;
    let buttonsBottom = '';
    let buttonsTop = '';
    const { history } = this.props;
    const alliance = this.selectedAlliance;

    if (!loading) {
      content = (
        <div id={'c2gScreen'}>
          <ContributionDetailTable
            data={contributionData}
            currency={currency}
            onClickEdit={() => history.push(`/management/contribution/edit/${contributionData.id}`)}
          />
          <InitiativeListTable initiatives={initiatives} />
          <RelatedItemsDetailTable relatedItems={relatedItems} />
          <RelatedItemsByItemDetailTable relatedItemsByItem={relatedItemsByItem} />
        </div>
      );

      buttonsTop = (
        <>
          <Heading type="h4" text={contributionData.name} />
          <TopButtons
            onClickClosed={history.goBack}
            onClickCollaborated={() => openComments(contributionData)}
          />
        </>
      );

      buttonsBottom = (
        <Row justifyContent="end">
          {canCompletedContribution(this.user, contributionData, alliance) ? (
            <ActionButton
              text="Mark Completed"
              fontAwesomeIcon="clipboard-list"
              onClick={() => {
                this.completedModal();
              }}
            />
          ) : null}

          {canRestoreContribution(this.user, contributionData, alliance) ? (
            <ActionButton
              text="Restore"
              fontAwesomeIcon="clipboard-list"
              onClick={() => {
                this.restoreModal();
              }}
            />
          ) : null}
        </Row>
      );
    }

    return (
      <React.Fragment>
        <Card.Header>{buttonsTop}</Card.Header>
        <DetailViewCardBody>{content}</DetailViewCardBody>
        <CardFooter>{buttonsBottom}</CardFooter>
        <YesNoDialog
          title={'Complete Contribution'}
          onYes={this.onYesModal}
          onClose={this.onClose}
          onNo={this.onCompletedModal}
          text={'Are you sure you want to Mark the Contribution as Completed?'}
          isOpen={completedModalIsOpen}
        />

        <YesNoDialog
          title={'Restore Contribution'}
          onYes={this.onYesRestore}
          onClose={this.onClose}
          onNo={this.onRestoreModal}
          text={'Are you sure you want the Contribution as Restored?'}
          isOpen={restoreModalIsOpen}
        />
      </React.Fragment>
    );
  }
}

ContributionDetailView.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default withRouter(withAlliance(ContributionDetailView));
