import { curry, apply, clone } from 'ramda';
import { getRoleOnAlliance, isUserAdminOrSERInAlliance } from './alliance-utils';
import { ALLIANCE_ADMINISTRATOR, ALLIANCE_SER } from './roles';
import BigInt from 'big-integer';
import { isAllianceCompleted } from '@cobuildlab/collabtogrow-permissions';
import { initiativesApprovedValidator } from '../modules/management/initiative/initiative-validators';
import sessionStore, { NEW_SESSION_EVENT } from './SessionStore';
import * as Excel from 'exceljs';
import * as FileSaver from 'file-saver';
import { log } from '@cobuildlab/pure-logger';
import {
  ALLOWED_DECIMALS_BY_KPI,
  KPI_WITH_CURRENCY_TYPES,
  KPI_WITH_PERCENTAGE_TYPES,
} from '../modules/settings/alliance-management/allianceKPIs/allianceKPIs-model';

/**
 *
 * @param {object} state - The current State.
 * @param {object} object - Object to update keys from.
 * @returns {{}} A New State object.
 * @deprecated Not to use, See Contributing guidelines.
 */
export const updateStateFromObject = (state, object) => {
  const newState = {};
  Object.keys(state).forEach((key) => {
    const newValue = object[key];
    const oldValue = state[key];
    if (newValue === undefined) {
      newState[key] = oldValue;
      return;
    }
    if (newValue === null) {
      newState[key] = oldValue;
      return;
    }

    if (typeof newValue === 'object') {
      if (newValue.id) newState[key] = newValue.id;
      else newState[key] = newValue;
      return;
    }

    if (Array.isArray(newValue)) {
      const newValues = [];
      newValue.forEach((value) => {
        if (value === undefined) return;
        if (value === null) return;

        if (typeof value === 'object') {
          if (value.id) newValues.push(value.id);
          return;
        }
        newValues.push(value);
      });
      newState[key] = newValues;
      return;
    }

    newState[key] = newValue;
  });
  return newState;
};

/**
 * Helper to change Array keys to 8base 'reconnect' multiple references.
 * WARNING: This function mutates the data.
 *
 * @param {Array}data - Array.
 * @param {string}key - Array key.
 */
export const sanitize8BaseReconnects = (data, key) => {
  if (Array.isArray(data[key])) {
    data[key] = {
      reconnect: data[key].map((id) => {
        return { id: id };
      }),
    };
  } else delete data[key];
};

/**
 * Helper to change Array key objects to 8base 'reconnect' multiple references.
 * WARNING: This function mutates the data.
 *
 * @param {object} data - To mutate.
 * @param {string} key - The key of the property to mutate.
 */
export const sanitize8BaseReconnectsFromObjects = (data, key) => {
  if (Array.isArray(data[key])) {
    data[key] = {
      reconnect: data[key].map(({ id }) => {
        return { id: id };
      }),
    };
  } else delete data[key];
};

/**
 * Helper to change Array keys to 8base 'connect' multiple references
 * WARNING: This function mutates the data.
 *
 * @param {Array}data - Array.
 * @param {string}key - Array key.
 */
export const sanitize8BaseReferences = (data, key) => {
  if (Array.isArray(data[key])) {
    data[key] = {
      connect: data[key].map((id) => {
        return { id: id };
      }),
    };
  } else delete data[key];
};

/**
 * Helper to change Array keys objects to 8base 'connect' multiple references
 * WARNING: This function mutates the data.
 *
 * @deprecated Use @cobuildlab/8base-utils:normalize8BaseReference.
 * @param {Array}data - Array.
 * @param {string}key - Array Key.
 */
export const sanitize8BaseReferencesFromObjects = (data, key) => {
  if (Array.isArray(data[key])) {
    data[key] = {
      connect: data[key].map(({ id }) => {
        return { id: id };
      }),
    };
  } else delete data[key];
};

/**
 * Helper to change non null keys to 8base 'connect' reference
 WARNING: This function mutates the data.
 *
 * @deprecated Use @cobuildlab/8base-utils:normalize8BaseReference.
 *
 * @param {object} data - To mutate.
 * @param {string} key - The key of the property to mutate.
 */
export const sanitize8BaseReference = (data, key) => {
  if (data[key] !== null && data[key] !== '') data[key] = { connect: { id: data[key] } };
  else delete data[key];
};

/**
 * Helper function to non null objects to 8base 'connect' reference
 * WARNING: This function mutates the data.
 *
 * @param {object}data - Object.
 * @param {string}key - Key object.
 */
export const sanitize8BaseReferenceFromObject = (data, key) => {
  if (data[key] !== null && data[key] !== '' && data[key] !== undefined) {
    if (data[key].id === undefined)
      throw new Error(
        `${key} property on 'data' does not have a field 'id' so is not possible to create the 'connect'`,
      );
    data[key] = { connect: { id: data[key].id } };
  } else delete data[key];
};

/**
 * Helper to change non null keys to 8base 'create' reference
 WARNING: This function mutates the data.
 *
 * @param {object} data - To mutate.
 * @param {string} key - The key of the property to mutate.
 */
export const sanitize8BaseNewObject = (data, key) => {
  if (data[key] !== null && data[key] !== '') data[key] = { create: { id: data[key] } };
  else delete data[key];
};

/**
 * Helper to change non null keys to 8base 'create' reference for Documents
 WARNING: This function mutates the data.
 *
 * @deprecated Use @cobuildlab/8base-utils:normalize8baseDocumentCreate.
 *
 * @param {object} data - To mutate.
 * @param {string} key - The key of the property to mutate.
 */
export const sanitize8BaseDocumentCreate = (data, key) => {
  if (data[key] !== null && data[key] !== '') data[key] = { create: data[key] };
  else delete data[key];
};

/**
 * Helper to change non null keys to 8base 'create' reference for Documents List
 WARNING: This function mutates the data.
 *
 * @deprecated Use @cobuildlab/8base-utils:normalize8baseDocumentsCreate.
 *
 * @param {object} data - To mutate.
 * @param {string} key - The key of the property to mutate.
 */
export const sanitize8BaseDocumentsCreate = (data, key) => {
  if (data[key] !== null && data[key] !== '') {
    data[key] = {
      create: data[key].map((document) => {
        return { ...document };
      }),
    };
  } else delete data[key];
};

/**
 * Helper to change non null keys to 8base 'create' reference for Documents.
 * WARNING: This function mutates the data.
 *
 * @deprecated Use @cobuildlab/8base-utils:normalize8baseDocumentDeleteAndUpdate.
 *
 * @param {object} data - To mutate.
 * @param {string} key - The key of the property to mutate.
 */
export const sanitize8BaseDocumentUpdate = (data, key) => {
  if (data[key] !== null && data[key].downloadUrl === undefined) data[key] = { create: data[key] };
  else delete data[key];
};

/**
 * Helper to change and delete one document.
 *
 * @deprecated Use @cobuildlab/8base-utils:normalize8baseDocumentDeleteAndUpdate.
 *
 * @param {object}data - Data for mutation.
 * @param {string}key - Key object.
 * @param {object}originalData - Original object.
 */
export const sanitize8baseDocumentDeleteAndUpdate = (data, key, originalData) => {
  if (data[key] !== null) {
    if (data[key].downloadUrl === undefined) data[key] = { create: data[key] };
    else delete data[key];
  } else {
    if (originalData[key] !== null) data[key] = { disconnect: { id: originalData[key].id } };
  }
};

/**
 * Helper to change non null keys to 8base 'create' reference for Documents Lists
 WARNING: This function mutates the data.
 *
 * @param {object} data - To mutate.
 * @param {string} key - The key of the property to mutate.
 */
export const sanitize8BaseDocumentsUpdate = (data, key) => {
  if (data[key] !== null && data[key].length > 0) {
    const docsToCreate = data[key].filter((file) => file.downloadUrl === undefined);
    if (docsToCreate.length > 0) {
      data[key] = {
        create: docsToCreate.map((document) => {
          return { ...document };
        }),
      };
      return;
    }
  }
  delete data[key];
};

/**
 * Helper to change non null keys to 8base 'delete & create' reference for Documents Lists
 WARNING: This function mutates the data.
 *
 * @deprecated Use @cobuildlab/8base-utils:normalize8BaseDocumentsDeleteAndUpdate.
 *
 * @param {object} data - To mutate.
 * @param {string} key - The key of the property to mutate.
 * @param {Array} originalData - The documents originals.
 */
export const sanitize8BaseDocumentsDeleteAndUpdate = (data, key, originalData) => {
  log('sanitize8BaseDocumentsDeleteAndUpdate', data, key, originalData);

  if (data[key] === undefined || data[key] === null) {
    delete data[key];
    return;
  }

  const toBeDeleted = [],
    toBeCreated = [];

  data[key].forEach((document) => {
    if (document.id === undefined) toBeCreated.push(document);
  });

  originalData[key].forEach((original) => {
    const originalDocument = data[key].find((file) => file.id === original.id);
    if (originalDocument === undefined) toBeDeleted.push({ id: original.id });
  });

  if (toBeCreated.length === 0 && toBeDeleted.length === 0) {
    delete data[key];
    return;
  }

  data[key] = {};

  if (toBeCreated.length > 0)
    data[key].create = toBeCreated.map((document) => {
      return { ...document };
    });
  if (toBeDeleted.length > 0) data[key].disconnect = toBeDeleted;
};

/**
 * Helper to remove null keys from objects
 WARNING: This function mutates the data.
 *
 * @param {object} data - To mutate.
 * @param {string} key - The key of the property to mutate.
 */
export const sanitize8BaseNonNull = (data, key) => {
  if (data[key] === null) delete data[key];
};

/**
 * Helper to remove null or blank dates keys from objects
 WARNING: This function mutates the data.
 *
 * @param {object} data - To mutate.
 * @param {string} key - The key of the property to mutate.
 */
export const sanitize8BaseDate = (data, key) => {
  if (data[key] === null || data[key] === '') delete data[key];
};

/**
 * Helper to truncate text to a given length and add ellipsis.
 *
 * @param {string} str - Original string to be truncated.
 * @param {number} length - (optional) length of new truncated string that will be returned.
 * @returns {string|*} The new truncated string with an ellipsis at the end.
 */
export const truncateText = (str, length = 75) => {
  if (str.length > length) {
    return str.substring(0, length - 3) + '...';
  }
  return str;
};

/**
 * @deprecated // Use ./shared/validators isValidString.
 *
 * Helper to check valid Strings: not null, not undefined, not blank.
 *
 * @param {string} str - String.
 * @returns {string|*} True if the string is Valid, otherwise false.
 */
export const isValidString = (str) => {
  if (str === undefined) return false;
  if (str === null) return false;
  return str !== '';
};

export const pct = (total, fraction) => {
  if (total === 0) return 0;
  return Math.ceil((fraction * 100) / total);
};

/**
 * Shuffles array in place. ES6 version.
 *
 * Https://stackoverflow.com/questions/6274339/how-can-i-shuffle-an-array/6274381#6274381.
 *
 * @param {Array} a - Items An array containing the items.
 * @returns {*} Object.
 */
export const shuffle = (a) => {
  for (let i = a.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [a[i], a[j]] = [a[j], a[i]];
  }
  return a;
};

/* Set the redirectUri to use after login and store query string params
 * // TODO: add any auth route on the if coditional
 * // This wont override urls that includes '/invitations/accept' string
 */
export const setLoginRedirectUri = () => {
  const { pathname, search } = window.location;
  const routesToIgnore = ['/home', '/auth', '/wizard', '/company-management'];

  if (pathname === '/') return;
  for (const route of routesToIgnore) {
    // dont set redirectUri if its an ignored route
    if (pathname.includes(route)) return;
  }

  const redirectUri = localStorage.getItem('redirectUri');
  // don't override '/invitations/accept' routes
  if (!redirectUri || !redirectUri.includes('/invitations/accept')) {
    localStorage.setItem('redirectUri', pathname);
  }
  if (search) localStorage.setItem('search', search);
};

/**
 * Get invitation from search query stored with setLoginRedirectUri.
 *
 * @returns {null|{id: string, type: string, email: string}} The invitation from the acceptInvitation link.
 */
export const getInvitationFromSearch = () => {
  console.log('getInvitationFromSearch:');
  const search = localStorage.getItem('search');
  const params = new URLSearchParams(search);
  const id = params.get('id');
  const companyName = params.get('companyName');
  const allianceName = params.get('allianceName');
  const type = params.get('invitationType');
  const email = params.get('email');
  console.log(
    'getInvitationFromSearch:',
    search,
    params,
    id,
    companyName,
    allianceName,
    type,
    email,
  );
  if (type) {
    const invitation = { type, email, id };
    if (type === 'company') invitation.name = companyName;
    else if (type === 'alliance' || type === 'alliance-member') invitation.name = allianceName;
    return invitation;
  }

  return null;
};

/**
 * Debounce.
 *
 * @param {boolean} immediate - If true run `fn` at the start of the timeout.
 * @param  timeMs - {number} Debounce timeout.
 * @param  fn - {Function} Function to debounce.
 *
 * @returns {number} Timeout.
 * @example
 *
 *    const say = (x) => console.log(x);
 *    const debouncedSay = debounce_(1000, say, false)();
 *    debouncedSay("1");
 */
export const debounce = curry((timeMs, fn, immediate = false) => () => {
  let timeout;

  return (...args) => {
    const later = () => {
      timeout = null;

      if (!immediate) {
        apply(fn, args);
      }
    };

    const callNow = immediate && !timeout;

    clearTimeout(timeout);
    timeout = setTimeout(later, timeMs);

    if (callNow) {
      apply(fn, args);
    }

    return timeout;
  };
});

/**
 * Returns true is the scroll bottom is reached and the view can load more items.
 *
 * @param  {event} event - The react node event.
 * @param  {Array} list -  The list to check if list is complete.
 * @param  {number} count - Total items from pagination.
 * @param  {boolean} loadingPage - If its already loading the page;.
 * @returns {boolean|boolean} Boolean.
 */
export const canLoadPageOnScroll = (event, list, count, loadingPage) => {
  const { target } = event;
  const isListCompleted = count <= list.length;
  const distanceToBottom = target.clientHeight - (target.scrollHeight - target.scrollTop);
  const isBottomReached = distanceToBottom <= 10 && distanceToBottom >= -10;
  // console.log('isBottomReached:', isBottomReached);
  // console.log('isListCompleted:', isListCompleted, count, list.length);
  return isBottomReached && !isListCompleted && !loadingPage;
};

/**
 * Helper to selected  item Menu.
 *
 * @param {string} url - Url String.
 * @param {object}compare - Compare object.
 * @returns {boolean} Boolean.
 */
export const checkUrl = (url, compare) => {
  let aux = false;
  if (typeof compare === 'object') {
    compare.forEach((value) => {
      if (url.pathname.search(value) >= 1) {
        aux = true;
      }
    });
  } else {
    if (url.pathname.search(compare) > 0) {
      aux = true;
    }
  }
  return aux;
};

/**
 * Save forms to local storage ignoring the fields send in propertiesToReset
 * and setting it to its default value from model, to ignore the Files from 8base.
 *
 * @param  {string} itemName - Item name.
 * @param  {object} data - Data.
 * @param  {object} model - Model.
 * @param  {Array}  propertiesToReset - Properties To reset.
 */
export const saveFormToSessionStorage = (itemName, data, model, propertiesToReset) => {
  const dataCopy = clone(data);
  for (const prop in dataCopy) {
    if (dataCopy.hasOwnProperty(prop)) {
      if (BigInt.isInstance(dataCopy[prop])) {
        dataCopy[prop] = dataCopy[prop].toString();
      }
      if (dataCopy[prop] && dataCopy[prop].fileId) {
        dataCopy[prop] = null;
      }
      if (propertiesToReset.includes(prop)) dataCopy[prop] = model[prop];
    }
  }

  sessionStorage.setItem(itemName, JSON.stringify(dataCopy));
};

export const b64toBlob = (dataURI, type) => {
  var byteString = atob(dataURI.split(',')[1]);
  var ab = new ArrayBuffer(byteString.length);
  var ia = new Uint8Array(ab);

  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  return new Blob([ab], { type: type });
};

export const downloadFile = (blob, tempLink, filename) => {
  let csvURL = window.URL.createObjectURL(blob);
  tempLink = document.createElement('a');
  tempLink.href = csvURL;
  tempLink.setAttribute('download', filename);
  tempLink.click();
};

export const ownerToString = (owner) => {
  if (!owner) return '';
  return formatUser(owner);
};

export const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1);

export const exportToFile = (fileData, fileName, format) => {
  let newFile;
  let downloadLink;

  // NEW FILE
  newFile = new Blob([fileData], { type: format ? format : '' });

  // Download link
  downloadLink = document.createElement('a');

  // File name
  downloadLink.download = fileName;

  // We have to create a link to the file
  downloadLink.href = window.URL.createObjectURL(newFile);

  // Make sure that the link is not displayed
  downloadLink.style.display = 'none';

  // Add the link to your DOM
  document.body.appendChild(downloadLink);

  downloadLink.click();
};

/**
 * Compare if 2 emails are equal.
 *
 * @param  {string} email - Email.
 * @param  {string} emailToCompare - EmailToCompare.
 * @returns {boolean} Boolean.
 */
export const areEmailsEqual = (email, emailToCompare) => {
  if (!email || !emailToCompare) return false;
  return email.toLowerCase() === emailToCompare.toLowerCase();
};

export const canShowOnBoarding = (user, selectedAlliance, metas) => {
  const role = getRoleOnAlliance(user, selectedAlliance);
  let showOnBoarding = true;
  if (role) {
    if (role.name !== ALLIANCE_ADMINISTRATOR && role.name !== ALLIANCE_SER) {
      showOnBoarding = false;
    }
  }
  if (user.didSawOnboarding) {
    showOnBoarding = false;
  }
  return showOnBoarding;
};

/**
 * Get format document to base64.
 *
 * @param {File}file - Document.
 * @returns {File} Document.
 */
export const documentFormatBase64 = (file: File) => {
  const reader = new FileReader();

  reader.addEventListener(
    'load',
    () => {
      file.base64 = reader.result;
    },
    false,
  );

  reader.readAsDataURL(file);

  return file;
};

/**
 * Fikter year.
 *
 * @param {string}year - Date for YYYY or YYYY/MM/DD  ex: year : 2019 or 2019/12/31.
 * @param {string}fieldName - Reference for use field in 8base.
 * @param {boolean}isDateTime - If the field is date/time or normal date.
 * @returns {{AND: [{}, {}]}}  Array Object.
 */
export const filterForYear = (year, fieldName = 'createdAt', isDateTime = true) => {
  let gte;
  let lte;

  if (isDateTime) {
    gte = `${year}-01-01T00:00:00.000Z`;
    lte = `${year}-12-31T00:00:00.000Z`;
  } else {
    gte = `${year}-01-01`;
    lte = `${year}-12-31`;
  }

  return {
    AND: [{ [fieldName]: { lte } }, { [fieldName]: { gte } }],
  };
};

/**
 * Transforms value into a String.
 *
 * @param {string}value - Value.
 * @returns {string|bigint} Value.
 */
export const stringify8BaseBigInt = (value) => {
  if (!value) return '0';
  return value.toString();
};

/**
 * Sanitize8BaseBigInt by using stringify8BaseBigInt, for BigInt fields.
 *
 * @param {object} data - Object data.
 * @param {string} fieldName - The fieldName to sanitize to string.
 */
export const sanitize8BaseBigInt = (data, fieldName) => {
  data[fieldName] = stringify8BaseBigInt(data[fieldName]);
};

/**
 * Sanitize8BaseBigInts by using stringify8BaseBigInt, for BigInt arrays.
 *
 * @param {object} data - Object Data.
 * @param {string} fieldName - The fieldName to sanitize values to string.
 */
export const sanitize8BaseBigInts = (data, fieldName) => {
  data[fieldName] = data[fieldName].map(stringify8BaseBigInt);
};

/**
 * Calculates The converted Value.
 *
 * @param {number}unitQuantity - Unit.
 * @param {number}unitMonetizationFactor - Unit.
 * @returns {string} String.
 */
export const calculateValueBigInt = (unitQuantity, unitMonetizationFactor) => {
  const _unitQuantity = unitQuantity || 0;
  const _unitMonetizationFactor = unitMonetizationFactor || '0';

  return BigInt(parseInt(_unitQuantity))
    .multiply(_unitMonetizationFactor)
    .toString();
};

export const showMonths = (i) => {
  let months = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];
  return months[i];
};

/**
 * Checks if a KPI has a currency type.
 *
 * @param {string} type - The type of the KPI.
 * @returns {boolean} - Wether the KPI has a currency type or not.
 */
export const isCurrencyTypeKPI = (type) => KPI_WITH_CURRENCY_TYPES.includes(type);

/**
 * Checks if a KPI has a percentage type.
 *
 * @param {string} type - The type of the KPI.
 * @returns {boolean} - Wether the KPI has a percentage type or not.
 */
export const isPercentageTypeKPI = (type) => KPI_WITH_PERCENTAGE_TYPES.includes(type);

/**
 * Get the quantity of decimals allowed for a specific KPI type.
 *
 * @param {string} type - The type of the KPI.
 * @returns {number} - The decimals allowed for the KPI.
 */
export const getAllowedDecimalsForKPI = (type) => ALLOWED_DECIMALS_BY_KPI[type];
/**
 * Validate item for submit for approval item.
 *
 * @param {object}item - Amo Item.
 * @returns {{userAdminOrSER: (boolean|*), allianceCompleted: boolean, initiativesUnapproved: boolean}} Boolean.
 */
export const validateSubmitForApprovalItem = (item) => {
  const { selectedAlliance, user } = sessionStore.getState(NEW_SESSION_EVENT);

  return {
    allianceCompleted: isAllianceCompleted(selectedAlliance),
    userAdminOrSER: isUserAdminOrSERInAlliance(user, selectedAlliance),
    initiativesUnapproved: validateInitiativeFoSubmitForApproval(item.initiatives),
  };
};

/**
 * Validate Initiative for submit for approval.
 *
 * @param {object}initiative - Selected initiatives for AMO Item.
 * @returns {boolean}Boolean.
 */
const validateInitiativeFoSubmitForApproval = (initiative) => {
  try {
    initiativesApprovedValidator(initiative);
  } catch (e) {
    return true;
  }
  return false;
};

/**
 * Generate Excel and Download.
 *
 * @param  {object} data - Data to export excel.
 * @param  {object} columnsObject - Object.
 * @param  {object} columns - Columns Excel Header.
 * @param  {string}  fileName - File Name.
 */
export const generatedExcel = (data, columnsObject, columns, fileName) => {
  const workbook = new Excel.Workbook();

  const worksheet = workbook.addWorksheet(fileName);

  worksheet.columns = columns;

  data.forEach((value) => {
    for (let key in value) {
      const result = columnsObject.find((data) => data.key === key);
      if (result !== undefined) {
        for (let i in result.value) {
          for (let aux in result.value[i]) {
            //if it is an object you need to iterate the value
            if (typeof result.value[i][aux] === 'object') {
              for (let aux1 in result.value[i][aux]) {
                if (value[key] !== null && value[key][aux] !== null) {
                  value[aux1] = Array.isArray(value[key][aux][result.value[i][aux][aux1]])
                    ? value[key][aux][result.value[i][aux][aux1]][0]
                    : value[key][aux][result.value[i][aux][aux1]];
                }
              }
            } else {
              //if value is a list, show first in the list
              if (value[key] !== null && Array.isArray(value[key][result.value[i][aux]])) {
                value[aux] = value[key] === null ? '' : value[key][result.value[i][aux]][0];
              } else {
                value[aux] = value[key] === null ? '' : value[key][result.value[i][aux]];
              }
            }
          }
        }
      }
    }
    worksheet.addRow(value);
  });

  // Save excel file on the computer
  workbook.xlsx
    .writeBuffer()
    .then((buffer) => FileSaver.saveAs(new Blob([buffer]), `${fileName}.xlsx`))
    .catch((err) => console.log('Error writing excel export', err));
};

/**
 * Remove whitespace from both sides of a email string.
 *
 * @param {string} email - The email to trim.
 * @returns {string} The email with no spaces.
 */
export const trimEmail = (email) => {
  return typeof email === 'string' ? email.trim() : email;
};

/**
 * Remove character ( - ) and upper case letter.
 *
 * @param {string}word - Word.
 * @returns {string} String.
 */
export const sanitizeSlug = (word) => {
  if (word.includes('-')) word = word.replace('-', ' ');
  return word.toLowerCase().replace(/\b[a-z]/g, (letter) => {
    return letter.toUpperCase();
  });
};

export const formatUser = (user) => {
  if (user && typeof user !== 'string') {
    return `${user.firstName} ${user.lastName}`;
  }
  return user;
};

export const fileSizeToMegabytes = (fileSize) => {
  return Math.round((fileSize / 1024 / 1024) * 100) / 100;
};
