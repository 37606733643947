import {
  STAGE_MAPPING_DELETE_MUTATION,
  STAGE_MAPPING_VALUES_CREATE_MUTATION,
  DEAL_STAGE_LIST_QUERY,
  DEAL_STAGE_VALUES_LIST_QUERY,
  DEAL_STAGES_LIST_QUERY,
} from './stage-mapping-queries';
import sessionStore, { APOLLO_CLIENT, NEW_SESSION_EVENT } from '../../../shared/SessionStore';
import { error, log } from '@cobuildlab/pure-logger';
import Flux from '@cobuildlab/flux-state';
import {
  STAGE_MAPPING_ERROR_EVENT,
  STAGE_MAPPING_DETAIL_EVENT,
  STAGE_MAPPING_UPDATE_EVENT,
} from './stage-mapping-store';
import { isSomeValueDuplicated } from './stage-mapping-utils';
import {
  OnStageMappingDetail,
  OnStageMappingError,
  OnStageMappingUpdate,
} from './stage-mapping-events';

/**
 * Get dealStageList and filter for id on dealStageValues.
 *
 * @param {string} companyId - The Id of the Company.
 * @returns {Promise<boolean|*>} - The result.
 */
export const fetchStageMapping = async (companyId) => {
  const client = sessionStore.getState(APOLLO_CLIENT);

  let response;
  const filter = { company: { id: { equals: companyId } } };

  // Get dealStageValuesList
  try {
    response = await client.mutate({
      mutation: DEAL_STAGE_VALUES_LIST_QUERY,
      variables: { filter },
    });
  } catch (e) {
    error('fetchStageMapping:dealStageValues', e);
    OnStageMappingError.dispatch(e);
    return Flux.dispatchEvent(STAGE_MAPPING_DETAIL_EVENT, e);
  }

  const { dealStageValuesList } = response.data;

  // get dealStagesList
  try {
    response = await client.mutate({
      mutation: DEAL_STAGE_LIST_QUERY,
    });
  } catch (e) {
    error('fetchStageMapping:dealStagesList', e);
    OnStageMappingError.dispatch(e);
    return Flux.dispatchEvent(STAGE_MAPPING_DETAIL_EVENT, e);
  }
  const { dealStagesList } = response.data;

  response.data.dealStagesList = sanitizeDealStageList(dealStagesList, dealStageValuesList);

  log('fetchStageMapping', response);
  OnStageMappingDetail.dispatch(response.data);
  Flux.dispatchEvent(STAGE_MAPPING_DETAIL_EVENT, response.data);
  return response.data;
};

/**
 * Find values for stages.
 *
 * @param {object}dealStagesList - The list.
 * @param {Array}dealStageValuesList - The stages.
 * @returns {*} - The result.
 */
const sanitizeDealStageList = (dealStagesList, dealStageValuesList) => {
  dealStagesList.items.forEach((dealStage) => {
    const values = dealStageValuesList.items.filter(
      (dealStageValue) => dealStageValue.dealStageDealStageValuesRelation.id === dealStage.id,
    );
    if (values.length) {
      dealStage.values = values;
    } else {
      dealStage.values = [{ value: dealStage.name }];
    }
  });

  return dealStagesList;
};

/**
 * Create value for each dealStage.
 *
 * @param {object}client - The client.
 * @param {Array}dealStage - : deal Stage List [ {description, winCriteria , ...}].
 * @param {Array}values - : deal Stage Values List [ ].
 * @param {string}companyId - The company Id.
 * @returns {Promise<void|*>} - The response.
 */
export const createDealStageValue = async (client, dealStage, values, companyId) => {
  if (values.length !== 0) {
    for (let i = 0, j = values.length; i < j; i++) {
      const data = {
        value: values[i],
        company: { connect: { id: companyId } },
        dealStageDealStageValuesRelation: { connect: { id: dealStage.id } },
      };

      try {
        await client.mutate({
          mutation: STAGE_MAPPING_VALUES_CREATE_MUTATION,
          variables: { data },
        });
      } catch (e) {
        error('updateStageMapping', e);
        return Flux.dispatchEvent(STAGE_MAPPING_ERROR_EVENT, e);
      }
    }
  }

  return dealStage;
};

/**
 * Fetch Deal Stage List.
 *
 * @returns {Promise<void|*>}Promise.
 */
export const fetchDealStage = async () => {
  const client = sessionStore.getState(APOLLO_CLIENT);

  let response;
  try {
    response = await client.mutate({
      mutation: DEAL_STAGES_LIST_QUERY,
    });
  } catch (e) {
    error('fetchStageMapping:dealStagesList', e);
    return Flux.dispatchEvent(STAGE_MAPPING_DETAIL_EVENT, e);
  }

  Flux.dispatchEvent(STAGE_MAPPING_DETAIL_EVENT, response.data);
  OnStageMappingDetail.dispatch(response.data);

  return response.data;
};

/**
 * Relation values with deal stage.
 *
 * @param {Array}stageMappingData - Stage Mapping Data.
 * @param {Array}originalStageMappingValues - Original Stage Mapping Values.
 * @returns {Promise<void>} Promise.
 */
export const updateStageMapping = async (stageMappingData, originalStageMappingValues) => {
  const client = sessionStore.getState(APOLLO_CLIENT);
  const { user } = sessionStore.getState(NEW_SESSION_EVENT);
  const companyId = user.companyUserRelation.items[0].company.id;

  for (let i = 0, j = stageMappingData.length; i < j; i++) {
    const dealStage = stageMappingData[i];

    const _originalStageMappingValues = originalStageMappingValues[i].map((item) => item);
    const createNewValues = dealStage.values.filter((val) => {
      const isNewValue = !_originalStageMappingValues.some(({ value }) => value === val);
      const unsavedOriginalValue = _originalStageMappingValues.some(
        ({ value, id }) => value === val && !id,
      );

      return isNewValue || unsavedOriginalValue;
    }); // compare both list and return list of new values

    const deleteValues = originalStageMappingValues[i].filter(
      (val) => !dealStage.values.includes(val.value),
    ); // compare both list and return list of delete values

    if (deleteValues.length !== 0) {
      let response;
      for (let x = 0, y = deleteValues.length; x < y; x++) {
        if (deleteValues[x].id) {
          try {
            response = await client.mutate({
              mutation: STAGE_MAPPING_DELETE_MUTATION,
              variables: { data: { id: deleteValues[x].id, force: false } },
            });
          } catch (e) {
            error('updateStageMapping', e);
            OnStageMappingError.dispatch(e);
            return Flux.dispatchEvent(STAGE_MAPPING_ERROR_EVENT, e);
          }

          log('updateStageMapping===deleteData', response);
        }
      }
    }

    const allValues = stageMappingData.flatMap((dealStage) => dealStage.values);
    if (isSomeValueDuplicated(allValues)) {
      const e = new Error('Some stage value is duplicated');
      error('updateStageMapping', e);
      OnStageMappingError.dispatch(e);
      Flux.dispatchEvent(STAGE_MAPPING_ERROR_EVENT, e);
      return;
    }

    if (createNewValues.length !== 0) {
      await createDealStageValue(client, dealStage, createNewValues, companyId);
    }
  }
  OnStageMappingUpdate.dispatch();
  Flux.dispatchEvent(STAGE_MAPPING_UPDATE_EVENT);
};
