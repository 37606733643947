import sessionStore, { APOLLO_CLIENT, NEW_SESSION_EVENT } from '../../../shared/SessionStore';
import Flux from '@cobuildlab/flux-state';
import {
  ALL_DEAL_DATA_LIST_EVENT,
  DEAL_AUTO_SAVE_CREATE_ERROR_EVENT,
  DEAL_AUTO_SAVE_CREATE_EVENT,
  DEAL_COMPANIES_EVENT,
  DEAL_COMPLETED_EVENT,
  DEAL_CREATE_EVENT,
  DEAL_DELETE_EVENT,
  DEAL_DELETE_LIST_EVENT,
  DEAL_DETAIL_EVENT,
  DEAL_ERROR_EVENT,
  DEAL_IMPORTED_ERROR_EVENT,
  DEAL_IMPORTED_EVENT,
  DEAL_IMPORTED_HEADER_ERROR_EVENT,
  DEAL_IMPORTED_VALID_EVENT,
  DEAL_LIST_EVENT,
  DEAL_MONTHLY_SNAPSHOT_LIST_EVENT,
  DEAL_STAGES_EVENT,
  DEAL_UPDATE_EVENT,
  RELATED_DEAL_LIST_EVENT,
} from './deal-store';
import {
  ALL_DEAL_DATA_LIST_QUERY,
  DEAL_COMMENTS_QUERY,
  DEAL_COMPANIES_LIST_QUERY,
  DEAL_DATA_CREATE_MUTATION,
  DEAL_DATA_DELETE_MUTATION,
  DEAL_DATA_DETAIL_QUERY,
  DEAL_DATA_ID_LIST,
  DEAL_DATA_LIST_QUERY,
  DEAL_DATA_LIST_SLIM_QUERY,
  DEAL_DATA_SMALL_LIST_QUERY,
  DEAL_DATA_UPDATE_MUTATION,
  DEAL_MONTHLY_SNAPSHOT_LIST_QUERY,
  DEAL_SOURCE_ID_QUERY,
  DEAL_STAGE_LIST_QUERY,
  RELATED_DEALS_LIST_QUERY,
} from './deal-queries';
import { IntegrityError, ValidationError } from '../../../shared/errors';
import { error, log } from '@cobuildlab/pure-logger';
import {
  filterForYear,
  isValidString,
  sanitize8BaseReconnectsFromObjects,
  sanitize8BaseReference,
  sanitize8BaseReferencesFromObjects,
} from '../../../shared/utils';
import { canCompletedDeal, canDeleteDeal } from './deal-permissions';
import { DEAL_COMPLETED, DEAL_OPEN } from '../../../shared/status';
import * as Papa from 'papaparse';
import { DEAL_TYPE } from '../../../shared/item-types';
import {
  COMMENT_CREATE_EVENT,
  COMMENT_ERROR_EVENT,
  COMMENT_REQUEST_EVENT,
  COMMENTS_EVENT,
} from '../../comment/comment-store';
import { COMMENTS_CREATE_MUTATION } from '../../comment/comment-queries';
import {
  createAssociatedDealIdValidator,
  dealImportedHeaderValidate,
  dealImportedValidator,
  dealSourceIdValidator,
  dealValidator,
  updateAssociatedDealIdValidator,
} from './deal-validators';
import moment from 'moment';
import currency from 'currency.js';
import { RELATED_ITEM_UPDATE_MUTATION } from '../../related-item/related-item-queries';
import { hasActiveAllianceDecorator } from '../../../shared/decorators';
import * as R from 'ramda';
import { fetchStageMapping } from '../../settings/stage-mapping/stage-mapping-action';
import { replaceDealStage } from './deal-utils';
import { initiativesItemValidator } from '../initiative/initiative-validators';
import { getCurrencyOnSession } from '../../../shared/alliance-utils';
import {
  OnDealsList,
  OnDealError,
  OnDealDelete,
  OnDealStageList,
  OnDealCompaniesList,
  OnDealDeleteList,
  OnDealsFullList,
  OnDealCreate,
  OnDealAutoSaveCreate,
  OnDealAutoSaveCreateError,
  OnDealDetail,
  OnDealRelatedList,
  OnDealComplete,
  OnDealUpdate,
  OnDealImported,
  OnDealImportedError,
  OnDealImportedHeaderError,
  OnDealImportedValid,
  OnAssociatedDeals,
  OnDealAllData,
} from './deal-events';
import {
  OnCommentError,
  OnCommentRequest,
  OnCommentCreate,
  OnComment,
} from '../../comment/comment-events';

/**
 * Returns the monthly data for deals Data.
 *
 * @returns {Promise<Array|void>}Promise.
 */
const _fetchDealMonthlySnapShots = async () => {
  const client = sessionStore.getState(APOLLO_CLIENT);
  const { selectedAlliance } = sessionStore.getState(NEW_SESSION_EVENT);
  const currentYear = new Date().getFullYear();
  const filter = {
    alliance: { id: { equals: selectedAlliance.id } },
    year: { in: [currentYear, currentYear - 1] },
  };

  let response;
  try {
    response = await client.query({
      query: DEAL_MONTHLY_SNAPSHOT_LIST_QUERY,
      fetchPolicy: 'network-only',
      variables: { data: filter },
    });
  } catch (e) {
    error('fetchDealMonthlySnapShots', e);
    return Flux.dispatchEvent(DEAL_ERROR_EVENT, e);
  }
  log('fetchDealMonthlySnapShots', response);
  Flux.dispatchEvent(
    DEAL_MONTHLY_SNAPSHOT_LIST_EVENT,
    response.data.dealMonthlySnapshotsList.items,
  );
  return response.data.dealMonthlySnapshotsList.items;
};

export const fetchDealMonthlySnapShots = hasActiveAllianceDecorator(
  _fetchDealMonthlySnapShots,
  DEAL_ERROR_EVENT,
);

/**
 * Creates a filter object and search by a string
 on string properties of the deal.
 *
 * @param {object} data -  Data to create filter object.
 
 * @returns {object}  The filter object.
 */
export const dealFilter = ({ allianceId, search = '', stage, companyId, filterYear = null }) => {
  const filter = {
    itemDealDataRelation: { alliance: { id: { equals: allianceId } } },
    status: { equals: DEAL_OPEN },
    OR: [
      { name: { contains: search } },
      { owner: { firstName: { contains: search } } },
      { owner: { lastName: { contains: search } } },
      {
        owner: {
          email: { contains: search },
        },
      },
      {
        description: { contains: search },
      },
      {
        customerName: { contains: search },
      },
      {},
    ],
  };

  if (filterYear) {
    const filterForYearData = filterForYear(
      filterYear.year,
      filterYear.fieldName,
      filterYear.isDateTime,
    );

    filter.AND = filterForYearData.AND;
  }

  if (stage) {
    filter.stage = { equals: stage };
  }
  if (companyId) {
    filter.company = { id: { equals: companyId } };
  }

  return filter;
};
/**
 * Creates a filter object and filters the deals associated to the selected deal by dealSourceId or dealId.
 *
 * @param {string} allianceId -  The allianceId to filter.
 * @param {string}sourceId - Source Id.
 * @param {string}dealId - Deal Id.
 * @returns {object}             The filter object.
 */
const associatedDealFilter = (allianceId, sourceId, dealId) => {
  const filter = {
    itemDealDataRelation: { alliance: { id: { equals: allianceId } } },
    status: { equals: DEAL_OPEN },
    OR: [
      {
        associatedDealId: { equals: sourceId },
      },
      {
        AND: [
          {
            associatedDealId: { equals: dealId },
          },
        ],
      },
    ],
  };

  return filter;
};

/**
 * Create a comment on a Deal.
 *
 * @param {string}dealId - Deal Id.
 * @param {string}comment - Comment.
 * @returns {Promise<*>} Promise.
 */
export const createDealComment = async (dealId, comment) => {
  const client = sessionStore.getState(APOLLO_CLIENT);
  const data = {
    comment,
    dealDataCommentsRelation: { connect: { id: dealId } },
  };
  let response;
  try {
    response = await client.mutate({
      mutation: COMMENTS_CREATE_MUTATION,
      variables: { data },
    });
  } catch (e) {
    error('createDealComment', e);
    OnCommentError.dispatch(e);
    Flux.dispatchEvent(COMMENT_ERROR_EVENT, e);
    throw e;
  }
  log('createDealComment', response);
  OnCommentCreate.dispatch(response.data);
  Flux.dispatchEvent(COMMENT_CREATE_EVENT, response.data);
  return response.data;
};
/**
 * Fetches the Deal Comments.
 *
 * @param {string}dealId - Deal Id.
 * @returns {Promise<void>} Promise - Promise.
 */
export const fetchDealComments = async (dealId) => {
  const client = sessionStore.getState(APOLLO_CLIENT);
  const data = { id: dealId };
  const { user } = sessionStore.getState(NEW_SESSION_EVENT);

  let response;
  try {
    response = await client.query({
      query: DEAL_COMMENTS_QUERY,
      variables: data,
      fetchPolicy: 'network-only',
    });
  } catch (e) {
    error('fetchDealComments', e);
    OnCommentError.dispatch(e);
    return Flux.dispatchEvent(COMMENT_ERROR_EVENT, e);
  }
  log('fetchDealComments', response);

  const comments = R.clone(response.data.dealDatum.comments);
  comments.items = response.data.dealDatum.comments.items.map((comment) => ({
    ...comment,
  }));

  comments.userId = user.id;
  OnComment.dispatch(comments);

  Flux.dispatchEvent(COMMENTS_EVENT, comments);
  return comments;
};

/**
 * Notifies a Request for Comments for a Decision.
 */
export const openComments = ({ id: dealId }) => {
  OnCommentRequest.dispatch({ type: DEAL_TYPE, id: dealId });
  Flux.dispatchEvent(COMMENT_REQUEST_EVENT, { type: DEAL_TYPE, id: dealId });
};

/**
 * Fetches the Stage of all the Deals and filter the repeated elements.
 *
 * @returns {Promise<void>}Promise.
 */
export const fetchDealsStages = async () => {
  const client = sessionStore.getState(APOLLO_CLIENT);
  const { selectedAlliance } = sessionStore.getState(NEW_SESSION_EVENT);

  if (!selectedAlliance || !isValidString(selectedAlliance.id)) {
    OnDealError.dispatch(new IntegrityError('An Alliance must be selected'));
    return Flux.dispatchEvent(DEAL_ERROR_EVENT, new IntegrityError('An Alliance must be selected'));
  }

  const filter = {
    itemDealDataRelation: { alliance: { id: { equals: selectedAlliance.id } } },
  };

  let response;

  try {
    response = await client.query({
      query: DEAL_STAGE_LIST_QUERY,
      fetchPolicy: 'network-only',
      variables: { data: filter },
    });
  } catch (e) {
    error('fetchDealsData', e);
    OnDealError.dispatch(e);
    return Flux.dispatchEvent(DEAL_ERROR_EVENT, e);
  }

  // Repeated Stages Filter
  const stages = [...new Set(response.data.dealDataList.items.map((deal) => deal.stage))];
  stages.unshift('');
  log('fetchDealsData', response);

  Flux.dispatchEvent(DEAL_STAGES_EVENT, stages);
  OnDealStageList.dispatch(stages);

  console.log(stages);
  return stages;
};
/**
 * Fetch companies.
 *
 * @returns {Promise<void>}Promise.
 */
export const fetchDealsCompanies = async () => {
  const client = sessionStore.getState(APOLLO_CLIENT);
  const { selectedAlliance } = sessionStore.getState(NEW_SESSION_EVENT);
  if (!selectedAlliance || !isValidString(selectedAlliance.id)) {
    OnDealError.dispatch(new IntegrityError('An Alliance must be selected'));
    return Flux.dispatchEvent(DEAL_ERROR_EVENT, new IntegrityError('An Alliance must be selected'));
  }

  const filter = {
    itemDealDataRelation: { alliance: { id: { equals: selectedAlliance.id } } },
  };

  let response;
  try {
    response = await client.query({
      query: DEAL_COMPANIES_LIST_QUERY,
      fetchPolicy: 'network-only',
      variables: { data: filter },
    });
  } catch (e) {
    error('fetchDealsData', e);
    OnDealError.dispatch(e);
    return Flux.dispatchEvent(DEAL_ERROR_EVENT, e);
  }

  // Repeated Stages Filter
  const companies = [...new Set(response.data.dealDataList.items.map((deal) => deal.company))];
  companies.unshift({ id: '', name: 'Combined Companies' });
  OnDealCompaniesList.dispatch(companies);
  Flux.dispatchEvent(DEAL_COMPANIES_EVENT, companies);
  return companies;
};

/**
 * Fetches the Deals Data List.
 *
 * @param {string}search - Search.
 * @param {number}page - Page.
 * @param {number}first - First.
 * @param {boolean}slim - Slim.
 * @param {string}stage - Stage.
 * @param {string}companyId - Company Id.
 * @returns {Promise<void>}Promise.
 */
export const fetchDealsData = async (
  search = '',
  page = 1,
  first = 20,
  slim = false,
  stage,
  companyId,
) => {
  const client = sessionStore.getState(APOLLO_CLIENT);
  const { selectedAlliance } = sessionStore.getState(NEW_SESSION_EVENT);
  const skip = (page - 1) * first;
  if (!selectedAlliance || !isValidString(selectedAlliance.id)) {
    OnDealError.dispatch(new IntegrityError('An Alliance must be selected'));

    return Flux.dispatchEvent(DEAL_ERROR_EVENT, new IntegrityError('An Alliance must be selected'));
  }

  const filter = dealFilter({ allianceId: selectedAlliance.id, search, stage, companyId });

  let response;
  try {
    response = await client.query({
      query: slim ? DEAL_DATA_LIST_SLIM_QUERY : DEAL_DATA_LIST_QUERY,
      fetchPolicy: 'network-only',
      variables: { data: filter, skip, first },
    });
    response.data.page = page;
    response.data.first = first;
    response.data.search = search;
  } catch (e) {
    error('fetchDealsData', e);
    OnDealError.dispatch(e);
    return Flux.dispatchEvent(DEAL_ERROR_EVENT, e);
  }

  log('fetchDealsData', response);
  OnDealsList.dispatch(response.data);
  Flux.dispatchEvent(DEAL_LIST_EVENT, response.data);
  return response.data;
};

/**
 * Fetches the Deals Data List.
 *
 * @param {string} search - Search.
 * @param {number} page - Page.
 * @param {number} first - First.
 * @param {boolean} fullList - FullList.
 * @param {string} stage - Stage.
 * @param {string} companyId - Company Id.
 * @param {object} options - Object options.
 * @returns {Promise<void>} Promise.
 */
export const fetchDealsList = async (
  search = '',
  page = 1,
  first = 50,
  fullList = false,
  stage,
  companyId,
  options = {},
) => {
  const client = sessionStore.getState(APOLLO_CLIENT);
  const { selectedAlliance } = sessionStore.getState(NEW_SESSION_EVENT);
  const fetchPolicy = options.isCacheFirst ? 'cache-first' : 'network-only';

  if (!(selectedAlliance && selectedAlliance.id)) {
    OnDealError.dispatch(new IntegrityError('An Alliance must be selected'));
    return Flux.dispatchEvent(DEAL_ERROR_EVENT, new IntegrityError('An Alliance must be selected'));
  }

  const skip = (page - 1) * first;
  const filter = dealFilter({ allianceId: selectedAlliance.id, search, stage, companyId });

  try {
    const { data } = await client.query({
      query: !fullList ? DEAL_DATA_SMALL_LIST_QUERY : DEAL_DATA_LIST_QUERY,
      fetchPolicy: fetchPolicy,
      variables: { data: filter, skip, first },
    });

    const response = { ...data };

    log('fetchDealsData', response);
    Flux.dispatchEvent(DEAL_LIST_EVENT, response);
    OnDealsList.dispatch(response);
    return response;
  } catch (e) {
    error('fetchDealsData', e);
    console.log('fetchDealsData', e);
    Flux.dispatchEvent(DEAL_ERROR_EVENT, e);
    OnDealError.dispatch(e);
  }
};
/**
 * Fetches the Associated Deals list.
 *
 * @param {boolean}slim - Slim.
 * @param {string}dealSourceId - Deal Source Id.
 * @param {string}dealId - Deal Id.
 * @returns {Promise<void>}Promise.
 */
export const fetchAssociatedDeals = async (slim = false, dealSourceId, dealId) => {
  const client = sessionStore.getState(APOLLO_CLIENT);
  const { selectedAlliance } = sessionStore.getState(NEW_SESSION_EVENT);
  if (!selectedAlliance || !isValidString(selectedAlliance.id)) {
    OnDealError.dispatch(new IntegrityError('An Alliance must be selected'));
    return Flux.dispatchEvent(DEAL_ERROR_EVENT, new IntegrityError('An Alliance must be selected'));
  }

  const filter = associatedDealFilter(selectedAlliance.id, dealSourceId, dealId, '');

  let response;
  try {
    response = await client.query({
      query: slim ? DEAL_DATA_LIST_SLIM_QUERY : DEAL_DATA_LIST_QUERY,
      fetchPolicy: 'network-only',
      variables: { data: filter },
    });
  } catch (e) {
    error('fetchDealsData', e);
    OnDealError.dispatch(e);
    return Flux.dispatchEvent(DEAL_ERROR_EVENT, e);
  }

  log('fetchDealsData', response);
  OnAssociatedDeals.dispatch(response.data);
  Flux.dispatchEvent(DEAL_LIST_EVENT, response.data);
  return response.data;
};
/**
 * Fetch deal data by year.
 *
 * @param {string}search - Search.
 * @param {string}filterYear - Filter Year.
 * @returns {Promise<void|*>} Promise.
 */
export const fetchAllDealsData = async (search = '', filterYear = null) => {
  const client = sessionStore.getState(APOLLO_CLIENT);
  const { selectedAlliance } = sessionStore.getState(NEW_SESSION_EVENT);
  if (!selectedAlliance || !isValidString(selectedAlliance.id)) {
    OnDealError.dispatch(new IntegrityError('An Alliance must be selected'));

    return Flux.dispatchEvent(DEAL_ERROR_EVENT, new IntegrityError('An Alliance must be selected'));
  }

  const filter = dealFilter({ allianceId: selectedAlliance.id, search, filterYear });

  let response;
  try {
    response = await client.query({
      query: ALL_DEAL_DATA_LIST_QUERY,
      fetchPolicy: 'network-only',
      variables: { data: filter },
    });
  } catch (e) {
    error('fetchDealsData', e);
    OnDealError.dispatch(e);
    Flux.dispatchEvent(DEAL_ERROR_EVENT, e);
    throw e;
  }

  log('fetchDealsData', response);
  OnDealAllData.dispatch(response.data);
  Flux.dispatchEvent(ALL_DEAL_DATA_LIST_EVENT, response.data);
  return response.data;
};

/**
 * Fetches the Deals Id for this Alliance.
 *
 * @returns {Promise<void>}Promise.
 * @private
 */
const _fetchDealsId = async () => {
  const client = sessionStore.getState(APOLLO_CLIENT);
  const { selectedAlliance } = sessionStore.getState(NEW_SESSION_EVENT);
  if (!selectedAlliance || !isValidString(selectedAlliance.id))
    return Flux.dispatchEvent(DEAL_ERROR_EVENT, new IntegrityError('An Alliance must be selected'));

  const filter = { itemDealDataRelation: { alliance: { id: { equals: selectedAlliance.id } } } };
  let response;
  try {
    response = await client.query({
      query: DEAL_DATA_ID_LIST,
      fetchPolicy: 'network-only',
      variables: { data: filter },
    });
  } catch (e) {
    error('_fetchDealsId', e);
    throw e;
  }
  log('_fetchDealsId', response.data.dealDataList.items);
  return response.data.dealDataList.items;
};

/**
 * Fetch Related Deals.
 *
 * @param {object}deal - Deal.
 * @returns {Promise<Array|void|*>}Promise.
 */
export const fetchRelatedDeals = async (deal) => {
  if (!isValidString(deal.associatedDealId)) {
    OnDealRelatedList.dispatch([]);
    Flux.dispatchEvent(RELATED_DEAL_LIST_EVENT, []);
    return [];
  }
  const { selectedAlliance } = sessionStore.getState(NEW_SESSION_EVENT);
  if (!selectedAlliance || !isValidString(selectedAlliance.id)) {
    OnDealError.dispatch(new IntegrityError('An Alliance must be selected'));
    return Flux.dispatchEvent(DEAL_ERROR_EVENT, new IntegrityError('An Alliance must be selected'));
  }

  const client = sessionStore.getState(APOLLO_CLIENT);
  const filter = {
    dealSourceId: { equals: deal.associatedDealId },
    itemDealDataRelation: { alliance: { id: { equals: selectedAlliance.id } } },
  };

  let response;
  try {
    response = await client.query({
      query: RELATED_DEALS_LIST_QUERY,
      fetchPolicy: 'network-only',
      variables: { data: filter },
    });
  } catch (e) {
    error('fetchRelatedDeals', e);
    OnDealError.dispatch(e);
    return Flux.dispatchEvent(DEAL_ERROR_EVENT, e);
  }

  log('fetchRelatedDeals', response);
  OnDealRelatedList.dispatch(response.data.dealDataList.items);
  Flux.dispatchEvent(RELATED_DEAL_LIST_EVENT, response.data.dealDataList.items);
  return response.data.dealDataList.items;
};

/**
 * Fetches a list of DealSourceIds for an specific company.
 *
 * @param {number}companyId - Company Id.
 * @returns {Promise<void>}Promise.
 */
const fetchDealsSourceId = async (companyId) => {
  const client = sessionStore.getState(APOLLO_CLIENT);
  const { selectedAlliance } = sessionStore.getState(NEW_SESSION_EVENT);

  const filter = {
    company: { id: { equals: companyId } },
    itemDealDataRelation: { alliance: { id: { equals: selectedAlliance.id } } },
  };
  console.log('fetchDealsSourceId:company', companyId);
  console.log('fetchDealsSourceId:alliance', selectedAlliance.id);
  let response;
  try {
    response = await client.query({
      query: DEAL_SOURCE_ID_QUERY,
      fetchPolicy: 'network-only',
      variables: { data: filter },
    });
  } catch (e) {
    error('fetchDealsSourceId', e);
    OnDealError.dispatch(e);
    return Flux.dispatchEvent(DEAL_ERROR_EVENT, e);
  }
  log('fetchDealsSourceId', response.data.dealDataList.items);
  return response.data.dealDataList.items;
};

/**
 * Fetches the Deal list with the Deal Source Id of only the Deals that can be related to other deals via the associatedDealId relation.
 *
 * @param {number}companyId - Company Id.
 * @returns {Promise<void>}Promise.
 */
export const fetchAvailableDealsSourceId = async (companyId) => {
  const client = sessionStore.getState(APOLLO_CLIENT);
  const { selectedAlliance } = sessionStore.getState(NEW_SESSION_EVENT);
  const filter = {
    company: { id: { equals: companyId } },
    itemDealDataRelation: { alliance: { id: { equals: selectedAlliance.id } } },
    AND: {
      OR: [{ associatedDealId: { equals: '' } }, { associatedDealId: { equals: null } }],
    },
  };

  let response;
  try {
    response = await client.query({
      query: DEAL_SOURCE_ID_QUERY,
      fetchPolicy: 'network-only',
      variables: { data: filter },
    });
  } catch (e) {
    error('fetchDealsSourceId', e);
    return Flux.dispatchEvent(DEAL_ERROR_EVENT, e);
  }
  log('fetchDealsSourceId', response.data.dealDataList.items);
  return response.data.dealDataList.items;
};

/**
 * Pairs columns name positions with Row position into an Object.
 *
 * @param {object}csvRow - Object.
 * @param {object}columns - Columns.
 * @returns Paired object: {name: 'a', address: 'b'...}.
 */

const pairDealCsvData = (csvRow, columns) => {
  const obj = {};
  for (let i = 0, j = columns.length; i < j; i++) {
    const column = columns[i];
    if (column === null) continue;
    const { value, header } = column;
    if (value !== null && value !== undefined) obj[value] = csvRow[header];
  }
  return obj;
};

/**
 * Creates a Deal Data with the Deal Data.
 *
 * @param {object}dealData - Deal Data.
 * @returns {Promise<*>}Promise.
 * @private
 */
const _createDeal = async (dealData) => {
  const client = sessionStore.getState(APOLLO_CLIENT);

  let response;
  try {
    response = await client.mutate({
      mutation: DEAL_DATA_CREATE_MUTATION,
      variables: { data: dealData },
    });
  } catch (e) {
    error('_createDeal', e, dealData);
    throw e;
  }
  return response.data.dealDatumCreate;
};

/**
 *
 * @param {object}client - Client.
 * @param {object}dealData - Deal Data.
 * @returns {Promise<*>}Promise.
 * @private
 */
const _updateDealData = async (client, dealData) => {
  let response;
  try {
    response = await client.mutate({
      mutation: DEAL_DATA_UPDATE_MUTATION,
      variables: { data: dealData },
    });
  } catch (e) {
    error('_updateDealData', e);
    throw e;
  }
  return response.data.dealDatumUpdate;
};

/**
 * Format a date to YYYY-MM-DD.
 *
 * @param {string}date - Date.
 * @returns {string}String.
 * @private
 */
const _changeFormatDate = (date) => {
  return moment(new Date(date)).format('YYYY-MM-DD');
};

/**
 * Normalize amount field.
 *
 * @param {object}dealData - Deal Data.
 * @param {string}currencyOnAlliance - : The alliance Currency.
 * @returns {*}Object.
 */
export const sanitizeDealDataFormatAmount = (dealData, currencyOnAlliance) => {
  const currencyOptions = {
    symbol: currencyOnAlliance.symbol,
    decimal: currencyOnAlliance.decimalSeparator,
    separator: currencyOnAlliance.decimalSeparator,
    precision: 0,
  };
  if (dealData.amount !== undefined) {
    dealData.amount = String(currency(dealData.amount, currencyOptions).value);
  }

  return dealData;
};

/**
 * Format the dates of the Deal to valid dates.
 *
 * @param {object}dealData - Deal Data.
 * @returns {*}Object.
 */
const sanitizeDealDataFormatDate = (dealData) => {
  if (moment(dealData.closeDate).isValid() && dealData.closeDate !== undefined) {
    dealData.closeDate = _changeFormatDate(dealData.closeDate);
  }
  if (moment(dealData.lastActivityDate).isValid() && dealData.lastActivityDate !== undefined) {
    dealData.lastActivityDate = _changeFormatDate(dealData.lastActivityDate);
  }

  if (moment(dealData.lastContactedDate).isValid() && dealData.lastContactedDate !== undefined) {
    dealData.lastContactedDate = _changeFormatDate(dealData.lastContactedDate);
  }

  if (moment(dealData.lastModifiedDate).isValid() && dealData.lastModifiedDate !== undefined) {
    dealData.lastModifiedDate = _changeFormatDate(dealData.lastModifiedDate);
  }

  if (moment(dealData.nextActivityDate).isValid() && dealData.nextActivityDate !== undefined) {
    dealData.nextActivityDate = _changeFormatDate(dealData.nextActivityDate);
  }

  if (moment(dealData.createdDate).isValid() && dealData.createdDate !== undefined) {
    dealData.createdDate = _changeFormatDate(dealData.createdDate);
  }
  return dealData;
};

/**
 * Creates the new deals from the csv data.
 *
 * @param {object} dealOwners - Deal Owners.
 * @param {Array} data - Data.
 * @param {object} company - The company who is importing the deals.
 * @param {Array} otherCompany - Other Company.
 * @returns {Promise<void|*>} Promise.
 */
export const createDealFromCSV = async (dealOwners, data, company, otherCompany) => {
  console.log('createDealFromCSV:data', { dealOwners, data, company, otherCompany });

  const client = sessionStore.getState(APOLLO_CLIENT);

  const { selectedAlliance } = sessionStore.getState(NEW_SESSION_EVENT);

  if (!(selectedAlliance && selectedAlliance.id)) {
    OnDealError.dispatch(new IntegrityError('An Alliance must be selected'));
    return Flux.dispatchEvent(DEAL_ERROR_EVENT, new IntegrityError('An Alliance must be selected'));
  }

  const dealsSourceIdListOnTheSystem = await fetchDealsSourceId(company.id);
  const {
    dealStagesList: { items: mappingList },
  } = await fetchStageMapping(company.id);
  const otherCompanyDealsSourceIds = otherCompany ? await fetchDealsSourceId(otherCompany.id) : [];

  log(`createDealFromCSV:dealsSourceIdListOnTheSystem`, dealsSourceIdListOnTheSystem);
  log(`createDealFromCSV:mappingList`, mappingList);
  log(`createDealFromCSV:otherCompanyDealsSourceIds`, otherCompanyDealsSourceIds);

  const dealsSourceIdMap = Object.fromEntries(
    dealsSourceIdListOnTheSystem.map((item) => [
      item.dealSourceId,
      { id: item.id, owner: item.owner },
    ]),
  );
  log(`createDealFromCSV:dealsSourceIdMap`, dealsSourceIdMap);

  let operations = 0;
  for (let i = 0; i < data.length; i++) {
    // Parsing the Deal into a valid object
    let dataObjectCreate;

    const dataObject = data[i];
    if (dataObject.numberOfSalesActivities) {
      dataObject.numberOfSalesActivities = +dataObject.numberOfSalesActivities;
    }

    if (dataObject.numberOfTimesContacted) {
      dataObject.numberOfTimesContacted = +dataObject.numberOfTimesContacted;
    }
    const dealReference = dataObject.dealSourceId && dealsSourceIdMap[dataObject.dealSourceId];
    const dealDataOwnerId = dealOwners[i];

    if (dataObject.stage) {
      console.log('replaceDealStage:dataObject.stage', dataObject.stage);
      dataObject.stage = replaceDealStage(mappingList, dataObject.stage);
    }

    if (!dealReference) {
      // CREATE NEW DEAL
      const data = {
        itemDealDataRelation: { create: { alliance: { connect: { id: selectedAlliance.id } } } },
        ...dataObject,
        company: { connect: { id: company.id } },
        owner: dealDataOwnerId ? { connect: { id: dealDataOwnerId } } : null,
      };

      console.log(`createDealFromCSV:for:create`, data);
      dataObjectCreate = await _createDeal(data);

      operations++;
    } else {
      //Update Deal Data
      const data = {
        id: dealReference.id,
        ...dataObject,
      };

      if (dealReference.owner) {
        if (dealDataOwnerId) data.owner = { reconnect: { id: dealDataOwnerId } };
        else data.owner = { disconnect: { id: dealReference.owner.id } };
      } else {
        if (dealDataOwnerId) data.owner = { connect: { id: dealDataOwnerId } };
      }

      console.log(`createDealFromCSV:for:update`, data);
      dataObjectCreate = await _updateDealData(client, data);

      operations++;
    }

    // If the deal has associatedDealId we update that deal represented by the associatedDealId
    // and put his associatedDealId as our dealSourceId
    if (dataObject.associatedDealId) {
      const _dealAssociated = otherCompanyDealsSourceIds.find(
        (item) => item.dealSourceId === dataObject.associatedDealId,
      );

      if (_dealAssociated) {
        _dealAssociated.associatedDealId = dataObject.dealSourceId
          ? dataObject.dealSourceId
          : dataObjectCreate.id;

        delete _dealAssociated.owner;
        delete _dealAssociated.__typename;
        console.log('_dealAssociated', _dealAssociated);

        // We remove any previous association. Maybe we are updating the associatedDealId
        // await removeDealAssociatedId(dataObject, client, R.clone(otherCompanyDealsSourceIds));

        await _updateDealData(client, _dealAssociated);
      }
    } else {
      // We check if our dealSourceID exists as associatedDealId to break any previous associations
      await removeDealAssociatedId(dataObject, client, R.clone(otherCompanyDealsSourceIds));
    }

    await client.resetStore();
    OnDealCreate.dispatch({ operations });
    Flux.dispatchEvent(DEAL_CREATE_EVENT, { operations });
    operations = 0;
  }
};

/**
 * Fetch a single Deal.
 *
 * @param {string} id - Deal id.
 * @param {object} options - Object options.
 * @returns {Promise} Promise.
 */
export const fetchDealData = async (id, options = {}) => {
  const client = sessionStore.getState(APOLLO_CLIENT);
  const fetchPolicy = options.isCacheFirst ? 'cache-first' : 'network-only';
  let response;

  try {
    response = await client.query({
      query: DEAL_DATA_DETAIL_QUERY,
      variables: { id },
      fetchPolicy,
    });
  } catch (e) {
    error('fetchDealData', e);
    OnDealError.dispatch(e);
    return Flux.dispatchEvent(DEAL_ERROR_EVENT, e);
  }
  log('fetchDealData', response);

  OnDealDetail.dispatch(response.data);
  Flux.dispatchEvent(DEAL_DETAIL_EVENT, response.data);
  return response.data;
};

/**
 * Convert csv data into Deal Objects for 8base.
 *
 * @param {Array}csvData - Csv Data.
 * @param {Array}columns - Columns.
 * @param {string}currencyOnAlliance - Currency.
 * @returns {[]}Promise.
 */
export const sanitizeCsvData = (csvData, columns, currencyOnAlliance) => {
  const currentDate = moment().format('YYYY-MM-DD');

  const data = csvData.map((csvRow) => {
    let dataObject = pairDealCsvData(csvRow, columns);
    dataObject = sanitizeDealDataFormatDate(dataObject);
    dataObject = sanitizeDealDataFormatAmount(dataObject, currencyOnAlliance);
    dataObject.createdDate = dataObject.createdDate ? dataObject.createdDate : currentDate;

    return dataObject;
  });

  data.forEach((item) => {
    if (!item.associatedDealId) {
      const dealAssociated = data.find((dt) => dt.associatedDealId === item.dealSourceId);
      if (dealAssociated && dealAssociated.dealSourceId) {
        item.associatedDealId = dealAssociated.dealSourceId;
      }
    }
  });

  return data;
};

/**
 * Delete an Deal.
 *
 * @param  {object}deal - The id of the Deal to be deleted.
 * @param  {object} otherCompany - Other Company.
 * @returns {Promise}Promise.
 */
export const deleteDeal = async (deal, otherCompany) => {
  const client = sessionStore.getState(APOLLO_CLIENT);
  const { user, selectedAlliance } = sessionStore.getState(NEW_SESSION_EVENT);
  const dealId = deal.id;

  if (!canDeleteDeal(user, deal, selectedAlliance)[0]) {
    OnDealError.dispatch(new IntegrityError(`Permission Denied. Can't Delete this Deal`));
    return Flux.dispatchEvent(
      DEAL_ERROR_EVENT,
      new IntegrityError(`Permission Denied. Can't Delete this Deal`),
    );
  }

  const otherCompanyDealsSourceIdList = otherCompany
    ? await fetchDealsSourceId(otherCompany.id)
    : [];
  await removeDealAssociatedId(deal, client, R.clone(otherCompanyDealsSourceIdList));

  let response;
  try {
    response = await client.mutate({
      mutation: DEAL_DATA_DELETE_MUTATION,
      variables: { data: { id: dealId, force: true } },
    });
  } catch (e) {
    error('deleteDeal', e);
    OnDealError.dispatch(e);
    return Flux.dispatchEvent(DEAL_ERROR_EVENT, e);
  }
  log('deleteDeal', response);
  await client.resetStore();
  OnDealDelete.dispatch(response.data);
  Flux.dispatchEvent(DEAL_DELETE_EVENT, response.data);
  return response.data;
};

/**
 * Close a Deal.
 *
 * @param {object}user - User.
 * @param {object}dealData - Deal Data.
 * @param {object}alliance - Alliance.
 * @returns {Promise}Promise.
 */
export const completedDeal = async (user, dealData, alliance) => {
  const client = sessionStore.getState(APOLLO_CLIENT);
  let response;

  if (!canCompletedDeal(user, dealData, alliance)) {
    OnDealError.dispatch(new IntegrityError('You do not have permission to perform this action'));

    return Flux.dispatchEvent(
      DEAL_ERROR_EVENT,
      new IntegrityError('You do not have permission to perform this action'),
    );
  }

  try {
    response = await client.mutate({
      mutation: DEAL_DATA_UPDATE_MUTATION,
      variables: {
        data: {
          id: dealData.id,
          status: DEAL_COMPLETED,
        },
      },
    });
  } catch (e) {
    error('completedDeal', e);
    OnDealError.dispatch(e);
    return Flux.dispatchEvent(DEAL_ERROR_EVENT, e);
  }

  await client.resetStore();
  OnDealComplete.dispatch(response.data);
  Flux.dispatchEvent(DEAL_COMPLETED_EVENT, response.data);
  return response.data;
};

/**
 * Parse CSV files for import process.
 *
 * @param {object}dealsCompany -  The company to associated the Deals.
 * @param {object}otherCompany -  The other Company Id. It may be null.
 * @param {object}files - Files.
 * @returns {Promise<void>}Promise.
 */
export const parseCSV = async (dealsCompany, otherCompany, files) => {
  if (dealsCompany === null || dealsCompany === undefined) {
    OnDealError.dispatch(
      new IntegrityError(
        'Please select A Company to assign to the the deals that are going to be imported.',
      ),
    );

    return Flux.dispatchEvent(
      DEAL_ERROR_EVENT,
      new IntegrityError(
        'Please select A Company to assign to the the deals that are going to be imported.',
      ),
    );
  }

  let fileCounter = 0;
  const data = [];
  let headers = null;

  files.forEach((file) => {
    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      step: (results) => {
        const cleanedData = results.data.filter((row) => {
          const keys = Object.keys(row);
          for (let i = 0, j = keys.length; i < j; i++) if (isValidString(row[keys[i]])) return true;
          return false;
        });
        data.push(...cleanedData);
        if (headers === null && results.meta.fields) headers = results.meta.fields;
      },
      complete: () => {
        if (++fileCounter === files.length) {
          OnDealImported.dispatch({ data, headers, dealsCompany, otherCompany });
          Flux.dispatchEvent(DEAL_IMPORTED_EVENT, { data, headers, dealsCompany, otherCompany });
        }
      },
      error: (error) => {
        console.log('parseCSV:Error:', error);

        OnDealError.dispatch(error);
        Flux.dispatchEvent(DEAL_ERROR_EVENT, error);
      },
    });
  });
};

/**
 * Creates a New Deal.
 *
 * @param {object} deal - Deal.
 * @param {Array} relatedItems - Related Items.
 * @param {Array} otherCompany - Other Company.
 * @param {Array} initiatives - Initiatives.
 * @param {object} savedDeal - Save deal.
 * @returns {Promise<void>} Promise.
 */
export const createNewDeal = async (deal, relatedItems, otherCompany, initiatives, savedDeal) => {
  const { selectedAlliance } = sessionStore.getState(NEW_SESSION_EVENT);
  const allianceId = selectedAlliance.id;
  const client = sessionStore.getState(APOLLO_CLIENT);

  if (!allianceId) {
    OnDealError.dispatch(new IntegrityError('Must have an Active Alliance'));
    return Flux.dispatchEvent(DEAL_ERROR_EVENT, new IntegrityError('Must have an Active Alliance'));
  }

  log(`createNewDeal:deal`, deal);

  try {
    dealValidator(deal, selectedAlliance);
  } catch (err) {
    error('createNewDeal', err);
    OnDealError.dispatch(err);
    return Flux.dispatchEvent(DEAL_ERROR_EVENT, err);
  }

  try {
    const result = await createOrUpdateDeal(
      deal,
      relatedItems,
      otherCompany,
      initiatives,
      savedDeal,
    );
    log('createNewDeal', result);
    await client.resetStore();
    OnDealCreate.dispatch(result);
    Flux.dispatchEvent(DEAL_CREATE_EVENT, result);
  } catch (e) {
    error('createNewDeal', e);
    OnDealError.dispatch(e);
    Flux.dispatchEvent(DEAL_ERROR_EVENT, e);
  }
};

/**
 * Creates a New Deal.
 *
 * @param {object} deal - Deal.
 * @param {Array} relatedItems - Related Items.
 * @param {Array} otherCompany - Other Company.
 * @param {object} initiatives - Initiatives.
 * @returns {Promise<void>} Promise.
 */
export const createDeal = async (deal, relatedItems, otherCompany, initiatives) => {
  const client = sessionStore.getState(APOLLO_CLIENT);
  const { selectedAlliance } = sessionStore.getState(NEW_SESSION_EVENT);
  const allianceId = selectedAlliance.id;

  deal = sanitizeDealDataFormatAmount(deal, selectedAlliance.currency);
  log(`createDeal:deal`, deal);

  const companyId = deal.company.id;

  const dealsSourceIdList = await fetchDealsSourceId(companyId);
  log(`createDeal:dealsSourceIdList`, dealsSourceIdList);
  const dealsSourceIdMap = {};
  dealsSourceIdList.forEach(
    (item) =>
      (dealsSourceIdMap[item.dealSourceId] = {
        id: item.id,
        owner: item.owner,
        associatedDealId: item.associatedDealId,
      }),
  );

  if (deal.dealSourceId) {
    dealSourceIdValidator(deal.dealSourceId, dealsSourceIdList);
    initiativesItemValidator(initiatives);
  }

  if (deal.associatedDealId && otherCompany === null)
    throw new IntegrityError(
      `You can't associate a Deal to another Deal. You only have one company in your alliance`,
    );

  if (deal.associatedDealId === deal.dealSourceId && deal.associatedDealId && deal.dealSourceId)
    throw new IntegrityError(`You can't associate with your self!`);

  let otherCompanyDealsSourceIds = [];

  if (deal.associatedDealId) {
    // validate if source id exists
    otherCompanyDealsSourceIds = await fetchDealsSourceId(otherCompany.id);
    createAssociatedDealIdValidator(
      deal.associatedDealId,
      dealsSourceIdList,
      otherCompanyDealsSourceIds,
    );
  }

  deal.initiatives = initiatives;
  sanitize8BaseReferencesFromObjects(deal, 'initiatives');
  sanitize8BaseReference(deal, 'owner');
  deal.company = { connect: { id: deal.company.id } };
  deal.itemDealDataRelation = {
    create: {
      alliance: { connect: { id: allianceId } },
      itemsRelated: {
        connect: relatedItems.map((item) => {
          return { id: item.itemId };
        }),
      },
    },
  };

  const dealDatumCreate = await _createDeal(deal);

  if (deal.associatedDealId) {
    deal.id = dealDatumCreate.id;
    await updateDealAssociatedId(deal, client, R.clone(otherCompanyDealsSourceIds));
  }

  log('createDeal', dealDatumCreate);
  return dealDatumCreate;
};

export const autoSaveCreateDeal = async (
  dealData,
  relatedItems,
  otherCompany,
  initiatives,
  savedDeal,
) => {
  const client = sessionStore.getState(APOLLO_CLIENT);

  try {
    const deal = await createOrUpdateDeal(
      dealData,
      relatedItems,
      otherCompany,
      initiatives,
      savedDeal,
    );

    await client.resetStore();
    OnDealAutoSaveCreate.dispatch(deal);
    Flux.dispatchEvent(DEAL_AUTO_SAVE_CREATE_EVENT, deal);
    console.log('autoSaveCreateDeal:response', deal);
    return deal;
  } catch (e) {
    OnDealAutoSaveCreateError.dispatch(e);
    Flux.dispatchEvent(DEAL_AUTO_SAVE_CREATE_ERROR_EVENT, e);
    console.error('autoSaveCreateDeal', e);
  }
};

export const autoSaveUpdateDeal = async (
  deal,
  relatedItems,
  otherCompany,
  originalAssociatedDealId,
  initiatives,
) => {
  console.log('autoSaveUpdateDeal', {
    deal,
    relatedItems,
    otherCompany,
    originalAssociatedDealId,
    initiatives,
  });

  const client = sessionStore.getState(APOLLO_CLIENT);

  try {
    const dealUpdated = await _updateDeal(
      deal,
      relatedItems,
      otherCompany,
      originalAssociatedDealId,
      initiatives,
    );

    await client.resetStore();
    OnDealAutoSaveCreate.dispatch(dealUpdated);
    Flux.dispatchEvent(DEAL_AUTO_SAVE_CREATE_EVENT, dealUpdated);
    console.log('autoSaveCreateDeal:response', dealUpdated);
    return dealUpdated;
  } catch (e) {
    console.error('autoSaveCreateDeal', e);
  }
};

export const createOrUpdateDeal = (
  dealData,
  relatedItems,
  otherCompany,
  initiatives,
  savedDeal,
) => {
  console.log('createOrUpdateDeal', {
    dealData,
    relatedItems,
    otherCompany,
    initiatives,
    savedDeal,
  });

  if (!savedDeal) {
    // Create
    return createDeal(dealData, relatedItems, otherCompany, initiatives);
  } else {
    // Update
    const originalAssociatedDealId = savedDeal.associatedDealId;
    dealData.id = savedDeal.id;
    dealData.itemId = savedDeal.itemDealDataRelation.id;
    return _updateDeal(dealData, relatedItems, otherCompany, initiatives, originalAssociatedDealId);
  }
};

/**
 * Before update changes delete fields that are not necessary and change type of value of amount
 * and numberOfSalesActivities to String.
 *
 * @param {object}deal - Deal.
 * @returns {*}Object.
 */
const sanitizeUpdateDealData = (deal) => {
  sanitize8BaseReference(deal, 'owner');
  delete deal.itemDealDataRelation;
  delete deal.__typename;
  delete deal.company;
  delete deal.createdAt;
  delete deal.createdBy;
  delete deal.updatedAt;
  return deal;
};

/**
 * Update Deal.
 *
 * @param {object} deal - Deal.
 * @param {Array} relatedItems - Related Items.
 * @param {Array} otherCompany - Other Company.
 * @param {Array} originalAssociatedDealId - Original Associated Deal id.
 * @param {object} initiatives - Initiatives.
 * @returns {Promise<void|*>} Promise.
 */
export const updateDeal = async (
  deal,
  relatedItems,
  otherCompany,
  originalAssociatedDealId,
  initiatives,
) => {
  console.log(`updateDeal`, deal);
  const { selectedAlliance } = sessionStore.getState(NEW_SESSION_EVENT);
  const client = sessionStore.getState(APOLLO_CLIENT);

  try {
    dealValidator(deal, selectedAlliance);
    initiativesItemValidator(initiatives);
  } catch (e) {
    error('updateDeal', e);
    OnDealError.dispatch(new IntegrityError(e.message));
    return Flux.dispatchEvent(DEAL_ERROR_EVENT, new IntegrityError(e.message));
  }

  try {
    const dealUpdated = await _updateDeal(
      deal,
      relatedItems,
      otherCompany,
      originalAssociatedDealId,
      initiatives,
    );

    await client.resetStore();
    OnDealUpdate.dispatch(dealUpdated);
    Flux.dispatchEvent(DEAL_UPDATE_EVENT, dealUpdated);
    return dealUpdated;
  } catch (e) {
    error('updateDeal', e);
    OnDealError.dispatch(e);
    Flux.dispatchEvent(DEAL_ERROR_EVENT, e);
  }
};

/**
 * Update Deal.
 *
 * @param {object} deal - Deal.
 * @param {Array} relatedItems - Related Items.
 * @param {Array} otherCompany - Other Company.
 * @param {Array} originalAssociatedDealId - Original Associated Deal id.
 * @param {object} initiatives - Initiatives.
 * @returns {Promise<void|*>} Promise.
 */
export const _updateDeal = async (
  deal,
  relatedItems,
  otherCompany,
  originalAssociatedDealId,
  initiatives,
) => {
  console.log(`updateDeal`, deal);
  const client = sessionStore.getState(APOLLO_CLIENT);
  const { selectedAlliance } = sessionStore.getState(NEW_SESSION_EVENT);

  deal = sanitizeDealDataFormatAmount(deal, selectedAlliance.currency);

  const companyId = deal.company.id;
  const dealsSourceIdList = await fetchDealsSourceId(companyId);
  log(`updateDeal:dealsSourceIdList`, dealsSourceIdList);
  const dealsSourceIdMap = {};
  dealsSourceIdList.forEach(
    (item) =>
      (dealsSourceIdMap[item.dealSourceId] = {
        id: item.id,
        owner: item.owner,
        associatedDealId: item.associatedDealId,
      }),
  );

  if (deal.associatedDealId && otherCompany === null)
    throw new IntegrityError(
      `You can't associate a Deal to another Deal. You only have one company in your alliance`,
    );

  if (deal.associatedDealId === deal.dealSourceId && deal.associatedDealId && deal.dealSourceId) {
    OnDealError.dispatch(new IntegrityError(`You can't associate with your self!`));
    return Flux.dispatchEvent(
      DEAL_ERROR_EVENT,
      new IntegrityError(`You can't associate with your self!`),
    );
  }

  let otherCompanyDealsSourceIds = otherCompany ? await fetchDealsSourceId(otherCompany.id) : [];
  console.log('updateDeal:otherCompanyDealsSourceIds', otherCompanyDealsSourceIds);
  if (deal.associatedDealId) {
    //validate if deal source id exists
    updateAssociatedDealIdValidator(
      deal.dealSourceId,
      deal.associatedDealId,
      dealsSourceIdList,
      otherCompanyDealsSourceIds,
    );
  }

  const { itemId } = deal;
  delete deal.itemId;

  deal.initiatives = initiatives;
  sanitizeUpdateDealData(deal);
  sanitize8BaseReconnectsFromObjects(deal, 'initiatives');
  console.log('DEBUG:deal', deal);

  const {
    data: { dealDatumUpdate },
  } = await client.mutate({
    mutation: DEAL_DATA_UPDATE_MUTATION,
    variables: { data: deal },
  });

  // Reconnect related items
  const relatedItemsData = {
    id: itemId,
    itemsRelated: {
      reconnect: relatedItems.map((i) => {
        return { id: i.itemId };
      }),
    },
  };

  log('dealData:update:relatedItems:', relatedItemsData);
  await client.mutate({
    mutation: RELATED_ITEM_UPDATE_MUTATION,
    variables: { data: relatedItemsData },
  });

  if (originalAssociatedDealId !== deal.associatedDealId) {
    console.log('DEBUG:originalAssociatedDealId', originalAssociatedDealId);

    if (deal.associatedDealId) {
      await updateDealAssociatedId(deal, client, R.clone(otherCompanyDealsSourceIds));
      await removeDealAssociatedId(deal, client, R.clone(otherCompanyDealsSourceIds));
    } else {
      await removeDealAssociatedId(deal, client, R.clone(otherCompanyDealsSourceIds));
    }
  }

  return dealDatumUpdate;
};

/**
 * Search deal associated for update associatedDealId field.
 *
 * @param {object}deal - Deal.
 * @param {object}client - Client.
 * @param {Array}dealsSourceIdList - Deals Source.
 * @returns {Promise<void|*>}Promise.
 */
export const updateDealAssociatedId = async (deal, client, dealsSourceIdList) => {
  const dealAssociated = dealsSourceIdList.find(
    (item) => item.dealSourceId === deal.associatedDealId,
  );

  dealAssociated.associatedDealId = deal.dealSourceId;
  delete dealAssociated.__typename;
  delete dealAssociated.owner;

  try {
    await client.mutate({
      mutation: DEAL_DATA_UPDATE_MUTATION,
      variables: { data: dealAssociated },
    });
  } catch (e) {
    error('updateDealAssociatedId:updateDealAssociated', e);
    OnDealError.dispatch(e);
    return Flux.dispatchEvent(DEAL_ERROR_EVENT, e);
  }

  return dealAssociated;
};

/**
 * If other deals is associated with dealAssociated o deal reset that value.
 *
 * @param {object}deal - Deal.
 * @param {object}client - Client.
 * @param {object}otherCompanyDealsSourceIds - Other Company.
 * @returns {Promise<void>}Promise.
 */
export const removeDealAssociatedId = async (deal, client, otherCompanyDealsSourceIds) => {
  let dealAssociated = otherCompanyDealsSourceIds.find(
    (item) => item.associatedDealId === deal.dealSourceId,
  );

  if (dealAssociated) {
    dealAssociated.associatedDealId = null;
    delete dealAssociated.__typename;
    delete dealAssociated.owner;
    try {
      await client.mutate({
        mutation: DEAL_DATA_UPDATE_MUTATION,
        variables: { data: dealAssociated },
      });
    } catch (e) {
      error('removeDealAssociatedId:updateDealAssociated', e);
      OnDealError.dispatch(e);
      return Flux.dispatchEvent(DEAL_ERROR_EVENT, e);
    }
  }

  /*  dealAssociated = otherCompanyDealsSourceIds.find(
    (item) => item.associatedDealId === deal.associatedDealId,
  );
  console.log('removeDealAssociatedId:updateDealAssociated', dealAssociated);

  if (dealAssociated) {
    dealAssociated.associatedDealId = null;
    delete dealAssociated.__typename;
    delete dealAssociated.owner;
    try {
      await client.mutate({
        mutation: DEAL_DATA_UPDATE_MUTATION,
        variables: { data: dealAssociated },
      });
    } catch (e) {
      error('removeDealAssociatedId:updateDealAssociated', e);
      return Flux.dispatchEvent(DEAL_ERROR_EVENT, e);
    }
  }*/
};

/**
 * Validate every field.
 *
 * @param {Array}headers - Headers selected for User.
 * @param {Array}dataCSV -  Data Imported.
 * @param {object}company - Company importing the deals.
 * @param {object}otherCompany - Company not importing the deals.
 * @returns {Promise<void>}Promise.
 */

export const checkDataCvs = async (headers, dataCSV, company, otherCompany) => {
  const currency = getCurrencyOnSession();
  const promises = [fetchStageMapping(company.id), fetchAvailableDealsSourceId(company.id)];
  if (otherCompany) {
    promises.push(fetchAvailableDealsSourceId(otherCompany.id));
  }

  let mappingList, availableDealsSourceIds, availableDealsSourceIdsFromOtherCompany;

  try {
    const responses = await Promise.all(promises);
    mappingList = responses[0];
    availableDealsSourceIds = responses[1];
    availableDealsSourceIdsFromOtherCompany = otherCompany ? responses[2] : [];
  } catch (e) {
    console.warn(e);
  }

  log(
    `checkDataCvs:dealsSourceIdList`,
    availableDealsSourceIds,
    availableDealsSourceIdsFromOtherCompany,
  );

  const labelList = headers.filter((header) => header);

  const errors = dealImportedHeaderValidate(labelList);
  if (errors.length) {
    OnDealImportedError.dispatch(new ValidationError(errors));
    Flux.dispatchEvent(DEAL_IMPORTED_ERROR_EVENT, new ValidationError(errors));
    return;
  }

  const labelValues = labelList.map((label) => label.value);
  const headerValueDuplicated = labelValues.find(
    (label, index) => labelValues.indexOf(label, index + 1) >= 0,
  );

  if (headerValueDuplicated) {
    const columnIndexes = getKeyHeaderArray(headers, headerValueDuplicated);
    OnDealImportedHeaderError.dispatch(columnIndexes);
    Flux.dispatchEvent(DEAL_IMPORTED_HEADER_ERROR_EVENT, columnIndexes);
    return;
  }

  let response;
  const associatedDealIdLabel = labelList.find((item) => item.value === 'associatedDealId');
  const dealSourceIdLabel = labelList.find((item) => item.value === 'dealSourceId');

  const isValid = !dataCSV.some((dealRow, indexRow) => {
    return labelList.some((label, indexColumn) => {
      const { header: position, value: key } = label;
      const value = dealRow[position];

      const errors = dealImportedValidator(
        key,
        value,
        availableDealsSourceIds,
        availableDealsSourceIdsFromOtherCompany,
        dataCSV,
        associatedDealIdLabel,
        dealSourceIdLabel,
        currency,
        mappingList,
      );

      if (errors.length) {
        response = { indexColumn, indexRow, message: errors.join(',') };
        console.log('dealDataImportedError', response);
        return true;
      }
      return false;
    });
  });

  if (isValid) {
    OnDealImportedValid.dispatch(true);
    Flux.dispatchEvent(DEAL_IMPORTED_VALID_EVENT, true);
  } else {
    OnDealError.dispatch(response);
    Flux.dispatchEvent(DEAL_ERROR_EVENT, response);
  }
};

const getKeyHeaderArray = (dataHeaders, fieldValue) => {
  const arrayKeys = [];
  for (let i = 0, j = dataHeaders.length; i < j; i++) {
    if (dataHeaders[i] && dataHeaders[i].value === fieldValue) {
      arrayKeys.push(i);
    }
  }
  return arrayKeys;
};

/**
 * Delete the Deals List.
 *
 * @returns {Promise<void>}Promise.
 */
export const deleteDealList = async () => {
  const client = sessionStore.getState(APOLLO_CLIENT);
  const dealsId = await _fetchDealsId();
  const operations = [];

  for (let i = 0, j = dealsId.length; i < j; i++) {
    const dealId = dealsId[i].id;
    let operation;
    try {
      operation = client.mutate({
        mutation: DEAL_DATA_DELETE_MUTATION,
        variables: { data: { id: dealId, force: true } },
      });
    } catch (e) {
      error('deleteDeal', e);
      continue;
    }
    operations.push(operation);
  }
  await Promise.all(operations);
  OnDealDeleteList.dispatch();
  return Flux.dispatchEvent(DEAL_DELETE_LIST_EVENT, {});
};

/**
 * Fetch all deal.
 *
 * @param {string}search - Search.
 * @param {string}filterYear - Filter Year.
 * @returns {Promise<void|*>} Promise.
 */

export const fetchDealsFullList = async (search = '', filterYear = null) => {
  const client = sessionStore.getState(APOLLO_CLIENT);
  const { selectedAlliance } = sessionStore.getState(NEW_SESSION_EVENT);

  if (!selectedAlliance || !isValidString(selectedAlliance.id)) {
    OnDealError.dispatch(new IntegrityError('An Alliance must be selected'));
    return Flux.dispatchEvent(DEAL_ERROR_EVENT, new IntegrityError('An Alliance must be selected'));
  }

  const filter = dealFilter({ allianceId: selectedAlliance.id, search, filterYear });

  let response;
  try {
    response = await client.query({
      query: ALL_DEAL_DATA_LIST_QUERY,
      fetchPolicy: 'network-only',
      variables: { data: filter },
    });
  } catch (e) {
    error('fetchDealsData', e);
    Flux.dispatchEvent(DEAL_ERROR_EVENT, e);
    OnDealError.dispatch(e);
    throw e;
  }

  log('fetchDealsData', response);
  Flux.dispatchEvent(ALL_DEAL_DATA_LIST_EVENT, response.data);
  OnDealsFullList.dispatch(response.data);
};
