import React from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import { Tag } from '@8base/boost';

import { InitiativesCellWrap, TD } from '../Components';

const InitiativesDataCell = ({ initiatives }) => {
  const initiativesList = R.pathOr([], ['items'], initiatives);
  return (
    <TD>
      <InitiativesCellWrap>
        {initiativesList.map(({ name }, index) => (
          <Tag key={index}>{name}</Tag>
        ))}
      </InitiativesCellWrap>
    </TD>
  );
};

InitiativesDataCell.propTypes = {
  initiatives: PropTypes.object.isRequired,
};

export { InitiativesDataCell };
