import React from 'react';
import { Column, Dropdown, Icon, Label, Menu, Row, Table, TextAreaField } from '@8base/boost';
import { DropdownBodyOnTable } from '../../../../components/dropdown/DropdownBodyOnTable';
import PropTypes from 'prop-types';
import H3 from '../../../../components/H3';
import RecommendedSolutionDialog from './RecommendedSolutionDialog';
import { truncateText } from '../../../../shared/utils';
import PlusButton from '../../../../components/PlusButton';
import FileInputComponent from '../../../../components/inputs/FileInputComponent';
import { CurrencyInputField } from '../../../../shared/components/CurrencyInputField';
import { alternativeSolutionValidator } from '../business-case-validators';
import * as toast from '../../../../components/toast/Toast';
import YesNoDialog from '../../../../components/dialogs/YesNoDialog';
import { HorizontalLineText } from '../../../../components/new-ui/text/HorizontalLineText';
import { HorizontalLine } from '../../../../components/new-ui/text/HorizontalLine';
import { GroupInputs } from '../../../../components/new-ui/inputs/GroupInputs';
import ClickableLabel from '../../../../components/ClickableLabel';
import MinusButton from '../../../../components/MinusButton';
import DocumentTitle from '../../../../components/DocumentTitle';

class BusinessCaseForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      recommendedSolutionDialogOpen: false,
      recommendedSolutionPosition: -1,
      recommendedSolutionDescription: '',
      deleteDocumentModalIsOpen: false,
      deleteDocumentPos: null,
    };
    this.onClickDelete = this.onClickDelete.bind(this);
    this.onChangeDocuments = this.onChangeDocuments.bind(this);
    // this.formattedAnticipatedCostRef = React.createRef();
  }

  onAcceptRecommendedSolution = () => {
    const description = this.state.recommendedSolutionDescription;

    try {
      alternativeSolutionValidator({ description });
    } catch (e) {
      return toast.errors(e.arr);
    }

    const pos = this.state.recommendedSolutionPosition;
    const currentSolutions = this.props.data.recommendedSolutions;
    if (pos === -1) {
      // A new one
      currentSolutions.push({ description });
    } else {
      currentSolutions[pos].description = description;
    }
    this.props.onChange('recommendedSolutions', currentSolutions);
    this.setState({ recommendedSolutionDialogOpen: false });
  };

  onCancelRecommendedSolution = () => {
    this.setState({
      recommendedSolutionDialogOpen: false,
      recommendedSolutionPosition: -1,
      recommendedSolutionDescription: '',
    });
  };

  onChangeRecommendedSolution = (whichField, value) => {
    if (whichField === 'description') {
      this.setState({ recommendedSolutionDescription: value });
    }
  };

  onOpenRecommendedSolutionDialog = () => {
    this.setState({
      recommendedSolutionDialogOpen: true,
      recommendedSolutionPosition: -1,
      recommendedSolutionDescription: '',
    });
  };

  onEditRecommendedSolutionDialog = (pos) => {
    this.setState({
      recommendedSolutionDialogOpen: true,
      recommendedSolutionPosition: pos,
      recommendedSolutionDescription: this.props.data.recommendedSolutions[pos].description,
    });
  };

  onDeleteRecommendedSolutionDialog = (pos) => {
    const currentSolutions = this.props.data.recommendedSolutions;
    currentSolutions.splice(pos, 1);
    this.props.onChange('recommendedSolutions', currentSolutions);
  };

  onClickDelete = (pos, name) => {
    this.nameInput = name;
    this.setState({
      deleteDocumentModalIsOpen: true,
      deleteDocumentPos: pos,
    });
  };

  onYes = () => {
    this.setState(
      {
        deleteDocumentModalIsOpen: false,
      },
      () => {
        this.onDeleteDocuments(this.nameInput, this.state.deleteDocumentPos);
      },
    );
  };

  onClose = () => {
    this.setState({
      deleteDocumentModalIsOpen: false,
    });
  };

  onChangeDocuments(name, value) {
    const businessCaseData = this.props.data;
    businessCaseData[name] = value;
    this.props.onChange(name, businessCaseData[name]);
  }

  onDeleteDocuments = (name) => {
    const businessCaseData = this.props.data;
    businessCaseData[name] = null;
    this.setState({ deleteDocumentPos: null });
    this.props.onChange(name, businessCaseData[name]);
  };

  render() {
    const { deleteDocumentModalIsOpen } = this.state;
    const {
      onChange,
      isDisabled,
      currency,
      showBusinessFields,
      hideExpectCostAvoidance,
    } = this.props;

    const {
      backgroundSummary,
      visionMissionStrategy,
      marketAnalysis,
      salesMarketingStrategy,
      recommendedSolutions,
      risks,
      document,
      recommendedSolution,
      expectedRevenues,
      expectedCosts,
      anticipatedCosts,
    } = this.props.data;

    return (
      <React.Fragment>
        <HorizontalLine>
          <HorizontalLineText text={'BUSINESS CASE'} />
        </HorizontalLine>
        <GroupInputs text={'General'}>
          <Row growChildren gap="lg">
            <Column>
              <TextAreaField
                disabled={isDisabled}
                style={{ width: '100%', height: 152 }}
                label="Background Summary"
                input={{
                  name: 'backgroundSummary',
                  value: backgroundSummary,
                  onChange: (value) => onChange('backgroundSummary', value),
                }}
              />
              <TextAreaField
                disabled={isDisabled}
                style={{ width: '100%', height: 152 }}
                label="Vision, Mission and Strategy"
                input={{
                  name: 'visionMissionStrategy',
                  value: visionMissionStrategy,
                  onChange: (value) => onChange('visionMissionStrategy', value),
                }}
              />
              <TextAreaField
                disabled={isDisabled}
                style={{ width: '100%', height: 152 }}
                label="Market Analysis"
                input={{
                  name: 'marketAnalysis',
                  value: marketAnalysis,
                  onChange: (value) => onChange('marketAnalysis', value),
                }}
              />
            </Column>
          </Row>
        </GroupInputs>
        <GroupInputs text={'Strategy'}>
          <Row growChildren gap="lg">
            <Column>
              <TextAreaField
                disabled={isDisabled}
                style={{ width: '100%', height: 152 }}
                label="Sales and Marketing Strategy"
                input={{
                  name: 'salesMarketingStrategy',
                  value: salesMarketingStrategy,
                  onChange: (value) => onChange('salesMarketingStrategy', value),
                }}
              />
            </Column>
          </Row>
        </GroupInputs>
        <GroupInputs text={'Solution & Risks'}>
          <Row growChildren gap="lg">
            <Column>
              <TextAreaField
                disabled={isDisabled}
                style={{ width: '100%', height: 152 }}
                label="Recommended Solution"
                input={{
                  name: 'recommendedSolution',
                  value: recommendedSolution,
                  onChange: (value) => onChange('recommendedSolution', value),
                }}
              />
              <TextAreaField
                disabled={isDisabled}
                style={{ width: '100%', height: 152 }}
                label="Risks"
                input={{
                  name: 'risks',
                  value: risks,
                  onChange: (value) => onChange('risks', value),
                }}
              />

              <H3 text={'Alternative Solutions'} />
              <Table>
                <Table.Body style={{ textAlign: 'left' }} data={recommendedSolutions}>
                  {(solution, i) => (
                    <Table.BodyRow columns="2fr 1fr" key={i}>
                      <Table.BodyCell>{truncateText(solution.description)}</Table.BodyCell>
                      <Table.BodyCell>
                        {isDisabled ? null : (
                          <Dropdown defaultOpen={false}>
                            <Dropdown.Head>
                              <Icon name="More" className="more-icon" />
                            </Dropdown.Head>
                            <DropdownBodyOnTable>
                              {({ closeDropdown }) => (
                                <Menu>
                                  <Menu.Item
                                    onClick={() => {
                                      this.onEditRecommendedSolutionDialog(i);
                                      closeDropdown();
                                    }}>
                                    Edit
                                  </Menu.Item>
                                  <Menu.Item
                                    onClick={() => {
                                      this.onDeleteRecommendedSolutionDialog(i);
                                      closeDropdown();
                                    }}>
                                    Delete
                                  </Menu.Item>
                                </Menu>
                              )}
                            </DropdownBodyOnTable>
                          </Dropdown>
                        )}
                      </Table.BodyCell>
                    </Table.BodyRow>
                  )}
                </Table.Body>
                {isDisabled ? null : (
                  <Table.Footer>
                    <PlusButton
                      text={'Add Alternative Solution'}
                      onClick={this.onOpenRecommendedSolutionDialog}
                    />
                  </Table.Footer>
                )}
              </Table>
            </Column>
          </Row>
        </GroupInputs>
        {showBusinessFields && (
          <GroupInputs text={'Contributions & Bookings'} show={true}>
            {anticipatedCosts.map((item, i) => (
              <div key={i}>
                {i === 0 ? (
                  <CurrencyInputField
                    currency={currency}
                    label={`Anticipated Contributions Year ${i + 1}`}
                    value={item}
                    onChange={(val) => {
                      anticipatedCosts[i] = val;
                      onChange('anticipatedCosts', anticipatedCosts);
                    }}
                  />
                ) : (
                  <div style={{ display: 'flex' }}>
                    <div style={{ width: '95%' }}>
                      <CurrencyInputField
                        currency={currency}
                        label={`Anticipated Contributions Year ${i + 1}`}
                        value={item}
                        onChange={(val) => {
                          anticipatedCosts[i] = val;
                          onChange('anticipatedCosts', anticipatedCosts);
                        }}
                      />
                    </div>
                    {i > 0 ? (
                      <div style={{ width: '5%', marginTop: 28 }}>
                        <ClickableLabel
                          text={<MinusButton />}
                          onClick={() => {
                            anticipatedCosts.splice(i, 1);
                            onChange('anticipatedCosts', anticipatedCosts);
                          }}
                        />
                      </div>
                    ) : null}
                  </div>
                )}
              </div>
            ))}
            <PlusButton
              text={'Add Anticipated Contributions'}
              onClick={() => {
                anticipatedCosts.push('0');
                onChange('anticipatedCosts', anticipatedCosts);
              }}
            />
            {!hideExpectCostAvoidance && (
              <>
                {expectedCosts.map((item, i) => (
                  <div key={i}>
                    {i === 0 ? (
                      <CurrencyInputField
                        label={`Expected Costs Avoidance Year ${i + 1}`}
                        value={item}
                        onChange={(val) => {
                          expectedCosts[i] = val;
                          onChange('expectedCosts', expectedCosts);
                        }}
                        currency={currency}
                      />
                    ) : (
                      <div style={{ display: 'flex' }}>
                        <div style={{ width: '95%' }}>
                          <CurrencyInputField
                            label={`Expected Costs Avoidance Year ${i + 1}`}
                            value={item}
                            onChange={(val) => {
                              expectedCosts[i] = val;
                              onChange('expectedCosts', expectedCosts);
                            }}
                            currency={currency}
                          />
                        </div>
                        {i > 0 ? (
                          <div style={{ width: '5%', marginTop: 28 }}>
                            <ClickableLabel
                              text={<MinusButton />}
                              onClick={() => {
                                expectedCosts.splice(i, 1);
                                onChange('expectedCosts', expectedCosts);
                              }}
                            />
                          </div>
                        ) : null}
                      </div>
                    )}
                  </div>
                ))}
                <PlusButton
                  text={'Add Expected Costs Avoidance'}
                  onClick={() => {
                    expectedCosts.push('0');
                    onChange('expectedCosts', expectedCosts);
                  }}
                />
              </>
            )}
            {expectedRevenues.map((item, i) => (
              <div key={i}>
                {i === 0 ? (
                  <CurrencyInputField
                    currency={currency}
                    label={`Expected Bookings Year ${i + 1}`}
                    value={item}
                    onChange={(val) => {
                      expectedRevenues[i] = val;
                      onChange('expectedRevenues', expectedRevenues);
                    }}
                  />
                ) : (
                  <div style={{ display: 'flex' }}>
                    <div style={{ width: '95%' }}>
                      <CurrencyInputField
                        currency={currency}
                        label={`Expected Bookings Year ${i + 1}`}
                        value={item}
                        onChange={(val) => {
                          expectedRevenues[i] = val;
                          onChange('expectedRevenues', expectedRevenues);
                        }}
                      />
                    </div>
                    {i > 0 ? (
                      <div style={{ width: '5%', marginTop: 28 }}>
                        <ClickableLabel
                          text={<MinusButton />}
                          onClick={() => {
                            expectedRevenues.splice(i, 1);
                            onChange('expectedRevenues', expectedRevenues);
                          }}
                        />
                      </div>
                    ) : null}
                  </div>
                )}
              </div>
            ))}
            <PlusButton
              text={'Add Expected Bookings'}
              onClick={() => {
                expectedRevenues.push('0');
                onChange('expectedRevenues', expectedRevenues);
              }}
            />
          </GroupInputs>
        )}
        <Row growChildren>
          <Column stretch alignItems="stretch">
            {/* Document */}
            <HorizontalLine>
              <HorizontalLineText text={'Documents'} />
            </HorizontalLine>
            <Row style={{ 'padding-bottom': '15px' }}>
              <Column alignItems="">
                <div style={{ 'margin-left': '20px' }}>
                  <Label kind="secondary" style={{ textAlign: 'left' }}>
                    {' '}
                    <DocumentTitle text={'Document (Optional)'} maxFiles={1} files={document} />
                  </Label>
                  <FileInputComponent
                    onChange={this.onChangeDocuments}
                    nameInput={'document'}
                    field={document}
                    maxFiles={1}
                    text={'Add Document'}
                    onClickDelete={this.onClickDelete}
                  />
                </div>
              </Column>
            </Row>
          </Column>
        </Row>
        <RecommendedSolutionDialog
          onAccept={this.onAcceptRecommendedSolution}
          onCancel={this.onCancelRecommendedSolution}
          onChange={this.onChangeRecommendedSolution}
          description={this.state.recommendedSolutionDescription}
          isOpen={this.state.recommendedSolutionDialogOpen}
        />
        <YesNoDialog
          isOpen={deleteDocumentModalIsOpen}
          onYes={this.onYes}
          onNo={this.onClose}
          onClose={this.onClose}
          text={'Are you sure you want to Delete this Document?'}
          title={'Delete Document'}
        />
      </React.Fragment>
    );
  }
}

BusinessCaseForm.propTypes = {
  data: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
  currency: PropTypes.object.isRequired,
  showBusinessFields: PropTypes.bool,
  hideExpectCostAvoidance: PropTypes.bool,
};

BusinessCaseForm.defaultProps = {
  isDisabled: false,
  showBusinessFields: false,
  hideExpectCostAvoidance: false,
};

export default BusinessCaseForm;
