import { areEmailsEqual } from '../../../shared/utils';
import { ALLIANCE_INVITATION_PENDING, COMPANY_INVITATION_PENDING } from '../../../shared/status';

/**
 * Can Accept Company Invitation permision.
 *
 * @param  {object} user - The user.
 * @param  {object} invitation - The invitation to accept.
 * @returns {boolean} If the user can accept the invitation.
 */
export const canAcceptCompanyInvitation = (user, invitation) => {
  if (!areEmailsEqual(user.email, invitation.email)) return false;

  if (invitation.status === COMPANY_INVITATION_PENDING) return true;
};

/**
 * Can Reject Company Invitation permision.
 *
 * @param  {object} user - The user.
 * @param  {object} invitation - The invitation to reject.
 * @returns {boolean} If the user can accept the invitation.
 */
export const canRejectCompanyInvitation = (user, invitation) => {
  if (!areEmailsEqual(user.email, invitation.email)) return false;

  if (invitation.status === COMPANY_INVITATION_PENDING) return true;
};

/**
 * Can Accept Alliance Invitation permission.
 *
 * @param  {object} user - The user.
 * @param  {object} invitation - The invitation to accept.
 * @returns {boolean} If the user can accept the invitation.
 */
export const canAcceptAllianceInvitation = (user, invitation) => {
  if (!areEmailsEqual(user.email, invitation.email)) return false;

  if (invitation.status === ALLIANCE_INVITATION_PENDING) return true;
};

/**
 * Can Accept Alliance Member Invitation permission.
 *
 * @param  {object} user - The user.
 * @param  {object} invitation - The invitation to accept.
 * @returns {boolean} If the user can accept the invitation.
 */
export const canAcceptAllianceMemberInvitation = (user, invitation) => {
  if (!areEmailsEqual(user.email, invitation.email))
    return {
      success: false,
      message:
        "Permission Denied. Can't accept Alliance Membership Invitation. This invitation is not for the current user",
    };
  if (invitation.status !== ALLIANCE_INVITATION_PENDING)
    return {
      success: false,
      message: `The invitation has already been ${invitation.status.toLowerCase()}`,
    };
  return { success: true };
};
