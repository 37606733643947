import { createEvent } from '@cobuildlab/react-simple-state';

export const OnAllianceInvitationDelete = createEvent();
export const OnAllianceInvitationDeleteError = createEvent();
export const OnAllianceInvitationError = createEvent();
export const OnAllianceResendInvitation = createEvent();
export const OnAllianceResendInvitationMember = createEvent();
export const OnAllianceInvitationMemberUpdated = createEvent();
export const OnAllianceInvitationUpdated = createEvent();
export const OnAllianceInvitationErrorLocal = createEvent();
export const OnAllianceInvitationNoCompanyError = createEvent();
export const OnCompanyInvitationList = createEvent();
export const OnCompanyInvitationError = createEvent();
export const OnCompanyResendInvitation = createEvent();
export const OnInviteCompanyAdmins = createEvent();
export const OnCompanyInvitationAccepted = createEvent();
export const OnCompanyInvitationReject = createEvent();
