import Flux from '@cobuildlab/flux-state';

/**
 * Event that triggers a Procurer error.
 *
 * @type {string}
 */
export const PROCURER_ERROR_EVENT = 'onProcurerError';

/**
 * Event that triggers a Payment Update event.
 *
 * @type {string}
 */
export const UPDATE_PAYMENT_EVENT = 'onPaymentUpdate';

/**
 * Event that triggers a Cancel subscription event.
 *
 * @type {string}
 */
export const PROCURER_CANCEL_SUBSCRIPTION = 'onProcurerCancelSubscription';

/**
 * Event that triggers a Reactivate subscription event.
 *
 * @type {string}
 */
export const PROCURER_REACTIVATE_SUBSCRIPTION = 'onProcurerReactivateSubscription';

/**
 * Event that triggers a Change subscription plan event.
 *
 * @type {string}
 */
export const PROCURER_CHANGE_SUBSCRIPTION_PLAN = 'onProcurerChangeSubscriptionPlan';

/**
 * Event that triggers a Change subscription plan ERROR event.
 *
 * @type {string}
 */
export const PROCURER_CHANGE_SUBSCRIPTION_PLAN_ERROR = 'onProcurerChangeSubscriptionPlanError';

/**
 * Event that triggers a Invite company portfolio owner.
 *
 * @type {string}
 */
export const INVITE_COMPANY_PORTFOLIO_OWNER_EVENT = 'onInviteCompanyPortfolioOnwer';

/**
 * Event that triggers a  Company PortfolioOnwer Invitations list.
 *
 * @type {string}
 */
export const COMPANY_PORTFOLIO_OWNER_INVITATIONS_EVENT = 'onCompanyPortfolioOnwerInvitations';

/**
 * Event that triggers a Company subscription create.
 *
 * @type {string}
 */
export const COMPANY_SUBSCRIPTION_CREATE_EVENT = 'onCompanySubscriptionCreate';

/**
 * Event that triggers a Company subscription create.
 *
 * @type {string}
 */
export const SUBSCRIPTION_INVOICE_RETRY_EVENT = 'SUBSCRIPTION_INVOICE_RETRY_EVENT';
export const SUBSCRIPTION_INVOICE_RETRY_ERROR_EVENT = 'SUBSCRIPTION_INVOICE_RETRY_ERROR_EVENT';
export const SUBSCRIPTION_INVOICE_RETRY_LOADING_EVENT = 'SUBSCRIPTION_INVOICE_RETRY_LOADING_EVENT';

/**
 * Hold the Procurer Data.
 *
 */
class ProcurerStore extends Flux.DashStore {
  constructor() {
    super();
    this.addEvent(PROCURER_ERROR_EVENT);
    this.addEvent(UPDATE_PAYMENT_EVENT);
    this.addEvent(PROCURER_CANCEL_SUBSCRIPTION);
    this.addEvent(PROCURER_REACTIVATE_SUBSCRIPTION);
    this.addEvent(INVITE_COMPANY_PORTFOLIO_OWNER_EVENT);
    this.addEvent(COMPANY_PORTFOLIO_OWNER_INVITATIONS_EVENT);
    this.addEvent(COMPANY_SUBSCRIPTION_CREATE_EVENT);
    this.addEvent(PROCURER_CHANGE_SUBSCRIPTION_PLAN);
    this.addEvent(PROCURER_CHANGE_SUBSCRIPTION_PLAN_ERROR);
    this.addEvent(SUBSCRIPTION_INVOICE_RETRY_EVENT);
    this.addEvent(SUBSCRIPTION_INVOICE_RETRY_ERROR_EVENT);
    this.addEvent(SUBSCRIPTION_INVOICE_RETRY_LOADING_EVENT);
  }
}

export const procurerStore = new ProcurerStore();
