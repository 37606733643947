import { createEvent } from '@cobuildlab/react-simple-state';

export const OnDecisionCreate = createEvent();
export const OnDecisionError = createEvent();
export const OnDecisionAutoSave = createEvent();
export const OnDecisionAutoSaveError = createEvent();
export const OnDecisionDetail = createEvent();
export const OnDecisionCompleted = createEvent();
export const OnDecisionRestore = createEvent();
export const OnDecisionUpdate = createEvent();
