import { Card } from '@8base/boost';
import styled from '@emotion/styled';
import { css } from '@emotion/core';

const ChartCard = styled(Card)`
  border-top-left-radius: 0;
  display: grid;
  grid-template-rows: ${({ loading }) => (loading ? '64px 1fr' : '64px auto 1fr')};
  ${({ withFooter }) =>
    withFooter &&
    css`
      grid-template-rows: 64px 1fr 68px;
    `}
  & div:only-child {
    grid-row: 2;
  }
`;

export { ChartCard };
