import {
  AMO_ITEM_APPROVED,
  AMO_ITEM_SUBMITTED_FOR_APPROVAL,
  ALLIANCE_APPROVED,
  AMO_ITEM_COMPLETED,
  RISK_COMPLETED,
  ACTION_COMPLETED,
  ACTION_SUBMITTED_FOR_APPROVAL,
  ACTION_APPROVED,
  ISSUE_COMPLETED,
  DECISION_COMPLETED,
} from '../../../shared/status';
import {
  isUserAdminOrSERInAlliance,
  isUserCreatorInAlliance,
} from '../../../shared/alliance-utils';
import { initiativesApprovedValidator } from '../initiative/initiative-validators';
import { isAllianceCompleted } from '@cobuildlab/collabtogrow-permissions';

/**
 * Checks if a User can Edit An AMO Item.
 *
 * @param {object} user - The user.
 * @param {object} item - The item.
 * @param {object} alliance - The Alliance.
 *
 * @returns {boolean} - A boolean if we can edit amo item.
 */
export const canEditAMOItem = (user, item, alliance) => {
  if (isAllianceCompleted(alliance)) return false;

  if (isUserAdminOrSERInAlliance(user, alliance)) return true;

  if (
    [
      RISK_COMPLETED,
      ACTION_COMPLETED,
      ACTION_SUBMITTED_FOR_APPROVAL,
      ACTION_APPROVED,
      ISSUE_COMPLETED,
      DECISION_COMPLETED,
    ].includes(item.status)
  )
    return false;

  if (isUserCreatorInAlliance(user, alliance)) return true;

  return false;
};

/**
 * Checks if a User can Delete A AMO Item.
 *
 * @param {object} user - The user.
 * @param {object} item - The item.
 * @param {object} alliance - The Alliance.
 * @returns {[boolean, [string]]} - A boolean and an array of errors.
 */
export const canDeleteAMOItem = (user, item, alliance) => {
  const errors = [];
  if (isAllianceCompleted(alliance)) errors.push('Cannot delete item if the alliance is completed');

  if (item.status === AMO_ITEM_APPROVED)
    errors.push('Please set the amo item as completed to delete');

  if (!isUserAdminOrSERInAlliance(user, alliance) && !isUserCreatorInAlliance(user, alliance))
    errors.push('You must be an Admin, SER or Creator in the alliance to delete an item');

  return [!errors.length, errors];
};

/**
 * Checks if a User can Create AMO Items on an Alliance Alliance.
 *
 * @param {object} user - The user.
 * @param {object} alliance - The Alliance.
 *
 * @returns {boolean} - If can create an amo item.
 */
export const canCreateAMOItem = (user, alliance) => {
  if (isAllianceCompleted(alliance)) return false;

  return isUserAdminOrSERInAlliance(user, alliance) || isUserCreatorInAlliance(user, alliance);
};

/**
 * Checks if a User can Submit For approval an AMO item used for Am item list, use the specific item permission on the
 * submitForApproval action.
 *
 * @param {object} user - The user.
 * @param {object} item - The amo item.
 * @param {object} alliance - The Alliance.
 *
 * @returns {boolean} - If can submit for approval an amo item.
 */
export const canSubmitForApprovalAMOItem = (user, item, alliance) => {
  const errors = [];
  if (isAllianceCompleted(alliance))
    errors.push('Cannot submit for approval an item if the alliance is completed\n');

  if (alliance.status !== ALLIANCE_APPROVED)
    errors.push('Can’t submit for approval an item if the alliance is not approved\n');

  if (
    item.status === AMO_ITEM_APPROVED ||
    item.status === AMO_ITEM_COMPLETED ||
    item.status === AMO_ITEM_SUBMITTED_FOR_APPROVAL
  )
    errors.push('The item must be In Progress to submit for approval\n');

  try {
    initiativesApprovedValidator(item.initiatives);
  } catch (e) {
    errors.push(e.message);
  }

  if (!isUserAdminOrSERInAlliance(user, alliance))
    errors.push('Your role is not an Admin or SER in the alliance\n');

  return [!errors.length, errors];
};
