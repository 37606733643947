import React from 'react';
import PropTypes from 'prop-types';
import './file-icon.css';

const CloseIcon = ({ onClick, style = {}, className = '' }) => {
  return <div style={style} onClick={onClick} className={`close-icon ${className}`} />;
};

CloseIcon.propTypes = {
  // eslint-disable-next-line react/require-default-props
  onClick: PropTypes.func,
  // eslint-disable-next-line react/require-default-props
  style: PropTypes.object,
  // eslint-disable-next-line react/require-default-props
  className: PropTypes.string,
};

CloseIcon.defaultProps = {
  onClick: () => {},
  style: {},
  className: '',
};

export default CloseIcon;
