import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import { Tabs } from '../../../components/tabs/Tabs';
import { ActiveSalesPipelineConfigView as ConfigView } from './ActiveSalesPipelineConfigView';
import { ActiveSalesPipelineReportView as ReportView } from './ActiveSalesPipelineReportView';
import { ActiveSalesPipelineChartsView as ChartsView } from './ActiveSalesPipelineChartView';
import { TABS_LIST } from './active-sales-pipeline-model';

const ActiveSalesPipelineView = () => {
  return (
    <div id={'c2gScreen'}>
      <Tabs tabsList={TABS_LIST} />
      <Switch>
        <Route path="/reports/active-sales-pipeline/configuration" component={ConfigView} />
        <Route path="/reports/active-sales-pipeline/report" component={ReportView} />
        <Route path="/reports/active-sales-pipeline/charts" component={ChartsView} />
        <Redirect to="/reports/active-sales-pipeline/configuration" />
      </Switch>
    </div>
  );
};

export { ActiveSalesPipelineView };
