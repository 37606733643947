import Flux from '@cobuildlab/flux-state';

/**
 * Event that triggers a Alliance Invitation Error.
 *
 * @type {string}
 */
export const ALLIANCE_INVITATION_ERROR_LOCAL_EVENT = 'ALLIANCE_INVITATION_ERROR_LOCAL_EVENT';

/**
 * Event that triggers a Alliance Invitation Error.
 *
 * @type {string}
 */
export const ALLIANCE_INVITATION_ERROR_EVENT = 'onAllianceInvitationError';

/**
 * Event that triggers a Alliance Invitation Delete Error.
 *
 * @type {string}
 */
export const DELETE_ALLIANCE_INVITATION_ERROR_EVENT = 'onDeleteAllianceInvitationError';

/**
 * Event that triggers a Company Invitation Error.
 *
 * @type {string}
 */
export const COMPANY_INVITATION_ERROR_EVENT = 'onCompanyInvitationError';

/**
 * Event that triggers a Alliance Invitation Error (When you try to accept an invitation but you have no company).
 *
 * @type {string}
 */
export const ALLIANCE_INVITATION_NO_COMPANY_ERROR_EVENT = 'onAllianceInivtationNoCompanyError';

/**
 * Event that triggers a Alliance invitation Update.
 *
 * @type {string}
 */
export const ALLIANCE_INVITATION_UPDATED_EVENT = 'onAllianceInvitationUpdated';

/**
 * Event that triggers a Alliance member invitation Update.
 *
 * @type {string}
 */
export const ALLIANCE_MEMBER_INVITATION_UPDATED_EVENT = 'onAllianceMemberInvitationUpdated';

/**
 * Event that triggers a Alliance member invitation List.
 *
 * @type {string}
 */
export const ALLIANCE_MEMBER_INVITATION_LIST_EVENT = 'onAllianceMemberInvitationList';

/**
 * Event that triggers a Alliance member invitation Detail.
 *
 * @type {string}
 */
export const ALLIANCE_MEMBER_INVITATION_DETAIL_EVENT = 'onAllianceMemberInvitationDetail';

/**
 * Event that triggers a Alliance Deleted.
 *
 * @type {string}
 */
export const ALLIANCE_INVITATION_DELETED_EVENT = 'onAllianceInvitationDeleted';

/**
 * Event that triggers a Alliance invitation detail.
 *
 * @type {string}
 */
export const ALLIANCE_INVITATION_DETAIL_EVENT = 'onAllianceInvitationDetail';

/**
 * Event that triggers a Company invitation detail.
 *
 * @type {string}
 */
export const COMPANY_INVITATION_DETAIL_EVENT = 'onCompanyInvitationDetail';

/**
 * Event that triggers a Company invitation Accept.
 *
 * @type {string}
 */
export const COMPANY_INVITATION_ACCEPTED_EVENT = 'onCompanyInvitationAccept';

/**
 * Event that triggers a Company invitation Reject.
 *
 * @type {string}
 */
export const COMPANY_INVITATION_REJECTED_EVENT = 'onCompanyInvitationReject';

/**
 * Event that triggers a Invite company admins.
 *
 * @type {string}
 */
export const INVITE_COMPANY_ADMINS_EVENT = 'onInviteCompanyAdmins';

/**
 * Event that triggers a resend alliance invitation.
 *
 * @type {string}
 */
export const RESEND_ALLIANCE_MEMBER_INVITATION_EVENT = 'onResendAllianceMemberInvitation';

/**
 * Event that triggers a resend company invitation.
 *
 * @type {string}
 */
export const RESEND_COMPANY_MEMBER_INVITATION_EVENT = 'onResendCompanyMemberInvitation';

/**
 * Event that triggers a company invitation list.
 *
 * @type {string}
 */
export const COMPANY_INVITATION_LIST_EVENT = 'onCompanyInvitationList';

export const RESEND_ALLIANCE_PARTNER_INVITATION = 'ON_RESEND_ALLIANCE_PARTNER_INVITATION';

/**
 * Hold the Alliance Inivtation Data.
 *
 */
class AllianceInvitationStore extends Flux.DashStore {
  constructor() {
    super();
    this.addEvent(ALLIANCE_INVITATION_ERROR_EVENT);
    this.addEvent(DELETE_ALLIANCE_INVITATION_ERROR_EVENT);
    this.addEvent(COMPANY_INVITATION_ACCEPTED_EVENT);
    this.addEvent(COMPANY_INVITATION_REJECTED_EVENT);
    this.addEvent(COMPANY_INVITATION_DETAIL_EVENT);
    this.addEvent(INVITE_COMPANY_ADMINS_EVENT);
    this.addEvent(ALLIANCE_INVITATION_NO_COMPANY_ERROR_EVENT);
    this.addEvent(ALLIANCE_INVITATION_UPDATED_EVENT);
    this.addEvent(ALLIANCE_INVITATION_DELETED_EVENT);
    this.addEvent(ALLIANCE_MEMBER_INVITATION_UPDATED_EVENT);
    this.addEvent(ALLIANCE_MEMBER_INVITATION_LIST_EVENT);
    this.addEvent(ALLIANCE_MEMBER_INVITATION_DETAIL_EVENT);
    this.addEvent(ALLIANCE_INVITATION_DETAIL_EVENT);
    this.addEvent(RESEND_ALLIANCE_MEMBER_INVITATION_EVENT);
    this.addEvent(COMPANY_INVITATION_LIST_EVENT);
    this.addEvent(RESEND_COMPANY_MEMBER_INVITATION_EVENT);
    this.addEvent(RESEND_ALLIANCE_PARTNER_INVITATION);
    this.addEvent(COMPANY_INVITATION_ERROR_EVENT);
    this.addEvent(ALLIANCE_INVITATION_ERROR_LOCAL_EVENT);
  }
}

const invitationStore = new AllianceInvitationStore();
export default invitationStore;
