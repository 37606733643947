import * as R from 'ramda';
import Excel from 'exceljs';

import { exportToFile } from '../../../../shared/utils';
import { prepareDataToExport } from '../../utils';
import {
  BORDER_STYLE,
  FILL_STYLE,
  TITLE_CELL_STYLE,
  HORIZONTAL_ALIGNMENT,
} from '../../reports-model';

const exportToExcelPivotTable = (allTables, fileName = new Date().toISOString(), userName = '') => {
  if (!R.is(Array, allTables)) {
    allTables = [allTables];
  }

  // Create a Workbook
  let workbook = new Excel.Workbook();

  // Set Workbook Properties
  workbook.creator = userName;
  workbook.created = new Date();

  // Add a Worksheet
  let worksheet = workbook.addWorksheet(fileName);

  // Add tables to Worksheet
  allTables.forEach((tableData) => {
    // prepare table to insert to worksheet
    const table = prepareDataToExport(tableData);

    // do not change the declaration order of the
    // firstTableRow and lastTableRow constants.

    // first row number of the current table
    const firstTableRow = worksheet.rowCount + 1;

    // add rows to worksheet
    worksheet.addRows(table);

    // number of the last row of the current table
    const lastTableRow = worksheet.rowCount;

    // add empty rows to separate one table from another
    const emptyRow = new Array(table[0].length).fill('');
    worksheet.addRows([emptyRow, emptyRow]);

    // add thin border for all cells in table
    for (let rowNumber = firstTableRow; rowNumber <= lastTableRow; rowNumber++) {
      worksheet.getRow(rowNumber).eachCell((cell) => {
        cell.border = BORDER_STYLE;
      });
    }

    // columns count in our table
    const columnsCount = table[0].length;

    // do formatting title row
    if (allTables.length > 1) {
      const titleRow = worksheet.getRow(firstTableRow);
      const firstTitleCell = titleRow.getCell(1);
      const lastTitleCell = titleRow.getCell(columnsCount);
      firstTitleCell.font = TITLE_CELL_STYLE;
      firstTitleCell.fill = FILL_STYLE;
      worksheet.mergeCells(`${firstTitleCell.address}:${lastTitleCell.address}`);
    }

    // do formatting total row
    const totalRow = worksheet.getRow(lastTableRow);
    totalRow.eachCell(function(cell) {
      cell.fill = FILL_STYLE;
      if (R.is(Number, cell.value)) {
        cell.alignment = HORIZONTAL_ALIGNMENT;
      }
    });

    // do formatting header row
    const headerRow = worksheet.getRow(allTables.length > 1 ? firstTableRow + 1 : firstTableRow);
    headerRow.eachCell(function(cell, cellIndex) {
      cell.fill = FILL_STYLE;

      // If this column contains numeric values,
      // then apply text alignment to the right
      const totalValue = totalRow.getCell(cellIndex).value;
      if (R.is(Number, totalValue)) {
        cell.alignment = HORIZONTAL_ALIGNMENT;
      }

      worksheet.getColumn(cellIndex).width = 20;
    });
  });

  // Save excel file on the computer
  workbook.xlsx
    .writeBuffer()
    .then((buffer) => {
      exportToFile(buffer, `${fileName}.xlsx`);
    })
    .catch((err) => console.log('Error writing excel export', err));
};

export { exportToExcelPivotTable };
