import Flux from '@cobuildlab/flux-state';

/**
 * Event that saves filters for Sales Pipeline Trends.
 */
export const SALES_PIPELINE_TRENDS_FILTERS_EVENT = 'salesPipelineTrendsFilters';

const INITIAL_STATE = {
  filters: [],
  groups: [{ key: 'stage', sort: 'ASC' }],
};

/**
 * Hold the Active Sales Pipeline data.
 */
class SalesPipelineTrendsStore extends Flux.DashStore {
  constructor() {
    super();
    this.addEvent(SALES_PIPELINE_TRENDS_FILTERS_EVENT);
    Flux.dispatchEvent(SALES_PIPELINE_TRENDS_FILTERS_EVENT, INITIAL_STATE);
  }
}

const salesPipelineTrendsStore = new SalesPipelineTrendsStore();
export default salesPipelineTrendsStore;
