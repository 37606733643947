import React from 'react';
import { toast } from 'react-toastify';
import { ToastSuccess, ToastError, ToastWarn, ToastInfo } from './components';
import ToastErrors from './components/ToastErrors';

//const autoClose = 15000;

export const success = (text, autoClose = 15000) => {
  toast.success(<ToastSuccess text={text} />, { autoClose });
};

export const error = (text, autoClose = 15000) => {
  toast.error(<ToastError text={text} />, { autoClose });
};
export const errors = (arr, autoClose = 15000) => {
  toast.error(<ToastErrors arr={arr} />, { autoClose });
};

export const info = (text, autoClose = 15000) => {
  toast.info(<ToastInfo text={text} />, { autoClose });
};
export const warn = (text, autoClose = 15000) => {
  toast.warn(<ToastWarn text={text} />, { autoClose });
};
