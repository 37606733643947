import sessionStore, { APOLLO_CLIENT, NEW_SESSION_EVENT } from '../../../shared/SessionStore';
import {
  sanitize8BaseBigInt,
  sanitize8BaseReconnects,
  sanitize8BaseReferenceFromObject,
  sanitize8BaseReferences,
} from '../../../shared/utils';
import Flux from '@cobuildlab/flux-state';
import { IntegrityError } from '../../../shared/errors';
import { fundingRequestValidator, validateFundingRequestData } from './funding-request-validators';
import {
  sanitizeRecommendedSolutions,
  sanitizeRecommendedSolutionsToEdit,
  updateBusinessCase,
} from '../../document-management/business-case/businessCases.actions';
import { error, log } from '@cobuildlab/pure-logger';
import { fetchCurrentAllianceMembersAction } from '../../settings/alliance-management/alliance-actions';
import {
  FUNDING_REQUEST_APPROVALS_LIST_EVENT,
  FUNDING_REQUEST_AUTO_SAVE_ERROR_EVENT,
  FUNDING_REQUEST_AUTO_SAVE_EVENT,
  FUNDING_REQUEST_COMPLETED_EVENT,
  FUNDING_REQUEST_CREATE_EVENT,
  FUNDING_REQUEST_DETAIL_EVENT,
  FUNDING_REQUEST_ERROR_EVENT,
  FUNDING_REQUEST_FORM_DATA_EVENT,
  FUNDING_REQUEST_REJECT_EVENT,
  FUNDING_REQUEST_RESTORE_EVENT,
  FUNDING_REQUEST_SUBMIT_FOR_APPROVAL_EVENT,
  FUNDING_REQUEST_UPDATE_EVENT,
} from './funding-request-store';
import {
  getActiveAllianceId,
  getCompanyOnAlliance,
  isApprovalComplete,
} from '../../../shared/alliance-utils';
import {
  FUNDING_REQUEST_APPROVAL_MUTATION,
  FUNDING_REQUEST_APPROVAL_UPDATE_MUTATION,
  FUNDING_REQUEST_APPROVALS_LIST_QUERY,
  FUNDING_REQUEST_COMMENTS_QUERY,
  FUNDING_REQUEST_CREATE_MUTATION,
  FUNDING_REQUEST_DETAIL_QUERY,
  FUNDING_REQUEST_UPDATE_MUTATION,
  FUNDING_REQUEST_UPDATE_QUERY,
} from './funding-request-queries';
import {
  FUNDING_REQUEST_APPROVAL_APPROVED,
  FUNDING_REQUEST_APPROVAL_REJECTED,
  FUNDING_REQUEST_APPROVED,
  FUNDING_REQUEST_COMPLETED,
  FUNDING_REQUEST_IN_PROGRESS,
  FUNDING_REQUEST_SUBMITTED_FOR_APPROVAL,
} from '../../../shared/status';
import {
  COMMENT_CREATE_EVENT,
  COMMENT_ERROR_EVENT,
  COMMENT_REQUEST_EVENT,
  COMMENTS_EVENT,
} from '../../comment/comment-store';
import { FUNDING_REQUEST_TYPE } from '../../../shared/item-types';
import { COMMENTS_CREATE_MUTATION } from '../../comment/comment-queries';
import {
  canApproveFundingRequest,
  canCompletedFundingRequest,
  canRejectFundingRequest,
  canRestoreFundingRequest,
  canSubmitForApprovalFundingRequest,
} from './funding-request-permissions';
import { businessCaseValidator } from '../../document-management/business-case/business-case-validators';
import {
  completeItemsNextSteps,
  deleteNextSteps,
  nextStepsToBeCreated,
  sanitizeNextStepsCreate,
  sanitizeNextStepsToEdit,
  updateNextSteps,
} from '../../next-step/next-step-actions';
import * as R from 'ramda';
import { hasActiveAllianceDecorator } from '../../../shared/decorators';
import { RELATED_ITEM_UPDATE_MUTATION } from '../../related-item/related-item-queries';
import {
  normalize8baseDocumentCreate,
  normalize8baseDocumentsCreate,
  normalize8baseDocumentsDeleteAndUpdate,
} from '@cobuildlab/8base-utils';
import {
  onFundingRequestCompleted,
  onFundingRequestError,
  onFundingRequestCreate,
  onFundingRequestFormData,
  onFundingRequestAutoSave,
  onFundingRequestAutoSaveError,
  onFundingRequestDetail,
  onFundingRequestUpdate,
  onFundingRequestSubmitForApproval,
  onFundingRequestReject,
  onFundingRequestRestore,
} from './funding-request-event';
import {
  OnCommentError,
  OnCommentRequest,
  OnCommentCreate,
  OnComment,
} from '../../comment/comment-events';

/**
 * Notifies a Request for Comments for a Funding Request.
 */
export const openComments = ({ id: fundingRequestId }) => {
  OnCommentRequest.dispatch({ type: FUNDING_REQUEST_TYPE, id: fundingRequestId });
  Flux.dispatchEvent(COMMENT_REQUEST_EVENT, { type: FUNDING_REQUEST_TYPE, id: fundingRequestId });
};

/**
 * Create a Funding Request.
 *
 * @param {object} fundingRequestData - Funding Request.
 * @param {object} businessCaseData - Business Case.
 * @param {Array} relatedItems - Related Items.
 * @param {object} initiatives - Initiatives.
 * @param {object} savedFundingRequest - Saved funding request.
 * @returns {Promise<void|*>} Return promise.
 */
export const createFundingRequest = async (
  fundingRequestData,
  businessCaseData,
  relatedItems,
  initiatives,
  savedFundingRequest,
) => {
  log('createFundingRequest', fundingRequestData, businessCaseData, relatedItems);
  const { selectedAlliance } = sessionStore.getState(NEW_SESSION_EVENT);
  const allianceId = selectedAlliance.id;

  if (!allianceId) {
    onFundingRequestError.dispatch(new IntegrityError('Must have an Active Alliance'));

    return Flux.dispatchEvent(
      FUNDING_REQUEST_ERROR_EVENT,
      new IntegrityError('Must have an Active Alliance'),
    );
  }

  try {
    validateFundingRequestData(
      fundingRequestData,
      initiatives,
      businessCaseData,
      true,
      selectedAlliance,
    );
  } catch (err) {
    console.error('createFundingRequest', err);
    onFundingRequestError.dispatch(err);
    Flux.dispatchEvent(FUNDING_REQUEST_ERROR_EVENT, err);
    return err;
  }

  try {
    const _fundingRequest = await createOrUpdateFundingRequest(
      fundingRequestData,
      businessCaseData,
      relatedItems,
      initiatives,
      savedFundingRequest,
    );
    onFundingRequestCreate.dispatch(_fundingRequest);
    Flux.dispatchEvent(FUNDING_REQUEST_CREATE_EVENT, _fundingRequest);
    return _fundingRequest;
  } catch (e) {
    console.error('createFundingRequest', e);
    onFundingRequestError.dispatch(e);
    Flux.dispatchEvent(FUNDING_REQUEST_ERROR_EVENT, e);
  }
};

/**
 *
 * Create a Funding Request.
 *
 * @param {object}fundingRequestData - Funding Request.
 * @param {object}businessCaseData - Business Case.
 * @param {Array}relatedItems - Related Items.
 * @param {object}initiatives - Initiatives.
 * @returns {Promise<void|*>} Return promise.
 */
export const _createFundingRequest = async (
  fundingRequestData,
  businessCaseData,
  relatedItems,
  initiatives,
) => {
  log('createFundingRequest', fundingRequestData, businessCaseData, relatedItems);
  const client = sessionStore.getState(APOLLO_CLIENT);
  const { selectedAlliance } = sessionStore.getState(NEW_SESSION_EVENT);
  const allianceId = selectedAlliance.id;

  if (!allianceId) throw new IntegrityError('Must have an Active Alliance');

  // Remove
  delete fundingRequestData.id;
  delete businessCaseData.id;
  fundingRequestData.initiatives = initiatives.map((i) => i.id);

  // Transforming into 8base 'connect' relationships
  normalize8baseDocumentsCreate(fundingRequestData, 'documents');
  sanitize8BaseReferenceFromObject(fundingRequestData, 'requestedBy');
  sanitize8BaseReferenceFromObject(fundingRequestData, 'assignedTo');
  sanitize8BaseReferenceFromObject(fundingRequestData, 'source');
  sanitize8BaseReferences(fundingRequestData, 'initiatives');
  sanitizeNextStepsCreate(fundingRequestData);
  sanitize8BaseBigInt(fundingRequestData, 'unitMonetizationFactor');
  sanitizeRecommendedSolutions(businessCaseData);
  normalize8baseDocumentCreate(businessCaseData, 'document');

  fundingRequestData.businessCase = { create: businessCaseData };
  fundingRequestData.itemFundingRequestRelation = {
    create: {
      alliance: { connect: { id: allianceId } },
      itemsRelated: {
        connect: relatedItems.map((item) => {
          return { id: item.itemId };
        }),
      },
    },
  };
  log('createFundingRequest:fundingRequestData:', fundingRequestData);
  const {
    data: { fundingRequestCreate },
  } = await client.mutate({
    mutation: FUNDING_REQUEST_CREATE_MUTATION,
    variables: { data: fundingRequestData },
  });

  return fundingRequestCreate;
};

/**
 * Auto-Save Create a Funding Request.
 *
 * @param {object} fundingRequestData - Funding Request.
 * @param {object} businessCaseData - Business Case.
 * @param {Array} relatedItems - Related Items.
 * @param {object} initiatives - Initiatives.
 * @param {object} savedFundingRequest - Saved funding request.
 * @returns {Promise<void|*>} Return promise.
 */
export const autoSaveCreateFundingRequest = async (
  fundingRequestData,
  businessCaseData,
  relatedItems,
  initiatives,
  savedFundingRequest,
) => {
  console.log('Auto-Save: ', {
    fundingRequestData,
    businessCaseData,
    relatedItems,
    initiatives,
    savedFundingRequest,
  });

  try {
    const _fundingRequest = await createOrUpdateFundingRequest(
      fundingRequestData,
      businessCaseData,
      relatedItems,
      initiatives,
      savedFundingRequest,
    );

    onFundingRequestAutoSave.dispatch(_fundingRequest);
    Flux.dispatchEvent(FUNDING_REQUEST_AUTO_SAVE_EVENT, _fundingRequest);
    console.log('autoSaveCreateFundingRequest:response', _fundingRequest);
    return _fundingRequest;
  } catch (e) {
    onFundingRequestAutoSaveError.dispatch(e);
    Flux.dispatchEvent(FUNDING_REQUEST_AUTO_SAVE_ERROR_EVENT, e);
    console.error('autoSaveCreateFundingRequest', e);
  }
};

export const autoSaveUpdateFundingRequest = async (
  fundingRequestData,
  businessCaseData,
  originalRecommendedSolutions,
  originalNextSteps,
  relatedItems,
  initiatives,
  originalData,
) => {
  try {
    const fundingRequest = await _updateFundingRequest(
      fundingRequestData,
      businessCaseData,
      originalRecommendedSolutions,
      originalNextSteps,
      relatedItems,
      initiatives,
      originalData,
    );

    onFundingRequestAutoSave.dispatch(fundingRequest);
    Flux.dispatchEvent(FUNDING_REQUEST_AUTO_SAVE_EVENT, fundingRequest);
    console.log('autoSaveUpdateFundingRequest:response', fundingRequest);
    return fundingRequest;
  } catch (e) {
    console.error('autoSaveUpdateFundingRequest:error', e);
  }
};

export const createOrUpdateFundingRequest = (
  fundingRequest,
  businessCaseData,
  relatedItems,
  initiatives,
  savedFundingRequest,
) => {
  if (!savedFundingRequest) {
    return _createFundingRequest(fundingRequest, businessCaseData, relatedItems, initiatives);
  } else {
    const fundingRequestData = R.clone(fundingRequest);
    const originalData = R.clone(savedFundingRequest);

    fundingRequestData.id = originalData.id;
    fundingRequestData.itemFundingRequestRelation = originalData.itemFundingRequestRelation;

    originalData.documents = originalData.documents.items;

    const businessCaseData = R.clone(originalData.businessCase);
    const originalRecommendedSolutions = sanitizeRecommendedSolutionsToEdit(businessCaseData);
    const originalNextSteps = sanitizeNextStepsToEdit(originalData);

    return _updateFundingRequest(
      fundingRequestData,
      businessCaseData,
      originalRecommendedSolutions,
      originalNextSteps,
      relatedItems,
      initiatives,
      originalData,
    );
  }
};

/**
 * Retrieve the Data necessary to the Funding Request Form.
 *
 * @returns {Promise<void|*>} Return promise.
 */
export const fetchFundingRequestFormDataAction = async () => {
  log('fetchFundingRequestFormDataAction');
  let allianceMembers;
  try {
    allianceMembers = await fetchCurrentAllianceMembersAction();
  } catch (e) {
    error('fetchFundingRequestFormDataAction', e);
    onFundingRequestError.dispatch(e);
    return Flux.dispatchEvent(FUNDING_REQUEST_ERROR_EVENT, e);
  }
  const data = { ...allianceMembers };
  onFundingRequestFormData.dispatch(data);
  Flux.dispatchEvent(FUNDING_REQUEST_FORM_DATA_EVENT, data);
  return data;
};

/**
 * Create a comment on a Funding Request.
 *
 * @param {string}fundingRequestId - Funding Request Id.
 * @param {Array}comment - Comment.
 * @returns {Promise<void|*>} Return promise.
 */
export const createFundingRequestComment = async (fundingRequestId, comment) => {
  const client = sessionStore.getState(APOLLO_CLIENT);
  const data = {
    comment,
    fundingRequestCommentsRelation: { connect: { id: fundingRequestId } },
  };

  let response;
  try {
    response = await client.mutate({
      mutation: COMMENTS_CREATE_MUTATION,
      variables: { data },
    });
  } catch (e) {
    error('createFundingRequestComment', e);
    OnCommentError.dispatch(e);
    return Flux.dispatchEvent(COMMENT_ERROR_EVENT, e);
  }
  log('createFundingRequestComment', response);
  OnCommentCreate.dispatch(response.data);
  Flux.dispatchEvent(COMMENT_CREATE_EVENT, response.data);
  return response.data;
};

/**
 * Fetches the funding Request Item Comments.
 *
 * @param {string}fundingRequestId - Funding Request Id.
 * @returns {Promise<void|*>} Return promise.
 */
export const fetchFundingRequestComments = async (fundingRequestId) => {
  const client = sessionStore.getState(APOLLO_CLIENT);
  const data = { id: fundingRequestId };
  const { user } = sessionStore.getState(NEW_SESSION_EVENT);

  let response;
  try {
    response = await client.query({
      query: FUNDING_REQUEST_COMMENTS_QUERY,
      variables: data,
      fetchPolicy: 'network-only',
    });
  } catch (e) {
    error('fetchFundingRequestComments', e);
    OnCommentError.dispatch(e);
    return Flux.dispatchEvent(COMMENT_ERROR_EVENT, e);
  }
  log('fetchFundingRequestComments', response);
  response.data.fundingRequest.comments.userId = user.id;

  const comments = R.clone(response.data.fundingRequest.comments);

  comments.items = response.data.fundingRequest.comments.items.map((comment) => ({
    ...comment,
  }));

  comments.userId = user.id;
  OnComment.dispatch(comments);
  Flux.dispatchEvent(COMMENTS_EVENT, comments);
  return comments;
};

/**
 * Fetches the funding Request Item.
 *
 * @param {string}fundingRequestId - Funding Request Id.
 * @returns {Promise<void|*>} Return promise.
 */
export const fetchFundingRequest = async (fundingRequestId) => {
  const client = sessionStore.getState(APOLLO_CLIENT);
  const data = { id: fundingRequestId };

  let response;
  try {
    response = await client.query({
      query: FUNDING_REQUEST_DETAIL_QUERY,
      variables: data,
      fetchPolicy: 'network-only',
    });
  } catch (e) {
    error('fetchFundingRequest', e);
    throw e;
  }

  log('fetchFundingRequest:response:', response.data);

  return response.data;
};

const handleUpdateFundingRequest = async (
  fundingRequestData,
  businessCaseData,
  originalRecommendedSolutions,
  originalNextSteps,
  relatedItems,
  initiatives,
  originalData,
) => {
  log(`_updateFundingRequest:`, fundingRequestData, originalData);
  const { selectedAlliance } = sessionStore.getState(NEW_SESSION_EVENT);

  try {
    validateFundingRequestData(
      fundingRequestData,
      initiatives,
      businessCaseData,
      true,
      selectedAlliance,
    );
  } catch (err) {
    console.error('Update funding Request Validator', err);
    onFundingRequestError.dispatch(err);
    Flux.dispatchEvent(FUNDING_REQUEST_ERROR_EVENT, err);
    return err;
  }

  // Updating Funding Request
  try {
    const updatedFundingRequest = await _updateFundingRequest(
      fundingRequestData,
      businessCaseData,
      originalRecommendedSolutions,
      originalNextSteps,
      relatedItems,
      initiatives,
      originalData,
    );
    onFundingRequestUpdate.dispatch(updatedFundingRequest);
    Flux.dispatchEvent(FUNDING_REQUEST_UPDATE_EVENT, updatedFundingRequest);
    return updatedFundingRequest;
  } catch (e) {
    onFundingRequestError.dispatch(e);
    console.log('fundingRequestData:update:', fundingRequestData);
    Flux.dispatchEvent(FUNDING_REQUEST_ERROR_EVENT, e);
  }
};

const _updateFundingRequest = async (
  fundingRequestData,
  businessCaseData,
  originalRecommendedSolutions,
  originalNextSteps,
  relatedItems,
  initiatives,
  originalData,
) => {
  log(`_updateFundingRequest:`, fundingRequestData, originalData);
  const client = sessionStore.getState(APOLLO_CLIENT);
  const itemId = fundingRequestData.itemFundingRequestRelation.id;

  // Remove
  delete fundingRequestData.itemFundingRequestRelation;
  delete fundingRequestData.createdBy;
  delete fundingRequestData.createdAt;
  delete fundingRequestData.fundingRequestApprovalRelation;
  delete fundingRequestData.status;
  delete fundingRequestData.__typename;
  delete businessCaseData.createdAt;
  delete businessCaseData.status;
  delete businessCaseData.recommendedSolutionsRelation;
  delete businessCaseData.__typename;
  delete businessCaseData.owner;
  fundingRequestData.initiatives = initiatives.map((i) => i.id);

  sanitize8BaseReferenceFromObject(fundingRequestData, 'requestedBy');
  sanitize8BaseReferenceFromObject(fundingRequestData, 'assignedTo');
  sanitize8BaseReferenceFromObject(fundingRequestData, 'source');
  sanitize8BaseReconnects(fundingRequestData, 'initiatives');
  sanitize8BaseBigInt(fundingRequestData, 'unitMonetizationFactor');
  normalize8baseDocumentsDeleteAndUpdate(fundingRequestData, 'documents', originalData);

  // nextSteps to be created
  const nextSteps = R.clone(fundingRequestData.nextSteps);
  fundingRequestData.nextSteps = nextStepsToBeCreated(nextSteps, originalNextSteps);
  sanitizeNextStepsCreate(fundingRequestData);

  // update and delete nextSteps
  await deleteNextSteps(nextSteps, originalNextSteps);
  await updateNextSteps(nextSteps, originalNextSteps);

  console.log('fundingRequestData:update:fundingRequestData:', fundingRequestData);
  console.log('fundingRequestData:update:originalData:', originalData);
  // Updating Funding Request
  const {
    data: { fundingRequestUpdate },
  } = await client.mutate({
    mutation: FUNDING_REQUEST_UPDATE_QUERY,
    variables: { data: fundingRequestData },
  });

  // Updating Business Case
  console.log('fundingRequestData:update:businessCaseData:', businessCaseData);

  await updateBusinessCase(
    businessCaseData,
    originalRecommendedSolutions,
    originalData.businessCase,
  );

  // Updating Related Items
  const data = {
    id: itemId,
    itemsRelated: {
      reconnect: relatedItems.map((item) => ({ id: item.itemId })),
    },
  };

  await client.mutate({
    mutation: RELATED_ITEM_UPDATE_MUTATION,
    variables: { data },
  });

  return fundingRequestUpdate;
};

export const updateFundingRequest = hasActiveAllianceDecorator(
  handleUpdateFundingRequest,
  FUNDING_REQUEST_ERROR_EVENT,
);

/**
 * Submit For Approval a Funding Request.
 *
 * @param {object}investmentItem - Investment Item.
 * @returns {Promise<void|*>} Return promise.
 */
export const requestApprovalForFundingRequest = async (investmentItem) => {
  const { user, selectedAlliance } = sessionStore.getState(NEW_SESSION_EVENT);
  const fundingRequestId = investmentItem.fundingRequest.id;
  const { fundingRequest } = await fetchFundingRequest(fundingRequestId);

  if (!canSubmitForApprovalFundingRequest(user, fundingRequest, selectedAlliance)) {
    onFundingRequestError.dispatch(
      new IntegrityError('You do not have permission to perform this action'),
    );

    return Flux.dispatchEvent(
      FUNDING_REQUEST_ERROR_EVENT,
      new IntegrityError('You do not have permission to perform this action'),
    );
  }

  log('requestApprovalForFundingRequest', 'toValidate', fundingRequest);
  // set initiatives & nextSteps for validators
  fundingRequest.initiatives = fundingRequest.initiatives.items;
  fundingRequest.nextSteps = fundingRequest.nextSteps.items;
  try {
    fundingRequestValidator(fundingRequest, selectedAlliance);
    businessCaseValidator(fundingRequest.businessCase);
  } catch (e) {
    console.error('Update funding Request Validator', e);
    onFundingRequestError.dispatch(e);
    return Flux.dispatchEvent(FUNDING_REQUEST_ERROR_EVENT, e);
  }

  try {
    const response = await _requestApprovalForFundingRequest(fundingRequest);
    onFundingRequestSubmitForApproval.dispatch(response);
    Flux.dispatchEvent(FUNDING_REQUEST_SUBMIT_FOR_APPROVAL_EVENT, response);
    return response;
  } catch (e) {
    console.error('requestApprovalForFundingRequest', e, investmentItem);
    onFundingRequestError.dispatch(e);
    return Flux.dispatchEvent(FUNDING_REQUEST_ERROR_EVENT, e);
  }
};

/**
 * Submit For Approval a Funding Request.
 *
 * @param {object} fundingRequest - Funding Request.
 * @param {object} selectedAlliance - The alliance that holds the companies to
 *  be connected with.
 * @returns {Promise<void|*>} Return promise.
 */
export const _requestApprovalForFundingRequest = async (
  fundingRequest,
  selectedAlliance = null,
) => {
  const client = sessionStore.getState(APOLLO_CLIENT);
  if (!selectedAlliance)
    selectedAlliance = sessionStore.getState(NEW_SESSION_EVENT).selectedAlliance;

  const initiativeApprovalList = [
    { company: { connect: { id: selectedAlliance.clientCompany.id } } },
  ];
  if (!selectedAlliance.oneSided) {
    initiativeApprovalList.push({
      company: { connect: { id: selectedAlliance.partnerCompany.id } },
    });
  }

  const data = {
    id: fundingRequest.id,
    status: FUNDING_REQUEST_SUBMITTED_FOR_APPROVAL,
    fundingRequestApprovalRelation: {
      create: initiativeApprovalList,
    },
  };

  let response;
  try {
    response = await client.mutate({
      mutation: FUNDING_REQUEST_UPDATE_MUTATION,
      variables: { data },
    });
  } catch (e) {
    console.error('requestApprovalForFundingRequest', e, fundingRequest);
    throw e;
  }
  return response.data;
};

/**
 * Fetches the Funding Request approvals for the User.
 *
 * @param {string} fundingRequestId - Funding Request Id.
 * @returns {Promise<void|*>} Return promise.
 */
export const fetchFundingRequestApprovals = async (fundingRequestId) => {
  const client = sessionStore.getState(APOLLO_CLIENT);
  const filter = { fundingRequest: { id: { equals: fundingRequestId } } };
  const sort = {
    createdAt: 'DESC',
  };
  let response;
  try {
    response = await client.query({
      query: FUNDING_REQUEST_APPROVALS_LIST_QUERY,
      variables: { filter, sort },
      fetchPolicy: 'network-only',
    });
  } catch (e) {
    error('fetchFundingRequestApprovals', e);
    return Flux.dispatchEvent(FUNDING_REQUEST_ERROR_EVENT, e);
  }
  log('fetchFundingRequestApprovals', response);
  return response.data;
};

/**
 * Approve Funding Request.
 *
 * @param {object}fundingRequest - Funding Request.
 * @returns {Promise<void|*>} Return promise.
 */
export const approveFundingRequest = async (fundingRequest) => {
  const client = sessionStore.getState(APOLLO_CLIENT);
  const { user, selectedAlliance } = sessionStore.getState(NEW_SESSION_EVENT);
  const fundingRequestId = fundingRequest.id;
  const allianceId = selectedAlliance.id;
  const company = getCompanyOnAlliance(user, { id: allianceId });

  if (!canApproveFundingRequest(user, fundingRequest, { id: allianceId })) {
    onFundingRequestError.dispatch(
      new IntegrityError(`Permission Denied. Can't approve this Funding Request`),
    );

    return Flux.dispatchEvent(
      FUNDING_REQUEST_ERROR_EVENT,
      new IntegrityError(`Permission Denied. Can't approve this Funding Request`),
    );
  }

  // We fetch the approvals to see what's gonna happen
  const { fundingRequestApprovalsList } = await fetchFundingRequestApprovals(fundingRequestId);

  const approvalsList = fundingRequestApprovalsList.items;

  if (!selectedAlliance.oneSided && approvalsList.length < 2) {
    onFundingRequestError.dispatch(
      new IntegrityError('Funding Request must have have at least 2 request for approvals'),
    );

    return Flux.dispatchEvent(
      FUNDING_REQUEST_ERROR_EVENT,
      new IntegrityError('Funding Request must have have at least 2 request for approvals'),
    );
  }

  let approvalsCounter = 0;
  let approvalId = null;
  approvalsList.forEach((approval) => {
    if (approval.status === FUNDING_REQUEST_APPROVAL_APPROVED) approvalsCounter++;
    if (approval.company.id === company.id) approvalId = approval.id;
  });
  if (approvalId === null) {
    onFundingRequestError.dispatch(
      new IntegrityError(
        "You can't approve this Funding Request, your Company does not belong here.",
      ),
    );

    return Flux.dispatchEvent(
      FUNDING_REQUEST_ERROR_EVENT,
      new IntegrityError(
        "You can't approve this Funding Request, your Company does not belong here.",
      ),
    );
  }

  // Update the status of the Approval
  const mutation = {
    mutation: FUNDING_REQUEST_APPROVAL_UPDATE_MUTATION,
    variables: {
      approval: {
        id: approvalId,
        status: FUNDING_REQUEST_APPROVAL_APPROVED,
        dateOfResponse: new Date(),
        approvedBy: { connect: { id: user.id } },
      },
    },
  };
  // If there are more than 0 approvals: Update the status of the fundingRequest
  if (isApprovalComplete(approvalsCounter)) {
    mutation.mutation = FUNDING_REQUEST_APPROVAL_MUTATION;
    mutation.variables.fundingRequest = {
      id: fundingRequestId,
      status: FUNDING_REQUEST_APPROVED,
    };
  }

  let response;
  try {
    response = await client.mutate(mutation);
  } catch (e) {
    error('approveFundingRequest', e);
    onFundingRequestError.dispatch(e);
    return Flux.dispatchEvent(FUNDING_REQUEST_ERROR_EVENT, e);
  }
  log('approveFundingRequest', response);
  onFundingRequestUpdate.dispatch(response.data);
  Flux.dispatchEvent(FUNDING_REQUEST_UPDATE_EVENT, response.data);
  return response.data;
};

/**
 * Reject Funding Request.
 *
 * @param {string} fundingRequest - Funding Request.
 * @returns {Promise<void|*>} Return promise.
 */
export const rejectFundingRequest = async (fundingRequest) => {
  const client = sessionStore.getState(APOLLO_CLIENT);
  const user = sessionStore.getState(NEW_SESSION_EVENT).user;
  const fundingRequestId = fundingRequest.id;
  const allianceId = getActiveAllianceId(user);
  const company = getCompanyOnAlliance(user, { id: allianceId });

  if (!canRejectFundingRequest(user, fundingRequest, { id: allianceId })) {
    onFundingRequestError.dispatch(
      new IntegrityError(`Permission Denied. Can't reject this Funding Request`),
    );

    return Flux.dispatchEvent(
      FUNDING_REQUEST_ERROR_EVENT,
      new IntegrityError(`Permission Denied. Can't reject this Funding Request`),
    );
  }

  // We fetch the approvals to see what's gonna happen
  const { fundingRequestApprovalsList } = await fetchFundingRequestApprovals(fundingRequestId);
  const approvalsList = fundingRequestApprovalsList.items.slice(0, 2);
  if (approvalsList.length < 2) {
    onFundingRequestError.dispatch(
      new IntegrityError('Funding Request must have have at least 2 request for approvals'),
    );

    return Flux.dispatchEvent(
      FUNDING_REQUEST_ERROR_EVENT,
      new IntegrityError('Funding Request must have have at least 2 request for approvals'),
    );
  }

  let approvalId = null;
  /*
    let alliedCompanyApprovalId = null;
  */
  approvalsList.forEach((approval) => {
    if (approval.company.id === company.id) {
      approvalId = approval.id;
    }
  });
  if (approvalId === null) {
    onFundingRequestError.dispatch(
      new IntegrityError(
        "You can't reject this Funding Request, your Company does not belong here.",
      ),
    );

    return Flux.dispatchEvent(
      FUNDING_REQUEST_ERROR_EVENT,
      new IntegrityError(
        "You can't reject this Funding Request, your Company does not belong here.",
      ),
    );
  }

  // Update the status of the Approval
  const userCompanyMutation = {
    mutation: FUNDING_REQUEST_APPROVAL_MUTATION,
    variables: {
      approval: {
        id: approvalId,
        status: FUNDING_REQUEST_APPROVAL_REJECTED,
        dateOfResponse: new Date(),
        approvedBy: { connect: { id: user.id } },
      },
      fundingRequest: {
        id: fundingRequestId,
        status: FUNDING_REQUEST_APPROVAL_REJECTED,
      },
    },
  };

  /*const alliedCompanyMutation = {
    mutation: FUNDING_REQUEST_APPROVAL_UPDATE_MUTATION,
    variables: {
      approval: {
        id: alliedCompanyApprovalId,
        status: FUNDING_REQUEST_APPROVAL_REJECTED,
        dateOfResponse: new Date(),
      },
    },
  };*/

  let response;
  try {
    response = await client.mutate(userCompanyMutation);
    //await client.mutate(alliedCompanyMutation);
  } catch (e) {
    error('rejectFundingRequest', e);
    onFundingRequestError.dispatch(e);
    return Flux.dispatchEvent(FUNDING_REQUEST_ERROR_EVENT, e);
  }
  log('rejectFundingRequest', response);
  onFundingRequestReject.dispatch(response.data);
  Flux.dispatchEvent(FUNDING_REQUEST_REJECT_EVENT, response.data);
  return response.data;
};

/**
 * Completed a Funding Request.
 *
 * @param {object}fundingRequestData - Funding Request.
 * @returns {Promise<void|*>} Return promise.
 */
export const completedFundingRequest = async (fundingRequestData) => {
  const client = sessionStore.getState(APOLLO_CLIENT);
  const { selectedAlliance, user } = sessionStore.getState(NEW_SESSION_EVENT);
  const { fundingRequest } = await fetchFundingRequest(fundingRequestData.id);
  let response;

  if (!canCompletedFundingRequest(user, fundingRequest, selectedAlliance)) {
    onFundingRequestError.dispatch(
      new IntegrityError('You do not have permission to perform this action'),
    );

    return Flux.dispatchEvent(
      FUNDING_REQUEST_ERROR_EVENT,
      new IntegrityError('You do not have permission to perform this action'),
    );
  }

  try {
    response = await client.mutate({
      mutation: FUNDING_REQUEST_UPDATE_QUERY,
      variables: {
        data: {
          id: fundingRequest.id,
          status: FUNDING_REQUEST_COMPLETED,
        },
      },
    });
  } catch (e) {
    error('completedFundingRequest', e);
    onFundingRequestError.dispatch(e);
    return Flux.dispatchEvent(FUNDING_REQUEST_ERROR_EVENT, e);
  }

  try {
    await completeItemsNextSteps(fundingRequest);
  } catch (e) {
    log('completeItemsNextStepsError', e);
  }
  onFundingRequestCompleted.dispatch(response);
  Flux.dispatchEvent(FUNDING_REQUEST_COMPLETED_EVENT, response);
  return response.data;
};

/**
 * Restore Funding Request.
 *
 * @param {object}fundingRequestData - Funding Request.
 * @returns {Promise<void|*>} Return promise.
 */
export const restoreFundingRequest = async (fundingRequestData) => {
  const client = sessionStore.getState(APOLLO_CLIENT);
  const { selectedAlliance, user } = sessionStore.getState(NEW_SESSION_EVENT);
  const { fundingRequest } = await fetchFundingRequest(fundingRequestData.id);
  let response;

  if (!canRestoreFundingRequest(user, fundingRequest, selectedAlliance)) {
    onFundingRequestError.dispatch(
      new IntegrityError('You do not have permission to perform this action'),
    );

    return Flux.dispatchEvent(
      FUNDING_REQUEST_ERROR_EVENT,
      new IntegrityError('You do not have permission to perform this action'),
    );
  }

  try {
    response = await client.mutate({
      mutation: FUNDING_REQUEST_UPDATE_QUERY,
      variables: {
        data: {
          id: fundingRequest.id,
          status: FUNDING_REQUEST_IN_PROGRESS,
        },
      },
    });
  } catch (e) {
    error('completedFundingRequest', e);
    onFundingRequestError.dispatch(e);
    return Flux.dispatchEvent(FUNDING_REQUEST_ERROR_EVENT, e);
  }

  onFundingRequestRestore.dispatch(response);
  Flux.dispatchEvent(FUNDING_REQUEST_RESTORE_EVENT, response);
  return response.data;
};

/**
 * This function call funding request data and dispatch event.
 *
 * @param {string}id - Id.
 * @returns {Promise<*>} Return promise.
 */
export const fetchFundingRequestDetail = async (id) => {
  let response;
  try {
    response = await fetchFundingRequest(id);
  } catch (e) {
    onFundingRequestError.dispatch(e);
    return Flux.dispatchEvent(FUNDING_REQUEST_ERROR_EVENT, e);
  }

  onFundingRequestDetail.dispatch(response);
  Flux.dispatchEvent(FUNDING_REQUEST_DETAIL_EVENT, response);

  return response;
};

/**
 * Dispatch Event to fetch funding request list.
 *
 * @param {string}fundingRequestId - Funding Request Id.
 * @returns {Promise<void|*>} Promise.
 */
export const fetchFundingRequestApprovalsList = async (fundingRequestId) => {
  let response;
  try {
    response = await fetchFundingRequestApprovals(fundingRequestId);
  } catch (e) {
    error('fetchFundingRequestApprovalsList', e);
    return Flux.dispatchEvent(FUNDING_REQUEST_ERROR_EVENT, e);
  }
  Flux.dispatchEvent(FUNDING_REQUEST_APPROVALS_LIST_EVENT, response);
  log('fetchFundingRequestApprovalsList', response);
  return response.data;
};
