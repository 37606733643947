import React from 'react';
import PropTypes from 'prop-types';
import DetailValue from '../../../../components/DetailValue';
import SourceDetailValue from '../../../../components/SourceDetailValue';
import { CurrencyTextField } from '../../../../shared/components/CurrencyTextField';
import DocumentsFileComponent from '../../../../components/inputs/DocumentsFileComponent';
import { CONTRIBUTION_DOCUMENTS } from '../contribution-model';
import { calculateValueBigInt } from '../../../../shared/utils';
import { HorizontalLine } from '../../../../components/new-ui/text/HorizontalLine';
import { HorizontalLineText } from '../../../../components/text/HorizontalLineText';
import { BoderDetailView } from '../../../../components/new-ui/div/BorderDetailView';
import { Grid } from '@8base/boost';
import { TablePosition } from '../../../../components/new-ui/div/TablePosition';
import { TableDetail } from '../../../../components/new-ui/table/TableDetail';
import DetailDateValue from '../../../../components/DetailDateValue';
import { ThTitlePosition } from '../../../../components/new-ui/div/ThTitlePosition';
import { HeaderText } from '../../../../components/new-ui/text/HeaderText';
import { EditButton } from '../../../../components/new-ui/buttons/EditButton';
import { canEditContribution } from '../contribution-permissions';
import sessionStore, { NEW_SESSION_EVENT } from '../../../../shared/SessionStore';
import {
  TRAINING_UNIT_TYPE,
  CUSTOMERS_SATISFACTION_SCORE_UNIT_TYPE,
} from '../../../../shared/item-types';

/**
 * Detail View Table For The Contribution Detail Table.
 *
 */

const ContributionDetailTable = (props) => {
  const { data, currency, onClickEdit } = props;

  const {
    name,
    description,
    documents,
    status,
    unitType,
    unitValueDescription,
    unitMonetizationFactor,
    unitQuantity,
    contributionDate,
    source,
    professionalsTrained,
    professionalsCertified,
    customerSatisfaction,
  } = data;

  const { user, selectedAlliance } = sessionStore.getState(NEW_SESSION_EVENT);

  return (
    <>
      <HorizontalLine>
        <HorizontalLineText>CONTRIBUTION</HorizontalLineText>
      </HorizontalLine>
      <>
        <BoderDetailView>
          <Grid.Layout
            columns="auto 100px"
            areas={[['left', 'right']]}
            style={{ width: '100%', height: '100%' }}>
            <Grid.Box area="left">
              <div style={{ position: 'absolute', top: '21px', left: '25px' }}>
                <HeaderText>CONTRIBUTION</HeaderText>
              </div>
            </Grid.Box>
            <Grid.Box area="right" justifyContent={'center'}>
              {canEditContribution(user, data, selectedAlliance) ? (
                <EditButton onClick={onClickEdit} text="Edit" />
              ) : null}
            </Grid.Box>
          </Grid.Layout>
        </BoderDetailView>
        <TablePosition>
          <TableDetail>
            <tbody>
              <tr>
                <th>
                  <ThTitlePosition>
                    <span>STATE</span>
                  </ThTitlePosition>
                </th>
                <td>
                  <span> {status} </span>
                </td>
              </tr>
              <tr>
                <th>
                  <ThTitlePosition>
                    <span>NAME</span>
                  </ThTitlePosition>
                </th>
                <td>
                  <DetailValue text={name} />
                </td>
              </tr>
              <tr>
                <th>
                  <ThTitlePosition>
                    <span>DESCRIPTION</span>
                  </ThTitlePosition>
                </th>
                <td>
                  <DetailValue text={description} />
                </td>
              </tr>
              <tr>
                <th>
                  <ThTitlePosition>
                    <span>CONTRIBUTION DATE</span>
                  </ThTitlePosition>
                </th>
                <td>
                  <DetailDateValue date={contributionDate} />
                </td>
              </tr>
              <tr>
                <th>
                  <ThTitlePosition>
                    <span>SOURCE</span>
                  </ThTitlePosition>
                </th>
                <td>
                  <SourceDetailValue source={source} />
                </td>
              </tr>
              <tr>
                <th>
                  <ThTitlePosition>
                    <span>UNIT TYPE</span>
                  </ThTitlePosition>
                </th>
                <td>
                  <DetailValue text={unitType} />
                </td>
              </tr>
              {unitType === TRAINING_UNIT_TYPE ? (
                <>
                  <tr>
                    <th>
                      <ThTitlePosition>
                        <span>Professionals Trained</span>
                      </ThTitlePosition>
                    </th>
                    <td>
                      <DetailValue text={professionalsTrained} />
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <ThTitlePosition>
                        <span>Professionals Certified</span>
                      </ThTitlePosition>
                    </th>
                    <td>
                      <DetailValue text={professionalsCertified} />
                    </td>
                  </tr>
                </>
              ) : unitType === CUSTOMERS_SATISFACTION_SCORE_UNIT_TYPE ? (
                <>
                  <tr>
                    <th>
                      <ThTitlePosition>
                        <span>Customer Satisfaction</span>
                      </ThTitlePosition>
                    </th>
                    <td>
                      <DetailValue text={customerSatisfaction} />
                    </td>
                  </tr>
                </>
              ) : (
                <tr>
                  <th>
                    <ThTitlePosition>
                      <span>UNIT QUANTITY</span>
                    </ThTitlePosition>
                  </th>
                  <td>
                    <DetailValue text={unitQuantity} />
                  </td>
                </tr>
              )}
              <tr>
                <th>
                  <ThTitlePosition>
                    <span>UNIT VALUE DESCRIPTION</span>
                  </ThTitlePosition>
                </th>
                <td>
                  <DetailValue text={unitValueDescription} />
                </td>
              </tr>
              <tr>
                <th>
                  <ThTitlePosition>
                    <span>UNIT MONETIZATION FACTOR</span>
                  </ThTitlePosition>
                </th>
                <td>
                  <CurrencyTextField value={unitMonetizationFactor} currency={currency} />
                </td>
              </tr>
              <tr>
                <th>
                  <ThTitlePosition>
                    <span>CALCULATED VALUE</span>
                  </ThTitlePosition>
                </th>
                <td>
                  <CurrencyTextField
                    value={calculateValueBigInt(unitQuantity, unitMonetizationFactor)}
                    currency={currency}
                  />
                </td>
              </tr>
              <tr>
                <th>
                  <ThTitlePosition>
                    <span>DOCUMENTS</span>
                  </ThTitlePosition>
                </th>
                <td>
                  <DocumentsFileComponent data={documents} localKey={CONTRIBUTION_DOCUMENTS} />
                </td>
              </tr>
            </tbody>
          </TableDetail>
        </TablePosition>
      </>
    </>
  );
};

ContributionDetailTable.defaultProps = {
  onClickEdit: null, // null for form previews
};

ContributionDetailTable.propTypes = {
  data: PropTypes.object.isRequired,
  currency: PropTypes.object.isRequired,
  onClickEdit: PropTypes.func,
};

export default ContributionDetailTable;
