import React from 'react';
import { Text } from '@8base/boost';
import { KPIDot } from './';
import PropTypes from 'prop-types';
import { getKPIProgress, getKPITargetText } from '../balanced-scorecard-utils';
import targetSvg from '../../../../images/icons/target.svg';

const ScorecardTarget = ({ currency, kpiType, fullYearTarget, kpiCurrent, proRataTarget }) => {
  const progress = getKPIProgress(kpiCurrent, proRataTarget, kpiType);
  const targetText = getKPITargetText(currency, kpiType, fullYearTarget);

  return (
    <>
      <img
        style={{ margin: '0 4px 0 0' }}
        width={'16px'}
        height={'16px'}
        alt={'target'}
        src={targetSvg}
      />
      <Text weight="semibold">{`${targetText}`}</Text>
      <KPIDot progress={progress} />
    </>
  );
};

ScorecardTarget.propTypes = {
  currency: PropTypes.object,
  kpiType: PropTypes.string.isRequired,
  fullYearTarget: PropTypes.string.isRequired,
  kpiCurrent: PropTypes.string.isRequired,
  proRataTarget: PropTypes.string.isRequired,
};
ScorecardTarget.defaultProps = {
  currency: null,
};

export { ScorecardTarget };
