import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';

const StyledForm = styled.div`
  width: 80% !important;
  margin: 0 auto;
  padding-bottom: 10px;
`;

const TablePosition = ({ children }) => {
  return <StyledForm>{children}</StyledForm>;
};

TablePosition.propTypes = {
  children: PropTypes.any.isRequired,
};

export { TablePosition };
