import GearsIcon from '../../../images/icons/gears.svg';
import TableIcon from '../../../images/icons/table.svg';
import PieChartIcon from '../../../images/icons/pie-chart.svg';

const FIELDS_LIST = [
  {
    id: 'id-1',
    displayName: 'Stage',
    fieldType: 'STRING',
    name: 'stage',
  },
  {
    id: 'id-2',
    displayName: 'Close date',
    fieldType: 'DATE',
    name: 'closeDate',
  },
  {
    id: 'id-3',
    displayName: 'Alliance deal owner',
    fieldType: 'RELATION',
    name: 'owner.id',
  },
  {
    id: 'id-4',
    displayName: 'Sales account rep',
    fieldType: 'STRING',
    name: 'salesPerson',
  },
  {
    id: 'id-5',
    displayName: 'Customer name',
    fieldType: 'STRING',
    name: 'customerName',
  },
];

const TABS_LIST = [
  {
    to: '/reports/active-sales-pipeline/configuration',
    label: 'Configuration',
    icon: GearsIcon,
  },
  {
    to: '/reports/active-sales-pipeline/report',
    label: 'Report',
    icon: TableIcon,
  },
  {
    to: '/reports/active-sales-pipeline/charts',
    label: 'Charts',
    icon: PieChartIcon,
  },
];

const INITIAL_STATE = {
  filters: [],
  sorts: [],
  groups: [{ value: 'ASC', key: 'stage' }],
  isTotals: false,
};

export { FIELDS_LIST, TABS_LIST, INITIAL_STATE };
