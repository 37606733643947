import React from 'react';
import { Table, Grid } from '@8base/boost';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { HorizontalLine } from '../../../../components/new-ui/text/HorizontalLine';
import { HorizontalLineText } from '../../../../components/text/HorizontalLineText';
import { BoderDetailView } from '../../../../components/new-ui/div/BorderDetailView';
import { HeaderText } from '../../../../components/new-ui/text/HeaderText';
import { TablePosition } from '../../../../components/new-ui/div/TablePosition';
import { TableDetail } from '../../../../components/new-ui/table/TableDetail';

const CompanyUsersList = ({ companyUsers }) => {
  return (
    <>
      <HorizontalLine>
        <HorizontalLineText>Procurer and Portfolio Owner List</HorizontalLineText>
      </HorizontalLine>
      <BoderDetailView>
        <Grid.Layout columns="50% 50%" areas={[['left', 'right']]} style={{ width: '100%' }}>
          <Grid.Box area="left">
            <div style={{ position: 'absolute', top: '21px', left: '25px' }}>
              <HeaderText>Procurer and Portfolio Owner List</HeaderText>
            </div>
          </Grid.Box>
        </Grid.Layout>
      </BoderDetailView>
      <TablePosition>
        <TableDetail>
          <Table.Header columns="repeat(4, 1fr)">
            <Table.HeaderCell>First Name</Table.HeaderCell>
            <Table.HeaderCell>Last Name</Table.HeaderCell>
            <Table.HeaderCell>Email</Table.HeaderCell>
            <Table.HeaderCell>Role</Table.HeaderCell>
          </Table.Header>
          <Table.Body data={companyUsers}>
            {(user) => (
              <Table.BodyRow columns="repeat(4, 1fr)" key={user.id}>
                <Table.BodyCell>{R.pathOr('', ['user', 'firstName'], user)}</Table.BodyCell>
                <Table.BodyCell>{R.pathOr('', ['user', 'lastName'], user)}</Table.BodyCell>
                <Table.BodyCell>{R.pathOr('', ['user', 'email'], user)}</Table.BodyCell>
                <Table.BodyCell>{R.pathOr('', ['role', 'name'], user)}</Table.BodyCell>
              </Table.BodyRow>
            )}
          </Table.Body>
        </TableDetail>
      </TablePosition>
    </>
  );
};

CompanyUsersList.propTypes = {
  companyUsers: PropTypes.object.isRequired,
};

export { CompanyUsersList };
