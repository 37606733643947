import React from 'react';
import * as R from 'ramda';
import { Query } from 'react-apollo';
import { Field } from 'react-final-form';
import PropTypes from 'prop-types';
import { SelectField, InputField, DateInputField } from '@8base/boost';
import { ConditionalField } from '../../../../components/forms/ConditionalField';
import { uiFilterOperations } from '../../../../shared/select-utils';
import sessionStore, { NEW_SESSION_EVENT } from '../../../../shared/SessionStore';
import {
  DeleteButton,
  FilterRow,
  DateMonthSetCondField,
  DateRangesCondField,
} from '../../components';
import {
  FILTER_OPERSTORS_OPTIONS_NUMBER,
  FILTER_OPERSTORS_OPTIONS_TEXT,
  FILTER_OPERSTORS_OPTIONS_RELATION,
  FILTER_OPERSTORS_OPTIONS_RELATION_LIST,
} from '../../reports-model';
import { getRelationConditionalOprions, getRelationOptionsQuery } from '../utils';

const FilterFieldsRow = ({
  input,
  deleteFilter,
  fieldsDict,
  fieldsOptions,
  itemType,
  changeFormValue,
  withDeleteButton,
}) => {
  const { value: fieldValue, name } = input;
  const { fieldId, rule } = fieldValue;
  const fieldName = R.path([fieldId, 'name'], fieldsDict);
  const fieldType = R.path([fieldId, 'fieldType'], fieldsDict);
  const isList = R.path([fieldId, 'isList'], fieldsDict);
  const renderRuleField = (fieldType) => {
    switch (fieldType) {
    case 'DATE': {
      const dateOptions = Object.keys(uiFilterOperations.DATE).map((key) => ({
        label: uiFilterOperations.DATE[key],
        value: key,
      }));
      return (
        <ConditionalField
          key={'rule-DATE'}
          changeFormValue={changeFormValue}
          name={`${name}.rule`}
          subscription={{ active: true, value: true, error: true, touched: true }}
          label="Filter Operation"
          placeholder="Select Field"
          options={dateOptions}
          component={SelectField}
        />
      );
    }
    case 'NUMBER': {
      return (
        <ConditionalField
          key={'rule-NUMBER'}
          changeFormValue={changeFormValue}
          name={`${name}.rule`}
          subscription={{ active: true, value: true, error: true, touched: true }}
          label="Filter Operation"
          placeholder="Select Field"
          options={FILTER_OPERSTORS_OPTIONS_NUMBER}
          component={SelectField}
        />
      );
    }
    case 'TEXT': {
      return (
        <ConditionalField
          key={'rule-NUMBER'}
          changeFormValue={changeFormValue}
          name={`${name}.rule`}
          subscription={{ active: true, value: true, error: true, touched: true }}
          label="Filter Operation"
          placeholder="Select Field"
          options={FILTER_OPERSTORS_OPTIONS_TEXT}
          component={SelectField}
        />
      );
    }
    case 'RELATION': {
      return (
        <ConditionalField
          key={'rule-RELATION'}
          changeFormValue={changeFormValue}
          name={`${name}.rule`}
          subscription={{ active: true, value: true, error: true, touched: true }}
          label="Filter Operation"
          placeholder="Select Field"
          options={
            isList ? FILTER_OPERSTORS_OPTIONS_RELATION_LIST : FILTER_OPERSTORS_OPTIONS_RELATION
          }
          component={SelectField}
        />
      );
    }
    case 'SWITCH': {
      return (
        <ConditionalField
          key={'rule-SWITCH'}
          changeFormValue={changeFormValue}
          name={`${name}.rule`}
          subscription={{ active: true, value: true, error: true, touched: true }}
          label="Filter Operation"
          placeholder="Select Field"
          options={FILTER_OPERSTORS_OPTIONS_RELATION}
          component={SelectField}
        />
      );
    }
    default:
      return null;
    }
  };

  const renderCondField = (fieldType, currentRule, fieldName) => {
    if (fieldType === 'DATE') {
      switch (currentRule) {
      case 'range':
        return (
          <ConditionalField
            key={'filter-range'}
            changeFormValue={changeFormValue}
            name={`${name}.cond`}
            subscription={{ active: true, value: true, error: true, touched: true }}
            component={DateRangesCondField}
          />
        );
      case 'year':
        return (
          <ConditionalField
            key={'filter-year'}
            changeFormValue={changeFormValue}
            name={`${name}.cond`}
            subscription={{ active: true, value: true, error: true, touched: true }}
            label="Year"
            type="number"
            placeholder={new Date().getFullYear()}
            component={InputField}
          />
        );
      case 'quarter':
        return (
          <ConditionalField
            key={'filter-quarter'}
            changeFormValue={changeFormValue}
            name={`${name}.cond`}
            subscription={{ active: true, value: true, error: true, touched: true }}
            label="Quarter"
            placeholder="2002, Q2"
            mask="9999, Q9"
            render={({ input, ...restProps }) => {
              const { value, onChange, ...restInputProps } = input;
              return (
                <InputField
                  input={{
                    value,
                    onChange: (newValue) => onChange(newValue.split(', Q')),
                    ...restInputProps,
                  }}
                  {...restProps}
                />
              );
            }}
          />
        );
      case 'month':
        return (
          <ConditionalField
            key={'filter-month'}
            changeFormValue={changeFormValue}
            name={`${name}.cond`}
            subscription={{ active: true, value: true, error: true, touched: true }}
            label="Month"
            placeholder="mm/yyyy"
            component={DateInputField}
            isMonthPicker
          />
        );
      case 'monthSet':
        return (
          <ConditionalField
            key={'filter-monthSet'}
            changeFormValue={changeFormValue}
            name={`${name}.cond`}
            subscription={{ active: true, value: true, error: true, touched: true }}
            component={DateMonthSetCondField}
          />
        );
      default:
        return null;
      }
    }
    if (fieldType === 'SWITCH') {
      if (!currentRule) {
        return null;
      }
      const fieldTypeAttributes = R.pathOr(
        [],
        [fieldId, 'fieldTypeAttributes', 'listOptions'],
        fieldsDict,
      );
      const switchOptions = fieldTypeAttributes.map((atribute) => ({
        label: atribute,
        value: atribute,
      }));
      return (
        <ConditionalField
          key={'cond-SWITCH'}
          changeFormValue={changeFormValue}
          name={`${name}.cond`}
          subscription={{ active: true, value: true, error: true, touched: true }}
          label="Filter Operation"
          placeholder="Select Switch Item"
          options={switchOptions}
          component={SelectField}
        />
      );
    }
    if (fieldType === 'NUMBER') {
      if (!currentRule) {
        return null;
      }
      return (
        <ConditionalField
          key={'cond-NUMBER'}
          changeFormValue={changeFormValue}
          name={`${name}.cond`}
          subscription={{ active: true, value: true, error: true, touched: true }}
          label="Number"
          type="number"
          placeholder="Enter Number"
          component={InputField}
        />
      );
    }
    if (fieldType === 'TEXT') {
      if (!currentRule || currentRule === 'is_empty' || currentRule === 'is_not_empty') {
        return null;
      }
      if (currentRule !== 'equals' && currentRule !== 'not_equals')
        return (
          <ConditionalField
            key={'cond-TEXT'}
            changeFormValue={changeFormValue}
            name={`${name}.cond`}
            subscription={{ active: true, value: true, error: true, touched: true }}
            label="Text"
            placeholder="Enter Text"
            component={InputField}
          />
        );

      const { selectedAlliance } = sessionStore.getState(NEW_SESSION_EVENT);
      const relationOptionsQuery = getRelationOptionsQuery(itemType, R.path([fieldId], fieldsDict));

      const queryArgs = {
        filter: {
          AND: [],
        },
      };

      const relationFilterName = `item${itemType}Relation`;

      queryArgs.filter.AND.push({
        [relationFilterName]:
          selectedAlliance && selectedAlliance.id
            ? { alliance: { id: { equals: selectedAlliance.id } } }
            : undefined,
      });

      return (
        <Query
          query={relationOptionsQuery}
          skip={!selectedAlliance || !selectedAlliance.id}
          variables={queryArgs}>
          {({ data, loading }) => {
            const conditionalOptions = getRelationConditionalOprions(data, itemType, fieldName);

            return (
              <ConditionalField
                key={'cond-RELATION'}
                changeFormValue={changeFormValue}
                name={`${name}.cond`}
                subscription={{ active: true, value: true, error: true, touched: true }}
                label="Select"
                placeholder="Select Option"
                options={conditionalOptions}
                component={SelectField}
                loading={loading}
                multiple
              />
            );
          }}
        </Query>
      );
    }
    if (fieldType === 'RELATION') {
      if (!currentRule || currentRule === 'is_empty' || currentRule === 'is_not_empty') {
        return null;
      }

      const { selectedAlliance } = sessionStore.getState(NEW_SESSION_EVENT);
      const relationOptionsQuery = getRelationOptionsQuery(itemType, R.path([fieldId], fieldsDict));

      const queryArgs = {
        filter: {
          AND: [],
        },
      };

      const relationFilterName = `item${itemType}Relation`;

      queryArgs.filter.AND.push({
        [relationFilterName]:
          selectedAlliance && selectedAlliance.id
            ? { alliance: { id: { equals: selectedAlliance.id } } }
            : undefined,
      });

      return (
        <Query
          query={relationOptionsQuery}
          skip={!selectedAlliance || !selectedAlliance.id}
          variables={queryArgs}>
          {({ data, loading }) => {
            const conditionalOptions = getRelationConditionalOprions(data, itemType, fieldName);

            return (
              <ConditionalField
                key={'cond-RELATION'}
                changeFormValue={changeFormValue}
                name={`${name}.cond`}
                subscription={{ active: true, value: true, error: true, touched: true }}
                label="Select"
                placeholder="Select Option"
                options={conditionalOptions}
                component={SelectField}
                loading={loading}
                multiple
              />
            );
          }}
        </Query>
      );
    }
    return null;
  };

  return (
    <FilterRow withDeleteButton={withDeleteButton}>
      <Field
        name={`${name}.fieldId`}
        subscription={{ active: true, value: true, error: true, touched: true }}
        label="Filter by"
        placeholder="Select Field"
        options={fieldsOptions}
        component={SelectField}
      />
      {renderRuleField(fieldType)}
      {renderCondField(fieldType, rule, fieldName)}
      {withDeleteButton && (
        <DeleteButton text={''} iconName={'Delete'} onClick={deleteFilter} iconSize={'md'} />
      )}
    </FilterRow>
  );
};

FilterFieldsRow.displayName = 'FilterFieldsRow';
FilterFieldsRow.propTypes = {
  input: PropTypes.object.isRequired,
  deleteFilter: PropTypes.func.isRequired,
  fieldsDict: PropTypes.object.isRequired,
  fieldsOptions: PropTypes.array.isRequired,
  changeFormValue: PropTypes.func.isRequired,
  withDeleteButton: PropTypes.bool.isRequired,
  itemType: PropTypes.string.isRequired,
};

export { FilterFieldsRow };
