import styled from '@emotion/styled';
import React from 'react';
import { GRAY_COLOR } from '../shared/colors';
import PropTypes from 'prop-types';

const StyledStatusBox = styled(`span`)({
  marginRight: '1px',
  marginTop: '3px',
  borderRadius: '100%',
  width: '8px',
  height: '8px',
  display: 'inline',
});

const StatusBox = ({ color }) => {
  return <StyledStatusBox style={{ backgroundColor: color }} />;
};

StatusBox.propTypes = {
  color: PropTypes.string,
};

StatusBox.defaultProps = {
  color: GRAY_COLOR,
};

export default StatusBox;
