import React from 'react';
import { Tooltip } from '@8base/boost';
import PropTypes from 'prop-types';

export const TooltipConditional = ({ show, ...props }) => {
  if (show) return <Tooltip {...props} />;
  return props.children;
};

TooltipConditional.propTypes = {
  show: PropTypes.bool.isRequired,
};
