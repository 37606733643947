import React from 'react';
import { Heading, Loader, SelectField, Grid } from '@8base/boost';
import * as R from 'ramda';
import { View } from '@cobuildlab/react-simple-state';
import BigInt from 'big-integer';
import { CurrencyFormat } from '../../../shared/components/CurrencyFormat';
import { getCurrencyOnSession } from '../../../shared/alliance-utils';
import { randomHEX } from '../utils';

import {
  ChartCard,
  ReportHeader,
  GeneralInfo,
  GeneralInfoItem,
  GeneralInfoName,
  GeneralInfoValue,
} from '../components';
import { MONTHS } from '../reports-model';
import { SalesPipelineTrendsDataHandler } from './SalesPipelineTrendsDataHandler';
import { SalesPipelineTrendsChart } from './SalesPipelineTrendsChart';

const clientCompanySelector = 'clientCompanyMonthSnapshots';
const partnerCompanySelector = 'partnerCompanyMonthSnapshots';

class ChartView extends View {
  state = {
    selected: clientCompanySelector,
  };

  onChangeSelected = () => {
    this.setState((currentState) => ({
      selected:
        currentState.selected === clientCompanySelector
          ? partnerCompanySelector
          : clientCompanySelector,
    }));
  };

  onSelectedCompany = (selected) => {
    this.setState({ selected });
  };

  dataForChart = ({ data, monthsInfo, group }) => {
    const xAxis = Object.entries(monthsInfo.monthNumberDict)
      .map(([year, months]) => Object.keys(months).map((key) => `${MONTHS[key - 1]}-${year}`))
      .flat();

    return data.reduce(
      (acc, snapshot) => {
        if (!acc.formattedData.length) {
          return {
            formattedData: snapshot.map((month, i) => ({
              name: xAxis[i],
              [month[group]]: month.amount,
            })),
            lines: [{ color: randomHEX(), name: snapshot[0][group] }],
          };
        }

        return {
          formattedData: snapshot.map((month, i) => ({
            ...acc.formattedData[i],
            [month[group]]: month.amount,
          })),
          lines: [...acc.lines, { color: randomHEX(), name: snapshot[0][group] }],
        };
      },
      { formattedData: [], lines: [] },
    );
  };

  dataForGeneralInfo = (data) => {
    const { selected } = this.state;

    const clientStagesAverage = data[clientCompanySelector].length
      ? data[clientCompanySelector].map((stageSnapshots) => {
        const average = stageSnapshots.reduce((acc, snapshot) => {
          if (!acc) return snapshot;
          acc.amount = BigInt(acc.amount)
            .add(snapshot.amount)
            .toString();

          return acc;
        }, null);

        average.average = BigInt(average.amount)
          .divide(stageSnapshots.length || 1)
          .toString();

        return average;
      })
      : [];

    const partnerStagesAverage = data[partnerCompanySelector].length
      ? data[partnerCompanySelector].map((stageSnapshots) => {
        const average = stageSnapshots.reduce((acc, snapshot) => {
          if (!acc) return snapshot;
          acc.amount = BigInt(acc.amount)
            .add(snapshot.amount)
            .toString();

          return acc;
        }, null);

        average.average = BigInt(average.amount)
          .divide(stageSnapshots.length || 1)
          .toString();

        return average;
      })
      : [];

    const selectedCompanyAverage =
      selected === clientCompanySelector ? clientStagesAverage : partnerStagesAverage;

    return selectedCompanyAverage.map((item) => {
      return {
        name: `Average Stage: ${item.stage} - ${item.company.name}`,
        value: item.average,
      };
    });
  };

  renderContent = () => {
    const { selected } = this.state;
    const {
      sharedData: { clientCompany, partnerCompany },
      requestedData: { enhancedData, groupBy, requestMonthsInfo },
      loading,
    } = this.props;
    if (loading) {
      return <Loader stretch />;
    }

    const { formattedData, lines } = this.dataForChart({
      data: R.path([selected], enhancedData),
      monthsInfo: requestMonthsInfo,
      group: groupBy[1],
    });

    const generalInfo = this.dataForGeneralInfo(R.clone(enhancedData));

    const currency = getCurrencyOnSession();

    const companyOptions = [
      {
        label: clientCompany.name,
        value: clientCompanySelector,
      },
      {
        label: partnerCompany ? partnerCompany.name : 'Partner',
        value: partnerCompanySelector,
      },
    ];

    const selectedCompanyName =
      selected === clientCompanySelector ? clientCompany.name : partnerCompany.name;

    return (
      <div id={'c2gScreen'}>
        <ReportHeader>
          <Heading type="h4" text={'Sales Pipeline Trends'} />
          <Grid.Box direction="row" justifySelf="end" alignSelf="center">
            <SelectField
              style={{ width: '150px', alignSelf: 'end' }}
              options={companyOptions}
              input={{
                name: 'companyFilter',
                value: selected,
                onChange: (value) => this.onSelectedCompany(value),
              }}
            />
          </Grid.Box>
        </ReportHeader>
        <GeneralInfo>
          {generalInfo.map((el, index) => {
            return (
              <>
                <GeneralInfoItem key={index}>
                  <GeneralInfoName>{el.name}</GeneralInfoName>
                  <GeneralInfoValue>
                    {el.value === '0' ? (
                      'N/A'
                    ) : (
                      <CurrencyFormat {...currency} value={el.value} displayType="text" />
                    )}
                  </GeneralInfoValue>
                </GeneralInfoItem>
              </>
            );
          })}
        </GeneralInfo>
        <SalesPipelineTrendsChart
          data={formattedData}
          onChangeSelected={this.onChangeSelected}
          selectedCompanyName={selectedCompanyName}
          lineList={lines}
          currency={currency}
          noSwitcher={
            !enhancedData[clientCompanySelector].length ||
            !enhancedData[partnerCompanySelector].length
          }
        />
      </div>
    );
  };

  render() {
    return (
      <ChartCard loading={this.props.loading} stretch>
        {this.renderContent()}
      </ChartCard>
    );
  }
}

export const SalesPipelineTrendsChartsView = SalesPipelineTrendsDataHandler(ChartView);
