import React from 'react';
import { Grid } from '@8base/boost';
import { LeftWizardSection } from './components/LeftWizardSection';
import { RightWizardSection } from './components/RightWizardSection';
import { withRouter } from 'react-router-dom';
import { withAuth } from '8base-react-sdk';
import { getScreens } from './index';
import styled from '@emotion/styled';
import './css/wizard.css';
import { withApollo } from 'react-apollo';
import PropTypes from 'prop-types';
import sessionStore, { NEW_SESSION_EVENT } from '../../shared/SessionStore';
import { fetchSession } from '../../modules/auth/auth.actions';
import { onErrorMixin } from '../../shared/mixins';
import { View } from '@cobuildlab/react-simple-state';
import InviteCompanyAdminsView from './onboarding/InviteCompanyAdminsView';
import { COMPANY_PORTFOLIO_OWNER } from '../../shared/roles';
import { updateUser } from './wizard.actions';
import MainLoader from '../../shared/components/MainLoader';

const MainLayout = styled(Grid.Layout)({
  backgroundColor: 'white',
  boxShadow: '1px 1px 5px darkgrey',
  borderRadius: 5,
  maxWidth: 1122,
  margin: '0 auto',
  transition: 'all .5s',
});

class Wizard extends View {
  constructor(props) {
    super(props);
    this.state = {
      currentScreen: 0,
      screens: getScreens(),
      inviteAdminsDialogIsOpen: true,
    };
    this.selectScreen = this.selectScreen.bind(this);
    this.nextScreen = this.nextScreen.bind(this);
    this.prevScreen = this.prevScreen.bind(this);
    this.onError = onErrorMixin.bind(this);
  }

  selectScreen(selection) {
    this.setState({ currentScreen: selection });
  }

  componentDidMount() {
    this.setState({
      ...this.state,
      screens: getScreens(),
    });
  }

  async nextScreen(callUpdateMeta = true) {
    console.log(`NEXT SCREEN DEBBUG:`, this.state);
    if (this.state.currentScreen + 1 >= this.state.screens.length) {
      await updateUser({ didCompleteWizard: true, didInviteAdmins: true });
      await fetchSession();
      console.log('Debbug: redirecting because was complete the steps');
      this.props.history.push('/welcome');
      return;
    }
    if (callUpdateMeta) {
      await fetchSession();
    }
    this.setState({ currentScreen: this.state.currentScreen + 1 });
  }

  prevScreen() {
    if (this.state.currentScreen === 0) return;

    this.setState({ currentScreen: this.state.currentScreen - 1 });
  }

  render() {
    console.log('DEBUG:render Wizard');
    const { user, allianceInvitationsList } = sessionStore.getState(NEW_SESSION_EVENT);
    const companyUsers = user.companyUserRelation.items;
    const { didInviteAdmins } = user;
    const { inviteAdminsDialogIsOpen, screens, currentScreen } = this.state;

    //if I have a company, and I'm the portfolio Owner, then show me the invite admin
    const portfolioCompanyUser = companyUsers.find(
      ({ role }) => role.name === COMPANY_PORTFOLIO_OWNER,
    );
    if (portfolioCompanyUser && !didInviteAdmins && inviteAdminsDialogIsOpen) {
      return (
        <InviteCompanyAdminsView
          onClose={() => this.setState({ inviteAdminsDialogIsOpen: false })}
        />
      );
    }

    if (!portfolioCompanyUser && inviteAdminsDialogIsOpen) {
      this.setState({ inviteAdminsDialogIsOpen: false });
      return <MainLoader />;
    }

    // If none of that is true, then finally show me the money (Wizard)
    const { selectScreen, nextScreen, prevScreen } = this;
    const screen = screens[currentScreen];
    const progressBarValue =
      screens.length === 1 ? 100 : (((currentScreen + 1) * 100) / screens.length).toFixed(0);
    return (
      <MainLayout columns="minmax(260px, 286px) auto">
        <LeftWizardSection
          sections={screens}
          currentScreen={currentScreen}
          selectScreen={selectScreen}
        />
        <RightWizardSection
          screen={screen}
          currentScreen={currentScreen}
          nextScreen={nextScreen}
          prevScreen={prevScreen}
          progressBarValue={progressBarValue}
          allianceInvitationsList={allianceInvitationsList}
        />
      </MainLayout>
    );
  }
}

Wizard.propTypes = {
  auth: PropTypes.object.isRequired,
  client: PropTypes.object.isRequired,
  computedMatch: PropTypes.object,
};
Wizard.defaultProps = {
  computedMatch: PropTypes.object,
};

export default withRouter(withAuth(withApollo(Wizard)));
