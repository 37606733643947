import { isValidString } from '../../shared/validators';

export const isSalesforceFieldMappingValid = (fieldMapping) => {
  if (!isValidString(fieldMapping.object)) return [false, 'Please enter the object field'];

  if (!isValidString(fieldMapping.dealField)) return [false, 'Please enter the deal field'];

  if (!isValidString(fieldMapping.salesforceField))
    return [false, 'Please enter the salesforce field'];

  return [true];
};
