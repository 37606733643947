import gql from 'graphql-tag';

export const UserFragment = gql`
  fragment UserFragment on User {
    id
    firstName
    lastName
    email
    didCompleteWizard
    didInviteAdmins
    didSawOnboarding
    selectedAllianceId
    selectedAlliance {
      id
    }
    weeklyEmailNotification
    avatar {
      id
      fileId
      filename
      downloadUrl
    }
  }
`;

export const UserShortFragment = gql`
  fragment UserShortFragment on User {
    id
    firstName
    lastName
    email
  }
`;

export const SelectedAllianceFragment = gql`
  fragment SelectedAllianceFragment on Alliance {
    id
    name
    status
    createdAt
    oneSided
    clientCompany {
      id
      name
      companyUserRelation {
        count
        items {
          user {
            id
            firstName
            lastName
            email
          }
          role {
            id
            name
          }
        }
      }
      companyCompanySubscriptionRelation {
        items {
          id
          last4
          status
          expireDate
        }
      }
    }
    partnerCompany {
      id
      name
      companyUserRelation {
        count
        items {
          user {
            id
            firstName
            lastName
            email
          }
          role {
            id
            name
          }
        }
      }
      companyCompanySubscriptionRelation {
        items {
          id
          last4
          status
          expireDate
        }
      }
    }
    owner {
      ...UserShortFragment
    }
    currency {
      id
      thousandSeparator
      decimalSeparator
      symbol
      shortName
    }
  }
  ${UserShortFragment}
`;

export const CompanyInvitationFragment = gql`
  fragment CompanyInvitationFragment on CompanyInvitation {
    id
    email
    status
    createdBy {
      ...UserShortFragment
    }
    dateOfResponse
    role {
      id
      name
    }
    company {
      id
      name
    }
  }
  ${UserShortFragment}
`;

export const AllianceMemberInvitationFragment = gql`
  fragment AllianceMemberInvitationFragment on AllianceMemberInvitation {
    id
    email
    status
    dateOfResponse
    createdBy {
      id
      firstName
      lastName
    }
    role {
      id
      name
    }
    companyRole {
      id
      name
    }
    company {
      id
      name
    }
    alliance {
      id
      name
    }
  }
`;

export const RoleFragment = gql`
  fragment RolesFragment on Role {
    id
    name
    description
  }
`;

export const AllianceInvitationFragment = gql`
  fragment AllianceInvitationFragment on AllianceInvitation {
    id
    email
    alliance {
      id
      name
      status
      owner {
        id
        lastName
        firstName
      }
      clientCompany {
        id
        name
        companyCompanySubscriptionRelation {
          items {
            id
            last4
            status
            cardBrand
            cardExpireDate
            expireDate
            isAnnualPlan
            stripeSubscriptionId
            cancelAtPeriodEnd
            subscriptionPlan {
              id
              name
              price
              monthlyPrice
              anualSavings
              annualPrice
              monthlyStripePlanId
              annualStripePlanId
            }
            companyInvoice_companySubscriptionRelation {
              count
              items {
                id
                total
                status
                createdAt
                stripeInvoiceId
                receiptUrl
              }
            }
          }
        }
      }
    }
    dateOfResponse
    createdAt
    createdBy {
      ...UserShortFragment
    }
    status
  }
  ${UserShortFragment}
`;

/**
 * Query the invitations
 * E.X. Input:
 *
 {
  "allianceData": {
    "email": { "equals": "alacret@gmail.com"}.
  },
  "allianceMemberInvitationFilter": {
    "email": { "equals": "alacret@gmail.com"}.
  }.
}.
 *
 */

export const INVITATIONS_QUERY = gql`
  query allianceInvitations(
    $allianceData: AllianceInvitationFilter!
    $allianceMemberInvitationFilter: AllianceMemberInvitationFilter!
    $companyInvitationFilter: CompanyInvitationFilter!
  ) {
    companyInvitationsList(filter: $companyInvitationFilter) {
      count
      items {
        ...CompanyInvitationFragment
      }
    }
    allianceInvitationsList(filter: $allianceData) {
      count
      items {
        ...AllianceInvitationFragment
      }
    }
    allianceMemberInvitationsList(filter: $allianceMemberInvitationFilter) {
      count
      items {
        ...AllianceMemberInvitationFragment
      }
    }
  }
  ${AllianceInvitationFragment}
  ${AllianceMemberInvitationFragment}
  ${CompanyInvitationFragment}
`;

export const SESSION_QUERY = gql`
  query SESSION_QUERY(
    $allianceData: AllianceInvitationFilter!
    $allianceMemberInvitationFilter: AllianceMemberInvitationFilter!
    $companyInvitationFilter: CompanyInvitationFilter!
    $CompanyFilter: CompanyFilter!
  ) {
    user {
      ...UserFragment
      selectedAlliance {
        ...SelectedAllianceFragment
      }
      companyUserRelation {
        count
        items {
          id
          allianceUserCompanyUserRelation {
            count
            items {
              id
              alliance {
                id
              }
              role {
                id
                name
              }
            }
          }
          company {
            id
          }
          role {
            id
            name
          }
        }
      }
    }
    companiesList(filter: $CompanyFilter) {
      count
      items {
        id
        name
        allianceClientCompanyRelation {
          count
        }
        companyCompanySubscriptionRelation {
          items {
            id
            last4
            status
            cardBrand
            cardExpireDate
            expireDate
            isAnnualPlan
            stripeSubscriptionId
            cancelAtPeriodEnd
            subscriptionPlan {
              id
              name
              price
              monthlyPrice
              anualSavings
              annualPrice
              monthlyStripePlanId
              annualStripePlanId
              allianceLimit
            }
            companyInvoice_companySubscriptionRelation {
              count
              items {
                id
                total
                status
                createdAt
                stripeInvoiceId
                receiptUrl
              }
            }
          }
        }
        logoFile {
          id
          downloadUrl
        }
      }
    }
    companyInvitationsList(filter: $companyInvitationFilter) {
      count
      items {
        ...CompanyInvitationFragment
      }
    }
    allianceInvitationsList(filter: $allianceData) {
      count
      items {
        ...AllianceInvitationFragment
      }
    }
    allianceMemberInvitationsList(filter: $allianceMemberInvitationFilter) {
      count
      items {
        ...AllianceMemberInvitationFragment
      }
    }
    systemSettingsList {
      items {
        name
        state
      }
    }
  }
  ${UserFragment}
  ${AllianceInvitationFragment}
  ${AllianceMemberInvitationFragment}
  ${CompanyInvitationFragment}
  ${SelectedAllianceFragment}
`;

export const USER_QUERY = gql`
  query User($id: ID) {
    user(id: $id) {
      ...UserFragment
      metaRelation {
        count
        items {
          name
          value
        }
      }
      companyUserRelation {
        count
        items {
          id
          allianceUserCompanyUserRelation {
            count
            items {
              id
              alliance {
                id
              }
              role {
                id
                name
              }
            }
          }
          company {
            id
            name
          }
          role {
            id
            name
          }
        }
      }
    }
  }
  ${UserFragment}
`;

export const BASIC_USER_QUERY = gql`
  query User($id: ID) {
    user(id: $id) {
      id
      firstName
      lastName
      email
    }
  }
`;

export const USERS_AND_ROLES_QUERY = gql`
  query {
    usersList {
      count
      items {
        ...UserFragment
      }
    }
    rolesList {
      count
      items {
        ...RolesFragment
      }
    }
  }
  ${UserFragment}
  ${RoleFragment}
`;

export const CREATE_USER_QUERY = gql`
  mutation($user: UserCreateInput!, $authId: ID!) {
    userSignUpWithToken(user: $user, authProfileId: $authId) {
      id
      email
    }
  }
`;

export const COMPANY_CLIENT_SUBSCRIPTION = gql`
  query($filter: CompanyFilter!) {
    companiesList(filter: $filter) {
      items {
        companyCompanySubscriptionRelation {
          items {
            status
          }
        }
        id
      }
    }
  }
`;
