import React from 'react';
import * as toast from '../../../components/toast/Toast';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import { View } from '@cobuildlab/react-simple-state';
import { Card, Table, Dropdown, Menu, Heading, SelectField, InputField } from '@8base/boost';
import { DropdownBodyOnTable } from '../../../components/dropdown/DropdownBodyOnTable';
import { ViewCardBody } from '../../../components/card/ViewCardBody';
import sessionStore, { NEW_SESSION_EVENT } from '../../../shared/SessionStore';
import ModalWithInputs from '../../../components/ModalWithInputs';
import {
  fetchMembersAction,
  leaveCompanyAction,
  transferOwnerAction,
  changeMemberRoleAction,
  createUserInvitation,
  fetchCompanyAction,
  resetPasswordEmail,
} from './company-actions';
import YesNoDialog from '../../../components/dialogs/YesNoDialog';
import { getCompanyRoles } from '../../../shared/roles';
import { fetchRoles } from '../../dashboard/dashboard-actions';
import { ActionButton } from '../../../components/buttons/ActionButton';
import { onErrorMixin } from '../../../shared/mixins';
import { ActionButtonClose } from '../../../components/buttons/ActionButtonClose';
import plusCircleIcon from '../../../images/icons/plus-circle.svg';
import { TransparentButtonSvg } from '../../../components/buttons/TransparentButtonSvg';
import {
  canEditCompanyPermission,
  canInviteCompanyMember,
  isUserPortfolioOwner,
  isUserCompanyAdmin,
} from '@cobuildlab/collabtogrow-permissions';
import { OnRoles } from '../../../shared/session-events';
import {
  OnCompanyListMemebers,
  OnCompanyDetail,
  OnCompanyRoleChange,
  OnCompanyLeave,
  OnCompanyTransfer,
  OnCompanyResetPassword,
  OnCompanyResetPasswordError,
  OnCompanyError,
} from '../../settings/company-management/company-events';
import { OnCompanyCreateUserInvitation } from '../../wizard/wizard-events';

export default class CompanyMembersView extends View {
  constructor(props) {
    super(props);
    this.state = {
      members: [],
      loading: true,
      member: '',
      memberId: 0,
      companyUserId: 0,
      removeModalIsOpen: false,
      transferModalIsOpen: false,
      resetEmailModalIsOpen: false,
      changeRoleModalIsOpen: false,
      inviteModalIsOpen: false,
      isUserCanInvite: false,
      isPortfolioOwner: false,
      isCompanyAdmin: false,
      email: '',
      role: null,
      newRole: null,
      roles: [],
    };
    this.onError = onErrorMixin.bind(this);
  }

  componentDidMount() {
    this.subscribe(OnCompanyError, this.onError);

    this.subscribe(OnRoles, (data) => {
      // Filter the roles to only include the ones that are related to company
      const validRoles = getCompanyRoles();
      const filteredRoles = data.filter(
        (item) => validRoles.find((validRole) => validRole.name === item.name) !== undefined,
      );

      this.setState({ roles: filteredRoles });
    });

    this.subscribe(OnCompanyListMemebers, (state) => {
      const user = sessionStore.getState(NEW_SESSION_EVENT).user;

      // Not sure why the next few lines were there
      // Filter out the currently logged in user
      // const filteredMembers = state.companyUsersList.items.filter(
      //   (member) => member.user.id !== user.id,
      // );

      // Find own user to set the companyUserId to the state
      const ownUser = state.companyUsersList.items.find((member) => member.user.id === user.id);
      this.setState({
        members: state.companyUsersList,
        companyUserId: ownUser.id,
        loading: false,
      });
    });

    this.subscribe(OnCompanyCreateUserInvitation, () => {
      toast.success('Invitation Successfully Sent');
      fetchMembersAction(this.props.match.params.id);
      fetchRoles();
    });

    this.subscribe(OnCompanyDetail, (state) => {
      const { company } = state;
      const { user } = sessionStore.getState(NEW_SESSION_EVENT);

      const isUserManage = canEditCompanyPermission(user, company);

      if (!isUserManage) this.props.history.goBack();

      this.setState({
        company,
        isUserCanInvite: canInviteCompanyMember(user, company),
        isPortfolioOwner: isUserPortfolioOwner(user, company),
        isCompanyAdmin: isUserCompanyAdmin(user, company),
      });
    });

    this.subscribe(OnCompanyRoleChange, () => {
      toast.success('Role Changed Successfully');
      fetchMembersAction(this.props.match.params.id);
    });

    this.subscribe(OnCompanyLeave, () => {
      toast.success('Member Removed');
      fetchMembersAction(this.props.match.params.id);
    });

    this.subscribe(OnCompanyTransfer, () => {
      toast.success('You transferred the ownership successfully');
      const { match } = this.props;
      this.props.history.push(`/settings/company-management/${match.params.id}`);
    });
    this.subscribe(OnCompanyResetPassword, () => {
      toast.success('Reset Password email sent');
      this.setState({
        loading: false,
      });
    });
    this.subscribe(OnCompanyResetPasswordError, (e) => {
      toast.error('e');
      this.setState({
        loading: false,
      });
    });

    fetchCompanyAction(this.props.match.params.id);
    fetchMembersAction(this.props.match.params.id);
    fetchRoles();
  }

  onChange = (name, value) => {
    this.setState({ [name]: value });
  };

  onChangeRole = (value) => {
    this.setState({ role: value });
  };

  onNewRole = (value) => {
    this.setState({ newRole: value });
  };

  onSelectForRemove = (id) => {
    this.setState({
      memberId: id,
      removeModalIsOpen: true,
    });
  };

  onSelectForTransfer = (id) => {
    this.setState({
      memberId: id,
      transferModalIsOpen: true,
    });
  };
  onSelectForResetEmail = (email) => {
    this.setState({
      userEmail: email,
      resetEmailModalIsOpen: true,
    });
  };

  onSelectForChangeRole = (member) => {
    this.setState({
      member,
      changeRoleModalIsOpen: true,
    });
  };

  onYesRemove = () => {
    this.setState(
      {
        removeModalIsOpen: false,
        loading: true,
      },
      () => leaveCompanyAction(this.state.memberId, this.state.company),
    );
  };

  onYesTransfer = () => {
    this.setState(
      {
        transferModalIsOpen: false,
        loading: true,
      },
      () => transferOwnerAction(this.state.companyUserId, this.state.memberId),
    );
  };
  onYesReset = () => {
    this.setState(
      {
        resetEmailModalIsOpen: false,
        loading: true,
      },
      () => resetPasswordEmail(this.state.userEmail),
    );
  };
  onYesChangeRole = () => {
    const role = this.state.roles.find((role) => role.id === this.state.newRole);
    this.setState(
      {
        changeRoleModalIsOpen: false,
        loading: true,
      },
      () => {
        changeMemberRoleAction(this.state.member, role, this.state.members);
      },
    );
  };

  onYesInvite = () => {
    const { email, role } = this.state;
    this.setState(
      {
        inviteModalIsOpen: false,
        loading: true,
      },
      () => {
        createUserInvitation(email, role);
      },
    );
  };

  onSelectForInvite = () => {
    this.setState({
      inviteModalIsOpen: true,
    });
  };

  onClose = () => {
    this.setState({
      email: '',
      role: null,
      member: '',
      newRole: null,
      removeModalIsOpen: false,
      transferModalIsOpen: false,
      changeRoleModalIsOpen: false,
      inviteModalIsOpen: false,
      resetEmailModalIsOpen: false,
    });
  };

  render() {
    const {
      removeModalIsOpen,
      transferModalIsOpen,
      changeRoleModalIsOpen,
      inviteModalIsOpen,
      loading,
      isUserCanInvite,
      isPortfolioOwner,
      isCompanyAdmin,
      resetEmailModalIsOpen,
    } = this.state;
    const { history } = this.props;
    const { user } = sessionStore.getState(NEW_SESSION_EVENT);
    const topButtons = !loading ? (
      <div className="company-icons">
        {isUserCanInvite ? (
          <TransparentButtonSvg
            iconSvg={plusCircleIcon}
            onClick={() => this.onSelectForInvite()}
            text="Invite Member"
          />
        ) : (
          ''
        )}

        <ActionButtonClose onClick={history.goBack} />
      </div>
    ) : null;

    return (
      <React.Fragment>
        <Card.Header>
          <Heading type="h4" text="Member Management" />
          {topButtons}
        </Card.Header>
        <ViewCardBody borderRadius="all" style={{ padding: 0, textAlign: 'center' }}>
          <div id={'c2gScreen'}>
            <Table>
              <Table.Header columns="repeat(5, 1fr)">
                <Table.HeaderCell>First Name</Table.HeaderCell>
                <Table.HeaderCell>Last Name</Table.HeaderCell>
                <Table.HeaderCell>Email</Table.HeaderCell>
                <Table.HeaderCell>Role</Table.HeaderCell>
                <Table.HeaderCell>Options</Table.HeaderCell>
              </Table.Header>
              <Table.Body loading={this.state.loading} data={this.state.members.items}>
                {(member) => (
                  <Table.BodyRow columns="repeat(5, 1fr)" key={member.id}>
                    <Table.BodyCell>{R.pathOr('', ['user', 'firstName'], member)}</Table.BodyCell>
                    <Table.BodyCell>{R.pathOr('', ['user', 'lastName'], member)}</Table.BodyCell>
                    <Table.BodyCell>{R.pathOr('', ['user', 'email'], member)}</Table.BodyCell>
                    <Table.BodyCell>{R.pathOr('', ['role', 'name'], member)}</Table.BodyCell>
                    <Table.BodyCell>
                      {member.user.id !== user.id && (
                        <Dropdown defaultOpen={false}>
                          <Dropdown.Head>
                            <ActionButton onClick={() => {}} text="Options" />
                          </Dropdown.Head>
                          <DropdownBodyOnTable>
                            {({ closeDropdown }) => (
                              <Menu>
                                {isPortfolioOwner && (
                                  <Menu.Item
                                    onClick={() => {
                                      closeDropdown();
                                      this.onSelectForChangeRole(member);
                                    }}>
                                    Change Role
                                  </Menu.Item>
                                )}
                                <Menu.Item
                                  onClick={() => {
                                    closeDropdown();
                                    this.onSelectForRemove(member.id);
                                  }}>
                                  Remove Member
                                </Menu.Item>
                                <Menu.Item
                                  onClick={() => {
                                    closeDropdown();
                                    this.onSelectForTransfer(member.id);
                                  }}>
                                  Transfer Ownership
                                </Menu.Item>
                                {(isPortfolioOwner || isCompanyAdmin) && (
                                  <Menu.Item
                                    onClick={() => {
                                      closeDropdown();
                                      this.onSelectForResetEmail(member.user.email);
                                    }}>
                                    Send Reset Password Link
                                  </Menu.Item>
                                )}
                              </Menu>
                            )}
                          </DropdownBodyOnTable>
                        </Dropdown>
                      )}
                    </Table.BodyCell>
                  </Table.BodyRow>
                )}
              </Table.Body>
            </Table>
          </div>
        </ViewCardBody>
        <YesNoDialog
          isOpen={removeModalIsOpen}
          onYes={this.onYesRemove}
          onNo={this.onClose}
          onClose={this.onClose}
          text={'Are you sure that you want to remove this user from the Company?'}
          title={'Remove Member'}
        />
        <YesNoDialog
          isOpen={transferModalIsOpen}
          onYes={this.onYesTransfer}
          onNo={this.onClose}
          onClose={this.onClose}
          text={'Are you sure that you want to Transfer Ownership of the Company to this user?'}
          title={'Transfer Ownership'}
        />
        <YesNoDialog
          isOpen={resetEmailModalIsOpen}
          onYes={this.onYesReset}
          onNo={this.onClose}
          onClose={this.onClose}
          text={'Are you sure that you want to send a reset password email to this user?'}
          title={'Send Reset Password Email'}
        />

        {/* Change role Modal */}
        <ModalWithInputs
          isOpen={changeRoleModalIsOpen}
          onYes={this.onYesChangeRole}
          onNo={this.onClose}
          onClose={this.onClose}
          yesText={'Change Role'}
          noText={'Cancel'}
          text={'Select the new Role for this member'}
          title={'Role Change'}>
          <React.Fragment>
            <SelectField
              label="Role"
              input={{
                name: 'role',
                value: this.state.newRole,
                onChange: (value) => this.onNewRole(value),
              }}
              meta={{}}
              placeholder="Select Role"
              options={this.state.roles.map((rol) => {
                return { label: rol.name, value: rol.id };
              })}
            />
          </React.Fragment>
        </ModalWithInputs>

        {/* Create Invite Modal */}
        <ModalWithInputs
          isOpen={inviteModalIsOpen}
          onYes={this.onYesInvite}
          onNo={this.onClose}
          onClose={this.onClose}
          yesText={'Invite Member'}
          noText={'Cancel'}
          text={'Type the email of the user you wish to invite to your Company'}
          title={'Invite a new Member'}>
          <React.Fragment>
            <InputField
              stretch
              placeholder="Email"
              label="User Email"
              input={{
                name: 'email',
                value: this.state.email,
                onChange: (value, e) => this.onChange(e.target.name, value),
              }}
            />
            <br />
            <SelectField
              label="Role"
              input={{
                name: 'role',
                value: this.state.role,
                onChange: (value) => this.onChangeRole(value),
              }}
              meta={{}}
              placeholder="Select Role"
              options={this.state.roles.map((rol) => {
                return { label: rol.name, value: rol.id };
              })}
            />
          </React.Fragment>
        </ModalWithInputs>
      </React.Fragment>
    );
  }
}

CompanyMembersView.propTypes = {
  history: PropTypes.object.isRequired,
};
