import gql from 'graphql-tag';

const SalesforceConnectionFragment = gql`
  fragment SalesforceConnectionFragment on SalesforceConnection {
    id
    accessToken
    organizationId
    userId
    instanceUrl
    updateInterval
    lastUpdate
    filterName
    customField
  }
`;

const SalesforceFieldMappingFragment = gql`
  fragment SalesforceFieldMappingFragment on SalesforceFieldMapping {
    id
    object
    dealField
    salesforceField
  }
`;

export const SalesforceLogFragment = gql`
  fragment SalesforceLogFragment on SalesforceLog {
    id
    createdAt
    level
    message
  }
`;

export const SALESFORCE_OAUTH2_AUTHORIZATION_RESOLVER = gql`
  query SALESFORCE_OAUTH2_AUTHORIZATION_RESOLVER($data: SalesforceOauth2CallbackInput!) {
    salesforceOauth2Callback(data: $data) {
      success
      message
    }
  }
`;

export const SALESFORCE_CONNECTION_LIST_QUERY = gql`
  query SALESFORCE_CONNECTION_LIST_QUERY($filter: SalesforceConnectionFilter!) {
    salesforceConnectionsList(filter: $filter, first: 1, sort: [{ createdAt: DESC }]) {
      items {
        ...SalesforceConnectionFragment
      }
    }
  }
  ${SalesforceConnectionFragment}
`;

export const SALESFORCE_FIELD_MAPPING_LIST_QUERY = gql`
  query SALESFORCE_FIELDS_MAPPING_LIST_QUERY($filter: SalesforceFieldMappingFilter!) {
    salesforceFieldMappingsList(filter: $filter) {
      items {
        ...SalesforceFieldMappingFragment
      }
    }
  }
  ${SalesforceFieldMappingFragment}
`;

export const SALESFORCE_CONNECTION_REFRESH_QUERY = gql`
  query SALESFORCE_CONNECTION_REFRESH_QUERY($id: ID!) {
    salesforceRefreshToken(id: $id) {
      accessToken
    }
  }
`;

export const SALESFORCE_FIELD_MAPPING_DELETE_MUTATION = gql`
  mutation SALESFORCE_FIELD_MAPPING_DELETE_MUTATION($data: SalesforceFieldMappingDeleteInput!) {
    salesforceFieldMappingDelete(data: $data, force: true) {
      success
    }
  }
`;

export const SALESFORCE_FIELD_MAPPING_UPDATE_MUTATION = gql`
  mutation SALESFORCE_FIELD_MAPPING_UPDATE_MUTATION($data: SalesforceFieldMappingUpdateInput!) {
    salesforceFieldMappingUpdate(data: $data) {
      ...SalesforceFieldMappingFragment
    }
  }
  ${SalesforceFieldMappingFragment}
`;

export const SALESFORCE_FIELD_MAPPING_CREATE_MUTATION = gql`
  mutation SALESFORCE_FIELD_MAPPING_CREATE_MUTATION($data: SalesforceFieldMappingCreateInput!) {
    salesforceFieldMappingCreate(data: $data) {
      ...SalesforceFieldMappingFragment
    }
  }
  ${SalesforceFieldMappingFragment}
`;

export const SALESFORCE_CONNECTION_UPDATE_MUTATION = gql`
  mutation SALESFORCE_CONNECTION_UPDATE_MUTATION($data: SalesforceConnectionUpdateInput!) {
    salesforceConnectionUpdate(data: $data) {
      ...SalesforceConnectionFragment
    }
  }
  ${SalesforceConnectionFragment}
`;

export const SALESFORCE_LOG_LIST_QUERY = gql`
  query SALESFORCE_LOG_LIST_QUERY($id: ID!) {
    salesforceConnection(id: $id) {
      lastUpdate
      salesforceLogs(first: 50, sort: [{ createdAt: DESC }]) {
        items {
          ...SalesforceLogFragment
        }
      }
    }
  }
  ${SalesforceLogFragment}
`;

export const SALESFORCE_UPDATE_NOW_MUTATION = gql`
  mutation SALESFORCE_UPDATE_NOW_MUTATION($id: ID!) {
    salesforceUpdateNow(id: $id) {
      success
    }
  }
`;

export const SALESFORCE_LOG_SUBSCRIPTION = gql`
  subscription($filter: SalesforceLogSubscriptionFilter) {
    SalesforceLogs(filter: $filter) {
      mutation
      node {
        id
      }
    }
  }
`;
