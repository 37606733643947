import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';

const DealDate = (props) => {
  const { dealDate } = props; //2019-10-01
  let date;
  let isValid = true;
  if (dealDate) {
    date = moment(dealDate, 'YYYY-MM-DD', true).format('MMMM Do, YYYY');
    if (date === 'Invalid date') isValid = false;
  }
  return (
    <>
      {dealDate === undefined || dealDate === null || !isValid ? (
        <span style={{ color: 'lightgrey' }}>Not Available</span>
      ) : (
        date
      )}
    </>
  );
};

DealDate.propTypes = {
  dealDate: PropTypes.string,
};

DealDate.defaultProps = {
  dealDate: null,
};

export default DealDate;
