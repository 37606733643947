import {
  IDEA_APPROVAL_LIST_EVENT,
  IDEA_AUTO_SAVE_ERROR_EVENT,
  IDEA_AUTO_SAVE_EVENT,
  IDEA_COMPLETED_EVENT,
  IDEA_CREATE_EVENT,
  IDEA_DELETE_EVENT,
  IDEA_DETAIL_EVENT,
  IDEA_ERROR_EVENT,
  IDEA_FULL_LIST_EVENT,
  IDEA_LIST_EVENT,
  IDEA_REJECT_EVENT,
  IDEA_RESTORE_EVENT,
  IDEA_SUBMIT_FOR_APPROVAL_EVENT,
  IDEA_UPDATE_EVENT,
} from './idea-store';
import Flux from '@cobuildlab/flux-state';
import { IntegrityError } from '../../../shared/errors';
import { ideaValidator } from './idea-validators';
import { businessCaseValidator } from '../../document-management/business-case/business-case-validators';
import { initiativesItemValidator } from '../initiative/initiative-validators';
import sessionStore, { APOLLO_CLIENT, NEW_SESSION_EVENT } from '../../../shared/SessionStore';
import {
  IDEA_APPROVAL_MUTATION,
  IDEA_APPROVAL_UPDATE_MUTATION,
  IDEA_APPROVALS_LIST_QUERY,
  IDEA_COMMENTS_QUERY,
  IDEA_CREATE_MUTATION,
  IDEA_DELETE_MUTATION,
  IDEA_DETAIL_QUERY,
  IDEA_LIST_QUERY,
  IDEA_UPDATE_MUTATION,
} from './idea-queries';
import { error, log } from '@cobuildlab/pure-logger';
import {
  filterForYear,
  sanitize8BaseBigInt,
  sanitize8BaseDate,
  sanitize8BaseReconnectsFromObjects,
  sanitize8BaseReferenceFromObject,
  sanitize8BaseReferencesFromObjects,
} from '../../../shared/utils';
import {
  getActiveAllianceId,
  getCompanyOnAlliance,
  isApprovalComplete,
} from '../../../shared/alliance-utils';
import {
  businessCaseSearchFilterOR,
  sanitizeRecommendedSolutions,
  updateBusinessCase,
} from '../../document-management/business-case/businessCases.actions';
import {
  IDEA_APPROVAL_APPROVED,
  IDEA_APPROVAL_REJECTED,
  IDEA_APPROVED,
  IDEA_COMPLETED,
  IDEA_IN_PROGRESS,
  IDEA_REJECTED,
  IDEA_SUBMITTED_FOR_APPROVAL,
} from '../../../shared/status';
import {
  canApproveIdea,
  canCompletedIdea,
  canRejectIdea,
  canRestoreIdea,
  canSubmitForApprovalIdea,
} from './idea-permissions';
import {
  COMMENT_CREATE_EVENT,
  COMMENT_ERROR_EVENT,
  COMMENT_REQUEST_EVENT,
  COMMENTS_EVENT,
} from '../../comment/comment-store';
import { IDEA_TYPE } from '../../../shared/item-types';
import { COMMENTS_CREATE_MUTATION } from '../../comment/comment-queries';
import {
  completeItemsNextSteps,
  deleteNextSteps,
  nextStepsToBeCreated,
  sanitizeNextStepsCreate,
  sanitizeNextStepsToEdit,
  updateNextSteps,
} from '../../next-step/next-step-actions';
import { RELATED_ITEM_UPDATE_MUTATION } from '../../related-item/related-item-queries';
import { INITIATIVE_CREATE_MUTATION } from '../initiative/initiative-queries';
import * as R from 'ramda';
import {
  normalize8baseDocumentCreate,
  normalize8baseDocumentsCreate,
  normalize8baseDocumentsDeleteAndUpdate,
} from '@cobuildlab/8base-utils';
import {
  OnIdeaDelete,
  OnIdeaError,
  OnIdeaList,
  OnIdeaSubmitApproval,
  OnIdeaFullList,
  OnIdeaCreate,
  OnIdeaAutoSaveCreate,
  OnIdeaAutoSaveCreateError,
  OnIdeaDetail,
  OnIdeaApprovalList,
  OnIdeaRestore,
  OnIdeaReject,
  OnIdeaUpdate,
  OnIdeaCompleted,
} from './idea-events';
import {
  OnCommentError,
  OnCommentRequest,
  OnCommentCreate,
  OnComment,
} from '../../comment/comment-events';

/**
 * Creates a filter object and search by a string
 on string properties of the idea.
 *
 * @param {string} data - Data to create Filter object.
 
 * @returns {object} Filter object.
 */
export const ideaFilter = ({ allianceId, search = '', status, filterYear = null }) => {
  const filter = {
    alliance: { id: { equals: allianceId } },
    idea: {
      id: { not_equals: null },
      OR: [
        {
          name: {
            contains: search,
          },
        },
        {
          description: {
            contains: search,
          },
        },
        {
          requestedBy: {
            firstName: { contains: search },
          },
        },
        {
          requestedBy: {
            lastName: { contains: search },
          },
        },
        {
          requestedBy: {
            email: { contains: search },
          },
        },
        {
          assignedTo: {
            firstName: { contains: search },
          },
        },
        {
          assignedTo: {
            lastName: { contains: search },
          },
        },
        {
          assignedTo: {
            email: { contains: search },
          },
        },
      ].concat(businessCaseSearchFilterOR(search)),
    },
  };

  if (filterYear) {
    if (filterYear) {
      const filterForYearData = filterForYear(
        filterYear.year,
        filterYear.fieldName,
        filterYear.isDateTime,
      );

      if (filterYear.fieldName === 'dateOfResponse') {
        filter.idea.ideaApprovalRelation = {
          some: {
            AND: filterForYearData.AND,
          },
        };
      } else {
        filter.idea.AND = filterForYearData.AND;
      }
    }
  }

  if (status) {
    filter.idea.status = { equals: status };
  }

  return filter;
};

/**
 * Notifies a Request for Comments for an Idea.
 */
export const openComments = ({ id: ideaId }) => {
  OnCommentRequest.dispatch({ type: IDEA_TYPE, id: ideaId });
  Flux.dispatchEvent(COMMENT_REQUEST_EVENT, { type: IDEA_TYPE, id: ideaId });
};

/**
 * Creates a New Idea
 * - TODO: Sets the Owner to the current logged User
 * - Create the Idea.
 *
 * @param {object} idea - Idea.
 * @param {object} businessCase - Business Case.
 * @param {Array} relatedItems - Related Items.
 * @param {Array} initiatives - Initiatives.
 * @param {object} savedIdea - Saved idea.
 * @returns {Promise<void>} Promise.
 */
export const createIdea = async (idea, businessCase, relatedItems, initiatives, savedIdea) => {
  const { user, selectedAlliance } = sessionStore.getState(NEW_SESSION_EVENT);
  const allianceId = getActiveAllianceId(user);
  const client = sessionStore.getState(APOLLO_CLIENT);

  if (!allianceId) {
    OnIdeaError.dispatch(new IntegrityError('Must have an Active Alliance'));
    return Flux.dispatchEvent(IDEA_ERROR_EVENT, new IntegrityError('Must have an Active Alliance'));
  }

  log('IdeaData: ', idea, businessCase, relatedItems, initiatives);
  try {
    ideaValidator(idea, selectedAlliance);
    initiativesItemValidator(initiatives);
    businessCaseValidator(businessCase);
  } catch (e) {
    error('createIdea', e);
    OnIdeaError.dispatch(e);
    return Flux.dispatchEvent(IDEA_ERROR_EVENT, e);
  }

  try {
    const _idea = await createOrUpdateIdea(
      idea,
      businessCase,
      relatedItems,
      initiatives,
      savedIdea,
    );

    await client.resetStore();
    OnIdeaCreate.dispatch(_idea);
    Flux.dispatchEvent(IDEA_CREATE_EVENT, _idea);
    return _idea;
  } catch (e) {
    error('createIdea', e);
    OnIdeaError.dispatch(e);
    return Flux.dispatchEvent(IDEA_ERROR_EVENT, e);
  }
};

/**
 * Creates a New Idea.
 *
 * @param {object}idea - Idea.
 * @param {object}businessCase - Business Case.
 * @param {Array}relatedItems - Related Items.
 * @param {Array}initiatives - Initiatives.
 * @returns {Promise<void>} Promise.
 */
export const _createIdea = async (idea, businessCase, relatedItems, initiatives) => {
  const client = sessionStore.getState(APOLLO_CLIENT);
  const { user } = sessionStore.getState(NEW_SESSION_EVENT);
  const allianceId = getActiveAllianceId(user);

  delete idea.id;
  delete businessCase.id;

  if (!allianceId) throw new IntegrityError('Must have an Active Alliance');

  log('IdeaData: ', idea, businessCase, relatedItems, initiatives);

  idea.initiatives = initiatives;
  sanitize8BaseReferencesFromObjects(idea, 'initiatives');
  normalize8baseDocumentsCreate(idea, 'documents');
  sanitize8BaseReferenceFromObject(idea, 'assignedTo');
  sanitize8BaseReferenceFromObject(idea, 'source');
  sanitize8BaseReferenceFromObject(idea, 'requestedBy');
  sanitize8BaseBigInt(idea, 'unitMonetizationFactor');

  sanitize8BaseDate(idea, 'assignedDate');
  sanitize8BaseDate(idea, 'originalDueDate');
  sanitize8BaseDate(idea, 'revisedDueDate');
  sanitize8BaseDate(idea, 'requestedDate');

  sanitizeNextStepsCreate(idea);
  sanitizeRecommendedSolutions(businessCase);
  normalize8baseDocumentCreate(businessCase, 'document');
  idea.businessCase = { create: businessCase };
  idea.itemIdeaRelation = {
    create: {
      alliance: { connect: { id: allianceId } },
      itemsRelated: {
        connect: relatedItems.map((item) => {
          return { id: item.itemId };
        }),
      },
    },
  };

  const {
    data: { ideaCreate },
  } = await client.mutate({
    mutation: IDEA_CREATE_MUTATION,
    variables: { data: idea },
  });

  return ideaCreate;
};

export const autoSaveCreateIdea = async (
  idea,
  businessCase,
  relatedItems,
  initiatives,
  savedIdea,
) => {
  console.log('Auto-Save: ', {
    idea,
    businessCase,
    relatedItems,
    initiatives,
    savedIdea,
  });

  const client = sessionStore.getState(APOLLO_CLIENT);

  try {
    const _idea = await createOrUpdateIdea(
      idea,
      businessCase,
      relatedItems,
      initiatives,
      savedIdea,
    );

    await client.resetStore();
    OnIdeaAutoSaveCreate.dispatch(_idea);
    Flux.dispatchEvent(IDEA_AUTO_SAVE_EVENT, _idea);
    console.log('autoSaveCreateIdea:response', _idea);
    return _idea;
  } catch (e) {
    OnIdeaAutoSaveCreateError.dispatch(e);
    Flux.dispatchEvent(IDEA_AUTO_SAVE_ERROR_EVENT, e);
    console.error('autoSaveCreateIdea', e);
  }
};

/**
 * Auto Save - Update idea Data.
 *
 * @param {object} idea - Idea.
 * @param {object} businessCaseData - BusinessCaseData.
 * @param {Array} relatedItems - RelatedItems.
 * @param {object} initiatives - Initiatives.
 * @param {Array} originalRecommendedSolutions - OriginalRecommendedSolutions.
 * @param {Array} originalNextSteps - OriginalNextSteps.
 * @param {object} originalIdeaData - OriginalDocuments.
 * @returns {Promise<void>} Return promise.
 */
export const autoSaveUpdateIdea = async (
  idea,
  businessCaseData,
  relatedItems,
  initiatives,
  originalRecommendedSolutions,
  originalNextSteps,
  originalIdeaData,
) => {
  const client = sessionStore.getState(APOLLO_CLIENT);

  try {
    const _idea = await _updateIdea(
      idea,
      businessCaseData,
      relatedItems,
      initiatives,
      originalRecommendedSolutions,
      originalNextSteps,
      originalIdeaData,
    );

    await client.resetStore();
    OnIdeaAutoSaveCreate.dispatch(_idea);
    Flux.dispatchEvent(IDEA_AUTO_SAVE_EVENT, _idea);
    console.log('autoSaveUpdateIdea:response', _idea);
    return _idea;
  } catch (e) {
    console.error('autoSaveUpdateIdea:error', e);
  }
};

export const createOrUpdateIdea = (idea, businessCase, relatedItems, initiatives, savedIdea) => {
  if (!savedIdea) {
    return _createIdea(idea, businessCase, relatedItems, initiatives);
  } else {
    const ideaData = R.clone(idea);
    const originalIdeaData = R.clone(savedIdea);

    const originalRecommendedSolutions = R.clone(
      originalIdeaData.businessCase.recommendedSolutionsRelation.items,
    );
    const originalNextSteps = sanitizeNextStepsToEdit(originalIdeaData);

    businessCase.id = originalIdeaData.businessCase.id;
    ideaData.id = originalIdeaData.id;
    ideaData.itemIdeaRelation = originalIdeaData.itemIdeaRelation;

    originalIdeaData.documents = originalIdeaData.documents.items;

    return _updateIdea(
      ideaData,
      businessCase,
      relatedItems,
      initiatives,
      originalRecommendedSolutions,
      originalNextSteps,
      originalIdeaData,
    );
  }
};

/**
 * Fetch the Idea by id of the Current Alliance.
 *
 * @param {string} id - Id.
 * @param {object} options - Object options.
 * @returns {Promise<void>} Promise.
 */
export const fetchIdea = async (id, options = {}) => {
  const client = sessionStore.getState(APOLLO_CLIENT);
  const fetchPolicy = options.isCacheFirst ? 'cache-first' : 'network-only';

  let response;

  try {
    response = await client.query({
      query: IDEA_DETAIL_QUERY,
      fetchPolicy,
      variables: { id },
    });
  } catch (e) {
    error('fetchIdea', e);
    throw e;
  }
  log('fetchIdea', response);
  return response.data;
};

/**
 * Edit an Idea
 - Create the Idea.
 *
 * @param {object} idea - Idea.
 * @param {object} businessCaseData - Business Case Data.
 * @param {Array} relatedItems - Related Items.
 * @param {Array} initiatives - Initiatives.
 * @param {Array} originalRecommendedSolutions - Original Recommended Solutions.
 * @param {Array} originalNextSteps - Original Next Steps.
 * @param {object}originalIdeaData - Original Idea Data.
 * @returns {Promise<void>} Promise.
 */
export const updateIdea = async (
  idea,
  businessCaseData,
  relatedItems,
  initiatives,
  originalRecommendedSolutions,
  originalNextSteps,
  originalIdeaData,
) => {
  const { selectedAlliance } = sessionStore.getState(NEW_SESSION_EVENT);
  const client = sessionStore.getState(APOLLO_CLIENT);

  log('UpdateIdea: ', idea, businessCaseData, relatedItems, initiatives);
  try {
    ideaValidator(idea, selectedAlliance);
    initiativesItemValidator(initiatives);
    businessCaseValidator(businessCaseData);
  } catch (e) {
    error('createIdea', e);
    OnIdeaError.dispatch(e);
    return Flux.dispatchEvent(IDEA_ERROR_EVENT, e);
  }

  try {
    const _idea = await _updateIdea(
      idea,
      businessCaseData,
      relatedItems,
      initiatives,
      originalRecommendedSolutions,
      originalNextSteps,
      originalIdeaData,
    );

    await client.resetStore();
    OnIdeaUpdate.dispatch(_idea);
    Flux.dispatchEvent(IDEA_UPDATE_EVENT, _idea);
    return _idea;
  } catch (e) {
    error('updateIdea', e);
    OnIdeaError.dispatch(e);
    Flux.dispatchEvent(IDEA_ERROR_EVENT, e);
  }
};

/**
 * Edit an Idea.
 *
 * @param {object} idea - Idea.
 * @param {object} businessCaseData - Business Case Data.
 * @param {Array} relatedItems - Related Items.
 * @param {Array} initiatives - Initiatives.
 * @param {Array} originalRecommendedSolutions - Original Recommended Solutions.
 * @param {Array} originalNextSteps - Original Next Steps.
 * @param {object}originalIdeaData - Original Idea Data.
 * @returns {Promise<void>} Promise.
 */
export const _updateIdea = async (
  idea,
  businessCaseData,
  relatedItems,
  initiatives,
  originalRecommendedSolutions,
  originalNextSteps,
  originalIdeaData,
) => {
  const client = sessionStore.getState(APOLLO_CLIENT);
  const itemId = idea.itemIdeaRelation.id;

  delete idea.__typename;
  delete idea.status;
  delete idea.createdAt;
  delete idea.createdBy;
  delete idea.businessCase;
  delete idea.itemIdeaRelation;
  delete idea.ideaApprovalRelation;
  delete businessCaseData.__typename;
  delete businessCaseData.owner;
  delete businessCaseData.status;
  delete businessCaseData.createdAt;
  delete businessCaseData.createdBy;
  delete businessCaseData.recommendedSolutionsRelation;

  log('UpdateIdea: ', idea, businessCaseData, relatedItems, initiatives);

  idea.initiatives = initiatives;

  sanitize8BaseReconnectsFromObjects(idea, 'initiatives');
  sanitize8BaseReferenceFromObject(idea, 'assignedTo');
  sanitize8BaseReferenceFromObject(idea, 'source');
  sanitize8BaseReferenceFromObject(idea, 'requestedBy');
  sanitize8BaseBigInt(idea, 'unitMonetizationFactor');
  sanitize8BaseDate(idea, 'assignedDate');
  sanitize8BaseDate(idea, 'originalDueDate');
  sanitize8BaseDate(idea, 'revisedDueDate');
  sanitize8BaseDate(idea, 'requestedDate');
  normalize8baseDocumentsDeleteAndUpdate(idea, 'documents', originalIdeaData);

  // nextSteps to be created
  const nextSteps = R.clone(idea.nextSteps);
  idea.nextSteps = nextStepsToBeCreated(nextSteps);
  sanitizeNextStepsCreate(idea);

  const originalBusinessCaseData = originalIdeaData.businessCase
    ? originalIdeaData.businessCase
    : null;

  // update and delete nextSteps
  await deleteNextSteps(nextSteps, originalNextSteps);
  await updateNextSteps(nextSteps, originalNextSteps);

  const {
    data: { ideaUpdate },
  } = await client.mutate({
    mutation: IDEA_UPDATE_MUTATION,
    variables: { data: idea },
  });

  await updateBusinessCase(
    businessCaseData,
    originalRecommendedSolutions,
    originalBusinessCaseData,
  );

  // Reconnect related items
  const relatedItemsData = {
    id: itemId,
    itemsRelated: {
      reconnect: relatedItems.map((i) => ({ id: i.itemId })),
    },
  };

  log('IdeaData:update:relatedItems:', relatedItemsData);
  await client.mutate({
    mutation: RELATED_ITEM_UPDATE_MUTATION,
    variables: { data: relatedItemsData },
  });

  return ideaUpdate;
};

/**
 * Submit For Approval an Idea.
 *
 * @param {object}amoItem - The Idea.
 * @returns {Promise<void>} Promise.
 */
export const requestApprovalForIdea = async (amoItem) => {
  const { user, selectedAlliance } = sessionStore.getState(NEW_SESSION_EVENT);
  const ideaId = amoItem.idea.id;
  const { idea } = await fetchIdea(ideaId);
  const client = sessionStore.getState(APOLLO_CLIENT);

  if (!canSubmitForApprovalIdea(user, idea, selectedAlliance)[0]) {
    OnIdeaError.dispatch(new IntegrityError('You do not have permission to perform this action'));

    return Flux.dispatchEvent(
      IDEA_ERROR_EVENT,
      new IntegrityError('You do not have permission to perform this action'),
    );
  }

  log('requestApprovalForIdea', 'toValidate', idea);
  // set initiatives & nextSteps for validators
  idea.initiatives = idea.initiatives.items;
  idea.nextSteps = idea.nextSteps.items;
  try {
    ideaValidator(idea, selectedAlliance);
    businessCaseValidator(idea.businessCase);
  } catch (e) {
    console.error('requestApprovalForIdea', e);
    OnIdeaError.dispatch(e);
    return Flux.dispatchEvent(IDEA_ERROR_EVENT, e);
  }

  try {
    const response = await _requestApprovalForIdea(idea);

    await client.resetStore();
    OnIdeaSubmitApproval.dispatch(response);
    Flux.dispatchEvent(IDEA_SUBMIT_FOR_APPROVAL_EVENT, response);
    return response;
  } catch (e) {
    console.error('requestApprovalForIdea', e, amoItem);
    OnIdeaError.dispatch(e);
    return Flux.dispatchEvent(IDEA_ERROR_EVENT, e);
  }
};

/**
 * Submit For Approval an Idea.
 *
 * @param {object} idea - The Idea.
 * @param {object} selectedAlliance - The alliance that holds the companies to
 *  be connected with.
 * @returns {Promise<void>} Promise.
 */
export const _requestApprovalForIdea = async (idea, selectedAlliance = null) => {
  const client = sessionStore.getState(APOLLO_CLIENT);
  if (!selectedAlliance)
    selectedAlliance = sessionStore.getState(NEW_SESSION_EVENT).selectedAlliance;

  const initiativeApprovalList = [
    { company: { connect: { id: selectedAlliance.clientCompany.id } } },
  ];
  if (!selectedAlliance.oneSided) {
    initiativeApprovalList.push({
      company: { connect: { id: selectedAlliance.partnerCompany.id } },
    });
  }

  const data = {
    id: idea.id,
    status: IDEA_SUBMITTED_FOR_APPROVAL,
    ideaApprovalRelation: {
      create: initiativeApprovalList,
    },
  };

  let response;
  try {
    response = await client.mutate({
      mutation: IDEA_UPDATE_MUTATION,
      variables: { data },
    });
  } catch (e) {
    console.error('requestApprovalForIdea', e, idea);
    throw e;
  }
  return response.data;
};

/**
 * Approve Idea.
 *
 * @param {string} idea - Idea.
 * @returns {Promise<void>} Promise.
 */
export const approveIdea = async (idea) => {
  const client = sessionStore.getState(APOLLO_CLIENT);
  const { selectedAlliance, user } = sessionStore.getState(NEW_SESSION_EVENT);
  const ideaId = idea.id;
  const allianceId = selectedAlliance.id;
  const company = getCompanyOnAlliance(user, { id: allianceId });

  if (!canApproveIdea(user, idea, { id: allianceId })) {
    OnIdeaError.dispatch(new IntegrityError(`Permission Denied. Can't approve this Idea`));

    return Flux.dispatchEvent(
      IDEA_ERROR_EVENT,
      new IntegrityError(`Permission Denied. Can't approve this Idea`),
    );
  }

  // We fetch the approvals to see what's gonna happen
  const { ideaApprovalsList } = await fetchIdeaApprovals(ideaId);
  const approvalsList = ideaApprovalsList.items.slice(0, 2);

  if (!selectedAlliance.oneSided && approvalsList.length < 2) {
    OnIdeaError.dispatch(
      new IntegrityError('Idea must have have at least 2 request for approvals'),
    );

    return Flux.dispatchEvent(
      IDEA_ERROR_EVENT,
      new IntegrityError('Idea must have have at least 2 request for approvals'),
    );
  }

  let approvalsCounter = 0;
  let approvalId = null;
  approvalsList.forEach((approval) => {
    if (approval.status === IDEA_APPROVAL_APPROVED) approvalsCounter++;
    if (approval.company.id === company.id) approvalId = approval.id;
  });
  if (approvalId === null) {
    OnIdeaError.dispatch(
      new IntegrityError("You can't approve this Idea, your Company does not belong here."),
    );

    return Flux.dispatchEvent(
      IDEA_ERROR_EVENT,
      new IntegrityError("You can't approve this Idea, your Company does not belong here."),
    );
  }

  // Update the status of the Aproval
  const mutation = {
    mutation: IDEA_APPROVAL_UPDATE_MUTATION,
    variables: {
      approval: {
        id: approvalId,
        status: IDEA_APPROVAL_APPROVED,
        dateOfResponse: new Date(),
        approvedBy: { connect: { id: user.id } },
      },
    },
  };
  // If there are more than 0 approvals: Update the status of the idea
  if (isApprovalComplete(approvalsCounter)) {
    mutation.mutation = IDEA_APPROVAL_MUTATION;
    mutation.variables.idea = {
      id: ideaId,
      status: IDEA_APPROVED,
    };

    try {
      await createInitiativeOfApprovedIdea(ideaId);
    } catch (e) {
      error('createInitiativeOfApprovedIdeaError', e);
      OnIdeaError.dispatch(e);
      return Flux.dispatchEvent(IDEA_ERROR_EVENT, e);
    }
  }

  let response;
  try {
    response = await client.mutate(mutation);
  } catch (e) {
    error('approveIdea', e);
    OnIdeaError.dispatch(e);
    return Flux.dispatchEvent(IDEA_ERROR_EVENT, e);
  }
  log('approveIdea', response);

  await client.resetStore();
  OnIdeaUpdate.dispatch(response.data);
  Flux.dispatchEvent(IDEA_UPDATE_EVENT, response.data);
  return response.data;
};

/**
 * Creates an initiative from an idea.
 *
 * @param  {string}ideaId - Idea Id.
 * @returns {Promise<void>} Promise.
 */
const createInitiativeOfApprovedIdea = async (ideaId) => {
  const client = sessionStore.getState(APOLLO_CLIENT);
  const user = sessionStore.getState(NEW_SESSION_EVENT).user;
  const allianceId = getActiveAllianceId(user);
  const {
    idea: { name, description, businessCase, revisedDueDate, requestedBy, requestedDate },
  } = await fetchIdea(ideaId);

  // TODO: copy documents
  const initiative = {
    name,
    description,
    owner: requestedBy,
    baselineStartDate: revisedDueDate,
    baselineEndDate: revisedDueDate,
    forecastedEndDate: revisedDueDate,
    alliance: { id: allianceId },
    requestedBy,
    requestedDate,
  };
  const businessCaseData = R.clone(businessCase);
  delete businessCaseData.id;
  delete businessCaseData.createdAt;
  delete businessCaseData.__typename;
  delete businessCaseData.document;

  // sanitize initiative
  sanitize8BaseDate(initiative, 'baselinestartDate');
  sanitize8BaseDate(initiative, 'baselineEndDate');
  sanitize8BaseDate(initiative, 'forecastedEndDate');
  sanitize8BaseReferenceFromObject(initiative, 'alliance');
  sanitize8BaseReferenceFromObject(initiative, 'requestedBy');
  sanitize8BaseReferenceFromObject(initiative, 'owner');
  sanitize8BaseDate(initiative, 'requestedDate');
  // sanitize businessCase
  businessCaseData.recommendedSolutionsRelation = {
    create: businessCaseData.recommendedSolutionsRelation.items.map(({ description }) => {
      return { description };
    }),
  };
  initiative.businessCase = {
    create: businessCaseData,
  };

  console.log('createInitiativeOfApprovedIdea: ', initiative);
  let response;
  try {
    response = await client.mutate({
      mutation: INITIATIVE_CREATE_MUTATION,
      variables: { data: initiative },
    });
  } catch (e) {
    error('createInitiativeOfApprovedIdea', e);
    throw e;
  }
  console.log('createInitiativeOfApprovedIdeaResponse: ', response);

  return response;
};

/**
 * Reject Idea.
 *
 * @param {object}idea - Idea.
 * @returns {Promise<void>} Promise.
 */
export const rejectIdea = async (idea) => {
  const client = sessionStore.getState(APOLLO_CLIENT);
  const user = sessionStore.getState(NEW_SESSION_EVENT).user;
  const ideaId = idea.id;
  const allianceId = getActiveAllianceId(user);
  const company = getCompanyOnAlliance(user, { id: allianceId });

  if (!canRejectIdea(user, idea, { id: allianceId })) {
    OnIdeaError.dispatch(new IntegrityError(`Permission Denied. Can't reject this Idea`));

    return Flux.dispatchEvent(
      IDEA_ERROR_EVENT,
      new IntegrityError(`Permission Denied. Can't reject this Idea`),
    );
  }

  // We fetch the approvals to see what's gonna happen
  const { ideaApprovalsList } = await fetchIdeaApprovals(ideaId);
  const approvalsList = ideaApprovalsList.items.slice(0, 2);

  if (approvalsList.length < 2) {
    OnIdeaError.dispatch(
      new IntegrityError('Idea must have have at least 2 request for approvals'),
    );

    return Flux.dispatchEvent(
      IDEA_ERROR_EVENT,
      new IntegrityError('Idea must have have at least 2 request for approvals'),
    );
  }

  let approvalId = null;

  approvalsList.forEach((approval) => {
    if (approval.company.id === company.id) {
      approvalId = approval.id;
    }
  });

  if (approvalId === null) {
    OnIdeaError.dispatch(
      new IntegrityError("You can't reject this Idea, your Company does not belong here."),
    );

    return Flux.dispatchEvent(
      IDEA_ERROR_EVENT,
      new IntegrityError("You can't reject this Idea, your Company does not belong here."),
    );
  }

  // Update the status of the Approval
  const userCompanyMutation = {
    mutation: IDEA_APPROVAL_MUTATION,
    variables: {
      approval: {
        id: approvalId,
        status: IDEA_APPROVAL_REJECTED,
        dateOfResponse: new Date(),
        approvedBy: { connect: { id: user.id } },
      },
      idea: {
        id: ideaId,
        status: IDEA_REJECTED,
      },
    },
  };

  /*const alliedCompanyMutation = {
    mutation: IDEA_APPROVAL_UPDATE_MUTATION,
    variables: {
      approval: {
        id: alliedCompanyApprovalId,
        status: IDEA_APPROVAL_REJECTED,
        dateOfResponse: new Date(),
      },
    },
  };*/

  let response;
  try {
    response = await client.mutate(userCompanyMutation);
    //await client.mutate(alliedCompanyMutation);
  } catch (e) {
    error('rejectIdea', e);
    OnIdeaError.dispatch(e);
    return Flux.dispatchEvent(IDEA_ERROR_EVENT, e);
  }
  log('rejectIdea', response);

  await client.resetStore();
  OnIdeaReject.dispatch(response.data);
  Flux.dispatchEvent(IDEA_REJECT_EVENT, response.data);
  return response.data;
};

/**
 * Fetches the Ideas approvals for the User.
 *
 * @param {string} ideaId - Idea Id.
 * @returns {Promise<void>} Promise.
 */
export const fetchIdeaApprovals = async (ideaId) => {
  const client = sessionStore.getState(APOLLO_CLIENT);
  const data = { idea: { id: { equals: ideaId } } };
  const sort = {
    createdAt: 'DESC',
  };
  let response;
  try {
    response = await client.query({
      query: IDEA_APPROVALS_LIST_QUERY,
      variables: { data, sort },
      fetchPolicy: 'network-only',
    });
  } catch (e) {
    error('fetchIdeaApprovals', e);
    return Flux.dispatchEvent(IDEA_ERROR_EVENT, e);
  }
  log('fetchIdeaApprovals', response);
  return response.data;
};

/**
 * Fetches the Idea Comments.
 *
 * @param {string}ideaId - Idea Id.
 * @returns {Promise<void>} Promise.
 */
export const fetchIdeaComments = async (ideaId) => {
  const client = sessionStore.getState(APOLLO_CLIENT);
  const data = { id: ideaId };
  const { user } = sessionStore.getState(NEW_SESSION_EVENT);

  let response;
  try {
    response = await client.query({
      query: IDEA_COMMENTS_QUERY,
      variables: data,
      fetchPolicy: 'network-only',
    });
  } catch (e) {
    error('fetchIdeaComments', e);
    OnCommentError.dispatch(e);
    return Flux.dispatchEvent(COMMENT_ERROR_EVENT, e);
  }
  log('fetchIdeaComments', response);

  const comments = R.clone(response.data.idea.comments);

  comments.items = response.data.idea.comments.items.map((comment) => ({
    ...comment,
  }));

  comments.userId = user.id;

  OnComment.dispatch(comments);
  Flux.dispatchEvent(COMMENTS_EVENT, comments);
  return comments;
};

/**
 * Create a comment on an Idea.
 *
 * @param {string}ideaId - Idea Id.
 * @param {object}comment - Comment.
 * @returns {Promise<*>}Promise.
 */
export const createIdeaComment = async (ideaId, comment) => {
  const client = sessionStore.getState(APOLLO_CLIENT);
  const data = {
    comment,
    ideaCommentsRelation: { connect: { id: ideaId } },
  };

  let response;
  try {
    response = await client.mutate({
      mutation: COMMENTS_CREATE_MUTATION,
      variables: { data },
    });
  } catch (e) {
    error('createIdeaComment', e);
    OnCommentError.dispatch(e);
    return Flux.dispatchEvent(COMMENT_ERROR_EVENT, e);
  }
  log('createIdeaComment', response);
  OnCommentCreate.dispatch(response.data);
  Flux.dispatchEvent(COMMENT_CREATE_EVENT, response.data);
  return response.data;
};

/**
 * Fetches the Ideas of the Current Alliance.
 *
 * @param {string} search - Search.
 * @param {number} page - Page.
 * @param {number} first - First.
 * @param {string} status - Status.
 * @param {object} filterYear - Filter year.
 * @param {object} options - Object options.
 * @returns {Promise<void>} Promise.
 */
export const fetchIdeas = async (
  search = '',
  page = 1,
  first = 20,
  status,
  filterYear = null,
  options = {},
) => {
  const client = sessionStore.getState(APOLLO_CLIENT);
  const user = sessionStore.getState(NEW_SESSION_EVENT).user;
  const allianceId = getActiveAllianceId(user);
  const skip = (page - 1) * first;
  const fetchPolicy = options.isCacheFirst ? 'cache-first' : 'network-only';

  if (!allianceId) {
    OnIdeaError.dispatch(new IntegrityError('An Alliance must be selected'));
    return Flux.dispatchEvent(IDEA_ERROR_EVENT, new IntegrityError('An Alliance must be selected'));
  }

  const filter = ideaFilter({ allianceId, search, status, filterYear });

  let response;
  try {
    response = await client.query({
      query: IDEA_LIST_QUERY,
      variables: { data: filter, skip, first },
      fetchPolicy,
    });
  } catch (e) {
    error('fetchIdeas', e);
    OnIdeaError.dispatch(e);
    Flux.dispatchEvent(IDEA_ERROR_EVENT, e);
    throw e;
  }

  log('fetchIdeas', response);
  OnIdeaList.dispatch(response.data);
  Flux.dispatchEvent(IDEA_LIST_EVENT, response.data);

  return response.data;
};

/**
 * Delete an idea.
 *
 * @param {object}idea - Idea Object.
 * @returns {Promise<*>} Promise.
 */
export const deleteIdea = async (idea) => {
  const client = sessionStore.getState(APOLLO_CLIENT);

  let response;
  try {
    response = await client.mutate({
      mutation: IDEA_DELETE_MUTATION,
      variables: { data: { id: idea.id, force: true } },
    });
  } catch (e) {
    error('deleteIdea', e);
    OnIdeaError.dispatch(e);
    return Flux.dispatchEvent(IDEA_ERROR_EVENT, e);
  }
  log('deleteIdea', response);
  await client.resetStore();
  OnIdeaDelete.dispatch(response.data);
  Flux.dispatchEvent(IDEA_DELETE_EVENT, response.data);
  return response.data;
};

/**
 * Completed a Idea.
 *
 * @param {object}ideaData - Idea Data.
 * @returns {Promise} Promise.
 */
export const completedIdea = async (ideaData) => {
  const client = sessionStore.getState(APOLLO_CLIENT);
  const { selectedAlliance, user } = sessionStore.getState(NEW_SESSION_EVENT);
  const { idea } = await fetchIdea(ideaData.id);

  let response;
  if (!canCompletedIdea(user, idea, selectedAlliance)) {
    OnIdeaError.dispatch(new IntegrityError('You do not have permission to perform this action'));

    return Flux.dispatchEvent(
      IDEA_ERROR_EVENT,
      new IntegrityError('You do not have permission to perform this action'),
    );
  }

  try {
    response = await client.mutate({
      mutation: IDEA_UPDATE_MUTATION,
      variables: {
        data: {
          id: idea.id,
          status: IDEA_COMPLETED,
        },
      },
    });
  } catch (e) {
    error('completedIdea', e);
    OnIdeaError.dispatch(e);
    return Flux.dispatchEvent(IDEA_ERROR_EVENT, e);
  }

  try {
    await completeItemsNextSteps(idea);
  } catch (e) {
    log('completeItemsNextStepsError', e);
  }

  await client.resetStore();
  OnIdeaCompleted.dispatch(response);
  Flux.dispatchEvent(IDEA_COMPLETED_EVENT, response);
  return response.data;
};

/**
 * Restore a Idea.
 *
 * @param {object}ideaData - Idea data.
 * @returns {Promise<void|*>} Promise.
 */
export const restoreIdea = async (ideaData) => {
  const client = sessionStore.getState(APOLLO_CLIENT);
  const { selectedAlliance, user } = sessionStore.getState(NEW_SESSION_EVENT);
  const { idea } = await fetchIdea(ideaData.id);

  let response;

  if (!canRestoreIdea(user, idea, selectedAlliance)) {
    OnIdeaError.dispatch(new IntegrityError('You do not have permission to perform this action'));

    return Flux.dispatchEvent(
      IDEA_ERROR_EVENT,
      new IntegrityError('You do not have permission to perform this action'),
    );
  }

  try {
    response = await client.mutate({
      mutation: IDEA_UPDATE_MUTATION,
      variables: {
        data: {
          id: idea.id,
          status: IDEA_IN_PROGRESS,
        },
      },
    });
  } catch (e) {
    error('restoreIdea', e);
    OnIdeaError.dispatch(e);
    return Flux.dispatchEvent(IDEA_ERROR_EVENT, e);
  }

  await client.resetStore();
  OnIdeaRestore.dispatch(response);
  Flux.dispatchEvent(IDEA_RESTORE_EVENT, response);
  return response.data;
};

/**
 * This function call idea data and dispatch event.
 *
 * @param {string} id - Id.
 * @param {object} options - Object options.
 * @returns {Promise<*>} Promise.
 */
export const fetchIdeaDetail = async (id, options = {}) => {
  let response;

  try {
    response = await fetchIdea(id, options);
  } catch (e) {
    OnIdeaError.dispatch(response);
    return Flux.dispatchEvent(IDEA_ERROR_EVENT, response);
  }

  OnIdeaDetail.dispatch(response);
  Flux.dispatchEvent(IDEA_DETAIL_EVENT, response);
  return response;
};

/**
 * Dispatch Event to Fetch Idea approvals list for idea detail view.
 *
 * @param {string}ideaId - Idea Id.
 * @returns {Promise<void|*>} Promise.
 */
export const fetchIdeaApprovalsList = async (ideaId) => {
  let response;

  try {
    response = await fetchIdeaApprovals(ideaId);
  } catch (e) {
    error('fetchIdeaApprovalsList', e);
    OnIdeaError.dispatch(e);
    return Flux.dispatchEvent(IDEA_ERROR_EVENT, e);
  }

  OnIdeaApprovalList.dispatch(response);
  Flux.dispatchEvent(IDEA_APPROVAL_LIST_EVENT, response);
  log('fetchIdeaApprovalsList', response);
  return response;
};

/**
 * Fetches a list of all Idea with a minimum data
 * for use as options in the Select component.
 */

export const fetchIdeaFullList = async (search = '') => {
  const client = sessionStore.getState(APOLLO_CLIENT);
  const { selectedAlliance } = sessionStore.getState(NEW_SESSION_EVENT);
  const activeAllianceId = selectedAlliance.id;

  log('allianceId', activeAllianceId);

  const filter = ideaFilter({ allianceId: activeAllianceId, search });
  let response;
  try {
    response = await client.query({
      query: IDEA_LIST_QUERY,
      fetchPolicy: 'network-only',
      variables: { data: filter },
    });
  } catch (e) {
    error('fetchAllIdeaList', e);
    OnIdeaError.dispatch(e);
    return Flux.dispatchEvent(IDEA_ERROR_EVENT, e);
  }

  log('fetchAllIdeaList', response);
  OnIdeaFullList.dispatch(response.data);
  Flux.dispatchEvent(IDEA_FULL_LIST_EVENT, response.data);
  return response.data;
};
