import styled from '@emotion/styled';
import { css } from '@emotion/core';

const FilterRow = styled('div')`
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 24px;
  ${({ withDeleteButton }) =>
    withDeleteButton &&
    css`
      padding-left: 23px;
    `}
  margin-bottom: 22px;
  grid-template-columns: 336px 336px 326px;
  position: relative;
  align-items: end;
`;

export { FilterRow };
