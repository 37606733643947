export const DEFAULT_MONTHS_RANGE = [1, 12];

// Balanced scorrcard full year selectors
export const YEAR = { value: '[1, 12]', label: 'Year' };
// Balanced scorrcard quearter month selectors
export const Q1 = { value: '[1, 3]', label: 'Q1' };
export const Q2 = { value: '[4, 6]', label: 'Q2' };
export const Q3 = { value: '[7, 9]', label: 'Q3' };
export const Q4 = { value: '[10, 12]', label: 'Q4' };
// Balanced scorrcard half month selectors
export const H_M1 = { value: '[1, 6]', label: 'H1' };
export const H_M2 = { value: '[7, 12]', label: 'H2' };
// Months ranges options
export const MONTHS_RANGES = [YEAR, Q1, Q2, Q3, Q4, H_M1, H_M2];

/**
 * Replace the number average 't' character with this value to get the full average number.
 *
 * @type {string}
 */
export const NUMBER_T = '000000000000';
