import gql from 'graphql-tag';
import { UserFragment } from '../../modules/auth/queries';

export const NextStepFragment = gql`
  fragment NextStepFragment on NextStep {
    id
    status
    dueDate
    description
    assignedTo {
      ...UserFragment
    }
  }
  ${UserFragment}
`;

export const NEXT_STEP_UPDATE_MUTATION = gql`
  mutation($data: NextStepUpdateInput!) {
    nextStepUpdate(data: $data) {
      id
    }
  }
`;

export const NEXT_STEP_DELETE_MUTATION = gql`
  mutation($data: NextStepDeleteInput!) {
    nextStepDelete(data: $data) {
      success
    }
  }
`;
