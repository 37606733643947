import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';

const DetailTable = styled('table')`
  font-family: Poppins;
  border-collapse: collapse;
  width: 100% !important;
  text-align: left;
  margin: 0 auto;
  & th {
    width: 150px !important;
    height: 33px;
    color: #7d828a !important;
    font-family: Poppins;
    border: 1px solid #f4f7f9;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0.5px;
    line-height: 16px;
    background-color: #edf0f2;
    min-height: 6%;
  }
  & td {
    border: 1px solid rgb(241, 241, 241);
    padding: 10px;
    height: 33px;
    width: 624px;
    color: #323c47;
    font-family: Poppins;
    font-size: 13px;
    line-height: 18px;
  }
`;

const TableDetail = ({ children }) => {
  return <DetailTable>{children}</DetailTable>;
};

TableDetail.propTypes = {
  children: PropTypes.any.isRequired,
};

export { TableDetail };
