import React from 'react';
import PropTypes from 'prop-types';
import { SelectField } from '@8base/boost';

const SelectInputById = ({ options, input, ...rest }) => {
  const { name, value, onChange, ...inputRest } = input;
  if (value) {
    if (value.id === undefined)
      throw new Error(
        `Error in '${name}' field: 'value' must have a .id property, right now is: ${JSON.stringify(
          value,
        )}`,
      );
  }

  const realOptions = options.map((option) => {
    if (option.value === undefined || option.value.id === undefined)
      throw new Error(`'options' must have a .value.id property`);
    return { label: option.label, value: option.value.id };
  });

  return (
    <SelectField
      input={{
        name,
        value: value ? value.id : null,
        onChange: (value) => {
          onChange(options.find((option) => option.value.id === value).value);
        },
        ...inputRest,
      }}
      options={realOptions}
      {...rest}
    />
  );
};

SelectInputById.propTypes = {
  options: PropTypes.array.isRequired,
  input: PropTypes.object.isRequired,
};

export { SelectInputById };
