import React from 'react';
import PropTypes from 'prop-types';
import Status from '../../../../components/Status';
import { HorizontalLineText } from '../../../../components/text/HorizontalLineText';
import { Table } from '@8base/boost';
import { Link } from 'react-router-dom';
import Moment from 'react-moment';
import DetailValue from '../../../../components/DetailValue';
import { TablePosition } from '../../../../components/new-ui/div/TablePosition';
import { TableDetail } from '../../../../components/new-ui/table/TableDetail';
import { HorizontalLine } from '../../../../components/new-ui/text/HorizontalLine';
import { formatUser } from '../../../../shared/utils';
const InitiativeListTable = (props) => {
  const { initiatives } = props;

  /*
    const StyledListCardBody = styled(ListCardBody)`
      min-height: 20vh !important;
    `;
  */

  let initiativeComponent = 'No initiatives selected';
  if (initiatives.length > 0) {
    initiativeComponent = (
      <TablePosition>
        <TableDetail>
          <Table.Header className="justify-center-column" columns="3fr 3fr 3fr 3fr 3fr">
            <Table.HeaderCell className="name-column">Name</Table.HeaderCell>
            <Table.HeaderCell>Owner</Table.HeaderCell>
            <Table.HeaderCell>Baseline Start Date</Table.HeaderCell>
            <Table.HeaderCell>Baseline End Date</Table.HeaderCell>
            <Table.HeaderCell>Status</Table.HeaderCell>
          </Table.Header>
          <Table.Body data={initiatives} className="card-body-list-details">
            {(initiative, index) => {
              const { id, name, baselineStartDate, baselineEndDate, status, owner } = initiative;

              return (
                <>
                  <Table.BodyRow
                    columns="3fr 3fr 3fr 3fr 3fr"
                    key={index}
                    className={'justify-center-column'}>
                    <Table.BodyCell className="justify-center-column">
                      <Link className="item-name" to={`/management/initiative/${id}/`}>
                        {name}
                      </Link>
                    </Table.BodyCell>
                    <Table.BodyCell>
                      <DetailValue text={formatUser(owner)} />
                    </Table.BodyCell>
                    <Table.BodyCell>
                      <Moment format="MMMM Do, YYYY">{baselineStartDate}</Moment>
                    </Table.BodyCell>
                    <Table.BodyCell>
                      <Moment format="MMMM Do, YYYY">{baselineEndDate}</Moment>
                    </Table.BodyCell>
                    <Table.BodyCell>
                      <Status status={status} />
                    </Table.BodyCell>
                  </Table.BodyRow>
                </>
              );
            }}
          </Table.Body>
        </TableDetail>
      </TablePosition>
    );
  }

  return (
    <>
      <HorizontalLine>
        <HorizontalLineText>RELATED INITIATIVES</HorizontalLineText>
      </HorizontalLine>
      {initiativeComponent}
    </>
  );
};

InitiativeListTable.propTypes = {
  initiatives: PropTypes.object.isRequired,
};

export { InitiativeListTable };
