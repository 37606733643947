export const COMPANY_ADMINISTRATOR = 'Company Administrator';
export const COMPANY_PORTFOLIO_OWNER = 'Company Portfolio Owner';
export const COMPANY_MEMBER = 'Company Member';
export const COMPANY_PROCUREMENT = 'Company Procurement';

/**
 * This list order matter. Do not change.
 *
 * @type {({name: string}|{name: string}|{name: string}|{name: string})[]}
 */
export const COMPANY_ROLES = [
  { name: COMPANY_PORTFOLIO_OWNER },
  { name: COMPANY_ADMINISTRATOR },
  { name: COMPANY_PROCUREMENT },
  { name: COMPANY_MEMBER },
];

export const getCompanyRoles = () => {
  return COMPANY_ROLES;
};

export const ALLIANCE_ADMINISTRATOR = 'Alliance Administrator';
export const ALLIANCE_SER = 'Alliance SER';
export const ALLIANCE_CREATOR = 'Alliance Creator';
export const ALLIANCE_COLLABORATOR = 'Alliance Collaborator';

export const ALLIANCE_ROLES = [
  { name: ALLIANCE_ADMINISTRATOR },
  { name: ALLIANCE_SER },
  { name: ALLIANCE_CREATOR },
  { name: ALLIANCE_COLLABORATOR },
];

export const getAllianceRoles = () => {
  return ALLIANCE_ROLES.concat();
};
