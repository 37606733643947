import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const MinusButton = ({ onClick }) => {
  return (
    <FontAwesomeIcon style={{ width: 16, height: 16 }} onClick={onClick} icon="minus-circle" />
  );
};

MinusButton.defaultProps = {
  onClick: () => {},
};
MinusButton.propTypes = {
  onClick: PropTypes.func,
};

export default MinusButton;
