import { createEvent } from '@cobuildlab/react-simple-state';

export const OnCompanyError = createEvent();
export const OnCompanyListMemebers = createEvent();
export const OnCompanyList = createEvent();
export const OnCompanyFormData = createEvent();
export const OnCompanyDetail = createEvent();
export const OnCompanyEdit = createEvent();
export const OnCompanyLeave = createEvent();
export const OnCompanySubscription = createEvent();
export const OnCompanyCreate = createEvent();
export const OnCompanyRoleChange = createEvent();
export const OnCompanyTransfer = createEvent();
export const OnCompanyResetPassword = createEvent();
export const OnCompanyResetPasswordError = createEvent();
