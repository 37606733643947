import React from 'react';
import styled from '@emotion/styled';
import { Grid } from '@8base/boost';
import PropTypes from 'prop-types';

const ContentPlateTag = styled(Grid.Box)({
  padding: '0 2rem 0rem 0',
  width: 'calc(100vw - 77px)',
  minHeight: 0,
});

const ContentPlate = ({ children }) => <ContentPlateTag area="content">{children}</ContentPlateTag>;

ContentPlate.propTypes = {
  // eslint-disable-next-line react/require-default-props
  children: PropTypes.element,
};

ContentPlate.defaultProps = {
  children: '<div></div>',
};

export { ContentPlate };
