import React from 'react';
import PropTypes from 'prop-types';
import LightGreyText from './LightGreyText';
import { formatUser } from '../shared/utils';

// Component checks if there's a value present to render
// or else returns unassignedText
const UserDetailValue = ({ user, fontSize, unassignedText }) => {
  if (user && user.firstName) {
    return <span style={fontSize ? { fontSize: fontSize } : {}}>{formatUser(user)}</span>;
  }
  return <LightGreyText fontSize={fontSize} text={unassignedText} />;
};

UserDetailValue.propTypes = {
  user: PropTypes.object,
  fontSize: PropTypes.number,
  unassignedText: PropTypes.string,
};

UserDetailValue.defaultProps = {
  user: {},
  fontSize: 12,
  unassignedText: 'Unassigned',
};

export default UserDetailValue;
