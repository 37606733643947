import React from 'react';
import PropTypes from 'prop-types';
import { Card, Grid, Heading, Loader, Row } from '@8base/boost';
import { CreateViewCardBody } from '../../../components/new-ui/card/CreateViewCardBody';
import DecisionForm from './components/DecisionForm';
import { onChangeDataMixin, onErrorMixin } from '../../../shared/mixins';
import * as R from 'ramda';
import DecisionModel, { DECISION_DOCUMENTS } from './decision-model';
import { withRouter } from 'react-router-dom';
import { fetchCurrentAllianceMembersAction } from '../../settings/alliance-management/alliance-actions';
import * as toast from '../../../components/toast/Toast';
import { View } from '@cobuildlab/react-simple-state';
import { autoSaveCreateDecision, createDecision } from './decision-actions';
import { fetchInitiativeList } from '../initiative/initiative-actions';
import SubHeader from '../../../components/SubHeader';
import DecisionDetailTable from './components/DecisionDetailTable';
import { getCurrencyOnSession } from '../../../shared/alliance-utils';
import { ActionButton } from '../../../components/buttons/ActionButton';
import { FormSteps } from '../../../components/dots/FormSteps';
import { ActionButtonClose } from '../../../components/buttons/ActionButtonClose';
import { TransparentButton } from '../../../components/buttons/TransparentButton';
import { decisionValidator } from './decision-validators';
import { saveFormToSessionStorage } from '../../../shared/utils';
import RelatedItemForm from '../../related-item/components/RelatedItemForm';
import { initiativesItemValidator } from '../initiative/initiative-validators';
import { RelatedItemsDetailTable } from '../../related-item/components/RelatedItemsDetailTable';
import { InitiativeListTable } from '../initiative/components/InitiativeListTable';
import { LeftProgressSection } from '../../../components/new-ui/LeftProgressSection';
import { SCREENS_DECISION } from '../screenView';
import { BoxCard } from '../../../components/new-ui/div/BoxCard';
import { CardFooter } from '../../../components/new-ui/card/CardFooter';
import { TextDateAgo } from '../../../components/text/TextDateAgo';
import {
  OnDecisionCreate,
  OnDecisionError,
  OnDecisionAutoSave,
  OnDecisionAutoSaveError,
} from './decision-events';
import { OnInitiativeList } from '../initiative/initiative-events';
import { OnAllianceMemberList } from '../../settings/alliance-management/alliance-events';

export const DECISION_DATA_STORE = 'decisionCreateView';

// eslint-disable-next-line
/**
 * Create Decision View.
 *
 */
class DecisionCreateView extends View {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        decisionData: R.clone(DecisionModel),
        initiatives: [],
        relatedItems: [],
      },
      initiativesList: [],
      clientCompany: null,
      partnerCompany: null,
      step: 0,
      loading: true,
      savedAt: null,
      autoSaving: false,
    };
    this.onError = onErrorMixin.bind(this);
    this.onChangeData = onChangeDataMixin.bind(this);

    this.autoSaveTimer = null;
    this.savedDecision = null;
  }

  onChangeDecisionData = (name, value) => {
    const { data } = this.state;
    data.decisionData[name] = value;
    this.autoSave();
    this.setState({ data });
    const model = R.clone(DecisionModel);
    saveFormToSessionStorage(DECISION_DATA_STORE, data.decisionData, model, ['documents']);
  };

  componentDidMount = () => {
    const decisionData = JSON.parse(sessionStorage.getItem(DECISION_DATA_STORE));

    this.subscribe(OnDecisionError, this.onError);

    this.subscribe(OnAllianceMemberList, (state) => {
      this.setState({
        clientCompany: R.clone(state.clientCompany),
        partnerCompany: R.clone(state.partnerCompany),
      });
    });

    this.subscribe(OnDecisionCreate, (state) => {
      sessionStorage.removeItem(DECISION_DATA_STORE);
      toast.success('Decision Successfully Created');
      this.props.history.push(`/management/amo-item`);
    });

    this.subscribe(OnInitiativeList, (state) => {
      this.setState({
        loading: false,
        initiativesList: R.clone(state.initiativesList.items),
      });
    });

    this.subscribe(OnDecisionAutoSave, (decision) => {
      sessionStorage.removeItem(DECISION_DATA_STORE);
      this.setState({ savedAt: new Date(), autoSaving: false });
      this.savedDecision = R.clone(decision);
    });

    this.subscribe(OnDecisionAutoSaveError, () => {
      this.setState({ autoSaving: false });
    });

    // set decisionData from sessionStorage
    if (decisionData) {
      const { data } = this.state;
      data.decisionData = decisionData;
      this.setState({ data });
    }

    const fetchPolicyOptions = { isCacheFirst: true };
    fetchInitiativeList('', 1, 1000, null, null, fetchPolicyOptions);
    fetchCurrentAllianceMembersAction();
  };

  componentWillUnmount() {
    super.componentWillUnmount();
    clearTimeout(this.autoSaveTimer);
    localStorage.removeItem(DECISION_DOCUMENTS);
  }

  autoSave = () => {
    const waitTime = 10000; // Wait 10s
    clearTimeout(this.autoSaveTimer);
    this.autoSaveTimer = setTimeout(() => {
      this.setState({ autoSaving: true });
      const decisionData = R.clone(this.state.data.decisionData);
      const relatedItems = R.clone(this.state.data.relatedItems);
      const initiatives = R.clone(this.state.data.initiatives);
      autoSaveCreateDecision(decisionData, relatedItems, initiatives, this.savedDecision);
    }, waitTime);
  };

  onSubmit = () => {
    this.setState({ loading: true }, () => {
      clearTimeout(this.autoSaveTimer);
      const decisionData = R.clone(this.state.data.decisionData);
      const relatedItems = R.clone(this.state.data.relatedItems);
      const initiatives = R.clone(this.state.data.initiatives);
      createDecision(decisionData, relatedItems, initiatives, this.savedDecision);
    });
  };

  onDecisionStepChange = (nextStep) => {
    const decisionData = R.clone(this.state.data.decisionData);
    try {
      decisionValidator(decisionData);
    } catch (e) {
      return this.onError(e);
    }
    this.onScreen(nextStep);
  };

  onRelatedItemsStepChange = (step) => {
    const initiatives = R.clone(this.state.data.initiatives);
    try {
      initiativesItemValidator(initiatives);
    } catch (e) {
      return this.onError(e);
    }
    this.onScreen(step);
  };

  onScreen = (step) => {
    if (step === 2) clearTimeout(this.autoSaveTimer);
    this.setState({ step });
  };

  render() {
    const {
      data,
      initiativesList,
      step,
      loading,
      clientCompany,
      partnerCompany,
      savedAt,
      autoSaving,
    } = this.state;
    const { decisionData, relatedItems, initiatives } = data;
    const { history } = this.props;

    const currency = getCurrencyOnSession();
    let content = <Loader stretch />;
    let footer = <></>;

    if (!loading && step === 0) {
      content = (
        <div id={'c2gScreen'}>
          <DecisionForm
            data={decisionData}
            onChange={this.onChangeDecisionData}
            clientCompany={clientCompany}
            partnerCompany={partnerCompany}
            currency={currency}
          />
        </div>
      );
      footer = (
        <CardFooter>
          <ActionButton onClick={() => this.onDecisionStepChange(1)} text={'Next'} />
        </CardFooter>
      );
    }

    if (!loading && step === 1) {
      content = (
        <div id={'c2gScreen'}>
          <RelatedItemForm
            selectedInitiatives={initiatives}
            relatedItems={relatedItems}
            initiatives={initiativesList}
            onChange={(key, value) => {
              this.autoSave();
              this.onChangeData(key, value);
            }}
          />
        </div>
      );

      footer = (
        <CardFooter>
          <ActionButton onClick={() => this.onRelatedItemsStepChange(2)} text={'Next'} />
          <TransparentButton onClick={() => this.onScreen(0)} text={'Previous'} />
        </CardFooter>
      );
    }

    if (!loading && step === 2) {
      const decisionDetail = R.clone(decisionData);

      decisionDetail.initiatives = { items: initiatives };
      decisionDetail.documents = { items: decisionDetail.documents };

      content = (
        <div id={'c2gScreen'}>
          <DecisionDetailTable data={decisionDetail} currency={currency} />
          <SubHeader text="Business Case" status={decisionDetail.status} />
          <InitiativeListTable initiatives={initiatives} />
          <RelatedItemsDetailTable relatedItems={relatedItems} />
        </div>
      );
      footer = (
        <CardFooter>
          <ActionButton
            disabled={autoSaving}
            onClick={() => this.onSubmit()}
            text="Create Decision"
          />
          <TransparentButton onClick={() => this.onScreen(1)} text="Previous" />
        </CardFooter>
      );
    }

    return (
      <React.Fragment>
        <Card.Header>
          <Grid.Layout
            columns="400px auto 400px"
            areas={[['left', 'center', 'right']]}
            style={{ width: '100%' }}>
            <Grid.Box area="left">
              <Row>
                <Heading type="h4" text="Create Decision" />
                <TextDateAgo from={savedAt} />
              </Row>
            </Grid.Box>
            <Grid.Box area="center">
              <FormSteps totalSteps={3} step={step} />
            </Grid.Box>
          </Grid.Layout>
          <ActionButtonClose onClick={history.goBack} />
        </Card.Header>
        <CreateViewCardBody>
          <Grid.Layout columns="30% 70%" areas={[['left', 'right']]} style={{ width: '100%' }}>
            <Grid.Box area="left">
              <LeftProgressSection sections={SCREENS_DECISION} currentScreen={step} />
            </Grid.Box>
            <BoxCard>
              <Grid.Box area="right">{content}</Grid.Box>
            </BoxCard>
          </Grid.Layout>
        </CreateViewCardBody>
        {footer}
      </React.Fragment>
    );
  }
}

DecisionCreateView.propTypes = {
  history: PropTypes.object.isRequired,
};

export default withRouter(DecisionCreateView);
