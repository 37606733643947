import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { Text } from '@8base/boost';

const StyledSmall2 = styled(Text)`
  font-family: Poppins;
  font-weight: 500;
  font-size: 10px;
  color: #7d828c;
  letter-spacing: 0.2px;
  line-height: 16px;
  text-transform: uppercase;
`;

const Small2 = ({ children }) => {
  return <StyledSmall2>{children}</StyledSmall2>;
};

Small2.propTypes = {
  children: PropTypes.any.isRequired,
};

export { Small2 };
