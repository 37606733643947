import gql from 'graphql-tag';
import { CompanyFragment } from '../settings/company-management/Company.queries';

export const CREATE_COMPANY_INFORMATION_MUTATION = gql`
  mutation($data: CompanyCreateInput!) {
    companyCreate(data: $data) {
      id
    }
  }
`;

export const UPDATE_COMPANY_INFORMATION_MUTATION = gql`
  mutation($data: CompanyUpdateInput!) {
    companyUpdate(data: $data) {
      id
    }
  }
`;

/**
 * Company Information Query
 * {
 *   "userId":  ""
 * }.
 */
export const COMPANY_INFORMATION_QUERY = gql`
  query($userId: ID) {
    companyUsersList(filter: { user: { id: { equals: $userId } } }) {
      items {
        company {
          ...CompanyFragment
        }
        role {
          id
          name
        }
      }
      count
    }
    countriesList(sort: { name: ASC }) {
      count
      items {
        id
        name
        code
      }
    }
    statesList(filter: { country: { name: { equals: "United States" } } }) {
      items {
        id
        name
      }
    }
    industriesList {
      count
      items {
        id
        name
      }
    }
    subscriptionPlansList(orderBy: order_ASC, filter: { active: { equals: true } }) {
      count
      items {
        id
        name
        price
        active
        anualSavings
        monthlyPrice
        annualPrice
        monthlyStripePlanId
        annualStripePlanId
        allianceLimit
      }
    }
  }
  ${CompanyFragment}
`;

export const COMPANY_LAST_SUBSCRIPTION_QUERY = gql`
  query($filter: CompanySubscriptionFilter!) {
    companySubscriptionsList(first: 1, filter: $filter, orderBy: createdAt_DESC) {
      items {
        status
        expireDate
        subscriptionPlan {
          allianceLimit
        }
        company {
          allianceClientCompanyRelation {
            count
          }
        }
      }
    }
  }
`;
