import React from 'react';
import PropTypes from 'prop-types';
import DayComponent from './DayComponent';
import rightArrow from '../../../images/icons/right-arrow.svg';
import leftArrow from '../../../images/icons/left-arrow.svg';

const WeekComponent = ({ week, selectedDay, onRightClick, onLeftClick, onSelectDay }) => {
  return (
    <>
      <img
        onClick={onLeftClick}
        src={leftArrow}
        style={{ width: '16px', cursor: 'pointer' }}
        alt="left"
      />
      {week.map((day, key) => {
        const active = day.fullDate === selectedDay;
        return (
          <DayComponent
            key={key}
            onClick={onSelectDay}
            day={day.day}
            active={active}
            weekday={day.weekday}
            date={day.fullDate}
          />
        );
      })}
      <img
        onClick={onRightClick}
        src={rightArrow}
        style={{ width: '16px', cursor: 'pointer' }}
        alt="right"
      />
    </>
  );
};

WeekComponent.propTypes = {
  week: PropTypes.object.isRequired,
  selectedDay: PropTypes.string.isRequired,
  onLeftClick: PropTypes.func.isRequired,
  onRightClick: PropTypes.func.isRequired,
  onSelectDay: PropTypes.func.isRequired,
};

export default WeekComponent;
