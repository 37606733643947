// string constants
const id = 'ID';
const createdAt = 'Created At';
const updatedAt = 'Updated At';
const createdBy = 'Created By';
const name = 'Name';
const itemRiskRelation = 'Item Risk';
const description = 'Description';
const status = 'Status';
const documents = 'Documents';
const owner = 'Owner';
const initiatives = 'Initiatives';
const unitType = 'Unit Type';
const unitQuantity = 'Unit Quantity';
const unitValueDescription = 'Unit Value Description';
const unitMonetizationFactor = 'Unit Monetization Factor';
const comments = 'Comments';
const assignedTo = 'Assigned To';
const assignedDate = 'Assigned Date';
const originalDueDate = 'Original Due Date';
const revisedDueDate = 'Revised Due Date';
const source = 'Source';
const nextSteps = 'Next Steps';
const notificationsRiskRelation = 'Notifications Risk';
const itemActionRelation = 'Item Action Relation';
const businessCase = 'Business Case';
const requestedDate = 'Requested Date';
const requestedBy = 'Requested By';
const actionApprovalRelation = 'Action Approval Relation';
const notificationsActionRelation = 'Notifications Action';
const itemIssueRelation = 'Item Issue';
const notificationsIssueRelation = 'Notifications Issue';
const itemDecisionRelation = 'Item Decision';
const decisionApprovalDecisionRelation = 'Decision Approval Decision';
const notificationsDecisionRelation = 'Notifications Decision';
const deal = 'Deal';
const customerName = 'Customer Name';
const amount = 'Amount';
const dealSourceId = 'Deal Source Id';
const associatedDealId = 'Associated Deal Id';
const partnerRegistrationId = 'Partner Registration Id';
const closeDate = 'Close Date';
const lostReason = 'Lost Reason';
const wonReason = 'Won Reason';
const createdDate = 'Created Date';
const stage = 'Stage';
const type = 'Type';
const salesPerson = 'SalesPerson';
const lastActivityDate = 'Last Activity Date';
const lastContactedDate = 'Last Contacted Date';
const lastModifiedDate = 'Last Modified Date';
const nextActivityDate = 'Next Activity Date';
const nextActivityDescription = 'Next Activity Description';
const numberOfSalesActivities = 'Number Of Sales Activities';
const numberOfTimesContacted = 'Number Of Times Contacted';
const line = 'Line';
const dealReferencable = 'Deal Referencable';
const itemDealDataRelation = 'Item Deal Data';
const notificationsDealDataRelation = 'Notifications Deal Data';
const itemFundingRequestRelation = 'Item Funding Request';
const fundingRequestApprovalRelation = 'Funding Request Approval';
const notificationsFundingRequestRelation = 'Notifications Funding Request';
const itemContributionRelation = 'Item Contribution';
const contributionApprovalContributionRelation = 'Contribution Approval Contribution';
const notificationsContributionRelation = 'Notifications Contribution Relation';
const calculatedValue = 'Calculated Value';
const company = 'Company';
const contributionDate = 'Contribution Date';
const ideaApprovalRelation = 'Idea Approval Idea';
const itemIdeaRelation = 'Item Idea';
const notificationsIdeaRelation = 'Notifications Idea';
const customerSatisfaction = 'Customer Satisfaction';

// displayNamesDict
const Risk = {
  id,
  createdAt,
  updatedAt,
  createdBy,
  name,
  itemRiskRelation,
  description,
  status,
  documents,
  owner,
  initiatives,
  unitType,
  unitQuantity,
  unitValueDescription,
  unitMonetizationFactor,
  comments,
  assignedTo,
  assignedDate,
  originalDueDate,
  revisedDueDate,
  source,
  nextSteps,
  notificationsRiskRelation,
  calculatedValue,
};

const Action = {
  id,
  createdAt,
  updatedAt,
  createdBy,
  source,
  name,
  description,
  assignedTo,
  initiatives,
  assignedDate,
  originalDueDate,
  revisedDueDate,
  status,
  unitQuantity,
  unitValueDescription,
  unitMonetizationFactor,
  documents,
  owner,
  itemActionRelation,
  businessCase,
  comments,
  requestedDate,
  requestedBy,
  actionApprovalRelation,
  unitType,
  nextSteps,
  notificationsActionRelation,
  calculatedValue,
};

const Issue = {
  id,
  createdAt,
  updatedAt,
  createdBy,
  itemIssueRelation,
  name,
  description,
  status,
  documents,
  owner,
  initiatives,
  unitType,
  unitQuantity,
  unitValueDescription,
  unitMonetizationFactor,
  comments,
  assignedTo,
  assignedDate,
  originalDueDate,
  revisedDueDate,
  source,
  nextSteps,
  notificationsIssueRelation,
  calculatedValue,
};

const Decision = {
  id,
  createdAt,
  updatedAt,
  createdBy,
  itemDecisionRelation,
  name,
  description,
  status,
  owner,
  initiatives,
  businessCase,
  unitType,
  unitQuantity,
  unitValueDescription,
  unitMonetizationFactor,
  documents,
  comments,
  decisionApprovalDecisionRelation,
  source,
  notificationsDecisionRelation,
  calculatedValue,
};

const DealData = {
  id,
  createdAt,
  updatedAt,
  createdBy,
  company,
  deal,
  customerName,
  amount,
  dealSourceId,
  associatedDealId,
  partnerRegistrationId,
  closeDate,
  lostReason,
  wonReason,
  createdDate,
  name,
  description,
  stage,
  type,
  salesPerson,
  lastActivityDate,
  lastContactedDate,
  lastModifiedDate,
  nextActivityDate,
  nextActivityDescription,
  numberOfSalesActivities,
  numberOfTimesContacted,
  line,
  dealReferencable,
  owner,
  status,
  itemDealDataRelation,
  comments,
  notificationsDealDataRelation,
};

const FundingRequest = {
  id,
  createdAt,
  updatedAt,
  createdBy,
  requestedBy,
  requestedDate,
  assignedTo,
  originalDueDate,
  revisedDueDate,
  name,
  businessCase,
  unitType,
  unitQuantity,
  unitValueDescription,
  unitMonetizationFactor,
  description,
  status,
  documents,
  owner,
  itemFundingRequestRelation,
  fundingRequestApprovalRelation,
  comments,
  initiatives,
  assignedDate,
  source,
  nextSteps,
  notificationsFundingRequestRelation,
  calculatedValue,
};

const Contribution = {
  id,
  createdAt,
  updatedAt,
  createdBy,
  name,
  itemContributionRelation,
  description,
  status,
  documents,
  owner,
  contributionApprovalContributionRelation,
  initiatives,
  unitType,
  unitQuantity,
  unitValueDescription,
  unitMonetizationFactor,
  comments,
  source,
  businessCase,
  notificationsContributionRelation,
  calculatedValue,
  contributionDate,
  customerSatisfaction,
};

const Idea = {
  id,
  createdAt,
  updatedAt,
  createdBy,
  name,
  source,
  description,
  assignedTo,
  initiatives,
  assignedDate,
  originalDueDate,
  revisedDueDate,
  status,
  unitType,
  unitQuantity,
  unitValueDescription,
  unitMonetizationFactor,
  documents,
  owner,
  businessCase,
  comments,
  requestedDate,
  requestedBy,
  ideaApprovalRelation,
  itemIdeaRelation,
  nextSteps,
  notificationsIdeaRelation,
  calculatedValue,
};

const DISPLAY_NAME_DICTS = {
  Risk,
  Action,
  Issue,
  Decision,
  DealData,
  FundingRequest,
  Contribution,
  Idea,
};

export { DISPLAY_NAME_DICTS };
