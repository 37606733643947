const MONTHS = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];

const ERRORS_TEXT = {
  incorrect: 'Incorrect',
  required: 'Required',
};

const SORT_NAME_MAP = {
  'owner.id': 'owner.firstName',
};

const FILTER_OPERSTORS_OPTIONS_NUMBER = [
  {
    label: 'Equals',
    value: 'equals',
  },
  {
    label: 'Not equals',
    value: 'not_equals',
  },
  {
    label: 'Greater than',
    value: 'gt',
  },
  {
    label: 'Greater than or equal',
    value: 'gte',
  },
  {
    label: 'Less than',
    value: 'lt',
  },
  {
    label: 'Less than or equal',
    value: 'lte',
  },
];

const FILTER_OPERSTORS_OPTIONS_TEXT = [
  {
    label: 'Contains',
    value: 'contains',
  },
  {
    label: 'Does not contain',
    value: 'not_contains',
  },
  {
    label: 'Is',
    value: 'equals',
  },
  {
    label: 'Is not',
    value: 'not_equals',
  },
  {
    label: 'Is empty',
    value: 'is_empty',
  },
  {
    label: 'Is not empty',
    value: 'is_not_empty',
  },
];

const FILTER_OPERSTORS_OPTIONS_RELATION = [
  {
    label: 'Is',
    value: 'equals',
  },
  {
    label: 'Is not',
    value: 'not_equals',
  },
];

const FILTER_OPERSTORS_OPTIONS_RELATION_LIST = [
  {
    label: 'Some',
    value: 'some',
  },
  {
    label: 'None',
    value: 'none',
  },
];

// styles constant for export to excel
const BORDER_STYLE = {
  top: { style: 'thin' },
  left: { style: 'thin' },
  bottom: { style: 'thin' },
  right: { style: 'thin' },
};
// styles constant for export to excel
const FILL_STYLE = {
  type: 'pattern',
  pattern: 'solid',
  fgColor: { argb: 'FFF4F7f9' },
};
// styles constant for export to excel
const TITLE_CELL_STYLE = {
  size: 14,
  bold: true,
};
// styles constant for export to excel
const HORIZONTAL_ALIGNMENT = {
  horizontal: 'right',
};

const KPI_FIELDS = [
  { label: 'Financial', value: 'financial' },
  { label: 'Operational', value: 'operational' },
  { label: 'Relationship', value: 'relationship' },
  { label: 'Strategy', value: 'strategy' },
];

export {
  MONTHS,
  ERRORS_TEXT,
  SORT_NAME_MAP,
  BORDER_STYLE,
  FILL_STYLE,
  TITLE_CELL_STYLE,
  HORIZONTAL_ALIGNMENT,
  KPI_FIELDS,
  FILTER_OPERSTORS_OPTIONS_NUMBER,
  FILTER_OPERSTORS_OPTIONS_TEXT,
  FILTER_OPERSTORS_OPTIONS_RELATION,
  FILTER_OPERSTORS_OPTIONS_RELATION_LIST,
};
