import React, { useCallback, useEffect, useState } from 'react';
import { Card, Column, Heading, Paragraph, Row, SelectField, Text } from '@8base/boost';
import {
  fetchSalesforceLogs,
  salesforceManualUpdate,
  updateSalesforceUpdateInterval,
} from '../../salesforce/salesforce-actions';
import { useSubscription } from '@cobuildlab/react-simple-state';
import { SALESFORCE_CONNECTED_EVENT, salesforceStore } from '../../salesforce/salesforce-store';
import { ActionButton } from '../../../components/buttons/ActionButton';
import { SCHEDULE_TIME_OPTIONS } from '../../salesforce/salesforce-models';
import * as toast from '../../../components/toast/Toast';
import Moment from 'react-moment';
import { TooltipConditional } from '../../../components/TooltipConditional';
import { canImportDeal } from '@cobuildlab/collabtogrow-permissions';
import { useSubscription as useApolloSubscription } from '@apollo/client';
import { SALESFORCE_LOG_SUBSCRIPTION } from '../../salesforce/salesforce-queries';
import { createApolloClient } from '../../../shared/apollo';
import { getToken } from '../../../shared/apollo/helpers';
import {
  OnSalesforceConnected,
  OnSalesforceDisconnected,
  OnSalesforceConnectionUpdate,
  OnSalesforceConnectionUpdateError,
  OnSalesforceUpdateNow,
  OnSalesforceUpdateNowError,
} from '../../salesforce/salesforce-events';

export const SalesforceScheduleUpdateSettingsCard = () => {
  const [loading, setLoading] = useState(false);
  const [client, setclient] = useState(createApolloClient(getToken));
  const connectionEvent = salesforceStore.getState(SALESFORCE_CONNECTED_EVENT);

  const [salesforceConnection, setSalesforceConnection] = useState(
    connectionEvent ? connectionEvent.salesforceConnection : null,
  );
  const [updateInterval, setUpdateInterval] = useState(0);

  const subscriptionFilter = {
    node: {
      salesforceConnection: {
        id: {
          equals: salesforceConnection ? salesforceConnection.id : '',
        },
      },
    },
  };

  const { data, loading: loadingSubscription, error } = useApolloSubscription(
    SALESFORCE_LOG_SUBSCRIPTION,
    {
      variables: { filter: subscriptionFilter },
      client,
    },
  );

  console.log('SOCKET SALESFORCE DATA', data);
  console.log('SOCKET LOADING', loadingSubscription);
  console.log('SOCKET ERROR', error);

  useSubscription(OnSalesforceConnected, (salesforceConnectionEvent) => {
    if (salesforceConnectionEvent)
      setSalesforceConnection(salesforceConnectionEvent.salesforceConnection);
  });

  useSubscription(OnSalesforceDisconnected, () => {
    setSalesforceConnection(null);
  });

  useSubscription(OnSalesforceConnectionUpdate, () => {
    toast.success('Update Interval has been changed');
    setLoading(false);
  });

  useSubscription(OnSalesforceConnectionUpdateError, (error) => {
    toast.error(error.message);
    setLoading(false);
  });

  useSubscription(OnSalesforceUpdateNow, () => {
    toast.success(
      'Deals are being updated in the background. Change may take a couple of minutes to reflect',
    );
    setLoading(false);
  });

  useSubscription(OnSalesforceUpdateNowError, (error) => {
    toast.error(error.message);
    setLoading(false);
  });

  useEffect(() => {
    if (salesforceConnection) setUpdateInterval(salesforceConnection.updateInterval);
  }, [salesforceConnection]);

  useEffect(() => {
    if (!loading && !error && data) {
      fetchSalesforceLogs();
    }
  }, [loading && error && data]);

  useEffect(() => {
    if (!client || (error && error.message === 'Client is not authorized')) {
      setclient(createApolloClient(getToken));
      console.log('SOCKET re rendering');
    }
  }, [client, error]);

  const handleScheduleUpdate = useCallback(() => {
    setLoading(true);
    updateSalesforceUpdateInterval(updateInterval);
  }, [updateInterval, setLoading]);

  const handleManualUpdate = useCallback(() => {
    setLoading(true);
    salesforceManualUpdate();
  }, [setLoading]);

  if (!salesforceConnection) return null;

  const hasChanged = updateInterval !== salesforceConnection.updateInterval;
  const { lastUpdate } = salesforceConnection;

  const [canUpdateNow, canUpdateNowMessage] = canImportDeal(salesforceConnection);

  return (
    <Card stretch style={{ overflow: 'initial' }}>
      <Card.Header>
        <Heading type="h4" text="Schedule Updates" />
      </Card.Header>
      <Card.Body>
        <Column stretch>
          <Paragraph>Set an interval to schedule a data synchronization from salesforce</Paragraph>

          <Row>
            <Text weight="medium" text={`Last Update At:`} />
            {lastUpdate ? (
              <Text>
                <Moment format="MM/DD/YYYY - h:mm a">{lastUpdate}</Moment>
              </Text>
            ) : (
              <Text weight="bold" text={`Never`} />
            )}
          </Row>
          <Row alignItems="end">
            <SelectField
              disabled={loading}
              stretch={false}
              label="Update Interval: "
              placeholder="Update Interval"
              options={SCHEDULE_TIME_OPTIONS}
              input={{ value: updateInterval, onChange: setUpdateInterval }}
            />
            {hasChanged ? (
              <ActionButton
                loading={loading}
                text="Update Schedule"
                onClick={handleScheduleUpdate}
              />
            ) : (
              <TooltipConditional
                show={!canUpdateNow}
                placement="right"
                message={canUpdateNowMessage}>
                <ActionButton
                  disabled={!canUpdateNow}
                  loading={loading}
                  color="neutral"
                  text="Update Now"
                  onClick={handleManualUpdate}
                />
              </TooltipConditional>
            )}
          </Row>
        </Column>
      </Card.Body>
    </Card>
  );
};
