import React from 'react';
import PropTypes from 'prop-types';
import './file-icon.css';
import styled from '@emotion/styled';
import { Text } from '@8base/boost';
import IconPreview from '../components/buttons/IconPreview';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import docx from '../images/icons/file/docx_16x1.svg';
import accdb from '../images/icons/file/accdb_16x1.svg';
import csv from '../images/icons/file/csv_16x1.svg';
import dotx from '../images/icons/file/dotx_16x1.svg';
import mpp from '../images/icons/file/mpp_16x1.svg';
import onepkg from '../images/icons/file/onepkg_16x1.svg';
import mpt from '../images/icons/file/mpt_16x1.svg';
import odp from '../images/icons/file/odp_16x1.svg';
import ods from '../images/icons/file/ods_16x1.svg';
import odt from '../images/icons/file/odt_16x1.svg';
import one from '../images/icons/file/one_16x1.svg';
import onetoc from '../images/icons/file/onetoc_16x1.svg';
import potx from '../images/icons/file/potx_16x1.svg';
import ppsx from '../images/icons/file/ppsx_16x1.svg';
import pptx from '../images/icons/file/pptx_16x1.svg';
import pub from '../images/icons/file/pub_16x1.svg';
import vsdx from '../images/icons/file/vsdx_16x1.svg';
import vssx from '../images/icons/file/vssx_16x1.svg';
import vstx from '../images/icons/file/vstx_16x1.svg';
import xls from '../images/icons/file/xls_16x1.svg';
import xlsx from '../images/icons/file/xlsx_16x1.svg';
import xltx from '../images/icons/file/xltx_16x1.svg';
import xsn from '../images/icons/file/xsn_16x1.svg';

import logoPdf from '../images/icons/2000px-PDF_file_icon.png';
import imgIcon from '../images/icons/img_148071.png';

import { faFileAlt as textFileIcon, faFile as fileIcon } from '@fortawesome/free-solid-svg-icons';

const StyleLabel = styled(Text)({
  width: '118px!important',
  height: '44px!important',
  whiteSpace: 'nowrap!important',
  textOverflow: 'ellipsis!important',
  overflow: 'hidden!important',
  display: 'block',
  paddingRight: '10px',
});

const Div = styled('div')({
  width: 44,
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
});

const fileTypesIcons = [
  { name: 'docx', value: docx },
  { name: 'doc', value: docx },
  { name: 'xlsx', value: xlsx },
  { name: 'xls', value: xls },
  { name: 'csv', value: csv },
  { name: 'pptx', value: pptx },
  { name: 'potx', value: potx },
  { name: 'odt', value: odt },
  { name: 'one', value: one },
  { name: 'ods', value: ods },
  { name: 'odp', value: odp },
  { name: 'accdb', value: accdb },
  { name: 'dotx', value: dotx },
  { name: 'pub', value: pub },
  { name: 'vstx', value: vstx },
  { name: 'xltx', value: xltx },
  { name: 'mpp', value: mpp },
  { name: 'onepkg', value: onepkg },
  { name: 'ppsx', value: ppsx },
  { name: 'vsdx', value: vsdx },
  { name: 'mpt', value: mpt },
  { name: 'onetoc', value: onetoc },
  { name: 'vssx', value: vssx },
  { name: 'xsn', value: xsn },
  { name: 'pdf', value: logoPdf },
  { name: 'png', value: imgIcon },
  { name: 'jpg', value: imgIcon },
  { name: 'jpeg', value: imgIcon },
  { name: 'gif', value: imgIcon },
  { name: 'svg', value: imgIcon },
  {
    name: 'txt',
    useFontAwesome: true,
    component: <FontAwesomeIcon size={'lg'} icon={textFileIcon} />,
  },
];

// Component receives a "file" array and depending on the 'file.filename'
// chooses which file icon to render or returns "None"
const FileIcon = ({ index, file, width, onClickDelete, nameInput }) => {
  // Check if there is even a file being received
  if (!file || !(file.filename || file.name)) {
    return null;
  }

  // Default style
  const style = { width: width, display: 'inline-block', transform: 'translateY(2px)' };

  const fileName = file.name || file.filename;
  const extension = fileName
    .trim()
    .split('.')
    .pop();

  const fileTypesIcon = fileTypesIcons.find((data) => data.name === extension);

  // Image Icon
  let Icon;
  if (fileTypesIcon) {
    if (fileTypesIcon.useFontAwesome) {
      Icon = fileTypesIcon.component;
    } else {
      Icon = <img style={style} src={fileTypesIcon.value} alt={`${extension} icon`} />;
    }
  } else {
    Icon = <FontAwesomeIcon size={'lg'} icon={fileIcon} />;
  }

  // PDF File Icon
  if (extension === 'pdf') {
    style.transform = 'translateY(4px)';
  }

  return (
    <div className="file-icon">
      <Div>{Icon}</Div>
      <div style={{ width: '66px' }}>
        <StyleLabel text={fileName} />
      </div>
      <IconPreview file={file} index={index} onClickDelete={onClickDelete} nameInput={nameInput} />
    </div>
  );
};

FileIcon.propTypes = {
  file: PropTypes.object.isRequired,
  nameInput: PropTypes.string,
  index: PropTypes.number,
  width: PropTypes.number,
  onClickDelete: PropTypes.func,
};

FileIcon.defaultProps = {
  nameInput: null,
  index: null,
  onClickDelete: null,
  width: 22,
};

export default FileIcon;
