import React from 'react';
import { Heading, Row, NoData, Grid, Loader, SelectField, Card } from '@8base/boost';
import { ReportHeader } from '../components';
import sessionStore, { NEW_SESSION_EVENT } from '../../../shared/SessionStore';
import { View } from '@cobuildlab/react-simple-state';
import { ForecastForm } from './components';
import { onErrorMixin } from '../../../shared/mixins';
import { fetchForecastingData, saveAllianceForecasting } from './forecasting-actions';
import { getAllianceForecastingData } from './forecasting-utils';
import { HorizontalLine } from '../../../components/new-ui/text/HorizontalLine';
import { HorizontalLineText } from '../../../components/new-ui/text/HorizontalLineText';
import { CreateViewCardBody } from '../../../components/new-ui/card/CreateViewCardBody';
import { CardFooter } from '../../../components/new-ui/card/CardFooter';
import { ActionButton } from '../../../components/buttons/ActionButton';
import { TransparentButton } from '../../../components/buttons/TransparentButton';
import moment from 'moment';
import * as R from 'ramda';
import { OnForecastingError, OnForecastingSaved, OnForecastingData } from './forecasting-events';

class ForecastingFormView extends View {
  constructor(props) {
    super(props);
    this.state = {
      alliance: null,
      selectedForecastYear: moment().year(),
      forecastYears: [moment().year()],
      loading: true,
    };
    this.selectedAlliance = sessionStore.getState(NEW_SESSION_EVENT).selectedAlliance;
    this.originalForecastingYears = [];
    this.onError = onErrorMixin.bind(this);
  }

  componentDidMount() {
    this.subscribe(OnForecastingError, this.onError);

    this.subscribe(OnForecastingSaved, () => {
      fetchForecastingData(R.clone(this.state.selectedForecastYear));
    });

    this.subscribe(OnForecastingData, async (state) => {
      const year = R.clone(this.state.selectedForecastYear);

      const allianceData = R.clone(state.alliance);

      const kpis = state.kpis.map((kpi) => ({
        ...kpi,
      }));

      const contributions = state.contributions.map((contribution) => ({
        ...contribution,
      }));

      const deals = state.deals.map((deal) => ({
        ...deal,
      }));

      const {
        alliance,
        selectedForecastYear,
        forecastYears,
        originalForecastingYears,
      } = await getAllianceForecastingData(kpis, contributions, deals, allianceData, year);

      this.originalForecastingYears = originalForecastingYears;

      this.setState({ alliance, selectedForecastYear, forecastYears, loading: false });
    });

    fetchForecastingData(R.clone(this.state.selectedForecastYear));
  }

  onSelectedYearChange = (selectedForecastYear) => {
    this.setState({ selectedForecastYear, loading: true }, () => {
      fetchForecastingData(R.clone(this.state.selectedForecastYear));
    });
  };

  onChange = (name, value) => {
    const { alliance } = this.state;
    alliance[name] = value;
    this.setState({ alliance });
  };

  onSave = () => {
    const alliance = R.clone(this.state.alliance);
    this.setState({ loading: true }, () => {
      saveAllianceForecasting(alliance, this.originalForecastingYears);
    });
  };

  render() {
    const { alliance, selectedForecastYear, loading, forecastYears } = this.state;
    const { history } = this.props;
    let content = <Loader stretch />;
    let footer = <></>;

    if (alliance && !loading) {
      const { clientCompany, partnerCompany, effectiveDate, currency } = alliance;
      // const startYear = moment(effectiveDate || undefined).year();
      const clientCompanyName = clientCompany ? clientCompany.name : 'Client';
      const partnerCompanyName = partnerCompany ? partnerCompany.name : 'Partner';

      content = (
        <>
          <HorizontalLine>
            <HorizontalLineText text={`BOOKINGS`} />
          </HorizontalLine>

          <Row gap={'xxl'} justifyContent={'center'} style={{ padding: '0px 50px 0px 50px' }}>
            <ForecastForm
              readOnly
              title={`EXPECTED BOOKINGS - ${clientCompanyName}`}
              alliance={alliance}
              forecastField={'clientExpectedBookings'}
              selectedYear={selectedForecastYear}
            />
            <ForecastForm
              readOnly
              title={`ACTUAL BOOKINGS - ${clientCompanyName}`}
              alliance={alliance}
              forecastField={'clientBookingsActuals'}
              selectedYear={selectedForecastYear}
            />
            <ForecastForm
              currency={currency}
              title={`FORECAST BOOKINGS - ${clientCompanyName}`}
              alliance={alliance}
              forecastField={'clientBookingsForecast'}
              selectedYear={selectedForecastYear}
              effectiveDate={effectiveDate}
              onChange={this.onChange}
            />
          </Row>

          <Row
            gap={'xxl'}
            justifyContent={'center'}
            style={{ padding: '0px 50px 0px 50px', marginTop: '48px' }}>
            <ForecastForm
              readOnly
              title={`EXPECTED BOOKINGS - ${partnerCompanyName}`}
              alliance={alliance}
              forecastField={'partnerExpectedBookings'}
              selectedYear={selectedForecastYear}
            />
            <ForecastForm
              readOnly
              title={`ACTUAL BOOKINGS - ${partnerCompanyName}`}
              alliance={alliance}
              forecastField={'partnerBookingsActuals'}
              selectedYear={selectedForecastYear}
            />
            <ForecastForm
              currency={currency}
              title={`FORECAST BOOKINGS - ${partnerCompanyName}`}
              alliance={alliance}
              forecastField={'partnerBookingsForecast'}
              selectedYear={selectedForecastYear}
              effectiveDate={effectiveDate}
              onChange={this.onChange}
            />
          </Row>

          <HorizontalLine>
            <HorizontalLineText text={`CONTRIBUTIONS`} />
          </HorizontalLine>

          <Row gap={'xxl'} justifyContent={'center'} style={{ padding: '0px 50px 0px 50px' }}>
            <ForecastForm
              readOnly
              title={`EXPECTED CONTRIBUTIONS - ${clientCompanyName}`}
              alliance={alliance}
              forecastField={'clientExpectedContributions'}
              selectedYear={selectedForecastYear}
            />
            <ForecastForm
              readOnly
              title={`ACTUAL CONTRIBUTIONS - ${clientCompanyName}`}
              alliance={alliance}
              forecastField={'clientContributionsActuals'}
              selectedYear={selectedForecastYear}
            />
            <ForecastForm
              currency={currency}
              title={`FORECAST CONTRIBUTIONS - ${clientCompanyName}`}
              alliance={alliance}
              forecastField={'clientContributionsForecast'}
              selectedYear={selectedForecastYear}
              effectiveDate={effectiveDate}
              onChange={this.onChange}
            />
          </Row>

          <Row
            gap={'xxl'}
            justifyContent={'center'}
            style={{ padding: '0px 50px 0px 50px', margin: '48px 0' }}>
            <ForecastForm
              readOnly
              title={`EXPECTED CONTRIBUTIONS - ${partnerCompanyName}`}
              alliance={alliance}
              forecastField={'partnerExpectedContributions'}
              selectedYear={selectedForecastYear}
            />
            <ForecastForm
              readOnly
              title={`ACTUAL CONTRIBUTIONS - ${partnerCompanyName}`}
              alliance={alliance}
              forecastField={'partnerContributionsActuals'}
              selectedYear={selectedForecastYear}
            />
            <ForecastForm
              currency={currency}
              title={`FORECAST CONTRIBUTIONS - ${partnerCompanyName}`}
              alliance={alliance}
              forecastField={'partnerContributionsForecast'}
              selectedYear={selectedForecastYear}
              effectiveDate={effectiveDate}
              onChange={this.onChange}
            />
          </Row>
        </>
      );

      footer = (
        <CardFooter>
          <ActionButton onClick={this.onSave} text={'Save'} />
          <TransparentButton onClick={history.goBack} text={'Cancel'} />
        </CardFooter>
      );
    }

    if (!alliance && !loading) content = <NoData />;

    return (
      <Card stretch>
        <ReportHeader>
          <Heading type="h4" text={'Forecasting'} />
          <Grid.Box direction="row" justifySelf="end" alignSelf="center">
            {alliance ? (
              <SelectField
                style={{ width: '150px', alignSelf: 'end' }}
                input={{
                  name: 'yearFilter',
                  value: selectedForecastYear,
                  onChange: (year) => this.onSelectedYearChange(year),
                }}
                placeholder={'Select a Year'}
                options={forecastYears.map((year) => {
                  return { label: year, value: year };
                })}
              />
            ) : null}
          </Grid.Box>
        </ReportHeader>
        <CreateViewCardBody>{content}</CreateViewCardBody>
        {footer}
      </Card>
    );
  }
}

export { ForecastingFormView };
