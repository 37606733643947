import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@8base/boost';
import styled from '@emotion/styled';

/**
 * Use this instead of Card.Body for Create/Edit Views,
 * add any css Padding, margin on this component.
 *
 */

const StyledGroupInputs = styled.div`
  border-bottom: 1px solid rgb(233, 239, 244);
  margin-bottom: 20px;
  padding-bottom: 20px;
`;

const StyledGroupInputswithBorder = styled.div`
  margin-bottom: 10px;
  padding-bottom: 20px;
`;

const StyledPositionTitle = styled.div`
  position: absolute;
  font-size: 13px;
  font-family: Poppins;
  font-weight: 600;
  line-height: 20px;
  left: 28px;
`;

const StyledGridBox = styled(Grid.Box)({
  paddingLeft: '24px',
  paddingRight: '24px',
  marginLeft: '112px',
});

const GroupInputs = ({ text, children, show, ...rest }) => {
  if (show) {
    return (
      <StyledGroupInputswithBorder>
        <Grid.Layout columns="20% 80%">
          <Grid.Box>
            <StyledPositionTitle>{text}</StyledPositionTitle>
          </Grid.Box>
          <StyledGridBox>{children}</StyledGridBox>
        </Grid.Layout>
      </StyledGroupInputswithBorder>
    );
  } else {
    return (
      <StyledGroupInputs>
        <Grid.Layout columns="20% 80%">
          <Grid.Box>
            <StyledPositionTitle>{text}</StyledPositionTitle>
          </Grid.Box>
          <StyledGridBox>{children}</StyledGridBox>
        </Grid.Layout>
      </StyledGroupInputs>
    );
  }
};

GroupInputs.propTypes = {
  children: PropTypes.any.isRequired,
  text: PropTypes.string.isRequired,
};

export { GroupInputs };
