import React from 'react';
import { Button, Tag, Text } from '@8base/boost';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

const StyledButton = styled(Button)({
  backgroundColor: '#e9eff4',
  borderColor: '#e9eff4',
});

const WithTagCountButton = ({ onClick, count, text, ...rest }) => {
  const tagCount = count ? <Tag>{count}</Tag> : null;

  return (
    <StyledButton onClick={onClick} {...rest}>
      <Text weight="bold">{text}</Text>
      {tagCount}
    </StyledButton>
  );
};

WithTagCountButton.propTypes = {
  count: PropTypes.number.isRequired,
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default WithTagCountButton;
