import React from 'react';
import styled from '@emotion/styled';
import { Text } from '@8base/boost';
import PropTypes from 'prop-types';

const StyleText = styled(Text)`
  color: #323c47;
  font-family: Poppins;
  font-size: 13px;
  line-height: 20px;
  display: block;
  padding-left: 10px;
  position: relative;
  &:before {
    content: '';
    height: 14px;
    width: 14px;
    background-color: ${(props) => props.color};
    border-radius: 50%;
    margin-top: 3px;
    position: absolute;
    left: -10px;
  }
`;
export const ForecastReportChartLegendItem = ({ name, color }) => {
  return <StyleText text={name} color={color} />;
};

ForecastReportChartLegendItem.propTypes = {
  name: PropTypes.string.isRequired,
  color: PropTypes.string,
};

ForecastReportChartLegendItem.defaultProps = {
  color: '#2BA1FF',
};
