import React from 'react';
import { Card, Heading, Table } from '@8base/boost';
import { ListCardBody } from '../../../components/card/ListCardBody';
import * as toast from '../../../components/toast/Toast';
import { ALLIANCE_INVITATION_ERROR_LOCAL_EVENT } from './invitations.store';
import * as R from 'ramda';
import { acceptAllianceInvitation, resendAlliancePartnerInvitation } from './invitations.actions';
import { View } from '@cobuildlab/react-simple-state';
import YesNoDialog from '../../../components/dialogs/YesNoDialog';
import sessionStore, { NEW_SESSION_EVENT } from '../../../shared/SessionStore';
import PropTypes from 'prop-types';
import { fetchInvitations, fetchSession } from '../../auth/auth.actions';
import { ACCEPT_ALLIANCE_INVITATION } from './index';
import Moment from 'react-moment';
import Status from '../../../components/Status';
import { ActionButton } from '../../../components/buttons/ActionButton';
import ErrorDialog from '../../../components/dialogs/ErrorDialog';
import { sortByPendingInvitation } from './invitations-utils';
import { SelectCompany } from './components/SelectCompany';
import { getCompanyUserAdminAndPortfolio } from '../company-management/company-actions';
import { canAcceptAllianceInvitation } from './invitations-permissions';
import { canResendAlliancePartnerInvitation } from './invitations-validators';
import { onErrorMixin } from '../../../shared/mixins';
import { OnInvitations, OnSessionError } from '../../../shared/session-events';
import {
  OnAllianceResendInvitation,
  OnAllianceInvitationError,
  OnAllianceInvitationUpdated,
  OnAllianceInvitationErrorLocal,
  OnAllianceInvitationNoCompanyError,
} from '../invitations/invitations-events';

class AllianceInvitationListView extends View {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      loading: true,
      invitation: null,
      yesNoDialogIsOpen: false,
      yesNoDialogTitle: '',
      yesNoDialogDescription: '',
      isGonnaAccept: false,
      noCompanyErrorDialogIsOpen: false,
      isOpen: false,
      companyId: null,
      resendInvitationModalIsOpen: false,
      resendInvitation: null,
    };
    this.user = sessionStore.getState(NEW_SESSION_EVENT).user;
    this.companyUsers = getCompanyUserAdminAndPortfolio();
    this.onError = onErrorMixin.bind(this);
  }

  componentDidMount() {
    const { history } = this.props;

    this.subscribe(OnSessionError, (data) => {
      toast.error(data.message);
      this.setState({
        loading: false,
      });
    });

    this.subscribe(OnAllianceInvitationNoCompanyError, (data) => {
      this.setState({ noCompanyErrorDialogIsOpen: true });
    });

    this.subscribe(OnAllianceInvitationErrorLocal, (data) => {
      toast.error(data.message);
      this.setState({ loading: false });
    });

    this.subscribe(OnInvitations, (data) => {
      this.setState({
        data: data.allianceInvitationsList.items.sort(sortByPendingInvitation),
        loading: false,
      });
    });

    this.subscribe(OnAllianceInvitationUpdated, async () => {
      await fetchSession();
      const id = this.state.invitation.alliance.id;
      toast.success('Alliance invitation successfully accepted');
      history.push(`/settings/alliance-management/${id}`);
    });
    this.subscribe(OnAllianceResendInvitation, async (state) => {
      await fetchInvitations();
      toast.success('Resend alliance Membership invitation successfully');
    });
    this.subscribe(OnAllianceInvitationError, this.onError);

    fetchInvitations();
  }

  onSelectForAccept = (invitation, name) => {
    if (this.companyUsers.length > 1) {
      this.setState({
        isOpen: true,
        invitation,
      });
    } else {
      this.setState({
        invitation,
        isGonnaAccept: true,
        yesNoDialogIsOpen: true,
        yesNoDialogTitle: name,
        yesNoDialogDescription: ACCEPT_ALLIANCE_INVITATION,
      });
    }
  };

  onYes = () => {
    this.setState(
      {
        yesNoDialogIsOpen: false,
        loading: true,
      },
      () => {
        if (this.state.isGonnaAccept)
          acceptAllianceInvitation(
            this.state.invitation,
            this.companyUsers[0],
            ALLIANCE_INVITATION_ERROR_LOCAL_EVENT,
          );
      },
    );
  };
  onAccept = () => {
    const { invitation, companyId } = this.state;
    let companySelected = this.companyUsers.find((companyUser) => {
      return companyUser.company.id === companyId;
    });
    this.setState(
      {
        isOpen: false,
        loading: true,
      },
      () => setTimeout(() => acceptAllianceInvitation(invitation, companySelected), 2000),
    );
  };
  onCloseDialog = () => {
    this.setState({
      yesNoDialogIsOpen: false,
    });
  };

  onNoCompanyErrorDialogOk = () => {
    this.setState({ noCompanyErrorDialogIsOpen: false });
    this.props.history.push('/settings/company-management/create');
  };
  onChangeCompany = (companyId) => {
    this.setState({
      companyId: companyId,
    });
  };
  onClose = () => {
    this.setState({
      isOpen: false,
    });
  };

  onResendInvitation = (invitation) => {
    this.setState({
      resendInvitation: invitation,
      resendInvitationModalIsOpen: true,
    });
  };

  onCloseResendInvitation = () => {
    this.setState({
      resendInvitationModalIsOpen: false,
    });
  };

  onYesResendInvitation = () => {
    this.setState(
      {
        resendInvitationModalIsOpen: false,
        loading: true,
      },
      () => {
        console.log(`Resend alliance invitation`, this.state.resendInvitation);
        resendAlliancePartnerInvitation(this.state.resendInvitation);
      },
    );
  };

  render() {
    const {
      yesNoDialogIsOpen,
      yesNoDialogTitle,
      yesNoDialogDescription,
      noCompanyErrorDialogIsOpen,
      isOpen,
      companyId,
      resendInvitationModalIsOpen,
    } = this.state;
    const tableHeaders = ['Alliance Name', 'Sent to', 'Status', 'Date of Response'];
    const columnSize = `repeat(${tableHeaders.length}, 1fr) 220px`;
    let selector = <></>;
    if (this.companyUsers.length > 1) {
      selector = (
        <SelectCompany
          isOpen={isOpen}
          onClose={this.onClose}
          companyUsers={this.companyUsers}
          onChange={this.onChangeCompany}
          onAccept={this.onAccept}
          companyId={companyId}
          title={'Select Your Company'}
        />
      );
    }

    return (
      <React.Fragment>
        <Card.Header>
          <Heading type="h4" text="My Alliances" />
        </Card.Header>
        <ListCardBody>
          <div id={'c2gScreen'}>
            <Table>
              <Table.Header columns={columnSize}>
                {tableHeaders.map((header, index) => (
                  <Table.HeaderCell key={index}>{header}</Table.HeaderCell>
                ))}
                <Table.HeaderCell />
              </Table.Header>
              <Table.Body
                loading={this.state.loading}
                data={this.state.data}
                className="card-body-list">
                {(invitation) => {
                  const _canAcceptAllianceInvitation = canAcceptAllianceInvitation(
                    this.user,
                    invitation,
                  );
                  const [canResendInvitation] = canResendAlliancePartnerInvitation(invitation);

                  return (
                    <Table.BodyRow columns={columnSize} key={invitation.id}>
                      <Table.BodyCell>
                        {R.pathOr('Unititled', ['alliance', 'name'], invitation)}
                      </Table.BodyCell>
                      <Table.BodyCell>{R.pathOr('', ['email'], invitation)}</Table.BodyCell>
                      <Table.BodyCell>
                        <Status status={R.pathOr('', ['status'], invitation)} />
                      </Table.BodyCell>
                      <Table.BodyCell>
                        {invitation.dateOfResponse === null ? (
                          ''
                        ) : (
                          <Moment format="MMMM Do, YYYY">
                            {new Date(invitation.dateOfResponse)}
                          </Moment>
                        )}
                      </Table.BodyCell>
                      <Table.BodyCell>
                        {_canAcceptAllianceInvitation ? (
                          <ActionButton
                            text={'Accept'}
                            onClick={() =>
                              this.onSelectForAccept(invitation, invitation.alliance.name)
                            }
                          />
                        ) : null}
                        {canResendInvitation ? (
                          <ActionButton
                            text={'Resend Invitation'}
                            onClick={() => this.onResendInvitation(invitation)}
                          />
                        ) : null}
                      </Table.BodyCell>
                    </Table.BodyRow>
                  );
                }}
              </Table.Body>
            </Table>
          </div>
        </ListCardBody>
        <YesNoDialog
          isOpen={yesNoDialogIsOpen}
          onYes={this.onYes}
          onNo={this.onCloseDialog}
          onClose={this.onCloseDialog}
          text={yesNoDialogDescription}
          title={yesNoDialogTitle}
        />
        <YesNoDialog
          isOpen={resendInvitationModalIsOpen}
          onYes={this.onYesResendInvitation}
          onNo={this.onCloseResendInvitation}
          onClose={this.onCloseResendInvitation}
          text={'Are you sure you want to resend Alliance Partner Invitation?'}
          title={'Resend Invitation'}
        />
        <ErrorDialog
          isOpen={noCompanyErrorDialogIsOpen}
          onOk={this.onNoCompanyErrorDialogOk}
          text={'You must create a Company in order to accept Alliance Invitations'}
          okText={'Create Company'}
          title={'Error accepting the Alliance Invitation'}
        />
        {selector}
      </React.Fragment>
    );
  }
}

// // TODO:
AllianceInvitationListView.propTypes = {
  history: PropTypes.object.isRequired,
};

export default AllianceInvitationListView;
