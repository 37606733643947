import Flux from '@cobuildlab/flux-state';
import sessionStore, { APOLLO_CLIENT, NEW_SESSION_EVENT } from '../../../shared/SessionStore';
import { IntegrityError } from '../../../shared/errors';
import { error, log } from '@cobuildlab/pure-logger';
import {
  AMO_ITEM_DELETE_EVENT,
  AMO_ITEM_ERROR_EVENT,
  AMO_ITEM_FULL_LIST_EVENT,
  AMO_ITEM_LIST_EVENT,
} from './amo-item-store';
import {
  AMO_ALL_ITEM_LIST_QUERY,
  AMO_ITEM_DELETE_MUTATION,
  AMO_ITEM_LIST_QUERY,
} from './amo-item-queries';
import {
  ACTION_CLOSED,
  ACTION_REJECTED,
  DECISION_CLOSED,
  ISSUE_CLOSED,
  RISK_CLOSED,
  AMO_ITEM_OVER_DUE,
  AMO_ITEM_DUE_THIS_WEEK,
  AMO_ITEM_ON_TIME,
  ACTION_COMPLETED,
  ISSUE_COMPLETED,
  RISK_COMPLETED,
} from '../../../shared/status';
import { businessCaseSearchFilterOR } from '../../document-management/business-case/businessCases.actions';
import moment from 'moment';
import { ITEM_ACTION, ITEM_RISK, ITEM_ISSUE, ITEM_DECISION } from '../../../shared/items-util';
import {
  OnAmoItemList,
  OnAmoItemError,
  OnAmoItemDelete,
  OnAmoItemFullList,
} from './amo-item-events';

/**
 * Creates a filter object and search by a string
on string properties of the AMOItems.
 *
 * @param {string} allianceId -  The allianceId to filter.
 * @param {string} [search=''] - The string to search.
 * @param {string} dueParam - Dua param.
 * @param {string} itemTypeFilter - Item Type filter.
 * @param {string} initiativeFilter - Initiative filter.
 * @returns {object}             The filter object.
 */
const amoItemsFilter = (allianceId, search = '', dueParam, itemTypeFilter, initiativeFilter) => {
  const actionFilter = {
    action: {
      id: { not_equals: null },
      status: { not_in: [ACTION_CLOSED, ACTION_REJECTED] },
      OR: [
        {
          name: {
            contains: search,
          },
        },
        {
          description: {
            contains: search,
          },
        },
        {
          requestedBy: {
            firstName: { contains: search },
          },
        },
        {
          requestedBy: {
            lastName: { contains: search },
          },
        },
        {
          requestedBy: {
            email: { contains: search },
          },
        },
      ].concat(businessCaseSearchFilterOR(search)),
    },
  };
  const decisionFilter = {
    decision: {
      id: { not_equals: null },
      status: { not_in: [DECISION_CLOSED] },
      OR: [
        {
          name: {
            contains: search,
          },
        },
        {
          description: {
            contains: search,
          },
        },
      ],
    },
  };
  const issueFilter = {
    issue: {
      id: { not_equals: null },
      status: { not_in: [ISSUE_CLOSED] },
      OR: [
        {
          name: {
            contains: search,
          },
        },
        {
          description: {
            contains: search,
          },
        },
        {
          assignedTo: {
            firstName: { contains: search },
          },
        },
        {
          assignedTo: {
            lastName: { contains: search },
          },
        },
        {
          assignedTo: {
            email: { contains: search },
          },
        },
      ],
    },
  };
  const riskFilter = {
    risk: {
      id: { not_equals: null },
      status: { not_in: [RISK_CLOSED] },
      OR: [
        {
          name: {
            contains: search,
          },
        },
        {
          description: {
            contains: search,
          },
        },
        {
          assignedTo: {
            firstName: { contains: search },
          },
        },
        {
          assignedTo: {
            lastName: { contains: search },
          },
        },
        {
          assignedTo: {
            email: { contains: search },
          },
        },
      ],
    },
  };

  if (initiativeFilter) {
    if (itemTypeFilter === ITEM_ACTION) {
      actionFilter.action.initiatives = { some: { id: { equals: initiativeFilter } } };
    } else if (itemTypeFilter === ITEM_RISK) {
      riskFilter.risk.initiatives = { some: { id: { equals: initiativeFilter } } };
    } else if (itemTypeFilter === ITEM_ISSUE) {
      issueFilter.issue.initiatives = { some: { id: { equals: initiativeFilter } } };
    } else if (itemTypeFilter === ITEM_DECISION) {
      decisionFilter.decision.initiatives = { some: { id: { equals: initiativeFilter } } };
    }
  }

  let filter = {
    alliance: { id: { equals: allianceId } },
    OR: [actionFilter, decisionFilter, issueFilter, riskFilter],
  };
  // If the filter is by Due Date
  if (dueParam === AMO_ITEM_OVER_DUE) {
    actionFilter.action.revisedDueDate = { lte: moment().format('YYYY-MM-DD') };
    issueFilter.issue.revisedDueDate = { lte: moment().format('YYYY-MM-DD') };
    riskFilter.risk.revisedDueDate = { lte: moment().format('YYYY-MM-DD') };
    actionFilter.action.AND = {
      status: { not_in: ACTION_COMPLETED },
    };
    issueFilter.issue.AND = {
      status: { not_in: ISSUE_COMPLETED },
    };
    riskFilter.risk.AND = {
      status: { not_in: RISK_COMPLETED },
    };
    filter = {
      alliance: { id: { equals: allianceId } },
      OR: [actionFilter, issueFilter, riskFilter],
    };
  } else if (dueParam === AMO_ITEM_DUE_THIS_WEEK) {
    // Filtering with a conditional Condition less than the next week and greater than today
    actionFilter.action.AND = [
      {
        revisedDueDate: {
          lt: moment()
            .add(7, 'days')
            .format('YYYY-MM-DD'),
        },
      },
      {
        revisedDueDate: { gt: moment().format('YYYY-MM-DD') },
      },
      {
        status: {
          not_in: ACTION_COMPLETED,
        },
      },
    ];
    issueFilter.issue.AND = [
      {
        revisedDueDate: {
          lt: moment()
            .add(7, 'days')
            .format('YYYY-MM-DD'),
        },
      },
      {
        revisedDueDate: { gt: moment().format('YYYY-MM-DD') },
      },
      {
        status: {
          not_in: ISSUE_COMPLETED,
        },
      },
    ];
    riskFilter.risk.AND = [
      {
        revisedDueDate: {
          lt: moment()
            .add(7, 'days')
            .format('YYYY-MM-DD'),
        },
      },
      {
        revisedDueDate: { gt: moment().format('YYYY-MM-DD') },
      },
      {
        status: {
          not_in: RISK_COMPLETED,
        },
      },
    ];

    filter = {
      alliance: { id: { equals: allianceId } },
      OR: [actionFilter, issueFilter, riskFilter],
    };
  } else if (dueParam === AMO_ITEM_ON_TIME) {
    actionFilter.action.revisedDueDate = {
      gte: moment()
        .add(7, 'days')
        .format('YYYY-MM-DD'),
    };
    issueFilter.issue.revisedDueDate = {
      gte: moment()
        .add(7, 'days')
        .format('YYYY-MM-DD'),
    };
    riskFilter.risk.revisedDueDate = {
      gte: moment()
        .add(7, 'days')
        .format('YYYY-MM-DD'),
    };
    actionFilter.action.AND = {
      status: { not_in: ACTION_COMPLETED },
    };
    issueFilter.issue.AND = {
      status: { not_in: ISSUE_COMPLETED },
    };
    riskFilter.risk.AND = {
      status: { not_in: RISK_COMPLETED },
    };
    filter = {
      alliance: { id: { equals: allianceId } },
      OR: [actionFilter, issueFilter, riskFilter],
    };
    //If the filter is by status
  } else if (dueParam !== null) {
    actionFilter.action.status = { equals: dueParam };
    decisionFilter.decision.status = { equals: dueParam };
    issueFilter.issue.status = { equals: dueParam };
    riskFilter.risk.status = { equals: dueParam };
  }

  if (itemTypeFilter === ITEM_ACTION) {
    filter = {
      alliance: { id: { equals: allianceId } },
      OR: [actionFilter],
    };
  } else if (itemTypeFilter === ITEM_RISK) {
    filter = {
      alliance: { id: { equals: allianceId } },
      OR: [riskFilter],
    };
  } else if (itemTypeFilter === ITEM_ISSUE) {
    filter = {
      alliance: { id: { equals: allianceId } },
      OR: [issueFilter],
    };
  } else if (itemTypeFilter === ITEM_DECISION) {
    filter = {
      alliance: { id: { equals: allianceId } },
      OR: [decisionFilter],
    };
  }

  return filter;
};

/**
 * Fetches the AMOItems of the Current Alliance.
 *
 * @param {string} search - Search.
 * @param {number} page - Page.
 * @param {number} first - First.
 * @param {string} dueParam - DueParam.
 * @param {string} itemTypeFilter - Item type filter.
 * @param {string} initiativeFilter - Initiative filter.
 * @param {object} options - Options.
 * @returns {Array} Amo items.
 */
export const fetchAMOItems = async (
  search = '',
  page = 1,
  first = 20,
  dueParam = null,
  itemTypeFilter = null,
  initiativeFilter = null,
  options = {},
) => {
  const client = sessionStore.getState(APOLLO_CLIENT);
  const { selectedAlliance } = sessionStore.getState(NEW_SESSION_EVENT);
  const allianceId = selectedAlliance.id;
  const skip = (page - 1) * first;
  const fetchPolicy = options.isCacheFirst ? 'cache-first' : 'network-only';

  if (!allianceId) {
    OnAmoItemError.dispatch(new IntegrityError('An Alliance must be selected'));

    return Flux.dispatchEvent(
      AMO_ITEM_ERROR_EVENT,
      new IntegrityError('An Alliance must be selected'),
    );
  }

  const filter = amoItemsFilter(allianceId, search, dueParam, itemTypeFilter, initiativeFilter);

  let response;
  try {
    response = await client.query({
      query: AMO_ITEM_LIST_QUERY,
      variables: { data: filter, skip, first },
      fetchPolicy,
    });
  } catch (e) {
    error('fetchAMOItems', e);
    OnAmoItemError.dispatch(e);
    Flux.dispatchEvent(AMO_ITEM_ERROR_EVENT, e);
    throw e;
  }
  log('fetchAMOItems', response);

  OnAmoItemList.dispatch(response.data);
  Flux.dispatchEvent(AMO_ITEM_LIST_EVENT, response.data);

  return response.data;
};

export const fetchAllAMOItems = async () => {
  const client = sessionStore.getState(APOLLO_CLIENT);
  const { selectedAlliance } = sessionStore.getState(NEW_SESSION_EVENT);
  const allianceId = selectedAlliance.id;

  if (!allianceId) {
    OnAmoItemError.dispatch(new IntegrityError('An Alliance must be selected'));

    return Flux.dispatchEvent(
      AMO_ITEM_ERROR_EVENT,
      new IntegrityError('An Alliance must be selected'),
    );
  }

  const filter = amoItemsFilter(allianceId);

  let response;
  try {
    response = await client.query({
      query: AMO_ALL_ITEM_LIST_QUERY,
      variables: { data: filter },
      fetchPolicy: 'network-only',
    });
  } catch (e) {
    error('fetchAMOItems', e);
  }
  log('fetchAMOItems', response);

  OnAmoItemFullList.dispatch(response.data);
  Flux.dispatchEvent(AMO_ITEM_FULL_LIST_EVENT, response.data);

  return response.data;
};

/**
 * Delete an AMO Item.
 *
 * @param {object} investmentItem - Investiment item.
 * @returns {Promise<*>} Ppomise.
 */
export const deleteAMOItem = async (investmentItem) => {
  const client = sessionStore.getState(APOLLO_CLIENT);

  let response;
  try {
    response = await client.mutate({
      mutation: AMO_ITEM_DELETE_MUTATION,
      variables: { data: { id: investmentItem.id, force: true } },
    });
  } catch (e) {
    error('deleteAMOItem', e);
    OnAmoItemError.dispatch(e);
    return Flux.dispatchEvent(AMO_ITEM_ERROR_EVENT, e);
  }
  log('deleteAMOItem', response);
  OnAmoItemDelete.dispatch(response.data);
  Flux.dispatchEvent(AMO_ITEM_DELETE_EVENT, response.data);
  return response.data;
};
