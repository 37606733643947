import gql from 'graphql-tag';
import * as R from 'ramda';

import { SchemaNameGenerator } from '@8base/schema-name-generator';
import { generateFieldsString } from '../utils';
import { RELATION_QUERIES } from '../model';

const getRelationOptionsQuery = (itemType, field) => {
  const fieldName = R.path(['name'], field);

  const { getFilterInputTypeName, getTableListFieldName } = SchemaNameGenerator;

  const intemTypeFIlter = getFilterInputTypeName(itemType);
  const itemTypeList = getTableListFieldName(itemType);

  const queryResponseBody = generateFieldsString([field], RELATION_QUERIES);

  return gql`
    query PivotTable${itemType}${fieldName}${itemTypeList}($filter: ${intemTypeFIlter}) {
      ${itemTypeList}(filter: $filter) {
        items {
          ${queryResponseBody}
        }
      }
    }
  `;
};

export { getRelationOptionsQuery };
