import React from 'react';
import { Card, Grid, Heading, Loader, Row } from '@8base/boost';
import { CreateViewCardBody } from '../../../components/new-ui/card/CreateViewCardBody';
import DealForm from './components/DealForm';
import * as toast from '../../../components/toast/Toast';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { View } from '@cobuildlab/react-simple-state';
import { onChangeDataMixin, onErrorMixin } from '../../../shared/mixins';
import * as R from 'ramda';
import { fetchCurrentAllianceMembersAction } from '../../settings/alliance-management/alliance-actions';
import {
  concatClientAndPartnerCompanyUsers,
  concatClientAndPartnerUsers,
  getCurrencyOnSession,
} from '../../../shared/alliance-utils';
import { ActionButton } from '../../../components/buttons/ActionButton';
import { autoSaveCreateDeal, createNewDeal } from './deal-actions';
import { DealData } from './deal-model';
import { TransparentButton } from '../../../components/buttons/TransparentButton';
import { dealValidator } from './deal-validators';
import { FormSteps } from '../../../components/dots/FormSteps';
import DealDetailTable from './components/DealDetailTable';
import RelatedItemForm from '../../related-item/components/RelatedItemForm';
import { RelatedItemsDetailTable } from '../../related-item/components/RelatedItemsDetailTable';
import sessionStore, { NEW_SESSION_EVENT } from '../../../shared/SessionStore';
import { LeftProgressSection } from '../../../components/new-ui/LeftProgressSection';
import { SCREENS_DEALS } from '../screenView';
import { BoxCard } from '../../../components/new-ui/div/BoxCard';
import { ActionButtonClose } from '../../../components/buttons/ActionButtonClose';
import { fetchDealStage } from '../../settings/stage-mapping/stage-mapping-action';
import { fetchInitiativeList } from '../initiative/initiative-actions';
import { initiativesItemValidator } from '../initiative/initiative-validators';
import { InitiativeListTable } from '../initiative/components/InitiativeListTable';
import { TextDateAgo } from '../../../components/text/TextDateAgo';
import { OnStageMappingDetail } from '../../settings/stage-mapping/stage-mapping-events';
import { OnInitiativeList } from '../../management/initiative/initiative-events';
import { OnAllianceMemberList } from '../../settings/alliance-management/alliance-events';
import {
  OnDealCreate,
  OnDealError,
  OnDealAutoSaveCreate,
  OnDealAutoSaveCreateError,
} from '../../management/deal/deal-events';

const DEAL_DATA_STORE = 'dealCreateView';

class DealCreateView extends View {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        dealData: R.clone(DealData),
        relatedItems: [],
        relatedDeals: [],
        initiatives: [],
      },
      allianceMembersOptions: [],
      otherCompany: null,
      loading: true,
      step: 0,
      initiativesList: [],
      dealStagesOptions: [],
      savedAt: null,
      autoSaving: false,
    };
    this.onChangeData = (name, value) => {
      this.autoSave();
      onChangeDataMixin.call(this, name, value);
    };
    this.onError = onErrorMixin.bind(this);
    this.user = sessionStore.getState(NEW_SESSION_EVENT).user;

    this.autoSaveTimer = null;
    this.savedDeal = null;
  }

  onChangeDealData = (name, value) => {
    const { data } = this.state;
    data.dealData[name] = value;
    this.setState({ data });
    this.autoSave();
    sessionStorage.setItem(DEAL_DATA_STORE, JSON.stringify(data));
  };

  componentDidMount() {
    const data = JSON.parse(sessionStorage.getItem(DEAL_DATA_STORE));

    this.subscribe(OnDealError, this.onError);
    this.subscribe(OnDealCreate, () => {
      sessionStorage.removeItem(DEAL_DATA_STORE);
      toast.success('Deal Successfully Created');
      this.props.history.push('/management/deal');
    });

    // set data from sessionStorage
    if (data) {
      this.setState({ data });
    }

    this.subscribe(OnStageMappingDetail, (state) => {
      const { dealStagesList } = state;
      const dealStagesOptions = dealStagesList.items.map((dealStage) => {
        return {
          label: `${dealStage.name}`,
          value: `${dealStage.name}`,
        };
      });
      this.setState({
        dealStagesOptions,
      });
    });

    this.subscribe(OnAllianceMemberList, (state) => {
      const clientCompany = R.clone(state.clientCompany);
      const partnerCompany = R.clone(state.partnerCompany);
      const { data } = this.state;

      const companyUsers = concatClientAndPartnerCompanyUsers(clientCompany, partnerCompany);
      const members = concatClientAndPartnerUsers(clientCompany, partnerCompany);

      const dealCompany =
        companyUsers.find((companyUser) => companyUser.user.id === this.user.id).company || null;
      data.dealData.company = dealCompany;

      console.log('ALLIANCE_LIST_MEMBERS_EVENT:members', state);
      this.setState({
        data,
        loading: false,
        otherCompany: partnerCompany !== null ? partnerCompany : null,
        allianceMembersOptions: members.map((member) => {
          return {
            label: `${member.firstName} ${member.lastName}`,
            value: member.id,
          };
        }),
      });
    });

    this.subscribe(OnInitiativeList, (state) => {
      const initiativesList = state.initiativesList.items.map((item) => ({ ...item }));
      this.setState({
        loading: false,
        initiativesList,
      });
    });

    this.subscribe(OnDealAutoSaveCreate, (deal) => {
      sessionStorage.removeItem(DEAL_DATA_STORE);
      this.setState({ savedAt: new Date(), autoSaving: false });
      this.savedDeal = R.clone(deal);
    });

    this.subscribe(OnDealAutoSaveCreateError, () => {
      this.setState({ autoSaving: false });
    });

    fetchDealStage();
    fetchInitiativeList('', 1, 1000);
    fetchCurrentAllianceMembersAction();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
    clearTimeout(this.autoSaveTimer);
  }

  onSubmit = () => {
    this.setState({ loading: true }, () => {
      clearTimeout(this.autoSaveTimer);
      const dealData = R.clone(this.state.data.dealData);
      const relatedItems = R.clone(this.state.data.relatedItems);
      const initiatives = R.clone(this.state.data.initiatives);
      createNewDeal(dealData, relatedItems, this.state.otherCompany, initiatives, this.savedDeal);
    });
  };

  autoSave = () => {
    const waitTime = 10000; // Wait 10s
    clearTimeout(this.autoSaveTimer);
    this.autoSaveTimer = setTimeout(() => {
      this.setState({ autoSaving: true });
      const dealData = R.clone(this.state.data.dealData);
      const relatedItems = R.clone(this.state.data.relatedItems);
      const initiatives = R.clone(this.state.data.initiatives);
      autoSaveCreateDeal(
        dealData,
        relatedItems,
        this.state.otherCompany,
        initiatives,
        this.savedDeal,
      );
    }, waitTime);
  };

  onScreen = (step) => {
    if (step === 2) clearTimeout(this.autoSaveTimer);
    this.setState({ step });
  };

  onDealStepChange = (step) => {
    const { selectedAlliance } = sessionStore.getState(NEW_SESSION_EVENT);
    const dealData = R.clone(this.state.data.dealData);
    try {
      dealValidator(dealData, selectedAlliance);
    } catch (e) {
      return this.onError(e);
    }
    this.onScreen(step);
  };

  onRelatedItemsStepChange = (step) => {
    const initiatives = R.clone(this.state.data.initiatives);
    try {
      initiativesItemValidator(initiatives);
    } catch (e) {
      return this.onError(e);
    }
    this.onScreen(step);
  };

  render() {
    const {
      loading,
      allianceMembersOptions,
      data,
      step,
      initiativesList,
      dealStagesOptions,
      savedAt,
      autoSaving,
    } = this.state;
    const { dealData, relatedItems, relatedDeals, initiatives } = data;
    const { history } = this.props;

    const currency = getCurrencyOnSession();
    let content = <Loader stretch />;
    let footer = <></>;
    let buttonsTop = <></>;

    if (!loading && step === 0) {
      content = (
        <DealForm
          dealData={dealData}
          onChange={this.onChangeDealData}
          currency={currency}
          allianceMembersOptions={allianceMembersOptions}
          dealStagesOptions={dealStagesOptions}
        />
      );
      footer = (
        <Card.Footer>
          <ActionButton onClick={() => this.onDealStepChange(1)} text={'Next'} />
        </Card.Footer>
      );
    }

    if (!loading && step === 1) {
      content = (
        <RelatedItemForm
          relatedItems={relatedItems}
          initiatives={initiativesList}
          onChange={(key, value) => {
            this.onChangeData(key, value);
          }}
          selectedInitiatives={initiatives}
        />
      );

      footer = (
        <Card.Footer>
          <ActionButton onClick={() => this.onRelatedItemsStepChange(2)} text={'Next'} />
          <TransparentButton onClick={() => this.onScreen(0)} text={'Previous'} />
        </Card.Footer>
      );
    }

    if (!loading && step === 2) {
      const dealDetail = R.clone(dealData);
      content = (
        <>
          <DealDetailTable dealData={dealDetail} currency={currency} relatedDeals={relatedDeals} />
          <InitiativeListTable initiatives={initiatives} />
          <RelatedItemsDetailTable relatedItems={relatedItems} />
        </>
      );
      footer = (
        <Card.Footer>
          <ActionButton disabled={autoSaving} onClick={() => this.onSubmit()} text="Create Deal" />
          <TransparentButton onClick={() => this.onScreen(1)} text="Previous" />
        </Card.Footer>
      );
    }

    if (!loading)
      buttonsTop = (
        <>
          <div className="company-icons">
            <ActionButtonClose onClick={history.goBack} />
          </div>
        </>
      );

    return (
      <React.Fragment>
        <Card.Header>
          <Grid.Layout
            columns="400px auto 400px"
            areas={[['left', 'center', 'right']]}
            style={{ width: '100%' }}>
            <Grid.Box area="left">
              <Row>
                <Heading type="h4" text="Create Deal" />
                <TextDateAgo from={savedAt} />
              </Row>
            </Grid.Box>
            <Grid.Box area="center">
              <FormSteps totalSteps={3} step={step} />
            </Grid.Box>
            <Grid.Box area="right" />
          </Grid.Layout>
          {buttonsTop}
        </Card.Header>
        <CreateViewCardBody>
          <Grid.Layout columns="30% 70%" areas={[['left', 'right']]} style={{ width: '100%' }}>
            <Grid.Box area="left">
              <LeftProgressSection sections={SCREENS_DEALS} currentScreen={step} />
            </Grid.Box>
            <BoxCard>
              <Grid.Box area="right">{content}</Grid.Box>
            </BoxCard>
          </Grid.Layout>
        </CreateViewCardBody>
        {footer}
      </React.Fragment>
    );
  }
}

DealCreateView.propTypes = {
  history: PropTypes.object.isRequired,
};

export default withRouter(DealCreateView);
