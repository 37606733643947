import Flux from '@cobuildlab/flux-state';

export const AUDIT_LOG_ERROR_EVENT = 'onAuditLogErrorEvent';
export const AUDIT_LOG_LIST_EVENT = 'onAuditLogListEvent';
export const AUDIT_LOG_USER_LIST_EVENT = 'onAuditLogUserListEvent';

class AuditLogStore extends Flux.DashStore {
  constructor() {
    super();
    this.addEvent(AUDIT_LOG_ERROR_EVENT);
    this.addEvent(AUDIT_LOG_LIST_EVENT);
    this.addEvent(AUDIT_LOG_USER_LIST_EVENT);
  }
}

export default new AuditLogStore();
