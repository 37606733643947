import React from 'react';
import PropTypes from 'prop-types';
import { View } from '@cobuildlab/react-simple-state';
import { Card, Table, Heading, Loader, Row } from '@8base/boost';
import { ListCardBody } from '../../../components/card/ListCardBody';
import { stageDetailMappingData } from './stage-mapping-utils';
import { StageMappingRow } from './component/stageMappingRow';
import { ActionButton } from '../../../components/buttons/ActionButton';
import YesNoDialog from '../../../components/dialogs/YesNoDialog';
import * as R from 'ramda';
import { fetchStageMapping, updateStageMapping } from './stage-mapping-action';
import { onErrorMixin } from '../../../shared/mixins';
import * as toast from '../../../components/toast/Toast';
import sessionStore, { NEW_SESSION_EVENT } from '../../../shared/SessionStore';
import {
  OnStageMappingDetail,
  OnStageMappingError,
  OnStageMappingUpdate,
} from './stage-mapping-events';

class StageMappingView extends View {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      stageMappingData: [],
      isSave: false,
      saveModalIsOpen: false,
      originalStageMappingValues: [],
    };
    this.onError = onErrorMixin.bind(this);

    this.user = sessionStore.getState(NEW_SESSION_EVENT).user;
  }

  componentDidMount() {
    this.subscribe(OnStageMappingError, this.onError);
    this.subscribe(OnStageMappingDetail, (state) => {
      const { dealStagesList } = state;
      const originalStageMappingValues = dealStagesList.items.map((item) =>
        item.values.map((dealStageValue) => {
          return { value: dealStageValue.value, id: dealStageValue.id };
        }),
      );

      const stageMappingData = stageDetailMappingData(dealStagesList);
      this.setState({
        stageMappingData,
        loading: false,
        originalStageMappingValues,
      });
    });

    this.subscribe(OnStageMappingUpdate, () => {
      const companyId = this.user.companyUserRelation.items[0].company.id;
      this.setState({}, () => {
        fetchStageMapping(companyId);
        toast.success('Stage Mapping Successfully saved');
      });
    });

    const companyId = this.user.companyUserRelation.items[0].company.id;

    fetchStageMapping(companyId);
  }

  onSaveModal = () => {
    const saveModalIsOpen = true;
    this.setState({
      saveModalIsOpen,
    });
  };

  onCloseSaveModal = () => {
    const saveModalIsOpen = false;
    this.setState({
      saveModalIsOpen,
    });
  };

  onYesSaveModal = () => {
    const saveModalIsOpen = false;
    const loading = true;
    this.setState(
      {
        saveModalIsOpen,
        loading,
      },
      () => {
        const stageMappingData = R.clone(this.state.stageMappingData);
        const originalStageMappingValues = R.clone(this.state.originalStageMappingValues);
        updateStageMapping(stageMappingData, originalStageMappingValues);
      },
    );
  };

  onChangeValues = (tags, index) => {
    const { stageMappingData } = this.state;
    stageMappingData[index].values = tags;

    const isSave = stageMappingData.length !== 0;
    this.setState({
      stageMappingData,
      isSave,
    });
  };

  render() {
    const { loading, stageMappingData, isSave, saveModalIsOpen } = this.state;
    let content = <Loader stretch />;
    let buttonsBottom = '';
    if (!loading) {
      content = (
        <div id={'c2gScreen'}>
          {stageMappingData.map((stage, key) => (
            <StageMappingRow stage={stage} index={key} key={key} onChange={this.onChangeValues} />
          ))}
        </div>
      );
      buttonsBottom = (
        <Row justifyContent="end">
          <ActionButton
            text="Save"
            disabled={!isSave}
            onClick={() => {
              this.onSaveModal();
            }}
          />
        </Row>
      );
    }

    return (
      <React.Fragment>
        <Card>
          <Card.Header>
            <Heading type="h4" text="Stage Mapping" />
          </Card.Header>
          <ListCardBody>
            <ListCardBody className="items-table">
              <Table>
                <Table.Body>{content}</Table.Body>
              </Table>
            </ListCardBody>
          </ListCardBody>
          <Card.Footer>{buttonsBottom}</Card.Footer>
        </Card>
        <YesNoDialog
          title={'Save Stage Mapping'}
          onYes={this.onYesSaveModal}
          onClose={this.onCloseSaveModal}
          onNo={this.onCloseSaveModal}
          text={'Are you sure you want to Save Stage Mapping?'}
          isOpen={saveModalIsOpen}
        />
      </React.Fragment>
    );
  }
}

StageMappingView.propTypes = {
  history: PropTypes.object.isRequired,
};

export default StageMappingView;
