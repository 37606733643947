import moment from 'moment';
import Excel from 'exceljs';
import { DateTime } from 'luxon';
import * as R from 'ramda';
import { exportToFile } from '../../../shared/utils';
import {
  MONTHS,
  BORDER_STYLE,
  FILL_STYLE,
  TITLE_CELL_STYLE,
  HORIZONTAL_ALIGNMENT,
} from '../reports-model';
import {
  PERIODICITY_TYPE,
  BOOKINGS_CLIENT_TYPE,
  BOOKINGS_PARTNER_TYPE,
  CONTRIBUTIONS_CLIENT_TYPE,
  CONTRIBUTIONS_PARTNER_TYPE,
} from '../../settings/alliance-management/allianceKPIs/allianceKPIs-model';
import { getKPIYears } from '../../settings/alliance-management/allianceKPIs/allianceKPIs-actions';
import { forecastingYearModel } from './forecasting-model';
import {
  getKPIFromList,
  getFinancialScorecardData,
} from '../balanced-scorecard/balanced-scorecard-utils';
import {
  getLastYearDay,
  getLastCurrentMonthDay,
  getLastQuarterMonth,
} from '../../../shared/date-utils';
import BigInt from 'big-integer';
import sessionStore, { NEW_SESSION_EVENT } from '../../../shared/SessionStore';

/**
 * Prepare forecastingYears for edit/create view.
 *
 * @param {object} alliance - To remove the unwanted forecastingYear
 * properties.
 * @returns {Array}  To set the originalForecastingYears.
 */
export const sanitizeForecastingYearsToEditOrCreate = (alliance) => {
  const forecastingYearsSanitizeData = alliance.forecastingYears.map((forecastingYear) => {
    delete forecastingYear.__typename;
    return forecastingYear;
  });

  alliance.forecastingYears = forecastingYearsSanitizeData;
  return R.clone(forecastingYearsSanitizeData);
};

/**
 * Get KPI expected monthByMonth for forecasting expected contributions & bookings.
 *
 * @param  {object} kpi - Kpi.
 * @returns {Array} ExpectedMonthByMonths.
 */
export const getKPIExpectedMonthByMonth = (kpi) => {
  if (!kpi) return Array.from({ length: 12 }, (e) => '0');

  const expectedMonthByMonths =
    kpi.targetDistributionType === PERIODICITY_TYPE
      ? Array.from({ length: 12 }, (e) =>
        BigInt(kpi.target)
          .divide(12)
          .toString(),
      )
      : kpi.monthByMonth.map((value) => BigInt(value).toString());

  return expectedMonthByMonths;
};

/**
 *  Set forecastingYears or remove not included on forecastingMaxYears.
 *
 * @param  {object} allianceData - Alliance data.
 * @param  {Array} forecastYears - The list of years.
 */
export function setForecastMaxYears(allianceData, forecastYears) {
  console.log('setForecastMaxYears', forecastYears, allianceData);
  // add missing years
  for (const forecastYear of forecastYears) {
    if (!allianceData.forecastingYears.some(({ year }) => forecastYear === year)) {
      allianceData.forecastingYears.push(forecastingYearModel(forecastYear));
      console.log(allianceData.forecastingYears);
    }
  }
  // remove not included years
  allianceData.forecastingYears = allianceData.forecastingYears.filter(({ year }) =>
    forecastYears.includes(year),
  );
}

/**
 *  Set Expected Forecasting Contributions & Bookings.
 *
 * @param {object} alliance - Alliance.
 * @param {number} year - Year.
 * @param {object} kpis - KPIS.
 */
export const setExpectedForecasting = (alliance, year, kpis) => {
  // GET KPIs from list
  const clientBookingsKPI = getKPIFromList(kpis, BOOKINGS_CLIENT_TYPE, year);
  const partnerBookingsKPI = getKPIFromList(kpis, BOOKINGS_PARTNER_TYPE, year);
  const clientContributionsKPI = getKPIFromList(kpis, CONTRIBUTIONS_CLIENT_TYPE, year);
  const partnerContributionsKPI = getKPIFromList(kpis, CONTRIBUTIONS_PARTNER_TYPE, year);
  // get getKPIExpectedMonthByMonths
  const clientExpectedBookings = getKPIExpectedMonthByMonth(clientBookingsKPI);
  const partnerExpectedBookings = getKPIExpectedMonthByMonth(partnerBookingsKPI);
  const clientExpectedContributions = getKPIExpectedMonthByMonth(clientContributionsKPI);
  const partnerExpectedContributions = getKPIExpectedMonthByMonth(partnerContributionsKPI);
  // set expected contributions & bookings on selected year
  alliance.forecastingYears.forEach((forecast) => {
    if (forecast.year === year) {
      // month by month
      forecast.clientExpectedBookings = clientExpectedBookings;
      forecast.partnerExpectedBookings = partnerExpectedBookings;
      forecast.clientExpectedContributions = clientExpectedContributions;
      forecast.partnerExpectedContributions = partnerExpectedContributions;
      // totals
      forecast.totalClientContributionsBudget = clientContributionsKPI.target;
      forecast.totalPartnerContributionsBudget = partnerContributionsKPI.target;
      forecast.totalClientBookingsBudget = clientBookingsKPI.target;
      forecast.totalPartnerBookingsBudget = partnerBookingsKPI.target;
    }
  });
};

/**
 *  Set Forecasting Actuals Contributions & Bookings.
 *
 * @param  {object}  kpis - KPIS.
 * @param  {object}  contributions - Contributions.
 * @param  {object}  deals - Deals.
 * @param  {object}  alliance - Alliance.
 * @param  {number}  year - Year.
 * @returns {Promise} - Void.
 */
export const setForecastingActuals = async (kpis, contributions, deals, alliance, year) => {
  const dataKPIs = await getFinancialScorecardData(kpis, deals, contributions, alliance, year);

  console.log('dataKPIs', dataKPIs);
  // get financial KPI data
  const clientContributionsKPI = dataKPIs.find(({ type }) => type === CONTRIBUTIONS_CLIENT_TYPE);
  const partnerContributionsKPI = dataKPIs.find(({ type }) => type === CONTRIBUTIONS_PARTNER_TYPE);
  const clientBookingsKPI = dataKPIs.find(({ type }) => type === BOOKINGS_CLIENT_TYPE);
  const partnerBookingsKPI = dataKPIs.find(({ type }) => type === BOOKINGS_PARTNER_TYPE);
  // get forecasting actuals
  const clientContributionsActuals = clientContributionsKPI.monthByMonthForecastActuals;
  const partnerContributionsActuals = partnerContributionsKPI.monthByMonthForecastActuals;
  const clientBookingsActuals = clientBookingsKPI.monthByMonthForecastActuals;
  const partnerBookingsActuals = partnerBookingsKPI.monthByMonthForecastActuals;
  // set forecasting actuals
  alliance.forecastingYears.forEach((forecast) => {
    if (forecast.year === year) {
      forecast.clientContributionsActuals = clientContributionsActuals;
      forecast.partnerContributionsActuals = partnerContributionsActuals;
      forecast.clientBookingsActuals = clientBookingsActuals;
      forecast.partnerBookingsActuals = partnerBookingsActuals;
    }
  });
};

/**
 * Replace old Forecast With Actuals of the selected year
 * old forecasting values (prior to the current month)
 * are replaced by the actuals.
 *
 * @param {object} alliance - The alliance with the forecasting.
 * @param {number} year - The year to replace the forecasting.
 */
export const replaceOldForecastWithActuals = (alliance, year) => {
  const currentYear = moment().year();
  const currentMonth = moment().month();
  alliance.forecastingYears.forEach((forecast) => {
    if (forecast.year === year) {
      for (var i = 0; i < 12; i++) {
        if ((currentYear === forecast.year && i <= currentMonth) || currentYear > forecast.year) {
          forecast.clientContributionsForecast[i] = R.clone(forecast.clientContributionsActuals[i]);
          forecast.partnerContributionsForecast[i] = R.clone(
            forecast.partnerContributionsActuals[i],
          );
          forecast.clientBookingsForecast[i] = R.clone(forecast.clientBookingsActuals[i]);
          forecast.partnerBookingsForecast[i] = R.clone(forecast.partnerBookingsActuals[i]);
        }
      }
    }
  });
};

/**
 * Formula Variance = (total actuals + future months) - Budget = total forecast - total budget = Variance.
 *
 * @param {string} totalForecast - The total forecasting.
 * @param {string} totalBudget - Total budget.
 * @returns {string} The variance.
 */
const calcVariance = (totalForecast, totalBudget) => {
  const variance = BigInt(totalForecast)
    .subtract(totalBudget)
    .toString();

  return variance;
};

/**
 * Get the total forecasting data for Report View from the monthByMonth data.
 *
 * @param {object} forecast - Forecasting year.
 * @returns {object} - The bookings/contributions data for reports.
 */
export const getTotalForecast = (forecast) => {
  const sumReducer = (array) => {
    return array.reduce(
      (a, b) =>
        BigInt(a)
          .add(b)
          .toString(),
      '0',
    );
  };

  const {
    clientExpectedContributions: clientContributionsBudget,
    partnerExpectedContributions: partnerContributionsBudget,
    totalClientContributionsBudget,
    totalPartnerContributionsBudget,
    clientContributionsActuals,
    partnerContributionsActuals,
    clientContributionsForecast,
    partnerContributionsForecast,
    clientExpectedBookings: clientBookingsBudget,
    partnerExpectedBookings: partnerBookingsBudget,
    totalClientBookingsBudget,
    totalPartnerBookingsBudget,
    clientBookingsActuals,
    partnerBookingsActuals,
    clientBookingsForecast,
    partnerBookingsForecast,
  } = forecast;

  // total contributions
  const totalClientContributionsActuals = sumReducer(clientContributionsActuals);
  const totalPartnerContributionsActuals = sumReducer(partnerContributionsActuals);

  const totalClientContributionsForecast = sumReducer(clientContributionsForecast);
  const totalPartnerContributionsForecast = sumReducer(partnerContributionsForecast);

  const clientContributionsVariance = calcVariance(
    totalClientContributionsForecast,
    totalClientContributionsBudget,
  );

  const partnerContributionsVariance = calcVariance(
    totalPartnerContributionsForecast,
    totalPartnerContributionsBudget,
  );

  const bothCompaniesContributionsBudget = BigInt(totalClientContributionsBudget)
    .add(totalPartnerContributionsBudget)
    .toString();

  const bothCompaniesContributionsActuals = BigInt(totalClientContributionsActuals)
    .add(totalPartnerContributionsActuals)
    .toString();

  const bothCompaniesContributionsForecast = BigInt(totalClientContributionsForecast)
    .add(totalPartnerContributionsForecast)
    .toString();

  const bothCompaniesContributionsVariance = calcVariance(
    bothCompaniesContributionsForecast,
    bothCompaniesContributionsBudget,
  );

  // total bookings
  const totalClientBookingsActuals = sumReducer(clientBookingsActuals);
  const totalPartnerBookingsActuals = sumReducer(partnerBookingsActuals);

  const totalClientBookingsForecast = sumReducer(clientBookingsForecast);
  const totalPartnerBookingsForecast = sumReducer(partnerBookingsForecast);

  const clientBookingsVariance = calcVariance(
    totalClientBookingsForecast,
    totalClientBookingsBudget,
  );

  const partnerBookingsVariance = calcVariance(
    totalPartnerBookingsForecast,
    totalPartnerBookingsBudget,
  );

  const bothCompaniesBookingsBudget = BigInt(totalClientBookingsBudget)
    .add(totalPartnerBookingsBudget)
    .toString();

  const bothCompaniesBookingsActuals = BigInt(totalClientBookingsActuals)
    .add(totalPartnerBookingsActuals)
    .toString();

  const bothCompaniesBookingsForecast = BigInt(totalClientBookingsForecast)
    .add(totalPartnerBookingsForecast)
    .toString();

  const bothCompaniesBookingsVariance = calcVariance(
    bothCompaniesBookingsForecast,
    bothCompaniesBookingsBudget,
  );

  return {
    /*
    monthByMonth
     */
    // contributions
    clientContributionsBudget,
    partnerContributionsBudget,
    clientContributionsActuals,
    partnerContributionsActuals,
    clientContributionsForecast,
    partnerContributionsForecast,
    // bookings
    clientBookingsBudget,
    partnerBookingsBudget,
    clientBookingsActuals,
    partnerBookingsActuals,
    clientBookingsForecast,
    partnerBookingsForecast,
    /*
    totals
     */
    // contributions
    totalClientContributionsBudget,
    totalPartnerContributionsBudget,
    totalClientContributionsActuals,
    totalPartnerContributionsActuals,
    totalClientContributionsForecast,
    totalPartnerContributionsForecast,
    clientContributionsVariance,
    partnerContributionsVariance,
    bothCompaniesContributionsVariance,
    bothCompaniesContributionsBudget,
    bothCompaniesContributionsActuals,
    bothCompaniesContributionsForecast,
    // bookings
    totalClientBookingsBudget,
    totalPartnerBookingsBudget,
    totalClientBookingsActuals,
    totalPartnerBookingsActuals,
    totalClientBookingsForecast,
    totalPartnerBookingsForecast,
    clientBookingsVariance,
    partnerBookingsVariance,
    bothCompaniesBookingsVariance,
    bothCompaniesBookingsBudget,
    bothCompaniesBookingsActuals,
    bothCompaniesBookingsForecast,
  };
};

/**
 * Get Alliance Forecasting Data.
 *
 * @param {object} kpis - Kpis.
 * @param {object} contributions - Contributions.
 * @param {object} deals - Deals.
 * @param {object} alliance - Alliance.
 * @param {number} year - Year.
 */
export const getAllianceForecastingData = async (kpis, contributions, deals, alliance, year) => {
  const originalForecastingYears = sanitizeForecastingYearsToEditOrCreate(alliance);
  const { businessCase } = alliance;
  const { forecastYears } = getKPIYears(alliance, businessCase);

  setForecastMaxYears(alliance, forecastYears);
  setExpectedForecasting(alliance, year, kpis);

  await setForecastingActuals(kpis, contributions, deals, alliance, year);
  replaceOldForecastWithActuals(alliance, year);

  const forecast = R.clone(alliance.forecastingYears.find((forecast) => forecast.year === year));
  const reportForecasting = getTotalForecast(forecast);

  const selectedForecastYear = year;
  return {
    alliance,
    selectedForecastYear,
    forecastYears,
    originalForecastingYears,
    reportForecasting,
  };
};

/**
 * Set a property with the specific years on the forecastingYears based on the initiative baselineStartDate
 * calc the budgets months based on anticipatedCosts years
 * sets the varianceMonths (budget - yTDActual).
 *
 * @param {Array} initiatives
 * @param {object} requestMonthsInfo
 */

const getInitiativesListData = (initiatives, requestMonthsInfo) => {
  const initiativesWithBusinessCase = initiatives.filter(
    ({ businessCase }) => businessCase && businessCase.anticipatedCosts,
  );
  const initiativesList = R.clone(initiativesWithBusinessCase);

  initiativesList.forEach((initiative) => {
    initiative.forecastingYears = initiative.forecastingYears.items;
    const {
      baselineStartDate,
      forecastingYears,
      businessCase: { anticipatedCosts: yearBudgets },
    } = initiative;
    let baselineStartYear = moment(baselineStartDate).year();
    const baselineStartMonth = moment(baselineStartDate).month();

    initiative.forecastingsByYears = {};

    forecastingYears.forEach((forecastingYear, yearIndex) => {
      initiative.forecastingsByYears[baselineStartYear] = [];

      const currentBudget = yearBudgets.length > yearIndex ? yearBudgets[yearIndex] : null;
      let budgetMonth = null;
      if (currentBudget) {
        budgetMonth =
          yearIndex === 0 ? currentBudget / (12 - baselineStartMonth) : currentBudget / 12;
      }

      const { forecastings, yTDActuals } = forecastingYear;
      forecastings.forEach((forecasting, monthIndex) => {
        // set budget to null if not greaterOrEqual baselineStartDate
        const budget = yearIndex === 0 && monthIndex < baselineStartMonth ? null : budgetMonth;
        const monthData = {
          month: monthIndex + 1,
          year: baselineStartYear,
          forecasting,
          yTDActuals: yTDActuals[monthIndex],
          budget,
          variance: budget - yTDActuals[monthIndex],
        };
        initiative.forecastingsByYears[baselineStartYear].push(monthData);
      });

      ++baselineStartYear;
    });
  });

  initiativesList.forEach((initiative) => {
    initiative.forecastingsInSpecifiedRange = [];
    initiative.totalsInSpecifiedRange = {
      forecasting: 0,
      yTDActuals: 0,
      budget: 0,
      variance: 0,
    };
    Object.keys(requestMonthsInfo.monthNumberDict).forEach((year) => {
      if (initiative.forecastingsByYears[year]) {
        Object.keys(requestMonthsInfo.monthNumberDict[year]).forEach((month) => {
          const monthData = initiative.forecastingsByYears[year][month - 1];
          const { forecasting, yTDActuals, budget, variance } = monthData;
          initiative.totalsInSpecifiedRange.forecasting += forecasting;
          initiative.totalsInSpecifiedRange.yTDActuals += yTDActuals;
          initiative.totalsInSpecifiedRange.budget += budget;
          initiative.totalsInSpecifiedRange.variance += variance;
          initiative.forecastingsInSpecifiedRange.push(monthData);
        });
      } else {
        Object.keys(requestMonthsInfo.monthNumberDict[year]).forEach((month) => {
          initiative.forecastingsInSpecifiedRange.push({ month, year });
        });
      }
    });
  });

  return initiativesList;
};

const generateBudgetVsForecastFilters = (filters) => {
  return filters.reduce((accum, filter) => {
    const { rule, cond } = filter;
    switch (rule) {
    case 'thisYear': {
      return [
        ...accum,
        {
          baselineStartDate: {
            lt: getLastYearDay().toISODate(),
          },
        },
      ];
    }
    case 'year': {
      return [
        ...accum,
        {
          baselineStartDate: {
            lt: getLastYearDay(cond).toISODate(),
          },
        },
      ];
    }
    case 'quarter': {
      return [
        ...accum,
        {
          baselineStartDate: {
            lt: getLastQuarterMonth(...cond)
              .plus({ month: 1 })
              .toISODate(),
          },
        },
      ];
    }
    case 'lastMonth': {
      return [
        ...accum,
        {
          baselineStartDate: {
            lt: getLastCurrentMonthDay()
              .minus({ month: 1 })
              .toISODate(),
          },
        },
      ];
    }
    case 'rollingSixMonth': {
      return [
        ...accum,
        {
          baselineStartDate: {
            lt: getLastCurrentMonthDay().toISODate(),
          },
        },
      ];
    }
    case 'month': {
      const newDate = DateTime.fromSQL(cond);
      return [
        ...accum,
        {
          baselineStartDate: {
            lt: newDate
              .plus({ month: 1 })
              .set({ day: 1 })
              .toISODate(),
          },
        },
      ];
    }
    case 'monthSet': {
      const endDate = DateTime.fromSQL(cond[1]);

      return [
        ...accum,
        {
          baselineStartDate: {
            lt: endDate
              .plus({ month: 1 })
              .set({ day: 1 })
              .toISODate(),
          },
        },
      ];
    }
    case 'ytd': {
      return [
        ...accum,
        {
          baselineStartDate: {
            lt: getLastCurrentMonthDay().toISODate(),
          },
        },
      ];
    }

    default:
      return [];
    }
  }, []);
};

const convertInitiativesListToExcelData = (initiativesList, currentInitiative, fileName) => {
  let totalTable = []; // initiative budget table
  let totalAmountsRow = ['TOTAL', 0]; // footer row in the total table
  // this row separates the initiative tables from each other
  let emptyRow = [];

  const withoutTotalTable = !!R.path(['id'], currentInitiative);

  // form rows with data for each initiative
  // and fill totalTable and totalAmountsRow
  const initiativesTables = initiativesList.reduce((result, initiative, initiativeIndex) => {
    const { name, forecastingsInSpecifiedRange, totalsInSpecifiedRange } = initiative;
    // this row is the firt row in the table and it contains only initiative name
    const titleRow = new Array(forecastingsInSpecifiedRange.length + 2).fill('');
    titleRow[0] = name;
    // this row separates the initiative tables from each other
    if (emptyRow.length === 0) {
      emptyRow = new Array(forecastingsInSpecifiedRange.length + 2).fill('');
    }

    const {
      budget: totalBudget,
      forecasting: totalForecasting,
      variance: totalVariance,
      yTDActuals: totalYTDActuals,
    } = totalsInSpecifiedRange;

    // calculate the sum of all budgets for totalAmountsRow in the totalTable
    totalAmountsRow[1] = totalAmountsRow[1] + totalBudget;

    const initiativeRows = forecastingsInSpecifiedRange.reduce((rows, monthData, index) => {
      const { budget, forecasting, variance, yTDActuals, year, month } = monthData;

      // calculate the sum of budgets by months for totalAmountsRow in the totalTable
      totalAmountsRow[index + 2] = (totalAmountsRow[index + 2] || 0) + (budget || 0);

      const [
        headerRow = ['OVERVIEW', ''],
        budgetRow = ['Budget', totalBudget],
        yTDActualsRow = ['YTD Actuals', totalForecasting],
        varianceRow = ['Variance', totalVariance],
        forecastingRow = ['Forecast', totalYTDActuals],
      ] = rows;

      // form rows with data for each initiative
      return [
        [...headerRow, `${MONTHS[month - 1]}-${String(year).substring(2, 4)}`],
        [...budgetRow, budget ? budget : 0],
        [...yTDActualsRow, yTDActuals ? yTDActuals : 0],
        [...varianceRow, variance ? variance : 0],
        [...forecastingRow, forecasting ? forecasting : 0],
      ];
    }, []);

    // form title and header rows for totalTable
    if (initiativeIndex === 0) {
      const titleHeaderRow = new Array(forecastingsInSpecifiedRange.length + 2).fill('');
      titleHeaderRow[0] = 'Total Budget';

      const [headerRow] = initiativeRows;
      const totalHeaderRow = ['INITIATIVES', ...headerRow.slice(1)];

      totalTable = [...totalTable, titleHeaderRow, totalHeaderRow];
    }

    // form row with budget data by currennt initiative
    const budgetRow = initiativeRows[1];
    const totalBudgetRow = [name, ...budgetRow.slice(1)];
    totalTable = [...totalTable, totalBudgetRow];

    // add footer row (totalAmountsRow) to totalTable
    if (initiativeIndex === initiativesList.length - 1) {
      totalTable = [...totalTable, totalAmountsRow];
    }

    // create initiative table
    const initiativeTable = [titleRow, ...initiativeRows];

    return [...result, initiativeTable];
  }, []);

  const allTables = [...initiativesTables];
  // add total table only if no specific initiative is selected
  if (!withoutTotalTable) {
    allTables.push(totalTable);
  }

  // Create a Workbook
  let workbook = new Excel.Workbook();

  // Set Workbook Properties
  workbook.creator = 'User Name';
  workbook.created = new Date();

  // Add a Worksheet
  let worksheet = workbook.addWorksheet('My Sheet Name');

  // Add tables to Worksheet
  allTables.forEach((table, tableIndex) => {
    // add rows to worksheet
    worksheet.addRows(table);

    // for all tables except total table
    if (allTables.length !== tableIndex + 1 || withoutTotalTable) {
      // add empty rows to separate one table from another
      worksheet.addRows([emptyRow, emptyRow]);
      // add thin border for all cells in initiative table
      const firstTableRow = 1 + tableIndex * 8;
      const lastTableRow = 6 + tableIndex * 8;
      for (let rowNumber = firstTableRow; rowNumber <= lastTableRow; rowNumber++) {
        worksheet.getRow(rowNumber).eachCell((cell) => {
          cell.border = BORDER_STYLE;
        });
      }
    }

    // columns count in our table
    const rowLength = emptyRow ? emptyRow.length : 1;

    // do formatting title row
    const titleRow = worksheet.getRow(1 + tableIndex * 8);
    const firstTitleCell = titleRow.getCell(1);
    const lastTitleCell = titleRow.getCell(rowLength);
    firstTitleCell.font = TITLE_CELL_STYLE;
    firstTitleCell.fill = FILL_STYLE;
    worksheet.mergeCells(`${firstTitleCell.address}:${lastTitleCell.address}`);

    // do formatting header row
    const headerRow = worksheet.getRow(2 + tableIndex * 8);
    const firstHeaderCell = headerRow.getCell(1);
    const secondHeaderCell = headerRow.getCell(2);
    headerRow.eachCell(function(cell, cellIndex) {
      cell.fill = FILL_STYLE;
      if (cellIndex > 2) {
        cell.alignment = HORIZONTAL_ALIGNMENT;
      }
    });
    worksheet.mergeCells(`${firstHeaderCell.address}:${secondHeaderCell.address}`);

    // do formatting total table
    if (!withoutTotalTable && allTables.length === tableIndex + 1) {
      const firstTotalTableRow = 1 + tableIndex * 8;
      const lastTotalTableRow = 2 + allTables.length + tableIndex * 8;
      for (let rowNumber = firstTotalTableRow; rowNumber <= lastTotalTableRow; rowNumber++) {
        worksheet.getRow(rowNumber).eachCell((cell) => {
          if (rowNumber === lastTotalTableRow) {
            cell.border = BORDER_STYLE;
            cell.fill = FILL_STYLE;
          } else {
            cell.border = BORDER_STYLE;
          }
        });
      }
    }
  });

  // Save excel file on the computer
  workbook.xlsx
    .writeBuffer()
    .then((buffer) => {
      exportToFile(buffer, `${fileName}.xlsx`);
    })
    .catch((err) => console.log('Error writing excel export', err));
};

export {
  generateBudgetVsForecastFilters,
  getInitiativesListData,
  convertInitiativesListToExcelData,
};

export const getCompanyOptions = () => {
  const selectedAlliance = sessionStore.getState(NEW_SESSION_EVENT).selectedAlliance;
  const { clientCompany, partnerCompany } = selectedAlliance;

  const options = [];
  options.push({ label: 'Both Companies', value: null });
  options.push({ label: clientCompany.name, value: 'clientCompany' });
  if (partnerCompany) {
    options.push({ label: partnerCompany.name, value: 'partnerCompany' });
  }

  return options;
};
