import React from 'react';
import { Dialog, Grid, Button, Input, Checkbox, Table, Text, Loader } from '@8base/boost';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { formatUser, isValidString } from '../../../shared/utils';
import {
  ACTION_TYPE,
  CONTRIBUTION_TYPE,
  DEAL_TYPE,
  DECISION_TYPE,
  FUNDING_REQUEST_TYPE,
  IDEA_TYPE,
  ISSUE_TYPE,
  RISK_TYPE,
} from '../../../shared/item-types';
import { searchItems } from '../related-item-actions';
import { View } from '@cobuildlab/react-simple-state';
import { onErrorMixin } from '../../../shared/mixins';
import { getItemByType } from '../../../shared/items-util';
import Moment from 'react-moment/dist/index';
import { ActionButton } from '../../../components/buttons/ActionButton';
import { OnItems, OnRelatedItemError } from '../related-item-events';

const ITEM_TYPES = [
  { label: 'Funding Requests', checked: true, type: FUNDING_REQUEST_TYPE },
  { label: 'Contributions', checked: true, type: CONTRIBUTION_TYPE },
  { label: 'Deals', checked: true, type: DEAL_TYPE },
  { label: 'Actions', checked: true, type: ACTION_TYPE },
  { label: 'Issues', checked: true, type: ISSUE_TYPE },
  { label: 'Risks', checked: true, type: RISK_TYPE },
  { label: 'Decisions', checked: true, type: DECISION_TYPE },
  { label: 'Ideas', checked: true, type: IDEA_TYPE },
];

class RelatedIdemDialog extends View {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      loading: false,
      checks: R.clone(ITEM_TYPES),
      term: '',
    };
    this.onError = onErrorMixin.bind(this);
  }

  componentDidMount(): undefined {
    const { allowedDealOption } = this.props;
    let { checks } = this.state;

    this.subscribe(OnRelatedItemError, this.onError);
    this.subscribe(OnItems, (state) => {
      const items = state.itemsList.items.map((item) => {
        return {
          checked: false,
          ...getItemByType(item),
        };
      });
      const filteredItems = items.filter((item) => !this.props.filteredIds.includes(item.itemId));
      this.setState({ items: filteredItems, loading: false });
    });

    if (!allowedDealOption) {
      this.setState({ checks: checks.filter((item) => item.type !== 'DEAL') });
    }
  }

  onSelect = () => {
    const { items } = this.state;
    const selectedItems = items.filter((item) => item.checked);
    if (selectedItems.length > 0) this.props.onRelatedItems(selectedItems);
    this.props.onClose();
  };

  onChangeCheckBox = (i) => {
    const { checks } = this.state;
    checks[i].checked = !checks[i].checked;
    this.setState({ checks });
  };

  onChangeCheckBoxItem = (i) => {
    const { items } = this.state;
    items[i].checked = !items[i].checked;
    this.setState({ items });
  };

  onSearchItems = () => {
    const { term, checks } = this.state;
    const { itemData } = this.props;

    const selectedTypes = checks.filter((check) => check.checked).map((check) => check.type);
    if (selectedTypes.length === 0) return;
    this.setState({ loading: true, items: [] }, () => {
      searchItems(term, selectedTypes, itemData);
    });
  };

  render() {
    const { isOpen, onClose } = this.props;
    const { loading, items, term, checks } = this.state;
    let content = <Loader stretch />;

    if (items.length === 0 && loading === false)
      content = <Text> Search For an Item to start </Text>;

    if (items.length > 0 && loading === false)
      content = (
        <>
          <Text weight={'bold'}> Results: </Text>
          <br />
          <Table style={{ height: 'auto' }}>
            <Table.Header columns="1fr 1fr 1fr 1fr 1fr">
              <Table.HeaderCell>Select</Table.HeaderCell>
              <Table.HeaderCell>Type</Table.HeaderCell>
              <Table.HeaderCell>Name</Table.HeaderCell>
              <Table.HeaderCell>Due Date</Table.HeaderCell>
              <Table.HeaderCell>Rquested By</Table.HeaderCell>
            </Table.Header>
          </Table>
          <br />
          <div className="related-table">
            <Grid.Layout gap="sm" stretch columns="1fr 1fr 1fr 1fr 1fr">
              {items.map((item, i) => {
                const check = item.checked ? (
                  <Checkbox onChange={() => this.onChangeCheckBoxItem(i)} checked />
                ) : (
                  <Checkbox onChange={() => this.onChangeCheckBoxItem(i)} />
                );
                return (
                  <React.Fragment key={i}>
                    {check}
                    <Text>{item.type}</Text>
                    <Text>{item.name}</Text>
                    <Text>
                      {isValidString(item.originalDueDate) ? (
                        <Moment format="MMMM Do, YYYY">{item.originalDueDate}</Moment>
                      ) : (
                        ''
                      )}
                    </Text>
                    <Text>{formatUser(item.requestedBy)}</Text>
                  </React.Fragment>
                );
              })}
            </Grid.Layout>
          </div>
        </>
      );

    return (
      <Dialog size="xg" isOpen={isOpen}>
        <Dialog.Header title={'Select a Related Item'} onClose={onClose} />
        <Dialog.Body scrollable style={{ minHeight: '250px', maxWidth: '50vw' }}>
          <Grid.Layout gap="sm" stretch columns="4fr 1fr">
            <div>
              <Input
                value={term}
                onChange={(val) => this.setState({ term: val })}
                name="input"
                clearable
              />
            </div>
            <ActionButton onClick={this.onSearchItems} text="Search" />
          </Grid.Layout>
          <br />
          <Grid.Layout gap="sm" stretch columns="1fr 1fr 1fr 1fr">
            {checks.map((item, i) => {
              if (item.checked)
                return (
                  <Checkbox
                    onChange={() => this.onChangeCheckBox(i)}
                    key={i}
                    label={item.label}
                    checked
                  />
                );
              return (
                <Checkbox
                  onChange={() => this.onChangeCheckBox(i)}
                  key={i}
                  label={item.label}
                  checked={false}
                />
              );
            })}
          </Grid.Layout>
          <br />
          {content}
          <br />
        </Dialog.Body>
        <Dialog.Footer>
          <Button color="neutral" variant="outlined" onClick={onClose}>
            Close
          </Button>
          <ActionButton onClick={this.onSelect} text={'Select'} />
        </Dialog.Footer>
      </Dialog>
    );
  }
}

RelatedIdemDialog.propTypes = {
  onRelatedItems: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  filteredIds: PropTypes.array,
  isOpen: PropTypes.bool.isRequired,
  allowedDealOption: PropTypes.bool.isRequired,
  itemData: PropTypes.object,
};

export default RelatedIdemDialog;
