import { FIELD_TYPE } from '@8base/utils';

import { convertRelationToString } from '../utils';

const prepareDataToExport = ({ title, headerRow, dataRows, totalRow }) => {
  headerRow = headerRow.map(({ value }) => value);
  dataRows = dataRows.map((singleRow) =>
    singleRow.map(({ value, fieldType, name }) =>
      fieldType !== FIELD_TYPE.RELATION ? value : convertRelationToString(name, value),
    ),
  );
  totalRow = totalRow.map(({ value }) => value);

  if (title) {
    // create titleRow
    const titleRow = new Array(headerRow.length).fill('');
    titleRow[0] = title;

    return [titleRow, headerRow, ...dataRows, totalRow];
  }
  return [headerRow, ...dataRows, totalRow];
};

export { prepareDataToExport };
