import styled from 'styled-components';

const ReportTotalSection = styled.div`
  box-sizing: border-box;
  border: 1px solid #e9eff4;
  border-radius: 5px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.06);
  overflow: auto;
  background-color: #f4f7f9;
  margin: 24px 24px 25px 24px;
  padding: 16px 24px;
  display: flex;
  justify-content: space-between;
`;

const TotalSectionTitle = styled.div`
  color: #7e828b;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0.5px;
  line-height: 16px;
`;

const TotalSectionAmount = styled.div`
  color: #323c47;
  font-size: 13px;
  font-weight: 600;
  line-height: 16px;
  text-align: right;
`;

export { ReportTotalSection, TotalSectionTitle, TotalSectionAmount };
