import { createEvent } from '@cobuildlab/react-simple-state';

export const OnWizardError = createEvent();
export const OnUserInformation = createEvent();
export const OnUserUpdate = createEvent();
export const OnCompanyInformation = createEvent();
export const OnCompanyInformationCreate = createEvent();
export const OnCompanyInformationUpdate = createEvent();
export const OnAllianceInformationCreate = createEvent();
export const OnCompanyCreateUserInvitation = createEvent();
