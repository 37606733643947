import React from 'react';
import { Card, Grid, Heading, Loader, Row } from '@8base/boost';
import { CreateViewCardBody } from '../../../components/new-ui/card/CreateViewCardBody';
import FundingRequestForm from './components/FundingRequestForm';
import * as toast from '../../../components/toast/Toast';
import {
  autoSaveCreateFundingRequest,
  createFundingRequest,
  fetchFundingRequestFormDataAction,
} from './funding-request-action';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { FUNDING_REQUEST_DOCUMENTS, FundingRequestModel } from './funding-request-model';
import { View } from '@cobuildlab/react-simple-state';
import { onChangeDataMixin, onErrorMixin } from '../../../shared/mixins';
import * as R from 'ramda';
import BusinessCaseForm from '../../document-management/business-case/components/BusinessCaseForm';
import BusinessCaseModel from '../../document-management/business-case/BusinessCase.model';
import sessionStore, { NEW_SESSION_EVENT } from '../../../shared/SessionStore';
import { fetchInitiativeList } from '../initiative/initiative-actions';
import BusinessCaseDetailTable from '../../document-management/business-case/components/BusinessCaseDetailTable';
import FundingRequestDetailTable from './components/FundingRequestDetailTable';
import { getCurrencyOnSession } from '../../../shared/alliance-utils';
import { ActionButton } from '../../../components/buttons/ActionButton';
import { TransparentButton } from '../../../components/buttons/TransparentButton';
import { ActionButtonClose } from '../../../components/buttons/ActionButtonClose';
import { FormSteps } from '../../../components/dots/FormSteps';
import { fundingRequestValidator } from './funding-request-validators';
import { businessCaseValidator } from '../../document-management/business-case/business-case-validators';
import { saveFormToSessionStorage } from '../../../shared/utils';
import { fetchCurrentAllianceMembersAction } from '../../settings/alliance-management/alliance-actions';
import RelatedItemForm from '../../related-item/components/RelatedItemForm';
import { getItemByType } from '../../../shared/items-util';
import { RelatedItemsDetailTable } from '../../related-item/components/RelatedItemsDetailTable';
import { HorizontalLineText } from '../../../components/text/HorizontalLineText';
import { initiativesApproved, initiativesItemValidator } from '../initiative/initiative-validators';
import { InitiativeListTable } from '../initiative/components/InitiativeListTable';
import { LeftProgressSection } from '../../../components/new-ui/LeftProgressSection';
import { SCREENS_FUNDING } from '../screenView';
import { BoxCard } from '../../../components/new-ui/div/BoxCard';
import { CardFooter } from '../../../components/new-ui/card/CardFooter';
import { TextDateAgo } from '../../../components/text/TextDateAgo';
import {
  onFundingRequestCreate,
  onFundingRequestError,
  onFundingRequestFormData,
  onFundingRequestAutoSave,
  onFundingRequestAutoSaveError,
} from './funding-request-event';
import { OnRelatedItems } from '../../related-item/related-item-events';
import { OnInitiativeList } from '../initiative/initiative-events';

const FUNDING_REQUEST_DATA_STORE = 'fundingRequestCreateView';

/**
 * Create Funding Request.
 *
 */

class FundingRequestCreateView extends View {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        fundingRequestData: R.clone(FundingRequestModel),
        businessCaseData: R.clone(BusinessCaseModel),
        relatedItems: [],
        initiatives: [],
      },
      loading: true,
      clientCompanyMembers: [],
      partnerCompanyMembers: [],
      step: 0,
      initiativesList: [],
      clientCompany: null,
      partnerCompany: null,
      userRole: true,
      savedAt: null,
      autoSaving: false,
    };
    this.onError = onErrorMixin.bind(this);
    this.onChangeData = onChangeDataMixin.bind(this);

    this.autoSaveTimer = null;
    this.savedFundingRequest = null;
  }

  onChangeFundingRequestData = (name, value) => {
    const { data } = this.state;
    data.fundingRequestData[name] = value;
    if (name === 'originalDueDate') {
      data.fundingRequestData.revisedDueDate = value;
    }

    this.autoSave();
    this.setState({ data });
    const model = R.clone(FundingRequestModel);
    // Save state to local Storage
    saveFormToSessionStorage(FUNDING_REQUEST_DATA_STORE, data.fundingRequestData, model, [
      'documents',
    ]);
  };

  onChangeBusinessCaseData = (name, value) => {
    const { data } = this.state;
    data.businessCaseData[name] = value;
    this.autoSave();
    this.setState({ data });
  };

  componentDidMount() {
    const fundingRequestData = JSON.parse(sessionStorage.getItem(FUNDING_REQUEST_DATA_STORE));

    this.subscribe(onFundingRequestError, this.onError);
    this.subscribe(onFundingRequestFormData, (state) => {
      this.setState({
        loading: false,
        clientCompany: R.clone(state.clientCompany),
        partnerCompany: R.clone(state.partnerCompany),
      });
    });
    this.subscribe(onFundingRequestCreate, () => {
      sessionStorage.removeItem(FUNDING_REQUEST_DATA_STORE);
      toast.success('Funding Request Successfully Created');
      this.props.history.goBack();
    });
    this.subscribe(OnInitiativeList, (state) => {
      const initiativesList = state.initiativesList.items.map((initiative) => ({
        ...initiative,
      }));

      this.setState({
        initiativesList,
      });
    });

    this.subscribe(OnRelatedItems, (state) => {
      const items = state.item.itemsRelated.items.map((item) => getItemByType(item));
      this.setState({
        items,
      });
    });

    this.subscribe(onFundingRequestAutoSave, (fundingRequest) => {
      sessionStorage.removeItem(FUNDING_REQUEST_DATA_STORE);
      this.setState({ savedAt: new Date(), autoSaving: false });
      this.savedFundingRequest = R.clone(fundingRequest);
    });

    this.subscribe(onFundingRequestAutoSaveError, () => {
      this.setState({ autoSaving: false });
    });

    // set fundingRequestData from sessionStorage
    if (fundingRequestData) {
      const { data } = this.state;
      data.fundingRequestData = fundingRequestData;
      this.setState({ data });
    }

    fetchFundingRequestFormDataAction();
    fetchInitiativeList('', 1, 1000);
    fetchCurrentAllianceMembersAction();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
    clearTimeout(this.autoSaveTimer);
    localStorage.removeItem(FUNDING_REQUEST_DOCUMENTS);
  }

  autoSave = () => {
    const waitTime = 10000; // Wait 10s
    clearTimeout(this.autoSaveTimer);
    this.autoSaveTimer = setTimeout(() => {
      this.setState({ autoSaving: true });
      const fundingRequestData = R.clone(this.state.data.fundingRequestData);
      const businessCaseData = R.clone(this.state.data.businessCaseData);
      const relatedItems = R.clone(this.state.data.relatedItems);
      const initiatives = R.clone(this.state.data.initiatives);
      autoSaveCreateFundingRequest(
        fundingRequestData,
        businessCaseData,
        relatedItems,
        initiatives,
        this.savedFundingRequest,
      );
    }, waitTime);
  };

  onSubmit = () => {
    this.setState({ loading: true }, () => {
      clearTimeout(this.autoSaveTimer);
      const fundingRequestData = R.clone(this.state.data.fundingRequestData);
      const businessCaseData = R.clone(this.state.data.businessCaseData);
      const relatedItems = R.clone(this.state.data.relatedItems);
      const initiatives = R.clone(this.state.data.initiatives);
      createFundingRequest(
        fundingRequestData,
        businessCaseData,
        relatedItems,
        initiatives,
        this.savedFundingRequest,
      );
    });
  };

  onFundingRequestStepChange = (nextStep) => {
    const { selectedAlliance } = sessionStore.getState(NEW_SESSION_EVENT);
    const fundingRequestData = R.clone(this.state.data.fundingRequestData);
    try {
      fundingRequestValidator(fundingRequestData, selectedAlliance);
    } catch (e) {
      return this.onError(e);
    }
    this.onScreen(nextStep);
  };

  onBusinessCaseStepChange = (nextStep) => {
    const businessCaseData = R.clone(this.state.data.businessCaseData);
    try {
      businessCaseValidator(businessCaseData);
    } catch (e) {
      return this.onError(e);
    }
    this.onScreen(nextStep);
  };

  onRelatedItemsStepChange = (step) => {
    const initiatives = R.clone(this.state.data.initiatives);
    try {
      initiativesItemValidator(initiatives);
      initiativesApproved(initiatives);
    } catch (e) {
      return this.onError(e);
    }
    this.onScreen(step);
  };

  onScreen = (step) => {
    this.setState({ step });
  };

  render() {
    const {
      initiativesList,
      step,
      loading,
      clientCompany,
      partnerCompany,
      data,
      savedAt,
      autoSaving,
    } = this.state;
    const { user, selectedAlliance } = sessionStore.getState(NEW_SESSION_EVENT);
    const { businessCaseData, relatedItems, initiatives, fundingRequestData } = data;
    const companyId = user.companyUserRelation.items[0].company.id;
    const currency = getCurrencyOnSession();
    let content = <Loader stretch />;
    let footer = <></>;

    if (!loading && step === 0) {
      content = (
        <div id={'c2gScreen'}>
          <FundingRequestForm
            data={fundingRequestData}
            onChange={this.onChangeFundingRequestData}
            clientCompany={clientCompany}
            partnerCompany={partnerCompany}
            myCompanyId={companyId}
            initiativesList={initiativesList}
            currency={currency}
            user={user}
            selectedAlliance={selectedAlliance}
          />
        </div>
      );
      footer = (
        <CardFooter>
          <ActionButton onClick={() => this.onFundingRequestStepChange(1)} text={'Next'} />
        </CardFooter>
      );
    }

    if (!loading && step === 1) {
      content = (
        <div id={'c2gScreen'}>
          <BusinessCaseForm
            data={businessCaseData}
            onChange={this.onChangeBusinessCaseData}
            currency={currency}
            showBusinessFields
            hideExpectCostAvoidance
          />
        </div>
      );
      footer = (
        <CardFooter>
          <ActionButton onClick={() => this.onBusinessCaseStepChange(2)} text={'Next'} />
          <TransparentButton onClick={() => this.onScreen(0)} text={'Previous'} />
        </CardFooter>
      );
    }

    if (!loading && step === 2) {
      content = (
        <div id={'c2gScreen'}>
          <RelatedItemForm
            relatedItems={relatedItems}
            initiatives={initiativesList}
            onChange={(key, value) => {
              this.autoSave();
              this.onChangeData(key, value);
            }}
            selectedInitiatives={initiatives}
          />
        </div>
      );

      footer = (
        <CardFooter>
          <ActionButton onClick={() => this.onRelatedItemsStepChange(3)} text={'Next'} />
          <TransparentButton onClick={() => this.onScreen(1)} text={'Previous'} />
        </CardFooter>
      );
    }

    if (!loading && step === 3) {
      const fundingRequestDataDetail = R.clone(fundingRequestData);
      fundingRequestDataDetail.documents = { items: fundingRequestDataDetail.documents };
      fundingRequestDataDetail.nextSteps = { items: fundingRequestDataDetail.nextSteps };

      content = (
        <div id={'c2gScreen'}>
          <FundingRequestDetailTable data={fundingRequestDataDetail} currency={currency} />
          <HorizontalLineText>Business Case</HorizontalLineText>
          <BusinessCaseDetailTable
            hideExpectedCostAvoidance
            data={businessCaseData}
            currency={currency}
          />
          <InitiativeListTable initiatives={initiatives} />
          <RelatedItemsDetailTable relatedItems={relatedItems} />
        </div>
      );
      footer = (
        <CardFooter>
          <ActionButton
            disabled={autoSaving}
            onClick={this.onSubmit}
            text={'Create Funding Request'}
          />
          <TransparentButton onClick={() => this.onScreen(2)} text={'Previous'} />
        </CardFooter>
      );
    }

    return (
      <React.Fragment>
        <Card.Header columns="4fr 2fr 4fr">
          <Grid.Layout
            columns="400px auto 400px"
            areas={[['left', 'center', 'right']]}
            style={{ width: '100%' }}>
            <Grid.Box area="left">
              <Row>
                <Heading type="h4" text="Create Funding Request" />
                <TextDateAgo from={savedAt} />
              </Row>
            </Grid.Box>
            <Grid.Box area="center">
              <FormSteps totalSteps={4} step={this.state.step} />
            </Grid.Box>
            <Grid.Box area="right" />
          </Grid.Layout>
          <ActionButtonClose onClick={() => this.props.history.goBack()} />
        </Card.Header>
        <CreateViewCardBody>
          <Grid.Layout columns="30% 70%" areas={[['left', 'right']]} style={{ width: '100%' }}>
            <Grid.Box area="left">
              <LeftProgressSection sections={SCREENS_FUNDING} currentScreen={step} />
            </Grid.Box>
            <BoxCard>
              <Grid.Box area="right">{content}</Grid.Box>
            </BoxCard>
          </Grid.Layout>
        </CreateViewCardBody>
        {footer}
      </React.Fragment>
    );
  }
}

FundingRequestCreateView.propTypes = {
  history: PropTypes.object.isRequired,
};

export default withRouter(FundingRequestCreateView);
