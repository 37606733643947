export default {
  id: null,
  backgroundSummary: '',
  visionMissionStrategy: '',
  marketAnalysis: '',
  salesMarketingStrategy: '',
  recommendedSolutions: [],
  recommendedSolution: '',
  expectedCosts: ['0'],
  expectedRevenues: ['0'],
  anticipatedCosts: ['0'],
  risks: '',
  document: null,
};

export const BUSINESS_CASE_DOCUMENT = 'businessCaseDocument';
