import React, { Fragment } from 'react';
import { Card, Heading, Loader, Grid } from '@8base/boost';
import sessionStore, { NEW_SESSION_EVENT } from '../../../shared/SessionStore';
import { withRouter } from 'react-router-dom';
import withAlliance from '../../../components/hoc/withAlliance';
import { ViewCardBody } from '../../../components/card/ViewCardBody';
import PropTypes from 'prop-types';
import { View } from '@cobuildlab/react-simple-state';
import { onErrorMixin } from '../../../shared/mixins';
import * as toast from '../../../components/toast/Toast';
import { fetchWorkQueueItems } from './work-queue-actions';
import { getUserActiveItems, filterActiveItemsByDueDate } from '../active-items-utils';
import HorizontalLineText from './components/HorizontalLineText';
import ActiveItem from '../ActiveItem';
import YesNoDialog from '../../../components/dialogs/YesNoDialog';
import SearchInput from '../../../components/inputs/SearchInput';
import { debounce } from '../../../shared/utils';
import * as R from 'ramda';
import { onWorkQueueError, onWorkQueueItemsEvent } from './work-queue-events';
import { OnIdeaError, OnIdeaCompleted } from '../../management/idea/idea-events';
import { OnIssueError, OnIssueCompleted } from '../../management/issue/issue-events';
import { OnRiskError, OnRiskCompleted } from '../../management/risk/risk-events';
import { OnActionError, OnActionCompleted } from '../../management/action/action-events';
import {
  onFundingRequestError,
  onFundingRequestCompleted,
} from '../../management/funding-request/funding-request-event';
import { nextStepCompletedEvent, nextStepErrorEvent } from '../../next-step/next-step-event';

/**
 * The filtered items to collapse on the view, other filtered items will remain
 * hidden.
 *
 * @type {Array}
 */
const FILTERED_ITEMS_TO_SHOW = [
  { stateName: 'overDueItems', text: 'Over Due' },
  { stateName: 'dueThisWeekItems', text: 'Due This Week' },
  { stateName: 'notDueItems', text: 'Not Due' },
  { stateName: 'completedDueItems', text: 'Completed' },
];

class WorkQueueView extends View {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      completeFunction: null,
      selectedItemToComplete: null,
      completeModalIsOpen: false,
      search: '',
      /*
      activeItems filtered by due date
       */
      overDueItems: [],
      overDueItemsIsOpen: true,
      dueThisWeekItems: [],
      dueThisWeekItemsIsOpen: false,
      notDueItems: [],
      notDueItemsIsOpen: false,
      completedDueItems: [],
      completedDueItemsIsOpen: false,
      /*
      activeItems filtered by due date
       */
    };
    this.onError = onErrorMixin.bind(this);
    this.user = sessionStore.getState(NEW_SESSION_EVENT).user;
    this.selectedAlliance = sessionStore.getState(NEW_SESSION_EVENT).selectedAlliance;
    this.searchWithDebounce = debounce(300, this.searchWithDebounce)();
  }

  componentDidMount() {
    this.subscribe(onWorkQueueError, this.onError);
    this.subscribe(nextStepErrorEvent, this.onError);
    this.subscribe(OnIdeaError, this.onError);
    this.subscribe(OnIssueError, this.onError);
    this.subscribe(OnRiskError, this.onError);
    this.subscribe(OnActionError, this.onError);
    this.subscribe(onFundingRequestError, this.onError);

    this.subscribe(nextStepCompletedEvent, () => {
      toast.success('Next Step Successfully Completed');
      fetchWorkQueueItems();
    });
    this.subscribe(OnIdeaCompleted, () => {
      toast.success('Idea Successfully Completed');
      fetchWorkQueueItems();
    });
    this.subscribe(OnIssueCompleted, () => {
      toast.success('Issue Successfully Completed');
      fetchWorkQueueItems();
    });
    this.subscribe(OnRiskCompleted, () => {
      toast.success('Risk Successfully Completed');
      fetchWorkQueueItems();
    });
    this.subscribe(OnActionCompleted, () => {
      toast.success('Action Successfully Completed');
      fetchWorkQueueItems();
    });
    this.subscribe(onFundingRequestCompleted, () => {
      toast.success('Funding Request Successfully Completed');
      fetchWorkQueueItems();
    });

    this.subscribe(onWorkQueueItemsEvent, (state) => {
      const { items } = state.itemsList;
      const { items: initiatives } = state.initiativesList;
      const { allActiveItems } = getUserActiveItems(items, initiatives);

      const {
        overDueItems,
        dueThisWeekItems,
        notDueItems,
        completedDueItems,
      } = filterActiveItemsByDueDate(allActiveItems);

      this.setState({
        loading: false,
        overDueItems,
        dueThisWeekItems,
        notDueItems,
        completedDueItems,
      });
    });

    fetchWorkQueueItems();
  }

  onSearchChange = (search) => {
    this.setState({ search });
    this.searchWithDebounce(search);
  };

  searchWithDebounce = (search) => {
    this.setState({ loading: true }, () => {
      fetchWorkQueueItems(search);
    });
  };

  toggleFilteredItems = (filteredItemsStateName) => {
    const stateName = `${filteredItemsStateName}IsOpen`;
    this.setState({ [stateName]: !this.state[stateName] });
  };

  onClickComplete = (completeFunction, selectedItemToComplete) => {
    this.setState({
      completeModalIsOpen: true,
      completeFunction,
      selectedItemToComplete,
    });
  };

  onYesComplete = () => {
    const completeFunction = R.clone(this.state.completeFunction);
    const selectedItemToComplete = R.clone(this.state.selectedItemToComplete);
    this.setState(
      {
        completeModalIsOpen: false,
        loading: true,
      },
      () => {
        completeFunction(selectedItemToComplete);
      },
    );
  };

  onCloseComplete = () => {
    this.setState({
      completeModalIsOpen: false,
    });
  };

  render() {
    const { loading, completeModalIsOpen, search } = this.state;

    let content = <Loader stretch />;

    if (!loading) {
      content = (
        <div id={'c2gScreen'}>
          {FILTERED_ITEMS_TO_SHOW.map(({ stateName, text }, i) => {
            const filteredItems = this.state[stateName];
            const active = this.state[`${stateName}IsOpen`];
            const count = filteredItems.length;

            return (
              <Fragment key={i}>
                <HorizontalLineText
                  onClick={() => this.toggleFilteredItems(stateName)}
                  text={text}
                  active={active}
                  count={count}
                />
                {/* show the filtered items if isOpen */}
                {active && count ? (
                  <Grid.Layout padding="xl">
                    {filteredItems.map((activeItem, i) => {
                      return (
                        <ActiveItem
                          item={activeItem}
                          key={i}
                          onClickComplete={this.onClickComplete}
                          showNextSteps={true}
                        />
                      );
                    })}
                  </Grid.Layout>
                ) : null}
              </Fragment>
            );
          })}
        </div>
      );
    }

    return (
      <Fragment>
        <Card.Header>
          <Grid.Layout
            columns="200px auto 200px"
            areas={[['left', 'center', 'right']]}
            style={{ width: '100%' }}>
            <Grid.Box area="left">
              <Heading type="h4" text={'My Work Queue'} />
            </Grid.Box>
            <Grid.Box justifySelf="center" area="center">
              <SearchInput className="search-input" value={search} onChange={this.onSearchChange} />
            </Grid.Box>
          </Grid.Layout>
        </Card.Header>
        <ViewCardBody>{content}</ViewCardBody>
        <YesNoDialog
          isOpen={completeModalIsOpen}
          onYes={this.onYesComplete}
          onNo={this.onCloseComplete}
          onClose={this.onCloseComplete}
          text={'Are you sure you want to Mark this Item as Completed?'}
          title={'Mark Completed'}
        />
      </Fragment>
    );
  }
}

WorkQueueView.propTypes = {
  history: PropTypes.object.isRequired,
};

export default withRouter(withAlliance(WorkQueueView));
