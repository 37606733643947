import React from 'react';
import PropTypes from 'prop-types';
import Moment from 'react-moment';
import styled from 'styled-components';
import { Tooltip } from '@8base/boost';

import { CurrencyFormat } from '../../../shared/components/CurrencyFormat';
import {
  ReportGroupSection,
  ReportGroupTitle,
  ReportTable,
  ReportTableTH as TH,
  ReportTableTR as TR,
  ReportTableTD as TD,
  ReportTableFooterTD as FooterTD,
  ReportTotalSection,
  TotalSectionAmount,
  TotalSectionTitle,
  ReportLink,
  ReportTableHeader,
} from '../components';
import { ownerToString } from '../../../shared/utils';
import { getFullUserName } from '../utils';
import { cropDealDescription } from './active-sales-pipeline-utils';

const DealNameWrap = styled.div`
  padding-left: 26px;
  width: 132px;
`;

const AmountWrap = styled.div`
  padding-right: 24px;
  width: 112px;
  text-align: right;
  white-space: nowrap;
`;

const CustomerNameWrap = styled.div`
  padding-left: 24px;
  width: 157px;
`;

const CloseDateWrap = styled.div`
  padding-left: 23px;
  width: 116px;
`;

const DealDescriptionWrap = styled.div`
  padding-left: 16px;
  min-width: 161px;
`;

const AllianceDealOwnerWrap = styled.div`
  padding-left: 25px;
  width: 185px;
`;

const SalesAccounRepWrap = styled.div`
  padding-left: 14px;
  padding-right: 15px;
  width: 163px;
`;

const StageWrap = styled.div`
  padding-right: 25px;
  text-align: right;
  width: 85px;
`;

class ActiveSalesPipelineTable extends React.PureComponent {
  render() {
    const { dealsList, groupBy, isTotals } = this.props;

    if (isTotals) {
      if (groupBy.length > 0) {
        return dealsList.map((dealsGroup) => this.renderTotalTable(dealsGroup[1], dealsGroup[0]));
      }
      return null;
    }

    if (groupBy.length === 0) {
      return this.renderTableWithoutGrouping(dealsList);
    }

    return dealsList.map((dealsGroup) => this.renderTable(dealsGroup[1], dealsGroup[0]));
  }

  renderTableWithoutGrouping = (dealsList) => {
    return (
      <ReportTable>
        {this.renderTableHeader()}
        <tbody>
          {dealsList.map((dealData) => {
            return this.renderTableRow(dealData);
          })}
        </tbody>
      </ReportTable>
    );
  };

  renderTotalTable = (dealsList, groupValue) => {
    const { groupBy, fieldsDict, currency } = this.props;
    const totalAmount = dealsList.reduce((sum, currentDeal) => {
      return sum + Number(currentDeal.amount);
    }, 0);
    return (
      <ReportTotalSection>
        <TotalSectionTitle>
          {`${fieldsDict[groupBy[0]].displayName.toUpperCase()}: `}
          {this.renderGroupValue(groupBy[0], groupValue, dealsList)}
        </TotalSectionTitle>
        <TotalSectionAmount>
          <CurrencyFormat {...currency} value={totalAmount} displayType="text" decimalScale={2} />
        </TotalSectionAmount>
      </ReportTotalSection>
    );
  };

  renderTableRow = (dealData) => {
    const { currency, groupBy } = this.props;
    const {
      amount,
      closeDate,
      customerName,
      description,
      id: dealId,
      name,
      owner,
      salesPerson,
      stage,
    } = dealData;
    const dealLinkUrl = `/management/deal/${dealId}`;
    return (
      <TR key={dealId}>
        <TD>
          <DealNameWrap>
            <ReportLink href={dealLinkUrl} target="blank">
              {name}
            </ReportLink>
          </DealNameWrap>
        </TD>
        <TD rightBorder>
          <AmountWrap>
            <CurrencyFormat {...currency} value={amount} displayType="text" decimalScale={2} />
          </AmountWrap>
        </TD>
        {groupBy[0] !== 'customerName' ? (
          <TD>
            <CustomerNameWrap>{customerName}</CustomerNameWrap>
          </TD>
        ) : null}
        {groupBy[0] !== 'closeDate' ? (
          <TD>
            <CloseDateWrap>
              {closeDate && <Moment format="MM/DD/YYYY">{new Date(closeDate)}</Moment>}
            </CloseDateWrap>
          </TD>
        ) : null}
        <TD>
          <DealDescriptionWrap>
            {description && description.length > 35 ? (
              <Tooltip message={description}>{cropDealDescription(description, 35)}</Tooltip>
            ) : (
              description
            )}
          </DealDescriptionWrap>
        </TD>
        {groupBy[0] !== 'owner.id' ? (
          <TD>
            <AllianceDealOwnerWrap>{ownerToString(owner)}</AllianceDealOwnerWrap>
          </TD>
        ) : null}
        {groupBy[0] !== 'salesPerson' ? (
          <TD>
            <SalesAccounRepWrap>{salesPerson}</SalesAccounRepWrap>
          </TD>
        ) : null}
        {groupBy[0] !== 'stage' ? (
          <TD>
            <StageWrap>{stage}</StageWrap>
          </TD>
        ) : null}
      </TR>
    );
  };

  renderTableHeader = () => {
    const { groupBy } = this.props;
    return (
      <ReportTableHeader fixed={!groupBy || groupBy.length === 0}>
        <TR>
          <TH>
            <DealNameWrap>Deal name</DealNameWrap>
          </TH>
          <TH rightBorder>
            <AmountWrap>Amount</AmountWrap>
          </TH>
          {groupBy[0] !== 'customerName' ? (
            <TH>
              <CustomerNameWrap>Customer name</CustomerNameWrap>
            </TH>
          ) : null}
          {groupBy[0] !== 'closeDate' ? (
            <TH>
              <CloseDateWrap>Close date</CloseDateWrap>
            </TH>
          ) : null}
          <TH>
            <DealDescriptionWrap>Deal description</DealDescriptionWrap>
          </TH>
          {groupBy[0] !== 'owner.id' ? (
            <TH>
              <AllianceDealOwnerWrap>Alliance deal owner</AllianceDealOwnerWrap>
            </TH>
          ) : null}
          {groupBy[0] !== 'salesPerson' ? (
            <TH>
              <SalesAccounRepWrap>Sales account rep</SalesAccounRepWrap>
            </TH>
          ) : null}
          {groupBy[0] !== 'stage' ? (
            <TH>
              <StageWrap>Stage</StageWrap>
            </TH>
          ) : null}
        </TR>
      </ReportTableHeader>
    );
  };

  renderTableFooter = (totalAmont) => {
    const { currency } = this.props;
    return (
      <tfoot>
        <tr>
          <FooterTD>
            <DealNameWrap>Total</DealNameWrap>
          </FooterTD>
          <FooterTD total rightBorder>
            <AmountWrap>
              <CurrencyFormat
                {...currency}
                value={totalAmont}
                displayType="text"
                decimalScale={2}
              />
            </AmountWrap>
          </FooterTD>
          <FooterTD colSpan={6} />
        </tr>
      </tfoot>
    );
  };

  renderGroupValue = (groupField, groupValue, dealsList) => {
    switch (groupField) {
    case 'closeDate':
      return groupValue !== 'null' ? (
        <Moment format="MM/DD/YYYY">{new Date(groupValue)}</Moment>
      ) : (
        'No date'
      );
    case 'owner.id': {
      return dealsList[0].owner ? getFullUserName(dealsList[0].owner) : 'No name';
    }
    default:
      return groupValue ? groupValue : '';
    }
  };

  renderTable = (dealsList, groupValue) => {
    const { groupBy, fieldsDict } = this.props;
    let totalAmount = 0;
    return (
      <ReportGroupSection>
        {groupValue ? (
          <ReportGroupTitle>
            {`${fieldsDict[groupBy[0]].displayName}: `}
            {this.renderGroupValue(groupBy[0], groupValue, dealsList)}
          </ReportGroupTitle>
        ) : null}
        <ReportTable borderTop>
          {this.renderTableHeader()}
          <tbody>
            {dealsList.map((dealData) => {
              totalAmount += Number(dealData.amount);
              return this.renderTableRow(dealData);
            })}
          </tbody>
          {this.renderTableFooter(totalAmount)}
        </ReportTable>
      </ReportGroupSection>
    );
  };
}

ActiveSalesPipelineTable.propTypes = {
  groupBy: PropTypes.array.isRequired,
  fieldsDict: PropTypes.object.isRequired,
  currency: PropTypes.object.isRequired,
  dealsList: PropTypes.array.isRequired,
  isTotals: PropTypes.bool.isRequired,
};

export { ActiveSalesPipelineTable };
