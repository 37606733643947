import React from 'react';
import PropTypes from 'prop-types';
import { View } from '@cobuildlab/react-simple-state';
import { withRouter } from 'react-router-dom';
import * as toast from '../../../components/toast/Toast';
import * as R from 'ramda';
import { isAllianceActive, isCompanyActive, isCompanyPartner } from '../../procurer/procurer-utils';
import { fetchCompanyListAction } from './company-actions';
import { onErrorMixin } from '../../../shared/mixins';
import { COMPANY_PROCUREMENT } from '../../../shared/roles';
import { OnCompanyList, OnCompanyError } from '../company-management/company-events';
import { OnNewSession } from '../../../shared/session-events';

class CompanyExpiredView extends View {
  constructor(props) {
    super(props);
    this.state = {
      procurerUser: null,
    };

    this.onError = onErrorMixin.bind(this);
    this.user = R.clone(OnNewSession.get().user);
  }

  componentDidMount() {
    this.subscribe(OnCompanyError, this.onError);

    this.subscribe(OnCompanyList, (state) => {
      const selectedAlliance = R.clone(OnNewSession.get().selectedAlliance);
      const { companiesList } = state;
      const companyUserList = companiesList.items.length
        ? companiesList.items[0].companyUserRelation
        : null;

      const procurerUser = companyUserList
        ? companyUserList.items.find((user) => user.role.name === COMPANY_PROCUREMENT)
        : null;
      const { companyUserRelation } = this.user;
      const company =
        companyUserRelation.count !== 0 ? R.clone(companyUserRelation.items[0].company) : null;
      let message = procurerUser
        ? `Alliance disabled. Please contact ${procurerUser.user.firstName} ${procurerUser.user.lastName} ${procurerUser.user.email}`
        : `It appears that your Company doesn't have a procurement role. Contact Support for more information.`;

      if (
        !isCompanyActive(company) &&
        !isAllianceActive(selectedAlliance) &&
        !isCompanyPartner(company, selectedAlliance)
      )
        toast.error(message, 1800000);

      if (isCompanyPartner(company, selectedAlliance)) this.props.history.push('/dashboard');

      this.setState({
        procurerUser,
      });
    });

    fetchCompanyListAction();
  }

  render() {
    return <div id={'c2gScreen'}></div>;
  }
}

CompanyExpiredView.propTypes = {
  history: PropTypes.object.isRequired,
};

export default withRouter(CompanyExpiredView);
