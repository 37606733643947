import React from 'react';
import { Input } from '@8base/boost';
import PropTypes from 'prop-types';
import searchIcon from '../../images/icons/Search-dark.svg';
const SearchInput = ({ onChange, value, ...rest }) => {
  return (
    <Input
      style={{
        color: '#b3b3b3',
      }}
      rightIcon={<img src={searchIcon} alt="" />}
      value={value}
      onChange={onChange}
      name="search"
      placeholder="Search.."
      stretch={true}
      {...rest}
    />
  );
};

SearchInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
};

SearchInput.defaultProps = {
  value: '',
};

export default SearchInput;
