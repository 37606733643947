import {
  FUNDING_REQUEST_COMPLETED,
  FUNDING_REQUEST_APPROVED,
  FUNDING_REQUEST_SUBMITTED_FOR_APPROVAL,
  ALLIANCE_APPROVED,
} from '../../../shared/status';
import {
  isUserAdminOrSERInAlliance,
  isUserCreatorInAlliance,
} from '../../../shared/alliance-utils';
import { initiativesApprovedValidator } from '../initiative/initiative-validators';
import { isUserPendingFundingRequestApproval } from './funding-request-utils';
import { isAllianceCompleted } from '@cobuildlab/collabtogrow-permissions';

/**
 * Checks if a User can Edit  a funding request.
 *
 * @param {object} user - User.
 * @param {object} fundingRequest - Funding Request.
 * @param {object} alliance - Alliance.
 * @returns {boolean} Validate Options.
 */
export const canEditFundingRequest = (user, fundingRequest, alliance) => {
  if (isAllianceCompleted(alliance)) return false;
  if (fundingRequest.status === FUNDING_REQUEST_COMPLETED) return false;
  if (isUserAdminOrSERInAlliance(user, alliance)) return true;

  if (fundingRequest.status === FUNDING_REQUEST_APPROVED) return false;
  if (fundingRequest.status === FUNDING_REQUEST_SUBMITTED_FOR_APPROVAL) return false;

  if (isUserCreatorInAlliance(user, alliance)) return true;

  return false;
};

/**
 * Checks if a User can Approve  a funding request.
 *
 * @param {object} user - User.
 * @param {object} fundingRequest - Funding Request.
 * @param {object} alliance - Alliance.
 * @returns {boolean} Validate Options.
 */
export const canApproveFundingRequest = (user, fundingRequest, alliance) => {
  if (isAllianceCompleted(alliance)) return false;

  if (fundingRequest.status !== FUNDING_REQUEST_SUBMITTED_FOR_APPROVAL) return false;

  if (!isUserAdminOrSERInAlliance(user, alliance)) return false;

  return isUserPendingFundingRequestApproval(user, alliance, fundingRequest);
};

/**
 * Checks if a User can Reject  a funding request.
 *
 * @param {object} user - User.
 * @param {object} fundingRequest - Funding Request.
 * @param {object} alliance - Alliance.
 * @returns {boolean} Validate Options.
 */
export const canRejectFundingRequest = (user, fundingRequest, alliance) => {
  if (isAllianceCompleted(alliance)) return false;

  if (fundingRequest.status !== FUNDING_REQUEST_SUBMITTED_FOR_APPROVAL) return false;

  if (!isUserAdminOrSERInAlliance(user, alliance)) return false;

  return isUserPendingFundingRequestApproval(user, alliance, fundingRequest);
};

/**
 * Checks if a User can Complete a funding request.
 *
 * @param {object} user - User.
 * @param {object} fundingRequest - Funding Request.
 * @param {object} alliance - Alliance.
 * @returns {boolean} Validate Options.
 */
export const canCompletedFundingRequest = (user, fundingRequest, alliance) => {
  if (isAllianceCompleted(alliance)) return false;

  if (fundingRequest.status === FUNDING_REQUEST_COMPLETED) return false;

  if (fundingRequest.assignedTo && fundingRequest.assignedTo.id === user.id) return true;

  return isUserAdminOrSERInAlliance(user, alliance);
};

/**
 * Checks if a User can Submit For approval a funding request.
 *
 * @param {object} user - User.
 * @param {object} fundingRequest - Funding Request.
 * @param {object} alliance - Alliance.
 * @returns {boolean} Validate Options.
 */
export const canSubmitForApprovalFundingRequest = (user, fundingRequest, alliance) => {
  if (isAllianceCompleted(alliance)) return false;

  if (alliance.status !== ALLIANCE_APPROVED) return false;
  if (
    fundingRequest.status === FUNDING_REQUEST_APPROVED ||
    fundingRequest.status === FUNDING_REQUEST_COMPLETED
  )
    return false;

  try {
    initiativesApprovedValidator(fundingRequest.initiatives);
  } catch (e) {
    return false;
  }

  return isUserAdminOrSERInAlliance(user, alliance);
};

/**
 * Checks if a User can Restore a funding request.
 *
 * @param {object} user - User.
 * @param {object} fundingRequest - Funding Request.
 * @param {object} alliance - Alliance.
 * @returns {boolean} Validate Options.
 */
export const canRestoreFundingRequest = (user, fundingRequest, alliance) => {
  if (isAllianceCompleted(alliance)) return false;

  if (fundingRequest.status !== FUNDING_REQUEST_COMPLETED) return false;

  if (fundingRequest.assignedTo && fundingRequest.assignedTo.id === user.id) return true;

  return isUserAdminOrSERInAlliance(user, alliance);
};
