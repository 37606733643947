import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import styled from '@emotion/styled';
import { Button, ButtonGroup, Grid } from '@8base/boost';
import UserDropdown from './UserDropdown.js';
import logo from '../images/logos/collabtogrow_main_logo.svg';
import AllianceDropdown from '../shared/components/AllianceDropdown';
import { View } from '@cobuildlab/react-simple-state';
import { openActiveItems } from '../modules/active-items/active-items-actions';
import NOTIFICATIONS_ICON from '../images/icons/notifications.svg';
import CHAT_ICON from '../images/icons/Chat.svg';
import CAMERA_ICON from '../images/icons/camera.svg';
import QUESTION_MARK_ICON from '../images/icons/question-mark.svg';
import HeaderIcon from './img/HeaderIcon';
import CompanyHeaderButton from './buttons/CompanyHeaderButton';
import { openNotificationsSideBar } from '../modules/notifications/notifications-actions';
import { toggleChatSideBar } from '../modules/chat/chat-actions';
import {
  isAllianceActive,
  isCompanyActive,
  isCompanyPartner,
  isProcurerOnly,
} from '../modules/procurer/procurer-utils';
import * as R from 'ramda';
import * as toast from '../components/toast/Toast';
import { COMPANY_PROCUREMENT } from '../shared/roles';
import { updateUser } from '../modules/wizard/wizard.actions';
import { fetchAlliancesBigList } from '../modules/settings/alliance-management/alliance-actions';
import { OnNewSession, OnSessionAlliances } from '../shared/session-events';
import { OnNotification } from '../modules/notifications/notifications-events';
import { OnChatUnReads } from '../modules/chat/chat-events';

const HeaderTag = styled(Grid.Layout)({
  height: '6rem',
  padding: '0 2rem',
  backgroundColor: '#fff',
  borderBottom: '1px solid #D0D7DD',
});

const HeaderLogo = styled('img')({
  width: '175px',
});

const IconsContainer = styled('div')({
  marginRight: 24,
  minWidth: 160,
});

class _AppHeader extends View {
  constructor(props) {
    super(props);

    const alliances = OnSessionAlliances.get();

    this.state = {
      session: OnNewSession.get(),
      alliances: alliances || [],
      alliancesLoading: !alliances,
      loading: false,
      hasNotifications: false,
      hasUnreadsChannels: false,
    };
  }

  componentDidMount() {
    this.subscribe(OnNewSession, (session) => {
      this.setState({ loading: false, session: R.clone(session) });
    });

    this.subscribe(OnSessionAlliances, (alliances) => {
      this.setState({
        alliancesLoading: false,
        alliances: alliances.map((alliance) => ({ ...alliance })),
      });
    });

    this.subscribe(OnNotification, (notifications) =>
      this.setState({ hasNotifications: notifications.length > 0 }),
    );

    this.subscribe(OnChatUnReads, (unreadsChannelsCount) => {
      this.setState({
        hasUnreadsChannels: unreadsChannelsCount > 0,
      });
    });

    if (!this.state.alliances.length) {
      // Wait 0.1s to avoid apollo batching this query with other queries.
      setTimeout(() => fetchAlliancesBigList(), 100);
    }
  }

  onSelect = async (alliance) => {
    const isActive = isAllianceActive(alliance);

    if (!isActive) {
      const procurerUser = alliance.clientCompany.companyUserRelation.items.find(
        (user) => user.role.name === COMPANY_PROCUREMENT,
      );
      const message = procurerUser
        ? `Alliance disabled. Please contact ${procurerUser.user.firstName} ${procurerUser.user.lastName} ${procurerUser.user.email}`
        : `It appears that your Company doesn't have a procurement role. Contact Support for more information.`;
      toast.error(message);
    } else {
      this.setState({ loading: true }, async () => {
        await updateUser({
          selectedAllianceId: alliance.id,
          selectedAlliance: {
            connect: {
              id: alliance.id,
            },
          },
        });
        window.location.reload();
      });
    }
  };

  onActiveItems = () => openActiveItems();
  onClickNotifications = () => openNotificationsSideBar();
  onClickChat = () => toggleChatSideBar();

  render() {
    const { loading, alliancesLoading } = this.state;
    const { hasNotifications, hasUnreadsChannels, session, alliances } = this.state;
    const { user, selectedAlliance } = session;
    const { companyUserRelation } = user;
    const company = companyUserRelation.count !== 0 ? companyUserRelation.items[0].company : null;
    const companyActive = isCompanyActive(company);

    // Top Bar
    let topBarIcons = null;
    if (!isProcurerOnly() && (companyActive || isCompanyPartner(company, selectedAlliance)))
      topBarIcons = (
        <IconsContainer>
          {company && (
            <HeaderIcon
              url={CHAT_ICON}
              onClick={this.onClickChat}
              hasNotifications={hasUnreadsChannels}
            />
          )}
          {selectedAlliance && (
            <HeaderIcon
              url={NOTIFICATIONS_ICON}
              onClick={this.onClickNotifications}
              hasNotifications={hasNotifications}
            />
          )}
          {selectedAlliance && <HeaderIcon url={CAMERA_ICON} onClick={this.props.screenOnClick} />}
          {selectedAlliance && (
            <a
              href="https://collabtogrow.com/product-support"
              rel="noopener noreferrer"
              target="_blank">
              <HeaderIcon url={QUESTION_MARK_ICON} />
            </a>
          )}
        </IconsContainer>
      );

    const content = (
      <AllianceDropdown
        loading={loading || alliancesLoading}
        items={alliances}
        selectedAlliance={selectedAlliance}
        onSelect={this.onSelect}
      />
    );

    return (
      <Grid.Box
        style={{
          position: 'fixed',
          width: '100%',
          zIndex: '2000',
        }}
        area="header">
        <HeaderTag columns="minmax(190px,400px) minmax(300px, 1fr) minmax(400px,auto)" gap="sm">
          <Grid.Box justifyContent="center" justifySelf="start">
            <Link to="/">
              <HeaderLogo src={logo} alt="CollabToGrow logo" />
            </Link>
          </Grid.Box>
          <Grid.Box justifyContent="center" alignItems="center">
            {content}
          </Grid.Box>
          <Grid.Box direction="row" alignItems="center" justifySelf="end">
            {topBarIcons}
            <ButtonGroup>
              <CompanyHeaderButton company={company} />
              <Button
                style={{ padding: '0 10px', boxShadow: 'none' }}
                color="neutral"
                variant="outlined">
                <UserDropdown />
              </Button>
            </ButtonGroup>
          </Grid.Box>
        </HeaderTag>
      </Grid.Box>
    );
  }
}

const AppHeader = withRouter(_AppHeader);
export { AppHeader };
