import createDecorator from 'final-form-calculate';

const spyItemType = createDecorator({
  field: 'itemType',
  isEqual: (prev, current) => {
    if (current) {
      return prev === current;
    }
    return true;
  },
  updates: {
    fieldsList: () => [],
    filters: () => [],
  },
});

export { spyItemType };
