import React from 'react';
import { Text, Progress } from '@8base/boost';
import PropTypes from 'prop-types';
import {
  getKPIColorThemeName,
  getKPIProgress,
  getKPIProgressText,
} from '../balanced-scorecard-utils';

const KPIProgress = ({ text, kpiTarget, current, currency, kpiType }) => {
  if (!kpiTarget) return null;

  const progress = getKPIProgress(current, kpiTarget, kpiType);
  const progressValue = progress < 100 ? progress : 100;
  const color = getKPIColorThemeName(progress);
  const progressText = getKPIProgressText(currency, kpiType, current, kpiTarget);

  return (
    <>
      <Text>{text}</Text>
      <Text disabled>{` ${progress}%`}</Text>
      <Progress color={color} size="sm" valueText={progressText} value={progressValue} />
    </>
  );
};

KPIProgress.defaultProps = {
  currency: null,
};

KPIProgress.propTypes = {
  current: PropTypes.number.isRequired,
  kpiTarget: PropTypes.number.isRequired,
  kpiType: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  currency: PropTypes.object,
};

export { KPIProgress };
