import React from 'react';
import { Text } from '@8base/boost';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';

const StyledHeader = styled(Text)`
  fontsize: 13px;
  color: #a5afb6;
  font-weight: 600 !important;
  letter-spacing: 1.3px;
  line-height: 20px;
  height: 19px;
  font-family: Poppins;
`;

const GrayBoldH2 = ({ text }) => {
  return (
    <>
      <StyledHeader text={text} />
    </>
  );
};

GrayBoldH2.propTypes = {
  text: PropTypes.string.isRequired,
};

export { GrayBoldH2 };
