import gql from 'graphql-tag';

/**
 * Create.
 */
export const STAGE_MAPPING_CREATE_MUTATION = gql`
  mutation($data: DealStageCreateInput!) {
    dealStageCreate(data: $data) {
      id
    }
  }
`;

export const STAGE_MAPPING_UPDATE_MUTATION = gql`
  mutation($data: DealStageUpdateInput!) {
    dealStageUpdate(data: $data) {
      id
    }
  }
`;

export const STAGE_MAPPING_VALUES_CREATE_MUTATION = gql`
  mutation($data: DealStageValueCreateInput!) {
    dealStageValueCreate(data: $data) {
      id
    }
  }
`;

export const STAGE_MAPPING_DELETE_MUTATION = gql`
  mutation($data: DealStageValueDeleteInput!) {
    dealStageValueDelete(data: $data) {
      success
    }
  }
`;
/**
 *
 */
export const DEAL_STAGE_LIST_QUERY = gql`
  query {
    dealStagesList {
      items {
        id
        description
        exitCriteria
        winProbability
        name
        entranceCriteria
      }
    }
  }
`;
/**
 *
 */
export const DEAL_STAGE_VALUES_LIST_QUERY = gql`
  query($filter: DealStageValueFilter) {
    dealStageValuesList(filter: $filter) {
      items {
        value
        id
        dealStageDealStageValuesRelation {
          id
          name
        }
      }
    }
  }
`;

export const DEAL_STAGES_LIST_QUERY = gql`
  query {
    dealStagesList {
      items {
        name
      }
    }
  }
`;
