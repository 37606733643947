import { DateTime } from 'luxon';
import Excel from 'exceljs';
import * as R from 'ramda';
import { currentDay } from '../../../shared/date-utils.js';
import sessionStore, { NEW_SESSION_EVENT } from '../../../shared/SessionStore';
import { GROUP_TITLE } from './sales-pipeline-trends-model';
import { getFullNameWithEmail } from '../utils';
import { exportToFile } from '../../../shared/utils';
import { DEAL_STAGES_MAP } from '../../management/deal/deal-model';
import {
  MONTHS,
  BORDER_STYLE,
  FILL_STYLE,
  TITLE_CELL_STYLE,
  HORIZONTAL_ALIGNMENT,
} from '../reports-model';
import BigInt from 'big-integer';

const sortByStagesWinProbability = (a, b) => {
  if (DEAL_STAGES_MAP[a[0].stage] < DEAL_STAGES_MAP[b[0].stage]) return -1;
  if (DEAL_STAGES_MAP[a[0].stage] > DEAL_STAGES_MAP[b[0].stage]) return 1;
};

const defaultFilters = [{ fieldId: 'id-2', rule: 'thisYear' }];

const generateSalesPipelineTrendsFilters = (filters) => {
  const _filters = filters && filters.length ? filters : R.clone(defaultFilters);

  return _filters.reduce((accum, filter) => {
    const { rule, cond } = filter;
    switch (rule) {
    case 'thisYear': {
      return [
        ...accum,
        {
          year: {
            equals: new Date().getFullYear(),
          },
        },
      ];
    }
    case 'year': {
      return [
        ...accum,
        {
          year: {
            equals: Number(cond),
          },
        },
      ];
    }
    case 'quarter': {
      const [year, quarterNumber] = cond;
      const monthFIlter = [
        {
          gte: 1,
          lte: 3,
        },
        {
          gte: 4,
          lte: 6,
        },
        {
          gte: 7,
          lte: 9,
        },
        {
          gte: 10,
          lte: 12,
        },
      ][quarterNumber - 1];
      return [
        ...accum,
        {
          AND: [
            {
              year: {
                equals: Number(year),
              },
              month: {
                gte: monthFIlter.gte,
              },
            },
            {
              year: {
                equals: Number(year),
              },
              month: {
                lte: monthFIlter.lte,
              },
            },
          ],
        },
      ];
    }
    case 'lastMonth': {
      const newDate = currentDay.minus({ month: 1 });
      return [
        ...accum,
        {
          year: {
            equals: newDate.year,
          },
          month: {
            equals: newDate.month,
          },
        },
      ];
    }
    case 'rollingSixMonth': {
      const startDate = currentDay.minus({ month: 5 });
      return [
        ...accum,
        {
          AND: [
            {
              year: {
                equals: startDate.year,
              },
              month: {
                gte: startDate.month,
              },
            },
            {
              year: {
                equals: currentDay.year,
              },
              month: {
                lte: currentDay.month,
              },
            },
          ],
        },
      ];
    }
    case 'month': {
      const newDate = DateTime.fromSQL(cond);
      return [
        ...accum,
        {
          year: {
            equals: newDate.year,
          },
          month: {
            equals: newDate.month,
          },
        },
      ];
    }
    case 'monthSet': {
      const startDate = DateTime.fromSQL(cond[0]);
      const endDate = DateTime.fromSQL(cond[1]);

      return [
        ...accum,
        {
          AND: [
            {
              year: {
                equals: startDate.year,
              },
              month: {
                gte: startDate.month,
              },
            },
            {
              year: {
                equals: endDate.year,
              },
              month: {
                lte: endDate.month,
              },
            },
          ],
        },
      ];
    }
    case 'ytd': {
      const startDate = currentDay.minus({ month: 11 });
      return [
        ...accum,
        {
          AND: [
            {
              year: {
                equals: startDate.year,
              },
              month: {
                gte: startDate.month,
              },
            },
            {
              year: {
                equals: currentDay.year,
              },
              month: {
                lte: currentDay.month,
              },
            },
          ],
        },
      ];
    }
    default: {
      return accum;
    }
    }
  }, []);
};

const createSnapshotList = (monthlySnapshots, groups, requestMonthsInfo) => {
  const { monthsCount, monthNumberDict } = requestMonthsInfo;

  const {
    selectedAlliance: { partnerCompany, clientCompany },
  } = sessionStore.getState(NEW_SESSION_EVENT);

  const groupKey = groups[1];

  const snapshotData = {
    clientCompanyMonthSnapshots: [],
    clientCompanyMonthTotalAmounts: [],
    partnerCompanyMonthSnapshots: [],
    partnerCompanyMonthTotalAmounts: [],
  };

  Object.keys(monthlySnapshots).forEach((companyId) => {
    // add monthNumber to each snapshot
    const _companyMonthSnapshots = monthlySnapshots[companyId][groupKey].map((groupList) => {
      return groupList.map((snapshot) => ({
        ...snapshot,
        monthNumber: monthNumberDict[snapshot.year][snapshot.month],
      }));
    });

    // fill missing months
    const companyMonthSnapshots = fillMonths(_companyMonthSnapshots, monthsCount, groupKey);

    // order by stages
    companyMonthSnapshots.sort(sortByStagesWinProbability);

    // month Total Amounts
    const companyMonthTotalAmounts = companyMonthSnapshots.length
      ? new Array(companyMonthSnapshots[0].length).fill(0)
      : [];
    companyMonthSnapshots.forEach((stageMonths) => {
      stageMonths.forEach(({ amount }, i) => {
        companyMonthTotalAmounts[i] = BigInt(companyMonthTotalAmounts[i])
          .add(amount)
          .toString();
      });
    });

    if (clientCompany.id === companyId) {
      snapshotData.clientCompanyMonthSnapshots = companyMonthSnapshots;
      snapshotData.clientCompanyMonthTotalAmounts = companyMonthTotalAmounts;
    } else if (partnerCompany.id === companyId) {
      snapshotData.partnerCompanyMonthSnapshots = companyMonthSnapshots;
      snapshotData.partnerCompanyMonthTotalAmounts = companyMonthTotalAmounts;
    }
  });

  return snapshotData;
};

/**
 * Fill missing dealMonthlySnapshots months.
 *
 * @param {Array} snapshotsGroupByStage - Snapthots array of arrays groupBy stage.
 * @param {number} monthsCount - Number of months in the requested range, by default = 12.
 * @param {object} groupKey - GroupKey.
 * @returns {Array} Array of arrays with filled missing months.
 */
const fillMonths = (snapshotsGroupByStage, monthsCount = 12, groupKey) => {
  // fill with arrays of arrays to set snapshotsGroupByStage
  const snapshotsToFill = Array.from({ length: snapshotsGroupByStage.length }, (e) => []);

  snapshotsGroupByStage.forEach((months, i) => {
    const monthToFill = R.clone(months[0]);
    delete monthToFill.month; // month number of the year
    delete monthToFill.year;
    delete monthToFill.monthNumber; // month number of requested range
    delete monthToFill.id;
    monthToFill.amount = '0';

    if (groupKey === 'owner.id') {
      monthToFill[groupKey] = `${monthToFill.owner.firstName} ${monthToFill.owner.lastName}`;
    }

    if (!monthToFill[groupKey]) {
      monthToFill[groupKey] = 'No name';
    }

    // fill will snapshotData
    snapshotsToFill[i] = Array.from({ length: monthsCount }, (e) => R.clone(monthToFill));
    // set amount
    months.forEach(({ monthNumber, amount }) => {
      snapshotsToFill[i][monthNumber - 1].amount = amount;
    });
  });

  return snapshotsToFill;
};

const getRowTitle = (groupKey, rowData) => {
  const path = groupKey.split('.');
  if (groupKey === 'owner.id') {
    return getFullNameWithEmail(R.path(['0', 'owner'], rowData));
  }
  return R.pathOr('No name', ['0', ...path], rowData);
};

const fillMonthlySnapshotsList = (groupList, monthNumberDict) => {
  const snapshotsDict = groupList.reduce((accum, snapshot) => {
    const monthNumber = monthNumberDict[snapshot.year][snapshot.month];
    accum[monthNumber] = snapshot;
    return accum;
  }, {});

  const resultList = Object.keys(monthNumberDict).reduce((yearsList, currentYear) => {
    Object.keys(monthNumberDict[currentYear]).forEach((currentMonth) => {
      const monthNumber = monthNumberDict[currentYear][currentMonth];
      const currentMonthData = R.path([monthNumber], snapshotsDict);
      if (currentMonthData) {
        yearsList[monthNumber - 1] = { ...currentMonthData, monthNumber };
      } else {
        yearsList[monthNumber - 1] = {
          year: currentYear,
          month: currentMonth,
          monthNumber,
          amount: '0',
        };
      }
    });
    return yearsList;
  }, []);

  return resultList;
};

const generateSalesPipelineTrendsTables = (monthlySnapshots, groupBy, requestMonthsInfo) => {
  const { monthsCount, monthNumberDict } = requestMonthsInfo;

  const groupKey = groupBy[1];

  const allTables = Object.keys(monthlySnapshots).map((companyId) => {
    // this row is the firt row in the table and it contains only company name
    const titleRow = new Array(monthsCount + 2).fill('');

    // create headerRow
    const headerRow = new Array(monthsCount + 2).fill('');

    // create totalRow
    let totalRow = new Array(monthsCount + 2).fill(0);
    totalRow[0] = 'TOTAL';

    // create groupRows
    const groupRows = monthlySnapshots[companyId][groupKey].map((groupList, groupIndex) => {
      // set values to titleRow and headerRow
      if (groupIndex === 0) {
        // set companyName to titleRow
        const companyName = R.path(['0', 'company', 'name'], groupList);
        titleRow[0] = companyName;

        // ser groupName to headerRow
        const groupName = GROUP_TITLE[groupKey];
        headerRow[0] = groupName;
      }

      //fill groupList
      const filledGrouptList = fillMonthlySnapshotsList(groupList, monthNumberDict);

      // create groupRow
      let groupRow = new Array(monthsCount + 2).fill(0);
      const groupRowTitle = getRowTitle(groupKey, groupList);
      groupRow[0] = groupRowTitle;

      // fill groupRow and headerRow
      groupRow.forEach((__, cellIndex) => {
        if (cellIndex > 1) {
          // month index in filledGrouptList array
          const monthIndex = cellIndex - 2;

          const currentMonthData = filledGrouptList[monthIndex];
          const { month, year, amount } = currentMonthData;

          // add amount to total value for currentRow
          groupRow[1] = BigInt(groupRow[1])
            .add(amount)
            .toString();

          // add amounts to total Row
          totalRow[1] = BigInt(totalRow[1])
            .add(amount)
            .toString();
          totalRow[cellIndex] = BigInt(totalRow[cellIndex])
            .add(amount)
            .toString();

          // Set the value to the corresponding month in groupRow
          groupRow[cellIndex] = amount;

          headerRow[cellIndex] = `${MONTHS[month - 1]}-${String(year).substring(2, 4)}`;
        }
      });

      return groupRow;
    });
    return [titleRow, headerRow, ...groupRows, totalRow];
  });

  return allTables;
};

const exportToExcelSalesPipelineTrendsTables = (allTables, fileName, userName) => {
  // Create a Workbook
  let workbook = new Excel.Workbook();

  // Set Workbook Properties
  workbook.creator = userName;
  workbook.created = new Date();

  // Add a Worksheet
  let worksheet = workbook.addWorksheet('SalesPipelineTrends');

  // Add tables to Worksheet
  allTables.forEach((table, tableIndex) => {
    // add rows to worksheet
    worksheet.addRows(table);
    // add empty rows to separate one table from another
    const emptyRow = new Array(table[0].length).fill('');
    worksheet.addRows([emptyRow, emptyRow]);

    // find out the number of rows in the table
    const rowsCount = table.length;
    // find out the number of rows in the table with 2 empty rows
    const rowsCountWithEmptyRows = rowsCount + 2;

    // add thin border for all cells in table
    const firstTableRow = 1 + tableIndex * rowsCountWithEmptyRows;
    const lastTableRow = rowsCount + tableIndex * rowsCountWithEmptyRows;
    for (let rowNumber = firstTableRow; rowNumber <= lastTableRow; rowNumber++) {
      worksheet.getRow(rowNumber).eachCell((cell) => {
        cell.border = BORDER_STYLE;
      });
    }

    // columns count in our table
    const columnsCount = table[0].length;

    // do formatting title row
    const titleRow = worksheet.getRow(firstTableRow);
    const firstTitleCell = titleRow.getCell(1);
    const lastTitleCell = titleRow.getCell(columnsCount);
    firstTitleCell.font = TITLE_CELL_STYLE;
    firstTitleCell.fill = FILL_STYLE;
    worksheet.mergeCells(`${firstTitleCell.address}:${lastTitleCell.address}`);

    // do formatting header row
    const headerRow = worksheet.getRow(2 + tableIndex * rowsCountWithEmptyRows);
    const firstHeaderCell = headerRow.getCell(1);
    const secondHeaderCell = headerRow.getCell(2);
    headerRow.eachCell(function(cell, cellIndex) {
      cell.fill = FILL_STYLE;
      if (cellIndex > 2) {
        cell.alignment = HORIZONTAL_ALIGNMENT;
      }
    });
    worksheet.mergeCells(`${firstHeaderCell.address}:${secondHeaderCell.address}`);

    // do formatting total row
    const totalRow = worksheet.getRow(lastTableRow);
    totalRow.eachCell(function(cell, cellIndex) {
      cell.fill = FILL_STYLE;
      if (cellIndex > 1) {
        cell.alignment = HORIZONTAL_ALIGNMENT;
      }
    });
  });

  // Save excel file on the computer
  workbook.xlsx
    .writeBuffer()
    .then((buffer) => {
      exportToFile(buffer, `${fileName}.xlsx`);
    })
    .catch((err) => console.log('Error writing excel export', err));
};

export {
  generateSalesPipelineTrendsFilters,
  createSnapshotList,
  generateSalesPipelineTrendsTables,
  exportToExcelSalesPipelineTrendsTables,
};
