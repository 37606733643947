import gql from 'graphql-tag';
import { UserFragment } from '../../auth/queries';
import { BusinessCaseFragment } from '../../document-management/business-case/businessCase.queries';
import { CommentFragment } from '../../comment/comment-queries';

const ActionFragment = gql`
  fragment ActionFragment on Action {
    id
    name
    description
    status
    originalDueDate
    assignedDate
    revisedDueDate
    requestedDate
    createdAt
    requestedBy {
      ...UserFragment
    }
    unitType
    unitQuantity
    unitValueDescription
    unitMonetizationFactor
    requestedBy {
      ...UserFragment
    }
    createdBy {
      firstName
      lastName
      id
    }
    assignedTo {
      ...UserFragment
    }
    initiatives {
      items {
        id
        name
        status
        baselineStartDate
        baselineEndDate
        owner {
          ...UserFragment
        }
      }
    }
    nextSteps {
      items {
        id
        dueDate
        description
        status
        assignedTo {
          ...UserFragment
        }
      }
    }
    businessCase {
      ...BusinessCaseFragment
    }
    documents {
      items {
        id
        downloadUrl
        filename
        shareUrl
        fileId
      }
    }
    itemActionRelation {
      id
    }
    source {
      id
      name
    }

    actionApprovalRelation {
      items {
        dateOfResponse
        status
        approvedBy {
          ...UserFragment
        }
        company {
          id
          name
        }
      }
    }
  }
  ${UserFragment}
  ${BusinessCaseFragment}
`;

/**
 * Create.
 */
export const ACTION_CREATE_MUTATION = gql`
  mutation($data: ActionCreateInput!) {
    actionCreate(data: $data) {
      ...ActionFragment
    }
  }
  ${ActionFragment}
`;

/**
 * Retrieve.
 */
export const ACTION_DETAIL_QUERY = gql`
  query($id: ID!) {
    action(id: $id) {
      ...ActionFragment
    }
  }
  ${ActionFragment}
`;

/**
 * Update.
 */
export const ACTION_UPDATE_MUTATION = gql`
  mutation($data: ActionUpdateInput!) {
    actionUpdate(data: $data) {
      ...ActionFragment
    }
  }
  ${ActionFragment}
`;

export const ActionApprovalFragment = gql`
  fragment ActionApprovalFragment on ActionApproval {
    id
    action {
      id
      name
    }
    company {
      id
      name
    }
    status
    dateOfResponse
  }
`;

export const ACTION_APPROVALS_LIST_QUERY = gql`
  query($filter: ActionApprovalFilter, $sort: [ActionApprovalSort!]) {
    actionApprovalsList(filter: $filter, sort: $sort) {
      count
      items {
        ...ActionApprovalFragment
      }
    }
  }
  ${ActionApprovalFragment}
`;

/**
 * Update an Action Approval.
 */
export const ACTION_APPROVAL_UPDATE_MUTATION = gql`
  mutation($approval: ActionApprovalUpdateInput!) {
    actionApprovalUpdate(data: $approval) {
      id
    }
  }
`;
/**
 * Update an Action Approval and an Action.
 */
export const ACTION_APPROVAL_MUTATION = gql`
  mutation($action: ActionUpdateInput!, $approval: ActionApprovalUpdateInput!) {
    actionUpdate(data: $action) {
      id
    }
    actionApprovalUpdate(data: $approval) {
      id
    }
  }
`;
/**
 * Query for the Action Comments.
 *
 * @type {*|*}
 */
export const ACTION_COMMENTS_QUERY = gql`
  query($id: ID!) {
    action(id: $id) {
      id
      comments {
        items {
          ...CommentFragment
        }
        count
      }
    }
  }
  ${CommentFragment}
`;

/**
 * Delete a business case.
 */
export const ACTION_BUSINESSCASE_DELETE = gql`
  mutation($businessCaseId: ID!, $actionId: ID!) {
    businessCaseDelete(data: { id: $businessCaseId }) {
      success
    }
    actionDelete(data: { id: $actionId }) {
      success
    }
  }
`;
