import React from 'react';
import { Card, Heading, Loader, Grid, Row } from '@8base/boost';
import { CreateViewCardBody } from '../../../components/new-ui/card/CreateViewCardBody';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import * as toast from '../../../components/toast/Toast';
import * as R from 'ramda';
import { View } from '@cobuildlab/react-simple-state';
import { onChangeDataMixin, onErrorMixin } from '../../../shared/mixins';
import { FundingRequestModel, FUNDING_REQUEST_DOCUMENTS } from './funding-request-model';
import FundingRequestForm from './components/FundingRequestForm';
import {
  autoSaveUpdateFundingRequest,
  fetchFundingRequestDetail,
  openComments,
  updateFundingRequest,
} from './funding-request-action';
import BusinessCaseModel, {
  BUSINESS_CASE_DOCUMENT,
} from '../../document-management/business-case/BusinessCase.model';
import BusinessCaseForm from '../../document-management/business-case/components/BusinessCaseForm';
import { fetchFundingRequestFormDataAction } from './funding-request-action';
import { fetchInitiativeList } from '../initiative/initiative-actions';
import FundingRequestDetailTable from './components/FundingRequestDetailTable';
import BusinessCaseDetailTable from '../../document-management/business-case/components/BusinessCaseDetailTable';
import { getCurrencyOnSession } from '../../../shared/alliance-utils';
import { ActionButton } from '../../../components/buttons/ActionButton';
import { FormSteps } from '../../../components/dots/FormSteps';
import { TransparentButton } from '../../../components/buttons/TransparentButton';
import { fundingRequestValidator } from './funding-request-validators';
import { businessCaseValidator } from '../../document-management/business-case/business-case-validators';
import { fetchRelatedItems } from '../../related-item/related-item-actions';
import { sanitizeNextStepsToEdit } from '../../../modules/next-step/next-step-actions';
import { fetchCurrentAllianceMembersAction } from '../../settings/alliance-management/alliance-actions';
import sessionStore, { NEW_SESSION_EVENT } from '../../../shared/SessionStore';
import { sanitizeRecommendedSolutionsToEdit } from '../../document-management/business-case/businessCases.actions';
import RelatedItemForm from '../../related-item/components/RelatedItemForm';
import { getItemByType } from '../../../shared/items-util';
import { TopButtons } from '../../../components/buttons/TopButtons';
import { RelatedItemsDetailTable } from '../../related-item/components/RelatedItemsDetailTable';
import { initiativesItemValidator, initiativesApproved } from '../initiative/initiative-validators';
import { InitiativeListTable } from '../initiative/components/InitiativeListTable';
import { LeftProgressSection } from '../../../components/new-ui/LeftProgressSection';
import { SCREENS_FUNDING } from '../screenView';
import { BoxCard } from '../../../components/new-ui/div/BoxCard';
import { CardFooter } from '../../../components/new-ui/card/CardFooter';
import { FUNDING_REQUEST_COMPLETED } from '../../../shared/status';
import { TextDateAgo } from '../../../components/text/TextDateAgo';
import {
  onFundingRequestError,
  onFundingRequestFormData,
  onFundingRequestDetail,
  onFundingRequestAutoSave,
  onFundingRequestUpdate,
} from './funding-request-event';
import { OnRelatedItems } from '../../related-item/related-item-events';
import { OnInitiativeList } from '../initiative/initiative-events';

class FundingRequestEditView extends View {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        fundingRequestData: R.clone(FundingRequestModel),
        businessCaseData: R.clone(BusinessCaseModel),
        relatedItems: [],
        initiatives: [],
      },
      loading: true,
      step: 0,
      initiativesList: [],
      clientCompany: null,
      partnerCompany: null,
      userRole: false,
      savedAt: null,
    };
    this.onError = onErrorMixin.bind(this);
    this.onChangeData = onChangeDataMixin.bind(this);
    this.originalRecommendedSolutions = [];
    this.originalNextSteps = [];
    this.originalData = null;

    this.autoSaveTimer = null;
  }

  onChangeFundingRequestData = (name, value) => {
    const { data } = this.state;
    data.fundingRequestData[name] = value;
    this.autoSave();
    this.setState({ data });
  };

  onChangeBusinessCaseData = (name, value) => {
    const { data } = this.state;
    data.businessCaseData[name] = value;
    this.autoSave();
    this.setState({ data });
  };

  componentDidMount() {
    const { match } = this.props;
    if (!match.params.id) return toast.error('Funding Request ID missing');

    this.subscribe(onFundingRequestError, this.onError);
    this.subscribe(onFundingRequestFormData, (state) => {
      this.setState({
        clientCompany: R.clone(state.clientCompany),
        partnerCompany: R.clone(state.partnerCompany),
      });
    });
    this.subscribe(onFundingRequestDetail, (state) => {
      const fundingRequestData = R.clone(state.fundingRequest);
      const businessCaseData = R.clone(fundingRequestData.businessCase);

      this.originalRecommendedSolutions = sanitizeRecommendedSolutionsToEdit(businessCaseData);
      this.originalNextSteps = sanitizeNextStepsToEdit(fundingRequestData);
      fundingRequestData.documents = fundingRequestData.documents.items.map((document) => ({
        ...document,
      }));

      fundingRequestData.initiatives = fundingRequestData.initiatives.items.map((initiative) => ({
        ...initiative,
      }));

      this.originalData = R.clone(fundingRequestData);
      delete fundingRequestData.businessCase;

      const { data } = this.state;
      data.initiatives = fundingRequestData.initiatives;
      data.fundingRequestData = fundingRequestData;
      data.businessCaseData = businessCaseData;

      this.setState({ data }, () => {
        fetchRelatedItems(fundingRequestData.itemFundingRequestRelation.id);
      });
    });

    this.subscribe(OnRelatedItems, (state) => {
      const items = state.item.itemsRelated.items.map((item) => getItemByType(item));
      const { data } = this.state;
      data.relatedItems = items;
      this.setState({ data, loading: false });
    });

    this.subscribe(onFundingRequestUpdate, () => {
      toast.success('Funding Request Successfully Updated');
      this.props.history.goBack();
    });

    this.subscribe(OnInitiativeList, (state) => {
      const initiativesList = state.initiativesList.items.map((initiative) => ({
        ...initiative,
      }));

      this.setState({
        initiativesList,
      });
    });

    this.subscribe(onFundingRequestAutoSave, (fundingRequest) => {
      this.setState({ savedAt: new Date() });
      this.savedFundingRequest = R.clone(fundingRequest);
    });

    // Apollo may be capable to make just one call
    fetchFundingRequestDetail(match.params.id);
    fetchInitiativeList('', 1, 1000);
    fetchFundingRequestFormDataAction();
    fetchCurrentAllianceMembersAction();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
    localStorage.removeItem(FUNDING_REQUEST_DOCUMENTS);
    localStorage.removeItem(BUSINESS_CASE_DOCUMENT);
  }

  autoSave = () => {
    const waitTime = 10000; // Wait 10s
    clearTimeout(this.autoSaveTimer);
    this.autoSaveTimer = setTimeout(() => {
      autoSaveUpdateFundingRequest(
        R.clone(this.state.data.fundingRequestData),
        R.clone(this.state.data.businessCaseData),
        this.originalRecommendedSolutions,
        this.originalNextSteps,
        R.clone(this.state.data.relatedItems),
        R.clone(this.state.data.initiatives),
        this.originalData,
      );
    }, waitTime);
  };

  onSubmit = () => {
    this.setState({ loading: true }, () => {
      clearTimeout(this.autoSaveTimer);
      updateFundingRequest(
        R.clone(this.state.data.fundingRequestData),
        R.clone(this.state.data.businessCaseData),
        this.originalRecommendedSolutions,
        this.originalNextSteps,
        R.clone(this.state.data.relatedItems),
        R.clone(this.state.data.initiatives),
        this.originalData,
      );
    });
  };

  onFundingRequestStepChange = (nextStep) => {
    const { selectedAlliance } = sessionStore.getState(NEW_SESSION_EVENT);
    const fundingRequestData = R.clone(this.state.data.fundingRequestData);
    try {
      fundingRequestValidator(fundingRequestData, selectedAlliance);
    } catch (e) {
      return this.onError(e);
    }
    this.onScreen(nextStep);
  };

  onBusinessCaseStepChange = (nextStep) => {
    const businessCaseData = R.clone(this.state.data.businessCaseData);
    try {
      businessCaseValidator(businessCaseData);
    } catch (e) {
      return this.onError(e);
    }
    this.onScreen(nextStep);
  };

  onRelatedItemsStepChange = (step) => {
    const initiatives = R.clone(this.state.data.initiatives);
    try {
      initiativesItemValidator(initiatives);
      initiativesApproved(initiatives);
    } catch (e) {
      return this.onError(e);
    }
    this.onScreen(step);
  };

  onScreen = (step) => {
    this.setState({ step });
  };

  render() {
    const {
      initiativesList,
      companyId,
      loading,
      step,
      clientCompany,
      partnerCompany,
      data,
      savedAt,
    } = this.state;
    const { initiatives, businessCaseData, fundingRequestData, relatedItems } = data;
    const { user, selectedAlliance } = sessionStore.getState(NEW_SESSION_EVENT);
    const { history } = this.props;
    const currency = getCurrencyOnSession();
    let content = <Loader stretch />;
    let footer = <></>;
    let buttonsTop = '';
    if (fundingRequestData.status === FUNDING_REQUEST_COMPLETED)
      history.push(`/management/investment-item/`);
    if (!loading && step === 0) {
      content = (
        <div id={'c2gScreen'}>
          <FundingRequestForm
            data={this.state.data.fundingRequestData}
            onChange={this.onChangeFundingRequestData}
            myCompanyId={companyId}
            initiativesList={initiativesList}
            clientCompany={clientCompany}
            partnerCompany={partnerCompany}
            currency={currency}
            user={user}
            selectedAlliance={selectedAlliance}
          />
        </div>
      );
      footer = (
        <CardFooter>
          <ActionButton onClick={() => this.onFundingRequestStepChange(1)} text={'Next'} />
        </CardFooter>
      );
    }

    if (!loading && step === 1) {
      content = (
        <div id={'c2gScreen'}>
          <BusinessCaseForm
            data={businessCaseData}
            onChange={this.onChangeBusinessCaseData}
            currency={currency}
            hideExpectCostAvoidance
            showBusinessFields
          />
        </div>
      );
      footer = (
        <CardFooter>
          <ActionButton onClick={() => this.onBusinessCaseStepChange(2)} text={'Next'} />
          <TransparentButton onClick={() => this.onScreen(0)} text={'Previous'} />
        </CardFooter>
      );
    }

    if (!loading && step === 2) {
      const itemData = { id: fundingRequestData.id, type: fundingRequestData.__typename };

      content = (
        <div id={'c2gScreen'}>
          <RelatedItemForm
            relatedItems={relatedItems}
            initiatives={initiativesList}
            onChange={(key, value) => {
              this.autoSave();
              this.onChangeData(key, value);
            }}
            selectedInitiatives={initiatives}
            itemData={itemData}
          />
        </div>
      );

      footer = (
        <CardFooter>
          <ActionButton onClick={() => this.onRelatedItemsStepChange(3)} text={'Next'} />
          <TransparentButton onClick={() => this.onScreen(1)} text={'Previous'} />
        </CardFooter>
      );
    }

    if (!loading && step === 3) {
      const fundingRequestDataDetail = R.clone(fundingRequestData);
      fundingRequestDataDetail.documents = { items: fundingRequestDataDetail.documents };
      fundingRequestDataDetail.nextSteps = { items: fundingRequestDataDetail.nextSteps };

      content = (
        <div id={'c2gScreen'}>
          <FundingRequestDetailTable data={fundingRequestDataDetail} currency={currency} />
          <BusinessCaseDetailTable
            hideExpectedCostAvoidance
            data={businessCaseData}
            currency={currency}
          />
          <InitiativeListTable initiatives={initiatives} />
          <RelatedItemsDetailTable relatedItems={relatedItems} />
        </div>
      );
      footer = (
        <CardFooter>
          <ActionButton onClick={this.onSubmit} text={'Update Funding Request'} />
          <TransparentButton onClick={() => this.onScreen(2)} text={'Previous'} />
        </CardFooter>
      );
    }

    if (!loading)
      buttonsTop = (
        <TopButtons
          onClickClosed={() => this.props.history.goBack()}
          onClickCollaborated={() => openComments(this.state.data.fundingRequestData)}
        />
      );

    return (
      <React.Fragment>
        <Card.Header>
          <Grid.Layout
            columns="400px auto 400px"
            areas={[['left', 'center', 'right']]}
            style={{ width: '100%' }}>
            <Grid.Box area="left">
              <Row>
                <Heading type="h4" text="Edit Funding Request" />
                <TextDateAgo from={savedAt} />
              </Row>
            </Grid.Box>
            <Grid.Box area="center">
              <FormSteps totalSteps={4} step={this.state.step} />
            </Grid.Box>
            <Grid.Box area="right" />
          </Grid.Layout>
          {buttonsTop}
        </Card.Header>
        <CreateViewCardBody>
          <Grid.Layout columns="30% 70%" areas={[['left', 'right']]} style={{ width: '100%' }}>
            <Grid.Box area="left">
              <LeftProgressSection sections={SCREENS_FUNDING} currentScreen={step} />
            </Grid.Box>
            <BoxCard>
              <Grid.Box area="right">{content}</Grid.Box>
            </BoxCard>
          </Grid.Layout>
        </CreateViewCardBody>
        {footer}
      </React.Fragment>
    );
  }
}

FundingRequestEditView.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default withRouter(FundingRequestEditView);
