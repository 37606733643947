import gql from 'graphql-tag';
import {
  AllianceInvitationFragment,
  AllianceMemberInvitationFragment,
  CompanyInvitationFragment,
} from '../../auth/queries';

/**
 * Updates the Alliance Mutation Entity.
 */
export const UPDATE_ALLIANCE_INVITATION_MUTATION = gql`
  mutation($data: AllianceInvitationUpdateInput!) {
    allianceInvitationUpdate(data: $data) {
      id
    }
  }
`;

/**
 * Create a CompanyUser Relationship.
 */
export const CREATE_COMPANY_USER_MUTATION = gql`
  mutation($data: CompanyUserCreateInput!) {
    companyUserCreate(data: $data) {
      id
    }
  }
`;

/**
 * Delete an Invitation.
 */
export const ALLIANCE_INVITATION_DELETE_MUTATION = gql`
  mutation($data: AllianceInvitationDeleteInput!) {
    allianceInvitationDelete(data: $data) {
      success
    }
  }
`;

export const ALLIANCE_INVITATION_QUERY = gql`
  query($id: ID!) {
    allianceInvitation(id: $id) {
      ...AllianceInvitationFragment
    }
  }
  ${AllianceInvitationFragment}
`;

export const ALLIANCE_MEMBER_INVITATIONS_QUERY = gql`
  query($filter: AllianceMemberInvitationFilter!) {
    allianceMemberInvitationsList(filter: $filter) {
      count
      items {
        ...AllianceMemberInvitationFragment
      }
    }
  }
  ${AllianceMemberInvitationFragment}
`;

export const ALLIANCE_MEMBER_INVITATION_QUERY = gql`
  query($id: ID!) {
    allianceMemberInvitation(id: $id) {
      ...AllianceMemberInvitationFragment
    }
  }
  ${AllianceMemberInvitationFragment}
`;

export const UPDATE_ALLIANCE_MEMBER_INVITATION_MUTATION = gql`
  mutation($data1: AllianceMemberInvitationUpdateInput!, $data2: UserUpdateInput!) {
    allianceMemberInvitationUpdate(data: $data1) {
      id
    }
    userUpdate(data: $data2) {
      id
    }
  }
`;

export const COMPANY_INVITATION_QUERY = gql`
  query($id: ID!) {
    companyInvitation(id: $id) {
      ...CompanyInvitationFragment
    }
  }
  ${CompanyInvitationFragment}
`;

export const UPDATE_COMPANY_INVITATION_MUTATION = gql`
  mutation($data: CompanyInvitationUpdateInput!) {
    companyInvitationUpdate(data: $data) {
      id
    }
  }
`;

export const DELETE_COMPANY_INVITATION_MUTATION = gql`
  mutation($data: CompanyInvitationDeleteInput!) {
    companyInvitationDelete(data: $data) {
      success
    }
  }
`;

export const COMPANY_INVITATION_LIST_QUERY = gql`
  query($filter: CompanyInvitationFilter!) {
    companyInvitationsList(filter: $filter) {
      items {
        id
        company {
          id
          name
        }
        createdBy {
          id
          firstName
        }
        email
        status
        dateOfResponse
      }
    }
  }
`;

export const RESEND_ALLIANCE_PARTNER_INVITATION_RESOLVER = gql`
  mutation($id: ID!) {
    resendAlliancePartnerInvitation(id: $id) {
      success
    }
  }
`;
