import Flux from '@cobuildlab/flux-state';

/**
 * Event that triggers a Risk error.
 *
 * @type {string}
 */
export const RISK_ERROR_EVENT = 'onRiskError';

/**
 * Event that triggers a Risk Detail event.
 *
 * @type {string}
 */
export const RISK_DETAIL_EVENT = 'onRiskDetail';

/**
 * Event that triggers a Risk Create event.
 *
 * @type {string}
 */
export const RISK_CREATE_EVENT = 'onRiskCreate';

/**
 * Event that triggers a Risk Update event.
 *
 * @type {string}
 */
export const RISK_UPDATE_EVENT = 'onRiskUpdate';

/**
 * Event that triggers the fetch of the Risk Form Data.
 *
 * @type {string}
 */
export const RISK_FORM_DATA_EVENT = 'onRiskFormData';

/**
 * Event that triggers the fetch of the Risk Form Data.
 *
 * @type {string}
 */
export const RISK_COMPLETED_EVENT = 'onRiskCompleted';

/**
 * Event that triggers a Risk Restore Event.
 *
 * @type {string}
 */
export const RISK_RESTORE_EVENT = 'onRiskRestored';

/**
 * Event that triggers a Risk Restore Event.
 *
 * @type {string}
 */
export const RISK_AUTO_SAVE_CREATE_EVENT = 'RISK_AUTO_SAVE_CREATE_EVENT';

/**
 * Event that triggers a RISK_AUTO_SAVE_CREATE_ERROR_EVENT.
 *
 * @type {string}
 */
export const RISK_AUTO_SAVE_CREATE_ERROR_EVENT = 'RISK_AUTO_SAVE_CREATE_ERROR_EVENT';

/**
 * Hold the Investment Item Data.
 */
class RiskStore extends Flux.DashStore {
  constructor() {
    super();
    this.addEvent(RISK_ERROR_EVENT);
    this.addEvent(RISK_CREATE_EVENT);
    this.addEvent(RISK_FORM_DATA_EVENT);
    this.addEvent(RISK_DETAIL_EVENT);
    this.addEvent(RISK_UPDATE_EVENT);
    this.addEvent(RISK_COMPLETED_EVENT);
    this.addEvent(RISK_RESTORE_EVENT);
    this.addEvent(RISK_AUTO_SAVE_CREATE_EVENT);
    this.addEvent(RISK_AUTO_SAVE_CREATE_ERROR_EVENT);
  }
}

export default new RiskStore();
