import React from 'react';
import { Avatar, Card } from '@8base/boost';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { CommentText } from './components/CommentText';
import { CommentInfoUser } from './components/CommentInfoUser';
import * as R from 'ramda';

const CommentSection = styled(Card.Section)`
  width: 100%;
  position: relative;
`;

//const StyledText = styled(Text)({
//   fontSize: 16,
//   color: '#ff6d4a',
// });

const Comment = ({ avatar, firstName, lastName, text, date, commentOwner, success }) => (
  <CommentSection>
    <Avatar
      src={avatar ? R.path(['downloadUrl'], avatar) : ''}
      firstName={firstName}
      lastName={lastName}
      size="sm"
    />
    <CommentInfoUser username={`${firstName} ${lastName}`} date={date} />
    <CommentText text={text} commentOwner={commentOwner} success={success} />
  </CommentSection>
);

Comment.defaultProps = {
  firstName: '',
  lastName: '',
};

Comment.propTypes = {
  date: PropTypes.object.isRequired,
  text: PropTypes.string.isRequired,
  commentOwner: PropTypes.bool.isRequired,
  success: PropTypes.bool.isRequired,
  avatar: PropTypes.object.isRequired,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
};

export { Comment };
