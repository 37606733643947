import Flux from '@cobuildlab/flux-state';
import sessionStore, { APOLLO_CLIENT, NEW_SESSION_EVENT } from '../../shared/SessionStore';
import { error, log } from '@cobuildlab/pure-logger';
import { ITEMS_LIST_QUERY } from '../related-item/related-item-queries';
import {
  ACTIVE_ITEMS_ERROR_EVENT,
  ACTIVE_ITEMS_REQUEST_EVENT,
  ACTIVE_ITEMS_EVENT,
} from './active-items-store';

/**
 * Filter items with next steps.
 *
 * @param {string} allianceId -  The allianceId to filter.
 * @param {string} search - The search key.
 * @returns {object}             The filter object.
 */
const itemsFilter = (allianceId, search = '') => {
  const riskFilter = {
    risk: {
      id: { not_equals: null },
    },
  };

  const issueFilter = {
    issue: {
      id: { not_equals: null },
    },
  };

  const ideaFilter = {
    idea: {
      id: { not_equals: null },
    },
  };

  const fundingRequestFilter = {
    fundingRequest: {
      id: { not_equals: null },
    },
  };

  const actionFilter = {
    action: {
      id: { not_equals: null },
    },
  };

  const decisionFilter = {
    decision: {
      id: { not_equals: null },
    },
  };

  const contributionFilter = {
    contribution: {
      id: { not_equals: null },
    },
  };

  const filter = {
    alliance: { id: { equals: allianceId } },
    OR: [
      riskFilter,
      issueFilter,
      ideaFilter,
      fundingRequestFilter,
      actionFilter,
      decisionFilter,
      contributionFilter,
    ],
  };

  return filter;
};

/**
 * Fetch Active items.
 *
 * @returns {Promise<void>} - The active items.
 */
export const fetchActiveItems = async () => {
  const client = sessionStore.getState(APOLLO_CLIENT);
  const { selectedAlliance } = sessionStore.getState(NEW_SESSION_EVENT);
  const allianceId = selectedAlliance.id;
  const filter = itemsFilter(allianceId);

  let response;
  try {
    response = await client.query({
      query: ITEMS_LIST_QUERY,
      variables: { data: filter },
      fetchPolicy: 'network-only',
    });
  } catch (e) {
    error('fetchActiveItems', e);
    return Flux.dispatchEvent(ACTIVE_ITEMS_ERROR_EVENT, e);
  }
  log('fetchActiveItems', response.data);
  Flux.dispatchEvent(ACTIVE_ITEMS_EVENT, response.data);
  return response.data;
};

/**
 * Dispatch a Request for seen the related Items.
 *
 * @param {string} itemId - The Id of the Item.
 */
export const openActiveItems = (itemId) => {
  Flux.dispatchEvent(ACTIVE_ITEMS_REQUEST_EVENT, { itemId });
};
