import sessionStore, { TOKEN_EVENT } from '../SessionStore';

export const getToken = () => {
  let token;

  token = sessionStore.getState(TOKEN_EVENT);

  if (!token) {
    const auth = localStorage.getItem('auth');
    const authObject = JSON.parse(auth);
    token = authObject ? authObject.token : '';
  }

  return token;
};
