import Flux from '@cobuildlab/flux-state';

/**
 * Event that triggers a Notification error.
 *
 * @type {string}
 */
export const NOTIFICATIONS_ERROR_EVENT = 'onNotificationError';

/**
 * Event that triggers when some object request the Notifications.
 *
 * @type {string}
 */
export const NOTIFICATIONS_REQUEST_EVENT = 'onNotificationRequest';

/**
 * Event that triggers a Notifications Events.
 *
 * @type {string}
 */
export const NOTIFICATIONS_EVENT = 'onNotifications';

/**
 * Hold the NotificationsData.
 */
class NotificationsStore extends Flux.DashStore {
  constructor() {
    super();
    this.addEvent(NOTIFICATIONS_ERROR_EVENT);
    this.addEvent(NOTIFICATIONS_EVENT);
    this.addEvent(NOTIFICATIONS_REQUEST_EVENT);
  }
}

const notificationsStore = new NotificationsStore();
export default notificationsStore;
