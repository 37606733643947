import { requestApprovalForFundingRequest } from '../funding-request/funding-request-action';
import { formatUser } from '../../../shared/utils';

/**
 * Return the [type, url, id] of the Item.
 *
 * @param {object} item - The Investment Item.
 * @returns {object} InvestimentITemType.
 */
export const getInvestmentItemType = (item) => {
  if (item.fundingRequest !== null) {
    const approvalItems =
      item.fundingRequest.fundingRequestApprovalRelation !== undefined
        ? item.fundingRequest.fundingRequestApprovalRelation.items
        : null;

    return {
      type: 'Funding Request',
      url: 'funding-request',
      id: item.fundingRequest.id,
      name: item.fundingRequest.name,
      status: item.fundingRequest.status,
      createdAt: item.fundingRequest.createdAt,
      revisedDueDate: item.fundingRequest.revisedDueDate,
      requestedBy:
        item.fundingRequest.requestedBy &&
        `${item.fundingRequest.requestedBy.firstName} ${item.fundingRequest.requestedBy.lastName}`,
      initiatives: item.fundingRequest.initiatives,
      approvalFunction: requestApprovalForFundingRequest,
      approvalItems: approvalItems,
      source: item.fundingRequest.source,
    };
  }
  if (item.contribution !== null) {
    return {
      type: 'Contribution',
      url: 'contribution',
      id: item.contribution.id,
      name: item.contribution.name,
      status: item.contribution.status,
      createdAt: item.contribution.createdAt,
      revisedDueDate: '',
      initiatives: item.contribution.initiatives,
      approvalFunction: null,
      approvalItems: null,
      createdBy: item.contribution.createdBy ? formatUser(item.contribution.createdBy) : null,
      source: item.contribution.source,
    };
  }
  return null;
};
