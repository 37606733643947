import React from 'react';
import { View } from '@cobuildlab/react-simple-state';
import { Row, Card, Heading, Loader } from '@8base/boost';
import IdeaModel from './idea-model';
import { onErrorMixin } from '../../../shared/mixins';
import * as R from 'ramda';
import sessionStore, { NEW_SESSION_EVENT } from '../../../shared/SessionStore';
import IdeaDetailTable from './components/IdeaDetailTable';
import {
  approveIdea,
  rejectIdea,
  completedIdea,
  requestApprovalForIdea,
  openComments,
  fetchIdeaDetail,
  restoreIdea,
  fetchIdeaApprovalsList,
} from './idea-actions';
import * as toast from '../../../components/toast/Toast';
import PropTypes from 'prop-types';
import withAlliance from '../../../components/hoc/withAlliance';
import { withRouter } from 'react-router-dom';
import BusinessCaseModel from '../../document-management/business-case/BusinessCase.model';
import BusinessCaseDetailTable from '../../document-management/business-case/components/BusinessCaseDetailTable';
import {
  canApproveIdea,
  canRejectIdea,
  canCompletedIdea,
  canSubmitForApprovalIdea,
  canRestoreIdea,
} from './idea-permissions';
import YesNoDialog from '../../../components/dialogs/YesNoDialog';
import { DangerButton } from '../../../components/buttons/DangerButton';
import { DetailViewCardBody } from '../../../components/card/DetailViewCardBody';
import { RelatedItemsDetailTable } from '../../related-item/components/RelatedItemsDetailTable';
import { TopButtons } from '../../../components/buttons/TopButtons';
import { ActionButton } from '../../../components/buttons/ActionButton';
import { getCurrencyOnSession } from '../../../shared/alliance-utils';
import {
  fetchRelatedItems,
  fetchRelatedItemsByItemId,
} from '../../related-item/related-item-actions';
import AppovalDetailTable from '../../../components/tables/approvalTable/ApprovalDetailTable';
import { getItemByType } from '../../../shared/items-util';
import { RelatedItemsByItemDetailTable } from '../../related-item/components/RelatedItemsByItemDetailTable';
import { InitiativeListTable } from '../initiative/components/InitiativeListTable';
import { CardFooter } from '../../../components/new-ui/card/CardFooter';
import { NoteSubmitForApprovalText } from '../../../components/NoteSubmitForApprovalText';
import { IDEA_IN_PROGRESS } from '../../../shared/status';
import {
  OnIdeaDetail,
  OnIdeaError,
  OnIdeaSubmitApproval,
  OnIdeaApprovalList,
  OnIdeaRestore,
  OnIdeaReject,
  OnIdeaUpdate,
  OnIdeaCompleted,
} from './idea-events';
import {
  OnRelatedItems,
  OnRelatedItemError,
  OnRelatedItemsByItem,
} from '../../related-item/related-item-events';

class IdeaDetailView extends View {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        ideaData: R.clone(IdeaModel),
        businessCaseData: R.clone(BusinessCaseModel),
        relatedItems: [],
        initiatives: [],
        approvalData: [],
        relatedItemsByItem: [],
        approvalDataList: [],
      },
      loading: true,
      approvalModalIsOpen: false,
      rejectModalIsOpen: false,
      completedModalIsOpen: false,
      submitForApprovalModalIsOpen: false,
      restoreModalIsOpen: false,
    };
    this.user = sessionStore.getState(NEW_SESSION_EVENT).user;
    this.onError = onErrorMixin.bind(this);
    this.selectedAlliance = sessionStore.getState(NEW_SESSION_EVENT).selectedAlliance;
  }

  componentDidMount = () => {
    const { match } = this.props;
    if (!match.params.id) return toast.error('Idea ID missing');

    this.subscribe(OnIdeaError, this.onError);
    this.subscribe(OnRelatedItemError, this.onError);

    this.subscribe(OnIdeaDetail, (state) => {
      const ideaData = R.clone(state.idea);
      const businessCaseData = R.clone(ideaData.businessCase);
      const initiatives = ideaData.initiatives.items.map((initiative) => ({ ...initiative }));
      const { data } = this.state;
      data.ideaData = ideaData;
      data.businessCaseData = businessCaseData;
      data.initiatives = initiatives;
      this.setState(
        { data },
        () => fetchRelatedItems(ideaData.itemIdeaRelation.id),
        fetchRelatedItemsByItemId(ideaData.itemIdeaRelation.id),
        fetchIdeaApprovalsList(match.params.id),
      );
    });

    this.subscribe(OnRelatedItems, (state) => {
      const items = state.item.itemsRelated.items.map((item) => getItemByType(item));
      const { data } = this.state;

      data.relatedItems = items;
      this.setState({ data });
    });

    this.subscribe(OnIdeaUpdate, () => {
      fetchIdeaDetail(match.params.id);
      toast.success('Idea Successfully Approved');
    });

    this.subscribe(OnIdeaReject, () => {
      fetchIdeaDetail(match.params.id);
      toast.success('Idea Successfully Rejected');
    });

    this.subscribe(OnIdeaSubmitApproval, (state) => {
      fetchIdeaDetail(match.params.id);
      toast.success('Idea Submitted For Approval!');
    });
    this.subscribe(OnIdeaCompleted, (state) => {
      fetchIdeaDetail(match.params.id);
      toast.success('Idea Successfully Completed');
    });

    this.subscribe(OnIdeaRestore, (state) => {
      fetchIdeaDetail(match.params.id);
      toast.success('Idea Successfully Restored');
    });

    this.subscribe(OnRelatedItemsByItem, (state) => {
      const {
        itemsList: { items: itemsRelated },
      } = state;

      const relatedItemsByItem = itemsRelated.map((item) => getItemByType(item));
      const { data } = this.state;

      data.relatedItemsByItem = relatedItemsByItem;
      this.setState({ data });
    });

    this.subscribe(OnIdeaApprovalList, (state) => {
      const { ideaApprovalsList } = state;
      const { data } = this.state;
      const approvalData = ideaApprovalsList.items.slice(0, 2);
      const approvalDataList = ideaApprovalsList.items.map((item) => ({ ...item }));
      data.approvalData = approvalData;
      this.setState({
        approvalDataList,
        approvalData,
        data,
        loading: false,
      });
    });

    const fetchPolicyOptions = { isCacheFirst: true };
    fetchIdeaDetail(match.params.id, fetchPolicyOptions);
  };

  approve = () => {
    this.setState({
      approvalModalIsOpen: true,
    });
  };

  onYesApprove = () => {
    this.setState(
      {
        approvalModalIsOpen: false,
        loading: true,
      },
      () => {
        const ideaData = R.clone(this.state.data.ideaData);
        approveIdea(ideaData);
      },
    );
  };

  onCloseApprove = () => {
    this.setState({
      approvalModalIsOpen: false,
    });
  };

  reject = () => {
    this.setState({
      rejectModalIsOpen: true,
    });
  };

  onYesReject = () => {
    this.setState(
      {
        rejectModalIsOpen: false,
        loading: true,
      },
      () => {
        const ideaData = R.clone(this.state.data.ideaData);
        rejectIdea(ideaData);
      },
    );
  };

  onCloseReject = () => {
    this.setState({
      rejectModalIsOpen: false,
    });
  };

  completedModal = () => {
    this.setState({
      completedModalIsOpen: true,
    });
  };
  restoreModal = () => {
    this.setState({
      restoreModalIsOpen: true,
    });
  };

  onYesCompleted = () => {
    this.setState(
      {
        completedModalIsOpen: false,
        loading: true,
      },
      () => {
        const ideaData = R.clone(this.state.data.ideaData);
        completedIdea(ideaData);
      },
    );
  };

  onYesRestore = () => {
    this.setState(
      {
        restoreModalIsOpen: false,
        loading: true,
      },
      () => {
        const ideaData = R.clone(this.state.data.ideaData);
        restoreIdea(ideaData);
      },
    );
  };

  onCloseCompleted = () => {
    this.setState({
      completedModalIsOpen: false,
    });
  };
  onCloseRestore = () => {
    this.setState({
      restoreModalIsOpen: false,
    });
  };

  onCloseSubmitForApprovalModal = () => {
    this.setState({
      submitForApprovalModalIsOpen: false,
    });
  };

  onYesSubmitForApproval = () => {
    this.setState(
      {
        submitForApprovalModalIsOpen: false,
        loading: true,
      },
      () => {
        const idea = R.clone(this.state.data.ideaData);
        requestApprovalForIdea({ idea });
      },
    );
  };

  onSelectSubmitForApprovalModal = (idea) => {
    const [canSubmit, errors] = canSubmitForApprovalIdea(this.user, idea, this.selectedAlliance);
    if (canSubmit) {
      this.setState({
        idea,
        submitForApprovalModalIsOpen: true,
      });
    } else {
      toast.errors(errors);
    }
  };

  render() {
    const {
      loading,
      data,
      approvalModalIsOpen,
      rejectModalIsOpen,
      completedModalIsOpen,
      submitForApprovalModalIsOpen,
      restoreModalIsOpen,
      approvalDataList,
    } = this.state;
    const {
      ideaData,
      businessCaseData,
      relatedItems,
      initiatives,
      approvalData,
      relatedItemsByItem,
    } = data;
    const { history } = this.props;
    const currency = getCurrencyOnSession();
    const alliance = this.selectedAlliance;
    let content = <Loader stretch />;
    let buttonsBottom = '';
    let buttonsTop = '';

    if (!loading) {
      content = (
        <div id={'c2gScreen'}>
          <IdeaDetailTable
            data={ideaData}
            currency={currency}
            onClickEdit={() => history.push(`/management/idea/edit/${ideaData.id}`)}
          />
          <BusinessCaseDetailTable
            hideExpectedCostAvoidance
            data={businessCaseData}
            currency={currency}
          />
          <AppovalDetailTable data={approvalData} />
          <AppovalDetailTable data={approvalDataList} title={'APPROVAL HISTORY'} />
          <InitiativeListTable initiatives={initiatives} />
          <RelatedItemsDetailTable relatedItems={relatedItems} />
          <RelatedItemsByItemDetailTable relatedItemsByItem={relatedItemsByItem} />
        </div>
      );

      buttonsTop = (
        <>
          <Heading type="h4" text={ideaData.name} />

          <TopButtons
            onClickClosed={history.goBack}
            onClickCollaborated={() => openComments(ideaData)}
          />
        </>
      );

      buttonsBottom = (
        <Row justifyContent="end">
          {ideaData.status === IDEA_IN_PROGRESS ? (
            <ActionButton
              fontAwesomeIcon={'check'}
              onClick={() => {
                this.onSelectSubmitForApprovalModal(ideaData);
              }}
              text={'Submit For Approval'}
            />
          ) : null}
          <NoteSubmitForApprovalText item={ideaData} />
          <DangerButton
            text={'Reject'}
            fontAwesomeIcon={'times'}
            onClick={this.reject}
            disabled={
              !canRejectIdea(this.user, ideaData, {
                id: this.props.allianceId,
              })
            }
          />

          <ActionButton
            fontAwesomeIcon={'check'}
            onClick={this.approve}
            text={'Approve'}
            disabled={
              !canApproveIdea(this.user, ideaData, {
                id: this.props.allianceId,
              })
            }
          />
          {canCompletedIdea(this.user, ideaData, alliance) ? (
            <ActionButton
              text="Mark Completed"
              fontAwesomeIcon="clipboard-list"
              onClick={() => {
                this.completedModal();
              }}
            />
          ) : null}
          {canRestoreIdea(this.user, ideaData, alliance) ? (
            <ActionButton
              text="Restore"
              fontAwesomeIcon="clipboard-list"
              onClick={() => {
                this.restoreModal();
              }}
            />
          ) : null}
        </Row>
      );
    }

    return (
      <React.Fragment>
        <Card.Header>{buttonsTop}</Card.Header>
        <DetailViewCardBody>{content}</DetailViewCardBody>
        <CardFooter>{buttonsBottom}</CardFooter>
        <YesNoDialog
          title={'Approve Idea'}
          onYes={this.onYesApprove}
          onClose={this.onCloseApprove}
          onNo={this.onCloseApprove}
          text={'Are you sure you want to Approve the Idea?'}
          isOpen={approvalModalIsOpen}
        />
        <YesNoDialog
          title={'Reject Idea'}
          onYes={this.onYesReject}
          onClose={this.onCloseReject}
          onNo={this.onCloseReject}
          text={'Are you sure you want to Reject the Idea?'}
          isOpen={rejectModalIsOpen}
        />
        <YesNoDialog
          title={'Complete Idea'}
          onYes={this.onYesCompleted}
          onClose={this.onCloseCompleted}
          onNo={this.onCloseCompleted}
          text={'Are you sure you want to Mark the Idea as Completed?'}
          isOpen={completedModalIsOpen}
        />

        <YesNoDialog
          title={'Restore Idea'}
          onYes={this.onYesRestore}
          onClose={this.onCloseRestore}
          onNo={this.onCloseRestore}
          text={'Are you sure you want the Idea to Restore?'}
          isOpen={restoreModalIsOpen}
        />
        <YesNoDialog
          isOpen={submitForApprovalModalIsOpen}
          onYes={this.onYesSubmitForApproval}
          onNo={this.onCloseSubmitForApprovalModal}
          onClose={this.onCloseSubmitForApprovalModal}
          text={'Are you sure you want to Submit For Approval this Idea?'}
          title={'Submit For Approval'}
        />
      </React.Fragment>
    );
  }
}

IdeaDetailView.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default withRouter(withAlliance(IdeaDetailView));
