import React from 'react';
import { Card, Heading } from '@8base/boost';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const ItemHeader = styled(Heading)`
  width: 300px;
`;

const RelatedItemsHeader = ({ text }) => (
  <Card.Header>
    <ItemHeader type="h4" text={text} />
  </Card.Header>
);

RelatedItemsHeader.propTypes = {
  text: PropTypes.string.isRequired,
};

export { RelatedItemsHeader };
