import { Text } from '@8base/boost';
import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';

export const TextDateAgo = ({ from }) => {
  const [date, setDate] = useState({ value: 0, unit: 's' });
  const timer = useRef();

  useEffect(() => {
    if (from) {
      const fromMoment = moment(from);

      const secondsDiff = moment().diff(fromMoment, 'seconds');
      const minutesDiff = moment().diff(fromMoment, 'minutes');

      if (secondsDiff < 60) setDate({ value: secondsDiff, unit: 's' });
      else setDate({ value: minutesDiff, unit: 'min' });

      clearTimeout(timer.current);
    }
  }, [from]);

  useEffect(() => {
    const waitTime = date.unit === 's' ? 1000 : 60000;
    timer.current = setTimeout(() => {
      if (date.unit === 's') {
        if (date.value >= 59) setDate({ value: 1, unit: 'min' });
        else setDate({ value: date.value + 1, unit: 's' });
      } else {
        setDate({ value: date.value + 1, unit: 'min' });
      }
    }, waitTime);

    return () => clearTimeout(timer.current);
  }, [date]);

  const text = from ? `Auto-saved ${date.value}${date.unit} ago` : 'No auto-saved';

  return <Text style={{ color: '#878C93', fontSize: 12 }} text={text} />;
};

TextDateAgo.propTypes = {
  from: PropTypes.any.isRequired,
};
