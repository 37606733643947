import React from 'react';
import PropTypes from 'prop-types';
import { SelectField } from '@8base/boost';
import { ConditionalField } from '../../../components/forms/ConditionalField';
import { SortSwitch } from '../../../components/switch/SortSwitch';
import { DeleteButton, FilterRow } from '../components';
import { filterFieldsOptions } from '../utils';

const GroupFieldsRow = ({
  input,
  deleteFilter,
  fieldsDict,
  fieldsOptions,
  changeFormValue,
  selectedGroups,
  fieldIndex,
  withDeleteButton,
}) => {
  const {
    value: { key },
    name,
  } = input;

  const fieldType = key && fieldsDict[key].fieldType;
  const sortSwitchType =
    fieldType === 'DATE' ? 'date' : fieldType === 'NUMBER' ? 'numeric' : 'alphabet';

  const filteredFieldsOptions = filterFieldsOptions(fieldsOptions, selectedGroups, fieldIndex);

  return (
    <FilterRow withDeleteButton={withDeleteButton}>
      <ConditionalField
        name={`${name}.key`}
        subscription={{ active: true, value: true, error: true, touched: true }}
        changeFormValue={changeFormValue}
        component={SelectField}
        label="Group by"
        placeholder="Select Field"
        options={filteredFieldsOptions}
      />
      <ConditionalField
        name={`${name}.sort`}
        subscription={{ value: true }}
        changeFormValue={changeFormValue}
        render={({ input: { value, onChange } }) => {
          return <SortSwitch value={value} onChange={onChange} type={sortSwitchType} />;
        }}
      />
      {withDeleteButton && (
        <DeleteButton text={''} iconName={'Delete'} onClick={deleteFilter} iconSize={'md'} />
      )}
    </FilterRow>
  );
};

GroupFieldsRow.displayName = 'GroupFieldsRow';
GroupFieldsRow.propTypes = {
  input: PropTypes.object.isRequired,
  deleteFilter: PropTypes.func.isRequired,
  fieldsDict: PropTypes.object.isRequired,
  fieldsOptions: PropTypes.array.isRequired,
  changeFormValue: PropTypes.func.isRequired,
  selectedGroups: PropTypes.array.isRequired,
  fieldIndex: PropTypes.number.isRequired,
  withDeleteButton: PropTypes.bool.isRequired,
};

export { GroupFieldsRow };
