import {
  DECISION_AUTO_SAVE_ERROR_EVENT,
  DECISION_AUTO_SAVE_EVENT,
  DECISION_COMPLETED_EVENT,
  DECISION_CREATE_EVENT,
  DECISION_DETAIL_EVENT,
  DECISION_ERROR_EVENT,
  DECISION_RESTORE_EVENT,
  DECISION_UPDATE_EVENT,
} from './decision-store';
import Flux from '@cobuildlab/flux-state';
import { IntegrityError } from '../../../shared/errors';
import { validateDecisionData } from './decision-validators';
import sessionStore, { APOLLO_CLIENT, NEW_SESSION_EVENT } from '../../../shared/SessionStore';
import {
  DECISION_COMMENTS_QUERY,
  DECISION_CREATE_MUTATION,
  DECISION_DETAIL_QUERY,
  DECISION_UPDATE_MUTATION,
} from './decision-queries';
import { error, log } from '@cobuildlab/pure-logger';
import {
  sanitize8BaseBigInt,
  sanitize8BaseReconnectsFromObjects,
  sanitize8BaseReferenceFromObject,
  sanitize8BaseReferencesFromObjects,
} from '../../../shared/utils';
import { getActiveAllianceId } from '../../../shared/alliance-utils';

import { INITIATIVE_ERROR_EVENT } from '../initiative/initiative-store';
import { DECISION_COMPLETED, DECISION_OPEN } from '../../../shared/status';
import {
  COMMENT_CREATE_EVENT,
  COMMENT_ERROR_EVENT,
  COMMENT_REQUEST_EVENT,
  COMMENTS_EVENT,
} from '../../comment/comment-store';
import { DECISION_TYPE } from '../../../shared/item-types';
import { COMMENTS_CREATE_MUTATION } from '../../comment/comment-queries';
import { canCompletedDecision, canRestoreDecision } from './decision-permissions';
import { RELATED_ITEM_UPDATE_MUTATION } from '../../related-item/related-item-queries';
import {
  normalize8baseDocumentsCreate,
  normalize8baseDocumentsDeleteAndUpdate,
} from '@cobuildlab/8base-utils';
import * as R from 'ramda';
import {
  OnDecisionCreate,
  OnDecisionError,
  OnDecisionAutoSave,
  OnDecisionAutoSaveError,
  OnDecisionDetail,
  OnDecisionCompleted,
  OnDecisionRestore,
  OnDecisionUpdate,
} from './decision-events';
import {
  OnCommentError,
  OnCommentRequest,
  OnCommentCreate,
  OnComment,
} from '../../comment/comment-events';

/**
 * Notifies a Request for Comments for a Decision.
 */
export const openComments = ({ id: decisionId }) => {
  OnCommentRequest.dispatch({ type: DECISION_TYPE, id: decisionId });
  Flux.dispatchEvent(COMMENT_REQUEST_EVENT, { type: DECISION_TYPE, id: decisionId });
};

/**
 * Creates a New Decision.
 *
 * @param {object} decision - Decision.
 * @param {object} relatedItems - RelatedItems.
 * @param {Array} initiatives - Initiatives.
 * @param {object} savedDecision - Saved decisions.
 * @returns {Promise<void>} Return promise.
 */
export const createDecision = async (decision, relatedItems, initiatives, savedDecision) => {
  const { user } = sessionStore.getState(NEW_SESSION_EVENT);
  const allianceId = getActiveAllianceId(user);
  const client = sessionStore.getState(APOLLO_CLIENT);

  if (!allianceId) {
    OnDecisionError.dispatch(new IntegrityError('Must have an Active Alliance'));

    return Flux.dispatchEvent(
      DECISION_ERROR_EVENT,
      new IntegrityError('Must have an Active Alliance'),
    );
  }

  try {
    validateDecisionData(decision, initiatives, true);
  } catch (e) {
    error('createDecision', e);
    OnDecisionError.dispatch(e);
    return Flux.dispatchEvent(DECISION_ERROR_EVENT, e);
  }

  try {
    const _decision = await createOrUpdateDecision(
      decision,
      relatedItems,
      initiatives,
      savedDecision,
    );

    await client.resetStore();
    OnDecisionCreate.dispatch(_decision);
    Flux.dispatchEvent(DECISION_CREATE_EVENT, _decision);
    return _decision;
  } catch (e) {
    error('createDecision', e);
    OnDecisionError.dispatch(e);
    Flux.dispatchEvent(DECISION_ERROR_EVENT, e);
  }
};

/**
 * Creates a New Decision.
 *
 * @param {object}decision - Decision.
 * @param {object}relatedItems - RelatedItems.
 * @param {Array}initiatives - Initiatives.
 * @returns {Promise<void>} Return promise.
 */
export const _createDecision = async (decision, relatedItems, initiatives) => {
  const client = sessionStore.getState(APOLLO_CLIENT);
  const { user } = sessionStore.getState(NEW_SESSION_EVENT);
  const allianceId = getActiveAllianceId(user);

  delete decision.id;

  if (!allianceId) throw new IntegrityError('Must have an Active Alliance');

  decision.initiatives = initiatives;
  normalize8baseDocumentsCreate(decision, 'documents');
  sanitize8BaseReferencesFromObjects(decision, 'initiatives');
  sanitize8BaseReferenceFromObject(decision, 'source');
  sanitize8BaseBigInt(decision, 'unitMonetizationFactor');
  decision.status = DECISION_OPEN;
  decision.itemDecisionRelation = {
    create: {
      alliance: { connect: { id: allianceId } },
      itemsRelated: {
        connect: relatedItems.map((item) => {
          return { id: item.itemId };
        }),
      },
    },
  };

  const {
    data: { decisionCreate },
  } = await client.mutate({
    mutation: DECISION_CREATE_MUTATION,
    variables: { data: decision },
  });

  return decisionCreate;
};

/**
 * Auto-Save Creates a New Decision.
 *
 * @param {object} decision - Decision.
 * @param {object} relatedItems - RelatedItems.
 * @param {Array} initiatives - Initiatives.
 * @param {object} savedDecision - Saved decisions.
 * @returns {Promise<void>} Return promise.
 */
export const autoSaveCreateDecision = async (
  decision,
  relatedItems,
  initiatives,
  savedDecision,
) => {
  console.log('Auto-Save: ', {
    decision,
    relatedItems,
    initiatives,
    savedDecision,
  });

  const client = sessionStore.getState(APOLLO_CLIENT);

  try {
    const _decision = await createOrUpdateDecision(
      decision,
      relatedItems,
      initiatives,
      savedDecision,
    );

    await client.resetStore();
    OnDecisionAutoSave.dispatch(_decision);
    Flux.dispatchEvent(DECISION_AUTO_SAVE_EVENT, _decision);
    console.log('autoSaveCreateDecision:response', _decision);
    return decision;
  } catch (e) {
    OnDecisionAutoSaveError.dispatch(e);
    Flux.dispatchEvent(DECISION_AUTO_SAVE_ERROR_EVENT, e);
    console.error('autoSaveCreateDecision', e);
  }
};

/**
 * Auto Save - Update decision Data.
 *
 * @param {object} decisionData - Decision.
 * @param {object} businessCaseData - BusinessCaseData.
 * @param {Array} originalRecommendedSolutions - OriginalRecommendedSolutions.
 * @param {Array} originalNextSteps - OriginalNextSteps.
 * @param {Array} relatedItems - RelatedItems.
 * @param {object} initiatives - Initiatives.
 * @param {object} originalData - OriginalDocuments.
 * @returns {Promise<void>} Return promise.
 */
export const autoSaveUpdateDecision = async (
  decisionData,
  businessCaseData,
  originalRecommendedSolutions,
  originalNextSteps,
  relatedItems,
  initiatives,
  originalData,
) => {
  const client = sessionStore.getState(APOLLO_CLIENT);

  try {
    const decision = await _updateDecision(
      decisionData,
      businessCaseData,
      originalRecommendedSolutions,
      originalNextSteps,
      relatedItems,
      initiatives,
      originalData,
    );

    await client.resetStore();
    OnDecisionAutoSave.dispatch(decision);
    Flux.dispatchEvent(DECISION_AUTO_SAVE_EVENT, decision);
    console.log('autoSaveUpdateDecision:response', decision);
    return decision;
  } catch (e) {
    console.error('autoSaveUpdateDecision:error', e);
  }
};

export const createOrUpdateDecision = async (
  decision,
  relatedItems,
  initiatives,
  savedDecision,
) => {
  if (!savedDecision) {
    return await _createDecision(decision, relatedItems, initiatives);
  } else {
    const decisionData = R.clone(decision);
    const originalData = R.clone(savedDecision);

    decisionData.id = originalData.id;
    decisionData.itemDecisionRelation = originalData.itemDecisionRelation;

    originalData.documents = originalData.documents.items;

    return await _updateDecision(decisionData, relatedItems, initiatives, originalData);
  }
};

/**
 * Fetch the Decision by id of the Current Alliance.
 *
 * @param {string} id - Id.
 * @param {object} options - Object options.
 * @returns {Promise<void>} Return promise.
 */
export const fetchDecision = async (id, options = {}) => {
  const client = sessionStore.getState(APOLLO_CLIENT);
  const fetchPolicy = options.isCacheFirst ? 'cache-first' : 'network-only';

  let response;

  try {
    response = await client.query({
      query: DECISION_DETAIL_QUERY,
      fetchPolicy,
      variables: { id },
    });
  } catch (e) {
    error('fetchDecision', e);
    OnDecisionError.dispatch(e);
    return Flux.dispatchEvent(DECISION_ERROR_EVENT, e);
  }
  log('fetchDecision', response);
  OnDecisionDetail.dispatch(response.data);
  Flux.dispatchEvent(DECISION_DETAIL_EVENT, response.data);
  return response.data;
};

/**
 * Edit a Decision.
 *
 * @param {object}decision - Decision.
 * @param {Array}relatedItems - RelatedItems.
 * @param {object}initiatives - Initiatives.
 * @param {Array}originalData - The Original Decision Object.
 * @returns {Promise<void>} Return promise.
 */
export const updateDecision = async (decision, relatedItems, initiatives, originalData) => {
  const client = sessionStore.getState(APOLLO_CLIENT);

  try {
    validateDecisionData(decision, initiatives, true);
  } catch (e) {
    error('updateDecision', e);
    return Flux.dispatchEvent(DECISION_ERROR_EVENT, e);
  }

  try {
    const _decision = await _updateDecision(decision, relatedItems, initiatives, originalData);

    await client.resetStore();
    OnDecisionUpdate.dispatch(_decision);
    Flux.dispatchEvent(DECISION_UPDATE_EVENT, _decision);
    return _decision;
  } catch (e) {
    error('updateDecision', e);
    OnDecisionError.dispatch(e);
    Flux.dispatchEvent(DECISION_ERROR_EVENT, e);
  }
};

/**
 * Edit a Decision.
 *
 * @param {object}decision - Decision.
 * @param {Array}relatedItems - RelatedItems.
 * @param {object}initiatives - Initiatives.
 * @param {Array}originalData - The Original Decision Object.
 * @returns {Promise<void>} Return promise.
 */
export const _updateDecision = async (decision, relatedItems, initiatives, originalData) => {
  const client = sessionStore.getState(APOLLO_CLIENT);
  const itemId = decision.itemDecisionRelation.id;

  delete decision.__typename;
  delete decision.status;
  delete decision.createdAt;
  delete decision.createdBy;
  delete decision.itemDecisionRelation;

  decision.initiatives = initiatives;
  sanitize8BaseReconnectsFromObjects(decision, 'initiatives');
  sanitize8BaseReferenceFromObject(decision, 'source');
  normalize8baseDocumentsDeleteAndUpdate(decision, 'documents', originalData);
  sanitize8BaseBigInt(decision, 'unitMonetizationFactor');

  const {
    data: { decisionUpdate },
  } = await client.mutate({
    mutation: DECISION_UPDATE_MUTATION,
    variables: { data: decision },
  });

  // Reconnect related items
  const relatedItemsData = {
    id: itemId,
    itemsRelated: {
      reconnect: relatedItems.map((item) => ({ id: item.itemId })),
    },
  };

  log('decisionData:update:relatedItems:', relatedItemsData);
  await client.mutate({
    mutation: RELATED_ITEM_UPDATE_MUTATION,
    variables: { data: relatedItemsData },
  });

  return decisionUpdate;
};

/**
 * Fetches the Decision Comments.
 *
 * @param {string}decisionId - Id.
 * @returns {Promise<void>} Return promise.
 */
export const fetchDecisionComments = async (decisionId) => {
  const client = sessionStore.getState(APOLLO_CLIENT);
  const data = { id: decisionId };
  const { user } = sessionStore.getState(NEW_SESSION_EVENT);

  let response;
  try {
    response = await client.query({
      query: DECISION_COMMENTS_QUERY,
      variables: data,
      fetchPolicy: 'network-only',
    });
  } catch (e) {
    error('fetchDecisionComments', e);
    OnCommentError.dispatch(e);
    return Flux.dispatchEvent(COMMENT_ERROR_EVENT, e);
  }
  log('fetchDecisionComments', response);
  response.data.decision.comments.userId = user.id;

  const comments = R.clone(response.data.decision.comments);

  comments.items = response.data.decision.comments.items.map((comment) => ({
    ...comment,
  }));

  comments.userId = user.id;

  OnComment.dispatch(comments);
  Flux.dispatchEvent(COMMENTS_EVENT, comments);
  return comments;
};

/**
 * Create a comment on a Decision.
 *
 * @param {string}decisionId - Id.
 * @param {Array}comment - Comment.
 * @returns {Promise<*>} Return promise.
 */
export const createDecisionComment = async (decisionId, comment) => {
  const client = sessionStore.getState(APOLLO_CLIENT);
  const data = {
    comment,
    decisionCommentsRelation: { connect: { id: decisionId } },
  };

  let response;
  try {
    response = await client.mutate({
      mutation: COMMENTS_CREATE_MUTATION,
      variables: { data },
    });
  } catch (e) {
    error('createDecisionComment', e);
    OnCommentError.dispatch(e);
    return Flux.dispatchEvent(COMMENT_ERROR_EVENT, e);
  }
  log('createDecisionComment', response);
  OnCommentCreate.dispatch(response.data);
  Flux.dispatchEvent(COMMENT_CREATE_EVENT, response.data);
  return response.data;
};

/**
 * Change status item the completed to open.
 *
 * @param {object}decisionData - Decision.
 * @returns {Promise} Return promise.
 */
export const completedDecision = async (decisionData) => {
  const client = sessionStore.getState(APOLLO_CLIENT);
  const { selectedAlliance, user } = sessionStore.getState(NEW_SESSION_EVENT);

  if (!canCompletedDecision(user, decisionData, selectedAlliance)) {
    OnDecisionError.dispatch(
      new IntegrityError('You do not have permission to perform this action'),
    );

    return Flux.dispatchEvent(
      INITIATIVE_ERROR_EVENT,
      new IntegrityError('You do not have permission to perform this action'),
    );
  }

  let response;

  try {
    response = await client.mutate({
      mutation: DECISION_UPDATE_MUTATION,
      variables: {
        data: {
          id: decisionData.id,
          status: DECISION_COMPLETED,
        },
      },
    });
  } catch (e) {
    error('completedDecision', e);
    OnDecisionError.dispatch(e);
    return Flux.dispatchEvent(DECISION_ERROR_EVENT, e);
  }

  await client.resetStore();
  OnDecisionCompleted.dispatch(response);
  Flux.dispatchEvent(DECISION_COMPLETED_EVENT, response);
  return response.data;
};

/**
 * Restore status the completed to open.
 *
 * @param {object}decisionData - Decision.
 * @returns {Promise<void|*>} Return Promise.
 */
export const restoreDecision = async (decisionData) => {
  const client = sessionStore.getState(APOLLO_CLIENT);
  const { selectedAlliance, user } = sessionStore.getState(NEW_SESSION_EVENT);

  if (!canRestoreDecision(user, decisionData, selectedAlliance)) {
    OnDecisionError.dispatch(
      new IntegrityError('You do not have permission to perform this action'),
    );

    return Flux.dispatchEvent(
      INITIATIVE_ERROR_EVENT,
      new IntegrityError('You do not have permission to perform this action'),
    );
  }

  let response;

  try {
    response = await client.mutate({
      mutation: DECISION_UPDATE_MUTATION,
      variables: {
        data: {
          id: decisionData.id,
          status: DECISION_OPEN,
        },
      },
    });
  } catch (e) {
    error('restoredDecision', e);
    OnDecisionError.dispatch(e);
    return Flux.dispatchEvent(DECISION_ERROR_EVENT, e);
  }

  await client.resetStore();
  OnDecisionRestore.dispatch(response);
  Flux.dispatchEvent(DECISION_RESTORE_EVENT, response);
  return response.data;
};
