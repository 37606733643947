import React from 'react';
import * as R from 'ramda';
import { Form, Field } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';
import { Card, Heading, SelectField } from '@8base/boost';
import { withTablesList } from '@8base-react/table-schema-provider';
import { View } from '@cobuildlab/react-simple-state';
import { TransparentButtonWithIcon } from '../../../../components/buttons/TransparentButtonWithIcon';
import { ViewCardBody } from '../../../../components/card/ViewCardBody';
import { ActionButton } from '../../../../components/buttons/ActionButton';
import { fetchCurrentAllianceMembersAction } from '../../../settings/alliance-management/alliance-actions';
import {
  FieldSection,
  FieldSectionHeader,
  FieldSectionText,
  FilterRow,
  SortFieldsRow,
  GroupFieldsRow,
  ReportsCard,
} from '../../components';
import { TableFieldsSelect, FilterFieldsRow } from '../components';
import { REMOVES_FILTERS_LISTS, DISPLAY_NAME_DICTS } from '../model';
import { validatePivotTableConfig } from '../utils';
import { savePivotTableFilters } from '../pivot-table-actions';
import { ITEM_TYPES_LIST, INITIAL_STATE } from '../model';
import { fetchAllDealsData } from '../../../management/deal/deal-actions';
import { spyItemType, spyFieldsList, spySingleFilterItem } from '../utils';
import { OnAllianceMemberList } from '../../../settings/alliance-management/alliance-events';
import { OnDealAllData } from '../../../management/deal/deal-events';
import { OnPivotTableFilter } from '../pivot-table-events';

class PivotTableConfigViewComponent extends View {
  constructor(props) {
    super(props);

    this.state = {
      data: OnPivotTableFilter.get() || INITIAL_STATE,
      clientCompany: null,
      partnerCompany: null,
      dealsList: [],
    };
  }

  componentDidMount() {
    this.subscribe(OnPivotTableFilter, (data) => {
      this.setState({ data: R.clone(data) });
    });

    this.subscribe(OnAllianceMemberList, (data) => {
      this.setState({
        clientCompany: R.clone(data.clientCompany),
        partnerCompany: R.clone(data.partnerCompany),
      });
    });

    this.subscribe(OnDealAllData, (state) => {
      const items = state.dealDataList.items.map((deal) => ({
        ...deal,
      }));

      this.setState({
        dealsList: items,
      });
    });

    fetchCurrentAllianceMembersAction();
    fetchAllDealsData();
  }

  onSubmit = (data) => {
    const { history } = this.props;
    console.log('ON_SUBMIT >>>>>', data);
    savePivotTableFilters(data);
    history.push('/reports/pivot-table/report');
  };

  render() {
    const { tablesList } = this.props;

    return (
      <Form
        validate={validatePivotTableConfig}
        onSubmit={this.onSubmit}
        subscription={{
          submitting: true,
          error: true,
          pristine: true,
          values: true,
          touched: true,
        }}
        mutators={{
          ...arrayMutators,
        }}
        decorators={[spyItemType, spyFieldsList, spySingleFilterItem]}
        initialValues={this.state.data}
        render={({
          form: { change },
          handleSubmit,
          submitting,
          validating,
          values: formValues,
        }) => {
          const itemType = formValues.itemType;
          const formFieldsList = formValues.fieldsList;

          const currentTable = tablesList.find((table) => table.name === itemType);
          const fieldsList = R.pathOr([], ['fields'], currentTable);

          const removeFiltersList = REMOVES_FILTERS_LISTS[itemType];

          const filteredFieldsList = fieldsList.filter((filter) => {
            return (
              !removeFiltersList.some((filterName) => filterName === filter.name) &&
              filter.fieldType !== 'FILE'
            );
          });

          const filtersFieldsOptions = filteredFieldsList.map((field) => {
            const displayName = DISPLAY_NAME_DICTS[itemType][field.name];
            return {
              label: displayName ? displayName : field.displayName,
              value: field.id,
            };
          });

          const filtersFieldsDict = filteredFieldsList.reduce((dict, field) => {
            const displayName = DISPLAY_NAME_DICTS[itemType][field.name];
            return {
              ...dict,
              [field.id]: {
                ...field,
                displayName: displayName ? displayName : field.displayName,
              },
            };
          }, {});

          const fieldsOptions = filteredFieldsList
            .filter((field) =>
              formFieldsList.some((formField) => formField.name === field.name && !field.isList),
            )
            .map((field) => {
              const displayName = DISPLAY_NAME_DICTS[itemType][field.name];
              return {
                label: displayName ? displayName : field.displayName,
                value: field.name,
              };
            });

          const fieldsDict = filteredFieldsList.reduce((dict, field) => {
            const displayName = DISPLAY_NAME_DICTS[itemType][field.name];
            return {
              ...dict,
              [field.name]: {
                ...field,
                displayName: displayName ? displayName : field.displayName,
              },
            };
          }, {});

          return (
            <div id={'c2gScreen'}>
              <form onSubmit={handleSubmit}>
                <ReportsCard stretch withFooter>
                  <Card.Header>
                    <Heading type="h4" text={'Alliance Analytics'} />
                  </Card.Header>
                  <ViewCardBody
                    style={{ textAlign: 'left', padding: '25px 24px' }}
                    className="card-body-report-with-footer">
                    <FieldSection>
                      <FieldSectionHeader weight="semibold" text="Data Filter" />
                      <FilterRow>
                        <Field
                          name="itemType"
                          subscription={{ active: true, value: true, touched: true, error: true }}
                          component={SelectField}
                          placeholder="Select Item Type"
                          label="Item Type"
                          options={ITEM_TYPES_LIST}
                        />
                      </FilterRow>
                    </FieldSection>
                    <FieldSection>
                      <Field
                        name="fieldsList"
                        label="Fields"
                        component={TableFieldsSelect}
                        fieldsOptions={filtersFieldsOptions}
                        fieldsDict={filtersFieldsDict}
                        itemType={itemType}
                      />
                    </FieldSection>
                    <FieldSection>
                      <FieldSectionHeader weight="semibold" text="Filter" />
                      <FieldArray name="filters">
                        {({ fields }) => {
                          return (
                            <>
                              {fields.length > 0 ? (
                                fields.map((name, fieldIndex) => {
                                  return (
                                    <Field
                                      key={fieldIndex}
                                      name={name}
                                      component={FilterFieldsRow}
                                      deleteFilter={() => fields.remove(fieldIndex)}
                                      fieldsDict={filtersFieldsDict}
                                      fieldsOptions={filtersFieldsOptions}
                                      itemType={itemType}
                                      changeFormValue={change}
                                      withDeleteButton
                                    />
                                  );
                                })
                              ) : (
                                <FieldSectionText text="No filters have been applied to this report." />
                              )}
                              {itemType ? (
                                <TransparentButtonWithIcon
                                  text={'Add Filter'}
                                  iconName={'Add'}
                                  onClick={() => fields.push({})}
                                />
                              ) : null}
                            </>
                          );
                        }}
                      </FieldArray>
                    </FieldSection>
                    <FieldSection>
                      <FieldSectionHeader weight="semibold" text="Sort" />
                      <FieldArray name="sorts">
                        {({ fields }) => {
                          return (
                            <>
                              {fields.length > 0 ? (
                                fields.map((name, fieldIndex) => {
                                  return (
                                    <Field
                                      key={fieldIndex}
                                      name={name}
                                      subscription={{ active: true, value: true, error: true }}
                                      component={SortFieldsRow}
                                      deleteFilter={() => fields.remove(fieldIndex)}
                                      fieldsDict={fieldsDict}
                                      selectedSorts={fields.value}
                                      fieldIndex={fieldIndex}
                                      fieldsOptions={fieldsOptions}
                                      changeFormValue={change}
                                      withDeleteButton
                                    />
                                  );
                                })
                              ) : (
                                <FieldSectionText text="No sorts have been applied to this report." />
                              )}
                              {fields.length < fieldsOptions.length ? (
                                <TransparentButtonWithIcon
                                  text={'Add Sort'}
                                  iconName={'Add'}
                                  onClick={() => fields.push({ value: 'ASC' })}
                                />
                              ) : null}
                            </>
                          );
                        }}
                      </FieldArray>
                    </FieldSection>
                    <FieldSection>
                      <FieldSectionHeader weight="semibold" text="Group" />
                      <FieldArray name="groups">
                        {({ fields }) => {
                          return (
                            <>
                              {fields.length > 0 ? (
                                fields.map((name, fieldIndex) => {
                                  return (
                                    <Field
                                      key={fieldIndex}
                                      name={name}
                                      subscription={{ active: true, value: true, error: true }}
                                      component={GroupFieldsRow}
                                      deleteFilter={() => fields.remove(fieldIndex)}
                                      fieldsDict={fieldsDict}
                                      selectedGroups={fields.value}
                                      fieldIndex={fieldIndex}
                                      fieldsOptions={fieldsOptions}
                                      changeFormValue={change}
                                      withDeleteButton
                                    />
                                  );
                                })
                              ) : (
                                <FieldSectionText text="No groups have been applied to this report." />
                              )}
                              {fields.length < fieldsOptions.length && fields.length < 1 ? (
                                <TransparentButtonWithIcon
                                  text={'Add Group'}
                                  iconName={'Add'}
                                  onClick={() => fields.push({ sort: 'ASC' })}
                                />
                              ) : null}
                            </>
                          );
                        }}
                      </FieldArray>
                    </FieldSection>
                  </ViewCardBody>
                  <Card.Footer style={{ padding: '19px 23px' }}>
                    <ActionButton
                      text="Run"
                      style={{ width: '151px' }}
                      type="submit"
                      disabled={submitting || validating}
                    />
                  </Card.Footer>
                </ReportsCard>
              </form>
            </div>
          );
        }}
      />
    );
  }
}

const PivotTableConfigView = withTablesList(PivotTableConfigViewComponent);

export { PivotTableConfigView };
