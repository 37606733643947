import {
  isUserAdminOrSERInAlliance,
  isUserCreatorInAlliance,
} from '../../../shared/alliance-utils';
import { DECISION_COMPLETED } from '../../../shared/status';
import { isAllianceCompleted } from '@cobuildlab/collabtogrow-permissions';

/**
 * Checks if a User can Edit an Decision.
 *
 * @param {object} user - The user.
 * @param {object} decision - Decision.
 * @param {object} alliance - The Alliance.
 * @returns {boolean|boolean|*} Validate Options.
 */
export const canEditDecision = (user, decision, alliance): boolean => {
  if (isAllianceCompleted(alliance)) return false;
  if (decision.status === DECISION_COMPLETED) return false;
  if (isUserAdminOrSERInAlliance(user, alliance)) return true;
  if (isUserCreatorInAlliance(user, alliance)) return true;

  return false;
};

/**
 * Checks if a User can Complete an Decision.
 *
 * @param {object} user - The user.
 * @param {object} decision - Decision.
 * @param {object} alliance - The Alliance.
 * @returns {boolean|boolean|*} Validate Options.
 */
export const canCompletedDecision = (user, decision, alliance) => {
  if (isAllianceCompleted(alliance)) return false;

  if (decision.status === DECISION_COMPLETED) return false;

  return isUserAdminOrSERInAlliance(user, alliance);
};

/**
 * Checks if a User can Restore an Decision.
 *
 * @param {object} user - The user.
 * @param {object} decision - Decision.
 * @param {object} alliance - The Alliance.
 * @returns {boolean|boolean|*} Validate Options.
 */
export const canRestoreDecision = (user, decision, alliance) => {
  if (isAllianceCompleted(alliance)) return false;

  if (decision.status !== DECISION_COMPLETED) return false;

  return isUserAdminOrSERInAlliance(user, alliance);
};
