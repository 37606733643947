import {
  isUserAdminOrSERInAlliance,
  isUserCreatorInAlliance,
} from '../../../shared/alliance-utils';
import {
  IDEA_COMPLETED,
  IDEA_APPROVED,
  IDEA_SUBMITTED_FOR_APPROVAL,
  ALLIANCE_APPROVED,
} from '../../../shared/status';
import { initiativesApprovedValidator } from '../initiative/initiative-validators';
import { isUserPendingIdeaApproval } from './idea-utils';
import { isAllianceCompleted } from '@cobuildlab/collabtogrow-permissions';

/**
 * Checks if a User can Edit a Idea.
 *
 * @param {object}user - User.
 * @param {object}idea - Idea.
 * @param {object}alliance - Alliance.
 * @returns {boolean} Validate Options.
 */
export const canEditIdea = (user, idea, alliance) => {
  if (isAllianceCompleted(alliance)) return false;

  if (idea.status === IDEA_COMPLETED) return false;

  if (isUserAdminOrSERInAlliance(user, alliance)) return true;

  if (idea.status === IDEA_APPROVED) return false;

  if (idea.status === IDEA_SUBMITTED_FOR_APPROVAL) return false;

  if (isUserCreatorInAlliance(user, alliance)) return true;

  return false;
};

/**
 * Checks if a User can Approve a Idea.
 *
 * @param {object}user - User.
 * @param {object}idea - Idea.
 * @param {object}alliance - Alliance.
 * @returns {boolean} Validate Options.
 */
export const canApproveIdea = (user, idea, alliance) => {
  if (isAllianceCompleted(alliance)) return false;

  if (idea.status !== IDEA_SUBMITTED_FOR_APPROVAL) return false;

  if (!isUserAdminOrSERInAlliance(user, alliance)) return false;

  return isUserPendingIdeaApproval(user, alliance, idea);
};

/**
 * Checks if a User can Reject a Idea.
 *
 * @param {object}user - User.
 * @param {object}idea - Idea.
 * @param {object}alliance - Alliance.
 * @returns {boolean} Validate Options.
 */
export const canRejectIdea = (user, idea, alliance) => {
  if (isAllianceCompleted(alliance)) return false;

  if (idea.status !== IDEA_SUBMITTED_FOR_APPROVAL) return false;

  if (idea.status !== IDEA_SUBMITTED_FOR_APPROVAL) return false;

  if (!isUserAdminOrSERInAlliance(user, alliance)) return false;

  return isUserPendingIdeaApproval(user, alliance, idea);
};

/**
 * Checks if a User can Submit for Approval a Idea.
 *
 * @param {object}user - User.
 * @param {object}idea - Idea.
 * @param {object}alliance - Alliance.
 * @returns {boolean} Validate Options.
 */
export const canSubmitForApprovalIdea = (user, idea, alliance) => {
  const errors = [];
  if (isAllianceCompleted(alliance))
    errors.push('Cannot submit for approval if the alliance is completed');

  if (alliance.status !== ALLIANCE_APPROVED)
    errors.push('Can not submit for approval if the alliance is not approved');

  if (
    idea.status === IDEA_APPROVED ||
    idea.status === IDEA_COMPLETED ||
    idea.status === IDEA_SUBMITTED_FOR_APPROVAL
  )
    errors.push('The idea must be In Progress to submit for approval');

  try {
    initiativesApprovedValidator(idea.initiatives);
  } catch (e) {
    errors.push(e.message);
  }

  if (!isUserAdminOrSERInAlliance(user, alliance))
    errors.push('You must be Admin or SER in alliance to submit for approval');

  return [!errors.length, errors];
};

/**
 * Checks if a User can Create a Idea.
 *
 * @param {object}user - User.
 * @param {object}alliance - Alliance.
 * @returns {boolean} Validate Options.
 */
export const canCreateIdea = (user, alliance) => {
  if (isAllianceCompleted(alliance)) return false;

  if (isUserAdminOrSERInAlliance(user, alliance)) return true;

  if (isUserCreatorInAlliance(user, alliance)) return true;

  return false;
};

/**
 * Checks if a User can Deleted a Idea.
 *
 * @param {object}user - User.
 * @param {object}idea - Idea.
 * @param {object}alliance - Alliance.
 * @returns {boolean} Validate Options.
 */
export const canDeleteIdea = (user, idea, alliance) => {
  const errors = [];
  if (isAllianceCompleted(alliance))
    errors.push('Cannot delete an idea if the alliance is completed');

  if (idea.status === IDEA_SUBMITTED_FOR_APPROVAL)
    errors.push('Cannot delete the idea if it is submitted for approval');
  if (idea.status === IDEA_APPROVED) errors.push('Cannot delete the idea if it is approved');

  if (!isUserAdminOrSERInAlliance(user, alliance))
    errors.push('You must be Admin or SER in the alliance to delete');

  return [!errors.length, errors];
};

/**
 * Checks if a User can Completed a Idea.
 *
 * @param {object}user - User.
 * @param {object}idea - Idea.
 * @param {object}alliance - Alliance.
 * @returns {boolean} Validate Options.
 */
export const canCompletedIdea = (user, idea, alliance) => {
  if (isAllianceCompleted(alliance)) return false;

  if (idea.status === IDEA_COMPLETED) return false;

  if (idea.assignedTo && idea.assignedTo.id === user.id) return true;

  return isUserAdminOrSERInAlliance(user, alliance);
};

/**
 * Checks if a User can Restore a Idea.
 *
 * @param {object}user - User.
 * @param {object}idea - Idea.
 * @param {object}alliance - Alliance.
 * @returns {boolean} Validate Options.
 */
export const canRestoreIdea = (user, idea, alliance) => {
  if (isAllianceCompleted(alliance)) return false;

  if (idea.status !== IDEA_COMPLETED) return false;

  if (idea.assignedTo && idea.assignedTo.id === user.id) return true;

  return isUserAdminOrSERInAlliance(user, alliance);
};
