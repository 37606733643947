import { createEvent } from '@cobuildlab/react-simple-state';

export const OnIdeaError = createEvent();
export const OnIdeaList = createEvent();
export const OnIdeaDelete = createEvent();
export const OnIdeaSubmitApproval = createEvent();
export const OnIdeaFullList = createEvent();
export const OnIdeaCreate = createEvent();
export const OnIdeaAutoSaveCreate = createEvent();
export const OnIdeaAutoSaveCreateError = createEvent();
export const OnIdeaDetail = createEvent();
export const OnIdeaApprovalList = createEvent();
export const OnIdeaRestore = createEvent();
export const OnIdeaReject = createEvent();
export const OnIdeaUpdate = createEvent();
export const OnIdeaCompleted = createEvent();
