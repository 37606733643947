import React from 'react';
import FileIcon from '../FileIcon';
import PropTypes from 'prop-types';
import LightGreyText from '../LightGreyText';

const DocumentsFileComponent = ({ data, fontSize }) => {
  let document;

  if (data) {
    document = data.items ? data.items : data;
  }

  return (
    <>
      {Array.isArray(document) ? (
        document.length > 0 ? (
          document.map((value, index) => <FileIcon key={index} index={index} file={value} />)
        ) : (
          <LightGreyText fontSize={fontSize} text="None" />
        )
      ) : document !== '' && document !== null ? (
        <FileIcon key={0} index={0} file={data} />
      ) : (
        <LightGreyText fontSize={fontSize} text="None" />
      )}
    </>
  );
};

DocumentsFileComponent.propTypes = {
  data: PropTypes.object,
  fontSize: PropTypes.number,
};

DocumentsFileComponent.defaultProps = {
  fontSize: null,
  data: null,
};

export default DocumentsFileComponent;
