import { ValidationError } from '../../../shared/errors';
import { isValidString, isValidBigInt } from '../../../shared/validators';
import { initiativesItemValidator } from '../initiative/initiative-validators';

/**
 * Validate that the Decision has all the information necesarry to create it.
 *
 * @param {object} decision - The Decision to be reviewed.
 */
export const decisionValidator = (decision) => {
  let errorMessages = [];
  if (!isValidString(decision.name)) errorMessages.push('The Decision must have a valid Name');

  if (!isValidString(decision.description))
    errorMessages.push('The Decision must have a valid Description');

  if (!isValidString(decision.unitType, false, true))
    errorMessages.push('The Decision must have a valid Unit type');

  if (!(parseFloat(decision.unitQuantity) >= 0))
    errorMessages.push('The Decision must have a valid Unit');

  if (!isValidString(decision.unitValueDescription, true, true))
    errorMessages.push('The Decision must have a valid Unit Description');

  if (!isValidBigInt(decision.unitMonetizationFactor))
    errorMessages.push('The Decision must have a Valid unit Monetization Factor');

  if (errorMessages.length !== 0) throw new ValidationError(errorMessages);
};

export const validateDecisionData = (decisionData, initiativesData) => {
  decisionValidator(decisionData);
  initiativesItemValidator(initiativesData);
};
