import moment from 'moment';
import { DEFAULT_MONTHS_RANGE } from '../balanced-scorecard/balanced-scorecard-model';
import { getStartEndAndTotalMonths } from '../balanced-scorecard/balanced-scorecard-utils';

const chartsMonths = (monthsRange = DEFAULT_MONTHS_RANGE) => {
  const { startMonth, endMonth } = getStartEndAndTotalMonths(monthsRange);
  const months = [];

  for (let monthIndex = startMonth - 1; monthIndex < endMonth; monthIndex++) {
    const data = {
      name: moment()
        .month(monthIndex)
        .format('MMM YY'),
    };
    months.push(data);
  }

  return months;
};

export { chartsMonths };
