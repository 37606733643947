import * as R from 'ramda';
import Excel from 'exceljs';

import { exportToFile, ownerToString } from '../../../shared/utils';
import { BORDER_STYLE, FILL_STYLE, HORIZONTAL_ALIGNMENT, TITLE_CELL_STYLE } from '../reports-model';
import BigInt from 'big-integer';

const cropDealDescription = (description, charsLimit) => {
  if (description && description.length > charsLimit) {
    return `${description.substr(0, charsLimit)}...`;
  }
  return description;
};

const HEADER_COLUMN_LIST = [
  { displayName: 'DEAL NAME', key: 'name' },
  { displayName: 'AMOUNT', key: 'amount' },
  { displayName: 'CUSTOMER NAME', key: 'customerName' },
  { displayName: 'CLOSE DATE', key: 'closeDate' },
  { displayName: 'DEAL DESCRIPTION', key: 'description' },
  { displayName: 'ALLIANCE DEAL OWNER', key: 'owner.id' },
  { displayName: 'SALES ACCOUNT REP', key: 'salesPerson' },
  { displayName: 'STAGE', key: 'stage' },
];

const generateSingleActiveSalesPipelineTable = (groupList, groupValue, groupKey) => {
  // In the table we do not display the column by which we group
  let headerList = HEADER_COLUMN_LIST.filter(({ key }) => key !== groupKey);

  //  create totalRow
  let totalRow = new Array(headerList.length).fill('');
  totalRow[0] = 'TOTAL';
  totalRow[1] = BigInt();

  const dealsRows = groupList.map((currentDeal) => {
    return headerList.reduce((accum, title) => {
      const { key } = title;

      if (key === 'owner.id') {
        accum.push(ownerToString(currentDeal.owner));
      } else if (key === 'amount') {
        const amount = currentDeal[key] ? currentDeal[key] : 0;
        accum.push(parseInt(amount));
        // add amount to totalValue
        totalRow[1] = totalRow[1].plus(amount);
      } else {
        const cellValue = currentDeal[key] ? currentDeal[key] : '';
        accum.push(cellValue);
      }

      return accum;
    }, []);
  });

  totalRow[1] = parseInt(totalRow[1].toString());

  const headerRow = headerList.map(({ displayName }) => displayName);

  // return with titleRow
  if (groupKey) {
    const titleRow = new Array(headerList.length).fill('');

    let titleText = 'No data';
    const owner = R.path(['0', 'owner'], groupList);
    if (owner) {
      titleText = ownerToString(owner);
    }
    if (groupValue !== 'null') {
      titleText = groupValue;
    }

    const displayName = HEADER_COLUMN_LIST.find(({ key }) => key === groupKey).displayName;

    titleRow[0] = `${displayName}: ${titleText}`;

    return [titleRow, headerRow, ...dealsRows, totalRow];
  }

  return [headerRow, ...dealsRows, totalRow];
};

const generateActiveSalesPipelineTables = (deals, groupBy) => {
  if (groupBy.length === 0) {
    return generateSingleActiveSalesPipelineTable(deals);
  }
  return deals.map(([groupValue, groupList]) =>
    generateSingleActiveSalesPipelineTable(groupList, groupValue, groupBy[0]),
  );
};

const exportToExcelActiveSalesPipelineTables = (allTables, groupBy, fileName, userName) => {
  if (groupBy.length === 0) {
    allTables = [allTables];
  }
  // Create a Workbook
  const workbook = new Excel.Workbook();

  // Set Workbook Properties
  workbook.creator = userName;
  workbook.created = new Date();

  // Add a Worksheet
  const worksheet = workbook.addWorksheet('SalesPipelineTrends');

  // Empty rows between tables
  const emptyRows = 2;

  let lastTableRow = 0;

  // Add tables to Worksheet
  allTables.forEach((table) => {
    // columns count in our table
    const columnsCount = table[0].length;
    // find out the number of rows in the table
    const rowsCount = table.length;

    // add rows to worksheet
    worksheet.addRows(table);
    // add empty rows to separate one table from another
    const emptyRow = new Array(columnsCount).fill('');
    worksheet.addRows(new Array(emptyRows).fill(emptyRow));

    // add thin border for all cells in table
    const firstTableRow = lastTableRow + 1;
    lastTableRow = firstTableRow + rowsCount + emptyRows - 1;

    const borderLength = rowsCount - 1;

    for (let rowNumber = 1; rowNumber <= borderLength; rowNumber++) {
      worksheet.getRow(rowNumber + firstTableRow).eachCell((cell) => {
        cell.border = BORDER_STYLE;
      });
    }

    const isGrouped = groupBy.length > 0;

    // do formatting title row
    if (isGrouped) {
      const titleRow = worksheet.getRow(firstTableRow);
      const firstTitleCell = titleRow.getCell(1);
      const lastTitleCell = titleRow.getCell(columnsCount);
      firstTitleCell.font = TITLE_CELL_STYLE;
      firstTitleCell.fill = FILL_STYLE;
      worksheet.mergeCells(`${firstTitleCell.address}:${lastTitleCell.address}`);
    }

    // do formatting header row
    const headerRow = worksheet.getRow(isGrouped ? firstTableRow + 1 : firstTableRow);
    headerRow.eachCell(function(cell, cellIndex) {
      cell.fill = FILL_STYLE;
      if (cellIndex === 2) {
        cell.alignment = HORIZONTAL_ALIGNMENT;
      }
      worksheet.getColumn(cellIndex).width = 20;
    });

    // do formatting total row
    const totalRow = worksheet.getRow(lastTableRow - emptyRows);
    totalRow.eachCell(function(cell, cellIndex) {
      cell.fill = FILL_STYLE;
      if (cellIndex > 1) {
        cell.alignment = HORIZONTAL_ALIGNMENT;
      }
    });
  });

  // Save excel file on the computer
  workbook.xlsx
    .writeBuffer()
    .then((buffer) => {
      exportToFile(buffer, `${fileName}.xlsx`);
    })
    .catch((err) => console.log('Error writing excel export', err));
};

export {
  cropDealDescription,
  generateActiveSalesPipelineTables,
  exportToExcelActiveSalesPipelineTables,
};
