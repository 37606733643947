import { Text } from '@8base/boost';
import styled from '@emotion/styled';

const PlanName = styled(Text)`
  margin: 0 !important;
  color: ${({ color }) => color};
  font-size: 14px;
`;

export { PlanName };
