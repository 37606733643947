import React from 'react';
import PropTypes from 'prop-types';
import { Table } from '@8base/boost';

/**
 * Detail View Table For The Action Entity.
 *
 */

const AllianceUserRoleDetailColumn = (props) => {
  const { companyUser, role } = props.data;

  // Integrity Check
  if (!companyUser) return null;

  const userName = `${companyUser.user.firstName} ${companyUser.user.lastName}`;
  const roleName = role.name;

  return (
    <>
      <Table.BodyRow columns="3fr 3fr" className={'justify-center-column'}>
        <Table.BodyCell className="justify-center-column">{userName}</Table.BodyCell>
        <Table.BodyCell>{roleName}</Table.BodyCell>
      </Table.BodyRow>
    </>
  );
};

AllianceUserRoleDetailColumn.propTypes = {
  data: PropTypes.object.isRequired,
};

export default AllianceUserRoleDetailColumn;
