import React from 'react';
import { Table, Dropdown, Menu, Icon, Loader, Tooltip } from '@8base/boost';
import { DropdownBodyOnTable } from '../../../../components/dropdown/DropdownBodyOnTable';
import { Link } from 'react-router-dom';
import * as R from 'ramda';
import { canEditDeal } from '../deal-permissions';
import PropTypes from 'prop-types';
import CurrencyFormat from 'react-currency-format';
import DetailValue from '../../../../components/DetailValue';
import DealDate from './DealDate';
import { cropDealDescription } from '../../../reports/active-sales-pipeline/active-sales-pipeline-utils';

class DealFullTable extends React.Component {
  render() {
    const {
      user,
      dealsList,
      loadingPage,
      loading,
      history,
      currency,
      alliance,
      onSelectForDelete,
    } = this.props;
    return (
      <div>
        <Table>
          <Table.Header
            className="justify-center-column"
            inline
            columns=" 200px 200px 200px 200px 200px 200px 200px 200px 200px
          200px 200px 200px 200px 200px 200px 200px 200px 200px 200px 200px 200px 200px 200px 200px">
            <Table.HeaderCell className="name-column">Name</Table.HeaderCell>
            <Table.HeaderCell>Customer Name</Table.HeaderCell>
            <Table.HeaderCell>Company</Table.HeaderCell>
            <Table.HeaderCell>Amount</Table.HeaderCell>
            <Table.HeaderCell>Description</Table.HeaderCell>
            <Table.HeaderCell>Partner Registration Id</Table.HeaderCell>
            <Table.HeaderCell>Closed Date</Table.HeaderCell>
            <Table.HeaderCell>Owner</Table.HeaderCell>
            <Table.HeaderCell>Last Activity Date</Table.HeaderCell>
            <Table.HeaderCell>Created Date</Table.HeaderCell>
            <Table.HeaderCell>Last Modified Date</Table.HeaderCell>
            <Table.HeaderCell>Lost Reason</Table.HeaderCell>
            <Table.HeaderCell>Next Activity Date</Table.HeaderCell>
            <Table.HeaderCell>Next Activity Description</Table.HeaderCell>
            <Table.HeaderCell>Number Of Sales Activities</Table.HeaderCell>
            <Table.HeaderCell>Sales Person</Table.HeaderCell>
            <Table.HeaderCell>Stage</Table.HeaderCell>
            <Table.HeaderCell>Type</Table.HeaderCell>
            <Table.HeaderCell>Won Reason</Table.HeaderCell>
            <Table.HeaderCell>Service Line</Table.HeaderCell>
            <Table.HeaderCell>Deal Reference (Y/S)</Table.HeaderCell>
            <Table.HeaderCell>Associated Deal Id</Table.HeaderCell>
            <Table.HeaderCell>Deal Source Id</Table.HeaderCell>
            <Table.HeaderCell>Options</Table.HeaderCell>
          </Table.Header>
          <Table.Body loading={loading} data={dealsList} className="card-body-list">
            {(deal, index) => {
              let customerName = 'N/A';
              let amount = '';
              if (deal.customerName) customerName = deal.customerName;
              if (deal.amount) amount = deal.amount;
              const isLast = index === dealsList.length - 1;

              const pageLoader = isLast && loadingPage ? <Loader stretch /> : null;
              return (
                <div>
                  <Table.BodyRow
                    inline
                    columns="220px 230px 180px 180px 210px 190px 220px 190px 200px 190px 230px
                  180px 200px 210px 230px 200px 200px  200px 190px 200px 200px 200px 200px 200px"
                    key={deal.id}>
                    <Table.BodyCell className="deal-column">
                      <Link className="item-name" to={`/management/deal/${deal.id}/`}>
                        {deal.name}
                      </Link>
                    </Table.BodyCell>
                    <Table.BodyCell className="justify-left-row deal-column">
                      {customerName}
                    </Table.BodyCell>
                    <Table.BodyCell className="justify-left-row deal-column">
                      {deal.company !== null ? `${deal.company.name}` : null}
                    </Table.BodyCell>
                    <Table.BodyCell className="justify-left-row deal-column">
                      <CurrencyFormat
                        value={amount}
                        displayType={'text'}
                        thousandSeparator={currency.thousandSeparator}
                        decimalSeparator={currency.decimalSeparator}
                        prefix={`${currency.symbol} `}
                      />
                    </Table.BodyCell>
                    <Table.BodyCell className=" deal-column">
                      {deal.description && deal.description.length > 35 ? (
                        <Tooltip message={deal.description}>
                          {cropDealDescription(deal.description, 35)}
                        </Tooltip>
                      ) : (
                        deal.description
                      )}{' '}
                    </Table.BodyCell>
                    <Table.BodyCell className="justify-left-row deal-column">
                      <Link className="item-name" to={`/management/deal/${deal.id}/`}>
                        <DetailValue text={deal.partnerRegistrationId} />
                      </Link>
                    </Table.BodyCell>
                    <Table.BodyCell className="deal-column">
                      <DealDate dealDate={deal.closeDate} />
                    </Table.BodyCell>
                    <Table.BodyCell className="justify-left-row deal-column">
                      {`${R.pathOr('', ['owner', 'firstName'], deal)}  ${R.pathOr(
                        '',
                        ['owner', 'lastName'],
                        deal,
                      )}`}
                    </Table.BodyCell>
                    <Table.BodyCell className="justify-left-row deal-column">
                      <DealDate dealDate={deal.lastActivityDate} />
                    </Table.BodyCell>
                    <Table.BodyCell className="justify-left-row deal-column">
                      <DealDate dealDate={deal.createdDate} />
                    </Table.BodyCell>
                    <Table.BodyCell className="justify-left-row deal-column">
                      <DealDate dealDate={deal.lastModifiedDate} />
                    </Table.BodyCell>
                    <Table.BodyCell className=" justify-left-row deal-column">
                      <DetailValue text={deal.lostReason} />
                    </Table.BodyCell>
                    <Table.BodyCell className="justify-left-row deal-column">
                      <DealDate dealDate={deal.nextActivityDate} />
                    </Table.BodyCell>
                    <Table.BodyCell className="justify-left-row deal-column">
                      <DetailValue text={deal.nextActivityDescription} />
                    </Table.BodyCell>
                    <Table.BodyCell className="justify-left-row deal-column">
                      <DetailValue text={deal.numberOfSalesActivities} />
                    </Table.BodyCell>
                    <Table.BodyCell className="justify-left-row deal-column">
                      <DetailValue text={deal.salesPerson} />
                    </Table.BodyCell>
                    <Table.BodyCell className="justify-left-row deal-column">
                      <DetailValue text={deal.stage} />
                    </Table.BodyCell>
                    <Table.BodyCell className="justify-left-row deal-column">
                      <DetailValue text={deal.type} />
                    </Table.BodyCell>
                    <Table.BodyCell className="justify-left-row deal-column">
                      <DetailValue text={deal.wonReason} />
                    </Table.BodyCell>
                    <Table.BodyCell className="justify-left-row deal-column">
                      <DetailValue text={deal.line} />
                    </Table.BodyCell>
                    <Table.BodyCell className="justify-left-row deal-column">
                      <DetailValue text={deal.dealReferencable} />
                    </Table.BodyCell>
                    <Table.BodyCell className="justify-left-row deal-column">
                      <DetailValue text={deal.associatedDealId} />
                    </Table.BodyCell>
                    <Table.BodyCell className="justify-left-row deal-column">
                      <DetailValue text={deal.dealSourceId} />
                    </Table.BodyCell>
                    <Table.BodyCell className="justify-left-row deal-column">
                      <Dropdown defaultOpen={false}>
                        <Dropdown.Head>
                          <Icon name="More" className="more-icon" />
                        </Dropdown.Head>
                        <DropdownBodyOnTable>
                          {({ closeDropdown }) => (
                            <Menu>
                              <Menu.Item
                                onClick={() => {
                                  history.push(`/management/deal/${deal.id}`);
                                }}>
                                Details
                              </Menu.Item>
                              {canEditDeal(user, deal, alliance) ? (
                                <Menu.Item
                                  onClick={() => {
                                    history.push(`/management/deal/edit/${deal.id}`);
                                  }}>
                                  Edit
                                </Menu.Item>
                              ) : null}
                              <Menu.Item
                                onClick={() => {
                                  closeDropdown();
                                  onSelectForDelete(deal);
                                }}>
                                Delete
                              </Menu.Item>
                            </Menu>
                          )}
                        </DropdownBodyOnTable>
                      </Dropdown>
                    </Table.BodyCell>
                  </Table.BodyRow>
                  {pageLoader}
                </div>
              );
            }}
          </Table.Body>
        </Table>
      </div>
    );
  }
}

DealFullTable.propTypes = {
  user: PropTypes.object.isRequired,
  dealsList: PropTypes.array.isRequired,
  loadingPage: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired,
  currency: PropTypes.object.isRequired,
  alliance: PropTypes.string.isRequired,
  onSelectForDelete: PropTypes.func.isRequired,
};

export default DealFullTable;
