import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const StyledHeading = styled.h6`
  margin-bottom: 10px;
  padding: 0 20px 0 24px; /* must match LinkItem padding */
  text-transform: uppercase;
  font-size: 1.2rem;
  font-weight: 600;
  letter-spacing: 0.5px;
  color: #939ea7;

  &:not(:first-of-type) {
    margin-top: 15px;
  }
`;

const LinkTitle = ({ text, ...rest }) => {
  return (
    <StyledHeading type="h6" {...rest}>
      {text}
    </StyledHeading>
  );
};

LinkTitle.propTypes = {
  text: PropTypes.string.isRequired,
};

export default LinkTitle;
