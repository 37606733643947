import React from 'react';
import { Divider } from '@8base/boost';
import PropTypes from 'prop-types';

const HorizontalLineText = ({ text }) => {
  return <Divider>{text}</Divider>;
};

HorizontalLineText.defaultProps = {
  text: null, // null for divider only without text
};

HorizontalLineText.propTypes = {
  text: PropTypes.string,
};

export { HorizontalLineText };
