import React from 'react';
import { Text } from '@8base/boost';
import PropTypes from 'prop-types';

/**
 * Prefix for expectedCosts, expectedRevenues & anticipatedCosts arrays.
 */

const CostYearPrefix = ({ index }) => {
  return <Text weight="medium">{`Year ${index + 1}:`}&nbsp;&nbsp;</Text>;
};

CostYearPrefix.propTypes = {
  index: PropTypes.number.isRequired, // the array index
};

export default CostYearPrefix;
