import { createEvent } from '@cobuildlab/react-simple-state';

export const OnInitiativeError = createEvent();
export const OnInitiativeList = createEvent();
export const OnInitiativeSmallList = createEvent();
export const OnInitiativeDelete = createEvent();
export const OnInitiativeSubmitForApproval = createEvent();
export const OnInitiativeFullList = createEvent();
export const OnInitiativeCreate = createEvent();
export const OnInitiativeAutoSaveCreate = createEvent();
export const OnInitiativeAutoSaveCreateError = createEvent();
export const OnInitiativeDetail = createEvent();
export const OnInitiativeRestore = createEvent();
export const OnInitiativeUpdate = createEvent();
export const OnInitiativeReject = createEvent();
export const OnInitiativeCompleted = createEvent();
