import React from 'react';
import { Row, Column, Text } from '@8base/boost';
import PropTypes from 'prop-types';
import { getPlanColor } from '../procurer-utils';
import { PlanName } from './PlanName';
import { PlanPaper } from './PlanPaper';

const CancelPlanDetailsCard = ({ plan }) => {
  const { name } = plan;
  const capitalizeName = name.toUpperCase();
  const color = getPlanColor(name);

  return (
    <PlanPaper padding={'md'}>
      <Row>
        <Column stretch>
          <PlanName weight="bold" color={color}>
            {capitalizeName}
          </PlanName>
          <Text>{'Subscription Plan'}</Text>
        </Column>
        <Column stretch />
        <Column stretch />
      </Row>
    </PlanPaper>
  );
};

CancelPlanDetailsCard.propTypes = {
  plan: PropTypes.object.isRequired,
};

export { CancelPlanDetailsCard };
