import React from 'react';
import { Card, Grid, Heading, Loader, Row } from '@8base/boost';
import { CreateViewCardBody } from '../../../components/new-ui/card/CreateViewCardBody';
import { riskValidator } from './risk-validators';
import RiskForm from './components/RiskForm';
import * as toast from '../../../components/toast/Toast';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { View } from '@cobuildlab/react-simple-state';
import { onChangeDataMixin, onErrorMixin } from '../../../shared/mixins';
import * as R from 'ramda';
import sessionStore, { NEW_SESSION_EVENT } from '../../../shared/SessionStore';
import { autoSaveCreateRisk, createRisk } from './risk-action';
import { fetchInitiativeList } from '../initiative/initiative-actions';
import { initiativesItemValidator } from '../initiative/initiative-validators';
import { fetchCurrentAllianceMembersAction } from '../../settings/alliance-management/alliance-actions';
import { getCurrencyOnSession } from '../../../shared/alliance-utils';
import { ActionButton } from '../../../components/buttons/ActionButton';
import { ActionButtonClose } from '../../../components/buttons/ActionButtonClose';
import { TransparentButton } from '../../../components/buttons/TransparentButton';
import { FormSteps } from '../../../components/dots/FormSteps';
import RelatedItemForm from '../../related-item/components/RelatedItemForm';
import RiskDetailTable from './components/RiskDetailTable';
import { RelatedItemsDetailTable } from '../../related-item/components/RelatedItemsDetailTable';
import { saveFormToSessionStorage } from '../../../shared/utils';
import { RISK_DOCUMENTS, RiskModel } from './risk-model';
import { InitiativeListTable } from '../initiative/components/InitiativeListTable';
import { LeftProgressSection } from '../../../components/new-ui/LeftProgressSection';
import { SCREENS_RISK } from '../screenView';
import { BoxCard } from '../../../components/new-ui/div/BoxCard';
import { TextDateAgo } from '../../../components/text/TextDateAgo';
import { OnRiskError, OnRiskCreate, OnRiskAutoSave, OnRiskAutoSaveError } from './risk-events';
import { OnInitiativeList } from '../initiative/initiative-events';
import { OnAllianceMemberList } from '../../settings/alliance-management/alliance-events';

const RISK_DATA_STORE = 'riskCreateView';

/**
 * Create Risk View.
 *
 */

class RiskCreateView extends View {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        riskData: R.clone(RiskModel),
        relatedItems: [],
        initiatives: [],
      },
      step: 0,
      loading: true,
      clientCompany: null,
      partnerCompany: null,
      initiativesList: [],
      savedAt: null,
      autoSaving: false,
    };
    this.onError = onErrorMixin.bind(this);
    this.onChangeData = onChangeDataMixin.bind(this);

    this.autoSaveTimer = null;
    this.savedRisk = null;
  }

  onChangeRiskData = (name, value) => {
    const { data } = this.state;
    data.riskData[name] = value;
    if (name === 'originalDueDate') {
      data.riskData.revisedDueDate = value;
    }
    this.autoSave();
    this.setState({ data });
    const model = R.clone(RiskModel);
    saveFormToSessionStorage(RISK_DATA_STORE, data.riskData, model, ['documents']);
  };

  componentDidMount() {
    const riskData = JSON.parse(sessionStorage.getItem(RISK_DATA_STORE));

    this.subscribe(OnRiskError, this.onError);

    this.subscribe(OnAllianceMemberList, (state) => {
      this.setState({
        clientCompany: R.clone(state.clientCompany),
        partnerCompany: R.clone(state.partnerCompany),
      });
    });

    this.subscribe(OnRiskCreate, () => {
      sessionStorage.removeItem(RISK_DATA_STORE);
      toast.success('Risk Successfully Created');
      this.props.history.goBack();
    });

    this.subscribe(OnInitiativeList, (state) => {
      this.setState({
        loading: false,
        initiativesList: R.clone(state.initiativesList.items),
      });
    });

    this.subscribe(OnRiskAutoSave, (risk) => {
      sessionStorage.removeItem(RISK_DATA_STORE);
      this.setState({ savedAt: new Date(), autoSaving: false });
      this.savedRisk = R.clone(risk);
    });

    this.subscribe(OnRiskAutoSaveError, () => {
      this.setState({ autoSaving: false });
    });

    // set riskData from sessionStorage
    if (riskData) {
      const { data } = this.state;
      data.riskData = riskData;
      this.setState({ data });
    }

    const fetchPolicyOptions = { isCacheFirst: true };
    fetchInitiativeList('', 1, 1000, null, null, fetchPolicyOptions);
    fetchCurrentAllianceMembersAction();
  }

  autoSave = () => {
    const waitTime = 10000; // Wait 10s
    clearTimeout(this.autoSaveTimer);
    this.autoSaveTimer = setTimeout(() => {
      this.setState({ autoSaving: true });
      const riskData = R.clone(this.state.data.riskData);
      const relatedItems = R.clone(this.state.data.relatedItems);
      const initiatives = R.clone(this.state.data.initiatives);
      autoSaveCreateRisk(riskData, relatedItems, initiatives, this.savedRisk);
    }, waitTime);
  };

  componentWillUnmount() {
    super.componentWillUnmount();
    clearTimeout(this.autoSaveTimer);
    localStorage.removeItem(RISK_DOCUMENTS);
  }

  onSubmit = () => {
    this.setState({ loading: true }, () => {
      clearTimeout(this.autoSaveTimer);
      const riskData = R.clone(this.state.data.riskData);
      const relatedItems = R.clone(this.state.data.relatedItems);
      const initiatives = R.clone(this.state.data.initiatives);
      createRisk(riskData, relatedItems, initiatives, this.savedRisk);
    });
  };

  onRiskStepChange = (step) => {
    const { data } = this.state;
    const { selectedAlliance } = sessionStore.getState(NEW_SESSION_EVENT);
    const riskData = R.clone(data.riskData);
    try {
      riskValidator(riskData, selectedAlliance);
    } catch (e) {
      return this.onError(e);
    }
    this.onScreen(step);
  };

  onRelatedItemsStepChange = (step) => {
    const initiatives = R.clone(this.state.data.initiatives);
    try {
      initiativesItemValidator(initiatives);
    } catch (e) {
      return this.onError(e);
    }
    this.onScreen(step);
  };

  onScreen = (step) => {
    if (step === 2) clearTimeout(this.autoSaveTimer);
    this.setState({ step });
  };

  render() {
    const {
      initiativesList,
      loading,
      clientCompany,
      partnerCompany,
      data,
      step,
      savedAt,
      autoSaving,
    } = this.state;
    const { riskData, initiatives, relatedItems } = data;
    const { history } = this.props;
    const { user, selectedAlliance } = sessionStore.getState(NEW_SESSION_EVENT);

    const currency = getCurrencyOnSession();

    let content = <Loader stretch />;
    let footer = <></>;

    if (!loading && step === 0) {
      content = (
        <div id={'c2gScreen'}>
          <RiskForm
            data={riskData}
            onChange={this.onChangeRiskData}
            clientCompany={clientCompany}
            partnerCompany={partnerCompany}
            currency={currency}
            user={user}
            selectedAlliance={selectedAlliance}
          />
        </div>
      );
      footer = (
        <Card.Footer>
          <ActionButton onClick={() => this.onRiskStepChange(1)} text={'Next'} />
        </Card.Footer>
      );
    }

    if (!loading && step === 1) {
      content = (
        <div id={'c2gScreen'}>
          <RelatedItemForm
            relatedItems={relatedItems}
            selectedInitiatives={initiatives}
            initiatives={initiativesList}
            onChange={(key, value) => {
              this.autoSave();
              this.onChangeData(key, value);
            }}
          />
        </div>
      );

      footer = (
        <Card.Footer>
          <ActionButton onClick={() => this.onRelatedItemsStepChange(2)} text={'Next'} />
          <TransparentButton onClick={() => this.onScreen(0)} text={'Previous'} />
        </Card.Footer>
      );
    }

    if (!loading && step === 2) {
      const riskDetail = R.clone(riskData);
      riskDetail.documents = { items: riskDetail.documents };
      riskDetail.initiatives = { items: riskDetail.initiatives };
      riskDetail.nextSteps = { items: riskDetail.nextSteps };

      content = (
        <div id={'c2gScreen'}>
          <RiskDetailTable data={riskDetail} currency={currency} />
          <InitiativeListTable initiatives={initiatives} />

          <RelatedItemsDetailTable relatedItems={relatedItems} />
        </div>
      );

      footer = (
        <Card.Footer>
          <ActionButton disabled={autoSaving} onClick={this.onSubmit} text={'Create Risk'} />
          <TransparentButton onClick={() => this.onScreen(1)} text={'Previous'} />
        </Card.Footer>
      );
    }

    return (
      <React.Fragment>
        <Card.Header>
          <Grid.Layout
            columns="400px auto 400px"
            areas={[['left', 'center', 'right']]}
            style={{ width: '100%' }}>
            <Grid.Box area="left">
              <Row>
                <Heading type="h4" text="Create Risk" />
                <TextDateAgo from={savedAt} />
              </Row>
            </Grid.Box>
            <Grid.Box area="center">
              <FormSteps totalSteps={3} step={step} />
            </Grid.Box>
            <Grid.Box area="right" />
          </Grid.Layout>
          <ActionButtonClose onClick={history.goBack} />
        </Card.Header>
        <CreateViewCardBody>
          <Grid.Layout columns="30% 70%" areas={[['left', 'right']]} style={{ width: '100%' }}>
            <Grid.Box area="left">
              <LeftProgressSection sections={SCREENS_RISK} currentScreen={step} />
            </Grid.Box>
            <BoxCard>
              <Grid.Box area="right">{content}</Grid.Box>
            </BoxCard>
          </Grid.Layout>
        </CreateViewCardBody>
        {footer}
      </React.Fragment>
    );
  }
}

RiskCreateView.propTypes = {
  history: PropTypes.object.isRequired,
};

export default withRouter(RiskCreateView);
