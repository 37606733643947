import Flux from '@cobuildlab/flux-state';

/**
 * Event that triggers a AMO Item error.
 *
 * @type {string}
 */
export const AMO_ITEM_ERROR_EVENT = 'onInvestmentItemError';

/**
 * Event that triggers a AMO Item List event.
 *
 * @type {string}
 */
export const AMO_ITEM_LIST_EVENT = 'onAMOItemList';

/**
 * Event that triggers a AMO Item Delete event.
 *
 * @type {string}
 */
export const AMO_ITEM_DELETE_EVENT = 'onAMOItemDelete';

/**
 * Event that triggers a AMO Item Delete event.
 *
 * @type {string}
 */
export const AMO_ITEM_FULL_LIST_EVENT = 'onAMOItemFullList';

/**
 * Hold the AMO Items.
 */
class AMOItemStore extends Flux.DashStore {
  constructor() {
    super();
    this.addEvent(AMO_ITEM_ERROR_EVENT);
    this.addEvent(AMO_ITEM_LIST_EVENT);
    this.addEvent(AMO_ITEM_DELETE_EVENT);
    this.addEvent(AMO_ITEM_FULL_LIST_EVENT);
  }
}

export default new AMOItemStore();
