import React from 'react';
import PropTypes from 'prop-types';
import '../../css/alliance.css';
import { CurrencyTextField } from '../../../../shared/components/CurrencyTextField';
import DocumentsFileComponent from '../../../../components/inputs/DocumentsFileComponent';
import { COMPANY_DOCUMENT } from '../CompanyDetailView';
import { HorizontalLine } from '../../../../components/new-ui/text/HorizontalLine';
import { HorizontalLineText } from '../../../../components/text/HorizontalLineText';
import { BoderDetailView } from '../../../../components/new-ui/div/BorderDetailView';
import { Grid } from '@8base/boost';
import { HeaderText } from '../../../../components/new-ui/text/HeaderText';
import { EditButton } from '../../../../components/new-ui/buttons/EditButton';
import { TablePosition } from '../../../../components/new-ui/div/TablePosition';
import { TableDetail } from '../../../../components/new-ui/table/TableDetail';
import { ThTitlePosition } from '../../../../components/new-ui/div/ThTitlePosition';

const CompanyDetailTable = (props) => {
  const { currency, onClickEdit, canEdit } = props;
  const {
    description,
    missionAndGoals,
    website,
    country,
    city,
    state,
    zipCode,
    address1,
    address2,
    phone,
    numberOfEmployees,
    annualRevenue,
    parentCompany,
    companyStatus,
    tickerSymbol,
    industry,
    logoFile,
  } = props.data;

  return (
    <>
      <HorizontalLine>
        <HorizontalLineText>COMPANY</HorizontalLineText>
      </HorizontalLine>
      <>
        <BoderDetailView>
          <Grid.Layout
            columns="auto 100px"
            areas={[['left', 'right']]}
            w
            style={{ width: '100%', height: '100%' }}>
            <Grid.Box area="left">
              <div style={{ position: 'absolute', top: '21px', left: '25px' }}>
                <HeaderText>COMPANY</HeaderText>
              </div>
            </Grid.Box>
            <Grid.Box area="right" justifyContent={'center'}>
              {canEdit && <EditButton onClick={onClickEdit} text="Edit" />}
            </Grid.Box>
          </Grid.Layout>
        </BoderDetailView>
        <TablePosition>
          <TableDetail>
            <tbody>
              <tr>
                <th>
                  <ThTitlePosition>
                    <span>DESCRIPTION</span>
                  </ThTitlePosition>
                </th>
                <td>{description}</td>
              </tr>
              <tr>
                <th>
                  <ThTitlePosition>
                    <span>LOGO</span>
                  </ThTitlePosition>
                </th>
                <td>
                  <DocumentsFileComponent data={logoFile} localKey={COMPANY_DOCUMENT} />
                </td>
              </tr>
              <tr>
                <th>
                  <ThTitlePosition>
                    <span>MISSION AND GOALS</span>
                  </ThTitlePosition>
                </th>
                <td>{missionAndGoals}</td>
              </tr>
              <tr>
                <th>
                  <ThTitlePosition>
                    <span>COUNTRY</span>
                  </ThTitlePosition>
                </th>
                <td>{(country && country.name) || ''}</td>
              </tr>
              <tr>
                <th>
                  <ThTitlePosition>
                    <span>STATE</span>
                  </ThTitlePosition>
                </th>
                <td>{state}</td>
              </tr>
              <tr>
                <th>
                  <ThTitlePosition>
                    <span>CITY</span>
                  </ThTitlePosition>
                </th>
                <td>{city}</td>
              </tr>
              <tr>
                <th>
                  <ThTitlePosition>
                    <span>ZIP CODE</span>
                  </ThTitlePosition>
                </th>
                <td>{zipCode}</td>
              </tr>
              <tr>
                <th>
                  <ThTitlePosition>
                    <span>ADDRESS 1</span>
                  </ThTitlePosition>
                </th>
                <td>{address1}</td>
              </tr>
              <tr>
                <th>
                  <ThTitlePosition>
                    <span>ADDRESS 2</span>
                  </ThTitlePosition>
                </th>
                <td>{address2 || ''}</td>
              </tr>
              <tr>
                <th>
                  <ThTitlePosition>
                    <span>PHONE</span>
                  </ThTitlePosition>
                </th>
                <td>{phone}</td>
              </tr>
              <tr>
                <th>
                  <ThTitlePosition>
                    <span>NUMBER OF EMPLOYEES</span>
                  </ThTitlePosition>
                </th>
                <td>{numberOfEmployees}</td>
              </tr>
              <tr>
                <th>
                  <ThTitlePosition>
                    <span>ANNUAL REVENUE</span>
                  </ThTitlePosition>
                </th>
                <td>
                  <CurrencyTextField value={annualRevenue} currency={currency} decimalScale={0} />
                </td>
              </tr>
              <tr>
                <th>
                  <ThTitlePosition>
                    <span>INDUSTRY</span>
                  </ThTitlePosition>
                </th>
                <td>{(industry && industry.name) || ''}</td>
              </tr>
              <tr>
                <th>
                  <ThTitlePosition>
                    <span>PARENT COMPANY</span>
                  </ThTitlePosition>
                </th>
                <td>{parentCompany || ''}</td>
              </tr>
              <tr>
                <th>
                  <ThTitlePosition>
                    <span>COMPANY STATUS</span>
                  </ThTitlePosition>
                </th>
                <td>{companyStatus}</td>
              </tr>
              <tr>
                <th>
                  <ThTitlePosition>
                    <span>WEBSITE</span>
                  </ThTitlePosition>
                </th>
                <td>{website || ''}</td>
              </tr>
              <tr>
                {companyStatus === 'public' ? (
                  <>
                    <th>
                      <ThTitlePosition>
                        <span>TICKER SYMBOL</span>
                      </ThTitlePosition>
                    </th>
                    <td>{tickerSymbol}</td>
                  </>
                ) : null}
              </tr>
            </tbody>
          </TableDetail>
        </TablePosition>
      </>
    </>
  );
};

CompanyDetailTable.defaultProps = {
  onClickEdit: null, // null for form previews
  canEdit: false, // null for form previews
};

CompanyDetailTable.propTypes = {
  data: PropTypes.object.isRequired,
  currency: PropTypes.object.isRequired,
  onClickEdit: PropTypes.func,
  canEdit: PropTypes.bool,
};

export default CompanyDetailTable;
