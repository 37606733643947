/* eslint-disable jsdoc/require-returns */
import { isValidEmail, isValidString } from '../../../shared/validators';
import { ValidationError } from '../../../shared/errors';
import { ALLIANCE_INVITATION_ACCEPTED } from '../../../shared/status';
import { getSelectedCompany } from '../../auth/auth.actions';
import { getMyAllCompanyUsers } from '../company-management/company-actions';
import { COMPANY_ADMINISTRATOR, COMPANY_PORTFOLIO_OWNER } from '../../../shared/roles';
import sessionStore, { NEW_SESSION_EVENT } from '../../../shared/SessionStore';

/**
 * Validator for the invite company admins action.
 *
 * @param {Array} adminEmails - Fields from the company form.
 * @param  {string} companyId - The company id.
 */
export const inviteCompanyAdminsValidator = (adminEmails, companyId) => {
  const errorMessages = [];

  if (!isValidString(companyId)) errorMessages.push('Invalid company.');

  if (Array.isArray(adminEmails)) {
    if (!adminEmails.length) {
      errorMessages.push('You must enter an email to invite the administrators.');
    }

    adminEmails.forEach((email) => {
      if (!isValidEmail(email)) {
        errorMessages.push('The Company Administrator Email must ve valid.');
      }
    });
  } else errorMessages.push('Invalid emails.');

  if (errorMessages.length) throw new ValidationError(errorMessages);
};

/**
 * Validate if user is alliance ser or alliance administrator.
 *
 * @param {object} invitation - Invitation to check permissions.
 */
export const canResendAlliancePartnerInvitation = (invitation) => {
  if (!invitation) return [false, 'Invalid invitation'];
  const { user } = sessionStore.getState(NEW_SESSION_EVENT);

  if (invitation.status === ALLIANCE_INVITATION_ACCEPTED)
    return [false, 'Invitation was already accepted'];

  if (invitation.email === user.email)
    return [false, 'You cannot resent an invitation to yourself'];

  const companyUsers = getMyAllCompanyUsers();
  const selectedCompany = getSelectedCompany();
  const selectedCompanyUser = companyUsers.find(
    (companyUser) => companyUser.company.id === selectedCompany.id,
  );
  if (!selectedCompanyUser) return [false, 'Invalid user'];
  if (
    !selectedCompanyUser.role ||
    (selectedCompanyUser.role.name !== COMPANY_PORTFOLIO_OWNER &&
      selectedCompanyUser.role.name !== COMPANY_ADMINISTRATOR)
  )
    return [false, 'Invalid role, only portfolio owners can resend alliance invitation'];

  return [true, 'Can resend alliance Invitation'];
};
