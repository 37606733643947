import React from 'react';
import { Card, Grid, SecondaryNavigation } from '@8base/boost';
import { Redirect, Route, Switch } from 'react-router-dom';
import InvestmentItemListView from './investment-item/InvestmentItemListView';
import AMOItemListView from './amo-item/AMOItemListView';
import FundingRequestCreateView from './funding-request/FundingRequestCreateView';
import IssueCreateView from './issue/IssueCreateView';
import IssueEditView from './issue/IssueEditView';
import IssueDetailView from './issue/IssueDetailView';
import InitiativeListView from './initiative/InitiativeListView';
import InitiativeCreateView from './initiative/InitiativeCreateView';
import InitiativeEditView from './initiative/InitiativeEditView';
import InitiativeDetailView from './initiative/InitiativeDetailView';
import FundingRequestEditView from './funding-request/FundingRequestEditView';
import FundingRequestDetailView from './funding-request/FundingRequestDetailView';
import ActionCreateView from './action/ActionCreateView';
import ActionEditView from './action/ActionEditView';
import ActionDetailView from './action/ActionDetailView';
import ContributionCreateView from './contribution/ContributionCreateView';
import ContributionEditView from './contribution/ContributionEditView';
import ContributionDetailView from './contribution/ContributionDetailView';
import DecisionCreateView from './decision/DecisionCreateView';
import DecisionEditView from './decision/DecisionEditView';
import DecisionDetailView from './decision/DecisionDetailView';
import RiskCreateView from './risk/RiskCreateView';
import RiskEditView from './risk/RiskEditView';
import RiskDetailView from './risk/RiskDetailView';
import IdeaCreateView from './idea/IdeaCreateView';
import IdeaEditView from './idea/IdeaEditView';
import IdeaDetailView from './idea/IdeaDetailView';
import IdeaListView from './idea/IdeaListView';
import ActionWithOutBusinessCaseCreateView from './action/ActionWithOutBusinessCaseCreateView';
import ActionWithOutBusinessCaseEditView from './action/ActionWithOutBusinessCaseEditView';
import ActionWithOutBusinessCaseDetailView from './action/ActionWithOutBusinessCaseDetailView';
import DealListView from './deal/DealListView';
import DealImportView from './deal/DealImportView';
import DealImportedView from './deal/DealImportedView';
import DealDetailView from './deal/DealDetailView';
import DealCreateView from './deal/DealCreateView';
import DealEditView from './deal/DealEditView';
import { LinkItem } from '../../components/link/LinkItem';
import PropTypes from 'prop-types';

/**
 * View for the Management Section.
 */

const ManagementView = (props) => {
  const { location } = props;
  return (
    <Grid.Layout className="minHeightCard" columns="215px 1fr">
      <Grid.Box>
        <SecondaryNavigation>
          <LinkItem
            to={'/management/initiative'}
            label={'Initiatives'}
            location={location}
            url={'initiative'}
          />
          <LinkItem to={'/management/deal'} label={'Deals'} location={location} url={'deal'} />
          <LinkItem
            to={'/management/amo-item'}
            label={'AMO Items'}
            location={location}
            url={['amo-item', 'action', 'issue', 'risk', 'decision']}
          />
          <LinkItem
            to={'/management/investment-item'}
            label={'Investment Item'}
            location={location}
            url={['investment-item', 'funding-request', 'contribution']}
          />
          <LinkItem to={'/management/idea'} label={'Ideas'} location={location} url={'idea'} />
        </SecondaryNavigation>
      </Grid.Box>
      <Grid.Box style={{ overflow: 'hidden' }}>
        <Card stretch>
          <Switch>
            {/* Contribution */}
            <Route path="/management/contribution/create" component={ContributionCreateView} />
            <Route path="/management/contribution/edit/:id" component={ContributionEditView} />
            <Route
              path="/management/contribution/:id"
              component={ContributionDetailView}
              key={String(Math.random().toFixed(10))}
            />
            {/* Funding request */}
            <Route path="/management/funding-request/create" component={FundingRequestCreateView} />
            <Route path="/management/funding-request/edit/:id" component={FundingRequestEditView} />
            <Route
              path="/management/funding-request/:id"
              component={FundingRequestDetailView}
              key={String(Math.random().toFixed(10))}
            />
            {/* Investment Item */}
            <Route path="/management/investment-item" component={InvestmentItemListView} />
            {/* AMO Item */}
            <Route path="/management/amo-item" component={AMOItemListView} />
            {/* Issue */}
            <Route path="/management/issue/create" component={IssueCreateView} />
            <Route path="/management/issue/edit/:id" component={IssueEditView} />
            <Route
              path="/management/issue/:id"
              component={IssueDetailView}
              key={String(Math.random().toFixed(10))}
            />
            {/* Action */}
            <Route path="/management/action/create" component={ActionCreateView} />
            <Route path="/management/action/edit/:id" component={ActionEditView} />
            <Route
              path="/management/action/:id"
              component={ActionDetailView}
              key={String(Math.random().toFixed(10))}
            />
            <Route
              path="/management/action-without-business-case/create"
              component={ActionWithOutBusinessCaseCreateView}
            />
            <Route
              path="/management/action-without-business-case/edit/:id"
              component={ActionWithOutBusinessCaseEditView}
            />
            <Route
              path="/management/action-without-business-case/:id"
              component={ActionWithOutBusinessCaseDetailView}
              key={String(Math.random().toFixed(10))}
            />
            {/* Idea */}
            <Route path="/management/idea/create" component={IdeaCreateView} />
            <Route path="/management/idea/edit/:id" component={IdeaEditView} />
            <Route
              path="/management/idea/:id"
              component={IdeaDetailView}
              key={String(Math.random().toFixed(10))}
            />
            <Route path="/management/idea" component={IdeaListView} />
            {/* Risk */}
            <Route path="/management/risk/create" component={RiskCreateView} />
            <Route path="/management/risk/edit/:id" component={RiskEditView} />
            <Route
              path="/management/risk/:id"
              component={RiskDetailView}
              key={String(Math.random().toFixed(10))}
            />
            {/* Decision */}
            <Route path="/management/decision/create" component={DecisionCreateView} />
            <Route path="/management/decision/edit/:id" component={DecisionEditView} />
            <Route
              path="/management/decision/:id"
              component={DecisionDetailView}
              key={String(Math.random().toFixed(10))}
            />
            {/* Initiative */}
            <Route exact path="/management/initiative" component={InitiativeListView} />
            <Route exact path="/management/initiative/create" component={InitiativeCreateView} />
            <Route
              exact
              path="/management/initiative/:id"
              component={InitiativeDetailView}
              key={String(Math.random().toFixed(10))}
            />
            <Route exact path="/management/initiative/edit/:id" component={InitiativeEditView} />

            {/* Deals */}
            <Route exact path="/management/deal" component={DealListView} />
            <Route exact path="/management/deal/create" component={DealCreateView} />
            <Route exact path="/management/deal/import" component={DealImportView} />
            <Route exact path="/management/deal/imported" component={DealImportedView} />
            <Route exact path="/management/deal/edit/:id" component={DealEditView} />

            <Route
              exact
              path="/management/deal/:id"
              component={DealDetailView}
              key={String(Math.random().toFixed(10))}
            />
            {/*<Route exact path="/management/deal/edit/:id" component={InitiativeEditView}/>*/}
            {/* Redirect Default View */}
            <Redirect to="/management/initiative" />
          </Switch>
        </Card>
      </Grid.Box>
    </Grid.Layout>
  );
};

ManagementView.propTypes = {
  location: PropTypes.any.isRequired,
};

export default ManagementView;
