import React from 'react';
import { withRouter } from 'react-router-dom';
import { withAuth } from '8base-react-sdk';
import { withApollo } from 'react-apollo';
import PropTypes from 'prop-types';
import { fetchSession } from '../../../modules/auth/auth.actions';
import { onErrorMixin } from '../../../shared/mixins';
import { View } from '@cobuildlab/react-simple-state';
import * as R from 'ramda';
import YesNoDialog from '../../../components/dialogs/YesNoDialog';
import {
  acceptCompanyInvitation,
  rejectCompanyInvitation,
} from '../../settings/invitations/invitations.actions';
import sessionStore, { NEW_SESSION_EVENT } from '../../../shared/SessionStore';
import { CompanyInvitationDialog } from '../components/CompanyInvitationDialog';
import { logout } from '../../../modules/auth//auth.actions';
import {
  OnAllianceInvitationError,
  OnCompanyInvitationAccepted,
  OnCompanyInvitationReject,
} from '../../settings/invitations/invitations-events';

class CompanyInvitationView extends View {
  constructor(props) {
    super(props);
    this.state = {
      companyInvitationDialogIsOpen: true,
      logoutIsOpen: false,
      loading: false,
    };

    this.onError = onErrorMixin.bind(this);
  }

  componentDidMount() {
    this.subscribe(OnAllianceInvitationError, this.onError);

    this.subscribe(OnCompanyInvitationAccepted, async (data) => {
      await fetchSession();
      this.setState({ loading: false });
    });

    this.subscribe(OnCompanyInvitationReject, async (data) => {
      await fetchSession();
      this.setState({ loading: false });
    });
  }

  onOpenLogout = () => {
    this.setState({ logoutIsOpen: true });
  };

  onCloseLogout = () => {
    this.setState({ logoutIsOpen: false });
  };

  onClickAcceptCompanyInvitation = (invitation) => {
    this.setState({ loading: true }, () => {
      acceptCompanyInvitation(R.clone(invitation));
    });
  };

  onClickRejectCompanyInvitation = (invitation) => {
    this.setState({ loading: true }, () => {
      rejectCompanyInvitation(R.clone(invitation));
    });
  };

  render() {
    const {
      onCloseLogout,
      onOpenLogout,
      onClickAcceptCompanyInvitation,
      onClickRejectCompanyInvitation,
    } = this;

    const { loading, companyInvitationDialogIsOpen, logoutIsOpen } = this.state;

    const {
      companyInvitationsList: { items: companyInvitations },
    } = sessionStore.getState(NEW_SESSION_EVENT);

    const logoutDialog = (
      <YesNoDialog
        onYes={() => logout(this.props)}
        onNo={onCloseLogout}
        text={'Are you sure you want to log out?'}
        isOpen={logoutIsOpen}
        title={'Logout'}
        onClose={onCloseLogout}
      />
    );

    return (
      <>
        <CompanyInvitationDialog
          isOpen={companyInvitationDialogIsOpen}
          onCloseDialog={onOpenLogout}
          onClickAcceptCompanyInvitation={onClickAcceptCompanyInvitation}
          onClickRejectCompanyInvitation={onClickRejectCompanyInvitation}
          loading={loading}
          companyInvitations={companyInvitations}
        />
        {logoutDialog}
      </>
    );
  }
}

CompanyInvitationView.propTypes = {
  auth: PropTypes.object.isRequired,
  client: PropTypes.object.isRequired,
};

export default withRouter(withAuth(withApollo(CompanyInvitationView)));
