import { Paper } from '@8base/boost';
import styled from '@emotion/styled';

const PlanPaper = styled(Paper)`
  cursor: ${({ disabled }) => (disabled ? null : 'pointer')};
  width: 100%;
  border: ${({ selected }) => (selected ? ' 2px solid #3EB7F9' : '1px solid #d0d7dd')};
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  border-radius: 5px;
  box-shadow: none !important;
  min-height: 85px;
`;

export { PlanPaper };
