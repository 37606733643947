import gql from 'graphql-tag';
import { UserFragment } from '../../auth/queries';
import { BusinessCaseFragment } from '../../document-management/business-case/businessCase.queries';
import { CommentFragment } from '../../comment/comment-queries';

const IdeaFragment = gql`
  fragment IdeaFragment on Idea {
    id
    name
    description
    status
    originalDueDate
    assignedDate
    revisedDueDate
    requestedDate
    createdAt
    unitType
    unitQuantity
    unitValueDescription
    unitMonetizationFactor
    requestedBy {
      ...UserFragment
    }
    createdBy {
      firstName
      lastName
      id
    }
    assignedTo {
      ...UserFragment
    }
    initiatives {
      items {
        id
        name
        baselineStartDate
        baselineEndDate
        owner {
          ...UserFragment
        }
        status
      }
    }
    nextSteps {
      items {
        id
        dueDate
        description
        status
        assignedTo {
          ...UserFragment
        }
      }
    }
    businessCase {
      ...BusinessCaseFragment
    }
    documents {
      items {
        id
        downloadUrl
        filename
        shareUrl
        fileId
      }
    }
    itemIdeaRelation {
      id
    }
    source {
      id
      name
    }

    ideaApprovalRelation {
      items {
        dateOfResponse
        status
        approvedBy {
          ...UserFragment
        }
        company {
          id
          name
        }
      }
    }
  }
  ${UserFragment}
  ${BusinessCaseFragment}
`;

/**
 * Create.
 */
export const IDEA_CREATE_MUTATION = gql`
  mutation($data: IdeaCreateInput!) {
    ideaCreate(data: $data) {
      ...IdeaFragment
    }
  }
  ${IdeaFragment}
`;

/**
 * Retrieve.
 */
export const IDEA_DETAIL_QUERY = gql`
  query($id: ID!) {
    idea(id: $id) {
      ...IdeaFragment
    }
  }
  ${IdeaFragment}
`;

/**
 * Update.
 */
export const IDEA_UPDATE_MUTATION = gql`
  mutation($data: IdeaUpdateInput!) {
    ideaUpdate(data: $data) {
      ...IdeaFragment
    }
  }
  ${IdeaFragment}
`;

export const IdeaApprovalFragment = gql`
  fragment IdeaApprovalFragment on IdeaApproval {
    id
    idea {
      id
      name
    }
    company {
      id
      name
    }
    status
    dateOfResponse
  }
`;

export const IDEA_APPROVALS_LIST_QUERY = gql`
  query($data: IdeaApprovalFilter, $sort: [IdeaApprovalSort!]) {
    ideaApprovalsList(filter: $data, sort: $sort) {
      count
      items {
        ...IdeaApprovalFragment
      }
    }
  }
  ${IdeaApprovalFragment}
`;

/**
 * Update an Idea Approval.
 */
export const IDEA_APPROVAL_UPDATE_MUTATION = gql`
  mutation($approval: IdeaApprovalUpdateInput!) {
    ideaApprovalUpdate(data: $approval) {
      id
    }
  }
`;
/**
 * Update an Idea Approval and an Idea.
 */
export const IDEA_APPROVAL_MUTATION = gql`
  mutation($idea: IdeaUpdateInput!, $approval: IdeaApprovalUpdateInput!) {
    ideaUpdate(data: $idea) {
      id
    }
    ideaApprovalUpdate(data: $approval) {
      id
    }
  }
`;
/**
 * Query for the Idea Comments.
 *
 * @type {*|*}
 */
export const IDEA_COMMENTS_QUERY = gql`
  query($id: ID!) {
    idea(id: $id) {
      id
      comments {
        items {
          ...CommentFragment
        }
        count
      }
    }
  }
  ${CommentFragment}
`;

export const IDEA_LIST_QUERY = gql`
  query($data: ItemFilter, $skip: Int, $first: Int) {
    itemsList(filter: $data, skip: $skip, first: $first) {
      count
      items {
        id
        createdAt
        idea {
          id
          name
          description
          status
          revisedDueDate
          requestedBy {
            ...UserFragment
          }
          assignedTo {
            ...UserFragment
          }
          initiatives {
            items {
              id
              name
              baselineStartDate
              baselineEndDate
              owner {
                ...UserFragment
              }
              status
            }
          }
          ideaApprovalRelation {
            items {
              dateOfResponse
              status
            }
          }
        }
      }
    }
  }
  ${UserFragment}
`;

/**
 * Delete.
 */
export const IDEA_DELETE_MUTATION = gql`
  mutation($data: ItemDeleteInput!) {
    itemDelete(data: $data, force: true) {
      success
    }
  }
`;
