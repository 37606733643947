import sessionStore, { NEW_SESSION_EVENT } from '../../shared/SessionStore';
import { COMPANY_PROCUREMENT, COMPANY_PORTFOLIO_OWNER } from '../../shared/roles';
import {
  COMPANY_SUBSCRIPTION_ACTIVE,
  COMPANY_SUBSCRIPTION_TRIALING,
  COMPANY_SUBSCRIPTION_CANCELED,
  COMPANY_SUBSCRIPTION_UNPAID,
  COMPANY_SUBSCRIPTION_INCOMPLETE,
} from '../../shared/status';
import { isPlanFreeTrial } from '../settings/company-management/company-actions';
import moment from 'moment';

/**
 * Get invoice color based on the status.
 *
 * @param  {string} invoiceStatus - The invoice status.
 * @returns {string} The color.
 */
export const getInvoiceColor = (invoiceStatus) => {
  if (invoiceStatus === 'draft') return 'YELLOW';
  if (invoiceStatus === 'open') return 'LIGHT_BLUE';
  if (invoiceStatus === 'paid') return 'GREEN';
  if (invoiceStatus === 'failed') return 'RED';
  if (invoiceStatus === 'uncollectible') return 'RED';
  if (invoiceStatus === 'void') return 'AMBER';
};

/**
 * Get plan color based on the name.
 *
 * @param  {string} planName -The plan name.
 * @returns {string} The color.
 */
export const getPlanColor = (planName) => {
  if (isPlanFreeTrial(planName)) return '#3DD828';
  if (planName === 'Bronze') return '#008AAB';
  if (planName === 'Silver') return '#8196A9';
  if (planName === 'Gold') return '#D6AB2B';
};

/**
 * Get plan savings text or period based on plan anualSavings & period of plan.
 *
 * @param  {object} plan - The plan.
 * @param  {boolean} isAnnualPlan - If The plan is annual.
 * @returns {string} The color.
 */
export const getPlanSavingsOrPeriod = (plan, isAnnualPlan) => {
  const { anualSavings, name } = plan;

  if (name === 'Daily Plan') return '';
  if (name === 'Beta Plan') return '6 Months';
  if (name === 'Free Daily Plan') return 'A Free Trial Day';

  return isAnnualPlan ? `Annual savings: $${anualSavings}` : '30 days';
};

/**
 * Check if the user is only COMPANY_PROCUREMENT and no other roles.
 *
 * @returns {boolean} If the user has only that role.
 */
export const isProcurerOnly = () => {
  const {
    user: {
      companyUserRelation: { items: companyUsers },
    },
  } = sessionStore.getState(NEW_SESSION_EVENT);

  let isProcurer = false;
  let isOtherRole = false;
  companyUsers.forEach(({ role }) => {
    if (role && role.name === COMPANY_PROCUREMENT) isProcurer = true;
    else isOtherRole = true;
  });

  return isProcurer && !isOtherRole;
};

/**
 * Check if a User is PROCUREMENT on it's first company.
 *
 * @param {object}user - The User to be cheked.
 * @returns {boolean} - If the User has the PROCUREMENT ROLE in the first company.
 */
export const isProcurer = (user) => {
  if (!user.companyUserRelation) return false;
  if (!user.companyUserRelation.items) return false;
  if (!user.companyUserRelation.items.length) return false;

  const companyUsers = user.companyUserRelation.items;
  const company = user.companyUserRelation.items[0].company;
  let isProcurer = false;
  companyUsers.forEach((companyUser) => {
    if (company.id === companyUser.company.id)
      if (companyUser.role.name === COMPANY_PROCUREMENT) isProcurer = true;
  });

  return isProcurer;
};

/**
 * Validate if an Alliance subscription is active or trialing for show Right Section.
 *
 * @param {object} alliance - The Alliance to check.
 * @returns {boolean}Boolean.
 */
export const isAllianceActive = (alliance) => {
  if (!alliance) return false;
  if (!alliance.clientCompany) return false;

  return isCompanyActive(alliance.clientCompany);
};

/**
 * Validate if a Company is active or trialing.
 *
 * @param {object} company - The Company to check.
 * @returns {boolean}Boolean.
 */
export const isCompanyActive = (company) => {
  if (!company) return false;
  if (!company.companyCompanySubscriptionRelation) return false;
  if (!company.companyCompanySubscriptionRelation.items) return false;
  if (!company.companyCompanySubscriptionRelation.items.length) return false;

  const companySubscriptions = company.companyCompanySubscriptionRelation.items;
  const companySubscription = companySubscriptions.length
    ? companySubscriptions[companySubscriptions.length - 1]
    : {};

  if (!companySubscription.status) return false;
  const { status } = companySubscription;

  return (
    status === COMPANY_SUBSCRIPTION_ACTIVE ||
    status === COMPANY_SUBSCRIPTION_TRIALING ||
    status === COMPANY_SUBSCRIPTION_INCOMPLETE
  );
};

/**
 * Returns true if the company is the Partner in the alliance.
 *
 * @param {object} company - The company.
 * @param {object} alliance - The Alliance.
 * @returns {boolean} Boolean.
 */
export const isCompanyPartner = (company, alliance) => {
  if (!alliance) return false;
  if (!company) return false;
  if (alliance.partnerCompany === null) return false;
  return company.id === alliance.partnerCompany.id;
};

/**
 * Validate if companySubscription cancelAtPeriodEnd is false and company is active to show the cancel button.
 *
 * @param {object} companySubscription - The companySubscription to check.
 * @returns {boolean} Boolean.
 */
export const showCancelSubscription = (companySubscription) => {
  const { cancelAtPeriodEnd, status } = companySubscription;

  return cancelAtPeriodEnd === false && status === COMPANY_SUBSCRIPTION_ACTIVE;
};

/**
 * Validate if companySubscription cancelAtPeriodEnd is true and company is active to show the cancel button.
 *
 * @param {object} companySubscription - The companySubscription to check.
 * @returns {boolean} Boolean.
 */
export const showReactivateSubscription = (companySubscription) => {
  const { cancelAtPeriodEnd, status } = companySubscription;

  return cancelAtPeriodEnd === true && status === COMPANY_SUBSCRIPTION_ACTIVE;
};

/**
 * Validate if companySubscription status is canceled or active.
 *
 * @param {object} companySubscription - The companySubscription to check.
 * @returns {boolean} Boolean.
 */
export const showRenewSubscription = (companySubscription) => {
  const { status } = companySubscription;
  return status === COMPANY_SUBSCRIPTION_CANCELED || status === COMPANY_SUBSCRIPTION_UNPAID;
};

/**
 * Validate if company has no portFolioOwners.
 *
 * @param {object} companyUsers - The companyUsers list.
 * @returns {boolean} Boolean.
 */
export const showInvitePortfolioOwnersButton = (companyUsers) => {
  for (const companyUser of companyUsers) {
    const { role } = companyUser;
    if (role && role.name === COMPANY_PORTFOLIO_OWNER) return false;
  }

  return true;
};

/**
 * Whether we are on the Wizard or not.
 *
 * @returns {boolean} If user is on Wizard.
 */
export const isUserOnWizard = () => {
  return window.location.href.indexOf('wizard') > -1;
};

export const getCompanyInvoices = (company) => {
  const companySubscriptions = company.companyCompanySubscriptionRelation.items;

  const invoices = companySubscriptions.flatMap((subscription) => {
    const subscriptionInvoices = subscription.companyInvoice_companySubscriptionRelation.items;

    // Set The planName to every invoice
    subscriptionInvoices.forEach((invoice) => (invoice.plan = subscription.subscriptionPlan.name));

    return subscriptionInvoices;
  });

  // Sort invoices DESC by date
  invoices.sort((inv1, inv2) => {
    const date1 = moment(inv1);
    const date2 = moment(inv2);
    if (date1 === date2) return 0;
    return date1 > date2 ? 1 : -1;
  });

  return invoices;
};
