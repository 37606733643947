import { DateTime } from 'luxon';
import moment from 'moment';

export const currentDay = DateTime.local()
  .toUTC(0)
  .set({ hour: 0, minute: 0, second: 0 });

export const getCurrentDayStart = () => currentDay;

export const getCurrentDayEnd = () => getCurrentDayStart().plus({ day: 1 });

export const getFirstYearDay = (year) => currentDay.set({ year, month: 1, day: 1 });

export const getLastYearDay = (year) => getFirstYearDay(year).plus({ year: 1 });

export const getFirstMonthDay = (date) =>
  date ? date.set({ day: 1 }) : currentDay.set({ day: 1 });

export const getLastMonthDay = (date) =>
  date
    ? getFirstMonthDay(date).plus({ month: 1 })
    : getFirstMonthDay(currentDay).plus({ month: 1 });
export const getFirstCurrentMonthDay = () => currentDay.set({ day: 1 });

export const getLastCurrentMonthDay = () => getFirstCurrentMonthDay().plus({ month: 1 });

export const getFirstCurrentWeekDay = () =>
  currentDay.set({ day: currentDay.day - currentDay.weekday + 1 });

export const getLastCurrentWeekDay = () => getFirstCurrentWeekDay().plus({ day: 7 });

export const getFirstQuarterMonth = (year, quarter) => {
  const date = moment(year, 'YYYY')
    .quarter(quarter)
    .startOf('quarter');
  return currentDay.set({ year: date.year(), month: date.month(), day: date.day() });
};

export const getLastQuarterMonth = (year, quarter) => {
  const date = moment(year, 'YYYY')
    .quarter(quarter)
    .endOf('quarter');
  return currentDay.set({ year: date.year(), month: date.month(), day: date.day() });
};
