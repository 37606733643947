import Flux from '@cobuildlab/flux-state';

/**
 * Event that triggers a Business Cases error.
 *
 * @type {string}
 */
export const BUSINESS_CASE_ERROR_EVENT = 'onBusinessCaseError';

/**
 * Event that triggers a Business Cases List event.
 *
 * @type {string}
 */
export const BUSINESS_CASE_LIST_EVENT = 'onBusinessCaseList';

/**
 * Event that triggers a Business Cases Detail event.
 *
 * @type {string}
 */
export const BUSINESS_CASE_DETAIL_EVENT = 'onBusinessCaseDetail';

/**
 * Event that triggers a Business Cases Create event.
 *
 * @type {string}
 */
export const BUSINESS_CASE_CREATE_EVENT = 'onBusinessCaseCreate';

/**
 * Event that triggers a Business Cases Update event.
 *
 * @type {string}
 */
export const BUSINESS_CASE_UPDATE_EVENT = 'onBusinessCaseUpdate';

/**
 * Event that triggers a Business Cases Delete event.
 *
 * @type {string}
 */
export const BUSINESS_CASE_DELETE_EVENT = 'onBusinessCaseDelete';

/**
 * Hold the BusinessCase Data.
 *
 */
class BusinessCaseStore extends Flux.DashStore {
  constructor() {
    super();
    this.addEvent(BUSINESS_CASE_ERROR_EVENT);
    this.addEvent(BUSINESS_CASE_LIST_EVENT);
    this.addEvent(BUSINESS_CASE_CREATE_EVENT);
    this.addEvent(BUSINESS_CASE_DETAIL_EVENT);
    this.addEvent(BUSINESS_CASE_UPDATE_EVENT);
    this.addEvent(BUSINESS_CASE_DELETE_EVENT);
  }
}

export default new BusinessCaseStore();
