const ITEM_TYPES_LIST = [
  {
    label: 'Risks',
    value: 'Risk',
  },
  {
    label: 'Action items',
    value: 'Action',
  },
  {
    label: 'Issues',
    value: 'Issue',
  },
  {
    label: 'Decisions',
    value: 'Decision',
  },
  {
    label: 'Deals',
    value: 'DealData',
  },
  {
    label: 'Funding Request',
    value: 'FundingRequest',
  },
  {
    label: 'Contributions',
    value: 'Contribution',
  },
  {
    label: 'Idea',
    value: 'Idea',
  },
];

export { ITEM_TYPES_LIST };
