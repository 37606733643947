import React from 'react';
import { Dialog, Text, Row } from '@8base/boost';
import PropTypes from 'prop-types';
import { TransparentButton } from '../../../components/buttons/TransparentButton';
import { CancelPlanDetailsCard } from './CancelPlanDetailsCard';
import { DangerButton } from '../../../components/buttons/DangerButton';
import styled from '@emotion/styled';
import moment from 'moment';

const Note = styled(Text)`
  color: #a5afb6;
  margin-top: 5px;
`;

const MainText = styled(Text)`
  margin-bottom: 5px;
`;

const BottomText = styled(Text)`
  margin-top: 5px;
`;

const CancelSubscriptionDialog = ({
  text,
  note,
  isOpen,
  title,
  onClose,
  onCloseText,
  onYes,
  onYesText,
  companySubscription,
  loading,
}) => {
  const { expireDate, subscriptionPlan } = companySubscription;
  const expireDateText = moment(expireDate).format('L');

  return (
    <Dialog size="md" isOpen={isOpen}>
      <Dialog.Header title={title} onClose={onClose} />
      <Dialog.Body>
        <MainText>{text}</MainText>
        <CancelPlanDetailsCard plan={subscriptionPlan} />
        <BottomText>
          <Note>{note}</Note>
          <Text weight="bold">{expireDateText}</Text>
          {'.'}
        </BottomText>
      </Dialog.Body>
      <Dialog.Footer>
        <Row alignItems="center" justifyContent="end">
          <TransparentButton text={onCloseText} onClick={onClose} />
          <DangerButton loading={loading} text={onYesText} onClick={onYes} fontAwesomeIcon={''} />
        </Row>
      </Dialog.Footer>
    </Dialog>
  );
};

CancelSubscriptionDialog.defaultProps = {
  onCloseText: 'No, Do not cancel',
  onYesText: 'Yes, Cancel',
  title: 'Cancel Subscription',
  text:
    'Are you sure that you want to cancel? Your subscription will be canceled for the following workspace:',
  note: 'You will still have full access to your workspace until ',
};

CancelSubscriptionDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  onYes: PropTypes.func.isRequired,
  title: PropTypes.string,
  text: PropTypes.string,
  note: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  onCloseText: PropTypes.string,
  onYesText: PropTypes.string,
  companySubscription: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
};

export { CancelSubscriptionDialog };
