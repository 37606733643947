import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { Text } from '@8base/boost';

const StyledOverline1 = styled(Text)`
  font-family: Poppins;
  font-weight: 400;
  font-size: 12px;
  letter-spacing: 0.5px;
  line-height: 16px;
  color: #7d828c;
  & first-letter {
    text-transform: capitalize;
  }
`;

const Overline1 = ({ children }) => {
  return <StyledOverline1>{children}</StyledOverline1>;
};

Overline1.propTypes = {
  children: PropTypes.any.isRequired,
};

export { Overline1 };
