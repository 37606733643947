import Flux from '@cobuildlab/flux-state';
import {
  ITEMS_EVENT,
  RELATED_ITEM_CREATE_EVENT,
  RELATED_ITEM_ERROR_EVENT,
  RELATED_ITEM_REMOVE_EVENT,
  RELATED_ITEM_REQUEST_EVENT,
  RELATED_ITEMS_EVENT,
  RELATED_ITEMS_BY_ITEM_EVENT,
} from './related-item-store';
import sessionStore, { APOLLO_CLIENT, NEW_SESSION_EVENT } from '../../shared/SessionStore';
import { error, log } from '@cobuildlab/pure-logger';
import {
  ITEMS_LIST_QUERY,
  RELATED_ITEM_UPDATE_MUTATION,
  RELATED_ITEMS_LIST_QUERY,
  ITEMS_LIST_BY_ITEM_ID_QUERY,
} from './related-item-queries';
import {
  ACTION_TYPE,
  CONTRIBUTION_TYPE,
  DEAL_TYPE,
  DECISION_TYPE,
  FUNDING_REQUEST_TYPE,
  IDEA_TYPE,
  ISSUE_TYPE,
  RISK_TYPE,
} from '../../shared/item-types';
import { IntegrityError } from '../../shared/errors';
import {
  OnRelatedItemError,
  OnRelatedItemsByItem,
  OnRelatedItems,
  OnItems,
} from './related-item-events';

/**
 * Dispatch a Request for seen the related Items.
 *
 * @param {string }itemId - Item id.
 */
export const openRelatedItems = (itemId) => {
  Flux.dispatchEvent(RELATED_ITEM_REQUEST_EVENT, { itemId });
};

/**
 * Filter for related items.
 *
 * @returns {object} [description].
 */
const relatedItemsFilter = () => {
  return {
    OR: [
      { action: { id: { not_equals: null } } },
      { issue: { id: { not_equals: null } } },
      { risk: { id: { not_equals: null } } },
      { decision: { id: { not_equals: null } } },
      { contribution: { id: { not_equals: null } } },
      { fundingRequest: { id: { not_equals: null } } },
      { idea: { id: { not_equals: null } } },
      { dealData: { id: { not_equals: null } } },
    ],
  };
};

/**
 * Fetches the Related Items.
 *
 * @returns {Promise<void>} - Promise.
 * @param { string }itemId - Item id.
 */
export const fetchRelatedItems = async (itemId) => {
  const client = sessionStore.getState(APOLLO_CLIENT);
  const filter = relatedItemsFilter();

  let response;
  try {
    response = await client.query({
      query: RELATED_ITEMS_LIST_QUERY,
      variables: { filter, id: itemId },
      fetchPolicy: 'network-only',
    });
  } catch (e) {
    error('fetchRelatedItems', e);
    OnRelatedItemError.dispatch(e);
    return Flux.dispatchEvent(RELATED_ITEM_ERROR_EVENT, e);
  }
  log('fetchRelatedItems', response);
  OnRelatedItems.dispatch(response.data);
  Flux.dispatchEvent(RELATED_ITEMS_EVENT, response.data);
  return response.data;
};

export const fetchRelatedItemsByItemId = async (itemId) => {
  //ITEMS_LIST_BY_ITEM_ID_QUERY
  const { id: allianceId } = sessionStore.getState(NEW_SESSION_EVENT).selectedAlliance;

  const client = sessionStore.getState(APOLLO_CLIENT);
  const filter = {
    alliance: { id: { equals: allianceId } },
    itemsRelated: { some: { id: { equals: itemId } } },
  };
  let response;
  try {
    response = await client.query({
      query: ITEMS_LIST_BY_ITEM_ID_QUERY,
      variables: { data: filter },
      fetchPolicy: 'network-only',
    });
  } catch (e) {
    error('fetchRelatedItemsByItem', e);
    OnRelatedItemError.dispatch(e);
    return Flux.dispatchEvent(RELATED_ITEM_ERROR_EVENT, e);
  }
  log('fetchRelatedItemsByItem', response);
  OnRelatedItemsByItem.dispatch(response.data);
  Flux.dispatchEvent(RELATED_ITEMS_BY_ITEM_EVENT, response.data);
  return response.data;
};

/**
 * Adds a Related Item to an Item.
 *
 * @param {string} itemId - Item id.
 * @param {Array} relatedItems - Related Items.
 * @returns {Promise<*>} - Promise.
 */
export const createRelatedItems = async (itemId, relatedItems) => {
  const client = sessionStore.getState(APOLLO_CLIENT);
  const data = { id: itemId, itemsRelated: { connect: [] } };
  const relations = [];

  if (relatedItems.length === 0)
    return Flux.dispatchEvent(
      RELATED_ITEM_ERROR_EVENT,
      new IntegrityError('Need to select at least one item to add'),
    );

  relatedItems.forEach((item) => {
    relations.push({ id: item.itemId });
  });

  data.itemsRelated.connect = relations;
  console.log('Test', data);
  let response;

  try {
    response = await client.mutate({
      mutation: RELATED_ITEM_UPDATE_MUTATION,
      variables: { data },
    });
  } catch (e) {
    console.error('createRelatedItems', e, itemId);
    return Flux.dispatchEvent(RELATED_ITEM_ERROR_EVENT, e);
  }

  Flux.dispatchEvent(RELATED_ITEM_CREATE_EVENT, response.data);
  return response.data;
};

export const relatedItemsSearchFilter = (term, types, itemData) => {
  const { id: allianceId } = sessionStore.getState(NEW_SESSION_EVENT).selectedAlliance;
  const OR = [];

  types.forEach((type) => {
    if (type === FUNDING_REQUEST_TYPE) {
      const item = {
        alliance: { id: { equals: allianceId } },
        fundingRequest: { id: { not_equals: null } },
      };
      if (term !== '') item.fundingRequest.name = { contains: term };
      if (itemData && itemData.type === 'Funding Request')
        item.fundingRequest = { AND: { id: { not_equals: itemData.id } } };
      OR.push(item);
    }
    if (type === CONTRIBUTION_TYPE) {
      const item = {
        alliance: { id: { equals: allianceId } },
        contribution: { id: { not_equals: null } },
      };
      if (term !== '') item.contribution.name = { contains: term };
      if (itemData && itemData.type === 'Contribution')
        item.contribution.AND = { id: { not_equals: itemData.id } };

      OR.push(item);
    }
    if (type === ACTION_TYPE) {
      const item = {
        alliance: { id: { equals: allianceId } },
        action: { id: { not_equals: null } },
      };
      if (term !== '') item.action.name = { contains: term };
      if (itemData && itemData.type === 'Action')
        item.action.AND = { id: { not_equals: itemData.id } };

      OR.push(item);
    }
    if (type === DECISION_TYPE) {
      const item = {
        alliance: { id: { equals: allianceId } },
        decision: { id: { not_equals: null } },
      };
      if (term !== '') item.decision.name = { contains: term };
      if (itemData && itemData.type === 'Decision')
        item.decision.AND = { id: { not_equals: itemData.id } };

      OR.push(item);
    }
    if (type === RISK_TYPE) {
      const item = { alliance: { id: { equals: allianceId } }, risk: { id: { not_equals: null } } };
      if (term !== '') item.risk.name = { contains: term };
      if (itemData && itemData.type === 'Risk') item.risk.AND = { id: { not_equals: itemData.id } };

      OR.push(item);
    }
    if (type === IDEA_TYPE) {
      const item = { alliance: { id: { equals: allianceId } }, idea: { id: { not_equals: null } } };
      if (term !== '') item.idea.name = { contains: term };
      if (itemData && itemData.type === 'Idea') item.idea.AND = { id: { not_equals: itemData.id } };

      OR.push(item);
    }
    if (type === ISSUE_TYPE) {
      const item = {
        alliance: { id: { equals: allianceId } },
        issue: { id: { not_equals: null } },
      };
      if (term !== '') item.issue.name = { contains: term };
      if (itemData && itemData.type === 'Issue')
        item.issue.AND = { id: { not_equals: itemData.id } };

      OR.push(item);
    }
    if (type === DEAL_TYPE) {
      const item = {
        alliance: { id: { equals: allianceId } },
        dealData: { id: { not_equals: null } },
      };
      if (term !== '') item.dealData.name = { contains: term };
      if (itemData && itemData.type === 'DealDatum')
        item.dealData.AND = { id: { not_equals: itemData.id } };

      OR.push(item);
    }
  });

  return OR;
};

export const searchItems = async (term, types, itemData) => {
  const client = sessionStore.getState(APOLLO_CLIENT);
  const OR = relatedItemsSearchFilter(term, types, itemData);
  let response;
  try {
    response = await client.query({
      query: ITEMS_LIST_QUERY,
      variables: { data: { OR } },
      fetchPolicy: 'network-only',
    });
  } catch (e) {
    error('searchItems', e);
    OnRelatedItemError.dispatch(e);
    return Flux.dispatchEvent(RELATED_ITEM_ERROR_EVENT, e);
  }
  log('searchItems', response.data);
  OnItems.dispatch(response.data);
  Flux.dispatchEvent(ITEMS_EVENT, response.data);
  return response.data;
};

/**
 * Removes a Related Item from an Item.
 *
 * @param {string} itemId - Item id.
 * @param {Array} relatedItem - Related items.
 * @returns {Promise<*>} Promise.
 */
export const removeRelatedItems = async (itemId, relatedItem) => {
  const client = sessionStore.getState(APOLLO_CLIENT);
  const data = { id: itemId, itemsRelated: { disconnect: [] } };
  const relations = [];

  relatedItem.forEach((item) => {
    relations.push({ id: item.itemId });
  });

  data.itemsRelated.disconnect = relations;
  let response;
  try {
    response = await client.mutate({
      mutation: RELATED_ITEM_UPDATE_MUTATION,
      variables: { data },
    });
  } catch (e) {
    console.error('removeRelatedItems', e, itemId);
    return Flux.dispatchEvent(RELATED_ITEM_ERROR_EVENT, e);
  }

  Flux.dispatchEvent(RELATED_ITEM_REMOVE_EVENT, response.data);
  return response.data;
};
