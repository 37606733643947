import sessionStore, { NEW_SESSION_EVENT } from './SessionStore';
import { ALLIANCE_ADMINISTRATOR, ALLIANCE_CREATOR, ALLIANCE_SER, getCompanyRoles } from './roles';
import { ALLIANCE_APPROVAL_PENDING } from './status';
import { isValidString } from './validators';

/**
 * Extracts the Role of a User on an Alliance, null if it does not have one.
 *
 * @param {object} user - The User.
 * @param {object} alliance - The Alliance.
 * @returns {null|*} Null.
 */
export const getRoleOnAlliance = (user, alliance) => {
  if (!alliance) {
    return null;
  }

  for (let i = 0; i < user.companyUserRelation.items.length; i++) {
    const companyUser = user.companyUserRelation.items[i];
    for (let j = 0; j < companyUser.allianceUserCompanyUserRelation.items.length; j++) {
      const allianceUser = companyUser.allianceUserCompanyUserRelation.items[j];
      if (allianceUser.alliance.id === alliance.id) {
        return allianceUser.role;
      }
    }
  }
  return null;
};
/**
 * Extracts the Roles of a User in an Alliance.
 *
 * @param {object} user - The User.
 * @param {object} alliance - The Alliance.
 * @returns {null|*} Null.
 */
export const getRolesOnAlliance = (user, alliance) => {
  if (!alliance || !user) {
    return null;
  }

  return user.companyUserRelation.items.flatMap((companyUser) =>
    companyUser.allianceUserCompanyUserRelation.items
      .filter((allianceUser) => allianceUser.alliance.id === alliance.id)
      .map((allianceUser) => allianceUser.role.name),
  );
};

/**
 * Extracts the Alliance of a Logged User.
 *
 * @returns {null|string} The active alliance id.
 */
export const getActiveAllianceId = () => {
  const { selectedAlliance } = sessionStore.getState(NEW_SESSION_EVENT);
  if (selectedAlliance) return selectedAlliance.id;
  return null;
};

/**
 * Extracts the company of a User on an Alliance, null if it does not have one.
 *
 * @param {object} user - The User.
 * @param {object} alliance - The Alliance.
 * @returns {null|*} Null.
 */
export const getCompanyOnAlliance = (user, alliance) => {
  for (let i = 0; i < user.companyUserRelation.items.length; i++) {
    const companyUser = user.companyUserRelation.items[i];
    for (let j = 0; j < companyUser.allianceUserCompanyUserRelation.items.length; j++) {
      const allianceUser = companyUser.allianceUserCompanyUserRelation.items[j];
      if (allianceUser.alliance && allianceUser.alliance.id === alliance.id) {
        return companyUser.company;
      }
    }
  }
  return user.companyUserRelation.items.length ? user.companyUserRelation.items[0].company : null;
};

/**
 * Return the currency on the selected alliance, or null if there is no selected alliance.
 *
 * @returns {*}Object.
 */
export const getCurrencyOnSession = () => {
  const { selectedAlliance } = sessionStore.getState(NEW_SESSION_EVENT);
  return !selectedAlliance ? null : selectedAlliance.currency;
};

/**
 * Formats an alliance to be updated.
 *
 * @param {object} alliance - Alliance retrieved from 8base via apollo.
 */
export const formatAllianceToUpdate = (alliance) => {
  delete alliance.businessCase;
  delete alliance.allianceKPIAllianceRelation;
  delete alliance.allianceInvitationRelation;
  delete alliance.allianceApprovalRelation;
  delete alliance.allianceForecastAllianceRelation;
  delete alliance.__typename;
  delete alliance.createdAt;
  delete alliance.createdBy;
  delete alliance.owner;
};

/**
 * Formats a businessCase to be updated.
 *
 * @param {object} businessCase - BusinessCase retrieved from 8base via apollo.
 */
export const formatBusinessCaseToUpdate = (businessCase) => {
  delete businessCase.owner;
  delete businessCase.createdAt;
  delete businessCase.createdBy;
  delete businessCase.__typename;
  if (!isValidString(businessCase.backgroundSummary, true)) businessCase.backgroundSummary = '';
  if (!isValidString(businessCase.marketAnalysis, true)) businessCase.marketAnalysis = '';
  if (!isValidString(businessCase.salesMarketingStrategy, true))
    businessCase.salesMarketingStrategy = '';
  if (!isValidString(businessCase.visionMissionStrategy, true))
    businessCase.visionMissionStrategy = '';
  if (!isValidString(businessCase.risks, true)) businessCase.risks = '';
  if (!isValidString(businessCase.recommendedSolution, true)) businessCase.recommendedSolution = '';
};

/**
 * Concat the clientCompany and partnerCompany users and returns the result
 * validate if there is no client or partner company.
 *
 * @param  {object} clientCompany -  [description].
 * @param  {object} partnerCompany - [description].
 * @returns {Array}  Members of both companies.
 */
export const concatClientAndPartnerUsers = (clientCompany, partnerCompany) => {
  return concatClientAndPartnerCompanyUsers(clientCompany, partnerCompany).map(
    (companyUser) => companyUser.user,
  );
};

/**
 * Concat the clientCompany and partnerCompany users and returns the result
 * validate if there is no client or partner company.
 *
 * @param  {object} clientCompany -  Client Company.
 * @param  {object} partnerCompany - Partner Company.
 * @returns {object[]} CompanyUsers.
 */
export const concatClientAndPartnerCompanyUsers = (clientCompany, partnerCompany) => {
  const extractUsersFromCompany = (company) => (company && company.allianceUsers) || [];

  const clientUsers = extractUsersFromCompany(clientCompany);
  const partnerUsers = extractUsersFromCompany(partnerCompany);

  return [...clientUsers, ...partnerUsers];
};

/**
 * Returns true if the User has the role or any the roles
 * in the provided Alliance.
 *
 * @param {object} user - The user.
 * @param {object} alliance - The Alliance.
 * @param {string|string[]} role - Role name or array or Role names.
 * @returns {boolean|*}Boolean.
 */
export const userHasAnyRoleInAlliance = (user, alliance, role) => {
  const userRole = getRoleOnAlliance(user, alliance);
  if (!userRole) return false;

  if (Array.isArray(role)) {
    return role.includes(userRole.name);
  }
  return role === userRole.name;
};

/**
 * Returns true if the User has the role
 * of Alliance Administrator or Alliance SER in the Alliance.
 *
 * @param {object} user - The user.
 * @param {object} alliance - The Alliance.
 * @returns {boolean|*} Boolean.
 */
export const isUserAdminOrSERInAlliance = (user, alliance) => {
  return userHasAnyRoleInAlliance(user, alliance, [ALLIANCE_ADMINISTRATOR, ALLIANCE_SER]);
};

/**
 * Returns true if the User has the role
 * of Alliance CREATOR in the Alliance.
 *
 * @param {object} user - The user.
 * @param {object} alliance - The Alliance.
 * @returns {boolean|*} Boolean.
 */
export const isUserCreatorInAlliance = (user, alliance) => {
  return userHasAnyRoleInAlliance(user, alliance, [ALLIANCE_CREATOR]);
};

/**
 * Returns true if the User has the role
 * of Alliance SER in the provided Alliance.
 *
 * @param {object} user - The user.
 * @param {object} alliance - The Alliance.
 * @returns {boolean|*} Boolean.
 */
export const isUserAllianceSER = (user, alliance) => {
  return userHasAnyRoleInAlliance(user, alliance, ALLIANCE_SER);
};

/**
 * Returns true if the User has the role
 * of Alliance Administrator in the provided Alliance.
 *
 * @param {object} user - The user.
 * @param {object} alliance - The Alliance.
 * @returns {boolean|*} Boolean.
 */
export const isUserAllianceAdmin = (user, alliance) => {
  return userHasAnyRoleInAlliance(user, alliance, ALLIANCE_ADMINISTRATOR);
};

/**
 * Return true if the User's company's approval or rejection
 * of the Alliance is pending.
 *
 * @param {object} user - The user.
 * @param {object} alliance - The Alliance.
 * @returns {boolean} Boolean.
 */
export const isUserPendingApprovalOfAlliance = (user, alliance) => {
  const { allianceApprovalRelation } = alliance;
  const userCompany = getCompanyOnAlliance(user, alliance);

  const userCompanyIsPendingResponse = allianceApprovalRelation.items.find(
    (approval) =>
      userCompany.id === approval.company.id && approval.status === ALLIANCE_APPROVAL_PENDING,
  );

  return !!userCompanyIsPendingResponse;
};

/**
 * If User is from client company.
 *
 * @param {object}user - User.
 * @param {object}alliance - Alliance.
 * @returns {boolean}Boolean.
 */
export const isUserFromClientCompany = (user, alliance) => {
  const companyId = alliance.clientCompany.id;
  const { companyUserRelation } = user;
  return !!companyUserRelation.items.find((item) => item.company.id === companyId);
};

/**
 * Gets the Roles of the first company of the User sorted by relevance.
 * The relevance is given by roles.js:COMPANY_ROLES.
 *
 * @param {object} user - The User to check the roles.
 * @returns {[]|*[]} - The list of roles for the User in the First Company.
 */
export const getFirstCompanyRoles = (user) => {
  const { items } = user.companyUserRelation;
  if (items.length === 0) return [];

  const firstCompanyId = items[0].company.id;
  const roles = [];
  for (const companyUser of items) {
    if (companyUser.company.id === firstCompanyId) {
      roles.push(companyUser.role.name);
    }
  }
  roles.sort(_compareCompanyRoles);
  return roles;
};

const _compareCompanyRoles = (a, b) => {
  const ROLES = getCompanyRoles();
  let aOrder = 0;
  let bOrder = 0;
  for (let i = 0, j = ROLES.length; i < j; i++) {
    if (ROLES[i].name === a) aOrder = i;
    if (ROLES[i].name === b) bOrder = i;
  }
  if (aOrder < bOrder) return -1;
  if (aOrder > bOrder) return 1;
  return 0;
};

/**
 * Determine if a Business Item is ready to be approved.
 *
 * @param {number} count - Previous number of approvals.
 * @returns {boolean} - Is Ready?
 */
export const isApprovalComplete = (count) => {
  const { selectedAlliance } = sessionStore.getState(NEW_SESSION_EVENT);
  const { oneSided } = selectedAlliance;

  const numApprovalsNeeded = oneSided ? 1 : 2;

  return count + 1 >= numApprovalsNeeded;
};
