import * as R from 'ramda';

import check from '../../images/icons/check-mark-outlined.svg';
import action from '../../images/icons/notifications/action.svg';
import contribution from '../../images/icons/notifications/contribution.svg';
import deal from '../../images/icons/notifications/deal.svg';
import decision from '../../images/icons/notifications/decisions.svg';
import fundingRequest from '../../images/icons/notifications/funding-request.svg';
import idea from '../../images/icons/notifications/ideas.svg';
import initiative from '../../images/icons/notifications/initiatives.svg';
import issue from '../../images/icons/notifications/issue.svg';

/**
 * Extracts the information of the Notification depending of the Type.
 *
 * @param {object} notification - Notification.
 * @returns {object} - Notifications.
 */
export const getNotificationByType = (notification) => {
  const _notification = {
    id: notification.id,
    message: notification.message,
    createdAt: notification.createdAt,
    icon: check,
  };
  if (notification.action !== null && notification.action !== undefined) {
    if (notification.action.businessCase === null)
      return {
        ..._notification,
        type: 'Action',
        url: 'management/action-without-business-case',
        objectId: notification.action.id,
        name: notification.action.name,
        status: notification.action.status,
        revisedDueDate: notification.action.revisedDueDate,
        originalDueDate: notification.action.originalDueDate,
        owner: `${R.pathOr('', ['action', 'owner', 'firstName'], notification)} ${R.pathOr(
          '',
          ['action', 'owner', 'lastName'],
          notification,
        )}`,
        icon: action,
      };
    return {
      ..._notification,
      type: 'Action',
      url: 'management/action',
      objectId: notification.action.id,
      name: notification.action.name,
      status: notification.action.status,
      revisedDueDate: notification.action.revisedDueDate,
      originalDueDate: notification.action.originalDueDate,
      owner: `${R.pathOr('', ['action', 'owner', 'firstName'], notification)} ${R.pathOr(
        '',
        ['action', 'owner', 'lastName'],
        notification,
      )}`,
      icon: action,
    };
  } else if (notification.idea !== null && notification.idea !== undefined)
    return {
      ..._notification,
      type: 'Idea',
      url: 'management/idea',
      objectId: notification.idea.id,
      name: notification.idea.name,
      status: notification.idea.status,
      revisedDueDate: notification.idea.revisedDueDate,
      originalDueDate: notification.idea.originalDueDate,
      owner: `${R.pathOr('', ['idea', 'owner', 'firstName'], notification)} ${R.pathOr(
        '',
        ['idea', 'owner', 'lastName'],
        notification,
      )}`,
      icon: idea,
    };
  else if (notification.issue !== null && notification.issue !== undefined)
    return {
      ..._notification,
      type: 'Issue',
      url: 'management/issue',
      objectId: notification.issue.id,
      name: notification.issue.name,
      status: notification.issue.status,
      revisedDueDate: notification.issue.revisedDueDate,
      originalDueDate: notification.issue.originalDueDate,
      owner: `${R.pathOr('', ['issue', 'owner', 'firstName'], notification)} ${R.pathOr(
        '',
        ['issue', 'owner', 'lastName'],
        notification,
      )}`,
      icon: issue,
    };
  else if (notification.decision !== null && notification.decision !== undefined) {
    return {
      ..._notification,
      type: 'Decision',
      url: 'management/decision',
      objectId: notification.decision.id,
      name: notification.decision.name,
      status: notification.decision.status,
      owner: `${R.pathOr('', ['decision', 'owner', 'firstName'], notification)} ${R.pathOr(
        '',
        ['decision', 'owner', 'lastName'],
        notification,
      )}`,
      icon: decision,
    };
  } else if (notification.risk !== null && notification.risk !== undefined)
    return {
      ..._notification,
      type: 'Risk',
      url: 'management/risk',
      objectId: notification.risk.id,
      name: notification.risk.name,
      status: notification.risk.status,
      revisedDueDate: notification.risk.revisedDueDate,
      originalDueDate: notification.risk.originalDueDate,
      owner: `${R.pathOr('', ['risk', 'owner', 'firstName'], notification)} ${R.pathOr(
        '',
        ['risk', 'owner', 'lastName'],
        notification,
      )}`,
      icon: check,
    };
  else if (notification.fundingRequest !== null && notification.fundingRequest !== undefined)
    return {
      ..._notification,
      type: 'Funding Request',
      url: 'management/funding-request',
      objectId: notification.fundingRequest.id,
      name: notification.fundingRequest.name,
      status: notification.fundingRequest.status,
      revisedDueDate: notification.fundingRequest.revisedDueDate,
      originalDueDate: notification.fundingRequest.originalDueDate,
      owner: `${R.pathOr('', ['fundingRequest', 'owner', 'firstName'], notification)} ${R.pathOr(
        '',
        ['fundingRequest', 'owner', 'lastName'],
        notification,
      )}`,
      icon: fundingRequest,
    };
  else if (notification.contribution !== null && notification.contribution !== undefined) {
    return {
      ..._notification,
      type: 'Contribution',
      url: 'management/contribution',
      objectId: notification.contribution.id,
      name: notification.contribution.name,
      status: notification.contribution.status,
      owner: `${R.pathOr('', ['contribution', 'owner', 'firstName'], notification)} ${R.pathOr(
        '',
        ['contribution', 'owner', 'lastName'],
        notification,
      )}`,
      icon: contribution,
    };
  } else if (notification.dealData !== null && notification.dealData !== undefined)
    return {
      ..._notification,
      type: 'Deal',
      url: 'management/deal',
      objectId: notification.dealData.id,
      name: notification.dealData.name,
      status: notification.dealData.status,
      owner: `${R.pathOr('', ['dealData', 'owner', 'firstName'], notification)} ${R.pathOr(
        '',
        ['dealData', 'owner', 'lastName'],
        notification,
      )}`,
      icon: deal,
    };
  else if (notification.initiative !== null && notification.initiative !== undefined)
    return {
      ..._notification,
      type: 'Initiative',
      url: 'management/initiative',
      objectId: notification.initiative.id,
      name: notification.initiative.name,
      status: notification.initiative.status,
      owner: `${R.pathOr('', ['initiative', 'owner', 'firstName'], notification)} ${R.pathOr(
        '',
        ['initiative', 'owner', 'lastName'],
        notification,
      )}`,
      icon: initiative,
    };
  else if (notification.alliance !== null && notification.alliance !== undefined)
    return {
      ..._notification,
      type: 'Alliance',
      url: 'settings/alliance-management',
      objectId: notification.alliance.id,
      name: notification.alliance.name,
      status: notification.alliance.status,
      owner: `${R.pathOr('', ['alliance', 'owner', 'firstName'], notification)} ${R.pathOr(
        '',
        ['alliance', 'owner', 'lastName'],
        notification,
      )}`,
      icon: check,
    };
  else if (notification.nextStep !== null) {
    let url = '';
    let objectId = '';
    let name = '';
    if (notification.nextStep.ideaNextStepsRelation !== null) {
      url = 'management/idea';
      objectId = notification.nextStep.ideaNextStepsRelation.id;
      name = notification.nextStep.ideaNextStepsRelation.name;
    } else if (notification.nextStep.fundingRequestNextStepsRelation !== null) {
      url = 'management/funding-request';
      objectId = notification.nextStep.fundingRequestNextStepsRelation.id;
      name = notification.nextStep.fundingRequestNextStepsRelation.name;
    } else if (notification.nextStep.actionNextStepsRelation !== null) {
      url = 'management/action';
      objectId = notification.nextStep.actionNextStepsRelation.id;
      name = notification.nextStep.actionNextStepsRelation.name;
    } else if (notification.nextStep.issueNextStepsRelation !== null) {
      url = 'management/issue';
      objectId = notification.nextStep.issueNextStepsRelation.id;
      name = notification.nextStep.issueNextStepsRelation.name;
    }

    const owner = notification.nextStep.assignedTo
      ? `${notification.nextStep.assignedTo.firstName} ${notification.nextStep.assignedTo.lastName}`
      : null;

    return {
      ..._notification,
      type: 'Next Step',
      url: url,
      objectId: objectId,
      owner: owner,
      name: name,
    };
  } else throw new Error('getNotificationByType: Unknown notification');
};
