import React from 'react';
import * as R from 'ramda';
import { View } from '@cobuildlab/react-simple-state';
import { onErrorMixin } from '../../../shared/mixins';
import AuditLogTable from './components/AuditLogTable';
import { ListCardBody } from '../../../components/card/ListCardBody';
import { Card, Grid, Heading, Pagination } from '@8base/boost';
import { fetchAuditLog, auditLogUserList } from './audit-log-actions';
import ItemFilter from '../../../components/ItemFilter';
import {
  AUDIT_ACTION_TYPE_OPTIONS,
  AUDIT_OBJECT_TYPE_OPTIONS,
} from '../utils/auditLogFilterOptions';
import { OnAuditLogError, OnAuditLogList, OnAuditLogUserList } from './audit-log-events';

/**
 * View for the Audit logs.
 *
 */

class AuditLogView extends View {
  constructor(props) {
    super(props);
    this.state = {
      auditLogsList: [],
      loading: true,
      isFullTable: false,
      allianceMembersOptions: [],
      userFilter: null,
      objectTypeFilter: null,
      actionTypeFilter: null,
      count: null,
      page: 1,
    };
    this.onError = onErrorMixin.bind(this);
  }

  componentDidMount() {
    this.subscribe(OnAuditLogError, this.onError);
    this.subscribe(OnAuditLogList, (state) => {
      const { count } = R.clone(state.auditLogsList);
      const auditLogsList = state.auditLogsList.items.map((auditLog) => ({
        ...auditLog,
      }));

      this.setState({
        count,
        auditLogsList,
        loading: false,
      });
    });

    this.subscribe(OnAuditLogUserList, (state) => {
      const { auditLogsList } = state;
      const allianceMembersOptions = auditLogsList.groups.map((member) => {
        return {
          label: `${member.userFirstName} ${member.userLastName}`,
          value: member.userId,
        };
      });
      allianceMembersOptions.push({
        label: 'NONE',
        value: '',
      });
      this.setState({
        loading: false,
        allianceMembersOptions,
      });
    });

    fetchAuditLog();
    auditLogUserList();
  }

  userFilter = (value) => {
    const { objectTypeFilter, actionTypeFilter } = this.state;
    this.setState(
      {
        loading: true,
        userFilter: value,
      },
      () => {
        fetchAuditLog(actionTypeFilter, objectTypeFilter, value);
      },
    );
  };

  objectFilter = (value) => {
    const { userFilter, actionTypeFilter } = this.state;
    this.setState(
      {
        loading: true,
        objectTypeFilter: value,
      },
      () => {
        fetchAuditLog(actionTypeFilter, value, userFilter);
      },
    );
  };

  actionFilter = (value) => {
    const { userFilter, objectTypeFilter } = this.state;
    this.setState(
      {
        loading: true,
        actionTypeFilter: value,
      },
      () => {
        fetchAuditLog(value, objectTypeFilter, userFilter);
      },
    );
  };

  changePage = (value) => {
    const { actionTypeFilter, objectTypeFilter, userFilter } = this.state;
    let skip = (value - 1) * 10;

    this.setState(
      {
        loading: true,
        page: value,
      },
      () => {
        fetchAuditLog(actionTypeFilter, objectTypeFilter, userFilter, skip);
      },
    );
  };

  render() {
    const {
      loading,
      auditLogsList,
      allianceMembersOptions,
      userFilter,
      objectTypeFilter,
      actionTypeFilter,
      count,
      page,
    } = this.state;

    return (
      <div id={'c2gScreen'}>
        <Card stretch>
          <div className="items-card" style={{ height: '64vh', width: '60vw' }}>
            <Card.Header>
              <Grid.Layout
                columns="7fr 3fr 9fr 9fr 9fr 9fr"
                areas={[['left', 'left2', 'center', 'right', 'right-1', 'right-2']]}
                style={{ width: '100%' }}>
                <Grid.Box justifySelf="flex-start" area="left" className="list-box">
                  <Heading type="h4" text="Audit Logs" />
                </Grid.Box>

                <Grid.Box area="center" className="list-box">
                  <ItemFilter
                    onChange={this.userFilter}
                    value={userFilter}
                    options={allianceMembersOptions}
                    placeholder="Alliance Members"
                  />
                </Grid.Box>
                <Grid.Box area="right" className="list-box">
                  <ItemFilter
                    onChange={this.objectFilter}
                    value={objectTypeFilter}
                    options={AUDIT_OBJECT_TYPE_OPTIONS}
                    placeholder="Object Type"
                  />
                </Grid.Box>

                <Grid.Box area="right-1" className="list-box">
                  <ItemFilter
                    onChange={this.actionFilter}
                    value={actionTypeFilter}
                    options={AUDIT_ACTION_TYPE_OPTIONS}
                    placeholder="Action Type"
                  />
                </Grid.Box>
              </Grid.Layout>
            </Card.Header>
            <ListCardBody className="items-table">
              <AuditLogTable loading={loading} audiLogList={auditLogsList} />
            </ListCardBody>
          </div>
          <Card.Footer style={{ margin: 'auto' }}>
            <Pagination page={page} total={count} onChange={this.changePage} />
          </Card.Footer>
        </Card>
      </div>
    );
  }
}

export { AuditLogView };
