import React from 'react';
import PropTypes from 'prop-types';
import { Text } from '@8base/boost';
import styled from '@emotion/styled';

const Name = styled(Text)`
  box-shadow: none !important;
`;

const CompanyHeaderName = ({ name, ...rest }) => {
  return (
    <Name color="GRAY1" weight="bold" ellipsis {...rest}>
      {name}
    </Name>
  );
};

CompanyHeaderName.propTypes = {
  name: PropTypes.string.isRequired,
};

export default CompanyHeaderName;
