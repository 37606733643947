import { getFullCompanyName, initiativesToString } from '../utils';
import { ownerToString } from '../../../shared/utils';

const convertRelationToString = (name, data) => {
  switch (name) {
  case 'owner':
  case 'createdBy':
  case 'assignedTo':
  case 'requestedBy': {
    return ownerToString(data);
  }
  case 'company':
  case 'source': {
    return getFullCompanyName(data);
  }
  case 'initiatives': {
    return initiativesToString(data);
  }
  default:
    return {};
  }
};

export { convertRelationToString };
