import gql from 'graphql-tag';
import { UserFragment } from '../../auth/queries';
import { BusinessCaseFragment } from '../../document-management/business-case/businessCase.queries';

/*
 * List Query
 * Example:
 * {
    "data": {
      "alliance": {
        "id": {
          "equals": "cjouj32uc000h01rxv4nevfin"
        }.
      }.
    }.
  }.
 */
export const INVESTMENT_ITEM_LIST_QUERY = gql`
  query($data: ItemFilter, $skip: Int, $first: Int) {
    itemsList(filter: $data, skip: $skip, first: $first) {
      count
      items {
        id
        createdAt
        contribution {
          id
          name
          description
          status
          unitType
          unitQuantity
          unitMonetizationFactor
          calculatedValue
          unitValueDescription
          contributionDate
          professionalsTrained
          professionalsCertified
          source {
            id
            name
          }
          initiatives {
            items {
              id
              name
              status
            }
          }
          createdBy {
            ...UserFragment
          }
        }
        fundingRequest {
          id
          name
          description
          status
          revisedDueDate
          businessCase {
            ...BusinessCaseFragment
          }
          source {
            id
            name
          }
          requestedBy {
            ...UserFragment
          }
          initiatives {
            items {
              id
              name
              status
            }
          }
          fundingRequestApprovalRelation {
            items {
              dateOfResponse
              status
            }
          }
        }
      }
    }
  }
  ${UserFragment},
  ${BusinessCaseFragment}
`;
/**
 * Delete.
 */
export const INVESTMENT_ITEM_DELETE_MUTATION = gql`
  mutation($data: ItemDeleteInput!) {
    itemDelete(data: $data, force: true) {
      success
    }
  }
`;
