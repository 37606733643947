import React from 'react';
import styled from '@emotion/styled';
import { Row } from '@8base/boost';
import PropTypes from 'prop-types';

const StyledRowForm = styled(Row)`
  margin-bottom: 12px;
`;

const RowForm = ({ children }) => {
  return <StyledRowForm>{children}</StyledRowForm>;
};

RowForm.propTypes = {
  children: PropTypes.any.isRequired,
};

export { RowForm };
