/**
 * When find value, replace on stage for dealStage name.
 *
 * @param {object} mappingList - Mapping List.
 * @param {string} stage - Stages.
 * @returns {string} - Deal Stage Value.
 */
export const replaceDealStage = (mappingList, stage) => {
  let dealStageName = stage;

  mappingList.forEach((dealStage) => {
    const values = dealStage.values.find((vl) => vl.value === stage);
    if (values) {
      dealStageName = dealStage.name;
    }
  });

  return dealStageName;
};
