import { createEvent } from '@cobuildlab/react-simple-state';

export const OnAllianceError = createEvent();
export const OnAllianceMemberList = createEvent();
export const OnAllianceFileSize = createEvent();
export const OnAllianceList = createEvent();
export const OnAllianceExcelList = createEvent();
export const OnAllianceSubmitForApproval = createEvent();
export const OnAllianceDelete = createEvent();
export const OnRemainingAlliances = createEvent();
export const OnAllianceFormData = createEvent();
export const OnAllianceCreate = createEvent();
export const OnAllianceAutoSave = createEvent();
export const OnAllianceAutoSaveError = createEvent();
export const OnAllianceDetail = createEvent();
export const OnAllianceUpdate = createEvent();
export const OnAllianceAutoUpdate = createEvent();
export const OnAllianceAutoUpdateError = createEvent();
export const OnAllianceApprovalList = createEvent();
export const OnAllianceReactive = createEvent();
export const OnAllianceReject = createEvent();
export const OnAllianceCompleted = createEvent();
export const OnAllianceRestore = createEvent();
export const OnAllianceCancelDeleted = createEvent();
export const OnAllianceDetailListMembers = createEvent();
export const OnAllianceDeletedList = createEvent();
export const OnAllianceDeleted = createEvent();
export const OnAllianceMemberWithRoles = createEvent();
export const OnAllianceRoleChange = createEvent();
export const OnAllianceMemberDelete = createEvent();
export const OnAllianceMemberCreate = createEvent();
export const OnAllianceInvitationMember = createEvent();
