import React from 'react';
import { RED_COLOR } from '../../../../shared/colors';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';

const SubscriptionExpiredDiv = styled('div')`
  height: 24px;
  background-color: ${RED_COLOR};
  width: 100%;
  text-align: center;
  color: white !important;
  position: fixed;
  top: 60px;
  z-index: 2;
`;

const WhiteFont = styled('span')`
  color: white !important;
  font-family: Poppins;
  font-weight: 600;
  font-size: 12px;
  letter-spacing: 0.2px;
  line-height: 16px;
  text-transform: uppercase;
`;

const CompanySubscriptionExpired = ({ isProcurer = false }) => {
  const line1 = 'You do not have a valid Subscription.';
  const line2 = isProcurer ? null : 'Please contact your Company Procurement Role.';
  return (
    <SubscriptionExpiredDiv>
      <WhiteFont>
        {line1} {line2}
      </WhiteFont>
    </SubscriptionExpiredDiv>
  );
};

CompanySubscriptionExpired.defaultProps = {
  isProcurer: false,
};

CompanySubscriptionExpired.propTypes = {
  isProcurer: PropTypes.bool,
};

export { CompanySubscriptionExpired };
