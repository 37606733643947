import { createEvent } from '@cobuildlab/react-simple-state';

export const onFundingRequestCompleted = createEvent();
export const onFundingRequestError = createEvent();
export const onFundingRequestCreate = createEvent();
export const onFundingRequestFormData = createEvent();
export const onFundingRequestAutoSave = createEvent();
export const onFundingRequestAutoSaveError = createEvent();
export const onFundingRequestDetail = createEvent();
export const onFundingRequestUpdate = createEvent();
export const onFundingRequestSubmitForApproval = createEvent();
export const onFundingRequestReject = createEvent();
export const onFundingRequestRestore = createEvent();
