import gql from 'graphql-tag';
import { AllianceInvitationFragment } from '../auth/queries';

export const CREATE_ALLIANCE_INFORMATION_MUTATION = gql`
  mutation($data: AllianceCreateInput!) {
    allianceCreate(data: $data) {
      id
    }
  }
`;

export const UPDATE_ALLIANCE_INFORMATION_MUTATION = gql`
  mutation($data: AllianceUpdateInput!) {
    allianceUpdate(data: $data) {
      id
    }
  }
`;

export const ALLIANCE_INFORMATION_QUERY = gql`
  query($userId: ID) {
    alliancesList(filter: { owner: { id: { equals: $userId } } }) {
      count
      items {
        id
        name
        description
        currency {
          id
          thousandSeparator
          decimalSeparator
          symbol
          shortName
        }
        allianceInvitationRelation {
          count
          items {
            ...AllianceInvitationFragment
          }
        }
      }
    }
  }
  ${AllianceInvitationFragment}
`;
