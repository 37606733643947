import React from 'react';
import { Paper, Column, Row } from '@8base/boost';
import { Overline2 } from '../../../../components/new-ui/font-style/Overline2';
import { CurrencyInputField } from '../../../../shared/components/CurrencyInputField';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { MONTHS } from '../forecasting-model';
import moment from 'moment';

const StyledPaper = styled(Paper)`
  box-shadow: none;
  border: 1px solid #e0e5e9;
  min-width: 300px;
  max-width: 300px;
  width: 300px;
`;

const Rectangle = styled.div`
  box-sizing: border-box;
  height: 16px;
  line-height: 16px;
  width: 107px;
  border: 1px solid #6fd34b;
  border-radius: 5px;
  background-color: rgba(111, 211, 75, 0.1);
`;

const Tag = styled.p`
  color: #6fd34b;
  font-family: Poppins;
  font-size: 10px;
  font-weight: 500;
  line-height: 16px;
  text-align: center;
`;

export const ForecastForm = ({
  alliance,
  title,
  selectedYear,
  onChange,
  readOnly,
  forecastField,
}) => {
  const { forecastingYears, currency } = alliance;
  const currentYear = moment().year();
  const currentMonth = moment().month();
  // get forecasting selected year & field data
  const forecastingYear = forecastingYears.find(({ year }) => year === selectedYear);
  const forecastingYearFieldList = forecastingYear ? forecastingYear[forecastField] || [] : [];

  return (
    <StyledPaper padding={'lg'}>
      <Overline2>{title}</Overline2>
      <Column alignItems={'center'} offsetTop={'md'}>
        {forecastingYearFieldList.map((value, i) => {
          const labelText = `${MONTHS[i]} ${selectedYear}`;
          const isCurrentmonth = currentMonth === i && currentYear === selectedYear;
          const _readOnly =
            readOnly ||
            (currentMonth >= i && currentYear === selectedYear) ||
            selectedYear < currentYear;

          const label = isCurrentmonth ? (
            <Row justifyContent={'start'} gap={'sm'}>
              <span>{labelText}</span>
              <Rectangle>
                <Tag>{'CURRENT MONTH'}</Tag>
              </Rectangle>
            </Row>
          ) : (
            labelText
          );

          return (
            <>
              <CurrencyInputField
                stretch
                key={i}
                value={value}
                currency={currency}
                readOnly={_readOnly}
                label={label}
                onChange={(value) => {
                  forecastingYearFieldList[i] = value;
                  onChange('forecastingYears', forecastingYears);
                }}
              />
            </>
          );
        })}
      </Column>
    </StyledPaper>
  );
};

ForecastForm.defaultProps = {
  onChange: () => {},
  readOnly: false,
};

ForecastForm.propTypes = {
  alliance: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  selectedYear: PropTypes.number.isRequired,
  forecastField: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  readOnly: PropTypes.bool,
};
