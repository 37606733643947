import React, { useEffect, useRef, useState } from 'react';
import { Card, Heading, Loader, Pagination, Row, Table, Text } from '@8base/boost';
import { fetchSalesforceLogs } from '../../salesforce/salesforce-actions';
import { useSubscription } from '@cobuildlab/react-simple-state';
import * as toast from '../../../components/toast/Toast';
import Moment from 'react-moment';
import {
  OnSalesforceLogs,
  OnSalesforceLogsError,
  OnSalesforceUpdateNow,
} from '../../salesforce/salesforce-events';

const ITEMS_PER_PAGE = 10;

export const SalesforceLogsCard = () => {
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);

  const allSalesforceLogs = useRef([]);
  const [salesforceLogs, setSalesforceLogs] = useState([]);

  useSubscription(OnSalesforceLogs, (logs) => {
    allSalesforceLogs.current = logs;
    setSalesforceLogs(logs.slice(0, ITEMS_PER_PAGE));
    setLoading(false);
    setTimeout(() => fetchSalesforceLogs(), 5000);
  });

  useSubscription(OnSalesforceLogsError, (error) => {
    toast.error(error.message);
    setLoading(false);
  });

  useSubscription(OnSalesforceUpdateNow, () => {
    setLoading(true);
  });

  useEffect(() => {
    fetchSalesforceLogs();
  }, []);

  useEffect(() => {
    setSalesforceLogs(allSalesforceLogs.current.slice((page - 1) * ITEMS_PER_PAGE, ITEMS_PER_PAGE));
  }, [page]);

  return (
    <Card stretch style={{ overflow: 'initial' }}>
      <Card.Header>
        <Heading type="h4" text="Update Logs" />
      </Card.Header>
      {loading ? (
        <Card.Body>
          <Loader stretch />
        </Card.Body>
      ) : (
        <div style={{ display: 'flex', height: '400px' }}>
          <Table>
            <Table.Header columns="200px 100px 1fr">
              <Table.HeaderCell>Executed At</Table.HeaderCell>
              <Table.HeaderCell>Type</Table.HeaderCell>
              <Table.HeaderCell>Message</Table.HeaderCell>
            </Table.Header>

            <Table.Body data={salesforceLogs}>
              {(log) => {
                return (
                  <Table.BodyRow columns="200px 100px 1fr" key={log.id}>
                    <Table.BodyCell>
                      <Moment format="MM/DD/YYYY - h:mm a">{log.createdAt}</Moment>
                    </Table.BodyCell>
                    <Table.BodyCell>
                      {log.level === 'error' ? (
                        <Text text="ERROR" color="RED" weight="bold" />
                      ) : (
                        <Text text="INFO" color="BLUE" weight="bold" />
                      )}
                    </Table.BodyCell>
                    <Table.BodyCell>{log.message}</Table.BodyCell>
                  </Table.BodyRow>
                );
              }}
            </Table.Body>
            {allSalesforceLogs.current.length > ITEMS_PER_PAGE ? (
              <Table.Footer>
                <Row justifyContent="center">
                  <Pagination
                    page={page}
                    pageSize={ITEMS_PER_PAGE}
                    total={allSalesforceLogs.current.length}
                    onChange={setPage}
                  />
                </Row>
              </Table.Footer>
            ) : null}
          </Table>
        </div>
      )}
    </Card>
  );
};
