import React from 'react';
import { withRouter } from 'react-router-dom';
import { Card, Grid, Heading, Row } from '@8base/boost';
import PropTypes from 'prop-types';
import { View } from '@cobuildlab/react-simple-state';
import { onErrorMixin, onChangeDataMixin } from '../../shared/mixins';
import * as toast from '../../components/toast/Toast';
import { createPaymentMethod } from '../../shared/stripe/stripe';
import { ActionButton } from '../../components/buttons/ActionButton';
import { createCompanySubscription } from './procurer-actions';
import { ElementsConsumer } from '@stripe/react-stripe-js';
import { CompanySubscriptionDialogForm } from './components/CompanySubscriptionDialogForm';
import * as R from 'ramda';
import { CompanySubscription } from './procurer-model';
import { fetchSession } from '../../modules/auth/auth.actions';
import { NoSubscriptionPlan } from './components/NoSubscriptionPlan';
import { OnCompanySubscriptionCreate, OnProcurerError } from './procurer-events';

class CreateCompanySubscriptionView extends View {
  constructor(props) {
    super(props);
    this.state = {
      data: R.clone(CompanySubscription),
      showAnnualPrice: false,
      loading: false,
    };
    this.onError = onErrorMixin.bind(this);
    this.onChangeData = onChangeDataMixin.bind(this);
  }

  componentDidMount() {
    this.subscribe(OnProcurerError, () => {
      this.setState({ loading: false });
    });

    this.subscribe(OnCompanySubscriptionCreate, async () => {
      await fetchSession();
      this.setState({
        loading: false,
        createCompanySubscriptionDialogIsOpen: false,
        data: R.clone(CompanySubscription),
      });
      toast.success('Company subscribed!');
    });
  }

  onOpenCreateCompanySubscriptionDialog = () => {
    this.setState({ createCompanySubscriptionDialogIsOpen: true });
  };

  onCloseCreateCompanySubscriptionDialog = () => {
    this.setState({ createCompanySubscriptionDialogIsOpen: false });
  };

  onChangeAnnualPrice = (showAnnualPrice) => {
    this.setState({ showAnnualPrice });
  };

  onCreateCompanySubscription = async (stripe, elements) => {
    const { company, companySubscription } = this.props;
    const { data, showAnnualPrice } = this.state;
    const { paymentMethod, error } = await createPaymentMethod(
      stripe,
      elements,
      R.clone(data.creditCard),
    );

    this.setState({ loading: true }, () => {
      createCompanySubscription(
        R.clone(data),
        R.clone(showAnnualPrice),
        R.clone(company),
        R.clone(companySubscription),
        paymentMethod,
        error,
      );
    });
  };

  render() {
    const { countries, subscriptionPlans } = this.props;
    const { loading, data, createCompanySubscriptionDialogIsOpen, showAnnualPrice } = this.state;
    const {
      onChangeData,
      onOpenCreateCompanySubscriptionDialog,
      onCloseCreateCompanySubscriptionDialog,
      onCreateCompanySubscription,
      onChangeAnnualPrice,
    } = this;

    return (
      <>
        <Card style={{ marginBottom: '20px' }}>
          <Card.Header>
            <Grid.Layout
              columns="200px auto 200px"
              areas={[['left', 'center', 'right']]}
              style={{ width: '100%' }}>
              <Grid.Box area="left">
                <Heading type="h4" text="Subscription Plan" />
              </Grid.Box>
              <Grid.Box area="center" />
              <Grid.Box area="right" />
            </Grid.Layout>
          </Card.Header>

          <Card.Body padding={'none'}>
            <Card.Section>
              <NoSubscriptionPlan />

              <Row alignItems="center" offsetTop="md">
                <ActionButton text={'Subscribe'} onClick={onOpenCreateCompanySubscriptionDialog} />
              </Row>
            </Card.Section>
          </Card.Body>
        </Card>

        <ElementsConsumer>
          {({ elements, stripe }) => {
            return (
              <CompanySubscriptionDialogForm
                onChangeAnnualPrice={onChangeAnnualPrice}
                showAnnualPrice={showAnnualPrice}
                countries={countries}
                subscriptionPlans={subscriptionPlans}
                loading={loading}
                onClose={onCloseCreateCompanySubscriptionDialog}
                isOpen={createCompanySubscriptionDialogIsOpen}
                onSubmit={() => onCreateCompanySubscription(stripe, elements)}
                onChange={onChangeData}
                data={data}
              />
            );
          }}
        </ElementsConsumer>
      </>
    );
  }
}

CreateCompanySubscriptionView.propTypes = {
  history: PropTypes.object.isRequired,
  company: PropTypes.object.isRequired,
  countries: PropTypes.array.isRequired,
  subscriptionPlans: PropTypes.array.isRequired,
  companySubscription: PropTypes.object,
};

const enhancedCreateCompanySubscriptionView = withRouter(CreateCompanySubscriptionView);
export { enhancedCreateCompanySubscriptionView as CreateCompanySubscriptionView };
