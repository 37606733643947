import React from 'react';
import { Text, Icon, Grid, Heading } from '@8base/boost';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const StyledLayout = styled(Grid.Layout)`
  margin: 20px 0;
`;

const Line = styled.div`
  display: flex;
  height: 0;
  border: solid 1px #edf0f2;
  width: 100%;
`;

const StyledHeading = styled(Heading)`
  cursor: pointer;
  padding: 0 10px;
`;

const TextBlue = styled(Text)`
  color: #3eb7f9 !important;
`;

const TextGray = styled(Text)`
  color: #878c93 !important;
`;

const HorizontalLineText = ({ text, count, active, onClick, ...rest }) => {
  const iconName = active ? 'ChevronDown' : 'ChevronTop';

  return (
    <StyledLayout columns="1fr auto 1fr" {...rest}>
      <Grid.Box justifyContent="center">
        <Line />
      </Grid.Box>
      <Grid.Box>
        <StyledHeading type="h6" onClick={onClick}>
          <TextGray weight="bold">
            {text}&nbsp;(<TextBlue weight="bold">{count || '0'}</TextBlue>) &nbsp;
            <Icon size="xs" name={iconName} />
          </TextGray>
        </StyledHeading>
      </Grid.Box>
      <Grid.Box justifyContent="center">
        <Line />
      </Grid.Box>
    </StyledLayout>
  );
};

HorizontalLineText.propTypes = {
  text: PropTypes.string.isRequired,
  count: PropTypes.number.isRequired,
  active: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default HorizontalLineText;
