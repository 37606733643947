import Flux from '@cobuildlab/flux-state';

/**
 * Event that toggles chat sidebar.
 *
 * @type {string}
 */
export const CHAT_TOGGLE_EVENT = 'chatToggleEvent';

export const CHAT_UNREADS_EVENT = 'chatUnreadsEvent';

/**
 * Hold the ChatData.
 */
class ChatStore extends Flux.DashStore {
  constructor() {
    super();
    this.addEvent(CHAT_TOGGLE_EVENT);
    this.addEvent(CHAT_UNREADS_EVENT);
  }
}

const chatStore = new ChatStore();
export default chatStore;
