import React from 'react';
import {
  Column,
  DateInputField,
  Grid,
  InputField,
  Label,
  Paragraph,
  Row,
  TextAreaField,
} from '@8base/boost';
import PropTypes from 'prop-types';
import { onErrorMixin } from '../../../../shared/mixins';
import '../../css/alliance.css';
import CenterParagraph from '../../../../components/CenterParagraph';
import FileInputComponent from '../../../../components/inputs/FileInputComponent';
import { SelectInputById } from '../../../../components/forms/SelectInputById';
import YesNoDialog from '../../../../components/dialogs/YesNoDialog';
import { HorizontalLine } from '../../../../components/new-ui/text/HorizontalLine';
import { HorizontalLineText } from '../../../../components/new-ui/text/HorizontalLineText';
import { GroupInputs } from '../../../../components/new-ui/inputs/GroupInputs';
import DocumentTitle from '../../../../components/DocumentTitle';
import { SwitchField } from '../../../../components/switch/SwitchField';
import { ActionButton } from '../../../../components/buttons/ActionButton';

class AllianceForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      deleteDocumentModalIsOpen: false,
      deleteDocumentPos: null,
      isPreAllianceModalOpen: false,
    };
    this.onClickDelete = this.onClickDelete.bind(this);
    this.onChangeDocuments = this.onChangeDocuments.bind(this);
    this.nameInput = null;
  }

  onClickDelete = (pos, name) => {
    this.nameInput = name;
    this.setState({
      deleteDocumentModalIsOpen: true,
      deleteDocumentPos: pos,
    });
  };

  onYes = () => {
    this.setState(
      {
        deleteDocumentModalIsOpen: false,
      },
      () => {
        this.onDeleteDocuments(this.nameInput, this.state.deleteDocumentPos);
      },
    );
  };

  onClose = () => {
    this.setState({
      deleteDocumentModalIsOpen: false,
    });
  };

  onChangeDocuments(name, value) {
    const allianceData = this.props.data;
    if (name === 'documents') {
      if (Array.isArray(value)) {
        for (let aux of value) {
          allianceData[name].push(aux);
        }
      } else {
        allianceData[name].push(value);
      }
    } else {
      allianceData[name] = value;
    }

    this.props.onChange(name, allianceData[name]);
  }

  onDeleteDocuments = (name, pos) => {
    const allianceData = this.props.data;

    if (name === 'organizationalChartFile') {
      allianceData[name] = null;
    } else if (name === 'policyGuidelinesFile') {
      allianceData[name] = null;
    } else if (name === 'documents') {
      allianceData[name].splice(pos, 1);
    }

    this.setState({ deleteDocumentPos: null });
    this.props.onChange(name, allianceData[name]);
  };

  onYesPreAllianceModal = () => {
    this.props.onChange('oneSided', false);
    this.setState({ isPreAllianceModalOpen: false });
  };

  setPreAllianceModalState = (isOpen) => {
    this.setState({ isPreAllianceModalOpen: isOpen });
  };

  render() {
    const { deleteDocumentModalIsOpen, isPreAllianceModalOpen } = this.state;
    const { onChange, invitations, currencies, companyUsers, data: alliance, edit } = this.props;
    const {
      name,
      description,
      policyGuidelinesFile,
      organizationalChartFile,
      invitationEmail,
      currency,
      documents,
      effectiveDate,
      clientCompany,
      partnerCompany,
      oneSided,
      partnerCompanyName,
      partnerInvitationEmail,
    } = alliance;
    return (
      <>
        <HorizontalLine>
          <HorizontalLineText text={'ALLIANCE'} />
        </HorizontalLine>
        <GroupInputs text={'General'}>
          <Row growChildren gap="lg">
            <Column>
              <InputField
                stretch
                label="Name"
                placeholder={'Name'}
                input={{
                  name: 'name',
                  value: name,
                  onChange: (value, e) => onChange(e.target.name, value),
                }}
              />
              <TextAreaField
                style={{ width: '100%', height: 152 }}
                label="Description"
                placeholder={'Description'}
                input={{
                  name: 'description',
                  value: description,
                  onChange: (value) => onChange('description', value),
                }}
              />
            </Column>
          </Row>
        </GroupInputs>
        <GroupInputs text={'Date & Currency'}>
          <Row growChildren gap="lg">
            <Column style={{ width: '35%' }}>
              <DateInputField
                label="Effective Date"
                input={{
                  name: 'effectiveDate',
                  value: effectiveDate,
                  onChange: (value) => onChange('effectiveDate', value),
                }}
              />
            </Column>
            <Column style={{ width: '65%' }}>
              <SelectInputById
                label="Currency"
                input={{
                  name: 'currency',
                  value: currency,
                  onChange: (value) => onChange('currency', value),
                }}
                meta={{}}
                placeholder="Currency of your Alliance"
                options={currencies.map((currency) => {
                  return { label: currency.shortName, value: currency };
                })}
              />
            </Column>
          </Row>
        </GroupInputs>
        <GroupInputs text={'Companies'}>
          <Row growChildren gap="lg">
            <Column>
              {companyUsers ? (
                <SelectInputById
                  label="Company"
                  input={{
                    name: 'clientCompany',
                    value: clientCompany,
                    onChange: (value) => onChange('clientCompany', value),
                  }}
                  meta={{}}
                  placeholder="Select your Company"
                  options={companyUsers.map((companyRelation) => {
                    return {
                      label: companyRelation.company.name,
                      value: companyRelation.company,
                    };
                  })}
                />
              ) : null}
            </Column>
          </Row>
        </GroupInputs>

        <Row growChildren gap="lg">
          <Column>
            <HorizontalLine>
              <HorizontalLineText text={'Documents'} />
            </HorizontalLine>
            <Row style={{ 'padding-bottom': '15px' }}>
              <Column alignItems="">
                <div style={{ 'margin-left': '30px' }}>
                  <Label kind="secondary" style={{ textAlign: 'left' }}>
                    <DocumentTitle
                      text={'Alliance Organizational Chart  '}
                      maxFiles={1}
                      files={organizationalChartFile}
                    />
                  </Label>
                  <FileInputComponent
                    onChange={this.onChangeDocuments}
                    nameInput={'organizationalChartFile'}
                    field={organizationalChartFile}
                    maxFiles={1}
                    text={'Add Documents'}
                    onClickDelete={(pos) => this.onClickDelete(pos, 'organizationalChartFile')}
                  />
                </div>
              </Column>

              <Column>
                <div style={{ 'margin-left': '30px' }}>
                  <Label kind="secondary" style={{ textAlign: 'left' }}>
                    <DocumentTitle
                      text={'Policy Guidelines File'}
                      maxFiles={1}
                      files={policyGuidelinesFile}
                    />
                  </Label>
                  <FileInputComponent
                    onChange={this.onChangeDocuments}
                    nameInput={'policyGuidelinesFile'}
                    field={policyGuidelinesFile}
                    maxFiles={1}
                    text={'Add Documents'}
                    onClickDelete={(pos) => this.onClickDelete(pos, 'policyGuidelinesFile')}
                  />
                </div>
              </Column>
            </Row>
            <Row style={{ 'padding-bottom': '15px' }}>
              <Column alignItems="">
                <div style={{ 'margin-left': '30px' }}>
                  <Label kind="secondary" style={{ textAlign: 'left' }}>
                    <DocumentTitle text={'Documents File'} maxFiles={5} files={documents} />
                  </Label>
                  <FileInputComponent
                    onChange={this.onChangeDocuments}
                    nameInput={'documents'}
                    field={documents}
                    maxFiles={5}
                    text={'Add Documents'}
                    onClickDelete={(pos) => this.onClickDelete(pos, 'documents')}
                  />
                </div>
              </Column>
            </Row>
          </Column>
        </Row>

        <Grid.Box offsetBottom="md">
          <HorizontalLine>
            <HorizontalLineText text={'PRE-ALLIANCE'} />
          </HorizontalLine>
          <Grid.Layout columns="60% 1fr">
            <Grid.Box style={{ padding: 16 }} alignItems="end">
              <Paragraph>
                Pre-alliances can perform all of Succeed&#39;s functions without having to
                immediately invite their alliance partner
              </Paragraph>
            </Grid.Box>
            <Grid.Box justifyContent="center">
              {edit && oneSided ? (
                <ActionButton
                  type="button"
                  onClick={() => this.setPreAllianceModalState(true)}
                  text="Revert Pre-Alliance"
                />
              ) : edit ? (
                <Paragraph weight="bold">You are not in Pre-Alliance</Paragraph>
              ) : (
                <div>
                  <SwitchField
                    label="Active"
                    input={{ value: oneSided, onChange: (value) => onChange('oneSided', value) }}
                  />
                </div>
              )}
            </Grid.Box>
          </Grid.Layout>
          {oneSided ? (
            <>
              <HorizontalLine>
                <HorizontalLineText text={'PARTNER COMPANY'} />
              </HorizontalLine>
              <Row
                growChildren
                gap="lg"
                style={{ paddingBottom: 15, maxWidth: 650, margin: 'auto' }}>
                <CenterParagraph>Partner Company</CenterParagraph>
                <InputField
                  style={{ width: '100%' }}
                  label="Partner Company Name"
                  disabled={invitations && invitations.length}
                  key={'partnerCompanyName'}
                  input={{
                    name: 'partnerCompanyName',
                    value: partnerCompanyName,
                    onChange: (value, e) => onChange(e.target.name, value),
                  }}
                />
              </Row>
            </>
          ) : partnerCompany &&
            partnerCompany.companyUserRelation &&
            partnerCompany.companyUserRelation.count === 0 ? (
            <>
              <HorizontalLine>
                <HorizontalLineText text={'INVITATION'} />
              </HorizontalLine>
              <Row growChildren gap="lg" style={{ maxWidth: 650, margin: 'auto' }}>
                <CenterParagraph>
                  Invite a Portfolio Owner for your Alliance Partner Company
                </CenterParagraph>
                <InputField
                  style={{ width: '100%' }}
                  label="E-Mail"
                  key={'partnerInvitationEmail'}
                  input={{
                    type: 'email',
                    name: 'partnerInvitationEmail',
                    value: partnerInvitationEmail,
                    onChange: (value, e) => onChange(e.target.name, value),
                  }}
                />
              </Row>
            </>
            ) : (
            <>
              <HorizontalLine>
                <HorizontalLineText text={'INVITATION'} />
              </HorizontalLine>
              <Row growChildren gap="lg" style={{ maxWidth: 650, margin: 'auto' }}>
                <CenterParagraph>
                  Invite your Alliance Partner to begin collaborating
                </CenterParagraph>
                <InputField
                  style={{ width: '100%' }}
                  label="E-Mail"
                  disabled={invitations && invitations.length}
                  input={{
                    type: 'email',
                    name: 'invitationEmail',
                    value: invitationEmail,
                    onChange: (value, e) => onChange(e.target.name, value),
                  }}
                />
              </Row>
            </>
            )}
        </Grid.Box>
        <YesNoDialog
          isOpen={deleteDocumentModalIsOpen}
          onYes={this.onYes}
          onNo={this.onClose}
          onClose={this.onClose}
          text={'Are you sure you want to Delete this Document?'}
          title={'Delete Document'}
        />
        <YesNoDialog
          isOpen={isPreAllianceModalOpen}
          onYes={this.onYesPreAllianceModal}
          onNo={() => this.setPreAllianceModalState(false)}
          onClose={() => this.setPreAllianceModalState(false)}
          text={'Are you sure you want to revert the Pre-Alliance?'}
          title={'Revert Pre-Alliance'}
        />
      </>
    );
  }
}

Object.assign(AllianceForm.prototype, onErrorMixin);

AllianceForm.defaultProps = {
  invitations: [],
  companyUsers: null, // null for edit view
  companyId: null, // null for edit view
};

AllianceForm.propTypes = {
  data: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  currencies: PropTypes.array.isRequired,
  invitations: PropTypes.array,
  companyUsers: PropTypes.array,
  edit: PropTypes.bool,
};
AllianceForm.defaultProps = {
  invitations: [],
  edit: false,
};

export default AllianceForm;
