import gql from 'graphql-tag';
import { UserFragment } from '../../auth/queries';

export const BusinessCaseFragment = gql`
  fragment BusinessCaseFragment on BusinessCase {
    id
    code
    status
    backgroundSummary
    visionMissionStrategy
    marketAnalysis
    salesMarketingStrategy
    recommendedSolution
    recommendedSolutionsRelation {
      count
      items {
        id
        description
        __typename
      }
    }
    risks
    anticipatedCosts
    expectedCosts
    expectedRevenues
    document {
      id
      downloadUrl
      filename
      fileId
    }
    owner {
      ...UserFragment
    }
    createdAt
  }
  ${UserFragment}
`;

export const BUSINESS_CASE_LIST_QUERY = gql`
  query {
    businessCasesList {
      count
      items {
        ...BusinessCaseFragment
      }
    }
  }
  ${BusinessCaseFragment}
`;

export const BUSINESS_CASE_DETAIL_QUERY = gql`
  query($id: ID!) {
    businessCase(id: $id) {
      ...BusinessCaseFragment
    }
  }
  ${BusinessCaseFragment}
`;

export const BUSINESS_CASE_CREATE_MUTATION = gql`
  mutation($data: BusinessCaseCreateInput!) {
    businessCaseCreate(data: $data) {
      id
    }
  }
`;

export const BUSINESS_CASE_UPDATE_MUTATION = gql`
  mutation($data: BusinessCaseUpdateInput!) {
    businessCaseUpdate(data: $data) {
      id
    }
  }
`;

export const BUSINESS_CASE_DELETE_MUTATION = gql`
  mutation($data: BusinessCaseDeleteInput!) {
    businessCaseDelete(data: $data, force: true) {
      success
    }
  }
`;

export const RECOMMENDED_SOLUTION_UPDATE_MUTATION = gql`
  mutation($data: RecommendedSolutionUpdateInput!) {
    recommendedSolutionUpdate(data: $data) {
      id
    }
  }
`;

export const RECOMMENDED_SOLUTION_DELETE_MUTATION = gql`
  mutation($data: RecommendedSolutionDeleteInput!) {
    recommendedSolutionDelete(data: $data) {
      success
    }
  }
`;
