import React from 'react';
import PropTypes from 'prop-types';
import { Card, Grid, Heading, Loader, Row } from '@8base/boost';
import { CreateViewCardBody } from '../../../components/new-ui/card/CreateViewCardBody';
import ActionForm from './components/ActionForm';
import {
  onChangeBusinessCaseDataMixin,
  onChangeDataMixin,
  onErrorMixin,
} from '../../../shared/mixins';
import * as R from 'ramda';
import ActionModel from './action-model';
import { withRouter } from 'react-router-dom';
import * as toast from '../../../components/toast/Toast';
import { View } from '@cobuildlab/react-simple-state';
import {
  autoSaveUpdateAction,
  fetchActionDetail,
  openComments,
  updateAction,
} from './action-actions';
import { fetchCurrentAllianceMembersAction } from '../../settings/alliance-management/alliance-actions';
import { fetchInitiativeList } from '../initiative/initiative-actions';
import BusinessCaseModel from '../../document-management/business-case/BusinessCase.model';
import BusinessCaseForm from '../../document-management/business-case/components/BusinessCaseForm';
import BusinessCaseDetailTable from '../../document-management/business-case/components/BusinessCaseDetailTable';
import ActionDetailTable from './components/ActionDetailTable';
import { ActionButton } from '../../../components/buttons/ActionButton';
import { FormSteps } from '../../../components/dots/FormSteps';
import { TransparentButton } from '../../../components/buttons/TransparentButton';
import { actionValidator } from './action-validators';
import { businessCaseValidator } from '../../document-management/business-case/business-case-validators';
import { fetchRelatedItems } from '../../related-item/related-item-actions';

import { getCurrencyOnSession } from '../../../shared/alliance-utils';
import { sanitizeNextStepsToEdit } from '../../next-step/next-step-actions';
import sessionStore, { NEW_SESSION_EVENT } from '../../../shared/SessionStore';
import { getItemByType } from '../../../shared/items-util';
import RelatedItemForm from '../../related-item/components/RelatedItemForm';
import { initiativesItemValidator } from '../initiative/initiative-validators';
import { RelatedItemsDetailTable } from '../../related-item/components/RelatedItemsDetailTable';
import { TopButtons } from '../../../components/buttons/TopButtons';
import { InitiativeListTable } from '../initiative/components/InitiativeListTable';
import { LeftProgressSection } from '../../../components/new-ui/LeftProgressSection';
import { SCREENS_ACTIONS } from '../screenView';
import { BoxCard } from '../../../components/new-ui/div/BoxCard';
import { CardFooter } from '../../../components/new-ui/card/CardFooter';
import { ACTION_COMPLETED } from '../../../shared/status';
import { TextDateAgo } from '../../../components/text/TextDateAgo';
import { OnActionUpdate, OnActionDetail, OnActionError, OnActionAutoSave } from './action-events';
import { OnInitiativeList } from '../initiative/initiative-events';
import { OnAllianceMemberList } from '../../settings/alliance-management/alliance-events';
import { OnRelatedItems } from '../../related-item/related-item-events';

// Edit Action
class ActionEditView extends View {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        actionData: R.clone(ActionModel),
        businessCaseData: R.clone(BusinessCaseModel),
        relatedItems: [],
        initiatives: [],
      },
      clientCompany: null,
      partnerCompany: null,
      initiativesList: [],
      step: 0,
      loading: true,
      savedAt: null,
    };
    this.onError = onErrorMixin.bind(this);
    this.onChangeBusinessCaseData = (name, value) => {
      this.autoSave();
      onChangeBusinessCaseDataMixin.call(this, name, value);
    };
    this.originalRecommendedSolutions = [];
    this.originalNextSteps = [];
    this.onChangeData = onChangeDataMixin.bind(this);
    this.originalData = [];
    this.autoSaveTimer = null;
    this.queryOptions = { isCacheFirst: true };
  }

  onChangeActionData = (name, value) => {
    const { data } = this.state;
    data.actionData[name] = value;
    this.autoSave();
    this.setState({ data });
  };

  componentDidMount = () => {
    const { match } = this.props;
    if (!match.params.id) return toast.error('Action ID missing');

    this.subscribe(OnActionError, this.onError);
    this.subscribe(OnAllianceMemberList, (state) => {
      this.setState(
        {
          clientCompany: R.clone(state.clientCompany),
          partnerCompany: R.clone(state.partnerCompany),
        },
        () => {
          fetchActionDetail(match.params.id, this.queryOptions);
        },
      );
    });
    this.subscribe(OnActionDetail, (state) => {
      const actionData = R.clone(state.action);
      const businessCaseData = R.clone(actionData.businessCase);
      const { data } = this.state;
      // Business Case
      businessCaseData.recommendedSolutions = businessCaseData.recommendedSolutionsRelation.items.map(
        (solution) => {
          delete solution.__typename;
          return solution;
        },
      );
      this.originalRecommendedSolutions = businessCaseData.recommendedSolutions.concat();
      actionData.itemId = actionData.itemActionRelation.id;
      actionData.documents = actionData.documents.items;
      data.actionData = actionData;
      //data.actionData.initiatives = actionData.initiatives.items;
      data.businessCaseData = businessCaseData;
      data.actionData = actionData;
      data.initiatives = R.clone(actionData.initiatives.items);

      this.originalNextSteps = sanitizeNextStepsToEdit(actionData);
      this.originalData = R.clone(actionData);
      this.setState(
        {
          data,
        },
        () => fetchRelatedItems(actionData.itemId),
      );
    });
    this.subscribe(OnInitiativeList, (state) => {
      const initiativesList = R.clone(state.initiativesList.items);
      this.setState({
        initiativesList,
      });
    });

    this.subscribe(OnActionUpdate, (state) => {
      toast.success(`Action Successfully Updated`);
      this.props.history.goBack();
    });

    this.subscribe(OnRelatedItems, (state) => {
      const items = state.item.itemsRelated.items.map((item) => getItemByType(item));
      const { data } = this.state;
      data.relatedItems = items;
      this.setState({
        data,
        loading: false,
      });
    });

    this.subscribe(OnActionAutoSave, (action) => {
      this.setState({ savedAt: new Date() });
    });

    fetchInitiativeList('', 1, 1000, null, null, this.queryOptions);
    fetchCurrentAllianceMembersAction();
  };

  autoSave = () => {
    const waitTime = 10000; // Wait 10s
    clearTimeout(this.autoSaveTimer);
    this.autoSaveTimer = setTimeout(() => {
      const { actionData, businessCaseData, relatedItems, initiatives } = this.state.data;
      autoSaveUpdateAction(
        R.clone(actionData),
        R.clone(businessCaseData),
        this.originalRecommendedSolutions,
        this.originalNextSteps,
        relatedItems,
        initiatives,
        this.originalData,
      );
    }, waitTime);
  };

  onSubmit = () => {
    this.setState({ loading: true }, () => {
      clearTimeout(this.autoSaveTimer);
      const { actionData, businessCaseData, relatedItems, initiatives } = this.state.data;
      updateAction(
        R.clone(actionData),
        R.clone(businessCaseData),
        this.originalRecommendedSolutions,
        this.originalNextSteps,
        relatedItems,
        initiatives,
        this.originalData,
      );
    });
  };

  onActionStepChange = (nextStep) => {
    const { selectedAlliance } = sessionStore.getState(NEW_SESSION_EVENT);
    const actionData = R.clone(this.state.data.actionData);
    try {
      actionValidator(actionData, selectedAlliance);
    } catch (e) {
      return this.onError(e);
    }
    this.onScreen(nextStep);
  };

  onBusinessCaseStepChange = (nextStep) => {
    const businessCaseData = R.clone(this.state.data.businessCaseData);
    try {
      businessCaseValidator(businessCaseData);
    } catch (e) {
      return this.onError(e);
    }
    this.onScreen(nextStep);
  };

  onScreen = (step) => {
    this.setState({ step });
  };

  onRelatedItemsStepChange = (step) => {
    const initiatives = R.clone(this.state.data.initiatives);
    try {
      initiativesItemValidator(initiatives);
    } catch (e) {
      return this.onError(e);
    }
    this.onScreen(step);
  };

  render() {
    const {
      initiativesList,
      companyId,
      clientCompany,
      partnerCompany,
      loading,
      step,
      savedAt,
    } = this.state;
    const { actionData, businessCaseData, relatedItems, initiatives } = this.state.data;
    const { user, selectedAlliance } = sessionStore.getState(NEW_SESSION_EVENT);
    const { history } = this.props;
    const currency = getCurrencyOnSession();
    let content = <Loader stretch />;
    let footer = <></>;
    let buttonsTop = <></>;
    if (actionData.status === ACTION_COMPLETED) history.push(`/management/amo-item`);

    if (!loading && step === 0) {
      content = (
        <div id={'c2gScreen'}>
          <ActionForm
            data={actionData}
            onChange={this.onChangeActionData}
            clientCompany={clientCompany}
            partnerCompany={partnerCompany}
            myCompanyId={companyId}
            initiativesList={initiativesList}
            currency={currency}
            user={user}
            selectedAlliance={selectedAlliance}
          />
        </div>
      );
      footer = (
        <Card.Footer>
          <ActionButton onClick={() => this.onActionStepChange(1)} text={'Next'} />
        </Card.Footer>
      );
    }

    if (!loading && step === 1) {
      content = (
        <div id={'c2gScreen'}>
          <BusinessCaseForm
            data={businessCaseData}
            onChange={this.onChangeBusinessCaseData}
            currency={currency}
            showBusinessFields
            hideExpectCostAvoidance
          />
        </div>
      );
      footer = (
        <CardFooter>
          <ActionButton onClick={() => this.onBusinessCaseStepChange(2)} text={'Next'} />
          <TransparentButton onClick={() => this.onScreen(0)} text={'Previous'} />
        </CardFooter>
      );
    }

    if (!loading && step === 2) {
      const itemData = { id: actionData.id, type: actionData.__typename };
      content = (
        <div id={'c2gScreen'}>
          <RelatedItemForm
            relatedItems={relatedItems}
            initiatives={initiativesList}
            onChange={(key, value) => {
              this.autoSave();
              this.onChangeData(key, value);
            }}
            selectedInitiatives={initiatives}
            itemData={itemData}
          />
        </div>
      );

      footer = (
        <CardFooter>
          <ActionButton onClick={() => this.onRelatedItemsStepChange(3)} text={'Next'} />
          <TransparentButton onClick={() => this.onScreen(1)} text={'Previous'} />
        </CardFooter>
      );
    }

    if (!loading && step === 3) {
      const actionDetails = R.clone(actionData);

      actionDetails.documents = { items: actionDetails.documents };
      actionDetails.nextSteps = { items: actionDetails.nextSteps };

      content = (
        <div id={'c2gScreen'}>
          <ActionDetailTable
            data={actionDetails}
            initiatives={initiativesList}
            currency={currency}
          />
          <BusinessCaseDetailTable
            hideExpectedCostAvoidance
            data={businessCaseData}
            currency={currency}
          />
          <InitiativeListTable initiatives={initiatives} />
          <RelatedItemsDetailTable relatedItems={relatedItems} />
        </div>
      );
      footer = (
        <CardFooter>
          <ActionButton onClick={() => this.onSubmit()} text={'Update'} />
          <TransparentButton onClick={() => this.onScreen(2)} text={'Previous'} />
        </CardFooter>
      );
    }

    if (!loading)
      buttonsTop = (
        <TopButtons
          onClickClosed={history.goBack}
          onClickCollaborated={() => openComments(actionData)}
        />
      );

    return (
      <React.Fragment>
        <Card.Header>
          <Grid.Layout
            columns="400px auto 400px"
            areas={[['left', 'center', 'right']]}
            style={{ width: '100%' }}>
            <Grid.Box area="left">
              <Row>
                <Heading type="h4" text="Edit Action" />
                <TextDateAgo from={savedAt} />
              </Row>
            </Grid.Box>
            <Grid.Box area="center">
              <FormSteps totalSteps={4} step={step} />
            </Grid.Box>
            <Grid.Box area="right" />
          </Grid.Layout>
          {buttonsTop}
        </Card.Header>
        <CreateViewCardBody>
          <Grid.Layout columns="30% 70%" areas={[['left', 'right']]} style={{ width: '100%' }}>
            <Grid.Box area="left">
              <LeftProgressSection sections={SCREENS_ACTIONS} currentScreen={step} />
            </Grid.Box>
            <BoxCard>
              <Grid.Box area="right">{content}</Grid.Box>
            </BoxCard>
          </Grid.Layout>
        </CreateViewCardBody>
        {footer}
      </React.Fragment>
    );
  }
}

ActionEditView.propTypes = {
  history: PropTypes.object.isRequired,
};

export default withRouter(ActionEditView);
