const createSortValue = (keys, sort) => {
  const [key, ...rest] = keys;

  if (keys.length === 1) {
    return {
      [key]: sort,
    };
  }
  return {
    [key]: createSortValue(rest, sort),
  };
};

export { createSortValue };
