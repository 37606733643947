import React from 'react';
import { Heading } from '@8base/boost';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';

const StyledHeader = styled(Heading)({
  fontSize: 17,
  marginTop: 30,
  marginLeft: 25,
  position: 'relative',
  float: 'left',
});

const SubHeader = ({ text }) => {
  return (
    <>
      <StyledHeader text={text} />
      <div className="divider" />
    </>
  );
};

SubHeader.propTypes = {
  text: PropTypes.string.isRequired,
};

export default SubHeader;
