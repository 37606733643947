import React from 'react';
import { Table } from '@8base/boost';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { InitiativeRow } from './initiativeRow';
import { RelatedItemRow } from './relatedItemRow';
import RelatedIdemDialog from './RelatedItemDialog';
import YesNoDialog from '../../../components/dialogs/YesNoDialog';
import { HorizontalLineText } from '../../../components/new-ui/text/HorizontalLineText';
import { HorizontalLine } from '../../../components/new-ui/text/HorizontalLine';
import { GroupInputs } from '../../../components/new-ui/inputs/GroupInputs';
import PlusButton from '../../../components/PlusButton';

const StyledTable = styled('table')`
  width: 100%;
  text-align: center;
`;

class RelatedItemForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      relatedItemDialogIsOpen: false,
      relatedItemDeleteDialogIsOpen: false,
      item: null,
    };
  }

  onChangeInitiative = (check, initiative, index) => {
    console.log(`onChangeInitiative:`, check, initiative, index);
    let { selectedInitiatives } = this.props;
    // console.log(`onChangeInitiative:selectedInitiatives:`, selectedInitiatives);
    if (check) {
      selectedInitiatives.push(initiative);
    } else {
      console.log('DELETE ITEMS', initiative);
      selectedInitiatives = selectedInitiatives.filter(
        (_initiative) => _initiative.id !== initiative.id,
      );
    }
    console.log(`onChangeInitiative:selectedInitiatives:`, selectedInitiatives);
    this.props.onChange('initiatives', selectedInitiatives);
  };

  onChangeRelatedItems = (items) => {
    this.props.onChange('relatedItems', this.props.relatedItems.concat(items));
  };

  openRelatedItemDialog = () => {
    this.setState({ relatedItemDialogIsOpen: true });
  };

  closeRelatedItemDialog = () => {
    this.setState({ relatedItemDialogIsOpen: false });
  };

  openRelatedItemDeleteDialog = (i) => {
    const { relatedItems } = this.props;
    const item = relatedItems[i];
    this.setState({
      item,
      relatedItemDeleteDialogIsOpen: true,
    });
  };

  closeRelatedItemDeleteDialog = () => {
    this.setState({ item: null, relatedItemDeleteDialogIsOpen: false });
  };

  onDeleteRelatedItem = () => {
    let { relatedItems } = this.props;
    const { item } = this.state;
    let newRelatedItems = relatedItems.filter((relatedItem) => relatedItem.id !== item.id);
    this.setState(
      {
        relatedItemDeleteDialogIsOpen: false,
      },
      () => {
        this.props.onChange('relatedItems', newRelatedItems);
      },
    );
  };

  render() {
    const {
      initiatives,
      relatedItems,
      selectedInitiatives,
      allowedDealOption,
      itemData,
    } = this.props;
    const { relatedItemDialogIsOpen, relatedItemDeleteDialogIsOpen } = this.state;
    const filteredIds = relatedItems.map((item) => item.itemId);

    return (
      <>
        <>
          <HorizontalLine>
            <HorizontalLineText text={'RELATED ITEMS'} />
          </HorizontalLine>
          <GroupInputs text={'General'}>
            <StyledTable>
              <Table.Body data={initiatives}>
                {initiatives.map((initiative, i) => {
                  const selected = !(
                    selectedInitiatives.find((_initiative) => _initiative.id === initiative.id) ===
                    undefined
                  );
                  return (
                    <InitiativeRow
                      key={i}
                      initiative={initiative}
                      selected={selected}
                      onChange={(check) => this.onChangeInitiative(check, initiative, i)}
                    />
                  );
                })}
              </Table.Body>
            </StyledTable>
          </GroupInputs>
          <GroupInputs text={'Items'}>
            <StyledTable>
              <Table.Body data={relatedItems}>
                {relatedItems.map((item, i) => (
                  <RelatedItemRow
                    data={item}
                    key={i}
                    index={i}
                    onClose={this.openRelatedItemDeleteDialog}
                  />
                ))}
              </Table.Body>
              <Table.Footer justifyContent="center">
                <PlusButton text={'Add Relation Item'} onClick={this.openRelatedItemDialog} />
              </Table.Footer>
            </StyledTable>
          </GroupInputs>
          <RelatedIdemDialog
            filteredIds={filteredIds}
            onClose={this.closeRelatedItemDialog}
            onRelatedItems={this.onChangeRelatedItems}
            isOpen={relatedItemDialogIsOpen}
            allowedDealOption={allowedDealOption}
            itemData={itemData}
          />
          <YesNoDialog
            onYes={this.onDeleteRelatedItem}
            onNo={this.closeRelatedItemDeleteDialog}
            title={'Delete Related Item'}
            text={'Do you really want to Delete this relationship?'}
            isOpen={relatedItemDeleteDialogIsOpen}
          />
        </>
      </>
    );
  }
}

RelatedItemForm.propTypes = {
  relatedItems: PropTypes.array.isRequired,
  initiatives: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  selectedInitiatives: PropTypes.array.isRequired,
  allowedDealOption: PropTypes.bool,
  itemData: PropTypes.object,
};

RelatedItemForm.defaultProps = {
  allowedDealOption: true,
  itemData: null,
};

export default RelatedItemForm;
