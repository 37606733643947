import React from 'react';
import { withRouter } from 'react-router-dom';
import { withAuth } from '8base-react-sdk';
import { onErrorMixin } from '../../../shared/mixins';
import { View } from '@cobuildlab/react-simple-state';
import * as R from 'ramda';
import { inviteCompanyAdmins } from '../../settings/invitations/invitations.actions';
import { InviteCompanyAdminsDialog } from '../../settings/invitations/components/InviteCompanyAdminsDialog';
import { COMPANY_PORTFOLIO_OWNER } from '../../../shared/roles';
import sessionStore, { NEW_SESSION_EVENT } from '../../../shared/SessionStore';
import { updateUser } from '../wizard.actions';
import {
  OnInviteCompanyAdmins,
  OnAllianceInvitationError,
} from '../../settings/invitations/invitations-events';

class InviteCompanyAdminsView extends View {
  constructor(props) {
    super(props);
    this.state = {
      adminEmails: [],
      inviteCompanyAdminsDialogIsOpen: true,
      logoutIsOpen: false,
      loading: false,
    };

    this.onError = onErrorMixin.bind(this);
  }

  componentDidMount() {
    this.subscribe(OnAllianceInvitationError, (e) => {
      this.onError(e);
      this.setState({ loading: false });
    });

    this.subscribe(OnInviteCompanyAdmins, async (data) => {
      this.onClose();
    });
  }

  onChangeAdminEmails = (adminEmails) => {
    this.setState({ adminEmails });
  };

  onClose = async () => {
    this.setState({ loading: true }, async () => {
      await updateUser({ didInviteAdmins: true });
      // await fetchSession();
      this.props.onClose();
    });
  };

  onClickInviteCompanyAdmins = (invitation) => {
    const { adminEmails } = this.state;

    const { user } = sessionStore.getState(NEW_SESSION_EVENT);
    const companyUsers = user.companyUserRelation.items;
    const portfolioCompanyUser = companyUsers.find(
      ({ role }) => role.name === COMPANY_PORTFOLIO_OWNER,
    );
    const portfolioCompanyId = portfolioCompanyUser ? portfolioCompanyUser.company.id : null;

    this.setState({ loading: true }, () => {
      inviteCompanyAdmins(portfolioCompanyId, R.clone(adminEmails));
    });
  };

  render() {
    const { onClickInviteCompanyAdmins, onChangeAdminEmails } = this;

    const { loading, inviteCompanyAdminsDialogIsOpen, adminEmails } = this.state;

    return (
      <>
        <InviteCompanyAdminsDialog
          title="invite admins"
          isOpen={inviteCompanyAdminsDialogIsOpen}
          onCloseDialog={this.onClose}
          loading={loading}
          adminEmails={adminEmails}
          onChange={onChangeAdminEmails}
          onSubmit={onClickInviteCompanyAdmins}
        />
      </>
    );
  }
}

export default withRouter(withAuth(InviteCompanyAdminsView));
