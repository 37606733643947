import React from 'react';
import { Link } from 'react-router-dom';
import { SecondaryNavigation } from '@8base/boost';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { checkUrl } from '../../shared/utils';

const StyledLink = styled.div`
  position: relative;
  background-color: ${({ active }) => active && '#fff'};

  &:before {
    content: '';
    position: absolute;
    z-index: 1;
    height: 100%;
    width: 4px;
    background-color: ${({ active }) => active && '#3eb7f9'};
  }
`;

const LinkItem = ({ to, label, url, location }) => {
  let urlCheck = checkUrl(location, url);

  return (
    <StyledLink active={urlCheck}>
      <Link to={to}>
        <SecondaryNavigation.Item label={label} />
      </Link>
    </StyledLink>
  );
};

LinkItem.propTypes = {
  to: PropTypes.string,
  label: PropTypes.string,
  url: PropTypes.string,
  location: PropTypes.any.isRequired,
};

LinkItem.defaultProps = {
  to: '/',
  label: '',
  url: '',
};

export { LinkItem };
