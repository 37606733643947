import sessionStore, { APOLLO_CLIENT, NEW_SESSION_EVENT } from '../../shared/SessionStore';
import { NEXT_STEP_DELETE_MUTATION, NEXT_STEP_UPDATE_MUTATION } from './next-step-queries';
import { NEXT_STEP_ERROR_EVENT, NEXT_STEP_COMPLETE_EVENT } from './next-step-store';
import { IntegrityError } from '../../shared/errors';
import { sanitize8BaseReferenceFromObject } from '../../shared/utils';
import { NEXT_STEP_COMPLETED } from '../../shared/status';
import { canCompleteNextStep, canRestoreNextStep } from './next-step-permissions';
import { log, error } from '@cobuildlab/pure-logger';
import Flux from '@cobuildlab/flux-state';
import * as R from 'ramda';
import { NEXT_STEP_OPEN } from '../../shared/status';
import { nextStepCompletedEvent, nextStepErrorEvent } from './next-step-event';

/**
 * To get the nextSteps To Be deleted.
 *
 * @param  {Array} nextSteps - NextStep.
 * @param  {Array} originalNextSteps - OriginalNextSteps.
 * @returns {Array} Return Array.
 */
const nextStepsToBeDeleted = (nextSteps, originalNextSteps) => {
  const toBeDeleted = [];

  originalNextSteps.forEach((nextStep) => {
    const original = nextSteps.find((original) => original.id === nextStep.id);
    if (original === undefined) toBeDeleted.push(nextStep);
  });

  log('nextStepsToBeDeleted', toBeDeleted);
  return toBeDeleted;
};

/**
 * To delete nextSteps on the Items Update actions.
 *
 * @param  {Array} nextSteps - NextSteps.
 * @param  {Array} originalNextSteps - OriginalNextSteps.
 * @returns {Promise<[]>} Return promise.
 */
export const deleteNextSteps = async (nextSteps, originalNextSteps) => {
  console.log(`deleteNextSteps:`, nextSteps, originalNextSteps);
  const client = sessionStore.getState(APOLLO_CLIENT);
  const toBeDeleted = nextStepsToBeDeleted(nextSteps, originalNextSteps);

  let responses = [];
  toBeDeleted.forEach(async (nextStep) => {
    const data = { id: nextStep.id };
    try {
      responses.push(
        await client.mutate({
          mutation: NEXT_STEP_DELETE_MUTATION,
          variables: { data },
        }),
      );
    } catch (e) {
      error('deleteNextSteps', e, data);
      return Flux.dispatchEvent(NEXT_STEP_ERROR_EVENT, e);
    }
  });

  return responses;
};

/**
 * To get the nextSteps To Be updated.
 *
 * @param  {Array} nextSteps - NextSteps.
 * @param  {Array} originalNextSteps - OriginalNextSteps.
 * @returns {Array} Return Array.
 */
const nextStepsToBeUpdated = (nextSteps, originalNextSteps) => {
  const toBeUpdated = [];

  nextSteps.forEach((nextStep) => {
    if (nextStep.id) {
      const original = originalNextSteps.find((original) => original.id === nextStep.id);
      if (!R.equals(original, nextStep)) {
        toBeUpdated.push(nextStep);
      }
    }
  });

  log('nextStepsTobeUpdated', toBeUpdated);
  return toBeUpdated;
};

/**
 * To update nextSteps on the Items Update actions.
 *
 * @param  {Array} nextSteps
 * @param  {Array} originalNextSteps
 */

/**
 * To update nextSteps on the Items Update actions.
 *
 * @param  {Array} nextSteps - NextSteps.
 * @param  {Array} originalNextSteps - OriginalNextSteps.
 * @returns {[]} Return Array.
 */
export const updateNextSteps = (nextSteps, originalNextSteps) => {
  console.log(`updateNextSteps:`, nextSteps, originalNextSteps);
  const client = sessionStore.getState(APOLLO_CLIENT);
  const toBeUpdated = nextStepsToBeUpdated(nextSteps, originalNextSteps);

  let responses = [];
  toBeUpdated.forEach(async (nextStep) => {
    const data = R.clone(nextStep);
    sanitize8BaseReferenceFromObject(data, 'assignedTo');
    try {
      responses.push(
        await client.mutate({
          mutation: NEXT_STEP_UPDATE_MUTATION,
          variables: { data },
        }),
      );
    } catch (e) {
      error('updateNextSteps', e, data);
      return Flux.dispatchEvent(NEXT_STEP_ERROR_EVENT, e);
    }
  });

  return responses;
};

/**
 * To get the nextSteps To Be created on the items update actions.
 *
 * @param  {Array} nextSteps - NextSteps.
 * @returns {Array} Return Array.
 */
export const nextStepsToBeCreated = (nextSteps) => {
  const toBeCreated = [];

  nextSteps.forEach((nextStep) => {
    if (nextStep.id === undefined) toBeCreated.push(nextStep);
  });

  log('nextStepsToBeCreated', toBeCreated);
  return toBeCreated;
};

/**
 * Helper to change NextSteps arrays to 8base 'create' multiple
 * WARNING: This function mutates the data.
 *
 * @param {object} data - Object to mutate.
 */
export const sanitizeNextStepsCreate = (data) => {
  const nextStepsSanitizeData = data.nextSteps.map((nextStep) => {
    nextStep.assignedTo = data.assignedTo; // force item's assignedTo
    return nextStep;
  });

  data.nextSteps = { create: nextStepsSanitizeData };
};

/**
 * Prepare nextSteps for edit view.
 *
 * @param {object} data - To remove the unwanted nextStep properties.
 * @returns {Array}  To set the originalNextSteps.
 */
export const sanitizeNextStepsToEdit = (data) => {
  const nextStepsSanitizeData = data.nextSteps.items.map((nextStep) => {
    delete nextStep.__typename;
    return nextStep;
  });

  // original
  const originalNextSteps = R.clone(nextStepsSanitizeData);

  // force item's assignedTo
  const nextStepsSanitizeDataWithAssignedTo = nextStepsSanitizeData.map((nextStep) => {
    nextStep.assignedTo = data.assignedTo;
    return nextStep;
  });

  data.nextSteps = nextStepsSanitizeDataWithAssignedTo;
  return originalNextSteps;
};

/**
 * Complete next step without dispatchEvent.
 *
 * @param {object} nextStep - NextSteps.
 * @returns {Promise<*>} Return promise.
 */
export const completeNextStepWithoutDispatch = async (nextStep) => {
  const client = sessionStore.getState(APOLLO_CLIENT);
  const { selectedAlliance, user } = sessionStore.getState(NEW_SESSION_EVENT);

  if (!canCompleteNextStep(user, nextStep, selectedAlliance)) {
    throw new IntegrityError(`Permission Denied. Can't mark this Next Step as Completed`);
  }

  const data = {
    id: nextStep.id,
    status: NEXT_STEP_COMPLETED,
  };

  let response;
  try {
    response = await client.mutate({
      mutation: NEXT_STEP_UPDATE_MUTATION,
      variables: { data },
    });
  } catch (e) {
    error('completeNextSteps', e, data);
    throw e;
  }

  return response;
};

export const restoreNextStepWithoutDispatch = async (nextStep) => {
  const client = sessionStore.getState(APOLLO_CLIENT);
  const { selectedAlliance, user } = sessionStore.getState(NEW_SESSION_EVENT);

  if (!canRestoreNextStep(user, nextStep, selectedAlliance)) {
    throw new IntegrityError(`Permission Denied. Can't mark this Next Step as Completed`);
  }

  const data = {
    id: nextStep.id,
    status: NEXT_STEP_OPEN,
  };

  let response;
  try {
    response = await client.mutate({
      mutation: NEXT_STEP_UPDATE_MUTATION,
      variables: { data },
    });
  } catch (e) {
    error('completeNextSteps', e, data);
    throw e;
  }

  return response;
};

/**
 * Complete next step.
 *
 * @param {object}nextStep - NextStep.
 * @returns {Promise<void|*>} Return Promise.
 */
export const completeNextStep = async (nextStep) => {
  let response;
  try {
    response = await completeNextStepWithoutDispatch(nextStep);
  } catch (e) {
    nextStepErrorEvent.dispatch(e);
    return Flux.dispatchEvent(NEXT_STEP_ERROR_EVENT, e);
  }
  console.log(`nextStep`, nextStep);
  nextStepCompletedEvent.dispatch(nextStep);
  Flux.dispatchEvent(NEXT_STEP_COMPLETE_EVENT);
  return response;
};

/**
 * To automatically Complete item's next steps after complete the item.
 * // USE ONLY AFTER MARK A ITEM AS COMPLETED.
 *
 * @param  {object} item - A item with the nextSteps to mark as completed.
 * @returns {Promise<*>} Return Promise.
 */
export const completeItemsNextSteps = async (item) => {
  const {
    nextSteps: { items: nextSteps },
  } = item;

  const completeNextStepPromise = async (nextStep) => {
    if (nextStep.status === NEXT_STEP_COMPLETED) {
      return { message: 'Already Completed' };
    }

    const response = await completeNextStepWithoutDispatch(nextStep);
    return response;
  };

  let response;
  try {
    response = await Promise.all(nextSteps.map(completeNextStepPromise));
  } catch (e) {
    throw e;
  }

  return response;
};

/**
 * To automatically Restore item's next steps after restore the item.
 * // USE ONLY AFTER MARK A ITEM AS RESTORED.
 *
 * @param {object} item - Item.
 * @returns {Promise<*>} Return promise.
 */
export const restoreItemsNextSteps = async (item) => {
  const {
    nextSteps: { items: nextSteps },
  } = item;

  const restoreNextStepPromise = async (nextStep) => {
    if (nextStep.status === NEXT_STEP_OPEN) {
      return { message: 'Already Completed' };
    }

    const response = await restoreNextStepWithoutDispatch(nextStep);
    return response;
  };

  let response;
  try {
    response = await Promise.all(nextSteps.map(restoreNextStepPromise));
  } catch (e) {
    throw e;
  }

  return response;
};
