import React from 'react';
import { Card } from '@8base/boost';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

/**
 * Use this instead of Card.Body for Create/Edit Views,
 * add any css Padding, margin on this component.
 *
 */

const StyledCardFooter = styled(Card.Footer)`
  padding: 0px !important;
  height: 78px;
`;

const StyledPositionButton = styled('div')`
  margin-top: 19px;
  margin-right: 23px;
`;

const CardFooter = ({ children, ...rest }) => {
  return (
    <StyledCardFooter {...rest}>
      <StyledPositionButton>{children}</StyledPositionButton>
    </StyledCardFooter>
  );
};

CardFooter.propTypes = {
  children: PropTypes.any.isRequired,
};

export { CardFooter };
