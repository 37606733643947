import React from 'react';
import PropTypes from 'prop-types';
import { Loader, Card, Heading, Grid } from '@8base/boost';
import { CreateViewCardBody } from '../../../components/new-ui/card/CreateViewCardBody';
import ActionForm from './components/ActionForm';
import { onChangeDataMixin, onErrorMixin } from '../../../shared/mixins';
import * as R from 'ramda';
import ActionModel, { ACTION_DOCUMENTS } from './action-model';
import { withRouter } from 'react-router-dom';
import * as toast from '../../../components/toast/Toast';
import { View } from '@cobuildlab/react-simple-state';
import { fetchActionDetail, updateActionWithOutBusinessCase } from './action-actions';
import { fetchCurrentAllianceMembersAction } from '../../settings/alliance-management/alliance-actions';
import { fetchInitiativeList } from '../initiative/initiative-actions';
import { getCurrencyOnSession } from '../../../shared/alliance-utils';
import { ActionButton } from '../../../components/buttons/ActionButton';
import { openComments } from './action-actions';
import { fetchRelatedItems } from '../../related-item/related-item-actions';
import { sanitizeNextStepsToEdit } from '../../next-step/next-step-actions';
import { getItemByType } from '../../../shared/items-util';
import sessionStore, { NEW_SESSION_EVENT } from '../../../shared/SessionStore';
import { actionValidator } from './action-validators';
import RelatedItemForm from '../../related-item/components/RelatedItemForm';
import { TransparentButton } from '../../../components/buttons/TransparentButton';
import { RelatedItemsDetailTable } from '../../related-item/components/RelatedItemsDetailTable';
import { TopButtons } from '../../../components/buttons/TopButtons';
import { initiativesItemValidator } from '../initiative/initiative-validators';
import { InitiativeListTable } from '../initiative/components/InitiativeListTable';
import ActionDetailTable from './components/ActionDetailTable';
import { LeftProgressSection } from '../../../components/new-ui/LeftProgressSection';
import { SCREENS_ACTION_WITHOUT_BUSINESS_CASE } from '../screenView';
import { BoxCard } from '../../../components/new-ui/div/BoxCard';
import { CardFooter } from '../../../components/new-ui/card/CardFooter';
import { ACTION_COMPLETED } from '../../../shared/status';
import { OnInitiativeList } from '../initiative/initiative-events';
import { OnAllianceMemberList } from '../../settings/alliance-management/alliance-events';
import { OnRelatedItems } from '../../related-item/related-item-events';
import { OnActionUpdate, OnActionDetail, OnActionError } from './action-events';

// Edit Action
class ActionWithOutBusinessCaseEditView extends View {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        actionData: R.clone(ActionModel),
        relatedItems: [],
        initiatives: [],
      },
      clientCompany: null,
      partnerCompany: null,
      initiativesList: [],
      loading: true,
      step: 0,
    };
    this.onError = onErrorMixin.bind(this);
    this.originalNextSteps = [];
    this.onChangeData = onChangeDataMixin.bind(this);
    this.originalData = null;
    this.queryOptions = { isCacheFirst: true };
  }

  onChangeActionData = (name, value) => {
    const { data } = this.state;
    data.actionData[name] = value;
    this.setState({ data });
  };

  componentDidMount = () => {
    const { match } = this.props;
    if (!match.params.id) return toast.error('Action ID missing');

    this.subscribe(OnActionError, this.onError);
    this.subscribe(OnAllianceMemberList, (state) => {
      this.setState(
        {
          clientCompany: R.clone(state.clientCompany),
          partnerCompany: R.clone(state.partnerCompany),
        },
        () => {
          fetchActionDetail(match.params.id, this.queryOptions);
        },
      );
    });

    this.subscribe(OnActionDetail, (state) => {
      const actionData = R.clone(state.action);
      const { data } = this.state;
      actionData.documents = R.clone(actionData.documents.items);
      this.originalData = R.clone(actionData);
      actionData.itemId = actionData.itemActionRelation.id;
      this.originalNextSteps = sanitizeNextStepsToEdit(actionData);
      data.initiatives = R.clone(actionData.initiatives.items);
      data.actionData = actionData;
      this.setState(
        {
          data,
        },
        () => fetchRelatedItems(actionData.itemId),
      );
    });
    this.subscribe(OnInitiativeList, (state) => {
      const initiativesList = R.clone(state.initiativesList.items);
      this.setState({
        initiativesList,
      });
    });
    this.subscribe(OnActionUpdate, (state) => {
      toast.success(`Action Successfully Updated`);
      this.props.history.goBack();
    });

    this.subscribe(OnRelatedItems, (state) => {
      const items = state.item.itemsRelated.items.map((item) => getItemByType(item));
      const { data } = this.state;
      data.relatedItems = items;
      this.setState({
        data,
        loading: false,
      });
    });

    fetchInitiativeList('', 1, 1000, null, null, this.queryOptions);
    fetchCurrentAllianceMembersAction();
  };

  componentWillUnmount() {
    super.componentWillUnmount();
    localStorage.removeItem(ACTION_DOCUMENTS);
  }

  onSubmit = () => {
    this.setState({ loading: true }, () => {
      const { actionData, relatedItems, initiatives } = this.state.data;
      updateActionWithOutBusinessCase(
        R.clone(actionData),
        this.originalNextSteps,
        R.clone(relatedItems),
        R.clone(initiatives),
        this.originalData,
      );
    });
  };
  onActionStepChange = (nextStep) => {
    const { selectedAlliance } = sessionStore.getState(NEW_SESSION_EVENT);
    const actionData = R.clone(this.state.data.actionData);
    try {
      actionValidator(actionData, selectedAlliance);
    } catch (e) {
      return this.onError(e);
    }
    this.onScreen(nextStep);
  };

  onScreen = (step) => {
    this.setState({ step });
  };

  onRelatedItemsStepChange = (step) => {
    const initiatives = R.clone(this.state.data.initiatives);
    try {
      initiativesItemValidator(initiatives);
    } catch (e) {
      return this.onError(e);
    }
    this.onScreen(step);
  };

  render() {
    const { initiativesList, companyId, clientCompany, partnerCompany, loading, step } = this.state;
    const { actionData, relatedItems, initiatives } = this.state.data;
    const { user, selectedAlliance } = sessionStore.getState(NEW_SESSION_EVENT);
    const { history } = this.props;
    console.log(actionData);

    let content = <Loader stretch />;
    let footer = <></>;
    const currency = getCurrencyOnSession();
    let buttonsTop = '';
    if (actionData.status === ACTION_COMPLETED) history.push(`/management/amo-item`);
    if (!loading && step === 0) {
      content = (
        <div id={'c2gScreen'}>
          <ActionForm
            data={actionData}
            onChange={this.onChangeActionData}
            clientCompany={clientCompany}
            partnerCompany={partnerCompany}
            myCompanyId={companyId}
            currency={currency}
            selectedAlliance={selectedAlliance}
            user={user}
          />
        </div>
      );
      footer = (
        <CardFooter>
          <ActionButton onClick={() => this.onActionStepChange(1)} text="Next" />
        </CardFooter>
      );
    }

    if (!loading && step === 1) {
      const itemData = { id: actionData.id, type: actionData.__typename };

      content = (
        <div id={'c2gScreen'}>
          <RelatedItemForm
            relatedItems={relatedItems}
            initiatives={initiativesList}
            onChange={(key, value) => {
              this.onChangeData(key, value);
            }}
            selectedInitiatives={initiatives}
            allowedDealOption={true}
            itemData={itemData}
          />
        </div>
      );

      footer = (
        <CardFooter>
          <ActionButton onClick={() => this.onRelatedItemsStepChange(2)} text={'Next'} />
          <TransparentButton onClick={() => this.onScreen(0)} text={'Previous'} />
        </CardFooter>
      );
    }

    if (!loading && step === 2) {
      const actionDetails = R.clone(actionData);
      actionDetails.documents = { items: actionDetails.documents };
      actionDetails.nextSteps = { items: actionDetails.nextSteps };
      content = (
        <div id={'c2gScreen'}>
          <ActionDetailTable data={actionDetails} currency={currency} />
          <InitiativeListTable initiatives={initiatives} />
          <RelatedItemsDetailTable relatedItems={relatedItems} />
        </div>
      );
      footer = (
        <CardFooter>
          <ActionButton onClick={this.onSubmit} text={'Update Action'} />
          <TransparentButton onClick={() => this.onScreen(1)} text={'Previous'} />
        </CardFooter>
      );
    }

    if (!loading)
      buttonsTop = (
        <TopButtons
          onClickClosed={history.goBack}
          onClickCollaborated={() => openComments(actionData)}
        />
      );

    return (
      <React.Fragment>
        <Card.Header>
          <Heading type="h4" text="Edit Action" />
          {buttonsTop}
        </Card.Header>
        <CreateViewCardBody>
          <Grid.Layout columns="30% 70%" areas={[['left', 'right']]} style={{ width: '100%' }}>
            <Grid.Box area="left">
              <LeftProgressSection
                sections={SCREENS_ACTION_WITHOUT_BUSINESS_CASE}
                currentScreen={step}
              />
            </Grid.Box>
            <BoxCard>
              <Grid.Box area="right">{content}</Grid.Box>
            </BoxCard>
          </Grid.Layout>
        </CreateViewCardBody>
        {footer}
      </React.Fragment>
    );
  }
}

ActionWithOutBusinessCaseEditView.propTypes = {
  history: PropTypes.object.isRequired,
};

export default withRouter(ActionWithOutBusinessCaseEditView);
