import React from 'react';
import { Dialog, Grid } from '@8base/boost';
import PropTypes from 'prop-types';
import { ActionButton } from './buttons/ActionButton';
import { TransparentButton } from './buttons/TransparentButton';

export default class ModalWithInputs extends React.Component {
  render() {
    const {
      onYes,
      onNo,
      isOpen,
      text,
      title,
      children,
      yesText = 'Yes',
      noText = 'No',
    } = this.props;
    let { onClose } = this.props;

    if (!onClose) onClose = onNo;

    return (
      <Dialog size="sm" isOpen={isOpen}>
        <Dialog.Header title={title} onClose={onClose} />
        <Dialog.Body scrollable>
          <Grid.Layout gap="sm" stretch>
            <Grid.Box>{text}</Grid.Box>
            <Grid.Box>{children}</Grid.Box>
          </Grid.Layout>
        </Dialog.Body>
        <Dialog.Footer>
          <TransparentButton text={noText} onClick={onNo} />
          <ActionButton text={yesText} onClick={onYes} />
        </Dialog.Footer>
      </Dialog>
    );
  }
}

ModalWithInputs.propTypes = {
  onYes: PropTypes.func.isRequired,
  onClose: PropTypes.func,
  onNo: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  yesText: PropTypes.string,
  noText: PropTypes.string,
  children: PropTypes.element.isRequired,
};

ModalWithInputs.defaultProps = {
  onClose: () => {},
  yesText: '',
  noText: '',
};
