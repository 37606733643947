import React from 'react';
import { View } from '@cobuildlab/react-simple-state';
import { Query } from 'react-apollo';
import * as R from 'ramda';
import {
  generateSalesPipelineTrendsFilters,
  createSnapshotList,
} from './sales-pipeline-trends-utils';
import { createSortValue, generateRequestMonthsInfo } from '../utils';
import { SORT_NAME_MAP } from '../reports-model';
import { SALES_PIPELINE_TRENDS_QUERY } from '../reports-queries';
import { OnSalePipelineTrendsFilter } from './sales-pipeline-trends-events';
import { INITIAL_STATE } from './sales-pipeline-trends-model';
import { OnNewSession } from '../../../shared/session-events';

export const SalesPipelineTrendsDataHandler = (WrappedComponent) => {
  return class extends View {
    constructor(props) {
      super(props);

      const config = OnSalePipelineTrendsFilter.get() || INITIAL_STATE;

      this.state = {
        queryArgs: this.buildRequestArgs(config),
        config,
        selected: 'clientCompanyMonthSnapshots',
      };
    }

    componentDidMount() {
      this.subscribe(OnSalePipelineTrendsFilter, this.onConfigChange);
    }

    onConfigChange = (config) => {
      this.setState({
        queryArgs: this.buildRequestArgs(config),
        config,
      });
    };

    buildRequestArgs = (config) => {
      const args = {
        filter: {
          AND: [],
        },
        groupBy: [],
        sort: [],
      };

      const selectedAlliance = R.clone(OnNewSession.get().selectedAlliance);
      const filtersList = generateSalesPipelineTrendsFilters(config.filters);

      const groups = [...config.groups];

      groups.reverse().forEach(({ key, sort }) => {
        args.groupBy.unshift(key);

        if (sort) {
          args.sort = args.sort.filter((item) => !Object.keys(item).includes(key));
          args.sort.unshift({ [key]: sort });
        }
      });
      args.groupBy.unshift('company.id');
      args.groupBy.push('year', 'month');

      if (Array.isArray(config.filters)) {
        args.filter.AND.push(...filtersList);
      }

      if (config.organization && config.organization.id) {
        args.filter.AND.push({
          company: {
            id: {
              equals: config.organization.id,
            },
          },
        });
      }

      args.sort = args.sort.map((item) => {
        const key = Object.keys(item)[0];
        const keyValue = SORT_NAME_MAP[key] ? SORT_NAME_MAP[key] : key;
        const keys = keyValue.split('.');
        return createSortValue(keys, item[key]);
      });

      args.filter.AND.push({
        alliance:
          selectedAlliance && selectedAlliance.id
            ? { id: { equals: selectedAlliance.id } }
            : undefined,
      });

      return args;
    };

    handleData = (data) => {
      if (!data) return null;

      const { config } = this.state;
      const dateFilter = R.pathOr([], ['filters'], config).find(
        (filter) => filter.fieldId === 'id-2',
      );

      const requestMonthsInfo = generateRequestMonthsInfo(dateFilter);

      const monthlySnapshots = R.pathOr([], ['salesPipelineTrends', 'monthlySnapshots'], data);
      const groupBy = R.pathOr([], ['salesPipelineTrends', 'groupBy'], data);

      const enhancedData = createSnapshotList(monthlySnapshots, groupBy, requestMonthsInfo);

      return {
        enhancedData,
        groupBy,
        monthlySnapshots,
        requestMonthsInfo,
      };
    };

    render() {
      const {
        selectedAlliance,
        user: { firstName, lastName },
      } = OnNewSession.get();

      const { clientCompany, partnerCompany } = selectedAlliance;
      const { queryArgs } = this.state;

      return (
        <Query
          query={SALES_PIPELINE_TRENDS_QUERY}
          variables={queryArgs}
          skip={!selectedAlliance || !selectedAlliance.id}>
          {({ data, loading }) => {
            return (
              <>
                <WrappedComponent
                  {...this.props}
                  sharedData={{ ...this.state, firstName, lastName, clientCompany, partnerCompany }}
                  requestedData={data && this.handleData(data)}
                  loading={loading}
                />
              </>
            );
          }}
        </Query>
      );
    }
  };
};
