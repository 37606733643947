/* eslint-disable react/prop-types */
import React from 'react';
import { InputField, SelectField } from '@8base/boost';

const PhoneNumberInput = (props) => {
  const { key, phone, onChangePhone, onChangeType, types } = props;
  let { phoneType } = props;

  return (
    <>
      <InputField
        label="Phone Number"
        placeholder="xxx-xxx-xxxx"
        input={{
          name: `phone-${key}`,
          value: phone,
          onChange: (value) => onChangePhone(value),
        }}
      />
      <SelectField
        label="Type"
        input={{
          name: `phoneType-${key}`,
          value: phoneType,
          onChange: onChangeType,
        }}
        meta={{}}
        placeholder="Work"
        options={types.map((type) => {
          return { label: type.name, value: type.id };
        })}
      />
    </>
  );
};

export default PhoneNumberInput;
