import React from 'react';
import { Card, Heading, Loader } from '@8base/boost';
import { DetailViewCardBody } from '../../../components/card/DetailViewCardBody';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import * as toast from '../../../components/toast/Toast';
import { View } from '@cobuildlab/react-simple-state';
import { withRouter } from 'react-router-dom';
import sessionStore, { NEW_SESSION_EVENT } from '../../../shared/SessionStore';
import CompanyDetailTable from './components/CompanyDetailTable';
import YesNoDialog from '../../../components/dialogs/YesNoDialog';
import {
  fetchCompanyAction,
  fetchCompanySubscription,
  leaveCompanyAction,
} from './company-actions';
import { onErrorMixin } from '../../../shared/mixins';
import {
  canEditCompanyPermission,
  canLeaveCompany,
  canManageCompanyMembers,
} from '@cobuildlab/collabtogrow-permissions';
import { getCurrencyOnSession } from '../../../shared/alliance-utils';
import { DangerButton } from '../../../components/buttons/DangerButton';
import { TransparentButtonFontAwesome } from '../../../components/buttons/TransparentButtonFontAwesome';
import { ActionButtonClose } from '../../../components/buttons/ActionButtonClose';
import { CardFooter } from '../../../components/new-ui/card/CardFooter';
import { CompanyUsersList } from './components/CompanyUsersList';
import CompanyCurrentPlanDetailTable from './components/CompanyCurrentPlanDetailTable';
import {
  OnCompanyError,
  OnCompanyDetail,
  OnCompanyLeave,
  OnCompanySubscription,
} from './company-events';

export const COMPANY_DOCUMENT = 'companyDocument';

class CompanyDetailView extends View {
  constructor(props) {
    super(props);
    this.state = {
      company: null,
      loading: true,
      isOwner: false,
      companyUserId: 0,
      leaveModalIsOpen: false,
      canLeaveCompany: false,
      currentPlan: null,
    };
    this.onError = onErrorMixin.bind(this);
  }

  componentDidMount() {
    const { match } = this.props;
    if (!match.params.id) return toast.error('Company ID missing');

    this.subscribe(OnCompanyError, this.onError);
    this.subscribe(OnCompanyDetail, (state) => {
      const user = sessionStore.getState(NEW_SESSION_EVENT).user;
      const companyUser = state.company.companyUserRelation.items.find(
        (companyInformation) => companyInformation.user.id === user.id,
      );

      if (!companyUser) {
        toast.error('You are not part of this Company');
        return this.props.history.push('/settings');
      }

      const companyUserId = companyUser.id;
      this.setState({
        company: R.clone(state.company),
        companyUserId,
        loading: false,
      });
    });

    this.subscribe(OnCompanyLeave, () => {
      toast.success('You left the Company');
      this.props.history.push('/settings');
      window.location.reload();
    });

    this.subscribe(OnCompanySubscription, (state) => {
      const companySubscriptions = state.companySubscriptionsList.items.map(
        (companySubscription) => ({
          ...companySubscription,
        }),
      );

      let currentPlan = null;

      if (companySubscriptions.length) {
        const lastCompanySubscription = companySubscriptions[companySubscriptions.length - 1];
        currentPlan = lastCompanySubscription.subscriptionPlan;
      }

      this.setState({ currentPlan });
    });

    fetchCompanySubscription();
    fetchCompanyAction(match.params.id);
  }

  componentWillUnmount() {
    super.componentWillUnmount();
    localStorage.removeItem(COMPANY_DOCUMENT);
  }

  onLeaveCompany = (id) => {
    this.setState({
      companyId: id,
      leaveModalIsOpen: true,
    });
  };

  onYesLeave = () => {
    this.setState(
      {
        leaveModalIsOpen: false,
        loading: true,
      },
      () => leaveCompanyAction(this.state.companyUserId, this.state.company),
    );
  };

  onClose = () => {
    this.setState({
      leaveModalIsOpen: false,
    });
  };

  render() {
    const { company, loading, users, leaveModalIsOpen, currentPlan } = this.state;
    const user = sessionStore.getState(NEW_SESSION_EVENT).user;
    const currency = getCurrencyOnSession();
    console.log('DEBUG:::', company);
    let heading = '';
    let content = <Loader stretch />;
    let buttonsBottom = '';

    if (company && !loading) {
      content = (
        <div id={'c2gScreen'}>
          <CompanyDetailTable
            canEdit={canEditCompanyPermission(user, company)}
            currency={currency}
            data={company}
            users={users}
            isDisabled
            onClickEdit={() =>
              this.props.history.push(`/settings/company-management/edit/${company.id}`)
            }
          />
          <CompanyCurrentPlanDetailTable currentPlan={currentPlan} />
          <CompanyUsersList companyUsers={company.companyUserRelation.items} />
        </div>
      );
      heading = (
        <>
          <Heading type="h4" text={company.name} style={{ marginRight: '10px' }} />
          <>
            <div className="company-icons">
              {company && canManageCompanyMembers(user, company) && !loading ? (
                <TransparentButtonFontAwesome
                  text={'Manage Members'}
                  fontAwesomeIcon={'users-cog'}
                  onClick={() =>
                    this.props.history.push(`/settings/company-management/members/${company.id}`)
                  }
                />
              ) : null}
              <ActionButtonClose onClick={() => this.props.history.goBack()} />
            </div>
          </>
        </>
      );
      buttonsBottom = (
        <>
          <div className="positionBottonRight">
            {!loading ? (
              <DangerButton
                text={'Leave Company'}
                fontAwesomeIcon={'door-open'}
                onClick={() => this.onLeaveCompany(company.id)}
              />
            ) : null}
          </div>
        </>
      );
    }

    return (
      <React.Fragment>
        <Card.Header>{heading}</Card.Header>
        <DetailViewCardBody>{content}</DetailViewCardBody>
        <YesNoDialog
          isOpen={leaveModalIsOpen}
          onYes={this.onYesLeave}
          onNo={this.onClose}
          onClose={this.onClose}
          text={'Are you sure you want to Leave this Company?'}
          title={'Leave Company'}
        />
        <CardFooter>{canLeaveCompany ? buttonsBottom : null}</CardFooter>
      </React.Fragment>
    );
  }
}

CompanyDetailView.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default withRouter(CompanyDetailView);
