import { FIELD_TYPE } from '@8base/utils';
import moment from 'moment';

import { DISPLAY_NAME_DICTS } from '../model';
import { getGroupingsTitle } from '../utils';

const generateSinglePivotTable = (groupList, fieldsList, namesDict, groupKey, groupValue) => {
  //  In the table we do not display the column by which we group
  const columnsMeta = fieldsList.filter(({ name }) => name !== groupKey);

  // create headerRow
  const headerRow = columnsMeta.map(({ name, fieldType, displayName }) => {
    return {
      value: namesDict[name] ? namesDict[name] : displayName,
      fieldType,
      name,
    };
  });

  // create totalRow
  const totalRow = columnsMeta.map(({ name, fieldType }, columnIndex) => {
    let value = fieldType === FIELD_TYPE.NUMBER ? 0 : '';

    if (columnIndex === 0 && fieldType !== FIELD_TYPE.NUMBER) {
      value = 'Total';
    }

    return {
      value,
      fieldType,
      name,
    };
  });

  // create dataRows
  const dataRows = groupList.map((currentItem) => {
    return columnsMeta.reduce((accum, columnMeta, cellIndex) => {
      const { name, fieldType } = columnMeta;

      const value =
        fieldType !== FIELD_TYPE.DATE
          ? currentItem[name]
          : moment(currentItem[name]).format('MM/DD/YYYY');

      const cellMeta = {
        value,
        fieldType,
        name,
      };

      if (fieldType === FIELD_TYPE.NUMBER) {
        totalRow[cellIndex].value += Number(value);
      }

      accum.push(cellMeta);

      return accum;
    }, []);
  });

  // if we have groupKey, we return table with titleRow
  if (groupKey) {
    // find meta information about field by which we group
    const titleFieldMeta = fieldsList.find(({ name }) => name === groupKey);

    return {
      title: getGroupingsTitle(titleFieldMeta, groupValue, groupKey, groupList, namesDict),
      headerRow,
      dataRows,
      totalRow,
    };
  }

  // if we haven't got any grouping, we return table without titleRow
  return {
    headerRow,
    dataRows,
    totalRow,
  };
};

const generatePivotTablesData = (pivotTableData, config) => {
  const { groups, itemType, fieldsList } = config;

  const groupBy = groups.map(({ key }) => key);

  const namesDict = DISPLAY_NAME_DICTS[itemType];

  if (groups.length === 0) {
    return generateSinglePivotTable(pivotTableData, fieldsList, namesDict);
  }
  return pivotTableData.map(([groupValue, groupList]) =>
    generateSinglePivotTable(groupList, fieldsList, namesDict, groupBy[0], groupValue),
  );
};

const generatePivotTablesDataExtend = (pivotTableData, config) => {
  const generator = (data) => {
    data.dataRows.forEach((row) => {
      // find item id for assign to first item to row
      const itemId = row.find((item) => item.name === 'id');
      row.forEach((cell, index) => {
        if (itemId) {
          cell.itemId = itemId.value;
          cell.itemType = config.itemType.toLowerCase();
        }

        if (cell.name === 'id') row.splice(index, 1);
      });
    });

    //unset row id
    data.headerRow.forEach((cell, index) => {
      if (cell.name === 'id') data.headerRow.splice(index, 1);
    });

    //unset row id
    data.totalRow.forEach((cell, index) => {
      if (cell.name === 'id') data.totalRow.splice(index, 1);
    });
  };

  if (Array.isArray(pivotTableData)) {
    pivotTableData.forEach(generator);
  } else {
    generator(pivotTableData);
  }
};

export { generatePivotTablesData };
export { generatePivotTablesDataExtend };
