import styled from '@emotion/styled';

export const GeneralInfo = styled.div`
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
  white-space: nowrap;
  padding: 24px;
  border-bottom: 1px solid #e7ecf1;
`;

export const Break = styled.div`
  flex-basis: 100%;
  height: 10px;
`;

export const GeneralInfoItem = styled.div`
  display: inline-block;
  padding: 20px 15px;
  border-radius: 5px;
  border: 1px solid #eaf0f7;
  margin: 0 20px;
`;

export const GeneralInfoName = styled.p`
  margin-bottom: 15px;
  font-size: 1.2rem;
  text-transform: uppercase;
  color: #939ea7;
`;

export const GeneralInfoValue = styled.span`
  font-size: 3.4rem;
  line-height: 1.4;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #323c47;
`;
