import * as R from 'ramda';
import sessionStore, { NEW_SESSION_EVENT } from '../../shared/SessionStore';
import { ACTIVE_ITEM_COMPLETED, INITIATIVE_SUBMITTED_FOR_APPROVAL } from '../../shared/status';
import { canCompleteNextStep } from '../next-step/next-step-permissions';
import { completeNextStep } from '../next-step/next-step-actions';
import { canCompletedAction } from '@cobuildlab/collabtogrow-permissions';
import { completedAction } from '../management/action/action-actions';
import { canCompletedFundingRequest } from '../management/funding-request/funding-request-permissions';
import { completedFundingRequest } from '../management/funding-request/funding-request-action';
import { canCompletedIssue } from '../management/issue/issue-permissions';
import { completedIssue } from '../management/issue/issue-actions';
import { canCompletedRisk } from '@cobuildlab/collabtogrow-permissions';
import { completedRisk } from '../management/risk/risk-action';
import { canCompletedIdea } from '../management/idea/idea-permissions';
import { completedIdea } from '../management/idea/idea-actions';
import moment from 'moment';
import { INITIATIVE_TYPE, ALLIANCE_TYPE } from '../../shared/item-types';
import { ALLIANCE_SER } from '../../shared/roles';
import {
  ACTION_SUBMITTED_FOR_APPROVAL,
  IDEA_SUBMITTED_FOR_APPROVAL,
  FUNDING_REQUEST_SUBMITTED_FOR_APPROVAL,
} from '../../shared/status';
import { getRoleOnAlliance } from '../../shared/alliance-utils';

/**
 * Group active items by revisedDueDate.
 *
 * @param {Array}activeItems - Active Items.
 * @returns {any} Object.
 */
export const getTasksForDate = (activeItems) => {
  const byDate = R.groupBy(({ revisedDueDate }) => {
    const _revisedDueDate = revisedDueDate || undefined;
    const date = moment(_revisedDueDate).format('YYYY-MM-DD');
    return date;
  });
  const activeItemsByDate = byDate(activeItems);

  return activeItemsByDate;
};

/**
 * To check if an active item is overdue.
 *
 * @param  {Array}  activeItem - Active Items.
 * @returns {boolean}
 */

const isOverdue = (activeItem) => {
  const { revisedDueDate, status } = activeItem;
  if (status === ACTIVE_ITEM_COMPLETED) return false;
  return moment().isSameOrAfter(moment(revisedDueDate));
};

/**
 * OVER DUE (not Completed and revisedDueDate already passed).
 *
 * @param {Array}activeItems - Active Items.
 * @returns {*} Object.
 */
const filterActiveItemsOverDue = (activeItems) => {
  const overDueItems = activeItems.filter(isOverdue);

  return overDueItems;
};

/**
 * DUE THIS WEEK (not Completed and revisedDueDate less than a week from now).
 *
 * @param {Array}activeItems - Active Items.
 * @returns {*} Object.
 */
const filterActiveItemsDueThisWeek = (activeItems) => {
  const dueThisWeekItems = activeItems.filter(({ revisedDueDate, status }) => {
    if (status === ACTIVE_ITEM_COMPLETED) return false;

    if (moment().isSameOrAfter(moment(revisedDueDate))) return false;

    if (moment().diff(moment(revisedDueDate), 'weeks') === 0) return true;

    return false;
  });

  return dueThisWeekItems;
};

/**
 * NOT DUE (not Completed and revisedDueDate more than a week from now ).
 *
 * @param {object}activeItems - Active Items.
 * @returns {*} Object.
 */
const filterActiveItemsNotDue = (activeItems) => {
  const notDueItems = activeItems.filter(({ revisedDueDate, status }) => {
    if (status === ACTIVE_ITEM_COMPLETED) return false;

    if (!revisedDueDate) return true;

    if (moment().diff(moment(revisedDueDate), 'weeks') < 0) return true;

    return false;
  });

  return notDueItems;
};

/**
 * COMPLETED (Completed).
 *
 * @param  {Array} activeItems - Active Items.
 * @returns {*} Array.
 */
const filterActiveItemsCompleted = (activeItems) => {
  const completedDueItems = activeItems.filter(({ status }) => {
    return status === ACTIVE_ITEM_COMPLETED;
  });

  return completedDueItems;
};

/**
 * Filter activeItems by OVER DUE, DUE THIS WEEK, NOT DUE and Completed.
 *
 * @param {object}activeItems - Items with revisedDueDate and status.
 * @returns {{overDueItems: Array, notDueItems: *, completedDueItems: Array, dueThisWeekItems: Array}} Object.
 */
export const filterActiveItemsByDueDate = (activeItems) => {
  const overDueItems = filterActiveItemsOverDue(activeItems);
  const dueThisWeekItems = filterActiveItemsDueThisWeek(activeItems);
  const notDueItems = filterActiveItemsNotDue(activeItems);
  const completedDueItems = filterActiveItemsCompleted(activeItems);
  return { overDueItems, dueThisWeekItems, notDueItems, completedDueItems };
};

/**
 * Get the active items for a specific user.
 *
 * @param {Array} items - Alliances's Items with revisedDueDate/originalDueDate.
 * @param {Array} initiatives - Alliance's Initiatives SUBMITTED_FOR_APPROVAL.
 * @returns {object} AllActiveItems, inProgresActiveItems, completedActiveItems.
 */
export const getUserActiveItems = (items, initiatives) => {
  const { user, selectedAlliance } = sessionStore.getState(NEW_SESSION_EVENT);

  if (!selectedAlliance)
    return { allActiveItems: [], inProgresActiveItems: [], completedActiveItems: [] };

  const roleOnAlliance = getRoleOnAlliance(user, selectedAlliance);
  const userRole = roleOnAlliance ? roleOnAlliance.name : '';
  const userId = user.id;
  const itemActiveItems = [];

  // Check if the item is assignedTo the user or if the user is SER & the item is overdue & get the item's nextSteps as ActiveItems
  items.forEach((item) => {
    const activeItem = getActiveItemByType(item);
    if (
      (activeItem.assignedTo && activeItem.assignedTo.id === userId) ||
      (userRole === ALLIANCE_SER && isOverdue(activeItem))
    ) {
      const activeItemsFromNextSteps = getActiveItemsByNextSteps(activeItem);
      // push the ActiveItem
      itemActiveItems.push(activeItem);
      // push the nextSteps ActiveItems
      itemActiveItems.push.apply(itemActiveItems, activeItemsFromNextSteps);
    }
  });

  // Check if the user is SER
  const initiativeActiveItems = initiatives
    .filter(() => userRole === ALLIANCE_SER)
    .map(getActiveItemByInitiative);

  const allActiveItems = itemActiveItems.concat(initiativeActiveItems);

  const inProgresActiveItems = getInProgressActiveItems(allActiveItems);
  const completedActiveItems = getCompletedActiveItems(allActiveItems);

  return { allActiveItems, inProgresActiveItems, completedActiveItems };
};

/**
 * Get the active items from initiative's items.
 *
 * @param {Array} items - Initiatives's Items with revisedDueDate/originalDueDate.
 * @returns {object} AllActiveItems, inProgresActiveItems, completedActiveItems.
 */
export const getInitiativeActiveItems = (items) => {
  const activeItems = [];

  items.forEach((item) => {
    const activeItem = getActiveItemByType(item);
    const activeItemsFromNextSteps = getActiveItemsByNextSteps(activeItem);
    // push the ActiveItem
    activeItems.push(activeItem);
    // push the nextSteps ActiveItems
    activeItems.push.apply(activeItems, activeItemsFromNextSteps);
  });

  const allActiveItems = R.clone(activeItems);
  const inProgresActiveItems = getInProgressActiveItems(allActiveItems);
  const completedActiveItems = getCompletedActiveItems(allActiveItems);

  return { allActiveItems, inProgresActiveItems, completedActiveItems };
};

/**
 * Return All the activeItems with status !== Completed.
 *
 * @param {Array} activeItems - A list of Active items.
 * @returns {Array} In progress active items.
 */
const getInProgressActiveItems = (activeItems) => {
  const inProgresItems = activeItems.filter(({ status }) => status !== ACTIVE_ITEM_COMPLETED);

  return inProgresItems;
};

/**
 * Return All the activeItems with status === Completed.
 *
 * @param {Array}activeItems - Active Items.
 * @returns {*} Array.
 */
const getCompletedActiveItems = (activeItems) => {
  const completedItems = activeItems.filter(({ status }) => status === ACTIVE_ITEM_COMPLETED);

  return completedItems;
};

/**
 * Extract the information from the nextSteps to create a list of activeItems.
 *
 * @param {object}activeItem - Active item.
 * @returns {{revisedDueDate, owner, item, detailsUrl: string, name: *, id, completeFunction, type: string, title: string, assignedTo, status}[]}Object.
 */
export const getActiveItemsByNextSteps = (activeItem) => {
  const { user, selectedAlliance } = sessionStore.getState(NEW_SESSION_EVENT);
  const nextSteps = activeItem.nextSteps ? R.clone(activeItem.nextSteps.items) : [];

  const activeItems = nextSteps.map((nextStep) => {
    const _canCompleteNextStep = canCompleteNextStep(user, nextStep, selectedAlliance);
    const completeFunction = _canCompleteNextStep ? completeNextStep : null;

    const _activeItem = {
      type: 'Next Step',
      title: 'Next Step',
      detailsUrl: activeItem.detailsUrl,
      name: nextStep.description,
      id: nextStep.id,
      status: nextStep.status,
      revisedDueDate: nextStep.dueDate,
      assignedTo: nextStep.assignedTo,
      owner: activeItem.owner,
      item: activeItem,
      completeFunction,
    };

    return _activeItem;
  });

  return activeItems;
};

/**
 * Extracts the information of the Item depending of the Type.
 *
 * @param {object}item - Item.
 * @returns {{revisedDueDate: *, nextSteps: *, owner: *, itemId: *, detailsUrl: string, name: *, id: *, completeFunction: null, type: string, title: string, assignedTo: *, status: *}|{revisedDueDate: *, owner: *, detailsUrl: string, type: string, title: (string), assignedTo: *, nextSteps: *, itemId: *, createdAt: *, name: *, id: *, completeFunction: null, status: *}|{revisedDueDate: *, nextSteps: *, owner: *, itemId: *, detailsUrl: string, name: *, id: *, completeFunction: null, type: string, title: (string), assignedTo: *, status: *}|null} Object.
 */
const getActiveItemByType = (item) => {
  const { user, selectedAlliance } = sessionStore.getState(NEW_SESSION_EVENT);
  if (item.action) {
    const _canCompleteAction = canCompletedAction(user, item.action, selectedAlliance);
    const completeFunction = _canCompleteAction ? completedAction : null;
    let url = 'action';
    const title =
      item.action.status === ACTION_SUBMITTED_FOR_APPROVAL
        ? 'Review the Action For Approval'
        : 'Action';

    if (!item.action.businessCase) url = 'action-without-business-case';
    return {
      type: 'Action',
      title,
      detailsUrl: `/management/${url}/${item.action.id}`,
      id: item.action.id,
      itemId: item.id,
      name: item.action.name,
      status: item.action.status,
      revisedDueDate:
        item.action.status === ACTION_SUBMITTED_FOR_APPROVAL
          ? item.action.createdAt
          : item.action.revisedDueDate || item.action.originalDueDate,
      assignedTo: item.action.assignedTo,
      nextSteps: item.action.nextSteps,
      owner: item.action.owner,
      completeFunction,
    };
  }
  if (item.idea) {
    const _canCompleteIdea = canCompletedIdea(user, item.idea, selectedAlliance);
    const completeFunction = _canCompleteIdea ? completedIdea : null;
    const title =
      item.idea.status === IDEA_SUBMITTED_FOR_APPROVAL ? 'Review the Idea For Approval' : 'Idea';

    return {
      type: 'Idea',
      title,
      detailsUrl: `/management/idea/${item.idea.id}`,
      id: item.idea.id,
      itemId: item.id,
      name: item.idea.name,
      status: item.idea.status,
      revisedDueDate:
        item.idea.status === IDEA_SUBMITTED_FOR_APPROVAL
          ? item.idea.createdAt
          : item.idea.revisedDueDate || item.idea.originalDueDate,
      assignedTo: item.idea.assignedTo,
      nextSteps: item.idea.nextSteps,
      owner: item.idea.owner,
      completeFunction,
    };
  }
  if (item.issue) {
    const _canCompleteIssue = canCompletedIssue(user, item.issue, selectedAlliance);
    const completeFunction = _canCompleteIssue ? completedIssue : null;

    return {
      type: 'Issue',
      title: 'Issue',
      detailsUrl: `/management/issue/${item.issue.id}`,
      id: item.issue.id,
      itemId: item.id,
      name: item.issue.name,
      status: item.issue.status,
      revisedDueDate: item.issue.revisedDueDate || item.issue.originalDueDate,
      assignedTo: item.issue.assignedTo,
      nextSteps: item.issue.nextSteps,
      owner: item.issue.owner,
      completeFunction,
    };
  }
  if (item.risk) {
    const _canCompleteRisk = canCompletedRisk(user, item.risk, selectedAlliance);
    const completeFunction = _canCompleteRisk ? completedRisk : null;

    return {
      type: 'Risk',
      title: 'Risk',
      detailsUrl: `/management/risk/${item.risk.id}`,
      id: item.risk.id,
      itemId: item.id,
      name: item.risk.name,
      status: item.risk.status,
      revisedDueDate: item.risk.revisedDueDate || item.risk.originalDueDate,
      assignedTo: item.risk.assignedTo,
      nextSteps: item.risk.nextSteps,
      owner: item.risk.owner,
      completeFunction,
    };
  }
  if (item.fundingRequest) {
    const _canCompleteFundingRequest = canCompletedFundingRequest(
      user,
      item.fundingRequest,
      selectedAlliance,
    );
    const completeFunction = _canCompleteFundingRequest ? completedFundingRequest : null;
    const title =
      item.fundingRequest.status === FUNDING_REQUEST_SUBMITTED_FOR_APPROVAL
        ? 'Review the Funding Request For Approval'
        : 'Funding Request';
    return {
      type: 'Funding Request',
      title,
      detailsUrl: `/management/funding-request/${item.fundingRequest.id}`,
      id: item.fundingRequest.id,
      itemId: item.id,
      name: item.fundingRequest.name,
      status: item.fundingRequest.status,
      createdAt: item.fundingRequest.createdAt,
      revisedDueDate:
        item.fundingRequest.status === FUNDING_REQUEST_SUBMITTED_FOR_APPROVAL
          ? item.fundingRequest.createdAt
          : item.fundingRequest.revisedDueDate || item.fundingRequest.originalDueDate,
      assignedTo: item.fundingRequest.assignedTo,
      nextSteps: item.fundingRequest.nextSteps,
      owner: item.fundingRequest.owner,
      completeFunction,
    };
  }
  return null;
};

/**
 *  Get ActiveItem By Initiative.
 *
 * @param {object}initiative - Initiative.
 * @returns {{revisedDueDate: *, owner: *, detailsUrl: string, type: string, title: (string), assignedTo: null, nextSteps: null, itemId: null, createdAt: *, name: *, id: *, completeFunction: null, status: *}} Object.
 */
const getActiveItemByInitiative = (initiative) => {
  const title =
    initiative.status === INITIATIVE_SUBMITTED_FOR_APPROVAL
      ? 'Review the Initiative for approval'
      : 'Initiative';

  return {
    type: INITIATIVE_TYPE,
    title,
    detailsUrl: `/management/initiative/${initiative.id}`,
    id: initiative.id,
    itemId: null,
    name: initiative.name,
    status: initiative.status,
    createdAt: initiative.createdAt,
    revisedDueDate: initiative.createdAt,
    assignedTo: null,
    nextSteps: null,
    owner: initiative.owner,
    completeFunction: null,
  };
};

export const getTextUser = (type) => {
  let text;

  if (type === ALLIANCE_TYPE) {
    text = 'created this alliance';
  } else if (type === INITIATIVE_TYPE) {
    text = 'is the owner of this initiative';
  } else {
    text = 'is assigned to this item';
  }

  return text;
};

export const getUsername = (item) => {
  let username;

  if (item.type === ALLIANCE_TYPE) {
    username = item.createdBy;
  } else if (item.type === INITIATIVE_TYPE) {
    username = item.owner;
  } else {
    username = item.assignedTo;
  }

  return username;
};
