import styled from 'styled-components';

const ReportGroupSection = styled.div`
  box-sizing: border-box;
  border: 1px solid #e9eff4;
  border-radius: 5px;
  background-color: #ffffff;
  margin: 24px 24px 33px 24px;
  overflow: auto;
`;

export { ReportGroupSection };
