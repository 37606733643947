import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';

const StyledH3 = styled('h3')`
  font-family: Poppins;
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 0.2px;
  line-height: 24px;
  color: #323c47;
  & first-letter {
    text-transform: capitalize;
  }
`;

const H3 = ({ children }) => {
  return <StyledH3>{children}</StyledH3>;
};

H3.propTypes = {
  children: PropTypes.any.isRequired,
};

export { H3 };
