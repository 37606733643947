import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Row as BoostRow } from '@8base/boost';

import { Tab } from './Tab';

const Row = styled(BoostRow)`
  flex-shrink: 0;
`;

const Tabs = React.memo(({ tabsList }) => {
  return (
    <Row gap="xs">
      {tabsList.map((tabInfo, index) => (
        <Tab key={index} {...tabInfo} />
      ))}
    </Row>
  );
});

Tabs.displayName = 'Tabs';

const tabPropTypes = PropTypes.shape({
  to: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  iconName: PropTypes.string.isRequired,
});

const tabsListPropsTypes = PropTypes.arrayOf(tabPropTypes);

Tabs.propTypes = {
  tabsList: tabsListPropsTypes.isRequired,
};

export { Tabs };
