import React from 'react';
import PropTypes from 'prop-types';
import { Form, Row, Column } from '@8base/boost';
import { DialogForm } from '../../../../components/forms/DialogForm';
import { HorizontalLine } from '../../../../components/new-ui/text/HorizontalLine';
import { HorizontalLineText } from '../../../../components/new-ui/text/HorizontalLineText';
import { GroupInputs } from '../../../../components/new-ui/inputs/GroupInputs';
import { TagsInput } from '../../../../components/inputs/TagsInput';

const InviteCompanyAdminsDialog = ({
  isOpen,
  onCloseDialog,
  onChange,
  onSubmit,
  loading,
  adminEmails,
}) => {
  return (
    <div>
      <DialogForm
        size={'xl'}
        isOpen={isOpen}
        title="Invite Company Administrators"
        onCloseDialog={onCloseDialog}
        onClickCreate={onSubmit}
        createText={'Invite'}
        cancelText={'Skip'}
        loading={loading}>
        <Form>
          <Form.SectionBody>
            <HorizontalLine>
              <HorizontalLineText text={'Admin Invitations'} />
            </HorizontalLine>
            <GroupInputs text={'Emails'} show>
              <Row growChildren gap="lg">
                <Column>
                  <TagsInput
                    tags={adminEmails}
                    onChange={onChange}
                    label={'Company Administrators emails'}
                    placeholder={'Type an email and press enter'}
                    className="react-tagsinput admin-email"
                  />
                </Column>
              </Row>
            </GroupInputs>
          </Form.SectionBody>
        </Form>
      </DialogForm>
    </div>
  );
};

InviteCompanyAdminsDialog.propTypes = {
  adminEmails: PropTypes.array.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCloseDialog: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

export { InviteCompanyAdminsDialog };
