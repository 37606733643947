import React from 'react';
import { Dialog, Row, Column, Text } from '@8base/boost';
import PropTypes from 'prop-types';
import { ActionButton } from '../buttons/ActionButton';
import { H3 } from '../new-ui/font-style/H3.js';
import CHECK_MARK_OUTLINED_SVG from '../../images/icons/check-mark-outlined.svg'; // TODO: change to correct icon
import styled from '@emotion/styled';

const Icon = styled.img`
  height: 67px;
  width: 67px;
`;

const SuccessDialogBody = ({ note, text, onClose, buttonText }) => {
  return (
    <>
      <Dialog.Body padding="xl" scrollable>
        <Row alignItems="center" justifyContent="center">
          <Column alignItems="center">
            <Icon src={CHECK_MARK_OUTLINED_SVG} />
            <H3>{text}</H3>
            <Text align="center">{note}</Text>
          </Column>
        </Row>
      </Dialog.Body>
      <Dialog.Footer>
        <ActionButton stretch text={buttonText} onClick={onClose} />
      </Dialog.Footer>
    </>
  );
};

SuccessDialogBody.defaultProps = {
  text: 'Your payment was succesful',
  note:
    'You have succesfully upgraded your subscription plan. You can now close this dialog below.',
  buttonText: 'Close',
};

SuccessDialogBody.propTypes = {
  text: PropTypes.string,
  note: PropTypes.string,
  onClose: PropTypes.bool.isRequired,
  buttonText: PropTypes.string,
};

export { SuccessDialogBody };
