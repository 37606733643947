import gql from 'graphql-tag';
import { UserFragment } from '../auth/queries';

/**
 * Query for create a Funding Request Comment.
 *
 * @type {*|*}
 */
export const COMMENTS_CREATE_MUTATION = gql`
  mutation($data: CommentCreateInput!) {
    commentCreate(data: $data) {
      id
    }
  }
`;
/**
 *
 * @type {*|*}
 */
export const CommentFragment = gql`
  fragment CommentFragment on Comment {
    id
    comment
    createdBy {
      ...UserFragment
    }
    createdAt
  }
  ${UserFragment}
`;
