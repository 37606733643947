import Flux from '@cobuildlab/flux-state';

/**
 * Event that triggers a SALESFORCE_CONNECTED_EVENT.
 *
 * @type {string}
 */
export const SALESFORCE_CONNECTED_EVENT = 'SALESFORCE_CONNECTED_EVENT';

/**
 * Event that triggers a SALESFORCE_CONNECTED_ERROR_EVENT.
 *
 * @type {string}
 */
export const SALESFORCE_CONNECTED_ERROR_EVENT = 'SALESFORCE_CONNECTED_ERROR_EVENT';

/**
 * Event that triggers a SALESFORCE_CONNECTION_CREATED_EVENT.
 *
 * @type {string}
 */
export const SALESFORCE_CONNECTION_CREATED_EVENT = 'SALESFORCE_CONNECTION_CREATED_EVENT';

/**
 * Event that triggers a SALESFORCE_CONNECTION_CREATED_ERROR_EVENT.
 *
 * @type {string}
 */
export const SALESFORCE_CONNECTION_CREATED_ERROR_EVENT =
  'SALESFORCE_CONNECTION_CREATED_ERROR_EVENT';

/**
 * Event that triggers a SALESFORCE_DISCONNECTED_EVENT.
 *
 * @type {string}
 */
export const SALESFORCE_DISCONNECTED_EVENT = 'SALESFORCE_DISCONNECTED_EVENT';

/**
 * Event that triggers a SALESFORCE_DISCONNECTED_ERROR_EVENT.
 *
 * @type {string}
 */
export const SALESFORCE_DISCONNECTED_ERROR_EVENT = 'SALESFORCE_DISCONNECTED_ERROR_EVENT';

/**
 * Event that triggers a SALESFORCE_DISCONNECTED_EVENT.
 *
 * @type {string}
 */
export const SALESFORCE_FIELD_MAPPINGS_EVENT = 'SALESFORCE_FIELD_MAPPINGS_EVENT';

/**
 * Event that triggers a SALESFORCE_FIELD_MAPPINGS_ERROR_EVENT.
 *
 * @type {string}
 */
export const SALESFORCE_FIELD_MAPPINGS_ERROR_EVENT = 'SALESFORCE_FIELD_MAPPINGS_ERROR_EVENT';

/**
 * Event that triggers a SALESFORCE_DISCONNECTED_EVENT.
 *
 * @type {string}
 */
export const SALESFORCE_OBJECT_FIELDS_EVENT = 'SALESFORCE_OBJECT_FIELDS_EVENT';

/**
 * Event that triggers a SALESFORCE_OBJECT_FIELDS_ERROR_EVENT.
 *
 * @type {string}
 */
export const SALESFORCE_OBJECT_FIELDS_ERROR_EVENT = 'SALESFORCE_OBJECT_FIELDS_ERROR_EVENT';

/**
 * Event that triggers a SALESFORCE_FIELD_MAPPING_DELETE_EVENT.
 *
 * @type {string}
 */
export const SALESFORCE_FIELD_MAPPING_DELETE_EVENT = 'SALESFORCE_FIELD_MAPPING_DELETE_EVENT';

/**
 * Event that triggers a SALESFORCE_FIELD_MAPPING_DELETE_ERROR_EVENT.
 *
 * @type {string}
 */
export const SALESFORCE_FIELD_MAPPING_DELETE_ERROR_EVENT =
  'SALESFORCE_FIELD_MAPPING_DELETE_ERROR_EVENT';

/**
 * Event that triggers a SALESFORCE_FIELD_MAPPING_UPDATE_EVENT.
 *
 * @type {string}
 */
export const SALESFORCE_FIELD_MAPPING_UPDATE_EVENT = 'SALESFORCE_FIELD_MAPPING_UPDATE_EVENT';

/**
 * Event that triggers a SALESFORCE_FIELD_MAPPING_UPDATE_ERROR_EVENT.
 *
 * @type {string}
 */
export const SALESFORCE_FIELD_MAPPING_UPDATE_ERROR_EVENT =
  'SALESFORCE_FIELD_MAPPING_UPDATE_ERROR_EVENT';

/**
 * Event that triggers a SALESFORCE_FIELD_MAPPING_CREATE_EVENT.
 *
 * @type {string}
 */
export const SALESFORCE_FIELD_MAPPING_CREATE_EVENT = 'SALESFORCE_FIELD_MAPPING_CREATE_EVENT';

/**
 * Event that triggers a SALESFORCE_FIELD_MAPPING_CREATE_ERROR_EVENT.
 *
 * @type {string}
 */
export const SALESFORCE_FIELD_MAPPING_CREATE_ERROR_EVENT =
  'SALESFORCE_FIELD_MAPPING_CREATE_ERROR_EVENT';

/**
 * Event that triggers a SALESFORCE_FIELD_MAPPING_CREATE_EVENT.
 *
 * @type {string}
 */
export const SALESFORCE_CONNECTION_UPDATE_EVENT = 'SALESFORCE_CONNECTION_UPDATE_EVENT';

/**
 * Event that triggers a SALESFORCE_CONNECTION_UPDATE_ERROR_EVENT.
 *
 * @type {string}
 */
export const SALESFORCE_CONNECTION_UPDATE_ERROR_EVENT = 'SALESFORCE_CONNECTION_UPDATE_ERROR_EVENT';

/**
 * Event that triggers a SALESFORCE_LOGS_EVENT.
 *
 * @type {string}
 */
export const SALESFORCE_LOGS_EVENT = 'SALESFORCE_LOGS_EVENT';

/**
 * Event that triggers a SALESFORCE_LOGS_ERROR_EVENT.
 *
 * @type {string}
 */
export const SALESFORCE_LOGS_ERROR_EVENT = 'SALESFORCE_LOGS_ERROR_EVENT';

/**
 * Event that triggers a SALESFORCE_LOGS_ERROR_EVENT.
 *
 * @type {string}
 */
export const SALESFORCE_MISSING_FIELDS_EVENT = 'SALESFORCE_MISSING_FIELDS_EVENT';

/**
 * Event that triggers a SALESFORCE_LOGS_ERROR_EVENT.
 *
 * @type {string}
 */
export const SALESFORCE_CONNECTION_INVALID_EVENT = 'SALESFORCE_CONNECTION_INVALID_EVENT';

/**
 * Event that triggers a SALESFORCE_UPDATE_NOW_EVENT.
 *
 * @type {string}
 */
export const SALESFORCE_UPDATE_NOW_EVENT = 'SALESFORCE_UPDATE_NOW_EVENT';

/**
 * Event that triggers a SALESFORCE_UPDATE_NOW_ERROR_EVENT.
 *
 * @type {string}
 */
export const SALESFORCE_UPDATE_NOW_ERROR_EVENT = 'SALESFORCE_UPDATE_NOW_ERROR_EVENT';

/**
 * Event that triggers a SALESFORCE_CONNECTION_FIELDS_UPDATE_EVENT.
 *
 * @type {string}
 */
export const SALESFORCE_CONNECTION_FIELDS_UPDATE_EVENT =
  'SALESFORCE_CONNECTION_FIELDS_UPDATE_EVENT';

/**
 * Event that triggers a SALESFORCE_CONNECTION_FIELDS_UPDATE_ERROR_EVENT.
 *
 * @type {string}
 */
export const SALESFORCE_CONNECTION_FIELDS_UPDATE_ERROR_EVENT =
  'SALESFORCE_CONNECTION_FIELDS_UPDATE_ERROR_EVENT';

/**
 * Event that triggers a SALESFORCE_CONNECTION_LOADING_EVENT.
 *
 * @type {string}
 */
export const SALESFORCE_CONNECTION_LOADING_EVENT = 'SALESFORCE_CONNECTION_LOADING_EVENT';

/**
 * Event that triggers a SALESFORCE_CONECTION_EXPIRE.
 *
 * @type {string}
 */
export const SALESFORCE_CONNECTION_EXPIRED = 'SALESFORCE_CONNECTION_EXPIRED';

/**
 * Salesforce Storage.
 */
class SalesforceStore extends Flux.DashStore {
  constructor() {
    super();
    this.addEvent(SALESFORCE_CONNECTED_EVENT);
    this.addEvent(SALESFORCE_CONNECTED_ERROR_EVENT);
    this.addEvent(SALESFORCE_CONNECTION_CREATED_EVENT);
    this.addEvent(SALESFORCE_CONNECTION_CREATED_ERROR_EVENT);
    this.addEvent(SALESFORCE_DISCONNECTED_EVENT);
    this.addEvent(SALESFORCE_DISCONNECTED_ERROR_EVENT);
    this.addEvent(SALESFORCE_FIELD_MAPPINGS_EVENT);
    this.addEvent(SALESFORCE_FIELD_MAPPINGS_ERROR_EVENT);
    this.addEvent(SALESFORCE_OBJECT_FIELDS_EVENT);
    this.addEvent(SALESFORCE_OBJECT_FIELDS_ERROR_EVENT);
    this.addEvent(SALESFORCE_FIELD_MAPPING_DELETE_EVENT);
    this.addEvent(SALESFORCE_FIELD_MAPPING_DELETE_ERROR_EVENT);
    this.addEvent(SALESFORCE_FIELD_MAPPING_UPDATE_EVENT);
    this.addEvent(SALESFORCE_FIELD_MAPPING_UPDATE_ERROR_EVENT);
    this.addEvent(SALESFORCE_FIELD_MAPPING_CREATE_EVENT);
    this.addEvent(SALESFORCE_FIELD_MAPPING_CREATE_ERROR_EVENT);
    this.addEvent(SALESFORCE_CONNECTION_UPDATE_EVENT);
    this.addEvent(SALESFORCE_CONNECTION_UPDATE_ERROR_EVENT);
    this.addEvent(SALESFORCE_LOGS_EVENT);
    this.addEvent(SALESFORCE_LOGS_ERROR_EVENT);
    this.addEvent(SALESFORCE_MISSING_FIELDS_EVENT);
    this.addEvent(SALESFORCE_CONNECTION_INVALID_EVENT);
    this.addEvent(SALESFORCE_UPDATE_NOW_EVENT);
    this.addEvent(SALESFORCE_UPDATE_NOW_ERROR_EVENT);
    this.addEvent(SALESFORCE_CONNECTION_FIELDS_UPDATE_EVENT);
    this.addEvent(SALESFORCE_CONNECTION_FIELDS_UPDATE_ERROR_EVENT);
    this.addEvent(SALESFORCE_CONNECTION_LOADING_EVENT);
    this.addEvent(SALESFORCE_CONNECTION_EXPIRED);
  }
}

export const salesforceStore = new SalesforceStore();
