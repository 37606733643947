import React from 'react';
// import { Label } from '@8base/boost';
import PropTypes from 'prop-types';
import './clickable-label.css';

const ClickableLabel = ({ text, onClick }) => {
  // return <Label kind="secondary" style={{ cursor: 'pointer' }} text={text} onClick={onClick} />;
  return (
    <div className="clickable-label" style={{ height: '100%' }} onClick={onClick}>
      {text}
    </div>
  );
};

ClickableLabel.propTypes = {
  text: PropTypes.any.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default ClickableLabel;
