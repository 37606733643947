import gql from 'graphql-tag';

export const ForecastingYearFragment = gql`
  fragment ForecastingYearFragment on AllianceForecast {
    id
    year
    clientContributionsForecast
    partnerContributionsForecast
    clientBookingsForecast
    partnerBookingsForecast
  }
`;

export const FORECASTING_YEAR_UPDATE_MUTATION = gql`
  mutation($data: AllianceForecastUpdateInput!) {
    allianceForecastUpdate(data: $data) {
      id
    }
  }
`;

export const FORECASTING_YEAR_DELETE_MUTATION = gql`
  mutation($data: AllianceForecastDeleteInput!) {
    allianceForecastDelete(data: $data) {
      success
    }
  }
`;
