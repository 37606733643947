import React from 'react';
import { View } from '@cobuildlab/react-simple-state';
import { Button, Card, Heading, Loader } from '@8base/boost';
import { onErrorMixin } from '../../../shared/mixins';
import * as R from 'ramda';
import sessionStore, { NEW_SESSION_EVENT } from '../../../shared/SessionStore';
import DealDetailTable from './components/DealDetailTable';
import {
  openComments,
  completedDeal,
  fetchDealData,
  fetchRelatedDeals,
  fetchAssociatedDeals,
} from './deal-actions';
import * as toast from '../../../components/toast/Toast';
import PropTypes from 'prop-types';
import withAlliance from '../../../components/hoc/withAlliance';
import { withRouter } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { canCompletedDeal } from './deal-permissions';
import YesNoDialog from '../../../components/dialogs/YesNoDialog';
import {
  fetchRelatedItems,
  fetchRelatedItemsByItemId,
} from '../../related-item/related-item-actions';
import { DealData } from './deal-model';
import { getCurrencyOnSession } from '../../../shared/alliance-utils';
import { DetailViewCardBody } from '../../../components/card/DetailViewCardBody';

import { DEAL_COMPLETED } from '../../../shared/status';
import { RelatedItemsByItemDetailTable } from '../../related-item/components/RelatedItemsByItemDetailTable';
import { getItemByType } from '../../../shared/items-util';
import { TopButtons } from '../../../components/buttons/TopButtons';
import { RelatedItemsDetailTable } from '../../related-item/components/RelatedItemsDetailTable';
import { AssociatedItemsTable } from '../../related-item/components/AssociatedItemsTable';
import { InitiativeListTable } from '../initiative/components/InitiativeListTable';
import {
  OnDealDetail,
  OnDealError,
  OnDealRelatedList,
  OnDealComplete,
  OnAssociatedDeals,
} from '../deal/deal-events';
import { OnRelatedItemsByItem, OnRelatedItems } from '../../related-item/related-item-events';

/**
 * Deal Detail View.
 */

class DealDetailView extends View {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        dealData: R.clone(DealData),
        relatedDeals: [],
        relatedItems: [],
        associatedItems: [],
        relatedItemsByItem: [],
        initiatives: [],
      },
      loading: true,
      completedModalIsOpen: false,
    };
    this.user = sessionStore.getState(NEW_SESSION_EVENT).user;
    this.onError = onErrorMixin.bind(this);
    this.selectedAlliance = sessionStore.getState(NEW_SESSION_EVENT).selectedAlliance;
  }

  componentDidMount = () => {
    this.subscribe(OnDealError, this.onError);
    this.subscribe(OnDealDetail, ({ dealDatum }) => {
      const dealData = { itemId: dealDatum.itemDealDataRelation.id, ...dealDatum };
      const { data } = this.state;
      data.initiatives = dealDatum.initiatives.items.map((item) => ({ ...item }));

      data.dealData = dealData;
      this.setState(
        {
          data,
          loading: false,
        },
        () => setTimeout(() => fetchRelatedDeals(dealData), 0),
        fetchRelatedItems(dealData.itemId),
        fetchRelatedItemsByItemId(dealData.itemId),
        fetchAssociatedDeals(false, dealData.dealSourceId, match.params.id),
      );
    });

    this.subscribe(OnDealRelatedList, (deals) => {
      const { data } = this.state;
      data.relatedDeals = R.clone(deals);
      this.setState({ data });
    });

    this.subscribe(OnDealComplete, () => {
      const { data } = this.state;
      data.dealData.status = DEAL_COMPLETED;
      this.setState({
        data,
        loading: false,
      });
      toast.success('Deal Successfully Closed');
    });

    this.subscribe(OnRelatedItems, (state) => {
      const items = state.item.itemsRelated.items.map((item) => getItemByType(item));
      const { data } = this.state;

      data.relatedItems = items;
      this.setState({ data });
    });

    this.subscribe(OnRelatedItemsByItem, (state) => {
      const {
        itemsList: { items: itemsRelated },
      } = state;
      const relatedItemsByItem = itemsRelated.map((item) => getItemByType(item));
      const { data } = this.state;

      data.relatedItemsByItem = relatedItemsByItem;
      this.setState({ data, loading: false });
    });

    this.subscribe(OnAssociatedDeals, (items) => {
      const { data } = this.state;
      data.associatedItems = R.clone(items.dealDataList.items);
      this.setState({
        data,
      });
    });

    const { match } = this.props;
    if (!match.params.id) return toast.error('Deal ID missing');
    const fetchPolicyOptions = { isCacheFirst: true };
    fetchDealData(match.params.id, fetchPolicyOptions);
  };

  completedModal = () => {
    this.setState({
      completedModalIsOpen: true,
    });
  };

  onYesModal = () => {
    this.setState(
      {
        completedModalIsOpen: false,
        loading: true,
      },
      () => {
        const dealData = R.clone(this.state.data.dealData);
        completedDeal(this.user, dealData, { id: this.props.allianceId });
      },
    );
  };

  onCompletedModal = () => {
    this.setState({
      completedModalIsOpen: false,
    });
  };

  render() {
    const { loading, completedModalIsOpen, data } = this.state;
    const { dealData, relatedItems, relatedItemsByItem, associatedItems, initiatives } = data;
    const { history } = this.props;
    let content = <Loader stretch />;
    let buttonsBottom = '';
    let buttonsTop = '';
    const currency = getCurrencyOnSession();
    const alliance = this.selectedAlliance;

    if (!loading) {
      content = (
        <>
          <DealDetailTable
            currency={currency}
            dealData={dealData}
            onClickEdit={() => history.push(`/management/deal/edit/${dealData.id}`)}
          />
          <AssociatedItemsTable associatedItems={associatedItems} />
          <InitiativeListTable initiatives={initiatives} />
          <RelatedItemsDetailTable relatedItems={relatedItems} />
          <RelatedItemsByItemDetailTable relatedItemsByItem={relatedItemsByItem} />
        </>
      );

      buttonsTop = (
        <>
          <Heading type="h4" text={dealData.name} />

          <TopButtons
            onClickClosed={history.goBack}
            onClickCollaborated={() => openComments(dealData)}
          />
        </>
      );

      buttonsBottom = (
        <div className="positionBottonRight">
          {canCompletedDeal(this.user, dealData, alliance) ? (
            <Button
              onClick={() => {
                this.completedModal();
              }}>
              <FontAwesomeIcon icon="clipboard-list" />
              &nbsp; Mark Completed
            </Button>
          ) : null}
        </div>
      );
    }

    return (
      <React.Fragment>
        <Card.Header>{buttonsTop}</Card.Header>
        <DetailViewCardBody>{content}</DetailViewCardBody>
        <Card.Footer>{buttonsBottom}</Card.Footer>
        <YesNoDialog
          title={'Complete Deal'}
          onYes={this.onYesModal}
          onClose={this.onClose}
          onNo={this.onCompletedModal}
          text={'Are you sure you want to Mark the Deal as Completed?'}
          isOpen={completedModalIsOpen}
        />
      </React.Fragment>
    );
  }
}

DealDetailView.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default withRouter(withAlliance(DealDetailView));
