import sessionStore, { APOLLO_CLIENT, NEW_SESSION_EVENT } from '../../../shared/SessionStore';
import {
  sanitize8BaseBigInt,
  sanitize8BaseReconnectsFromObjects,
  sanitize8BaseReferenceFromObject,
  sanitize8BaseReferencesFromObjects,
} from '../../../shared/utils';
import Flux from '@cobuildlab/flux-state';
import { IntegrityError } from '../../../shared/errors';
import { ISSUE_COMPLETED, ISSUE_OPEN } from '../../../shared/status';
import { validateIssueData } from './issue-validators';
import { error, log } from '@cobuildlab/pure-logger';
import {
  ISSUE_AUTO_SAVE_ERROR_EVENT,
  ISSUE_AUTO_SAVE_EVENT,
  ISSUE_COMPLETED_EVENT,
  ISSUE_CREATE_EVENT,
  ISSUE_DETAIL_EVENT,
  ISSUE_ERROR_EVENT,
  ISSUE_RESTORE_EVENT,
  ISSUE_UPDATE_EVENT,
} from './issue-store';
import {
  ISSUE_COMMENTS_QUERY,
  ISSUE_CREATE_MUTATION,
  ISSUE_DETAIL_QUERY,
  ISSUE_UPDATE_MUTATION,
  ISSUE_UPDATE_QUERY,
} from './issue-queries';
import {
  COMMENT_CREATE_EVENT,
  COMMENT_ERROR_EVENT,
  COMMENT_REQUEST_EVENT,
  COMMENTS_EVENT,
} from '../../comment/comment-store';
import { ISSUE_TYPE } from '../../../shared/item-types';
import { COMMENTS_CREATE_MUTATION } from '../../comment/comment-queries';
import { canCompletedIssue, canRestoreIssue } from './issue-permissions';
import {
  completeItemsNextSteps,
  deleteNextSteps,
  nextStepsToBeCreated,
  sanitizeNextStepsCreate,
  sanitizeNextStepsToEdit,
  updateNextSteps,
} from '../../next-step/next-step-actions';
import * as R from 'ramda';
import { RELATED_ITEM_UPDATE_MUTATION } from '../../related-item/related-item-queries';
import {
  normalize8baseDocumentsCreate,
  normalize8baseDocumentsDeleteAndUpdate,
} from '@cobuildlab/8base-utils';
import {
  OnIssueCreate,
  OnIssueError,
  OnIssueAutoSave,
  OnIssueAutoSaveError,
  OnIssueDetail,
  OnIssueCompleted,
  OnIssueRestore,
  OnIssueUpdate,
} from './issue-events';
import {
  OnCommentError,
  OnCommentRequest,
  OnCommentCreate,
  OnComment,
} from '../../comment/comment-events';

/**
 * Notifies a Request for Comments for a Issue.
 */
export const openComments = ({ id }) => {
  OnCommentRequest.dispatch({ type: ISSUE_TYPE, id: id });
  Flux.dispatchEvent(COMMENT_REQUEST_EVENT, { type: ISSUE_TYPE, id: id });
};

/**
 * Create Issue.
 *
 * @param {object} issue - Issue.
 * @param {Array} relatedItems - RelatedItems.
 * @param {object} initiatives - Initiatives.
 * @param {object} savedIssue - SavedIssue.
 * @returns {Promise<void>} Return promise.
 */
export const createIssue = async (issue, relatedItems, initiatives, savedIssue) => {
  const { selectedAlliance } = sessionStore.getState(NEW_SESSION_EVENT);
  const allianceId = selectedAlliance.id;
  const client = sessionStore.getState(APOLLO_CLIENT);

  if (!allianceId) {
    OnIssueError.dispatch(new IntegrityError('Must have an Active Alliance'));

    return Flux.dispatchEvent(
      ISSUE_ERROR_EVENT,
      new IntegrityError('Must have an Active Alliance'),
    );
  }

  try {
    validateIssueData(issue, initiatives, selectedAlliance);
  } catch (err) {
    console.error('createIssue', err);
    OnIssueError.dispatch(err);
    return Flux.dispatchEvent(ISSUE_ERROR_EVENT, err);
  }

  console.log('createIssue', issue);
  try {
    const createdIssue = await createOrUpdateIssue(issue, relatedItems, initiatives, savedIssue);
    console.log('createdIssue', createdIssue);

    await client.resetStore();
    OnIssueCreate.dispatch(createdIssue);
    Flux.dispatchEvent(ISSUE_CREATE_EVENT, createdIssue);
    return createdIssue;
  } catch (e) {
    console.error('createdIssue:error', e);
    OnIssueError.dispatch(e);
    Flux.dispatchEvent(ISSUE_ERROR_EVENT, e);
  }
};

/**
 * Create Issue.
 *
 * @param {object} issue - Issue.
 * @param {Array} relatedItems - RelatedItems.
 * @param {object}initiatives - Initiatives.
 * @returns {Promise<void>} Return promise.
 */
export const _createIssue = async (issue, relatedItems, initiatives) => {
  const client = sessionStore.getState(APOLLO_CLIENT);
  const { selectedAlliance } = sessionStore.getState(NEW_SESSION_EVENT);

  const allianceId = selectedAlliance.id;

  delete issue.id;

  if (!allianceId) throw new IntegrityError('Must have an Active Alliance');

  issue.initiatives = initiatives;
  // Transforming into 8base 'connect' relationships
  sanitize8BaseReferencesFromObjects(issue, 'initiatives');
  sanitize8BaseReferenceFromObject(issue, 'assignedTo');
  sanitize8BaseReferenceFromObject(issue, 'source');
  normalize8baseDocumentsCreate(issue, 'documents');
  sanitize8BaseBigInt(issue, 'unitMonetizationFactor');
  sanitizeNextStepsCreate(issue);

  issue.itemIssueRelation = {
    create: {
      alliance: { connect: { id: allianceId } },
      itemsRelated: {
        connect: relatedItems.map((item) => ({ id: item.itemId })),
      },
    },
  };

  const {
    data: { issueCreate },
  } = await client.mutate({
    mutation: ISSUE_CREATE_MUTATION,
    variables: { data: issue },
  });

  console.log('createIssue', issueCreate);
  return issueCreate;
};

/**
 * Auto-Save Creates a New Issue.
 *
 * @param {object} issue - Issue.
 * @param {Array} relatedItems - RelatedItems.
 * @param {object} initiatives - Initiatives.
 * @param {object} savedIssue - Saved Issue.
 * @returns {Promise<void>} Return promise.
 */
export const autoSaveCreateIssue = async (issue, relatedItems, initiatives, savedIssue) => {
  console.log('Auto-Save: ', {
    issue,
    relatedItems,
    initiatives,
    savedIssue,
  });

  const client = sessionStore.getState(APOLLO_CLIENT);

  try {
    const _issue = await createOrUpdateIssue(issue, relatedItems, initiatives, savedIssue);

    await client.resetStore();
    OnIssueAutoSave.dispatch(_issue);
    Flux.dispatchEvent(ISSUE_AUTO_SAVE_EVENT, _issue);
    console.log('autoSaveCreateIssue:response', _issue);
    return issue;
  } catch (e) {
    OnIssueAutoSaveError.dispatch(e);
    Flux.dispatchEvent(ISSUE_AUTO_SAVE_ERROR_EVENT, e);
    console.error('autoSaveCreateIssue', e);
  }
};

/**
 * Auto Save - Update issue Data.
 *
 * @param {object} issueData - Issue.
 * @param {Array} originalNextSteps - OriginalNextSteps.
 * @param {Array} relatedItems - RelatedItems.
 * @param {object} initiatives - Initiatives.
 * @param {object} originalIssueData - OriginalDocuments.
 * @returns {Promise<void>} Return promise.
 */
export const autoSaveUpdateIssue = async (
  issueData,
  originalNextSteps,
  relatedItems,
  initiatives,
  originalIssueData,
) => {
  const client = sessionStore.getState(APOLLO_CLIENT);

  try {
    const issue = await _updateIssue(
      issueData,
      originalNextSteps,
      relatedItems,
      initiatives,
      originalIssueData,
    );

    await client.resetStore();
    OnIssueAutoSave.dispatch(issue);
    Flux.dispatchEvent(ISSUE_AUTO_SAVE_EVENT, issue);
    console.log('autoSaveUpdateIssue:response', issue);
    return issue;
  } catch (e) {
    console.error('autoSaveUpdateIssue:error', e);
  }
};

export const createOrUpdateIssue = (issue, relatedItems, initiatives, savedIssue) => {
  if (!savedIssue) {
    return _createIssue(issue, relatedItems, initiatives);
  } else {
    const issueData = R.clone(issue);
    const originalIssueData = R.clone(savedIssue);

    const originalNextSteps = sanitizeNextStepsToEdit(savedIssue);

    issueData.id = originalIssueData.id;
    issueData.itemIssueRelation = originalIssueData.itemIssueRelation;

    originalIssueData.documents = originalIssueData.documents.items;

    return _updateIssue(issueData, originalNextSteps, relatedItems, initiatives, originalIssueData);
  }
};

/**
 * Create a comment on a Issue.
 *
 * @param {string}issueId - Issue Id.
 * @param {Array}comment - Comment.
 * @returns {Promise<*>} Return promise.
 */
export const createIssueComment = async (issueId, comment) => {
  const client = sessionStore.getState(APOLLO_CLIENT);
  const data = {
    comment,
    issueCommentsRelation: { connect: { id: issueId } },
  };

  let response;
  try {
    response = await client.mutate({
      mutation: COMMENTS_CREATE_MUTATION,
      variables: { data },
    });
  } catch (e) {
    error('createIssueComment', e);
    OnCommentError.dispatch(e);
    return Flux.dispatchEvent(COMMENT_ERROR_EVENT, e);
  }
  log('createIssueComment', response);
  OnCommentCreate.dispatch(response.data);
  Flux.dispatchEvent(COMMENT_CREATE_EVENT, response.data);
  return response.data;
};

/**
 * Fetches the Issue Item Comments.
 *
 * @param {string}issueId - Issue.
 * @returns {Promise<void>} Return promise.
 */
export const fetchIssueComments = async (issueId) => {
  console.log('issueID', issueId);
  const client = sessionStore.getState(APOLLO_CLIENT);
  const data = { id: issueId };
  const { user } = sessionStore.getState(NEW_SESSION_EVENT);

  let response;
  try {
    response = await client.query({
      query: ISSUE_COMMENTS_QUERY,
      variables: data,
      fetchPolicy: 'network-only',
    });
  } catch (e) {
    error('fetchIssueComments', e);
    OnCommentError.dispatch(e);
    return Flux.dispatchEvent(COMMENT_ERROR_EVENT, e);
  }
  log('fetchIssueComments', response);
  response.data.issue.comments.userId = user.id;

  const comments = R.clone(response.data.issue.comments);

  comments.items = response.data.issue.comments.items.map((comment) => ({
    ...comment,
  }));

  comments.userId = user.id;

  OnComment.dispatch(comments);
  Flux.dispatchEvent(COMMENTS_EVENT, comments);
  return comments;
};

/**
 * Fetches the Issue Item.
 *
 * @param {string} issueId - Issue Id.
 * @param {object} options - Object options.
 * @returns {Promise<void>} Return promise.
 */
export const fetchIssue = async (issueId, options = {}) => {
  const client = sessionStore.getState(APOLLO_CLIENT);
  const data = { id: issueId };
  const fetchPolicy = options.isCacheFirst ? 'cache-first' : 'network-only';

  let response;

  try {
    response = await client.query({
      query: ISSUE_DETAIL_QUERY,
      variables: data,
      fetchPolicy,
    });
  } catch (e) {
    error('fetchIssue', e);
    throw e;
  }

  log('fetchIssue', response);
  return response.data;
};

/**
 * Fetches the Issue Item and dispatchEvent.
 *
 * @param {string} issueId - Issue Id.
 * @param {object} options - Object options.
 * @returns {Promise<void>} Return promise.
 */
export const fetchIssueDetail = async (issueId, options = {}) => {
  let response;
  try {
    response = await fetchIssue(issueId, options);
  } catch (e) {
    OnIssueError.dispatch(e);
    return Flux.dispatchEvent(ISSUE_ERROR_EVENT, e);
  }

  OnIssueDetail.dispatch(response);
  Flux.dispatchEvent(ISSUE_DETAIL_EVENT, response);
  return response;
};

/**
 * Update a Issue.
 *
 * @param {object}issueData - Issue.
 * @param {Array}originalNextSteps - OriginalNextSteps.
 * @param {Array}relatedItems - RelatedItems.
 * @param {object}initiatives - Initiatives.
 * @param {object}originalIssueData - OriginalDocuments.
 * @returns {Promise<void>} Return promise.
 */
export const updateIssue = async (
  issueData,
  originalNextSteps,
  relatedItems,
  initiatives,
  originalIssueData,
) => {
  const { selectedAlliance } = sessionStore.getState(NEW_SESSION_EVENT);
  const client = sessionStore.getState(APOLLO_CLIENT);

  try {
    validateIssueData(issueData, initiatives, selectedAlliance);
  } catch (e) {
    console.error('Update Issue Validator', e);
    OnIssueError.dispatch(e);
    return Flux.dispatchEvent(ISSUE_ERROR_EVENT, e);
  }

  try {
    const updatedIssue = await _updateIssue(
      issueData,
      originalNextSteps,
      relatedItems,
      initiatives,
      originalIssueData,
    );

    await client.resetStore();
    OnIssueUpdate.dispatch(updatedIssue);
    Flux.dispatchEvent(ISSUE_UPDATE_EVENT, updatedIssue);
    return updatedIssue;
  } catch (e) {
    console.error('updateIssue', e, issueData);
    OnIssueError.dispatch(e);
    Flux.dispatchEvent(ISSUE_ERROR_EVENT, e);
  }
};

/**
 * Update a Issue.
 *
 * @param {object}issueData - Issue.
 * @param {Array}originalNextSteps - OriginalNextSteps.
 * @param {Array}relatedItems - RelatedItems.
 * @param {object}initiatives - Initiatives.
 * @param {object}originalIssueData - OriginalDocuments.
 * @returns {Promise<void>} Return promise.
 */
export const _updateIssue = async (
  issueData,
  originalNextSteps,
  relatedItems,
  initiatives,
  originalIssueData,
) => {
  const client = sessionStore.getState(APOLLO_CLIENT);

  const itemId = issueData.itemIssueRelation.id;

  delete issueData.createdAt;
  delete issueData.itemId;
  delete issueData.itemIssueRelation;
  delete issueData.__typename;
  delete issueData.createdBy;

  log('issueData', issueData, relatedItems, initiatives, originalNextSteps);

  issueData.initiatives = initiatives;
  sanitize8BaseReconnectsFromObjects(issueData, 'initiatives');
  sanitize8BaseReferenceFromObject(issueData, 'requestedBy');
  sanitize8BaseReferenceFromObject(issueData, 'assignedTo');
  sanitize8BaseReferenceFromObject(issueData, 'source');
  sanitize8BaseBigInt(issueData, 'unitMonetizationFactor');
  normalize8baseDocumentsDeleteAndUpdate(issueData, 'documents', originalIssueData);

  // nextSteps to be created
  const nextSteps = R.clone(issueData.nextSteps);
  issueData.nextSteps = nextStepsToBeCreated(nextSteps);
  sanitizeNextStepsCreate(issueData);

  // update and delete nextSteps
  await deleteNextSteps(nextSteps, originalNextSteps);
  await updateNextSteps(nextSteps, originalNextSteps);

  const {
    data: { issueUpdate },
  } = await client.mutate({
    mutation: ISSUE_UPDATE_QUERY,
    variables: { data: issueData },
  });

  // Reconnect related items
  const relatedItemsData = {
    id: itemId,
    itemsRelated: {
      reconnect: relatedItems.map((i) => ({ id: i.itemId })),
    },
  };

  log('issueData:update:relatedItems:', relatedItemsData);

  await client.mutate({
    mutation: RELATED_ITEM_UPDATE_MUTATION,
    variables: { data: relatedItemsData },
  });

  return issueUpdate;
};

/**
 * Change status item the completed to open.
 *
 * @param {object}issueData - Issue.
 * @returns {Promise<void|*>} Return promise.
 */
export const completedIssue = async (issueData) => {
  const client = sessionStore.getState(APOLLO_CLIENT);
  const { user, selectedAlliance } = sessionStore.getState(NEW_SESSION_EVENT);
  const { issue } = await fetchIssue(issueData.id);

  if (!canCompletedIssue(user, issue, selectedAlliance)) {
    OnIssueError.dispatch(new IntegrityError('You do not have permission to perform this action'));

    return Flux.dispatchEvent(
      ISSUE_ERROR_EVENT,
      new IntegrityError('You do not have permission to perform this action'),
    );
  }

  let response;

  try {
    response = await client.mutate({
      mutation: ISSUE_UPDATE_MUTATION,
      variables: {
        data: {
          id: issue.id,
          status: ISSUE_COMPLETED,
        },
      },
    });
  } catch (e) {
    console.error('CompletedIssue', e, issue);
    OnIssueError.dispatch(e);
    return Flux.dispatchEvent(ISSUE_ERROR_EVENT, e);
  }

  try {
    await completeItemsNextSteps(issue);
  } catch (e) {
    log('completeItemsNextStepsError', e);
  }

  await client.resetStore();
  OnIssueCompleted.dispatch(response.data);
  Flux.dispatchEvent(ISSUE_COMPLETED_EVENT, response.data);
  return response.data;
};

/**
 * User Restore Issue.
 *
 * @param {object} issueData - Issue.
 * @returns {Promise<void|*>} - Promise.
 */
export const restoreIssue = async (issueData) => {
  const client = sessionStore.getState(APOLLO_CLIENT);
  const { user, selectedAlliance } = sessionStore.getState(NEW_SESSION_EVENT);
  const { issue } = await fetchIssue(issueData.id);

  if (!canRestoreIssue(user, issue, selectedAlliance)) {
    OnIssueError.dispatch(new IntegrityError('You do not have permission to perform this action'));

    return Flux.dispatchEvent(
      ISSUE_ERROR_EVENT,
      new IntegrityError('You do not have permission to perform this action'),
    );
  }

  let response;

  try {
    response = await client.mutate({
      mutation: ISSUE_UPDATE_MUTATION,
      variables: {
        data: {
          id: issue.id,
          status: ISSUE_OPEN,
        },
      },
    });
  } catch (e) {
    console.error('restoredIssue', e, issue);
    OnIssueError.dispatch(e);
    return Flux.dispatchEvent(ISSUE_ERROR_EVENT, e);
  }

  await client.resetStore();
  OnIssueRestore.dispatch(response.data);
  Flux.dispatchEvent(ISSUE_RESTORE_EVENT, response.data);
  return response.data;
};
