import React from 'react';
import { Form, Field } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';
import { Card, Heading } from '@8base/boost';
import { View } from '@cobuildlab/react-simple-state';
import * as R from 'ramda';
import { TransparentButtonWithIcon } from '../../../components/buttons/TransparentButtonWithIcon';
import { SwitchField } from '../../../components/switch/SwitchField';
import { ViewCardBody } from '../../../components/card/ViewCardBody';
import { ActionButton } from '../../../components/buttons/ActionButton';
import { fetchCurrentAllianceMembersAction } from '../../settings/alliance-management/alliance-actions';
import {
  FieldSection,
  FieldSectionHeader,
  FieldSectionText,
  FilterFieldsRow,
  SortFieldsRow,
  GroupFieldsRow,
  ReportsCard,
} from '../components';
import { getOptionsFromArray, getFullNameWithEmail, validateReportsConfig } from '../utils';
import { saveActiveSalesPipelineFilters } from './active-sales-pipeline-actions';
import { FIELDS_LIST, INITIAL_STATE } from './active-sales-pipeline-model';
import { fetchAllDealsData } from '../../management/deal/deal-actions';
import { OnAllianceMemberList } from '../../settings/alliance-management/alliance-events';
import { OnActiveSalesPipelineFilters } from './active-sales-pipeline-events';
import { OnDealAllData } from '../../management/deal/deal-events';

/**
 * These fieldsOptions and fieldsDict are used for 'sorts' and 'groups' fields.
 */
const fieldsOptions = FIELDS_LIST.map((field) => ({
  label: field.displayName,
  value: field.name,
}));
const fieldsDict = FIELDS_LIST.reduce((dict, field) => {
  return {
    ...dict,
    [field.name]: field,
  };
}, {});
/**
 * These fieldsOptionsByFilters and fieldsDictByFilters are used only for the 'filters' field.
 */
const fieldsOptionsByFilters = FIELDS_LIST.map((field) => ({
  label: field.displayName,
  value: field.id,
}));
const fieldsDictByFilters = FIELDS_LIST.reduce((dict, field) => {
  return {
    ...dict,
    [field.id]: field,
  };
}, {});

class ActiveSalesPipelineConfigView extends View {
  constructor(props) {
    super(props);

    this.state = {
      data: OnActiveSalesPipelineFilters.get() || INITIAL_STATE,
      clientCompany: null,
      partnerCompany: null,
      dealsList: [],
    };
  }

  componentDidMount() {
    this.subscribe(OnActiveSalesPipelineFilters, (data) => {
      this.setState({ data: R.clone(data) });
    });

    this.subscribe(OnAllianceMemberList, (data) => {
      this.setState({
        clientCompany: R.clone(data.clientCompany),
        partnerCompany: R.clone(data.partnerCompany),
      });
    });

    this.subscribe(OnDealAllData, (state) => {
      const items = state.dealDataList.items.map((deal) => ({
        ...deal,
      }));

      this.setState({
        dealsList: items,
      });
    });

    fetchCurrentAllianceMembersAction();
    fetchAllDealsData();
  }

  onSubmit = (data) => {
    const { history } = this.props;
    saveActiveSalesPipelineFilters(R.clone(data));
    console.log('ON_SUBMIT>>>', data);
    history.push('/reports/active-sales-pipeline/report');
  };

  render() {
    const { dealsList } = this.state;

    const optionArrays = dealsList.reduce(
      (result, deal) => {
        const { stageOptions, salesPersonOptions, customerOptions, ownerOptions } = result;
        const { stage, salesPerson, customerName, owner } = deal;

        const resultOptions = {
          stageOptions: [...stageOptions, stage],
          salesPersonOptions: [...salesPersonOptions, salesPerson],
          customerOptions: [...customerOptions, customerName],
        };

        if (!owner || ownerOptions.find(({ value }) => value === owner.id)) {
          resultOptions.ownerOptions = ownerOptions;
        } else {
          resultOptions.ownerOptions = [
            ...ownerOptions,
            {
              label: getFullNameWithEmail(owner),
              value: owner.id,
            },
          ];
        }

        return resultOptions;
      },
      {
        stageOptions: [],
        salesPersonOptions: [],
        customerOptions: [],
        ownerOptions: [],
      },
    );

    const stageOptions = getOptionsFromArray(Array.from(new Set(optionArrays.stageOptions)));
    const salesPersonOptions = getOptionsFromArray(
      Array.from(new Set(optionArrays.salesPersonOptions)),
    );
    const customerOptions = getOptionsFromArray(Array.from(new Set(optionArrays.customerOptions)));

    return (
      <Form
        validate={validateReportsConfig}
        onSubmit={this.onSubmit}
        subscription={{ submitting: true, error: true, pristine: true }}
        mutators={{
          ...arrayMutators,
        }}
        initialValues={this.state.data}
        render={({ form: { change }, handleSubmit, submitting, validating }) => {
          return (
            <div id={'c2gScreen'}>
              <form onSubmit={handleSubmit}>
                <ReportsCard stretch withFooter>
                  <Card.Header>
                    <Heading type="h4" text={'Active Sales Pipeline'} />
                  </Card.Header>
                  <ViewCardBody
                    style={{ textAlign: 'left', padding: '25px 24px' }}
                    className="card-body-report-with-footer">
                    {/*<FieldSection>
                      <FieldSectionHeader weight="semibold" text="Organization Filter" />
                      <Field
                        name="organization"
                        subscription={{ active: true, value: true, error: true }}
                        render={({ input: { value, onChange }, ...rest }) => (
                          <>
                            {value ? (
                              <FilterRow withDeleteButton>
                                <SelectField
                                  input={{
                                    value: value.id,
                                    onChange: (value) =>
                                      onChange({
                                        id: value,
                                      }),
                                  }}
                                  label="Filter by"
                                  placeholder="Select Company"
                                  options={organiationFilterOptions}
                                  {...rest}
                                />
                                <DeleteButton
                                  text={''}
                                  iconName={'Delete'}
                                  onClick={() => onChange(null)}
                                  iconSize={'md'}
                                />
                              </FilterRow>
                            ) : (
                              <>
                                <FieldSectionText text="No organization filters have been applied to this report, currently the report is capturing data from the entire alliance." />
                                <TransparentButtonWithIcon
                                  text={'Add Organization Filter'}
                                  iconName={'Add'}
                                  onClick={() => onChange({})}
                                />
                              </>
                            )}
                          </>
                        )}
                      />
                    </FieldSection>*/}
                    <FieldSection>
                      <FieldSectionHeader weight="semibold" text="Filter" />
                      <FieldArray name="filters">
                        {({ fields }) => {
                          return (
                            <>
                              {fields.length > 0 ? (
                                fields.map((name, fieldIndex) => {
                                  return (
                                    <Field
                                      key={fieldIndex}
                                      fieldIndex={fieldIndex}
                                      name={name}
                                      component={FilterFieldsRow}
                                      deleteFilter={() => fields.remove(fieldIndex)}
                                      fieldsDict={fieldsDictByFilters}
                                      selectedFilters={fields.value}
                                      fieldsOptions={fieldsOptionsByFilters}
                                      stageOptions={stageOptions}
                                      salesPersonOptions={salesPersonOptions}
                                      customerOptions={customerOptions}
                                      ownerOptions={optionArrays.ownerOptions}
                                      changeFormValue={change}
                                      withDeleteButton
                                    />
                                  );
                                })
                              ) : (
                                <FieldSectionText text="No filters have been applied to this report." />
                              )}
                              {fields.length < fieldsOptionsByFilters.length ? (
                                <TransparentButtonWithIcon
                                  text={'Add Filter'}
                                  iconName={'Add'}
                                  onClick={() => fields.push({})}
                                />
                              ) : null}
                            </>
                          );
                        }}
                      </FieldArray>
                    </FieldSection>
                    <FieldSection>
                      <FieldSectionHeader weight="semibold" text="Sort" />
                      <FieldArray name="sorts">
                        {({ fields }) => {
                          return (
                            <>
                              {fields.length > 0 ? (
                                fields.map((name, fieldIndex) => {
                                  return (
                                    <Field
                                      key={fieldIndex}
                                      name={name}
                                      subscription={{ active: true, value: true, error: true }}
                                      component={SortFieldsRow}
                                      deleteFilter={() => fields.remove(fieldIndex)}
                                      fieldsDict={fieldsDict}
                                      selectedSorts={fields.value}
                                      fieldIndex={fieldIndex}
                                      fieldsOptions={fieldsOptions}
                                      changeFormValue={change}
                                      withDeleteButton
                                    />
                                  );
                                })
                              ) : (
                                <FieldSectionText text="No sorts have been applied to this report." />
                              )}
                              {fields.length < fieldsOptions.length ? (
                                <TransparentButtonWithIcon
                                  text={'Add Sort'}
                                  iconName={'Add'}
                                  onClick={() => fields.push({ value: 'ASC' })}
                                />
                              ) : null}
                            </>
                          );
                        }}
                      </FieldArray>
                    </FieldSection>
                    <FieldSection>
                      <FieldSectionHeader weight="semibold" text="Group" />
                      <FieldArray name="groups">
                        {({ fields }) => {
                          return (
                            <>
                              {fields.length > 0 ? (
                                fields.map((name, fieldIndex) => {
                                  return (
                                    <Field
                                      key={fieldIndex}
                                      name={name}
                                      subscription={{ active: true, value: true, error: true }}
                                      component={GroupFieldsRow}
                                      deleteFilter={() => fields.remove(fieldIndex)}
                                      fieldsDict={fieldsDict}
                                      selectedGroups={fields.value}
                                      fieldIndex={fieldIndex}
                                      fieldsOptions={fieldsOptions}
                                      changeFormValue={change}
                                    />
                                  );
                                })
                              ) : (
                                <FieldSectionText text="No groups have been applied to this report." />
                              )}
                              {fields.length < 1 ? (
                                <TransparentButtonWithIcon
                                  text={'Add Group'}
                                  iconName={'Add'}
                                  onClick={() => fields.push({ value: 'ASC' })}
                                />
                              ) : null}
                            </>
                          );
                        }}
                      </FieldArray>
                    </FieldSection>
                    <FieldSection>
                      <FieldSectionHeader weight="semibold" text="Totals" />
                      <Field name="isTotals" label="Totals Only" component={SwitchField} />
                    </FieldSection>
                  </ViewCardBody>
                  <Card.Footer style={{ padding: '13px 23px' }}>
                    <ActionButton
                      text="Run"
                      style={{ width: '151px' }}
                      type="submit"
                      disabled={submitting || validating}
                    />
                  </Card.Footer>
                </ReportsCard>
              </form>
            </div>
          );
        }}
      />
    );
  }
}

export { ActiveSalesPipelineConfigView };
