import React from 'react';
import styled from 'styled-components';
import { CurrencyFormat } from '../../../shared/components/CurrencyFormat';

const TooltipBlock = styled.div`
  padding: 20px;
  border-radius: 5px;
  border: 1px solid #d0d7dd;
  text-align: left;
  background-color: #fff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);
`;

const TooltipLabel = styled.p`
  margin-bottom: 15px;
  text-transform: uppercase;
  font-weight: 300;
  color: #939ea7;
`;

const TooltipList = styled.ul`
  list-style: none;
`;

const TooltipListItem = styled.li`
  position: relative;
  padding-left: 20px;
  font-weight: ${({ active }) => (active ? '700' : '300')};
  color: #a5afb6;

  &:not(:last-child) {
    margin-bottom: 7px;
  }

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 5px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: ${({ color }) => color};
  }
`;

export const CustomTooltip = ({ active, label, payload, currency }) =>
  active && (
    <TooltipBlock>
      <TooltipLabel>{label}</TooltipLabel>
      <TooltipList>
        {payload.map((item, i) => (
          <TooltipListItem key={i} color={item.color}>
            {item.name}:{' '}
            <CurrencyFormat {...currency} value={item.value} displayType="text" decimalScale={2} />
          </TooltipListItem>
        ))}
      </TooltipList>
    </TooltipBlock>
  );
