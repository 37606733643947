import gql from 'graphql-tag';
import { UserFragment } from '../../auth/queries';
import { CommentFragment } from '../../comment/comment-queries';

/**
 * Issue Item.
 */
const IssueFragment = gql`
  fragment IssueFragment on Issue {
    id
    name
    description
    status
    assignedTo {
      ...UserFragment
    }
    assignedDate
    revisedDueDate
    originalDueDate
    createdAt
    documents {
      items {
        id
        filename
        downloadUrl
        fileId
      }
    }
    initiatives {
      items {
        id
        name
        baselineEndDate
        baselineStartDate
        owner {
          ...UserFragment
        }
        status
      }
    }
    nextSteps {
      items {
        id
        dueDate
        description
        status
        assignedTo {
          ...UserFragment
        }
      }
    }
    unitType
    unitQuantity
    unitValueDescription
    unitMonetizationFactor
    itemIssueRelation {
      id
    }
    source {
      id
      name
    }
  }
  ${UserFragment}
`;

/**
 * Create.
 */
export const ISSUE_CREATE_MUTATION = gql`
  mutation($data: IssueCreateInput!) {
    issueCreate(data: $data) {
      ...IssueFragment
    }
  }
  ${IssueFragment}
`;

export const ISSUE_DETAIL_QUERY = gql`
  query($id: ID!) {
    issue(id: $id) {
      ...IssueFragment
    }
  }
  ${IssueFragment}
`;
export const ISSUE_UPDATE_QUERY = gql`
  mutation($data: IssueUpdateInput!) {
    issueUpdate(data: $data) {
      ...IssueFragment
    }
  }
  ${IssueFragment}
`;
/**
 * Update.
 */
export const ISSUE_UPDATE_MUTATION = gql`
  mutation($data: IssueUpdateInput!) {
    issueUpdate(data: $data) {
      id
      status
    }
  }
`;

/**
 * Query for the Issue Comments.
 *
 * @type {*|*}
 */
export const ISSUE_COMMENTS_QUERY = gql`
  query($id: ID!) {
    issue(id: $id) {
      id
      comments {
        items {
          ...CommentFragment
        }
        count
      }
    }
  }
  ${CommentFragment}
`;
