import * as R from 'ramda';
import { FIELD_TYPE } from '@8base/utils';

import { convertRelationToString } from '../../utils';

const getGroupingsTitle = (titleFieldMeta, groupValue, groupKey, groupList, namesDict) => {
  const { fieldType, name, displayName } = titleFieldMeta;

  // title looks like "Header: groupValue"
  // Header is a displayName for groupKey
  // groupValue is a value of the field in database
  // (if it is RELATION we use convertRelationToString function)
  let titleText = groupValue;

  if (fieldType === FIELD_TYPE.RELATION) {
    const relationData = R.path(['0', groupKey], groupList);
    titleText = convertRelationToString(groupKey, relationData);
  }

  if (groupValue === 'null') {
    titleText = 'No Data';
  }

  const titleHeader = namesDict[name] ? namesDict[name] : displayName;

  return `${titleHeader}: ${titleText}`;
};

export { getGroupingsTitle };
