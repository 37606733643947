import gql from 'graphql-tag';

export const PIVOT_TABLE_QUERY = gql`
  query PivotTableReport(
    $tableName: String!
    $groupBy: [String!]
    $fields: String!
    $filter: JSON!
    $sort: JSON!
  ) {
    pivotTableReport(
      tableName: $tableName
      groupBy: $groupBy
      fields: $fields
      filter: $filter
      sort: $sort
    ) {
      groupBy
      pivotTableData
    }
  }
`;

export const ACTIVE_SALES_PIPELINE_QUERY = gql`
  query ActiveSalesPipeline(
    $groupBy: [String!]
    $sort: [DealDatumSort!]
    $filter: DealDatumFilter
  ) {
    activeSalesPipeline(groupBy: $groupBy, sort: $sort, filter: $filter) {
      groupBy
      deals
    }
  }
`;

export const SALES_PIPELINE_TRENDS_QUERY = gql`
  query SalesPipelineTrends(
    $groupBy: [String!]
    $sort: [DealMonthlySnapshotSort!]
    $filter: DealMonthlySnapshotFilter
  ) {
    salesPipelineTrends(groupBy: $groupBy, sort: $sort, filter: $filter) {
      groupBy
      monthlySnapshots
    }
  }
`;

export const BUDGET_VS_FORECAST_QUERY = gql`
  query BudgetVsForecast($filter: InitiativeFilter) {
    initiativesList(filter: $filter) {
      items {
        id
        name
        businessCase {
          id
          anticipatedCosts
        }
        baselineStartDate
        forecastingYears {
          items {
            id
            yTDActuals
            forecastings
          }
        }
      }
    }
  }
`;

export const INITIATIVE_FULL_LIST_QUERY = gql`
  query InitiativesBySelectOptions($filter: InitiativeFilter) {
    initiativesList(filter: $filter) {
      items {
        id
        name
      }
    }
  }
`;

export const ALLIANCE_FORECAST_QUERY = gql`
  query AllianceForecast($filter: AllianceForecastFilter) {
    allianceForecastsList(filter: $filter) {
      items {
        id
        year
        clientContributionsActuals
        clientContributionsForecast
        partnerContributionsActuals
        partnerContributionsForecast
        clientBookingsActuals
        clientBookingsForecast
        partnerBookingsActuals
        partnerBookingsForecast
      }
    }
  }
`;
