import React from 'react';
import PropTypes from 'prop-types';
import DetailValue from '../../../components/DetailValue';
import Status from '../../../components/Status';
import { HorizontalLineText } from '../../../components/text/HorizontalLineText';
import { Grid, Table } from '@8base/boost';
import { Link } from 'react-router-dom';
import Moment from 'react-moment';
import { TableDetail } from '../../../components/new-ui/table/TableDetail';
import { BoderDetailView } from '../../../components/new-ui/div/BorderDetailView';
import { TablePosition } from '../../../components/new-ui/div/TablePosition';
import { HeaderText } from '../../../components/new-ui/text/HeaderText';
import { formatUser } from '../../../shared/utils';

const RelatedItemsDetailTable = (props) => {
  const { relatedItems } = props;

  let itemsComponent = 'No items selected';

  if (relatedItems.length > 0) {
    itemsComponent = (
      <TablePosition>
        <TableDetail>
          <Table.Header className="justify-center-column" columns="3fr 3fr 3fr 3fr 3fr">
            <Table.HeaderCell className="name-column">Name</Table.HeaderCell>
            <Table.HeaderCell>Requested By</Table.HeaderCell>
            <Table.HeaderCell>Type</Table.HeaderCell>
            <Table.HeaderCell>Due Date</Table.HeaderCell>
            <Table.HeaderCell>Status</Table.HeaderCell>
          </Table.Header>
          <Table.Body data={relatedItems} className="card-body-list-details">
            {(item, index) => {
              const { id, name, originalDueDate, type, status, requestedBy, url } = item;

              return (
                <>
                  <Table.BodyRow
                    columns="3fr 3fr 3fr 3fr 3fr"
                    key={index}
                    className={'justify-center-column'}>
                    <Table.BodyCell className="justify-center-column">
                      <Link className="item-name" to={`/management/${url}/${id}/`}>
                        {name}
                      </Link>
                    </Table.BodyCell>
                    <Table.BodyCell>
                      <DetailValue text={formatUser(requestedBy)} />
                    </Table.BodyCell>
                    <Table.BodyCell>
                      <DetailValue text={type} />
                    </Table.BodyCell>
                    <Table.BodyCell>
                      <Moment format="MMMM Do, YYYY">{originalDueDate}</Moment>
                    </Table.BodyCell>
                    <Table.BodyCell>
                      <Status status={status} />
                    </Table.BodyCell>
                  </Table.BodyRow>
                </>
              );
            }}
          </Table.Body>
        </TableDetail>
      </TablePosition>
    );
  }

  return (
    <>
      <HorizontalLineText>RELATED ITEMS</HorizontalLineText>
      <>
        <BoderDetailView>
          <Grid.Layout columns="50% 50%" areas={[['left', 'right']]} style={{ width: '100%' }}>
            <Grid.Box area="left">
              <div style={{ position: 'absolute', top: '21px', left: '25px' }}>
                <HeaderText>RELATED ITEMS</HeaderText>
              </div>
            </Grid.Box>
          </Grid.Layout>
        </BoderDetailView>
        <div>{itemsComponent}</div>
      </>
    </>
  );
};

RelatedItemsDetailTable.propTypes = {
  relatedItems: PropTypes.object.isRequired,
};

export { RelatedItemsDetailTable };
