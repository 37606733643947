import Flux from '@cobuildlab/flux-state';
import {
  COMPANY_DETAIL_QUERY,
  COMPANY_FORM_DATA_QUERY,
  COMPANY_INVITATIONS_QUERY,
  COMPANY_LIST_MEMBERS_QUERY,
  COMPANY_LIST_QUERY,
  CREATE_COMPANY_MUTATION,
  EDIT_COMPANY_MUTATION,
  LEAVE_COMPANY_MUTATION,
  TRANSFER_OWNER_MUTATION,
  UPDATE_ROLE_MUTATION,
  COMPANY_SUBSCRIPTION_QUERY,
} from './Company.queries';
import {
  COMPANY_CREATE_EVENT,
  COMPANY_DETAIL_EVENT,
  COMPANY_EDIT_EVENT,
  COMPANY_ERROR_EVENT,
  COMPANY_FORM_DATA_EVENT,
  COMPANY_LEAVE_EVENT,
  COMPANY_LIST_EVENT,
  COMPANY_LIST_MEMBERS_EVENT,
  COMPANY_ROLE_CHANGE_EVENT,
  COMPANY_TRANSFER_EVENT,
  COMPANY_SUBSCRIPTION_EVENT,
  COMPANY_RESET_PASSWORD_ERROR,
  COMPANY_RESET_PASSWORD_EVENT,
} from './company.store';
import {
  createCompanyProcurementValidator,
  createCompanyValidator,
  createUserInvitationValidator,
  validateCompanyMemberInvitation,
} from './company.validators';
import sessionStore, { APOLLO_CLIENT, NEW_SESSION_EVENT } from '../../../shared/SessionStore';
import { fetchRoles } from '../../dashboard/dashboard-actions';
import { IntegrityError, ValidationError } from '../../../shared/errors';
import {
  ALLIANCE_COLLABORATOR,
  COMPANY_ADMINISTRATOR,
  COMPANY_PORTFOLIO_OWNER,
  COMPANY_PROCUREMENT,
} from '../../../shared/roles';
import { error, log } from '@cobuildlab/pure-logger';
import { COMPANY_SUBSCRIPTION_FREE_TRIAL_PLANS } from './company-models';
import { UPDATE_COMPANY_INFORMATION_MUTATION } from '../../wizard/company.queries';
import { CREATE_USER_INVITATION_EVENT } from '../../wizard/WizardStore';
import { fetchAlliances } from '../alliance-management/alliance-actions';
import { ALLIANCE_UPDATE_MUTATION } from '../alliance-management/alliance-queries';
import moment from 'moment';
import { canEditCompanyPermission } from '@cobuildlab/collabtogrow-permissions';
import { updateUser } from '../../wizard/wizard.actions';
import {
  normalize8baseDocumentCreate,
  normalize8baseDocumentDeleteAndUpdate,
  normalize8baseReferenceConnect,
} from '@cobuildlab/8base-utils';
import { canChangeUserRole } from '@cobuildlab/collabtogrow-permissions';
import { checkStatus } from '../../../shared/fetch';
import {
  OnCompanyError,
  OnCompanyListMemebers,
  OnCompanyList,
  OnCompanyFormData,
  OnCompanyDetail,
  OnCompanyEdit,
  OnCompanyLeave,
  OnCompanySubscription,
  OnCompanyCreate,
  OnCompanyRoleChange,
  OnCompanyTransfer,
  OnCompanyResetPassword,
  OnCompanyResetPasswordError,
} from './company-events';
import { OnCompanyCreateUserInvitation } from '../../wizard/wizard-events';

/**
 * Fetches data for the company creation and edit form.
 *
 * @returns {Promise<void>}Promise.
 */
export const fetchCompanyFormDataAction = async () => {
  const client = sessionStore.getState(APOLLO_CLIENT);
  const user = sessionStore.getState(NEW_SESSION_EVENT).user;

  let response;

  try {
    response = await client.query({
      query: COMPANY_FORM_DATA_QUERY,
      variables: { userId: user.id },
      fetchPolicy: 'network-only',
    });
  } catch (e) {
    console.error('fetchCompanyFormDataAction', e);
    OnCompanyError.dispatch(e);
    return Flux.dispatchEvent(COMPANY_ERROR_EVENT, e);
  }

  console.log('fetchCompanyFormData', response);
  OnCompanyFormData.dispatch(response.data);
  return Flux.dispatchEvent(COMPANY_FORM_DATA_EVENT, response.data);
};

/**
 *
 * @param {object}client - Client.
 * @param {object}data - Data.
 * @returns {Promise<void|*>}Promise.
 * @private
 */
async function _createCompany(client, data) {
  log('_createCompany: ', data);

  let response;
  try {
    response = await createCompanyMutation(client, data);
  } catch (e) {
    console.error('createCompany', e);
    OnCompanyError.dispatch(e);
    return Flux.dispatchEvent(COMPANY_ERROR_EVENT, e);
  }

  console.log('createCompany Response', response);
  OnCompanyCreate.dispatch(response.data);
  Flux.dispatchEvent(COMPANY_CREATE_EVENT, response.data);
  return response.data;
}

/**
 * @param {object} client - Client.
 * @param {object} data - Data.
 *
 * @returns {Promise<void>} - Company created.
 */
export function createCompanyMutation(client, data) {
  return client.mutate({
    mutation: CREATE_COMPANY_MUTATION,
    variables: { data },
  });
}

export const updateCompanyMutation = (client, data) => {
  return client.mutate({
    mutation: UPDATE_COMPANY_INFORMATION_MUTATION,
    variables: { data },
  });
};

/**
 * Creates a New Company.
 *
 * @param {object} data - Object with all the company information.
 * @param {object} paymentMethod - Stripe paymentMethod.
 * @param {object} stripeError - Stripe Error.
 * @param {boolean} isAnnualSubscriptionPlan - If the selected plan is annual.
 * @returns {Promise} The company data.
 */
export const createCompany = async (data, paymentMethod, stripeError, isAnnualSubscriptionPlan) => {
  const client = sessionStore.getState(APOLLO_CLIENT);
  const { user } = sessionStore.getState(NEW_SESSION_EVENT);

  log('createCompany: ', data);

  try {
    createCompanyProcurementValidator(data, paymentMethod, stripeError, isAnnualSubscriptionPlan);
  } catch (err) {
    console.error('createCompany Validator', err);
    OnCompanyError.dispatch(err);
    return Flux.dispatchEvent(COMPANY_ERROR_EVENT, err);
  }

  const companyUsers = [
    {
      user: { connect: { id: user.id } },
      role: { connect: { name: COMPANY_PROCUREMENT } },
    },
  ];

  if (data.isCompanyPortfolioOwner) {
    companyUsers.unshift({
      user: { connect: { id: user.id } },
      role: { connect: { name: COMPANY_PORTFOLIO_OWNER } },
    });
  } else {
    data.companyInvitationRelation = {
      create: {
        email: data.portfolioOwnerEmail,
        role: { connect: { name: COMPANY_PORTFOLIO_OWNER } },
      },
    };
  }
  data.companyUserRelation = { create: companyUsers };

  // get data subscriptionPlan fields
  const {
    id: subscriptionPlanId,
    name: subscriptionPlanName,
    monthlyStripePlanId,
    annualStripePlanId,
  } = data.subscriptionPlan;
  const isFreeTrialPlan = isPlanFreeTrial(subscriptionPlanName);
  const isAnnualPlan = isFreeTrialPlan ? false : isAnnualSubscriptionPlan;
  const stripePlanId = isAnnualPlan ? annualStripePlanId : monthlyStripePlanId;

  // get paymentMethod data
  let creditCard = {};
  if (paymentMethod) {
    log('company-actions:CreateCompany:paymentMethod:', paymentMethod);
    // get extra fields from paymentMethod;
    const {
      id: stripePaymentMethodId,
      billing_details: {
        address: { postal_code: zipCode },
      },
      card: { last4, brand: cardBrand, exp_month: expMonth, exp_year: expYear },
    } = paymentMethod;
    const cardExpireDate = moment.utc(`${expYear}-${expMonth}`, 'YYYY-MM').format('YYYY-MM-DD');
    creditCard = {
      last4,
      cardBrand,
      cardExpireDate,
      stripePaymentMethodId,
      zipCode,
      ...data.creditCard,
    };
    log('company-actions:CreateCompany:creditCard:', creditCard);
  }

  // Company subscription dataq
  data.companyCompanySubscriptionRelation = {
    create: {
      subscriptionPlan: {
        connect: {
          id: subscriptionPlanId,
        },
      },
      ...creditCard,
      stripePlanId,
      isAnnualPlan,
      isFreeTrialPlan,
    },
  };

  delete data.id;
  delete data.portfolioOwnerEmail;
  delete data.isCompanyPortfolioOwner;
  delete data.subscriptionPlan;
  delete data.creditCard;

  log('CreateCompany: ', data);
  return await _createCompany(client, data);
};

/**
 * Creates a New Company.
 *
 * @param {object} data - Object with all the company information.
 * @returns {Promise<void>}Promise.
 * @deprecated Use createCompany.
 */
export const createCompanyAction = async (data) => {
  const client = sessionStore.getState(APOLLO_CLIENT);
  const user = sessionStore.getState(NEW_SESSION_EVENT).user;
  const roles = await fetchRoles();
  const role = roles.find((role) => role.name === COMPANY_ADMINISTRATOR);

  delete data.id;
  delete data.pictureUrl;

  data.annualRevenue = String(data.annualRevenue);
  data.country = { connect: { id: data.country } };
  data.industry = { connect: { id: data.industry } };
  data.companyUserRelation = {
    create: [{ user: { connect: { id: user.id } }, role: { connect: { id: role.id } } }],
  };

  try {
    createCompanyValidator(data);
  } catch (err) {
    console.error('createCompany Validator', err);
    return Flux.dispatchEvent(COMPANY_ERROR_EVENT, err);
  }

  normalize8baseDocumentCreate(data, 'logoFile');

  return await _createCompany(client, data);
};

/**
 * Fetch a single Company.
 *
 * @param {string} id - Of the company to fetch.
 * @param {boolean} dispatchEvents - If the function should fire events.
 * @returns {Promise<void>}Promise.
 */
export const fetchCompanyAction = async (id, dispatchEvents = true) => {
  const client = sessionStore.getState(APOLLO_CLIENT);

  let response;

  try {
    response = await client.query({
      query: COMPANY_DETAIL_QUERY,
      variables: { id },
      fetchPolicy: 'network-only',
    });
  } catch (e) {
    console.error('fetchCompany', e);
    if (dispatchEvents) {
      OnCompanyError.dispatch(e);
      Flux.dispatchEvent(COMPANY_ERROR_EVENT, e);
    }

    return;
  }

  console.log('fetchCompany', response);

  if (dispatchEvents) {
    OnCompanyDetail.dispatch(response.data);
    Flux.dispatchEvent(COMPANY_DETAIL_EVENT, response.data);
  }

  return response.data;
};

export const fetchCompanyListAction = async (page = 1, first = 20) => {
  const client = sessionStore.getState(APOLLO_CLIENT);
  const user = sessionStore.getState(NEW_SESSION_EVENT).user;
  const skip = (page - 1) * first;
  const filter = {
    companyUserRelation: {
      some: { user: { id: { equals: user.id } } },
    },
  };

  let response;
  try {
    response = await client.query({
      query: COMPANY_LIST_QUERY,
      variables: { data: filter, skip, first },
      fetchPolicy: 'network-only',
    });
  } catch (e) {
    console.error('FetchCompanies', e);
    OnCompanyError.dispatch(e);
    return Flux.dispatchEvent(COMPANY_ERROR_EVENT);
  }

  console.log('fetchCompanies', response);
  OnCompanyList.dispatch(response.data);
  Flux.dispatchEvent(COMPANY_LIST_EVENT, response.data);
};

/**
 * Update a Company.
 *
 * @param {object} data - The Updated Company.
 * @returns {Promise<void>}Promise.
 */
export const editCompanyAction = async (data) => {
  log('editCompanyAction', data);
  const client = sessionStore.getState(APOLLO_CLIENT);
  const user = sessionStore.getState(NEW_SESSION_EVENT).user;
  const { company: originalCompany } = await fetchCompanyAction(data.id, false);

  if (!canEditCompanyPermission(user, originalCompany)) {
    const e = new Error("Permission Error. User can't edit this company.");
    error('editCompanyAction:permissionError', e);
    OnCompanyError.dispatch(e);
    return Flux.dispatchEvent(COMPANY_ERROR_EVENT, e);
  }

  delete data.__typename;
  delete data.companyUserRelation;
  delete data.pictureUrl;

  data.annualRevenue = String(data.annualRevenue);
  normalize8baseReferenceConnect(data, 'country');
  normalize8baseReferenceConnect(data, 'industry');
  normalize8baseDocumentDeleteAndUpdate(data, 'logoFile', originalCompany);

  try {
    createCompanyValidator(data);
  } catch (err) {
    console.error('editCompanyAction:Validator:', err);
    OnCompanyError.dispatch(err);
    return Flux.dispatchEvent(COMPANY_ERROR_EVENT, err);
  }

  let response;
  try {
    response = await client.mutate({
      mutation: EDIT_COMPANY_MUTATION,
      variables: { data },
    });
  } catch (e) {
    console.error('EditCompany', e, data);
    OnCompanyError.dispatch(e);
    return Flux.dispatchEvent(COMPANY_ERROR_EVENT, e);
  }

  console.log('EditCompany', response);
  OnCompanyEdit.dispatch(response.data);
  Flux.dispatchEvent(COMPANY_EDIT_EVENT, response.data);
  return response.data;
};

/**
 * Leave the company action.
 *
 * @param {string} companyUserId - Id of the relation.
 * @param {object} company - Company to leave.
 * @returns {Promise<void>} Promise.
 */
export const leaveCompanyAction = async (companyUserId, company) => {
  const client = sessionStore.getState(APOLLO_CLIENT);

  const companyUser = company.companyUserRelation.items.find(({ id }) => companyUserId === id);
  if (companyUser && companyUser.role.name === COMPANY_PORTFOLIO_OWNER) {
    const portfolioOwners = company.companyUserRelation.items.filter(
      (_companyUser) => _companyUser.role.name === COMPANY_PORTFOLIO_OWNER,
    );

    if (portfolioOwners.length === 1) {
      OnCompanyError.dispatch(
        new ValidationError(
          'The company needs at least one Portfolio Owner, please assign another Portfolio Owner before leaving',
        ),
      );

      return Flux.dispatchEvent(
        COMPANY_ERROR_EVENT,
        new ValidationError(
          'The company needs at least one Portfolio Owner, please assign another Portfolio Owner before leaving',
        ),
      );
    }
  }

  let response;

  try {
    response = await client.mutate({
      mutation: LEAVE_COMPANY_MUTATION,
      variables: { data: { id: companyUserId, force: true } },
    });
  } catch (e) {
    console.error('leaveCompany', e);
    OnCompanyError.dispatch(e);
    return Flux.dispatchEvent(COMPANY_ERROR_EVENT, e);
  }

  try {
    await updateUser({ selectedAllianceId: null });
  } catch (e) {
    console.error('Update META_ALLIANCE_SELECTED', e);
    OnCompanyError.dispatch(e);
    return Flux.dispatchEvent(COMPANY_ERROR_EVENT, e);
  }

  console.log('leaveCompany', response);
  OnCompanyLeave.dispatch(response.data);
  Flux.dispatchEvent(COMPANY_LEAVE_EVENT, response.data);
  return response.data;
};

/**
 * Fetches the Members from my Company.
 *
 * @returns {Promise<void>}Promise.
 */
export const fetchMyCompanyMembersAction = async () => {
  const user = sessionStore.getState(NEW_SESSION_EVENT).user;
  const companyId = user.companyUserRelation.items[0].company.id;

  if (!companyId) {
    OnCompanyError.dispatch(new IntegrityError(`You don't belong to a Company yet!`));

    return Flux.dispatchEvent(
      COMPANY_ERROR_EVENT,
      new IntegrityError(`You don't belong to a Company yet!`),
    );
  }

  return await fetchMembersAction(companyId);
};

/**
 * Fetches the Members for the Company.
 *
 * @param {string} id - Of the company.
 * @param {boolean} dispatch - If shoud dispatch and event.
 * @returns {Promise<void>} Promise.
 */
export const fetchMembersAction = async (id, dispatch = true) => {
  log('fetchMembersAction:', id);
  const client = sessionStore.getState(APOLLO_CLIENT);

  let response;
  try {
    response = await client.query({
      query: COMPANY_LIST_MEMBERS_QUERY,
      variables: { id },
      fetchPolicy: 'network-only',
    });
  } catch (e) {
    error('fetchMembersAction:error', e);
    if (dispatch) {
      OnCompanyError.dispatch(e);
      Flux.dispatchEvent(COMPANY_ERROR_EVENT, e);
    }

    return;
  }

  log('fetchMembersAction:response', response.data);
  if (dispatch) {
    OnCompanyListMemebers.dispatch(response.data);
    Flux.dispatchEvent(COMPANY_LIST_MEMBERS_EVENT, response.data);
  }

  return response.data;
};

/**
 * Action to transfer the ownership of a company.
 *
 * @param {string}companyUserId - The id of your own company user relation.
 * @param {string}memberId - The id of the company user relation of the new owner.
 * @returns {Promise<void>} Promise.
 */
export const transferOwnerAction = async (companyUserId, memberId) => {
  const client = sessionStore.getState(APOLLO_CLIENT);

  const roles = await fetchRoles();
  const collaboratorRole = roles.find((role) => role.name === COMPANY_ADMINISTRATOR);
  const ownerRole = roles.find((role) => role.name === COMPANY_PORTFOLIO_OWNER);

  let data1 = {};
  let data2 = {};

  data1.id = companyUserId;
  data2.id = memberId;

  data1.role = {
    connect: { id: collaboratorRole.id },
  };

  data2.role = {
    connect: { id: ownerRole.id },
  };

  console.log('transferOwnerAction data 1', data1);
  console.log('transferOwnerAction data 2', data2);

  let response;

  try {
    response = await client.mutate({
      mutation: TRANSFER_OWNER_MUTATION,
      variables: { data1, data2 },
    });
  } catch (e) {
    console.error('transferOwner', e);
    OnCompanyError.dispatch(e);
    return Flux.dispatchEvent(COMPANY_ERROR_EVENT, e);
  }

  console.log('transferOwner', response);
  OnCompanyTransfer.dispatch(response.data);
  Flux.dispatchEvent(COMPANY_TRANSFER_EVENT, response.data);
  return response.data;
};

/**
 * Change the role of a member.
 *
 * @param {object} companyUser - Member Id.
 * @param {object} newRole - New Role.
 * @param {object} companyUserList - Company User list.
 *  @returns {Promise<void>} Promise.
 */
export const changeMemberRoleAction = async (companyUser, newRole, companyUserList) => {
  const client = sessionStore.getState(APOLLO_CLIENT);

  try {
    canChangeUserRole(companyUser, companyUserList);
  } catch (e) {
    console.error('changeMemberRoleAction: validateChangeUserRole', e);
    OnCompanyError.dispatch(e);
    return Flux.dispatchEvent(COMPANY_ERROR_EVENT, e);
  }

  const data = {};
  data.id = companyUser.id;
  data.role = {
    connect: { id: newRole.id },
  };

  let response;

  try {
    response = await client.mutate({
      mutation: UPDATE_ROLE_MUTATION,
      variables: { data },
    });
  } catch (e) {
    console.error('changeMemberRoleAction', e);
    OnCompanyError.dispatch(e);
    return Flux.dispatchEvent(COMPANY_ERROR_EVENT, e);
  }

  // If the assigned Role is company Portfolio Owner, add to all the alliances
  if (newRole.name === COMPANY_PORTFOLIO_OWNER) {
    const allRoles = await fetchRoles();
    const alliances = await fetchAlliances('', 1, 100);
    const allianceCollaboratorRole = allRoles.find((role) => role.name === ALLIANCE_COLLABORATOR);
    const promises = alliances.alliancesList.items.map((alliance) =>
      addAllianceCollaborator(alliance.id, companyUser.id, allianceCollaboratorRole.id),
    );
    await Promise.all(promises);
  }

  console.log('changeMemberRoleAction', response);
  OnCompanyRoleChange.dispatch(response.data);
  Flux.dispatchEvent(COMPANY_ROLE_CHANGE_EVENT, response.data);
  return response.data;
};

/**
 * Adds a CompanyUser as Alliance Collaborator.
 *
 * @param {string} allianceId - Alliance Id.
 * @param {string} companyUserId - Company User Id.
 * <<<<<<< HEAD.
 * @param {string} allianceCollaboratorRoleId - Alliance Collaborator Role id.
 * =======.
 * @returns {Promise<*>} Promise.
 */
const addAllianceCollaborator = async (allianceId, companyUserId, allianceCollaboratorRoleId) => {
  log('addAllianceCollaborator', allianceId, companyUserId);
  const client = sessionStore.getState(APOLLO_CLIENT);

  const allianceData = {
    id: allianceId,
    allianceUserAllianceRelation: {
      create: [
        {
          companyUser: { connect: { id: companyUserId } },
          role: { connect: { id: allianceCollaboratorRoleId } },
        },
      ],
    },
  };

  let response;
  try {
    response = await client.mutate({
      mutation: ALLIANCE_UPDATE_MUTATION,
      variables: { data: allianceData },
    });
    log('addAllianceCollaborator', response);
  } catch (e) {
    throw e;
  }

  return response;
};

/**
 * Get all the user's company where the user is Administrator.
 *
 * @returns {*} Object.
 */
export const getCompanyUserAdminAndPortfolio = () => {
  const user = sessionStore.getState(NEW_SESSION_EVENT).user;
  const companies = user.companyUserRelation;
  return companies.items.filter((company) => {
    return (
      company.role &&
      (company.role.name === COMPANY_PORTFOLIO_OWNER || company.role.name === COMPANY_ADMINISTRATOR)
    );
  });
};

/**
 * Get all the user's company where the user is Administrator.
 *
 * @returns {*} Object.
 */
export const getMyAllCompanyUsers = () => {
  const user = sessionStore.getState(NEW_SESSION_EVENT).user;
  const companies = user.companyUserRelation;
  return companies.items.filter((company) => Boolean(company.role));
};

/**
 * Create user invitation member company.
 *
 * @param {string}email - Email.
 * @param {string}role - Role.
 * @returns {Promise<void|*>} Promise.
 */
export const createUserInvitation = async (email, role) => {
  const { user } = sessionStore.getState(NEW_SESSION_EVENT);
  const { company } = user.companyUserRelation.items[0];

  try {
    const result = await _createUserInvitation(company, email, role);

    console.log('createUserInvitation', result);
    OnCompanyCreateUserInvitation.dispatch(result);
    Flux.dispatchEvent(CREATE_USER_INVITATION_EVENT, result);
    return result;
  } catch (e) {
    console.error('createUserInvitation', e);
    OnCompanyError.dispatch(e);
    return Flux.dispatchEvent(COMPANY_ERROR_EVENT, e);
  }
};

/**
 * Create user invitation member company.
 *
 * @param {object} company - Company.
 * @param {string} email - Email.
 * @param {string} roleId - Role.
 * @returns {Promise<void|*>} Promise.
 */
export const _createUserInvitation = async (company, email, roleId) => {
  const client = sessionStore.getState(APOLLO_CLIENT);
  const companyId = company.id;

  try {
    createUserInvitationValidator(email, roleId);
  } catch (err) {
    console.error('createUserInvitationValidator', err);
    throw err;
  }

  const { companyInvitationsList } = await fetchCompanyInvitations(email);

  try {
    validateCompanyMemberInvitation(companyInvitationsList);
  } catch (err) {
    error('validateCompanyMemberInvitation', err);
    throw err;
  }

  const usersInvitations = [{ email, role: { connect: { id: roleId } } }];

  const data = {
    id: companyId,
    companyInvitationRelation: { create: usersInvitations },
  };

  console.log('createUserInvitation data', data);

  try {
    const result = await client.mutate({
      mutation: UPDATE_COMPANY_INFORMATION_MUTATION,
      variables: { data },
    });

    console.log('createUserInvitation', result);
    return result;
  } catch (e) {
    console.error('createUserInvitation', e);
    throw e;
  }
};

/**
 * Filter company invitations by email.
 *
 * @param {string}email - Email.
 * @returns {Promise<void|*>} Promise.
 */
export const fetchCompanyInvitations = async (email) => {
  const user = sessionStore.getState(NEW_SESSION_EVENT).user;
  const client = sessionStore.getState(APOLLO_CLIENT);
  const companyId = user.companyUserRelation.items[0].company.id;

  const filter = {
    email: {
      equals: email,
    },
    company: {
      id: {
        equals: companyId,
      },
    },
  };

  let response;

  try {
    response = await client.mutate({
      mutation: COMPANY_INVITATIONS_QUERY,
      variables: { filter },
    });
  } catch (e) {
    console.error('fetchCompanyInvitations', e);
    return Flux.dispatchEvent(COMPANY_ERROR_EVENT, e);
  }

  return response.data;
};

/**
 * Get Company subscriptions details.
 *
 * @returns {Promise<void|*>} Promise.
 */
export const fetchCompanySubscription = async () => {
  const user = sessionStore.getState(NEW_SESSION_EVENT).user;
  const client = sessionStore.getState(APOLLO_CLIENT);
  const companyId = user.companyUserRelation.items[0].company.id;

  const filter = {
    company: {
      id: {
        equals: companyId,
      },
    },
  };

  const sort = {
    createdAt: 'DESC',
  };

  const first = 1;

  console.log('fetchCompanySubscription:filter', filter);
  let response;

  try {
    response = await client.mutate({
      mutation: COMPANY_SUBSCRIPTION_QUERY,
      variables: { filter, sort, first },
    });
  } catch (e) {
    console.error('fetchCompanySubscription', e);
    OnCompanyError.dispatch(e);
    return Flux.dispatchEvent(COMPANY_ERROR_EVENT, e);
  }

  OnCompanySubscription.dispatch(response.data);
  Flux.dispatchEvent(COMPANY_SUBSCRIPTION_EVENT, response.data);

  return response.data;
};

/**
 * Sends a  reset passowrd email to a User.
 *
 * @param {string} userEmail - The User Email.
 */
export const resetPasswordEmail = async (userEmail) => {
  const { REACT_APP_8BASE_API_ENDPOINT } = process.env;
  console.log('sendResetPasswordEmail:', userEmail);
  let response;
  try {
    response = await fetch(`${REACT_APP_8BASE_API_ENDPOINT}/webhook/resetPassword`, {
      body: JSON.stringify({ userEmail }),
      method: 'POST',
    });
  } catch (e) {
    OnCompanyResetPasswordError.dispatch(e);
    Flux.dispatchEvent(COMPANY_RESET_PASSWORD_ERROR, e);
  }
  const parsedResponse = await checkStatus(response);

  OnCompanyResetPassword.dispatch(parsedResponse);
  Flux.dispatchEvent(COMPANY_RESET_PASSWORD_EVENT, parsedResponse);
};

export const isPlanFreeTrial = (planName) => {
  return COMPANY_SUBSCRIPTION_FREE_TRIAL_PLANS.includes(planName);
};
