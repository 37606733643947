import Flux from '@cobuildlab/flux-state';

/**
 * Event that triggers a Company Management error.
 *
 * @type {string}
 */
export const COMPANY_ERROR_EVENT = 'onCompanyError';

/**
 * Event that triggers a Company Management Detail event.
 *
 * @type {string}
 */
export const COMPANY_DETAIL_EVENT = 'onCompanyDetail';
/**
 * Event that triggers a Company Management List event.
 *
 * @type {string}
 */

export const COMPANY_LIST_EVENT = 'onCompanyEvent';

/**
 * Event that triggers a Alliance Management Create event.
 *
 * @type {string}
 */

export const COMPANY_CREATE_EVENT = 'onCompanyCreate';

/**
 * Event that triggers a Company Management Edit event.
 *
 * @type {string}
 */
export const COMPANY_EDIT_EVENT = 'onCompanyEdit';

/**
 * Event that triggers the fetch of the Company Form Dialog.
 *
 * @type {string}
 */
export const COMPANY_FORM_DATA_EVENT = 'onCompanyFormData';

/**
 * Event that triggers the fetch of the Company Members List.
 *
 * @type {string}
 */
export const COMPANY_LIST_MEMBERS_EVENT = 'onCompanyMembersList';

/**
 * Event that triggers a Company Management Leave event.
 *
 * @type {string}
 */
export const COMPANY_LEAVE_EVENT = 'onCompanyLeave';

/**
 * Event that triggers a Company Management Remove event.
 *
 * @type {string}
 */
export const COMPANY_TRANSFER_EVENT = 'onCompanyTransfer';

/**
 * Event that triggers the role change of a user.
 *
 * @type {string}
 */
export const COMPANY_ROLE_CHANGE_EVENT = 'onRoleChange';

/**
 * Event that triggers a Company subscriptions list.
 *
 * @type {string}
 */
export const COMPANY_SUBSCRIPTION_EVENT = 'onCompanySubscription';

/**
 * Event that triggers a Company Management error.
 *
 * @type {string}
 */
export const COMPANY_RESET_PASSWORD_EVENT = 'onResetPassword';

/**
 * Event that triggers a Company Management error.
 *
 * @type {string}
 */
export const COMPANY_RESET_PASSWORD_ERROR = 'onResetPasswordError';

/**
 * Hold the Company Data.
 */
class CompanyStore extends Flux.DashStore {
  constructor() {
    super();
    this.addEvent(COMPANY_ERROR_EVENT);
    this.addEvent(COMPANY_CREATE_EVENT);
    this.addEvent(COMPANY_DETAIL_EVENT);
    this.addEvent(COMPANY_EDIT_EVENT);
    this.addEvent(COMPANY_FORM_DATA_EVENT);
    this.addEvent(COMPANY_LIST_MEMBERS_EVENT);
    this.addEvent(COMPANY_LEAVE_EVENT);
    this.addEvent(COMPANY_TRANSFER_EVENT);
    this.addEvent(COMPANY_ROLE_CHANGE_EVENT);
    this.addEvent(COMPANY_LIST_EVENT);
    this.addEvent(COMPANY_SUBSCRIPTION_EVENT);
    this.addEvent(COMPANY_RESET_PASSWORD_ERROR);
    this.addEvent(COMPANY_RESET_PASSWORD_EVENT);
  }
}

export default new CompanyStore();
