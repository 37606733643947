import React from 'react';
import { Card, Grid, Heading, Loader, Row } from '@8base/boost';
import { CreateViewCardBody } from '../../../components/new-ui/card/CreateViewCardBody';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import * as toast from '../../../components/toast/Toast';
import * as R from 'ramda';
import { View } from '@cobuildlab/react-simple-state';
import { onChangeDataMixin, onErrorMixin } from '../../../shared/mixins';
import { DealData } from './deal-model';
import DealForm from './components/DealForm';
import { autoSaveUpdateDeal, fetchDealData, openComments, updateDeal } from './deal-actions';
import { concatClientAndPartnerUsers, getCurrencyOnSession } from '../../../shared/alliance-utils';
import { ActionButton } from '../../../components/buttons/ActionButton';
import { fetchRelatedItems } from '../../related-item/related-item-actions';
import { fetchCurrentAllianceMembersAction } from '../../settings/alliance-management/alliance-actions';
import { getItemByType } from '../../../shared/items-util';
import { TransparentButton } from '../../../components/buttons/TransparentButton';
import DealDetailTable from './components/DealDetailTable';
import { FormSteps } from '../../../components/dots/FormSteps';
import { ActionButtonClose } from '../../../components/buttons/ActionButtonClose';
import { dealValidator } from './deal-validators';
import RelatedItemForm from '../../related-item/components/RelatedItemForm';
import { RelatedItemsDetailTable } from '../../related-item/components/RelatedItemsDetailTable';
import sessionStore, { NEW_SESSION_EVENT } from '../../../shared/SessionStore';
import { LeftProgressSection } from '../../../components/new-ui/LeftProgressSection';
import { SCREENS_DEALS } from '../screenView';
import { BoxCard } from '../../../components/new-ui/div/BoxCard';
import { TransparentButtonSvg } from '../../../components/buttons/TransparentButtonSvg';
import collaborateIcon from '../../../images/icons/collab-chat-icon.svg';
import { fetchDealStage } from '../../settings/stage-mapping/stage-mapping-action';
import actionStore, { ACTION_UPDATE_EVENT } from '../action/action-store';
import { fetchInitiativeList } from '../initiative/initiative-actions';
import { initiativesItemValidator } from '../initiative/initiative-validators';
import { InitiativeListTable } from '../initiative/components/InitiativeListTable';
import { TextDateAgo } from '../../../components/text/TextDateAgo';
import { OnDealDetail, OnDealError, OnDealAutoSaveCreate, OnDealUpdate } from './deal-events';
import { OnRelatedItems } from '../../related-item/related-item-events';
import { OnStageMappingDetail } from '../../settings/stage-mapping/stage-mapping-events';
import { OnInitiativeList } from '../../management/initiative/initiative-events';
import { OnAllianceMemberList } from '../../settings/alliance-management/alliance-events';

class DealEditView extends View {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        dealData: R.clone(DealData),
        relatedItems: [],
        relatedDeals: [],
        initiatives: [],
        originalDealAssociatedId: null,
      },
      loading: true,
      allianceMembersOptions: [],
      step: 0,
      initiativesList: [],
      otherCompany: null,
      dealStagesOptions: [],
      savedAt: null,
    };
    this.onError = onErrorMixin.bind(this);
    this.onChangeData = (name, value) => {
      this.autoSave();
      onChangeDataMixin.call(this, name, value);
    };
    this.user = sessionStore.getState(NEW_SESSION_EVENT).user;
    this.queryOptions = { isCacheFirst: true };
  }

  onChangeDealData = (name, value) => {
    const { data } = this.state;
    data.dealData[name] = value;
    this.autoSave();
    this.setState({ data });
  };

  componentDidMount() {
    const { match } = this.props;

    this.subscribe(OnDealError, this.onError);

    this.subscribe(OnDealDetail, (state) => {
      const dealDatum = R.clone(state.dealDatum);
      dealDatum.owner = dealDatum.owner === null ? null : dealDatum.owner.id;
      dealDatum.itemId = dealDatum.itemDealDataRelation.id;
      const { data } = this.state;
      data.dealData = dealDatum;
      data.originalDealAssociatedId = R.clone(data.dealData.associatedDealId);
      data.initiatives = dealDatum.initiatives.items.map((item) => ({ ...item }));

      this.setState(
        {
          data,
        },
        () => fetchRelatedItems(dealDatum.itemId),
      );
    });

    this.subscribe(OnDealUpdate, () => {
      toast.success('Deal Successfully Updated');
      this.props.history.goBack();
    });

    this.subscribe(OnRelatedItems, (state) => {
      const items = state.item.itemsRelated.items.map((item) => getItemByType(item));
      const { data } = this.state;

      data.relatedItems = items;
      this.setState({ data, loading: false });
    });

    this.subscribe(OnStageMappingDetail, (state) => {
      const { dealStagesList } = state;
      const { data } = this.state;
      const dealStagesOptions = dealStagesList.items.map((dealStage) => {
        return {
          label: `${dealStage.name}`,
          value: `${dealStage.name}`,
        };
      });

      const dealStage = dealStagesOptions.find(
        (dealStage) => dealStage.label === data.dealData.stage,
      );
      data.dealData.stage = dealStage ? dealStage.value : null;

      this.setState({
        dealStagesOptions,
        data,
      });
    });

    this.subscribe(OnInitiativeList, (state) => {
      const initiativesList = state.initiativesList.items.map((item) => ({
        ...item,
      }));

      this.setState({
        initiativesList,
      });
    });
    this.subscribe(actionStore, ACTION_UPDATE_EVENT, (state) => {
      toast.success(`Action Successfully Updated`);
      this.props.history.goBack();
    });

    this.subscribe(OnAllianceMemberList, (state) => {
      const clientCompany = R.clone(state.clientCompany);
      const partnerCompany = R.clone(state.partnerCompany);

      const members = concatClientAndPartnerUsers(clientCompany, partnerCompany);
      const { data } = this.state;
      let otherCompany;
      if (data.dealData.company.id === clientCompany.id) {
        // if company deal is client company the other company is partner company
        otherCompany = R.clone(partnerCompany);
      } else {
        otherCompany = R.clone(clientCompany);
      }

      this.setState({
        data,
        otherCompany: otherCompany,
        allianceMembersOptions: members.map((member) => {
          return {
            label: `${member.firstName} ${member.lastName}`,
            value: member.id,
          };
        }),
      });
    });

    this.subscribe(OnDealAutoSaveCreate, (deal) => {
      this.setState({ savedAt: new Date() });
      this.savedDeal = R.clone(deal);
    });

    this.autoSaveTimer = null;
    this.savedDeal = null;

    if (!match.params.id) return toast.error('Deal ID missing');

    fetchDealStage();
    fetchDealData(match.params.id, this.queryOptions);
    fetchInitiativeList('', 1, 1000, null, null, this.queryOptions);
    fetchCurrentAllianceMembersAction();
  }

  onSubmit = () => {
    this.setState({ loading: true }, () => {
      clearTimeout(this.autoSaveTimer);
      const dealData = R.clone(this.state.data.dealData);
      const relatedItems = R.clone(this.state.data.relatedItems);
      const initiatives = R.clone(this.state.data.initiatives);
      const originalAssociatedDealId = R.clone(this.state.data.originalDealAssociatedId);
      const dealStagesOptions = R.clone(this.state.dealStagesOptions);
      const dealStageName = dealStagesOptions.find(
        (dealStage) => dealStage.value === dealData.stage,
      );
      dealData.stage = dealStageName ? dealStageName.label : null;
      updateDeal(
        dealData,
        relatedItems,
        this.state.otherCompany,
        originalAssociatedDealId,
        initiatives,
      );
    });
  };

  autoSave = () => {
    const waitTime = 10000; // Wait 10s
    clearTimeout(this.autoSaveTimer);
    this.autoSaveTimer = setTimeout(() => {
      const dealData = R.clone(this.state.data.dealData);
      const relatedItems = R.clone(this.state.data.relatedItems);
      const initiatives = R.clone(this.state.data.initiatives);
      const originalAssociatedDealId = R.clone(this.state.data.originalDealAssociatedId);
      const dealStagesOptions = R.clone(this.state.dealStagesOptions);
      const dealStageName = dealStagesOptions.find(
        (dealStage) => dealStage.value === dealData.stage,
      );
      dealData.stage = dealStageName ? dealStageName.label : null;
      autoSaveUpdateDeal(
        dealData,
        relatedItems,
        this.state.otherCompany,
        originalAssociatedDealId,
        initiatives,
      );
    }, waitTime);
  };

  onDealStepChange = (step) => {
    const { selectedAlliance } = sessionStore.getState(NEW_SESSION_EVENT);
    const dealData = R.clone(this.state.data.dealData);
    try {
      dealValidator(dealData, selectedAlliance);
    } catch (e) {
      return this.onError(e);
    }

    this.onScreen(step);
  };

  onScreen = (step) => {
    this.setState({ step });
  };

  onRelatedItemsStepChange = (step) => {
    const initiatives = R.clone(this.state.data.initiatives);
    try {
      initiativesItemValidator(initiatives);
    } catch (e) {
      return this.onError(e);
    }
    this.onScreen(step);
  };

  render() {
    const {
      loading,
      allianceMembersOptions,
      data,
      step,
      initiativesList,
      dealStagesOptions,
      savedAt,
    } = this.state;
    const { dealData, relatedItems, relatedDeals, initiatives } = data;
    const { history } = this.props;
    let content = <Loader stretch />;
    let footer = <></>;
    let buttonsTop = <></>;
    const currency = getCurrencyOnSession();

    if (!loading && step === 0) {
      content = (
        <DealForm
          dealData={dealData}
          onChange={this.onChangeDealData}
          currency={currency}
          allianceMembersOptions={allianceMembersOptions}
          dealStagesOptions={dealStagesOptions}
        />
      );
      footer = (
        <Card.Footer>
          <ActionButton onClick={() => this.onDealStepChange(1)} text={'Next'} />
        </Card.Footer>
      );
    }

    if (!loading && step === 1) {
      const itemData = { id: dealData.id, type: dealData.__typename };

      content = (
        <RelatedItemForm
          relatedItems={relatedItems}
          initiatives={initiativesList}
          onChange={(key, value) => {
            this.onChangeData(key, value);
          }}
          selectedInitiatives={initiatives}
          allowedDealOption={true}
          itemData={itemData}
        />
      );

      footer = (
        <Card.Footer>
          <ActionButton onClick={() => this.onRelatedItemsStepChange(2)} text={'Next'} />
          <TransparentButton onClick={() => this.onScreen(0)} text={'Previous'} />
        </Card.Footer>
      );
    }

    if (!loading && step === 2) {
      const dealDetail = R.clone(dealData);
      const dealStageSelected = dealStagesOptions.find(
        (dealStage) => dealStage.value === dealDetail.stage,
      );
      dealDetail.stage = dealStageSelected ? dealStageSelected.label : null;
      content = (
        <>
          <DealDetailTable dealData={dealDetail} currency={currency} relatedDeals={relatedDeals} />
          <InitiativeListTable initiatives={initiatives} />
          <RelatedItemsDetailTable relatedItems={relatedItems} />
        </>
      );
      footer = (
        <Card.Footer>
          <ActionButton onClick={() => this.onSubmit()} text="Edit Deal" />
          <TransparentButton onClick={() => this.onScreen(1)} text="Previous" />
        </Card.Footer>
      );
    }

    if (!loading)
      buttonsTop = (
        <>
          <div className="company-icons">
            <TransparentButtonSvg
              iconSvg={collaborateIcon}
              onClick={() => openComments(dealData)}
            />
            <ActionButtonClose onClick={history.goBack} />
          </div>
        </>
      );

    return (
      <React.Fragment>
        <Card.Header>
          <Grid.Layout
            columns="200px auto 200px"
            areas={[['left', 'center', 'right']]}
            style={{ width: '100%' }}>
            <Grid.Box area="left">
              <Row>
                <Heading type="h4" text="Edit Deal" />
                <TextDateAgo from={savedAt} />
              </Row>
            </Grid.Box>
            <Grid.Box area="center">
              <FormSteps totalSteps={3} step={step} />
            </Grid.Box>
            <Grid.Box area="right" />
          </Grid.Layout>
          {buttonsTop}
        </Card.Header>
        <CreateViewCardBody>
          <Grid.Layout columns="30% 70%" areas={[['left', 'right']]} style={{ width: '100%' }}>
            <Grid.Box area="left">
              <LeftProgressSection sections={SCREENS_DEALS} currentScreen={step} />
            </Grid.Box>
            <BoxCard>
              <Grid.Box area="right">{content}</Grid.Box>
            </BoxCard>
          </Grid.Layout>
        </CreateViewCardBody>
        {footer}
      </React.Fragment>
    );
  }
}

DealEditView.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default withRouter(DealEditView);
