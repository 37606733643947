import {
  BUSINESS_CASE_UPDATE_MUTATION,
  BUSINESS_CASE_LIST_QUERY,
  BUSINESS_CASE_DETAIL_QUERY,
  BUSINESS_CASE_CREATE_MUTATION,
  RECOMMENDED_SOLUTION_DELETE_MUTATION,
  RECOMMENDED_SOLUTION_UPDATE_MUTATION,
  BUSINESS_CASE_DELETE_MUTATION,
} from './businessCase.queries';
import Flux from '@cobuildlab/flux-state';
import {
  BUSINESS_CASE_LIST_EVENT,
  BUSINESS_CASE_ERROR_EVENT,
  BUSINESS_CASE_CREATE_EVENT,
  BUSINESS_CASE_DETAIL_EVENT,
  BUSINESS_CASE_UPDATE_EVENT,
  BUSINESS_CASE_DELETE_EVENT,
} from './BusinessCase.store';
import sessionStore, { APOLLO_CLIENT, NEW_SESSION_EVENT } from '../../../shared/SessionStore';
import { businessCaseValidator } from './business-case-validators';
import { log, error } from '@cobuildlab/pure-logger';
import * as R from 'ramda';
import { isValidString } from '../../../shared/validators';
import { sanitize8BaseBigInts } from '../../../shared/utils';
import { normalize8baseDocumentDeleteAndUpdate } from '@cobuildlab/8base-utils';

/**
 * To use on other items filter, concat it with the OR array of other items.
 *
 * @param  {string} [search=''] - [description].
 * @returns {Array}  OrToConcat  the OR conditions with the string properties
 * of the businessCase.
 */
export const businessCaseSearchFilterOR = (search = '') => {
  const orToConcat = [
    {
      businessCase: {
        backgroundSummary: { contains: search },
      },
    },
    {
      businessCase: {
        visionMissionStrategy: { contains: search },
      },
    },
    {
      businessCase: {
        marketAnalysis: { contains: search },
      },
    },
    {
      businessCase: {
        salesMarketingStrategy: { contains: search },
      },
    },
    {
      businessCase: {
        risks: { contains: search },
      },
    },
    {
      businessCase: {
        recommendedSolution: { contains: search },
      },
    },
  ];

  return orToConcat;
};

/**
 * Delete a Business Case.
 *
 * @param {string}id - Id.
 * @returns {Promise<void|*>} Promise.
 */
export const deleteBusinessCase = async (id): void => {
  const client = sessionStore.getState(APOLLO_CLIENT);
  let response;
  try {
    response = await client.mutate({
      mutation: BUSINESS_CASE_DELETE_MUTATION,
      variables: { data: { id, force: true } },
    });
  } catch (e) {
    console.error('deleteBusinessCase', e);
    return Flux.dispatchEvent(BUSINESS_CASE_ERROR_EVENT, e);
  }
  console.log('deleteBusinessCase', response);
  Flux.dispatchEvent(BUSINESS_CASE_DELETE_EVENT, response.data);
  return response.data;
};

/**
 * Sanitize expectedCosts, expectedRevenues, anticipatedCosts.
 *
 * @param  {object} businessCase - BusinessCase.
 */
export const sanitizeBusinessExpecteds = (businessCase) => {
  sanitize8BaseBigInts(businessCase, 'expectedCosts');
  sanitize8BaseBigInts(businessCase, 'expectedRevenues');
  sanitize8BaseBigInts(businessCase, 'anticipatedCosts');
};

/**
 * Update a Business Case.
 *
 * @param {object}data - The Updated Business Case.
 * @param {Array}originalRecommendedSolutions - The initial Recommended Solutions.
 * @param {object}originalBusinessCase - The initial business case documents.
 * @returns {Promise<void>}Promise.
 */
export const updateBusinessCase = async (
  data,
  originalRecommendedSolutions,
  originalBusinessCase,
): void => {
  log('updateBusinessCase', data, originalRecommendedSolutions, originalBusinessCase);
  const client = sessionStore.getState(APOLLO_CLIENT);
  delete data.creator;

  normalize8baseDocumentDeleteAndUpdate(data, 'document', originalBusinessCase);

  // Recommended Solutions
  const toBeDeleted = [],
    toBeUpdated = [],
    toBeCreated = [];

  data.recommendedSolutions.forEach((solution) => {
    if (solution.id === undefined) toBeCreated.push(solution);
    else {
      const original = originalRecommendedSolutions.find((original) => original.id === solution.id);
      if (original.description !== solution.description) toBeUpdated.push(solution);
    }
  });

  originalRecommendedSolutions.forEach((solution) => {
    const original = data.recommendedSolutions.find((original) => original.id === solution.id);
    if (original === undefined) toBeDeleted.push(solution);
  });

  data.recommendedSolutions = toBeCreated;
  sanitizeRecommendedSolutions(data);
  sanitizeBusinessExpecteds(data);

  try {
    businessCaseValidator(data);
  } catch (e) {
    error('businessCaseValidator', e);
    Flux.dispatchEvent(BUSINESS_CASE_ERROR_EVENT, e);
    throw e;
  }

  let response;
  try {
    response = await client.mutate({
      mutation: BUSINESS_CASE_UPDATE_MUTATION,
      variables: { data },
    });
  } catch (e) {
    error('updateBusinessCase', e, data);
    Flux.dispatchEvent(BUSINESS_CASE_ERROR_EVENT, e);
    throw e;
  }
  log('updateBusinessCase', response);

  toBeDeleted.forEach(async (solution) => {
    const data = { id: solution.id };
    try {
      response = await client.mutate({
        mutation: RECOMMENDED_SOLUTION_DELETE_MUTATION,
        variables: { data },
      });
    } catch (e) {
      error('updateBusinessCase', e, data);
      return Flux.dispatchEvent(BUSINESS_CASE_ERROR_EVENT, e);
    }
  });

  toBeUpdated.forEach(async (solution) => {
    try {
      response = await client.mutate({
        mutation: RECOMMENDED_SOLUTION_UPDATE_MUTATION,
        variables: { data: solution },
      });
    } catch (e) {
      error('updateBusinessCase', e, data);
      return Flux.dispatchEvent(BUSINESS_CASE_ERROR_EVENT, e);
    }
  });

  Flux.dispatchEvent(BUSINESS_CASE_UPDATE_EVENT, response.data);
  return response.data;
};

/**
 * Fetch a single Business Case.
 *
 * @param {string}id - Business Case id.
 * @returns {Promise<void|*>} Promise.
 */
export const fetchBusinessCase = async (id: number): void => {
  const client = sessionStore.getState(APOLLO_CLIENT);
  let response;
  try {
    response = await client.query({
      query: BUSINESS_CASE_DETAIL_QUERY,
      variables: { id },
      fetchPolicy: 'network-only',
    });
  } catch (e) {
    console.error('fetchBusinessCase', e);
    return Flux.dispatchEvent(BUSINESS_CASE_ERROR_EVENT, e);
  }
  console.log('fetchBusinessCase', response);
  Flux.dispatchEvent(BUSINESS_CASE_DETAIL_EVENT, response.data);
  return response.data;
};

/**
 * Fetches the Business Cases for the User.
 *
 * @returns {Promise<void>} Promise.
 */

export const fetchBusinessCases = async (): void => {
  const client = sessionStore.getState(APOLLO_CLIENT);
  let response;
  try {
    response = await client.query({
      query: BUSINESS_CASE_LIST_QUERY,
      fetchPolicy: 'network-only',
    });
  } catch (e) {
    console.error('fetchBusinessCases', e);
    return Flux.dispatchEvent(BUSINESS_CASE_ERROR_EVENT, e);
  }
  console.log('fetchBusinessCases', response);
  Flux.dispatchEvent(BUSINESS_CASE_LIST_EVENT, response.data);
  return response.data;
};

/**
 * Creates a New Business Case.
 *
 * @returns {Promise<void>} Promise.
 * @param {object}data - Data.
 */
export const createBusinessCase = async (data): void => {
  const client = sessionStore.getState(APOLLO_CLIENT);
  const user = sessionStore.getState(NEW_SESSION_EVENT).user;

  data.creator = { connect: { id: user.id } };
  if (data.document !== null) data.document = { create: data.document };
  else delete data.document;

  sanitizeRecommendedSolutions(data);

  try {
    businessCaseValidator(data);
  } catch (e) {
    error('businessCaseValidator', e);
    return Flux.dispatchEvent(BUSINESS_CASE_ERROR_EVENT, e);
  }

  let response;
  log('createBusinessCases', data);
  try {
    response = await client.mutate({
      mutation: BUSINESS_CASE_CREATE_MUTATION,
      variables: { data },
    });
  } catch (e) {
    error('createBusinessCases', e);
    return Flux.dispatchEvent(BUSINESS_CASE_ERROR_EVENT, e);
  }
  log('createBusinessCases', response);
  Flux.dispatchEvent(BUSINESS_CASE_CREATE_EVENT, response.data);
  return response.data;
};

/**
 * Prepare recommendedSolutions for edit view.
 *
 * @param {object} businessCase - To remove unwanted recommendedSolutions
 * properties.
 * @returns {Array}  To set the originalRecommendedSolutions.
 */
export const sanitizeRecommendedSolutionsToEdit = (businessCase) => {
  const recommendedSolutionsSanitizeData = businessCase.recommendedSolutionsRelation.items.map(
    (solution) => {
      delete solution.__typename;
      return solution;
    },
  );

  businessCase.recommendedSolutions = recommendedSolutionsSanitizeData;
  return R.clone(recommendedSolutionsSanitizeData);
};

/**
 * Prepare for Graphql the Recommended Solutions.
 *
 * @param {object}data - Data.
 */
export const sanitizeRecommendedSolutions = (data) => {
  data.recommendedSolutionsRelation = {
    create: data.recommendedSolutions
      .filter(({ description }) => isValidString(description))
      .map(({ description }) => {
        return { description };
      }),
  };
  delete data.recommendedSolutions;
};
