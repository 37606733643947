import Flux from '@cobuildlab/flux-state';
import {
  ALLIANCE_INVITATION_DELETED_EVENT,
  ALLIANCE_INVITATION_DETAIL_EVENT,
  ALLIANCE_INVITATION_ERROR_EVENT,
  ALLIANCE_INVITATION_NO_COMPANY_ERROR_EVENT,
  ALLIANCE_INVITATION_UPDATED_EVENT,
  ALLIANCE_MEMBER_INVITATION_DETAIL_EVENT,
  ALLIANCE_MEMBER_INVITATION_UPDATED_EVENT,
  COMPANY_INVITATION_ACCEPTED_EVENT,
  COMPANY_INVITATION_DETAIL_EVENT,
  COMPANY_INVITATION_ERROR_EVENT,
  COMPANY_INVITATION_LIST_EVENT,
  COMPANY_INVITATION_REJECTED_EVENT,
  DELETE_ALLIANCE_INVITATION_ERROR_EVENT,
  INVITE_COMPANY_ADMINS_EVENT,
  RESEND_ALLIANCE_MEMBER_INVITATION_EVENT,
  RESEND_COMPANY_MEMBER_INVITATION_EVENT,
  RESEND_ALLIANCE_PARTNER_INVITATION,
  ALLIANCE_INVITATION_ERROR_LOCAL_EVENT,
} from './invitations.store';
import sessionStore, { APOLLO_CLIENT, NEW_SESSION_EVENT } from '../../../shared/SessionStore';
import { areEmailsEqual, trimEmail } from '../../../shared/utils';
import {
  ALLIANCE_INVITATION_DELETE_MUTATION,
  ALLIANCE_INVITATION_QUERY,
  ALLIANCE_MEMBER_INVITATION_QUERY,
  COMPANY_INVITATION_LIST_QUERY,
  COMPANY_INVITATION_QUERY,
  CREATE_COMPANY_USER_MUTATION,
  UPDATE_ALLIANCE_INVITATION_MUTATION,
  UPDATE_ALLIANCE_MEMBER_INVITATION_MUTATION,
  UPDATE_COMPANY_INVITATION_MUTATION,
  RESEND_ALLIANCE_PARTNER_INVITATION_RESOLVER,
} from './invitations.queries';
import { fetchMembersAction } from '../company-management/company-actions';
import { IntegrityError } from '../../../shared/errors';
import { ALLIANCE_UPDATE_MUTATION } from '../alliance-management/alliance-queries';
import { EDIT_COMPANY_MUTATION } from '../company-management/Company.queries';
import {
  createAllianceMember,
  fetchAlliance,
  fetchAllianceMembersAction,
} from '../alliance-management/alliance-actions';
import {
  ALLIANCE_INVITATION_ACCEPTED,
  ALLIANCE_INVITATION_CANCELLED,
  ALLIANCE_INVITATION_REJECTED,
  ALLIANCE_MEMBER_INVITATION_ACCEPTED,
  COMPANY_INVITATION_ACCEPTED,
  COMPANY_INVITATION_REJECTED,
} from '../../../shared/status';
import {
  ALLIANCE_ADMINISTRATOR,
  ALLIANCE_COLLABORATOR,
  COMPANY_ADMINISTRATOR,
  COMPANY_MEMBER,
  COMPANY_PORTFOLIO_OWNER,
} from '../../../shared/roles';
import { error, log } from '@cobuildlab/pure-logger';
import {
  canAcceptAllianceInvitation,
  canAcceptAllianceMemberInvitation,
  canAcceptCompanyInvitation,
  canRejectCompanyInvitation,
} from './invitations-permissions';
import { inviteCompanyAdminsValidator } from './invitations-validators';
import { postData } from '../../../shared/fetch';
import { COMPANY_ERROR_EVENT } from '../company-management/company.store';
import { isCompanyActive } from '../../procurer/procurer-utils';
import {
  OnAllianceInvitationDelete,
  OnAllianceInvitationDeleteError,
  OnAllianceInvitationError,
  OnAllianceResendInvitation,
  OnAllianceResendInvitationMember,
  OnAllianceInvitationMemberUpdated,
  OnAllianceInvitationUpdated,
  OnAllianceInvitationErrorLocal,
  OnAllianceInvitationNoCompanyError,
  OnCompanyInvitationList,
  OnCompanyInvitationError,
  OnCompanyResendInvitation,
  OnInviteCompanyAdmins,
  OnCompanyInvitationAccepted,
  OnCompanyInvitationReject,
} from './invitations-events';

/**
 * Accepts an Alliance Invitation.
 *
 * @param {object}invitation - Invitation.
 * @param {object}companyUser - Company User.
 * @param {string}errorEvent - Error Event.
 * @returns {Promise<void|*>} Promise.
 */
export const acceptAllianceInvitation = async (
  invitation,
  companyUser,
  errorEvent = ALLIANCE_INVITATION_ERROR_EVENT,
) => {
  const client = sessionStore.getState(APOLLO_CLIENT);
  const { user } = sessionStore.getState(NEW_SESSION_EVENT);
  let dispatcherError = OnAllianceInvitationError;

  if (errorEvent === ALLIANCE_INVITATION_ERROR_LOCAL_EVENT) {
    dispatcherError = OnAllianceInvitationErrorLocal;
  }

  // Checks if the client company has an active subscription
  if (!isCompanyActive(invitation.alliance.clientCompany)) {
    dispatcherError.dispatch(new IntegrityError('The Alliance Is not Active'));
    return Flux.dispatchEvent(errorEvent, new IntegrityError('The Alliance Is not Active'));
  }

  if (invitation.status === ALLIANCE_INVITATION_ACCEPTED) {
    dispatcherError.dispatch(new IntegrityError('Alliance Invitation Already Accepted'));
    return Flux.dispatchEvent(
      errorEvent,
      new IntegrityError('Alliance Invitation Already Accepted'),
    );
  }

  if (!areEmailsEqual(user.email, invitation.email)) {
    dispatcherError.dispatch(new IntegrityError('Invitations can only be accepted by their owner'));

    return Flux.dispatchEvent(
      errorEvent,
      new IntegrityError('Invitations can only be accepted by their owner'),
    );
  }

  if (!canAcceptAllianceInvitation(user, invitation)) {
    dispatcherError.dispatch(
      new IntegrityError(`Permission Denied. Can't accept Alliance Invitation`),
    );

    return Flux.dispatchEvent(
      errorEvent,
      new IntegrityError(`Permission Denied. Can't accept Alliance Invitation`),
    );
  }

  // Fetch the User company, to check if it has one
  if (!companyUser) {
    OnAllianceInvitationNoCompanyError.dispatch(
      new IntegrityError('You must create a company in order to accept alliance invitation.'),
    );

    return Flux.dispatchEvent(
      ALLIANCE_INVITATION_NO_COMPANY_ERROR_EVENT,
      new IntegrityError('You must create a company in order to accept alliance invitation.'),
    );
  }

  const companyInformation = companyUser.role.name === COMPANY_PORTFOLIO_OWNER;

  if (companyInformation === false) {
    dispatcherError.dispatch(
      new IntegrityError('Only the Portfolio Owner of a Company can Accept Invitations.'),
    );

    return Flux.dispatchEvent(
      errorEvent,
      new IntegrityError('Only the Portfolio Owner of a Company can Accept Invitations.'),
    );
  }

  // Update Invitation to status ACCEPTED
  const invitationData = {
    id: invitation.id,
    status: ALLIANCE_INVITATION_ACCEPTED,
    dateOfResponse: new Date(),
  };

  let response;
  try {
    response = await client.mutate({
      mutation: UPDATE_ALLIANCE_INVITATION_MUTATION,
      variables: { data: invitationData },
    });
  } catch (e) {
    error('acceptAllianceInvitation', e);
    dispatcherError.dispatch(e);
    return Flux.dispatchEvent(errorEvent, e);
  }

  // get the Alliance ID of the Invitation
  const allianceId = invitation.alliance.id;
  const companyId = companyUser.company.id;
  const allianceData = {
    id: allianceId,
    // Join the Company to the Alliance
    partnerCompany: {
      connect: { id: companyId },
    },
    // And the User as an Administrator to the Alliance
    allianceUserAllianceRelation: {
      create: [
        {
          companyUser: { connect: { id: companyUser.id } },
          role: { connect: { name: ALLIANCE_ADMINISTRATOR } },
        },
      ],
    },
  };
  try {
    await client.mutate({
      mutation: ALLIANCE_UPDATE_MUTATION,
      variables: { data: allianceData },
    });
  } catch (e) {
    error('acceptAllianceInvitation', e);
    console.log(e);
    dispatcherError.dispatch(e);
    return Flux.dispatchEvent(errorEvent, e);
  }

  // Cancel Every Other Invitation to that Alliance
  const allianceFetch = await fetchAlliance(allianceId);
  const { allianceInvitationRelation } = allianceFetch.alliance;
  const otherInvitations = allianceInvitationRelation.items.filter(
    (allianceInvitation) => invitation.id !== allianceInvitation.id,
  );
  otherInvitations.forEach(async (otherInvitation) => {
    try {
      await client.mutate({
        mutation: UPDATE_ALLIANCE_INVITATION_MUTATION,
        variables: {
          data: {
            id: otherInvitation.id,
            status: ALLIANCE_INVITATION_CANCELLED,
            dateOfResponse: new Date(),
          },
        },
      });
    } catch (e) {
      error('acceptAllianceInvitation', e);
      dispatcherError.dispatch(e);
      return Flux.dispatchEvent(errorEvent, e);
    }
  });

  // try {
  //   await addPortfolioOwnersAsAllianceCollaborator(allianceId, companyId, roles);
  // } catch (e) {
  //   error('addPortfolioOwnersAsAllianceCollaborator', e);
  //   return Flux.dispatchEvent(ALLIANCE_INVITATION_ERROR_EVENT, e);
  // }

  // Update Alliance with the partnerCompany
  OnAllianceInvitationUpdated.dispatch([response.data, allianceId]);
  Flux.dispatchEvent(ALLIANCE_INVITATION_UPDATED_EVENT, [response.data, allianceId]);
  return response.data;
};

/**
 * Rejects an Alliance Invitation.
 *
 * @param {string}id - Id.
 * @returns {Promise<void|*>}Promise.
 */
export const rejectAllianceInvitation = async ({ id }) => {
  const client = sessionStore.getState(APOLLO_CLIENT);
  const data = {
    id,
    status: ALLIANCE_INVITATION_REJECTED,
    dateOfResponse: new Date(),
  };
  let response;
  try {
    response = await client.mutate({
      mutation: UPDATE_ALLIANCE_INVITATION_MUTATION,
      variables: { data },
    });
  } catch (e) {
    error('rejectAllianceInvitation', e);
    return Flux.dispatchEvent(ALLIANCE_INVITATION_ERROR_EVENT, e);
  }
  log('rejectAllianceInvitation', response);
  Flux.dispatchEvent(ALLIANCE_INVITATION_UPDATED_EVENT, response.data);
  return response.data;
};

/**
 * Add portfolio owners of the company as alliance collaborators of the alliance.
 * Used on alliance creation & allianceInvitation accept to add the company.
 * Portfolio owners as alliance collaborator of that alliance.
 *
 * @param {string}allianceId - Alliance Id.
 * @param {string}companyId - Company Id.
 * @param {Array}roles - To avoid another query on roles.
 * @returns {Promise<[]>}Promise.
 */
export const addPortfolioOwnersAsAllianceCollaborator = async (allianceId, companyId, roles) => {
  log('addPortfolioOwnersAsAllianceCollaborator', allianceId, companyId, roles);
  const client = sessionStore.getState(APOLLO_CLIENT);
  const allianceCollaboratorRole = roles.find((role) => role.name === ALLIANCE_COLLABORATOR);
  const {
    companyUsersList: { items: companyMembers },
  } = await fetchMembersAction(companyId, false);

  const { allianceUserAllianceRelation } = await fetchAllianceMembersAction(allianceId);

  log(
    'addPortfolioOwnersAsAllianceCollaborator:MembersOfCompany',
    companyMembers,
    allianceUserAllianceRelation,
  );
  const companyMembersToAdd = companyMembers.filter((member) => {
    if (member.role.name !== COMPANY_PORTFOLIO_OWNER) return false;

    // Check if the User already belongs to the Alliance
    for (const allianceUser of allianceUserAllianceRelation.items) {
      if (allianceUser.companyUser.user.id === member.user.id) return false;
    }

    return true;
  });
  log('addPortfolioOwnersAsAllianceCollaborator:MembersToAdd', companyMembersToAdd);

  const createMemberPromise = async (member) => {
    const allianceData = {
      id: allianceId,
      allianceUserAllianceRelation: {
        create: [
          {
            companyUser: { connect: { id: member.id } },
            role: { connect: { id: allianceCollaboratorRole.id } },
          },
        ],
      },
    };

    let response;
    try {
      response = await client.mutate({
        mutation: ALLIANCE_UPDATE_MUTATION,
        variables: { data: allianceData },
      });
      log('addPortfolioOwnerAsAllianceCollaborator', response);
    } catch (e) {
      throw e;
    }

    return response;
  };

  const responses = await Promise.all(companyMembersToAdd.map(createMemberPromise));

  return responses;
};

/**
 * Delete an Alliance Invitation.
 *
 * @param {object}invitation - Invitation.
 * @returns {Promise<void>} Promise.
 */
export const deleteAllianceInvitation = async (invitation) => {
  const client = sessionStore.getState(APOLLO_CLIENT);

  if (invitation.status === ALLIANCE_INVITATION_ACCEPTED) {
    OnAllianceInvitationDeleteError.dispatch(
      new IntegrityError("You can't delete a Invitation that has been Accepted"),
    );

    return Flux.dispatchEvent(
      DELETE_ALLIANCE_INVITATION_ERROR_EVENT,
      new IntegrityError("You can't delete a Invitation that has been Accepted"),
    );
  }

  let response;

  try {
    response = await client.mutate({
      mutation: ALLIANCE_INVITATION_DELETE_MUTATION,
      variables: { data: { id: invitation.id } },
    });
  } catch (e) {
    error('deleteAllianceInvitation', e);
    OnAllianceInvitationDeleteError.dispatch(e);
    return Flux.dispatchEvent(DELETE_ALLIANCE_INVITATION_ERROR_EVENT, e);
  }

  log('deleteAllianceInvitation', response);
  OnAllianceInvitationDelete.dispatch(response.data);
  Flux.dispatchEvent(ALLIANCE_INVITATION_DELETED_EVENT, response.data);
  return response.data;
};

/**
 * Fetch a single Alliance Invitation.
 *
 * @param  {string}  id -   The invitation id.
 * @returns {Promise}Promise.
 */
export const fetchAllianceInvitation = async (id) => {
  const client = sessionStore.getState(APOLLO_CLIENT);
  let response;
  try {
    response = await client.query({
      query: ALLIANCE_INVITATION_QUERY,
      variables: { id },
      fetchPolicy: 'network-only',
    });
  } catch (e) {
    error('fetchAllianceInvitation', e);
    return Flux.dispatchEvent(ALLIANCE_INVITATION_ERROR_EVENT, e);
  }

  log('fetchAllianceInvitation', response);
  Flux.dispatchEvent(ALLIANCE_INVITATION_DETAIL_EVENT, response.data);
  return response.data;
};

/**
 * Fetch a single Alliance Membership Invitation.
 *
 * @param  {string}  id -   The invitation id.
 * @returns {Promise}Promise.
 */
export const fetchAllianceMemberInvitation = async (id) => {
  const client = sessionStore.getState(APOLLO_CLIENT);
  console.log('fetchAllianceMemberInvitation', id);
  let response;
  try {
    response = await client.query({
      query: ALLIANCE_MEMBER_INVITATION_QUERY,
      variables: { id },
      fetchPolicy: 'network-only',
    });
  } catch (e) {
    error('fetchAllianceMemberInvitation', e);
    return Flux.dispatchEvent(ALLIANCE_INVITATION_ERROR_EVENT, e);
  }

  log('fetchAllianceMemberInvitation', response);
  Flux.dispatchEvent(ALLIANCE_MEMBER_INVITATION_DETAIL_EVENT, response.data);
  return response.data;
};

/**
 * Create a company user.
 *
 * @param  {string}  companyId - Company Id.
 * @param  {string}  roleId - Role Id.
 * @param  {string}  userId - User Id.
 * @returns {Promise<*>}Promise.
 */
export const createCompanyMember = async (companyId, roleId, userId) => {
  const client = sessionStore.getState(APOLLO_CLIENT);
  log('createCompanyMember: ', companyId, roleId, userId);

  const companyUserData = {
    company: {
      connect: { id: companyId },
    },
    role: {
      connect: { id: roleId },
    },
    user: {
      connect: { id: userId },
    },
  };

  let companyUserResponse;
  try {
    companyUserResponse = await client.mutate({
      mutation: CREATE_COMPANY_USER_MUTATION,
      variables: { data: companyUserData },
    });
  } catch (e) {
    error('createCompanyMember', e);
    Flux.dispatchEvent(ALLIANCE_INVITATION_ERROR_EVENT, e);
    throw e;
  }

  return companyUserResponse.data;
};
/**
 * Create a company user.
 *
 * @param {string} companyId - Company Id.
 * @param {string} roleName - Role name.
 * @param {string} userId - User Id.
 * @returns {Promise<*>} Promise.
 */
export const createCompanyMemberByName = async (companyId, roleName, userId) => {
  const client = sessionStore.getState(APOLLO_CLIENT);
  log('createCompanyMember: ', companyId, roleName, userId);

  const companyUserData = {
    company: {
      connect: { id: companyId },
    },
    role: {
      connect: { name: roleName },
    },
    user: {
      connect: { id: userId },
    },
  };

  let companyUserResponse;
  try {
    companyUserResponse = await client.mutate({
      mutation: CREATE_COMPANY_USER_MUTATION,
      variables: { data: companyUserData },
    });
  } catch (e) {
    error('createCompanyMember', e);
    Flux.dispatchEvent(ALLIANCE_INVITATION_ERROR_EVENT, e);
    throw e;
  }

  return companyUserResponse.data;
};

/**
 * Accepts an Alliance Member Invitation.
 *
 * @param {object}invitation - The Alliance Member Invitation to be accepted.
 * @returns {Promise<void|*>}Promise.
 */
export const acceptAllianceMemberInvitation = async (invitation) => {
  log('acceptAllianceMemberInvitation', invitation);
  const client = sessionStore.getState(APOLLO_CLIENT);
  const { user } = sessionStore.getState(NEW_SESSION_EVENT);
  const { companyUserRelation } = user;
  const companyId = invitation.company.id;
  const companyUsers = companyUserRelation.items;
  // To find if the user belongs to the company of the invitation
  const sameCompanyUser = companyUsers.find(({ company }) => company.id === companyId);

  const canAcceptInvitation = canAcceptAllianceMemberInvitation(user, invitation);

  if (!canAcceptInvitation.success) {
    const e = new IntegrityError(canAcceptInvitation.message);
    error('acceptAllianceMemberInvitation', e);
    OnAllianceInvitationError.dispatch(e);
    return Flux.dispatchEvent(ALLIANCE_INVITATION_ERROR_EVENT, e);
  }

  // Update Invitation to status ACCEPTED
  const invitationData = {
    id: invitation.id,
    status: ALLIANCE_MEMBER_INVITATION_ACCEPTED,
    dateOfResponse: new Date(),
  };

  let response;
  try {
    response = await client.mutate({
      mutation: UPDATE_ALLIANCE_MEMBER_INVITATION_MUTATION,
      variables: { data1: invitationData, data2: { id: user.id, didInviteAdmins: true } },
    });
  } catch (e) {
    error('acceptAllianceMemberInvitation', e);
    OnAllianceInvitationError.dispatch(e);
    return Flux.dispatchEvent(ALLIANCE_INVITATION_ERROR_EVENT, e);
  }

  let companyUserId;
  if (!sameCompanyUser) {
    // set the invitation company to the user
    let roleName = COMPANY_MEMBER;
    if (invitation.companyRole) {
      roleName = invitation.companyRole.name;
    }

    let companyUserResponse;
    try {
      companyUserResponse = await createCompanyMemberByName(companyId, roleName, user.id);
    } catch (e) {
      error('setUserCompany', e);
      OnAllianceInvitationError.dispatch(e);
      return Flux.dispatchEvent(ALLIANCE_INVITATION_ERROR_EVENT, e);
    }
    log('setUserCompany', companyUserResponse);
    companyUserId = companyUserResponse.companyUserCreate.id;
  } else companyUserId = sameCompanyUser.id;

  // get the Alliance and role ID of the Invitation
  const allianceId = invitation.alliance.id;
  const allianceRoleId = invitation.role.id;
  try {
    await createAllianceMember(companyUserId, allianceRoleId, allianceId);
  } catch (e) {
    error('acceptAllianceMemberInvitation', e);
    OnAllianceInvitationError.dispatch(e);
    return Flux.dispatchEvent(ALLIANCE_INVITATION_ERROR_EVENT, e);
  }

  log('acceptAllianceMemberInvitation', response);
  OnAllianceInvitationMemberUpdated.dispatch(response.data);
  Flux.dispatchEvent(ALLIANCE_MEMBER_INVITATION_UPDATED_EVENT, response.data);
  return response.data;
};

/**
 * Fetch a Company Invitation.
 *
 * @param  {string} id - The invitation id.
 * @returns {Promise} The invitation.
 */
export const fetchCompanyInvitation = async (id) => {
  const client = sessionStore.getState(APOLLO_CLIENT);
  console.log('fetchCompanyInvitation', id);
  let response;
  try {
    response = await client.query({
      query: COMPANY_INVITATION_QUERY,
      variables: { id },
      fetchPolicy: 'network-only',
    });
  } catch (e) {
    error('fetchCompanyInvitation', e);
    return Flux.dispatchEvent(ALLIANCE_INVITATION_ERROR_EVENT, e);
  }

  log('fetchCompanyInvitation', response);
  Flux.dispatchEvent(COMPANY_INVITATION_DETAIL_EVENT, response.data);
  return response.data;
};

/**
 * Accepts a Company Invitation.
 *
 * @param {object} invitation - The Company Invitation to be accepted.
 * @returns {Promise} The accepted invitation.
 */
export const acceptCompanyInvitationWithoutDispatch = async (invitation) => {
  const client = sessionStore.getState(APOLLO_CLIENT);
  const { user } = sessionStore.getState(NEW_SESSION_EVENT);
  const {
    company: { id: companyId },
    role: { id: companyRoleId },
  } = invitation;

  if (invitation.status === COMPANY_INVITATION_ACCEPTED) {
    throw new IntegrityError('COMPANY Invitation Already Accepted');
  }

  if (!areEmailsEqual(user.email, invitation.email)) {
    throw new IntegrityError('Invitations can only be accepted by their owner');
  }

  if (!canAcceptCompanyInvitation(user, invitation)) {
    throw new IntegrityError(`Permision Denied. Can't accept Company Invitation`);
  }

  // Update Invitation to status ACCEPTED
  const invitationData = {
    id: invitation.id,
    status: COMPANY_INVITATION_ACCEPTED,
    dateOfResponse: new Date(),
  };

  let response;
  try {
    response = await client.mutate({
      mutation: UPDATE_COMPANY_INVITATION_MUTATION,
      variables: { data: invitationData },
    });
  } catch (e) {
    error('acceptCompanyInvitation', e);
    throw e;
  }

  try {
    await createCompanyMember(companyId, companyRoleId, user.id);
  } catch (e) {
    error('setUserCompany', e);
    throw e;
  }

  log('acceptCompanyInvitation', response);
  return response.data;
};

/**
 * Accepts a Company Invitation.
 *
 * @param {object} invitation - The Company Invitation to be accepted.
 * @returns {Promise} The accepted invitation.
 */
export const acceptCompanyInvitation = async (invitation) => {
  let response;
  try {
    response = await acceptCompanyInvitationWithoutDispatch(invitation);
  } catch (e) {
    OnAllianceInvitationError.dispatch(e);
    return Flux.dispatchEvent(ALLIANCE_INVITATION_ERROR_EVENT, e);
  }

  OnCompanyInvitationAccepted.dispatch(response.data);
  Flux.dispatchEvent(COMPANY_INVITATION_ACCEPTED_EVENT, response.data);
  return response;
};

/**
 * Rejects a Company Invitation.
 *
 * @param {object} invitation - The Company Invitation to be rejected.
 * @returns {Promise} The rejected invitation.
 */
export const rejectCompanyInvitation = async (invitation) => {
  const client = sessionStore.getState(APOLLO_CLIENT);
  const { user } = sessionStore.getState(NEW_SESSION_EVENT);
  const { id: invitationId } = invitation;

  if (invitation.status === COMPANY_INVITATION_REJECTED) {
    OnAllianceInvitationError.dispatch(new IntegrityError('COMPANY Invitation Already Rejected'));

    return Flux.dispatchEvent(
      ALLIANCE_INVITATION_ERROR_EVENT,
      new IntegrityError('COMPANY Invitation Already Rejected'),
    );
  }

  if (!areEmailsEqual(user.email, invitation.email)) {
    OnAllianceInvitationError.dispatch(
      new IntegrityError('Invitations can only be rejected by their owner'),
    );

    return Flux.dispatchEvent(
      ALLIANCE_INVITATION_ERROR_EVENT,
      new IntegrityError('Invitations can only be rejected by their owner'),
    );
  }

  if (!canRejectCompanyInvitation(user, invitation)) {
    OnAllianceInvitationError.dispatch(
      new IntegrityError(`Permision Denied. Can't reject Company Invitation`),
    );

    return Flux.dispatchEvent(
      ALLIANCE_INVITATION_ERROR_EVENT,
      new IntegrityError(`Permision Denied. Can't reject Company Invitation`),
    );
  }

  // Update Invitation to status REJECTED
  const invitationData = {
    id: invitationId,
    status: COMPANY_INVITATION_REJECTED,
    dateOfResponse: new Date(),
  };

  let response;
  try {
    response = await client.mutate({
      mutation: UPDATE_COMPANY_INVITATION_MUTATION,
      variables: { data: invitationData },
    });
  } catch (e) {
    error('rejectCompanyInvitation', e);
    throw e;
  }

  log('rejectCompanyInvitation', response);
  OnCompanyInvitationReject.dispatch(response.data);
  Flux.dispatchEvent(COMPANY_INVITATION_REJECTED_EVENT, response.data);
  return response.data;
};

/**
 * Invite company admins.
 *
 * @param  {string} companyId - The company id.
 * @param  {Array} adminEmails - Email to invite the admins.
 * @returns {Promise} The invited admins.
 */
export const inviteCompanyAdmins = async (companyId, adminEmails) => {
  const client = sessionStore.getState(APOLLO_CLIENT);
  adminEmails = adminEmails.map(trimEmail);

  try {
    inviteCompanyAdminsValidator(adminEmails, companyId);
  } catch (e) {
    OnAllianceInvitationError.dispatch(e);
    return Flux.dispatchEvent(ALLIANCE_INVITATION_ERROR_EVENT, e);
  }

  const data = {
    id: companyId,
    companyInvitationRelation: {
      create: adminEmails.map((email) => {
        return {
          email,
          role: { connect: { name: COMPANY_ADMINISTRATOR } },
        };
      }),
    },
  };

  let response;
  try {
    response = await client.mutate({
      mutation: EDIT_COMPANY_MUTATION,
      variables: { data },
    });
  } catch (e) {
    error('inviteCompanyAdmins', e);
    OnAllianceInvitationError.dispatch(e);
    return Flux.dispatchEvent(ALLIANCE_INVITATION_ERROR_EVENT, e);
  }

  OnInviteCompanyAdmins.dispatch(response.data);
  Flux.dispatchEvent(INVITE_COMPANY_ADMINS_EVENT, response.data);
  return response.data;
};

/**
 * Resend Alliance member Invitation email.
 *
 * @param {object}invitation - Invitation.
 * @returns {Promise<void|*>}Promise.
 */
export const resendAllianceMemberInvitation = async (invitation) => {
  const { id: invitationId } = invitation;
  const url = `${invitationId}/resend-alliance-invitation`;

  console.log('resendAllianceMemberInvitation invitation: ', invitation);

  let response;
  try {
    response = await postData(url);
  } catch (e) {
    console.log('resendAllianceMemberInvitation Error:', e);
    OnAllianceInvitationError.dispatch(e);
    return Flux.dispatchEvent(ALLIANCE_INVITATION_ERROR_EVENT, e);
  }

  console.log('resendAllianceMemberInvitation Response: ', response);
  OnAllianceResendInvitationMember.dispatch(response);
  Flux.dispatchEvent(RESEND_ALLIANCE_MEMBER_INVITATION_EVENT, response);
  return response;
};

/**
 * Resend Alliance Partner Invitation email.
 *
 * @param {object}invitation - Invitation.
 * @returns {Promise<void|*>}Promise.
 */
export const resendAlliancePartnerInvitation = async (invitation) => {
  const { id } = invitation;
  const client = sessionStore.getState(APOLLO_CLIENT);

  console.log('resendAlliancePartnerInvitation invitation: ', invitation);

  let response;
  try {
    response = await client.mutate({
      mutation: RESEND_ALLIANCE_PARTNER_INVITATION_RESOLVER,
      variables: { id },
    });
  } catch (e) {
    console.log('resendAlliancePartnerInvitation Error:', e);
    OnAllianceInvitationError.dispatch(e);
    return Flux.dispatchEvent(ALLIANCE_INVITATION_ERROR_EVENT, e);
  }

  console.log('resendAlliancePartnerInvitation Response: ', response);
  OnAllianceResendInvitation.dispatch(response);
  Flux.dispatchEvent(RESEND_ALLIANCE_PARTNER_INVITATION, response);
  return response;
};

/**
 * Resend Company Member Invitation email.
 *
 * @param {object}invitation - Invitation.
 * @returns {Promise<void|*>} Promise.
 */
export const resendCompanyMemberInvitation = async (invitation) => {
  const { id: invitationId } = invitation;
  const url = `${invitationId}/resend-company-invitation`;

  console.log('resendCompanyMemberInvitation invitation: ', invitation);

  let response;
  try {
    response = await postData(url);
  } catch (e) {
    console.log('resendCompanyMemberInvitation Error:', e);
    OnCompanyInvitationError.dispatch(e);
    return Flux.dispatchEvent(COMPANY_INVITATION_ERROR_EVENT, e);
  }

  console.log('resendCompanyMemberInvitation Response: ', response);
  OnCompanyResendInvitation.dispatch(response);
  Flux.dispatchEvent(RESEND_COMPANY_MEMBER_INVITATION_EVENT, response);
  return response;
};

/**
 * Fetch Company Member Invitations List.
 *
 * @returns {Promise<void|*>} Promise.
 */
export const fetchCompanyInvitationList = async () => {
  const user = sessionStore.getState(NEW_SESSION_EVENT).user;
  const client = sessionStore.getState(APOLLO_CLIENT);
  const companyId = user.companyUserRelation.items[0].company.id;

  const filter = {
    company: {
      id: {
        equals: companyId,
      },
    },
  };

  let response;

  try {
    response = await client.mutate({
      mutation: COMPANY_INVITATION_LIST_QUERY,
      variables: { filter },
    });
  } catch (e) {
    console.error('fetchCompanyInvitations', e);
    return Flux.dispatchEvent(COMPANY_ERROR_EVENT, e);
  }

  OnCompanyInvitationList.dispatch(response);
  Flux.dispatchEvent(COMPANY_INVITATION_LIST_EVENT, response);
  return response;
};
