import React from 'react';
import { Card, Table, Dropdown, Menu, Heading, Icon, Grid, Loader, Row } from '@8base/boost';
import { DropdownBodyOnTable } from '../../../components/dropdown/DropdownBodyOnTable';
import { ListCardBody } from '../../../components/card/ListCardBody';
import * as toast from '../../../components/toast/Toast';
import * as R from 'ramda';
import { ActionButtonListView } from '../../../components/buttons/ActionButtonListView';
import { Link } from 'react-router-dom';
import { fetchAMOItems, deleteAMOItem, fetchAllAMOItems } from './amo-item-actions';
import { View } from '@cobuildlab/react-simple-state';
import YesNoDialog from '../../../components/dialogs/YesNoDialog';
import PropTypes from 'prop-types';
import sessionStore, { NEW_SESSION_EVENT } from '../../../shared/SessionStore';
import Status from '../../../components/Status';
import { onErrorMixin, onListScrollMixin } from '../../../shared/mixins';
import { getAMOItemType } from './index';
import OptionsDialog from '../../../components/dialogs/OptionsDialog';
import { withRouter } from 'react-router-dom';
import withAlliance from '../../../components/hoc/withAlliance';
import {
  canCreateAMOItem,
  canDeleteAMOItem,
  canEditAMOItem,
  canSubmitForApprovalAMOItem,
} from './amo-item-permissions';
import actionStore, {
  ACTION_SUBMIT_FOR_APPROVAL_EVENT,
  ACTION_ERROR_EVENT,
} from '../action/action-store';
import decisionStore, {
  DECISION_UPDATE_EVENT,
  DECISION_ERROR_EVENT,
} from '../decision/decision-store';
import SearchInput from '../../../components/inputs/SearchInput';
import { debounce, generatedExcel } from '../../../shared/utils';
import DetailDateValue from '../../../components/DetailDateValue';
import { AMO_ITEMS_STATUS_LABELS, AMO_ITEM_IN_PROGRESS } from '../../../shared/status';
import ItemFilter from '../../../components/ItemFilter';
import { AMO_ITEMS_LABELS } from '../../../shared/items-util';
import { DateText } from '../../../components/DateText';
import { Body } from '../../../components/new-ui/font-style/Body';
import { Small1 } from '../../../components/new-ui/font-style/Small1';
import { MoreActionButton } from '../../../components/buttons/MoreActionButton';
import { COLUMNSEXCEL, COLUMNSOBJECT } from './amo-item-model';
import {
  OnAmoItemList,
  OnAmoItemError,
  OnAmoItemDelete,
  OnAmoItemFullList,
} from './amo-item-events';

// eslint-disable-next-line
/**
 * List All the AMO Items.
 *
 */
class AMOItemListView extends View {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      loading: true,
      loadingPage: false,
      item: null,
      deleteModalIsOpen: false,
      createModalIsOpen: false,
      submitForApprovalModalIsOpen: false,
      approvalFunction: null,
      search: '',
      page: 1,
      count: 0,
      filter: null,
      itemFilter: null,
      initiativeFilter: null,
    };
    this.user = sessionStore.getState(NEW_SESSION_EVENT).user;
    this.selectedAlliance = sessionStore.getState(NEW_SESSION_EVENT).selectedAlliance;
    this.onError = onErrorMixin.bind(this);
    this.onListScroll = onListScrollMixin.bind(this);
    this.searchWithDebounce = debounce(300, this.searchWithDebounce)();
  }

  componentDidMount() {
    this.subscribe(OnAmoItemError, this.onError);
    this.subscribe(OnAmoItemList, (state) => {
      const { count } = R.clone(state.itemsList);
      const newItemsList = state.itemsList.items.map((item) => ({ ...item }));
      const { loadingPage, items: oldItemsList } = this.state;
      const items = loadingPage ? oldItemsList.concat(newItemsList) : newItemsList;
      this.setState({
        items,
        count,
        loading: false,
        loadingPage: false,
      });
    });

    this.subscribe(OnAmoItemDelete, () => {
      const { search, filter, itemFilter, initiativeFilter } = this.state;

      this.setState({ page: 1 }, () => {
        toast.success('AMO Item Deleted!');
        fetchAMOItems(search, 1, 20, filter, itemFilter, initiativeFilter);
      });
    });

    this.subscribe(actionStore, ACTION_SUBMIT_FOR_APPROVAL_EVENT, () => {
      const { search, filter, itemFilter, initiativeFilter } = this.state;

      this.setState({ page: 1 }, () => {
        toast.success('Action Submitted For Approval!');
        fetchAMOItems(search, 1, 20, filter, itemFilter, initiativeFilter);
      });
    });
    this.subscribe(actionStore, ACTION_ERROR_EVENT, this.onError);
    this.subscribe(decisionStore, DECISION_ERROR_EVENT, this.onError);
    this.subscribe(decisionStore, DECISION_UPDATE_EVENT, () => {
      const { search, filter, itemFilter, initiativeFilter } = this.state;
      this.setState({ page: 1 }, () => {
        toast.success('Decision Submitted For Approval!');
        fetchAMOItems(search, 1, 20, filter, itemFilter, initiativeFilter);
      });
    });

    this.subscribe(OnAmoItemFullList, (state) => {
      const items = state.itemsList.items.map((item) => ({
        ...item,
      }));

      generatedExcel(items, COLUMNSOBJECT, COLUMNSEXCEL, 'AMOITEMS');
      this.setState({ loading: false });
    });

    const searchParams = new URLSearchParams(this.props.location.search);

    if (searchParams.get('due') || searchParams.get('item') || searchParams.get('initiative')) {
      fetchAMOItems(
        '',
        1,
        20,
        searchParams.get('due'),
        searchParams.get('item'),
        searchParams.get('initiative'),
      );
      this.setState({
        filter: searchParams.get('due'),
        initiativeFilter: searchParams.get('initiative'),
        itemFilter: searchParams.get('item'),
      });
    } else {
      const fetchPolicyOptions = { isCacheFirst: true };
      fetchAMOItems('', 1, 20, null, null, null, fetchPolicyOptions);
    }
  }

  onSelectForDelete = (item) => {
    const amoItem = getAMOItemType(item);
    const [canDelete, errors] = canDeleteAMOItem(this.user, amoItem, this.selectedAlliance);
    if (canDelete) {
      this.setState({
        item,
        deleteModalIsOpen: true,
      });
    } else {
      toast.errors(errors);
    }
  };

  onSelectForSubmitForApproval = (item, approvalFunction) => {
    const [canSubmit, errors] = canSubmitForApprovalAMOItem(this.user, item, this.selectedAlliance);
    if (canSubmit) {
      this.setState({
        item,
        submitForApprovalModalIsOpen: true,
        approvalFunction,
      });
    } else {
      toast.errors(errors);
    }
  };

  onYes = () => {
    this.setState(
      {
        deleteModalIsOpen: false,
        loading: true,
      },
      () => deleteAMOItem(this.state.item),
    );
  };

  onYesSubmitForApproval = () => {
    this.setState(
      {
        submitForApprovalModalIsOpen: false,
        loading: true,
      },
      () => this.state.approvalFunction(this.state.item),
    );
  };

  onClose = () => {
    this.setState({
      deleteModalIsOpen: false,
      submitForApprovalModalIsOpen: false,
    });
  };

  onSearchChange = (value) => {
    this.setState({ search: value });
    this.searchWithDebounce(value);
  };

  searchWithDebounce = (value) => {
    const { filter, itemFilter } = this.state;
    this.setState({ loading: true, page: 1 }, () => {
      fetchAMOItems(value, 1, 20, filter, itemFilter);
    });
  };

  filter = (value) => {
    const { itemFilter } = this.state;
    this.setState(
      {
        loading: true,
        filter: value,
        page: 1,
      },
      () => {
        fetchAMOItems('', 1, 20, value, itemFilter);
      },
    );
  };
  itemFilter = (value) => {
    const { filter } = this.state;
    this.setState(
      {
        loading: true,
        itemFilter: value,
        page: 1,
      },
      () => {
        fetchAMOItems('', 1, 20, filter, value);
      },
    );
  };

  exportExcel = () => {
    this.setState({ loading: true });
    fetchAllAMOItems();
  };

  render() {
    const {
      deleteModalIsOpen,
      createModalIsOpen,
      items,
      submitForApprovalModalIsOpen,
      search,
      loading,
      loadingPage,
      filter,
      itemFilter,
    } = this.state;
    const alliance = this.selectedAlliance;
    const { history } = this.props;

    return (
      <div className="items-card">
        <Card.Header>
          <Grid.Layout
            columns="115px auto 150px 132px 38px"
            areas={[['left', 'center', 'right', 'right2', 'right3']]}
            style={{ width: '100%' }}>
            <Grid.Box justifySelf="flex-start" area="left" className="list-box">
              <Heading type="h4" text="Office Items" />
            </Grid.Box>
            <Grid.Box justifySelf="center" area="center" className="list-box">
              <SearchInput className="search-input" value={search} onChange={this.onSearchChange} />
            </Grid.Box>
            <Grid.Box area="right" className="list-box">
              <ItemFilter
                onChange={this.filter}
                value={filter}
                options={AMO_ITEMS_STATUS_LABELS}
                placeholder="Filter By Status"
              />
            </Grid.Box>
            <Grid.Box area="right2" className="list-box">
              <ItemFilter
                onChange={this.itemFilter}
                value={itemFilter}
                options={AMO_ITEMS_LABELS}
                placeholder="Select Item"
              />
            </Grid.Box>
            <Grid.Box area="right3" className="list-box" justifySelf="flex-end">
              <Dropdown defaultOpen={false} style={{ position: 'absolute', right: '-14px' }}>
                <Dropdown.Head>
                  <MoreActionButton />
                </Dropdown.Head>
                <Dropdown.Body pin="right">
                  {({ closeDropdown }) => (
                    <Menu>
                      <Menu.Item
                        onClick={() => {
                          this.exportExcel();
                          closeDropdown();
                        }}>
                        Export to Excel
                      </Menu.Item>
                    </Menu>
                  )}
                </Dropdown.Body>
              </Dropdown>
            </Grid.Box>
          </Grid.Layout>
        </Card.Header>
        <ListCardBody className="items-table">
          <Table>
            <Table.Header
              className="justify-center-column"
              columns="230px 230px 230px 230px 230px 230px 230px">
              <Table.HeaderCell>Item Type</Table.HeaderCell>
              <Table.HeaderCell>State</Table.HeaderCell>
              <Table.HeaderCell className="name-column">Name</Table.HeaderCell>
              <Table.HeaderCell>Due Date</Table.HeaderCell>
              <Table.HeaderCell>Requested By</Table.HeaderCell>
              <Table.HeaderCell>Options</Table.HeaderCell>
              <Table.HeaderCell>
                Approvals <br />
                CLIENT / PARTNER{' '}
              </Table.HeaderCell>
            </Table.Header>
            <Table.Body
              onScroll={(event) => this.onListScroll(event, items, fetchAMOItems)}
              loading={loading}
              data={items}
              className="card-body-list">
              {(item, index) => {
                const amoItem = getAMOItemType(item);
                const {
                  id,
                  url,
                  name,
                  requestedBy,
                  approvalFunction,
                  revisedDueDate,
                  status,
                  type,
                  approvalItems,
                  createdBy,
                } = amoItem;
                const isLast = index === items.length - 1;
                const pageLoader = isLast && loadingPage ? <Loader stretch /> : null;
                const user = requestedBy ? requestedBy : createdBy;
                return (
                  <>
                    <Table.BodyRow columns="240px 230px 230px 250px 210px 230px 400px" key={id}>
                      <Table.BodyCell className=" name-column table-cell">
                        <Link className="item-name " to={`/management/${url}/${id}/`}>
                          {type}
                        </Link>
                      </Table.BodyCell>
                      <Table.BodyCell>
                        <span>{status}</span>
                      </Table.BodyCell>
                      <Table.BodyCell className="name-column table-cell">
                        <Link className="item-name" to={`/management/${url}/${id}/`}>
                          {name}
                        </Link>
                      </Table.BodyCell>
                      <Table.BodyCell className="table-cell">
                        <Body>
                          <DateText date={revisedDueDate} />
                        </Body>
                      </Table.BodyCell>
                      <Table.BodyCell className="justify-center-row table-cell">
                        <Body>
                          <span className="owner-name">{user}</span>
                        </Body>
                      </Table.BodyCell>
                      <Table.BodyCell className="justify-center-row">
                        <Dropdown defaultOpen={false}>
                          <Dropdown.Head>
                            <Icon name="More" className="more-icon" />
                          </Dropdown.Head>
                          <DropdownBodyOnTable>
                            {({ closeDropdown }) => (
                              <Menu>
                                <Menu.Item
                                  onClick={() => {
                                    closeDropdown();
                                    history.push(`/management/${url}/${id}/`);
                                  }}>
                                  Details
                                </Menu.Item>
                                {canEditAMOItem(this.user, amoItem, alliance) ? (
                                  <Menu.Item
                                    onClick={() => {
                                      closeDropdown();
                                      history.push(`/management/${url}/edit/${id}/`);
                                    }}>
                                    Edit
                                  </Menu.Item>
                                ) : (
                                  ''
                                )}
                                <Menu.Item
                                  onClick={() => {
                                    closeDropdown();
                                    this.onSelectForDelete(item);
                                  }}>
                                  Delete
                                </Menu.Item>
                                {approvalFunction && item.status === AMO_ITEM_IN_PROGRESS ? (
                                  <Menu.Item
                                    onClick={() => {
                                      closeDropdown();
                                      this.onSelectForSubmitForApproval(item, approvalFunction);
                                    }}>
                                    Submit For Approval
                                  </Menu.Item>
                                ) : null}
                              </Menu>
                            )}
                          </DropdownBodyOnTable>
                        </Dropdown>
                      </Table.BodyCell>
                      <Table.BodyCell>
                        {approvalItems !== null
                          ? approvalItems.slice(-2).map((approval, i) => (
                            <div key={i}>
                              <Status status={approval.status} />
                              <DetailDateValue date={approval.dateOfResponse} />
                            </div>
                          ))
                          : ''}
                      </Table.BodyCell>
                    </Table.BodyRow>
                    {pageLoader}
                  </>
                );
              }}
            </Table.Body>
          </Table>
        </ListCardBody>
        <Card.Footer>
          <Row justifyContent={'left'}>
            <Small1>
              <u>NOTE:</u> Items with status REJECT or CLOSE will not be show
            </Small1>
          </Row>
        </Card.Footer>
        <YesNoDialog
          isOpen={deleteModalIsOpen}
          onYes={this.onYes}
          onNo={this.onClose}
          onClose={this.onClose}
          text={'Are you sure you want to Delete this Item?'}
          title={'Delete Item'}
        />
        <YesNoDialog
          isOpen={submitForApprovalModalIsOpen}
          onYes={this.onYesSubmitForApproval}
          onNo={this.onClose}
          onClose={this.onClose}
          text={'Are you sure you want to Submit For Approval this Item?'}
          title={'Submit For Approval'}
        />
        <OptionsDialog
          isOpen={createModalIsOpen}
          onClose={() => this.setState({ createModalIsOpen: false })}
          text={'Select an Item Type'}
          options={[
            {
              text: 'Risk',
              action: () => {
                this.props.history.push(`/management/risk/create`);
              },
            },
            {
              text: 'Action',
              action: () => {
                this.props.history.push(`/management/action/create`);
              },
            },
            {
              text: 'Issue',
              action: () => {
                this.props.history.push(`/management/issue/create`);
              },
            },
            {
              text: 'Decision',
              action: () => {
                this.props.history.push(`/management/decision/create`);
              },
            },
          ]}
          title={'Create AMO Item'}
        />
        {canCreateAMOItem(this.user, alliance) ? (
          <ActionButtonListView
            onClick={() => this.setState({ createModalIsOpen: true })}
            text="Create Item"
          />
        ) : (
          ''
        )}
      </div>
    );
  }
}

AMOItemListView.propTypes = {
  history: PropTypes.object.isRequired,
};

export default withRouter(withAlliance(AMOItemListView));
