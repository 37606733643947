import React, { useCallback, useEffect, useState } from 'react';
import { Button, Column, Row, Text } from '@8base/boost';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import FileIcon from '../FileIcon';
import { FileInput } from '@8base-react/file-input';
import * as R from 'ramda';
import { fetchSelectedAllianceFilesSize } from '../../modules/settings/alliance-management/alliance-actions';
import { useSubscription } from '@cobuildlab/react-simple-state';
import { fileSizeToMegabytes } from '../../shared/utils';
import { OnAllianceFileSize } from '../../modules/settings/alliance-management/alliance-events';

export const FILESTACK_DEFAULT_OPTIONS = {
  fromSources: ['local_file_system', 'googledrive', 'dropbox', 'onedrive', 'onedriveforbusiness'],
};

export const FileInputComponent = ({
  onChange,
  field = [],
  nameInput,
  maxFiles,
  text,
  onClickDelete,
}) => {
  const [files, setFiles] = useState(field);
  const [sizesLoaded, setSizesLoaded] = useState(false);
  const [allianceFilesSize, setAllianceFilesSize] = useState({ size: 0, maxSize: 0 });

  useEffect(() => {
    fetchSelectedAllianceFilesSize();
  }, []);

  useSubscription(OnAllianceFileSize, (filesSize) => {
    setSizesLoaded(true);
    setAllianceFilesSize(filesSize);
  });

  useEffect(() => {
    setFiles(field);
  }, [field]);

  const onChangeInput = useCallback(
    (file, maxFiles) => {
      let listFile = R.clone(files);

      if (listFile === null || maxFiles === 1) {
        listFile = [];
      }

      if (listFile.length >= maxFiles) return;
      listFile.push(file);

      setFiles(listFile);
    },
    [setFiles, files],
  );

  const disabled = Array.isArray(files) ? files.length === maxFiles : files !== null;

  const filesUploaded = Array.isArray(files) ? files.length : 0;
  const maxFilesUpload = maxFiles - filesUploaded;

  const allianceSize = fileSizeToMegabytes(allianceFilesSize.size);
  const allianceMaxSize = fileSizeToMegabytes(allianceFilesSize.maxSize);

  const onFileInputChange = useCallback(
    (picture, file) => {
      if (maxFiles > 1 && Array.isArray(picture)) {
        picture.forEach((value, key) => onChangeInput(file[key], maxFiles));
        onChange(nameInput, picture);
      } else {
        onChangeInput(file, maxFiles);
        onChange(nameInput, picture);
      }
    },
    [onChange, onChangeInput],
  );

  return (
    <FileInput onChange={onFileInputChange} value={field} maxFiles={maxFilesUpload}>
      {({ pick }) => {
        return (
          <Row stretch alignItems="center">
            <Column>
              {Array.isArray(files) ? (
                files.map(
                  (val, i) =>
                    i < maxFiles && (
                      <FileIcon
                        key={i}
                        index={i}
                        file={val}
                        onClickDelete={onClickDelete}
                        nameInput={nameInput}
                      />
                    ),
                )
              ) : (
                <FileIcon
                  index={0}
                  file={files}
                  onClickDelete={onClickDelete}
                  nameInput={nameInput}
                />
              )}
              <Button
                type="button"
                onClick={() => pick(FILESTACK_DEFAULT_OPTIONS)}
                color="neutral"
                disabled={disabled}
                title={
                  files !== null && files.length >= maxFiles ? 'Max Documents ' + maxFiles : ''
                }>
                <FontAwesomeIcon icon="file-upload" />
                &nbsp; {text}
              </Button>
              {sizesLoaded ? (
                <Row justifyContent="center" stretch>
                  <Text text={`(${allianceSize}mb / ${allianceMaxSize}mb)`} />
                </Row>
              ) : null}
            </Column>
          </Row>
        );
      }}
    </FileInput>
  );
};

FileInputComponent.propTypes = {
  onChange: PropTypes.func.isRequired,
  field: PropTypes.object,
  nameInput: PropTypes.string.isRequired,
  maxFiles: PropTypes.number.isRequired,
  text: PropTypes.string.isRequired,
  onClickDelete: PropTypes.func,
};

FileInputComponent.defaultProps = {
  field: null,
  onClickDelete: null,
};

export default FileInputComponent;
