import React, { useState } from 'react';
import { createApolloClient } from '.';
import PropTypes from 'prop-types';
import { ApolloProvider } from 'react-apollo';
import { getToken } from './helpers';

/**
 *  @returns {object} Apollo client.
 */
export function apolloClient() {
  return createApolloClient(getToken);
}
// createApolloClient
/**
 * @param {object} props - Props.
 * @param {React.Component} props.children - Children element.
 * @returns {React.FC} - React Component.
 */
export function Apollo({ children }) {
  const [apollo] = useState(() => createApolloClient(getToken));
  return <ApolloProvider client={apollo}>{children}</ApolloProvider>;
}

Apollo.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};
