import React from 'react';
import { Button } from '@8base/boost';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

const StyledButtomListView = styled(Button)({
  height: '40px',
  width: '166px',
  'border-radius': '19.5px',
  'background-color': '#3EB7F9',
  'box-shadow': '0 2px 4px 0 rgba(10,18,14,0.18), 0 4px 6px 0 rgba(18,61,43,0.08)',
});

const ActionButtonListView = ({ fontAwesomeIcon = '', onClick, text = '', ...rest }) => {
  const icon = fontAwesomeIcon ? <FontAwesomeIcon icon={fontAwesomeIcon} /> : null;

  return (
    <div className="button-list-view">
      <StyledButtomListView onClick={onClick} {...rest}>
        {icon}
        {text}
      </StyledButtomListView>
    </div>
  );
};

ActionButtonListView.defaultProps = {
  fontAwesomeIcon: '',
  text: '',
};

ActionButtonListView.propTypes = {
  onClick: PropTypes.func.isRequired,
  fontAwesomeIcon: PropTypes.string,
  text: PropTypes.string,
};

export { ActionButtonListView };
