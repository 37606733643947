import Flux from '@cobuildlab/flux-state';

/**
 * Event that triggers a wizard error.
 *
 * @type {string}
 */
export const WIZARD_ERROR = 'onWizardError';

/**
 * Event that triggers a user information.
 *
 * @type {string}
 */
export const USER_INFORMATION_EVENT = 'onUserInfo';

/**
 * Event that triggers when the User Information is Updated.
 *
 * @type {string}
 */
export const USER_UPDATE_EVENT = 'onUserUpdated';

/**
 * Event that triggers a Company Information.
 *
 * @type {string}
 */
export const COMPANY_INFORMATION_EVENT = 'onCompanyInfo';

/**
 * Event that triggers a Company Information Updated.
 *
 * @type {string}
 */
export const COMPANY_INFORMATION_UPDATE_EVENT = 'onCompanyInfoUpdated';

/**
 * Event that triggers a Company Information Created event.
 *
 * @type {string}
 */
export const COMPANY_INFORMATION_CREATE_EVENT = 'onCompanyCreated';
/**
 * Event that triggers a Alliance Information Updated.
 *
 * @type {string}
 */
export const ALLIANCE_INFORMATION_UPDATE_EVENT = 'onAllianceInfoUpdated';
/**
 * Event that triggers a Alliance Information Created.
 *
 * @type {string}
 */
export const ALLIANCE_INFORMATION_CREATE_EVENT = 'onAllianceCreated';

/**
 * Event that triggers a Company Information.
 *
 * @type {string}
 */
export const ALLIANCE_INFORMATION_EVENT = 'onAllianceInfo';

/**
 * Event that triggers an Invitation creation.
 *
 * @type {string}
 */
export const CREATE_USER_INVITATION_EVENT = 'onUserInvitationCreate';

/**
 * Hold the Wizard Data.
 */
class WizardStore extends Flux.DashStore {
  constructor() {
    super();
    this.addEvent(USER_INFORMATION_EVENT);
    this.addEvent(COMPANY_INFORMATION_EVENT);
    this.addEvent(WIZARD_ERROR);
    this.addEvent(USER_UPDATE_EVENT);
    this.addEvent(ALLIANCE_INFORMATION_EVENT);
    this.addEvent(COMPANY_INFORMATION_UPDATE_EVENT);
    this.addEvent(ALLIANCE_INFORMATION_UPDATE_EVENT);
    this.addEvent(CREATE_USER_INVITATION_EVENT);
    this.addEvent(COMPANY_INFORMATION_CREATE_EVENT);
    this.addEvent(ALLIANCE_INFORMATION_CREATE_EVENT);
  }
}

export const wizardStore = new WizardStore();
export default wizardStore;
