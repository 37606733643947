import React from 'react';
import { Column, InputField, Row, SelectField, Switch } from '@8base/boost';
import PhoneNumberInput from '../wizard/components/PhoneNumberInput';
import { FileInput } from '@8base-react/file-input';
import PropTypes from 'prop-types';
import DeleteIcon from '../../components/DeleteIcon';
import { PhoneType } from './auth-models';
import PlusButton from '../../components/PlusButton';
import ImgUpload from '../../components/forms/ImgUpload';
import { FILESTACK_DEFAULT_OPTIONS } from '../../components/inputs/FileInputComponent';

class UserInformationForm extends React.Component {
  onChangePhone = (index, value) => {
    const phones = this.props.data.phones;
    phones[index].phone = value;
    this.props.onChange('phones', phones);
  };

  onChangePhoneType = (index, value) => {
    const type = this.props.phoneTypes.find((type) => type.id === value);
    const phones = this.props.data.phones;
    phones[index].type = type;
    this.props.onChange('phones', phones);
  };
  /**
   * @function addPhone
   * @description
   * Adds a phone number field to assign a phone information.
   */
  addPhone = () => {
    const phones = this.props.data.phones;
    const { phoneTypes } = this.props;
    const defaultPhoneType = phoneTypes.length ? phoneTypes[0] : Object.assign({}, PhoneType);

    const newPhones = phones.concat({
      phone: '',
      type: defaultPhoneType,
      id: null,
    });
    this.props.onChange('phones', newPhones);
  };

  /**
   * @function removePhone
   * @param {number} phoneIndex - Phone number index.
   * @description
   * Remove input phone by specific Index.
   * [1, 2, 3, ] removePhone(0) => [2, 3].
   */
  removePhone = (phoneIndex) => {
    const phones = this.props.data.phones;
    const actualPhones = phones.filter((_, currentPhone) => currentPhone !== phoneIndex);
    this.props.onChange('phones', actualPhones);
  };

  render() {
    const {
      onChange,
      phoneTypes,
      countries,
      usaStates,
      data: {
        firstName,
        lastName,
        weeklyEmailNotification,
        email,
        phones,
        country,
        zipCode,
        city,
        state,
        avatar, // the user's avatar
        pictureUrl, // temporal url for update avatar only
        title,
      },
    } = this.props;

    // set pictureUrlToShow set to pictureUrl || avatar.downloadUrl
    let pictureUrlToShow;
    if (pictureUrl) pictureUrlToShow = pictureUrl;
    else if (avatar) pictureUrlToShow = avatar.downloadUrl;
    // Show USA states options Select if country === USA
    const currentCountry = countries.find((item) => country === item.id);
    const showUsaStates = !!(currentCountry && currentCountry.name === 'United States');

    return (
      <Row growChildren gap="lg">
        <Column style={{ width: '50%' }} alignItems="stretch">
          <Row>
            <InputField
              label="First Name"
              placeholder="First Name"
              input={{
                name: 'firstName',
                value: firstName,
                onChange: (value) => onChange('firstName', value),
              }}
            />
            <div style={{ width: '100%' }} className="label-gone">
              <InputField
                stretch
                label="Last Name"
                placeholder="Last Name"
                input={{
                  name: 'lastName',
                  value: lastName,
                  onChange: (value) => onChange('lastName', value),
                }}
              />
            </div>
          </Row>
          <InputField
            stretch
            disabled
            label="Email Address"
            placeholder="Email Address"
            input={{
              name: 'email',
              type: 'email',
              value: email,
              onChange: (value) => onChange('email', value),
            }}
          />
          <SelectField
            label="Location"
            input={{
              name: 'country',
              value: country,
              onChange: (value) => {
                onChange('country', value);
                // if Country === USA set state to ''
                const selectedCountry = countries.find((item) => value === item.id);
                const deleteCurrentState = !!(
                  selectedCountry && selectedCountry.name === 'United States'
                );
                if (deleteCurrentState) onChange('state', '');
              },
            }}
            meta={{}}
            placeholder="Country"
            options={countries.map((country) => {
              return { label: country.name, value: country.id };
            })}
          />
          <Row style={{ marginTop: 13 }}>
            <InputField
              placeholder="Postal/Zip"
              input={{
                name: 'zipCode',
                value: zipCode,
                onChange: (value) => onChange('zipCode', value),
              }}
            />
            <InputField
              placeholder="City"
              input={{
                name: 'city',
                value: city,
                onChange: (value) => onChange('city', value),
              }}
            />
            {showUsaStates ? (
              <SelectField
                input={{
                  name: 'state',
                  value: state,
                  onChange: (value) => onChange('state', value),
                }}
                meta={{}}
                placeholder="State"
                options={usaStates.map((state) => {
                  return { label: state.name, value: state.name };
                })}
              />
            ) : (
              <InputField
                input={{
                  name: 'state',
                  value: state,
                  onChange: (value) => onChange('state', value),
                }}
                placeholder="State"
              />
            )}
          </Row>
          <div className="add-hover">
            <PlusButton onClick={this.addPhone} text="Add Phone Number" />
          </div>
          {/* PHONES */}
          {phones.map((phone, i) => {
            return (
              <Row key={i} style={{ width: '95%', marginTop: 15 }}>
                <PhoneNumberInput
                  key={i}
                  phone={phone.phone}
                  phoneType={phone.type.id}
                  onChangePhone={(value) => this.onChangePhone(i, value)}
                  onChangeType={(type) => this.onChangePhoneType(i, type)}
                  types={phoneTypes}
                />
                <DeleteIcon onClick={() => this.removePhone(i)} />
              </Row>
            );
          })}
        </Column>
        <Column style={{ width: '50%' }} alignItems="stretch">
          <FileInput
            onChange={(picture, file, ...rest) => {
              const reader = new FileReader();
              reader.addEventListener(
                'load',
                () => {
                  onChange('pictureUrl', reader.result);
                },
                false,
              );
              reader.readAsDataURL(file);
              onChange('avatar', picture);
            }}
            value={'input.value'}
            maxFiles={1}>
            {({ pick }) => {
              return (
                <Column stretch justifyContent="center" alignItems="center">
                  <ImgUpload
                    onClick={() => pick(FILESTACK_DEFAULT_OPTIONS)}
                    url={pictureUrlToShow}
                  />
                </Column>
              );
            }}
          </FileInput>

          <InputField
            stretch
            label="Title"
            placeholder="Title"
            input={{
              name: 'title',
              value: title,
              onChange: (value) => onChange('title', value),
            }}
          />

          <Switch
            label="Receive weekly digest email notification"
            value={weeklyEmailNotification}
            onChange={(value) => onChange('weeklyEmailNotification', value)}
          />
        </Column>
      </Row>
    );
  }
}

UserInformationForm.propTypes = {
  onChange: PropTypes.func.isRequired,
  phoneTypes: PropTypes.array.isRequired,
  countries: PropTypes.array.isRequired,
  usaStates: PropTypes.array.isRequired,
  data: PropTypes.object.isRequired,
};

export default UserInformationForm;
