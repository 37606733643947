import React from 'react';
import { TextAreaField, Dialog, Button } from '@8base/boost';
import PropTypes from 'prop-types';
import { ActionButton } from '../../../../components/buttons/ActionButton';

class RecommendedSolutionDialog extends React.Component {
  render() {
    const { onChange, description, onAccept, onCancel, isOpen } = this.props;
    return (
      <Dialog size="sm" isOpen={isOpen}>
        <Dialog.Header title="Alternative Solution" onClose={onCancel} />
        <Dialog.Body>
          <TextAreaField
            label="Description"
            style={{ width: '100%' }}
            stretch
            input={{
              name: 'description',
              value: description,
              onChange: (value) => onChange('description', value),
            }}
          />
        </Dialog.Body>
        <Dialog.Footer>
          <Button color="neutral" variant="outlined" onClick={onCancel}>
            Cancel
          </Button>
          <ActionButton onClick={onAccept} text={'Save'} />
        </Dialog.Footer>
      </Dialog>
    );
  }
}

RecommendedSolutionDialog.propTypes = {
  onAccept: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  description: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
};

export default RecommendedSolutionDialog;
