import { IntegrityError, ValidationError } from '../../../shared/errors';
import { isValidString } from '../../../shared/validators';

/**
 * Validate the Business Case.
 *
 * @param {object} businessCaseData - The business Case Data.
 */
export const businessCaseValidator = (businessCaseData) => {
  let errorMessages = [];

  if (!isValidString(businessCaseData.backgroundSummary, true))
    errorMessages.push('The Business Case must have a valid Background Summary');

  if (!isValidString(businessCaseData.marketAnalysis, true))
    errorMessages.push('The Business Case must have a valid Market Analysis');

  if (!isValidString(businessCaseData.salesMarketingStrategy, true))
    errorMessages.push('The Business Case must have a valid Sales Marketing Strategy');

  if (!isValidString(businessCaseData.visionMissionStrategy, true))
    errorMessages.push('The Business Case must have a valid Vision and Mission Strategy');

  if (!isValidString(businessCaseData.risks, true))
    errorMessages.push('The Business Case must have a valid Risks');

  if (!isValidString(businessCaseData.recommendedSolution, true))
    errorMessages.push('The Business Case must have a valid Recommended solution');

  // if (
  //   !Array.isArray(businessCaseData.anticipatedCosts) ||
  //   !businessCaseData.anticipatedCosts.length
  // ) {
  //   errorMessages.push('The Business Case must have a valid Anticipated Contributions');
  // }
  // businessCaseData.anticipatedCosts.forEach((cost) => {
  //   if (!isValidNumber(cost, true))
  //     errorMessages.push('The Business Case Anticipated Contributions must have a valid number');
  // });
  //
  // if (!Array.isArray(businessCaseData.expectedCosts) || !businessCaseData.expectedCosts.length) {
  //   errorMessages.push('The Business Case must have a valid Expected Costs Avoidance');
  // }
  // businessCaseData.expectedCosts.forEach((cost) => {
  //   if (!isValidNumber(cost, true))
  //     errorMessages.push('The Business Case Expected Costs Avoidance must be valid numbers');
  // });
  //
  // if (
  //   !Array.isArray(businessCaseData.expectedRevenues) ||
  //   !businessCaseData.expectedRevenues.length
  // ) {
  //   errorMessages.push('The Business Case must have a valid Expected Bookings');
  // }
  // businessCaseData.expectedRevenues.forEach((cost) => {
  //   if (!isValidNumber(cost, true))
  //     errorMessages.push('The Business Case Expected Reveneus must be valid numbers');
  // });

  if (errorMessages.length !== 0) throw new ValidationError(errorMessages);
};

/**
 * Validate alternative solutions array elements.
 *
 * @param {Array} alternativeSolutions - Alternative solutions.
 */
export const alternativeSolutionsValidator = (alternativeSolutions) => {
  if (!Array.isArray(alternativeSolutions))
    throw new IntegrityError('Invalid Alternative Solutions');

  alternativeSolutions.forEach((alternativeSolution) => {
    alternativeSolutionValidator(alternativeSolution);
  });
};

export const alternativeSolutionValidator = (alternativeSolution) => {
  let errorMessages = [];

  if (!isValidString(alternativeSolution.description))
    errorMessages.push('An Alternative Solution must have a valid description');

  if (errorMessages.length !== 0) throw new ValidationError(errorMessages);
};
