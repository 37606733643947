import React from 'react';
import { Card, Grid, SecondaryNavigation } from '@8base/boost';
import { Route, Switch, Redirect } from 'react-router-dom';
import WorkQueueView from './work-queue/WorkQueueView';
import InitiativeActiveItemsView from './initiative-active-items/InitiativeActiveItemsView';
import PropTypes from 'prop-types';
import { LinkItem } from '../../components/link/LinkItem';
import LinkTitle from '../../components/link/LinkTitle';
import { onErrorMixin } from '../../shared/mixins';
import { fetchInitiativeList } from '../management/initiative/initiative-actions';
import { View } from '@cobuildlab/react-simple-state';
import { OnInitiativeError, OnInitiativeList } from '../management/initiative/initiative-events';

class ActiveItemsView extends View {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      initiatives: [],
    };
    this.onError = onErrorMixin.bind(this);
  }

  componentDidMount() {
    this.subscribe(OnInitiativeError, this.onError);
    this.subscribe(OnInitiativeList, (data) => {
      this.setState({
        initiatives: data.initiativesList.items,
        loading: false,
      });
    });

    fetchInitiativeList('', 1, 1000);
  }

  render() {
    const { location } = this.props;
    const { initiatives } = this.state;

    return (
      <Grid.Layout className="minHeightCard" columns="215px 1fr">
        <Grid.Box>
          <SecondaryNavigation>
            <LinkTitle text={'Personal'} />
            <LinkItem
              to={'/active-items/work-queue'}
              label={'My Work Queue'}
              location={location}
              url={'work-queue'}
            />
            <LinkTitle text={'Initiative'} />
            {initiatives.map(({ id, name }) => (
              <LinkItem
                key={id}
                to={`/active-items/initiative/${id}`}
                label={name}
                location={location}
                url={`initiative/${id}`}
              />
            ))}
          </SecondaryNavigation>
        </Grid.Box>
        <Grid.Box>
          <Card stretch>
            <Switch>
              <Route path="/active-items/work-queue" component={WorkQueueView} />
              <Route
                path="/active-items/initiative/:id"
                component={InitiativeActiveItemsView}
                key={String(Math.random().toFixed(10))}
              />
              <Redirect to="/active-items/work-queue" />
            </Switch>
          </Card>
        </Grid.Box>
      </Grid.Layout>
    );
  }
}

ActiveItemsView.propTypes = {
  location: PropTypes.any.isRequired,
};

export default ActiveItemsView;
