import React from 'react';
import { Card } from '@8base/boost';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

/**
 * Use this instead of Card.Body for Create/Edit Views,
 * add any css Padding, margin on this component.
 *
 */

const StyledCard = styled(Card.Body)`
  padding: 0px !important;
`;

const CreateViewCardBody = ({ children, ...rest }) => {
  return (
    <StyledCard borderRadius="all" className="card-body" {...rest}>
      {children}
    </StyledCard>
  );
};

CreateViewCardBody.propTypes = {
  children: PropTypes.any.isRequired,
};

export { CreateViewCardBody };
