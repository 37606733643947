import React from 'react';
import { Dialog, Grid, Text, Menu } from '@8base/boost';
import PropTypes from 'prop-types';

class OptionsDialog extends React.Component {
  render() {
    const { options, text, isOpen, title, onClose } = this.props;
    return (
      <Dialog size="sm" isOpen={isOpen}>
        <Dialog.Header title={title} onClose={onClose} />
        <Dialog.Body scrollable>
          <Grid.Layout gap="sm" stretch>
            <Grid.Box>
              <Text>{text}</Text>
              <br />
              <Menu>
                {options.map(({ text, action }, i) => (
                  <Menu.Item key={i} onClick={action}>
                    {text}
                  </Menu.Item>
                ))}
              </Menu>
            </Grid.Box>
          </Grid.Layout>
        </Dialog.Body>
      </Dialog>
    );
  }
}

OptionsDialog.propTypes = {
  onClose: PropTypes.func,
  options: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
};

OptionsDialog.defaultProps = {
  onClose: () => {},
};

export default OptionsDialog;
