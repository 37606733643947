import React from 'react';
import { Button } from '@8base/boost';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';

const TransparentButton = ({ fontAwesomeIcon = '', onClick, text = '', ...rest }) => {
  const icon = fontAwesomeIcon ? <FontAwesomeIcon icon={fontAwesomeIcon} /> : null;

  return (
    <Button
      onClick={onClick}
      color={'neutral'}
      variant={'outlined'}
      {...rest}
      style={{ 'margin-right': '15px' }}>
      {icon}
      {text}
    </Button>
  );
};

TransparentButton.propTypes = {
  fontAwesomeIcon: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  text: PropTypes.string,
};

TransparentButton.defaultProps = {
  fontAwesomeIcon: '',
  text: '',
};

export { TransparentButton };
