import { ValidationError } from '../../../shared/errors';
import {
  isValidString,
  setErrorIfDateIsBeforeAllianceStart,
  isValidBigInt,
} from '../../../shared/validators';
import moment from 'moment';
import { nextStepsValidator } from '../../../modules/next-step/next-step-validators';
import { initiativesItemValidator } from '../initiative/initiative-validators';

/**
 * Validate that the Item has all the fields necessaries.
 *
 * @param { object } data - Data to validate.
 * @param { object } allianceDetails - Details.
 */
export const issueValidator = (data, allianceDetails) => {
  let errorMessages = [];

  if (!isValidString(data.name)) errorMessages.push('The Issue must have a valid Name');

  if (!isValidString(data.description))
    errorMessages.push('The Issue must have a valid Description');

  if (Array.isArray(data.nextSteps) && data.nextSteps.length > 0) {
    try {
      nextStepsValidator(data.nextSteps);
    } catch (e) {
      errorMessages.push(e.message);
    }
  } else errorMessages.push('The Issue must have valid Next Steps');

  if (!data.assignedTo) errorMessages.push('The Issue must have a valid Assignee');

  if (!moment(data.assignedDate).isValid()) {
    errorMessages.push('The Issue must have a valid Assigned Date');
  } else {
    setErrorIfDateIsBeforeAllianceStart(
      data.assignedDate,
      allianceDetails,
      'Assigned Date',
      errorMessages,
    );
  }

  if (!moment(data.originalDueDate).isValid()) {
    errorMessages.push('The Issue must have a valid Original Due Date');
  } else {
    setErrorIfDateIsBeforeAllianceStart(
      data.originalDueDate,
      allianceDetails,
      'Original Due Date',
      errorMessages,
    );
  }

  if (moment(data.revisedDueDate).isValid()) {
    setErrorIfDateIsBeforeAllianceStart(
      data.revisedDueDate,
      allianceDetails,
      'Revised Due Date',
      errorMessages,
    );
  }

  if (!isValidString(data.unitType, false, true))
    errorMessages.push('The Issue must have a valid Unit Type');

  if (!(parseFloat(data.unitQuantity) >= 0)) errorMessages.push('The Issue must have a valid Unit');

  if (!isValidString(data.unitValueDescription, true, true))
    errorMessages.push('The Issue must have a valid Unit Description');

  if (!isValidBigInt(data.unitMonetizationFactor))
    errorMessages.push('The Issue must have a valid Monetization Factor');

  if (errorMessages.length !== 0) throw new ValidationError(errorMessages);
};

export const validateIssueData = (issueData, initiativesData, allianceDetails) => {
  issueValidator(issueData, allianceDetails);
  initiativesItemValidator(initiativesData);
};
