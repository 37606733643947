import gql from 'graphql-tag';
import { UserFragment } from '../../auth/queries';
import { BusinessCaseFragment } from '../../document-management/business-case/businessCase.queries';
import { CommentFragment } from '../../comment/comment-queries';
import { NextStepFragment } from '../../next-step/next-step-queries';

export const AllianceForInitiativeApprovalFragment = gql`
  fragment AllianceForInitiativeApprovalFragment on Alliance {
    id
    name
    clientCompany {
      id
      name
    }
    partnerCompany {
      id
      name
    }
  }
`;

export const InitiativeFragment = gql`
  fragment InitiativeFragment on Initiative {
    id
    name
    description
    createdAt
    alliance {
      ...AllianceForInitiativeApprovalFragment
    }
    businessCase {
      ...BusinessCaseFragment
    }
    owner {
      ...UserFragment
    }
    documentsFile {
      items {
        id
        downloadUrl
        filename
        fileId
      }
    }
    currentDashboardFile {
      id
      downloadUrl
      filename
      fileId
    }
    requestedBy {
      ...UserFragment
    }
    requestedDate
    status
    ragStatus
    baselineStartDate
    baselineEndDate
    forecastedEndDate
    initiativeApprovalInitiativeRelation {
      items {
        id
        dateOfResponse
        status
        approvedBy {
          ...UserFragment
        }
        company {
          id
          name
        }
        initiative {
          id
          name
        }
      }
    }
    actionInitiativesRelation {
      count
      items {
        id
        name
        description
        status
        createdAt
        revisedDueDate
        originalDueDate
        businessCase {
          id
        }
        owner {
          ...UserFragment
        }
        assignedTo {
          ...UserFragment
        }
        nextSteps {
          items {
            ...NextStepFragment
          }
        }
        itemId: itemActionRelation {
          id
        }
      }
    }

    contributionInitiativesRelation {
      count
      items {
        id
        name
        description
        status
        createdAt
        businessCase {
          id
        }
        owner {
          ...UserFragment
        }
        itemId: itemContributionRelation {
          id
        }
      }
    }

    decisionInitiativesRelation {
      count
      items {
        id
        name
        description
        status
        createdAt

        owner {
          ...UserFragment
        }
        itemId: itemDecisionRelation {
          id
        }
      }
    }

    fundingRequestInitiativesRelation {
      count
      items {
        id
        name
        description
        status
        createdAt
        revisedDueDate
        originalDueDate
        businessCase {
          id
        }
        owner {
          ...UserFragment
        }
        requestedBy {
          ...UserFragment
        }
        assignedTo {
          ...UserFragment
        }
        nextSteps {
          items {
            ...NextStepFragment
          }
        }
        itemId: itemFundingRequestRelation {
          id
        }
      }
    }

    ideaInitiativesRelation {
      count
      items {
        id
        name
        description
        status
        createdAt
        revisedDueDate
        originalDueDate
        owner {
          ...UserFragment
        }
        assignedTo {
          ...UserFragment
        }
        requestedBy {
          ...UserFragment
        }
        nextSteps {
          items {
            ...NextStepFragment
          }
        }
        itemId: itemIdeaRelation {
          id
        }
      }
    }

    issueInitiativesRelation {
      count
      items {
        id
        name
        description
        status
        createdAt
        revisedDueDate
        originalDueDate
        owner {
          ...UserFragment
        }
        assignedTo {
          ...UserFragment
        }
        nextSteps {
          items {
            ...NextStepFragment
          }
        }
        itemId: itemIssueRelation {
          id
        }
      }
    }

    riskInitiativesRelation {
      count
      items {
        id
        name
        description
        status
        createdAt
        revisedDueDate
        originalDueDate
        owner {
          ...UserFragment
        }
        assignedTo {
          ...UserFragment
        }
        nextSteps {
          items {
            ...NextStepFragment
          }
        }
        itemId: itemRiskRelation {
          id
        }
      }
    }

    dealDatatInitiativesRelation {
      count
      items {
        id
        name
        description
        status
        createdAt
        owner {
          ...UserFragment
        }
        itemId: itemDealDataRelation {
          id
        }
      }
    }
  }
  ${UserFragment}
  ${BusinessCaseFragment}
  ${AllianceForInitiativeApprovalFragment}
  ${NextStepFragment}
`;

export const InitiativeApprovalFragment = gql`
  fragment InitiativeApprovalFragment on InitiativeApproval {
    id
    initiative {
      id
      name
    }
    company {
      id
      name
    }
    approvedBy {
      ...UserFragment
    }
    status
    dateOfResponse
  }
  ${UserFragment}
`;

export const INITIATIVE_LIST_QUERY = gql`
  query($data: InitiativeFilter, $skip: Int, $first: Int) {
    initiativesList(filter: $data, skip: $skip, first: $first) {
      count
      items {
        ...InitiativeFragment
      }
    }
  }
  ${InitiativeFragment}
`;

export const INITIATIVE_LIST_SMALL_QUERY = gql`
  query($data: InitiativeFilter, $skip: Int, $first: Int) {
    initiativesList(filter: $data, skip: $skip, first: $first) {
      count
      items {
        id
        name
        description
        createdAt
        status
        ragStatus
        owner {
          id
          firstName
          lastName
          email
          avatar {
            id
            fileId
            filename
            downloadUrl
          }
        }
      }
    }
  }
`;

export const INITIATIVE_FULL_LIST_QUERY = gql`
  query($data: InitiativeFilter) {
    initiativesList(filter: $data) {
      items {
        id
        name
      }
    }
  }
`;

export const INITIATIVE_DETAIL_QUERY = gql`
  query($id: ID!) {
    initiative(id: $id) {
      ...InitiativeFragment
    }
  }
  ${InitiativeFragment}
`;

export const INITIATIVE_CREATE_MUTATION = gql`
  mutation($data: InitiativeCreateInput!) {
    initiativeCreate(data: $data) {
      ...InitiativeFragment
    }
  }
  ${InitiativeFragment}
`;

export const INITIATIVE_UPDATE_MUTATION = gql`
  mutation($data: InitiativeUpdateInput!) {
    initiativeUpdate(data: $data) {
      ...InitiativeFragment
    }
  }
  ${InitiativeFragment}
`;

export const INITIATIVE_APPROVALS_LIST_QUERY = gql`
  query($filter: InitiativeApprovalFilter, $sort: [InitiativeApprovalSort!]) {
    initiativeApprovalsList(filter: $filter, sort: $sort) {
      count
      items {
        ...InitiativeApprovalFragment
      }
    }
  }
  ${InitiativeApprovalFragment}
`;

/**
 * Update an Initiative Approval.
 */
export const INITIATIVE_APPROVAL_UPDATE_MUTATION = gql`
  mutation($approval: InitiativeApprovalUpdateInput!) {
    initiativeApprovalUpdate(data: $approval) {
      id
    }
  }
`;

/**
 * Update an Initiative Approval and a Initiative.
 */
export const INITIATIVE_INITIATIVE_APPROVAL_MUTATION = gql`
  mutation($initiative: InitiativeUpdateInput!, $approval: InitiativeApprovalUpdateInput!) {
    initiativeUpdate(data: $initiative) {
      id
    }
    initiativeApprovalUpdate(data: $approval) {
      id
    }
  }
`;

export const INITIATIVE_DELETE_MUTATION = gql`
  mutation($data: InitiativeDeleteInput!) {
    initiativeDelete(data: $data, force: true) {
      success
    }
  }
`;

export const INITIATIVE_COMMENTS_QUERY = gql`
  query($id: ID!) {
    initiative(id: $id) {
      id
      comments {
        items {
          ...CommentFragment
        }
        count
      }
    }
  }
  ${CommentFragment}
`;
