import React from 'react';
import { Card, Grid, Heading, Loader, Row } from '@8base/boost';
import { CreateViewCardBody } from '../../../components/new-ui/card/CreateViewCardBody';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import * as toast from '../../../components/toast/Toast';
import * as R from 'ramda';
import { View } from '@cobuildlab/react-simple-state';
import { RISK_DOCUMENTS, RiskModel } from './risk-model';
import RiskForm from './components/RiskForm';
import { autoSaveUpdateRisk, fetchRiskDetail, openComments, updateRisk } from './risk-action';
import { fetchInitiativeList } from '../initiative/initiative-actions';
import { fetchCurrentAllianceMembersAction } from '../../settings/alliance-management/alliance-actions';
import { getCurrencyOnSession } from '../../../shared/alliance-utils';
import { ActionButton } from '../../../components/buttons/ActionButton';
import { TopButtons } from '../../../components/buttons/TopButtons';
import { sanitizeNextStepsToEdit } from '../../next-step/next-step-actions';
import sessionStore, { NEW_SESSION_EVENT } from '../../../shared/SessionStore';
import { riskValidator } from './risk-validators';
import { onChangeDataMixin, onErrorMixin } from '../../../shared/mixins';
import { initiativesItemValidator } from '../initiative/initiative-validators';
import { TransparentButton } from '../../../components/buttons/TransparentButton';
import RelatedItemForm from '../../related-item/components/RelatedItemForm';
import RiskDetailTable from './components/RiskDetailTable';
import { RelatedItemsDetailTable } from '../../related-item/components/RelatedItemsDetailTable';
import { fetchRelatedItems } from '../../related-item/related-item-actions';
import { FormSteps } from '../../../components/dots/FormSteps';
import { getItemByType } from '../../../shared/items-util';
import { InitiativeListTable } from '../initiative/components/InitiativeListTable';
import { BUSINESS_CASE_DOCUMENT } from '../../document-management/business-case/BusinessCase.model';
import { LeftProgressSection } from '../../../components/new-ui/LeftProgressSection';
import { SCREENS_RISK } from '../screenView';
import { BoxCard } from '../../../components/new-ui/div/BoxCard';
import { RISK_COMPLETED } from '../../../shared/status';
import { TextDateAgo } from '../../../components/text/TextDateAgo';
import { OnInitiativeList } from '../initiative/initiative-events';
import { OnAllianceMemberList } from '../../settings/alliance-management/alliance-events';
import { OnRelatedItems, OnRelatedItemError } from '../../related-item/related-item-events';
import { OnRiskDetail, OnRiskUpdate, OnRiskError, OnRiskAutoSave } from './risk-events';

class RiskEditView extends View {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        riskData: R.clone(RiskModel),
        relatedItems: [],
        initiativesList: [],
      },
      step: 0,
      loading: true,
      clientCompany: null,
      partnerCompany: null,
      initiativesList: [],
      savedAt: null,
    };
    this.onError = onErrorMixin.bind(this);
    this.onChangeData = onChangeDataMixin.bind(this);
    this.originalNextSteps = [];
    this.originalData = null;
    this.autoSaveTimer = null;
    this.queryOptions = { isCacheFirst: true };
  }

  onChangeRiskData = (name, value) => {
    const { data } = this.state;
    data.riskData[name] = value;
    this.autoSave();
    this.setState({ data });
  };

  componentDidMount() {
    const { match } = this.props;
    if (!match.params.id) return toast.error('Risk ID missing');

    this.subscribe(OnRiskError, this.onError);
    this.subscribe(OnRelatedItemError, this.onError);

    this.subscribe(OnAllianceMemberList, (state) => {
      this.setState({
        clientCompany: R.clone(state.clientCompany),
        partnerCompany: R.clone(state.partnerCompany),
      });
    });

    this.subscribe(OnRiskDetail, (state) => {
      const { risk } = state;
      const { data } = this.state;
      const riskData = R.clone(risk);
      const initiatives = R.clone(riskData.initiatives.items);

      riskData.documents = riskData.documents.items;
      this.originalData = R.clone(riskData);
      this.originalNextSteps = sanitizeNextStepsToEdit(riskData);
      data.riskData = riskData;
      data.initiatives = initiatives;

      this.setState({ data }, () => fetchRelatedItems(riskData.itemRiskRelation.id));
    });

    this.subscribe(OnRelatedItems, (state) => {
      const items = state.item.itemsRelated.items.map((item) => getItemByType(item));
      const { data } = this.state;

      data.relatedItems = items;

      this.setState({ data, loading: false });
    });

    this.subscribe(OnRiskUpdate, () => {
      toast.success('Risk Successfully Updated');
      this.props.history.goBack();
    });

    this.subscribe(OnInitiativeList, (state) => {
      this.setState({
        initiativesList: R.clone(state.initiativesList.items),
      });
    });

    this.subscribe(OnRiskAutoSave, (risk) => {
      this.setState({ savedAt: new Date() });
    });

    fetchRiskDetail(match.params.id, this.queryOptions);
    fetchInitiativeList('', 1, 1000, null, null, this.queryOptions);
    fetchCurrentAllianceMembersAction();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
    localStorage.removeItem(RISK_DOCUMENTS);
    localStorage.removeItem(BUSINESS_CASE_DOCUMENT);
  }

  onRiskStepChange = (step) => {
    const { selectedAlliance } = sessionStore.getState(NEW_SESSION_EVENT);
    const riskData = R.clone(this.state.data.riskData);
    try {
      riskValidator(riskData, selectedAlliance);
    } catch (e) {
      return this.onError(e);
    }
    this.onScreen(step);
  };

  onRelatedItemsStepChange = (step) => {
    const initiatives = R.clone(this.state.data.initiatives);
    try {
      initiativesItemValidator(initiatives);
    } catch (e) {
      return this.onError(e);
    }
    this.onScreen(step);
  };

  onScreen = (step) => {
    this.setState({ step });
  };

  onSubmit = () => {
    this.setState({ loading: true }, () => {
      clearTimeout(this.autoSaveTimer);
      const riskData = R.clone(this.state.data.riskData);
      const relatedItems = R.clone(this.state.data.relatedItems);
      const initiatives = R.clone(this.state.data.initiatives);
      updateRisk(riskData, relatedItems, initiatives, this.originalNextSteps, this.originalData);
    });
  };

  autoSave = () => {
    const waitTime = 10000; // Wait 10s
    clearTimeout(this.autoSaveTimer);
    this.autoSaveTimer = setTimeout(() => {
      const riskData = R.clone(this.state.data.riskData);
      const relatedItems = R.clone(this.state.data.relatedItems);
      const initiatives = R.clone(this.state.data.initiatives);
      autoSaveUpdateRisk(
        riskData,
        relatedItems,
        initiatives,
        this.originalNextSteps,
        this.originalData,
      );
    }, waitTime);
  };

  render() {
    const {
      initiativesList,
      loading,
      clientCompany,
      partnerCompany,
      data,
      step,
      savedAt,
    } = this.state;
    const { riskData, initiatives, relatedItems } = data;
    const { history } = this.props;
    const { user, selectedAlliance } = sessionStore.getState(NEW_SESSION_EVENT);

    let content = <Loader stretch />;
    let footer = <></>;
    let buttonsTop = '';
    const currency = getCurrencyOnSession();
    if (riskData.status === RISK_COMPLETED) history.push(`/management/amo-item`);

    if (!loading && step === 0) {
      content = (
        <div id={'c2gScreen'}>
          <RiskForm
            data={riskData}
            onChange={this.onChangeRiskData}
            clientCompany={clientCompany}
            partnerCompany={partnerCompany}
            currency={currency}
            user={user}
            selectedAlliance={selectedAlliance}
          />
        </div>
      );
      footer = (
        <Card.Footer>
          <ActionButton onClick={() => this.onRiskStepChange(1)} text={'Next'} />
        </Card.Footer>
      );
    }

    if (!loading && step === 1) {
      const itemData = { id: riskData.id, type: riskData.__typename };

      content = (
        <div id={'c2gScreen'}>
          <RelatedItemForm
            relatedItems={relatedItems}
            selectedInitiatives={initiatives}
            initiatives={initiativesList}
            onChange={(key, value) => {
              this.autoSave();
              this.onChangeData(key, value);
            }}
            itemData={itemData}
          />
        </div>
      );

      footer = (
        <Card.Footer>
          <ActionButton onClick={() => this.onRelatedItemsStepChange(2)} text={'Next'} />
          <TransparentButton onClick={() => this.onScreen(0)} text={'Previous'} />
        </Card.Footer>
      );
    }

    if (!loading && step === 2) {
      const riskDetail = R.clone(riskData);
      riskDetail.documents = { items: riskDetail.documents };
      riskDetail.initiatives = { items: riskDetail.initiatives };
      riskDetail.nextSteps = { items: riskDetail.nextSteps };

      content = (
        <div id={'c2gScreen'}>
          <RiskDetailTable data={riskDetail} currency={currency} />
          <InitiativeListTable initiatives={initiatives} />
          <RelatedItemsDetailTable relatedItems={relatedItems} />
        </div>
      );

      footer = (
        <Card.Footer>
          <ActionButton onClick={this.onSubmit} text={'Update Risk'} />
          <TransparentButton onClick={() => this.onScreen(1)} text={'Previous'} />
        </Card.Footer>
      );
    }

    if (!loading)
      buttonsTop = (
        <TopButtons
          onClickClosed={history.goBack}
          onClickCollaborated={() => openComments(riskData)}
        />
      );

    return (
      <React.Fragment>
        <Card.Header>
          <Grid.Layout
            columns="400px auto 400px"
            areas={[['left', 'center', 'right']]}
            style={{ width: '100%' }}>
            <Grid.Box area="left">
              <Row>
                <Heading type="h4" text="Edit Risk" />
                <TextDateAgo from={savedAt} />
              </Row>
            </Grid.Box>
            <Grid.Box area="center">
              <FormSteps totalSteps={3} step={step} />
            </Grid.Box>
            <Grid.Box area="right" />
          </Grid.Layout>
          {buttonsTop}
        </Card.Header>
        <CreateViewCardBody>
          <Grid.Layout columns="30% 70%" areas={[['left', 'right']]} style={{ width: '100%' }}>
            <Grid.Box area="left">
              <LeftProgressSection sections={SCREENS_RISK} currentScreen={step} />
            </Grid.Box>
            <BoxCard>
              <Grid.Box area="right">{content}</Grid.Box>
            </BoxCard>
          </Grid.Layout>
        </CreateViewCardBody>
        {footer}
      </React.Fragment>
    );
  }
}

RiskEditView.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default withRouter(RiskEditView);
