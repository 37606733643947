import React from 'react';
import PropTypes from 'prop-types';
import './dots.css';
import { Dot } from './Dot';

const DotStep = ({ totalSteps, step }) => {
  let stepList = [];

  for (let i = 0; i < totalSteps; i++) {
    if (i === step) {
      stepList.push(<Dot key={i} active={true} />);
    } else {
      stepList.push(<Dot key={i} />);
    }
  }

  return stepList;
};

DotStep.propTypes = {
  totalSteps: PropTypes.number,
  step: PropTypes.number,
};

export { DotStep };
