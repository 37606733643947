import React from 'react';
import { withRouter } from 'react-router-dom';
import { withAuth } from '8base-react-sdk';
import PropTypes from 'prop-types';
import { fetchSession } from '../../../modules/auth/auth.actions';
import { createCompany } from '../../settings/company-management/company-actions';
import { withApollo } from 'react-apollo';
import { fetchCompanyInformation } from '../company-actions';
import { CompanyDialogForm } from '../../settings/company-management/components/CompanyDialogForm';
import { onErrorMixin, onChangeDataMixin } from '../../../shared/mixins';
import { createPaymentMethod } from '../../../shared/stripe/stripe';
import { View } from '@cobuildlab/react-simple-state';
import * as R from 'ramda';
import { ElementsConsumer } from '@stripe/react-stripe-js';
import YesNoDialog from '../../../components/dialogs/YesNoDialog';
import { CompanyInitialForm } from '../../settings/company-management/company-models';
import { logout } from '../../../modules/auth//auth.actions';
import { updateUser } from '../wizard.actions';
import { sanitizeSlug } from '../../../shared/utils';
import { OnCompanyError, OnCompanyCreate } from '../../settings/company-management/company-events';
import { OnCompanyInformation } from '../wizard-events';

class CreateCompanyView extends View {
  constructor(props) {
    super(props);
    this.state = {
      data: R.clone(CompanyInitialForm),
      companyDialogIsOpen: true,
      subscriptionPlans: [],
      countries: [],
      showAnnualPrice: false,
      loading: true,
    };

    this.onChangeData = onChangeDataMixin.bind(this);
    this.onError = onErrorMixin.bind(this);
  }

  componentDidMount() {
    this.subscribe(OnCompanyError, this.onError);

    this.subscribe(OnCompanyCreate, async () => {
      const { data: companyData } = this.state;
      if (!companyData.isCompanyPortfolioOwner) {
        await updateUser({ didCompleteWizard: true });
      }
      await fetchSession();
    });

    this.subscribe(OnCompanyInformation, (state) => {
      const { subscriptionPlansList, countriesList } = state;
      const { data } = this.state;
      const countries = countriesList.items.filter(({ code }) => (code ? true : false));
      const search = localStorage.getItem('search');
      if (search) {
        const urlParams = new URLSearchParams(search);
        let plan = urlParams.get('plan');
        if (plan) {
          for (const _subscriptionPlan of subscriptionPlansList.items) {
            if (sanitizeSlug(plan) === _subscriptionPlan.name)
              data.subscriptionPlan = _subscriptionPlan;
          }
          localStorage.removeItem(search);
        }
      }

      this.setState({
        countries,
        data,
        subscriptionPlans: subscriptionPlansList.items,
        loading: false,
      });
    });

    fetchCompanyInformation();
  }

  onOpenLogout = () => {
    this.setState({ logoutIsOpen: true });
  };

  onCloseLogout = () => {
    this.setState({ logoutIsOpen: false });
  };

  onChangeYearlyPrice = (showAnnualPrice) => {
    this.setState({ showAnnualPrice });
  };

  onSubmitCompany = async (stripe, elements) => {
    if (!stripe || !elements) return;

    const { data: companyData, showAnnualPrice } = this.state;

    this.setState({ loading: true }, async () => {
      const company = R.clone(companyData);
      const creditCard = R.clone(companyData.creditCard);
      const isAnnualPlan = R.clone(showAnnualPrice);
      const { paymentMethod, error } = await createPaymentMethod(stripe, elements, creditCard);
      createCompany(company, paymentMethod, error, isAnnualPlan);
    });
  };

  render() {
    const {
      onCloseLogout,
      onChangeData,
      onOpenLogout,
      onSubmitCompany,
      onChangeYearlyPrice,
    } = this;

    const {
      data: companyData,
      loading,
      companyDialogIsOpen,
      logoutIsOpen,
      subscriptionPlans,
      showAnnualPrice,
      countries,
    } = this.state;

    const logoutDialog = (
      <YesNoDialog
        onYes={() => logout(this.props)}
        onNo={onCloseLogout}
        text={'Are you sure you want to log out?'}
        isOpen={logoutIsOpen}
        title={'Logout'}
        onClose={onCloseLogout}
      />
    );

    return (
      <>
        <ElementsConsumer>
          {({ elements, stripe }) => {
            return (
              <CompanyDialogForm
                countries={countries}
                onChangeYearlyPrice={onChangeYearlyPrice}
                showAnnualPrice={showAnnualPrice}
                isOpen={companyDialogIsOpen}
                onChange={onChangeData}
                data={companyData}
                onSubmit={() => onSubmitCompany(stripe, elements)}
                subscriptionPlans={subscriptionPlans}
                onCloseForm={onOpenLogout}
                loading={loading}
              />
            );
          }}
        </ElementsConsumer>
        {logoutDialog}
      </>
    );
  }
}

CreateCompanyView.propTypes = {
  auth: PropTypes.object.isRequired,
  client: PropTypes.object.isRequired,
};

export default withRouter(withAuth(withApollo(CreateCompanyView)));
