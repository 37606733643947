import { createEvent } from '@cobuildlab/react-simple-state';

export const OnIssueCreate = createEvent();
export const OnIssueError = createEvent();
export const OnIssueAutoSave = createEvent();
export const OnIssueAutoSaveError = createEvent();
export const OnIssueDetail = createEvent();
export const OnIssueCompleted = createEvent();
export const OnIssueRestore = createEvent();
export const OnIssueUpdate = createEvent();
