import React from 'react';
import { Grid, SecondaryNavigation } from '@8base/boost';
import { Route, Switch, Redirect } from 'react-router-dom';
import { LinkItem } from '../../components/link/LinkItem';
import PropTypes from 'prop-types';
import LinkTitle from '../../components/link/LinkTitle';
import { ActiveSalesPipelineView } from './active-sales-pipeline';
import { ForecastingView } from './forecasting';
import { SalesPipelineTrendsView } from './sales-pipeline-trends';
import { PivotTableView } from './pivot-table';
import { BalancedScorecardView } from './balanced-scorecard';
import { DealsFunnelView } from './deals-funnel/DealsFunnelView';
import { AuditLogView } from './audit-log/AuditLogView';
import { isUserAllianceAdmin } from '../../shared/alliance-utils';
import sessionStore, { NEW_SESSION_EVENT } from '../../shared/SessionStore';

const ReportsView = (props) => {
  const { location } = props;
  const { user, selectedAlliance } = sessionStore.getState(NEW_SESSION_EVENT);

  return (
    <Grid.Layout className="minHeightCard" columns="215px 1fr">
      <Grid.Box>
        <SecondaryNavigation>
          <LinkTitle text="Performance" />
          <LinkItem
            to={'/reports/balanced-scorecard'}
            label={'Balanced Scorecard'}
            location={location}
            url={'balanced-scorecard'}
          />
          <LinkTitle text={'Reports'} />
          <LinkItem
            to={'/reports/active-sales-pipeline'}
            label={'Active Sales Pipeline'}
            location={location}
            url={'active-sales-pipeline'}
          />
          <LinkItem
            to={'/reports/sales-pipeline-trends'}
            label={'Sales Pipeline Trends'}
            location={location}
            url={'sales-pipeline-trends'}
          />
          <LinkItem
            to={'/reports/forecasting'}
            label={'Forecasting'}
            location={location}
            url={'forecasting'}
          />
          <LinkItem
            to={'/reports/deals-funnel'}
            label={'Deals Funnel'}
            location={location}
            url={'deals-funnel'}
          />
          <LinkItem
            to={'/reports/pivot-table'}
            label={'Alliance Analytics'}
            location={location}
            url={'pivot-table'}
          />
          {isUserAllianceAdmin(user, selectedAlliance) ? (
            <LinkItem
              to={'/reports/audit-log'}
              label={'Audit Log'}
              location={location}
              url={'audit-log'}
            />
          ) : (
            ''
          )}
        </SecondaryNavigation>
      </Grid.Box>
      <Grid.Box>
        <Switch>
          <Route path="/reports/balanced-scorecard" component={BalancedScorecardView} />
          <Route path="/reports/active-sales-pipeline" component={ActiveSalesPipelineView} />
          <Route path="/reports/sales-pipeline-trends" component={SalesPipelineTrendsView} />
          <Route path="/reports/forecasting" component={ForecastingView} />
          <Route path="/reports/pivot-table" component={PivotTableView} />
          <Route path="/reports/deals-funnel" component={DealsFunnelView} />
          <Route path="/reports/audit-log" component={AuditLogView} />
          <Redirect to="/reports/balanced-scorecard" />
        </Switch>
      </Grid.Box>
    </Grid.Layout>
  );
};

ReportsView.propTypes = {
  location: PropTypes.any.isRequired,
};

export { ReportsView };
