import React from 'react';
import PropTypes from 'prop-types';
import DetailValue from '../../../components/DetailValue';
import Status from '../../../components/Status';
import { HorizontalLineText } from '../../../components/text/HorizontalLineText';
import { Table } from '@8base/boost';
import { Link } from 'react-router-dom';
import Moment from 'react-moment';
import { TablePosition } from '../../../components/new-ui/div/TablePosition';
import { TableDetail } from '../../../components/new-ui/table/TableDetail';

const AssociatedItemsTable = (props) => {
  const { associatedItems } = props;

  let itemsComponent = 'No Deals Associated';

  if (associatedItems.length > 0) {
    itemsComponent = (
      <TablePosition>
        <TableDetail>
          <Table.Header className="justify-center-column" columns="3fr 3fr 3fr 3fr 3fr">
            <Table.HeaderCell className="name-column">Name</Table.HeaderCell>
            <Table.HeaderCell>Owner</Table.HeaderCell>
            <Table.HeaderCell>Type</Table.HeaderCell>
            <Table.HeaderCell>Due Date</Table.HeaderCell>
            <Table.HeaderCell>Status</Table.HeaderCell>
          </Table.Header>
          <Table.Body data={associatedItems} className="card-body-list-details">
            {(item, index) => {
              const { id, name, originalDueDate, type, status, owner } = item;
              const username = owner ? `${owner.firstName} ${owner.lastName}` : owner;
              return (
                <>
                  <Table.BodyRow
                    columns="3fr 3fr 3fr 3fr 3fr"
                    key={index}
                    className={'justify-center-column'}>
                    <Table.BodyCell className="justify-center-column">
                      <Link className="item-name" to={`/management/deal/${id}/`}>
                        {name}
                      </Link>
                    </Table.BodyCell>
                    <Table.BodyCell>
                      <DetailValue text={username} />
                    </Table.BodyCell>
                    <Table.BodyCell>
                      <DetailValue text={type} />
                    </Table.BodyCell>
                    <Table.BodyCell>
                      <Moment format="MMMM Do, YYYY">{originalDueDate}</Moment>
                    </Table.BodyCell>
                    <Table.BodyCell>
                      <Status status={status} />
                    </Table.BodyCell>
                  </Table.BodyRow>
                </>
              );
            }}
          </Table.Body>
        </TableDetail>
      </TablePosition>
    );
  }

  return (
    <>
      <HorizontalLineText>ASSOCIATED DEALS</HorizontalLineText>
      {itemsComponent}
    </>
  );
};

AssociatedItemsTable.propTypes = {
  associatedItems: PropTypes.object.isRequired,
};

export { AssociatedItemsTable };
