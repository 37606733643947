import React from 'react';
import PropTypes from 'prop-types';
import { Form, Row, Column } from '@8base/boost';
import { DialogForm } from '../../../components/forms/DialogForm';
import { HorizontalLine } from '../../../components/new-ui/text/HorizontalLine';
import { HorizontalLineText } from '../../../components/new-ui/text/HorizontalLineText';
import { GroupInputs } from '../../../components/new-ui/inputs/GroupInputs';
import { PlanCard } from './PlanCard';
import { MonthlyYearlyButton } from './MonthlyYearlyButton';
import { PaymentForm } from './PaymentForm';

const CompanySubscriptionDialogForm = ({
  isOpen,
  onClose,
  onChange,
  data,
  onSubmit,
  loading,
  subscriptionPlans,
  countries,
  showAnnualPrice,
  onChangeAnnualPrice,
}) => {
  const { creditCard, subscriptionPlan } = data;

  const subscriptionPlansContent = subscriptionPlans.length ? (
    <GroupInputs text={'Subscriptions Plan'}>
      <Row>
        <Column stretch alignItems="end">
          <MonthlyYearlyButton showAnnualPrice={showAnnualPrice} onChange={onChangeAnnualPrice} />
          {subscriptionPlans.map((plan, index) => {
            return (
              <PlanCard
                key={index}
                plan={plan}
                selected={subscriptionPlan}
                showAnnualPrice={showAnnualPrice}
                onSelect={(value) => onChange('subscriptionPlan', value)}
              />
            );
          })}
        </Column>
      </Row>
    </GroupInputs>
  ) : null;

  return (
    <div>
      <DialogForm
        size={'xxl'}
        isOpen={isOpen}
        title="Create your subscription"
        onCloseDialog={onClose}
        onClickCreate={onSubmit}
        createText={'Pay and Continue'}
        loading={loading}>
        <Form>
          <Form.SectionBody>
            <HorizontalLine>
              <HorizontalLineText text={'Subscribe'} />
            </HorizontalLine>

            {subscriptionPlansContent}

            <GroupInputs text={'Card'}>
              <Row growChildren gap="lg">
                <Column>
                  <PaymentForm
                    countries={countries}
                    selected={subscriptionPlan}
                    onChange={(fieldName, value) => {
                      creditCard[fieldName] = value;
                      onChange('creditCard', creditCard);
                    }}
                    data={creditCard}
                  />
                </Column>
              </Row>
            </GroupInputs>
          </Form.SectionBody>
        </Form>
      </DialogForm>
    </div>
  );
};

CompanySubscriptionDialogForm.propTypes = {
  data: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  subscriptionPlans: PropTypes.array.isRequired,
  countries: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  showAnnualPrice: PropTypes.bool.isRequired,
  onChangeAnnualPrice: PropTypes.func.isRequired,
};

export { CompanySubscriptionDialogForm };
