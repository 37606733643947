import React from 'react';
import PropTypes from 'prop-types';
import ReactCurrencyFormat from 'react-currency-format';

const CurrencyFormat = ({ symbol, extraSpaces, ...rest }) => {
  return <ReactCurrencyFormat {...rest} prefix={`${extraSpaces}${symbol} ${extraSpaces}`} />;
};

CurrencyFormat.propTypes = {
  symbol: PropTypes.string,
  decimalSeparator: PropTypes.string,
  thousandSeparator: PropTypes.string,
  extraSpaces: PropTypes.string,
};

CurrencyFormat.defaultProps = {
  symbol: '$',
  decimalSeparator: '.',
  thousandSeparator: ',',
  extraSpaces: '',
};

export { CurrencyFormat };
