import React from 'react';
import PropTypes from 'prop-types';
import { onErrorMixin } from '../../../../shared/mixins';
import '../../css/alliance.css';
import DetailValue from '../../../../components/DetailValue';
import Status from '../../../../components/Status';
import DocumentsFileComponent from '../../../../components/inputs/DocumentsFileComponent';
import {
  ALLIANCE_ORGANIZATIONAL_CHART_FILE,
  ALLIANCE_POLICY_GUIDELINES_FILE,
  ALLIANCE_DOCUMENTS_FILE,
} from '../Alliance.model';
import DetailDateValue from '../../../../components/DetailDateValue';
import { HorizontalLine } from '../../../../components/new-ui/text/HorizontalLine';
import { HorizontalLineText } from '../../../../components/text/HorizontalLineText';
import { BoderDetailView } from '../../../../components/new-ui/div/BorderDetailView';
import { Grid, Text } from '@8base/boost';
import { HeaderText } from '../../../../components/new-ui/text/HeaderText';
import { EditButton } from '../../../../components/new-ui/buttons/EditButton';
import { TablePosition } from '../../../../components/new-ui/div/TablePosition';
import { TableDetail } from '../../../../components/new-ui/table/TableDetail';
import sessionStore, { NEW_SESSION_EVENT } from '../../../../shared/SessionStore';
import { ThTitlePosition } from '../../../../components/new-ui/div/ThTitlePosition';
import { canEditAlliancePermission } from '@cobuildlab/collabtogrow-permissions';
import { CurrencyTextField } from '../../../../shared/components/CurrencyTextField';
import { getCurrencyOnSession } from '../../../../shared/alliance-utils';
import { isCurrencyTypeKPI, isPercentageTypeKPI, showMonths } from '../../../../shared/utils';
import { getKPIName } from '../../../reports/balanced-scorecard/balanced-scorecard-utils';
import { JOINT_SALES_CALLS_TYPE } from '../allianceKPIs/allianceKPIs-model';

/**
 * Detail View Table For The Alliance Entity.
 */
class AllianceDetailTable extends React.Component {
  constructor(props) {
    super(props);
    this.onError = onErrorMixin.bind(this);
  }

  render() {
    const { data, onClickEdit } = this.props;
    let {
      clientCompany,
      partnerCompany,
      organizationalChartFile,
      policyGuidelinesFile,
      documents,
      status,
      description,
      effectiveDate,
      allianceKPIAllianceRelation,
    } = data;

    const clientCompanyName = clientCompany ? clientCompany.name : '';
    let partnerCompanyName = data.partnerCompanyName || (partnerCompany ? partnerCompany.name : '');
    const { user, selectedAlliance } = sessionStore.getState(NEW_SESSION_EVENT);
    const currency = data.currency || getCurrencyOnSession();

    return (
      <>
        <HorizontalLine>
          <HorizontalLineText>ALLIANCE</HorizontalLineText>
        </HorizontalLine>
        <>
          <BoderDetailView>
            <Grid.Layout
              columns="auto 100px"
              areas={[['left', 'right']]}
              style={{ width: '100%', height: '100%' }}>
              <Grid.Box area="left">
                <div style={{ position: 'absolute', top: '21px', left: '25px' }}>
                  <HeaderText>ALLIANCE</HeaderText>
                </div>
              </Grid.Box>
              <Grid.Box area="right" justifyContent={'center'}>
                {canEditAlliancePermission(user, data, selectedAlliance) ? (
                  <EditButton onClick={onClickEdit} text="Edit" />
                ) : null}
              </Grid.Box>
            </Grid.Layout>
          </BoderDetailView>
          <TablePosition>
            <TableDetail>
              <tbody>
                <tr>
                  <th>
                    <ThTitlePosition>
                      <span>STATUS</span>
                    </ThTitlePosition>
                  </th>
                  <td>
                    <Status status={status} />
                  </td>
                </tr>
                <tr>
                  <th>
                    <ThTitlePosition>
                      <span>DESCRIPTION</span>
                    </ThTitlePosition>
                  </th>
                  <td>
                    <DetailValue text={description} />
                  </td>
                </tr>
                <tr>
                  <th>
                    <ThTitlePosition>
                      <span>CLIENT COMPANY</span>
                    </ThTitlePosition>
                  </th>
                  <td>
                    <DetailValue text={clientCompanyName} />
                  </td>
                </tr>
                <tr>
                  <th>
                    <ThTitlePosition>
                      <span>PARTNER COMPANY</span>
                    </ThTitlePosition>
                  </th>
                  <td>
                    <DetailValue text={partnerCompanyName} />
                  </td>
                </tr>
                <tr>
                  <th>
                    <ThTitlePosition>
                      <span>POLICY GUIDELINES</span>
                    </ThTitlePosition>
                  </th>
                  <td>
                    <DocumentsFileComponent
                      data={policyGuidelinesFile}
                      localKey={ALLIANCE_POLICY_GUIDELINES_FILE}
                    />
                  </td>
                </tr>
                <tr>
                  <th>
                    <ThTitlePosition>
                      <span>ORGANIZATIONAL CHART</span>
                    </ThTitlePosition>
                  </th>
                  <td>
                    <DocumentsFileComponent
                      data={organizationalChartFile}
                      localKey={ALLIANCE_ORGANIZATIONAL_CHART_FILE}
                    />
                  </td>
                </tr>
                <tr>
                  <th>
                    <ThTitlePosition>
                      <span>DOCUMENTS</span>
                    </ThTitlePosition>
                  </th>
                  <td>
                    <DocumentsFileComponent data={documents} localKey={ALLIANCE_DOCUMENTS_FILE} />
                  </td>
                </tr>
                <tr>
                  <th>
                    <ThTitlePosition>
                      <span>EFFECTIVE DATE</span>
                    </ThTitlePosition>
                  </th>
                  <td>
                    <DetailDateValue date={effectiveDate} />
                  </td>
                </tr>
                <tr>
                  <th>
                    <ThTitlePosition>
                      <span>KPI</span>
                    </ThTitlePosition>
                  </th>
                  <td>
                    {allianceKPIAllianceRelation &&
                      allianceKPIAllianceRelation.items.length > 0 &&
                      allianceKPIAllianceRelation.items
                        .filter(({ type }) => type !== JOINT_SALES_CALLS_TYPE)
                        .map((item, i) => {
                          const kpiName = getKPIName(data, item.type);
                          const currencyOptions = {};
                          if (isCurrencyTypeKPI(item.type)) {
                            currencyOptions.currency = currency;
                            currencyOptions.decimalScale = 0;
                            currencyOptions.suffix = '';
                          } else if (isPercentageTypeKPI(item.type)) {
                            currencyOptions.currency = { ...currency, symbol: '' };
                            currencyOptions.suffix = '%';
                            currencyOptions.decimalScale = 1;
                          } else {
                            currencyOptions.currency = { ...currency, symbol: '' };
                            currencyOptions.decimalScale = 0;
                            currencyOptions.suffix = '';
                          }

                          return (
                            <div key={i}>
                              <span>
                                <Text weight="medium">Type:</Text> {kpiName}
                              </span>
                              <br />
                              <ul>
                                <li>
                                  <span>
                                    <Text weight="medium">Target Distribution Type:</Text>{' '}
                                    {item.targetDistributionType}
                                  </span>
                                </li>
                                <li>
                                  <span>
                                    <Text weight="medium">Year:</Text> {item.year}
                                  </span>
                                </li>
                                <li>
                                  {item.targetDistributionType === 'MANUAL' ? (
                                    <div>
                                      {item.monthByMonth.map((value, x) => {
                                        return (
                                          <>
                                            <span key={x}>
                                              <Text weight="medium">
                                                Month: {showMonths(x)} &nbsp;
                                              </Text>
                                              <>
                                                <CurrencyTextField
                                                  value={String(value)}
                                                  currency={currencyOptions.currency}
                                                  decimalScale={currencyOptions.decimalScale}
                                                  suffix={currencyOptions.suffix}
                                                  isNumericString={true}
                                                />
                                              </>
                                            </span>
                                            <br />
                                          </>
                                        );
                                      })}
                                    </div>
                                  ) : (
                                    <span>
                                      <Text weight="medium">Target:&nbsp;</Text>
                                      <CurrencyTextField
                                        value={item.target}
                                        currency={currencyOptions.currency}
                                        decimalScale={currencyOptions.decimalScale}
                                        suffix={currencyOptions.suffix}
                                        isNumericString={true}
                                      />
                                    </span>
                                  )}
                                </li>
                              </ul>
                              <br />
                            </div>
                          );
                        })}
                  </td>
                </tr>
              </tbody>
            </TableDetail>
          </TablePosition>
        </>
      </>
    );
  }
}

AllianceDetailTable.defaultProps = {
  onClickEdit: null, // null for form previews
};

AllianceDetailTable.propTypes = {
  data: PropTypes.object.isRequired,
  onClickEdit: PropTypes.func,
};

export default AllianceDetailTable;
