import React from 'react';
import { Column, DateInputField, InputField, Row, SelectField, TextAreaField } from '@8base/boost';
import PropTypes from 'prop-types';
import { CurrencyInputField } from '../../../../shared/components/CurrencyInputField';
import { HorizontalLine } from '../../../../components/new-ui/text/HorizontalLine';
import { HorizontalLineText } from '../../../../components/new-ui/text/HorizontalLineText';
import { GroupInputs } from '../../../../components/new-ui/inputs/GroupInputs';
import sessionStore, { NEW_SESSION_EVENT } from '../../../../shared/SessionStore';

/**
 * The Form for the Action.
 */
class DealForm extends React.Component {
  render() {
    const { onChange, currency, allianceMembersOptions, dealData, dealStagesOptions } = this.props;
    const {
      customerName,
      name,
      amount,
      description,
      closeDate,
      createdDate,
      lastActivityDate,
      lastContactedDate,
      lastModifiedDate,
      nextActivityDate,
      numberOfSalesActivities,
      salesPerson,
      type,
      wonReason,
      stage,
      lostReason,
      nextActivityDescription,
      numberOfTimesContacted,
      line,
      owner,
      company,
      dealSourceId,
      associatedDealId,
      partnerRegistrationId,
      dealReferencable,
    } = dealData;
    const companyName = company.name;

    const { selectedAlliance } = sessionStore.getState(NEW_SESSION_EVENT);
    const { partnerCompany, clientCompany } = selectedAlliance;

    const companies = [clientCompany, partnerCompany].filter((company) => company);
    const companyNames = companies.map((company) => ({ label: company.name, value: company.name }));

    console.log('allianceMembersOptions', allianceMembersOptions);
    console.log('owner', owner);
    const dealReferencableList = [
      { label: 'Y', value: 'Y' },
      { label: 'N', value: 'N' },
    ];
    return (
      <>
        <HorizontalLine>
          <HorizontalLineText text={'Company: ' + companyName} />
        </HorizontalLine>
        <GroupInputs text={'General'}>
          <Row growChildren gap="lg">
            <Column>
              <InputField
                stretch
                label="Deal Name"
                input={{
                  name: 'name',
                  value: name,
                  onChange: (value) => onChange('name', value),
                }}
              />
              <SelectField
                label="Company"
                input={{
                  name: 'company',
                  value: companyName,
                  onChange: (value) => {
                    const selectedCompany = companies.find((company) => company.name === value);
                    onChange('company', selectedCompany);
                  },
                }}
                meta={{}}
                placeholder="Select"
                options={companyNames}
              />
              <TextAreaField
                style={{ width: '100%', height: 152 }}
                label="Description"
                input={{
                  name: 'description',
                  value: description,
                  onChange: (value) => onChange('description', value),
                }}
              />
            </Column>
          </Row>
        </GroupInputs>
        <GroupInputs text={'Dates'}>
          <Row growChildren gap="lg">
            <Column>
              <DateInputField
                label="Closed Date"
                input={{
                  name: 'closeDate',
                  value: closeDate,
                  onChange: (value) => onChange('closeDate', value),
                }}
              />
              <DateInputField
                label="Created Date"
                input={{
                  name: 'createdDate',
                  value: createdDate,
                  onChange: (value) => onChange('createdDate', value),
                }}
              />
              <DateInputField
                label="Last Contacted Date"
                input={{
                  name: 'lastContactedDate',
                  value: lastContactedDate,
                  onChange: (value) => onChange('lastContactedDate', value),
                }}
              />
              <DateInputField
                label="Next Activity Date	"
                input={{
                  name: 'nextActivityDate',
                  value: nextActivityDate,
                  onChange: (value) => onChange('nextActivityDate', value),
                }}
              />
              <DateInputField
                label="Last Activity Date"
                input={{
                  name: 'lastActivityDate',
                  value: lastActivityDate,
                  onChange: (value) => onChange('lastActivityDate', value),
                }}
              />
              <DateInputField
                label="Last Modified Date"
                input={{
                  name: 'lastModifiedDate',
                  value: lastModifiedDate,
                  onChange: (value) => onChange('lastModifiedDate', value),
                }}
              />
            </Column>
          </Row>
        </GroupInputs>
        <GroupInputs text={'Others'} show={true}>
          <Row growChildren gap="lg">
            <Column>
              <InputField
                stretch
                label="Sales Person"
                input={{
                  name: 'salesPerson',
                  value: salesPerson,
                  onChange: (value, e) => onChange(e.target.name, value),
                }}
              />
              <InputField
                stretch
                label="Won Reason"
                input={{
                  name: 'wonReason',
                  value: wonReason,
                  onChange: (value, e) => onChange(e.target.name, value),
                }}
              />
              <InputField
                stretch
                label="Next Activity Description"
                input={{
                  name: 'nextActivityDescription',
                  value: nextActivityDescription,
                  onChange: (value, e) => onChange(e.target.name, value),
                }}
              />
              <InputField
                stretch
                label="Number of time contacted"
                input={{
                  type: 'number',
                  name: 'numberOfTimesContacted',
                  value: numberOfTimesContacted,
                  onChange: (value, e) => onChange(e.target.name, value),
                }}
              />
              <SelectField
                label="Deal Referencable (Y/N)"
                input={{
                  name: 'dealReferencable',
                  value: dealReferencable,
                  onChange: (value) => onChange('dealReferencable', value),
                }}
                meta={{}}
                placeholder="Select a Deal Referencable"
                options={dealReferencableList}
              />
              <SelectField
                label="Owner"
                input={{
                  name: 'Owner',
                  value: owner,
                  onChange: (value) => onChange('owner', value),
                }}
                meta={{}}
                placeholder="Select a Deal Owner"
                options={allianceMembersOptions}
              />
              <InputField
                stretch
                label="Customer Name"
                input={{
                  name: 'customerName',
                  value: customerName,
                  onChange: (value, e) => onChange(e.target.name, value),
                }}
              />
              <CurrencyInputField
                currency={currency}
                label={`Amount`}
                value={amount}
                onChange={(val) => {
                  onChange('amount', val);
                }}
              />
              <InputField
                stretch
                label="Number Of Sales Activities"
                input={{
                  type: 'number',
                  name: 'numberOfSalesActivities',
                  value: numberOfSalesActivities,
                  onChange: (value, e) => onChange(e.target.name, value),
                }}
              />

              <InputField
                stretch
                label="Type"
                input={{
                  name: 'type',
                  value: type,
                  onChange: (value, e) => onChange(e.target.name, value),
                }}
              />
              {/*              <InputField
                stretch
                label="Stage"
                input={{
                  name: 'stage',
                  value: stage,
                  onChange: (value, e) => onChange(e.target.name, value),
                }}
              />*/}

              <SelectField
                label="Stage"
                input={{
                  name: 'stage',
                  value: stage,
                  onChange: (value) => onChange('stage', value),
                }}
                meta={{}}
                placeholder="Select a Deal Stage"
                options={dealStagesOptions}
              />
              <InputField
                stretch
                label="Lost Reason"
                input={{
                  name: 'lostReason',
                  value: lostReason,
                  onChange: (value, e) => onChange(e.target.name, value),
                }}
              />
              <InputField
                stretch
                label="Line"
                input={{
                  name: 'line',
                  value: line,
                  onChange: (value, e) => onChange(e.target.name, value),
                }}
              />
              <InputField
                stretch
                label="Deal Source Id"
                input={{
                  name: 'dealSourceId',
                  value: dealSourceId,
                  onChange: (value, e) => onChange(e.target.name, value),
                }}
              />
              <InputField
                stretch
                label="Associated Deal Id"
                input={{
                  name: 'associatedDealId',
                  value: associatedDealId,
                  onChange: (value, e) => onChange(e.target.name, value),
                }}
              />
              <InputField
                stretch
                label="Partner Registration Id"
                input={{
                  name: 'partnerRegistrationId',
                  value: partnerRegistrationId,
                  onChange: (value, e) => onChange(e.target.name, value),
                }}
              />
            </Column>
          </Row>
        </GroupInputs>
      </>
    );
  }
}

DealForm.propTypes = {
  currency: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  dealData: PropTypes.object.isRequired,
  allianceMembersOptions: PropTypes.array.isRequired,
  dealStagesOptions: PropTypes.array.isRequired,
};

export default DealForm;
