import { Paragraph } from '@8base/boost';
import styled from '@emotion/styled';

const CenterParagraph = styled(Paragraph)({
  margin: '10px 0',
  opacity: '0.9',
  color: '#323C47',
  fontSize: 16,
  fontWeight: 600,
});

export default CenterParagraph;
