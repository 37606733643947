import styled from 'styled-components';
import { TransparentButtonWithIcon } from '../../../../components/buttons/TransparentButtonWithIcon';

const DeleteButton = styled(TransparentButtonWithIcon)`
  padding-left: 4px !important;
  padding-right: 4px !important;
  height: 100% !important !important;
  background-color: transparent;
  & span {
    position: relative;
    top: 1px;
  }
`;

export { DeleteButton };
