import moment from 'moment';

const currentDate = moment().format('YYYY-MM-DD');

export default {
  id: null,
  name: '',
  description: '',
  currentDashboardFile: null,
  documentsFile: [],
  businessCase: null,
  owner: null,
  alliance: null,
  baselineStartDate: '',
  baselineEndDate: '',
  forecastedEndDate: null,
  requestedDate: currentDate,
  requestedBy: null,
  ragStatus: '',
};

export const INITIATIVE_DATA_STORE = 'initiativeCreateView';
export const INITIATIVE_CURRENT_DASHBOARD_DOCUMENTS = 'initiativeCurrentDashboardDocuments';
export const INITIATIVE_DOCUMENTS = 'initiativeDocuments';

export const COLUMNSEXCEL = [
  { header: 'ID', key: 'id', width: 32 },
  { header: 'NAME', key: 'name', width: 32 },
  { header: 'DESCRIPTION', key: 'description', width: 32 },
  { header: 'ALLIANCE_NAME', key: 'allianceName', width: 32 },
  { header: 'BUSINESS_CASE_STATUS', key: 'businessStatus', width: 32 },
  { header: 'BUSINESS_CASE_BACKGROUND_SUMMARY', key: 'backgroundSummary', width: 32 },
  { header: 'BUSINESS_CASE_VISION_MISSION_STRATEGY', key: 'visionMissionStrategy', width: 32 },
  { header: 'BUSINESS_CASE_MARKET_ANALISYS', key: 'marketAnalysis', width: 32 },
  { header: 'BUSINESS_CASE_SALES_MARKETING_STRATEGY', key: 'salesMarketingStrategy', width: 32 },
  { header: 'BUSINESS_CASE_RECOMMENDED_SOLUTION', key: 'recommendedSolution', width: 32 },
  { header: 'BUSINESS_CASE_RISK', key: 'risks', width: 32 },
  { header: 'BUSINESS_OWNER_EMAIL', key: 'businessCaseOwnerEmail', width: 32 },
  { header: 'BUSINESS_OWNER_FIRSTNAME', key: 'businessCaseOwnerFirstName', width: 32 },
  { header: 'BUSINESS_OWNER_LASTNAME', key: 'businessCaseOwnerLastName', width: 32 },
  { header: 'BUSINESS_ANTICIPATED_COST', key: 'anticipatedCosts', width: 32 },
  { header: 'BUSINESS_EXPECTED_COST', key: 'expectedCosts', width: 32 },
  { header: 'BUSINESS_EXPECTED_REVENUED', key: 'expectedRevenues', width: 32 },
  { header: 'OWNER_EMAIL', key: 'ownerEmail', width: 32 },
  { header: 'OWNER_FIRSTNAME', key: 'ownerFirstName', width: 32 },
  { header: 'OWNER_LASTNAME', key: 'ownerLastName', width: 32 },
  { header: 'REQUESTED_BY_EMAIL', key: 'requestedByEmail', width: 32 },
  { header: 'REQUESTED_BY_FIRSTNAME', key: 'requestedByFirstName', width: 32 },
  { header: 'REQUESTED_BY_LASTNAME', key: 'requestedByLastName', width: 32 },
  { header: 'REQUESTED_DATE', key: 'requestedDate', width: 32 },
  { header: 'STATUS', key: 'status', width: 32 },
];

export const COLUMNSOBJECT = [
  { key: 'alliance', value: [{ allianceName: 'name' }] },
  {
    key: 'businessCase',
    value: [
      { businessStatus: 'status' },
      { backgroundSummary: 'backgroundSummary' },
      { visionMissionStrategy: 'visionMissionStrategy' },
      { marketAnalysis: 'marketAnalysis' },
      { salesMarketingStrategy: 'salesMarketingStrategy' },
      { recommendedSolution: 'recommendedSolution' },
      { anticipatedCosts: 'anticipatedCosts' },
      { expectedCosts: 'expectedCosts' },
      { expectedRevenues: 'expectedRevenues' },
      { risks: 'risks' },
      {
        owner: {
          businessCaseOwnerEmail: 'email',
          businessCaseOwnerFirstName: 'firstName',
          businessCaseOwnerLastName: 'lastName',
        },
      },
    ],
  },
  {
    key: 'owner',
    value: [
      { ownerEmail: 'email' },
      { ownerFirstName: 'firstName' },
      { ownerLastName: 'lastName' },
    ],
  },
  {
    key: 'requestedBy',
    value: [
      { requestedByEmail: 'email' },
      { requestedByFirstName: 'firstName' },
      { requestedByLastName: 'lastName' },
    ],
  },
];
