import gql from 'graphql-tag';

export const AllianceKPIFragment = gql`
  fragment AllianceKPIFragment on AllianceKPI {
    id
    type
    year
    target
    monthByMonth
    targetDistributionType
  }
`;

export const ALLIANCE_KPI_UPDATE_MUTATION = gql`
  mutation($data: AllianceKPIUpdateInput!) {
    allianceKPIUpdate(data: $data) {
      id
    }
  }
`;

export const ALLIANCE_KPI_DELETE_MUTATION = gql`
  mutation($data: AllianceKPIDeleteInput!) {
    allianceKPIDelete(data: $data) {
      success
    }
  }
`;
