import React from 'react';
import PropTypes from 'prop-types';
import collaborateIcon from '../../images/icons/collab-chat-icon.svg';
import { ActionButtonClose } from './ActionButtonClose';
import { TransparentButtonSvg } from './TransparentButtonSvg';

const TopButtons = ({ onClickClosed, onClickCollaborated }) => {
  return (
    <div className="company-icons top-icons">
      <TransparentButtonSvg
        text={'Collaborate'}
        onClick={onClickCollaborated}
        iconSvg={collaborateIcon}
      />
      <ActionButtonClose onClick={onClickClosed} />
    </div>
  );
};

TopButtons.propTypes = {
  onClickClosed: PropTypes.func.isRequired,
  onClickCollaborated: PropTypes.func.isRequired,
};

export { TopButtons };
