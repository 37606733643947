export default {
  id: null,
  name: '',
  description: '',
  missionAndGoals: '',
  website: '',
  country: '',
  address1: '',
  address2: '',
  zipCode: '',
  city: '',
  state: '',
  phone: '',
  numberOfEmployees: '',
  annualRevenue: '0',
  parentCompany: '',
  companyStatus: 'Public',
  tickerSymbol: '',
  industry: '',
  logoFile: null,
};
