import React from 'react';
import { Field } from 'react-final-form';
import PropTypes from 'prop-types';

class ConditionalField extends React.PureComponent {
  componentWillUnmount() {
    this.props.changeFormValue(this.props.name, undefined);
  }

  render() {
    const { changeFormValue, ...restProps } = this.props;
    return <Field {...restProps} />;
  }
}

ConditionalField.propTypes = {
  name: PropTypes.string.isRequired,
  changeFormValue: PropTypes.func.isRequired,
};

export { ConditionalField };
