import React from 'react';
import { Card, Heading, Column, Text } from '@8base/boost';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Wrapper = styled.div`
  cursor: pointer;
`;

const ItemsCard = ({ width = '40px', count, text, ...rest }) => {
  const Bar = styled.div`
    width: ${width};
    height: 8px;
    border-radius: 10px;
    background-color: #d0d7dd;
    margin: 0 0 30px 0 !important;
  `;
  const CardComponent = Wrapper;

  return (
    <CardComponent style={{ border: '1px solid #EAEFF3' }} {...rest} className="ItemsCard">
      <Card.Body>
        <Column alignItems="center">
          <Bar />
          <Heading type="h1">{count || '0'}</Heading>
          <Text style={{ color: '#D0D7DD' }}>{text}</Text>
        </Column>
      </Card.Body>
    </CardComponent>
  );
};

ItemsCard.propTypes = {
  count: PropTypes.number.isRequired,
  text: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
};

export default ItemsCard;
