import moment from 'moment';

const currentDate = moment().format('YYYY-MM-DD');

export default {
  id: null,
  name: '',
  description: '',
  documents: [],
  assignedTo: null,
  initiatives: [],
  nextSteps: [],
  requestedBy: null,
  requestedDate: currentDate,
  originalDueDate: null,
  revisedDueDate: null,
  assignedDate: currentDate,
  createdAt: currentDate,
  unitType: null,
  unitQuantity: 0,
  unitValueDescription: '',
  unitMonetizationFactor: '0',
  source: null,
};

export const ACTION_DOCUMENTS = 'actionDocuments';
