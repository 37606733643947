import sessionStore, { NEW_SESSION_EVENT } from '../../../shared/SessionStore';

export const AUDIT_ACTION_TYPE_OPTIONS = [
  {
    label: 'CREATE',
    value: 'CREATE',
  },
  {
    label: 'UPDATE',
    value: 'UPDATE',
  },
  {
    label: 'DELETE',
    value: 'DELETE',
  },
  {
    label: 'LOGIN',
    value: 'LOGIN',
  },
  {
    label: 'LOGOUT',
    value: 'LOGOUT',
  },
  {
    label: 'ACCEPT',
    value: 'ACCEPT',
  },
  {
    label: 'NONE',
    value: '',
  },
];

export const AUDIT_OBJECT_TYPE_OPTIONS = [
  {
    label: 'ACTION',
    value: 'ACTION',
  },
  {
    label: 'ALLIANCE',
    value: 'ALLIANCE',
  },
  {
    label: 'RISK',
    value: 'RISK',
  },
  {
    label: 'IDEA',
    value: 'IDEA',
  },
  {
    label: 'DECISION',
    value: 'DECISION',
  },
  {
    label: 'ISSUE',
    value: 'ISSUE',
  },
  {
    label: 'FUNDING REQUEST',
    value: 'FUNDINGREQUEST',
  },
  {
    label: 'ALLIANCE FORECAST',
    value: 'ALLIANCEFORECAST',
  },
  {
    label: 'DEAL',
    value: 'DEAL',
  },
  {
    label: 'CONTRIBUTION',
    value: 'CONTRIBUTION',
  },
  {
    label: 'INITIATIVE',
    value: 'INITIATIVE',
  },
  {
    label: 'ALLIANCE DELETED',
    value: 'ALLIANCEDELETED',
  },
  {
    label: 'ALLIANCE MEMBER INVITATION',
    value: 'ALLIANCEMEMBERINVITATION',
  },
  {
    label: 'USER',
    value: 'USER',
  },
  {
    label: 'NONE',
    value: '',
  },
];

/**
 *
 * @returns {[{label: string, value: string}]} - Object.
 */
export const auditLogCompanyOptions = () => {
  const { selectedAlliance } = sessionStore.getState(NEW_SESSION_EVENT);
  const filter = [
    {
      label: 'Both Companies',
      value: '',
    },
  ];

  if (selectedAlliance.clientCompany) {
    const clientCompany = {
      label: selectedAlliance.clientCompany.name,
      value: selectedAlliance.clientCompany.id,
    };
    filter.push(clientCompany);
  }
  if (selectedAlliance.partnerCompany) {
    const partnerCompany = {
      label: selectedAlliance.partnerCompany.name,
      value: selectedAlliance.partnerCompany.id,
    };
    filter.push(partnerCompany);
  }

  return filter;
};
