import React from 'react';
import PropTypes from 'prop-types';
import DetailValue from '../../../components/DetailValue';
import Status from '../../../components/Status';
import { Table } from '@8base/boost';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import Moment from 'react-moment';
import { HorizontalLineText } from '../../../components/text/HorizontalLineText';
import { ListCardBody } from '../../../components/card/ListCardBody';
import { TablePosition } from '../../../components/new-ui/div/TablePosition';

const RelatedItemsByItemDetailTable = (props) => {
  const { relatedItemsByItem } = props;

  let itemsComponent = 'No items selected';

  const StyledListCardBody = styled(ListCardBody)`
    min-height: 20vh !important;
  `;

  if (relatedItemsByItem.length > 0) {
    itemsComponent = (
      <StyledListCardBody>
        <TablePosition>
          <Table.Header className="justify-center-column" columns="3fr 3fr 3fr 3fr 3fr">
            <Table.HeaderCell className="name-column">Name</Table.HeaderCell>
            <Table.HeaderCell>Owner</Table.HeaderCell>
            <Table.HeaderCell>Type</Table.HeaderCell>
            <Table.HeaderCell>Due Date</Table.HeaderCell>
            <Table.HeaderCell>Status</Table.HeaderCell>
          </Table.Header>
          <Table.Body data={relatedItemsByItem} className="card-body-list-details">
            {(item, index) => {
              const { id, name, originalDueDate, type, status, owner, url } = item;
              return (
                <>
                  <Table.BodyRow
                    columns="3fr 3fr 3fr 3fr 3fr"
                    key={index}
                    className={'justify-center-column'}>
                    <Table.BodyCell className="justify-center-column">
                      <Link className="item-name" to={`/management/${url}/${id}/`}>
                        {name}
                      </Link>
                    </Table.BodyCell>
                    <Table.BodyCell>
                      <DetailValue text={owner} />
                    </Table.BodyCell>
                    <Table.BodyCell>
                      <DetailValue text={type} />
                    </Table.BodyCell>
                    <Table.BodyCell>
                      <Moment format="MMMM Do, YYYY">{originalDueDate}</Moment>
                    </Table.BodyCell>
                    <Table.BodyCell>
                      <Status status={status} />
                    </Table.BodyCell>
                  </Table.BodyRow>
                </>
              );
            }}
          </Table.Body>
        </TablePosition>
      </StyledListCardBody>
    );
  }

  return (
    <>
      <HorizontalLineText>RELATED ITEMS BY ITEM</HorizontalLineText>
      {itemsComponent}
    </>
  );
};

RelatedItemsByItemDetailTable.propTypes = {
  relatedItemsByItem: PropTypes.object.isRequired,
};

export { RelatedItemsByItemDetailTable };
