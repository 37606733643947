import { requestApprovalForAction } from '../action/action-actions';
import { formatUser } from '../../../shared/utils';

/**
 * Return the [type, url, id] of the Item.
 *
 * @param {object} item - The AMO Item.
 * @returns {object} Amo item type.
 */
export const getAMOItemType = (item) => {
  if (item.action !== null) {
    if (item.action.businessCase === null)
      return {
        type: 'Action',
        url: 'action-without-business-case',
        id: item.action.id,
        itemId: item.id,
        name: item.action.name,
        status: item.action.status,
        createdAt: item.action.createdAt,
        revisedDueDate: item.action.revisedDueDate,
        originalDueDate: item.action.originalDueDate,
        requestedBy: item.action.requestedBy ? formatUser(item.action.requestedBy) : null,
        initiatives: item.action.initiatives,
        approvalFunction: null,
        approvalItems: null,
      };
    return {
      type: 'Action',
      url: 'action',
      id: item.action.id,
      itemId: item.id,
      name: item.action.name,
      status: item.action.status,
      createdAt: item.action.createdAt,
      revisedDueDate: item.action.revisedDueDate,
      originalDueDate: item.action.originalDueDate,
      requestedBy: item.action.requestedBy ? formatUser(item.action.requestedBy) : null,
      initiatives: item.action.initiatives,
      approvalFunction: requestApprovalForAction,
      approvalItems: item.action.actionApprovalRelation.items,
    };
  }
  if (item.issue !== null)
    return {
      type: 'Issue',
      url: 'issue',
      id: item.issue.id,
      itemId: item.id,
      name: item.issue.name,
      status: item.issue.status,
      createdAt: item.issue.createdAt,
      revisedDueDate: item.issue.revisedDueDate,
      originalDueDate: item.issue.originalDueDate,
      initiatives: item.issue.initiatives,
      approvalFunction: null,
      approvalItems: null,
      createdBy: item.issue.createdBy ? formatUser(item.issue.createdBy) : null,
    };

  if (item.decision !== null) {
    return {
      type: 'Decision',
      url: 'decision',
      id: item.decision.id,
      itemId: item.id,
      name: item.decision.name,
      status: item.decision.status,
      createdAt: item.decision.createdAt,
      revisedDueDate: '',
      initiatives: item.decision.initiatives,
      approvalItems: null,
      createdBy: item.decision.createdBy ? formatUser(item.decision.createdBy) : null,
    };
  }
  if (item.risk !== null)
    return {
      type: 'Risk',
      url: 'risk',
      id: item.risk.id,
      itemId: item.id,
      name: item.risk.name,
      status: item.risk.status,
      createdAt: item.risk.createdAt,
      revisedDueDate: item.risk.revisedDueDate,
      originalDueDate: item.risk.originalDueDate,
      initiatives: item.risk.initiatives,
      approvalFunction: null,
      approvalItems: null,
      createdBy: item.risk.createdBy ? formatUser(item.risk.createdBy) : null,
    };
  return null;
};

export const DECISION_UNIT_TYPE = ['PMO', 'CLIENT REFERENCE', 'OTHER'];
