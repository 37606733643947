import { isProcurerInCompany } from '@cobuildlab/collabtogrow-permissions';

/**
 * Can Cancel companySubscription permission.
 *
 * @param  {object} user - The current user.
 * @param  {object} company - The company.
 * @returns {boolean} If the user has permission to cancel the stripe subscription.
 */
export const canCancelSubscription = (user, company) => {
  return isProcurerInCompany(user, company);
};

/**
 * Can Change companySubscription plan permission.
 *
 * @param  {object} user - The current user.
 * @param  {object} company - The company.
 * @param  {object} subscriptionPlan - The new subscriptionPlan.
 * @returns {boolean} If the user has permission to change the stripe subscription plan.
 */
export const canChangeCompanySubscriptionPlan = (user, company, subscriptionPlan) => {
  return isProcurerInCompany(user, company) && subscriptionPlan.active;
};

/**
 * Can Change companySubscription paymentMethod plan permission.
 *
 * @param  {object} user - The current user.
 * @param  {object} company - The company.
 * @returns {boolean} If the user has permission to change the stripe subscription payment.
 */
export const canChangeCompanySubscriptionPaymentMethod = (user, company) => {
  return isProcurerInCompany(user, company);
};

/**
 * Can Create companySubscription permission.
 *
 * @param  {object} user - The current user.
 * @param  {object} company - The company.
 * @param  {object} companySubscription - The new companySubscription.
 * @returns {boolean} If the user has permission to create the stripe subscription.
 */
export const canCreateCompanySubscription = (user, company, companySubscription) => {
  return (
    isProcurerInCompany(user, company) &&
    (!companySubscription || !companySubscription.stripeSubscriptionId)
  );
};
