import React from 'react';
import { Text } from '@8base/boost';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';

const StyledButton = styled(Text)`
  font-family: Poppins;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 0.2px;
  line-height: 20px;
  color: #323c47;
  & first-letter {
    text-transform: capitalize;
  }
`;

const Button = ({ children }) => {
  return <StyledButton>{children}</StyledButton>;
};

Button.propTypes = {
  children: PropTypes.any.isRequired,
};

export { Button };
