import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from '@emotion/styled';
import { faMinusCircle } from '@fortawesome/free-solid-svg-icons';

/**
 * Delete icon button for form removable inputs.
 */
const IconContainer = styled('div')`
  height: 100%;
  padding-top: 26px;
`;

const DeleteIcon = ({ onClick }) => {
  return (
    <IconContainer className="clickable-label" onClick={onClick}>
      <div className="clickable-icon">
        <FontAwesomeIcon icon={faMinusCircle} />
      </div>
    </IconContainer>
  );
};

DeleteIcon.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default DeleteIcon;
