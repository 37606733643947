import { ALLIANCE_ADMINISTRATOR, ALLIANCE_SER } from '../../../shared/roles';
import { CONTRIBUTION_COMPLETED } from '../../../shared/status';
import {
  getRoleOnAlliance,
  isUserAdminOrSERInAlliance,
  isUserCreatorInAlliance,
} from '../../../shared/alliance-utils';
import { isAllianceCompleted } from '@cobuildlab/collabtogrow-permissions';

/**
 * Checks if a User can Edit A Issue Item.
 *
 * @param {object}user - User.
 * @param {object}contribution - Contribution.
 * @param {object}alliance - Alliance.
 * @returns {boolean} Validate Options.
 */
export const canEditContribution = (user, contribution, alliance): boolean => {
  if (isAllianceCompleted(alliance)) return false;
  if (contribution.status === CONTRIBUTION_COMPLETED) return false;

  if (isUserAdminOrSERInAlliance(user, alliance)) return true;

  if (isUserCreatorInAlliance(user, alliance)) return true;

  return false;
};

/**
 * Checks if a User can Complete A Issue Item.
 *
 * @param {object}user - User.
 * @param {object}contribution - Contribution.
 * @param {object}alliance - Alliance.
 * @returns {boolean} Validate Options.
 */
export const canCompletedContribution = (user, contribution, alliance) => {
  if (isAllianceCompleted(alliance)) return false;

  if (contribution.status === CONTRIBUTION_COMPLETED) return false;

  const role = getRoleOnAlliance(user, alliance);
  if (!role) return false;
  return [ALLIANCE_ADMINISTRATOR, ALLIANCE_SER].includes(role.name);
};

/**
 * Checks if a User can Restore A Issue Item.
 *
 * @param {object}user - User.
 * @param {object}contribution - Contribution.
 * @param {object}alliance - Alliance.
 * @returns {boolean} Validate Options.
 */
export const canRestoreContribution = (user, contribution, alliance) => {
  if (isAllianceCompleted(alliance)) return false;

  if (contribution.status !== CONTRIBUTION_COMPLETED) return false;

  const role = getRoleOnAlliance(user, alliance);
  if (!role) return false;
  return [ALLIANCE_ADMINISTRATOR, ALLIANCE_SER].includes(role.name);
};
