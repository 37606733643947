import React from 'react';
import { Row, Column, Card, SelectField, Loader, Grid } from '@8base/boost';
import {
  KPITitle,
  KPIProgress,
  ScorecardSection,
  ScorecardDate,
  ScorecardTarget,
  DetailCardFooter,
  BalanceScorecardChartLegend,
} from './';
import { getKPIName, getRelationshipScorecardData } from '../balanced-scorecard-utils';
import { KPI_FIELDS } from '../../reports-model';
import { KPICharts } from './KPICharts';
import { View } from '@cobuildlab/react-simple-state';
import { onErrorMixin } from '../../../../shared/mixins';
import { fetchBalancedScorecardData } from '../balanced-scorecard-actions';
import {
  CUSTOMER_SATISFACTION_SCORE_TYPE,
  JOINT_TRAINING_INVESTMENT_TYPE,
  KPI_WITH_CURRENCY_TYPES,
  PROFESSIONALS_TRAINED_TYPE,
  PROFESSIONALS_CERTIFIED_TYPE,
} from '../../../settings/alliance-management/allianceKPIs/allianceKPIs-model';
import { ContributionList } from './ContributionList';
import { DealList } from './DealList';
import moment from 'moment';
import * as R from 'ramda';
import { getCurrencyOnSession } from '../../../../shared/alliance-utils';
import sessionStore, { NEW_SESSION_EVENT } from '../../../../shared/SessionStore';
import { MONTHS_RANGES } from '../balanced-scorecard-model';
import { getStartEndAndTotalMonths } from '../balanced-scorecard-utils';
import { getKPIYears } from '../../../settings/alliance-management/allianceKPIs/allianceKPIs-actions';
import { OnBalancedScorecardData, OnBalancedScorecardError } from '../balanced-scorecard-events';

class ScorecardRelationshipDetail extends View {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      relationshipKPIs: [],
      selectedRelationshipKPI: null,
      selectedKPIYear: moment().year(),
      kpiYears: [],
      selectedMonthsRange: MONTHS_RANGES[0].value,
    };

    this.onError = onErrorMixin.bind(this);
    this.selectedAlliance = sessionStore.getState(NEW_SESSION_EVENT).selectedAlliance;
  }

  componentDidMount() {
    this.subscribe(OnBalancedScorecardError, this.onError);

    this.subscribe(OnBalancedScorecardData, this.setBalancedScorecardData);

    fetchBalancedScorecardData(R.clone(this.state.selectedKPIYear));
  }

  setBalancedScorecardData = async (data) => {
    const year = R.clone(this.state.selectedKPIYear);
    const selectedMonthsRange = JSON.parse(this.state.selectedMonthsRange);

    const kpis = data.kpis.map((kpi) => ({
      ...kpi,
    }));

    const deals = data.deals.map((deal) => ({
      ...deal,
    }));

    const contributions = data.contributions.map((contribution) => ({
      ...contribution,
    }));

    const alliance = R.clone(data.alliance);

    const relationshipKPIs = await getRelationshipScorecardData(
      kpis,
      deals,
      contributions,
      year,
      selectedMonthsRange,
      alliance,
    );
    const { kpiYears } = getKPIYears(alliance, alliance.businessCase);

    const kpiFilter = (kpiData) => {
      const kpi = kpis.find((kpi) => kpi.type === kpiData.type && kpi.year === year);
      return +kpi.target > 0;
    };

    const filteredRelationshipKPIs = relationshipKPIs.filter(kpiFilter);

    const selectedRelationshipKPI = filteredRelationshipKPIs.length
      ? filteredRelationshipKPIs[0]
      : null;
    this.setState({
      kpiYears,
      relationshipKPIs: filteredRelationshipKPIs,
      selectedRelationshipKPI,
      selectedKPIYear: year,
      loading: false,
    });
  };

  onSelectedKPI = (selectedRelationshipKPI) => {
    this.setState({ selectedRelationshipKPI });
  };

  onSelectedYearChange = (year) => {
    this.setState({ selectedKPIYear: year, loading: true }, () => {
      fetchBalancedScorecardData(R.clone(this.state.selectedKPIYear));
    });
  };

  onSelectedMonthsRange = (selectedMonthsRange) => {
    this.setState({ selectedMonthsRange, loading: true }, () => {
      const data = OnBalancedScorecardData.get();
      if (!data) {
        fetchBalancedScorecardData(R.clone(this.state.selectedKPIYear));
      }
      this.setBalancedScorecardData(data);
    });
  };

  render() {
    const {
      loading,
      relationshipKPIs,
      selectedRelationshipKPI,
      kpiYears,
      selectedKPIYear,
      selectedMonthsRange,
    } = this.state;
    const { onSelectedMonthsRange } = this;
    const currency = getCurrencyOnSession();
    const { startMonth, endMonth } = getStartEndAndTotalMonths(JSON.parse(selectedMonthsRange));

    let content = <></>;
    let list = <></>;

    if (loading)
      content = (
        <Row growChildren gap="lg" offsetY="lg" offsetX="lg">
          <Loader stretch />
        </Row>
      );

    if (!loading && selectedRelationshipKPI) {
      const kpiCurrency = KPI_WITH_CURRENCY_TYPES.includes(selectedRelationshipKPI.type)
        ? currency
        : null;

      const kpiName = getKPIName(this.selectedAlliance, selectedRelationshipKPI.type);
      const monthsRange = JSON.parse(selectedMonthsRange);

      content = (
        <Card.Body padding="none">
          <Row>
            <Column style={{ width: '50%' }} alignItems="stretch">
              <KPICharts
                selectedKPI={selectedRelationshipKPI}
                currency={kpiCurrency}
                monthsRange={monthsRange}
              />
              <div style={{ paddingLeft: '20%' }}>
                <BalanceScorecardChartLegend
                  name={`${kpiName} - Cumulative Actuals `}
                  color={'#3EB7F9'}
                />
                <BalanceScorecardChartLegend
                  name={`${kpiName} Cumulative Target `}
                  color={'#70D34C'}
                />
              </div>
            </Column>
            <Column
              style={{ width: '50%', borderLeft: '1px solid #e9eff4', minHeight: '465px' }}
              alignItems="stretch">
              {relationshipKPIs.map((kpi, i) => {
                const selected = kpi.name === selectedRelationshipKPI.name;

                return (
                  <ScorecardSection
                    selected={selected}
                    onClick={() => this.onSelectedKPI(kpi)}
                    key={i}>
                    <Row growChildren>
                      <Column alignItems="start">
                        <KPITitle text={kpi.name} selected={selected} />
                      </Column>
                      <Column alignItems="end">
                        <Row>
                          <ScorecardTarget
                            currency={currency}
                            kpiType={kpi.type}
                            fullYearTarget={kpi.fullYearTarget}
                            proRataTarget={kpi.proRataTarget}
                            kpiCurrent={kpi.current}
                          />
                        </Row>
                      </Column>
                    </Row>
                  </ScorecardSection>
                );
              })}

              <DetailCardFooter>
                <ScorecardDate
                  startMonth={startMonth}
                  endMonth={endMonth}
                  year={selectedKPIYear}
                  kpiName={selectedRelationshipKPI.name}
                />
                <KPIProgress
                  kpiType={selectedRelationshipKPI.type}
                  kpiTarget={selectedRelationshipKPI.fullYearTarget}
                  current={selectedRelationshipKPI.current}
                  text="Full Period"
                  currency={kpiCurrency}
                />
                <KPIProgress
                  kpiType={selectedRelationshipKPI.type}
                  kpiTarget={selectedRelationshipKPI.proRataTarget}
                  current={selectedRelationshipKPI.current}
                  text="Pro Rata"
                  currency={kpiCurrency}
                />
              </DetailCardFooter>
            </Column>
          </Row>
        </Card.Body>
      );

      const contributionTypeKPIs = [
        JOINT_TRAINING_INVESTMENT_TYPE,
        CUSTOMER_SATISFACTION_SCORE_TYPE,
        PROFESSIONALS_TRAINED_TYPE,
        PROFESSIONALS_CERTIFIED_TYPE,
      ];

      if (contributionTypeKPIs.includes(selectedRelationshipKPI.type)) {
        list = <ContributionList selectedKPI={selectedRelationshipKPI} />;
      } else {
        list = <DealList selectedKPI={selectedRelationshipKPI} />;
      }
    }

    return (
      <>
        <Card>
          <Card.Header>
            <SelectField
              input={{
                name: 'optionKPI',
                value: 'relationship',
                onChange: (value) => {
                  this.props.history.push(`/reports/balanced-scorecard/${value}/`);
                },
              }}
              meta={{}}
              options={KPI_FIELDS}
              style={{ width: '156px', height: '37px' }}
            />
            <Grid.Box direction="row" justifySelf="end" alignSelf="center">
              <SelectField
                style={{ width: '150px', alignSelf: 'end', marginRight: '15px' }}
                input={{
                  name: 'monthsRangeSelector',
                  value: selectedMonthsRange,
                  onChange: (value) => onSelectedMonthsRange(value),
                }}
                placeholder={'Months Range'}
                options={MONTHS_RANGES}
              />
              {kpiYears.length ? (
                <SelectField
                  style={{ width: '150px', alignSelf: 'end', marginRight: '15px' }}
                  input={{
                    name: 'yearFilter',
                    value: selectedKPIYear,
                    onChange: (year) => this.onSelectedYearChange(year),
                  }}
                  placeholder={'Select a Year'}
                  options={kpiYears.map((year) => {
                    return { label: year, value: year };
                  })}
                />
              ) : null}
            </Grid.Box>
          </Card.Header>
          {content}
        </Card>
        {list}
      </>
    );
  }
}

// ScorecardStrategyDetail.propTypes = {
//   kpi: PropTypes.object.isRequired,
// };

export { ScorecardRelationshipDetail };
