import styled from '@emotion/styled';
import { TransparentButtonWithIcon } from '../../../components/buttons/TransparentButtonWithIcon';

const DeleteButton = styled(TransparentButtonWithIcon)`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 18px;
  height: 36px;
`;

export { DeleteButton };
