import React from 'react';
import PropTypes from 'prop-types';

import { DataRow } from '../Components';

const DataRows = ({ rowsList, currencyFields }) => (
  <tbody>
    {rowsList.map((row, index) => (
      <DataRow row={row} currencyFields={currencyFields} key={index} />
    ))}
  </tbody>
);

DataRows.propTypes = {
  rowsList: PropTypes.array.isRequired,
  currencyFields: PropTypes.array.isRequired,
};

export { DataRows };
