import React from 'react';
import PropTypes from 'prop-types';
import { Form, Row, Column, InputField, TextAreaField, Checkbox } from '@8base/boost';
import { DialogForm } from '../../../../components/forms/DialogForm';
import { HorizontalLine } from '../../../../components/new-ui/text/HorizontalLine';
import { HorizontalLineText } from '../../../../components/new-ui/text/HorizontalLineText';
import { GroupInputs } from '../../../../components/new-ui/inputs/GroupInputs';
import { PlanCard } from '../../../procurer/components/PlanCard';
import { MonthlyYearlyButton } from '../../../procurer/components/MonthlyYearlyButton';
import { Small2 } from '../../../../components/new-ui/font-style/Small2';
import { CompanyInitialForm } from '../company-models';
import { PaymentForm } from '../../../procurer/components/PaymentForm';
import sessionStore, { NEW_SESSION_EVENT } from '../../../../shared/SessionStore';

const CompanyDialogForm = ({
  isOpen,
  onCloseForm,
  onChange,
  data,
  onSubmit,
  loading,
  subscriptionPlans,
  countries,
  showAnnualPrice,
  onChangeYearlyPrice,
}: {
  data: CompanyInitialForm,
}) => {
  const {
    name,
    description,
    creditCard,
    portfolioOwnerEmail,
    isCompanyPortfolioOwner,
    subscriptionPlan,
  } = data;
  const { user } = sessionStore.getState(NEW_SESSION_EVENT);

  const subscriptionPlansContent = subscriptionPlans.length ? (
    <GroupInputs text={'Subscriptions Plan'}>
      <Row>
        <Column stretch alignItems="end">
          <MonthlyYearlyButton showAnnualPrice={showAnnualPrice} onChange={onChangeYearlyPrice} />
          {subscriptionPlans.map((plan, index) => {
            return (
              <PlanCard
                key={index}
                plan={plan}
                selected={subscriptionPlan}
                showAnnualPrice={showAnnualPrice}
                onSelect={(value) => onChange('subscriptionPlan', value)}
              />
            );
          })}
        </Column>
      </Row>
    </GroupInputs>
  ) : null;

  return (
    <div>
      <DialogForm
        size={'xxl'}
        isOpen={isOpen}
        title="Create your first company"
        onCloseDialog={onCloseForm}
        onClickCreate={onSubmit}
        createText={'Pay and Continue'}
        loading={loading}>
        <Form>
          <Form.SectionBody>
            <HorizontalLine>
              <HorizontalLineText text={'COMPANY'} />
            </HorizontalLine>
            <GroupInputs text={'General'}>
              <Row style={{ marginBottom: '10px', margin: '0 auto' }} justifyContent="center">
                <Column />
                <Column />
              </Row>
              <Row growChildren gap="lg">
                <Column>
                  <InputField
                    stretch
                    placeholder="Name of your Company"
                    label="Company Name"
                    input={{
                      name: 'name',
                      value: name,
                      onChange: (value, e) => onChange('name', value),
                    }}
                  />
                  <TextAreaField
                    style={{ width: '100%', height: 121 }}
                    placeholder="Description of your Company"
                    label="Description"
                    input={{
                      name: 'description',
                      value: description,
                      onChange: (value) => onChange('description', value),
                    }}
                  />
                </Column>
              </Row>
            </GroupInputs>

            {subscriptionPlansContent}

            <GroupInputs text={'Card'}>
              <Row growChildren gap="lg">
                <Column>
                  <PaymentForm
                    countries={countries}
                    selected={subscriptionPlan}
                    onChange={(fieldName, value) => {
                      creditCard[fieldName] = value;
                      onChange('creditCard', creditCard);
                    }}
                    data={creditCard}
                  />
                </Column>
              </Row>
            </GroupInputs>
            <GroupInputs text={'Procurement Role'} show={true}>
              <Row>
                <Column>
                  <Small2>
                    The Procurement Role is in charge of managing the Billing of the Company:
                    <p>- Manages Subscriptions </p>
                    <p>- Payment Methods</p>
                  </Small2>
                  <InputField
                    disabled={true}
                    label="Procurement Role"
                    placeholder="Email"
                    input={{
                      name: 'procurementEmail',
                      type: 'text',
                      value: user.email,
                    }}
                  />
                </Column>
              </Row>
            </GroupInputs>
            <GroupInputs text={'Portfolio Owner'} show={true}>
              <Row growChildren gap="lg">
                <Column>
                  <Small2>
                    {/* eslint-disable-next-line react/no-unescaped-entities */}
                    The Portfolio Owner role will be the sole manager of your company's profile
                    information and will have the ability to manage alliances and invite other
                    Company Administrators (Alliance Managers) to manage alliances. The Portfolio
                    Owner is, in effect, the business manager of the Succeed Platform for your
                    company.
                  </Small2>
                  <Checkbox
                    id="bePortfolioOwnerCheckbox"
                    label={'I will be the Company Portfolio Owner'}
                    onChange={(value) => onChange('isCompanyPortfolioOwner', value)}
                    checked={isCompanyPortfolioOwner}
                    className={'companyPortfolioOwner'}
                  />
                  <InputField
                    disabled={isCompanyPortfolioOwner}
                    label="Portfolio Owners email"
                    placeholder="Email"
                    input={{
                      name: 'portfolioOwnerEmail',
                      type: 'text',
                      value: isCompanyPortfolioOwner ? user.email : portfolioOwnerEmail,
                      onChange: (value) => onChange('portfolioOwnerEmail', value),
                    }}
                  />
                </Column>
              </Row>
            </GroupInputs>
          </Form.SectionBody>
        </Form>
      </DialogForm>
    </div>
  );
};

CompanyDialogForm.propTypes = {
  data: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCloseForm: PropTypes.func.isRequired,
  subscriptionPlans: PropTypes.array.isRequired,
  countries: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  showAnnualPrice: PropTypes.bool.isRequired,
  onChangeYearlyPrice: PropTypes.func.isRequired,
};

export { CompanyDialogForm };
