import React from 'react';
import { Dialog, Grid } from '@8base/boost';
import PropTypes from 'prop-types';
import FileViewer from 'react-file-viewer';
import { TransparentButtonFontAwesome } from '../buttons/TransparentButtonFontAwesome';
import { b64toBlob, downloadFile } from '../../shared/utils';
import { Overline1 } from '../new-ui/font-style/Overline1';

class PreviewDialog extends React.Component {
  tempLink: HTMLAnchorElement;
  constructor(props) {
    super(props);
    this.filename = null;
  }

  handleDownloadClick(file) {
    if (file.image) {
      this.filename = file.name;
      fetch(file.image)
        .then((res) => res.blob())
        .then((blob) => downloadFile(blob, this.tempLink, this.filename));
    } else {
      this.filename = file.name;
      downloadFile(b64toBlob(file.base64, file.type), this.tempLink, this.filename);
    }
  }

  render() {
    const { isOpen, file, onClose } = this.props;

    let open = false;

    if (file !== null && isOpen !== false) {
      open = true;
    }

    let content, preview;
    let type, url, name;

    if (file !== null && file.downloadUrl !== undefined) {
      type = file.filename.split('.').pop();
      url = file.downloadUrl;
      name = file.filename;
      preview = <FileViewer fileType={type.toLowerCase()} filePath={url} />;
    } else if (file !== null) {
      type = file !== null ? file.name.split('.').pop() : null;
      name = file.name;
      let unsupportedDocumentContent = (
        <>
          <div>
            <Overline1>{`This file type is not supported for previewing.`}</Overline1>
          </div>
          <div>
            <Overline1>
              {`You can always download it locally to open it in your computer by clicking the Download option in the top right corner of this window.`}
            </Overline1>
          </div>
          <div>
            <ul className={'unsupportedDocumentList'}>
              <li>
                <Overline1>{`Supported file types for preview:`}</Overline1>
              </li>
              <li>
                <Overline1>{`- pdf (Preferred format for preview)`}</Overline1>
              </li>
              <li>
                <Overline1>{`- Images: png, jpeg, gif, bmp, including 360-degree images`}</Overline1>
              </li>
              <li>
                <Overline1>{`- csv`}</Overline1>
              </li>
              <li>
                <Overline1>{`- xslx`}</Overline1>
              </li>
              <li>
                <Overline1>{`- docx`}</Overline1>
              </li>
            </ul>
          </div>
        </>
      );

      preview = (
        <FileViewer
          fileType={type.toLowerCase()}
          unsupportedComponent={() => unsupportedDocumentContent}
          filePath={file.image}
        />
      );
    }

    content = (
      <>
        <Dialog size="xl" isOpen={open}>
          <Dialog.Header title={name} onClose={onClose} />
          <div className="icon-download">
            <TransparentButtonFontAwesome
              text={'Download'}
              fontAwesomeIcon={'download'}
              onClick={() => this.handleDownloadClick(file)}
            />
          </div>
          <Dialog.Body>
            <Grid.Layout gap="xl">
              <Grid.Box>{preview}</Grid.Box>
            </Grid.Layout>
          </Dialog.Body>
        </Dialog>
      </>
    );
    return <>{content}</>;
  }
}

PreviewDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  file: PropTypes.any.isRequired,
};

export default PreviewDialog;
