import React from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { NoData } from '@8base/boost';

import { CurrencyFormat } from '../../../shared/components/CurrencyFormat';
import {
  ReportGroupSection,
  ReportGroupTitle,
  ReportTable,
  ReportTableTH as TH,
  ReportTableTR as TR,
  ReportTableTD as TD,
  ReportTableFooterTD as FooterTD,
} from '../components';
import { MONTHS } from '../reports-model';
import { createSnapshotList } from './sales-pipeline-trends-utils';
import { getFullNameWithEmail } from '../utils';
import BigInt from 'big-integer';

const DealNameWrap = styled.div`
  padding-left: 26px;
  min-width: 132px;
  white-space: nowrap;
`;

const NumberWrap = styled.div`
  padding-right: 32px;
  padding-left: 21px;
  text-align: right;
  white-space: nowrap;
`;

const AmountWrap = styled.div`
  padding-right: 24px;
  padding-left: 21px;
  text-align: right;
  white-space: nowrap;
`;

const shouldRender = (filterCompanyId, companyData) => {
  let currentCompanyId = R.pathOr('', ['id'], companyData);
  return filterCompanyId ? currentCompanyId === filterCompanyId : true;
};

class SalesPipelineTrendsTable extends React.PureComponent {
  render() {
    const {
      dealMonthlySnapshotsList,
      groupBy,
      requestMonthsInfo,
      clientCompany,
      partnerCompany,
      config,
    } = this.props;

    const { monthNumberDict } = requestMonthsInfo;

    const {
      clientCompanyMonthSnapshots,
      clientCompanyMonthTotalAmounts,
      partnerCompanyMonthSnapshots,
      partnerCompanyMonthTotalAmounts,
    } = createSnapshotList(dealMonthlySnapshotsList, groupBy, requestMonthsInfo);

    const filterCompanyId = R.pathOr('', ['organization', 'id'], config);
    const groupKey = groupBy[1];

    return (
      <>
        {shouldRender(filterCompanyId, clientCompany) &&
          this.renderTable(
            clientCompanyMonthSnapshots,
            clientCompanyMonthTotalAmounts,
            monthNumberDict,
            groupKey,
          )}
        {shouldRender(filterCompanyId, partnerCompany) &&
          this.renderTable(
            partnerCompanyMonthSnapshots,
            partnerCompanyMonthTotalAmounts,
            monthNumberDict,
            groupKey,
          )}
      </>
    );
  }

  renderTable = (snapshotsGroup, monthTotalAmounts, monthNumberDict, groupKey) => {
    const comapnyName = R.pathOr('', ['0', '0', 'company', 'name'], snapshotsGroup);

    return (
      <ReportGroupSection>
        <ReportGroupTitle>{comapnyName}</ReportGroupTitle>
        {snapshotsGroup.length > 0 ? (
          <ReportTable>
            {this.renderTableHeader(monthNumberDict, groupKey)}
            <tbody>
              {snapshotsGroup.map((rowData, rowIndex) => {
                return this.renderTableRow(rowData, groupKey, rowIndex);
              })}
            </tbody>
            {this.renderTableFooter(monthTotalAmounts)}
          </ReportTable>
        ) : (
          <div style={{ borderTop: '1px solid #e9eff4' }}>
            <NoData />
          </div>
        )}
      </ReportGroupSection>
    );
  };

  getRowTitle = (groupKey, rowData) => {
    const path = groupKey.split('.');
    if (groupKey === 'owner.id') {
      return getFullNameWithEmail(R.path(['0', 'owner'], rowData));
    }
    return R.pathOr('No name', ['0', ...path], rowData);
  };

  renderTableRow = (rowData, groupKey, rowIndex) => {
    const { currency } = this.props;

    const title = this.getRowTitle(groupKey, rowData);
    const rowAmount = rowData.reduce(
      (accum, item) =>
        BigInt(accum)
          .add(item.amount)
          .toString(),
      '0',
    );

    return (
      <TR key={rowIndex}>
        <TD>
          <DealNameWrap>{title}</DealNameWrap>
        </TD>
        <TD rightBorder>
          <AmountWrap>
            <CurrencyFormat {...currency} value={rowAmount} displayType="text" />
          </AmountWrap>
        </TD>
        {rowData.map((item, key) => (
          <TD key={key}>
            <NumberWrap>
              <CurrencyFormat {...currency} value={item.amount} displayType="text" />
            </NumberWrap>
          </TD>
        ))}
      </TR>
    );
  };

  renderTableHeader = (monthNumberDict, groupKey) => {
    const headerTitles = Object.keys(monthNumberDict)
      .map((year) =>
        Object.keys(monthNumberDict[year]).map(
          (month) => `${MONTHS[month - 1]}-${String(year).substring(2, 4)}`,
        ),
      )
      .reduce((accum, headers) => [...accum, ...headers], []);

    const groupTitle = {
      stage: 'STAGES',
      'owner.id': 'ALLIANCE DEAL OWNER',
      salesPerson: 'SALES ACCOUNT REP',
    }[groupKey];

    return (
      <thead>
        <TR>
          <TH>
            <DealNameWrap>{groupTitle}</DealNameWrap>
          </TH>
          <TH rightBorder></TH>
          {headerTitles.map((title, key) => {
            return (
              <TH key={key}>
                <NumberWrap>{title}</NumberWrap>
              </TH>
            );
          })}
        </TR>
      </thead>
    );
  };

  renderTableFooter = (monthTotalAmounts) => {
    const totalAmont = monthTotalAmounts.reduce(
      (total, monthAmount) =>
        BigInt(total)
          .add(monthAmount)
          .toString(),
      '0',
    );

    const { currency } = this.props;
    return (
      <tfoot>
        <tr>
          <FooterTD>
            <DealNameWrap>Total</DealNameWrap>
          </FooterTD>
          <FooterTD total rightBorder>
            <AmountWrap>
              <CurrencyFormat {...currency} value={totalAmont} displayType="text" />
            </AmountWrap>
          </FooterTD>
          {monthTotalAmounts.map((monthAmount, key) => (
            <FooterTD total key={key}>
              <NumberWrap>
                <CurrencyFormat {...currency} value={monthAmount} displayType="text" />
              </NumberWrap>
            </FooterTD>
          ))}
        </tr>
      </tfoot>
    );
  };
}

SalesPipelineTrendsTable.propTypes = {
  currency: PropTypes.object.isRequired,
  dealMonthlySnapshotsList: PropTypes.array.isRequired,
  requestMonthsInfo: PropTypes.object.isRequired,
  clientCompany: PropTypes.object.isRequired,
  partnerCompany: PropTypes.object.isRequired,
  config: PropTypes.object.isRequired,
  groupBy: PropTypes.array.isRequired,
};

export { SalesPipelineTrendsTable };
