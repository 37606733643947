import React from 'react';
import PropTypes from 'prop-types';
import DetailValue from '../../../../components/DetailValue';
import DetailDateValue from '../../../../components/DetailDateValue';
import { CurrencyTextField } from '../../../../shared/components/CurrencyTextField';
import UserDetailValue from '../../../../components/UserDetailValue';
import { HorizontalLineText } from '../../../../components/text/HorizontalLineText';
import { BoderDetailView } from '../../../../components/new-ui/div/BorderDetailView';
import { Grid } from '@8base/boost';
import { HeaderText } from '../../../../components/new-ui/text/HeaderText';
import { EditButton } from '../../../../components/new-ui/buttons/EditButton';
import { TablePosition } from '../../../../components/new-ui/div/TablePosition';
import { TableDetail } from '../../../../components/new-ui/table/TableDetail';
import { canEditDeal } from '../deal-permissions';
import sessionStore, { NEW_SESSION_EVENT } from '../../../../shared/SessionStore';
import { ThTitlePosition } from '../../../../components/new-ui/div/ThTitlePosition';

/**
 * Detail View Table For The Deal Entity.
 */
class DealDetailTable extends React.Component {
  render() {
    const { currency, dealData, onClickEdit } = this.props;
    const { owner, company } = dealData;
    const { user, selectedAlliance } = sessionStore.getState(NEW_SESSION_EVENT);

    return (
      <>
        <HorizontalLineText>DEAL INFORMATION</HorizontalLineText>
        <>
          <BoderDetailView>
            <Grid.Layout
              columns="auto 100px"
              areas={[['left', 'right']]}
              style={{ width: '100%', height: '100%' }}>
              <Grid.Box area="left">
                <div style={{ position: 'absolute', top: '21px', left: '25px' }}>
                  <HeaderText>DEAL INFORMATION</HeaderText>
                </div>
              </Grid.Box>
              <Grid.Box area="right" justifyContent={'center'}>
                {canEditDeal(user, dealData, selectedAlliance) ? (
                  <EditButton onClick={onClickEdit} text="Edit" />
                ) : null}
              </Grid.Box>
            </Grid.Layout>
          </BoderDetailView>
          <TablePosition>
            <TableDetail>
              <tbody>
                <tr>
                  <th>
                    <ThTitlePosition>
                      <span>CUSTOMER NAME</span>
                    </ThTitlePosition>
                  </th>
                  <td className="spacing-td">
                    <DetailValue text={dealData.customerName} />
                  </td>
                </tr>
                <tr>
                  <th>
                    <ThTitlePosition>
                      <span>COMPANY</span>
                    </ThTitlePosition>
                  </th>
                  <td className="spacing-td">
                    <DetailValue text={company ? company.name : null} />
                  </td>
                </tr>
                <tr>
                  <th>
                    <ThTitlePosition>
                      <span>AMOUNT</span>
                    </ThTitlePosition>
                  </th>
                  <td className="spacing-td">
                    <CurrencyTextField value={dealData.amount} currency={currency} />
                  </td>
                </tr>
                <tr>
                  <th>
                    <ThTitlePosition>
                      <span>NAME</span>
                    </ThTitlePosition>
                  </th>
                  <td className="spacing-td">
                    <DetailValue text={dealData.name} />
                  </td>
                </tr>
                <tr>
                  <th>
                    <ThTitlePosition>
                      <span>DEAL DESCRIPTION</span>
                    </ThTitlePosition>
                  </th>
                  <td className="spacing-td">
                    <DetailValue text={dealData.description} />
                  </td>
                </tr>
                <tr>
                  <th>
                    <ThTitlePosition>
                      <span>CLOSED DATE</span>
                    </ThTitlePosition>
                  </th>
                  <td className="spacing-td">
                    <DetailDateValue date={dealData.closeDate} />
                  </td>
                </tr>
                <tr>
                  <th>
                    <ThTitlePosition>
                      <span>DEAL OWNER DATE</span>
                    </ThTitlePosition>
                  </th>
                  <td className="spacing-td">
                    <UserDetailValue user={owner} />
                  </td>
                </tr>
                <tr>
                  <th>
                    <ThTitlePosition>
                      <span>LAST ACTIVITY DATE</span>
                    </ThTitlePosition>
                  </th>
                  <td className="spacing-td">
                    <DetailDateValue date={dealData.lastActivityDate} />
                  </td>
                </tr>
                <tr>
                  <th>
                    <ThTitlePosition>
                      <span>CREATED DATE</span>
                    </ThTitlePosition>
                  </th>
                  <td className="spacing-td">
                    <DetailDateValue date={dealData.createdDate} />
                  </td>
                </tr>
                <tr>
                  <th>
                    <ThTitlePosition>
                      <span>LAST CONTACTED DATE</span>
                    </ThTitlePosition>
                  </th>
                  <td className="spacing-td">
                    <DetailDateValue date={dealData.lastContactedDate} />
                  </td>
                </tr>
                <tr>
                  <th>
                    <ThTitlePosition>
                      <span>LAST MODIFIED DATE</span>
                    </ThTitlePosition>
                  </th>
                  <td className="spacing-td">
                    <DetailDateValue date={dealData.lastModifiedDate} />
                  </td>
                </tr>
                <tr>
                  <th>
                    <ThTitlePosition>
                      <span>LOST REASON</span>
                    </ThTitlePosition>
                  </th>
                  <td className="spacing-td">
                    <DetailValue text={dealData.lostReason} />
                  </td>
                </tr>
                <tr>
                  <th>
                    <ThTitlePosition>
                      <span>NEXT ACTIVITY DATE</span>
                    </ThTitlePosition>
                  </th>
                  <td className="spacing-td">
                    <DetailDateValue date={dealData.nextActivityDate} />
                  </td>
                </tr>
                <tr>
                  <th>
                    <ThTitlePosition>
                      <span>NEXT ACTIVITY DESCRIPTION</span>
                    </ThTitlePosition>
                  </th>
                  <td className="spacing-td">
                    <DetailValue text={dealData.nextActivityDescription} />
                  </td>
                </tr>
                <tr>
                  <th>
                    <ThTitlePosition>
                      <span>NUMBER OF SALES ACTIVITIES</span>
                    </ThTitlePosition>
                  </th>
                  <td className="spacing-td">
                    <DetailValue text={dealData.numberOfSalesActivities} />
                  </td>
                </tr>
                <tr>
                  <th>
                    <ThTitlePosition>
                      <span>SALES PERSON</span>
                    </ThTitlePosition>
                  </th>
                  <td className="spacing-td">
                    <DetailValue text={dealData.salesPerson} />
                  </td>
                </tr>
                <tr>
                  <th>
                    <ThTitlePosition>
                      <span>STAGE</span>
                    </ThTitlePosition>
                  </th>
                  <td className="spacing-td">
                    <DetailValue text={dealData.stage} />
                  </td>
                </tr>
                <tr>
                  <th>
                    <ThTitlePosition>
                      <span>TYPE</span>
                    </ThTitlePosition>
                  </th>
                  <td className="spacing-td">
                    <DetailValue text={dealData.type} />
                  </td>
                </tr>
                <tr>
                  <th>
                    <ThTitlePosition>
                      <span>WON REASON</span>
                    </ThTitlePosition>
                  </th>
                  <td className="spacing-td">
                    <DetailValue text={dealData.wonReason} />
                  </td>
                </tr>
                <tr>
                  <th>
                    <ThTitlePosition>
                      <span>SERVICE LINE</span>
                    </ThTitlePosition>
                  </th>
                  <td className="spacing-td">
                    <DetailValue text={dealData.line} />
                  </td>
                </tr>
                <tr>
                  <th>
                    <ThTitlePosition>
                      <span>DEAL REFERENCABLE (Y/N)</span>
                    </ThTitlePosition>
                  </th>
                  <td className="spacing-td">
                    <DetailValue text={dealData.dealReferencable} />
                  </td>
                </tr>
                <tr>
                  <th>
                    <ThTitlePosition>
                      <span>DEAL SOURCE ID</span>
                    </ThTitlePosition>
                  </th>
                  <td className="spacing-td">
                    <DetailValue text={dealData.dealSourceId} />
                  </td>
                </tr>
                <tr>
                  <th>
                    <ThTitlePosition>
                      <span>ASSOCIATED DEAL ID</span>
                    </ThTitlePosition>
                  </th>
                  <td className="spacing-td">
                    <DetailValue text={dealData.associatedDealId} />
                  </td>
                </tr>
                <tr>
                  <th>
                    <ThTitlePosition>
                      <span>PARTNER REGISTRATION ID</span>
                    </ThTitlePosition>
                  </th>
                  <td className="spacing-td">
                    <DetailValue text={dealData.partnerRegistrationId} />
                  </td>
                </tr>
              </tbody>
            </TableDetail>
          </TablePosition>
        </>
      </>
    );
  }
}

DealDetailTable.defaultProps = {
  onClickEdit: null, // null for form previews
};

DealDetailTable.propTypes = {
  dealData: PropTypes.object.isRequired,
  currency: PropTypes.object.isRequired,
  onClickEdit: PropTypes.func,
};

export default DealDetailTable;
