export const GRAY_COLOR = '#D0D7DD';
export const GREEN_COLOR = '#70D44B';
export const YELLOW_COLOR = '#FCBD00';
export const RED_COLOR = '#EB4235';
export const TRANSPARENT_COLOR = 'transparent';
export const LIGHT_BLUE_COLOR = '#3EB7F9';
export const BLUE_COLOR = '#008AAB';
export const LIGHT_GREEN_COLOR = '#6FD34B';
export const AMBER_COLOR = '#FCBD00';
export const GRAY_30 = '#CFD7DE'; // PLACEHOLDER_COLOR
