import React from 'react';
import { Text } from '@8base/boost';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

const StyledText = styled(Text)({
  fontSize: 16,
  color: '#ff6d4a',
});

const ToastError = ({ text = '' }) => {
  return (
    <>
      <StyledText weight="bold">{'Ooops!'}</StyledText>
      &nbsp;&nbsp;
      <StyledText>{text}</StyledText>
    </>
  );
};

ToastError.propTypes = {
  text: PropTypes.string.isRequired,
};

export default ToastError;
