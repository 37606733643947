import React from 'react';
import PropTypes from 'prop-types';
import { Text } from '@8base/boost';
import styled from '@emotion/styled';
import imgUpload from '../../images/icons/picture-upload.png';

const IMG_WIDTH = 200;
const IMG_HEIGHT = 200;

const SemiCircle = styled('div')`
  position: absolute;
  height: ${IMG_HEIGHT / 4}px;
  width: ${IMG_WIDTH}px;
  text-align: center;
  background-color: rgba(61, 61, 61, 0.6);
  bottom: 0;
`;

const VerticalCenterText = styled(Text)`
  color: rgba(255, 255, 255, 0.8);
  height: ${IMG_HEIGHT / 4}px;
  line-height: ${IMG_HEIGHT / 4}px;
`;

const ImgUpload = ({ url, disabled = false, ...rest }) => {
  const imageUrl = url ? url : imgUpload;
  const borderRadius = url ? '50%' : 'none';
  const border = url ? '1px solid #d0d7dd' : 'none';

  const StyledImgUpload = styled('div')`
    height: ${IMG_HEIGHT}px;
    width: ${IMG_WIDTH}px;
    background-image: url(${imageUrl});
    border-radius: ${borderRadius};
    border: ${border};
    position: relative;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    overflow: hidden;
    cursor: pointer;
    &:hover {
      opacity: 0.8;
    }
  `;

  return (
    <StyledImgUpload {...rest}>
      {url && !disabled ? (
        <SemiCircle>
          <VerticalCenterText weight="bold">{'Change Photo'}</VerticalCenterText>
        </SemiCircle>
      ) : null}
    </StyledImgUpload>
  );
};

ImgUpload.defaultProps = {
  url: '',
  disabled: false,
};

ImgUpload.propTypes = {
  url: PropTypes.string,
  disabled: PropTypes.bool,
};

export default ImgUpload;
