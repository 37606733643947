import Flux from '@cobuildlab/flux-state';

/**
 * Event that triggers a COMMENT error.
 *
 * @type {string}
 */
export const COMMENT_ERROR_EVENT = 'onCommentError';

/**
 * Event that triggers when some object request the comments.
 *
 * @type {string}
 */
export const COMMENT_REQUEST_EVENT = 'onCommentRequest';

/**
 * Event that triggers when some create a new comment.
 *
 * @type {string}
 */
export const COMMENT_CREATE_EVENT = 'onCommentCreate';

/**
 * Event that triggers a Comments Events.
 *
 * @type {string}
 */
export const COMMENTS_EVENT = 'onComments';

/**
 * Hold the CommentsData.
 *
 */
class CommentStore extends Flux.DashStore {
  constructor() {
    super();
    this.addEvent(COMMENT_ERROR_EVENT);
    this.addEvent(COMMENTS_EVENT);
    this.addEvent(COMMENT_REQUEST_EVENT);
    this.addEvent(COMMENT_CREATE_EVENT);
  }
}

export default new CommentStore();
