import React from 'react';
import * as R from 'ramda';
import { NavPlate } from '../components/NavPlate';
import { NavItem } from '../components/NavItem';
import { View } from '@cobuildlab/react-simple-state';
import {
  isProcurerOnly,
  isProcurer,
  isCompanyActive,
  isAllianceActive,
} from '../modules/procurer/procurer-utils';
import { OnNewSession } from '../shared/session-events';

const WIZARD_MENU = null;

const USER_MENU = [
  { icon: 'SpeedMeter', to: '/dashboard', label: 'Dashboard' },
  { icon: 'Gear', to: '/settings', label: 'Settings' },
];

const USER_MENU_WITH_ALLIANCE = [
  { icon: 'SpeedMeter', to: '/dashboard', label: 'Dashboard' },
  { icon: 'Planet', to: '/active-items', label: 'Active Items' },
  { icon: 'Diagram', to: '/management', label: 'Collaborate' },
  { icon: 'Table', to: '/reports', label: 'Reports' },
  { icon: 'Group', to: '/members', label: 'Members' },
  { icon: 'Gear', to: '/settings', label: 'Settings' },
];

const PROCURER_MENU = [{ icon: 'Schema', to: '/procurer', label: 'Billing' }];

/**
 * Renders the Menu of the App.
 */
class Menu extends View {
  constructor(props) {
    super(props);
    this.state = {
      session: null,
    };
  }

  componentDidMount() {
    this.subscribe(OnNewSession, (session) => {
      this.setState({ session: R.clone(session) });
    });
  }

  render() {
    const user = R.clone(OnNewSession.get().user);
    const selectedAlliance = R.clone(OnNewSession.get().selectedAlliance);

    const { companyUserRelation } = user;
    const company = companyUserRelation.count !== 0 ? companyUserRelation.items[0].company : null;
    let userMenu = [];
    let procurerMenu = [];

    if (!user.didCompleteWizard && !isProcurer(user)) {
      return WIZARD_MENU;
    }

    if (isProcurer(user)) procurerMenu = PROCURER_MENU;

    // if it has other role
    if (!isProcurerOnly()) {
      userMenu = selectedAlliance ? USER_MENU_WITH_ALLIANCE : USER_MENU;
    }

    // We check the company and the Alliance, because the user may be of the Partner Company
    if (!isCompanyActive(company) && !isAllianceActive(selectedAlliance)) {
      userMenu = [];
    }

    return (
      <NavPlate color={'PRIMARY'}>
        {userMenu.map(({ icon, to, label }) => (
          <NavItem className={label} key={to} icon={icon} to={to} label={label} />
        ))}

        {procurerMenu.map(({ icon, to, label }) => (
          <NavItem key={to} icon={icon} to={to} label={label} />
        ))}
      </NavPlate>
    );
  }
}

export default Menu;
