import React from 'react';
import { Card, Heading, Grid } from '@8base/boost';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';

const CommentHeader = styled(Heading)`
  height: 60px;
  width: 208px;
  color: #323c47;
  font-family: Poppins;
  font-size: 14px;
  line-height: 38px;
  text-transform: capitalize;
`;

const StyledHeaderContainer = styled(Card.Header)`
  width: 315px;
  height: 95px;
`;

const BlueSpan = styled('span')`
  color: #3eb7f9;
  font-family: Poppins;
  font-size: 12px;
  line-height: 18px;
  cursor: pointer;
  width: 100px;
  position: relative;
  bottom: 0px;
  top: 50px;
  right: 20px;
`;

const NotificationsHeader = ({ text, onMarkAllAsRead }) => (
  <StyledHeaderContainer>
    <Grid.Layout gap={'none'} columns="auto 40px">
      <Grid.Box>
        <CommentHeader type="h4" text={text} />
      </Grid.Box>
      <Grid.Box>
        <BlueSpan onClick={onMarkAllAsRead}>Mark all as read</BlueSpan>
      </Grid.Box>
    </Grid.Layout>
  </StyledHeaderContainer>
);

NotificationsHeader.propTypes = {
  text: PropTypes.string.isRequired,
  onMarkAllAsRead: PropTypes.func.isRequired,
};

export { NotificationsHeader };
