import React from 'react';
import { Button } from '@8base/boost';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

const StyledOptionButton = styled(Button)({
  color: '#3eb7f9',
});

const OptionButton = ({ fontAwesomeIcon, onClick, text = '' }) => {
  const icon = fontAwesomeIcon ? <FontAwesomeIcon icon={fontAwesomeIcon} /> : null;

  return (
    <StyledOptionButton variant="outlined" onClick={onClick}>
      {icon}
      &nbsp; {text}
    </StyledOptionButton>
  );
};

OptionButton.defaultProps = {
  text: '',
};

OptionButton.propTypes = {
  fontAwesomeIcon: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  text: PropTypes.string,
};

export { OptionButton };
