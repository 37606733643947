import React from 'react';
import PropTypes from 'prop-types';
import { withAuth } from '8base-react-sdk';
import Flux from '@cobuildlab/flux-state';

import { client } from '../../../../shared/api';
import * as gql from '../../../../shared/graphql';
import MainLoader from '../../../../shared/components/MainLoader';
import ErrorDialog from '../../../../components/dialogs/ErrorDialog';
import { sendVerificationEmail } from '../../auth.actions';
import * as jwtToken from 'jwt-decode';
import { UPDATE_USER_MUTATION } from '../../../dashboard/queries';
import { TOKEN_EVENT, USER_BASIC_EVENT } from '../../../../shared/SessionStore';

const { REACT_APP_AUTH_PROVIDER_ID } = process.env;

class CallbackContainerClass extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isAuthorized: true,
      auth0UserId: null,
    };
  }

  async handleAuthentication({ idToken, email }) {
    console.log(`DEBUG:index:handleAuthentication:`, idToken, email);
    /**
     * Auth headers for communicating with the 8base API.
     */
    client.setIdToken(idToken);
    Flux.dispatchEvent(TOKEN_EVENT, idToken);
    /**
     * Check if user exists in 8base.
     */
    try {
      const { user } = await client.request(gql.CURRENT_USER_QUERY);
      Flux.dispatchEvent(USER_BASIC_EVENT, user);
    } catch (error) {
      /**
       * If user doesn't exist, an error will be
       * thrown, which then the new user can be
       * created using the authResult values.
       */
      const user = { email: email };
      let jwtData = null;
      try {
        jwtData = jwtToken(idToken)['https://user'];
      } catch (e) {
        console.error('Auth:error:', e);
      }
      user.firstName = jwtData ? jwtData.first_name : '';
      user.lastName = jwtData ? jwtData.last_name : '';

      const response = await client.request(gql.USER_SIGN_UP_MUTATION, {
        user,
        authProfileId: REACT_APP_AUTH_PROVIDER_ID,
      });

      await client.request(UPDATE_USER_MUTATION, {
        data: { id: response.userSignUpWithToken.id, userInformationRelation: { create: {} } },
      });
    }
  }

  async componentDidMount() {
    const { auth, history } = this.props;
    const { hash } = history.location;
    if (hash && hash.length > 0) {
      const params = new URLSearchParams(String(hash).substring(1));
      if (params.get('error') === 'unauthorized') {
        const userId = params.get('error_description').split(':')[1];
        return this.setState({ isAuthorized: false, auth0UserId: userId });
      }
    }

    /* Get authResult from auth client after redirect */
    const authResult = await auth.authClient.getAuthorizedData();
    /* Identify or create user record using authenticated details */
    await this.handleAuthentication(authResult);
    /* Add the idToken to the auth state */
    auth.authClient.setState({ token: authResult.idToken });
    /* Redirect user to root path */
    const redirectUri = localStorage.getItem('redirectUri') || '/welcome';
    // remove redirectUri, we dont need it anymore, this avoids user redirected to redirectUri after logout and login again on same tab
    localStorage.removeItem('redirectUri');
    history.replace(redirectUri);
  }

  render() {
    const { auth } = this.props;

    if (this.state.isAuthorized === false)
      return (
        <ErrorDialog
          isOpen={true}
          onOk={() => auth.authClient.logout()}
          title={'Welcome'}
          text={
            'An email has been sent to verify your email. Please verify your inbox and access the verification link before logging in.'
          }
          secondaryText={'Resend Verification'}
          onSecondaryAction={async () => {
            await sendVerificationEmail(this.state.auth0UserId);
            auth.authClient.logout();
          }}
        />
      );

    return <MainLoader />;
  }
}

CallbackContainerClass.propTypes = {
  auth: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

/* withAuth injects 'auth' prop into component */
const CallbackContainer = withAuth(CallbackContainerClass);

export { CallbackContainer };
