import React from 'react';
import PropTypes from 'prop-types';
import * as toast from '../../../components/toast/Toast';
import { View } from '@cobuildlab/react-simple-state';
import { Card, Paragraph, Heading, Loader } from '@8base/boost';
import { fetchSession } from '../../auth/auth.actions';
import businessCase from '../../../images/wizard/Business_Case_Graphic.svg';
import { ActionButtonListView } from '../../../components/buttons/ActionButtonListView';
import { OnSessionError, OnNewSession } from '../../../shared/session-events';

// eslint-disable-next-line
/**
 * Main screen of company
 * If the user doesn't have a company it redirects to the Create View.
 */
export default class MyCompanyView extends View {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
  }

  componentDidMount() {
    this.subscribe(OnSessionError, (state) => {
      toast.error(state.message);
      this.setState({
        loading: false,
      });
    });

    this.subscribe(OnNewSession, (state) => {
      const {
        user: { companyUserRelation },
      } = state;
      if (companyUserRelation.count === 1) {
        return this.props.history.push(
          `/settings/company-management/${state.user.companyUserRelation.items[0].company.id}`,
        );
      } else if (companyUserRelation.count > 1) {
        return this.props.history.push(`/settings/company-management/company-list`);
      }
      this.setState({
        loading: false,
      });
    });
    fetchSession();
  }

  render() {
    const { loading } = this.state;

    if (loading) return <Loader stretch />;

    return (
      <div id={'c2gScreen'}>
        <Card.Header>
          <Heading type="h4" text="Welcome to the Company Administration Panel" />
        </Card.Header>
        <Card.Body borderRadius="all" style={{ textAlign: 'center' }} className="card-body">
          <Paragraph
            style={{
              opacity: '0.9',
              color: '#323C47',
              fontSize: 16,
              fontWeight: 600,
            }}>
            Let&apos;s get started!
          </Paragraph>
          <img style={{ width: 212, margin: '26px 0' }} src={businessCase} alt="CollabToGrow" />
          <Paragraph
            style={{
              color: '#323C47',
              fontSize: 13,
              width: 600,
              margin: 'auto',
            }}>
            In order to successfully set up your Company you will first need to create it.
            <br />
            <ActionButtonListView
              onClick={() => this.props.history.push('/settings/company-management/create')}
              text={'Create a Company'}
            />
          </Paragraph>
        </Card.Body>
      </div>
    );
  }
}

MyCompanyView.propTypes = {
  history: PropTypes.object.isRequired,
};
