import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@8base/boost';
import { withRouter } from 'react-router';
import CompanyHeaderName from '../text/CompanyHeaderName';
import styled from '@emotion/styled';
import * as R from 'ramda';

const StyledCompanyHeaderButton = styled(Button)`
  padding: 0 10px;
  box-shadow: none !important;
`;

const ImgLogo = styled('img')`
  margin: 0;
  max-height: 32px;
  max-width: 32px;
  min-height: 32px;
  min-width: 32px;
  height: 32px;
  width: 32px;
`;

const CompanyHeaderButton = ({ company, history, ...rest }) => {
  const companyUrl = company ? `/settings/company-management/${company.id}` : '';

  return (
    <>
      {company ? (
        <StyledCompanyHeaderButton
          onClick={() => history.push(companyUrl)}
          color="neutral"
          variant="outlined"
          {...rest}>
          <>
            {company.logoFile ? (
              <>
                <ImgLogo src={R.path(['logoFile', 'downloadUrl'], company)} />
                &nbsp;
              </>
            ) : null}
            <CompanyHeaderName name={company.name} />
          </>
        </StyledCompanyHeaderButton>
      ) : null}
    </>
  );
};

CompanyHeaderButton.propTypes = {
  company: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired, // from withRouter
};

export default withRouter(CompanyHeaderButton);
