import Flux from '@cobuildlab/flux-state';

/**
 * Event that triggers the creation of a Decision.
 *
 * @type {string}
 */
export const DECISION_CREATE_EVENT = 'onCreateDecision';

/**
 * Event that triggers a Decision error.
 *
 * @type {string}
 */
export const DECISION_ERROR_EVENT = 'onDecisionError';

/**
 * Event that triggers a Decision List fetched.
 *
 * @type {string}
 */
export const DECISION_LIST_EVENT = 'onDecisionList';

/**
 * Event that triggers a Decision Update event.
 *
 * @type {string}
 */
export const DECISION_UPDATE_EVENT = 'onDecisionUpdate';

/**
 * Event that triggers a Decision by id if fetched.
 *
 * @type {string}
 */
export const DECISION_DETAIL_EVENT = 'onDecisionDetail';

/**
 * Event that triggers a Decision by id if fetched.
 *
 * @type {string}
 */
export const DECISION_COMPLETED_EVENT = 'onDecisionCompleted';

/**
 * Event that triggers a Decision Restored.
 *
 * @type {string}
 */
export const DECISION_RESTORE_EVENT = 'onDecisionRestored';

/**
 * Event that triggers a DECISION_AUTO_SAVE EVENT.
 *
 * @type {string}
 */
export const DECISION_AUTO_SAVE_EVENT = 'DECISION_AUTO_SAVE_EVENT';

/**
 * Event that triggers a DECISION_AUTO_SAVE_ERROR_EVENT.
 *
 * @type {string}
 */
export const DECISION_AUTO_SAVE_ERROR_EVENT = 'DECISION_AUTO_SAVE_ERROR_EVENT';

/**
 * Hold the Decision Data.
 */
class DecisionStore extends Flux.DashStore {
  constructor() {
    super();
    this.addEvent(DECISION_CREATE_EVENT);
    this.addEvent(DECISION_ERROR_EVENT);
    this.addEvent(DECISION_LIST_EVENT);
    this.addEvent(DECISION_UPDATE_EVENT);
    this.addEvent(DECISION_DETAIL_EVENT);
    this.addEvent(DECISION_COMPLETED_EVENT);
    this.addEvent(DECISION_RESTORE_EVENT);
    this.addEvent(DECISION_AUTO_SAVE_EVENT);
    this.addEvent(DECISION_AUTO_SAVE_ERROR_EVENT);
  }
}

export default new DecisionStore();
