import React from 'react';
import PropTypes from 'prop-types';
import { Icon, NoData } from '@8base/boost';
import {
  ResponsiveContainer,
  CartesianGrid,
  LineChart,
  Line,
  XAxis,
  YAxis,
  Legend,
  Tooltip,
} from 'recharts';
import * as currencyFormatter from 'currency.js';
import { ViewCardBody } from '../../../components/card/ViewCardBody';
import { CustomTooltip, ChartSwitcher, ChartSwitcherWrapper, InlineHeading } from '../components';

export const SalesPipelineTrendsChart = ({
  data,
  onChangeSelected,
  selectedCompanyName,
  lineList,
  currency,
  noSwitcher,
}) => {
  return data.length ? (
    <ViewCardBody>
      {noSwitcher || (
        <ChartSwitcherWrapper>
          <ChartSwitcher onClick={onChangeSelected}>
            <Icon name="ChevronLeft" size="xs" />
          </ChartSwitcher>
          <ChartSwitcher onClick={onChangeSelected}>
            <Icon name="ChevronRight" size="xs" />
          </ChartSwitcher>
          <InlineHeading>Sales Pipeline Trends {selectedCompanyName}</InlineHeading>
        </ChartSwitcherWrapper>
      )}
      <ResponsiveContainer width="100%" height={400}>
        <LineChart data={data} margin={{ top: 10, left: 60, right: 30, bottom: 10 }}>
          <CartesianGrid stroke="#ccc" strokeDasharray="2 3" />
          <XAxis dataKey="name" tickSize={15} tickMargin={10} stroke="#333" />
          <YAxis
            tickSize={12}
            tickMargin={5}
            stroke="#333"
            tickFormatter={(value) =>
              currencyFormatter(value, {
                symbol: currency.symbol,
                decimal: currency.decimalSeparator,
                separator: currency.thousandSeparator,
              }).format(true)
            }
          />
          <Legend
            align="right"
            layout="vertical"
            verticalAlign="top"
            width={220}
            wrapperStyle={{ paddingLeft: 50 }}
          />
          <Tooltip
            cursor={{ stroke: '#333', strokeWidth: 1 }}
            content={<CustomTooltip currency={currency} />}
          />
          {lineList.map((line) => (
            <Line
              type="monotone"
              key={line.color}
              dataKey={line.name}
              stroke={line.color}
              strokeWidth={2}
              activeDot={{ r: 7 }}
            />
          ))}
        </LineChart>
      </ResponsiveContainer>
    </ViewCardBody>
  ) : (
    <NoData stretch />
  );
};

SalesPipelineTrendsChart.propTypes = {
  data: PropTypes.shape({}).isRequired,
  onChangeSelected: PropTypes.func.isRequired,
  selectedCompanyName: PropTypes.string.isRequired,
  lineList: PropTypes.arrayOf({ color: PropTypes.string, name: PropTypes.string }).isRequired,
  currency: PropTypes.any.isRequired,
  noSwitcher: PropTypes.bool.isRequired,
};
