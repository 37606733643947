// import welcomeImage from '../../../images/wizard/Welcome_Graphic.svg';
import personalImage from '../../images/wizard/Personal_Information_Graphic.svg';
import companyImage from '../../images/wizard/Company_Information_Graphic.svg';
import allianceImage from '../../images/wizard/Alliance_Graphic.svg';
// import Welcome from './inner-screens/Welcome';
import UserInformationView from './inner-screens/UserInformationView';
import CompanyInformation from './inner-screens/CompanyInformation';
import AllianceInformation from './inner-screens/AllianceInformation';
import sessionStore, { NEW_SESSION_EVENT } from '../../shared/SessionStore';
import { COMPANY_ADMINISTRATOR, COMPANY_PORTFOLIO_OWNER } from '../../shared/roles';

const ALLIANCE_INFORMATION_SCREEN = {
  progressName: 'Alliance Information',
  header: 'Alliance',
  layout: '1fr 40px 1fr',
  image: allianceImage,
  component: AllianceInformation,
};
const COMPANY_INFORMATION_SCREEN = {
  progressName: 'Company Information',
  header: 'Company',
  layout: '1fr 40px 1fr',
  image: companyImage,
  component: CompanyInformation,
};
const USER_INFORMATION_SCREEN = {
  progressName: 'User Information',
  header: 'Personal',
  layout: '1fr 40px 1fr',
  image: personalImage,
  component: UserInformationView,
};
/**
 * * @deprecated.
 *
 * @type {({layout: string, image, component: UserInformationView, header: string, progressName: string, progressBarValue: string}|{layout: string, image, component: CompanyInformation, header: string, progressName: string, progressBarValue: string}|{layout: string, image, component: *, header: string, progressName: string, progressBarValue: string})[]}
 */
export const SCREENS = [
  USER_INFORMATION_SCREEN,
  COMPANY_INFORMATION_SCREEN,
  ALLIANCE_INFORMATION_SCREEN,
];

/**
 * Get the screens for the Wizard.
 *
 * @returns {Array} - The screens for the Wizard.
 */
export const getScreens = () => {
  const screens = [USER_INFORMATION_SCREEN];
  const { user, allianceInvitationsList, alliancesList } = sessionStore.getState(NEW_SESSION_EVENT);
  const companyUsers = user.companyUserRelation.items;
  const companyUser = companyUsers.length > 0 ? companyUsers[0] : null;
  if (
    !companyUser ||
    (companyUser &&
      [COMPANY_PORTFOLIO_OWNER, COMPANY_ADMINISTRATOR].includes(companyUser.role.name))
  ) {
    screens.push(COMPANY_INFORMATION_SCREEN);
  }

  if (
    (allianceInvitationsList.items.length > 0 || alliancesList.count < 1) &&
    (!companyUser ||
      [COMPANY_PORTFOLIO_OWNER, COMPANY_ADMINISTRATOR].includes(companyUser.role.name))
  )
    screens.push(ALLIANCE_INFORMATION_SCREEN);

  return screens;
};

/**
 * @deprecated
 * @type {number}
 */
export const NUMBER_OF_WIZARD_SCREENS = SCREENS.length;
