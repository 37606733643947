import React from 'react';
import * as toast from '../../../components/toast/Toast';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import { View } from '@cobuildlab/react-simple-state';
import { Card, Table, Dropdown, Menu, Heading, Icon, SelectField, InputField } from '@8base/boost';
import { DropdownBodyOnTable } from '../../../components/dropdown/DropdownBodyOnTable';
import { ListCardBody } from '../../../components/card/ListCardBody';
import sessionStore, { NEW_SESSION_EVENT } from '../../../shared/SessionStore';
import ModalWithInputs from '../../../components/ModalWithInputs';
import {
  fetchAllianceMembersWithRoleAction,
  updateMemberRoleAction,
  createAllianceMember,
  deleteAllianceMember,
  createAllianceMemberInvitation,
} from './alliance-actions';
import { ALLIANCE_ROLES } from '../../../shared/roles';
import { fetchMyCompanyMembersAction } from '../company-management/company-actions';
import YesNoDialog from '../../../components/dialogs/YesNoDialog';
import plusCircleIcon from '../../../images/icons/plus-circle.svg';
import { TransparentButtonSvg } from '../../../components/buttons/TransparentButtonSvg';
import { ActionButtonClose } from '../../../components/buttons/ActionButtonClose';
import {
  canChangeRole,
  canDeleteAllianceMemberPermission,
} from '@cobuildlab/collabtogrow-permissions';
import {
  OnAllianceError,
  OnAllianceMemberWithRoles,
  OnAllianceRoleChange,
  OnAllianceMemberDelete,
  OnAllianceMemberCreate,
  OnAllianceInvitationMember,
} from './alliance-events';
import { OnCompanyListMemebers } from '../company-management/company-events';

class AllianceMembersView extends View {
  constructor(props) {
    super(props);
    this.state = {
      members: [],
      loading: true,
      member: '',
      memberId: 0,
      deleteModalIsOpen: false,
      changeRoleModalIsOpen: false,
      addModalIsOpen: false,
      inviteModalIsOpen: false,
      role: null,
      changedRole: null,
      roles: ALLIANCE_ROLES,
      companyUserId: null,
      inviteEmail: '',
      companyMembers: [],
    };
    this.user = sessionStore.getState(NEW_SESSION_EVENT).user;
  }

  componentDidMount() {
    this.subscribe(OnAllianceError, (state) => {
      toast.error(state.message);
      this.setState({
        loading: false,
      });
    });

    this.subscribe(OnAllianceMemberWithRoles, (state) => {
      const members = state.allianceUsersList.items.map((member) => {
        const filteredMember = {
          id: member.id,
          role: member.role,
          user: member.companyUser.user,
          company: R.clone(member.companyUser.company),
        };
        return filteredMember;
      });

      this.setState({
        members,
        loading: false,
      });
    });

    this.subscribe(OnAllianceRoleChange, () => {
      this.onClose();
      toast.success('Role Successfully Changed');
      fetchAllianceMembersWithRoleAction(this.props.match.params.id);
    });

    this.subscribe(OnAllianceMemberDelete, () => {
      this.onClose();
      toast.success('Member Successfully deleted');
      fetchAllianceMembersWithRoleAction(this.props.match.params.id);
    });

    this.subscribe(OnAllianceMemberCreate, () => {
      this.onClose();
      toast.success('Member Successfully added');
      fetchAllianceMembersWithRoleAction(this.props.match.params.id);
    });

    this.subscribe(OnAllianceInvitationMember, () => {
      this.onClose();
      toast.success('Invitation Successfully sent');
      fetchAllianceMembersWithRoleAction(this.props.match.params.id);
    });

    this.subscribe(OnCompanyListMemebers, (state) => {
      const companyMembers = state.companyUsersList.items.map((companyMember) => ({
        ...companyMember,
      }));

      this.setState({ companyMembers });
    });

    fetchAllianceMembersWithRoleAction(this.props.match.params.id);
    fetchMyCompanyMembersAction();
  }

  onSelectForDelete = (member) => {
    this.setState({
      member,
      deleteModalIsOpen: true,
    });
  };

  onYesDelete = () => {
    this.setState(
      {
        deleteModalIsOpen: false,
        loading: true,
      },
      () => deleteAllianceMember(this.state.member),
    );
  };

  onChange = (name, value) => {
    this.setState({ [name]: value });
  };

  onChangedRole = (value) => {
    this.setState({ changedRole: value });
  };

  onSelectForChangeRole = (member) => {
    this.setState({
      member,
      changeRoleModalIsOpen: true,
    });
  };

  onYesChangeRole = () => {
    const role = this.state.roles.find((role) => role.name === this.state.changedRole);
    this.setState(
      {
        changeRoleModalIsOpen: false,
        loading: true,
      },
      () => {
        updateMemberRoleAction(this.state.member.id, role);
      },
    );
  };

  onSelectForInvite = () => {
    this.setState({
      inviteModalIsOpen: true,
    });
  };

  onYesInviteMember = () => {
    const { inviteEmail, role } = this.state;
    const { match } = this.props;

    this.setState(
      {
        inviteModalIsOpen: false,
        loading: true,
      },
      () => {
        createAllianceMemberInvitation(inviteEmail, role, match.params.id);
      },
    );
  };

  onSelectForAddMember = () => {
    this.setState({
      addModalIsOpen: true,
    });
  };

  onYesAddMember = () => {
    this.setState(
      {
        addModalIsOpen: false,
        loading: true,
      },
      () => {
        createAllianceMember(this.state.companyUserId, this.state.role, this.props.match.params.id);
      },
    );
  };

  onClose = () => {
    this.setState({
      role: null,
      member: '',
      email: '',
      companyUserId: null,
      changedRole: null,
      changeRoleModalIsOpen: false,
      inviteModalIsOpen: false,
      addModalIsOpen: false,
      deleteModalIsOpen: false,
    });
  };

  render() {
    const {
      changeRoleModalIsOpen,
      addModalIsOpen,
      deleteModalIsOpen,
      inviteModalIsOpen,
      members,
      loading,
      inviteEmail,
    } = this.state;
    const { history } = this.props;
    const allianceId = this.props.match.params.id;
    const companyMembersNotInAlliance = this.state.companyMembers.filter((companyMember) =>
      members.some((member) => member.user.id !== companyMember.user.id),
    );

    const topButtons = !loading ? (
      <div className="company-icons">
        <TransparentButtonSvg
          iconSvg={plusCircleIcon}
          onClick={() => this.onSelectForInvite()}
          text="Invite Member"
        />
        <TransparentButtonSvg
          iconSvg={plusCircleIcon}
          onClick={() => this.onSelectForAddMember()}
          text="Add Member"
        />
        <ActionButtonClose onClick={history.goBack} />
      </div>
    ) : null;

    const moreOptionItems = [
      {
        canShow: canChangeRole(this.user, { id: allianceId }),
        text: 'Change Role',
        callback: this.onSelectForChangeRole,
      },
      {
        canShow: canDeleteAllianceMemberPermission(this.user, { id: allianceId }),
        text: 'Delete',
        callback: this.onSelectForDelete,
      },
    ];

    const moreOptionItemsLength = moreOptionItems.filter((item) => item.canShow).length;

    return (
      <React.Fragment>
        <Card.Header>
          <Heading type="h4" text="Member Management" />
          {topButtons}
        </Card.Header>
        <ListCardBody>
          <Table>
            <Table.Header columns="repeat(6, 1fr)">
              <Table.HeaderCell>Company Name</Table.HeaderCell>
              <Table.HeaderCell>First Name</Table.HeaderCell>
              <Table.HeaderCell>Last Name</Table.HeaderCell>
              <Table.HeaderCell>Email</Table.HeaderCell>
              <Table.HeaderCell>Role</Table.HeaderCell>
              {moreOptionItemsLength > 0 && <Table.HeaderCell>Options</Table.HeaderCell>}
            </Table.Header>
            <Table.Body loading={loading} data={members}>
              {(member) => (
                <Table.BodyRow columns="repeat(6, 1fr)" key={member.id}>
                  <Table.BodyCell>{R.pathOr('', ['company', 'name'], member)}</Table.BodyCell>
                  <Table.BodyCell>{R.pathOr('', ['user', 'firstName'], member)}</Table.BodyCell>
                  <Table.BodyCell>{R.pathOr('', ['user', 'lastName'], member)}</Table.BodyCell>
                  <Table.BodyCell>{R.pathOr('', ['user', 'email'], member)}</Table.BodyCell>
                  <Table.BodyCell>{R.pathOr('', ['role', 'name'], member)}</Table.BodyCell>
                  {moreOptionItemsLength > 0 && (
                    <Table.BodyCell>
                      <Dropdown defaultOpen={false}>
                        <Dropdown.Head>
                          <Icon name="More" className="more-icon" />
                        </Dropdown.Head>
                        <DropdownBodyOnTable>
                          {({ closeDropdown }) => (
                            <Menu>
                              {moreOptionItems.map(
                                (option) =>
                                  option.canShow && (
                                    <Menu.Item
                                      onClick={() => {
                                        closeDropdown();
                                        option.callback(member);
                                      }}>
                                      {option.text}
                                    </Menu.Item>
                                  ),
                              )}
                            </Menu>
                          )}
                        </DropdownBodyOnTable>
                      </Dropdown>
                    </Table.BodyCell>
                  )}
                </Table.BodyRow>
              )}
            </Table.Body>
          </Table>
        </ListCardBody>
        {/* Change role Modal */}
        <ModalWithInputs
          isOpen={changeRoleModalIsOpen}
          onYes={this.onYesChangeRole}
          onNo={this.onClose}
          onClose={this.onClose}
          yesText={'Change Role'}
          noText={'Cancel'}
          text={'Select the new Role for this member'}
          title={'Role Change'}>
          <React.Fragment>
            <SelectField
              label="Role"
              input={{
                name: 'role',
                value: this.state.changedRole,
                onChange: (value) => this.onChangedRole(value),
              }}
              meta={{}}
              placeholder="Select Role"
              options={this.state.roles.map((rol) => {
                return { label: rol.name, value: rol.name };
              })}
            />
          </React.Fragment>
        </ModalWithInputs>
        {/* Add member Modal */}
        <ModalWithInputs
          isOpen={addModalIsOpen}
          onYes={this.onYesAddMember}
          onNo={this.onClose}
          onClose={this.onClose}
          yesText={'Add'}
          noText={'Cancel'}
          text={'Select the User to add to this Alliance'}
          title={'Add New Member'}>
          <React.Fragment>
            <SelectField
              label="Company Member"
              input={{
                name: 'companyUserId',
                value: this.state.companyUserId,
                onChange: (value) => this.onChange('companyUserId', value),
              }}
              meta={{}}
              placeholder="Select Company Member"
              options={companyMembersNotInAlliance.map((member) => {
                const { firstName, lastName, email } = member.user;
                return { label: `${firstName} ${lastName} (${email})`, value: member.id };
              })}
            />
            <SelectField
              label="Role"
              input={{
                name: 'role',
                value: this.state.role,
                onChange: (value) => this.onChange('role', value),
              }}
              meta={{}}
              placeholder="Select Role"
              options={this.state.roles.map((rol) => {
                return { label: rol.name, value: rol.name };
              })}
            />
          </React.Fragment>
        </ModalWithInputs>
        {/* Invite Modal */}
        <ModalWithInputs
          isOpen={inviteModalIsOpen}
          onYes={this.onYesInviteMember}
          onNo={this.onClose}
          onClose={this.onClose}
          yesText={'Invite'}
          noText={'Cancel'}
          text={`Enter the user's email to invite`}
          title={'Invite New Member'}>
          <React.Fragment>
            <InputField
              label="User Email"
              placeholder="Email"
              input={{
                name: 'inviteEmail',
                value: inviteEmail,
                onChange: (value) => this.onChange('inviteEmail', value),
              }}
            />
            <SelectField
              label="Role"
              input={{
                name: 'role',
                value: this.state.role,
                onChange: (value) => this.onChange('role', value),
              }}
              meta={{}}
              placeholder="Select Role"
              options={this.state.roles.map((rol) => {
                return { label: rol.name, value: rol.name };
              })}
            />
          </React.Fragment>
        </ModalWithInputs>
        <YesNoDialog
          isOpen={deleteModalIsOpen}
          onYes={this.onYesDelete}
          onNo={this.onClose}
          onClose={this.onClose}
          text={'Are you sure you want to Delete this Member?'}
          title={'Delete Member'}
        />
      </React.Fragment>
    );
  }
}

AllianceMembersView.propTypes = {
  history: PropTypes.object.isRequired,
};

export default AllianceMembersView;
