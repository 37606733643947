import React from 'react';
import { Card } from '@8base/boost';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

const StyledCardSection = styled(Card.Section)`
  padding: 16px 24px !important;
  margin: 0px !important;
  border: ${({ selected }) => (selected ? '2px solid #3eb7f9 !important' : null)};
`;

const ScorecardSection = ({ selected, children, ...rest }) => {
  return (
    <StyledCardSection hoverable selected={selected} {...rest}>
      {children}
    </StyledCardSection>
  );
};

ScorecardSection.propTypes = {
  selected: PropTypes.bool.isRequired,
  children: PropTypes.any.isRequired,
};

export { ScorecardSection };
