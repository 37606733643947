import React from 'react';
import { Button, Icon, Text } from '@8base/boost';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

const StyledButton = styled(Button)`
  border-width: 0px !important;
`;

const StyledH1 = styled(Text)`
  color: #323c47;
  font-family: Poppins;
  font-size: 13px;
  line-height: 18px;
`;

const EditButton = ({ text, onClick }) => {
  return (
    <>
      {onClick ? (
        <StyledButton onClick={onClick} stretch color="neutral" variant="outlined">
          <Icon name="EditPencil" />
          <StyledH1>{text}</StyledH1>
        </StyledButton>
      ) : null}
    </>
  );
};

EditButton.propTypes = {
  onClick: PropTypes.func,
  text: PropTypes.string,
};

EditButton.defaultProps = {
  text: 'Edit',
  onClick: null, // null to hide edit button on create/edit review
};

export { EditButton };
