import React from 'react';
import { Card, Dropdown, Grid, Heading, Menu, Row, Switch, Text } from '@8base/boost';
import { DealCardBody } from '../../../components/card/DealCardBody';
import {
  deleteDeal,
  deleteDealList,
  fetchDealsCompanies,
  fetchDealsFullList,
  fetchDealsList,
  fetchDealsStages,
} from './deal-actions';
import sessionStore, { NEW_SESSION_EVENT } from '../../../shared/SessionStore';
import { canBulkDeals, canCreateDeal, canDeleteDeal, canImportCSV } from './deal-permissions';
import {
  OnDealsList,
  OnDealError,
  OnDealDelete,
  OnDealStageList,
  OnDealCompaniesList,
  OnDealDeleteList,
  OnDealsFullList,
} from './deal-events';
import withAlliance from '../../../components/hoc/withAlliance';
import { View } from '@cobuildlab/react-simple-state';
import PropTypes from 'prop-types';
import YesNoDialog from '../../../components/dialogs/YesNoDialog';
import { onErrorMixin } from '../../../shared/mixins';
import { withRouter } from 'react-router-dom';
import * as toast from '../../../components/toast/Toast';
import * as R from 'ramda';
import { TransparentButtonSvg } from '../../../components/buttons/TransparentButtonSvg';
import { ActionButtonListView } from '../../../components/buttons/ActionButtonListView';
import importCSV from '../../../images/icons/import-csv.svg';
import SearchInput from '../../../components/inputs/SearchInput';
import { canLoadPageOnScroll, debounce, generatedExcel } from '../../../shared/utils';
import { getCurrencyOnSession } from '../../../shared/alliance-utils';
import DealTable from './components/DealTable';
import DealFullTable from './components/DealFullTable';
import ItemFilter from '../../../components/ItemFilter';
import { MoreActionButton } from '../../../components/buttons/MoreActionButton';
import { COLUMNSEXCEL, COLUMNSOBJECT } from './deal-model';

/**
 * List All the Deal Items.
 */

class DealListView extends View {
  constructor(props) {
    super(props);
    this.state = {
      dealsList: [],
      deal: {},
      loading: true,
      loadingPage: false,
      search: '',
      page: 1,
      count: 0,
      deleteModalIsOpen: false,
      bulkDeleteModalIsOpen: false,
      isFullTable: false,
      filter: null,
      stages: [],
      itemFilter: null,
      companiesOptions: [],
    };
    this.user = sessionStore.getState(NEW_SESSION_EVENT).user;
    this.onError = onErrorMixin.bind(this);
    this.searchWithDebounce = debounce(300, this.searchWithDebounce)();
    this.selectedAlliance = sessionStore.getState(NEW_SESSION_EVENT).selectedAlliance;
  }

  changeTableSize = () => {
    const { isFullTable, search, filter, itemFilter } = this.state;
    this.setState({ isFullTable: !isFullTable, loading: true, dealsList: [], page: 1 }, () =>
      fetchDealsList(search, 1, 50, !isFullTable, filter, itemFilter),
    );
  };

  onListScroll = (event) => {
    const {
      count,
      loadingPage,
      search,
      page,
      filter,
      itemFilter,
      dealsList,
      isFullTable,
    } = this.state;
    const canLoadPage = canLoadPageOnScroll(event, dealsList, count, loadingPage);

    if (canLoadPage) {
      const nextPage = page + 1;

      this.setState({ loadingPage: true, page: nextPage }, () => {
        fetchDealsList(search, nextPage, 50, isFullTable, filter, itemFilter);
      });
    }
  };

  componentDidMount() {
    this.subscribe(OnDealError, this.onError);

    this.subscribe(OnDealsList, (event) => {
      const { dealDataList } = R.clone(event);
      const { count } = R.clone(dealDataList);
      const items = dealDataList.items.map((item) => ({ ...item }));
      const { loadingPage, dealsList: oldDealsList } = this.state;
      const dealsList = loadingPage ? oldDealsList.concat(items) : items;

      this.setState({
        dealsList,
        loading: false,
        loadingPage: false,
        count,
      });
    });

    this.subscribe(OnDealStageList, (stages) => {
      this.setState({
        stages: stages.map((stage) => stage),
      });
    });

    this.subscribe(OnDealCompaniesList, (companiesOptions) => {
      this.setState({
        companiesOptions: companiesOptions.map((companyOption) => ({ ...companyOption })),
      });
    });

    this.subscribe(OnDealDelete, () => {
      const { search, filter, itemFilter, isFullTable } = this.state;

      this.setState({ page: 1 }, () => {
        toast.success('Deal Deleted!');
        fetchDealsList(search, 1, 50, isFullTable, filter, itemFilter);
      });
    });

    this.subscribe(OnDealDeleteList, () => {
      const { search, filter, itemFilter, isFullTable } = this.state;

      this.setState({ page: 1 }, () => {
        toast.success('Deals List Deleted!');
        fetchDealsList(search, 1, 50, isFullTable, filter, itemFilter);
      });
    });

    this.subscribe(OnDealsFullList, (state) => {
      this.setState({ loading: false });
      const items = state.dealDataList.items.map((item) => ({ ...item }));
      generatedExcel(items, COLUMNSOBJECT, COLUMNSEXCEL, 'DealList');
    });

    const searchParams = new URLSearchParams(this.props.location.search);
    const urlStage = searchParams.get('stage');
    const urlCompany = searchParams.get('company');
    if (urlStage && urlCompany) {
      const company = urlCompany === 'null' ? '' : urlCompany;
      this.setState(
        {
          filter: urlStage,
          itemFilter: company,
          page: 1,
        },
        () => fetchDealsList('', 1, 50, this.state.isFullTable, urlStage, company),
      );
    } else {
      const fetchPolicyOptions = { isCacheFirst: true };

      fetchDealsList('', 1, 50, this.state.isFullTable, null, null, fetchPolicyOptions);
    }
    fetchDealsStages();
    fetchDealsCompanies();
  }

  goToImportDeals = () => {
    this.props.history.push('/management/deal/import');
  };

  onSelectForDelete = (deal) => {
    const [canDelete, errors] = canDeleteDeal(this.user, deal, this.selectedAlliance);
    if (canDelete) {
      this.setState({
        deal,
        deleteModalIsOpen: true,
      });
    } else {
      toast.errors(errors);
    }
  };

  onYes = () => {
    const { companiesOptions } = this.state;
    const { deal } = this.state;
    const otherCompany = companiesOptions.find(
      (company) => company.id !== deal.company.id && company.id,
    );
    this.setState(
      {
        deleteModalIsOpen: false,
        loading: true,
      },
      () => deleteDeal(this.state.deal, otherCompany),
    );
  };

  onClose = () => {
    this.setState({
      deleteModalIsOpen: false,
    });
  };

  onBulkDelete = (deal) => {
    this.setState({
      bulkDeleteModalIsOpen: true,
    });
  };

  onYesBulkDelete = () => {
    this.setState(
      {
        bulkDeleteModalIsOpen: false,
        loading: true,
      },
      () => {
        const { dealsList } = this.state;
        deleteDealList(dealsList);
      },
    );
  };

  onCloseBulkDelete = () => {
    this.setState({
      bulkDeleteModalIsOpen: false,
    });
  };

  onSearchChange = (value) => {
    this.setState({ search: value });
    this.searchWithDebounce(value);
  };

  searchWithDebounce = (value) => {
    const { filter, itemFilter, isFullTable } = this.state;
    this.setState({ loading: true, page: 1 }, () => {
      fetchDealsList(value, 1, 50, isFullTable, filter, itemFilter);
    });
  };
  stageFilter = (value) => {
    const { search, itemFilter, isFullTable } = this.state;
    this.setState(
      {
        loading: true,
        filter: value,
        page: 1,
      },
      () => {
        fetchDealsList(search, 1, 50, isFullTable, value, itemFilter);
      },
    );
  };
  companyFilter = (value) => {
    const { search, filter, isFullTable } = this.state;
    this.setState(
      {
        loading: true,
        itemFilter: value,
        page: 1,
      },
      () => {
        fetchDealsList(search, 1, 50, isFullTable, filter, value);
      },
    );
  };

  exportExcel = () => {
    this.setState({ loading: true });
    fetchDealsFullList('', 0, 0, false);
  };

  render() {
    const {
      dealsList,
      deleteModalIsOpen,
      bulkDeleteModalIsOpen,
      search,
      loadingPage,
      loading,
      isFullTable,
      filter,
      stages,
      itemFilter,
      companiesOptions,
    } = this.state;
    const { history } = this.props;
    const currency = getCurrencyOnSession();
    const alliance = this.selectedAlliance;

    if (filter !== null && stages.indexOf(filter) === -1) {
      stages.push(filter);
    }

    return (
      <div className="items-card">
        <Card.Header>
          <Grid.Layout
            columns="7fr 9fr 9fr 9fr 3fr 4fr 20fr"
            areas={[['left', 'left2', 'center', 'right', 'right-1', 'right-2', 'right-3']]}
            style={{ width: '100%' }}>
            <Grid.Box justifySelf="flex-start" area="left" className="list-box">
              <Heading type="h4" text="Deals" />
            </Grid.Box>
            <Grid.Box area="left2" className="list-box">
              <SearchInput value={search} onChange={this.onSearchChange} />
            </Grid.Box>
            <Grid.Box area="center" className="list-box">
              <ItemFilter
                onChange={this.companyFilter}
                value={itemFilter}
                options={companiesOptions.map((company) => {
                  return { label: company.name, value: company.id };
                })}
                placeholder="Companies"
              />
            </Grid.Box>
            <Grid.Box area="right" className="list-box">
              <ItemFilter
                onChange={this.stageFilter}
                value={filter}
                options={stages.map((stage) => ({ label: stage, value: stage }))}
                placeholder="Filter By Stage"
              />
            </Grid.Box>

            <Grid.Box area="right-1" className="list-box">
              {canBulkDeals(alliance) ? (
                <TransparentButtonSvg
                  onClick={this.onBulkDelete}
                  iconSvg={importCSV}
                  text="Bulk Delete"
                />
              ) : null}
            </Grid.Box>
            <Grid.Box area="right-2" className="list-box">
              {canImportCSV(alliance) ? (
                <Dropdown defaultOpen={false}>
                  <Dropdown.Head stretch>
                    <TransparentButtonSvg iconSvg={importCSV} text="Import Deals" />
                  </Dropdown.Head>
                  <Dropdown.Body stretch placement="bottom" pin="left">
                    {({ closeDropdown }) => (
                      <Menu>
                        {canImportCSV(alliance) ? (
                          <Menu.Item
                            onClick={() => {
                              this.goToImportDeals();
                              closeDropdown();
                            }}>
                            <Row>
                              <img src={importCSV} alt="Collaborate" height="18" width="18" />
                              <Text text="CSV" />
                            </Row>
                          </Menu.Item>
                        ) : null}
                      </Menu>
                    )}
                  </Dropdown.Body>
                </Dropdown>
              ) : null}
            </Grid.Box>
            <Grid.Box area="right-3">
              <Switch
                label={'Show All Columns'}
                value={isFullTable}
                onChange={this.changeTableSize}
              />
              <Dropdown defaultOpen={false} style={{ position: 'absolute', right: '0px' }}>
                <Dropdown.Head>
                  <MoreActionButton />
                </Dropdown.Head>
                <Dropdown.Body pin="right">
                  {({ closeDropdown }) => (
                    <Menu>
                      <Menu.Item
                        onClick={() => {
                          this.exportExcel();
                          closeDropdown();
                        }}>
                        Export to Excel
                      </Menu.Item>
                    </Menu>
                  )}
                </Dropdown.Body>
              </Dropdown>
            </Grid.Box>
          </Grid.Layout>
        </Card.Header>
        <DealCardBody className="items-table" onScroll={this.onListScroll}>
          {isFullTable ? (
            <DealFullTable
              dealsList={dealsList}
              loadingPage={loadingPage}
              loading={loading}
              history={history}
              currency={currency}
              alliance={alliance}
              user={this.user}
              onSelectForDelete={this.onSelectForDelete}
            />
          ) : (
            <DealTable
              dealsList={dealsList}
              loadingPage={loadingPage}
              loading={loading}
              history={history}
              currency={currency}
              alliance={alliance}
              user={this.user}
              onSelectForDelete={this.onSelectForDelete}
            />
          )}
          {canCreateDeal(this.user, alliance) ? (
            <ActionButtonListView
              onClick={() => this.props.history.push('/management/deal/create')}
              text="Create Deals"
            />
          ) : null}
        </DealCardBody>
        <YesNoDialog
          isOpen={deleteModalIsOpen}
          onYes={this.onYes}
          onNo={this.onClose}
          onClose={this.onClose}
          text={'Are you sure you want to Delete this Deal?'}
          title={'Delete Deal'}
        />
        <YesNoDialog
          isOpen={bulkDeleteModalIsOpen}
          onYes={this.onYesBulkDelete}
          onNo={this.onCloseBulkDelete}
          onClose={this.onCloseBulkDelete}
          text={'Are you sure you want to Delete all Deals?'}
          title={'Bulk delete'}
        />
      </div>
    );
  }
}

DealListView.propTypes = {
  history: PropTypes.object.isRequired,
};

export default withRouter(withAlliance(DealListView));
