import { Text } from '@8base/boost';
import styled from '@emotion/styled';

const FieldSectionText = styled(Text)`
  display: block;
  margin-bottom: 11px;
  color: #a5afb6;
  line-height: 18px;
`;

export { FieldSectionText };
