import { DEAL_CLOSED, DEAL_COMPLETED } from '../../../shared/status';
import {
  getRolesOnAlliance,
  isUserAdminOrSERInAlliance,
  isUserCreatorInAlliance,
} from '../../../shared/alliance-utils';
import { isAllianceCompleted } from '@cobuildlab/collabtogrow-permissions';
import { getSystemSettingStatus, SYSTEM_SETTING } from '../../../shared/session-utils';
import { ALLIANCE_ADMINISTRATOR } from '../../../shared/roles';
import sessionStore, { NEW_SESSION_EVENT } from '../../../shared/SessionStore';

/**
 * Checks if a User can Edit A Deal.
 *
 * @param {object} user - The user.
 * @param {object} alliance - The Alliance.
 * @param {object} deal - The Deal.
 *
 */

export const canEditDeal = (user, deal, alliance) => {
  if (!deal || !deal.status || !user || !alliance) return false;

  if (isAllianceCompleted(alliance)) return false;

  if (isUserAdminOrSERInAlliance(user, alliance)) return true;

  if (deal.status === DEAL_CLOSED) return false;
  if (deal.status === DEAL_COMPLETED) return false;

  if (isUserCreatorInAlliance(user, alliance)) return true;

  return false;
};

/**
 * Checks if a User can Delete A Deal.
 *
 * @param {object} user - The user.
 * @param {object} deal - The Deal.
 * @param {object} alliance - The Alliance.
 *
 * @returns {[boolean, [string]]} - A boolean and an array of errors.
 */
export const canDeleteDeal = (user, deal, alliance) => {
  const errors = [];
  if (isAllianceCompleted(alliance))
    errors.push('Cannot delete a deal if the alliance is completed');

  if (deal.status === DEAL_CLOSED) errors.push('Cannot delete a closed deal');

  if (!isUserAdminOrSERInAlliance(user, alliance))
    errors.push('You must be Admin or SER in the alliance to delete');

  return [!errors.length, errors];
};

/**
 * Checks if a User can Close a Deal.
 *
 * @param {object} user - The user.
 * @param {object} deal - The Deal.
 * @param {object} alliance - The Alliance.
 *
 * @returns {boolean} - A boolean if we can complete.
 */
export const canCompletedDeal = (user, deal, alliance) => {
  if (isAllianceCompleted(alliance)) return false;

  if (deal.status === DEAL_COMPLETED) return false;

  return isUserAdminOrSERInAlliance(user, alliance);
};

/**
 *
 * @param {object} user - Current user.
 * @param { object } alliance - Alliance.
 * @returns {boolean} If can create deal.
 */
export const canCreateDeal = (user, alliance) => {
  if (isAllianceCompleted(alliance)) return false;

  if (isUserAdminOrSERInAlliance(user, alliance)) return true;

  if (isUserCreatorInAlliance(user, alliance)) return true;

  return false;
};

export const canBulkDeals = (alliance) => {
  return !isAllianceCompleted(alliance);
};

export const canImportCSV = (alliance) => {
  return !isAllianceCompleted(alliance);
};

export const canImportSalesforce = (alliance) => {
  const { user } = sessionStore.getState(NEW_SESSION_EVENT);
  const roles = getRolesOnAlliance(user, alliance);

  // Only Alliance Administrators are able to use salesforce
  if (!roles.includes(ALLIANCE_ADMINISTRATOR)) return false;

  const isActive = getSystemSettingStatus(SYSTEM_SETTING.DEALS_SALESFORCE_IMPORT);
  return isActive && !isAllianceCompleted(alliance);
};
