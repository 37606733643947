import gql from 'graphql-tag';
import { NextStepFragment } from '../../../modules/next-step/next-step-queries';

export const UserToItemFragment = gql`
  fragment UserToItemFragment on User {
    id
    firstName
    lastName
    email
    avatar {
      id
      fileId
      filename
      downloadUrl
    }
  }
`;

export const ActiveItemFragment = gql`
  fragment ActiveItemFragment on Item {
    id
    createdAt
    action {
      id
      name
      description
      status
      createdAt
      revisedDueDate
      originalDueDate
      businessCase {
        id
      }
      owner {
        ...UserToItemFragment
      }
      assignedTo {
        ...UserToItemFragment
      }
      nextSteps {
        items {
          ...NextStepFragment
        }
      }
    }
    issue {
      id
      name
      description
      status
      createdAt
      revisedDueDate
      originalDueDate
      owner {
        ...UserToItemFragment
      }
      assignedTo {
        ...UserToItemFragment
      }
      nextSteps {
        items {
          ...NextStepFragment
        }
      }
    }
    risk {
      id
      name
      description
      status
      createdAt
      revisedDueDate
      originalDueDate
      owner {
        ...UserToItemFragment
      }
      assignedTo {
        ...UserToItemFragment
      }
      nextSteps {
        items {
          ...NextStepFragment
        }
      }
    }
    fundingRequest {
      id
      name
      description
      status
      createdAt
      revisedDueDate
      originalDueDate
      businessCase {
        id
      }
      owner {
        ...UserToItemFragment
      }
      assignedTo {
        ...UserToItemFragment
      }
      nextSteps {
        items {
          ...NextStepFragment
        }
      }
    }
    idea {
      id
      name
      description
      status
      createdAt
      revisedDueDate
      originalDueDate
      businessCase {
        id
      }
      owner {
        ...UserToItemFragment
      }
      assignedTo {
        ...UserToItemFragment
      }
      nextSteps {
        items {
          ...NextStepFragment
        }
      }
    }
  }
  ${UserToItemFragment}
  ${NextStepFragment}
`;

const InitiativeActiveItemFragment = gql`
  fragment InitiativeActiveItemFragment on Initiative {
    id
    name
    description
    createdAt
    owner {
      ...UserToItemFragment
    }
    status
    baselineEndDate
  }
`;

export const WORK_QUEUE_ITEMS_LIST_QUERY = gql`
  query($itemFilter: ItemFilter, $initiativesFilter: InitiativeFilter) {
    itemsList(filter: $itemFilter) {
      count
      items {
        ...ActiveItemFragment
      }
    }
    initiativesList(filter: $initiativesFilter) {
      count
      items {
        ...InitiativeActiveItemFragment
      }
    }
  }
  ${ActiveItemFragment}
  ${InitiativeActiveItemFragment}
  ${NextStepFragment}
`;
