import Flux from '@cobuildlab/flux-state';

/**
 * Event that triggers a NEXT ACTION error.
 *
 * @type {string}
 */
export const ACTIVE_ITEMS_ERROR_EVENT = 'onActiveItemsError';

/**
 * Event that triggers the next Action SideBar.
 *
 * @type {string}
 */
export const ACTIVE_ITEMS_REQUEST_EVENT = 'onActiveItemsRequest';

/**
 * Event that triggers a Next Action retrieved Event.
 *
 * @type {string}
 */
export const ACTIVE_ITEMS_EVENT = 'onActiveItems';

/**
 * Hold the CommentsData.
 */
class ActiveItemsStore extends Flux.DashStore {
  constructor() {
    super();
    this.addEvent(ACTIVE_ITEMS_ERROR_EVENT);
    this.addEvent(ACTIVE_ITEMS_REQUEST_EVENT);
    this.addEvent(ACTIVE_ITEMS_EVENT);
  }
}

export default new ActiveItemsStore();
