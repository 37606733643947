import React from 'react';
import { Row, Column, Text } from '@8base/boost';
import { PaymentSection } from './PaymentSection';
import PropTypes from 'prop-types';
import moment from 'moment';

const PaymentDetails = ({ companySubscription }) => {
  const { last4, cardExpireDate } = companySubscription;
  const cardExpireDateText = cardExpireDate ? moment(cardExpireDate).format('MM/YY') : '';
  const cardLast4Text = last4 ? `**** **** **** ${last4}` : '';

  return (
    <>
      <PaymentSection>
        <Row alignItems="center">
          <Column stretch>
            <Text>{`Credit Card`}</Text>
          </Column>
          <Column stretch>
            <Text>{cardLast4Text}</Text>
          </Column>
          <Column alignItems="end" stretch>
            {/* card icon*/}
          </Column>
        </Row>
      </PaymentSection>
      <PaymentSection>
        <Row alignItems="center">
          <Column stretch>
            <Text>{`Expiration Date`}</Text>
          </Column>
          <Column stretch>
            <Text>{cardExpireDateText}</Text>
          </Column>
          <Column stretch></Column>
        </Row>
      </PaymentSection>
    </>
  );
};

PaymentDetails.propTypes = {
  companySubscription: PropTypes.object.isRequired,
};

export { PaymentDetails };
