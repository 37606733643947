import React from 'react';
import {
  Column,
  InputField,
  Radio,
  RadioGroupField,
  Row,
  SelectField,
  TextAreaField,
} from '@8base/boost';
import PropTypes from 'prop-types';
import { onErrorMixin } from '../../../../shared/mixins';
import { FileInput } from '@8base-react/file-input';
import ImgUpload from '../../../../components/forms/ImgUpload';
import { CurrencyInputField } from '../../../../shared/components/CurrencyInputField';
import numeral from 'numeral';
import { HorizontalLine } from '../../../../components/new-ui/text/HorizontalLine';
import { HorizontalLineText } from '../../../../components/new-ui/text/HorizontalLineText';
import { GroupInputs } from '../../../../components/new-ui/inputs/GroupInputs';
import { FILESTACK_DEFAULT_OPTIONS } from '../../../../components/inputs/FileInputComponent';

/**
 * The Form for the Company Entity.
 */
class CompanyForm extends React.Component {
  render() {
    const { isDisabled, onChange, countries, usaStates, industries } = this.props;
    const {
      name,
      description,
      missionAndGoals,
      website,
      address1,
      address2,
      zipCode,
      city,
      state,
      phone,
      annualRevenue,
      numberOfEmployees,
      parentCompany,
      companyStatus,
      tickerSymbol,
      country,
      industry,
      logoFile,
      pictureUrl, // temporal url for update avatar only
    } = this.props.data;

    // Show USA states options Select if country === USA
    const currentCountry = countries.find((item) => country === item.id);
    const showUsaStates = currentCountry && currentCountry.name === 'United States' ? true : false;
    // set pictureUrlToShow to pictureUrl || avatar.downloadUrl
    let pictureUrlToShow;
    if (pictureUrl) pictureUrlToShow = pictureUrl;
    else if (logoFile) pictureUrlToShow = logoFile.downloadUrl;

    return (
      <React.Fragment>
        <HorizontalLine>
          <HorizontalLineText text={'COMPANY'} />
        </HorizontalLine>
        <GroupInputs text={'General'}>
          <Row style={{ marginBottom: '10px', margin: '0 auto' }} justifyContent="center">
            <Column />
            <FileInput
              onChange={(picture, file) => {
                const reader = new FileReader();
                reader.addEventListener(
                  'load',
                  () => {
                    onChange('pictureUrl', reader.result);
                  },
                  false,
                );
                reader.readAsDataURL(file);
                onChange('logoFile', picture);
              }}
              value={'input.value'}
              maxFiles={1}>
              {({ pick }) => {
                return (
                  <Column stretch justifyContent="center">
                    <ImgUpload
                      disabled={isDisabled}
                      onClick={isDisabled ? null : () => pick(FILESTACK_DEFAULT_OPTIONS)}
                      url={pictureUrlToShow}
                    />
                  </Column>
                );
              }}
            </FileInput>
            <Column />
          </Row>
          <Row growChildren gap="lg">
            <Column>
              <InputField
                disabled={isDisabled}
                stretch
                placeholder="Name of your Company"
                label="Company Name"
                input={{
                  name: 'name',
                  value: name,
                  onChange: (value, e) => onChange(e.target.name, value),
                }}
              />

              <TextAreaField
                disabled={isDisabled}
                style={{ width: '100%', height: 121 }}
                placeholder="Description of your Company"
                label="Description"
                input={{
                  name: 'description',
                  value: description,
                  onChange: (value) => onChange('description', value),
                }}
              />
              <InputField
                disabled={isDisabled}
                stretch
                label="Phone Number"
                placeholder="xxx-xxx-xxxx"
                input={{
                  name: 'phone',
                  value: phone,
                  onChange: (value, e) => onChange(e.target.name, value),
                }}
              />

              <TextAreaField
                disabled={isDisabled}
                style={{ width: '100%', height: 121 }}
                placeholder="Mission and Goals"
                label="Company's Mission and Goals"
                input={{
                  name: 'missionAndGoals',
                  value: missionAndGoals,
                  onChange: (value) => onChange('missionAndGoals', value),
                }}
              />
              <InputField
                disabled={isDisabled}
                stretch
                label="Website URL"
                placeholder="URL of your Website"
                input={{
                  name: 'website',
                  value: website,
                  onChange: (value, e) => onChange(e.target.name, value),
                }}
              />
              <InputField
                disabled={isDisabled}
                stretch
                label="Parent Company"
                placeholder="Company Name"
                input={{
                  name: 'parentCompany',
                  value: parentCompany,
                  onChange: (value, e) => onChange(e.target.name, value),
                }}
              />
              <CurrencyInputField
                disabled={isDisabled}
                label={'Annual Revenue'}
                value={annualRevenue}
                onChange={(val) => {
                  onChange('annualRevenue', numeral(val).value());
                }}
              />
              <SelectField
                stretch
                disabled={isDisabled}
                label="Number Of Employees"
                input={{
                  name: 'numberOfEmployees',
                  value: numberOfEmployees,
                  onChange: (value) => onChange('numberOfEmployees', value),
                }}
                meta={{}}
                placeholder="Select"
                options={[
                  { label: '1 - 99', value: '1 - 99' },
                  { label: '100 - 249', value: '100 - 249' },
                  { label: '250 - 999', value: '250 - 999' },
                  { label: '> 1000', value: '> 1000' },
                ]}
              />
              <SelectField
                placeholder="Select"
                disabled={isDisabled}
                label="Industry"
                input={{
                  name: 'industry',
                  value: industry,
                  onChange: (value) => onChange('industry', value),
                }}
                meta={{}}
                options={industries.map((industry) => {
                  return { label: industry.name, value: industry.id };
                })}
              />

              <RadioGroupField
                disabled={isDisabled}
                direction="row"
                label="Company Status"
                input={{
                  name: 'companyStatus',
                  value: companyStatus,
                  onChange: (value, e) => onChange(e.target.name, value),
                }}
                meta={{}}>
                <Radio.Item default label="Private" value={'private'} id="private" />
                <Radio.Item label="Public" value={'public'} />
              </RadioGroupField>
              <span
                style={{ marginTop: 0 }}
                className={`ticker ${companyStatus === 'public' ? 'is-visible' : ''}`}>
                <InputField
                  disabled={isDisabled}
                  stretch
                  label="Ticker Symbol (Optional)"
                  placeholder="Symbol"
                  input={{
                    name: 'tickerSymbol',
                    value: tickerSymbol,
                    onChange: (value, e) => onChange(e.target.name, value),
                  }}
                />
              </span>
            </Column>
          </Row>
        </GroupInputs>
        <GroupInputs text={'Address'} show={true}>
          <Row growChildren gap="lg">
            <Column>
              <InputField
                disabled={isDisabled}
                stretch
                placeholder="Address Line 1"
                label="Address Line 1"
                input={{
                  name: 'address1',
                  value: address1,
                  onChange: (value, e) => onChange(e.target.name, value),
                }}
              />

              <InputField
                stretch
                disabled={isDisabled}
                placeholder="Address Line 2"
                label="Address Line 2"
                input={{
                  name: 'address2',
                  value: address2,
                  onChange: (value, e) => onChange(e.target.name, value),
                }}
              />
              <SelectField
                disabled={isDisabled}
                label="Country"
                input={{
                  name: 'country',
                  value: country,
                  onChange: (value) => {
                    onChange('country', value);
                    // if Country === USA set state to ''
                    const selectedCountry = countries.find((item) => value === item.id);
                    const deleteCurrentState =
                      selectedCountry && selectedCountry.name === 'United States' ? true : false;
                    if (deleteCurrentState) onChange('state', '');
                  },
                }}
                meta={{}}
                placeholder="Country of your Company"
                options={countries.map((country) => {
                  return { label: country.name, value: country.id };
                })}
              />

              <InputField
                disabled={isDisabled}
                placeholder="Postal/Zip Code"
                label="Postal/Zip Code"
                input={{
                  name: 'zipCode',
                  value: zipCode,
                  onChange: (value, e) => onChange(e.target.name, value),
                }}
              />

              {showUsaStates ? (
                <SelectField
                  disabled={isDisabled}
                  input={{
                    name: 'state',
                    value: state,
                    onChange: (value) => onChange('state', value),
                  }}
                  meta={{}}
                  placeholder="State"
                  label="State"
                  options={usaStates.map((state) => {
                    return { label: state.name, value: state.name };
                  })}
                />
              ) : (
                <InputField
                  disabled={isDisabled}
                  placeholder="State"
                  label="State"
                  input={{
                    name: 'state',
                    value: state,
                    onChange: (value, e) => onChange(e.target.name, value),
                  }}
                />
              )}
              <InputField
                disabled={isDisabled}
                placeholder="City"
                label="City"
                input={{
                  name: 'city',
                  value: city,
                  onChange: (value, e) => onChange(e.target.name, value),
                }}
              />
            </Column>
          </Row>
        </GroupInputs>
      </React.Fragment>
    );
  }
}

Object.assign(CompanyForm.prototype, onErrorMixin);

CompanyForm.propTypes = {
  data: PropTypes.object.isRequired,
  countries: PropTypes.array.isRequired,
  usaStates: PropTypes.array.isRequired,
  industries: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
};
CompanyForm.defaultProps = {
  isDisabled: false,
};
export default CompanyForm;
