import React from 'react';
import PropTypes from 'prop-types';
import { Card, Grid, Heading, Loader, Row, Switch } from '@8base/boost';
import { CreateViewCardBody } from '../../../components/new-ui/card/CreateViewCardBody';
import ActionForm from './components/ActionForm';
import {
  onChangeBusinessCaseDataMixin,
  onChangeDataMixin,
  onErrorMixin,
} from '../../../shared/mixins';
import * as R from 'ramda';
import ActionModel, { ACTION_DOCUMENTS } from './action-model';
import { withRouter } from 'react-router-dom';
import * as toast from '../../../components/toast/Toast';
import { View } from '@cobuildlab/react-simple-state';
import { autoSaveCreateAction, createAction, deleteActionBusinessCase } from './action-actions';
import { fetchCurrentAllianceMembersAction } from '../../settings/alliance-management/alliance-actions';
import { fetchInitiativeList } from '../initiative/initiative-actions';
import BusinessCaseModel from '../../document-management/business-case/BusinessCase.model';
import sessionStore, { NEW_SESSION_EVENT } from '../../../shared/SessionStore';
import BusinessCaseForm from '../../document-management/business-case/components/BusinessCaseForm';
import SubHeader from '../../../components/SubHeader';
import BusinessCaseDetailTable from '../../document-management/business-case/components/BusinessCaseDetailTable';
import ActionDetailTable from './components/ActionDetailTable';
import { getCurrencyOnSession } from '../../../shared/alliance-utils';
import { ActionButton } from '../../../components/buttons/ActionButton';
import { ActionButtonClose } from '../../../components/buttons/ActionButtonClose';
import { TransparentButton } from '../../../components/buttons/TransparentButton';
import { FormSteps } from '../../../components/dots/FormSteps';
import { actionValidator } from './action-validators';
import { businessCaseValidator } from '../../document-management/business-case/business-case-validators';
import { saveFormToSessionStorage } from '../../../shared/utils';
import { initiativesItemValidator } from '../initiative/initiative-validators';
import RelatedItemForm from '../../related-item/components/RelatedItemForm';
import { RelatedItemsDetailTable } from '../../related-item/components/RelatedItemsDetailTable';
import { InitiativeListTable } from '../initiative/components/InitiativeListTable';
import { CardFooter } from '../../../components/new-ui/card/CardFooter';
import { LeftProgressSection } from '../../../components/new-ui/LeftProgressSection';
import { SCREENS_ACTIONS } from '../screenView';
import { BoxCard } from '../../../components/new-ui/div/BoxCard';
import { TextDateAgo } from '../../../components/text/TextDateAgo';
import YesNoDialog from '../../../components/dialogs/YesNoDialog';
import {
  OnActionError,
  OnActionCreate,
  OnActionAutoSave,
  OnActionAutoSaveError,
  OnActionBusinessCaseDelete,
  OnActionBusinessCaseDeleteError,
} from './action-events';
import { OnInitiativeList } from '../initiative/initiative-events';
import { OnAllianceMemberList } from '../../settings/alliance-management/alliance-events';

export const ACTION_DATA_STORE = 'actionCreateView';

// eslint-disable-next-line
/**
 * Create Action.
 *
 */
class ActionCreateView extends View {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        actionData: R.clone(ActionModel),
        businessCaseData: R.clone(BusinessCaseModel),
        relatedItems: [],
        initiatives: [],
      },
      clientCompany: null,
      partnerCompany: null,
      initiativesList: [],
      step: 0,
      loading: true,
      savedAt: null,
      autoSaving: false,
      isOpen: false,
    };
    this.onChangeData = onChangeDataMixin.bind(this);
    this.onError = onErrorMixin.bind(this);
    this.onChangeBusinessCaseData = (name, value) => {
      this.autoSave();
      onChangeBusinessCaseDataMixin.call(this, name, value);
    };

    this.autoSaveTimer = null;
    this.savedAction = null;
  }

  onChangeActionData = (name, value) => {
    const { data } = this.state;
    data.actionData[name] = value;
    if (name === 'originalDueDate') {
      data.actionData.revisedDueDate = value;
    }
    this.autoSave();
    this.setState({ data });
    const model = R.clone(ActionModel);
    saveFormToSessionStorage(ACTION_DATA_STORE, data.actionData, model, ['documents']);
  };

  componentDidMount = () => {
    this.subscribe(OnActionError, this.onError);

    this.subscribe(OnAllianceMemberList, (state) => {
      this.setState({
        clientCompany: R.clone(state.clientCompany),
        partnerCompany: R.clone(state.partnerCompany),
      });
    });

    this.subscribe(OnActionCreate, (state) => {
      sessionStorage.removeItem(ACTION_DATA_STORE);
      toast.success('Action Successfully Created');
      this.props.history.push(`/management/amo-item`);
    });
    this.subscribe(OnInitiativeList, (state) => {
      const initiativesList = R.clone(state.initiativesList.items);
      this.setState({
        loading: false,
        initiativesList,
      });
    });

    this.subscribe(OnActionAutoSave, (action) => {
      sessionStorage.removeItem(ACTION_DATA_STORE);
      const data = R.clone(this.state.data);
      data.actionData = R.clone(this.state.data.actionData);
      data.businessCaseData = R.clone(this.state.data.businessCaseData);
      data.actionData.id = action.id;
      data.businessCaseData.id = action.businessCase.id;

      this.setState({ savedAt: new Date(), autoSaving: false, data });
      this.savedAction = R.clone(action);
    });

    this.subscribe(OnActionAutoSaveError, () => {
      this.setState({ autoSaving: false });
    });

    this.subscribe(OnActionBusinessCaseDelete, () => {
      this.props.history.push('/management/action-without-business-case/create');
    });

    this.subscribe(OnActionBusinessCaseDeleteError, () => {
      this.setState({ loading: false });
      toast.error('An error has occurred, please try again');
    });

    // set actionData from sessionStorage
    const actionData = JSON.parse(sessionStorage.getItem(ACTION_DATA_STORE));
    if (actionData) {
      const { data } = this.state;
      data.actionData = actionData;
      this.setState({ data });
    }

    fetchInitiativeList('', 1, 1000);
    fetchCurrentAllianceMembersAction();
  };

  autoSave = () => {
    const waitTime = 10000; // Wait 10s
    clearTimeout(this.autoSaveTimer);
    this.autoSaveTimer = setTimeout(() => {
      this.setState({ autoSaving: true });
      const actionData = R.clone(this.state.data.actionData);
      const businessCaseData = R.clone(this.state.data.businessCaseData);
      const relatedItems = R.clone(this.state.data.relatedItems);
      const initiatives = R.clone(this.state.data.initiatives);

      actionData.id = null;
      businessCaseData.id = null;

      autoSaveCreateAction(
        actionData,
        businessCaseData,
        relatedItems,
        initiatives,
        this.savedAction,
      );
    }, waitTime);
  };

  componentWillUnmount() {
    super.componentWillUnmount();
    clearTimeout(this.autoSaveTimer);
    localStorage.removeItem(ACTION_DOCUMENTS);
  }

  onSubmit = () => {
    this.setState({ loading: true }, () => {
      clearTimeout(this.autoSaveTimer);
      const actionData = R.clone(this.state.data.actionData);
      const businessCaseData = R.clone(this.state.data.businessCaseData);
      const relatedItems = R.clone(this.state.data.relatedItems);
      const initiatives = R.clone(this.state.data.initiatives);
      actionData.id = null;
      businessCaseData.id = null;

      createAction(actionData, businessCaseData, relatedItems, initiatives, this.savedAction);
    });
  };

  onSwitchPage = () => {
    const { history } = this.props;
    const { data, autoSaving } = this.state;
    const { actionData, businessCaseData } = data;

    if (autoSaving) {
      toast.info('Auto saving data please wait');
      return;
    }

    if (actionData.id || businessCaseData.id) {
      this.onToggleModal();
    } else {
      history.push('/management/action-without-business-case/create');
    }
  };

  onActionStepChange = (nextStep) => {
    const { selectedAlliance } = sessionStore.getState(NEW_SESSION_EVENT);
    const actionData = R.clone(this.state.data.actionData);
    try {
      actionValidator(actionData, selectedAlliance);
    } catch (e) {
      return this.onError(e);
    }
    this.onScreen(nextStep);
  };

  onBusinessCaseStepChange = (nextStep) => {
    const businessCaseData = R.clone(this.state.data.businessCaseData);
    try {
      businessCaseValidator(businessCaseData);
    } catch (e) {
      return this.onError(e);
    }
    this.onScreen(nextStep);
  };

  onRelatedItemsStepChange = (step) => {
    const initiatives = R.clone(this.state.data.initiatives);
    try {
      initiativesItemValidator(initiatives);
    } catch (e) {
      return this.onError(e);
    }
    this.onScreen(step);
  };

  onScreen = (step) => {
    if (step === 3) clearTimeout(this.autoSaveTimer);
    this.setState({ step });
  };

  onToggleModal = () => this.setState({ isOpen: !this.state.isOpen });

  onDeleteData = () => {
    const { actionData, businessCaseData } = this.state.data;
    deleteActionBusinessCase(actionData.id, businessCaseData.id);
    this.onToggleModal();
    this.setState({ loading: true });
  };

  render() {
    const {
      initiativesList,
      clientCompany,
      partnerCompany,
      step,
      loading,
      savedAt,
      autoSaving,
      isOpen,
    } = this.state;
    const { actionData, businessCaseData, relatedItems, initiatives } = this.state.data;
    const { user, selectedAlliance } = sessionStore.getState(NEW_SESSION_EVENT);
    const companyId = user.companyUserRelation.items[0].company.id;
    const { history } = this.props;
    const currency = getCurrencyOnSession();
    let content = <Loader stretch />;
    let footer = <></>;

    if (!loading && step === 0) {
      content = (
        <div id={'c2gScreen'}>
          <ActionForm
            data={actionData}
            onChange={this.onChangeActionData}
            clientCompany={clientCompany}
            partnerCompany={partnerCompany}
            myCompanyId={companyId}
            currency={currency}
            user={user}
            selectedAlliance={selectedAlliance}
          />
        </div>
      );
      footer = (
        <CardFooter>
          <ActionButton onClick={() => this.onActionStepChange(1)} text={'Next'} />
        </CardFooter>
      );
    }

    if (!loading && step === 1) {
      content = (
        <div id={'c2gScreen'}>
          <BusinessCaseForm
            data={businessCaseData}
            onChange={this.onChangeBusinessCaseData}
            currency={currency}
            showBusinessFields
            hideExpectCostAvoidance
          />
        </div>
      );
      footer = (
        <CardFooter>
          <ActionButton onClick={() => this.onBusinessCaseStepChange(2)} text={'Next'} />
          <TransparentButton onClick={() => this.onScreen(0)} text={'Previous'} />
        </CardFooter>
      );
    }

    if (!loading && step === 2) {
      content = (
        <div id={'c2gScreen'}>
          <RelatedItemForm
            relatedItems={relatedItems}
            initiatives={initiativesList}
            onChange={(key, value) => {
              this.autoSave();
              this.onChangeData(key, value);
            }}
            selectedInitiatives={initiatives}
          />
        </div>
      );

      footer = (
        <CardFooter>
          <ActionButton onClick={() => this.onRelatedItemsStepChange(3)} text={'Next'} />
          <TransparentButton onClick={() => this.onScreen(1)} text={'Previous'} />
        </CardFooter>
      );
    }
    if (!loading && step === 3) {
      const actionDetails = R.clone(actionData);

      actionDetails.documents = { items: actionData.documents };
      actionDetails.nextSteps = { items: actionData.nextSteps };

      content = (
        <div id={'c2gScreen'}>
          <ActionDetailTable data={actionDetails} currency={currency} />
          <SubHeader text="Business Case" status={actionData.status} />
          <BusinessCaseDetailTable
            hideExpectedCostAvoidance
            data={businessCaseData}
            currency={currency}
          />
          <InitiativeListTable initiatives={initiatives} />
          <RelatedItemsDetailTable relatedItems={relatedItems} />
        </div>
      );
      footer = (
        <CardFooter>
          <ActionButton
            disabled={autoSaving}
            onClick={() => this.onSubmit()}
            text={'Create Action'}
          />
          <TransparentButton onClick={() => this.onScreen(2)} text={'Previous'} />
        </CardFooter>
      );
    }

    return (
      <React.Fragment>
        <Card.Header>
          <Grid.Layout
            columns="400px auto 400px"
            areas={[['left', 'center', 'right']]}
            style={{ width: '100%' }}>
            <Grid.Box area="left">
              <Row>
                <Heading type="h4" text="Create Action" />
                <TextDateAgo from={savedAt} />
              </Row>
            </Grid.Box>
            <Grid.Box area="center">
              <FormSteps totalSteps={4} step={step} />
            </Grid.Box>

            <Grid.Box area="right" direction="row" alignItems="center">
              <Switch label={'Business Case'} value onChange={this.onSwitchPage} />
            </Grid.Box>
          </Grid.Layout>
          <ActionButtonClose onClick={history.goBack} />
        </Card.Header>
        <CreateViewCardBody>
          <Grid.Layout columns="30% 70%" areas={[['left', 'right']]} style={{ width: '100%' }}>
            <Grid.Box area="left">
              <LeftProgressSection sections={SCREENS_ACTIONS} currentScreen={step} />
            </Grid.Box>
            <BoxCard>
              <Grid.Box area="right">{content}</Grid.Box>
            </BoxCard>
          </Grid.Layout>
        </CreateViewCardBody>
        {footer}
        <YesNoDialog
          isOpen={isOpen}
          title="Without business case"
          text="Do you want to change the form? Some data can be deleted"
          onNo={this.onToggleModal}
          onYes={this.onDeleteData}
        />
      </React.Fragment>
    );
  }
}

ActionCreateView.propTypes = {
  history: PropTypes.object.isRequired,
};

export default withRouter(ActionCreateView);
