import React from 'react';
import { Card, Heading, Loader, Row } from '@8base/boost';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import * as toast from '../../../components/toast/Toast';
import * as R from 'ramda';
import { View } from '@cobuildlab/react-simple-state';
import { onErrorMixin } from '../../../shared/mixins';
import { IssueModel } from './issue-model';
import { fetchIssueDetail, completedIssue, restoreIssue } from './issue-actions';
import sessionStore, { NEW_SESSION_EVENT } from '../../../shared/SessionStore';
import { onChangeMixin } from '../../../shared/mixins';
import withAlliance from '../../../components/hoc/withAlliance';
import IssueDetailTable from './components/IssueDetailTable';
import { openComments } from './issue-actions';
import { canCompletedIssue, canRestoreIssue } from './issue-permissions';
import {
  fetchRelatedItems,
  fetchRelatedItemsByItemId,
} from '../../related-item/related-item-actions';
import YesNoDialog from '../../../components/dialogs/YesNoDialog';
import { DetailViewCardBody } from '../../../components/card/DetailViewCardBody';
import { ActionButton } from '../../../components/buttons/ActionButton';
import { getCurrencyOnSession } from '../../../shared/alliance-utils';
import { RelatedItemsDetailTable } from '../../related-item/components/RelatedItemsDetailTable';
import { getItemByType } from '../../../shared/items-util';
import { TopButtons } from '../../../components/buttons/TopButtons';
import { RelatedItemsByItemDetailTable } from '../../related-item/components/RelatedItemsByItemDetailTable';
import { InitiativeListTable } from '../initiative/components/InitiativeListTable';
import { CardFooter } from '../../../components/new-ui/card/CardFooter';
import { OnRelatedItems, OnRelatedItemsByItem } from '../../related-item/related-item-events';
import { OnIssueError, OnIssueDetail, OnIssueCompleted, OnIssueRestore } from './issue-events';

/**
 * Issue Detail View.
 */
class IssueDetailView extends View {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        issueData: R.clone(IssueModel),
        relatedItems: [],
        initiatives: [],
        relatedItemsByItem: [],
      },
      loading: true,
      completedModalIsOpen: false,
      restoreModalIsOpen: false,
    };
    this.onChange = onChangeMixin.bind(this);
    this.onError = onErrorMixin.bind(this);
    this.user = sessionStore.getState(NEW_SESSION_EVENT).user;
    this.selectedAlliance = sessionStore.getState(NEW_SESSION_EVENT).selectedAlliance;
  }

  componentDidMount() {
    this.subscribe(OnIssueError, this.onError);
    this.subscribe(OnIssueDetail, (state) => {
      const { issue: issueData } = state;
      const { data } = this.state;
      const initiatives = R.clone(issueData.initiatives.items);
      data.initiatives = initiatives;
      data.issueData = issueData;
      this.setState(
        {
          data,
        },
        () => fetchRelatedItems(issueData.itemIssueRelation.id),
        fetchRelatedItemsByItemId(issueData.itemIssueRelation.id),
      );
    });

    this.subscribe(OnIssueCompleted, (state) => {
      fetchIssueDetail(match.params.id);
      toast.success('Issue Successfully Completed');
    });

    this.subscribe(OnIssueRestore, (state) => {
      fetchIssueDetail(match.params.id);
      toast.success('Issue Successfully Restored');
    });

    this.subscribe(OnRelatedItems, (state) => {
      const items = state.item.itemsRelated.items.map((item) => getItemByType(item));
      const { data } = this.state;

      data.relatedItems = items;
      this.setState({ data });
    });

    this.subscribe(OnRelatedItemsByItem, (state) => {
      const {
        itemsList: { items: itemsRelated },
      } = state;
      const relatedItemsByItem = itemsRelated.map((item) => getItemByType(item));
      const { data } = this.state;

      data.relatedItemsByItem = relatedItemsByItem;
      this.setState({ data, loading: false });

      console.log('relatedItemsByItem', relatedItemsByItem);
    });

    const { match } = this.props;
    if (!match.params.id) return toast.error('Issue ID missing');
    const fetchPolicyOptions = { isCacheFirst: true };

    fetchIssueDetail(match.params.id, fetchPolicyOptions);
  }

  closed = () => {
    this.setState({
      completedModalIsOpen: true,
    });
  };

  onYes = () => {
    this.setState(
      {
        completedModalIsOpen: false,
        loading: true,
      },
      () => {
        const issueData = R.clone(this.state.data.issueData);
        completedIssue(issueData);
      },
    );
  };

  onCompletedCloseModal = () => {
    this.setState({
      completedModalIsOpen: false,
    });
  };

  restoreModal = () => {
    this.setState({
      restoreModalIsOpen: true,
    });
  };

  onRestoreCloseModal = () => {
    this.setState({
      restoreModalIsOpen: false,
    });
  };

  onYesRestore = () => {
    this.setState(
      {
        restoreModalIsOpen: false,
        loading: true,
      },
      () => {
        const issueData = R.clone(this.state.data.issueData);
        restoreIssue(issueData);
      },
    );
  };

  render() {
    const { loading, completedModalIsOpen, restoreModalIsOpen } = this.state;
    const { issueData, relatedItems, initiatives, relatedItemsByItem } = this.state.data;
    const { history } = this.props;
    let content = <Loader stretch />;
    let buttonsBottom = '';
    let buttonsTop = '';
    const currency = getCurrencyOnSession();
    const alliance = this.selectedAlliance;

    if (!loading) {
      content = (
        <div id={'c2gScreen'}>
          <IssueDetailTable
            data={issueData}
            currency={currency}
            onClickEdit={() => history.push(`/management/issue/edit/${issueData.id}`)}
          />
          <InitiativeListTable initiatives={initiatives} />
          <RelatedItemsDetailTable relatedItems={relatedItems} />
          <RelatedItemsByItemDetailTable relatedItemsByItem={relatedItemsByItem} />
        </div>
      );

      buttonsBottom = (
        <Row justifyContent="end">
          {canCompletedIssue(this.user, issueData, alliance) ? (
            <ActionButton
              text="Mark Completed"
              fontAwesomeIcon="clipboard-list"
              onClick={() => {
                this.closed();
              }}
            />
          ) : null}
          {canRestoreIssue(this.user, issueData, alliance) ? (
            <ActionButton
              text="Restore"
              fontAwesomeIcon="clipboard-list"
              onClick={() => {
                this.restoreModal();
              }}
            />
          ) : null}
        </Row>
      );
      buttonsTop = (
        <>
          <Heading type="h4" text={issueData.name} />

          <TopButtons
            onClickClosed={history.goBack}
            onClickCollaborated={() => openComments(issueData)}
          />
        </>
      );
    }

    return (
      <React.Fragment>
        <Card.Header>{buttonsTop}</Card.Header>
        <DetailViewCardBody>{content}</DetailViewCardBody>

        <CardFooter>{buttonsBottom}</CardFooter>
        <YesNoDialog
          title={'Complete Issue'}
          onYes={this.onYes}
          onCompletedCloseModal={this.onCompletedCloseModal}
          onNo={this.onCompletedCloseModal}
          text={'Are you sure you want to Mark the Issue as Completed?'}
          isOpen={completedModalIsOpen}
        />
        <YesNoDialog
          title={'Restore Issue'}
          onYes={this.onYesRestore}
          onCompletedCloseModal={this.onRestoreCloseModal}
          onNo={this.onRestoreCloseModal}
          text={'Are you sure you want to Mark the Issue as Restored?'}
          isOpen={restoreModalIsOpen}
        />
      </React.Fragment>
    );
  }
}

IssueDetailView.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default withRouter(withAlliance(IssueDetailView));
