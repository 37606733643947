import { ISSUE_CLOSED, ISSUE_COMPLETED } from '../../../shared/status';
import {
  isUserAdminOrSERInAlliance,
  isUserCreatorInAlliance,
} from '../../../shared/alliance-utils';
import { isAllianceCompleted } from '@cobuildlab/collabtogrow-permissions';

/**
 * Checks if a User can Edit A Issue Item.
 *
 * @param {object} user - The user.
 * @param {object} issue - The issue.
 * @param {object} alliance - The Alliance.
 * @returns {boolean} Return promise.
 */
export const canEditIssue = (user, issue, alliance): boolean => {
  if (isAllianceCompleted(alliance)) return false;
  if (issue.status === ISSUE_CLOSED) return false;
  if (issue.status === ISSUE_COMPLETED) return false;
  if (isUserAdminOrSERInAlliance(user, alliance)) return true;
  if (isUserCreatorInAlliance(user, alliance)) return true;

  return false;
};

/**
 * Checks if a User can Close A Issue Item.
 *
 * @param {object} user - The user.
 * @param {object} alliance - The Alliance.
 * @param {object} issue - The issue.
 * @returns {boolean} Return promise.
 */

export const canCompletedIssue = (user, issue, alliance) => {
  if (isAllianceCompleted(alliance)) return false;

  if (issue.status === ISSUE_COMPLETED) return false;

  if (issue.assignedTo && issue.assignedTo.id === user.id) return true;

  return isUserAdminOrSERInAlliance(user, alliance);
};
/**
 * Checks if a User can open modal for Restore Issue.
 *
 * @param {object}user - User.
 * @param {object}issue - Issue.
 * @param {object}alliance - Alliance.
 * @returns {boolean} Return promise.
 */
export const canRestoreIssue = (user, issue, alliance) => {
  if (isAllianceCompleted(alliance)) return false;

  if (issue.status !== ISSUE_COMPLETED) return false;

  if (issue.assignedTo && issue.assignedTo.id === user.id) return true;

  return isUserAdminOrSERInAlliance(user, alliance);
};
