import React from 'react';
import { NoData, Column, Grid, Row, Select, Text, Card, Heading, Loader } from '@8base/boost';
import { onErrorMixin } from '../../../shared/mixins';
import { View } from '@cobuildlab/react-simple-state';

import { fetchDashboardData, getChartOptions, getWeekForCalendar } from '../dashboard-actions';
import { getAMOItemType } from '../../management/amo-item';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import arrowToRight from '../../../images/icons/arrow-pointing-to-right.svg';
import ActiveItem from '../../active-items/ActiveItem';
import UserSmallAvatar from '../../../components/user/UserSmallAvatar';
import ItemsCard from '../../active-items/work-queue/components/ItemsCard';
import {
  getUserActiveItems,
  getTasksForDate,
  getInitiativeActiveItems,
  filterActiveItemsByDueDate,
} from '../../active-items/active-items-utils';
import { fetchInitiativeSmallList } from '../../management/initiative/initiative-actions';
import { fetchInitiativeActiveItemsToDashboard } from '../../active-items/initiative-active-items/initiative-active-items-actions';
import { fetchWorkQueueItems } from '../../active-items/work-queue/work-queue-actions';
import sessionStore, { NEW_SESSION_EVENT } from '../../../shared/SessionStore';
import WeekComponent from './WeekComponent';
import YesNoDialog from '../../../components/dialogs/YesNoDialog';
import * as toast from '../../../components/toast/Toast';
import PieChart from '../../../components/PieChart';
import Status from '../../../components/Status';
import styled from '@emotion/styled';
import {
  onWorkQueueError,
  onWorkQueueItemsEvent,
} from '../../active-items/work-queue/work-queue-events';
import { onDashboardError, onDashboardItems } from '../dashboard-events';
import {
  onActiveItemsError,
  onInitiativeDashoardActiveItems,
} from '../../active-items/initiative-active-items/initiative-active-items-event';
import {
  OnInitiativeError,
  OnInitiativeSmallList,
} from '../../management/initiative/initiative-events';
import { nextStepCompletedEvent } from '../../next-step/next-step-event';
import { OnIdeaCompleted } from '../../management/idea/idea-events';
import { OnIssueCompleted } from '../../management/issue/issue-events';
import { OnRiskCompleted } from '../../management/risk/risk-events';
import { OnActionCompleted } from '../../management/action/action-events';
import { onFundingRequestCompleted } from '../../management/funding-request/funding-request-event';

const LeftCardContainer = styled('div')({
  display: 'inline-block',
  width: 'calc(60% - 12px)',
});
const RightCardContainer = styled('div')({
  display: 'inline-block',
  marginLeft: 24,
  width: 'calc(40% - 12px)',
});

const StyledCard = styled(Card)({
  maxHeight: '400px',
  minHeight: '400px',
});

// eslint-disable-next-line
/**
 * Main screen of the user when it logs in.
 * If the user hasn't completed the Sign Up, it redirects to the Wizard View.
 *
 */
class PrivateGraphsDashboard extends View {
  constructor(props) {
    super(props);
    this.user = null;
    this.state = {
      items: [],
      companyOnState: null,
      initiatives: [],
      allItems: 0,
      inProgresItems: 0,
      completedItems: 0,
      itemsToShow: [],
      loadingInitiativeData: true,
      selectedInitiative: null,
      tasksByDate: {},
      selectedDay: moment().format('YYYY-MM-DD'),
      loadingTasks: true,
      week: [],
      dateReference: moment().format('YYYY-MM-DD'),
      overDueItems: [],
      completeModalIsOpen: false,
      selectedItemToComplete: null,
      completeFunction: null,
    };
    this.user = sessionStore.getState(NEW_SESSION_EVENT).user;
    this.selectedAlliance = sessionStore.getState(NEW_SESSION_EVENT).selectedAlliance;
    this.onError = onErrorMixin.bind(this);
  }

  componentDidMount() {
    this.subscribe(onWorkQueueError, (e) => {
      this.onError(e);
      this.setState({ loadingTasks: false });
    });
    this.subscribe(onDashboardError, this.onError);
    this.subscribe(onActiveItemsError, this.onError);
    this.subscribe(OnInitiativeError, (e) => {
      this.onError(e);
      this.setState({ loadingInitiativeData: false });
    });
    this.subscribe(nextStepCompletedEvent, () => {
      toast.success('Next Step Successfully Completed');
      fetchWorkQueueItems();
      this.fetchInitiativeActiveItems();
    });
    this.subscribe(OnIdeaCompleted, () => {
      toast.success('Idea Successfully Completed');
      fetchWorkQueueItems();
      this.fetchInitiativeActiveItems();
    });
    this.subscribe(OnIssueCompleted, () => {
      toast.success('Issue Successfully Completed');
      fetchWorkQueueItems();
      this.fetchInitiativeActiveItems();
    });
    this.subscribe(OnRiskCompleted, () => {
      toast.success('Risk Successfully Completed');
      fetchWorkQueueItems();
      this.fetchInitiativeActiveItems();
    });
    this.subscribe(OnActionCompleted, () => {
      toast.success('Action Successfully Completed');
      fetchWorkQueueItems();
      this.fetchInitiativeActiveItems();
    });
    this.subscribe(onFundingRequestCompleted, () => {
      toast.success('Funding Request Successfully Completed');
      fetchWorkQueueItems();
      this.fetchInitiativeActiveItems();
    });

    this.subscribe(OnInitiativeSmallList, (data) => {
      const initiatives = data.initiativesList.items;
      const selectedInitiative = initiatives.length ? initiatives[0] : null;

      if (!selectedInitiative) {
        return this.setState({ loadingInitiativeData: false });
      }

      this.setState(
        {
          initiatives,
          selectedInitiative,
        },
        () => this.fetchInitiativeActiveItems(),
      );
    });

    this.subscribe(onWorkQueueItemsEvent, (state) => {
      const { items } = state.itemsList;
      const { items: initiatives } = state.initiativesList;
      const { allActiveItems } = getUserActiveItems(items, initiatives);

      const { overDueItems, dueThisWeekItems, notDueItems } = filterActiveItemsByDueDate(
        allActiveItems,
      );

      const notDueAndDueThisWeekActiveItems = dueThisWeekItems.concat(notDueItems);

      const { dateReference } = this.state;
      const week = getWeekForCalendar(dateReference);
      const tasksByDate = getTasksForDate(notDueAndDueThisWeekActiveItems);

      this.setState({
        loadingTasks: false,
        week,
        tasksByDate,
        overDueItems,
      });
    });

    this.subscribe(onInitiativeDashoardActiveItems, (state) => {
      const { items } = state.itemsList;
      const {
        allActiveItems,
        inProgresActiveItems,
        completedActiveItems,
      } = getInitiativeActiveItems(items, []);

      const itemsToShow = [
        { count: allActiveItems.length, text: 'All Items' },
        { count: inProgresActiveItems.length, text: 'In Progress' },
        { count: completedActiveItems.length, text: 'Completed' },
      ];

      this.setState({
        loadingInitiativeData: false,
        allItems: allActiveItems.length,
        inProgresItems: inProgresActiveItems.length,
        completedItems: completedActiveItems.length,
        itemsToShow,
      });
    });

    this.subscribe(onDashboardItems, (state) => {
      const {
        amoItems: {
          itemsList: { items },
        },
      } = state;
      this.setState({ items: items.map((item) => getAMOItemType(item)) });
    });

    fetchDashboardData();
    fetchWorkQueueItems();
    fetchInitiativeSmallList('', 1, 1000);
  }

  fetchInitiativeActiveItems = () => {
    if (this.state.selectedInitiative) {
      fetchInitiativeActiveItemsToDashboard(this.state.selectedInitiative.id);
    } else this.setState({ loadingInitiativeData: false });
  };

  goToMyWorkQueue = () => {
    this.props.history.push('/active-items/my-work-queue');
  };

  goToAMOItems = () => {
    this.props.history.push('/management/amo-item');
  };

  goToDeals = (event) => {
    const stage = event.data.type;
    this.props.history.push(`/management/deal?q=${stage}`);
  };

  onClickDue = (due, item) => {
    this.props.history.push(`/management/amo-item?due=${due}&item=${item}`);
  };

  onClickChart = (event) => {
    let due = event.data.type;
    let item = event.data.itemType;
    this.props.history.push(`/management/amo-item?due=${due}&item=${item}`);
  };

  onSelectInitiative = (selectedInitiative) => {
    this.setState({ selectedInitiative, loadingInitiativeData: true }, () =>
      fetchInitiativeActiveItemsToDashboard(selectedInitiative.id),
    );
  };

  onClickInitiativeItems = (item, initiativeId) => {
    this.props.history.push(`/active-items/initiative/${initiativeId}`);
  };

  onSelectDay = (selectedDay) => {
    this.setState({ selectedDay });
  };

  onRightClick = () => {
    const dateReference = moment(this.state.dateReference).add(7, 'days');
    const week = getWeekForCalendar(dateReference);

    this.setState({
      week,
      dateReference,
    });
  };

  onLeftClick = () => {
    const dateReference = moment(this.state.dateReference).subtract(7, 'days');
    const week = getWeekForCalendar(dateReference);

    this.setState({
      week,
      dateReference,
    });
  };

  onClickComplete = (completeFunction, selectedItemToComplete) => {
    this.setState({
      completeModalIsOpen: true,
      completeFunction,
      selectedItemToComplete,
    });
  };

  onYesComplete = () => {
    const { completeFunction, selectedItemToComplete } = this.state;
    this.setState(
      {
        completeModalIsOpen: false,
        loadingTasks: true,
      },
      () => {
        completeFunction(selectedItemToComplete);
      },
    );
  };

  onCloseComplete = () => {
    this.setState({
      completeModalIsOpen: false,
    });
  };

  render() {
    const {
      items,
      selectedInitiative,
      initiatives,
      loadingInitiativeData,
      itemsToShow,
      loadingTasks,
      tasksByDate,
      selectedDay,
      week,
      overDueItems,
      completeModalIsOpen,
    } = this.state;
    const { risksOptions, issuesOptions, actionsOptions } = getChartOptions(items);
    const onEvents = {
      click: this.onClickChart,
    };
    const selectedDayFormat = moment(selectedDay).format('dddd MMMM Do');
    // fromNowDate
    const momentSelectedDay = moment(selectedDay);
    const fromNowDate =
      moment().diff(momentSelectedDay, 'days') >= 1
        ? momentSelectedDay.fromNow()
        : momentSelectedDay.calendar().split(' ')[0];

    const noData = !tasksByDate[selectedDay] && overDueItems.length === 0 ? <NoData /> : null;

    return (
      <>
        <Grid.Layout gap={'lg'} columns={'auto'} areas={[['left', 'center', 'right']]}>
          <Grid.Box area="left">
            <PieChart
              title="Risks"
              itemName="RISK"
              paperPadding="md"
              onEvents={onEvents}
              data={risksOptions}
              onClickLegend={this.onClickDue}
            />
          </Grid.Box>
          <Grid.Box area="center">
            <PieChart
              title="Issues"
              itemName="ISSUE"
              paperPadding="md"
              onEvents={onEvents}
              data={issuesOptions}
              onClickLegend={this.onClickDue}
            />
          </Grid.Box>
          <Grid.Box area="right">
            <PieChart
              title="Actions"
              itemName="ACTION"
              paperPadding="md"
              onEvents={onEvents}
              data={actionsOptions}
              onClickLegend={this.onClickDue}
            />
          </Grid.Box>
        </Grid.Layout>

        <div style={{ padding: '24px 0' }}>
          <LeftCardContainer>
            <StyledCard>
              <Card.Header>
                <Row growChildren alignItems="center" style={{ width: '100%' }}>
                  <Column alignItems="start">
                    <Heading type="h4" text={`Tasks for ${fromNowDate}, ${selectedDayFormat}`} />
                  </Column>
                  <Column alignItems="end">
                    <Row alignItems="center">
                      <Text
                        onClick={this.goToMyWorkQueue}
                        style={{ color: '#3EB7F9', cursor: 'pointer' }}>
                        View all tasks
                      </Text>
                      <img
                        onClick={this.goToMyWorkQueue}
                        style={{ width: '16px', cursor: 'pointer' }}
                        src={arrowToRight}
                        alt=""
                      />
                    </Row>
                  </Column>
                </Row>
              </Card.Header>
              <Card.Body>
                {!loadingTasks ? (
                  <>
                    <Row alignItems="center" justifyContent="center">
                      <WeekComponent
                        week={week}
                        onSelectDay={this.onSelectDay}
                        selectedDay={selectedDay}
                        onLeftClick={this.onLeftClick}
                        onRightClick={this.onRightClick}
                      />
                    </Row>
                    <Row offsetTop={'sm'} style={{ width: '100%' }}>
                      <Grid.Layout style={{ width: '100%' }}>
                        {overDueItems
                          ? overDueItems.map((item, i) => (
                            <ActiveItem
                              item={item}
                              key={i}
                              onClickComplete={this.onClickComplete}
                              color="#FCBD00"
                            />
                          ))
                          : null}
                        {tasksByDate[selectedDay]
                          ? tasksByDate[selectedDay].map((task, i) => (
                            <ActiveItem item={task} key={i} color="#FCBD00" />
                          ))
                          : null}

                        {noData}
                      </Grid.Layout>
                    </Row>
                  </>
                ) : (
                  <Loader stretch />
                )}
              </Card.Body>
            </StyledCard>
          </LeftCardContainer>

          <RightCardContainer>
            <StyledCard>
              <Card.Header>
                <Row growChildren alignItems="center" style={{ width: '100%' }}>
                  <Column alignItems="start">
                    <Heading type="h4" text={'Initiative State'} />
                  </Column>
                  <Column alignItems="end">
                    <Select
                      onChange={(initiative) => this.onSelectInitiative(initiative)}
                      value={selectedInitiative}
                      options={initiatives.map((initiative) => {
                        return {
                          label: initiative.name,
                          value: initiative,
                        };
                      })}
                    />
                  </Column>
                </Row>
              </Card.Header>
              <Card.Body>
                {!loadingInitiativeData ? (
                  <>
                    {selectedInitiative ? (
                      <>
                        <Row growChildren>
                          <Column>
                            <Text weight="bold">{selectedInitiative.name}</Text>
                            <Status status={selectedInitiative.ragStatus} />
                            {/* <Progress
                              size="sm"
                              color={selectedInitiative.ragStatus}
                              value={progressValue}
                            /> */}
                            <UserSmallAvatar
                              owner={selectedInitiative.owner}
                              text="is the owner of this initiative"
                            />
                          </Column>
                        </Row>
                        <Row offsetTop="lg" gap="lg" growChildren>
                          {itemsToShow.map((item, i) => {
                            const { text, count } = item;

                            return (
                              <ItemsCard
                                key={i}
                                text={text}
                                width="50px"
                                count={count}
                                onClick={() =>
                                  this.onClickInitiativeItems(item, selectedInitiative.id)
                                }
                              />
                            );
                          })}
                        </Row>{' '}
                      </>
                    ) : (
                      <NoData />
                    )}
                  </>
                ) : (
                  <Loader stretch />
                )}
              </Card.Body>
            </StyledCard>
          </RightCardContainer>
        </div>
        <YesNoDialog
          isOpen={completeModalIsOpen}
          onYes={this.onYesComplete}
          onNo={this.onCloseComplete}
          onClose={this.onCloseComplete}
          text={'Are you sure you want to Mark this Item as Completed?'}
          title={'Mark Completed'}
        />
      </>
    );
  }
}

const GraphsDashboard = withRouter(PrivateGraphsDashboard);
export { GraphsDashboard };
