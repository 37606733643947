import React from 'react';
import { Card } from '@8base/boost';
import PropTypes from 'prop-types';

/**
 * Use this instead of Card.Body for List Views,
 * add any css Padding, margin on this component.
 *
 */

const ListCardBody = ({ children, ...rest }) => {
  return (
    <Card.Body borderRadius="all" className="card-body-principal" {...rest}>
      {children}
    </Card.Body>
  );
};

ListCardBody.propTypes = {
  children: PropTypes.any.isRequired,
};

export { ListCardBody };
