import Flux from '@cobuildlab/flux-state';

/**
 * Event that triggers a ITEM error.
 *
 * @type {string}
 */
export const RELATED_ITEM_ERROR_EVENT = 'onRelatedItemError';

/**
 * Event that triggers when some object request the items.
 *
 * @type {string}
 */
export const RELATED_ITEM_REQUEST_EVENT = 'onRelatedItemRequest';

/**
 * Event that triggers when some create a new related-item.
 *
 * @type {string}
 */
export const RELATED_ITEM_CREATE_EVENT = 'onRelatedItemCreate';

/**
 * Event that triggers when a item is removed.
 *
 * @type {string}
 */
export const RELATED_ITEM_REMOVE_EVENT = 'onRelatedItemRemove';

/**
 * Event that triggers a RelatedItems Events.
 *
 * @type {string}
 */
export const RELATED_ITEMS_EVENT = 'onRelatedItems';

/**
 * Search Items.
 *
 * @type {string}
 */
export const ITEMS_EVENT = 'onItems';

export const RELATED_ITEMS_BY_ITEM_EVENT = 'onRelatedItemsByItem';

/**
 * Hold the RelatedItemsData.
 *
 */
class RelatedItemStore extends Flux.DashStore {
  constructor() {
    super();
    this.addEvent(RELATED_ITEM_ERROR_EVENT);
    this.addEvent(RELATED_ITEMS_EVENT);
    this.addEvent(RELATED_ITEM_REQUEST_EVENT);
    this.addEvent(RELATED_ITEM_CREATE_EVENT);
    this.addEvent(ITEMS_EVENT);
    this.addEvent(RELATED_ITEM_REMOVE_EVENT);
    this.addEvent(RELATED_ITEMS_BY_ITEM_EVENT);
  }
}

export default new RelatedItemStore();
