import PropTypes from 'prop-types';

const DocumentTitle = ({ maxFiles, text, files }) => {
  let currentFiles;
  if (Array.isArray(files)) {
    currentFiles = files.length ? files.length : 0;
  } else {
    currentFiles = files ? 1 : 0;
  }

  return `${text} ( ${currentFiles} / ${maxFiles} )`;
};

DocumentTitle.propTypes = {
  maxFiles: PropTypes.number.isRequired,
  text: PropTypes.string.isRequired,
  files: PropTypes.any.isRequired,
};

export default DocumentTitle;
