import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Button } from '@8base/boost';

const ActionLinkButton = ({ url, textValue = '' }) => {
  return (
    <div className="action-link-button">
      <Link to={url}>
        <Button style={{ borderRadius: '50px' }}>{textValue}</Button>
      </Link>
    </div>
  );
};

ActionLinkButton.defaultProps = {
  textValue: '',
};

ActionLinkButton.propTypes = {
  url: PropTypes.string.isRequired,
  textValue: PropTypes.string,
};

export { ActionLinkButton };
