import Flux from '@cobuildlab/flux-state';
import { CHAT_TOGGLE_EVENT, CHAT_UNREADS_EVENT } from './chat-store';
import { NOTIFICATIONS_REQUEST_EVENT } from '../notifications/notifications-store';
import { OnChatUnReads, OnChatToggle } from './chat-events';

export const toggleChatSideBar = (newValue) => {
  const isSidebarOpen = OnChatToggle.get();
  OnChatToggle.dispatch(typeof newValue !== 'undefined' ? newValue : !isSidebarOpen);

  Flux.dispatchEvent(
    CHAT_TOGGLE_EVENT,
    typeof newValue !== 'undefined' ? newValue : !isSidebarOpen,
  );
  Flux.dispatchEvent(NOTIFICATIONS_REQUEST_EVENT, false);
};

export const onChannelsUnreadsChange = (channelMembers) => {
  setTimeout(() => {
    OnChatUnReads.dispatch(channelMembers.reduce((acc, item) => acc + item.hasUnreads, 0));

    Flux.dispatchEvent(
      CHAT_UNREADS_EVENT,
      channelMembers.reduce((acc, item) => acc + item.hasUnreads, 0),
    );
  }, 0);
};
