import * as jsforce from 'jsforce';
import Flux from '@cobuildlab/flux-state';
import {
  SALESFORCE_CONNECTED_ERROR_EVENT,
  SALESFORCE_CONNECTED_EVENT,
  SALESFORCE_CONNECTION_CREATED_ERROR_EVENT,
  SALESFORCE_CONNECTION_CREATED_EVENT,
  SALESFORCE_CONNECTION_FIELDS_UPDATE_ERROR_EVENT,
  SALESFORCE_CONNECTION_FIELDS_UPDATE_EVENT,
  SALESFORCE_CONNECTION_INVALID_EVENT,
  SALESFORCE_CONNECTION_UPDATE_ERROR_EVENT,
  SALESFORCE_CONNECTION_UPDATE_EVENT,
  SALESFORCE_DISCONNECTED_ERROR_EVENT,
  SALESFORCE_DISCONNECTED_EVENT,
  SALESFORCE_FIELD_MAPPING_CREATE_ERROR_EVENT,
  SALESFORCE_FIELD_MAPPING_CREATE_EVENT,
  SALESFORCE_FIELD_MAPPING_DELETE_ERROR_EVENT,
  SALESFORCE_FIELD_MAPPING_DELETE_EVENT,
  SALESFORCE_FIELD_MAPPING_UPDATE_ERROR_EVENT,
  SALESFORCE_FIELD_MAPPING_UPDATE_EVENT,
  SALESFORCE_FIELD_MAPPINGS_ERROR_EVENT,
  SALESFORCE_FIELD_MAPPINGS_EVENT,
  SALESFORCE_LOGS_ERROR_EVENT,
  SALESFORCE_LOGS_EVENT,
  SALESFORCE_MISSING_FIELDS_EVENT,
  SALESFORCE_OBJECT_FIELDS_ERROR_EVENT,
  SALESFORCE_OBJECT_FIELDS_EVENT,
  SALESFORCE_UPDATE_NOW_ERROR_EVENT,
  SALESFORCE_UPDATE_NOW_EVENT,
  salesforceStore,
  SALESFORCE_CONNECTION_LOADING_EVENT,
  SALESFORCE_CONNECTION_EXPIRED,
} from './salesforce-store';
import { fetchCurrentAllianceMembersAction } from '../settings/alliance-management/alliance-actions';
import { DEAL_ERROR_EVENT, DEAL_IMPORTED_EVENT } from '../management/deal/deal-store';
import sessionStore, { APOLLO_CLIENT, NEW_SESSION_EVENT } from '../../shared/SessionStore';
import {
  SALESFORCE_CONNECTION_LIST_QUERY,
  SALESFORCE_CONNECTION_REFRESH_QUERY,
  SALESFORCE_CONNECTION_UPDATE_MUTATION,
  SALESFORCE_FIELD_MAPPING_CREATE_MUTATION,
  SALESFORCE_FIELD_MAPPING_DELETE_MUTATION,
  SALESFORCE_FIELD_MAPPING_LIST_QUERY,
  SALESFORCE_FIELD_MAPPING_UPDATE_MUTATION,
  SALESFORCE_LOG_LIST_QUERY,
  SALESFORCE_OAUTH2_AUTHORIZATION_RESOLVER,
  SALESFORCE_UPDATE_NOW_MUTATION,
} from './salesforce-queries';
import { isValidString } from '../../shared/validators';
import { getSelectedCompany } from '../auth/auth.actions';
import { isSalesforceFieldMappingValid } from './salesforce-validations';
import * as toast from '../../components/toast/Toast';
import {
  SALESFORCE_CONNECTION_STATUS_ACTIVE,
  SALESFORCE_CONNECTION_STATUS_INACTIVE,
  SALESFORCE_CUSTOM_FIELD_LABEL,
  SALESFORCE_OPPORTUNITY_REMOVE_FIELDS,
} from './salesforce-models';
import {
  OnSalesforceConnected,
  OnSalesforceConnectedError,
  OnSalesforceConnectionInvalid,
  OnSalesforceConnectionExpired,
  OnSalesforceDisconnected,
  OnSalesforceConnectionUpdate,
  OnSalesforceConnectionUpdateError,
  OnSalesforceUpdateNow,
  OnSalesforceUpdateNowError,
  OnSalesforceLogs,
  OnSalesforceLogsError,
  OnSalesforceFieldMappings,
  OnSalesforceFieldMappingsError,
  OnSalesforceObjectFields,
  OnSalesforceObjectFieldsError,
  OnSalesforceFieldMappingDelete,
  OnSalesforceFieldMappingDeleteError,
  OnSalesforceFieldMappingUpdate,
  OnSalesforceFieldMappingUpdateError,
  OnSalesforceFieldMappingCreate,
  OnSalesforceFieldMappingCreateError,
  OnSalesforceMissingsFields,
  OnSalesforceConnectionCreatedError,
  OnSalesforceConnectionCreated,
  OnSalesforceDisconnectedError,
  OnSalesforceConnectionLoading,
  OnSalesforceConnectionFieldUpdate,
  OnSalesforceConnectionFieldUpdateError,
} from './salesforce-events';

const { REACT_APP_SALESFORCE_CLIENT_ID, REACT_APP_SALESFORCE_REDIRECT_URI } = process.env;

export const initializeSalesforce = () => {
  loginListener();
  fetchSalesforceConnection();
};

const loginListener = () => {
  window.salesforceConnected = (code) => {
    authorizeConnection(code);
  };
};

export const salesforceLogin = () => {
  const oauth2 = new jsforce.OAuth2({
    clientId: REACT_APP_SALESFORCE_CLIENT_ID,
    redirectUri: REACT_APP_SALESFORCE_REDIRECT_URI,
  });
  const url = oauth2.getAuthorizationUrl({ scope: 'api id web refresh_token' });
  popupWin(url);
};

const popupWin = (url) => {
  const windowWidth = 912;
  const windowHeight = 513;
  const left = window.screen.width / 2 - windowWidth / 2;
  const top = window.screen.height / 2 - windowHeight / 2;
  return window.open(
    url,
    '_new',
    'location=yes,toolbar=no,status=no,menubar=no,width=' +
      windowWidth +
      ',height=' +
      windowHeight +
      ',top=' +
      top +
      ',left=' +
      left,
  );
};

/**
 * Fetch Opportunities from Salesforce.
 *
 * @param {{limit?: number, filter?:boolean, connection?:object}} options - Query Options.
 * @returns {Promise<object[]>} - Array of opportunities.
 */
export const fetchOpportunities = (options) =>
  fetchSalesforceObject({
    ...options,
    subject: 'Opportunity',
    query: '*, Account.Name, Account.Type, Account.Phone, Owner.Name',
  });

/**
 * Fetch Leads from Salesforce.
 *
 * @param {{limit?: number, filter?:boolean, connection?:object}} options - Query Options.
 * @returns {Promise<object[]>} - Array of leads.
 */
export const fetchLeads = (options) =>
  fetchSalesforceObject({
    ...options,
    subject: 'Lead',
    query: '*, Owner.Username, Owner.Email, Owner.Name',
  });

export const fetchSalesforceObject = (data) => {
  const salesforceConnectedEvent = salesforceStore.getState(SALESFORCE_CONNECTED_EVENT);

  const {
    subject,
    query,
    limit = 10000,
    connection = salesforceConnectedEvent.connection,
    filter = true,
  } = data;

  return new Promise((resolve, reject) => {
    const queryObject = connection
      .sobject(subject)
      .select(query)
      .limit(limit);

    let customFieldName = formatCustomFieldName(SALESFORCE_CUSTOM_FIELD_LABEL);

    if (salesforceConnectedEvent) {
      const { customField } = salesforceConnectedEvent.salesforceConnection;
      customFieldName = formatCustomFieldName(customField);
    }

    if (filter && salesforceConnectedEvent) {
      const { filterName } = salesforceConnectedEvent.salesforceConnection;
      queryObject.where({ [customFieldName]: { $includes: [filterName] } });
    }

    queryObject.execute(function(err, records) {
      if (err) {
        if (err.name === 'INVALID_FIELD') {
          toast.warn(
            `Please add the \`${customFieldName}\` field to the \`${subject}\` object in salesforce (no records retried)`,
          );
          resolve([]);
        } else if (err.name === 'INVALID_QUERY_FILTER_OPERATOR') {
          reject(
            new Error(
              `The type of the \`${customFieldName}\` field is not Multi-Picklist, please check your salesforce configuration`,
            ),
          );
        } else {
          reject(err);
        }
        return;
      }
      console.log('fetchSalesforceObject: ', subject, records);
      resolve(records);
    });
  });
};

/**
 * Format the salesforce custom field name.
 *
 * @param {string} customField - Custom Field Name.
 * @returns {string} Field Name.
 */
const formatCustomFieldName = (customField) => {
  const postfix = '__c';

  if (customField.endsWith(postfix)) {
    return customField;
  } else {
    return customField + postfix;
  }
};

const flatObject = (object) => {
  const flatten = [];
  Object.entries(object).forEach((field) => {
    const [key, value] = field;
    if (key === 'attributes') return;
    if (typeof value === 'object' && value) {
      const subFlattenObject = flatObject(value);
      const renameFieldsFlattenObject = Object.entries(subFlattenObject).map((subField) => {
        subField[0] = key + '_' + subField[0];
        return subField;
      });
      flatten.push(...renameFieldsFlattenObject);
    } else {
      flatten.push(field);
    }
  });

  return Object.fromEntries(flatten);
};

const formatField = (object) => {
  Object.entries(object).forEach(([key, value]) => {
    if (typeof value === 'boolean') object[key] = value ? 'Y' : 'N';
  });
  return object;
};

export const importSalesforceData = async (fetchHandler) => {
  try {
    const { clientCompany, partnerCompany } = await fetchCurrentAllianceMembersAction();
    const items = await fetchHandler();

    const opportunities = items.map(flatObject).map(formatField);

    if (!opportunities.length) {
      Flux.dispatchEvent(DEAL_ERROR_EVENT, { message: 'There are no opportunities to import' });
      return;
    }

    const headers = Object.keys(opportunities[0]);

    const data = opportunities.map((item) =>
      Object.fromEntries(headers.map((header) => [header, item[header]])),
    );

    Flux.dispatchEvent(DEAL_IMPORTED_EVENT, {
      data,
      headers,
      dealsCompany: clientCompany,
      otherCompany: partnerCompany,
    });
  } catch (error) {
    console.log('importSalesforceData:error', error);
    Flux.dispatchEvent(DEAL_ERROR_EVENT, { message: error.message });
  }
};

export const authorizeConnection = async (code) => {
  OnSalesforceConnectionLoading.dispatch(true);
  Flux.dispatchEvent(SALESFORCE_CONNECTION_LOADING_EVENT, true);
  const client = sessionStore.getState(APOLLO_CLIENT);
  const { selectedAlliance } = sessionStore.getState(NEW_SESSION_EVENT);
  const selectedCompany = getSelectedCompany();

  const [isValid, message] = validateSalesforceConnection(code, selectedAlliance, selectedCompany);

  if (!isValid) {
    OnSalesforceConnectionCreatedError.dispatch(message);
    Flux.dispatchEvent(SALESFORCE_CONNECTION_CREATED_ERROR_EVENT, message);
    return;
  }

  const data = { code, allianceId: selectedAlliance.id, companyId: selectedCompany.id };

  try {
    const {
      data: { salesforceOauth2Callback },
    } = await client.query({
      query: SALESFORCE_OAUTH2_AUTHORIZATION_RESOLVER,
      variables: { data },
      fetchPolicy: 'network-only',
    });
    console.log('salesforceOauth2Callback', { salesforceOauth2Callback });
    const { success, message } = salesforceOauth2Callback;

    if (!success) {
      OnSalesforceConnectionLoading.dispatch(false);
      Flux.dispatchEvent(SALESFORCE_CONNECTION_LOADING_EVENT, false);
      console.error('ERROR TO VALIDATE SALESFORCE:', message);

      OnSalesforceConnectionCreatedError.dispatch(
        message || 'There was an validation error with salesforce',
      );

      Flux.dispatchEvent(
        SALESFORCE_CONNECTION_CREATED_ERROR_EVENT,
        message || 'There was an validation error with salesforce',
      );
      return;
    }
  } catch (error) {
    console.error(error);
    OnSalesforceConnectionCreatedError.dispatch(
      error.message || 'There was an validation error with salesforce',
    );

    Flux.dispatchEvent(
      SALESFORCE_CONNECTION_CREATED_ERROR_EVENT,
      error.message || 'There was an validation error with salesforce',
    );
    return;
  }

  OnSalesforceConnectionCreated.dispatch('Connection with salesforce established');

  Flux.dispatchEvent(SALESFORCE_CONNECTION_CREATED_EVENT, 'Connection with salesforce established');
};

export const validateSalesforceConnection = (code, alliance, company) => {
  if (!isValidString(code)) {
    return [false, 'Invalid salesforce authorization code'];
  }

  if (!(alliance && alliance.id)) {
    return [false, 'You must have an alliance'];
  }

  if (!(company && company.id)) {
    return [false, 'You must have an company'];
  }

  return [true];
};

export const fetchSalesforceConnection = async () => {
  const storedConnection = salesforceStore.getState(SALESFORCE_CONNECTED_EVENT);

  // Dispatch previously used connection
  if (storedConnection) {
    setTimeout(() => {
      OnSalesforceConnected.dispatch(storedConnection);
      Flux.dispatchEvent(SALESFORCE_CONNECTED_EVENT, storedConnection);
    }, 1000);
    console.log('storedConnection', storedConnection);
    return storedConnection;
  }

  const client = sessionStore.getState(APOLLO_CLIENT);
  const { selectedAlliance } = sessionStore.getState(NEW_SESSION_EVENT);
  const selectedCompany = getSelectedCompany();

  const filter = {
    status: { equals: SALESFORCE_CONNECTION_STATUS_ACTIVE },
    alliance: { id: { equals: selectedAlliance.id } },
    company: { id: { equals: selectedCompany.id } },
  };

  try {
    const {
      data: { salesforceConnectionsList },
    } = await client.query({
      query: SALESFORCE_CONNECTION_LIST_QUERY,
      variables: { filter },
      fetchPolicy: 'network-only',
    });
    const [salesforceConnection] = salesforceConnectionsList.items;
    if (salesforceConnection) {
      const { accessToken, instanceUrl } = salesforceConnection;

      let connection = new jsforce.Connection({
        accessToken,
        instanceUrl,
      });

      // Verifying connection
      try {
        await testConnection(connection);
      } catch (error) {
        // Connection is invalid, let's refresh the access token
        console.log('identityConnection:error', error);

        const accessDenied = error.message === 'Access Declined';

        try {
          const { accessToken: refreshedAccessToken } = await handleTokenRefresh(
            salesforceConnection,
          );

          connection = new jsforce.Connection({
            accessToken: refreshedAccessToken,
            instanceUrl,
          });
          await testConnection(connection);
        } catch (err) {
          if (accessDenied) {
            OnSalesforceConnectionCreatedError.dispatch(
              `${error.message}. test connection failed, try reload or disconnect and reconnect  `,
            );

            Flux.dispatchEvent(
              SALESFORCE_CONNECTION_CREATED_ERROR_EVENT,
              `${error.message}. test connection failed, try reload or disconnect and reconnect  `,
            );
          }

          OnSalesforceConnectionExpired.dispatch({
            salesforceConnection,
            message: err.message,
          });

          Flux.dispatchEvent(SALESFORCE_CONNECTION_EXPIRED, {
            salesforceConnection,
            message: err.message,
          });
          console.log('SALESFORCE_CONNECTION_INVALID_EVENT', err, { salesforceConnection });
          return;
        }
      }

      OnSalesforceConnected.dispatch({ connection, salesforceConnection });
      Flux.dispatchEvent(SALESFORCE_CONNECTED_EVENT, { connection, salesforceConnection });
      console.log('SALESFORCE_CONNECTED_EVENT', { connection, salesforceConnection });
    } else {
      OnSalesforceConnectedError.dispatch({ message: 'No salesforce connection' });
      Flux.dispatchEvent(SALESFORCE_CONNECTED_ERROR_EVENT, { message: 'No salesforce connection' });
    }
  } catch (error) {
    console.error('fetchSalesforceConnection:error', error);
    OnSalesforceConnectedError.dispatch(error);
    Flux.dispatchEvent(SALESFORCE_CONNECTED_ERROR_EVENT, error);
  }
};

/**
 * Fetch Connection's identity.
 *
 * @param {object} connection - Salesforce Connection.
 * @returns {Promise<object>} - User Info.
 */
const testConnection = (connection) => {
  return fetchSalesforceObject({
    subject: 'Opportunity',
    query: 'Id',
    limit: 1,
    connection,
  });
};

export const handleTokenRefresh = async (salesforceConnection) => {
  const client = sessionStore.getState(APOLLO_CLIENT);

  const { id } = salesforceConnection;

  const {
    data: { salesforceRefreshToken },
  } = await client.query({
    query: SALESFORCE_CONNECTION_REFRESH_QUERY,
    variables: { id },
    fetchPolicy: 'network-only',
  });

  return salesforceRefreshToken;
};

export const disconnectSalesforce = async () => {
  try {
    await updateSalesforceConnection({ status: SALESFORCE_CONNECTION_STATUS_INACTIVE }, false);

    OnSalesforceDisconnected.dispatch();
    Flux.dispatchEvent(SALESFORCE_DISCONNECTED_EVENT);
    OnSalesforceConnected.dispatch(null);
    Flux.dispatchEvent(SALESFORCE_CONNECTED_EVENT, null);
  } catch (error) {
    console.error('fetchSalesforceConnection:error', error);
    OnSalesforceDisconnectedError.dispatch(error);
    Flux.dispatchEvent(SALESFORCE_DISCONNECTED_ERROR_EVENT, error);
  }
};

export const fetchIdentify = (connection) => {
  return new Promise((resolve, reject) => {
    connection.identity((err, res) => {
      if (err) reject(err);
      else resolve(res);
    });
  });
};

export const fetchSalesforceFieldMappings = async () => {
  const client = sessionStore.getState(APOLLO_CLIENT);
  const { selectedAlliance } = sessionStore.getState(NEW_SESSION_EVENT);
  const selectedCompany = getSelectedCompany();

  const filter = {
    alliance: { id: { equals: selectedAlliance.id } },
    company: { id: { equals: selectedCompany.id } },
  };

  try {
    const {
      data: { salesforceFieldMappingsList },
    } = await client.query({
      query: SALESFORCE_FIELD_MAPPING_LIST_QUERY,
      variables: { filter },
      fetchPolicy: 'network-only',
    });
    console.log(
      'fetchSalesforceFieldMappings:salesforceFieldMappingsList',
      salesforceFieldMappingsList,
    );

    OnSalesforceFieldMappings.dispatch(salesforceFieldMappingsList.items);
    Flux.dispatchEvent(SALESFORCE_FIELD_MAPPINGS_EVENT, salesforceFieldMappingsList.items);
    return salesforceFieldMappingsList.items;
  } catch (error) {
    console.error('fetchSalesforceFieldMappings:error', error);
    OnSalesforceFieldMappingsError.dispatch(error);
    Flux.dispatchEvent(SALESFORCE_FIELD_MAPPINGS_ERROR_EVENT, error);
  }
};

export const fetchSalesforceObjectField = async () => {
  try {
    const { salesforceConnection } = salesforceStore.getState(SALESFORCE_CONNECTED_EVENT);
    const { customField } = salesforceConnection;
    const customFieldName = formatCustomFieldName(customField);

    let opportunities = await fetchOpportunities({ limit: 1 });

    if (!opportunities.length) {
      opportunities = await fetchOpportunities({ limit: 1, filter: false });
    }

    const opportunityObject = opportunities[0] || {};

    if (opportunities.length && opportunityObject[customFieldName] === undefined) {
      setTimeout(() => {
        OnSalesforceMissingsFields.dispatch(
          `Please add the \`${customField}\` field to the \`Opportunity\` object in salesforce`,
        );

        Flux.dispatchEvent(
          SALESFORCE_MISSING_FIELDS_EVENT,
          `Please add the \`${customField}\` field to the \`Opportunity\` object in salesforce`,
        );
      }, 2000);
    }

    const opportunityKeys = Object.keys(flatObject(opportunityObject)).filter(
      (field) => !SALESFORCE_OPPORTUNITY_REMOVE_FIELDS.includes(field),
    );

    const objectFields = { opportunity: opportunityKeys };

    OnSalesforceObjectFields.dispatch(objectFields);
    Flux.dispatchEvent(SALESFORCE_OBJECT_FIELDS_EVENT, objectFields);
    return objectFields;
  } catch (error) {
    console.log(SALESFORCE_OBJECT_FIELDS_ERROR_EVENT, error);
    OnSalesforceObjectFieldsError.dispatch(error);
    Flux.dispatchEvent(SALESFORCE_OBJECT_FIELDS_ERROR_EVENT, error);
  }
};

export const deleteFieldMapping = async (fieldMapping) => {
  const client = sessionStore.getState(APOLLO_CLIENT);
  const { id } = fieldMapping;

  const data = { id, force: true };

  try {
    const {
      data: { salesforceFieldMappingDelete },
    } = await client.mutate({
      mutation: SALESFORCE_FIELD_MAPPING_DELETE_MUTATION,
      variables: { data },
    });
    console.log('deleteFieldMapping:salesforceFieldMappingDelete', salesforceFieldMappingDelete);

    // Update Stored Field Mappings, removed the one deleted
    const fieldMappings = salesforceStore.getState(SALESFORCE_FIELD_MAPPINGS_EVENT);
    const updatedFieldMappings = fieldMappings.filter(
      (storedFieldMapping) => storedFieldMapping.id !== fieldMapping.id,
    );

    OnSalesforceFieldMappingDelete.dispatch(salesforceFieldMappingDelete);
    Flux.dispatchEvent(SALESFORCE_FIELD_MAPPING_DELETE_EVENT, salesforceFieldMappingDelete);
    OnSalesforceFieldMappings.dispatch(updatedFieldMappings);
    Flux.dispatchEvent(SALESFORCE_FIELD_MAPPINGS_EVENT, updatedFieldMappings);
    return salesforceFieldMappingDelete;
  } catch (error) {
    console.error('deleteFieldMapping:error', error);
    OnSalesforceFieldMappingDeleteError.dispatch(error);
    Flux.dispatchEvent(SALESFORCE_FIELD_MAPPING_DELETE_ERROR_EVENT, error);
  }
};

export const updateFieldMappings = async (fieldMappings) => {
  const client = sessionStore.getState(APOLLO_CLIENT);
  const storedFieldMappings = salesforceStore.getState(SALESFORCE_FIELD_MAPPINGS_EVENT);

  // Validate
  const isDuplicated = fieldMappings.some((updatedFieldMapping) => {
    const storedModifiedFieldMapping = storedFieldMappings.find(
      (storedFieldMapping) => storedFieldMapping.id === updatedFieldMapping.id,
    );
    const completeUpdatedFieldMapping = { ...storedModifiedFieldMapping, ...updatedFieldMapping };
    return storedFieldMappings
      .filter((storedFieldMapping) => storedFieldMapping.id !== updatedFieldMapping.id)
      .some((storedFieldMapping) =>
        isEqualSalesforceFieldMapping(storedFieldMapping, completeUpdatedFieldMapping),
      );
  });
  if (isDuplicated) {
    OnSalesforceFieldMappingUpdateError.dispatch(
      new Error('2 Salesforce field mapping cannot have the same deal field'),
    );

    Flux.dispatchEvent(
      SALESFORCE_FIELD_MAPPING_UPDATE_ERROR_EVENT,
      new Error('2 Salesforce field mapping cannot have the same deal field'),
    );
    return;
  }

  // Update All Fields
  const updatePromises = fieldMappings.map(async (fieldMapping) => {
    const {
      data: { salesforceFieldMappingUpdate },
    } = await client.mutate({
      mutation: SALESFORCE_FIELD_MAPPING_UPDATE_MUTATION,
      variables: { data: fieldMapping },
    });
    console.log('updateFieldMappings:salesforceFieldMappingUpdate', salesforceFieldMappingUpdate);

    return salesforceFieldMappingUpdate;
  });

  try {
    const updatedFieldMappings = await Promise.all(updatePromises);
    OnSalesforceFieldMappingUpdate.dispatch(updatedFieldMappings);
    Flux.dispatchEvent(SALESFORCE_FIELD_MAPPING_UPDATE_EVENT, updatedFieldMappings);
  } catch (error) {
    console.error('deleteFieldMapping:error', error);
    OnSalesforceFieldMappingUpdateError.dispatch(error);
    Flux.dispatchEvent(SALESFORCE_FIELD_MAPPING_UPDATE_ERROR_EVENT, error);
  }
};

export const createFieldMapping = async (fieldMapping) => {
  const client = sessionStore.getState(APOLLO_CLIENT);
  const { selectedAlliance } = sessionStore.getState(NEW_SESSION_EVENT);
  const selectedCompany = getSelectedCompany();

  const fieldMappings = salesforceStore.getState(SALESFORCE_FIELD_MAPPINGS_EVENT);
  // Validate

  const [isValid, message] = isSalesforceFieldMappingValid(fieldMapping);
  if (!isValid) {
    OnSalesforceFieldMappingCreateError.dispatch(new Error(message));
    Flux.dispatchEvent(SALESFORCE_FIELD_MAPPING_CREATE_ERROR_EVENT, new Error(message));
    return;
  }

  const isDuplicated = fieldMappings.some((storedFieldMapping) =>
    isEqualSalesforceFieldMapping(fieldMapping, storedFieldMapping),
  );
  if (isDuplicated) {
    OnSalesforceFieldMappingCreateError.dispatch(new Error('This deal field already exists'));

    Flux.dispatchEvent(
      SALESFORCE_FIELD_MAPPING_CREATE_ERROR_EVENT,
      new Error('This deal field already exists'),
    );
    return;
  }

  // Create

  const data = {
    company: { connect: { id: selectedCompany.id } },
    alliance: { connect: { id: selectedAlliance.id } },
    ...fieldMapping,
  };

  try {
    const {
      data: { salesforceFieldMappingCreate },
    } = await client.mutate({
      mutation: SALESFORCE_FIELD_MAPPING_CREATE_MUTATION,
      variables: { data },
    });
    console.log('deleteFieldMapping:salesforceFieldMappingDelete', salesforceFieldMappingCreate);

    // Update Stored Field Mappings, add the one created.
    fieldMappings.push(salesforceFieldMappingCreate);
    OnSalesforceFieldMappings.dispatch(fieldMappings);
    Flux.dispatchEvent(SALESFORCE_FIELD_MAPPINGS_EVENT, fieldMappings);

    OnSalesforceFieldMappingCreate.dispatch(salesforceFieldMappingCreate);
    Flux.dispatchEvent(SALESFORCE_FIELD_MAPPING_CREATE_EVENT, salesforceFieldMappingCreate);
    return salesforceFieldMappingCreate;
  } catch (error) {
    console.error('deleteFieldMapping:error', error);
    OnSalesforceFieldMappingCreateError.dispatch(error);
    Flux.dispatchEvent(SALESFORCE_FIELD_MAPPING_CREATE_ERROR_EVENT, error);
  }
};

export const isEqualSalesforceFieldMapping = (fieldMapping1, fieldMapping2) => {
  return (
    fieldMapping1.object === fieldMapping2.object &&
    fieldMapping1.dealField === fieldMapping2.dealField
  );
};

export const _updateSalesforceConnection = async (data) => {
  const client = sessionStore.getState(APOLLO_CLIENT);

  const {
    data: { salesforceConnectionUpdate },
  } = await client.mutate({
    mutation: SALESFORCE_CONNECTION_UPDATE_MUTATION,
    variables: { data },
  });

  return salesforceConnectionUpdate;
};

export const updateSalesforceUpdateInterval = async (updateInterval) => {
  try {
    const updatedSalesforceConnection = await updateSalesforceConnection({ updateInterval });
    OnSalesforceConnectionUpdate.dispatch(updatedSalesforceConnection);
    Flux.dispatchEvent(SALESFORCE_CONNECTION_UPDATE_EVENT, updatedSalesforceConnection);
  } catch (error) {
    console.log('updateSalesforceUpdateInterval:error', error);
    OnSalesforceConnectionUpdateError.dispatch(error);
    Flux.dispatchEvent(SALESFORCE_CONNECTION_UPDATE_ERROR_EVENT, error);
  }
};

export const updateSalesforceUpdateFields = async (customField, filterName) => {
  try {
    const updatedSalesforceConnection = await updateSalesforceConnection({
      customField,
      filterName,
    });
    OnSalesforceConnectionFieldUpdate.dispatch(updatedSalesforceConnection);
    Flux.dispatchEvent(SALESFORCE_CONNECTION_FIELDS_UPDATE_EVENT, updatedSalesforceConnection);
  } catch (error) {
    console.log('updateSalesforceUpdateFields:error', error);
    OnSalesforceConnectionFieldUpdateError.dispatch(error);
    Flux.dispatchEvent(SALESFORCE_CONNECTION_FIELDS_UPDATE_ERROR_EVENT, error);
  }
};

export const updateSalesforceConnection = async (updatedData, updateStore = true) => {
  // The salesforce stored connection data can come from either of this sources
  const salesforceConnectionEventState = salesforceStore.getState(SALESFORCE_CONNECTED_EVENT);
  const salesforceConnectionInvalidEventState = salesforceStore.getState(
    SALESFORCE_CONNECTION_INVALID_EVENT,
  );

  if (!salesforceConnectionEventState && !salesforceConnectionInvalidEventState) {
    throw new Error('salesforce store: No stored connection has been found');
  }

  const { salesforceConnection, ...salesforceConnectionEvent } =
    salesforceConnectionEventState || salesforceConnectionInvalidEventState;

  const storeSource = salesforceConnectionEventState
    ? SALESFORCE_CONNECTED_EVENT
    : SALESFORCE_CONNECTION_INVALID_EVENT;

  const data = {
    id: salesforceConnection.id,
    ...updatedData,
  };

  try {
    const updatedSalesforceConnection = await _updateSalesforceConnection(data);
    console.log(
      'updateSalesforceConnection:updatedSalesforceConnection',
      updatedSalesforceConnection,
    );

    if (updateStore) {
      const updatedSalesforceConnectionEvent = {
        ...salesforceConnectionEvent,
        salesforceConnection: updatedSalesforceConnection,
      };

      if (storeSource === SALESFORCE_CONNECTION_INVALID_EVENT) {
        OnSalesforceConnectionInvalid.dispatch(updatedSalesforceConnectionEvent);
      }

      if (storeSource === SALESFORCE_CONNECTED_EVENT) {
        OnSalesforceConnected.dispatch(updatedSalesforceConnectionEvent);
      }

      Flux.dispatchEvent(storeSource, updatedSalesforceConnectionEvent);
    }

    return updatedSalesforceConnection;
  } catch (error) {
    console.error('updateSalesforceConnection:error', error);
    throw error;
  }
};

export const fetchSalesforceLogs = async () => {
  const client = sessionStore.getState(APOLLO_CLIENT);
  const {
    salesforceConnection: { id: salesforceConnectionId },
  } = salesforceStore.getState(SALESFORCE_CONNECTED_EVENT);

  try {
    const {
      data: { salesforceConnection },
    } = await client.query({
      query: SALESFORCE_LOG_LIST_QUERY,
      variables: { id: salesforceConnectionId },
      fetchPolicy: 'network-only',
    });
    console.log('fetchSalesforceLogs:salesforceConnection', salesforceConnection);

    const { lastUpdate, salesforceLogs } = salesforceConnection;

    updateSalesforceConnectionLastUpdate(lastUpdate);

    OnSalesforceLogs.dispatch(salesforceLogs.items);
    Flux.dispatchEvent(SALESFORCE_LOGS_EVENT, salesforceLogs.items);
    return salesforceLogs.items;
  } catch (error) {
    console.log('fetchSalesforceLogs:error', error);
    OnSalesforceLogsError.dispatch(error);
    Flux.dispatchEvent(SALESFORCE_LOGS_ERROR_EVENT, error);
  }
};

export const salesforceManualUpdate = async () => {
  const client = sessionStore.getState(APOLLO_CLIENT);
  const { salesforceConnection } = salesforceStore.getState(SALESFORCE_CONNECTED_EVENT);

  let response;
  try {
    response = await client.mutate({
      mutation: SALESFORCE_UPDATE_NOW_MUTATION,
      variables: { id: salesforceConnection.id },
    });
  } catch (error) {
    console.log('salesforceManualUpdate:error', error);
    OnSalesforceUpdateNowError.dispatch(error);
    Flux.dispatchEvent(SALESFORCE_UPDATE_NOW_ERROR_EVENT, error);
    return;
  }

  const { salesforceUpdateNow } = response.data;

  console.log('salesforceManualUpdate:salesforceUpdateNow', salesforceUpdateNow);

  OnSalesforceUpdateNow.dispatch(salesforceUpdateNow);
  Flux.dispatchEvent(SALESFORCE_UPDATE_NOW_EVENT, salesforceUpdateNow);
  return salesforceUpdateNow;
};

const updateSalesforceConnectionLastUpdate = (lastUpdate) => {
  const salesforceConnectionEvent = salesforceStore.getState(SALESFORCE_CONNECTED_EVENT);

  if (salesforceConnectionEvent.salesforceConnection.lastUpdate !== lastUpdate) {
    console.log('Update Last Update:', lastUpdate);

    const updatedSalesforceConnection = {
      ...salesforceConnectionEvent,
      salesforceConnection: {
        ...salesforceConnectionEvent.salesforceConnection,
        lastUpdate,
      },
    };

    OnSalesforceConnected.dispatch(updatedSalesforceConnection);
    Flux.dispatchEvent(SALESFORCE_CONNECTED_EVENT, updatedSalesforceConnection);
  }
};
