import React from 'react';
import PropTypes from 'prop-types';
import { DialogForm } from '../../../components/forms/DialogForm';
import { PaymentForm } from './PaymentForm';

const PaymentDialogForm = ({
  onCloseDialog,
  isOpen,
  onSubmit,
  onChange,
  data,
  countries,
  loading,
}) => {
  return (
    <DialogForm
      title="Update Payment"
      isOpen={isOpen}
      createText="Submit Changes"
      onCloseDialog={onCloseDialog}
      onClickCreate={onSubmit}
      loading={loading}>
      <PaymentForm data={data} countries={countries} onChange={onChange} />
    </DialogForm>
  );
};

PaymentDialogForm.propTypes = {
  onChange: PropTypes.func.isRequired,
  onCloseDialog: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  countries: PropTypes.array.isRequired,
  data: PropTypes.object.isRequired,
};

export { PaymentDialogForm };
