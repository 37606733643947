import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';

const StyledH4 = styled('h4')`
  font-family: Poppins;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 0.5px;
  line-height: 20px;
  color: #323c47;
  & first-letter {
    text-transform: capitalize;
  }
`;

const H4 = ({ children }) => {
  return <StyledH4>{children}</StyledH4>;
};

H4.propTypes = {
  children: PropTypes.any.isRequired,
};

export { H4 };
