import Flux from '@cobuildlab/flux-state';

/**
 * Event that saves filters for Active Sales Pipeline.
 */
export const ACTIVE_SALES_PIPELINE_FILTERS_EVENT = 'activeSalesPipelineFilters';

const INITIAL_STATE = {
  filters: [],
  sorts: [],
  groups: [{ value: 'ASC', key: 'stage' }],
  isTotals: false,
};

/**
 * Hold the Active Sales Pipeline data.
 */
class ActiveSalesPipelineStore extends Flux.DashStore {
  constructor() {
    super();
    this.addEvent(ACTIVE_SALES_PIPELINE_FILTERS_EVENT);
    Flux.dispatchEvent(ACTIVE_SALES_PIPELINE_FILTERS_EVENT, INITIAL_STATE);
  }
}

const activeSalesPipelineStore = new ActiveSalesPipelineStore();
export default activeSalesPipelineStore;
