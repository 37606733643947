import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Card, Column, Grid, Heading, Loader, Paragraph, Row, Text } from '@8base/boost';
import { ActionButton } from '../../../components/buttons/ActionButton';
import styled from '@emotion/styled';
import salesforceLogo from '../../../images/logos/salesforce.png';
import {
  disconnectSalesforce,
  fetchSalesforceConnection,
  salesforceLogin,
} from '../../salesforce/salesforce-actions';
import { useSubscription } from '@cobuildlab/react-simple-state';
import {
  SALESFORCE_CONNECTED_EVENT,
  SALESFORCE_CONNECTION_INVALID_EVENT,
  salesforceStore,
} from '../../salesforce/salesforce-store';
import * as toast from '../../../components/toast/Toast';
import { DangerButton } from '../../../components/buttons/DangerButton';
import YesNoDialog from '../../../components/dialogs/YesNoDialog';
import QuestionMarkIcon from '../../../components/QuestionMarkIcon';
import { SalesforceInstructionsDialog } from './SalesforceInstructionsDialog';
import { SalesforceFieldsForm } from './SalesforceFieldsForm';
import {
  OnSalesforceConnected,
  OnSalesforceConnectionInvalid,
  OnSalesforceConnectionCreatedError,
  OnSalesforceConnectionCreated,
  OnSalesforceDisconnected,
  OnSalesforceDisconnectedError,
  OnSalesforceConnectionLoading,
} from '../../salesforce/salesforce-events';

const SalesforceImg = styled('img')({
  width: 32,
});

export const SalesforceConnectionSettingsCard = ({ refreshTokenExpired }) => {
  const [loading, setLoading] = useState(false);
  const [isConnectionInvalid, setIsConnectionInvalid] = useState(false);
  const [disconnectModalOpen, setDisconnectModalOpen] = useState(false);
  const [areInstructionsOpen, setInstructionsOpen] = useState(false);

  const [salesforceEvent, setSalesforceConnection] = useState(
    salesforceStore.getState(SALESFORCE_CONNECTED_EVENT),
  );

  const [connectionInvalid, setConnectionInvalid] = useState(
    salesforceStore.getState(SALESFORCE_CONNECTION_INVALID_EVENT),
  );

  useSubscription(OnSalesforceConnected, (connectedData) => {
    setSalesforceConnection(connectedData);
    setIsConnectionInvalid(false);
  });

  useSubscription(OnSalesforceConnectionInvalid, (salesforceInvalidConnection) => {
    setConnectionInvalid(salesforceInvalidConnection);
    setIsConnectionInvalid(true);
  });

  useSubscription(OnSalesforceConnectionCreatedError, (message) => {
    toast.error(message);
    setLoading(false);
  });

  useSubscription(OnSalesforceConnectionCreated, () => {
    toast.success('Salesforce connection established');
    fetchSalesforceConnection();
    setLoading(false);
  });

  useSubscription(OnSalesforceDisconnected, () => {
    toast.success('Salesforce integration disconnected');
    setLoading(false);
  });

  useSubscription(OnSalesforceDisconnectedError, (message) => {
    toast.error(message);
    setLoading(false);
  });

  useSubscription(OnSalesforceConnectionLoading, setLoading);

  useEffect(() => {
    if (connectionInvalid) {
      setSalesforceConnection({ salesforceConnection: connectionInvalid.salesforceConnection });
      setIsConnectionInvalid(true);
    }
  }, [connectionInvalid]);

  const handleSalesforceLogin = useCallback(() => {
    salesforceLogin();
  }, []);

  const handleDisconnect = useCallback(() => {
    setDisconnectModalOpen(false);
    setLoading(true);
    disconnectSalesforce();
  }, []);

  const status =
    salesforceEvent && !isConnectionInvalid ? (
      <Text color="GREEN" weight="bold" text="ACTIVE" />
    ) : salesforceEvent && isConnectionInvalid ? (
      <Text color="RED" weight="bold" text="COULDN'T CONNECT" />
    ) : (
      <Text color="RED" weight="bold" text="NOT ACTIVE" />
    );

  const disconnectButton = (
    <DangerButton text={'Disconnect'} onClick={() => setDisconnectModalOpen(true)} />
  );

  const statusText = (
    <Row>
      <Text text="Status: " />
      {status}
    </Row>
  );
  return (
    <>
      <Card stretch style={{ overflow: 'initial' }}>
        <Card.Header>
          <Grid.Layout style={{ width: '100%' }} columns="1fr 100px">
            <Grid.Box justifyContent="center">
              <Row>
                <Heading type="h4" text="Salesforce Configuration" />
                <SalesforceImg src={salesforceLogo} alt="Salesforce logo" />
              </Row>
            </Grid.Box>
            <Grid.Box alignItems="flex-end">
              {salesforceEvent ? (
                <Button rounded squared color="neutral" onClick={() => setInstructionsOpen(true)}>
                  <QuestionMarkIcon />
                </Button>
              ) : null}
            </Grid.Box>
          </Grid.Layout>
        </Card.Header>
        <Card.Body>
          {loading ? (
            <Loader stretch />
          ) : isConnectionInvalid ? (
            <Column gap="md">
              {statusText}
              {refreshTokenExpired ? (
                <>
                  <Paragraph>
                    Your session has expired. Please re-enter your credentials to import or sync
                    data from salesforce.
                  </Paragraph>
                  <Row alignItems="end" stretch>
                    <ActionButton text="Connect with Salesforce" onClick={handleSalesforceLogin} />
                  </Row>
                </>
              ) : (
                <>
                  <Paragraph style={{ marginBottom: 16 }}>
                    We could not connect to salesforce, this may have occurred by one of the
                    following reasons:
                    <br />
                    1 Your organization does not have access to the Salesforce APIs, only the
                    Enterprise and Unlimited salesforce edition have access to them.
                    <br />
                    <br />2 The https://succeed.collabtogrow.com domain is not added to the CORS
                    whitelist, go to Setting &gt; Security &gt; CORS and click the New button to add
                    the domain.
                  </Paragraph>
                  {disconnectButton}
                </>
              )}
            </Column>
          ) : (
            <Grid.Layout stretch columns="40% 1fr">
              <Grid.Box>
                <Column gap="md">
                  {statusText}
                  {salesforceEvent ? (
                    disconnectButton
                  ) : (
                    <>
                      <Paragraph>
                        Enter your credentials to import or sync data from salesforce
                      </Paragraph>
                      <Row alignItems="end" stretch>
                        <ActionButton
                          text="Connect with Salesforce"
                          onClick={handleSalesforceLogin}
                        />
                      </Row>
                    </>
                  )}
                </Column>
              </Grid.Box>
              {salesforceEvent ? (
                <>
                  <Grid.Box>
                    <Column>
                      <Paragraph>
                        Remember to create the{' '}
                        <Text weight="bold">
                          {salesforceEvent.salesforceConnection.customField}
                        </Text>{' '}
                        select field in the opportunities object. Add the following value to the
                        list of values, only opportunities with this value selected will be fetched.
                      </Paragraph>
                      <SalesforceFieldsForm />
                    </Column>
                  </Grid.Box>
                  <SalesforceInstructionsDialog
                    field={salesforceEvent.salesforceConnection.customField}
                    isOpen={areInstructionsOpen}
                    onClose={() => setInstructionsOpen(false)}
                  />
                </>
              ) : null}
            </Grid.Layout>
          )}
        </Card.Body>
      </Card>
      <YesNoDialog
        title={'Disconnect from salesforce'}
        onYes={handleDisconnect}
        onNo={() => setDisconnectModalOpen(false)}
        text={'Are you sure you want to disconnect your integration with salesforce?'}
        isOpen={disconnectModalOpen}
      />
    </>
  );
};

SalesforceConnectionSettingsCard.propTypes = {
  refreshTokenExpired: PropTypes.bool.isRequired,
};
