import Flux from '@cobuildlab/flux-state';

/**
 * Event that triggers a NEXT ACTION error.
 *
 * @type {string}
 */
export const WORK_QUEUE_ERROR_EVENT = 'onWorkQueueError';

/**
 * Event that triggers a Next Action retrieved Event.
 *
 * @type {string}
 */
export const WORK_QUEUE_ITEMS_EVENT = 'onWorkQueueItems';

/**
 * Hold the CommentsData.
 */
class WorkQueueStore extends Flux.DashStore {
  constructor() {
    super();
    this.addEvent(WORK_QUEUE_ERROR_EVENT);
    this.addEvent(WORK_QUEUE_ITEMS_EVENT);
  }
}

export default new WorkQueueStore();
