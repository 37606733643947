import React from 'react';
import { Card, Heading, Table } from '@8base/boost';
import { ListCardBody } from '../../../../components/card/ListCardBody';
import { View } from '@cobuildlab/react-simple-state';
import PropTypes from 'prop-types';
import { onErrorMixin } from '../../../../shared/mixins';
import { fetchCurrentAllianceMembersAction } from '../alliance-actions';
import styled from '@emotion/styled';
import { concatClientAndPartnerCompanyUsers } from '../../../../shared/alliance-utils';
import { OnAllianceMemberList, OnAllianceError } from '../alliance-events';

/**
 * List All the Alliances Member Invitations.
 */

const TableBodyCell = styled(Table.BodyCell)`
  max-width: 250px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: clip !important;
  color: ${(props) => props.width} !important;
`;

class AllianceMembersList extends View {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      members: null,
    };
    this.onError = onErrorMixin.bind(this);
  }

  componentDidMount() {
    this.subscribe(OnAllianceError, this.onError);

    this.subscribe(OnAllianceMemberList, (state) => {
      const { clientCompany, partnerCompany } = state;

      const members = concatClientAndPartnerCompanyUsers(clientCompany, partnerCompany);

      this.setState({
        members,
        loading: false,
      });
    });

    fetchCurrentAllianceMembersAction();
  }

  render() {
    const { members, loading } = this.state;

    const columsGrid = '180px 180px 250px 250px 200px 200px';

    return (
      <React.Fragment>
        <Card.Header>
          <Heading type="h4" text="Alliance Members" />
        </Card.Header>
        <ListCardBody>
          <Table>
            <Table.Header className="justify-center-column" columns={columsGrid}>
              <Table.HeaderCell>First Name</Table.HeaderCell>
              <Table.HeaderCell>Last Name</Table.HeaderCell>
              <Table.HeaderCell>Email</Table.HeaderCell>
              <Table.HeaderCell>Company</Table.HeaderCell>
              <Table.HeaderCell>COMPANY ROLE</Table.HeaderCell>
              <Table.HeaderCell>ALLIANCE ROLE</Table.HeaderCell>
            </Table.Header>
            <Table.Body loading={loading} data={members} className="card-body-list">
              {(member) => {
                return (
                  <Table.BodyRow columns={columsGrid} key={member.id}>
                    <TableBodyCell width={'180px'}>
                      {member.user ? member.user.firstName : ''}
                    </TableBodyCell>
                    <TableBodyCell width={'180px'}>
                      {member.user ? member.user.lastName : ''}
                    </TableBodyCell>
                    <TableBodyCell width={'250px'}>
                      {member.user ? member.user.email : ''}
                    </TableBodyCell>
                    <TableBodyCell width={'250px'}>
                      {member.company ? member.company.name : ''}
                    </TableBodyCell>
                    <TableBodyCell width={'200px'}>
                      {member.role ? member.role.name : ''}
                    </TableBodyCell>
                    <TableBodyCell width={'200px'}>
                      {member.allianceRole ? member.allianceRole : ''}
                    </TableBodyCell>
                  </Table.BodyRow>
                );
              }}
            </Table.Body>
          </Table>
        </ListCardBody>
      </React.Fragment>
    );
  }
}

AllianceMembersList.propTypes = {
  history: PropTypes.object.isRequired,
};

export default AllianceMembersList;
