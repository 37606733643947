import React from 'react';
import PropTypes from 'prop-types';
import LightGreyText from './LightGreyText';
import moment from 'moment';

// Component checks if there's a value present to render
// or else returns "None"
const DetailDateValue = ({ date, fontSize }) => {
  if (date) {
    return <span style={fontSize ? { fontSize: fontSize } : {}}>{moment(date).format('LL')}</span>;
  }
  return <LightGreyText fontSize={fontSize} text="None" />;
};

DetailDateValue.propTypes = {
  date: PropTypes.string,
  fontSize: PropTypes.number,
};

DetailDateValue.defaultProps = {
  date: '',
  fontSize: 12,
};

export default DetailDateValue;
