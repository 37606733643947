import React from 'react';
import { Card } from '@8base/boost';
import PropTypes from 'prop-types';

/**
 * Use this instead of Card.Body for Views,
 * add any css Padding, margin on this component.
 *
 */

const ViewCardBody = ({ children, className, ...rest }) => {
  return (
    <Card.Body borderRadius="all" className={className ? className : 'card-body'} {...rest}>
      {children}
    </Card.Body>
  );
};

ViewCardBody.propTypes = {
  children: PropTypes.any.isRequired,
  className: PropTypes.string.isRequired,
};

export { ViewCardBody };
