export const SALESFORCE_CUSTOM_FIELD_LABEL = 'collabtogrow_alliances';

export const SALESFORCE_CONNECTION_STATUS_ACTIVE = 'ACTIVE';
export const SALESFORCE_CONNECTION_STATUS_INACTIVE = 'INACTIVE';

export const SALESFORCE_FIELD_MAPPING_OBJECTS = [{ value: 'opportunity', label: 'Opportunity' }];

export const SCHEDULE_TIME_OPTIONS = [
  { label: 'Never', value: 0 },
  { label: 'Every Day', value: 1 },
  { label: 'Every 2 Days', value: 2 },
  { label: 'Every 3 Days', value: 3 },
  { label: 'Every 4 Days', value: 4 },
  { label: 'Every 5 Days', value: 5 },
  { label: 'Every 6 Days', value: 6 },
  { label: 'Every Week', value: 7 },
];

export const SALESFORCE_OPPORTUNITY_REMOVE_FIELDS = [
  'AccountId',
  'CampaignId',
  'CurrentGenerators__c',
  'DeliveryInstallationStatus__c',
  'Fiscal',
  'FiscalQuarter',
  'FiscalYear',
  'ForecastCategory',
  'ForecastCategoryName',
  'HasOpenActivity',
  'HasOpportunityLineItem',
  'HasOverdueTask',
  'IsClosed',
  'IsDeleted',
  'IsPrivate',
  'IsWon',
  'LastModifiedById',
  'LastReferencedDate',
  'LastViewedDate',
  'LeadSource',
  'MainCompetitors__c',
  'OwnerId',
  'Pricebook2Id',
  'Probability',
  'SystemModstamp',
  'TotalOpportunityQuantity',
];
