import React from 'react';
import TagsInput from 'react-tagsinput';
import PropTypes from 'prop-types';
import './selectmappedvalues.css'; // If using WebPack and style-loader.
//import 'react-tagsinput/react-tagsinput.css'; // If using WebPack and style-loader.

const SelectMappedValue = ({ handleChange, tags, index }) => {
  const tagDisplayProp = {
    placeholder: 'e.g.1 , Lead, Meeting',
  };
  return (
    <TagsInput
      style={{ height: '30px', width: '100%' }}
      value={tags}
      onChange={(value) => handleChange(value, index)}
      inputProps={tagDisplayProp}
    />
  );
};

SelectMappedValue.propTypes = {
  handleChange: PropTypes.func.isRequired,
  tags: PropTypes.array.isRequired,
  index: PropTypes.number.isRequired,
};
export { SelectMappedValue };
