import React, { Component } from 'react';
import { InputField, Row, Label, Column, DateInputField, SelectField } from '@8base/boost';
import PropTypes from 'prop-types';
import { onErrorMixin } from '../../../../shared/mixins';
import FileInputComponent from '../../../../components/inputs/FileInputComponent';
import { SelectInputById } from '../../../../components/forms/SelectInputById';
import { concatClientAndPartnerUsers } from '../../../../shared/alliance-utils';
import YesNoDialog from '../../../../components/dialogs/YesNoDialog';
import { HorizontalLineText } from '../../../../components/new-ui/text/HorizontalLineText';
import { HorizontalLine } from '../../../../components/new-ui/text/HorizontalLine';
import { GroupInputs } from '../../../../components/new-ui/inputs/GroupInputs';
import { INITIATIVE_RAG_STATUS_LABELS } from '../../../../shared/status';
import { TextArea } from '../../../../shared/components/forms/TextArea';
import { StateContainer } from '../../../../shared/components/StateContainer';
import DocumentTitle from '../../../../components/DocumentTitle';

class InitiativeForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deleteDocumentModalIsOpen: false,
      deleteDocumentPos: null,
    };
    this.onClickDelete = this.onClickDelete.bind(this);
    this.onChangeDocuments = this.onChangeDocuments.bind(this);
    this.nameInput = null;
  }

  onClickDelete = (pos, name) => {
    this.nameInput = name;
    this.setState({
      deleteDocumentModalIsOpen: true,
      deleteDocumentPos: pos,
    });
  };

  onYes = () => {
    this.setState(
      {
        deleteDocumentModalIsOpen: false,
      },
      () => {
        this.onDeleteDocuments(this.nameInput, this.state.deleteDocumentPos);
      },
    );
  };

  onClose = () => {
    this.setState({
      deleteDocumentModalIsOpen: false,
    });
  };

  onChangeDocuments(name, value) {
    const initiativeData = this.props.data;

    if (name === 'documentsFile') {
      for (let aux of value) {
        initiativeData[name].push(aux);
      }
    } else {
      initiativeData[name] = value;
    }

    this.props.onChange(name, initiativeData[name]);
  }

  onDeleteDocuments = (name, pos) => {
    const initiativeData = this.props.data;
    if (name === 'documentsFile') {
      initiativeData[name].splice(pos, 1);
    } else if (name === 'currentDashboardFile') {
      initiativeData[name] = null;
    }
    this.setState({ deleteDocumentPos: null });
    this.props.onChange(name, initiativeData[name]);
  };

  render() {
    const { deleteDocumentModalIsOpen } = this.state;
    const { onChange, clientCompany, partnerCompany } = this.props;
    const {
      name,
      description,
      currentDashboardFile,
      documentsFile,
      baselineStartDate,
      baselineEndDate,
      forecastedEndDate,
      requestedBy,
      requestedDate,
      owner,
      ragStatus,
    } = this.props.data;
    // concat client and partner users
    const users = concatClientAndPartnerUsers(clientCompany, partnerCompany);
    return (
      <>
        <HorizontalLine>
          <HorizontalLineText text={'Initiative'} />
        </HorizontalLine>
        <GroupInputs text={'General'}>
          <Row growChildren gap="lg">
            <Column>
              <InputField
                stretch
                label="Name"
                placeholder={'Name'}
                input={{
                  name: 'name',
                  value: name,
                  onChange: (value, e) => onChange(e.target.name, value),
                }}
              />
              <TextArea
                style={{ width: '100%', height: 152 }}
                label="Description"
                placeholder={'Description'}
                maxlength={1000}
                input={{
                  name: 'description',
                  value: description,
                  onChange: (value) => onChange('description', value),
                }}
              />
            </Column>
          </Row>
        </GroupInputs>
        <GroupInputs text={'Dates'} show={true}>
          <Row>
            <Column style={{ width: '35%' }}>
              <DateInputField
                label="Requested Date"
                input={{
                  name: 'requestedDate',
                  value: requestedDate,
                  onChange: (value) => onChange('requestedDate', value),
                }}
              />
              <DateInputField
                label="Baseline Start Date"
                input={{
                  value: baselineStartDate,
                  onChange: (value) => onChange('baselineStartDate', value),
                }}
              />
              <DateInputField
                label="Baseline End Date"
                input={{
                  name: 'baselineEndDate',
                  value: baselineEndDate,
                  onChange: (value) => onChange('baselineEndDate', value),
                }}
              />
              <DateInputField
                label="Forecasted End Date"
                input={{
                  name: 'forecastedEndDate',
                  value: forecastedEndDate,
                  onChange: (value) => onChange('forecastedEndDate', value),
                }}
              />
            </Column>
            <Column style={{ width: '65%' }}>
              <SelectInputById
                label="Owner"
                input={{
                  name: 'owner',
                  value: owner,
                  onChange: (value) => onChange('owner', value),
                }}
                meta={{}}
                placeholder="Select"
                options={users.map((user) => {
                  return {
                    label: `${user.firstName} ${user.lastName}`,
                    value: user,
                  };
                })}
              />

              <SelectInputById
                label="Requested By"
                input={{
                  name: 'requestedBy',
                  value: requestedBy,
                  onChange: (value) => onChange('requestedBy', value),
                }}
                meta={{}}
                placeholder="Select"
                options={users.map((user) => {
                  return {
                    label: `${user.firstName} ${user.lastName}`,
                    value: user,
                  };
                })}
              />
              <StateContainer value={INITIATIVE_RAG_STATUS_LABELS[2].label}>
                <SelectField
                  label="RAG Status"
                  // style={{ width: '100%', marginBottom: '10px' }}
                  input={{
                    name: 'ragStatus',
                    value: ragStatus,
                    onChange: (value) => onChange('ragStatus', value),
                  }}
                  meta={{}}
                  placeholder="Select"
                  options={INITIATIVE_RAG_STATUS_LABELS}
                  clearable
                />
              </StateContainer>
            </Column>
          </Row>
        </GroupInputs>
        <Row growChildren gap="lg">
          <Column>
            <HorizontalLine>
              <HorizontalLineText text={'Documents'} />
            </HorizontalLine>
            <Row style={{ 'padding-bottom': '15px' }}>
              <Column alignItems="">
                <div style={{ 'margin-left': '20px' }}>
                  <Label kind="secondary" style={{ textAlign: 'left' }}>
                    <DocumentTitle
                      text={'Current Dashboard File'}
                      maxFiles={1}
                      files={currentDashboardFile}
                    />
                  </Label>
                  <FileInputComponent
                    onChange={this.onChangeDocuments}
                    nameInput={'currentDashboardFile'}
                    field={currentDashboardFile}
                    maxFiles={1}
                    text={'Add Document'}
                    onClickDelete={this.onClickDelete}
                  />
                </div>
              </Column>
              <Column alignItems="">
                <div style={{ 'margin-left': '20px' }}>
                  <Label kind="secondary" style={{ textAlign: 'left' }}>
                    <DocumentTitle text={'Documents File'} maxFiles={5} files={documentsFile} />
                  </Label>
                  <FileInputComponent
                    onChange={this.onChangeDocuments}
                    nameInput={'documentsFile'}
                    field={documentsFile}
                    maxFiles={5}
                    text={'Add Document'}
                    onClickDelete={this.onClickDelete}
                  />
                </div>
              </Column>
            </Row>
          </Column>
        </Row>
        <YesNoDialog
          isOpen={deleteDocumentModalIsOpen}
          onYes={this.onYes}
          onNo={this.onClose}
          onClose={this.onClose}
          text={'Are you sure you want to Delete this Document?'}
          title={'Delete Document'}
        />
      </>
    );
  }
}

Object.assign(InitiativeForm.prototype, onErrorMixin);

InitiativeForm.propTypes = {
  data: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  clientCompany: PropTypes.object.isRequired,
  partnerCompany: PropTypes.object.isRequired,
};

export default InitiativeForm;
