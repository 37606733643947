import styled, { css } from 'styled-components';

const ReportTable = styled.table`
  width: 100%;
  text-align: left;
  ${({ borderTop }) =>
    borderTop &&
    css`
      border-top: 1px solid #e9eff4;
    `}
`;

const ReportTableTR = styled.tr`
  border-bottom: 1px solid #e9eff4;
`;

const thStyles = css`
  color: #7e828b;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0.5px;
  line-height: 18px;
  text-transform: uppercase;
  background-color: #f4f7f9;
  ${({ rightBorder }) =>
    rightBorder &&
    css`
      border-right: 1px solid #e9eff4;
    `}
`;

const ReportTableTH = styled.th`
  padding: 16px 0 15px 0;
  ${thStyles}
`;

const ReportTableTD = styled.td`
  color: #323c47;
  font-size: 13px;
  line-height: 20px;
  padding: 14px 0;
  ${({ rightBorder }) =>
    rightBorder &&
    css`
      border-right: 1px solid #e9eff4;
    `};
`;

const ReportTableFooterTD = styled.td`
  padding: 3px 0;
  ${thStyles}
  ${({ total }) =>
    total &&
    css`
      color: #323c47;
      font-size: 13px;
      font-weight: 600;
      line-height: 18px;
      padding: 4px 0 2px 0;
    `};
`;

const ReportTableHeader = styled.thead`
  ${({ fixed }) =>
    fixed &&
    css`
      th {
        position: sticky;
        top: 0;
        padding: 0;
        div {
          padding-top: 15px;
          padding-bottom: 15px;
          border-bottom: 1px solid #e9eff4;
        }
      }
    `}
`;

export {
  ReportTable,
  ReportTableTR,
  ReportTableTH,
  ReportTableTD,
  ReportTableFooterTD,
  ReportTableHeader,
};
