import React from 'react';
import { Dialog, Text, Row } from '@8base/boost';
import PropTypes from 'prop-types';
import { TransparentButton } from '../../../components/buttons/TransparentButton';
import { ProcessingDialogBody } from '../../../components/dialogs/ProcessingDialogBody';
import { SuccessDialogBody } from '../../../components/dialogs/SuccessDialogBody';
import { ErrorDialogBody } from '../../../components/dialogs/ErrorDialogBody';
import { ConfirmPlanDetailsCard } from './ConfirmPlanDetailsCard';
import { ActionButton } from '../../../components/buttons/ActionButton';
import styled from '@emotion/styled';
import moment from 'moment';

const Note = styled(Text)`
  color: #a5afb6;
  margin-top: 5px;
`;

const MainText = styled(Text)`
  color: #7d828c;
  margin-bottom: 20px;
`;

const BottomText = styled(Text)`
  margin-top: 20px;
`;

const ConfirmNewPlanDialog = ({
  text,
  note,
  isOpen,
  title,
  onClose,
  onCloseText,
  onYes,
  onYesText,
  subscriptionPlan,
  status,
  showAnnualPrice,
}) => {
  const { monthlyPrice, annualPrice, name } = subscriptionPlan || {};
  const price = showAnnualPrice ? annualPrice : monthlyPrice;
  let momentAdd = showAnnualPrice ? 'years' : 'months';
  if (name === 'Daily Plan') momentAdd = 'days';
  const expireDateText = moment()
    .add(1, momentAdd)
    .format('L');

  let content;

  if (status === 'processing') content = <ProcessingDialogBody onClose={onClose} />;

  if (status === 'error') content = <ErrorDialogBody onClose={onClose} />;

  if (status === 'success') {
    content = (
      <SuccessDialogBody
        note={`You have succesfully upgraded the ${name} plan. You can now close this dialog below.`}
        onClose={onClose}
      />
    );
  }

  if (!status) {
    content = (
      <>
        <Dialog.Body>
          <MainText weight="semibold">{text}</MainText>
          <ConfirmPlanDetailsCard showAnnualPrice={showAnnualPrice} plan={subscriptionPlan} />
          <BottomText>
            <Note>{note}</Note>
            <Text weight="bold">{`$${price}`}</Text>
            <Note>{' on '}</Note>
            <Text weight="bold">{expireDateText}</Text>
          </BottomText>
        </Dialog.Body>
        <Dialog.Footer>
          <Row alignItems="center" justifyContent="end">
            <TransparentButton text={onCloseText} onClick={onClose} />
            <ActionButton text={onYesText} onClick={onYes} />
          </Row>
        </Dialog.Footer>
      </>
    );
  }

  return (
    <Dialog size="lg" isOpen={isOpen}>
      <Dialog.Header title={title} onClose={onClose} />
      {content}
    </Dialog>
  );
};

ConfirmNewPlanDialog.defaultProps = {
  onCloseText: 'Cancel',
  onYesText: 'Process Payment',
  title: 'Change Plan',
  text: 'CONFIRM NEW SUBSCRIPTION',
  note: `Note: You'll be charged `,
  subscriptionPlan: {},
  status: '',
};

ConfirmNewPlanDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  onYes: PropTypes.func.isRequired,
  title: PropTypes.string,
  text: PropTypes.string,
  note: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  showAnnualPrice: PropTypes.bool.isRequired,
  onCloseText: PropTypes.string,
  onYesText: PropTypes.string,
  subscriptionPlan: PropTypes.object,
  status: PropTypes.string, // 'error' || 'processing' || 'success'
};

export { ConfirmNewPlanDialog };
