import React from 'react';
import { Button } from '@8base/boost';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

const StyledButtonClose = styled(Button)({
  color: '#878c93',
  borderColor: 'transparent',
  right: '20px',
  padding: '0 2rem 0 2rem',
  top: '14px',
  height: '42px',
  width: '47px',
  'border-radius': '4px',
  'background-color': '#FFFFFF',
});

const ActionButtonClose = ({ onClick }) => {
  return (
    <div>
      <StyledButtonClose onClick={onClick} variant="outlined">
        <FontAwesomeIcon icon="times" size="lg" />
      </StyledButtonClose>
    </div>
  );
};

ActionButtonClose.propTypes = {
  // fontAwesomeIcon: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};

export { ActionButtonClose };
