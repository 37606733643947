import {
  allianceKPIsYearModel,
  BOOKINGS_CLIENT_TYPE,
  BOOKINGS_PARTNER_TYPE,
  CONTRIBUTIONS_CLIENT_TYPE,
  CONTRIBUTIONS_PARTNER_TYPE,
  OPPORTUNITY_WIN_RATIO_TYPE,
} from './allianceKPIs-model';
import { log, error } from '@cobuildlab/pure-logger';
import sessionStore, { APOLLO_CLIENT } from '../../../../shared/SessionStore';
import { ALLIANCE_KPI_DELETE_MUTATION, ALLIANCE_KPI_UPDATE_MUTATION } from './allianceKPIs-queries';
import { sanitize8BaseBigInt, sanitize8BaseBigInts } from '../../../../shared/utils';
import * as R from 'ramda';
import moment from 'moment';
import { getKPIFromList } from '../../../reports/balanced-scorecard/balanced-scorecard-utils';
import BigInt from 'big-integer';

/**
 * To get the AllianceKPIs To Be deleted.
 *
 * @param {Array} allianceKPIs - Alliance kpis list.
 * @param {Array} originalAllianceKPIs - Original alliance kpis.
 * @returns {Array} Kpis list to be delete.
 */
const allianceKPIsToBeDeleted = (allianceKPIs, originalAllianceKPIs) => {
  const toBeDeleted = [];

  originalAllianceKPIs.forEach((original) => {
    const _original = allianceKPIs.find((allianceKPIs) => original.id === allianceKPIs.id);
    if (_original === undefined) toBeDeleted.push(original);
  });

  log('allianceKPIsToBeDeleted', toBeDeleted);
  return toBeDeleted;
};

/**
 * To delete allianceKPIs on the Alliance Update actions.
 *
 * @param {Array} allianceKPIs - Alliance kpis list.
 * @param {Array} originalAllianceKPIs - Original alliance kpis.
 */
export const deleteAllianceKPIs = async (allianceKPIs, originalAllianceKPIs) => {
  log(`deleteAllianceKPIs:`, allianceKPIs, originalAllianceKPIs);
  const client = sessionStore.getState(APOLLO_CLIENT);
  const toBeDeleted = allianceKPIsToBeDeleted(allianceKPIs, originalAllianceKPIs);

  const deleteAllianceKPIPromise = async (allianceKPI) => {
    const data = { id: allianceKPI.id };

    let response;
    try {
      response = await client.mutate({
        mutation: ALLIANCE_KPI_DELETE_MUTATION,
        variables: { data },
      });
    } catch (e) {
      error('deleteAllianceKPIs', e, allianceKPI);
      throw e;
    }

    return response;
  };

  let responses;
  try {
    responses = await Promise.all(toBeDeleted.map(deleteAllianceKPIPromise));
  } catch (e) {
    throw e;
  }

  log('deleteAllianceKPIs', responses);
  return responses;
};

/**
 * To get the AllianceKPIs To Be updated.
 *
 * @param {Array} allianceKPIs - Alliance kpis list.
 * @param {Array} originalAllianceKPIs - Original alliance kpis.
 * @returns {Array} Alliance kpis to be updated.
 */
const allianceKPIsToBeUpdated = (allianceKPIs, originalAllianceKPIs) => {
  const toBeUpdated = [];

  allianceKPIs.forEach((allianceKPI) => {
    if (allianceKPI.id) {
      const original = originalAllianceKPIs.find((original) => original.id === allianceKPI.id);
      if (!R.equals(original, allianceKPI)) {
        toBeUpdated.push(allianceKPI);
      }
    }
  });

  log('allianceKPIsTobeUpdated', toBeUpdated);
  return toBeUpdated;
};

/**
 * To Update allianceKPIs on alliance update action.
 *
 * @param {Array} allianceKPIs - Alliance kpis list.
 * @param {Array} originalAllianceKPIs - Original alliance kpis.
 */
export const updateAllianceKPIs = async (allianceKPIs, originalAllianceKPIs) => {
  log(`updateAllianceKPIs:`, allianceKPIs, originalAllianceKPIs);
  const client = sessionStore.getState(APOLLO_CLIENT);
  const toBeUpdated = allianceKPIsToBeUpdated(allianceKPIs, originalAllianceKPIs);

  const updateAllianceKPIPromise = async (data) => {
    let response;
    try {
      response = await client.mutate({
        mutation: ALLIANCE_KPI_UPDATE_MUTATION,
        variables: { data },
      });
    } catch (e) {
      error('updateAllianceKPIs', e, data);
      throw e;
    }

    return response;
  };

  let responses;
  try {
    responses = await Promise.all(toBeUpdated.map(updateAllianceKPIPromise));
  } catch (e) {
    throw e;
  }

  log('updateAllianceKPIs', responses);
  return responses;
};

/**
 * Prepare allianceKPIs for edit view.
 *
 * @param {object} alliance - To remove the unwanted allianceKPIs
 * properties.
 * @returns {Array}  To set the originalAllianceKPIs.
 */
export const sanitizeAllianceKPIsToEdit = (alliance) => {
  const allianceKPIsSanitizeData = alliance.allianceKPIs.items.map((allianceKPI) => {
    delete allianceKPI.__typename;
    return allianceKPI;
  });

  alliance.allianceKPIs = R.clone(allianceKPIsSanitizeData);
  // set default allianceKPIs
  if (!alliance.allianceKPIs.length) {
    const date = alliance.effectiveDate || new Date();
    const year = moment(date).year();
    alliance.allianceKPIs = allianceKPIsYearModel(year);
  }
  return R.clone(allianceKPIsSanitizeData);
};

/**
 * To get the allianceKPIs To Be created on the alliance update action.
 *
 * @param  {Array} allianceKPIs - Alliance kpis.
 * @returns {Array} Kpis to be created list.
 */
export const allianceKPIsToBeCreated = (allianceKPIs) => {
  const toBeCreated = [];

  allianceKPIs.forEach((allianceKPI) => {
    if (allianceKPI.id === undefined) toBeCreated.push(allianceKPI);
  });

  log('allianceKPIsToBeCreated', toBeCreated);
  return toBeCreated;
};

/**
 * Sanitize AllianceKPIs to create
 * // WARNING: this function mutates the data.
 *
 * @param  {object} alliance - Alliance.
 */
export const sanitizeAllianceKPIsCreate = (alliance) => {
  alliance.allianceKPIAllianceRelation = {
    create: alliance.allianceKPIs,
  };
  delete alliance.allianceKPIs;
};

/**
 * GetKPIYears.
 *
 * @param  {object} allianceData - Alliance data.
 * @param  {object} businessCaseData - Bussines case data.
 * @returns {object} KPI by years.
 */
export const getKPIYears = (allianceData, businessCaseData) => {
  const { effectiveDate } = allianceData;
  const { anticipatedCosts, expectedCosts, expectedRevenues } = businessCaseData;

  const _effectiveDate = effectiveDate || new Date();
  const effectiveDateActualDiff = moment().year() - moment(_effectiveDate).year();
  const dateStart = moment(_effectiveDate);
  const kpiYears = [];
  // to get Max years
  const yearsOptions = [
    anticipatedCosts.length,
    expectedCosts.length,
    expectedRevenues.length,
    effectiveDateActualDiff + 1,
  ];

  // Max years for dateEnd & forecastingMaxYears
  const yearsCount = Math.max(...yearsOptions);
  // dateEnd for KPIs
  const dateEnd = moment(_effectiveDate).add(yearsCount - 1, 'years');

  // getKPIYears
  if (dateStart.year() >= dateEnd.year()) {
    kpiYears.push(dateStart.year());
  } else {
    while (dateStart.year() <= dateEnd.year()) {
      kpiYears.push(dateStart.year());
      dateStart.add(1, 'year');
    }
  }

  // getForecastYears
  const forecastYears = R.clone(kpiYears);
  // push one more year
  forecastYears.push(forecastYears[forecastYears.length - 1] + 1);

  return { kpiYears, forecastYears, yearsCount };
};

/**
 * Check ActiveYears And Set allianceData State Mixin
 * updates the selectedKPIYear &
 * allianceKPIs based on effectiveDate
 * // You must bind this function on the constructor.
 *
 * @param {object} allianceData - Alliance data.
 * @param { object } businessCaseData - BusinessCaseData.
 */
export function checkActiveYearsAndSetStateMixin(allianceData, businessCaseData) {
  const { selectedKPIYear } = this.state;
  const { kpiYears } = getKPIYears(allianceData, businessCaseData);

  // add missing years
  for (const kpiYear of kpiYears) {
    if (!allianceData.allianceKPIs.some(({ year }) => kpiYear === year)) {
      allianceData.allianceKPIs.push.apply(
        allianceData.allianceKPIs,
        allianceKPIsYearModel(kpiYear),
      );
    }
  }
  // remove not included years
  allianceData.allianceKPIs = allianceData.allianceKPIs.filter(({ year }) =>
    kpiYears.includes(year),
  );
  // set selected years
  const _selectedKPIYear = kpiYears.includes(selectedKPIYear) ? selectedKPIYear : kpiYears[0];

  this.setState({
    selectedKPIYear: _selectedKPIYear,
    allianceData,
    kpiYears,
  });
}

/**
 * Pops the last year from the businessCase.
 - This affects anticipatedCosts, expectedCosts and expectedRevenues.
 - If any of the arrays cannot pop, this will return without any pop action.
 *
 * @param {object} param0 - Business case object.
 */
export const popBusinessCaseKPIYear = ({ anticipatedCosts, expectedRevenues }) => {
  if (anticipatedCosts.length - 1 <= 0 || expectedRevenues.length - 1 <= 0) {
    return;
  }

  expectedRevenues.pop();
  anticipatedCosts.pop();
};

/**
 * Pushes a new year to the businessCase
 * - This affects anticipatedCosts, expectedCosts and expectedRevenues.
 *
 * @param {object} param0 - Business case object.
 */
export const pushBusinessCaseKPIYear = ({ anticipatedCosts, expectedRevenues }) => {
  anticipatedCosts.push('0');
  expectedRevenues.push('0');
};

/**
 * Sanitize Alliance KPIs.
 *
 * @param  {object} alliance - Alliance.
 */
export const sanitizeAllianceKPIs = (alliance) => {
  alliance.allianceKPIs = alliance.allianceKPIs.map((kpi) => {
    sanitize8BaseBigInts(kpi, 'monthByMonth');
    if (kpi.type !== OPPORTUNITY_WIN_RATIO_TYPE) sanitize8BaseBigInt(kpi, 'target');

    return kpi;
  });
};

/**
 * Get Business Case Anticipated Costs.
 - Calculates expected revenues and anticipated costs based on KPIs.
 *
 * @param  {object} alliance - Alliance.
 * @param  {object} businessCase - Business Case.
 * @returns {{expectedRevenues: string[], anticipatedCosts: string[]}} - Anticipated Costs.
 */
export const getBusinessCaseAnticipatedCosts = (alliance, businessCase) => {
  const { allianceKPIs } = alliance;
  const { kpiYears } = getKPIYears(alliance, businessCase);

  const results = kpiYears.map((kpiYear, i) => {
    // targetBookings
    const clientBookings = getKPIFromList(allianceKPIs, BOOKINGS_CLIENT_TYPE, kpiYear);
    const partnerBookings = getKPIFromList(allianceKPIs, BOOKINGS_PARTNER_TYPE, kpiYear);
    const clientBookingsTarget = clientBookings ? clientBookings.target : BigInt(0);
    const partnerBookingsTarget = partnerBookings ? partnerBookings.target : BigInt(0);
    const targetRevenue = BigInt(clientBookingsTarget).add(BigInt(partnerBookingsTarget));

    // targetContributions
    const clientContributions = getKPIFromList(allianceKPIs, CONTRIBUTIONS_CLIENT_TYPE, kpiYear);
    const partnerContributions = getKPIFromList(allianceKPIs, CONTRIBUTIONS_PARTNER_TYPE, kpiYear);
    const clientContributionsTarget = clientContributions ? clientContributions.target : BigInt(0);
    const partnerContributionsTarget = partnerContributions
      ? partnerContributions.target
      : BigInt(0);
    const targetCost = BigInt(clientContributionsTarget).add(BigInt(partnerContributionsTarget));

    return { targetRevenue, targetCost };
  });

  const expectedRevenues = results.map((result) => result.targetRevenue);
  const anticipatedCosts = results.map((result) => result.targetCost);

  return { expectedRevenues, anticipatedCosts };
};
