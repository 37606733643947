import React, { useEffect, useState } from 'react';
import { Card, Heading, Row, Loader } from '@8base/boost';
import { ViewCardBody } from '../../../components/card/ViewCardBody';
import PropTypes from 'prop-types';
import AllianceDetailTable from './components/AllianceDetailTable';
import BusinessCaseDetailTable from '../../document-management/business-case/components/BusinessCaseDetailTable';
import * as toast from '../../../components/toast/Toast';
import {
  fetchAlliance,
  updateAllianceApproval,
  completedAlliance,
  openComments,
  fetchAllianceDetailMembersAction,
  reactivateAlliance,
  createAllianceDeleted,
  fetchAllianceDeletedList,
  deleteAllianceDeleted,
  requestApprovalForAlliance,
  restoreAlliance,
  fetchAllianceApprovalsList,
} from './alliance-actions';
import AllianceModel from './Alliance.model';
import * as R from 'ramda';
import { View } from '@cobuildlab/react-simple-state';
import { onChangeMixin, onErrorMixin } from '../../../shared/mixins';
import sessionStore, { NEW_SESSION_EVENT } from '../../../shared/SessionStore';
import YesNoDialog from '../../../components/dialogs/YesNoDialog';
import { ALLIANCE_SER } from '../../../shared/roles';
import {
  canCompletedAlliance,
  canApproveAllianceV2,
  canReactivateAlliance,
  canManageMembers,
  canSubmitForApprovalAlliancePermission,
  canRestoreAlliance,
  canDeleteAllianceV2,
} from '@cobuildlab/collabtogrow-permissions';
import { ActionButtonClose } from '../../../components/buttons/ActionButtonClose';
import { ActionButton } from '../../../components/buttons/ActionButton';
import { OptionButton } from '../../../components/buttons/OptionButton';
import { DangerButton } from '../../../components/buttons/DangerButton';
import { TransparentButtonSvg } from '../../../components/buttons/TransparentButtonSvg';
import { TransparentButtonFontAwesome } from '../../../components/buttons/TransparentButtonFontAwesome';
import collaborateIcon from '../../../images/icons/collab-chat-icon.svg';
import ApprovalDetailTable from '../../../components/tables/approvalTable/ApprovalDetailTable';
import AllianceUserRoleDetailTable from '../../../components/tables/allianceUserRoleTable/AllianceUserRoleDetailTable';
import { CardFooter } from '../../../components/new-ui/card/CardFooter';
import BusinessCaseModel from '../../document-management/business-case/BusinessCase.model';
import { ALLIANCE_APPROVAL_APPROVED, ALLIANCE_APPROVAL_REJECTED } from '../../../shared/status';
import { fetchSession } from '../../auth/auth.actions';
import { ALLIANCE_UPDATE_SUBSCRIPTION } from './alliance-queries';
import { createApolloClient } from '../../../shared/apollo';
import { getToken } from '../../../shared/apollo/helpers';
import { useSubscription } from '@apollo/client';
import {
  OnAllianceDetail,
  OnAllianceError,
  OnAllianceApprovalList,
  OnAllianceSubmitForApproval,
  OnAllianceReject,
  OnAllianceUpdate,
  OnAllianceCompleted,
  OnAllianceRestore,
  OnAllianceDeleted,
  OnAllianceCancelDeleted,
  OnAllianceDetailListMembers,
  OnAllianceDeletedList,
  OnAllianceReactive,
} from './alliance-events';

/**
 * Alliance Detail View.
 *
 * @param {object} alliance - Alliance.
 */

class AllianceDetailView extends View {
  constructor(props) {
    super(props);

    const allianceModel = R.clone(AllianceModel);
    allianceModel.businessCase = R.clone(BusinessCaseModel);

    this.state = {
      data: R.clone(allianceModel),
      companyUserRelation: null,
      loading: true,
      approvalModalIsOpen: false,
      rejectModalIsOpen: false,
      completedModalIsOpen: false,
      reactivateModalIsOpen: false,
      submitForApprovalModalIsOpen: false,
      approvalData: [],
      allianceSerList: [],
      canAllianceDeleted: false,
      canCancelAllianceDeleted: false,
      allianceDeletedData: [],
      submitForApprovalAlliance: false,
      restoreModalIsOpen: false,
      approvalDataList: null,
    };
    this.onChange = onChangeMixin.bind(this);
    this.onError = onErrorMixin.bind(this);
    this.user = sessionStore.getState(NEW_SESSION_EVENT).user;
    this.selectedAlliance = sessionStore.getState(NEW_SESSION_EVENT).selectedAlliance;
  }

  componentDidMount() {
    const { match } = this.props;
    if (!match.params.id) return toast.error('Alliance ID missing');

    this.subscribe(OnAllianceError, this.onError);

    this.subscribe(OnAllianceDetail, (state) => {
      this.setState({
        data: R.clone(state.alliance),
        loading: false,
      });
    });

    this.subscribe(OnAllianceApprovalList, (state) => {
      const { allianceApprovalsList } = state;
      const approvalData = allianceApprovalsList.items.slice(0, 2);

      const approvalDataList = allianceApprovalsList.items.map((allianceApproval) => ({
        ...allianceApproval,
      }));

      const submitForApprovalAlliance = allianceApprovalsList.items.slice(0, 2);

      this.setState({
        approvalData,
        approvalDataList,
        loading: false,
        submitForApprovalAlliance,
      });
    });

    this.subscribe(OnAllianceUpdate, async (data) => {
      toast.success('The Alliance is under process of approval');
      if (this.state.data.id === this.selectedAlliance.id) {
        await fetchSession();
      }
      this.props.history.goBack();
    });

    this.subscribe(OnAllianceReactive, (state) => {
      toast.success('Alliance Successfully reactivate');
      const { data } = this.state;
      data.status = state.status;
      this.setState({
        loading: false,
        data,
      });
    });

    this.subscribe(OnAllianceReject, () => {
      toast.success('Alliance Successfully Rejected');
      this.props.history.goBack();
    });

    this.subscribe(OnAllianceCompleted, (state) => {
      const { allianceUpdate } = state;
      fetchAlliance(allianceUpdate.id);
      toast.success('Alliance Successfully Complete');
    });

    this.subscribe(OnAllianceRestore, (state) => {
      const { allianceUpdate } = state;
      fetchAlliance(allianceUpdate.id);
      toast.success('Alliance Successfully Restored');
    });

    this.subscribe(OnAllianceSubmitForApproval, (state) => {
      const { allianceUpdate } = state;
      fetchAlliance(allianceUpdate.id);
      fetchAllianceApprovalsList(allianceUpdate.id);
      toast.success('Alliance Submitted!');
    });

    this.subscribe(OnAllianceDeleted, (state) => {
      toast.success('Alliance scheduled for deletion!');
      const allianceDeletedData = R.clone(state.allianceDeletedCreate);
      this.setState({
        allianceDeletedData,
        canAllianceDeleted: false,
        canCancelAllianceDeleted: true,
        loading: false,
      });
    });

    this.subscribe(OnAllianceCancelDeleted, (state) => {
      const { success } = state.allianceDeletedDelete;
      const allianceDeletedData = [];
      const canAllianceDeleted = success;
      const canCancelAllianceDeleted = !success;
      toast.success('Cancel alliance deletion Successfully ');
      this.setState({
        allianceDeletedData,
        canAllianceDeleted,
        canCancelAllianceDeleted,
        loading: false,
      });
    });

    this.subscribe(OnAllianceDetailListMembers, (state) => {
      const items = state.allianceUserAllianceRelation.items.map((allianceUser) => ({
        ...allianceUser,
      }));

      const allianceSerList =
        items.length !== 0 ? items.filter((item) => item.role.name === ALLIANCE_SER) : [];

      this.setState({
        allianceSerList,
      });
    });

    this.subscribe(OnAllianceDeletedList, (state) => {
      const items = state.allianceDeletedsList.items.map((allianceDeleted) => ({
        ...allianceDeleted,
      }));

      const canAllianceDeleted = items.length === 0;
      const canCancelAllianceDeleted = items.length !== 0;
      const allianceDeletedData = items[0];

      this.setState({
        canAllianceDeleted,
        canCancelAllianceDeleted,
        allianceDeletedData,
      });
    });

    fetchAllianceDeletedList(match.params.id);
    fetchAlliance(match.params.id);
    fetchAllianceDetailMembersAction(match.params.id);
    fetchAllianceApprovalsList(match.params.id);
  }

  componentDidUpdate() {
    if (this.props.newData) {
      if (this.props.newData.Alliance && this.props.newData.Alliance.node) {
        const changedAlliance = this.props.newData.Alliance.node;
        const oldAlliance = this.state.data;
        if (changedAlliance.id === oldAlliance.id) {
          let render = false;
          Object.keys(changedAlliance).forEach((key) => {
            if (changedAlliance[key] !== oldAlliance[key]) render = true;
          });
          if (render) {
            this.setState({ data: { ...oldAlliance, ...changedAlliance } });
            console.log('PostRender', this.props);
          }
        }
      }
    }
  }

  approve = () => {
    this.setState({
      approvalModalIsOpen: true,
    });
  };

  onYes = () => {
    this.setState(
      {
        approvalModalIsOpen: false,
        loading: true,
      },
      () => {
        updateAllianceApproval({ ...this.state.data }, ALLIANCE_APPROVAL_APPROVED);
      },
    );
  };

  onClose = () => {
    this.setState({
      approvalModalIsOpen: false,
    });
  };

  reject = () => {
    this.setState({
      rejectModalIsOpen: true,
    });
  };

  onYesReject = () => {
    this.setState(
      {
        rejectModalIsOpen: false,
        loading: true,
      },
      () => {
        updateAllianceApproval({ ...this.state.data }, ALLIANCE_APPROVAL_REJECTED);
      },
    );
  };

  onCloseReject = () => {
    this.setState({
      rejectModalIsOpen: false,
    });
  };

  completeModal = () => {
    this.setState({
      completedModalIsOpen: true,
    });
  };

  restoreModal = () => {
    this.setState({
      restoreModalIsOpen: true,
    });
  };

  onYesModalCompleted = () => {
    this.setState(
      {
        completedModalIsOpen: false,
        loading: true,
      },
      () => {
        const allianceData = R.clone(this.state.data);
        completedAlliance(allianceData);
      },
    );
  };

  onYesModalRestore = () => {
    this.setState(
      {
        restoreModalIsOpen: false,
        loading: true,
      },
      () => {
        const allianceData = R.clone(this.state.data);
        restoreAlliance(allianceData);
      },
    );
  };

  onCloseModalCompleted = () => {
    this.setState({
      completedModalIsOpen: false,
    });
  };

  onCloseModalRestore = () => {
    this.setState({
      restoreModalIsOpen: false,
    });
  };

  onCloseModalReactivate = () => {
    this.setState({
      reactivateModalIsOpen: false,
    });
  };

  onYesModalReactivate = () => {
    this.setState(
      {
        reactivateModalIsOpen: false,
        loading: true,
      },
      () => {
        const allianceData = R.clone(this.state.data);
        reactivateAlliance(allianceData.id);
      },
    );
  };

  deleteAlliance = () => {
    const alliance = R.clone(this.state.data);
    this.setState(
      {
        loading: true,
      },
      () => createAllianceDeleted(alliance),
    );
  };

  recoverAlliance = () => {
    const allianceDeletedData = R.clone(this.state.allianceDeletedData);
    this.setState(
      {
        loading: true,
      },
      () => deleteAllianceDeleted(allianceDeletedData),
    );
  };

  reactivateModal = () => {
    this.setState({
      reactivateModalIsOpen: true,
    });
  };

  onSelectSubmitForApproval = (alliance) => {
    this.setState({
      alliance,
      submitForApprovalModalIsOpen: true,
    });
  };

  onYesModalSubmitForApproval = () => {
    this.setState(
      {
        submitForApprovalModalIsOpen: false,
        loading: true,
      },
      () => requestApprovalForAlliance(R.clone(this.state.alliance)),
    );
  };

  onCloseModalSubmitForApproval = () => {
    this.setState({
      submitForApprovalModalIsOpen: false,
    });
  };

  render() {
    const {
      loading,
      data: alliance,
      approvalModalIsOpen,
      rejectModalIsOpen,
      completedModalIsOpen,
      reactivateModalIsOpen,
      submitForApprovalModalIsOpen,
      approvalData,
      allianceSerList,
      canAllianceDeleted,
      canCancelAllianceDeleted,
      submitForApprovalAlliance,
      restoreModalIsOpen,
      approvalDataList,
    } = this.state;

    let content = <Loader stretch />;
    let buttonsTop = '';
    let buttonsBottom = '';

    if (!loading) {
      const [canApprove] = canApproveAllianceV2(this.user, alliance);

      content = (
        <div id={'c2gScreen'}>
          <AllianceDetailTable
            data={alliance}
            onChange={this.onChange}
            currency={alliance.currency}
            onClickEdit={() =>
              this.props.history.push(`/settings/alliance-management/edit/${alliance.id}`)
            }
          />
          <BusinessCaseDetailTable
            hideExpectedCostAvoidance
            data={alliance.businessCase}
            currency={alliance.currency}
          />
          <AllianceUserRoleDetailTable data={allianceSerList} />
          {approvalData && <ApprovalDetailTable data={approvalData} />}
          {approvalDataList && (
            <ApprovalDetailTable data={approvalDataList} title={'APPROVAL HISTORY'} />
          )}
        </div>
      );
      buttonsTop = (
        <>
          <div className="company-icons">
            {canManageMembers(this.user, alliance) ? (
              <TransparentButtonFontAwesome
                text={'Manage Members'}
                fontAwesomeIcon={'users-cog'}
                onClick={() =>
                  this.props.history.push(`/settings/alliance-management/members/${alliance.id}`)
                }
              />
            ) : null}
            <TransparentButtonSvg
              iconSvg={collaborateIcon}
              text={'Collaborate'}
              onClick={() => openComments(alliance)}
            />
            <ActionButtonClose onClick={() => this.props.history.goBack()} />
          </div>
        </>
      );

      buttonsBottom = (
        <Row justifyContent="end">
          {canReactivateAlliance(this.user, alliance) && !canCancelAllianceDeleted ? (
            <OptionButton
              text="Reactivate Alliance"
              fontAwesomeIcon="clipboard-list"
              onClick={this.reactivateModal}
            />
          ) : null}

          {canDeleteAllianceV2(this.user, alliance)[0] && canAllianceDeleted ? (
            <OptionButton text="Delete" fontAwesomeIcon="trash" onClick={this.deleteAlliance} />
          ) : null}

          {canDeleteAllianceV2(this.user, alliance)[0] && canCancelAllianceDeleted ? (
            <OptionButton
              text="Recover"
              fontAwesomeIcon="trash-restore"
              onClick={this.recoverAlliance}
            />
          ) : null}
          {canSubmitForApprovalAlliancePermission(this.user, alliance) ? (
            <ActionButton
              text={'Submit For Approval'}
              fontAwesomeIcon={'check'}
              onClick={() => {
                this.onSelectSubmitForApproval(alliance);
              }}
              disabled={!submitForApprovalAlliance}
            />
          ) : (
            ''
          )}
          <DangerButton
            text={'Reject'}
            fontAwesomeIcon={'times'}
            onClick={this.reject}
            disabled={!canApprove}
          />
          <ActionButton
            fontAwesomeIcon={'check'}
            onClick={this.approve}
            text={'Approve'}
            disabled={!canApprove}
          />

          {canCompletedAlliance(this.user, alliance) ? (
            <ActionButton
              text="Mark Completed"
              fontAwesomeIcon="clipboard-list"
              onClick={() => {
                this.completeModal(alliance);
              }}
            />
          ) : null}

          {canRestoreAlliance(this.user, alliance) ? (
            <ActionButton
              text="Restore"
              fontAwesomeIcon="clipboard-list"
              onClick={() => {
                this.restoreModal(alliance);
              }}
            />
          ) : null}
        </Row>
      );
    }

    return (
      <React.Fragment>
        <Card.Header>
          <Heading type="h4" text={alliance.name} />
          {buttonsTop}
        </Card.Header>
        <ViewCardBody>{content}</ViewCardBody>
        <CardFooter>{buttonsBottom}</CardFooter>
        <YesNoDialog
          title={'Approve Alliance'}
          onYes={this.onYes}
          onClose={this.onClose}
          onNo={this.onClose}
          text={'Are you sure you want to Approve the Alliance?'}
          isOpen={approvalModalIsOpen}
        />
        <YesNoDialog
          title={'Reject Alliance'}
          onYes={this.onYesReject}
          onClose={this.onCloseReject}
          onNo={this.onCloseReject}
          text={'Are you sure you want to Reject the Alliance?'}
          isOpen={rejectModalIsOpen}
        />
        <YesNoDialog
          title={'Complete Alliance '}
          onYes={this.onYesModalCompleted}
          onCloseModalCompleted={this.onCloseModalCompleted}
          onNo={this.onCloseModalCompleted}
          text={'Are you sure you want to Mark the Alliance as Completed?'}
          isOpen={completedModalIsOpen}
        />

        <YesNoDialog
          title={'Restore Alliance '}
          onYes={this.onYesModalRestore}
          onCloseModalCompleted={this.onCloseModalRestore}
          onNo={this.onCloseModalRestore}
          text={'Are you sure you want to Restore this alliance?'}
          isOpen={restoreModalIsOpen}
        />

        <YesNoDialog
          title={'Reactivate Alliance'}
          onYes={this.onYesModalReactivate}
          onCloseModalCompleted={this.onCloseModalReactivate}
          onNo={this.onCloseModalReactivate}
          text={'Are you sure you want to Reactivate Alliance?'}
          isOpen={reactivateModalIsOpen}
        />
        <YesNoDialog
          isOpen={submitForApprovalModalIsOpen}
          onYes={this.onYesModalSubmitForApproval}
          onNo={this.onCloseModalSubmitForApproval}
          onClose={this.onCloseModalSubmitForApproval}
          text={'Are you sure you want to Submit this Alliance For Approval?'}
          title={'Submit For Approval'}
        />
      </React.Fragment>
    );
  }
}

AllianceDetailView.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

const WithSubscription = ({ history, match }) => {
  const id = match.params.id;
  const [client, setClient] = useState(createApolloClient(getToken));
  const [data, setData] = useState(undefined);
  useEffect(() => {
    if (!client) {
      setClient(createApolloClient(getToken));
    }
  }, [client]);
  const subscription = useSubscription(ALLIANCE_UPDATE_SUBSCRIPTION, {
    variables: {
      id,
    },
    fetchPolicy: 'network-only',
    client,
    onSubscriptionData: ({ subscriptionData }) => {
      console.log('DEBUG:WithSubscription:onSubscriptionData:', subscriptionData);
      if (subscriptionData && subscriptionData.data) setData(subscriptionData.data);
    },
  });
  console.log('DEBUG:WithSubscription:subscription:', subscription);
  return <AllianceDetailView match={match} history={history} newData={data} />;
};

WithSubscription.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default WithSubscription;
