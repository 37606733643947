import React from 'react';
import PropTypes from 'prop-types';

const CenterImg = ({ width = 80, alt = 'img', src, ...rest }) => {
  return (
    <img
      style={{
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto',
      }}
      src={src}
      width={width}
      alt={alt}
      {...rest}
    />
  );
};

CenterImg.defaultProps = {
  width: 80,
  alt: 'img',
};

CenterImg.propTypes = {
  src: PropTypes.string.isRequired,
  width: PropTypes.number,
  alt: PropTypes.string,
};

export default CenterImg;
