import Flux from '@cobuildlab/flux-state';

/**
 * Event that saves filters for Active Sales Pipeline.
 */
export const PIVOT_TABLE_FILTERS_EVENT = 'pivotTableFilters';

const INITIAL_STATE = {
  filters: [],
  sorts: [],
  groups: [],
  fieldsList: [],
};

/**
 * Hold the Pivot Table Config Page data.
 */
class PivotTableStore extends Flux.DashStore {
  constructor() {
    super();
    this.addEvent(PIVOT_TABLE_FILTERS_EVENT);
    Flux.dispatchEvent(PIVOT_TABLE_FILTERS_EVENT, INITIAL_STATE);
  }
}

const pivotTableStore = new PivotTableStore();
export default pivotTableStore;
