import React from 'react';
import { Table, Dropdown, Menu, Icon, Loader } from '@8base/boost';
import { DropdownBodyOnTable } from '../../../../components/dropdown/DropdownBodyOnTable';
import { Link } from 'react-router-dom';
import * as R from 'ramda';
import { canDeleteDeal, canEditDeal } from '../deal-permissions';
import PropTypes from 'prop-types';
import { CurrencyTextField } from '../../../../shared/components/CurrencyTextField';
import DealDate from './DealDate';

const DealTable = ({
  user,
  dealsList,
  loadingPage,
  loading,
  history,
  currency,
  alliance,
  onSelectForDelete,
}) => {
  return (
    <Table>
      <Table.Header className="justify-center-column" columns="270px 270px 270px 270px 270px 270px">
        <Table.HeaderCell className="name-column">Name</Table.HeaderCell>
        <Table.HeaderCell>Customer Name</Table.HeaderCell>
        <Table.HeaderCell>Amount</Table.HeaderCell>
        <Table.HeaderCell>Owner</Table.HeaderCell>
        <Table.HeaderCell>Created Date</Table.HeaderCell>
        <Table.HeaderCell>Options</Table.HeaderCell>
      </Table.Header>
      <Table.Body loading={loading} data={dealsList} className="card-body-list">
        {(deal, index) => {
          let customerName = 'N/A';
          let amount = '';
          if (deal.customerName) customerName = deal.customerName;
          if (deal.amount) amount = deal.amount;
          const isLast = index === dealsList.length - 1;

          const pageLoader = isLast && loadingPage ? <Loader stretch /> : null;

          return (
            <>
              <Table.BodyRow columns="280px 330px 250px 270px 230px 270px" key={deal.id}>
                <Table.BodyCell className="deal-column">
                  <Link className="item-name" to={`/management/deal/${deal.id}/`}>
                    {deal.name}
                  </Link>
                </Table.BodyCell>
                <Table.BodyCell className="deal-column">
                  <Link className="item-name" to={`/management/deal/${deal.id}/`}>
                    {customerName}
                  </Link>
                </Table.BodyCell>
                <Table.BodyCell className="deal-column">
                  <CurrencyTextField textAlign={'center'} value={amount} currency={currency} />
                </Table.BodyCell>

                <Table.BodyCell className="justify-left-row deal-column">
                  {`${R.pathOr('', ['owner', 'firstName'], deal)}  ${R.pathOr(
                    '',
                    ['owner', 'lastName'],
                    deal,
                  )}`}
                </Table.BodyCell>
                <Table.BodyCell className="deal-column">
                  <DealDate dealDate={deal.createdDate} />
                </Table.BodyCell>
                <Table.BodyCell className="justify-center-row">
                  <Dropdown defaultOpen={false}>
                    <Dropdown.Head>
                      <Icon name="More" className="more-icon" />
                    </Dropdown.Head>
                    <DropdownBodyOnTable>
                      {({ closeDropdown }) => (
                        <Menu>
                          <Menu.Item
                            onClick={() => {
                              history.push(`/management/deal/${deal.id}`);
                            }}>
                            Details
                          </Menu.Item>
                          {canEditDeal(user, deal, alliance) ? (
                            <Menu.Item
                              onClick={() => {
                                history.push(`/management/deal/edit/${deal.id}`);
                              }}>
                              Edit
                            </Menu.Item>
                          ) : null}
                          {canDeleteDeal(user, deal, alliance) ? (
                            <Menu.Item
                              onClick={() => {
                                closeDropdown();
                                onSelectForDelete(deal);
                              }}>
                              Delete
                            </Menu.Item>
                          ) : null}
                        </Menu>
                      )}
                    </DropdownBodyOnTable>
                  </Dropdown>
                </Table.BodyCell>
              </Table.BodyRow>
              {pageLoader}
            </>
          );
        }}
      </Table.Body>
    </Table>
  );
};

DealTable.propTypes = {
  user: PropTypes.object.isRequired,
  dealsList: PropTypes.array.isRequired,
  loadingPage: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired,
  currency: PropTypes.object.isRequired,
  alliance: PropTypes.string.isRequired,
  onSelectForDelete: PropTypes.func.isRequired,
};

export default DealTable;
