import { createEvent } from '@cobuildlab/react-simple-state';

export const OnRiskError = createEvent();
export const OnRiskCreate = createEvent();
export const OnRiskAutoSave = createEvent();
export const OnRiskAutoSaveError = createEvent();
export const OnRiskDetail = createEvent();
export const OnRiskUpdate = createEvent();
export const OnRiskCompleted = createEvent();
export const OnRiskRestore = createEvent();
