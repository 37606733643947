import React from 'react';
import { Card, Heading, Label, Loader } from '@8base/boost';
import * as toast from '../../../components/toast/Toast';
import { Redirect, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { DEAL_FIELDS } from './deal-model';
import { View } from '@cobuildlab/react-simple-state';
import { onErrorMixin } from '../../../shared/mixins';
import Header from '../../../components/Header';
import { checkDataCvs, createDealFromCSV, sanitizeCsvData } from './deal-actions';
import { DealHeader } from './components/DealHeader';
import { ActionButton } from '../../../components/buttons/ActionButton';
import * as R from 'ramda';
import { fetchCurrentAllianceMembersAction } from '../../settings/alliance-management/alliance-actions';
import { DealDataCSVRow } from './components/DealDataCSVRow';
import {
  concatClientAndPartnerCompanyUsers,
  getCurrencyOnSession,
} from '../../../shared/alliance-utils';
import { formatUser } from '../../../shared/utils';
import sessionStore, { NEW_SESSION_EVENT } from '../../../shared/SessionStore';
import {
  OnDealImported,
  OnDealImportedError,
  OnDealImportedHeaderError,
  OnDealImportedValid,
  OnDealError,
  OnDealCreate,
} from '../deal/deal-events';
import { OnAllianceMemberList } from '../../settings/alliance-management/alliance-events';

/**
 * Import Deal.
 */
class DealImportedView extends View {
  constructor(props) {
    super(props);
    this.state = {
      selectedAlliance: sessionStore.getState(NEW_SESSION_EVENT).selectedAlliance,
      loading: true,
      allianceMembersOptions: [],
      data: [],
      dealOwner: [],
      dealsCreated: 0,
      dealImportedValid: false,
      dealImportedError: {},
      dealImportedHeaderError: {},
    };
    this.onError = onErrorMixin.bind(this);

    const state = OnDealImported.get();

    if (state) {
      const { data, headers, dealsCompany, otherCompany } = state;
      this.data = data.filter((d) => d);
      this.headers = headers;
      console.log('company', dealsCompany);
      console.log('other', otherCompany);
      this.company = dealsCompany;
      this.otherCompany = otherCompany;
      this.state.data = new Array(this.headers.length).fill(null);
      this.state.dealOwner = new Array(this.data.length).fill(null);
      this.dealsCreated = 0;
    }

    this.dealFieldsOptions = DEAL_FIELDS.map(({ id, label }) => ({ label, value: id }));
  }

  componentDidMount() {
    this.subscribe(OnDealError, (error) => {
      const { dealImportedError } = this.state;
      const dealImportedHeaderError = {};

      if (error.indexRow) {
        dealImportedError.indexRow = error.indexRow;
        dealImportedError.indexColumn = error.indexColumn;
      }

      this.setState({ loading: false, dealImportedError, dealImportedHeaderError });

      toast.error(error.message);
    });

    this.subscribe(OnDealImportedHeaderError, (state) => {
      const { dealImportedHeaderError } = this.state;
      const dealImportedError = {};
      dealImportedHeaderError.rows = state;

      this.setState({ loading: false, dealImportedHeaderError, dealImportedError });
      toast.error(`Deal imported can't repeat header fields`);
    });

    this.subscribe(OnDealImportedValid, (state) => {
      if (state) {
        const columns = this.state.data;
        const currency = getCurrencyOnSession();
        const data = sanitizeCsvData(this.data, columns, currency);
        createDealFromCSV(this.state.dealOwner, data, this.company, this.otherCompany);
      }
    });

    this.subscribe(OnDealImportedError, (error) => {
      this.setState({ loading: false });
      this.onError(error);
    });

    this.subscribe(OnDealCreate, (state) => {
      const { operations } = state;
      this.setState({ dealsCreated: this.state.dealsCreated + operations });
      if (this.state.dealsCreated >= this.data.length) {
        toast.success('Deals Successfully Imported');
        this.props.history.push('/management/deal');
      }
    });

    this.subscribe(OnAllianceMemberList, (state) => {
      const { clientCompany, partnerCompany } = state;
      const allianceMembers = concatClientAndPartnerCompanyUsers(clientCompany, partnerCompany);

      this.setState({
        loading: false,
        allianceMembersOptions: allianceMembers.map((member) => {
          return {
            label: formatUser(member.user),
            value: member.user.id,
          };
        }),
      });
    });

    fetchCurrentAllianceMembersAction();
  }

  onSubmit = () => {
    const headers = R.clone(this.state.data);
    const dataCvs = R.clone(this.data);
    const { oneSided: isPreAlliance, clientCompany, partnerCompany } = this.state.selectedAlliance;
    this.setState({ loading: true });
    if (isPreAlliance) {
      checkDataCvs(headers, dataCvs, clientCompany, partnerCompany);
    } else {
      checkDataCvs(headers, dataCvs, this.company, this.otherCompany);
    }
  };

  render() {
    const {
      loading,
      data,
      dealsCreated,
      dealImportedError,
      dealImportedHeaderError,
      allianceMembersOptions,
      dealOwner,
    } = this.state;

    if (!data.length) return <Redirect to={'/management/deal'} />;

    let content = (
      <div style={{ height: '80%' }}>
        <Loader stretch />
        <Label>This may take up to a minute... </Label>
        {dealsCreated > 1 ? <Label>{`${dealsCreated} deals Imported!`}</Label> : null}
      </div>
    );
    let footer = null;

    if (!loading) {
      content = (
        <>
          <Header text="Deals Information" />
          <table>
            <thead>
              <tr>
                <th>Alliance Deal Owner</th>
                {this.headers.map((header, i) => {
                  // Error Management
                  const addClassError =
                    dealImportedHeaderError.rows &&
                    dealImportedHeaderError.rows.find((ind) => ind === i);

                  return (
                    <th key={i}>
                      <DealHeader
                        options={this.dealFieldsOptions}
                        data={data}
                        header={header}
                        index={i}
                        addClassError={addClassError}
                      />
                    </th>
                  );
                })}
              </tr>
              <tr>
                <th className={'table-header-cell'} />
                {this.headers.map((header, i) => (
                  <th key={i} className={'table-header-cell'}>
                    {header}
                  </th>
                ))}
              </tr>
            </thead>

            <tbody>
              {this.data.map((row, i) => {
                const columns = this.headers.map((header) => row[header]);
                return (
                  <DealDataCSVRow
                    key={i}
                    index={i}
                    columns={columns}
                    dataCvs={this.data}
                    dealImportedError={dealImportedError}
                    allianceMembersOptions={allianceMembersOptions}
                    dealOwner={dealOwner}
                  />
                );
              })}
            </tbody>
          </table>
        </>
      );
      footer = (
        <Card.Footer>
          <ActionButton text={'Save'} onClick={this.onSubmit} />
        </Card.Footer>
      );
    }

    return (
      <React.Fragment>
        <Card.Header>
          <Heading type="h4" text={`Import Deals of: ${this.company.name}`} />
        </Card.Header>
        <Card.Body
          scrollable
          borderRadius="all"
          style={{ padding: 20, textAlign: 'center' }}
          className="card-body card-body-deals-import">
          {content}
        </Card.Body>
        {footer}
      </React.Fragment>
    );
  }
}

DealImportedView.propTypes = {
  history: PropTypes.object.isRequired,
};

export default withRouter(DealImportedView);
