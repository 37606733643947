import React from 'react';
import { Card, Heading, Loader, Grid, Row } from '@8base/boost';
import { CreateViewCardBody } from '../../../components/new-ui/card/CreateViewCardBody';

import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import * as toast from '../../../components/toast/Toast';
import * as R from 'ramda';
import { View } from '@cobuildlab/react-simple-state';
import { onChangeDataMixin, onErrorMixin } from '../../../shared/mixins';
import { ContributionModel, CONTRIBUTION_DOCUMENTS } from './contribution-model';
import ContributionForm from './components/ContributionForm';
import {
  autoSaveUpdateContribution,
  openComments,
  updateContribution,
} from './contribution-action';
import { fetchContribution } from './contribution-action';
import { fetchInitiativeList } from '../initiative/initiative-actions';
import { fetchCurrentAllianceMembersAction } from '../../settings/alliance-management/alliance-actions';
import { getCurrencyOnSession } from '../../../shared/alliance-utils';
import { ActionButton } from '../../../components/buttons/ActionButton';
import { fetchRelatedItems } from '../../related-item/related-item-actions';
import { getItemByType } from '../../../shared/items-util';
import { contributionValidator } from './contribution-validators';
import RelatedItemForm from '../../related-item/components/RelatedItemForm';
import { TransparentButton } from '../../../components/buttons/TransparentButton';
import ContributionDetailTable from './components/ContributionDetailTable';
import { RelatedItemsDetailTable } from '../../related-item/components/RelatedItemsDetailTable';
import { FormSteps } from '../../../components/dots/FormSteps';
import { initiativesItemValidator, initiativesApproved } from '../initiative/initiative-validators';
import { TopButtons } from '../../../components/buttons/TopButtons';
import { InitiativeListTable } from '../initiative/components/InitiativeListTable';
import { LeftProgressSection } from '../../../components/new-ui/LeftProgressSection';
import { SCREENS_CONTRIBUTION } from '../screenView';
import { BoxCard } from '../../../components/new-ui/div/BoxCard';
import { CardFooter } from '../../../components/new-ui/card/CardFooter';
import { CONTRIBUTION_COMPLETED } from '../../../shared/status';
import { TextDateAgo } from '../../../components/text/TextDateAgo';
import { OnAllianceMemberList } from '../../settings/alliance-management/alliance-events';
import { OnInitiativeList } from '../initiative/initiative-events';
import { OnRelatedItems } from '../../related-item/related-item-events';
import {
  OnContributionDetail,
  OnContributionError,
  OnContributionAutoSave,
  OnContributionUpdate,
} from './contribution-events';
// eslint-disable-next-line
/**
 * Contribution Edit View.
 *
 */
class ContributionEditView extends View {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        contributionData: R.clone(ContributionModel),
        relatedItems: [],
        initiatives: [],
      },
      loading: true,
      initiativesList: [],
      clientCompany: null,
      partnerCompany: null,
      step: 0,
      savedAt: null,
    };
    this.onError = onErrorMixin.bind(this);
    this.onChangeData = onChangeDataMixin.bind(this);
    this.originalContributionData = null;

    this.autoSaveTimer = null;
  }

  onChangeContributionData = (name, value) => {
    const { data } = this.state;
    data.contributionData[name] = value;
    this.autoSave();
    this.setState({ data });
  };

  componentDidMount() {
    this.subscribe(OnContributionError, this.onError);
    this.subscribe(OnAllianceMemberList, (state) => {
      this.setState({
        clientCompany: R.clone(state.clientCompany),
        partnerCompany: R.clone(state.partnerCompany),
      });
    });
    this.subscribe(OnContributionDetail, (state) => {
      const contributionData = R.clone(state.contribution);
      const { data } = this.state;

      contributionData.documents = contributionData.documents.items.map((document) => ({
        ...document,
      }));

      data.initiatives = contributionData.initiatives.items.map((initiative) => ({
        ...initiative,
      }));

      contributionData.initiatives = contributionData.initiatives.items.map((item) => item.id);

      contributionData.itemId = contributionData.itemContributionRelation.id;
      this.originalContributionData = R.clone(contributionData);
      data.contributionData = contributionData;
      console.log('data.initiatives', data);

      this.setState(
        {
          data,
          loading: false,
        },
        () => fetchRelatedItems(contributionData.itemContributionRelation.id),
      );
    });
    this.subscribe(OnContributionUpdate, () => {
      toast.success('Contribution Successfully Updated');
      this.props.history.goBack();
    });

    this.subscribe(OnRelatedItems, (state) => {
      const items = state.item.itemsRelated.items.map((item) => getItemByType(item));
      const { data } = this.state;
      data.relatedItems = items;
      this.setState({
        data,
      });
    });

    this.subscribe(OnInitiativeList, (state) => {
      const initiativesList = state.initiativesList.items.map((initiative) => ({
        ...initiative,
      }));

      this.setState(
        {
          initiativesList,
        },
        () => {
          const { match } = this.props;
          if (!match.params.id) return toast.error('Contribution ID missing');
          fetchContribution(match.params.id);
        },
      );
    });

    this.subscribe(OnContributionAutoSave, () => {
      this.setState({ savedAt: new Date() });
    });

    fetchInitiativeList('', 1, 1000);
    fetchCurrentAllianceMembersAction();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
    localStorage.removeItem(CONTRIBUTION_DOCUMENTS);
  }

  autoSave = () => {
    const waitTime = 10000; // Wait 10s
    clearTimeout(this.autoSaveTimer);
    this.autoSaveTimer = setTimeout(() => {
      const contributionData = R.clone(this.state.data.contributionData);
      const relatedItems = R.clone(this.state.data.relatedItems);
      const initiatives = R.clone(this.state.data.initiatives);
      autoSaveUpdateContribution(
        contributionData,
        relatedItems,
        initiatives,
        this.originalContributionData,
      );
    }, waitTime);
  };

  onSubmit = () => {
    this.setState({ loading: true }, () => {
      clearTimeout(this.autoSaveTimer);
      const contributionData = R.clone(this.state.data.contributionData);
      const relatedItems = R.clone(this.state.data.relatedItems);
      const initiatives = R.clone(this.state.data.initiatives);
      updateContribution(
        contributionData,
        relatedItems,
        initiatives,
        this.originalContributionData,
      );
    });
  };

  onContributionStepChange = (nextStep) => {
    const contributionData = R.clone(this.state.data.contributionData);
    try {
      contributionValidator(contributionData);
    } catch (e) {
      return this.onError(e);
    }
    this.onScreen(nextStep);
  };

  onScreen = (step) => {
    this.setState({ step });
  };

  onRelatedItemsStepChange = (step) => {
    const initiatives = R.clone(this.state.data.initiatives);
    try {
      initiativesItemValidator(initiatives);
      initiativesApproved(initiatives);
    } catch (e) {
      return this.onError(e);
    }
    this.onScreen(step);
  };

  render() {
    const {
      step,
      companyId,
      loading,
      initiativesList,
      clientCompany,
      partnerCompany,
      savedAt,
    } = this.state;
    let content = <Loader stretch />;
    let footer = <></>;
    const currency = getCurrencyOnSession();
    const { history } = this.props;
    const { contributionData, relatedItems, initiatives } = this.state.data;
    let buttonsTop = <></>;

    if (contributionData.status === CONTRIBUTION_COMPLETED)
      history.push(`/management/investment-item/`);

    if (!loading && step === 0) {
      content = (
        <div id={'c2gScreen'}>
          <ContributionForm
            data={contributionData}
            onChange={this.onChangeContributionData}
            myCompanyId={companyId}
            initiativesList={initiativesList}
            clientCompany={clientCompany}
            partnerCompany={partnerCompany}
            currency={currency}
          />
        </div>
      );
      footer = (
        <CardFooter>
          <ActionButton onClick={() => this.onContributionStepChange(1)} text="Next" />
        </CardFooter>
      );
    }

    if (!loading && step === 1) {
      const itemData = { id: contributionData.id, type: contributionData.__typename };

      content = (
        <div id={'c2gScreen'}>
          <RelatedItemForm
            relatedItems={relatedItems}
            initiatives={initiativesList}
            onChange={(key, value) => {
              this.autoSave();
              this.onChangeData(key, value);
            }}
            selectedInitiatives={initiatives}
            allowedDealOption={true}
            itemData={itemData}
          />
        </div>
      );

      footer = (
        <CardFooter>
          <ActionButton onClick={() => this.onRelatedItemsStepChange(2)} text={'Next'} />
          <TransparentButton onClick={() => this.onScreen(0)} text={'Previous'} />
        </CardFooter>
      );
    }

    if (!loading && step === 2) {
      const contributionDetail = R.clone(contributionData);

      contributionDetail.documents = { items: contributionDetail.documents };

      content = (
        <div id={'c2gScreen'}>
          <ContributionDetailTable data={contributionDetail} currency={currency} />
          <InitiativeListTable initiatives={initiatives} />
          <RelatedItemsDetailTable relatedItems={relatedItems} />
        </div>
      );
      footer = (
        <CardFooter>
          <ActionButton onClick={this.onSubmit} text={'Update Contribution'} />
          <TransparentButton onClick={() => this.onScreen(1)} text={'Previous'} />
        </CardFooter>
      );
    }

    if (!loading)
      buttonsTop = (
        <TopButtons
          onClickClosed={history.goBack}
          onClickCollaborated={() => openComments(contributionData)}
        />
      );

    return (
      <React.Fragment>
        <Card.Header>
          <Grid.Layout
            columns="400px auto 400px"
            areas={[['left', 'center', 'right']]}
            style={{ width: '100%' }}>
            <Grid.Box area="left">
              <Row>
                <Heading type="h4" text="Edit Contribution" />
                <TextDateAgo from={savedAt} />
              </Row>
            </Grid.Box>
            <Grid.Box area="center">
              <FormSteps totalSteps={3} step={step} />
            </Grid.Box>
            <Grid.Box area="right" />
          </Grid.Layout>
          {buttonsTop}
        </Card.Header>
        <CreateViewCardBody>
          <Grid.Layout columns="30% 70%" areas={[['left', 'right']]} style={{ width: '100%' }}>
            <Grid.Box area="left">
              <LeftProgressSection sections={SCREENS_CONTRIBUTION} currentScreen={step} />
            </Grid.Box>
            <BoxCard>
              <Grid.Box area="right">{content}</Grid.Box>
            </BoxCard>
          </Grid.Layout>
        </CreateViewCardBody>
        {footer}
      </React.Fragment>
    );
  }
}

ContributionEditView.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default withRouter(ContributionEditView);
