import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';

const HorizontalText = styled('div')`
  width: 90% !important;
  margin: 0 auto;
  opacity: 0.7 !important;
  color: #7e828b;
  font-family: Poppins;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0.5px;
  line-height: 16px;
`;

const HorizontalLine = ({ children }) => {
  return <HorizontalText>{children}</HorizontalText>;
};

HorizontalLine.propTypes = {
  children: PropTypes.any.isRequired,
};

export { HorizontalLine };
