export const COLUMNSEXCEL = [
  { header: 'ACTION_NAME', key: 'actionName', width: 32 },
  { header: 'ACTION_DESCRIPTION', key: 'actionDescription', width: 32 },
  { header: 'ACTION_STATUS', key: 'actionStatus', width: 32 },
  { header: 'ACTION_ORIGINAL_DUE_DATE', key: 'actionOriginalDueDate', width: 32 },
  { header: 'ACTION_REVISED_DUE_DATE', key: 'actionRevisedDueDate', width: 32 },
  { header: 'ACTION_UNIT_QUANTITY', key: 'actionUnitQuantity', width: 32 },
  { header: 'ACTION_UNIT_TYPE', key: 'actionUnitType', width: 32 },
  { header: 'ACTION_UNIT_VALUE_DESCRIPTION', key: 'actionUnitValueDescription', width: 32 },
  { header: 'ACTION_UNIT_MONETIZATION_FACTOR', key: 'actionUnitMonetizationFactor', width: 32 },
  { header: 'ACTION_BUSINESS_CASE_STATUS', key: 'businessStatus', width: 32 },
  { header: 'ACTION_BUSINESS_CASE_BACKGROUND_SUMMARY', key: 'backgroundSummary', width: 32 },
  {
    header: 'ACTION_BUSINESS_CASE_VISION_MISSION_STRATEGY',
    key: 'visionMissionStrategy',
    width: 32,
  },
  { header: 'ACTION_BUSINESS_CASE_MARKET_ANALISYS', key: 'marketAnalysis', width: 32 },
  {
    header: 'ACTION_BUSINESS_CASE_SALES_MARKETING_STRATEGY',
    key: 'salesMarketingStrategy',
    width: 32,
  },
  { header: 'ACTION_BUSINESS_CASE_RECOMMENDED_SOLUTION', key: 'recommendedSolution', width: 32 },
  { header: 'ACTION_BUSINESS_CASE_RISK', key: 'risks', width: 32 },
  { header: 'ACTION_BUSINESS_ANTICIPATED_COST', key: 'anticipatedCosts', width: 32 },
  { header: 'ACTION_BUSINESS_EXPECTED_COST', key: 'expectedCosts', width: 32 },
  { header: 'ACTION_BUSINESS_EXPECTED_REVENUED', key: 'expectedRevenues', width: 32 },
  { header: 'RISK_NAME', key: 'riskName', width: 32 },
  { header: 'RISK_DESCRIPTION', key: 'riskDescription', width: 32 },
  { header: 'RISK_STATUS', key: 'riskStatus', width: 32 },
  { header: 'RISK_REVISED_DUE_DATE', key: 'riskRevisedDueDate', width: 32 },
  { header: 'RISK_UNIT_QUANTITY', key: 'riskUnitQuantity', width: 32 },
  { header: 'RISK_UNIT_TYPE', key: 'riskUnitType', width: 32 },
  { header: 'RISK_UNIT_VALUE_DESCRIPTION', key: 'riskUnitValueDescription', width: 32 },
  { header: 'RISK_UNIT_MONETIZATION_FACTOR', key: 'riskUnitMonetizationFactor', width: 32 },
  { header: 'ISSUE_NAME', key: 'issueName', width: 32 },
  { header: 'ISSUE_DESCRIPTION', key: 'issueDescription', width: 32 },
  { header: 'ISSUE_STATUS', key: 'issueStatus', width: 32 },
  { header: 'ISSUE_ORIGINAL_DUE_DATE', key: 'issueOriginalDueDate', width: 32 },
  { header: 'ISSUE_REVISED_DUE_DATE', key: 'issueRevisedDueDate', width: 32 },
  { header: 'ISSUE_UNIT_TYPE', key: 'issueUnitType', width: 32 },
  { header: 'ISSUE_UNIT_QUANTITY', key: 'issueUnitQuantity', width: 32 },
  { header: 'ISSUE_UNIT_VALUE_DESCRIPTION', key: 'issueUnitValueDescription', width: 32 },
  { header: 'ISSUE_UNIT_MONETIZATION_FACTOR', key: 'issueUnitMonetizationFactor', width: 32 },
  { header: 'DECISION_NAME', key: 'decisionName', width: 32 },
  { header: 'DECISION_DESCRIPTION', key: 'decisionDescription', width: 32 },
  { header: 'DECISION_STATUS', key: 'decisionStatus', width: 32 },
  { header: 'DECISION_UNIT_TYPE', key: 'decisionUnitType', width: 32 },
  { header: 'DECISION_UNIT_QUANTITY', key: 'decisionUnitQuantity', width: 32 },
  { header: 'DECISION_UNIT_MONETIZATION_FACTOR', key: 'decisionUnitMonetizationFactor', width: 32 },
];

export const COLUMNSOBJECT = [
  {
    key: 'action',
    value: [
      { actionName: 'name' },
      { actionDescription: 'description' },
      { actionStatus: 'status' },
      { actionOriginalDueDate: 'originalDueDate' },
      { actionRevisedDueDate: 'revisedDueDate' },
      { actionUnitQuantity: 'unitQuantity' },
      { actionUnitType: 'unitType' },
      { actionUnitValueDescription: 'unitValueDescription' },
      { actionUnitMonetizationFactor: 'unitMonetizationFactor' },
      {
        businessCase: {
          businessStatus: 'status',
          backgroundSummary: 'backgroundSummary',
          visionMissionStrategy: 'visionMissionStrategy',
          marketAnalysis: 'marketAnalysis',
          salesMarketingStrategy: 'salesMarketingStrategy',
          recommendedSolution: 'recommendedSolution',
          anticipatedCosts: 'anticipatedCosts',
          expectedCosts: 'expectedCosts',
          expectedRevenues: 'expectedRevenues',
          risks: 'risks',
        },
      },
    ],
  },
  {
    key: 'risk',
    value: [
      { riskName: 'name' },
      { riskDescription: 'description' },
      { riskStatus: 'status' },
      { riskRevisedDueDate: 'revisedDueDate' },
      { riskUnitType: 'unitType' },
      { riskUnitQuantity: 'unitQuantity' },
      { riskUnitValueDescription: 'unitValueDescription' },
      { riskUnitMonetizationFactor: 'unitMonetizationFactor' },
    ],
  },
  {
    key: 'issue',
    value: [
      { issueName: 'name' },
      { issueDescription: 'description' },
      { issueStatus: 'status' },
      { issueOriginalDueDate: 'originalDueDate' },
      { issueRevisedDueDate: 'revisedDueDate' },
      { issueUnitType: 'unitType' },
      { issueUnitQuantity: 'unitQuantity' },
      { issueUnitValueDescription: 'unitValueDescription' },
      { issueUnitMonetizationFactor: 'unitMonetizationFactor' },
    ],
  },
  {
    key: 'decision',
    value: [
      { decisionName: 'name' },
      { decisionDescription: 'description' },
      { decisionStatus: 'status' },
      { decisionUnitType: 'unitType' },
      { decisionUnitQuantity: 'unitQuantity' },
      { decisionUnitMonetizationFactor: 'unitMonetizationFactor' },
    ],
  },
];
