import Rollbar from 'rollbar';

const { REACT_APP_ROLLBAR_ACCESS_TOKEN } = process.env;

const rollbar = new Rollbar({
  accessToken: REACT_APP_ROLLBAR_ACCESS_TOKEN,
  captureUncaught: true,
  captureUnhandledRejections: true,
});

/**
 * Report an error to rollbar.
 *
 * @param {Rollbar.LogArgument} error - Errors.
 * @returns {Rollbar.LogResult} LogResult.
 */
export function reportError(...error) {
  return rollbar.error(...error);
}
