import React from 'react';
import { Table } from '@8base/boost';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import Moment from 'react-moment';
import Status from '../../../../components/Status';
/**
 * List All the Alliances Invitations.
 */

const AllianceInvitationTable = (props) => {
  const { invitations, loading, children } = props;
  return (
    <Table>
      <Table.Header columns="3fr 3fr 2fr 3fr 3fr 60px">
        <Table.HeaderCell>Alliance Name</Table.HeaderCell>
        <Table.HeaderCell>E-Mail</Table.HeaderCell>
        <Table.HeaderCell>Status</Table.HeaderCell>
        <Table.HeaderCell>Date Invited</Table.HeaderCell>
        <Table.HeaderCell>Responded</Table.HeaderCell>
      </Table.Header>
      <Table.Body loading={loading} data={invitations}>
        {(invitation) => (
          <Table.BodyRow columns="3fr 3fr 2fr 3fr 3fr 60px" key={invitation.id}>
            <Table.BodyCell>
              {R.pathOr(
                <span style={{ color: 'lightgrey' }}>Not Available</span>,
                ['alliance', 'name'],
                invitation,
              )}
            </Table.BodyCell>
            <Table.BodyCell>
              {R.pathOr(
                <span style={{ color: 'lightgrey' }}>Not Available</span>,
                ['email'],
                invitation,
              )}
            </Table.BodyCell>
            <Table.BodyCell>
              <Status status={R.pathOr('Not Available', ['status'], invitation)} />
            </Table.BodyCell>
            <Table.BodyCell>
              <Moment format="MMMM Do, YYYY">
                {R.pathOr(
                  <span style={{ color: 'lightgrey' }}>Not Available</span>,
                  ['createdAt'],
                  invitation,
                )}
              </Moment>
            </Table.BodyCell>
            <Table.BodyCell>
              <Moment format="MMMM Do, YYYY">
                {R.pathOr(
                  <span style={{ color: 'lightgrey' }}>No Response</span>,
                  ['dateOfResponse'],
                  invitation,
                )}
              </Moment>
            </Table.BodyCell>
            <Table.BodyCell>{children(invitation)}</Table.BodyCell>
          </Table.BodyRow>
        )}
      </Table.Body>
    </Table>
  );
};

AllianceInvitationTable.defaultProps = {
  loading: false,
};

AllianceInvitationTable.propTypes = {
  invitations: PropTypes.array.isRequired,
  children: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

export default AllianceInvitationTable;
