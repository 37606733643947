import React from 'react';
import { Card, Grid, SecondaryNavigation } from '@8base/boost';
import { Redirect, Route, Switch } from 'react-router-dom';
import './css/settings.css';
/* Alliance */
import AllianceCreateView from './alliance-management/AllianceCreateView';
import AllianceEditView from './alliance-management/AllianceEditView';
import AllianceDetailView from './alliance-management/AllianceDetailView';
import AllianceListView from './alliance-management/AllianceListView';
import AllianceMembersView from './alliance-management/AllianceMembersView';
/* Company */
import CreateCompany from './company-management/CompanyCreateView';
import MyCompanyView from './company-management/MyCompanyView';
import EditCompany from './company-management/CompanyEditView';
import DetailCompany from './company-management/CompanyDetailView';
import CompanyMembersView from './company-management/CompanyMembersView';
import sessionStore, { NEW_SESSION_EVENT } from '../../shared/SessionStore';
import UserProfileView from './user-profile/UserProfileView';
import PropTypes from 'prop-types';
import { LinkItem } from '../../components/link/LinkItem';
import { COMPANY_PORTFOLIO_OWNER } from '../../shared/roles';
import CompanyListView from './company-management/CompanyListView';
/*Stage Mapping*/
import StageMapping from './stage-mapping/StageMappingView';
import AllianceInvitationListView from './invitations/AllianceInvitationListView';
import AllianceMemberInvitationListView from './invitations/AllianceMemberInvitationListView';
import { getFirstCompanyRoles } from '../../shared/alliance-utils';
import CompanyMemberInvitationListView from './invitations/CompanyMemberInvitationListView';
import { SalesforceSettingsView } from './salesforce/SalesforceSettingsView';
import { canImportSalesforce } from '../management/deal/deal-permissions';
import { Apollo } from '../../shared/apollo/Apollo';

const SettingsView = (props) => {
  const { user, selectedAlliance } = sessionStore.getState(NEW_SESSION_EVENT);
  const roles = getFirstCompanyRoles(user);

  const companyLabel =
    roles[0] === COMPANY_PORTFOLIO_OWNER ? 'Company Management' : 'Company Information';
  const salesforceImport = selectedAlliance && canImportSalesforce(selectedAlliance);

  const validated = roles.length > 0;
  const { location } = props;
  return (
    <Grid.Layout className="minHeightCard" columns="215px 1fr" id={'c2gScreen'}>
      <Grid.Box>
        <SecondaryNavigation>
          <LinkItem
            to={'/settings/company-management/company-list'}
            label={companyLabel}
            location={location}
            url={'company-management'}
          />
          {validated && (
            <LinkItem
              to={'/settings/alliance-management'}
              label={'Alliances'}
              location={location}
              url={'alliance-management'}
            />
          )}
          <LinkItem
            to={'/settings/user-profile'}
            label={'User Profile'}
            location={location}
            url={'user-profile'}
          />
          <LinkItem
            to={'/settings/stage-mapping'}
            label={'Stage Mapping'}
            location={location}
            url={'stage-mapping'}
          />
          {salesforceImport ? (
            <LinkItem
              to={'/settings/salesforce'}
              label={'Salesforce'}
              location={location}
              url={'salesforce'}
            />
          ) : null}
          <LinkItem
            to={'/settings/invitations/alliance-members-invitations'}
            label={'Alliance Members Invitations'}
            location={location}
            url={'alliance-members-invitations'}
          />
          <LinkItem
            to={'/settings/invitations/alliance-invitations'}
            label={'Alliance Invitations'}
            location={location}
            url={'alliance-invitations'}
          />
          <LinkItem
            to={'/settings/invitations/company-invitations'}
            label={'Company Invitations'}
            location={location}
            url={'company-invitations'}
          />
        </SecondaryNavigation>
      </Grid.Box>
      <Grid.Box>
        <Apollo>
          <Switch>
            {salesforceImport ? (
              <Route path="/settings/salesforce" component={SalesforceSettingsView} />
            ) : null}
            <Route
              render={() => (
                <Card stretch>
                  <Switch>
                    <Route
                      path="/settings/alliance-management/create"
                      component={AllianceCreateView}
                    />
                    <Route
                      path="/settings/alliance-management/edit/:id"
                      component={AllianceEditView}
                    />
                    <Route
                      path="/settings/alliance-management/members/:id"
                      component={AllianceMembersView}
                    />
                    <Route
                      path="/settings/alliance-management/:id"
                      component={AllianceDetailView}
                    />
                    <Route path="/settings/alliance-management" component={AllianceListView} />
                    {/* Company Management */}
                    <Route path="/settings/company-management/create" component={CreateCompany} />
                    <Route
                      path="/settings/company-management/my-company"
                      component={MyCompanyView}
                    />
                    <Route
                      path="/settings/company-management/company-list"
                      component={CompanyListView}
                    />
                    <Route path="/settings/company-management/edit/:id" component={EditCompany} />
                    <Route
                      path="/settings/company-management/members/:id"
                      component={CompanyMembersView}
                    />
                    <Route path="/settings/company-management/:id" component={DetailCompany} />

                    {/*Invitations*/}
                    <Route
                      path="/settings/invitations/alliance-members-invitations"
                      component={AllianceMemberInvitationListView}
                    />
                    <Route
                      path="/settings/invitations/alliance-invitations"
                      component={AllianceInvitationListView}
                    />
                    <Route
                      path="/settings/invitations/company-invitations"
                      component={CompanyMemberInvitationListView}
                    />
                    {/*Stage Mapping*/}
                    <Route path="/settings/stage-mapping" component={StageMapping} />
                    {/* User Profile */}
                    <Route exact path="/settings/user-profile/" component={UserProfileView} />

                    {validated ? (
                      <Redirect to="/settings/alliance-management" />
                    ) : (
                      <Redirect to="/settings/company-management/my-company" />
                    )}
                  </Switch>
                </Card>
              )}
            />
          </Switch>
        </Apollo>
      </Grid.Box>
    </Grid.Layout>
  );
};

SettingsView.propTypes = {
  location: PropTypes.any.isRequired,
};

export default SettingsView;
