import React from 'react';
import { Grid, Loader } from '@8base/boost';
import { CreateViewCardBody } from '../../../components/card/CreateViewCardBody';
import { CardFooter } from '../components/CardFooter';
import sessionStore, { NEW_SESSION_EVENT } from './../../../shared/SessionStore';
import { updateUserInformation, fetchUserInformation } from '../wizard.actions';
import PropTypes from 'prop-types';
import * as toast from '../../../components/toast/Toast';
import { View } from '@cobuildlab/react-simple-state';
import * as R from 'ramda';
import { Phone, PhoneType, UserInformation } from '../../auth/auth-models';
import { updateStateFromObject } from '../../../shared/utils';
import UserInformationForm from '../../auth/UserInformationForm';
import { onChangeMixin } from '../../../shared/mixins';
import { ActionButton } from '../../../components/buttons/ActionButton';
import { OnUserInformation, OnWizardError, OnUserUpdate } from '../wizard-events';

/**
 * The screen to edit my User Data.
 */
class UserInformationView extends View {
  constructor(props) {
    super(props);
    this.state = {
      data: R.clone(UserInformation),
      phoneTypes: [],
      countries: [],
      usaStates: [],
      loading: true,
    };
    this.originalPhones = [];
    const emptyPhone = R.clone(Phone);
    emptyPhone.type = R.clone(PhoneType);
    this.state.data.phones = [emptyPhone];
    this.user = sessionStore.getState(NEW_SESSION_EVENT).user;
    this.handleChange = this.handleChange.bind(this);
    this.uploadPicture = this.uploadPicture.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onChange = onChangeMixin.bind(this);
  }

  async componentDidMount() {
    this.subscribe(OnUserInformation, async (data) => {
      this.userInformation = R.clone(data.userInformationsList.items[0]);

      const phones = data.phonesList.items.map((phone) => ({
        ...phone,
      }));

      const phoneTypes = data.phoneTypesList.items.map((phoneType) => ({
        ...phoneType,
      }));

      const countries = data.countriesList.items.map((country) => ({
        ...country,
      }));

      const usaStates = data.statesList.items.map((usaState) => ({
        ...usaState,
      }));

      if (phones.length === 0) {
        const defaultPhoneType = phoneTypes.length ? phoneTypes[0] : Object.assign({}, PhoneType);
        phones.push({ phone: '', type: defaultPhoneType, id: null });
      }

      this.originalPhones = R.clone(phones);

      const country =
        this.userInformation.country === null ? null : this.userInformation.country.id;

      const userInformation = updateStateFromObject(this.state.data, this.userInformation);
      userInformation.firstName = this.user.firstName;
      userInformation.lastName = this.user.lastName;
      userInformation.avatar = this.user.avatar;
      userInformation.email = this.user.email;
      userInformation.weeklyEmailNotification = this.user.weeklyEmailNotification;
      userInformation.phones = phones;
      userInformation.country = country;

      this.setState({
        data: userInformation,
        phoneTypes,
        countries,
        usaStates,
        loading: false,
      });
    });

    this.subscribe(OnUserUpdate, () => {
      this.props.nextScreen();
    });

    this.subscribe(OnWizardError, (err) => {
      this.setState({
        loading: false,
      });
      toast.errors(err.arr ? err.arr : [err.message]);
    });
    // We fetch the system user first
    fetchUserInformation();
  }

  handleChange(value, e) {
    this.setState({ [e.target.name]: value });
  }

  uploadPicture() {
    this.setState({ isPictureUploaded: !this.state.isPictureUploaded });
  }

  handleSubmit() {
    if (this.state.loading) return;

    const phones = this.state.data.phones.filter((phone) => phone.phone !== '');
    const {
      firstName,
      lastName,
      city,
      country,
      state,
      zipCode,
      title,
      avatar,
      weeklyEmailNotification,
    } = this.state.data;
    const user = {
      avatar,
      firstName: firstName,
      lastName: lastName,
      id: this.user.id,
      weeklyEmailNotification,
    };
    const userInformation = {
      id: this.userInformation.id,
      country: country,
      city: city,
      state: state,
      zipCode: zipCode,
      title: title,
    };
    this.setState(
      {
        loading: true,
      },
      () => updateUserInformation(user, userInformation, phones, this.originalPhones),
    );
  }

  render() {
    const { loading, phoneTypes, data, countries, usaStates } = this.state;
    let content = (
      <>
        <Grid.Box />
        <Grid.Box>
          <Loader stretch />
        </Grid.Box>
        <Grid.Box />
      </>
    );

    if (!loading)
      content = (
        <UserInformationForm
          data={data}
          onChange={this.onChange}
          phoneTypes={phoneTypes}
          countries={countries}
          usaStates={usaStates}
        />
      );

    return (
      <React.Fragment>
        <CreateViewCardBody>{content}</CreateViewCardBody>
        <CardFooter>
          <ActionButton onClick={this.handleSubmit} text="Continue" />
        </CardFooter>
      </React.Fragment>
    );
  }
}

UserInformationView.propTypes = {
  layout: PropTypes.string.isRequired,
  currentScreen: PropTypes.number.isRequired,
  nextScreen: PropTypes.func.isRequired,
  prevScreen: PropTypes.func.isRequired,
};

export default UserInformationView;
