import React from 'react';
import { Bar, BarChart, Tooltip, XAxis, YAxis, CartesianGrid } from 'recharts';
import { Card, Row, Column, Loader, Heading, Grid, SelectField } from '@8base/boost';
import { View } from '@cobuildlab/react-simple-state';
import { ForecastReportCard } from './components/ForecastReportCard';
import moment from 'moment';
import sessionStore, { NEW_SESSION_EVENT } from '../../../shared/SessionStore';
import { onErrorMixin } from '../../../shared/mixins';
import { fetchForecastingData } from './forecasting-actions';
import { getAllianceForecastingData } from './forecasting-utils';
import { getCurrencyOnSession } from '../../../shared/alliance-utils';
import BigInt from 'big-integer';
import { showMonths } from '../../../shared/utils';
import { ForecastReportChartLegendItem } from './components/ForecastReportChartLegendItem';
import { getCompanyOptions } from './forecasting-utils';
import { ReportHeader } from '../components';
import * as R from 'ramda';
import { ForecastChartTooltip } from './components';
import { numberFormatter } from '../utils';
import { OnForecastingError, OnForecastingData } from './forecasting-events';

class ForecastingReportViewBookings extends View {
  constructor(props) {
    super(props);

    this.state = {
      alliance: null,
      selectedForecastYear: moment().year(),
      forecastYears: [],
      loading: false,
      reportForecasting: null,
      selectedCompany: null,
    };

    this.selectedAlliance = sessionStore.getState(NEW_SESSION_EVENT).selectedAlliance;
    this.onError = onErrorMixin.bind(this);
  }

  componentDidMount() {
    this.subscribe(OnForecastingError, this.onError);
    this.subscribe(OnForecastingData, async (state) => {
      const year = R.clone(this.state.selectedForecastYear);

      const allianceData = R.clone(state.alliance);

      const kpis = state.kpis.map((kpi) => ({
        ...kpi,
      }));

      const contributions = state.contributions.map((contribution) => ({
        ...contribution,
      }));

      const deals = state.deals.map((deal) => ({
        ...deal,
      }));

      const { reportForecasting, forecastYears } = await getAllianceForecastingData(
        kpis,
        contributions,
        deals,
        allianceData,
        year,
      );

      this.setState({ reportForecasting, forecastYears, loading: false, allianceData });
    });

    fetchForecastingData(R.clone(this.state.selectedForecastYear));
  }

  onSelectedCompany = (company) => {
    this.setState({
      selectedCompany: company,
    });
  };

  onSelectedYearChange = (year) => {
    this.setState({ selectedForecastYear: year, loading: true }, () => {
      fetchForecastingData(R.clone(this.state.selectedForecastYear));
    });
  };

  render() {
    const {
      loading,
      selectedCompany,
      selectedForecastYear,
      forecastYears,
      reportForecasting,
    } = this.state;
    let content = <Loader stretch />;
    let contentCard = '';
    let contentChartLegend = '';
    let contentBarChart = '';
    const currency = getCurrencyOnSession();
    const companyOptions = getCompanyOptions();

    if (!loading && reportForecasting) {
      const {
        // monthByMonth
        clientBookingsBudget,
        partnerBookingsBudget,
        clientBookingsActuals,
        partnerBookingsActuals,
        clientBookingsForecast,
        partnerBookingsForecast,
        // total
        totalClientBookingsBudget,
        totalPartnerBookingsBudget,
        totalClientBookingsActuals,
        totalPartnerBookingsActuals,
        totalClientBookingsForecast,
        totalPartnerBookingsForecast,
        clientBookingsVariance,
        partnerBookingsVariance,
        bothCompaniesBookingsVariance,
        bothCompaniesBookingsBudget,
        bothCompaniesBookingsActuals,
        bothCompaniesBookingsForecast,
      } = reportForecasting;

      const data = Array.from({ length: 12 }, (v, i) => {
        if (!selectedCompany) {
          return {
            month: showMonths(i),
            budget: BigInt(clientBookingsBudget[i])
              .add(partnerBookingsBudget[i])
              .toString(),
            totalBooking: BigInt(clientBookingsActuals[i])
              .add(partnerBookingsActuals[i])
              .toString(),
            totalBookingForecasting: BigInt(clientBookingsForecast[i])
              .add(partnerBookingsForecast[i])
              .toString(),
          };
        }

        if (selectedCompany === 'clientCompany') {
          return {
            month: showMonths(i),
            budget: clientBookingsBudget[i],
            clientBooking: clientBookingsActuals[i],
            clientBookingForecasting: clientBookingsForecast[i],
          };
        }

        if (selectedCompany === 'partnerCompany') {
          return {
            month: showMonths(i),
            budget: partnerBookingsBudget[i],
            partnerBooking: partnerBookingsActuals[i],
            partnerBookingForecasting: partnerBookingsForecast[i],
          };
        }
        return {};
      });

      if (!selectedCompany) {
        contentCard = (
          <>
            <ForecastReportCard
              header={'TOTAL - BUDGET'}
              amount={bothCompaniesBookingsBudget}
              currency={currency}
            />
            <ForecastReportCard
              header={'TOTAL - YTD ACTUAL'}
              amount={String(bothCompaniesBookingsActuals)}
              currency={currency}
            />

            <ForecastReportCard
              currency={currency}
              header={'TOTAL - FORECAST'}
              amount={bothCompaniesBookingsForecast}
            />
            <ForecastReportCard
              currency={currency}
              header={'VARIANCE'}
              amount={bothCompaniesBookingsVariance}
              isVariance={true}
            />
          </>
        );

        contentChartLegend = (
          <>
            <ForecastReportChartLegendItem name={'Budget'} />
            <ForecastReportChartLegendItem name={'Total Actuals'} color={'#70D34C'} />
            <ForecastReportChartLegendItem name={'Total Forecast'} color={'#0096B8'} />
          </>
        );

        contentBarChart = (
          <>
            <BarChart width={800} height={550} data={data}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="month" />
              <YAxis
                tickFormatter={(value) =>
                  currency ? `${currency.symbol} ${numberFormatter(value, 1)}` : value
                }
              />
              <Tooltip content={<ForecastChartTooltip currency={currency} />} />
              <Bar dataKey="budget" fill="#2BA1FF" barSize={36} />
              <Bar dataKey="totalBooking" fill="#70D34C" barSize={36} />
              <Bar dataKey="totalBookingForecasting" fill="#0096B8" barSize={36} />
            </BarChart>
          </>
        );
      }

      if (selectedCompany === 'clientCompany') {
        const { clientCompany } = this.selectedAlliance;
        const companyName = clientCompany ? clientCompany.name : 'Client';

        contentCard = (
          <>
            <ForecastReportCard
              header={`${companyName} - TOTAL BUDGET`}
              amount={totalClientBookingsBudget}
              currency={currency}
            />
            <ForecastReportCard
              header={`${companyName} - TOTAL YTD ACTUAL`}
              amount={totalClientBookingsActuals}
              currency={currency}
            />
            <ForecastReportCard
              currency={currency}
              header={`${companyName} - TOTAL FORECAST`}
              amount={totalClientBookingsForecast}
            />
            <ForecastReportCard
              currency={currency}
              header={`${companyName} - VARIANCE`}
              amount={clientBookingsVariance}
              isVariance={true}
            />
          </>
        );
        contentChartLegend = (
          <>
            <ForecastReportChartLegendItem name={'Budget'} />
            <ForecastReportChartLegendItem name={`${companyName} - Actuals`} color={'#70D34C'} />
            <ForecastReportChartLegendItem name={`${companyName} - Forecast`} color={'#0096B8'} />
          </>
        );

        contentBarChart = (
          <>
            <BarChart width={800} height={550} data={data}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="month" />
              <YAxis
                tickFormatter={(value) =>
                  currency ? `${currency.symbol} ${numberFormatter(value, 1)}` : value
                }
              />
              <Tooltip content={<ForecastChartTooltip currency={currency} />} />
              <Bar dataKey="budget" fill="#2BA1FF" barSize={36} />
              <Bar dataKey="clientBooking" fill="#70D34C" barSize={36} />
              <Bar dataKey="clientBookingForecasting" fill="#0096B8" barSize={36} />
            </BarChart>
          </>
        );
      }

      if (selectedCompany === 'partnerCompany') {
        const { partnerCompany } = this.selectedAlliance;
        const companyName = partnerCompany ? partnerCompany.name : 'Partner';

        contentCard = (
          <>
            <ForecastReportCard
              header={`${companyName} - TOTAL BUDGET`}
              amount={totalPartnerBookingsBudget}
              currency={currency}
            />
            <ForecastReportCard
              header={`${companyName} - TOTAL YTD ACTUAL`}
              amount={totalPartnerBookingsActuals}
              currency={currency}
            />
            <ForecastReportCard
              currency={currency}
              header={`${companyName} - TOTAL FORECAST`}
              amount={totalPartnerBookingsForecast}
            />
            <ForecastReportCard
              currency={currency}
              header={`${companyName} - VARIANCE`}
              amount={partnerBookingsVariance}
              isVariance={true}
            />
          </>
        );
        contentChartLegend = (
          <>
            <ForecastReportChartLegendItem name={'Budget'} />
            <ForecastReportChartLegendItem name={`${companyName} - Actuals`} color={'#70D34C'} />
            <ForecastReportChartLegendItem name={`${companyName} - Forecast`} color={'#0096B8'} />
          </>
        );

        contentBarChart = (
          <>
            <BarChart width={800} height={550} data={data}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="month" />
              <YAxis
                tickFormatter={(value) =>
                  currency ? `${currency.symbol} ${numberFormatter(value, 1)}` : value
                }
              />
              <Tooltip content={<ForecastChartTooltip currency={currency} />} />
              <Bar dataKey="budget" fill="#2BA1FF" barSize={36} />
              <Bar dataKey="partnerBooking" fill="#70D34C" barSize={36} />
              <Bar dataKey="partnerBookingForecasting" fill="#0096B8" barSize={36} />
            </BarChart>
          </>
        );
      }

      content = (
        <>
          <Row style={{ padding: '20px 16px' }}>
            {contentCard}
            {/*<ForecastReportCard*/}
            {/*  currency={currency}*/}
            {/*  header={'VARIANCE - FEBRUARY'}*/}
            {/*  color={'red'}*/}
            {/*  amount={'12,000'}*/}
            {/*/>*/}
          </Row>
          <Row style={{ borderTop: '1px solid #E7ECF1', padding: '20px' }}>
            <Column style={{ width: '80%' }}>{contentBarChart}</Column>
            <Column style={{ width: '20%' }}>
              <div
                style={{ width: '125px', height: '112px', marginTop: '35px', marginLeft: '10px' }}>
                {contentChartLegend}
                {/*RED #E54034*/}
              </div>
            </Column>
          </Row>
        </>
      );
    }

    return (
      <Card stretch>
        <ReportHeader>
          <Heading type="h4" text={'Report'} />
          <Grid.Box direction="row" justifySelf="end" alignSelf="center">
            {forecastYears.length ? (
              <SelectField
                style={{ width: '150px', alignSelf: 'end', marginRight: '15px' }}
                input={{
                  name: 'yearFilter',
                  value: selectedForecastYear,
                  onChange: (year) => this.onSelectedYearChange(year),
                }}
                placeholder={'Select a Year'}
                options={forecastYears.map((year) => {
                  return { label: year, value: year };
                })}
              />
            ) : (
              ''
            )}

            <SelectField
              style={{ width: '150px', alignSelf: 'end' }}
              options={companyOptions}
              input={{
                name: 'companyFilter',
                value: selectedCompany,
                onChange: (company) => this.onSelectedCompany(company),
              }}
            />
          </Grid.Box>
        </ReportHeader>
        <Card.Body style={{ padding: '0px' }}>{content}</Card.Body>
      </Card>
    );
  }
}

export { ForecastingReportViewBookings };
