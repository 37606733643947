import Flux from '@cobuildlab/flux-state';

/**
 * Event that triggers a Contribution error.
 *
 * @type {string}
 */
export const CONTRIBUTION_ERROR_EVENT = 'onContributionError';

/**
 * Event that triggers a Contribution Detail event.
 *
 * @type {string}
 */
export const CONTRIBUTION_DETAIL_EVENT = 'onContributionDetail';

/**
 * Event that triggers a Contribution Create event.
 *
 * @type {string}
 */
export const CONTRIBUTION_CREATE_EVENT = 'onContributionCreate';

/**
 * Event that triggers a Contribution Update event.
 *
 * @type {string}
 */
export const CONTRIBUTION_UPDATE_EVENT = 'onContributionUpdate';

/**
 * Event that triggers the fetch of the Contribution Form Data.
 *
 * @type {string}
 */
export const CONTRIBUTION_FORM_DATA_EVENT = 'onContributionFormData';

/**
 * Event that triggers the fetch of the Contribution Form Data.
 *
 * @type {string}
 */
export const CONTRIBUTION_COMPLETED_EVENT = 'onContributionCompleted';

/**
 * Event that triggers a Contribution Restore event.
 *
 * @type {string}
 */
export const CONTRIBUTION_RESTORE_EVENT = 'onContributionRestore';

/**
 * Event that triggers a CONTRIBUTION_AUTO_SAVE EVENT.
 *
 * @type {string}
 */
export const CONTRIBUTION_AUTO_SAVE_EVENT = 'CONTRIBUTION_AUTO_SAVE_EVENT';

/**
 * Event that triggers a CONTRIBUTION_AUTO_SAVE_ERROR_EVENT.
 *
 * @type {string}
 */
export const CONTRIBUTION_AUTO_SAVE_ERROR_EVENT = 'CONTRIBUTION_AUTO_SAVE_ERROR_EVENT';

/**
 * Hold the Investment Item Data.
 */
class ContributionStore extends Flux.DashStore {
  constructor() {
    super();
    this.addEvent(CONTRIBUTION_ERROR_EVENT);
    this.addEvent(CONTRIBUTION_CREATE_EVENT);
    this.addEvent(CONTRIBUTION_FORM_DATA_EVENT);
    this.addEvent(CONTRIBUTION_DETAIL_EVENT);
    this.addEvent(CONTRIBUTION_UPDATE_EVENT);
    this.addEvent(CONTRIBUTION_COMPLETED_EVENT);
    this.addEvent(CONTRIBUTION_RESTORE_EVENT);
    this.addEvent(CONTRIBUTION_AUTO_SAVE_EVENT);
    this.addEvent(CONTRIBUTION_AUTO_SAVE_ERROR_EVENT);
  }
}

export default new ContributionStore();
