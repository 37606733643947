import React from 'react';
import { Card, Grid, Heading, Loader, Row } from '@8base/boost';
import { CreateViewCardBody } from '../../../components/new-ui/card/CreateViewCardBody';
import InitiativeForm from './components/InitiativeForm';
import * as toast from '../../../components/toast/Toast';
import {
  autoSaveUpdateInitiative,
  fetchInitiative,
  getInitiativeRelationItem,
  openComments,
  updateInitiative,
} from './initiative-actions';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import InitiativeModel, {
  INITIATIVE_CURRENT_DASHBOARD_DOCUMENTS,
  INITIATIVE_DOCUMENTS,
} from './initiative-model';
import { View } from '@cobuildlab/react-simple-state';
import { onChangeDataMixin, onErrorMixin } from '../../../shared/mixins';
import * as R from 'ramda';
import BusinessCaseForm from '../../document-management/business-case/components/BusinessCaseForm';
import BusinessCaseModel, {
  BUSINESS_CASE_DOCUMENT,
} from '../../document-management/business-case/BusinessCase.model';
import InitiativeDetailTable from './components/InitiativeDetailTable';
import BusinessCaseDetailTable from '../../document-management/business-case/components/BusinessCaseDetailTable';
import { getCurrencyOnSession } from '../../../shared/alliance-utils';
import { FormSteps } from '../../../components/dots/FormSteps';
import { ActionButton } from '../../../components/buttons/ActionButton';
import { TransparentButton } from '../../../components/buttons/TransparentButton';
import { TransparentButtonSvg } from '../../../components/buttons/TransparentButtonSvg';
import collaborateIcon from '../../../images/icons/collab-chat-icon.svg';
import { ActionButtonClose } from '../../../components/buttons/ActionButtonClose';
import { initiativeValidator } from './initiative-validators';
import { businessCaseValidator } from '../../document-management/business-case/business-case-validators';
import { fetchCurrentAllianceMembersAction } from '../../settings/alliance-management/alliance-actions';
import { sanitizeRecommendedSolutionsToEdit } from '../../document-management/business-case/businessCases.actions';
import RelatedItemForm from '../../related-item/components/RelatedItemForm';
import { RelatedItemsDetailTable } from '../../related-item/components/RelatedItemsDetailTable';

import { LeftProgressSection } from '../../../components/new-ui/LeftProgressSection';
import { SCREENS_INITIATIVE } from '../screenView';
import { BoxCard } from '../../../components/new-ui/div/BoxCard';
import { CardFooter } from '../../../components/new-ui/card/CardFooter';
import { INITIATIVE_COMPLETED } from '../../../shared/status';
import { TextDateAgo } from '../../../components/text/TextDateAgo';
import {
  OnInitiativeError,
  OnInitiativeDetail,
  OnInitiativeAutoSaveCreate,
  OnInitiativeUpdate,
} from './initiative-events';
import { OnAllianceMemberList } from '../../settings/alliance-management/alliance-events';

class InitiativeEditView extends View {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        initiativeData: R.clone(InitiativeModel),
        businessCaseData: R.clone(BusinessCaseModel),
        relatedItems: [],
        initiatives: [],
      },
      clientCompany: null,
      partnerCompany: null,
      loading: true,
      step: 0,
      initiativesList: [],
    };

    this.onError = onErrorMixin.bind(this);
    this.originalRecommendedSolutions = [];
    this.originalInitiativeData = null;
    this.onChangeData = (name, value) => {
      this.autoSave();
      onChangeDataMixin.call(this, name, value);
    };
  }

  onChangeInitiativeData = (name, value) => {
    const { data } = this.state;
    data.initiativeData[name] = value;
    this.autoSave();
    this.setState({ data });
  };

  onChangeBusinessCaseData = (name, value) => {
    const { data } = this.state;
    data.businessCaseData[name] = value;
    this.autoSave();
    this.setState({ data });
  };

  componentDidMount() {
    const { match } = this.props;
    if (!match.params.id) return toast.error('Initiative ID missing');

    this.subscribe(OnInitiativeError, this.onError);

    this.subscribe(OnAllianceMemberList, (state) => {
      this.setState({
        clientCompany: R.clone(state.clientCompany),
        partnerCompany: R.clone(state.partnerCompany),
      });
    });

    this.subscribe(OnInitiativeUpdate, (state) => {
      toast.success('Initiative Successfully Updated');
      this.props.history.goBack();
    });

    this.subscribe(OnInitiativeDetail, (state) => {
      const initiativeData = R.clone(state.initiative);
      const businessCaseData = R.clone(initiativeData.businessCase);
      const { data } = this.state;

      this.originalRecommendedSolutions = sanitizeRecommendedSolutionsToEdit(businessCaseData);
      initiativeData.documentsFile = initiativeData.documentsFile.items.map((document) => ({
        ...document,
      }));
      data.initiativeData = initiativeData;
      data.businessCaseData = businessCaseData;
      data.relatedItems = getInitiativeRelationItem(R.clone(initiativeData));
      this.originalInitiativeData = R.clone(initiativeData);
      this.setState({ data, loading: false });
    });

    this.subscribe(OnInitiativeAutoSaveCreate, (initiative) => {
      this.setState({ savedAt: new Date() });
      this.savedInitiative = R.clone(initiative);
    });

    this.autoSaveTimer = null;

    const fetchPolicyOptions = { isCacheFirst: true };
    fetchInitiative(match.params.id, fetchPolicyOptions);
    fetchCurrentAllianceMembersAction();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
    localStorage.removeItem(INITIATIVE_DOCUMENTS);
    localStorage.removeItem(INITIATIVE_CURRENT_DASHBOARD_DOCUMENTS);
    localStorage.removeItem(BUSINESS_CASE_DOCUMENT);
  }

  autoSave = () => {
    const waitTime = 10000; // Wait 10s
    clearTimeout(this.autoSaveTimer);
    this.autoSaveTimer = setTimeout(() => {
      const initiativeData = R.clone(this.state.data.initiativeData);
      const businessCaseData = R.clone(this.state.data.businessCaseData);
      const relatedItems = R.clone(this.state.data.relatedItems);
      autoSaveUpdateInitiative(
        initiativeData,
        businessCaseData,
        relatedItems,
        this.originalRecommendedSolutions,
        this.originalInitiativeData,
      );
    }, waitTime);
  };

  onSubmit = () => {
    this.setState({ loading: true }, () => {
      clearTimeout(this.autoSaveTimer);
      const initiativeData = R.clone(this.state.data.initiativeData);
      const businessCaseData = R.clone(this.state.data.businessCaseData);
      const relatedItems = R.clone(this.state.data.relatedItems);
      updateInitiative(
        initiativeData,
        businessCaseData,
        relatedItems,
        this.originalRecommendedSolutions,
        this.originalInitiativeData,
      );
    });
  };

  onInitiativeStepChange = (step) => {
    const initiativeData = R.clone(this.state.data.initiativeData);
    try {
      initiativeValidator(initiativeData);
    } catch (e) {
      return this.onError(e);
    }
    this.onScreen(step);
  };

  onBusinessCaseStepChange = (step) => {
    const businessCaseData = R.clone(this.state.data.businessCaseData);
    try {
      businessCaseValidator(businessCaseData);
    } catch (e) {
      return this.onError(e);
    }
    this.onScreen(step);
  };

  onScreen = (step) => {
    this.setState({ step });
  };

  render() {
    const {
      data,
      step,
      loading,
      clientCompany,
      partnerCompany,
      initiativesList,
      savedAt,
    } = this.state;
    const { initiativeData, businessCaseData, relatedItems, initiatives } = data;
    const { history } = this.props;
    const currency = getCurrencyOnSession();
    let content = <Loader stretch />;
    let footer = <></>;
    //let headerText = '';
    let buttonsTop = <></>;

    if (initiativeData.status === INITIATIVE_COMPLETED) history.push(`/management/initiative/`);

    if (!loading && step === 0) {
      //headerText = 'Initiative Information';

      content = (
        <InitiativeForm
          data={initiativeData}
          onChange={this.onChangeInitiativeData}
          clientCompany={clientCompany}
          partnerCompany={partnerCompany}
        />
      );
      footer = (
        <CardFooter>
          <ActionButton onClick={() => this.onInitiativeStepChange(1)} text="Next" />
        </CardFooter>
      );
    }

    if (!loading && step === 1) {
      //headerText = 'Business Case Information';

      content = (
        <BusinessCaseForm
          data={businessCaseData}
          onChange={this.onChangeBusinessCaseData}
          currency={currency}
          hideExpectCostAvoidance
          showBusinessFields
        />
      );
      footer = (
        <CardFooter>
          <ActionButton onClick={() => this.onBusinessCaseStepChange(2)} text="Next" />
          <TransparentButton onClick={() => this.onScreen(0)} text="Previous" />
        </CardFooter>
      );
    }

    if (!loading && step === 2) {
      //headerText = 'Related Items';

      content = (
        <div id={'c2gScreen'}>
          <RelatedItemForm
            relatedItems={relatedItems}
            initiatives={initiativesList}
            onChange={(key, value) => {
              this.onChangeData(key, value);
            }}
            selectedInitiatives={initiatives}
            allowedDealOption={true}
          />
        </div>
      );

      footer = (
        <CardFooter>
          <ActionButton onClick={() => this.onScreen(3)} text={'Next'} />
          <TransparentButton onClick={() => this.onScreen(1)} text={'Previous'} />
        </CardFooter>
      );
    }

    if (!loading && step === 3) {
      //headerText = 'Preview Update Initiative';

      const initiativeDetail = R.clone(initiativeData);

      content = (
        <div id={'c2gScreen'}>
          <InitiativeDetailTable data={initiativeDetail} currency={currency} />
          <BusinessCaseDetailTable
            hideExpectedCostAvoidance
            data={businessCaseData}
            currency={currency}
          />
          <RelatedItemsDetailTable relatedItems={relatedItems} />
        </div>
      );
      footer = (
        <CardFooter>
          <ActionButton onClick={() => this.onSubmit()} text="Update Initiative" />
          <TransparentButton onClick={() => this.onScreen(2)} text="Previous" />
        </CardFooter>
      );
    }

    if (!loading)
      buttonsTop = (
        <div id={'c2gScreen'}>
          <div className="company-icons">
            <TransparentButtonSvg
              iconSvg={collaborateIcon}
              onClick={() => openComments(initiativeData)}
            />
            <ActionButtonClose onClick={history.goBack} />
          </div>
        </div>
      );

    return (
      <React.Fragment>
        <Card.Header>
          <Grid.Layout
            columns="400px auto 400px"
            areas={[['left', 'center', 'right']]}
            style={{ width: '100%' }}>
            <Grid.Box area="left">
              <Row>
                <Heading type="h4" text="Update Initiative" />
                <TextDateAgo from={savedAt} />
              </Row>
            </Grid.Box>
            <Grid.Box area="center">
              <FormSteps totalSteps={4} step={step} />
            </Grid.Box>
            <Grid.Box area="right" />
          </Grid.Layout>
          {buttonsTop}
        </Card.Header>
        <CreateViewCardBody>
          <Grid.Layout columns="30% 70%" areas={[['left', 'right']]} style={{ width: '100%' }}>
            <Grid.Box area="left">
              <LeftProgressSection sections={SCREENS_INITIATIVE} currentScreen={step} />
            </Grid.Box>
            <BoxCard>
              <Grid.Box area="right">{content}</Grid.Box>
            </BoxCard>
          </Grid.Layout>
        </CreateViewCardBody>
        {footer}
      </React.Fragment>
    );
  }
}

InitiativeEditView.propTypes = {
  history: PropTypes.object.isRequired,
};

export default withRouter(InitiativeEditView);
