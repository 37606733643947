import {
  BASIC_USER_QUERY,
  INVITATIONS_QUERY,
  SESSION_QUERY,
  USER_QUERY,
  USERS_AND_ROLES_QUERY,
} from './queries';
import Flux from '@cobuildlab/flux-state';

import sessionStore, {
  APOLLO_CLIENT,
  INVITATIONS_EVENT,
  INVITATIONS_USERS_EVENT,
  NEW_SESSION_EVENT,
  SESSION_ERROR,
  USER_AND_ROLES_EVENT,
  USER_BASIC_EVENT,
} from '../../shared/SessionStore';
import { error, log } from '@cobuildlab/pure-logger';
import { checkStatus } from '../../shared/fetch';
import { isAllianceActive } from '../procurer/procurer-utils';
import { updateUser } from '../wizard/wizard.actions';
import { fetchAlliancesBigList } from '../settings/alliance-management/alliance-actions';
import * as jwtToken from 'jwt-decode';
import { OnInvitations, OnSessionError, OnNewSession } from '../../shared/session-events';

const { REACT_APP_8BASE_API_ENDPOINT } = process.env;

/**
 * Fetches The logged user invitations, both Company and Alliance, and The User Data as well.
 *
 * @returns {object} - The User.
 */
export const fetchUserWithInvitations = async () => {
  let response;
  try {
    response = {
      user: await fetchUser(),
      invitations: await fetchInvitations(),
    };
  } catch (e) {
    error('fetchUserWithInvitations', e);
    OnSessionError.dispatch(e);
    return Flux.dispatchEvent(SESSION_ERROR, e);
  }
  log('fetchUserWithInvitations', response);
  Flux.dispatchEvent(INVITATIONS_USERS_EVENT, response);
  return response;
};

/**
 * Filter invitations by alliance and by user's email.
 *
 * @param  {string} email - Email.
 * @param  {string} allianceId - Alliance Id.
 * @returns {{OR: [{email: {equals: *}}]}} Filter.
 */
const invitationsFilter = (email, allianceId) => {
  const OR = [{ email: { equals: email } }];

  if (allianceId) OR.push({ alliance: { id: { equals: allianceId } } });

  const invitationsFilter = { OR };

  return invitationsFilter;
};

/**
 * Fetches The logged user invitations, both Company and Alliance, and Alliance
 * member.
 *
 * @returns {Promise<void>}Promise.
 */
export const fetchInvitations = async () => {
  const client = sessionStore.getState(APOLLO_CLIENT);
  const { user, selectedAlliance } = sessionStore.getState(NEW_SESSION_EVENT);
  const allianceId = selectedAlliance ? selectedAlliance.id : null;

  const filter = invitationsFilter(user.email, allianceId);
  const companyInvitationFilter = invitationsFilter(user.email);

  const variables = {
    companyInvitationFilter,
    allianceMemberInvitationFilter: filter,
    allianceData: filter,
  };
  let response;
  try {
    response = await client.query({
      query: INVITATIONS_QUERY,
      fetchPolicy: 'network-only',
      variables,
    });
  } catch (e) {
    error('fetchInvitations', e);
    OnSessionError.dispatch(e);
    return Flux.dispatchEvent(SESSION_ERROR, e);
  }
  log('fetchInvitations', response.data);
  OnInvitations.dispatch(response.data);
  Flux.dispatchEvent(INVITATIONS_EVENT, response.data);
  return response.data;
};

/**
 * Fetches a User Profile by Id or the logged User if no Id provided.
 *
 * @param {string}id - User Id.
 * @returns {Promise<void>}Promise.
 */
export const fetchUser = async (id) => {
  const client = sessionStore.getState(APOLLO_CLIENT);

  let response;
  try {
    response = await client.query({
      query: USER_QUERY,
      fetchPolicy: 'network-only',
      variables: { id },
    });
  } catch (e) {
    error('fetchUser', e);
    throw e;
  }
  log('fetchUser', response.data.user);
  return response.data.user;
};

export const fetchBasicUser = async (id) => {
  const client = sessionStore.getState(APOLLO_CLIENT);

  let response;
  try {
    response = await client.query({
      query: BASIC_USER_QUERY,
      fetchPolicy: 'network-only',
      variables: { id },
    });
  } catch (e) {
    error('fetchUser', e);
    throw e;
  }
  console.log('fetchUser', response);
  return response.data.user;
};

// export const createUserWithToken = async (token: string) => {
//   const { email } = jwtToken(token);
//
//   // const client = new ApolloClient({
//   //   uri: REACT_APP_8BASE_API_ENDPOINT,
//   // });
//   const client = sessionStore.getState(APOLLO_CLIENT);
//   const data = {
//     user: { email },
//     authId: REACT_APP_AUTH_PROVIDER_ID,
//   };
//
//   let response;
//   try {
//     response = await client.mutate({
//       mutation: CREATE_USER_QUERY,
//       variables: data,
//     });
//   } catch (e) {
//     error('createUserWithToken', e);
//     return Flux.dispatchEvent(SESSION_ERROR, e);
//   }
//   log('createUserWithToken', response.data);
//   Flux.dispatchEvent(NEW_USER_EVENT, response.data);
//   return response.data;
// };

const getActiveUserEmail = async (auth) => {
  let userEmail;
  let basicUser = sessionStore.getState(USER_BASIC_EVENT);
  const lastSession = sessionStore.getState(NEW_SESSION_EVENT);

  if (!basicUser && lastSession) {
    basicUser = lastSession.user;
  }

  if (basicUser && basicUser.email) {
    userEmail = basicUser.email;
  }

  if (!userEmail && auth) {
    let token = auth && auth.authState.token;
    if (!token && localStorage.auth) {
      try {
        ({ token } = JSON.parse(localStorage.auth));
      } catch (error) {
        console.log('parse-token-error:', error);
        token = null;
      }
    }

    if (token) {
      try {
        const jwtData = jwtToken(token);
        userEmail = jwtData.email;
      } catch (error) {
        console.log('jwtToken:error', error);
      }
    }
  }

  if (!userEmail) {
    try {
      basicUser = await fetchBasicUser();
      userEmail = basicUser.email;
    } catch (e) {
      error('fetchSession', e);
      OnSessionError.dispatch(e);
      Flux.dispatchEvent(SESSION_ERROR, e);
      throw e;
    }
  }

  return userEmail;
};

/**
 * Fetches the logged User Profile with invitations and Approved Alliances.
 *
 * @returns {Promise<void>} Promise.
 * @param {object} props - Function Props.
 */
export const fetchSession = async (props) => {
  const client = sessionStore.getState(APOLLO_CLIENT);

  const auth = props ? props.auth : null;
  const userEmail = await getActiveUserEmail(auth);

  let response;
  try {
    response = await client.query({
      query: SESSION_QUERY,
      fetchPolicy: 'network-only',
      variables: {
        allianceData: { email: { equals: userEmail } },
        allianceMemberInvitationFilter: { email: { equals: userEmail } },
        companyInvitationFilter: { email: { equals: userEmail } },
        CompanyFilter: {
          companyUserRelation: {
            some: { user: { email: { equals: userEmail } } },
          },
        },
      },
    });
  } catch (e) {
    error('fetchSession', e);
    // return Flux.dispatchEvent(SESSION_ERROR, e);
    return;
  }

  const { user, companiesList } = response.data;

  const userAlliances = new Set();

  user.companyUserRelation.items.forEach((companyUser) => {
    companyUser.company =
      companiesList.items.find((_company) => _company.id === companyUser.company.id) ||
      companyUser.company;

    companyUser.allianceUserCompanyUserRelation.items.forEach((allianceUser) => {
      if (allianceUser.alliance) userAlliances.add(allianceUser.alliance.id);
    });
  });

  let selectedAlliance = user.selectedAlliance;

  if (!selectedAlliance) {
    selectedAlliance = await determinateUserSelectedAlliance(user);
    user.selectedAlliance = selectedAlliance;
  }

  const session = {
    ...response.data,
    alliancesList: { count: userAlliances.size },
    selectedAlliance,
  };
  log('fetchSession', session);
  OnNewSession.dispatch(session);
  Flux.dispatchEvent(NEW_SESSION_EVENT, session);
  return session;
};

const determinateUserSelectedAlliance = async (user) => {
  const alliances = await fetchAlliancesBigList(user.id);
  console.log('determinateUserSelectedAlliance:alliances', alliances);

  const activeAlliances = alliances.filter((alliance) => isAllianceActive(alliance));

  const selectedAlliance = activeAlliances[0];

  if (selectedAlliance) {
    await updateUser({
      id: user.id,
      selectedAllianceId: selectedAlliance.id,
      selectedAlliance: { connect: { id: selectedAlliance.id } },
    });

    return selectedAlliance;
  }

  return null;
};

/**
 * Fetches all Application Users and Roles.
 *
 * @returns {Promise<void>}Promise.
 */
export const fetchUsersAndRoles = async () => {
  const client = sessionStore.getState(APOLLO_CLIENT);
  let response;
  try {
    response = await client.query({ query: USERS_AND_ROLES_QUERY });
  } catch (e) {
    error('fetchUsersAndRoles', e);
    OnSessionError.dispatch(e);
    return Flux.dispatchEvent(SESSION_ERROR, e);
  }
  log('fetchUsersAndRoles', response.data);
  Flux.dispatchEvent(USER_AND_ROLES_EVENT, response.data);
  return response.data;
};

/**
 * Logout function.
 *
 * @param {object} props - Component props.
 */
export const logout = async (props) => {
  const { auth, client, location } = props;
  let isEmailVerified = !!auth.isEmailVerified;
  let returnTo = `${window.location.origin.toString()}/auth`;

  if (!isEmailVerified && location && location.search.length > 0) {
    const queryParams = new URLSearchParams(location.search);
    // These variables indicate if the email was verified
    isEmailVerified = queryParams.get('supportSignUp') && queryParams.get('success');
    returnTo = isEmailVerified ? `${returnTo}?isEmailVerified=${isEmailVerified}` : returnTo;
  }

  await client.clearStore();

  auth.authClient.logout({
    returnTo,
  });
};

/**
 * Sends a Verification email to a User.
 *
 * @param {string} userId - The User Id in Auth0.
 */
export const sendVerificationEmail = async (userId) => {
  console.log('sendVerificationEmail:', userId);
  const response = await fetch(`${REACT_APP_8BASE_API_ENDPOINT}/webhook/verificationEmail`, {
    body: JSON.stringify({ userId }),
    method: 'POST',
  });

  await checkStatus(response);
};

export const getSelectedCompany = () => {
  const { user, selectedAlliance } = sessionStore.getState(NEW_SESSION_EVENT);
  if (selectedAlliance) {
    const { clientCompany, partnerCompany } = selectedAlliance;

    return [clientCompany, partnerCompany].find((company) => {
      return (
        company &&
        company.companyUserRelation.items.some(
          (companyUser) => companyUser.user && companyUser.user.id === user.id,
        )
      );
    });
  }

  return null;
};
