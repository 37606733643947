import React from 'react';
import { Card, Grid, SecondaryNavigation } from '@8base/boost';
import { Route, Switch, Redirect } from 'react-router-dom';
import '../../css/settings.css';
import { LinkItem } from '../../../../components/link/LinkItem';
import PropTypes from 'prop-types';
import AllianceMembersList from './AllianceMembersList';

/**
 * Menu view.
 *
 * @param {any}props - Props.
 * @returns {*} - List Menu items.
 */
const AllianceMembersListView = (props) => {
  const { location } = props;
  return (
    <Grid.Layout columns="215px 1fr">
      <Grid.Box>
        <SecondaryNavigation>
          <LinkItem
            to={'/members/alliance-members'}
            label={'Alliance Members'}
            location={location}
            url={'alliance-members'}
          />
        </SecondaryNavigation>
      </Grid.Box>
      <Grid.Box>
        <Card stretch>
          <Switch>
            <Route path="/members/alliance-members" component={AllianceMembersList} />
            <Redirect to="/members/alliance-members" />
          </Switch>
        </Card>
      </Grid.Box>
    </Grid.Layout>
  );
};

AllianceMembersListView.propTypes = {
  location: PropTypes.any.isRequired,
};

export default AllianceMembersListView;
