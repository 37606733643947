import { ERRORS_TEXT } from '../reports-model';

const validateReportsConfig = ({ filters }) => {
  const errors = {};

  if (filters && filters.length > 0) {
    errors.filters = [];
    filters.forEach((filter, index) => {
      const { fieldId, rule, cond } = filter;
      let error = {};

      if (!fieldId) {
        error.fieldId = ERRORS_TEXT.required;
      }

      if (fieldId && fieldId !== 'id-2' && !cond) {
        error.cond = ERRORS_TEXT.required;
      }

      if (fieldId === 'id-2') {
        if (!rule) {
          error.rule = ERRORS_TEXT.required;
        } else {
          switch (rule) {
          case 'quarter': {
            if (cond && Array.isArray(cond)) {
              const [year, month] = cond;
              if (Number.isNaN(+year) || Number.isNaN(+month)) {
                error.cond = ERRORS_TEXT.incorrect;
              }
              if (!Number.isNaN(+month) && month > 4) {
                error.cond = ERRORS_TEXT.incorrect;
              }
            } else {
              error.cond = ERRORS_TEXT.required;
            }
            break;
          }
          case 'month': {
            if (!cond) {
              error.cond = ERRORS_TEXT.required;
            }
            break;
          }
          case 'reange':
          case 'monthSet': {
            if (!cond) {
              error.cond = [ERRORS_TEXT.required, ERRORS_TEXT.required];
            } else {
              const monthSetError = [];
              const [firstDate, secondDate] = cond;
              if (!firstDate) {
                monthSetError[0] = ERRORS_TEXT.required;
              }
              if (!secondDate) {
                monthSetError[1] = ERRORS_TEXT.required;
              }
              error.cond = monthSetError;
            }
            break;
          }
          case 'year': {
            if (!cond) {
              error.cond = ERRORS_TEXT.required;
            } else {
              if (cond < 0 || String(cond).length !== 4) {
                error.cond = ERRORS_TEXT.incorrect;
              }
            }
            break;
          }
          default: {
            break;
          }
          }
        }
      }
      errors.filters[index] = error;
    });
  }

  return errors;
};

export { validateReportsConfig };
