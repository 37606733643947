import React from 'react';
import PropTypes from 'prop-types';
import { Dialog } from '@8base/boost';
import { ActionButton } from '../buttons/ActionButton';
import { TransparentButton } from '../buttons/TransparentButton';

const DialogForm = ({
  title,
  isOpen,
  createText,
  children,
  onCloseDialog,
  onClickCreate,
  loading,
  size,
  cancelText,
}) => {
  return (
    <Dialog size={size} isOpen={isOpen}>
      <Dialog.Header title={title} onClose={onCloseDialog} />
      <Dialog.Body>{children}</Dialog.Body>
      <Dialog.Footer>
        <TransparentButton
          data-dialog-form-cancel-btn
          onClick={onCloseDialog}
          text={cancelText}
          name="skip"
        />
        <ActionButton
          text={createText}
          name={createText}
          loading={loading}
          onClick={onClickCreate}
        />
      </Dialog.Footer>
    </Dialog>
  );
};

DialogForm.defaultProps = {
  size: 'lg',
  createText: 'Create',
  cancelText: 'Cancel',
  loading: false,
};

DialogForm.propTypes = {
  size: PropTypes.string,
  createText: PropTypes.string,
  cancelText: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  onClickCreate: PropTypes.func.isRequired,
  onCloseDialog: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

export { DialogForm };
