import { error } from '@cobuildlab/pure-logger';
import * as toast from '../components/toast/Toast';
import { canLoadPageOnScroll } from './utils';
import { getMessageFromGraphQLError } from '@cobuildlab/8base-utils';

/**
 * The onChange method for forms.
 *
 * @param {string}name - The name of the property on the state.
 * @param {any} value - The value of the property.
 */
export function onChangeMixin(name, value) {
  const data = this.state.data;
  data[name] = value;
  this.setState({ data });
}

/**
 * The onChange 'data' method for forms.
 *
 * @param {string}name - The name of the property on the state.
 * @param {any} value - The value of the property.
 */
export function onChangeDataMixin(name, value) {
  const { data } = this.state;
  data[name] = value;
  this.setState({ data });
}

/**
 * The onError method for View, shows the error on a Toast.
 *
 * @param {Error|Error[]}err - The error or Errors for the form.
 */
export function onErrorMixin(err) {
  error(err);
  if (Array.isArray(err.arr)) {
    toast.errors(err.arr);
  } else if (err.message.includes('GraphQL error')) {
    const message = getMessageFromGraphQLError(err);
    const messageCleaned = message.replace('GraphQL error:', '').trim();
    toast.error(messageCleaned);
  } else {
    toast.error(err.message);
  }

  this.setState({ loading: false, loadingPage: false });
}

/**
 * This on Change BusinessCase Data Mixin.
 *
 * @param {string}name - The name of the property on the state.
 * @param {any} value - The value of the property.
 */
export function onChangeBusinessCaseDataMixin(name, value) {
  const { data } = this.state;
  data.businessCaseData[name] = value;
  this.setState({ data });
}

/**
 * Fetch the items next page on scroll event if the canLoadPageOnScroll returns true.
 *
 * @param  {event} event - OnScroll event.
 * @param  {any[]} items - The array of items.
 * @param  {Function} fetchItemsAction -  The method to load the items.
 */
export function onListScrollMixin(event, items, fetchItemsAction) {
  const { count, loadingPage, search, page, filter, itemFilter } = this.state;
  const canLoadPage = canLoadPageOnScroll(event, items, count, loadingPage);

  if (canLoadPage) {
    const nextPage = page + 1;
    this.setState({ loadingPage: true, page: nextPage }, () => {
      fetchItemsAction(search, nextPage, 20, filter, itemFilter);
    });
  }
}
