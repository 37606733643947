import { IDEA_APPROVAL_APPROVED, INITIATIVE_APPROVAL_APPROVED } from '../../../shared/status';
import {
  CUSTOMERS_SATISFACTION_SCORE_UNIT_TYPE,
  TRAINING_UNIT_TYPE,
} from '../../../shared/item-types';
import {
  allianceKPIsYearModel,
  BOOKINGS_CLIENT_TYPE,
  BOOKINGS_PARTNER_TYPE,
  CONTRIBUTIONS_CLIENT_TYPE,
  CONTRIBUTIONS_PARTNER_TYPE,
  CUSTOMER_SATISFACTION_SCORE_TYPE,
  JOINT_INITIATIVES_TYPE,
  JOINT_INNOVATION_IDEAS_TYPE,
  JOINT_PROPOSALS_ISSUED_TYPE,
  JOINT_PROPOSALS_WON_TYPE,
  JOINT_REFERENCEABLE_CLIENTS_TYPE,
  JOINT_SALES_CALLS_TYPE,
  JOINT_TRAINING_INVESTMENT_TYPE,
  KPI_WITHOUT_CURRENCY_TYPES,
  OPPORTUNITY_WIN_RATIO_TYPE,
  PERIODICITY_TYPE,
  PIPELINE_CLIENT_TYPE,
  PIPELINE_PARTNER_TYPE,
  PROFESSIONALS_TRAINED_TYPE,
  PROFESSIONALS_CERTIFIED_TYPE,
} from '../../settings/alliance-management/allianceKPIs/allianceKPIs-model';
import {
  DEAL_ACTIVE_STAGES,
  DEAL_STAGE_CLOSED_WON,
  PROPOSAL_ISSUED_STAGES,
} from '../../management/deal/deal-model';
import {
  calculateValueBigInt,
  getAllowedDecimalsForKPI,
  isCurrencyTypeKPI,
  isPercentageTypeKPI,
} from '../../../shared/utils';
import { GREEN_COLOR, RED_COLOR, YELLOW_COLOR } from '../../../shared/colors';
import * as R from 'ramda';
import numbro from 'numbro';
import moment from 'moment';
import BigInt from 'big-integer';
import { getKPIYears } from '../../settings/alliance-management/allianceKPIs/allianceKPIs-actions';
import { DEFAULT_MONTHS_RANGE, NUMBER_T } from './balanced-scorecard-model';
import { chartsMonths } from '../utils';

/**
 * Thousand separator for bigInt values only, does not support decimals.
 *
 * @param  {bigint} bigIntValue - The bigInt value.
 * @returns {string} Thousand separated bigint as string.
 */
export const bigIntThousandSeparator = (bigIntValue) => {
  if (bigIntValue === null) return;

  return (
    bigIntValue
      .toString() // transform the number to string
      .split('') // transform the string to array with every digit becoming an element in the array
      .reverse() // reverse the array so that we can start process the number from the least digit
      .map((digit, index) => (index !== 0 && index % 3 === 0 ? `${digit},` : digit)) // map every digit from the array.
      // If the index is a multiple of 3 and it's not the least digit,
      // that is the place we insert the comma behind.
      .reverse() // reverse back the array so that the digits are sorted in correctly display order
      .join('')
  ); // transform the array back to the string
};

/**
 * Formats the bigInt value to average then unformat to get the average bigint.
 *
 * @param  {string} bigIntValue - BigInt value to get the average.
 * @returns {string} The average value of the bigIntValue.
 */
export const roundBigInt = (bigIntValue) => {
  let value = bigIntValue;

  if (typeof value !== 'string') value = value.toString();
  const isDecimal = value.includes('.');

  if (isDecimal) {
    const [whole, decimal] = value.split('.');
    value = BigInt(whole);
    if (Math.round(parseFloat(decimal))) value = value.add(1);
    value = value.toString();
  }

  const averageNumber = numbro(value).format({
    mantissa: 2,
    average: true,
    optionalMantissa: true,
  });

  // For big numbers replace t with NUMBER_T to avoid exponential values
  return averageNumber.includes('t')
    ? averageNumber.replace('t', NUMBER_T)
    : numbro.unformat(averageNumber).toFixed();
};

/**
 * Filter deals by monthsRange.
 *
 * @param  {string} closeDate - The closeDate to filter.
 * @param  {Array} monthsRange - Months range to filter.
 * @returns {Array} Filtered array.
 */
const monthsRangeDealsFilter = (closeDate, monthsRange) => {
  const { startMonth, endMonth } = getStartEndAndTotalMonths(monthsRange);

  const closeDateMonth = closeDate ? moment(closeDate).month() + 1 : null;
  return closeDateMonth >= startMonth && closeDateMonth <= endMonth;
};

/**
 * Filter contributions by monthsRange.
 *
 * @param  {string} contributionDate - The contributionDate to filter.
 * @param  {Array} monthsRange - Months range to filter.
 * @returns {Array} Filtered array.
 */
const monthsRangeContributionsFilter = (contributionDate, monthsRange) => {
  const { startMonth, endMonth } = getStartEndAndTotalMonths(monthsRange);
  const contributionDateMonth = contributionDate ? moment(contributionDate).month() + 1 : null;
  return contributionDateMonth >= startMonth && contributionDateMonth <= endMonth;
};

/**
 * Filter Items by approvedDate (any item or initiative with approvedDate).
 *
 * @param  {string} approvedDate - The approvedDate to filter.
 * @param  {Array} monthsRange - Months range to filter.
 * @returns {Array} Filtered array.
 */
const approvedItemsFilter = (approvedDate, monthsRange) => {
  const { startMonth, endMonth } = getStartEndAndTotalMonths(monthsRange);
  const approvedDateMonth = approvedDate ? moment(approvedDate).month() + 1 : null;
  return approvedDateMonth >= startMonth && approvedDateMonth <= endMonth;
};

/**
 * To get the month for the selected kpi year for fullYear & proRata.
 *
 * @param {number} year - The kpi selected year.
 * @param  {Array} monthsRange - The months range.
 * @returns {number} The actual month.
 */
export const getActualKPIMonth = (year, monthsRange = DEFAULT_MONTHS_RANGE) => {
  const { startMonth, endMonth } = getStartEndAndTotalMonths(monthsRange);
  const currentYear = moment().year();
  if (year < currentYear) return endMonth;
  if (year > currentYear) return startMonth;

  let actualKPIMonth = moment().month() + 1;
  if (actualKPIMonth > endMonth) actualKPIMonth = endMonth;
  if (actualKPIMonth < startMonth) actualKPIMonth = startMonth;

  return actualKPIMonth;
};

/**
 * Get kpi color from progress.
 *
 * @param  {number} progress - 0 to 100.
 * @returns {string} The color.
 */
export const getKPIColor = (progress) => {
  if (progress < 70) return RED_COLOR;
  if (progress >= 90) return GREEN_COLOR;

  return YELLOW_COLOR;
};

/**
 * Get kpi theme color from progress.
 *
 * @param  {number} progress - 0 to 100.
 * @returns {string} The theme color name.
 */
export const getKPIColorThemeName = (progress) => {
  if (progress < 70) return 'RED';
  if (progress >= 90) return 'GREEN';

  return 'YELLOW';
};

/**
 * Get startMonth, endMonth & totalMonths from monthsRange.
 *
 * @param  {Array} monthsRange - The months range.
 * @returns {object} StartMonth, endMonth & totalMonths.
 */
export const getStartEndAndTotalMonths = (monthsRange = DEFAULT_MONTHS_RANGE) => {
  const [startMonth, endMonth] = monthsRange;
  const totalMonths = endMonth - startMonth + 1;

  return { startMonth, endMonth, totalMonths };
};

/**
 * Get kpi from KPILIst.
 *
 * @param {Array} kpis - The kpi list.
 * @param {string} kpiType - The kpi type.
 * @param {number} kpiYear -  The kpi year.
 * @returns {object} The selected kpi.
 */
export const getKPIFromList = (kpis, kpiType, kpiYear) => {
  const kpi = kpis.find(({ type, year }) => type === kpiType && year === kpiYear);

  return kpi;
};

/**
 * Get KPI monthByMonth cumulative target.
 *
 * @param {object} kpi - The kpi to get the data.
 * @param  {Array} monthsRange - The months range.
 * @returns {Array} The cumulativeMonthByMonthTarget.
 */
export const getCumulativeMonthByMonthTarget = (kpi, monthsRange = DEFAULT_MONTHS_RANGE) => {
  if (!kpi) return chartsMonths(monthsRange).map(() => 0);

  const { type, monthByMonth, targetDistributionType, target } = kpi;
  const { startMonth, totalMonths, endMonth } = getStartEndAndTotalMonths(monthsRange);
  const lastMonthIndex = totalMonths - 1;

  const getPeriodicityMonthByMonthTarget = (kpi) => {
    const { type, target } = kpi;
    // Dont use BigInt for without currency kpis
    if (KPI_WITHOUT_CURRENCY_TYPES.includes(type)) {
      const monthValue = target / 12;
      return Array.from({ length: totalMonths }, () => monthValue.toFixed(2).toString());
    }

    const monthValue = BigInt(target)
      .divide(12)
      .toString();

    return Array.from({ length: totalMonths }, () => monthValue);
  };

  const monthByMonthCumulativeTarget =
    targetDistributionType === PERIODICITY_TYPE
      ? getPeriodicityMonthByMonthTarget(kpi)
      : R.clone(monthByMonth);

  // This is the target accummulated previous to the startMonth. For Example:
  // If the startMonth is JAN (1), the acummulated is 0.
  // If the startMonth is APR (4), the accummulated is the accumm target for March.
  const accummulatedTarget = (target / 12) * (startMonth - 1);
  const allowedDecimals = getAllowedDecimalsForKPI(kpi);

  monthByMonthCumulativeTarget.forEach((value, i) => {
    // to avoid big int divide lost
    if (i === lastMonthIndex) {
      const periodTarget = (target / 12) * endMonth;
      monthByMonthCumulativeTarget[i] = periodTarget.toFixed(allowedDecimals);
    }

    // Cumulative ints
    if (i !== lastMonthIndex && KPI_WITHOUT_CURRENCY_TYPES.includes(type)) {
      const currentMonthTarget = monthByMonthCumulativeTarget[i];
      const pastMonthCumulativeTarget =
        monthByMonthCumulativeTarget[i - 1] || accummulatedTarget.toFixed(allowedDecimals);
      const currentMonthCumulativeTarget =
        Number(currentMonthTarget) + Number(pastMonthCumulativeTarget);
      monthByMonthCumulativeTarget[i] = (
        Math.round(currentMonthCumulativeTarget * 100) / 100
      ).toString();
    }
    // cumulative big ints
    if (i !== lastMonthIndex && isCurrencyTypeKPI(type)) {
      const currentMonthTarget = monthByMonthCumulativeTarget[i];
      const pastMonthCumulativeTarget =
        monthByMonthCumulativeTarget[i - 1] || accummulatedTarget.toFixed(allowedDecimals);
      monthByMonthCumulativeTarget[i] = BigInt(currentMonthTarget)
        .add(pastMonthCumulativeTarget)
        .toString();
    }
  });

  // round targets if they are curency types
  const roundedMonthByMonthCumulativeTarget = isCurrencyTypeKPI(type)
    ? monthByMonthCumulativeTarget.map(roundBigInt)
    : monthByMonthCumulativeTarget;

  return R.clone(roundedMonthByMonthCumulativeTarget);
};

/**
 * Get KPI FullYear And ProRata.
 *
 * @param {object} kpi - The kpi to get the data.
 * @param {number} proRataEndMonth - The month from getActualKPIMonth func.
 * @param  {Array} monthsRange - The months range.
 * @returns {object} An object {fullYear, proRata}.
 */
export const getKPIFullYearAndProRata = (
  kpi,
  proRataEndMonth,
  monthsRange = DEFAULT_MONTHS_RANGE,
) => {
  if (!kpi) return { fullYear: '0', proRata: '0' };
  const { startMonth, endMonth, totalMonths } = getStartEndAndTotalMonths(monthsRange);
  const periodicityTotalMonths = proRataEndMonth - (startMonth - 1);
  const rangeMonthsDiff = endMonth - startMonth;

  /**
   * Get periodicity target for proRata & fullPeriod quarter/half.
   *
   * @param  {object} kpi - The Kpi.
   * @param  {number} periodicityMonths - The total months.
   * @returns {number} The target.
   */
  const getPeriodicityTarget = (kpi, periodicityMonths) => {
    const { type, target } = kpi;

    // Dont use BigInt for % currency kpis
    if (getAllowedDecimalsForKPI(type)) {
      const floatProRata = (target / 12) * periodicityMonths;
      return Math.round(floatProRata).toString();
    }
    // dont divide if its the last month, just return the fullTarget
    if (periodicityMonths === 12) return BigInt(target).toString();

    // Dont use BigInt for without currency kpis
    if (KPI_WITHOUT_CURRENCY_TYPES.includes(type)) {
      const floatProRata = (target / 12) * periodicityMonths;
      return Math.round(floatProRata).toString();
    }

    return roundBigInt(
      BigInt(target)
        .divide(12)
        .multiply(periodicityMonths)
        .toString(),
    );
  };

  let fullYear = BigInt(kpi.target).toString();
  // get fullPeriod data for quarter/half year.
  if (rangeMonthsDiff !== 11) {
    fullYear =
      kpi.targetDistributionType === PERIODICITY_TYPE
        ? getPeriodicityTarget(kpi, totalMonths)
        : kpi.monthByMonth.slice(startMonth - 1, endMonth).reduce(
          (a, b) =>
            BigInt(a)
              .add(b)
              .toString(),
          '0',
        );
  }

  const proRata =
    kpi.targetDistributionType === PERIODICITY_TYPE
      ? getPeriodicityTarget(kpi, periodicityTotalMonths)
      : kpi.monthByMonth.slice(startMonth - 1, proRataEndMonth).reduce(
        (a, b) =>
          BigInt(a)
            .add(b)
            .toString(),
        '0',
      );

  return { fullYear, proRata };
};

/**
 * Calc total monetized value of items.
 *
 * @param {Array} items - Items with unitQuantity, unitMonetizationFactor.
 * @param  {string} itemName - Item model name.
 * @returns {number} TotalMonetizedValue.
 */
export const getItemsMonetizedValue = (items, itemName) => {
  return items.reduce((total, item) => {
    const { unitQuantity, unitMonetizationFactor } = item[itemName];
    const calculatedValue = calculateValueBigInt(unitQuantity, unitMonetizationFactor);

    return BigInt(total)
      .add(calculatedValue)
      .toString();
  }, '0');
};

/**
 * Get monthByMonthCurrent total items From groupByRamdaObject.
 *
 *  @param {object} groupByRamdaObject - Group by ramda object.
 * @param {Array} monthsRange - StartMonth and endMonth.
 * @returns {Array} Array of months.
 */
const getMonthByMonthCurrentFromGroupBy = (
  groupByRamdaObject,
  monthsRange = DEFAULT_MONTHS_RANGE,
) => {
  const { startMonth, endMonth, totalMonths } = getStartEndAndTotalMonths(monthsRange);
  // fill with monthsRange totalMonths '0' values
  const monthByMonthCurrent = Array.from({ length: totalMonths }, () => '0');

  for (let monthIndex = startMonth - 1; monthIndex < endMonth; monthIndex++) {
    const monthByMonthCurrentIndex = monthIndex - startMonth + 1;
    const monthItems = groupByRamdaObject[`${monthIndex}`] || [];
    const monthItemsLength = monthItems.length;
    const pastMonthItemsLength = monthByMonthCurrent[monthByMonthCurrentIndex - 1] || '0';
    monthByMonthCurrent[monthByMonthCurrentIndex] = BigInt(monthItemsLength)
      .add(pastMonthItemsLength)
      .toString();
  }

  return monthByMonthCurrent;
};

/**
 * Get monthByMonthCurrent monetizedValue From groupByRamdaObject.
 *
 * @param {object} groupByRamdaObject - Group by ramda object.
 * @param {Array} monthsRange - StartMonth and endMonth.
 * @returns {Array} Array of months.
 */
const getMonthByMonthCurrentMonetizedFromGroupBy = (
  groupByRamdaObject,
  monthsRange = DEFAULT_MONTHS_RANGE,
) => {
  const { startMonth, endMonth, totalMonths } = getStartEndAndTotalMonths(monthsRange);
  // fill with monthsRange totalMonths '0' values
  const monthByMonthCurrent = Array.from({ length: totalMonths }, () => '0');
  const monthByMonthForecastActuals = Array.from({ length: totalMonths }, () => '0');
  // get monthByMonth of monthsRange
  for (let monthIndex = startMonth - 1; monthIndex < endMonth; monthIndex++) {
    const monthByMonthCurrentIndex = monthIndex - startMonth + 1;
    const monthItems = groupByRamdaObject[`${monthIndex}`] || [];
    const monthItemsMonetized = getItemsMonetizedValue(monthItems, 'contribution');
    const pastMonthMonetized = monthByMonthCurrent[monthByMonthCurrentIndex - 1] || '0';
    monthByMonthForecastActuals[monthByMonthCurrentIndex] = R.clone(monthItemsMonetized);
    monthByMonthCurrent[monthByMonthCurrentIndex] = BigInt(monthItemsMonetized)
      .add(pastMonthMonetized)
      .toString();
  }

  return { monthByMonthCurrent, monthByMonthForecastActuals };
};

/**
 * Get monthByMonthCurrent total amount From groupByRamdaObject.
 *
 * @param {object} groupByRamdaObject - Group by ramda object.
 * @param {Array} monthsRange - StartMonth and endMonth.
 * @returns {Array} Array of months.
 */
const getMonthByMonthCurrentAmountFromGroupBy = (
  groupByRamdaObject,
  monthsRange = DEFAULT_MONTHS_RANGE,
) => {
  const { startMonth, endMonth, totalMonths } = getStartEndAndTotalMonths(monthsRange);
  // fill with monthsRange totalMonths '0' values
  const monthByMonthCurrent = Array.from({ length: totalMonths }, () => '0');
  const monthByMonthForecastActuals = Array.from({ length: totalMonths }, () => '0');
  // get monthByMonth of monthsRange
  for (let monthIndex = startMonth - 1; monthIndex < endMonth; monthIndex++) {
    const monthByMonthCurrentIndex = monthIndex - startMonth + 1;
    const monthItems = groupByRamdaObject[monthIndex] || [];
    const monthItemsAmount = monthItems.reduce(
      (total, { amount }) =>
        BigInt(total)
          .add(amount)
          .toString(),
      '0',
    );
    const pastMonthAmount = monthByMonthCurrent[monthByMonthCurrentIndex - 1] || '0';
    monthByMonthForecastActuals[monthByMonthCurrentIndex] = R.clone(monthItemsAmount);
    monthByMonthCurrent[monthByMonthCurrentIndex] = BigInt(monthItemsAmount)
      .add(pastMonthAmount)
      .toString();
  }

  return { monthByMonthCurrent, monthByMonthForecastActuals };
};

/**
 * Get total value of monthByMonthCurrent.
 *
 * @param  {Array} monthByMonthCurrent - Month by month actuals.
 * @returns {number} TotalMonetizedValue.
 */
export const getMonthByMonthTotalValue = (monthByMonthCurrent) => {
  return BigInt(monthByMonthCurrent[monthByMonthCurrent.length - 1]).toString();
};

/**
 * Count the total/approved initiatives & ideas for the Strategy scorecard.
 *
 * @param {Array} kpis - All kpis list.
 * @param {Array} initiatives - All initiatives of selected year.
 * @param {Array} ideas - All ideas of selected year.
 * @param {number} year - The year for balanced scorecard data.
 * @param  {Array} monthsRange - The months range.
 * @returns {Array}  KPI data.
 */
export const getStrategyScorecardData = (
  kpis,
  initiatives,
  ideas,
  year,
  monthsRange = DEFAULT_MONTHS_RANGE,
) => {
  // With both approvals approved
  const approvedInitiatives = initiatives.filter(({ initiativeApprovalInitiativeRelation }) => {
    if (!initiativeApprovalInitiativeRelation.items.length) return false;
    for (const { status } of initiativeApprovalInitiativeRelation.items) {
      if (status !== INITIATIVE_APPROVAL_APPROVED) return false;
    }
    return true;
  });

  // With both approvals approved
  const approvedIdeas = ideas.filter(({ idea: { ideaApprovalRelation } }) => {
    if (!ideaApprovalRelation.items.length) return false;
    for (const { status } of ideaApprovalRelation.items) {
      if (status !== IDEA_APPROVAL_APPROVED) return false;
    }
    return true;
  });

  // set approvedInitiatives approvedDate
  const approvedInitiativesWithApprovedDate = approvedInitiatives
    .map((initiative) => {
      const { initiativeApprovalInitiativeRelation } = initiative;
      const { dateOfResponse } = initiativeApprovalInitiativeRelation.items.reduce(
        (a, b) =>
          // get the greater dateOfResponse
          moment(a.dateOfResponse).isSameOrAfter(moment(b.dateOfResponse)) ? a : b,
        { dateOfResponse: null },
      );

      initiative.approvedDate = dateOfResponse;
      return initiative;
    })
    .filter(({ approvedDate }) => approvedItemsFilter(approvedDate, monthsRange));

  // set approvedIdeas approvedDate
  const approvedIdeasWithApprovedDate = approvedIdeas
    .map((item) => {
      const { idea } = item;
      const { ideaApprovalRelation } = idea;
      const { dateOfResponse } = ideaApprovalRelation.items.reduce(
        (a, b) =>
          // get the greater dateOfResponse
          moment(a.dateOfResponse).isSameOrAfter(moment(b.dateOfResponse)) ? a : b,
        { dateOfResponse: null },
      );

      item.approvedDate = dateOfResponse;
      return item;
    })
    .filter(({ approvedDate }) => approvedItemsFilter(approvedDate, monthsRange));

  // groupBy approvedDate
  const byApprovedDate = R.groupBy(({ approvedDate }) => {
    if (!moment(approvedDate).isValid()) return null;
    return moment(approvedDate).month();
  });
  // groupBy
  const approvedInitiativesByApprovedDate = byApprovedDate(approvedInitiativesWithApprovedDate);
  const approvedIdeasByApprovedDate = byApprovedDate(approvedIdeasWithApprovedDate);
  // monthByMonthCurrents
  const monthByMonthApprovedInitiatives = getMonthByMonthCurrentFromGroupBy(
    approvedInitiativesByApprovedDate,
    monthsRange,
  );
  const monthByMonthApprovedIdeas = getMonthByMonthCurrentFromGroupBy(
    approvedIdeasByApprovedDate,
    monthsRange,
  );

  const month = getActualKPIMonth(year, monthsRange);
  const joinInitiativesKPI = getKPIFromList(kpis, JOINT_INITIATIVES_TYPE, year);
  const joinIdeasKPI = getKPIFromList(kpis, JOINT_INNOVATION_IDEAS_TYPE, year);

  const { fullYear: fullYearInitiatives, proRata: proRataInitiatives } = getKPIFullYearAndProRata(
    joinInitiativesKPI,
    month,
    monthsRange,
  );
  const { fullYear: fullYearIdeas, proRata: proRataIdeas } = getKPIFullYearAndProRata(
    joinIdeasKPI,
    month,
    monthsRange,
  );

  const approvedInitiativesCount = getMonthByMonthTotalValue(monthByMonthApprovedInitiatives);
  const approvedIdeasCount = getMonthByMonthTotalValue(monthByMonthApprovedIdeas);

  const initiativesKPIData = {
    type: JOINT_INITIATIVES_TYPE,
    name: JOINT_INITIATIVES_TYPE,
    current: approvedInitiativesCount,
    monthByMonthCurrent: monthByMonthApprovedInitiatives,
    monthByMonthCumulativeTarget: getCumulativeMonthByMonthTarget(joinInitiativesKPI, monthsRange),
    fullYearTarget: fullYearInitiatives,
    proRataTarget: proRataInitiatives,
    initiatives: approvedInitiativesWithApprovedDate,
  };

  const ideasKPIData = {
    type: JOINT_INNOVATION_IDEAS_TYPE,
    name: JOINT_INNOVATION_IDEAS_TYPE,
    current: approvedIdeasCount,
    monthByMonthCurrent: monthByMonthApprovedIdeas,
    monthByMonthCumulativeTarget: getCumulativeMonthByMonthTarget(joinIdeasKPI, monthsRange),
    fullYearTarget: fullYearIdeas,
    proRataTarget: proRataIdeas,
    ideas: approvedIdeasWithApprovedDate,
  };

  return [initiativesKPIData, ideasKPIData];
};

/**
 * Count the total referenceableDeals Get the total unit monetized value of unitType === TRAINING contributions.
 *
 * @param {Array} kpis - All kpis list.
 * @param {Array} deals - All deals of selected year.
 * @param {Array} contributions - All contributions of selected year.
 * @param {number} year - The year for balanced scorecard data.
 * @param {Array} monthsRange - The months range.
 * @param {object} alliance - Alliance.
 * @returns {Array}  KPI data.
 */
export const getRelationshipScorecardData = (
  kpis,
  deals,
  contributions,
  year,
  monthsRange = DEFAULT_MONTHS_RANGE,
  alliance,
) => {
  const filteredDeals = deals.filter(({ closeDate }) => moment(closeDate).year() === year);

  const monthsRangeDeals = filteredDeals.filter(({ closeDate }) =>
    monthsRangeDealsFilter(closeDate, monthsRange),
  );

  const monthsRangeContributions = contributions.filter(({ contribution: { contributionDate } }) =>
    monthsRangeContributionsFilter(contributionDate, monthsRange),
  );

  const referencableDeals = monthsRangeDeals.filter(
    ({ dealReferencable }) => dealReferencable === 'Y',
  );

  const trainingContributions = monthsRangeContributions.filter(
    ({ contribution: { unitType } }) => unitType === TRAINING_UNIT_TYPE,
  );

  const customersSATScoreContributions = monthsRangeContributions.filter(
    ({ contribution: { unitType } }) => unitType === CUSTOMERS_SATISFACTION_SCORE_UNIT_TYPE,
  );

  // groupBy closeDate for deals
  const byCloseDate = R.groupBy(({ closeDate }) => moment(closeDate).month());

  // groupBy contributionDate for contributions
  const byContributionDate = R.groupBy(({ contribution: { contributionDate } }) => {
    return moment(contributionDate || undefined).month();
  });

  // groupBy
  const referencableDealsByCloseDate = byCloseDate(referencableDeals);
  const trainingContributionsByContributionDate = byContributionDate(trainingContributions);
  const customersSATScoreByContributionDate = byContributionDate(customersSATScoreContributions);
  // monthByMonthCurrents
  const monthByMonthReferencableDeals = getMonthByMonthCurrentFromGroupBy(
    referencableDealsByCloseDate,
    monthsRange,
  );

  const average = (values) => {
    if (!values.length) return 0;
    return Math.round((R.sum(values) / values.length) * 100) / 100;
  };

  const getCustomerSatisfactionForMonth = (deals) =>
    BigInt(
      Math.round(
        average(deals.map(({ contribution: { customerSatisfaction } }) => customerSatisfaction)),
      ),
    ).toString();

  const getProfessionalsCertifiedForMonth = (deals) =>
    BigInt(
      R.sum(deals.map(({ contribution: { professionalsCertified } }) => professionalsCertified)),
    );

  const customerSatisfactionByMonths = getMonthsValues(
    customersSATScoreByContributionDate,
    monthsRange,
    getCustomerSatisfactionForMonth,
  );
  const professionalsCertifiedByMonths = getMonthsValues(
    trainingContributionsByContributionDate,
    monthsRange,
    getProfessionalsCertifiedForMonth,
  );
  const cumulativeProfessionalsCertifiedByMonths = [];
  professionalsCertifiedByMonths.forEach((value, i) =>
    i > 0
      ? cumulativeProfessionalsCertifiedByMonths.push(
        BigInt(cumulativeProfessionalsCertifiedByMonths[i - 1])
          .add(value)
          .toString(),
      )
      : cumulativeProfessionalsCertifiedByMonths.push(value.toString()),
  );

  const month = getActualKPIMonth(year, monthsRange);

  const customerSatisfactionCurrent = average(
    customersSATScoreContributions.map((item) => item.contribution.customerSatisfaction),
  );
  const professionalsCertifiedCurrent = trainingContributions.reduce(
    (a, { contribution: { professionalsCertified } }) => a + professionalsCertified,
    0,
  );

  const customerSatisfactionKPI = getKPIFromList(kpis, CUSTOMER_SATISFACTION_SCORE_TYPE, year);
  const {
    fullYear: fullYearCustomerSatisfaction,
    proRata: proRataCustomerSatisfaction,
  } = getKPIFullYearAndProRata(customerSatisfactionKPI, month, monthsRange);

  const professionalsTrainedKPI = getKPIFromList(kpis, PROFESSIONALS_TRAINED_TYPE, year);

  const professionalsCertifiedKPI = getKPIFromList(kpis, PROFESSIONALS_CERTIFIED_TYPE, year);
  const {
    fullYear: fullYearProfessionalsCertified,
    proRata: proRataProfessionalsCertified,
  } = getKPIFullYearAndProRata(professionalsCertifiedKPI, month, monthsRange);

  const {
    monthByMonthCurrent: monthByMonthJointTrainingInvestment,
  } = getMonthByMonthCurrentMonetizedFromGroupBy(
    trainingContributionsByContributionDate,
    monthsRange,
  );

  const referencableDealsCount = getMonthByMonthTotalValue(monthByMonthReferencableDeals);
  const jointTrainingInvestment = getMonthByMonthTotalValue(monthByMonthJointTrainingInvestment);

  const referencableDealsKPI = getKPIFromList(kpis, JOINT_REFERENCEABLE_CLIENTS_TYPE, year);
  const trainingContributionsKPI = getKPIFromList(kpis, JOINT_TRAINING_INVESTMENT_TYPE, year);

  const {
    fullYear: fullYearReferenceableDeals,
    proRata: proRataReferenceableDeals,
  } = getKPIFullYearAndProRata(referencableDealsKPI, month, monthsRange);
  const {
    fullYear: fullYearTrainingContributions,
    proRata: proRataTrainingContributions,
  } = getKPIFullYearAndProRata(trainingContributionsKPI, month, monthsRange);

  const referencableDealsKPIData = {
    type: JOINT_REFERENCEABLE_CLIENTS_TYPE,
    name: JOINT_REFERENCEABLE_CLIENTS_TYPE,
    current: BigInt(referencableDealsCount).toString(),
    monthByMonthCurrent: monthByMonthReferencableDeals,
    monthByMonthCumulativeTarget: getCumulativeMonthByMonthTarget(
      referencableDealsKPI,
      monthsRange,
    ),
    fullYearTarget: fullYearReferenceableDeals,
    proRataTarget: proRataReferenceableDeals,
    deals: referencableDeals,
  };

  const trainingContributionsKPIData = {
    type: JOINT_TRAINING_INVESTMENT_TYPE,
    name: JOINT_TRAINING_INVESTMENT_TYPE,
    current: jointTrainingInvestment,
    monthByMonthCurrent: monthByMonthJointTrainingInvestment,
    monthByMonthCumulativeTarget: getCumulativeMonthByMonthTarget(
      trainingContributionsKPI,
      monthsRange,
    ),
    fullYearTarget: fullYearTrainingContributions,
    proRataTarget: proRataTrainingContributions,
    contributions: trainingContributions,
  };

  const customerSatisfactionKPIData = {
    type: CUSTOMER_SATISFACTION_SCORE_TYPE,
    name: CUSTOMER_SATISFACTION_SCORE_TYPE,
    current: customerSatisfactionCurrent,
    monthByMonthCurrent: customerSatisfactionByMonths,
    monthByMonthCumulativeTarget: getCumulativeMonthByMonthTarget(
      customerSatisfactionKPI,
      monthsRange,
    ),
    fullYearTarget: fullYearCustomerSatisfaction,
    proRataTarget: proRataCustomerSatisfaction,
    contributions: customersSATScoreContributions,
  };

  const professionalsCertifiedKPIData = {
    type: PROFESSIONALS_CERTIFIED_TYPE,
    name: getKPIName(alliance, PROFESSIONALS_CERTIFIED_TYPE),
    current: professionalsCertifiedCurrent,
    monthByMonthCurrent: cumulativeProfessionalsCertifiedByMonths,
    monthByMonthCumulativeTarget: getCumulativeMonthByMonthTarget(
      professionalsCertifiedKPI,
      monthsRange,
    ),
    fullYearTarget: fullYearProfessionalsCertified,
    proRataTarget: proRataProfessionalsCertified,
    contributions: trainingContributions,
  };

  return [
    referencableDealsKPIData,
    trainingContributionsKPIData,
    customerSatisfactionKPIData,
    getProfessionalsTrainedKPIData(professionalsTrainedKPI, contributions, year, monthsRange),
    professionalsCertifiedKPIData,
  ];
};

const getRange = (start, end) => {
  let result = [];
  let i = start;
  while (i <= end) {
    result.push(i);
    i++;
  }
  return result;
};

const getMonthsValues = (items, monthsRange, getMonthValue) => {
  const { startMonth, endMonth } = getStartEndAndTotalMonths(monthsRange);

  return getRange(startMonth, endMonth).map((month) => {
    const monthItems = items[month - 1] || [];
    return getMonthValue(monthItems, month);
  });
};

/**
 * Count the total closedWon & proposalIssued deals Get the total amount of client & partner deals (Pipeline).
 *
 * @param {Array} kpis - All kpis list.
 * @param {Array} deals - All deals of selected year.
 * @param {object} alliance - To get partner & client Company.
 * @param {number} year - The year for balanced scorecard data.
 * @param  {Array} monthsRange - The months range.
 * @returns {object} KPIs data.
 */
export const getOperationalScorecardData = (
  kpis,
  deals,
  alliance,
  year,
  monthsRange = DEFAULT_MONTHS_RANGE,
) => {
  const { clientCompany, partnerCompany } = alliance;
  const clientCompanyId = clientCompany ? clientCompany.id : null;
  const partnerCompanyId = partnerCompany ? partnerCompany.id : null;

  const filteredDeals = deals.filter(({ closeDate }) => moment(closeDate).year() === year);

  const monthsRangeDeals = filteredDeals.filter(({ closeDate }) =>
    monthsRangeDealsFilter(closeDate, monthsRange),
  );
  const activeDeals = monthsRangeDeals.filter(({ stage }) => DEAL_ACTIVE_STAGES.includes(stage));
  const clientPipelineDeals = activeDeals.filter(
    ({ company }) => company && company.id === clientCompanyId,
  );
  const partnerPipelineDeals = activeDeals.filter(
    ({ company }) => company && company.id === partnerCompanyId,
  );

  const closedWonDeals = deals.filter(({ stage }) => stage === DEAL_STAGE_CLOSED_WON);
  const proposalDeals = monthsRangeDeals.filter(({ stage }) =>
    PROPOSAL_ISSUED_STAGES.includes(stage),
  );

  // groupBy closeDate for deals
  const byCloseDate = R.groupBy(({ closeDate }) => moment(closeDate).month());

  // groupBy
  const activeDealsByCloseDate = byCloseDate(activeDeals);
  const clientPipelineDealsByCloseDate = byCloseDate(clientPipelineDeals);
  const partnerPipelineDealsByCloseDate = byCloseDate(partnerPipelineDeals);
  const closedWonDealsByCloseDate = byCloseDate(closedWonDeals);
  const proposalDealsByCloseDate = byCloseDate(proposalDeals);

  const getDealNumberOfTimesContacted = (deals) =>
    BigInt(R.sum(deals.map((deal) => deal.numberOfTimesContacted))).toString();

  const numberOfTimesContactedByMonths = getMonthsValues(
    activeDealsByCloseDate,
    monthsRange,
    getDealNumberOfTimesContacted,
  );

  const closedWonDealsByMonth = [];
  const [start, end] = monthsRange;
  for (let i = start; i <= end; i++) closedWonDealsByMonth.push(0);
  for (const key in closedWonDealsByCloseDate) {
    if (!closedWonDealsByCloseDate.hasOwnProperty(key)) continue;
    closedWonDealsByMonth[+key] = closedWonDealsByCloseDate[key].length;
  }
  for (let i = 1; i < closedWonDealsByMonth.length; i++)
    closedWonDealsByMonth[i] += closedWonDealsByMonth[i - 1];

  const opportunityWinRatioByMonth = [];
  const totalClosedWonDeals = deals.filter(({ stage }) => stage === DEAL_STAGE_CLOSED_WON);
  const closedWonDealsLength = totalClosedWonDeals.length;
  const closedWonDealsRatio = closedWonDealsLength / deals.length;
  closedWonDealsByMonth.forEach((value) => {
    opportunityWinRatioByMonth.push(Math.round(closedWonDealsRatio * 1000) / 10);
  });

  // monthByMonthCurrents
  const {
    monthByMonthCurrent: monthByMonthClientPipelineDeals,
  } = getMonthByMonthCurrentAmountFromGroupBy(clientPipelineDealsByCloseDate, monthsRange);
  const {
    monthByMonthCurrent: monthByMonthPartnerPipelineDeals,
  } = getMonthByMonthCurrentAmountFromGroupBy(partnerPipelineDealsByCloseDate, monthsRange);

  const monthByMonthClosedWonDeals = getMonthByMonthCurrentFromGroupBy(
    closedWonDealsByCloseDate,
    monthsRange,
  );
  const monthByMonthProposalDeals = getMonthByMonthCurrentFromGroupBy(
    proposalDealsByCloseDate,
    monthsRange,
  );

  const month = getActualKPIMonth(year, monthsRange);

  const numberOfTimesContactedCurrent = numberOfTimesContactedByMonths[month - 1];

  const clientPipeline = getMonthByMonthTotalValue(monthByMonthClientPipelineDeals);
  const partnerPipeline = getMonthByMonthTotalValue(monthByMonthPartnerPipelineDeals);

  const closedWonDealsCount = getMonthByMonthTotalValue(monthByMonthClosedWonDeals);
  const proposalDealsCount = getMonthByMonthTotalValue(monthByMonthProposalDeals);

  const closedWonDealsKPI = getKPIFromList(kpis, JOINT_PROPOSALS_WON_TYPE, year);
  const proposalDealsKPI = getKPIFromList(kpis, JOINT_PROPOSALS_ISSUED_TYPE, year);
  const pipelineClientKPI = getKPIFromList(kpis, PIPELINE_CLIENT_TYPE, year);
  const pipelinePartnerKPI = getKPIFromList(kpis, PIPELINE_PARTNER_TYPE, year);
  const customersContactedKPI = getKPIFromList(kpis, JOINT_SALES_CALLS_TYPE, year);
  const opportunityWinRatioKPI = getKPIFromList(kpis, OPPORTUNITY_WIN_RATIO_TYPE, year);

  const {
    fullYear: fullYearClosedWonDeals,
    proRata: proRataClosedWonDeals,
  } = getKPIFullYearAndProRata(closedWonDealsKPI, month, monthsRange);
  const {
    fullYear: fullYearProposalDeals,
    proRata: proRataProposalDeals,
  } = getKPIFullYearAndProRata(proposalDealsKPI, month, monthsRange);
  const {
    fullYear: fullYearClientPipeline,
    proRata: proRataClientPipeline,
  } = getKPIFullYearAndProRata(pipelineClientKPI, month, monthsRange);
  const {
    fullYear: fullYearPartnerPipeline,
    proRata: proRataPartnerPipeline,
  } = getKPIFullYearAndProRata(pipelinePartnerKPI, month, monthsRange);
  const {
    fullYear: fullYearCustomersContacted,
    proRata: proRataCustomersContacted,
  } = getKPIFullYearAndProRata(customersContactedKPI, month, monthsRange);

  const clientPipelineName = getKPIName(alliance, PIPELINE_CLIENT_TYPE);
  const clientPipelineKPIData = {
    type: PIPELINE_CLIENT_TYPE,
    name: clientPipelineName,
    current: clientPipeline,
    monthByMonthCurrent: monthByMonthClientPipelineDeals,
    monthByMonthCumulativeTarget: getCumulativeMonthByMonthTarget(pipelineClientKPI, monthsRange),
    fullYearTarget: fullYearClientPipeline,
    proRataTarget: proRataClientPipeline,
    deals: clientPipelineDeals,
  };

  const partnerPipelineName = getKPIName(alliance, PIPELINE_PARTNER_TYPE);
  const partnerPipelineKPIData = {
    type: PIPELINE_PARTNER_TYPE,
    name: partnerPipelineName,
    current: partnerPipeline,
    monthByMonthCurrent: monthByMonthPartnerPipelineDeals,
    monthByMonthCumulativeTarget: getCumulativeMonthByMonthTarget(pipelinePartnerKPI, monthsRange),
    fullYearTarget: fullYearPartnerPipeline,
    proRataTarget: proRataPartnerPipeline,
    deals: partnerPipelineDeals,
  };

  const closedWonDealsKPIData = {
    type: JOINT_PROPOSALS_WON_TYPE,
    name: JOINT_PROPOSALS_WON_TYPE,
    current: BigInt(closedWonDealsCount).toString(),
    monthByMonthCurrent: monthByMonthClosedWonDeals,
    monthByMonthCumulativeTarget: getCumulativeMonthByMonthTarget(closedWonDealsKPI, monthsRange),
    fullYearTarget: fullYearClosedWonDeals,
    proRataTarget: proRataClosedWonDeals,
    deals: closedWonDeals,
  };

  const proposalDealsKPIData = {
    type: JOINT_PROPOSALS_ISSUED_TYPE,
    name: JOINT_PROPOSALS_ISSUED_TYPE,
    current: BigInt(proposalDealsCount).toString(),
    monthByMonthCurrent: monthByMonthProposalDeals,
    monthByMonthCumulativeTarget: getCumulativeMonthByMonthTarget(proposalDealsKPI, monthsRange),
    fullYearTarget: fullYearProposalDeals,
    proRataTarget: proRataProposalDeals,
    deals: proposalDeals,
  };

  const salesCallsKPIData = {
    type: JOINT_SALES_CALLS_TYPE,
    name: JOINT_SALES_CALLS_TYPE,
    current: numberOfTimesContactedCurrent,
    monthByMonthCurrent: numberOfTimesContactedByMonths,
    monthByMonthCumulativeTarget: getCumulativeMonthByMonthTarget(
      customersContactedKPI,
      monthsRange,
    ),
    fullYearTarget: fullYearCustomersContacted,
    proRataTarget: proRataCustomersContacted,
    deals: activeDeals,
  };
  console.log('disabled KPI:', salesCallsKPIData);

  return [
    clientPipelineKPIData,
    partnerPipelineKPIData,
    closedWonDealsKPIData,
    proposalDealsKPIData,
    // salesCallsKPIData,
    getOpportunityWinRatioKPIData(opportunityWinRatioKPI, deals, year, monthsRange),
  ];
};

/**
 * Get the client/partner (BOOKED REVENUE) & Contributions monetizedValue.
 *
 * @param {Array} kpis - All kpis list.
 * @param {Array} deals - Deals for Bookings calculations.
 * @param {Array} contributions - Contributions for calculations.
 * @param {object} alliance - To get partner & client company.
 * @param {number} year - The year for balanced scorecard data.
 * @param  {Array} monthsRange - The months range.
 * @returns {Array} Bookings & contributions KPI data.
 */
export const getFinancialScorecardData = (
  kpis,
  deals,
  contributions,
  alliance,
  year,
  monthsRange = DEFAULT_MONTHS_RANGE,
) => {
  const { clientCompany, partnerCompany } = alliance;
  const clientCompanyId = clientCompany ? clientCompany.id : null;
  const partnerCompanyId = partnerCompany ? partnerCompany.id : null;

  const filteredDeals = deals.filter(({ closeDate }) => moment(closeDate).year() === year);

  const monthsRangeDeals = filteredDeals.filter(({ closeDate }) =>
    monthsRangeDealsFilter(closeDate, monthsRange),
  );

  const monthsRangeContributions = contributions.filter(({ contribution: { contributionDate } }) =>
    monthsRangeContributionsFilter(contributionDate, monthsRange),
  );

  const closedWonDeals = monthsRangeDeals.filter(({ stage }) => {
    return stage === DEAL_STAGE_CLOSED_WON;
  });
  const clientRevenueDeals = closedWonDeals.filter(
    ({ company }) => company && company.id === clientCompanyId,
  );
  const partnerRevenueDeals = closedWonDeals.filter(
    ({ company }) => company && company.id === partnerCompanyId,
  );
  const clientContributions = monthsRangeContributions.filter(
    ({ contribution: { source } }) => source && source.id === clientCompanyId,
  );
  const partnerContributions = monthsRangeContributions.filter(
    ({ contribution: { source } }) => source && source.id === partnerCompanyId,
  );

  // groupBy closeDate for deals
  const byCloseDate = R.groupBy(({ closeDate }) => {
    return moment(closeDate).month();
  });
  // groupBy contributionDate for contributions
  const byContributionDate = R.groupBy(({ contribution: { contributionDate } }) => {
    return moment(contributionDate || undefined).month();
  });
  // groupBy
  const clientRevenueDealsByCloseDate = byCloseDate(clientRevenueDeals);
  const partnerRevenueDealsByCloseDate = byCloseDate(partnerRevenueDeals);
  const clientContributionsByContributionDate = byContributionDate(clientContributions);
  const partnerContributionsByContributionDate = byContributionDate(partnerContributions);
  // monthByMonthCurrents
  const {
    monthByMonthCurrent: monthByMonthClientRevenueDeals,
    monthByMonthForecastActuals: monthByMonthForecastClientRevenueDeals,
  } = getMonthByMonthCurrentAmountFromGroupBy(clientRevenueDealsByCloseDate, monthsRange);
  const {
    monthByMonthCurrent: monthByMonthPartnerRevenueDeals,
    monthByMonthForecastActuals: monthByMonthForecastPartnerRevenueDeals,
  } = getMonthByMonthCurrentAmountFromGroupBy(partnerRevenueDealsByCloseDate, monthsRange);
  const {
    monthByMonthCurrent: monthByMonthClientContributions,
    monthByMonthForecastActuals: monthByMonthForecastClientContributions,
  } = getMonthByMonthCurrentMonetizedFromGroupBy(
    clientContributionsByContributionDate,
    monthsRange,
  );
  const {
    monthByMonthCurrent: monthByMonthPartnerContributions,
    monthByMonthForecastActuals: monthByMonthForecastPartnerContributions,
  } = getMonthByMonthCurrentMonetizedFromGroupBy(
    partnerContributionsByContributionDate,
    monthsRange,
  );

  const clientRevenue = getMonthByMonthTotalValue(monthByMonthClientRevenueDeals);
  const partnerRevenue = getMonthByMonthTotalValue(monthByMonthPartnerRevenueDeals);

  const clientContributionsValue = getMonthByMonthTotalValue(monthByMonthClientContributions);
  const partnerContributionsValue = getMonthByMonthTotalValue(monthByMonthPartnerContributions);

  const month = getActualKPIMonth(year, monthsRange);
  const revenueClientKPI = getKPIFromList(kpis, BOOKINGS_CLIENT_TYPE, year);
  const revenuePartnerKPI = getKPIFromList(kpis, BOOKINGS_PARTNER_TYPE, year);
  const contributionsClientKPI = getKPIFromList(kpis, CONTRIBUTIONS_CLIENT_TYPE, year);
  const contributionsPartnerKPI = getKPIFromList(kpis, CONTRIBUTIONS_PARTNER_TYPE, year);

  const {
    fullYear: fullYearClientRevenue,
    proRata: proRataClientRevenue,
  } = getKPIFullYearAndProRata(revenueClientKPI, month, monthsRange);
  const {
    fullYear: fullYearPartnerRevenue,
    proRata: proRataPartnerRevenue,
  } = getKPIFullYearAndProRata(revenuePartnerKPI, month, monthsRange);
  const {
    fullYear: fullYearClientContributions,
    proRata: proRataClientContributions,
  } = getKPIFullYearAndProRata(contributionsClientKPI, month, monthsRange);
  const {
    fullYear: fullYearPartnerContributions,
    proRata: proRataPartnerContributions,
  } = getKPIFullYearAndProRata(contributionsPartnerKPI, month, monthsRange);

  const clientRevenueName = getKPIName(alliance, BOOKINGS_CLIENT_TYPE);
  const clientRevenueKPIData = {
    type: BOOKINGS_CLIENT_TYPE,
    name: clientRevenueName,
    current: clientRevenue,
    monthByMonthCurrent: monthByMonthClientRevenueDeals,
    monthByMonthForecastActuals: monthByMonthForecastClientRevenueDeals,
    monthByMonthCumulativeTarget: getCumulativeMonthByMonthTarget(revenueClientKPI, monthsRange),
    fullYearTarget: fullYearClientRevenue,
    proRataTarget: proRataClientRevenue,
    deals: clientRevenueDeals,
  };

  const partnerRevenueName = getKPIName(alliance, BOOKINGS_PARTNER_TYPE);
  const partnerRevenueKPIData = {
    type: BOOKINGS_PARTNER_TYPE,
    name: partnerRevenueName,
    current: partnerRevenue,
    monthByMonthCurrent: monthByMonthPartnerRevenueDeals,
    monthByMonthForecastActuals: monthByMonthForecastPartnerRevenueDeals,
    monthByMonthCumulativeTarget: getCumulativeMonthByMonthTarget(revenuePartnerKPI, monthsRange),
    fullYearTarget: fullYearPartnerRevenue,
    proRataTarget: proRataPartnerRevenue,
    deals: partnerRevenueDeals,
  };

  const clientContributionsName = getKPIName(alliance, CONTRIBUTIONS_CLIENT_TYPE);
  const clientContributionsKPIData = {
    type: CONTRIBUTIONS_CLIENT_TYPE,
    name: clientContributionsName,
    current: clientContributionsValue,
    monthByMonthCurrent: monthByMonthClientContributions,
    monthByMonthForecastActuals: monthByMonthForecastClientContributions,
    monthByMonthCumulativeTarget: getCumulativeMonthByMonthTarget(
      contributionsClientKPI,
      monthsRange,
    ),
    fullYearTarget: fullYearClientContributions,
    proRataTarget: proRataClientContributions,
    contributions: clientContributions,
  };

  const partnerContributionsName = getKPIName(alliance, CONTRIBUTIONS_PARTNER_TYPE);
  const partnerContributionsKPIData = {
    type: CONTRIBUTIONS_PARTNER_TYPE,
    name: partnerContributionsName,
    current: partnerContributionsValue,
    monthByMonthCurrent: monthByMonthPartnerContributions,
    monthByMonthForecastActuals: monthByMonthForecastPartnerContributions,
    monthByMonthCumulativeTarget: getCumulativeMonthByMonthTarget(
      contributionsPartnerKPI,
      monthsRange,
    ),
    fullYearTarget: fullYearPartnerContributions,
    proRataTarget: proRataPartnerContributions,
    contributions: partnerContributions,
  };

  return [
    clientRevenueKPIData,
    partnerRevenueKPIData,
    clientContributionsKPIData,
    partnerContributionsKPIData,
  ];
};

/**
 * Get Balanced Scorecad Strategy, Relationship, Operational & Financial KPIs.
 *
 * @param {Array} kpis - All kpis list.
 * @param {Array} initiatives - All Initiatives of selected year.
 * @param {Array} ideas - All ideas of selected year.
 * @param {Array} contributions - All contributions of selected year.
 * @param {Array} deals - All deals of selected year.
 * @param {object} alliance - The selected alliance.
 * @param {number} year - Selected year.
 * @param  {Array} monthsRange - The months range.
 * @returns {object} The {StrategyKPIs, RelationshipKPIs}.
 */
export const getBalancedScorecardData = (
  kpis,
  initiatives,
  ideas,
  contributions,
  deals,
  alliance,
  year = moment().year(),
  monthsRange = DEFAULT_MONTHS_RANGE,
) => {
  const strategyKPIs = getStrategyScorecardData(kpis, initiatives, ideas, year, monthsRange);
  const relationshipKPIs = getRelationshipScorecardData(
    kpis,
    deals,
    contributions,
    year,
    monthsRange,
    alliance,
  );
  const operationalKPIs = getOperationalScorecardData(kpis, deals, alliance, year, monthsRange);
  const financialKPIs = getFinancialScorecardData(
    kpis,
    deals,
    contributions,
    alliance,
    year,
    monthsRange,
  );

  const { businessCase } = alliance;
  const { kpiYears } = getKPIYears(alliance, businessCase);
  return { strategyKPIs, relationshipKPIs, operationalKPIs, financialKPIs, kpiYears };
};

/**
 * Get the progress based on current and targetKPI.
 *
 * @param  {number} current - Current kpi value.
 * @param  {number} kpiTarget - Target kpi.
 * @param  {string} kpiType - Kpi type.
 * @returns {number} Progress.
 */
export const getKPIProgress = (current, kpiTarget, kpiType) => {
  if (+kpiTarget === 0) return +current > 0 ? 100 : 0;

  if (getAllowedDecimalsForKPI(kpiType)) {
    return Math.trunc((current * 100) / kpiTarget);
  }

  if (BigInt(kpiTarget).isZero()) return 0;

  if (KPI_WITHOUT_CURRENCY_TYPES.includes(kpiType)) {
    return Math.trunc((current * 100) / kpiTarget);
  }

  const intNumber = parseInt(
    BigInt(current)
      .multiply(100)
      .divide(kpiTarget)
      .toString(),
  );
  return Math.trunc(intNumber);
};

/**
 *  GetKPI Progress Text.
 *
 * @param  {object}  currency - The currency.
 * @param  {string}  type - Kpi type.
 * @param  {number}  current - Current KPI value.
 * @param  {number}  target - KPI target.
 * @returns {string} Progress text.
 */
export const getKPIProgressText = (currency, type, current, target) => {
  const withCurrencyType = isCurrencyTypeKPI(type);

  const kpiCurency = withCurrencyType ? currency : null;
  let symbol = kpiCurency ? kpiCurency.symbol : '';
  symbol = isPercentageTypeKPI(type) ? '%' : symbol;

  const kpiCurrent = withCurrencyType
    ? numbro(current.toString()).format({
      average: true,
    })
    : current;
  const kpiTarget = withCurrencyType
    ? numbro(target.toString()).format({
      average: true,
    })
    : target;

  return isPercentageTypeKPI(type)
    ? `${kpiCurrent}${symbol}/${kpiTarget}${symbol}`
    : `${symbol}${kpiCurrent}/${symbol}${kpiTarget}`;
};

/**
 *  GetKPI Target Text.
 *
 * @param  {object}  currency - The currency.
 * @param  {string}  type - Kpi type.
 * @param  {number}  target - KPI target.
 * @returns {string} Target text.
 */
export const getKPITargetText = (currency, type, target) => {
  const isPercentage = isPercentageTypeKPI(type);
  const kpiCurency = isCurrencyTypeKPI(type) ? currency : null;

  const kpiTarget = kpiCurency
    ? bigIntThousandSeparator(target)
    : numbro(target.toString()).format({
      thousandSeparated: true,
    });

  let symbol = kpiCurency ? kpiCurency.symbol : '';
  symbol = isPercentage ? '%' : symbol;

  return isPercentage ? `${kpiTarget}${symbol}` : `${symbol}${kpiTarget}`;
};

/**
 * Get KPI Name based on company name.
 *
 * @param {object} alliance - The alliance.
 * @param {string} kpiType - Kpi Type.
 * @returns {string} KpiName.
 */
export const getKPIName = (alliance, kpiType) => {
  const { clientCompany, partnerCompany } = alliance;

  if (kpiType.includes('- Client')) {
    if (!clientCompany) return kpiType;
    return kpiType.replace('- Client', `- ${clientCompany.name}`);
  }

  if (kpiType.includes('- Partner')) {
    if (!partnerCompany) return kpiType;
    return kpiType.replace('- Partner', `- ${partnerCompany.name}`);
  }

  return kpiType;
};

/**
 * Set default KPIs for a specific year if none is already set (for balanced scorecard & forecast reports).
 *
 * @param {Array} kpis - The kpis list.
 * @param {Array} kpiYear - The kpi selected year.
 */
export const setDefaultKPIs = (kpis, kpiYear) => {
  const kpi = kpis.find(({ year }) => year === kpiYear);

  if (!kpi) kpis.push.apply(kpis, allianceKPIsYearModel(kpiYear));
};

/**
 *
 * @param {object} kpi - The OWR KPI to extract unprocessed data from.
 * @param {[object]} deals - List of all deals in the alliance.
 * @param {number} year - The year of the KPI.
 * @param {[number, number]} monthsRange - The start and end month of the filter.
 * @returns {object} The processed data of the KPI.
 */
const getOpportunityWinRatioKPIData = (kpi, deals, year, monthsRange = DEFAULT_MONTHS_RANGE) => {
  console.log('getOpportunityWinRatioKPIData:', kpi, deals, year, monthsRange);
  const [startMonth, endMonth] = monthsRange;
  const dealsCreatedUpToDate = deals.filter(({ createdAt }) => {
    const date = moment(createdAt);
    return date.year() < year || (date.year() === year && date.month() <= endMonth);
  });

  const dealsClosedWonInYear = dealsCreatedUpToDate.filter(
    ({ closeDate, stage }) => moment(closeDate).year() === year && stage === DEAL_STAGE_CLOSED_WON,
  );

  const dealsClosedWonByMonth = Array.from({ length: 12 }, () => []);

  dealsClosedWonInYear.forEach((deal) => {
    const { closeDate } = deal;
    const month = moment(closeDate).month();
    dealsClosedWonByMonth[month].push(deal);
  });

  let accumulatedClosedDeals = dealsCreatedUpToDate.filter(
    ({ closeDate, stage }) => moment(closeDate).year() < year && stage === DEAL_STAGE_CLOSED_WON,
  ).length;

  let monthByMonthCurrent;

  if (!dealsCreatedUpToDate.length) {
    monthByMonthCurrent = dealsClosedWonByMonth.map(() => 0);
  } else {
    monthByMonthCurrent = dealsClosedWonByMonth.map((dealsInMonth) => {
      accumulatedClosedDeals += dealsInMonth.length;
      return Math.round((accumulatedClosedDeals / dealsCreatedUpToDate.length) * 1000) / 10;
    });
  }

  const { monthByMonth, targetDistributionType, target } = kpi;

  const monthByMonthCumulativeTarget = [];

  const allowedDecimals = getAllowedDecimalsForKPI(kpi.type);
  if (targetDistributionType === PERIODICITY_TYPE) {
    const monthByMonthTarget = +(target / 12).toFixed(allowedDecimals);
    monthByMonth.forEach((_, i) => {
      if (0 < i && i < 11) {
        const value =
          monthByMonthCumulativeTarget[monthByMonthCumulativeTarget.length - 1] +
          monthByMonthTarget;
        monthByMonthCumulativeTarget.push(+value.toFixed(allowedDecimals));
      } else if (i === 0) {
        monthByMonthCumulativeTarget.push(monthByMonthTarget);
      } else {
        monthByMonthCumulativeTarget.push(+target);
      }
    });
  } else {
    monthByMonth.forEach((val, i) => {
      if (0 < i && i < 11) {
        const value = monthByMonthCumulativeTarget[monthByMonthCumulativeTarget.length - 1] + +val;
        monthByMonthCumulativeTarget.push(+value.toFixed(allowedDecimals));
      } else if (i === 0) {
        monthByMonthCumulativeTarget.push(+val);
      } else {
        monthByMonthCumulativeTarget.push(+target);
      }
    });
  }

  const month = moment().month();
  const current = monthByMonthCurrent[month];
  const proRataTarget = Math.round(monthByMonthCumulativeTarget[month]);

  monthByMonthCumulativeTarget.forEach((val, i) => {
    monthByMonthCumulativeTarget[i] = Math.round(val);
  });

  const monthsRangeFilter = (_, i) => startMonth - 1 <= i && i <= endMonth - 1;

  const data = {
    type: OPPORTUNITY_WIN_RATIO_TYPE,
    name: OPPORTUNITY_WIN_RATIO_TYPE,
    current,
    monthByMonthCurrent: monthByMonthCurrent.filter(monthsRangeFilter).map((v) => v.toString()),
    monthByMonthCumulativeTarget: monthByMonthCumulativeTarget
      .filter(monthsRangeFilter)
      .map((v) => v.toString()),
    fullYearTarget: kpi.target,
    proRataTarget,
    deals: deals.filter(({ stage }) => stage === DEAL_STAGE_CLOSED_WON),
  };
  console.log('OWR data:', data);
  return data;
};

/**
 *
 * @param {object} kpi - The OWR KPI to extract unprocessed data from.
 * @param {[object]} contributions - List of all contributions in the alliance.
 * @param {number} year - The year of the KPI.
 * @param {[number, number]} monthsRange - The start and end month of the filter.
 * @returns {object} The processed data of the KPI.
 */
const getProfessionalsTrainedKPIData = (
  kpi,
  contributions,
  year,
  monthsRange = DEFAULT_MONTHS_RANGE,
) => {
  const monthsRangeContributions = contributions.filter(({ contribution: { contributionDate } }) =>
    monthsRangeContributionsFilter(contributionDate, monthsRange),
  );

  const trainingContributions = monthsRangeContributions.filter(
    ({ contribution: { unitType } }) => unitType === TRAINING_UNIT_TYPE,
  );

  // groupBy contributionDate for contributions
  const byContributionDate = R.groupBy(({ contribution: { contributionDate } }) => {
    return moment(contributionDate || undefined).month();
  });

  const trainingContributionsByContributionDate = byContributionDate(trainingContributions);

  const getProfessionalsTrainedForMonth = (deals) =>
    BigInt(R.sum(deals.map(({ contribution: { professionalsTrained } }) => professionalsTrained)));

  const professionalsTrainedByMonths = getMonthsValues(
    trainingContributionsByContributionDate,
    monthsRange,
    getProfessionalsTrainedForMonth,
  );

  const cumulativeProfessionalsTrainedByMonths = [];
  professionalsTrainedByMonths.forEach((value, i) =>
    i > 0
      ? cumulativeProfessionalsTrainedByMonths.push(
        BigInt(cumulativeProfessionalsTrainedByMonths[i - 1])
          .add(value)
          .toString(),
      )
      : cumulativeProfessionalsTrainedByMonths.push(value.toString()),
  );

  const month = getActualKPIMonth(year, monthsRange);

  const professionalsTrainedCurrent = trainingContributions.reduce(
    (a, { contribution: { professionalsTrained } }) => a + professionalsTrained,
    0,
  );

  console.log('professionals', professionalsTrainedCurrent, cumulativeProfessionalsTrainedByMonths);

  const {
    fullYear: fullYearProfessionalsTrained,
    proRata: proRataProfessionalsTrained,
  } = getKPIFullYearAndProRata(kpi, month, monthsRange);
  return {
    type: PROFESSIONALS_TRAINED_TYPE,
    name: PROFESSIONALS_TRAINED_TYPE,
    current: professionalsTrainedCurrent,
    monthByMonthCurrent: cumulativeProfessionalsTrainedByMonths,
    monthByMonthCumulativeTarget: getCumulativeMonthByMonthTarget(kpi, monthsRange),
    fullYearTarget: fullYearProfessionalsTrained,
    proRataTarget: proRataProfessionalsTrained,
    contributions: trainingContributions,
  };
};
