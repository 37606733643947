import React from 'react';
import PropTypes from 'prop-types';
import Status from '../../Status';
import DetailDateValue from '../../DetailDateValue';
import { Table } from '@8base/boost';

/**
 * Detail View Table For The Action Entity.
 */

const ApprovalDetailColumn = (props) => {
  const { company, dateOfResponse, approvedBy, status } = props.data;

  const approvedName = approvedBy ? `${approvedBy.firstName} ${approvedBy.lastName}` : '';
  const companyName = company ? company.name : '';

  return (
    <>
      <Table.BodyRow columns="3fr 3fr 3fr 3fr" className={'justify-center-column'}>
        <Table.BodyCell className="justify-center-column">{companyName}</Table.BodyCell>
        <Table.BodyCell>
          <Status status={status} />
        </Table.BodyCell>
        <Table.BodyCell>
          <DetailDateValue date={dateOfResponse} />
        </Table.BodyCell>
        <Table.BodyCell>{approvedName}</Table.BodyCell>
      </Table.BodyRow>
    </>
  );
};

ApprovalDetailColumn.propTypes = {
  data: PropTypes.object.isRequired,
};

export default ApprovalDetailColumn;
