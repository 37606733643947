import React from 'react';
import { Text } from '@8base/boost';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

const StyledText = styled(Text)({
  fontSize: 16,
  color: '#e5b905',
});

const ToastWarn = ({ text = '' }) => {
  return (
    <>
      <StyledText weight="bold">{'Look!'}</StyledText>
      &nbsp;&nbsp;
      <StyledText>{text}</StyledText>
    </>
  );
};

ToastWarn.propTypes = {
  text: PropTypes.string.isRequired,
};

export default ToastWarn;
