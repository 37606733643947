import React from 'react';
import { Card, Heading } from '@8base/boost';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';

const CommentHeader = styled(Heading)`
  height: 20px;
  width: 208px;
  color: #323c47;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.5px;
  line-height: 21px;
`;

const StyledHeaderContainer = styled(Card.Header)`
  width: 315px;
  padding: 20px 19px;
`;

const CommentsHeader = ({ text }) => (
  <StyledHeaderContainer>
    <CommentHeader type="h4" text={text} />
  </StyledHeaderContainer>
);

CommentsHeader.propTypes = {
  text: PropTypes.string.isRequired,
};

export { CommentsHeader };
