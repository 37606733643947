import React from 'react';
import * as R from 'ramda';
import { View } from '@cobuildlab/react-simple-state';
import { Query } from 'react-apollo';

import { filterGenerator } from '../../../shared/select-utils';
import { getCurrencyOnSession } from '../../../shared/alliance-utils';

import { SORT_NAME_MAP } from '../reports-model';
import { FIELDS_LIST, INITIAL_STATE } from './active-sales-pipeline-model';
import { ACTIVE_SALES_PIPELINE_QUERY } from '../reports-queries';
import { OnActiveSalesPipelineFilters } from './active-sales-pipeline-events';
import { OnNewSession } from '../../../shared/session-events';
import { createSortValue } from '../utils';

export const ActiveSalesPipelineDataHandler = (WrappedComponent) => {
  return class extends View {
    constructor(props) {
      super(props);

      const config = OnActiveSalesPipelineFilters.get() || INITIAL_STATE;

      this.state = {
        queryArgs: this.buildRequestArgs(config),
        config,
      };
    }

    componentDidMount() {
      this.subscribe(OnActiveSalesPipelineFilters, this.onConfigChange);
    }

    onConfigChange = (config) => {
      this.setState({
        queryArgs: this.buildRequestArgs(config),
        config,
      });
    };

    getDealsTotal(deals) {
      let total = 0;

      if (!Array.isArray(deals)) {
        return total;
      }

      for (const item of deals) {
        if (Array.isArray(item) && Array.isArray(item[1])) {
          total += Number(this.getDealsTotal(item[1]));
        }

        if (item && item.amount) {
          total += Number(item.amount);
        }
      }

      return total;
    }

    buildRequestArgs(config) {
      const args = {
        filter: {
          AND: [],
        },
        groupBy: [],
        sort: [],
      };

      const selectedAlliance = R.clone(OnNewSession.get().selectedAlliance);
      if (config.sorts && config.sorts.length > 0) {
        args.sort = config.sorts.map(({ key, value }) => ({ [key]: value }));
      }

      if (Array.isArray(config.groups)) {
        const groups = config.isTotals ? config.groups.slice(0, 1) : [...config.groups];

        groups.reverse().forEach(({ key, sort }) => {
          args.groupBy.unshift(key);

          if (sort) {
            args.sort = args.sort.filter((item) => !Object.keys(item).includes(key));
            args.sort.unshift({ [key]: sort });
          }
        });
      }

      if (Array.isArray(config.filters)) {
        const configFilters = config.filters.map((filter) => {
          const rule = filter.rule ? filter.rule : 'equals';
          return {
            ...filter,
            rule,
          };
        });
        args.filter.AND.push(...filterGenerator(FIELDS_LIST)(configFilters));
      }

      if (config.organization && config.organization.id) {
        args.filter.AND.push({
          company: {
            id: {
              equals: config.organization.id,
            },
          },
        });
      }

      args.filter.AND.push({
        itemDealDataRelation:
          selectedAlliance && selectedAlliance.id
            ? { alliance: { id: { equals: selectedAlliance.id } } }
            : undefined,
        status: { equals: 'OPEN' },
      });

      args.sort = args.sort.map((item) => {
        const key = Object.keys(item)[0];
        const keyValue = SORT_NAME_MAP[key] ? SORT_NAME_MAP[key] : key;
        const keys = keyValue.split('.');
        return createSortValue(keys, item[key]);
      });

      return args;
    }

    handleData = (data) => {
      if (!data) return null;

      const deals = R.pathOr([], ['activeSalesPipeline', 'deals'], data);
      const groupBy = R.pathOr([], ['activeSalesPipeline', 'groupBy'], data);

      const total = this.getDealsTotal(deals);
      const currency = getCurrencyOnSession();

      return {
        deals,
        groupBy,
        total,
        currency,
      };
    };

    render() {
      const selectedAlliance = R.clone(OnNewSession.get().selectedAlliance);
      const { queryArgs } = this.state;

      return (
        <Query
          query={ACTIVE_SALES_PIPELINE_QUERY}
          variables={queryArgs}
          skip={!selectedAlliance || !selectedAlliance.id}>
          {({ loading, data }) =>
            !loading && (
              <WrappedComponent
                {...this.props}
                sharedData={{
                  ...this.state,
                  clientCompany: selectedAlliance.clientCompany,
                  partnerCompany: selectedAlliance.partnerCompany,
                }}
                requestedData={data && this.handleData(data)}
                loading={loading}
              />
            )
          }
        </Query>
      );
    }
  };
};
