import React from 'react';
import PropTypes from 'prop-types';
import { Select } from '@8base/boost';

class DealHeader extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      value: null,
    };
  }

  onChange = (value) => {
    let data = {};

    if (value !== null) {
      data.value = value;
      data.header = this.props.header;
    } else {
      data = null;
    }

    this.setState({ value }, () => {
      this.props.data[this.props.index] = data;
    });
  };

  render() {
    let classError = '';
    if (this.props.addClassError) {
      classError = 'cvs-error';
    }

    return (
      <div className={'cvs-input'}>
        <Select
          className={classError}
          style={{ width: '250px' }}
          value={this.state.value}
          onChange={this.onChange}
          meta={{ error: 'Required' }}
          clearable
          placeholder="Select"
          options={this.props.options}
        />
      </div>
    );
  }
}

DealHeader.propTypes = {
  options: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  header: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  addClassError: PropTypes.bool.isRequired,
};

export { DealHeader };
