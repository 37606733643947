import { IntegrityError, ValidationError } from '../../shared/errors';
import { isValidString } from '../../shared/validators';
import moment from 'moment';

/**
 * Validate nextSteps array elements.
 *
 * @param {Array} nextSteps - Next step to validate.
 */
export const nextStepsValidator = (nextSteps) => {
  if (!Array.isArray(nextSteps)) throw new IntegrityError('Invalid Next Steps');

  nextSteps.forEach((nextStep) => {
    nextStepValidator(nextStep);
  });
};

export const nextStepValidator = (nextStep) => {
  let errorMessages = [];

  if (!nextStep.dueDate || !moment(nextStep.dueDate).isValid())
    errorMessages.push('A Next Step must have a valid Date');

  if (!isValidString(nextStep.description))
    errorMessages.push('A Next Step must have a valid description');

  if (errorMessages.length !== 0) throw new ValidationError(errorMessages);
};
