import styled from '@emotion/styled';
import { Text } from '@8base/boost';

const FooterTotalValue = styled(Text)`
  font-size: 16px;
  margin-left: 12px;
  color: rgb(112, 212, 75);
`;

export { FooterTotalValue };
