import styled from '@emotion/styled';
import { Card } from '@8base/boost';

const ReportFooter = styled(Card.Footer)`
  display: flex;
  align-items: center;
  padding-top: 18px;
  padding-bottom: 18px;
  justify-content: flex-end;
`;

export { ReportFooter };
