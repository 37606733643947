import { DEAL_OPEN } from '../../../shared/status';
import moment from 'moment';

const currentDate = moment().format('YYYY-MM-DD');

/**
 * @deprecated
 * @type {{owner: null, name: string, id: null, status: string}}
 */
export const Deal = {
  id: null,
  name: '',
  owner: null,
  status: DEAL_OPEN,
};

export const DealData = {
  status: DEAL_OPEN,
  customerName: '',
  amount: '0',
  company: {},
  closeDate: currentDate,
  createdDate: currentDate,
  lastActivityDate: currentDate,
  lastContactedDate: currentDate,
  lastModifiedDate: currentDate,
  nextActivityDate: currentDate,
  nextActivityDescription: '',
  salesPerson: '',
  type: '',
  wonReason: '',
  stage: '',
  lostReason: '',
  numberOfTimesContacted: 0,
  numberOfSalesActivities: 0,
  line: '',
  dealSourceId: '',
  associatedDealId: '',
  partnerRegistrationId: '',
  dealReferencable: '',
  owner: null,
  description: '',
};

export type Currency = {
  __typename?: 'Currency',
  id?: string,
  symbol?: string,
  thousandSeparator?: string,
  decimalSeparator?: string,
  name?: string,
};

/**
 * Deal fields used in SalesforceFieldMappingSettingCard.
 */
export const SALESFORCE_DEAL_FIELDS = [
  { id: 'customerName', label: 'Customer Name' },
  { id: 'amount', label: 'Amount' },
  { id: 'dealSourceId', label: 'Deal Source ID' },
  { id: 'partnerRegistrationId', label: 'Partner Registration ID' },
  { id: 'closeDate', label: 'Close Date' },
  { id: 'lostReason', label: 'Lost Reason' },
  { id: 'wonReason', label: 'Won Reason' },
  { id: 'createdDate', label: 'Created Date' },
  { id: 'name', label: 'Deal Name' },
  { id: 'description', label: 'Deal Description' },
  { id: 'stage', label: 'Deal Stage' },
  { id: 'type', label: 'Deal Type' },
  { id: 'salesPerson', label: 'Sales Person' },
  { id: 'lastActivityDate', label: 'Last Activity Date' },
  { id: 'lastContactedDate', label: 'Last Contacted Date' },
  { id: 'lastModifiedDate', label: 'Last Modified Date' },
  { id: 'nextActivityDate', label: 'Next Activity Date' },
  { id: 'nextActivityDescription', label: 'Next Activity Description' },
  { id: 'numberOfSalesActivities', label: 'Number of Sales Activities' },
  { id: 'numberOfTimesContacted', label: 'Number of Times Contacted' },
  { id: 'line', label: 'Product Line' },
];

/**
 * Deal fields used in DealImportedView headers.
 */
export const DEAL_FIELDS = [
  ...SALESFORCE_DEAL_FIELDS,
  { id: 'associatedDealId', label: 'Associated Deal ID' },
  { id: 'dealReferencable', label: 'Deal Referenceable (Y / N)' },
];

/**
 * DEAL STAGES.
 *
 * @type {string}
 */
export const DEAL_STAGE_QUALIFICATION = 'Qualification';
export const DEAL_STAGE_NEEDS_ANALYSIS = 'Needs Analysis';
export const DEAL_STAGE_PROPOSAL_CREATION = 'Proposal Creation';
export const DEAL_STAGE_PROPOSAL_PRESENTATION = 'Proposal Presentation';
export const DEAL_STAGE_NEGOTIATION = 'Negotiation';
export const DEAL_STAGE_CLOSED_WON = 'Closed Won';
export const DEAL_STAGE_CLOSED_LOST = 'Closed Lost';
export const DEAL_STAGES_MAP = {
  [DEAL_STAGE_QUALIFICATION]: 10,
  [DEAL_STAGE_NEEDS_ANALYSIS]: 20,
  [DEAL_STAGE_PROPOSAL_CREATION]: 30,
  [DEAL_STAGE_PROPOSAL_PRESENTATION]: 50,
  [DEAL_STAGE_NEGOTIATION]: 90,
  [DEAL_STAGE_CLOSED_WON]: 100,
  [DEAL_STAGE_CLOSED_LOST]: 0,
};
export const DEAL_ACTIVE_STAGES = [
  DEAL_STAGE_QUALIFICATION,
  DEAL_STAGE_NEEDS_ANALYSIS,
  DEAL_STAGE_PROPOSAL_CREATION,
  DEAL_STAGE_PROPOSAL_PRESENTATION,
  DEAL_STAGE_NEGOTIATION,
];
export const PROPOSAL_ISSUED_STAGES = [
  DEAL_STAGE_PROPOSAL_PRESENTATION,
  DEAL_STAGE_NEGOTIATION,
  DEAL_STAGE_CLOSED_WON,
  DEAL_STAGE_CLOSED_LOST,
];

export const COLUMNSEXCEL = [
  { header: 'ID', key: 'id', width: 32 },
  { header: 'NAME', key: 'name', width: 32 },
  { header: 'DESCRIPTION', key: 'description', width: 32 },
  { header: 'STAGE', key: 'stage', width: 32 },
  { header: 'CUSTOMER_NAME', key: 'customerName', width: 32 },
  { header: 'DEAL_REFERENCABLE', key: 'dealReferencable', width: 32 },
  { header: 'SALES_PERSON', key: 'salesPerson', width: 32 },
  { header: 'AMOUNT', key: 'amount', width: 32 },
  { header: 'TYPE', key: 'type', width: 32 },
  { header: 'NEXT_ACTIVITY_DESCRIPTION', key: 'nextActivityDescription', width: 32 },
  { header: 'NEXT_ACTIVITY_DATE', key: 'nextActivityDate', width: 32 },
  { header: 'CLOSE_DATE', key: 'closeDate', width: 32 },
  { header: 'LAST_ACTIVITY_DATE', key: 'lastActivityDate', width: 32 },
  { header: 'LAST_CONTACTED_DATE', key: 'lastContactedDate', width: 32 },
  { header: 'LAST_MODIFIED_DATE', key: 'lastModifiedDate', width: 32 },
  { header: 'LOST_REASON', key: 'lostReason', width: 32 },
  { header: 'WON_REASON', key: 'wonReason', width: 32 },
  { header: 'NUMBER_OF_SALES_ACTIVITIES', key: 'numberOfSalesActivities', width: 32 },
  { header: 'NUMBER_OF_TIMES_CONTACTED', key: 'numberOfTimesContacted', width: 32 },
  { header: 'line', key: 'line', width: 32 },
  { header: 'COMPANY_NAME', key: 'companyName', width: 32 },
  { header: 'COMPANY_DESCRIPTION', key: 'companyDescription', width: 32 },
];

export const COLUMNSOBJECT = [
  {
    key: 'company',
    value: [{ companyName: 'name' }, { companyDescription: 'description' }],
  },
];
