import styled from '@emotion/styled';

export const ChartSwitcher = styled.button`
  background-color: transparent;
  text-align: center;
  height: 25px;
  line-height: 25px;
  padding-left: 2px;
  padding-right: 2px;
  border: none;
  outline: none;
  float: none;
  cursor: pointer;

  &:last-of-type {
    margin-right: 10px;
  }
`;

export const ChartSwitcherWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 40px;
`;

export const InlineHeading = styled.h4`
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 2.4rem;
  color: #323c47;
`;
