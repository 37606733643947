import { getInvitationFromSearch } from './utils';
import * as toast from '../components/toast/Toast';
import {
  acceptAllianceInvitation,
  acceptAllianceMemberInvitation,
  acceptCompanyInvitationWithoutDispatch,
  fetchAllianceInvitation,
  fetchAllianceMemberInvitation,
  fetchCompanyInvitation,
} from '../modules/settings/invitations/invitations.actions';
import { log } from '@cobuildlab/pure-logger';
import { fetchSession } from '../modules/auth/auth.actions';
import sessionStore, { NEW_SESSION_EVENT } from './SessionStore';

export const SYSTEM_SETTING = {
  DEALS_SALESFORCE_IMPORT: 'SALES_FORCE_DEALS_IMPORT',
};

/**
 * Handle alliance invitation link on session component
 // Must return on catch error on the session component.
 *
 * @param {object} user - User.
 * @param {object} companyUser - Company User.
 * @param {object} history - History.
 * @returns {Promise<void>} Promise.
 */
export async function handleAllianceInvitationLink(user, companyUser, history) {
  log('handleAllianceInvitationLink:', user, companyUser);
  const invitation = getInvitationFromSearch();

  // remove invitation query strings
  localStorage.removeItem('search');

  const { allianceInvitation } = await fetchAllianceInvitation(invitation.id);
  await acceptAllianceInvitation(allianceInvitation, companyUser);
  history.push('/welcome');
}

/**
 * Handle alliance member invitation link on session component
 * // Must return on catch error on the session component.
 *
 * @param  {object}  user - User.
 * @param  {number}  history - META_STEP_NAME.
 */
export async function handleAllianceMemberInvitationLink(user, history) {
  log('handleAllianceMemberInvitationLink:', user);
  let invitation = null;
  try {
    invitation = getInvitationFromSearch();
  } catch (e) {
    log('handleAllianceMemberInvitationLink:error:', e);
    history.push('/wizard');
    return;
  }

  if (!invitation) {
    log('handleAllianceMemberInvitationLink:problem reading the invitation:error', invitation);
    history.push('/wizard');
    return;
  }

  // remove invitation query strings
  localStorage.removeItem('search');

  const { allianceMemberInvitation } = await fetchAllianceMemberInvitation(invitation.id);
  await acceptAllianceMemberInvitation(allianceMemberInvitation);
}

/**
 * Handle alliance member invitation link on session component.
 // Must return on catch error on the session component.
 *
 * @param {object} user - The user.
 * @param {object} history - The history object.
 * @returns {Promise} The accepted invitation.
 */
export async function handleCompanyInvitationLink(user, history) {
  let invitation = null;
  try {
    invitation = getInvitationFromSearch();
  } catch (e) {
    log('handleCompanyInvitationLink:error:', e);
    history.push('/wizard');
    return;
  }
  if (!invitation) {
    log('handleCompanyInvitationLink:problem reading the invitation:error', invitation);
    history.push('/wizard');
    return;
  }
  const { companyInvitation } = await fetchCompanyInvitation(invitation.id);

  // logout if not my invitation
  if (companyInvitation && user.email.toLowerCase() !== companyInvitation.email.toLowerCase()) {
    throw new Error(`Can't accept company invitation, not invitation owner`);
  }

  // remove invitation query strings
  localStorage.removeItem('search');

  let response;
  try {
    response = await acceptCompanyInvitationWithoutDispatch(companyInvitation);
  } catch (e) {
    return toast.error(e.message);
  }

  // TODO: Here we have a dead loop all over again:
  // If I do history.push before the fetchSession, the Session component renders the
  // Company Invitation Dialog. That is not good, because I just accepted the invitations.
  // If a call the fetchSession and I wait, when the NEW_SESSION_EVENT is dispatched, the url for
  // the invitation still remain, so the system tries to accept the invitation again.
  // I'm stuck with option 2, and when the system can't accept the invitation because the user
  // already did, then throws a silent error. :S
  toast.success('Company Invitation successfully accepted');
  await fetchSession();
  history.push('/wizard');
  return response;
}

/**
 * Returns the system status of determined feature.
 *
 * @param {string} feature - Feature Name.
 * @returns {boolean} - System Status.
 */
export function getSystemSettingStatus(feature) {
  const { systemSettingsList } = sessionStore.getState(NEW_SESSION_EVENT);

  if (!systemSettingsList) return false;

  return systemSettingsList.items
    .filter((systemFeature) => systemFeature.name === feature)
    .some((systemFeature) => systemFeature.state);
}
