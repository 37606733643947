import sessionStore, { APOLLO_CLIENT, NEW_SESSION_EVENT } from '../../../shared/SessionStore';
import {
  sanitize8BaseBigInt,
  sanitize8BaseReconnectsFromObjects,
  sanitize8BaseReferenceFromObject,
  sanitize8BaseReferencesFromObjects,
} from '../../../shared/utils';
import Flux from '@cobuildlab/flux-state';
import { IntegrityError } from '../../../shared/errors';
import { validateRiskData } from './risk-validators';
import { error, log } from '@cobuildlab/pure-logger';
import {
  RISK_AUTO_SAVE_CREATE_ERROR_EVENT,
  RISK_AUTO_SAVE_CREATE_EVENT,
  RISK_COMPLETED_EVENT,
  RISK_CREATE_EVENT,
  RISK_DETAIL_EVENT,
  RISK_ERROR_EVENT,
  RISK_RESTORE_EVENT,
  RISK_UPDATE_EVENT,
} from './risk-store';
import {
  RISK_COMMENTS_QUERY,
  RISK_CREATE_MUTATION,
  RISK_DETAIL_QUERY,
  RISK_UPDATE_MUTATION,
} from './risk-queries';
import {
  COMMENT_CREATE_EVENT,
  COMMENT_ERROR_EVENT,
  COMMENT_REQUEST_EVENT,
  COMMENTS_EVENT,
} from '../../comment/comment-store';
import { RISK_TYPE } from '../../../shared/item-types';
import { COMMENTS_CREATE_MUTATION } from '../../comment/comment-queries';
import { RISK_COMPLETED, RISK_OPEN } from '../../../shared/status';
import { RELATED_ITEM_UPDATE_MUTATION } from '../../related-item/related-item-queries';
import { canCompletedRisk, canRestoreRisk } from '@cobuildlab/collabtogrow-permissions';
import {
  completeItemsNextSteps,
  deleteNextSteps,
  nextStepsToBeCreated,
  sanitizeNextStepsCreate,
  sanitizeNextStepsToEdit,
  updateNextSteps,
} from '../../next-step/next-step-actions';
import * as R from 'ramda';
import {
  normalize8baseDocumentsCreate,
  normalize8baseDocumentsDeleteAndUpdate,
} from '@cobuildlab/8base-utils';
import {
  OnRiskError,
  OnRiskCreate,
  OnRiskAutoSave,
  OnRiskAutoSaveError,
  OnRiskDetail,
  OnRiskUpdate,
  OnRiskCompleted,
  OnRiskRestore,
} from './risk-events';
import {
  OnCommentError,
  OnCommentRequest,
  OnCommentCreate,
  OnComment,
} from '../../comment/comment-events';

/**
 * Notifies a Request for Comments for a Risk.
 */
export const openComments = ({ id }) => {
  OnCommentRequest.dispatch({ type: RISK_TYPE, id: id });
  Flux.dispatchEvent(COMMENT_REQUEST_EVENT, { type: RISK_TYPE, id: id });
};

/**
 * Create a Risk.
 *
 * @param {object} risk - Risks.
 * @param {Array} relatedItems - RelatedItems.
 * @param {object} initiatives - Initiatives.
 * @param {object} savedRisk - Saved risk.
 * @returns {Promise<void>} Return promise.
 */
export const createRisk = async (risk, relatedItems, initiatives, savedRisk) => {
  const { selectedAlliance } = sessionStore.getState(NEW_SESSION_EVENT);
  const allianceId = selectedAlliance.id;
  const client = sessionStore.getState(APOLLO_CLIENT);

  if (!allianceId) {
    OnRiskError.dispatch(new IntegrityError('Must have an Active Alliance'));

    return Flux.dispatchEvent(RISK_ERROR_EVENT, new IntegrityError('Must have an Active Alliance'));
  }

  log('RiskData: ', risk, relatedItems, initiatives);

  try {
    validateRiskData(risk, initiatives, selectedAlliance);
  } catch (err) {
    error('createRisk', err);
    OnRiskError.dispatch(err);
    return Flux.dispatchEvent(RISK_ERROR_EVENT, err);
  }

  log('createRisk', risk);

  try {
    const createdRisk = await createOrUpdateRisk(risk, relatedItems, initiatives, savedRisk);
    log('riskCreated', createdRisk);

    await client.resetStore();
    OnRiskCreate.dispatch(createdRisk);
    Flux.dispatchEvent(RISK_CREATE_EVENT, createdRisk);
  } catch (e) {
    error('createRisk', e);
    OnRiskError.dispatch(e);
    Flux.dispatchEvent(RISK_ERROR_EVENT, e);
  }
};

/**
 * Create a Risk.
 *
 * @param {object}risk - Risks.
 * @param {Array}relatedItems - RelatedItems.
 * @param {object}initiatives - Initiatives.
 * @returns {Promise<void>} Return promise.
 */
export const _createRisk = async (risk, relatedItems, initiatives) => {
  const client = sessionStore.getState(APOLLO_CLIENT);
  const { selectedAlliance } = sessionStore.getState(NEW_SESSION_EVENT);
  const allianceId = selectedAlliance.id;

  delete risk.createdAt;
  delete risk.status;
  delete risk.id;

  if (!allianceId) throw new IntegrityError('Must have an Active Alliance');

  // Transforming into 8base 'connect' relationships
  risk.initiatives = initiatives;
  normalize8baseDocumentsCreate(risk, 'documents');
  sanitize8BaseReferencesFromObjects(risk, 'initiatives');
  sanitize8BaseReferenceFromObject(risk, 'assignedTo');
  sanitize8BaseReferenceFromObject(risk, 'source');
  sanitize8BaseBigInt(risk, 'unitMonetizationFactor');
  sanitizeNextStepsCreate(risk);

  risk.itemRiskRelation = {
    create: {
      alliance: { connect: { id: allianceId } },
      itemsRelated: {
        connect: relatedItems.map((item) => ({ id: item.itemId })),
      },
    },
  };

  const {
    data: { riskCreate },
  } = await client.mutate({
    mutation: RISK_CREATE_MUTATION,
    variables: { data: risk },
  });
  log('createRisk', riskCreate);

  return riskCreate;
};

export const createOrUpdateRisk = (risk, relatedItems, initiatives, savedRisk) => {
  if (!savedRisk) {
    return _createRisk(risk, relatedItems, initiatives);
  } else {
    const originalData = R.clone(savedRisk);
    originalData.documents = originalData.documents.items;

    const originalNextSteps = sanitizeNextStepsToEdit(originalData);

    risk.itemRiskRelation = savedRisk.itemRiskRelation;
    risk.id = savedRisk.id;

    return _updateRisk(risk, relatedItems, initiatives, originalNextSteps, originalData);
  }
};

/**
 * Auto-Save Create a Risk.
 *
 * @param {object} risk - Risks.
 * @param {Array} relatedItems - RelatedItems.
 * @param {object} initiatives - Initiatives.
 * @param {object} savedRisk - Saved Risk.
 * @returns {Promise<void>} Return promise.
 */
export const autoSaveCreateRisk = async (risk, relatedItems, initiatives, savedRisk) => {
  console.log('Auto-Save: ', {
    risk,
    relatedItems,
    initiatives,
    savedRisk,
  });

  const client = sessionStore.getState(APOLLO_CLIENT);

  try {
    const _risk = await createOrUpdateRisk(risk, relatedItems, initiatives, savedRisk);

    await client.resetStore();
    OnRiskAutoSave.dispatch(_risk);
    Flux.dispatchEvent(RISK_AUTO_SAVE_CREATE_EVENT, _risk);
    console.log('autoSaveCreateRisk:response', _risk);
    return _risk;
  } catch (e) {
    OnRiskAutoSaveError.dispatch(e);
    Flux.dispatchEvent(RISK_AUTO_SAVE_CREATE_ERROR_EVENT, e);
    console.error('autoSaveCreateRisk', e);
  }
};

/**
 * Auto-Save Update a Risk.
 *
 * @param {object} riskData - Risk.
 * @param {Array}relatedItems - RelatedItems.
 * @param {object}initiatives - Initiatives.
 * @param {Array}originalNextSteps - OriginalNextSteps.
 * @param {Array}originalData - OriginalDocuments.
 * @returns {Promise<void|*>} Return promise.
 */
export const autoSaveUpdateRisk = async (
  riskData,
  relatedItems,
  initiatives,
  originalNextSteps,
  originalData,
) => {
  const client = sessionStore.getState(APOLLO_CLIENT);

  try {
    const risk = await _updateRisk(
      riskData,
      relatedItems,
      initiatives,
      originalNextSteps,
      originalData,
    );

    await client.resetStore();
    OnRiskAutoSave.dispatch(risk);
    Flux.dispatchEvent(RISK_AUTO_SAVE_CREATE_EVENT, risk);
    console.log('autoSaveUpdateRisk:response', risk);
    return risk;
  } catch (e) {
    console.error('autoSaveUpdateRisk:error', e);
  }
};

/**
 * Create a comment on a Risk.
 *
 * @param {string}riskId - Risk Id.
 * @param {Array}comment - Comment.
 * @returns {Promise<*>} Return promise.
 */
export const createRiskComment = async (riskId, comment) => {
  const client = sessionStore.getState(APOLLO_CLIENT);
  const data = {
    comment,
    riskCommentsRelation: { connect: { id: riskId } },
  };

  let response;
  try {
    response = await client.mutate({
      mutation: COMMENTS_CREATE_MUTATION,
      variables: { data },
    });
  } catch (e) {
    error('createRiskComment', e);
    OnCommentError.dispatch(e);
    return Flux.dispatchEvent(COMMENT_ERROR_EVENT, e);
  }
  log('createRiskComment', response);
  OnCommentCreate.dispatch(response.data);
  Flux.dispatchEvent(COMMENT_CREATE_EVENT, response.data);
  return response.data;
};

/**
 * Fetches the Risk Item Comments.
 *
 * @param {string}riskId - Risk Id.
 * @returns {Promise<void>} Return promise.
 */
export const fetchRiskComments = async (riskId) => {
  const client = sessionStore.getState(APOLLO_CLIENT);
  const data = { id: riskId };
  const { user } = sessionStore.getState(NEW_SESSION_EVENT);
  let response;
  try {
    response = await client.query({
      query: RISK_COMMENTS_QUERY,
      variables: data,
      fetchPolicy: 'network-only',
    });
  } catch (e) {
    error('fetchRiskComments', e);
    OnCommentError.dispatch(e);
    return Flux.dispatchEvent(COMMENT_ERROR_EVENT, e);
  }
  log('fetchRiskComments', response);

  const comments = R.clone(response.data.risk.comments);

  comments.items = response.data.risk.comments.items.map((comment) => ({
    ...comment,
  }));

  comments.userId = user.id;

  OnComment.dispatch(comments);
  Flux.dispatchEvent(COMMENTS_EVENT, comments);

  return comments;
};

/**
 * Fetches the Risk Item.
 *
 * @param {string} riskId - Risk Id.
 * @param {object} options - Object options.
 * @returns {Promise<void>} Return promise.
 */
export const fetchRisk = async (riskId, options = {}) => {
  const client = sessionStore.getState(APOLLO_CLIENT);
  const data = { id: riskId };
  const fetchPolicy = options.isCacheFirst ? 'cache-first' : 'network-only';

  let response;

  try {
    response = await client.query({
      query: RISK_DETAIL_QUERY,
      variables: data,
      fetchPolicy: fetchPolicy,
    });
  } catch (e) {
    error('fetchRisk', e);
    throw e;
  }
  log('fetchRisk', response);
  return response.data;
};

/**
 * Fetches the Risk Item and dispatchEvent.
 *
 * @param {string} riskId - Risk Id.
 * @param {object} options - Object options.
 * @returns {Promise<void>} Return promise.
 */
export const fetchRiskDetail = async (riskId, options = {}) => {
  let response;

  try {
    response = await fetchRisk(riskId, options);
  } catch (e) {
    OnRiskError.dispatch(e);
    return Flux.dispatchEvent(RISK_ERROR_EVENT, e);
  }

  OnRiskDetail.dispatch(response);
  Flux.dispatchEvent(RISK_DETAIL_EVENT, response);
  return response;
};

/**
 * Update a Risk.
 *
 * @param {object} riskData - Risk.
 * @param {Array}relatedItems - RelatedItems.
 * @param {object}initiatives - Initiatives.
 * @param {Array}originalNextSteps - OriginalNextSteps.
 * @param {Array}originalData - OriginalDocuments.
 * @returns {Promise<void|*>} Return promise.
 */
export const updateRisk = async (
  riskData,
  relatedItems,
  initiatives,
  originalNextSteps,
  originalData,
) => {
  const { selectedAlliance } = sessionStore.getState(NEW_SESSION_EVENT);
  const client = sessionStore.getState(APOLLO_CLIENT);

  log('riskUpdate', riskData, relatedItems, initiatives, originalNextSteps);

  try {
    validateRiskData(riskData, initiatives, selectedAlliance);
  } catch (e) {
    error('Update Risk Validator', e);
    OnRiskError.dispatch(e);
    return Flux.dispatchEvent(RISK_ERROR_EVENT, e);
  }

  try {
    const updatedRisk = await _updateRisk(
      riskData,
      relatedItems,
      initiatives,
      originalNextSteps,
      originalData,
    );

    await client.resetStore();
    OnRiskUpdate.dispatch(updatedRisk);
    Flux.dispatchEvent(RISK_UPDATE_EVENT, updatedRisk);

    return updatedRisk;
  } catch (e) {
    error('updateRisk', e, riskData);
    OnRiskError.dispatch(e);
    Flux.dispatchEvent(RISK_ERROR_EVENT, e);
  }
};

/**
 * Update a Risk.
 *
 * @param {object} riskData - Risk.
 * @param {Array}relatedItems - RelatedItems.
 * @param {object}initiatives - Initiatives.
 * @param {Array}originalNextSteps - OriginalNextSteps.
 * @param {Array}originalData - OriginalDocuments.
 * @returns {Promise<void|*>} Return promise.
 */
export const _updateRisk = async (
  riskData,
  relatedItems,
  initiatives,
  originalNextSteps,
  originalData,
) => {
  const client = sessionStore.getState(APOLLO_CLIENT);
  const itemId = riskData.itemRiskRelation.id;

  delete riskData.itemRiskRelation;
  delete riskData.__typename;
  delete riskData.createdAt;
  delete riskData.createdBy;
  delete riskData.itemId;

  log('riskUpdate', riskData, relatedItems, initiatives, originalNextSteps);

  riskData.initiatives = initiatives;
  sanitize8BaseReconnectsFromObjects(riskData, 'initiatives');
  normalize8baseDocumentsDeleteAndUpdate(riskData, 'documents', originalData);
  sanitize8BaseReferenceFromObject(riskData, 'assignedTo');
  sanitize8BaseBigInt(riskData, 'unitMonetizationFactor');
  sanitize8BaseReferenceFromObject(riskData, 'source');

  // nextSteps to be created
  const nextSteps = R.clone(riskData.nextSteps);
  riskData.nextSteps = nextStepsToBeCreated(nextSteps);
  sanitizeNextStepsCreate(riskData);

  // update and delete nextSteps
  await deleteNextSteps(nextSteps, originalNextSteps);
  await updateNextSteps(nextSteps, originalNextSteps);

  const {
    data: { riskUpdate },
  } = await client.mutate({
    mutation: RISK_UPDATE_MUTATION,
    variables: { data: riskData },
  });

  // Reconnect related items
  const relatedItemsData = {
    id: itemId,
    itemsRelated: {
      reconnect: relatedItems.map((i) => ({ id: i.itemId })),
    },
  };

  await client.mutate({
    mutation: RELATED_ITEM_UPDATE_MUTATION,
    variables: { data: relatedItemsData },
  });

  return riskUpdate;
};

/**
 * Change item status the open to completed.
 *
 * @param {object} riskData - Risk.
 * @param {object} options - Object options.
 * @returns {Promise<void|*>} Return promise.
 */
export const completedRisk = async (riskData, options = {}) => {
  const client = sessionStore.getState(APOLLO_CLIENT);
  const { selectedAlliance, user } = sessionStore.getState(NEW_SESSION_EVENT);
  const { risk } = await fetchRisk(riskData.id, options);

  if (!canCompletedRisk(user, risk, selectedAlliance)) {
    OnRiskError.dispatch(new IntegrityError('You do not have permission to perform this action'));

    return Flux.dispatchEvent(
      RISK_ERROR_EVENT,
      new IntegrityError('You do not have permission to perform this action'),
    );
  }

  let response;

  try {
    response = await client.mutate({
      mutation: RISK_UPDATE_MUTATION,
      variables: {
        data: {
          id: risk.id,
          status: RISK_COMPLETED,
        },
      },
    });
  } catch (e) {
    console.error('completedRisk', e, risk);
    OnRiskError.dispatch(e);
    return Flux.dispatchEvent(RISK_ERROR_EVENT, e);
  }

  try {
    await completeItemsNextSteps(risk);
  } catch (e) {
    log('completeItemsNextStepsError', e);
  }

  await client.resetStore();
  OnRiskCompleted.dispatch(response.data);
  Flux.dispatchEvent(RISK_COMPLETED_EVENT, response.data);
  return response.data;
};

/**
 * Restore risk item, change status the completed to open.
 *
 * @param {object} riskData - Risk.
 * @param {object} options - Object options.
 * @returns {Promise<void|*>} Return Promise.
 */
export const restoreRisk = async (riskData, options = {}) => {
  const client = sessionStore.getState(APOLLO_CLIENT);
  const { selectedAlliance, user } = sessionStore.getState(NEW_SESSION_EVENT);
  const { risk } = await fetchRisk(riskData.id, options);

  if (!canRestoreRisk(user, risk, selectedAlliance)) {
    OnRiskError.dispatch(new IntegrityError('You do not have permission to perform this action'));

    return Flux.dispatchEvent(
      RISK_ERROR_EVENT,
      new IntegrityError('You do not have permission to perform this action'),
    );
  }

  let response;

  try {
    response = await client.mutate({
      mutation: RISK_UPDATE_MUTATION,
      variables: {
        data: {
          id: risk.id,
          status: RISK_OPEN,
        },
      },
    });
  } catch (e) {
    console.error('restoredRisk', e, risk);
    OnRiskError.dispatch(e);
    return Flux.dispatchEvent(RISK_ERROR_EVENT, e);
  }

  await client.resetStore();
  OnRiskRestore.dispatch(response.data);
  Flux.dispatchEvent(RISK_RESTORE_EVENT, response.data);
  return response.data;
};
