import { createEvent } from '@cobuildlab/react-simple-state';

export const OnContributionCreate = createEvent();
export const OnContributionError = createEvent();
export const OnContributionAutoSave = createEvent();
export const OnContributionAutoSaveError = createEvent();
export const OnContributionDetail = createEvent();
export const OnContributionUpdate = createEvent();
export const OnContributionCompleted = createEvent();
export const OnContributionRestore = createEvent();
