import React from 'react';
import { Text } from '@8base/boost';
import PropTypes from 'prop-types';
import { CommentDate } from './CommentDate';
import styled from '@emotion/styled';

const StyledUserName = styled(Text)`
  height: 18px;
  width: 177.97px;
  color: #47525d;
  font-family: Poppins;
  font-size: 12px;
  line-height: 18px;
  display: block;
`;

const CommentInfoUser = ({ username, date }) => (
  <div className={'CommentInfoUser'}>
    <StyledUserName>{username}</StyledUserName>
    <CommentDate date={date} />
  </div>
);

CommentInfoUser.propTypes = {
  username: PropTypes.string.isRequired,
  date: PropTypes.object.isRequired,
};

export { CommentInfoUser };
