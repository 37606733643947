import React from 'react';
import { Card, Heading, Row, Loader } from '@8base/boost';
import InitiativeDetailTable from './components/InitiativeDetailTable';
import sessionStore, { NEW_SESSION_EVENT } from '../../../shared/SessionStore';
import * as toast from '../../../components/toast/Toast';
import {
  fetchInitiative,
  approveInitiative,
  rejectInitiative,
  completedInitiative,
  openComments,
  getInitiativeRelationItem,
  requestApprovalForInitiative,
  restoreInitiative,
} from './initiative-actions';
import {
  canRejectInitiative,
  canApproveInitiative,
  canCompletedInitiative,
  canSubmitForApprovalInitiative,
  canRestoreInitiative,
} from './initiative-permissions';
import { withRouter } from 'react-router-dom';
import withAlliance from '../../../components/hoc/withAlliance';
import PropTypes from 'prop-types';
import { View } from '@cobuildlab/react-simple-state';
import { onErrorMixin } from '../../../shared/mixins';
import BusinessCaseDetailTable from '../../document-management/business-case/components/BusinessCaseDetailTable';
import YesNoDialog from '../../../components/dialogs/YesNoDialog';
import * as R from 'ramda';
import InitiativeModel from './initiative-model';
import BusinessCaseModel from '../../document-management/business-case/BusinessCase.model';
import { DangerButton } from '../../../components/buttons/DangerButton';
import { CreateViewCardBody } from '../../../components/new-ui/card/CreateViewCardBody';
import { ActionButton } from '../../../components/buttons/ActionButton';
import { getCurrencyOnSession } from '../../../shared/alliance-utils';
import ApprovalDetailTable from '../../../components/tables/approvalTable/ApprovalDetailTable';
import { RelatedItemsDetailTable } from '../../related-item/components/RelatedItemsDetailTable';
import { TopButtons } from '../../../components/buttons/TopButtons';
import { CardFooter } from '../../../components/new-ui/card/CardFooter';
import {
  OnInitiativeDetail,
  OnInitiativeError,
  OnInitiativeSubmitForApproval,
  OnInitiativeRestore,
  OnInitiativeUpdate,
  OnInitiativeReject,
  OnInitiativeCompleted,
} from '../initiative/initiative-events';

class InitiativeDetailView extends View {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        initiativeData: R.clone(InitiativeModel),
        businessCaseData: R.clone(BusinessCaseModel),
        approvalData: [],
        relatedItems: [],
      },
      loading: true,
      step: 0,
      approvalModalIsOpen: false,
      rejectModalIsOpen: false,
      completedModalIsOpen: false,
      submitForApprovalModalIsOpen: false,
      restoreModalIsOpen: false,
    };
    this.onError = onErrorMixin.bind(this);
    this.user = sessionStore.getState(NEW_SESSION_EVENT).user;
    this.selectedAlliance = sessionStore.getState(NEW_SESSION_EVENT).selectedAlliance;
  }

  componentDidMount() {
    const { match } = this.props;

    this.subscribe(OnInitiativeError, this.onError);

    this.subscribe(OnInitiativeDetail, (state) => {
      const initiativeData = R.clone(state.initiative);
      const businessCaseData = R.clone(initiativeData.businessCase);
      const initiativeApprovalsList = R.clone(initiativeData.initiativeApprovalInitiativeRelation);

      const { data } = this.state;
      data.initiativeData = initiativeData;
      data.businessCaseData = businessCaseData;
      data.relatedItems = getInitiativeRelationItem(R.clone(initiativeData));

      const approvalData = initiativeApprovalsList.items.slice(0, 2);
      const approvalDataList = initiativeApprovalsList.items.map((initiativeApproval) => ({
        ...initiativeApproval,
      }));

      const submitForApprovalAlliance = initiativeApprovalsList.items.slice(0, 2);
      data.approvalData = approvalData;

      this.setState({
        data,
        approvalDataList,
        submitForApprovalAlliance,
        loading: false,
      });
    });

    this.subscribe(OnInitiativeUpdate, () => {
      fetchInitiative(match.params.id);
      toast.success('Initiative Successfully Approved');
    });
    this.subscribe(OnInitiativeReject, () => {
      fetchInitiative(match.params.id);
      toast.success('Initiative Successfully Rejected');
    });
    this.subscribe(OnInitiativeCompleted, (state) => {
      fetchInitiative(match.params.id);
      toast.success('Initiative Successfully Completed');
    });

    this.subscribe(OnInitiativeRestore, (state) => {
      fetchInitiative(match.params.id);
      toast.success('Initiative Successfully Restore');
    });

    this.subscribe(OnInitiativeSubmitForApproval, () => {
      fetchInitiative(match.params.id);
      toast.success('Initiative Submitted!');
    });

    if (!match.params.id) return toast.error('Initiative ID missing');
    const fetchPolicyOptions = { isCacheFirst: true };
    fetchInitiative(match.params.id, fetchPolicyOptions);
  }

  approve = () => {
    this.setState({
      approvalModalIsOpen: true,
    });
  };

  onYesApprove = () => {
    this.setState(
      {
        approvalModalIsOpen: false,
        loading: true,
      },
      () => {
        const initiativeData = R.clone(this.state.data.initiativeData);
        approveInitiative(initiativeData);
      },
    );
  };

  onCloseApprove = () => {
    this.setState({
      approvalModalIsOpen: false,
    });
  };

  reject = () => {
    this.setState({
      rejectModalIsOpen: true,
    });
  };

  onYesReject = () => {
    this.setState(
      {
        rejectModalIsOpen: false,
        loading: true,
      },
      () => {
        const initiativeData = R.clone(this.state.data.initiativeData);
        rejectInitiative(initiativeData);
      },
    );
  };

  onCloseReject = () => {
    this.setState({
      rejectModalIsOpen: false,
    });
  };

  completedModal = () => {
    this.setState({
      completedModalIsOpen: true,
    });
  };

  restoreModal = () => {
    this.setState({
      restoreModalIsOpen: true,
    });
  };

  onCloseRestore = () => {
    this.setState({
      restoreModalIsOpen: false,
    });
  };

  onYesCompleted = () => {
    this.setState(
      {
        completedModalIsOpen: false,
        loading: true,
      },
      () => {
        const initiativeData = R.clone(this.state.data.initiativeData);
        completedInitiative(initiativeData);
      },
    );
  };

  onYesRestore = () => {
    this.setState(
      {
        restoreModalIsOpen: false,
        loading: true,
      },
      () => {
        const initiativeData = R.clone(this.state.data.initiativeData);
        restoreInitiative(initiativeData);
      },
    );
  };

  onCloseCompleted = () => {
    this.setState({
      completedModalIsOpen: false,
    });
  };

  onSelectSubmitForApproval = (initiative) => {
    const [canSubmit, errors] = canSubmitForApprovalInitiative(
      this.user,
      initiative,
      this.selectedAlliance,
    );
    if (canSubmit) {
      this.setState({
        initiative,
        submitForApprovalModalIsOpen: true,
      });
    } else {
      toast.errors(errors);
    }
  };

  onYesSubmitForApproval = () => {
    const {
      data: { initiativeData: initiative },
    } = this.state;
    this.setState(
      {
        submitForApprovalModalIsOpen: false,
        loading: true,
      },
      () => requestApprovalForInitiative(initiative),
    );
  };
  onCloseSubmitForApproval = () => {
    this.setState({
      submitForApprovalModalIsOpen: false,
    });
  };

  render() {
    const {
      data,
      loading,
      approvalModalIsOpen,
      rejectModalIsOpen,
      completedModalIsOpen,
      submitForApprovalModalIsOpen,
      restoreModalIsOpen,
      approvalDataList,
    } = this.state;
    const { initiativeData, businessCaseData, approvalData, relatedItems } = data;
    const { history, allianceId } = this.props;
    const currency = getCurrencyOnSession();
    const alliance = this.selectedAlliance;

    let content = <Loader stretch />;
    let buttonsBottom = '';
    let buttonsTop = '';

    const [canSubmit] = canSubmitForApprovalInitiative(
      this.user,
      initiativeData,
      this.selectedAlliance,
    );

    if (!loading) {
      content = (
        <div id={'c2gScreen'}>
          <InitiativeDetailTable
            data={initiativeData}
            alliance={alliance}
            onClickEdit={() => history.push(`/management/initiative/edit/${initiativeData.id}`)}
          />
          <BusinessCaseDetailTable
            hideExpectedCostAvoidance
            data={businessCaseData}
            currency={currency}
          />
          <ApprovalDetailTable data={approvalData} />
          <ApprovalDetailTable data={approvalDataList} title={'APPROVAL HISTORY'} />
          <RelatedItemsDetailTable relatedItems={relatedItems} />
        </div>
      );

      buttonsTop = (
        <>
          <Heading type="h4" text={initiativeData.name} />

          <TopButtons
            onClickClosed={history.goBack}
            onClickCollaborated={() => openComments(initiativeData)}
          />
        </>
      );

      buttonsBottom = (
        <Row justifyContent="end">
          {canRejectInitiative(this.user, initiativeData, {
            id: this.props.allianceId,
          }) ? (
              <DangerButton text={'Reject'} fontAwesomeIcon={'times'} onClick={this.reject} />
            ) : null}
          {canApproveInitiative(this.user, initiativeData, {
            id: allianceId,
          }) ? (
              <ActionButton fontAwesomeIcon={'check'} onClick={this.approve} text={'Approve'} />
            ) : null}
          {canCompletedInitiative(this.user, initiativeData, alliance) ? (
            <ActionButton
              text="Mark Completed"
              fontAwesomeIcon="clipboard-list"
              onClick={() => {
                this.completedModal();
              }}
            />
          ) : null}
          {canSubmit && (
            <ActionButton
              text="Submit For Approval"
              fontAwesomeIcon="check"
              onClick={() => {
                this.onSelectSubmitForApproval(initiativeData);
              }}
            />
          )}

          {canRestoreInitiative(this.user, initiativeData, alliance) ? (
            <ActionButton
              text="Restore"
              onClick={() => {
                this.restoreModal();
              }}
            />
          ) : null}
        </Row>
      );
    }

    return (
      <React.Fragment>
        <Card.Header>{buttonsTop}</Card.Header>
        <CreateViewCardBody>{content}</CreateViewCardBody>
        <CardFooter>{buttonsBottom}</CardFooter>
        <YesNoDialog
          title={'Approve Initiative'}
          onYes={this.onYesApprove}
          onClose={this.onCloseApprove}
          onNo={this.onCloseApprove}
          text={'Are you sure you want to Approve the Initiative?'}
          isOpen={approvalModalIsOpen}
        />
        <YesNoDialog
          title={'Reject Initiative'}
          onYes={this.onYesReject}
          onClose={this.onCloseReject}
          onNo={this.onCloseReject}
          text={'Are you sure you want to Reject the Initiative?'}
          isOpen={rejectModalIsOpen}
        />
        <YesNoDialog
          title={'Complete Initiative'}
          onYes={this.onYesCompleted}
          onClose={this.onCloseCompleted}
          onNo={this.onCloseCompleted}
          text={'Are you sure you want to Mark the Initiative as Completed?'}
          isOpen={completedModalIsOpen}
        />
        <YesNoDialog
          title={'Restore Initiative'}
          onYes={this.onYesRestore}
          onClose={this.onCloseRestore}
          onNo={this.onCloseRestore}
          text={'Are you sure you want to Restore the Initiative?'}
          isOpen={restoreModalIsOpen}
        />
        <YesNoDialog
          isOpen={submitForApprovalModalIsOpen}
          onYes={this.onYesSubmitForApproval}
          onNo={this.onCloseSubmitForApproval}
          onClose={this.onCloseSubmitForApproval}
          text={'Are you sure you want to Submit this Initiative For Approval?'}
          title={'Submit For Approval'}
        />
      </React.Fragment>
    );
  }
}

InitiativeDetailView.propTypes = {
  history: PropTypes.object.isRequired,
};

export default withRouter(withAlliance(InitiativeDetailView));
