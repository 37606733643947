import gql from 'graphql-tag';
import { UserFragment } from '../../auth/queries';
import { BusinessCaseFragment } from '../../document-management/business-case/businessCase.queries';

/**
 * List Query.
 * Example:
 * {
    "data": {
      "alliance": {
        "id": {
          "equals": "cjouj32uc000h01rxv4nevfin"
        }.
      }.
    }.
  }.
 */

export const AMO_ITEM_LIST_QUERY = gql`
  query($data: ItemFilter, $skip: Int, $first: Int) {
    itemsList(filter: $data, skip: $skip, first: $first) {
      count
      items {
        id
        createdAt
        action {
          id
          name
          description
          status
          originalDueDate
          revisedDueDate
          businessCase {
            id
          }
          requestedBy {
            ...UserFragment
          }
          initiatives {
            items {
              id
              name
              status
            }
          }
          actionApprovalRelation {
            items {
              dateOfResponse
              status
            }
          }
        }
        issue {
          originalDueDate
          id
          name
          revisedDueDate
          description
          status
          initiatives {
            items {
              id
              name
              status
            }
          }
          createdBy {
            ...UserFragment
          }
        }
        risk {
          originalDueDate
          id
          name
          description
          status
          revisedDueDate
          initiatives {
            items {
              id
              name
              status
            }
          }
          createdBy {
            ...UserFragment
          }
        }
        decision {
          id
          name
          description
          status
          unitType
          unitQuantity
          unitMonetizationFactor
          businessCase {
            id
          }
          initiatives {
            items {
              id
              name
              status
            }
          }
          createdBy {
            ...UserFragment
          }
        }
      }
    }
  }
  ${UserFragment}
`;

export const AMO_ALL_ITEM_LIST_QUERY = gql`
  query($data: ItemFilter, $skip: Int, $first: Int) {
    itemsList(filter: $data, skip: $skip, first: $first) {
      count
      items {
        id
        createdAt
        action {
          id
          name
          description
          status
          originalDueDate
          revisedDueDate
          unitQuantity
          unitType
          unitValueDescription
          unitMonetizationFactor
          businessCase {
            ...BusinessCaseFragment
          }
          requestedBy {
            ...UserFragment
          }
          initiatives {
            items {
              id
              name
              status
            }
          }
          actionApprovalRelation {
            items {
              dateOfResponse
              status
            }
          }
        }
        issue {
          originalDueDate
          id
          name
          revisedDueDate
          description
          status
          unitType
          unitQuantity
          unitValueDescription
          unitMonetizationFactor
          initiatives {
            items {
              id
              name
              status
            }
          }
          createdBy {
            ...UserFragment
          }
        }
        risk {
          originalDueDate
          id
          name
          description
          status
          revisedDueDate
          unitType
          unitQuantity
          unitValueDescription
          unitMonetizationFactor
          initiatives {
            items {
              id
              name
              status
            }
          }
          createdBy {
            ...UserFragment
          }
        }
        decision {
          id
          name
          description
          status
          unitType
          unitQuantity
          unitMonetizationFactor
          businessCase {
            id
          }
          initiatives {
            items {
              id
              name
              status
            }
          }
          createdBy {
            ...UserFragment
          }
        }
      }
    }
  }
  ${UserFragment}
  ${BusinessCaseFragment}
`;

/**
 * Delete.
 */
export const AMO_ITEM_DELETE_MUTATION = gql`
  mutation($data: ItemDeleteInput!) {
    itemDelete(data: $data, force: true) {
      success
    }
  }
`;
