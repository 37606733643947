import sessionStore, { APOLLO_CLIENT } from '../../shared/SessionStore';
import { error, log } from '@cobuildlab/pure-logger';
import Flux from '@cobuildlab/flux-state';
import { NEW_SESSION_EVENT } from '../../shared/SessionStore';
import {
  NOTIFICATIONS_LIST_QUERY,
  NOTIFICATION_DELETE_BY_FILTER_MUTATION,
  NOTIFICATION_DELETE_MUTATION,
} from './notifications-queries';
import {
  NOTIFICATIONS_ERROR_EVENT,
  NOTIFICATIONS_EVENT,
  NOTIFICATIONS_REQUEST_EVENT,
} from './notifications-store';
import { CHAT_TOGGLE_EVENT } from '../chat/chat-store';
import { OnChatToggle } from '../chat/chat-events';
import { OnNotification, OnNotificationError, OnNotificationRequest } from './notifications-events';
import { OnNewSession } from '../../shared/session-events';

const notificationsFilter = (allianceId, userId) => {
  const riskFilter = {
    risk: {
      id: { not_equals: null },
      itemRiskRelation: {
        alliance: { id: { equals: allianceId } },
      },
    },
  };
  const issueFilter = {
    issue: {
      id: { not_equals: null },
      itemIssueRelation: {
        alliance: { id: { equals: allianceId } },
      },
    },
  };
  const ideaFilter = {
    idea: {
      id: { not_equals: null },
      itemIdeaRelation: {
        alliance: { id: { equals: allianceId } },
      },
    },
  };
  const fundingRequestFilter = {
    fundingRequest: {
      id: { not_equals: null },
      itemFundingRequestRelation: {
        alliance: { id: { equals: allianceId } },
      },
    },
  };
  const actionFilter = {
    action: {
      id: { not_equals: null },
      itemActionRelation: {
        alliance: { id: { equals: allianceId } },
      },
    },
  };
  const decisionFilter = {
    decision: {
      id: { not_equals: null },
      itemDecisionRelation: {
        alliance: { id: { equals: allianceId } },
      },
    },
  };
  const contributionFilter = {
    contribution: {
      id: { not_equals: null },
      itemContributionRelation: {
        alliance: { id: { equals: allianceId } },
      },
    },
  };
  const dealDataFilter = {
    dealData: {
      id: { not_equals: null },
      itemDealDataRelation: {
        alliance: { id: { equals: allianceId } },
      },
    },
  };
  const initiativesFilter = {
    initiative: {
      id: { not_equals: null },
      alliance: { id: { equals: allianceId } },
    },
  };
  const alliancesFilter = {
    alliance: {
      id: { not_equals: null },
    },
  };
  const nextStepFilter = {
    nextStep: {
      id: { not_equals: null },
      OR: [
        {
          riskNextStepsRelation: {
            id: { not_equals: null },
            itemRiskRelation: { alliance: { id: { equals: allianceId } } },
          },
        },
        {
          ideaNextStepsRelation: {
            id: { not_equals: null },
            itemIdeaRelation: { alliance: { id: { equals: allianceId } } },
          },
        },
        {
          fundingRequestNextStepsRelation: {
            id: { not_equals: null },
            itemFundingRequestRelation: { alliance: { id: { equals: allianceId } } },
          },
        },
        {
          actionNextStepsRelation: {
            id: { not_equals: null },
            itemActionRelation: { alliance: { id: { equals: allianceId } } },
          },
        },
        {
          issueNextStepsRelation: {
            id: { not_equals: null },
            itemIssueRelation: { alliance: { id: { equals: allianceId } } },
          },
        },
      ],
    },
  };

  return {
    isRead: { equals: false },
    owner: { id: { equals: userId } },
    OR: [
      riskFilter,
      issueFilter,
      ideaFilter,
      fundingRequestFilter,
      actionFilter,
      decisionFilter,
      contributionFilter,
      dealDataFilter,
      initiativesFilter,
      alliancesFilter,
      nextStepFilter,
    ],
  };
};

/**
 * Fetches the notifications.
 *
 * @returns {Promise<void|number|*>} - The notifications for the selected Alliance.
 * @param {number} limit - The maximum amount of notifications.
 */
export const fetchNotifications = async (limit = 10) => {
  const client = sessionStore.getState(APOLLO_CLIENT);
  const { selectedAlliance, user } = OnNewSession.get();

  if (!selectedAlliance) {
    console.error(
      'notifications-actions:fetchNotifications:',
      'Permission Error. No Alliance Selected',
    );
    return;
  }

  const filter = notificationsFilter(selectedAlliance.id, user.id);
  let response;
  try {
    response = await client.query({
      query: NOTIFICATIONS_LIST_QUERY,
      variables: { data: filter, first: limit },
      fetchPolicy: 'network-only',
    });
  } catch (e) {
    error('fetchNotifications', e);
    OnNotificationError.dispatch(e);
    return Flux.dispatchEvent(NOTIFICATIONS_ERROR_EVENT, e);
  }
  log('fetchNotifications', response.data.notificationsList.items);
  OnNotification.dispatch(response.data.notificationsList.items);
  Flux.dispatchEvent(NOTIFICATIONS_EVENT, response.data.notificationsList.items);
  return response.data.notificationsList.items;
};

export const openNotificationsSideBar = () => {
  OnNotificationRequest.dispatch(null);
  Flux.dispatchEvent(NOTIFICATIONS_REQUEST_EVENT, null);
  OnChatToggle.dispatch(false);
  Flux.dispatchEvent(CHAT_TOGGLE_EVENT, false);
};

export const deleteAllNotifications = async () => {
  const client = sessionStore.getState(APOLLO_CLIENT);
  const { selectedAlliance, user } = sessionStore.getState(NEW_SESSION_EVENT);
  if (!selectedAlliance) {
    console.error(
      'notifications-actions:fetchNotifications:',
      'Permission Error. No Alliance Selected',
    );
    return;
  }

  const filter = notificationsFilter(selectedAlliance.id, user.id);
  try {
    await client.mutate({
      mutation: NOTIFICATION_DELETE_BY_FILTER_MUTATION,
      variables: { filter },
    });
  } catch (e) {
    console.warn(e);
  }
};

/**
 * Deletes a notification.
 *
 * @param {string} notificationId - The notification id.
 */
export const deleteNotification = async (notificationId) => {
  const client = sessionStore.getState(APOLLO_CLIENT);
  const data = { id: notificationId, force: true };
  try {
    await client.mutate({
      mutation: NOTIFICATION_DELETE_MUTATION,
      variables: { data },
    });
  } catch (e) {
    console.warn(e);
  }
};
