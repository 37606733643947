import React from 'react';
import PropTypes from 'prop-types';
import { withAuth } from '@8base-react/app-provider';
import MainLoader from '../../../../shared/components/MainLoader';

class AuthContainerClass extends React.Component {
  async componentDidMount() {
    const { auth, location } = this.props;
    let { isEmailVerified } = auth;

    if (!isEmailVerified && location && location.search.length > 0) {
      const queryParams = new URLSearchParams(location.search);
      isEmailVerified = Boolean(queryParams.get('isEmailVerified'));
    }

    await auth.authClient.authorize({
      initialScreen: isEmailVerified ? 'login' : 'signUp',
    });
  }

  render() {
    return <MainLoader />;
  }
}

AuthContainerClass.defaultProps = {
  location: null,
};

AuthContainerClass.propTypes = {
  auth: PropTypes.object.isRequired,
  location: PropTypes.object,
};

const AuthContainer = withAuth(AuthContainerClass);

export { AuthContainer };
