import React from 'react';
import { Card, Grid, Heading, Loader, Row, Text } from '@8base/boost';
import * as toast from '../../components/toast/Toast';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { View } from '@cobuildlab/react-simple-state';
import { onChangeDataMixin, onErrorMixin } from '../../shared/mixins';
import { ActionButton } from '../../components/buttons/ActionButton';
import { TransparentButton } from '../../components/buttons/TransparentButton';
import { DangerButton } from '../../components/buttons/DangerButton';
import { COMPANY_PORTFOLIO_OWNER, COMPANY_PROCUREMENT } from '../../shared/roles';
import { PlanDetailsCard } from './components/PlanDetailsCard';
import { CancelSubscriptionDialog } from './components/CancelSubscriptionDialog';
import { ReactivateSubscriptionDialog } from './components/ReactivateSubscriptionDialog';
import { ConfirmNewPlanDialog } from './components/ConfirmNewPlanDialog';
import { PaymentDetails } from './components/PaymentDetails';
import { ChangePlanDialog } from './components/ChangePlanDialog';
import { PaymentDialogForm } from './components/PaymentDialogForm';
import moment from 'moment';
import { fetchCompanyInformation } from '../wizard/company-actions';
import {
  fetchMembersAction,
  isPlanFreeTrial,
} from '../settings/company-management/company-actions';
import * as R from 'ramda';
import { CreditCard } from './procurer-model';
import { createPaymentMethod } from '../../shared/stripe/stripe';
import {
  cancelSubscription,
  changeCompanySubscriptionPlan,
  fetchPaymentData,
  fetchPortfolioOwnersCompanyInvitations,
  inviteCompanyPortfolioOwner,
  reactivateSubscription,
  retryInvoicePayment,
  updatePayment,
} from './procurer-actions';
import { fetchSession } from '../auth/auth.actions';
import { ElementsConsumer } from '@stripe/react-stripe-js';
import { InvoicesList } from './components/InvoicesList';
import { CompanyInvitationsList } from './components/CompanyInvitationsList';
import { InvitePortfolioOwnerDialog } from './components/InvitePortfolioOwnerDialog';
import {
  getCompanyInvoices,
  showCancelSubscription,
  showInvitePortfolioOwnersButton,
  showReactivateSubscription,
  showRenewSubscription,
} from './procurer-utils';
import { CreateCompanySubscriptionView } from './CreateCompanySubscriptionView';
import { ConfirmRetryPaymentDialog } from './components/ConfirmRetryPaymentDialog';
import { OnWizardError, OnCompanyInformation } from '../wizard/wizard-events';
import {
  OnCompanyError,
  OnCompanyListMemebers,
} from '../settings/company-management/company-events';
import {
  OnProcurerError,
  OnProcurerChangeSubscriptionPlan,
  OnProcurerChangeSubscriptionPlanError,
  OnInviteCompanyPortfolioOwner,
  OnCompanyPortfolioOwnerInvitations,
  OnUpdatePayment,
  OnProcurerCancelSubscription,
  OnProcurerReactiveSubscription,
  OnSubscriptionInvoiceRetry,
  OnSubscriptionInvoiceRetryError,
} from './procurer-events';
import { OnNewSession } from '../../shared/session-events';

class ProcurerBillingView extends View {
  stripe = null;
  checkedInvoice = null;

  constructor(props) {
    super(props);
    this.state = {
      data: R.clone(CreditCard),
      company: {},
      companySubscription: null,
      companySubscriptions: [],
      invoices: [],
      subscriptionPlans: [],
      countries: [],
      portfolioOwners: [],
      portfolioOwnerInvitations: [],
      loadingPortfolioOwnerInvitations: true,
      portFolioOwnerEmail: '',
      loadingCompanyUsers: true,
      loadingInvitePortfolioOwner: false,
      invitePortfolioOwnerDialogIsOpen: false,
      selectedPlan: null,
      confirmNewPlanDialogIsOpen: false,
      confirmNewPlanDialogStatus: '',
      confirmRetryPaymentDialogIsOpen: false,
      retryInvoice: null,
      cancelSubscriptionDialogIsOpen: false,
      changePlanDialogIsOpen: false,
      paymentDialogIsOpen: false,
      loading: true,
      loadingUpdatePayment: false,
      loadingCancelSubscription: false,
      showAnnualPrice: false,
      alliancesList: 0,
    };

    this.onError = onErrorMixin.bind(this);
    this.onChangeData = onChangeDataMixin.bind(this);
  }

  componentDidMount() {
    this.subscribe(OnWizardError, this.onError);

    this.subscribe(OnCompanyError, (e) => {
      this.onError(e);
      this.setState({ loadingCompanyUsers: false });
    });

    this.subscribe(OnProcurerError, (e) => {
      this.onError(e);
      this.setState({
        loadingUpdatePayment: false,
        loadingCancelSubscription: false,
        loadingInvitePortfolioOwner: false,
      });
    });

    this.subscribe(OnCompanyListMemebers, (data) => {
      const {
        companyUsersList: { items: members },
      } = data;

      const portfolioOwners = members.filter(
        ({ role }) => role && role.name === COMPANY_PORTFOLIO_OWNER,
      );

      this.setState({ portfolioOwners, loadingCompanyUsers: false });
    });

    this.subscribe(OnProcurerChangeSubscriptionPlanError, (e) => {
      this.onError(e);
      this.setState({
        loadingUpdatePayment: false,
        loadingCancelSubscription: false,
        confirmNewPlanDialogStatus: 'error',
      });
    });

    this.subscribe(OnNewSession, this.checkSession);

    this.subscribe(OnInviteCompanyPortfolioOwner, async () => {
      await fetchSession();
      this.setState({
        loadingInvitePortfolioOwner: false,
        invitePortfolioOwnerDialogIsOpen: false,
        portFolioOwnerEmail: '',
      });
      toast.success('Invitation succesfully sent.');
    });

    this.subscribe(OnProcurerChangeSubscriptionPlan, async () => {
      await fetchSession();
      this.setState({ confirmNewPlanDialogStatus: 'success' });
      toast.success('Plan succesfully changed.');
    });

    this.subscribe(OnCompanyPortfolioOwnerInvitations, async (data) => {
      const { companyInvitationsList } = data;
      const portfolioOwnerInvitations = companyInvitationsList.items.map((companyInv) => ({
        ...companyInv,
      }));

      this.setState({
        portfolioOwnerInvitations,
        loadingPortfolioOwnerInvitations: false,
      });
    });

    this.subscribe(OnUpdatePayment, async () => {
      await fetchSession();
      this.setState({
        loadingUpdatePayment: false,
        paymentDialogIsOpen: false,
      });
      toast.success('Payment successfully updated.');

      const [invoice] = this.state.invoices;
      if (invoice && invoice.status === 'failed') {
        toast.warn('Retrying last payment...');
        retryInvoicePayment(this.stripe, invoice);
      }
    });

    this.subscribe(OnProcurerCancelSubscription, async () => {
      await fetchSession();
      this.setState({
        loadingCancelSubscription: false,
        cancelSubscriptionDialogIsOpen: false,
      });
      toast.success('Subscription succesfully canceled.');
    });

    this.subscribe(OnProcurerReactiveSubscription, async () => {
      await fetchSession();
      this.setState({
        loadingReactivateSubscription: false,
        reactivateSubscriptionDialogIsOpen: false,
      });
      toast.success('Subscription succesfully reactivated.');
    });

    this.subscribe(OnCompanyInformation, (data) => {
      const { subscriptionPlansList, countriesList } = data;

      const countries = countriesList.items
        .filter(({ code }) => (code ? true : false))
        .map((country) => ({ ...country }));

      const subscriptionPlans = subscriptionPlansList.items.map((subscriptionPlan) => ({
        ...subscriptionPlan,
      }));

      this.setState({
        countries,
        subscriptionPlans,
        loading: false,
      });
    });

    this.subscribe(OnSubscriptionInvoiceRetry, () => {
      toast.success('Invoice payment successful!');
      setTimeout(() => window.location.reload(), 5000);
    });

    this.subscribe(OnSubscriptionInvoiceRetryError, (error) => {
      toast.error(`Payment Retry: ${error.message}`);
    });

    fetchCompanyInformation();

    this.checkSession();
  }

  checkSession = () => {
    const {
      user: {
        companyUserRelation: { items: companyUsers },
      },
    } = OnNewSession.get();
    const companyUserProcurement = companyUsers.find(
      ({ role }) => role.name === COMPANY_PROCUREMENT,
    );

    const company = companyUserProcurement
      ? companyUserProcurement.company
      : companyUsers[0].company;

    const companySubscriptions = company.companyCompanySubscriptionRelation.items;
    const companySubscription = companySubscriptions.length
      ? companySubscriptions[companySubscriptions.length - 1]
      : null;

    const alliancesList = company.allianceClientCompanyRelation.count;

    const { id: companyId } = company;
    fetchPortfolioOwnersCompanyInvitations(companyId);
    fetchMembersAction(companyId);

    const invoices = getCompanyInvoices(company);

    this.setState({
      company,
      companySubscriptions,
      companySubscription,
      invoices,
      alliancesList,
      loading: false,
    });

    const refreshSession = invoices.some((invoice) => invoice.status !== 'paid');
    if (refreshSession || !invoices.length) setTimeout(() => fetchSession(), 5000);

    const lastInvoiceFailed = invoices.find((invoice) => invoice.status === 'failed');
    this.checkLastInvoice(lastInvoiceFailed);
  };

  checkLastInvoice = async (invoice) => {
    if (invoice && !this.checkedInvoice) {
      this.checkedInvoice = invoice;
      const paymentData = await fetchPaymentData(invoice);
      console.log('check last invoice: ', { paymentData, invoice });
      if (paymentData.payment_intent_status === 'requires_action') {
        console.log('invoice payment requires action', { invoice });
        await retryInvoicePayment(this.stripe, invoice, paymentData);
      }
    }
  };

  onSelectPlan = (selectedPlan) => {
    this.setState({ selectedPlan });
  };

  onCloseChangePlanDialog = () => {
    this.setState({ changePlanDialogIsOpen: false });
  };

  onOpenChangePlanDialog = () => {
    this.setState({ changePlanDialogIsOpen: true });
  };

  onYesChangePlan = () => {
    const { selectedPlan } = this.state;
    if (!selectedPlan) return;

    this.setState({
      changePlanDialogIsOpen: false,
      confirmNewPlanDialogIsOpen: true,
    });
  };

  onCloseConfirmNewPlanDialog = () => {
    this.setState({
      confirmNewPlanDialogIsOpen: false,
      confirmNewPlanDialogStatus: '',
    });
  };

  onYesConfirmNewPlan = () => {
    const companySubscription = R.clone(this.state.companySubscription);
    const company = R.clone(this.state.company);
    const selectedPlan = R.clone(this.state.selectedPlan);
    const isAnnualPlan = R.clone(this.state.showAnnualPrice);

    this.setState({ confirmNewPlanDialogStatus: 'processing' }, () => {
      changeCompanySubscriptionPlan(selectedPlan, isAnnualPlan, companySubscription, company);
    });
  };

  onOpenPaymentDialog = () => {
    this.setState({ paymentDialogIsOpen: true, data: R.clone(CreditCard) });
  };

  onClosePaymentDialog = () => {
    this.setState({ paymentDialogIsOpen: false });
  };

  onUpdatePayment = (stripe, elements) => {
    if (!stripe || !elements) return;
    const {
      data,
      companySubscription: { id },
    } = this.state;

    this.setState({ loadingUpdatePayment: true }, async () => {
      const companySubscription = R.clone(this.state.companySubscription);
      const company = R.clone(this.state.company);
      const cardData = R.clone(data);
      cardData.id = id;
      const { paymentMethod, error } = await createPaymentMethod(stripe, elements, cardData);
      updatePayment(companySubscription, cardData, paymentMethod, error, company);
    });
  };

  onCloseCancelSubscriptionDialog = () => {
    this.setState({ cancelSubscriptionDialogIsOpen: false });
  };

  onOpenCancelSubscriptionDialog = () => {
    this.setState({ cancelSubscriptionDialogIsOpen: true });
  };

  onYesCancelSubscription = () => {
    const companySubscription = R.clone(this.state.companySubscription);
    const company = R.clone(this.state.company);

    this.setState({ loadingCancelSubscription: true }, () => {
      cancelSubscription(companySubscription, company);
    });
  };

  onCloseReactivateSubscriptionDialog = () => {
    this.setState({ reactivateSubscriptionDialogIsOpen: false });
  };

  onOpenReactivateSubscriptionDialog = () => {
    this.setState({ reactivateSubscriptionDialogIsOpen: true });
  };

  onYesReactivateSubscription = () => {
    const companySubscription = R.clone(this.state.companySubscription);
    const company = R.clone(this.state.company);

    this.setState({ loadingReactivateSubscription: true }, () => {
      reactivateSubscription(companySubscription, company);
    });
  };

  onChangeYearlyPrice = (showAnnualPrice) => {
    this.setState({ showAnnualPrice });
  };

  onChangeInviteEmail = (portFolioOwnerEmail) => {
    this.setState({ portFolioOwnerEmail });
  };

  onCloseInvitePortfolioOwnerDialog = () => {
    this.setState({ invitePortfolioOwnerDialogIsOpen: false });
  };

  onOpenInvitePortfolioOwnerDialog = () => {
    this.setState({ invitePortfolioOwnerDialogIsOpen: true });
  };

  onYesInvitePortfolioOwner = () => {
    const { portFolioOwnerEmail, company } = this.state;
    const companyId = company ? company.id : null;

    this.setState({ loadingInvitePortfolioOwner: true }, () => {
      inviteCompanyPortfolioOwner(companyId, portFolioOwnerEmail);
    });
  };

  confirmRetryInvoicePayment = (invoice) => {
    this.setState({
      retryInvoice: invoice,
      confirmRetryPaymentDialogIsOpen: true,
    });
  };

  onCloseConfirmRetryPaymentDialog = () => {
    this.setState({
      confirmRetryPaymentDialogIsOpen: false,
      retryInvoice: null,
    });
  };

  onYesRetryInvoicePayment = (stripe) => {
    const { retryInvoice } = this.state;
    retryInvoicePayment(stripe, retryInvoice);
    this.onCloseConfirmRetryPaymentDialog();
  };

  render() {
    const {
      onCloseChangePlanDialog,
      onOpenChangePlanDialog,
      onSelectPlan,
      onClosePaymentDialog,
      onOpenPaymentDialog,
      onUpdatePayment,
      onChangeData,
      onOpenCancelSubscriptionDialog,
      onCloseCancelSubscriptionDialog,
      onYesCancelSubscription,
      onOpenReactivateSubscriptionDialog,
      onCloseReactivateSubscriptionDialog,
      onYesReactivateSubscription,
      onYesChangePlan,
      onCloseConfirmNewPlanDialog,
      onYesConfirmNewPlan,
      onChangeYearlyPrice,
      onChangeInviteEmail,
      onCloseInvitePortfolioOwnerDialog,
      onOpenInvitePortfolioOwnerDialog,
      onYesInvitePortfolioOwner,
      confirmRetryInvoicePayment,
      onCloseConfirmRetryPaymentDialog,
      onYesRetryInvoicePayment,
    } = this;
    const {
      loading,
      loadingUpdatePayment,
      companySubscription,
      companySubscriptions,
      invoices,
      selectedPlan,
      subscriptionPlans: allSubscriptionPlans,
      countries,
      changePlanDialogIsOpen,
      paymentDialogIsOpen,
      loadingCancelSubscription,
      cancelSubscriptionDialogIsOpen,
      loadingReactivateSubscription,
      reactivateSubscriptionDialogIsOpen,
      confirmNewPlanDialogIsOpen,
      confirmNewPlanDialogStatus,
      data,
      showAnnualPrice,
      portfolioOwners,
      loadingCompanyUsers,
      portFolioOwnerEmail,
      invitePortfolioOwnerDialogIsOpen,
      loadingInvitePortfolioOwner,
      loadingPortfolioOwnerInvitations,
      portfolioOwnerInvitations,
      company,
      alliancesList,
      confirmRetryPaymentDialogIsOpen,
      retryInvoice,
    } = this.state;

    if (loading) return <Loader stretch />;

    let subscriptionPlans = allSubscriptionPlans;
    const canPickFreePlan = !companySubscriptions.length;
    if (!canPickFreePlan) {
      subscriptionPlans = subscriptionPlans.filter((plan) => !isPlanFreeTrial(plan.name));
    }

    if (!companySubscription || !companySubscription.stripeSubscriptionId) {
      return (
        <CreateCompanySubscriptionView
          company={company}
          countries={countries}
          subscriptionPlans={subscriptionPlans}
          companySubscription={companySubscription}
        />
      );
    }

    const { expireDate, subscriptionPlan, isAnnualPlan } = companySubscription;

    const _showCancelSubscription = showCancelSubscription(companySubscription);
    const _showReactivateSubscription = showReactivateSubscription(companySubscription);
    let cancelOrReactivateTitle;
    if (_showCancelSubscription) {
      cancelOrReactivateTitle = 'Cancel Subscription';
    } else if (_showReactivateSubscription) {
      cancelOrReactivateTitle = 'Reactivate Subscription';
    }

    const _showRenewSubscription = showRenewSubscription(companySubscription);
    const changeOrRenewPlanTitle = _showRenewSubscription ? 'Renew Subscription' : 'Change Plan';

    const { monthlyPrice, annualPrice } = subscriptionPlan;
    const price = isAnnualPlan ? annualPrice : monthlyPrice;
    const expireDateText = moment(expireDate).format('L');
    const expireDateTextContent = price ? (
      <>
        <Text color="GRAY_40">{`You'll be charged`}</Text>
        <Text weight="bold">{`$${price}`}</Text>
        <Text color="GRAY_40">{`on`}</Text>
        <Text weight="bold">{expireDateText}</Text>
      </>
    ) : (
      <>
        <Text color="GRAY_40">{`Your free trial ends on`}</Text>
        <Text weight="bold">{expireDateText}</Text>
      </>
    );
    const expireDateContent = _showRenewSubscription ? (
      <Text color="GRAY_40">{`The subscription of your company is expired, you can renew your plan to reactivate it.`}</Text>
    ) : (
      expireDateTextContent
    );

    const invitePortfolioOwnerInput =
      !loadingCompanyUsers && showInvitePortfolioOwnersButton(portfolioOwners) ? (
        <TransparentButton onClick={onOpenInvitePortfolioOwnerDialog} text="Invite user" />
      ) : null;

    return (
      <ElementsConsumer>
        {({ elements, stripe }) => {
          this.stripe = stripe;
          return (
            <>
              <Card style={{ marginBottom: '20px' }}>
                <Card.Header>
                  <Grid.Layout
                    columns="200px auto 200px"
                    areas={[['left', 'center', 'right']]}
                    style={{ width: '100%' }}>
                    <Grid.Box area="left">
                      <Heading type="h4" text="Subscription Plan" />
                    </Grid.Box>
                    <Grid.Box area="center" />
                    <Grid.Box area="right" />
                  </Grid.Layout>
                </Card.Header>

                <Card.Body padding={'none'}>
                  <Card.Section>
                    <PlanDetailsCard companySubscription={companySubscription} currentPlan />

                    <Row alignItems="center" offsetTop="md">
                      <ActionButton
                        text={changeOrRenewPlanTitle}
                        onClick={onOpenChangePlanDialog}
                      />
                      {expireDateContent}
                    </Row>
                  </Card.Section>
                </Card.Body>
              </Card>

              <Card style={{ marginBottom: '20px' }}>
                <Card.Header>
                  <Grid.Layout
                    columns="200px auto 200px"
                    areas={[['left', 'center', 'right']]}
                    style={{ width: '100%' }}>
                    <Grid.Box justifyContent="center" area="left">
                      <Heading type="h4" text="Portfolio owner" />
                    </Grid.Box>
                    <Grid.Box area="center" />
                    <Grid.Box area="right">{invitePortfolioOwnerInput}</Grid.Box>
                  </Grid.Layout>
                </Card.Header>

                <CompanyInvitationsList
                  loading={loadingPortfolioOwnerInvitations}
                  companyInvitations={portfolioOwnerInvitations}
                />
              </Card>

              <Card style={{ marginBottom: '20px' }}>
                <Card.Header>
                  <Grid.Layout
                    columns="200px auto 200px"
                    areas={[['left', 'center', 'right']]}
                    style={{ width: '100%' }}>
                    <Grid.Box area="left" justifyContent="center">
                      <Heading type="h4" text="Payment Details" />
                    </Grid.Box>
                    <Grid.Box area="center" />
                    <Grid.Box area="right">
                      <TransparentButton onClick={onOpenPaymentDialog} text="Update Payment" />
                    </Grid.Box>
                  </Grid.Layout>
                </Card.Header>

                <Card.Body padding={'none'}>
                  <PaymentDetails companySubscription={companySubscription} />
                </Card.Body>
              </Card>

              <Card style={{ marginBottom: '20px' }}>
                <Card.Header>
                  <Grid.Layout
                    columns="200px auto 200px"
                    areas={[['left', 'center', 'right']]}
                    style={{ width: '100%' }}>
                    <Grid.Box area="left">
                      <Heading type="h4" text="Billing History" />
                    </Grid.Box>
                    <Grid.Box area="center" />
                    <Grid.Box area="right" />
                  </Grid.Layout>
                </Card.Header>

                <InvoicesList invoices={invoices} onRetry={confirmRetryInvoicePayment} />
              </Card>

              {_showCancelSubscription || _showReactivateSubscription ? (
                <Card style={{ marginBottom: '20px' }}>
                  <Card.Header>
                    <Grid.Layout
                      columns="200px auto 200px"
                      areas={[['left', 'center', 'right']]}
                      style={{ width: '100%' }}>
                      <Grid.Box area="left">
                        <Heading type="h4" text={cancelOrReactivateTitle} />
                      </Grid.Box>
                      <Grid.Box area="center" />
                      <Grid.Box area="right" />
                    </Grid.Layout>
                  </Card.Header>

                  <Card.Body padding={'none'}>
                    <Card.Section>
                      {_showCancelSubscription ? (
                        <Row alignItems="center" gap="lg">
                          <DangerButton
                            onClick={onOpenCancelSubscriptionDialog}
                            text={'Cancel Subscription'}
                            fontAwesomeIcon={''}
                          />
                          <Text color="GRAY_40">{`You may cancel your subscription but you will not receive a refund for the fees already paid and you will be able to access the service until the end of your current subscription period.`}</Text>
                        </Row>
                      ) : null}
                      {_showReactivateSubscription ? (
                        <Row alignItems="center" gap="lg">
                          <ActionButton
                            onClick={onOpenReactivateSubscriptionDialog}
                            text={'Reactivate Subscription'}
                          />
                          <Text color="GRAY_40">{`You can reactivate your subscription before the current period ends, once you reactivate it, you can continue using the collabtogrow Succeed™ platform.`}</Text>
                        </Row>
                      ) : null}
                    </Card.Section>
                  </Card.Body>
                </Card>
              ) : null}

              <p
                style={{
                  textAlign: 'center',
                  display: 'block',
                  fontSize: '12px',
                  color: '#A5AFB6',
                  marginBottom: '20px',
                }}>
                <a
                  href="https://www.termsfeed.com/terms-conditions/54db4232b6ee133dbbe02f7d3bd4d81f"
                  rel="noopener noreferrer"
                  target="_blank">
                  Terms and Conditions
                </a>
                {` of the platform and it's `}
                <a
                  href="https://www.termsfeed.com/privacy-policy/28ac118c4c126c46d373c5cf39971e77"
                  rel="noopener noreferrer"
                  target="_blank">
                  Privacy Policy
                </a>
              </p>

              <ChangePlanDialog
                title={changeOrRenewPlanTitle}
                onChangeYearlyPrice={onChangeYearlyPrice}
                showAnnualPrice={showAnnualPrice}
                companySubscription={companySubscription}
                alliancesList={alliancesList}
                selectedPlan={selectedPlan}
                subscriptionPlans={subscriptionPlans}
                onSelect={onSelectPlan}
                onCloseDialog={onCloseChangePlanDialog}
                onSubmit={onYesChangePlan}
                isOpen={changePlanDialogIsOpen}
              />

              <CancelSubscriptionDialog
                loading={loadingCancelSubscription}
                companySubscription={companySubscription}
                isOpen={cancelSubscriptionDialogIsOpen}
                onClose={onCloseCancelSubscriptionDialog}
                onYes={onYesCancelSubscription}
              />

              <ReactivateSubscriptionDialog
                loading={loadingReactivateSubscription}
                companySubscription={companySubscription}
                isOpen={reactivateSubscriptionDialogIsOpen}
                onClose={onCloseReactivateSubscriptionDialog}
                onYes={onYesReactivateSubscription}
              />

              <ConfirmNewPlanDialog
                title={changeOrRenewPlanTitle}
                showAnnualPrice={showAnnualPrice}
                status={confirmNewPlanDialogStatus}
                subscriptionPlan={selectedPlan}
                isOpen={confirmNewPlanDialogIsOpen}
                onClose={onCloseConfirmNewPlanDialog}
                onYes={onYesConfirmNewPlan}
              />

              <InvitePortfolioOwnerDialog
                loading={loadingInvitePortfolioOwner}
                isOpen={invitePortfolioOwnerDialogIsOpen}
                onSubmit={onYesInvitePortfolioOwner}
                onCloseDialog={onCloseInvitePortfolioOwnerDialog}
                email={portFolioOwnerEmail}
                onChange={onChangeInviteEmail}
                onClick={onYesInvitePortfolioOwner}
              />

              <ConfirmRetryPaymentDialog
                invoice={retryInvoice}
                isOpen={confirmRetryPaymentDialogIsOpen}
                onClose={onCloseConfirmRetryPaymentDialog}
                onYes={() => onYesRetryInvoicePayment(stripe)}
              />
              <PaymentDialogForm
                countries={countries}
                loading={loadingUpdatePayment}
                onCloseDialog={onClosePaymentDialog}
                isOpen={paymentDialogIsOpen}
                onSubmit={() => onUpdatePayment(stripe, elements)}
                onChange={onChangeData}
                data={data}
              />
            </>
          );
        }}
      </ElementsConsumer>
    );
  }
}

ProcurerBillingView.propTypes = {
  history: PropTypes.object.isRequired,
};

const enhancedProcurerBillingView = withRouter(ProcurerBillingView);
export { enhancedProcurerBillingView as ProcurerBillingView };
