import React from 'react';
import { Text } from '@8base/boost';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';

const TextH1 = styled(Text)`
  font-family: Poppins;
  font-weight: 600;
  opacity: 0.9;
  height: 2.22%;
  width: 50.5%;
  letter-spacing: 0.5px;
  line-height: 21px;
`;

const HeaderText = ({ children }) => {
  return <TextH1 type="h1">{children}</TextH1>;
};

HeaderText.propTypes = {
  children: PropTypes.any.isRequired,
};

export { HeaderText };
