import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import { DateInputField } from '@8base/boost';

import { DateFieldsContainer } from '../components';

const DateRangesCondField = React.memo(({ input: { name } }) => (
  <DateFieldsContainer>
    <Field
      name={`${name}[0]`}
      subscription={{ active: true, value: true, error: true }}
      label="From"
      placeholder="mm/dd/yyyy"
      component={DateInputField}
    />
    <Field
      name={`${name}[1]`}
      subscription={{ active: true, value: true, error: true }}
      label="To"
      placeholder="mm/dd/yyyy"
      component={DateInputField}
    />
  </DateFieldsContainer>
));

DateRangesCondField.displayName = 'DateRangesCondField';
DateRangesCondField.propTypes = {
  input: PropTypes.object.isRequired,
};

export { DateRangesCondField };
