import React, { useEffect, useState } from 'react';
import { initializeSalesforce } from '../../salesforce/salesforce-actions';
import { useSubscription } from '@cobuildlab/react-simple-state';
import { SalesforceConnectionSettingsCard } from './SalesforceConnectionSettingCard';
import { Column, Loader } from '@8base/boost';
import { SalesforceFieldMappingSettingsCard } from './SalesforceFieldMappingSettingCard';
import { SalesforceScheduleUpdateSettingsCard } from './SalesforceScheduleUpdateSettingCard';
import { SalesforceLogsCard } from './SalesforceLogsCard';
import {
  OnSalesforceConnected,
  OnSalesforceConnectedError,
  OnSalesforceConnectionInvalid,
  OnSalesforceConnectionExpired,
} from '../../salesforce/salesforce-events';

export const SalesforceSettingsView = () => {
  const [loading, setLoading] = useState(true);
  const [isConnected, setConnected] = useState(false);
  const [refreshTokenExpired, setRefreshTokenExpired] = useState(false);

  useSubscription(OnSalesforceConnected, (eventData) => {
    setLoading(false);
    setConnected(Boolean(eventData));
    setRefreshTokenExpired(false);
  });
  useSubscription(OnSalesforceConnectedError, () => {
    setLoading(false);
    setConnected(false);
  });

  useSubscription(OnSalesforceConnectionInvalid, () => {
    setConnected(false);
    setLoading(false);
  });

  useSubscription(OnSalesforceConnectionExpired, () => {
    setConnected(false);
    setLoading(false);
    setRefreshTokenExpired(true);
  });

  useEffect(() => initializeSalesforce(), []);

  if (loading) return <Loader stretch />;

  return (
    <Column gap="xl" stretch offsetBottom="xl">
      <SalesforceConnectionSettingsCard refreshTokenExpired={refreshTokenExpired} />
      {isConnected ? (
        <>
          <SalesforceScheduleUpdateSettingsCard />
          <SalesforceLogsCard />
          <SalesforceFieldMappingSettingsCard />
        </>
      ) : null}
    </Column>
  );
};
