import React from 'react';
import PropTypes from 'prop-types';
import { Button, Column, Label } from '@8base/boost';
import InputFiles from 'react-input-files';

const FileInputField = ({ onChange, label = undefined, accept = 'image/*', multiple = false }) => {
  return (
    <Column alignContent={'start'}>
      {label !== undefined ? <Label kind="secondary" text={label} /> : null}
      {multiple ? (
        <InputFiles onChange={onChange} multiple accept={accept}>
          <Button type="button" color={'neutral'}>
            {'Select File'}
          </Button>
        </InputFiles>
      ) : (
        <InputFiles onChange={onChange} accept={accept}>
          <Button type="button" color={'neutral'}>
            {'Select File'}
          </Button>
        </InputFiles>
      )}
    </Column>
  );
};

FileInputField.defaultProps = {
  label: '',
  multiple: false,
  accept: 'image/*',
};

FileInputField.propTypes = {
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  multiple: PropTypes.bool,
  accept: PropTypes.string,
};

export { FileInputField };
