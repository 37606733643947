import styled from 'styled-components';

const ReportLink = styled.a`
  color: #3eb7f9;
  &:visited,
  &:hover {
    color: #3eb7f9;
  }
`;

const ReportReadMoreLink = styled(ReportLink)`
  margin-left: 6px;
`;

export { ReportLink, ReportReadMoreLink };
