import React from 'react';
import { Switch, Text } from '@8base/boost';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import UserSmallAvatar from '../../../components/user/UserSmallAvatar';
import Moment from 'react-moment/dist/index';
import { getDueDateColor } from '../../../shared/items-util';

const StyledDate = styled('div')`
  border-left: 1px solid #d0d7dd;
  border-right: 1px solid #d0d7dd;
  height: auto;
  min-height: 60px;
  padding: 20px 10px;
  max-width: 130px;
  min-width: 130px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledSwitch = styled('div')`
  border-right: 1px solid #d0d7dd;
  height: auto;
  min-height: 60px;
  max-width: 70px;
  min-width: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledMainContent = styled('div')`
  width: 100%;
  padding: 10px;
  height: auto;
  min-height: 60px;
`;

const StyledContainer = styled('div')`
  border: 1px solid #d0d7dd;
  width: 100%;
  border-radius: 0.5rem;
  border-left: 8px solid ${(props) => props.color};
  margin: 5px 0;
  display: flex;
  text-align: left;
`;

const InitiativeRow = (props) => {
  const { initiative, selected, onChange } = props;
  const { owner, name, baselineEndDate } = initiative;
  const baselineEndDateText = baselineEndDate ? (
    <Moment format="MMMM Do, YYYY">{baselineEndDate}</Moment>
  ) : (
    <Text>Not set</Text>
  );

  let Container = StyledContainer;

  const color = getDueDateColor(baselineEndDate);
  console.log('initiative', initiative);
  return (
    <Container color={color}>
      <StyledMainContent>
        <Text text={name} />
        <UserSmallAvatar owner={owner} />
      </StyledMainContent>
      <StyledDate>{baselineEndDateText}</StyledDate>
      <StyledSwitch>
        <Switch value={selected} className={'switchInitiative'} onChange={onChange} />
      </StyledSwitch>
    </Container>
  );
};

InitiativeRow.propTypes = {
  initiative: PropTypes.object.isRequired,
  selected: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};

export { InitiativeRow };
