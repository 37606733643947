import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

const LegendHeading = styled.h4`
  margin-bottom: 15px;
  font-size: 1.2rem;
  text-transform: uppercase;
  text-align: left;
  color: #939ea7;
`;

const LegendList = styled.ul`
  text-align: left;
`;

const LegendListItem = styled.li`
  position: relative;
  padding-left: 20px;

  &:not(:last-child) {
    margin-bottom: 12px;
  }

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 5px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: ${({ color }) => color};
  }
`;

export const CustomLegend = ({ payload }) => (
  <>
    <LegendHeading>Legend</LegendHeading>
    <LegendList>
      {payload.map((entry) => (
        <LegendListItem key={entry.value} color={entry.color}>
          {entry.value}
        </LegendListItem>
      ))}
    </LegendList>
  </>
);

CustomLegend.propTypes = {
  payload: PropTypes.arrayOf(PropTypes.shape({ value: PropTypes.string, color: PropTypes.string }))
    .isRequired,
};
