/**
 *
 * @param {Array} allOptions - All options available for pass to Select.
 * @param {Array} selectedOptions - List of options that are already selected in other Selects of this group.
 * @param {number} fieldIndex - Index of the current field in FieldArray.
 * @returns {Array} - List of options that should be shown to the user.
 */
const filterFieldsOptions = (allOptions = [], selectedOptions = [], fieldIndex) => {
  const unnecessaryOptions = selectedOptions.filter((_, index) => index !== fieldIndex);
  const filteredOptions = allOptions.filter((option) => {
    const { value } = option;

    return !unnecessaryOptions.some((uncOption) => {
      const { fieldId, key } = uncOption;
      if (fieldId) {
        return uncOption.fieldId === value;
      } else if (key) {
        return uncOption.key === value;
      } else {
        return false;
      }
    });
  });
  return filteredOptions;
};

export { filterFieldsOptions };
