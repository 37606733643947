import gql from 'graphql-tag';
import { UserFragment } from '../../auth/queries';
import { BusinessCaseFragment } from '../../document-management/business-case/businessCase.queries';
import { CommentFragment } from '../../comment/comment-queries';

/**
 * Funding Request Item.
 */
const FundingRequestFragment = gql`
  fragment FundingRequestFragment on FundingRequest {
    id
    name
    description
    status
    originalDueDate
    assignedDate
    revisedDueDate
    requestedDate
    createdAt
    unitType
    unitQuantity
    unitValueDescription
    unitMonetizationFactor
    source {
      id
      name
    }
    requestedBy {
      ...UserFragment
    }
    createdBy {
      firstName
      lastName
      id
    }
    assignedTo {
      ...UserFragment
    }
    initiatives {
      items {
        id
        name
        baselineEndDate
        owner {
          ...UserFragment
        }
        status
      }
    }
    nextSteps {
      items {
        id
        dueDate
        description
        status
        assignedTo {
          ...UserFragment
        }
      }
    }
    businessCase {
      ...BusinessCaseFragment
    }
    documents {
      items {
        id
        filename
        downloadUrl
        shareUrl
        fileId
      }
    }
    itemFundingRequestRelation {
      id
    }
    fundingRequestApprovalRelation {
      items {
        dateOfResponse
        status
        approvedBy {
          ...UserFragment
        }
        company {
          id
          name
        }
      }
    }
  }
  ${UserFragment}
  ${BusinessCaseFragment}
`;

/**
 * Create.
 */
export const FUNDING_REQUEST_CREATE_MUTATION = gql`
  mutation($data: FundingRequestCreateInput!) {
    fundingRequestCreate(data: $data) {
      ...FundingRequestFragment
    }
  }
  ${FundingRequestFragment}
`;

export const FUNDING_REQUEST_DETAIL_QUERY = gql`
  query($id: ID!) {
    fundingRequest(id: $id) {
      ...FundingRequestFragment
    }
  }
  ${FundingRequestFragment}
`;

export const FUNDING_REQUEST_UPDATE_QUERY = gql`
  mutation($data: FundingRequestUpdateInput!) {
    fundingRequestUpdate(data: $data) {
      ...FundingRequestFragment
    }
  }
  ${FundingRequestFragment}
`;
/**
 * Update.
 */
export const FUNDING_REQUEST_UPDATE_MUTATION = gql`
  mutation($data: FundingRequestUpdateInput!) {
    fundingRequestUpdate(data: $data) {
      id
      status
    }
  }
`;
/**
 * Query for the Funding Request Comments.
 *
 * @type {*|*}
 */
export const FUNDING_REQUEST_COMMENTS_QUERY = gql`
  query($id: ID!) {
    fundingRequest(id: $id) {
      id
      comments {
        items {
          ...CommentFragment
        }
        count
      }
    }
  }
  ${CommentFragment}
`;

export const FundingRequestApprovalFragment = gql`
  fragment FundingRequestApprovalFragment on FundingRequestApproval {
    id
    fundingRequest {
      id
      name
    }
    company {
      id
      name
    }
    status
    dateOfResponse
  }
`;

export const FUNDING_REQUEST_APPROVALS_LIST_QUERY = gql`
  query($filter: FundingRequestApprovalFilter, $sort: [FundingRequestApprovalSort!]) {
    fundingRequestApprovalsList(filter: $filter, sort: $sort) {
      count
      items {
        ...FundingRequestApprovalFragment
      }
    }
  }
  ${FundingRequestApprovalFragment}
`;

/**
 * Update an Funding Request Approval.
 */
export const FUNDING_REQUEST_APPROVAL_UPDATE_MUTATION = gql`
  mutation($approval: FundingRequestApprovalUpdateInput!) {
    fundingRequestApprovalUpdate(data: $approval) {
      id
    }
  }
`;
/**
 * Update a Funding Request Approval and a Funding Request.
 */
export const FUNDING_REQUEST_APPROVAL_MUTATION = gql`
  mutation(
    $fundingRequest: FundingRequestUpdateInput!
    $approval: FundingRequestApprovalUpdateInput!
  ) {
    fundingRequestUpdate(data: $fundingRequest) {
      id
    }
    fundingRequestApprovalUpdate(data: $approval) {
      id
    }
  }
`;
