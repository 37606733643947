import { Button, Dialog, Grid, Icon, Paper, Row, Text } from '@8base/boost';
import React, { useState } from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';

import SalesforceStep1Img from '../../../images/salesforce-instructions/salesforce-custom-field.jpg';
import SalesforceStep2Img from '../../../images/salesforce-instructions/salesforce-picklist-value.jpg';
import SalesforceStep3Img from '../../../images/salesforce-instructions/salesforce-object-tagged.jpg';
import SalesforceStep4Img from '../../../images/salesforce-instructions/salesforce-field-mapping.jpg';
import SalesforceStep5Img from '../../../images/salesforce-instructions/salesforce-stage-mapping.jpg';
import SalesforceStep2Part2Img from '../../../images/salesforce-instructions/salesforce-integration-step-2-2.jpg';

const StepNumber = styled(Text)({
  fontWeight: 'bold',
  fontSize: 28,
  lineHeight: 1,
});

const InstructionStep = ({ number, text, images }) => {
  const [showing, setShowing] = useState(false);
  return (
    <Row offsetTop="xl">
      <Paper padding="md" stretch onClick={() => setShowing(!showing)}>
        <Grid.Layout style={{ cursor: 'pointer' }} columns="48px 1fr 32px">
          <Grid.Box alignItems="center" justifyContent="center">
            <StepNumber>{number}</StepNumber>
          </Grid.Box>
          <Grid.Box justifyContent="center">
            <Text stlye={{ fontSize: 16 }} text={text} />
          </Grid.Box>
          <Grid.Box>
            <Button rounded squared color="neutral" size="sm">
              <Icon size="xs" name={showing ? 'ChevronTop' : 'ChevronDown'} />
            </Button>
          </Grid.Box>
        </Grid.Layout>
        {showing
          ? images.map((image, index) => (
            <Row key={index} offsetTop="md">
              <img width="100%" src={image} alt="Step" />
            </Row>
          ))
          : null}
      </Paper>
    </Row>
  );
};

InstructionStep.propTypes = {
  number: PropTypes.number.isRequired,
  text: PropTypes.string.isRequired,
  images: PropTypes.array.isRequired,
};

export const SalesforceInstructionsDialog = ({ isOpen, onClose, field }) => {
  return (
    <Dialog size="xxl" isOpen={isOpen}>
      <Dialog.Header title="Salesforce Configuration Instructions" onClose={onClose} />
      <Dialog.Body scrollable>
        <Grid.Layout gap="sm" stretch>
          <Grid.Box>
            <Text>
              <a
                rel="noopener noreferrer"
                href="https://collabtogrow.com/product-support/salesforce-api"
                target="_blank">
                <b>Click here</b>
              </a>{' '}
              to view a tutorial on how to set-up salesforce with succeed
            </Text>
            <br />
            <Text>Please follow these instructions to import deals from salesforce:</Text>
            <InstructionStep
              number={1}
              text={`The multi-picklist field named "${field}" must be added to the opportunity object
                in Salesforce`}
              images={[SalesforceStep1Img]}
            />
            <InstructionStep
              number={2}
              text={`The name entered into the Succeed "Alliance Field Value" field must also be added to the Salesforce multi-picklist field`}
              images={[SalesforceStep2Img, SalesforceStep2Part2Img]}
            />
            <InstructionStep
              number={3}
              text={`Alliance opportunities in Salesforce must be tagged with the appropriate multi-picklist value`}
              images={[SalesforceStep3Img]}
            />
            <InstructionStep
              number={4}
              text={`The default Succeed to Salesforce field mapping must be verified to your company's Opportunity fields in Salesforce (and updated as needed)`}
              images={[SalesforceStep4Img]}
            />
            <InstructionStep
              number={5}
              text={`The Stage Mapping in Succeed must be updated to map your company's Opportunity Stages in Salesforce to the Succeed Stages`}
              images={[SalesforceStep5Img]}
            />
          </Grid.Box>
        </Grid.Layout>
      </Dialog.Body>
    </Dialog>
  );
};

SalesforceInstructionsDialog.propTypes = {
  field: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
};
