import React from 'react';
import { Dialog, Row, Text } from '@8base/boost';
import PropTypes from 'prop-types';
import { TransparentButton } from '../../../components/buttons/TransparentButton';
import { ActionButton } from '../../../components/buttons/ActionButton';

const ConfirmRetryPaymentDialog = ({ isOpen, onClose, onYes, invoice }) => {
  if (!invoice) return null;
  return (
    <Dialog size="lg" isOpen={isOpen}>
      <Dialog.Header title="Retry Payment" onClose={onClose} />
      <Dialog.Body>
        <Text text={`Are you sure you want to retry paying this invoice for ${invoice.total}$?`} />
      </Dialog.Body>
      <Dialog.Footer>
        <Row alignItems="center" justifyContent="end">
          <TransparentButton text="No" onClick={onClose} />
          <ActionButton text="Yes" onClick={onYes} />
        </Row>
      </Dialog.Footer>
    </Dialog>
  );
};

ConfirmRetryPaymentDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  onYes: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  invoice: PropTypes.object.isRequired,
};

export { ConfirmRetryPaymentDialog };
