import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { PlanCard } from './PlanCard';
import { Row, Column, Text } from '@8base/boost';
import { MonthlyYearlyButton } from './MonthlyYearlyButton';
import { DialogForm } from '../../../components/forms/DialogForm';
import styled from '@emotion/styled';

const MainText = styled(Text)`
  color: #7d828c;
`;

const ChangePlanDialog = ({
  selectedPlan,
  subscriptionPlans,
  onSelect,
  onCloseDialog,
  isOpen,
  onSubmit,
  companySubscription,
  showAnnualPrice,
  onChangeYearlyPrice,
  title,
  alliancesList,
}) => {
  const { subscriptionPlan: currentPlan } = companySubscription;

  return (
    <DialogForm
      title={title}
      isOpen={isOpen}
      createText="Continue"
      onCloseDialog={onCloseDialog}
      onClickCreate={onSubmit}>
      <Row alignItems="center" offsetBottom="md">
        <Column stretch alignItems="start">
          <MainText weight="semibold">{'SUBSCRIPTION PLAN'}</MainText>
        </Column>
        <Column stretch alignItems="end">
          <MonthlyYearlyButton showAnnualPrice={showAnnualPrice} onChange={onChangeYearlyPrice} />
        </Column>
      </Row>

      {subscriptionPlans.map((plan, i) => {
        const disabled = plan.allianceLimit < alliancesList && title !== 'Renew Subscription';

        return (
          <Fragment key={i}>
            <PlanCard
              disabled={disabled}
              showAnnualPrice={showAnnualPrice}
              plan={plan}
              currentPlan={currentPlan}
              onSelect={onSelect}
              selected={selectedPlan}
            />
            <Row offsetBottom="md" />
          </Fragment>
        );
      })}
    </DialogForm>
  );
};

ChangePlanDialog.defaultProps = {
  selectedPlan: null,
  title: 'Change Plan',
};

ChangePlanDialog.propTypes = {
  selectedPlan: PropTypes.object,
  companySubscription: PropTypes.object.isRequired,
  onSelect: PropTypes.func.isRequired,
  onCloseDialog: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  subscriptionPlans: PropTypes.array.isRequired,
  showAnnualPrice: PropTypes.bool.isRequired,
  onChangeYearlyPrice: PropTypes.func.isRequired,
  alliancesList: PropTypes.number.isRequired,
  title: PropTypes.string,
};

export { ChangePlanDialog };
