import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Card, Heading, Table } from '@8base/boost';
import { ListCardBody } from '../../../../components/card/ListCardBody';
import DealDate from '../../../management/deal/components/DealDate';
import { CurrencyTextField } from '../../../../shared/components/CurrencyTextField';
import { Link } from 'react-router-dom';
import { OPPORTUNITY_WIN_RATIO_TYPE } from '../../../settings/alliance-management/allianceKPIs/allianceKPIs-model';
import { DEAL_STAGE_CLOSED_WON } from '../../../management/deal/deal-model';

const StyledHeading = styled(Heading)`
  height: 28px;
  color: #323c47;
  font-family: Poppins;
  font-size: 20px;
  line-height: 30px;
`;

const DealList = ({ selectedKPI, currency }) => {
  const { name: KPIname, deals, type } = selectedKPI;
  let filteredDeals = [...deals];
  if (type === OPPORTUNITY_WIN_RATIO_TYPE) {
    filteredDeals = deals.filter(({ stage }) => stage === DEAL_STAGE_CLOSED_WON);
  }
  return (
    <>
      <Card style={{ marginTop: '35px', marginBottom: '35px' }}>
        <Card.Header>
          <StyledHeading type="h4" text={KPIname} />
        </Card.Header>
        <Card.Body>
          <ListCardBody>
            <Table>
              <Table.Header className="justify-center-column" columns="3fr 3fr 3fr 3fr 2fr 3fr 4fr">
                <Table.HeaderCell className="name-column">Customer Name</Table.HeaderCell>
                <Table.HeaderCell>Amount</Table.HeaderCell>
                <Table.HeaderCell>Close Date</Table.HeaderCell>
                <Table.HeaderCell>Name</Table.HeaderCell>
                <Table.HeaderCell>Stage</Table.HeaderCell>
                <Table.HeaderCell>Next Activity Date</Table.HeaderCell>
                <Table.HeaderCell>Next Activity Description</Table.HeaderCell>
              </Table.Header>
              <Table.Body data={filteredDeals} className="card-body-list">
                {(deal, index) => {
                  const {
                    amount,
                    customerName,
                    closeDate,
                    stage,
                    name,
                    nextActivityDate,
                    nextActivityDescription,
                    id,
                  } = deal;
                  return (
                    <>
                      <Table.BodyRow columns="3fr 3fr 3fr 3fr 2fr 3fr 4fr" key={deal.id}>
                        <Table.BodyCell className="justify-center-row">
                          <Link className="item-name" to={`/management/deal/${id}/`}>
                            {customerName}
                          </Link>
                        </Table.BodyCell>
                        <Table.BodyCell className="justify-center-row">
                          <CurrencyTextField
                            textAlign={'center'}
                            value={amount}
                            currency={currency}
                          />
                        </Table.BodyCell>
                        <Table.BodyCell className="justify-center-row">
                          <DealDate dealDate={closeDate} />
                        </Table.BodyCell>
                        <Table.BodyCell className="justify-center-row">
                          <Link className="item-name" to={`/management/deal/${id}/`}>
                            {name}
                          </Link>
                        </Table.BodyCell>
                        <Table.BodyCell className="justify-center-row">{stage}</Table.BodyCell>
                        <Table.BodyCell className="justify-center-row">
                          <DealDate dealDate={nextActivityDate} />
                        </Table.BodyCell>
                        <Table.BodyCell className="justify-center-row">
                          {nextActivityDescription}
                        </Table.BodyCell>
                      </Table.BodyRow>
                    </>
                  );
                }}
              </Table.Body>
            </Table>
          </ListCardBody>
        </Card.Body>
      </Card>
    </>
  );
};

DealList.propTypes = {
  selectedKPI: PropTypes.object.isRequired,
  currency: PropTypes.object.isRequired,
};

export { DealList };
