import React from 'react';
import * as R from 'ramda';
import { FIELD_TYPE } from '@8base/utils';
import { Heading, NoData, Button, Icon, Grid, Dropdown, Loader } from '@8base/boost';
import { withTablesList } from '@8base-react/table-schema-provider';
import { View } from '@cobuildlab/react-simple-state';
import { Query } from 'react-apollo';
import { Menu } from '../../../../shared/components/Menu';
import { ViewCardBody } from '../../../../components/card/ViewCardBody';
import { pivotTableFilterGenerator } from '../../../../shared/select-utils';
import { SORT_NAME_MAP } from '../model';
import { ReportHeader } from '../../components';
import { PIVOT_TABLE_QUERY } from '../../reports-queries';
import sessionStore, { NEW_SESSION_EVENT } from '../../../../shared/SessionStore';
import { ReportsCard } from '../../components';
import { createSortValue } from '../../utils';
import { RELATION_QUERIES, CURRENCY_FIELDS, INITIAL_STATE } from '../model';
import { generateFieldsString, generatePivotTablesData, exportToExcelPivotTable } from '../utils';
import { PivotTable } from '../components';
import { generatePivotTablesDataExtend } from '../utils/generatePivotTablesData';
import { OnPivotTableFilter } from '../pivot-table-events';

class PivotTableReportViewComponent extends View {
  constructor(props) {
    super(props);

    const config = OnPivotTableFilter.get() || INITIAL_STATE;
    const { tablesList } = this.props;
    if (config.fieldsList.length > 0) {
      config.fieldsList.push({ name: 'id' });
    }
    this.state = {
      queryArgs: this.buildRequestArgs(config, tablesList),
      config,
    };
  }

  componentDidMount() {
    this.subscribe(OnPivotTableFilter, this.onConfigChange);
  }

  onConfigChange = (config) => {
    const { tablesList } = this.props;

    this.setState({
      queryArgs: this.buildRequestArgs(config, tablesList),
      config,
    });
  };
  render() {
    const { selectedAlliance } = sessionStore.getState(NEW_SESSION_EVENT);
    const { queryArgs, config } = this.state;
    const { itemType, fields } = config;
    return (
      <ReportsCard stretch>
        {!itemType && (!fields || fields.length === 0) ? (
          <NoData />
        ) : (
          <Query
            query={PIVOT_TABLE_QUERY}
            variables={queryArgs}
            skip={!selectedAlliance || !selectedAlliance.id}>
            {this.renderContent}
          </Query>
        )}
      </ReportsCard>
    );
  }

  renderContent = ({ data, loading }) => {
    if (loading) {
      return <Loader stretch />;
    }

    const {
      user: { firstName, lastName },
    } = sessionStore.getState(NEW_SESSION_EVENT);

    // We got this data from the request to the backend side
    // (custom function pivotTableReport.js)
    const pivotTableData = R.pathOr([], ['pivotTableReport', 'pivotTableData'], data);

    const { config } = this.state;

    // Prepare data for export in Excel format
    // and rendering on Pivot Table -> Table Page
    const tablesData = generatePivotTablesData(pivotTableData, config);

    generatePivotTablesDataExtend(tablesData, config);

    const reportName = 'Pivot Table';
    return (
      <div id={'c2gScreen'}>
        <ReportHeader>
          <Heading type="h4" text={reportName} />
          <Grid.Box direction="row" alignItems="center" justifyContent="flex-end">
            <Dropdown defaultOpen={false}>
              <Dropdown.Head>
                <Button variant="outlined" color="GRAY4" squared>
                  <Icon name="More" />
                </Button>
              </Dropdown.Head>
              <Dropdown.Body pin="right">
                {({ closeDropdown }) => (
                  <Menu>
                    <Menu.Item
                      onClick={() => {
                        const fileName = `${reportName} ${new Date().toISOString()}`;
                        const userName = `${firstName} ${lastName}`;
                        exportToExcelPivotTable(tablesData, fileName, userName);
                        closeDropdown();
                      }}>
                      Export to Excel
                    </Menu.Item>
                  </Menu>
                )}
              </Dropdown.Body>
            </Dropdown>
          </Grid.Box>
        </ReportHeader>
        <ViewCardBody style={{ padding: 0 }} className="card-body-report">
          <PivotTable tablesData={tablesData} currencyFields={CURRENCY_FIELDS} />
        </ViewCardBody>
      </div>
    );
  };

  buildRequestArgs(config, tablesList) {
    const tableName = R.path(['itemType'], config);

    const { selectedAlliance } = sessionStore.getState(NEW_SESSION_EVENT);

    const queryArgs = {
      tableName,
      fields: '',
      filter: {
        AND: [],
      },
      sort: [],
      groupBy: [],
    };

    const configFieldsList = R.pathOr([], ['fieldsList'], config);
    const configSorts = R.pathOr([], ['sorts'], config);
    const configGroups = R.pathOr([], ['groups'], config);

    const currentTable = tablesList.find((table) => table.name === queryArgs.tableName);
    const fieldsList = R.pathOr([], ['fields'], currentTable);

    queryArgs.fields = generateFieldsString(configFieldsList, RELATION_QUERIES);
    queryArgs.fields = `${queryArgs.fields}\nid`;
    if (configSorts && configSorts.length > 0) {
      queryArgs.sort = configSorts.map(({ key, value }) => ({ [key]: value }));
    }

    if (Array.isArray(configGroups)) {
      const groups = config.isTotals ? configGroups.slice(0, 1) : [...configGroups];

      groups.reverse().forEach(({ key, sort }) => {
        const { fieldType } = configFieldsList.find((fieldMeta) => fieldMeta.name === key);
        if (fieldType === FIELD_TYPE.RELATION) {
          key = `${key}.id`;
        }

        queryArgs.groupBy.unshift(key);

        if (sort) {
          queryArgs.sort = queryArgs.sort.filter((item) => !Object.keys(item).includes(key));
          queryArgs.sort.unshift({ [key]: sort });
        }
      });
    }

    if (Array.isArray(config.filters)) {
      const configFilters = R.pathOr([], ['filters'], config).map((filter) => {
        const rule = filter.rule ? filter.rule : 'equals';
        return {
          ...filter,
          rule,
        };
      });

      configFilters.forEach((filterItem) => {
        const { rule } = filterItem;
        switch (rule) {
        case 'is_empty': {
          filterItem.cond = true;
          break;
        }
        case 'is_not_empty': {
          filterItem.cond = true;
          break;
        }
        default:
          break;
        }
      });

      queryArgs.filter.AND.push(...pivotTableFilterGenerator(fieldsList)(configFilters));
    }

    const relationFilterName = `item${tableName}Relation`;

    queryArgs.filter.AND.push({
      [relationFilterName]:
        selectedAlliance && selectedAlliance.id
          ? { alliance: { id: { equals: selectedAlliance.id } } }
          : undefined,
    });

    queryArgs.sort = queryArgs.sort.map((item) => {
      const key = Object.keys(item)[0];
      const keyValue = SORT_NAME_MAP[key] ? SORT_NAME_MAP[key] : key;
      const keys = keyValue.split('.');
      return createSortValue(keys, item[key]);
    });

    return queryArgs;
  }
}

const PivotTableReportView = withTablesList(PivotTableReportViewComponent);

export { PivotTableReportView };
