import gql from 'graphql-tag';
import { NextStepFragment } from '../../next-step/next-step-queries';
import { ActiveItemFragment } from '../work-queue/work-queue-queries';

export const INITIATIVE_ACTIVE_ITEMS_LIST_QUERY = gql`
  query($itemFilter: ItemFilter) {
    itemsList(filter: $itemFilter) {
      count
      items {
        ...ActiveItemFragment
      }
    }
  }
  ${ActiveItemFragment}
  ${NextStepFragment}
`;

export const INITIATIVE_ACTIVE_ITEMS_LIST_SHORT_QUERY = gql`
  query($itemFilter: ItemFilter) {
    itemsList(filter: $itemFilter) {
      count
      items {
        id
        createdAt
        action {
          id
          status
        }
        issue {
          id
          status
        }
        risk {
          id
          status
        }
        fundingRequest {
          id
          status
        }
        idea {
          id
          status
        }
      }
    }
  }
`;
