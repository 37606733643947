const Risk = ['id', 'itemRiskRelation', 'notificationsRiskRelation', 'nextSteps', 'comments'];

const Action = [
  'id',
  'itemActionRelation',
  'comments',
  'nextSteps',
  'notificationsActionRelation',
  'actionApprovalRelation',
  'businessCase',
];

const Issue = ['id', 'itemIssueRelation', 'comments', 'nextSteps', 'notificationsIssueRelation'];

const Decision = [
  'id',
  'itemDecisionRelation',
  'comments',
  'decisionApprovalDecisionRelation',
  'notificationsDecisionRelation',
  'businessCase',
];

const DealData = [
  'id',
  'deal',
  'itemDealDataRelation',
  'comments',
  'notificationsDealDataRelation',
];

const FundingRequest = [
  'id',
  'itemFundingRequestRelation',
  'fundingRequestApprovalRelation',
  'comments',
  'nextSteps',
  'notificationsFundingRequestRelation',
  'businessCase',
];

const Contribution = [
  'id',
  'itemContributionRelation',
  'contributionApprovalContributionRelation',
  'comments',
  'businessCase',
  'notificationsContributionRelation',
];

const Idea = [
  'id',
  'itemIdeaRelation',
  'ideaApprovalRelation',
  'comments',
  'businessCase',
  'notificationsIdeaRelation',
];

const REMOVES_FILTERS_LISTS = {
  Risk,
  Action,
  Issue,
  Decision,
  DealData,
  FundingRequest,
  Contribution,
  Idea,
};

export { REMOVES_FILTERS_LISTS };
