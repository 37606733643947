import React from 'react';
import { compose } from 'recompose';
import { Dropdown, Menu, Avatar, Card } from '@8base/boost';
import { withAuth } from '8base-react-sdk';
import { withApollo } from 'react-apollo';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import sessionStore, { NEW_SESSION_EVENT } from '../shared/SessionStore';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import DropdownUserText from './DropdownUserText';
import styled from '@emotion/styled';
import { logout } from '../modules/auth/auth.actions';
import { auditLogUserLogin } from '../modules/reports/audit-log/audit-log-actions';
import { getFirstCompanyRoles } from '../shared/alliance-utils';

/**
 *
 * @returns {*}
 * @class
 */

const StyledAvatar = styled(Avatar)({
  height: '62px',
  width: '62px',
});

const UserDropdown = (props) => {
  const { user } = sessionStore.getState(NEW_SESSION_EVENT);
  const { items } = user.companyUserRelation;
  const { company } = items[0] || {};
  const companyName = company ? company.name : null;
  const roles = getFirstCompanyRoles(user);
  const roleName = roles.length > 0 ? roles[0] : null;

  return (
    <Dropdown defaultOpen={false}>
      <Dropdown.Head>
        <Avatar
          src={R.path(['avatar', 'downloadUrl'], user)}
          firstName={R.path(['firstName'], user)}
          lastName={R.path(['lastName'], user)}
          size="sm"
        />
      </Dropdown.Head>
      <Dropdown.Body pin="right" offset="md">
        {({ closeDropdown }) => (
          <Menu>
            <Card.Header
              onClick={() => {
                closeDropdown();
                props.history.push('/settings/user-profile');
              }}
              style={{ cursor: 'pointer', height: '115px', minWidth: '269px' }}
              padding={'md'}>
              <StyledAvatar
                src={R.path(['avatar', 'downloadUrl'], user)}
                firstName={R.path(['firstName'], user)}
                lastName={R.path(['lastName'], user)}
              />
              {user.lastName && user.firstName && (
                <div style={{ marginLeft: 10 }}>
                  <DropdownUserText
                    text={`${user.firstName} ${user.lastName}`}
                    fontSize={14}
                    color={'#323C47'}
                    fontWeight={700}
                  />
                  <DropdownUserText text={companyName} fontSize={12} />
                  <DropdownUserText text={roleName} fontSize={12} />
                  <DropdownUserText text={user.email} fontSize={13} color={'#323C47'} />
                </div>
              )}
            </Card.Header>
            <Menu.Item>
              <Link onClick={closeDropdown} to="/settings/user-profile">
                My Account
              </Link>
            </Menu.Item>
            <Menu.Item
              onClick={async () => {
                if (user.selectedAlliance) await auditLogUserLogin('LOGOUT');
                await logout(props);
                sessionStorage.clear();
                closeDropdown();
              }}>
              Log Out
            </Menu.Item>
          </Menu>
        )}
      </Dropdown.Body>
    </Dropdown>
  );
};

UserDropdown.propTypes = {
  history: PropTypes.object.isRequired,
};

export default compose(withAuth, withRouter, withApollo)(UserDropdown);
