import Flux from '@cobuildlab/flux-state';

/**
 * Event that triggers a Stage Mapping error.
 *
 * @type {string}
 */
export const STAGE_MAPPING_ERROR_EVENT = 'onStageMappingError';

export const STAGE_MAPPING_CREATE_EVENT = 'onStageMappingCreate';

export const STAGE_MAPPING_DETAIL_EVENT = 'onStageMappingDetail';

export const STAGE_MAPPING_UPDATE_EVENT = 'onStageMappingUpdate';

/**
 * Hold the Stage Mapping Data.
 *
 */
class StageMappingStore extends Flux.DashStore {
  constructor() {
    super();
    this.addEvent(STAGE_MAPPING_ERROR_EVENT);
    this.addEvent(STAGE_MAPPING_CREATE_EVENT);
    this.addEvent(STAGE_MAPPING_DETAIL_EVENT);
    this.addEvent(STAGE_MAPPING_UPDATE_EVENT);
  }
}

const stageMapping = new StageMappingStore();
export default stageMapping;
