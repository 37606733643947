import React from 'react';
import { Grid, Column, Row } from '@8base/boost';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';

const LeftSection = styled(Grid.Box)({
  textAlign: 'center',
  padding: '59px 55px 59px 21px',
});
const LeftTopImage = styled('div')({
  width: 201,
  height: 193,
  backgroundPosition: 'center',
  backgroundSize: 'contain',
  backgroundRepeat: 'no-repeat',
  marginRight: 0,
  marginBottom: 79,
  marginLeft: 'auto',
  transition: 'background-image .25s',
});

const ProgressIcon = (props) => <div className={`progress-icon ${props.type}`} alt="checkmark" />;

const renderProgressIcons = (currentScreen, sections) => {
  return sections.map((section, i) => {
    if (i < currentScreen) {
      return <ProgressIcon key={`icon${i}`} type="checked" />;
    }
    if ((currentScreen === i) & (i === sections.length - 1)) {
      return <ProgressIcon key={`icon${i}`} type="active last" />;
    }
    if (currentScreen === i) {
      return <ProgressIcon key={`icon${i}`} type="active" />;
    }
    if (i === sections.length - 1) {
      return <ProgressIcon key={`icon${i}`} type="last" />;
    }
    return <ProgressIcon key={`icon${i}`} />;
  });
};

const ProgressSectionName = (props) => (
  <div
    className={`progress-sec-name ${props.isActive}`}
    onClick={() => props.selectScreen(props.screenNumber)}>
    {props.sectionName}
  </div>
);

const renderSectionNames = (currentScreen, sections, selectScreen) => {
  return sections.map((section, i) => {
    if (i <= currentScreen) {
      return (
        <ProgressSectionName
          isActive="active"
          sectionName={section.progressName}
          selectScreen={selectScreen}
          screenNumber={i}
          key={`section${i}`}
        />
      );
    } else {
      return (
        <ProgressSectionName
          isActive=""
          sectionName={section.progressName}
          selectScreen={selectScreen}
          screenNumber={i}
          key={`section${i}`}
        />
      );
    }
  });
};

const LeftWizardSection = (props) => {
  const { currentScreen, sections, selectScreen } = props;
  const { image } = props.sections[currentScreen];

  return (
    <LeftSection>
      <LeftTopImage style={{ backgroundImage: `url("${image}")` }} />
      <Row>
        <Column>{renderProgressIcons(currentScreen, sections)}</Column>
        <Column>{renderSectionNames(currentScreen, sections, selectScreen)}</Column>
      </Row>
    </LeftSection>
  );
};

ProgressSectionName.propTypes = {
  isActive: PropTypes.string.isRequired,
  screenNumber: PropTypes.number.isRequired,
  selectScreen: PropTypes.func.isRequired,
  sectionName: PropTypes.string.isRequired,
};

ProgressIcon.propTypes = {
  type: PropTypes.string,
};

ProgressIcon.defaultProps = {
  type: 'default',
};

LeftWizardSection.propTypes = {
  currentScreen: PropTypes.number.isRequired,
  sections: PropTypes.array.isRequired,
  selectScreen: PropTypes.func.isRequired,
};

export { LeftWizardSection };
