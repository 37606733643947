import React, { Component } from 'react';
import sessionStore, { NEW_SESSION_EVENT } from '../../shared/SessionStore';
import * as toast from '../../components/toast/Toast';
import { Redirect } from 'react-router-dom';
import { isValidString } from '../../shared/validators';

/**
 * HOC to provide the alliance Id.
 *
 * @param {Component} ComponentToWrap - The component to wrap.
 * @returns {Component} Wrapped - The Wrapped Component.
 */
const withAlliance = (ComponentToWrap) => {
  return class WithAlliance extends Component {
    render() {
      const { selectedAlliance } = sessionStore.getState(NEW_SESSION_EVENT);
      if (!selectedAlliance || !isValidString(selectedAlliance.id)) {
        console.error('withAlliance:', 'Permission Error. No Alliance Selected');
        toast.error('Permission Error. No Alliance Selected');
        return <Redirect to={'/'} />;
      }
      return <ComponentToWrap {...this.props} allianceId={selectedAlliance.id} />;
    }
  };
};

export default withAlliance;
