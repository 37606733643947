import styled, { css } from 'styled-components';

const FieldItem = styled.div`
  border: 1px solid #d0d7dd;
  border-radius: 5px;
  display: flex;
  align-items: center;
  height: 36px;
  margin-bottom: 23px;
  position: relative;
  ${({ isDragging }) =>
    isDragging &&
    css`
      border: 1px dashed #d0d7dd;
      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #f4f5f6;
        z-index: 1;
      }
    `}
`;

const FieldItemName = styled.div`
  width: auto;
  white-space: nowrap;
  font-size: 13px;
  line-height: 19px;
  margin-right: 4px;
`;

export { FieldItem, FieldItemName };
