import { NEXT_STEP_COMPLETED } from '../../shared/status';
import { isUserAdminOrSERInAlliance } from '../../shared/alliance-utils';
import { isAllianceCompleted } from '@cobuildlab/collabtogrow-permissions';

/**
 * Can complete next Step Permission.
 *
 * @param {object} user - User.
 * @param {object} nextStep - NextStep.
 * @param {object} alliance - Alliance.
 * @returns {boolean} Validate Options.
 */
export const canCompleteNextStep = (user, nextStep, alliance) => {
  if (isAllianceCompleted(alliance)) return false;

  if (nextStep.status === NEXT_STEP_COMPLETED) return false;

  if (nextStep.assignedTo && nextStep.assignedTo.id === user.id) return true;

  return isUserAdminOrSERInAlliance(user, alliance);
};
/**
 * When restore item , change status to open.
 *
 * @param {object} user - User.
 * @param {object} nextStep - NextStep.
 * @param {object} alliance - Alliance.
 * @returns {boolean} Validate Options.
 */
export const canRestoreNextStep = (user, nextStep, alliance) => {
  if (isAllianceCompleted(alliance)) return false;

  if (nextStep.status !== NEXT_STEP_COMPLETED) return false;

  if (nextStep.assignedTo && nextStep.assignedTo.id === user.id) return true;

  return isUserAdminOrSERInAlliance(user, alliance);
};
