import * as R from 'ramda';
import { FIELD_TYPE } from '@8base/utils';
import { DateTime } from 'luxon';

import {
  getCurrentDayStart,
  getCurrentDayEnd,
  getFirstYearDay,
  getLastYearDay,
  getFirstCurrentMonthDay,
  getLastCurrentMonthDay,
  getFirstCurrentWeekDay,
  getLastCurrentWeekDay,
  getFirstQuarterMonth,
  getLastQuarterMonth,
  getFirstMonthDay,
  getLastMonthDay,
} from './date-utils';

export const uiFilterOperations = {
  DATE: {
    thisYear: 'This year',
    year: 'Year',
    quarter: 'Quarter',
    lastMonth: 'Last month',
    rollingSixMonth: 'Rolling 6 Months',
    month: 'Month',
    monthSet: 'Set of Months',
    lastWeek: 'Last week',
    today: 'Today',
    yesterday: 'Yesterday',
    mtd: 'MTD',
    ytd: 'YTD',
    range: 'Custom',
  },
  YEAR_MONTH: {
    thisYear: 'This year',
    year: 'Year',
    quarter: 'Quarter',
    lastMonth: 'Last month',
    rollingSixMonth: 'Rolling 6 Months',
    month: 'Month',
    monthSet: 'Set of Months',
    ytd: 'YTD',
  },
};

const getFieldById = (fieldsList, fieldId) => R.find(({ id }) => fieldId === id)(fieldsList);

const transformSingleFormRule = (mapConfig, formRule, fieldMeta) => {
  const { rule } = formRule;
  let { cond } = formRule;
  const currentRule = R.clone(rule);

  let { fieldType, name } = fieldMeta || {};

  if (fieldType === FIELD_TYPE.RELATION && currentRule) {
    name = name.split('.').shift();
  }

  const configPath = fieldType === FIELD_TYPE.RELATION ? name : rule;
  const mapConfigRule = R.path([fieldType, configPath], mapConfig);

  if (fieldType === FIELD_TYPE.NUMBER) {
    cond = Number(cond);
  }

  let mapConfigResult;
  if (fieldType === FIELD_TYPE.DATE) {
    mapConfigResult = R.isNil(mapConfigRule)
      ? { [rule]: cond }
      : mapConfigRule(cond, fieldMeta, rule);
  } else if (Array.isArray(cond) && currentRule !== 'some' && currentRule !== 'none') {
    mapConfigResult = cond.map((item) => {
      return { [rule]: item };
    });
  } else if (currentRule === 'some' || currentRule === 'none') {
    mapConfigResult = cond.map((item) => {
      return { id: { equals: item } };
    });
  } else {
    mapConfigResult = R.isNil(mapConfigRule)
      ? { [rule]: cond }
      : mapConfigRule(cond, fieldMeta, rule);
  }

  return Object.keys(mapConfigResult).reduce((accum, rule, index) => {
    if (!R.isNil(name) && !R.isNil(rule)) {
      if (
        fieldType === FIELD_TYPE.RELATION &&
        (currentRule === 'some' || currentRule === 'none') &&
        !R.isNil(mapConfigRule)
      ) {
        accum.push({ [name]: { [currentRule]: mapConfigResult[index] } });
      } else if (fieldType === FIELD_TYPE.TEXT && currentRule === 'equals') {
        accum.push({ [name]: mapConfigResult[index] });
      } else if (fieldType === FIELD_TYPE.TEXT && currentRule === 'not_equals') {
        if (index > 0) {
          accum.push({
            AND: { [name]: mapConfigResult[index] },
          });
        } else {
          accum.push({ [name]: mapConfigResult[index] });
        }
      } else {
        accum.push({ [name]: { [rule]: mapConfigResult[rule] } });
      }
    }
    return accum;
  }, []);
};

const formatDateISO = (fieldMeta) => (dateValue) =>
  R.path(['fieldTypeAttributes', 'format'], fieldMeta) === 'DATETIME'
    ? dateValue.toISO()
    : dateValue.toISODate();

const transformDateRelativeFormat = (rule) => (fieldValue, fieldMeta) =>
  R.map(
    (dateValue) => formatDateISO(fieldMeta)(dateValue),
    typeof rule === 'function' ? rule(fieldValue, fieldMeta) : rule,
  );

export const getOptionsFromObject = (obj) =>
  Object.entries(obj).map(([key, label]) => ({ value: key, label }));

export const createFilterGenerator = (mapConfig) => (fieldsList) =>
  R.pipe(
    R.map((formRule) => {
      const fieldMeta = getFieldById(fieldsList, formRule.fieldId);
      const filter = transformSingleFormRule(mapConfig, formRule, fieldMeta);
      const { rule: currentRule } = formRule;
      return currentRule === 'equals' ? { OR: filter } : filter;
    }),
    R.flatten,
  );

const getDefaultRelationRule = (cond, __, rule) => {
  return {
    id: {
      [rule]: cond,
    },
  };
};

const getInitiativesRule = (cond, __, rule) => {
  return {
    [rule]: {
      id: {
        equals: cond,
      },
    },
  };
};

export const generatorConfig = {
  DATE: {
    thisYear: transformDateRelativeFormat({
      gte: getFirstYearDay(),
      lt: getLastYearDay(),
    }),
    lastMonth: transformDateRelativeFormat({
      gte: getFirstCurrentMonthDay().minus({ month: 1 }),
      lt: getLastCurrentMonthDay().minus({ month: 1 }),
    }),
    rollingSixMonth: transformDateRelativeFormat({
      gte: getCurrentDayStart().minus({ month: 6 }),
      lt: getCurrentDayEnd(),
    }),
    lastWeek: transformDateRelativeFormat({
      gte: getFirstCurrentWeekDay().minus({ day: 7 }),
      lt: getLastCurrentWeekDay().minus({ day: 7 }),
    }),
    today: transformDateRelativeFormat({
      gte: getCurrentDayStart(),
      lt: getCurrentDayEnd(),
    }),
    yesterday: transformDateRelativeFormat({
      gte: getCurrentDayStart().minus({ day: 1 }),
      lt: getCurrentDayEnd().minus({ day: 1 }),
    }),
    mtd: transformDateRelativeFormat({
      gte: getCurrentDayStart().minus({ month: 1 }),
      lt: getCurrentDayStart().minus({ day: 1 }),
    }),
    ytd: transformDateRelativeFormat({
      gte: getCurrentDayStart().minus({ year: 1 }),
      lt: getCurrentDayStart().minus({ day: 1 }),
    }),
    range: transformDateRelativeFormat(([from, to]) => ({
      gte: DateTime.fromISO(from),
      lte: DateTime.fromISO(to),
    })),
    month: transformDateRelativeFormat((fieldValue) => ({
      gte: getFirstMonthDay(DateTime.fromISO(fieldValue)),
      lt: getLastMonthDay(DateTime.fromISO(fieldValue)),
    })),
    monthSet: transformDateRelativeFormat(([from, to]) => ({
      gte: DateTime.fromISO(from).set({ day: 1 }),
      lt: DateTime.fromISO(to)
        .set({ day: 1 })
        .plus({ month: 1 }),
    })),
    quarter: transformDateRelativeFormat(([year, quarterNumber]) => ({
      gte: getFirstQuarterMonth(year, quarterNumber),
      lt: getLastQuarterMonth(year, quarterNumber).plus({ month: 1 }),
    })),
    year: transformDateRelativeFormat((fieldValue) => ({
      gte: getFirstYearDay(fieldValue),
      lt: getLastYearDay(fieldValue),
    })),
  },
  RELATION: {
    owner: getDefaultRelationRule,
  },
};

const pivotTableGeneratorConfig = {
  DATE: generatorConfig.DATE,
  RELATION: {
    owner: getDefaultRelationRule,
    createdBy: getDefaultRelationRule,
    assignedTo: getDefaultRelationRule,
    requestedBy: getDefaultRelationRule,
    company: getDefaultRelationRule,
    source: getDefaultRelationRule,
    initiatives: getInitiativesRule,
  },
};

export const filterGenerator = createFilterGenerator(generatorConfig);
export const pivotTableFilterGenerator = createFilterGenerator(pivotTableGeneratorConfig);
