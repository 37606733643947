import React from 'react';
import { Row, Column, Text } from '@8base/boost';
import PropTypes from 'prop-types';
import { getPlanColor, getPlanSavingsOrPeriod } from '../procurer-utils';
import { PlanPrice } from './PlanPrice';
import { PlanName } from './PlanName';
import { PlanPaper } from './PlanPaper';

const ConfirmPlanDetailsCard = ({ plan, showAnnualPrice }) => {
  const { name } = plan;
  const capitalizeName = name.toUpperCase();
  const color = getPlanColor(name);
  const planSavings = getPlanSavingsOrPeriod(plan, showAnnualPrice);

  return (
    <PlanPaper padding={'md'} selected={true}>
      <Row>
        <Column stretch>
          <Column>
            <PlanName weight="bold" color={color}>
              {capitalizeName}
            </PlanName>
          </Column>
          <Text weight="normal">{planSavings}</Text>
        </Column>
        <Column stretch alignItems="end">
          <PlanPrice plan={plan} showAnnualPrice={showAnnualPrice} />
        </Column>
      </Row>
    </PlanPaper>
  );
};

ConfirmPlanDetailsCard.propTypes = {
  plan: PropTypes.object.isRequired,
  showAnnualPrice: PropTypes.bool.isRequired,
};

export { ConfirmPlanDetailsCard };
