import createDecorator from 'final-form-calculate';

const spySingleFilterItem = createDecorator({
  field: /filters\[\d\]\.fieldId/,
  isEqual: (prev, current) => {
    return prev === current;
  },
  updates: (__, fullFieldName) => {
    const [fieldName] = fullFieldName.split('.');
    return {
      [`${fieldName}.rule`]: undefined,
    };
  },
});

export { spySingleFilterItem };
