import React from 'react';
import { Dialog, Grid, Text, Button } from '@8base/boost';
import PropTypes from 'prop-types';
import { ActionButton } from '../buttons/ActionButton';
import logo from '../../images/logos/collabtogrow_main_logo.svg';

class ErrorDialog extends React.Component {
  render() {
    const { text, isOpen, title = '', onOk, okText, onSecondaryAction, secondaryText } = this.props;
    const headerContent = <img width={180} src={logo} alt={'Logo'} />;

    return (
      <Dialog size="sm" isOpen={isOpen}>
        <Dialog.Body scrollable>
          <Grid.Layout gap="sm" stretch>
            <Grid.Box alignItems={'center'}>{headerContent}</Grid.Box>
            <Grid.Box justifyContent={'center'} alignItems={'center'}>
              <br />
              <Text weight="bold">{title}</Text>
            </Grid.Box>
            <Grid.Box alignItems={'center'}>
              <br />
              <Text>{text}</Text>
              <br />
            </Grid.Box>
          </Grid.Layout>
        </Dialog.Body>
        <Dialog.Footer>
          {onSecondaryAction && (
            <Button
              type="button"
              onClick={onSecondaryAction}
              color="neutral"
              variant="outlined"
              className={'space-around'}>
              {secondaryText}
            </Button>
          )}
          <ActionButton fontAwesomeIcon={''} text={okText} onClick={onOk} />
        </Dialog.Footer>
      </Dialog>
    );
  }
}

ErrorDialog.defaultProps = {
  okText: 'Ok',
  onSecondaryAction: null,
  secondaryText: '',
};
ErrorDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onOk: PropTypes.func.isRequired,
  onSecondaryAction: PropTypes.func,
  secondaryText: PropTypes.string,
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  okText: PropTypes.string,
};

export default ErrorDialog;
