import React from 'react';
import ReactEcharts from 'echarts-for-react';
import PropTypes from 'prop-types';
import { Paper, Row, Text, NoData } from '@8base/boost';
import { PieLegend } from '../modules/dashboard/components/PieLegend';

const PieChart = ({
  title,
  data,
  itemName,
  onEvents,
  paperPadding,
  onClickLegend,
}) => {
  const isEmpty = data.series
    .map(serie => serie.data)
    .find(data => !data.length);
  
  return (
    <Paper style={{ cursor: 'pointer', height: '226px' }} stretch={true} padding={paperPadding}>
      <Text className="pie-title">{title}</Text>
      {!isEmpty ? (
        <>
          <Row growChildren>
            <ReactEcharts option={data} className="pie-graph" onEvents={onEvents} />
          </Row> 
          <PieLegend onClick={onClickLegend} item={itemName} />
        </>
      ) : (
        <NoData />
      )}
    </Paper>
  );
};

PieChart.propTypes = {
  data: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  itemName: PropTypes.string.isRequired,
  onEvents: PropTypes.object.isRequired,
  onClickLegend: PropTypes.func.isRequired,
  paperPadding: PropTypes.string.isRequired,
};

export default PieChart;
