import React from 'react';
import { Card, Grid, Heading, Loader, Row } from '@8base/boost';
import { CreateViewCardBody } from '../../../components/new-ui/card/CreateViewCardBody';
import IssueForm from './components/IssueForm';
import * as toast from '../../../components/toast/Toast';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { ISSUE_DOCUMENTS, IssueModel } from './issue-model';
import { View } from '@cobuildlab/react-simple-state';
import { onChangeDataMixin, onErrorMixin } from '../../../shared/mixins';
import * as R from 'ramda';
import sessionStore, { NEW_SESSION_EVENT } from '../../../shared/SessionStore';
import { autoSaveCreateIssue, createIssue } from './issue-actions';
import { fetchInitiativeList } from '../initiative/initiative-actions';
import { fetchCurrentAllianceMembersAction } from '../../settings/alliance-management/alliance-actions';
import { getCurrencyOnSession } from '../../../shared/alliance-utils';
import { ActionButton } from '../../../components/buttons/ActionButton';
import { ActionButtonClose } from '../../../components/buttons/ActionButtonClose';
import { saveFormToSessionStorage } from '../../../shared/utils';
import { issueValidator } from './issue-validators';
import { initiativesItemValidator } from '../initiative/initiative-validators';
import RelatedItemForm from '../../related-item/components/RelatedItemForm';
import { TransparentButton } from '../../../components/buttons/TransparentButton';
import { RelatedItemsDetailTable } from '../../related-item/components/RelatedItemsDetailTable';
import IssueDetailTable from './components/IssueDetailTable';
import { FormSteps } from '../../../components/dots/FormSteps';
import { InitiativeListTable } from '../initiative/components/InitiativeListTable';
import { LeftProgressSection } from '../../../components/new-ui/LeftProgressSection';
import { SCREENS_ISSUE } from '../screenView';
import { BoxCard } from '../../../components/new-ui/div/BoxCard';
import { CardFooter } from '../../../components/new-ui/card/CardFooter';
import { TextDateAgo } from '../../../components/text/TextDateAgo';
import { OnAllianceMemberList } from '../../settings/alliance-management/alliance-events';
import { OnInitiativeList } from '../initiative/initiative-events';
import { OnIssueCreate, OnIssueError, OnIssueAutoSave, OnIssueAutoSaveError } from './issue-events';

const ISSUE_DATA_STORE = 'issueCreateView';

/**
 * Create Issue.
 *
 */

class IssueCreateView extends View {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        issueData: R.clone(IssueModel),
        relatedItems: [],
        initiatives: [],
      },
      loading: true,
      initiativesList: [],
      clientCompany: null,
      partnerCompany: null,
      userRole: true,
      step: 0,
      savedAt: null,
      autoSaving: false,
    };
    this.onError = onErrorMixin.bind(this);
    this.onChangeData = onChangeDataMixin.bind(this);

    this.autoSaveTimer = null;
    this.savedIssue = null;
  }

  onChangeIssueData = (name, value) => {
    const { data } = this.state;
    data.issueData[name] = value;
    if (name === 'originalDueDate') {
      data.issueData.revisedDueDate = value;
    }
    this.autoSave();
    this.setState({ data });
    const model = R.clone(IssueModel);
    saveFormToSessionStorage(ISSUE_DATA_STORE, data.issueData, model, ['documents']);
  };

  componentDidMount() {
    const issueData = JSON.parse(sessionStorage.getItem(ISSUE_DATA_STORE));

    this.subscribe(OnIssueError, this.onError);

    this.subscribe(OnAllianceMemberList, (state) => {
      this.setState({
        clientCompany: R.clone(state.clientCompany),
        partnerCompany: R.clone(state.partnerCompany),
      });
    });

    this.subscribe(OnIssueCreate, () => {
      sessionStorage.removeItem(ISSUE_DATA_STORE);
      toast.success('Issue Successfully Created');
      this.props.history.goBack();
    });

    this.subscribe(OnInitiativeList, (state) => {
      const initiativesList = R.clone(state.initiativesList.items);
      this.setState({
        loading: false,
        initiativesList,
      });
    });

    this.subscribe(OnIssueAutoSave, (issue) => {
      sessionStorage.removeItem(ISSUE_DATA_STORE);
      this.setState({ savedAt: new Date(), autoSaving: false });
      this.savedIssue = R.clone(issue);
    });

    this.subscribe(OnIssueAutoSaveError, () => {
      this.setState({ autoSaving: false });
    });

    // set issueData from sessionStorage
    if (issueData) {
      const { data } = this.state;
      data.issueData = issueData;
      this.setState({ data });
    }

    const fetchPolicyOptions = { isCacheFirst: true };
    fetchInitiativeList('', 1, 1000, null, null, fetchPolicyOptions);
    fetchCurrentAllianceMembersAction();
  }

  autoSave = () => {
    const waitTime = 10000; // Wait 10s
    clearTimeout(this.autoSaveTimer);
    this.autoSaveTimer = setTimeout(() => {
      this.setState({ autoSaving: true });
      const issueData = R.clone(this.state.data.issueData);
      const relatedItems = R.clone(this.state.data.relatedItems);
      const initiatives = R.clone(this.state.data.initiatives);
      autoSaveCreateIssue(issueData, relatedItems, initiatives, this.savedIssue);
    }, waitTime);
  };

  componentWillUnmount() {
    super.componentWillUnmount();
    clearTimeout(this.autoSaveTimer);
    localStorage.removeItem(ISSUE_DOCUMENTS);
  }

  onSubmit = () => {
    this.setState({ loading: true }, () => {
      clearTimeout(this.autoSaveTimer);
      const issueData = R.clone(this.state.data.issueData);
      const relatedItems = R.clone(this.state.data.relatedItems);
      const initiatives = R.clone(this.state.data.initiatives);
      createIssue(issueData, relatedItems, initiatives, this.savedIssue);
    });
  };

  onIssueStepChange = (step) => {
    const { selectedAlliance } = sessionStore.getState(NEW_SESSION_EVENT);
    const issueData = R.clone(this.state.data.issueData);
    try {
      issueValidator(issueData, selectedAlliance);
    } catch (e) {
      return this.onError(e);
    }
    this.onScreen(step);
  };

  onScreen = (step) => {
    if (step === 2) clearTimeout(this.autoSaveTimer);
    this.setState({ step });
  };

  onRelatedItemsStepChange = (step) => {
    const initiatives = R.clone(this.state.data.initiatives);
    try {
      initiativesItemValidator(initiatives);
    } catch (e) {
      return this.onError(e);
    }
    this.onScreen(step);
  };

  render() {
    const {
      initiativesList,
      loading,
      clientCompany,
      partnerCompany,
      step,
      savedAt,
      autoSaving,
    } = this.state;
    const { user, selectedAlliance } = sessionStore.getState(NEW_SESSION_EVENT);
    const { relatedItems, issueData, initiatives } = this.state.data;
    const { history } = this.props;

    const companyId = user.companyUserRelation.items[0].company.id;
    const currency = getCurrencyOnSession();

    let content = <Loader stretch />;
    let footer = <></>;

    if (!loading && step === 0) {
      content = (
        <div id={'c2gScreen'}>
          <IssueForm
            data={issueData}
            onChange={this.onChangeIssueData}
            clientCompany={clientCompany}
            partnerCompany={partnerCompany}
            myCompanyId={companyId}
            currency={currency}
            user={user}
            selectedAlliance={selectedAlliance}
          />
        </div>
      );
      footer = (
        <CardFooter>
          <ActionButton onClick={() => this.onIssueStepChange(1)} text={'Next'} />
        </CardFooter>
      );
    }

    if (!loading && step === 1) {
      content = (
        <div id={'c2gScreen'}>
          <RelatedItemForm
            relatedItems={relatedItems}
            selectedInitiatives={initiatives}
            initiatives={initiativesList}
            onChange={(key, value) => {
              this.autoSave();
              this.onChangeData(key, value);
            }}
          />
        </div>
      );

      footer = (
        <CardFooter>
          <ActionButton onClick={() => this.onRelatedItemsStepChange(2)} text={'Next'} />
          <TransparentButton onClick={() => this.onScreen(0)} text={'Previous'} />
        </CardFooter>
      );
    }

    if (!loading && step === 2) {
      const issueDetail = R.clone(issueData);
      issueDetail.documents = { items: issueDetail.documents };
      issueDetail.nextSteps = { items: issueDetail.nextSteps };

      content = (
        <div id={'c2gScreen'}>
          <IssueDetailTable data={issueDetail} currency={currency} />
          <InitiativeListTable initiatives={initiatives} />

          <RelatedItemsDetailTable relatedItems={relatedItems} />
        </div>
      );

      footer = (
        <CardFooter>
          <ActionButton disabled={autoSaving} onClick={this.onSubmit} text={'Create Issue'} />
          <TransparentButton onClick={() => this.onScreen(1)} text={'Previous'} />
        </CardFooter>
      );
    }

    return (
      <React.Fragment>
        <Card.Header>
          <Grid.Layout
            columns="400px auto 400px"
            areas={[['left', 'center', 'right']]}
            style={{ width: '100%' }}>
            <Grid.Box area="left">
              <Row>
                <Heading type="h4" text="Create Issue" />
                <TextDateAgo from={savedAt} />
              </Row>
            </Grid.Box>
            <Grid.Box area="center">
              <FormSteps totalSteps={3} step={step} />
            </Grid.Box>
            <Grid.Box area="right" />
          </Grid.Layout>
          <ActionButtonClose onClick={history.goBack} />
        </Card.Header>
        <CreateViewCardBody>
          <Grid.Layout columns="30% 70%" areas={[['left', 'right']]} style={{ width: '100%' }}>
            <Grid.Box area="left">
              <LeftProgressSection sections={SCREENS_ISSUE} currentScreen={step} />
            </Grid.Box>
            <BoxCard>
              <Grid.Box area="right">{content}</Grid.Box>
            </BoxCard>
          </Grid.Layout>
        </CreateViewCardBody>
        {footer}
      </React.Fragment>
    );
  }
}

IssueCreateView.propTypes = {
  history: PropTypes.object.isRequired,
};

export default withRouter(IssueCreateView);
