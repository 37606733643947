import React from 'react';
import { Text } from '@8base/boost';
import PropTypes from 'prop-types';
/*
import UserDetailValue from '../../../components/UserDetailValue';
*/
import moment from 'moment';
import styled from 'styled-components';

/**
 * NextStepsDetail for DetailViews.
 */

const StyledContainer = styled('div')`
  border: 1px solid #d0d7dd;
  width: auto;
  position: relative;
  border-radius: 0.5rem;
  margin: 5px 0;
  display: flex;
  box-shadow: 1px 1px #0000001c;
  padding: 13px;

  & > .switchInitiative {
    right: 15px;
    top: 11px;
    */min-width: 48px;
    max-width: 48px;
    margin: 10px;
  }
`;

const StyledItemInfo = styled('div')`
  width: auto;
  padding: 10px;
`;

const StyledText = styled(Text)`
  display: block;
  text-align: left;
`;

const NextStepsDetail = ({ nextSteps, assignedTo }) => {
  let Container = StyledContainer;

  return (
    <>
      {nextSteps.items.map((nextStep, i) => {
        const momentDate = moment(nextStep.dueDate).format('MM/DD/YYYY');

        return (
          <>
            <Container key={i}>
              <StyledItemInfo>
                <StyledText>{nextStep.status}</StyledText>
                <StyledText>{nextStep.description}</StyledText>
                <StyledText weight={'medium'}>{momentDate}</StyledText>
              </StyledItemInfo>
            </Container>
          </>
        );
      })}
    </>
  );
};

NextStepsDetail.propTypes = {
  nextSteps: PropTypes.array.isRequired,
  assignedTo: PropTypes.string.isRequired,
};

export default NextStepsDetail;
