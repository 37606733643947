import React from 'react';
import { Card, Table, Heading, Dropdown, Menu, Icon, Grid, Loader } from '@8base/boost';
import { DropdownBodyOnTable } from '../../../components/dropdown/DropdownBodyOnTable';
import { ListCardBody } from '../../../components/card/ListCardBody';
import { Link } from 'react-router-dom';
import * as R from 'ramda';
import {
  fetchInitiativeList,
  requestApprovalForInitiative,
  deleteInitiative,
  fetchInitiativeFullList,
} from './initiative-actions';
import sessionStore, { NEW_SESSION_EVENT } from '../../../shared/SessionStore';
import {
  canEditInitiative,
  canSubmitForApprovalInitiative,
  canDeleteInitiative,
  canCreateInitiative,
} from './initiative-permissions';
import withAlliance from '../../../components/hoc/withAlliance';
import { View } from '@cobuildlab/react-simple-state';
import PropTypes from 'prop-types';
import YesNoDialog from '../../../components/dialogs/YesNoDialog';
import Status from '../../../components/Status';
import Moment from 'react-moment';
import { onErrorMixin, onListScrollMixin } from '../../../shared/mixins';
import { withRouter } from 'react-router-dom';
import * as toast from '../../../components/toast/Toast';
import { ActionButtonListView } from '../../../components/buttons/ActionButtonListView';
import { debounce, generatedExcel } from '../../../shared/utils';
import SearchInput from '../../../components/inputs/SearchInput';
import DetailDateValue from '../../../components/DetailDateValue';
import ItemFilter from '../../../components/ItemFilter';
import { INITIATIVE_STATUS_LABELS } from '../../../shared/status';
import { Body } from '../../../components/new-ui/font-style/Body';
import { COLUMNSEXCEL, COLUMNSOBJECT } from './initiative-model';
import { MoreActionButton } from '../../../components/buttons/MoreActionButton';
import {
  OnInitiativeError,
  OnInitiativeList,
  OnInitiativeDelete,
  OnInitiativeSubmitForApproval,
  OnInitiativeFullList,
} from './initiative-events';

/**
 * List All the Initiative Items.
 *
 * @param value
 * @param initiative
 */

class InitiativeListView extends View {
  constructor(props) {
    super(props);
    this.state = {
      initiativesList: [],
      initiative: {},
      loading: true,
      loadingPage: false,
      search: '',
      page: 1,
      count: 0,
      filter: '',
    };
    this.user = R.clone(sessionStore.getState(NEW_SESSION_EVENT).user);
    this.selectedAlliance = R.clone(sessionStore.getState(NEW_SESSION_EVENT).selectedAlliance);
    this.onError = onErrorMixin.bind(this);
    this.onListScroll = onListScrollMixin.bind(this);
    this.searchWithDebounce = debounce(300, this.searchWithDebounce)();
  }

  componentDidMount() {
    this.subscribe(OnInitiativeError, this.onError);
    this.subscribe(OnInitiativeList, (state) => {
      const items = state.initiativesList.items.map((initiative) => ({ ...initiative }));
      const { count } = R.clone(state.initiativesList);
      const { loadingPage, initiativesList: oldInitiativesList } = this.state;
      const initiativesList = loadingPage ? oldInitiativesList.concat(items) : items;

      this.setState({
        initiativesList,
        count,
        loading: false,
        loadingPage: false,
      });
    });

    this.subscribe(OnInitiativeDelete, () => {
      const { search, filter } = this.state;

      this.setState({ page: 1 }, () => {
        toast.success('Initiative Deleted!');
        fetchInitiativeList(search, 1, 20, filter);
      });
    });

    this.subscribe(OnInitiativeSubmitForApproval, () => {
      const { search, filter } = this.state;
      this.setState({ page: 1 }, () => {
        toast.success('Initiative Submitted!');
        fetchInitiativeList(search, 1, 20, filter);
      });
    });
    this.subscribe(OnInitiativeFullList, (state) => {
      this.setState({ loading: false });
      const items = state.initiativesList.items.map((item) => ({ ...item }));
      generatedExcel(items, COLUMNSOBJECT, COLUMNSEXCEL, 'InitiativeList');
    });

    const fetchPolicyOptions = { isCacheFirst: true };
    fetchInitiativeList('', 1, 20, '', null, fetchPolicyOptions);
  }

  goToCreateInitiative = () => {
    this.props.history.push('/management/initiative/create');
  };

  onSelectForDelete = (initiative) => {
    const [canDelete, errors] = canDeleteInitiative(this.user, initiative, this.selectedAlliance);
    if (canDelete) {
      this.setState({
        initiative,
        deleteModalIsOpen: true,
      });
    } else {
      toast.errors(errors);
    }
  };

  onSelectForApproval = (initiative) => {
    const [canSubmit, errors] = canSubmitForApprovalInitiative(
      this.user,
      initiative,
      this.selectedAlliance,
    );
    if (canSubmit) {
      this.setState({
        initiative,
        approvalModalIsOpen: true,
      });
    } else {
      toast.errors(errors);
    }
  };

  onYes = () => {
    this.setState(
      {
        deleteModalIsOpen: false,
        loading: true,
      },
      () => deleteInitiative(this.state.initiative),
    );
  };

  onYesApproval = () => {
    this.setState(
      {
        approvalModalIsOpen: false,
        loading: true,
      },
      () => requestApprovalForInitiative(this.state.initiative),
    );
  };

  onClose = () => {
    this.setState({
      deleteModalIsOpen: false,
      approvalModalIsOpen: false,
    });
  };

  onSearchChange = (value) => {
    this.setState({ search: value });
    this.searchWithDebounce();
  };

  searchWithDebounce = (value) => {
    const { filter } = this.state;
    this.setState({ loading: true, page: 1 }, () => {
      fetchInitiativeList(value, 1, 20, filter);
    });
  };

  statusFilter = (value) => {
    const { search } = this.state;
    this.setState(
      {
        loading: true,
        filter: value,
        page: 1,
      },
      () => {
        fetchInitiativeList(search, 1, 20, value);
      },
    );
  };

  exportExcel = () => {
    this.setState({ loading: true });
    fetchInitiativeFullList();
  };

  render() {
    const {
      initiativesList,
      deleteModalIsOpen,
      approvalModalIsOpen,
      search,
      loadingPage,
      loading,
      filter,
    } = this.state;
    const { history } = this.props;
    const alliance = this.selectedAlliance;

    return (
      <div className="items-card" id={'c2gScreen'}>
        <Card.Header>
          <Grid.Layout
            columns="150px 20fr 200px"
            areas={[['left', 'center', 'right']]}
            style={{ width: '100%' }}>
            <Grid.Box justifySelf="flex-start" area="left">
              <Heading type="h4" text="Initiatives" />
            </Grid.Box>
            <Grid.Box justifySelf="center" area="center">
              <SearchInput className="search-input" value={search} onChange={this.onSearchChange} />
            </Grid.Box>
            <Grid.Box area="right">
              <div style={{ width: '70%' }}>
                <ItemFilter
                  onChange={this.statusFilter}
                  value={filter}
                  options={INITIATIVE_STATUS_LABELS}
                  placeholder="Filter By Status"
                />
              </div>
              <Dropdown defaultOpen={false} style={{ position: 'absolute', right: '0px' }}>
                <Dropdown.Head>
                  <MoreActionButton />
                </Dropdown.Head>
                <Dropdown.Body pin="right">
                  {({ closeDropdown }) => (
                    <Menu>
                      <Menu.Item
                        onClick={() => {
                          this.exportExcel();
                          closeDropdown();
                        }}>
                        Export to Excel
                      </Menu.Item>
                    </Menu>
                  )}
                </Dropdown.Body>
              </Dropdown>
            </Grid.Box>
            <Grid.Box justifySelf="flex-end" />
          </Grid.Layout>
        </Card.Header>
        <ListCardBody className="items-table">
          <Table>
            <Table.Header
              className="justify-center-column"
              columns="220px 220px  220px  220px  220px  220px  220px">
              <Table.HeaderCell className="name-column">Name</Table.HeaderCell>
              <Table.HeaderCell>State</Table.HeaderCell>
              <Table.HeaderCell>RAG Status</Table.HeaderCell>
              <Table.HeaderCell>Date Created</Table.HeaderCell>
              <Table.HeaderCell>Owner</Table.HeaderCell>
              <Table.HeaderCell>Options</Table.HeaderCell>
              <Table.HeaderCell>
                Approvals <br />
                CLIENT / PARTNER{' '}
              </Table.HeaderCell>
            </Table.Header>
            <Table.Body
              onScroll={(event) => this.onListScroll(event, initiativesList, fetchInitiativeList)}
              loading={loading}
              data={initiativesList}
              className="card-body-list">
              {(initiative, index) => {
                const isLast = index === initiativesList.length - 1;
                const pageLoader = isLast && loadingPage ? <Loader stretch /> : null;
                const approvalItems =
                  initiative.initiativeApprovalInitiativeRelation.items !== undefined
                    ? initiative.initiativeApprovalInitiativeRelation.items.slice(-2)
                    : '';

                return (
                  <>
                    <Table.BodyRow
                      columns="250px 230px 200px 220px 190px 230px 400px"
                      key={initiative.id}>
                      <Table.BodyCell className="name-column table-cell">
                        <Link className="item-name" to={`/management/initiative/${initiative.id}/`}>
                          {initiative.name}
                        </Link>
                      </Table.BodyCell>
                      <Table.BodyCell className="table-cell">
                        <span>{initiative.status}</span>
                      </Table.BodyCell>
                      <Table.BodyCell className="table-cell">
                        <Status status={initiative.ragStatus} />
                      </Table.BodyCell>
                      <Table.BodyCell className="table-cell">
                        <Body>
                          <Moment format="MMMM Do, YYYY">
                            {R.pathOr(
                              <span style={{ color: 'lightgrey' }}>Not Available</span>,
                              ['createdAt'],
                              initiative,
                            )}
                          </Moment>
                        </Body>
                      </Table.BodyCell>
                      <Table.BodyCell className="justify-left-row table-cell">
                        <Body>
                          {`${R.pathOr('', ['owner', 'firstName'], initiative)}  ${R.pathOr(
                            '',
                            ['owner', 'lastName'],
                            initiative,
                          )}`}
                        </Body>
                      </Table.BodyCell>
                      <Table.BodyCell className="justify-center-row">
                        <Dropdown defaultOpen={false}>
                          <Dropdown.Head>
                            <Icon name="More" className="more-icon" />
                          </Dropdown.Head>
                          <DropdownBodyOnTable>
                            {({ closeDropdown }) => (
                              <Menu>
                                <Menu.Item
                                  onClick={() => {
                                    history.push(`/management/initiative/${initiative.id}`);
                                  }}>
                                  Details
                                </Menu.Item>
                                {canEditInitiative(this.user, initiative, alliance) ? (
                                  <Menu.Item
                                    onClick={() => {
                                      history.push(`/management/initiative/edit/${initiative.id}`);
                                    }}>
                                    Edit
                                  </Menu.Item>
                                ) : null}
                                <Menu.Item
                                  onClick={() => {
                                    closeDropdown();
                                    this.onSelectForApproval(initiative);
                                  }}>
                                  Submit For Approval
                                </Menu.Item>
                                <Menu.Item
                                  onClick={() => {
                                    closeDropdown();
                                    this.onSelectForDelete(initiative);
                                  }}>
                                  Delete
                                </Menu.Item>
                              </Menu>
                            )}
                          </DropdownBodyOnTable>
                        </Dropdown>
                      </Table.BodyCell>
                      <Table.BodyCell>
                        {approvalItems !== null
                          ? approvalItems.map((approval, i) => (
                            <div key={i}>
                              <Status status={approval.status} />
                              <DetailDateValue date={approval.dateOfResponse} />
                            </div>
                          ))
                          : ''}
                      </Table.BodyCell>
                    </Table.BodyRow>
                    {pageLoader}
                  </>
                );
              }}
            </Table.Body>
          </Table>
        </ListCardBody>
        <YesNoDialog
          isOpen={deleteModalIsOpen}
          onYes={this.onYes}
          onNo={this.onClose}
          onClose={this.onClose}
          text={'Are you sure you want to Delete this Initiative?'}
          title={'Delete Initiative'}
        />
        <YesNoDialog
          isOpen={approvalModalIsOpen}
          onYes={this.onYesApproval}
          onNo={this.onClose}
          onClose={this.onClose}
          text={'Are you sure you want to Submit this Initiative For Approval?'}
          title={'Submit For Approval'}
        />

        {canCreateInitiative(this.user, alliance) ? (
          <ActionButtonListView onClick={this.goToCreateInitiative} text="Create Initiative" />
        ) : null}
      </div>
    );
  }
}

InitiativeListView.propTypes = {
  history: PropTypes.object.isRequired,
};

export default withRouter(withAlliance(InitiativeListView));
