import React from 'react';
import {
  InputField,
  Row,
  TextAreaField,
  Label,
  Column,
  SelectField,
  DateInputField,
} from '@8base/boost';
import PropTypes from 'prop-types';
import { onErrorMixin } from '../../../../shared/mixins';
import { SelectInputById } from '../../../../components/forms/SelectInputById';
import { UNIT_TYPES } from '../../../../shared/item-types';
import NextStepForm from '../../../../modules/next-step/components/NextStepFormNew';
import { concatClientAndPartnerUsers } from '../../../../shared/alliance-utils';
import FileInputComponent from '../../../../components/inputs/FileInputComponent';
import YesNoDialog from '../../../../components/dialogs/YesNoDialog';
import { HorizontalLine } from '../../../../components/new-ui/text/HorizontalLine';
import { HorizontalLineText } from '../../../../components/new-ui/text/HorizontalLineText';
import { GroupInputs } from '../../../../components/new-ui/inputs/GroupInputs';
import { RowForm } from '../../../../components/new-ui/div/RowForm';
import { CurrencyInputField } from '../../../../shared/components/CurrencyInputField';
import DocumentTitle from '../../../../components/DocumentTitle';

class RiskForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      deleteDocumentModalIsOpen: false,
      deleteDocumentPos: null,
    };
    this.onClickDelete = this.onClickDelete.bind(this);
    this.onChangeDocuments = this.onChangeDocuments.bind(this);
  }

  onClickDelete = (pos) => {
    this.setState({
      deleteDocumentModalIsOpen: true,
      deleteDocumentPos: pos,
    });
  };

  onYes = () => {
    this.setState(
      {
        deleteDocumentModalIsOpen: false,
      },
      () => {
        this.onDeleteDocuments('documents', this.state.deleteDocumentPos);
      },
    );
  };

  onClose = () => {
    this.setState({
      deleteDocumentModalIsOpen: false,
    });
  };

  onChangeDocuments(name, value) {
    const riskData = this.props.data;
    for (let aux of value) {
      riskData[name].push(aux);
    }
    this.props.onChange('documents', riskData[name]);
  }

  onDeleteDocuments = (name, pos) => {
    const riskData = this.props.data;
    riskData[name].splice(pos, 1);
    this.setState({ deleteDocumentPos: null });
    this.props.onChange('documents', riskData[name]);
  };

  render() {
    const { deleteDocumentModalIsOpen } = this.state;
    const { onChange, clientCompany, partnerCompany, currency } = this.props;
    const {
      name,
      description,
      documents,
      unitType,
      unitQuantity,
      unitValueDescription,
      unitMonetizationFactor,
      nextSteps,
      assignedTo,
      assignedDate,
      revisedDueDate,
      originalDueDate,
      source,
    } = this.props.data;

    // concat client and partner users
    const users = concatClientAndPartnerUsers(clientCompany, partnerCompany);
    // company sources
    const companySources = [];
    if (clientCompany) {
      companySources.push({ label: clientCompany.name, value: clientCompany });
    }
    if (partnerCompany) {
      companySources.push({ label: partnerCompany.name, value: partnerCompany });
    }

    return (
      <>
        <HorizontalLine>
          <HorizontalLineText text={'RISK'} />
        </HorizontalLine>
        <>
          <GroupInputs text={'General'}>
            <Row growChildren gap="lg">
              <Column stretch>
                <InputField
                  stretch
                  label="Name"
                  input={{
                    name: 'name',
                    value: name,
                    onChange: (value, e) => onChange(e.target.name, value),
                  }}
                />
                <TextAreaField
                  style={{ width: '100%', height: 152 }}
                  label="Description"
                  input={{
                    name: 'description',
                    value: description,
                    onChange: (value) => onChange('description', value),
                  }}
                />
                <NextStepForm assignedTo={assignedTo} nextSteps={nextSteps} onChange={onChange} />
              </Column>
            </Row>
          </GroupInputs>
          <GroupInputs text={'Dates'}>
            <RowForm growChildren gap="lg">
              <Column style={{ width: '35%' }}>
                <DateInputField
                  label="Assigned Date"
                  input={{
                    name: 'assignedDate',
                    value: assignedDate,
                    onChange: (value) => onChange('assignedDate', value),
                  }}
                />

                <DateInputField
                  label="Original Due Date"
                  input={{
                    name: 'originalDueDate',
                    value: originalDueDate,
                    onChange: (value) => onChange('originalDueDate', value),
                  }}
                />

                <DateInputField
                  label="Revised Due Date"
                  input={{
                    name: 'revisedDueDate',
                    value: revisedDueDate,
                    onChange: (value) => onChange('revisedDueDate', value),
                  }}
                />
              </Column>
              <Column style={{ width: '65%', 'margin-left': '23px' }}>
                <SelectInputById
                  label="Assigned To"
                  input={{
                    name: 'assignedTo',
                    value: assignedTo,
                    onChange: (value) => {
                      nextSteps.forEach((nextStep) => (nextStep.assignedTo = value));
                      onChange('nextSteps', nextSteps);
                      onChange('assignedTo', value);
                    },
                  }}
                  meta={{}}
                  placeholder="Select"
                  options={users.map((user) => {
                    return {
                      label: `${user.firstName} ${user.lastName}`,
                      value: user,
                    };
                  })}
                />
              </Column>
            </RowForm>
          </GroupInputs>
          <GroupInputs text={'Funding Type'} show={true}>
            <RowForm growChildren gap="lg">
              <Column style={{ width: '100%' }}>
                <SelectInputById
                  label="Select Source (Optional)"
                  input={{
                    name: 'source',
                    value: source,
                    onChange: (value) => onChange('source', value),
                  }}
                  meta={{}}
                  placeholder="Select"
                  options={companySources}
                />
              </Column>
            </RowForm>
            <RowForm growChildren gap="lg">
              <Column style={{ width: '35%' }}>
                <InputField
                  placeholder={'Unit Quantity'}
                  label={`Unit Quantity`}
                  type={'number'}
                  input={{
                    value: unitQuantity,
                    onChange: (val) => {
                      onChange('unitQuantity', val);
                    },
                  }}
                />
              </Column>
              <Column style={{ width: '65%', 'margin-left': '23px' }}>
                <SelectField
                  label="Unit Type"
                  input={{
                    name: 'unitType',
                    value: unitType,
                    onChange: (value) => onChange('unitType', value),
                  }}
                  meta={{}}
                  placeholder="Select unit Type"
                  options={UNIT_TYPES.map((item) => {
                    return {
                      label: `${item}`,
                      value: item,
                    };
                  })}
                />
              </Column>
            </RowForm>
            <RowForm>
              <Column style={{ width: '100%' }}>
                <InputField
                  stretch
                  label="Unit Value Description"
                  placeholder={'Description'}
                  input={{
                    name: 'unitValueDescription',
                    value: unitValueDescription,
                    onChange: (value, e) => onChange(e.target.name, value),
                  }}
                />
                <CurrencyInputField
                  stretch
                  value={unitMonetizationFactor}
                  onChange={(val) => {
                    onChange('unitMonetizationFactor', val);
                  }}
                  label={`Unit Monetization Factor`}
                  currency={currency}
                />
              </Column>
            </RowForm>
          </GroupInputs>
          <HorizontalLine>
            <HorizontalLineText text={'DOCUMENTS'} />
          </HorizontalLine>
          <Row style={{ 'padding-bottom': '15px' }}>
            <Column alignItems="">
              <div style={{ 'margin-left': '20px' }}>
                <Label kind="secondary" style={{ textAlign: 'left' }}>
                  <DocumentTitle text={'Documents File'} maxFiles={5} files={documents} />
                </Label>
                <FileInputComponent
                  onChange={this.onChangeDocuments}
                  nameInput={'documents'}
                  field={documents}
                  maxFiles={5}
                  text={'Add Document'}
                  onClickDelete={this.onClickDelete}
                />
              </div>
            </Column>
          </Row>
        </>
        <YesNoDialog
          isOpen={deleteDocumentModalIsOpen}
          onYes={this.onYes}
          onNo={this.onClose}
          onClose={this.onClose}
          text={'Are you sure you want to Delete this Document?'}
          title={'Delete Document'}
        />
      </>
    );
  }
}

Object.assign(RiskForm.prototype, onErrorMixin);

RiskForm.propTypes = {
  data: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  currency: PropTypes.object.isRequired,
  clientCompany: PropTypes.object,
  partnerCompany: PropTypes.object,
};

RiskForm.defaultProps = {
  clientCompany: null,
  partnerCompany: null,
};

export default RiskForm;
