import React from 'react';
import { Button, Dropdown, Grid, Heading, Icon, Loader, Text } from '@8base/boost';
import { View } from '@cobuildlab/react-simple-state';

import { Menu } from '../../../shared/components/Menu';
import { CurrencyFormat } from '../../../shared/components/CurrencyFormat';
import { ViewCardBody } from '../../../components/card/ViewCardBody';
import sessionStore, { NEW_SESSION_EVENT } from '../../../shared/SessionStore';

import { FooterTotalValue, ReportFooter, ReportHeader, ReportsCard } from '../components';
import { FIELDS_LIST } from './active-sales-pipeline-model';
import { ActiveSalesPipelineTable } from './ActiveSalesPipelineTable';
import { ActiveSalesPipelineDataHandler } from './ActiveSalesPipelineDataHandler';
import {
  exportToExcelActiveSalesPipelineTables,
  generateActiveSalesPipelineTables,
} from './active-sales-pipeline-utils';
import { formatUser } from '../../../shared/utils';

const fieldsDict = FIELDS_LIST.reduce((dict, field) => {
  return {
    ...dict,
    [field.name]: field,
  };
}, {});

class ReportView extends View {
  render() {
    return (
      <div id={'c2gScreen'}>
        <ReportsCard stretch withFooter>
          {this.renderContent()}
        </ReportsCard>
      </div>
    );
  }

  renderContent = () => {
    const {
      sharedData: {
        config: { isTotals },
      },
      requestedData: { deals, groupBy, total, currency },
      loading,
    } = this.props;

    if (loading) {
      return <Loader stretch />;
    }

    const { user } = sessionStore.getState(NEW_SESSION_EVENT);

    const tablesData = generateActiveSalesPipelineTables(deals, groupBy);

    const reportName = 'Active Sales Pipeline';

    return (
      <>
        <ReportHeader>
          <Heading type="h4" text={reportName} />
          {/* Temporarily commented out, these functions are in development right now
          <SearchBox /> */}
          <Grid.Box direction="row" alignItems="center" justifyContent="flex-end">
            <Dropdown defaultOpen={false}>
              <Dropdown.Head>
                <Button variant="outlined" color="GRAY4" squared>
                  <Icon name="More" />
                </Button>
              </Dropdown.Head>
              <Dropdown.Body pin="right">
                {({ closeDropdown }) => (
                  <Menu>
                    <Menu.Item
                      onClick={() => {
                        const fileName = `${reportName} ${new Date().toISOString()}`;
                        const userName = formatUser(user);
                        exportToExcelActiveSalesPipelineTables(
                          tablesData,
                          groupBy,
                          fileName,
                          userName,
                        );
                        closeDropdown();
                      }}>
                      Export to Excel
                    </Menu.Item>
                  </Menu>
                )}
              </Dropdown.Body>
            </Dropdown>
          </Grid.Box>
        </ReportHeader>
        <ViewCardBody style={{ padding: 0 }} className="card-body-report-with-footer">
          <ActiveSalesPipelineTable
            dealsList={deals}
            groupBy={groupBy}
            currency={currency}
            fieldsDict={fieldsDict}
            isTotals={isTotals}
          />
        </ViewCardBody>
        <ReportFooter>
          <Text weight="semibold" color="GRAY4">
            TOTAL
          </Text>

          <FooterTotalValue weight="semibold">
            <CurrencyFormat {...currency} value={total} displayType="text" decimalScale={2} />
          </FooterTotalValue>
        </ReportFooter>
      </>
    );
  };
}

export const ActiveSalesPipelineReportView = ActiveSalesPipelineDataHandler(ReportView);
