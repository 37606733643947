import React from 'react';
import PropTypes from 'prop-types';
import DetailValue from '../../../../components/DetailValue';
import DetailDateValue from '../../../../components/DetailDateValue';
import { CurrencyTextField } from '../../../../shared/components/CurrencyTextField';
import NextStepsDetail from '../../../../modules/next-step/components/NextStepsDetail';
import UserDetailValue from '../../../../components/UserDetailValue';
import DocumentsFileComponent from '../../../../components/inputs/DocumentsFileComponent';
import { ISSUE_DOCUMENTS } from '../issue-model';
import { calculateValueBigInt } from '../../../../shared/utils';
import { HorizontalLine } from '../../../../components/new-ui/text/HorizontalLine';
import { HorizontalLineText } from '../../../../components/text/HorizontalLineText';
import { BoderDetailView } from '../../../../components/new-ui/div/BorderDetailView';
import { Grid } from '@8base/boost';
import { TablePosition } from '../../../../components/new-ui/div/TablePosition';
import { TableDetail } from '../../../../components/new-ui/table/TableDetail';
import { HeaderText } from '../../../../components/new-ui/text/HeaderText';
import { EditButton } from '../../../../components/new-ui/buttons/EditButton';
import sessionStore, { NEW_SESSION_EVENT } from '../../../../shared/SessionStore';
import { ThTitlePosition } from '../../../../components/new-ui/div/ThTitlePosition';
import { canEditIssue } from '../issue-permissions';

/**
 * Detail View Table For The Issue Detail Table.
 */

const IssueDetailTable = (props) => {
  const { data, currency, onClickEdit } = props;
  const {
    name,
    nextSteps,
    description,
    documents,
    status,
    assignedDate,
    originalDueDate,
    assignedTo,
    unitType,
    unitQuantity,
    unitValueDescription,
    unitMonetizationFactor,
  } = data;

  const { user, selectedAlliance } = sessionStore.getState(NEW_SESSION_EVENT);

  return (
    <>
      <HorizontalLine>
        <HorizontalLineText>ISSUE</HorizontalLineText>
      </HorizontalLine>
      <>
        <BoderDetailView>
          <Grid.Layout
            columns="auto 100px"
            areas={[['left', 'right']]}
            style={{ width: '100%', height: '100%' }}>
            <Grid.Box area="left">
              <div style={{ position: 'absolute', top: '21px', left: '25px' }}>
                <HeaderText>ISSUE</HeaderText>
              </div>
            </Grid.Box>
            <Grid.Box area="right" justifyContent={'center'}>
              {canEditIssue(user, data, selectedAlliance) ? (
                <EditButton onClick={onClickEdit} text="Edit" />
              ) : null}
            </Grid.Box>
          </Grid.Layout>
        </BoderDetailView>
        <TablePosition>
          <TableDetail>
            <tbody>
              <tr>
                <th>
                  <ThTitlePosition>
                    <span>STATE</span>
                  </ThTitlePosition>
                </th>
                <td>
                  <span> {status} </span>
                </td>
              </tr>
              <tr>
                <th>
                  <ThTitlePosition>
                    <span>NEXT STEPS</span>
                  </ThTitlePosition>
                </th>
                <td>
                  <NextStepsDetail nextSteps={nextSteps} assignedTo={assignedTo} />
                </td>
              </tr>
              <tr>
                <th>
                  <ThTitlePosition>
                    <span>NAME</span>
                  </ThTitlePosition>
                </th>
                <td>
                  <DetailValue text={name} />
                </td>
              </tr>
              <tr>
                <th>
                  <ThTitlePosition>
                    <span>DESCRIPTION</span>
                  </ThTitlePosition>
                </th>
                <td>
                  <DetailValue text={description} />
                </td>
              </tr>
              <tr>
                <th>
                  <ThTitlePosition>
                    <span>ASSIGNED TO</span>
                  </ThTitlePosition>
                </th>
                <td>
                  <UserDetailValue user={assignedTo} />
                </td>
              </tr>
              <tr>
                <th>
                  <ThTitlePosition>
                    <span>ASSIGNED DATE</span>
                  </ThTitlePosition>
                </th>
                <td>
                  <DetailDateValue date={assignedDate} />
                </td>
              </tr>
              <tr>
                <th>
                  <ThTitlePosition>
                    <span>ORIGINAL DUE DATE</span>
                  </ThTitlePosition>
                </th>
                <td>
                  <DetailDateValue date={originalDueDate} />
                </td>
              </tr>
              <tr>
                <th>
                  <ThTitlePosition>
                    <span>UNIT TYPE</span>
                  </ThTitlePosition>
                </th>
                <td>
                  <DetailValue text={unitType} />
                </td>
              </tr>
              <tr>
                <th>
                  <ThTitlePosition>
                    <span>UNIT QUANTITY</span>
                  </ThTitlePosition>
                </th>
                <td>
                  <DetailValue text={unitQuantity} />
                </td>
              </tr>
              <tr>
                <th>
                  <ThTitlePosition>
                    <span>UNIT VALUE DESCRIPTION</span>
                  </ThTitlePosition>
                </th>
                <td>
                  <DetailValue text={unitValueDescription} />
                </td>
              </tr>
              <tr>
                <th>
                  <ThTitlePosition>
                    <span>UNIT MONETIZATION FACTOR</span>
                  </ThTitlePosition>
                </th>
                <td>
                  <CurrencyTextField value={unitMonetizationFactor} currency={currency} />
                </td>
              </tr>
              <tr>
                <th>
                  <ThTitlePosition>
                    <span>CALCULATED VALUE</span>
                  </ThTitlePosition>
                </th>
                <td>
                  <CurrencyTextField
                    value={calculateValueBigInt(unitQuantity, unitMonetizationFactor)}
                  />
                </td>
              </tr>
              <tr>
                <th>
                  <ThTitlePosition>
                    <span>DOCUMENTS</span>
                  </ThTitlePosition>
                </th>
                <td>
                  <DocumentsFileComponent data={documents} localKey={ISSUE_DOCUMENTS} />
                </td>
              </tr>
            </tbody>
          </TableDetail>
        </TablePosition>
      </>
    </>
  );
};

IssueDetailTable.defaultProps = {
  onClickEdit: null, // null for form previews
};

IssueDetailTable.propTypes = {
  data: PropTypes.object.isRequired,
  currency: PropTypes.object.isRequired,
  onClickEdit: PropTypes.func,
};

export default IssueDetailTable;
