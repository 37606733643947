import moment from 'moment';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { TransparentButton } from '../../../components/buttons/TransparentButton';
import { Table } from '@8base/boost';
import { InvoiceStatusTag } from './InvoiceStatusTag';
import { CurrencyFormat } from '../../../shared/components/CurrencyFormat';
import React, { useState } from 'react';
import { useSubscription } from '@cobuildlab/react-simple-state';
import {
  OnSubscriptionInvoiceRetryLoading,
  OnSubscriptionInvoiceRetryError,
} from '../procurer-events';

export const InvoiceItem = ({ invoice, onRetry, ...props }) => {
  const [loading, setLoading] = useState(false);

  useSubscription(OnSubscriptionInvoiceRetryLoading, (loadingInvoice) => {
    if (loadingInvoice.id === invoice.id) setLoading(true);
  });
  useSubscription(OnSubscriptionInvoiceRetryError, () => {
    if (loading) setLoading(false);
  });

  const { total, createdAt, receiptUrl, status } = invoice;
  const date = moment(createdAt).format('L');
  const hasFailed = status === 'failed';
  const viewReceipt = receiptUrl ? (
    <Link class="item-name" onClick={() => window.open(receiptUrl)}>
      {'View PDF'}
    </Link>
  ) : null;

  const retryButton = (
    <TransparentButton loading={loading} onClick={() => onRetry(invoice)} text="Retry Payment" />
  );

  return (
    <Table.BodyRow {...props}>
      <Table.BodyCell justifyContent="start">{date}</Table.BodyCell>
      <Table.BodyCell justifyContent="start">{invoice.plan}</Table.BodyCell>
      <Table.BodyCell justifyContent="start">
        <InvoiceStatusTag invoice={invoice} />
      </Table.BodyCell>
      <Table.BodyCell justifyContent="end">
        <CurrencyFormat value={total} displayType="text" />
      </Table.BodyCell>
      <Table.BodyCell justifyContent="center">
        {hasFailed ? retryButton : viewReceipt}
      </Table.BodyCell>
    </Table.BodyRow>
  );
};

InvoiceItem.propTypes = {
  invoice: PropTypes.object.isRequired,
  onRetry: PropTypes.func.isRequired,
};
