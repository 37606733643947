import React from 'react';
import PropTypes from 'prop-types';
import { Text } from '@8base/boost';
import moment from 'moment';
import styled from '@emotion/styled';

// Component checks if there's a value present to render
// or else returns "None"

const StyledDate = styled(Text)`
  color: #989898;
  font-family: Poppins;
  font-size: 12px;
`;

const TimeFromNow = ({ date }) => {
  return <StyledDate text={moment(date).from(moment())} />;
};

TimeFromNow.propTypes = {
  date: PropTypes.string.isRequired,
};

export { TimeFromNow };
