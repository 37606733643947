export const UserInformation = {
  firstName: '',
  lastName: '',
  email: '',
  phones: [],
  country: null,
  zipCode: '',
  city: '',
  state: '',
  isPictureUploaded: false,
  title: '',
  avatar: null,
};

export const Phone = {
  id: '',
  type: null,
  phone: '',
};

export const PhoneType = {
  id: null,
  name: '',
};
