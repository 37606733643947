import gql from 'graphql-tag';
import { UserFragment, RoleFragment } from '../../auth/queries';

export const CompanyFragment = gql`
  fragment CompanyFragment on Company {
    id
    name
    description
    missionAndGoals
    website
    address1
    address2
    zipCode
    city
    state
    phone
    numberOfEmployees
    annualRevenue
    parentCompany
    companyUserRelation {
      count
      items {
        user {
          id
          firstName
          lastName
          email
        }
        role {
          id
          name
        }
      }
    }
    companyStatus
    tickerSymbol
    logoFile {
      id
      fileId
      filename
      downloadUrl
    }
    country {
      id
      name
    }
    industry {
      id
      name
    }
  }
`;

export const CompanyUserFragment = gql`
  fragment CompanyUserFragment on CompanyUser {
    id
    user {
      ...UserFragment
    }
    role {
      ...RolesFragment
    }
    company {
      id
      name
    }
  }
  ${UserFragment}
  ${RoleFragment}
`;

export const CREATE_COMPANY_MUTATION = gql`
  mutation CreateCompany($data: CompanyCreateInput!) {
    companyCreate(data: $data) {
      id
    }
  }
`;

export const COMPANY_FORM_DATA_QUERY = gql`
  query($userId: ID) {
    companyUsersList(filter: { user: { id: { equals: $userId } } }) {
      items {
        company {
          ...CompanyFragment
        }
        role {
          id
          name
        }
      }
      count
    }
    countriesList(sort: { name: ASC }) {
      count
      items {
        id
        name
      }
    }
    statesList(filter: { country: { name: { equals: "United States" } } }) {
      items {
        id
        name
      }
    }
    industriesList {
      count
      items {
        id
        name
      }
    }
  }
  ${CompanyFragment}
`;

export const COMPANY_DETAIL_QUERY = gql`
  query($id: ID) {
    company(id: $id) {
      ...CompanyFragment
      companyUserRelation {
        items {
          id
          user {
            id
            firstName
            lastName
            email
          }
          role {
            name
            id
          }
        }
      }
    }
  }
  ${CompanyFragment}
`;

export const COMPANY_LIST_QUERY = gql`
  query($data: CompanyFilter, $skip: Int, $first: Int) {
    companiesList(filter: $data, skip: $skip, first: $first) {
      count
      items {
        id
        name
        companyUserRelation {
          count
          items {
            user {
              id
              firstName
              lastName
              email
            }
            role {
              id
              name
            }
          }
        }
        companyStatus
      }
    }
  }
`;

export const EDIT_COMPANY_MUTATION = gql`
  mutation($data: CompanyUpdateInput!) {
    companyUpdate(data: $data) {
      id
    }
  }
`;

export const LEAVE_COMPANY_MUTATION = gql`
  mutation($data: CompanyUserDeleteInput) {
    companyUserDelete(data: $data, force: true) {
      success
    }
  }
`;

export const COMPANY_LIST_MEMBERS_QUERY = gql`
  query($id: ID) {
    companyUsersList(filter: { company: { id: { equals: $id } } }) {
      items {
        id
        role {
          id
          name
        }
        user {
          id
          email
          firstName
          lastName
        }
      }
    }
  }
`;

/* Mutation to change reverse the roles of the old owner and the new owner */
export const TRANSFER_OWNER_MUTATION = gql`
  mutation($data1: CompanyUserUpdateInput!, $data2: CompanyUserUpdateInput!) {
    updateCollaborator: companyUserUpdate(data: $data1) {
      id
    }
    updateOwner: companyUserUpdate(data: $data2) {
      id
    }
  }
`;

/* Mutation to assign a new role to a member */
export const UPDATE_ROLE_MUTATION = gql`
  mutation($data: CompanyUserUpdateInput!) {
    companyUserUpdate(data: $data) {
      id
    }
  }
`;

export const COMPANY_INVITATIONS_QUERY = gql`
  query($filter: CompanyInvitationFilter!) {
    companyInvitationsList(filter: $filter) {
      count
      items {
        id
        email
        status
        company {
          id
          name
        }
        role {
          id
          name
        }
      }
    }
  }
`;

export const COMPANY_SUBSCRIPTION_QUERY = gql`
  query($filter: CompanySubscriptionFilter!, $sort: [CompanySubscriptionSort!], $first: Int) {
    companySubscriptionsList(filter: $filter, sort: $sort, first: $first) {
      items {
        subscriptionPlan {
          name
          price
          anualSavings
          order
          description
          monthlyPrice
          annualPrice
          allianceLimit
        }
      }
    }
  }
`;
