import {
  CREATE_PHONE_MUTATION,
  DELETE_PHONE_MUTATION,
  UPDATE_PHONE_MUTATION,
  UPDATE_USER_INFORMATION_MUTATION,
  UPDATE_USER_MUTATION,
  USER_INFORMATION_QUERY,
} from './queries';
import Flux from '@cobuildlab/flux-state';
import { USER_INFORMATION_EVENT, USER_UPDATE_EVENT, WIZARD_ERROR } from './WizardStore';
import sessionStore, {
  APOLLO_CLIENT,
  NEW_SESSION_EVENT,
  SESSION_ERROR,
} from '../../shared/SessionStore';
import { updateUserPhonesValidator, updateUserValidator } from './users.validators';
import { error, log } from '@cobuildlab/pure-logger';
import { normalize8baseDocumentDeleteAndUpdate } from '@cobuildlab/8base-utils';
import { OnWizardError, OnUserInformation, OnUserUpdate } from './wizard-events';
import { OnSessionError } from '../../shared/session-events';

type User = {
  id: string,
  firstName: string,
  lastName: string,
};

type UserInformation = {
  id: string,
  country: string,
};

type PhoneType = {
  id: string,
  name: string,
};

type Phone = {
  id: string,
  phone: string,
  type: PhoneType,
};

/**
 * Updates The logged in User data.
 *
 * @param {User} user - The User of the system.
 * @returns {object} - The updated User.
 */
export const updateUser = async (user: User) => {
  const client = sessionStore.getState(APOLLO_CLIENT);

  if (!user.id) {
    const {
      user: { id },
    } = sessionStore.getState(NEW_SESSION_EVENT);
    user.id = id;
  }

  let userResult;
  try {
    userResult = await client.mutate({
      mutation: UPDATE_USER_MUTATION,
      variables: { user },
    });
  } catch (e) {
    error('updateUser', e);
    OnSessionError.dispatch(e);
    return Flux.dispatchEvent(SESSION_ERROR, e);
  }
  log('updateUser:userResult:', userResult);

  OnUserUpdate.dispatch(userResult.data.userUpdate);
  Flux.dispatchEvent(USER_UPDATE_EVENT, userResult.data.userUpdate);
  return { user: userResult.data.userUpdate };
};

/**
 * Updates a User.
 *
 * @param {User} user - The User of the system.
 * @param {UserInformation} userInformation - The User additional information.
 * @param {Array<Phone>} phones - The phones.
 * @param {Array<Phone>} originalPhones - The Original phones.
 * @returns {object} - The updated user.
 */
export const updateUserInformation = async (
  user: User,
  userInformation: UserInformation,
  phones: Array<Phone>,
  originalPhones,
) => {
  // Country
  if (userInformation.country !== null) {
    userInformation.country = { connect: { id: userInformation.country } };
  } else {
    delete userInformation.country;
  }

  const { user: oldUser } = sessionStore.getState(NEW_SESSION_EVENT);

  // Picture
  normalize8baseDocumentDeleteAndUpdate(user, 'avatar', oldUser);

  const data = { user, userInformation };

  try {
    updateUserValidator(data);
  } catch (err) {
    error('UpdateUserValidator', err);
    OnWizardError.dispatch(err);
    return Flux.dispatchEvent(WIZARD_ERROR, err);
  }

  try {
    updateUserPhonesValidator(phones);
  } catch (err) {
    error('updateUserInformation:UpdateUserPhonesValidator', err);
    OnWizardError.dispatch(err);
    return Flux.dispatchEvent(WIZARD_ERROR, err);
  }

  const client = sessionStore.getState(APOLLO_CLIENT);
  let userResult;

  try {
    userResult = await client.mutate({
      mutation: UPDATE_USER_INFORMATION_MUTATION,
      variables: data,
    });
  } catch (e) {
    error('updateUserInformation', e);
    OnWizardError.dispatch(e);
    return Flux.dispatchEvent(WIZARD_ERROR, e);
  }
  log('updateUserInformation:userResult:', userResult);

  let phonesData;
  try {
    phonesData = await updatePhones(user, phones, originalPhones);
  } catch (e) {
    error('updateUserInformation:updatePhones:', e, data);
    OnWizardError.dispatch(e);
    return Flux.dispatchEvent(WIZARD_ERROR, e);
  }
  log('updateUserInformation:updatePhones:', phonesData);

  OnUserUpdate.dispatch({
    user: userResult,
    phones: phonesData,
  });

  Flux.dispatchEvent(USER_UPDATE_EVENT, {
    user: userResult,
    phones: phonesData,
  });

  return { user: userResult, phones: phonesData };
};

/**
 * Fetches a User information by Id.
 *
 * @returns {Promise<void>} - The user information.
 */
export const fetchUserInformation = async (): void => {
  const client = sessionStore.getState(APOLLO_CLIENT);
  const user = sessionStore.getState(NEW_SESSION_EVENT).user;
  let response;
  try {
    response = await client.query({
      query: USER_INFORMATION_QUERY,
      variables: { userId: user.id },
      fetchPolicy: 'network-only',
    });
  } catch (e) {
    console.error('fetchUserInformation', e);
    OnWizardError.dispatch(e);
    return Flux.dispatchEvent(WIZARD_ERROR, e);
  }
  console.log('fetchUserInformation', response.data);
  OnUserInformation.dispatch(response.data);
  return Flux.dispatchEvent(USER_INFORMATION_EVENT, response.data);
};

/**
 * Create, Update, Delete phone.
 *
 * @param {User} user - The User of the system.
 * @param {Array<Phone>} phones - The phones.
 * @param {Array<Phone>} originalPhones - The Original phones.
 *
 * @returns {object} - The updated phones.
 */

export const updatePhones = async (user, phones, originalPhones): void => {
  log('updatePhones', phones, originalPhones);

  const client = sessionStore.getState(APOLLO_CLIENT);
  const toBeDeleted = [],
    toBeUpdated = [],
    toBeCreated = [];
  let phonesData = [];

  phones.forEach((data) => {
    if (data.id === null) toBeCreated.push(data);
    else {
      const originalPhone = originalPhones.find((original) => original.id === data.id);
      if (originalPhone.phone !== data.phone) toBeUpdated.push(data);
      else phonesData.push(data);
    }
  });

  originalPhones.forEach((data) => {
    const originalPhone = phones.find((original) => original.id === data.id);
    if (originalPhone === undefined) toBeDeleted.push(data);
  });

  toBeCreated.forEach(async (phone) => {
    let phoneResult, data;
    data = {
      phone: phone.phone,
      type: { connect: { id: phone.type.id } },
      user: { connect: { id: user.id } },
    };
    try {
      phoneResult = await client.mutate({ mutation: CREATE_PHONE_MUTATION, variables: { data } });
    } catch (e) {
      error('update Phone', e, data);
      return Flux.dispatchEvent(WIZARD_ERROR, e);
    }
    phonesData.push(phoneResult.data.phoneCreate);
  });

  toBeDeleted.forEach(async (phone) => {
    const data = { id: phone.id };
    try {
      await client.mutate({
        mutation: DELETE_PHONE_MUTATION,
        variables: { data },
      });
    } catch (e) {
      error('deletePhone', e, data);
      return Flux.dispatchEvent(WIZARD_ERROR, e);
    }
  });

  toBeUpdated.forEach(async (phone) => {
    let phoneResult, data;
    data = {
      id: phone.id,
      phone: phone.phone,
      type: { connect: { id: phone.type.id } },
    };
    try {
      phoneResult = await client.mutate({
        mutation: UPDATE_PHONE_MUTATION,
        variables: { data: data },
      });
    } catch (e) {
      error('updatePhone', e, data);
      return Flux.dispatchEvent(WIZARD_ERROR, e);
    }
    phonesData.push(phoneResult.data.phoneUpdate);
  });

  return phonesData;
};
