import createDecorator from 'final-form-calculate';

const spyFieldsList = createDecorator({
  field: 'fieldsList',
  isEqual: (prevList, currentList) => {
    if (prevList && currentList) {
      if (prevList.length !== currentList.length) {
        return false;
      }
      const isEqual = currentList.every((currentItem) => {
        return prevList.some((prevItem) => {
          return prevItem.id === currentItem.id;
        });
      });
      return isEqual;
    }
    return prevList === currentList;
  },
  updates: {
    sorts: (fieldsList, allValues) => {
      const { sorts } = allValues;
      const filteredSorts = sorts.filter((sort) =>
        fieldsList.some((field) => field.name === sort.key),
      );
      return filteredSorts;
    },
    groups: (fieldsList, allValues) => {
      const { groups } = allValues;
      const filteredSorts = groups.filter((group) =>
        fieldsList.some((field) => field.name === group.key),
      );
      return filteredSorts;
    },
  },
});

export { spyFieldsList };
