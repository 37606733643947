import { Grid, InputField } from '@8base/boost';
import React, { useCallback, useEffect, useState } from 'react';
import { useSubscription } from '@cobuildlab/react-simple-state';
import { ActionButton } from '../../../components/buttons/ActionButton';
import { updateSalesforceUpdateFields } from '../../salesforce/salesforce-actions';
import * as toast from '../../../components/toast/Toast';
import {
  OnSalesforceConnected,
  OnSalesforceConnectionFieldUpdate,
  OnSalesforceConnectionFieldUpdateError,
} from '../../salesforce/salesforce-events';

export const SalesforceFieldsForm = () => {
  const [salesforceEvent, setSalesforceConnection] = useState(OnSalesforceConnected.get());
  const [loading, setLoading] = useState(false);
  const [customField, setCustomField] = useState('');
  const [filterName, setFilterName] = useState('');

  useSubscription(OnSalesforceConnected, setSalesforceConnection);
  useSubscription(OnSalesforceConnectionFieldUpdate, () => {
    setLoading(false);
    toast.success('Fields updated successfully');
  });
  useSubscription(OnSalesforceConnectionFieldUpdateError, (error) => {
    setLoading(false);
    toast.error(error);
  });

  useEffect(() => {
    if (salesforceEvent) {
      setFilterName(salesforceEvent.salesforceConnection.filterName);
      setCustomField(salesforceEvent.salesforceConnection.customField);
    }
  }, [salesforceEvent]);

  const handleFieldsUpdate = useCallback(() => {
    setLoading(true);
    updateSalesforceUpdateFields(customField, filterName);
  }, [customField, filterName]);

  if (!salesforceEvent) return;

  const { salesforceConnection } = salesforceEvent;
  const { customField: originalCustomField, filterName: originalFilterName } = salesforceConnection;

  const hasChanged = customField !== originalCustomField || filterName !== originalFilterName;

  return (
    <Grid.Layout gap="md" columns="33% 33% 1fr" stretch>
      <Grid.Box>
        <InputField
          disabled={loading}
          label={'Custom Field Name'}
          placeholder={'Custom Field Name'}
          input={{
            value: customField,
            onChange: setCustomField,
          }}
        />
      </Grid.Box>
      <Grid.Box>
        <InputField
          disabled={loading}
          label={'Alliance Field Value'}
          placeholder={'Alliance Field Value'}
          input={{
            value: filterName,
            onChange: setFilterName,
          }}
        />
      </Grid.Box>
      <Grid.Box justifyContent="flex-end">
        {hasChanged ? (
          <ActionButton loading={loading} onClick={handleFieldsUpdate} text="Update" />
        ) : null}
      </Grid.Box>
    </Grid.Layout>
  );
};
