import {
  INITIATIVE_APPROVED,
  INITIATIVE_SUBMITTED_FOR_APPROVAL,
  INITIATIVE_COMPLETED,
  ALLIANCE_APPROVED,
} from '../../../shared/status';
import {
  isUserAdminOrSERInAlliance,
  isUserCreatorInAlliance,
} from '../../../shared/alliance-utils';
import { isUserPendingApprovalOfInitiative } from './initiative-utils';
import { isAllianceCompleted } from '@cobuildlab/collabtogrow-permissions';

/**
 * Checks if a User can Create A Initiative.
 *
 * @param {object} user - The User.
 * @param {object} alliance - The Alliance.
 * @returns {boolean} Boolean - Validate Options.
 */
export const canCreateInitiative = (user, alliance): boolean => {
  if (isAllianceCompleted(alliance)) return false;

  if (isUserAdminOrSERInAlliance(user, alliance)) return true;

  if (isUserCreatorInAlliance(user, alliance)) return true;

  return false;
};

/**
 * Checks if a User can Edit A Initiative.
 *
 * @param {object} user - The user.
 * @param {object} initiative - The Initiative.
 * @param {object} alliance - The Alliance.
 * @returns {boolean}  Boolean - Validate Options.
 */
export const canEditInitiative = (user, initiative, alliance): boolean => {
  if (!initiative || !initiative.status || !user || !alliance) return false;
  if (isAllianceCompleted(alliance)) return false;
  // Is Owner
  if (initiative.owner && initiative.owner.id === user.id) return true;
  if (isUserAdminOrSERInAlliance(user, alliance)) return true;
  if (initiative.status === INITIATIVE_APPROVED) return false;
  if (initiative.status === INITIATIVE_COMPLETED) return false;
  if (initiative.status === INITIATIVE_SUBMITTED_FOR_APPROVAL) return false;
  if (isUserCreatorInAlliance(user, alliance)) return true;
  return false;
};

/**
 * Checks if a User can Delete A Initiative.
 *
 * @param {object} user - The user.
 * @param {object} initiative - The Initiative.
 * @param {object} alliance - The Alliance.
 * @returns {boolean} Boolean - Validate Options.
 */
export const canDeleteInitiative = (user, initiative, alliance) => {
  const errors = [];
  if (!initiative || !initiative.status || !user || !alliance)
    errors.push('Invalid state for deletion');

  if (isAllianceCompleted(alliance))
    errors.push('Cannot delete an initiative if the alliance is completed');

  if (initiative.status === INITIATIVE_APPROVED)
    errors.push('Cannot delete if the initiative is approved. Please mark as completed first');

  if (!isUserAdminOrSERInAlliance(user, alliance))
    errors.push('You must be Admin or SER in the alliance to delete');

  return [!errors.length, errors];
};

/**
 * Checks if a User can Approve an Initiative.
 *
 * @param {object} user - The user.
 * @param {object} initiative - The Initiative.
 * @param {object} alliance - The Alliance.
 * @returns {boolean} Boolean - Validate Options.
 */
export const canApproveInitiative = (user, initiative, alliance) => {
  if (!initiative || !initiative.status || !user || !alliance) return false;

  if (isAllianceCompleted(alliance)) return false;

  if (initiative.status !== INITIATIVE_SUBMITTED_FOR_APPROVAL) return false;

  if (!isUserAdminOrSERInAlliance(user, alliance)) return false;

  return isUserPendingApprovalOfInitiative(user, initiative, alliance);
};

/**
 * Checks if a User can Reject an Initiative.
 *
 * @param {object} user - The user.
 * @param {object} initiative - The Initiative.
 * @param {object} alliance - The Alliance.
 * @returns {boolean} Boolean - Validate Options.
 */
export const canRejectInitiative = (user, initiative, alliance) => {
  if (!initiative || !initiative.status || !user || !alliance) return false;

  if (isAllianceCompleted(alliance)) return false;

  if (initiative.status !== INITIATIVE_SUBMITTED_FOR_APPROVAL) return false;

  if (!isUserAdminOrSERInAlliance(user, alliance)) return false;

  return isUserPendingApprovalOfInitiative(user, initiative, alliance);
};

/**
 * Checks if a User can Submit For approval a Initiative.
 *
 * @param {object} user - The user.
 * @param {object} initiative - The Initiative.
 * @param {object} alliance - The Alliance.
 * @returns {boolean} Boolean - Validate Options.
 */
export const canSubmitForApprovalInitiative = (user, initiative, alliance) => {
  const errors = [];
  if (!initiative || !initiative.status || !user || !alliance)
    errors.push('invalid state for deletion');

  if (isAllianceCompleted(alliance))
    errors.push('Cannot submit for approval an initiative if the alliance is completed');

  if (alliance.status !== ALLIANCE_APPROVED)
    errors.push('You cannot submit for approval if the alliance is not approved');
  if (initiative.status === INITIATIVE_COMPLETED)
    errors.push('You cannot submit for approval if the initiative is already completed');
  if (initiative.status === INITIATIVE_SUBMITTED_FOR_APPROVAL)
    errors.push('You cannot submit for approval if the initiative is already submitted');
  if (initiative.status === INITIATIVE_APPROVED)
    errors.push('You cannot submit for approval if the initiative is already approved');

  if (!isUserAdminOrSERInAlliance(user, alliance))
    errors.push('You must be Admin or SER in alliance to submit for approval an initiative');

  return [!errors.length, errors];
};

/**
 * Checks if a User can Close a Initiative.
 *
 * @param {object} user - The user.
 * @param {object} initiative - The Initiative.
 * @param {object} alliance - The Alliance.
 * @returns {boolean} Boolean - Validate Options.
 */
export const canCompletedInitiative = (user, initiative, alliance) => {
  if (!initiative || !initiative.status || initiative.status === INITIATIVE_COMPLETED) return false;
  if (isAllianceCompleted(alliance)) return false;

  return isUserAdminOrSERInAlliance(user, alliance);
};

/**
 * Checks if a User can Restore a Initiative.
 *
 * @param {object} user - The user.
 * @param {object} initiative - The Initiative.
 * @param {object} alliance - The Alliance.
 * @returns {boolean} Boolean - Validate Options.
 */
export const canRestoreInitiative = (user, initiative, alliance) => {
  if (isAllianceCompleted(alliance)) return false;
  if (!initiative || !initiative.status || initiative.status !== INITIATIVE_COMPLETED) return false;
  return isUserAdminOrSERInAlliance(user, alliance);
};
