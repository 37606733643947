import React from 'react';
import { Text } from '@8base/boost';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

const StyledKPITitle = styled(Text)`
  color: ${({ blueText }) => (blueText ? '#3eb7f9' : null)};
`;

const KPITitle = ({ selected, text, blueText }) => {
  const weight = selected ? 'semibold' : 'normal';

  return (
    <StyledKPITitle blueText={blueText} weight={weight} align="left">
      {text}
    </StyledKPITitle>
  );
};

KPITitle.propTypes = {
  selected: PropTypes.bool.isRequired,
  blueText: PropTypes.bool.isRequired,
  text: PropTypes.string.isRequired,
};

export { KPITitle };
