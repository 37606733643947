import styled from '@emotion/styled';
import { SwitchField as BoostSwitch } from '@8base/boost';

const SwitchField = styled(BoostSwitch)`
  & label {
    height: 30px;
    & div:first-of-type {
      height: 26px;
      width: 55px;
      &:before {
        height: 22px;
        width: 22px;
      }
    }
    & div:last-of-type {
      margin-left: 10px;
      font-size: 12px;
    }
    & input:checked + div:before {
      left: 31px;
    }
  }
`;

export { SwitchField };
