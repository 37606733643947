import React from 'react';
import { Tag, Text } from '@8base/boost';
import PropTypes from 'prop-types';
import { getInvoiceColor } from '../procurer-utils';

/**
 * Invoice status tag, gets the color based on the status string.
 *
 * @param {object} props - The component props.
 * @param {object} props.invoice - The invoice to get the status.
 * @returns {object} The component.
 */
const InvoiceStatusTag = ({ invoice }) => {
  const { status } = invoice;
  const color = getInvoiceColor(status);

  return (
    <Tag color={color}>
      <Text color={color} weight="semibold">
        {status}
      </Text>
    </Tag>
  );
};

InvoiceStatusTag.propTypes = {
  invoice: PropTypes.object.isRequired,
};

export { InvoiceStatusTag };
