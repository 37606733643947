import React from 'react';
import styled from '@emotion/styled';

const Rectangle = styled.div`
  box-sizing: border-box;
  height: 16px;
  line-height: 16px;
  width: 107px;
  border: 1px solid #008aab;
  border-radius: 5px;
  background-color: rgba(0, 138, 171, 0.1);
`;

const Tag = styled.p`
  color: #008aab;
  font-family: Poppins;
  font-size: 10px;
  font-weight: 500;
  line-height: 16px;
  text-align: center;
`;

const CurrentPlan = (props) => {
  return (
    <Rectangle>
      <Tag>{'CURRENT PLAN'}</Tag>
    </Rectangle>
  );
};

export { CurrentPlan };
