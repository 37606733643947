import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const StyledHr = styled.hr`
  border: solid 1px #edf0f2;
  margin: 20px ${(props) => props.marginHorizontal}px;
`;

/**
 * Hr with marginHorizontal to ignore padding.
 *
 * @param {number} [marginHorizontal=0 }] - Negative to ignore parent's padding.
 */

const Hr = ({ marginHorizontal = 0 }) => {
  return <StyledHr marginHorizontal={marginHorizontal} />;
};

Hr.defaultProps = {
  marginHorizontal: 0,
};

Hr.propTypes = {
  marginHorizontal: PropTypes.number,
};

export default Hr;
