import { FUNNEL_COLORS, FUNNEL_OPTIONS, PIE_DATE_ITEM } from '../../dashboard/dashboard-models';
import * as R from 'ramda';
import {
  DEAL_STAGE_CLOSED_LOST,
  DEAL_STAGE_CLOSED_WON,
  DEAL_STAGES_MAP,
} from '../../management/deal/deal-model';

export const getDataItem = (
  value = 0,
  name = '',
  color = '#70D44B',
  height = null,
  type,
  itemType,
) => {
  const item = R.clone(PIE_DATE_ITEM);
  item.value = value;
  item.name = name;
  item.type = type;
  item.itemType = itemType;
  item.itemStyle.color = color;
  if (height !== null) item.itemStyle.height = height;
  return item;
};
/**
 * Extract information from the deals to the Funnel Options.
 *
 * @param {object} deals - Deals.
 * @param {object} company - Company.
 * @param {number} baseHeight - BaseHeight.
 * @returns {Array} - Funnel options.
 */
export const getFunnelOptions = (deals, company, baseHeight = 290) => {
  const dataObj = {};
  const companiesData = {};
  let selectedCompanyMoney = 0;
  const selectedCompanyDeals = [];

  for (let i = 0, j = deals.length; i < j; i++) {
    const deal = deals[i];
    // List of available Companies
    companiesData[deal.company.id] = deal.company.name;
    // Only Deals from the selected company
    if (company.value !== null && deal.company.id !== company.value) continue;

    const stageName = deal.stage || 'N/A';
    let stage = dataObj[stageName];
    if (stage === null || stage === undefined)
      dataObj[stageName] = { dealsCounter: 0, moneyCounter: 0 };
    dataObj[stageName].dealsCounter = dataObj[stageName].dealsCounter + 1;

    // Money accumulators
    if (!deal.amount) continue;
    const amount = parseInt(deal.amount);
    if (isNaN(amount)) continue;
    selectedCompanyMoney = selectedCompanyMoney + amount;
    dataObj[stageName].moneyCounter = dataObj[stageName].moneyCounter + amount;

    selectedCompanyDeals.push({ amount, customer: deal.customerName, id: deal.id });
  }

  // Companies
  const companies = Object.keys(companiesData).map((id) => {
    return { value: id, label: companiesData[id] };
  });
  if (companies.length > 1) companies.push({ value: null, label: 'Combined Funnel' });

  // Funnel Data
  const labels = Object.keys(dataObj);
  labels.sort((a, b) => {
    const stageA = DEAL_STAGES_MAP[a] ? DEAL_STAGES_MAP[a] : 0;
    const stageB = DEAL_STAGES_MAP[b] ? DEAL_STAGES_MAP[b] : 0;
    return stageB - stageA;
  });
  const data = [];
  let dealsLost = 0;
  let dealsWon = 0;
  let moneyLost = 0;

  for (let i = 0, j = labels.length; i < j; i++) {
    const label = labels[i];
    const color = FUNNEL_COLORS[i % FUNNEL_COLORS.length];
    const { dealsCounter, moneyCounter } = dataObj[label];
    if (label === DEAL_STAGE_CLOSED_WON) dealsWon = dealsCounter;
    if (label === DEAL_STAGE_CLOSED_LOST) {
      dealsLost = dealsCounter;
      moneyLost = moneyCounter;
    } else {
      const itemHeight =
        (baseHeight * parseInt((moneyCounter * 100) / selectedCompanyMoney)) / 100 + 10;
      data.push(getDataItem(10 + 10 * i, `${label} (${dealsCounter})`, color, itemHeight, label));
    }
  }
  const options = R.clone(FUNNEL_OPTIONS);
  options.legend.data = labels;
  options.series[0].data = data;

  // Top 5 Deals
  selectedCompanyDeals.sort((a, b) => b.amount - a.amount);

  return [
    options,
    company,
    companies,
    selectedCompanyDeals.slice(0, 5),
    { dealsLost, moneyLost },
    { dealsWon },
  ];
};
