import { ValidationError } from '../../../shared/errors';
import { isValidString } from '../../../shared/validators';
import { businessCaseValidator } from '../../document-management/business-case/business-case-validators';
import { ALLIANCE_MEMBER_INVITATION_PENDING } from '../../../shared/status';

/**
 * Validate alliance fields.
 *
 * @param { object } alliance - The Alliance to be reviewed.
 */
export const createAllianceValidator = (alliance) => {
  let errorMessages = [];

  if (!isValidString(alliance.name)) errorMessages.push('The Alliance must have a valid Name');

  if (!isValidString(alliance.description, true))
    errorMessages.push('The Alliance must have a valid Description');

  if (!alliance.currency) errorMessages.push('The Alliance must have a valid Currency');

  if (errorMessages.length !== 0) throw new ValidationError(errorMessages);
};

/**
 * Validate that the alliance has all the valid fields (Uses createAllianceValidator && businessCaseValidator)
 * plus validates it has a partnerCompany.
 *
 * @param {object} alliance - The Alliance to be reviewed.
 * @param {object} businessCase - BussinesCase.
 */
export const requestApprovalAllianceValidator = (alliance, businessCase) => {
  // createAllianceValidator(alliance);
  businessCaseValidator(businessCase);

  let errorMessages = [];

  if (!alliance.clientCompany) errorMessages.push('The Alliance must have a valid Client Company');

  if (!alliance.partnerCompany)
    errorMessages.push('The Alliance must have a valid Partner Company');

  if (errorMessages.length !== 0) throw new ValidationError(errorMessages);
};

/**
 * Validate that the Alliance has all the information necesarry to create it.
 *
 * @param {object} alliance - The Alliance to be reviewed.
 */
export const createAllianceWizardValidator = (alliance) => {
  let errorMessages = [];
  if (!isValidString(alliance.name)) errorMessages.push('The Alliance must have a valid Name');

  if (!isValidString(alliance.description))
    errorMessages.push('The Alliance must have a valid Description');

  if (!isValidString(alliance.currency))
    errorMessages.push('The Alliance must have a valid Currency');

  if (errorMessages.length !== 0) throw new ValidationError(errorMessages);
};

export const createAllianceMemberValidator = (email, role) => {
  if (!isValidString(email)) {
    throw new Error("The Email can't be empty");
  }

  if (!isValidString(role)) {
    throw new Error("The role can't be empty");
  }
};

export const createAllianceMemberInvitationValidator = (email, role, allianceId) => {
  if (!isValidString(email)) {
    throw new Error("The Email can't be empty");
  }

  if (!isValidString(role)) {
    throw new Error("The role can't be empty");
  }

  if (!isValidString(allianceId)) {
    throw new Error("The Alliance can't be empty");
  }
};

export const validateAllianceMemberInvitation = (allianceMemberInvitations) => {
  const hasPendingInvitations = allianceMemberInvitations.items.some(
    (invitation) => invitation.status === ALLIANCE_MEMBER_INVITATION_PENDING,
  );
  if (hasPendingInvitations) {
    throw new Error('The invitation has already been sent');
  }
};
