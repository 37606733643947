import * as utils from '../../shared/validators';
import { ValidationError } from '../../shared/errors';

/**
 * Validator for update user wizard.
 *
 * @param {object} data - Fields from the user.
 */
export const updateUserValidator = (data) => {
  const { user, userInformation } = data;
  let errorMessages = [];

  if (!utils.isValidString(user.firstName)) {
    errorMessages.push("Your First Name can't be empty");
    // throw new Error("Your First Name can't be empty");
  }

  if (!utils.isValidString(user.lastName)) {
    errorMessages.push("Your Last Name can't be empty");
    // throw new Error("Your Last Name can't be empty");
  }

  if (!utils.isValidString(userInformation.city, true, true)) {
    errorMessages.push("Your City can't be empty");
    // throw new Error("Your City can't be empty");
  }

  if (!utils.isValidString(userInformation.state, true, true)) {
    errorMessages.push("Your State can't be empty");
    // throw new Error("Your State can't be empty");
  }

  if (!utils.isValidString(userInformation.zipCode, true, true)) {
    errorMessages.push("Your Zipcode can't be empty");
    // throw new Error("Your Zipcode can't be empty");
  }

  if (!utils.isValidString(userInformation.title)) {
    errorMessages.push("Your Title can't be empty");
    // throw new Error("Your Title can't be empty");
  }
  if (errorMessages.length !== 0) throw new ValidationError(errorMessages);
};

/**
 * Validator for update user phones.
 *
 * @param {Array} phones - Phones.
 */
export const updateUserPhonesValidator = (phones) => {
  let errorMessages = [];

  if (!Array.isArray(phones) || !phones.length) {
    errorMessages.push('You must enter at least one valid Phone');
    // throw new Error('You must enter atleast one valid Phone');
  }

  phones.forEach((phone) => {
    if (!utils.isValidString(phone.phone)) {
      errorMessages.push('This is not a valid phone');
      // throw new Error('This is not a valid phone');
    }
    if (!phone.type || !utils.isValidString(phone.type.id)) {
      errorMessages.push('You must select a phone type');
      // throw new Error('You must select a phone type');
    }
  });
  if (errorMessages.length !== 0) throw new ValidationError(errorMessages);
};

/**
 * Validator for the create user invitations.
 *
 * @param {object} data - Fields for the invitation.
 */
export const createUserInvitationsValidator = (data) => {
  let errorMessages = [];

  data.forEach((invitation) => {
    if (!utils.isValidEmail(invitation.email)) {
      errorMessages.push('Please provide valid emails for the invitations');
    }

    if (!utils.isValidString(invitation.role)) {
      errorMessages.push('Please select a valid role');
    }
  });
  if (errorMessages.length !== 0) throw new ValidationError(errorMessages);
};
