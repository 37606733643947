import { getCompanyOnAlliance } from '../../../shared/alliance-utils';
import { FUNDING_REQUEST_APPROVAL_PENDING } from '../../../shared/status';

/**
 * Return true if the User's company's approval or rejection
 * of the Funding Request is pending.
 *
 * @param {object} user - The user.
 * @param {object} alliance - The Alliance.
 * @param {object} fundingRequest - The Funding Request.
 * @returns {boolean} - IsUserPendingFundingRequestApproval.
 */
export const isUserPendingFundingRequestApproval = (user, alliance, fundingRequest) => {
  const { fundingRequestApprovalRelation } = fundingRequest;
  const userCompany = getCompanyOnAlliance(user, alliance);

  const userCompanyIsPendingResponse = fundingRequestApprovalRelation.items.find(
    (approval) =>
      userCompany.id === approval.company.id &&
      approval.status === FUNDING_REQUEST_APPROVAL_PENDING,
  );

  return !!userCompanyIsPendingResponse;
};
