import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { DndProvider } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import { withTablesList } from '@8base-react/table-schema-provider';

import { CardsList } from '../components';

const TableFieldsSelectViewComponent = (props) => {
  const {
    label,
    input,
    itemType,
    fieldsOptions,
    fieldsDict,
    meta: { error, touched },
  } = props;
  const { onChange: setCards, value: cards } = input;

  return (
    <Container>
      {label && <Label>{label}</Label>}
      {itemType ? (
        <FieldsList error={touched && error}>
          <DndProvider backend={HTML5Backend}>
            <CardsList
              fieldsOptions={fieldsOptions}
              fieldsDict={fieldsDict}
              setCards={setCards}
              cards={cards}
            />
          </DndProvider>
        </FieldsList>
      ) : (
        <EmptyFieldTypeText error={touched && error}>
          {'In order to see field options you will first need to select an item type.'}
        </EmptyFieldTypeText>
      )}
      {touched && error && (
        <ErrorContainer>
          <ErrorText>{error}</ErrorText>
        </ErrorContainer>
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  position: relative;
`;
const Label = styled.div`
  font-size: 1.2rem;
  line-height: 1.7rem;
  color: #878c93;
  margin-left: 0;
  margin-bottom: 4px;
`;
const FieldsList = styled.div`
  padding: 23px 16px 0 16px;
  display: flex;
  border: 1px dashed #d0d7dd;
  ${({ error }) =>
    error &&
    css`
      border: 1px dashed #ff6d4a;
    `}
  border-radius: 5px;
  width: 100%;
  flex-wrap: wrap;
`;
const EmptyFieldTypeText = styled.div`
  height: 84px;
  padding: 4px 16px 5px 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px dashed #d0d7dd;
  ${({ error }) =>
    error &&
    css`
      border: 1px dashed #ff6d4a;
    `}
  border-radius: 5px;
  width: 100%;
  flex-wrap: wrap;
  color: #a5afb6;
  font-size: 13px;
  line-height: 20px;
`;

const ErrorContainer = styled.div`
  display: block;
  position: absolute;
  bottom: 0;
  height: 0;
  max-width: 100%;
`;
const ErrorText = styled.span`
  position: relative;
  font-size: 1rem;
  line-height: 1.5rem;
  color: #ff6d4a;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
  max-width: 100%;
`;

const TableFieldsSelectView = withTablesList(TableFieldsSelectViewComponent);

TableFieldsSelectViewComponent.propTypes = {
  input: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  fieldsOptions: PropTypes.array.isRequired,
  fieldsDict: PropTypes.object.isRequired,
  itemType: PropTypes.string.isRequired,
  meta: PropTypes.object.isRequired,
};

export { TableFieldsSelectView };
