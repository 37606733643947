import moment from 'moment';

// targetDistributionTypes
export const PERIODICITY_TYPE = 'PERIODICITY';
export const MANUAL_TYPE = 'MANUAL';
export const TARGET_DISTRIBUTION_LABELS = [
  { value: PERIODICITY_TYPE, label: 'Periodicity' },
  { value: MANUAL_TYPE, label: 'Manual' },
];

// Types
export const BOOKINGS_CLIENT_TYPE = 'Bookings - Client';
export const BOOKINGS_PARTNER_TYPE = 'Bookings - Partner';
export const CONTRIBUTIONS_CLIENT_TYPE = 'Contributions - Client';
export const CONTRIBUTIONS_PARTNER_TYPE = 'Contributions - Partner';
export const PIPELINE_CLIENT_TYPE = 'Pipeline - Client';
export const PIPELINE_PARTNER_TYPE = 'Pipeline - Partner';
export const JOINT_PROPOSALS_ISSUED_TYPE = 'Joint Proposals Issued';
export const JOINT_PROPOSALS_WON_TYPE = 'Joint Proposals Won';
export const JOINT_REFERENCEABLE_CLIENTS_TYPE = 'Joint Referenceable Clients';
export const JOINT_TRAINING_INVESTMENT_TYPE = 'Joint Training Investment';
export const JOINT_INITIATIVES_TYPE = 'Joint Initiatives';
export const JOINT_INNOVATION_IDEAS_TYPE = 'Joint Innovation Ideas';
export const JOINT_SALES_CALLS_TYPE = 'Joint Sales Calls';
export const CUSTOMER_SATISFACTION_SCORE_TYPE = 'Customer Satisfaction Score';
export const OPPORTUNITY_WIN_RATIO_TYPE = 'Opportunity Win Ratio';
export const PROFESSIONALS_TRAINED_TYPE = 'Professionals Trained';
export const PROFESSIONALS_CERTIFIED_TYPE = 'Professionals Certified';

export const KPI_TYPES = [
  BOOKINGS_CLIENT_TYPE,
  BOOKINGS_PARTNER_TYPE,
  CONTRIBUTIONS_CLIENT_TYPE,
  CONTRIBUTIONS_PARTNER_TYPE,
  PIPELINE_CLIENT_TYPE,
  PIPELINE_PARTNER_TYPE,
  JOINT_PROPOSALS_ISSUED_TYPE,
  JOINT_PROPOSALS_WON_TYPE,
  JOINT_REFERENCEABLE_CLIENTS_TYPE,
  JOINT_TRAINING_INVESTMENT_TYPE,
  JOINT_INITIATIVES_TYPE,
  JOINT_INNOVATION_IDEAS_TYPE,
  JOINT_SALES_CALLS_TYPE,
  OPPORTUNITY_WIN_RATIO_TYPE,
  PROFESSIONALS_TRAINED_TYPE,
  PROFESSIONALS_CERTIFIED_TYPE,
  CUSTOMER_SATISFACTION_SCORE_TYPE,
];
export const KPI_WITH_CURRENCY_TYPES = [
  BOOKINGS_CLIENT_TYPE,
  BOOKINGS_PARTNER_TYPE,
  CONTRIBUTIONS_CLIENT_TYPE,
  CONTRIBUTIONS_PARTNER_TYPE,
  PIPELINE_CLIENT_TYPE,
  PIPELINE_PARTNER_TYPE,
  JOINT_TRAINING_INVESTMENT_TYPE,
];
export const KPI_WITH_PERCENTAGE_TYPES = [OPPORTUNITY_WIN_RATIO_TYPE];
export const KPI_WITHOUT_CURRENCY_TYPES = [
  JOINT_PROPOSALS_ISSUED_TYPE,
  JOINT_PROPOSALS_WON_TYPE,
  JOINT_REFERENCEABLE_CLIENTS_TYPE,
  JOINT_INITIATIVES_TYPE,
  JOINT_INNOVATION_IDEAS_TYPE,
  JOINT_SALES_CALLS_TYPE,
  OPPORTUNITY_WIN_RATIO_TYPE,
  PROFESSIONALS_TRAINED_TYPE,
  PROFESSIONALS_CERTIFIED_TYPE,
  CUSTOMER_SATISFACTION_SCORE_TYPE,
];
export const ALLOWED_DECIMALS_BY_KPI = {
  [BOOKINGS_CLIENT_TYPE]: 0,
  [BOOKINGS_PARTNER_TYPE]: 0,
  [CONTRIBUTIONS_CLIENT_TYPE]: 0,
  [CONTRIBUTIONS_PARTNER_TYPE]: 0,
  [PIPELINE_CLIENT_TYPE]: 0,
  [PIPELINE_PARTNER_TYPE]: 0,
  [JOINT_PROPOSALS_ISSUED_TYPE]: 0,
  [JOINT_PROPOSALS_WON_TYPE]: 0,
  [JOINT_REFERENCEABLE_CLIENTS_TYPE]: 0,
  [JOINT_TRAINING_INVESTMENT_TYPE]: 0,
  [JOINT_INITIATIVES_TYPE]: 0,
  [JOINT_INNOVATION_IDEAS_TYPE]: 0,
  [JOINT_SALES_CALLS_TYPE]: 0,
  [OPPORTUNITY_WIN_RATIO_TYPE]: 1,
  [PROFESSIONALS_TRAINED_TYPE]: 0,
  [PROFESSIONALS_CERTIFIED_TYPE]: 0,
  [CUSTOMER_SATISFACTION_SCORE_TYPE]: 0,
};

/**
 * Alliance KPIs model.
 *
 * @param {string} year - The year to set.
 * @param {string }type - Type.
 * @returns {object} - Kpi model.
 */
export const allianceKPIsModel = (year, type) => {
  const _year = year || moment().year();

  return {
    type: type,
    year: _year,
    monthByMonth: Array.from({ length: 12 }, (e) => '0'),
    targetDistributionType: PERIODICITY_TYPE,
    target: '0',
  };
};

/**
 * Alliance KPIs year model.
 *
 * @param  {string} year - The year to set.
 * @returns {object} An array with all KPI types with the selected year.
 */
export const allianceKPIsYearModel = (year) => {
  const allianceKPIs = KPI_TYPES.map((type) => allianceKPIsModel(year, type));

  return allianceKPIs;
};

export const MONTHS = [
  'JAN',
  'FEB',
  'MAR',
  'APR',
  'MAY',
  'JUN',
  'JUL',
  'AUG',
  'SEP',
  'OCT',
  'NOV',
  'DEC',
];
