import React from 'react';
import { Button } from '@8base/boost';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledButtomTopTransparent = styled(Button)`
  color: #878c93;
  font-weight: normal;
  border-color: transparent !important;
`;

const StyledImg = styled.img`
  margin: 0 !important;
`;

const StyledSpan = styled.span`
  color: #323c47;
  font-family: Poppins;
  font-size: 13px;
  line-height: 20px;
  font-weight: normal;
`;

const TransparentButtonSvg = ({ iconSvg, onClick, text, ...rest }) => {
  const hasSpace = text && iconSvg ? <>&nbsp;&nbsp;</> : null;

  return (
    <StyledButtomTopTransparent
      className="invite-button"
      onClick={onClick}
      color={'neutral'}
      variant={'outlined'}
      {...rest}>
      <StyledImg src={iconSvg} alt="Collaborate" height="24" width="24" />
      {hasSpace}
      <StyledSpan>{text}</StyledSpan>
    </StyledButtomTopTransparent>
  );
};

TransparentButtonSvg.propTypes = {
  iconSvg: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  text: PropTypes.string,
};

TransparentButtonSvg.defaultProps = {
  iconSvg: '',
  text: '',
};

export { TransparentButtonSvg };
