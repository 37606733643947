import React from 'react';
import * as toast from '../../../components/toast/Toast';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { View } from '@cobuildlab/react-simple-state';
import { Card, Grid, Heading, Loader } from '@8base/boost';
import CompanyForm from './components/CompanyForm';
import {
  fetchCompanyFormDataAction,
  editCompanyAction,
  fetchCompanyAction,
} from './company-actions';
import CompanyModel from './company.model';
import { onErrorMixin } from '../../../shared/mixins';
import { ActionButton } from '../../../components/buttons/ActionButton';
import { fetchSession } from '../../auth/auth.actions';
import { CardFooter } from '../../../components/new-ui/card/CardFooter';
import { FormSteps } from '../../../components/dots/FormSteps';
import { ActionButtonClose } from '../../../components/buttons/ActionButtonClose';
import { LeftProgressSection } from '../../../components/new-ui/LeftProgressSection';
import { SCREENS_COMPANY } from '../../management/screenView';
import { BoxCard } from '../../../components/new-ui/div/BoxCard';
import { TransparentButton } from '../../../components/buttons/TransparentButton';
import CompanyDetailTable from './components/CompanyDetailTable';
import { getCurrencyOnSession } from '../../../shared/alliance-utils';
import sessionStore, { NEW_SESSION_EVENT } from '../../../shared/SessionStore';
import { createCompanyValidator } from './company.validators';
import { CreateViewCardBody } from '../../../components/new-ui/card/CreateViewCardBody';
import {
  OnCompanyFormData,
  OnCompanyError,
  OnCompanyDetail,
  OnCompanyEdit,
} from './company-events';

class CompanyEditView extends View {
  constructor(props) {
    super(props);

    this.state = {
      data: R.clone(CompanyModel),
      countries: [],
      usaStates: [],
      industries: [],
      loading: true,
      step: 0,
    };

    this.onError = onErrorMixin.bind(this);
  }

  componentDidMount() {
    this.subscribe(OnCompanyError, this.onError);

    this.subscribe(OnCompanyFormData, (state) => {
      const countries = state.countriesList.items.map((country) => ({
        ...country,
      }));

      const usaStates = state.statesList.items.map((usaState) => ({
        ...usaState,
      }));

      const industries = state.industriesList.items.map((industry) => ({
        ...industry,
      }));

      this.setState(
        {
          countries,
          usaStates,
          industries,
        },
        () => {
          const { match } = this.props;
          if (!match.params.id) return toast.error('Company ID missing');
          fetchCompanyAction(match.params.id);
        },
      );
    });

    this.subscribe(OnCompanyDetail, (state) => {
      const company = R.clone(state.company);
      if (company.country) company.country = company.country.id;
      if (company.industry) company.industry = company.industry.id;

      this.setState({
        data: company,
        loading: false,
      });
    });

    this.subscribe(OnCompanyEdit, async () => {
      fetchSession();
      toast.success('Company Successfully Updated');
      this.props.history.push(`/settings/company-management/${this.props.match.params.id}`);
    });

    fetchCompanyFormDataAction();
  }

  /**
   * Submit method for the edit company form.
   */
  onSubmit = () => {
    this.setState({ loading: true }, () => {
      const data = R.clone(this.state.data);
      editCompanyAction(data);
    });
  };

  onChange = (name, value) => {
    const { data } = this.state;
    data[name] = value;
    this.setState({ data });
  };

  onCompanyStepChange = (step) => {
    const { selectedAlliance } = sessionStore.getState(NEW_SESSION_EVENT);
    const data = R.clone(this.state.data);
    try {
      createCompanyValidator(data, selectedAlliance);
    } catch (e) {
      return this.onError(e);
    }
    this.onScreen(step);
  };

  onScreen = (step) => {
    this.setState({ step });
  };

  render() {
    let footer = <></>;
    let content = <Loader stretch />;
    const { data, countries, usaStates, industries, users, step } = this.state;
    const currency = getCurrencyOnSession();

    if (!this.state.loading && step === 0) {
      content = (
        <div id={'c2gScreen'}>
          <CompanyForm
            data={data}
            onChange={this.onChange}
            countries={countries}
            usaStates={usaStates}
            industries={industries}
          />
        </div>
      );
      footer = (
        <CardFooter>
          <ActionButton onClick={() => this.onCompanyStepChange(1)} text={'Next'} />
        </CardFooter>
      );
    }

    if (!this.state.loading && step === 1) {
      content = (
        <div id={'c2gScreen'}>
          <CompanyDetailTable currency={currency} data={data} users={users} isDisabled />
        </div>
      );

      footer = (
        <CardFooter>
          <ActionButton onClick={this.onSubmit} text="Update Company" />
          <TransparentButton onClick={() => this.onScreen(0)} text={'Previous'} />
        </CardFooter>
      );
    }

    return (
      <React.Fragment>
        <Card.Header>
          <Grid.Layout
            columns="200px auto 200px"
            areas={[['left', 'center', 'right']]}
            style={{ width: '100%' }}>
            <Grid.Box area="left">
              <Heading type="h4" text="Update Company" />
            </Grid.Box>
            <Grid.Box area="center">
              <FormSteps totalSteps={2} step={step} />
            </Grid.Box>
            <Grid.Box area="right" />
          </Grid.Layout>
          <ActionButtonClose onClick={this.props.history.goBack} />
        </Card.Header>
        <CreateViewCardBody>
          <Grid.Layout columns="30% 70%" areas={[['left', 'right']]} style={{ width: '100%' }}>
            <Grid.Box area="left">
              <LeftProgressSection sections={SCREENS_COMPANY} currentScreen={step} />
            </Grid.Box>
            <BoxCard>
              <Grid.Box area="right">{content}</Grid.Box>
            </BoxCard>
          </Grid.Layout>
        </CreateViewCardBody>
        {footer}
      </React.Fragment>
    );
  }
}

CompanyEditView.propTypes = {
  history: PropTypes.object.isRequired,
};

export default withRouter(CompanyEditView);
