import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

const NotificationCircle = styled('span')`
  position: absolute;
  top: 0px;
  right: -2px;
  height: 4px;
  width: 4px;
  background-color: #3eb7f9;
  border-radius: 50%;
`;

const RelativeContainer = styled('div')`
  cursor: pointer;
  position: relative;
  display: inline;
  width: 24px;
  height: 24px;
  margin-left: 16px;
  &:hover {
    opacity: 0.8;
  }
`;

const HeaderIcon = ({ url, hasNotifications = false, ...rest }) => {
  return (
    <RelativeContainer {...rest}>
      <img src={url} width={24} alt="icon" />
      {hasNotifications ? <NotificationCircle /> : null}
    </RelativeContainer>
  );
};

HeaderIcon.defaultProps = {
  hasNotifications: false,
};

HeaderIcon.propTypes = {
  url: PropTypes.string.isRequired,
  hasNotifications: PropTypes.bool,
};

export default HeaderIcon;
