import { Card } from '@8base/boost';
import styled from '@emotion/styled';

/**
 * For the the payment details fields.
 *
 * @type {object}
 */
const PaymentSection = styled(Card.Section)`
  padding: 12px 24px !important;
`;

export { PaymentSection };
