import React from 'react';
import { Card, Heading, Loader, Row } from '@8base/boost';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import * as toast from '../../../components/toast/Toast';
import * as R from 'ramda';
import { View } from '@cobuildlab/react-simple-state';
import { onErrorMixin } from '../../../shared/mixins';
import { FundingRequestModel } from './funding-request-model';
import {
  approveFundingRequest,
  completedFundingRequest,
  openComments,
  rejectFundingRequest,
  requestApprovalForFundingRequest,
  fetchFundingRequestDetail,
  restoreFundingRequest,
  fetchFundingRequestApprovalsList,
} from './funding-request-action';
import sessionStore, { NEW_SESSION_EVENT } from '../../../shared/SessionStore';
import {
  canApproveFundingRequest,
  canCompletedFundingRequest,
  canRejectFundingRequest,
  canRestoreFundingRequest,
} from './funding-request-permissions';
import withAlliance from '../../../components/hoc/withAlliance';
import FundingRequestDetailTable from './components/FundingRequestDetailTable';
import BusinessCaseModel from '../../document-management/business-case/BusinessCase.model';
import YesNoDialog from '../../../components/dialogs/YesNoDialog';
import BusinessCaseDetailTable from '../../document-management/business-case/components/BusinessCaseDetailTable';
import fundingRequestStore, { FUNDING_REQUEST_APPROVALS_LIST_EVENT } from './funding-request-store';
import { DangerButton } from '../../../components/buttons/DangerButton';
import {
  fetchRelatedItems,
  fetchRelatedItemsByItemId,
} from '../../related-item/related-item-actions';
import { DetailViewCardBody } from '../../../components/card/DetailViewCardBody';
import { ActionButton } from '../../../components/buttons/ActionButton';
import { getCurrencyOnSession } from '../../../shared/alliance-utils';
import ApprovalDetailTable from '../../../components/tables/approvalTable/ApprovalDetailTable';
import { RelatedItemsDetailTable } from '../../related-item/components/RelatedItemsDetailTable';
import { getItemByType } from '../../../shared/items-util';
import { TopButtons } from '../../../components/buttons/TopButtons';
import { RelatedItemsByItemDetailTable } from '../../related-item/components/RelatedItemsByItemDetailTable';
import { InitiativeListTable } from '../initiative/components/InitiativeListTable';
import { canSubmitForApprovalInvestmentItem } from '../investment-item/investment-item-permissions';
import { CardFooter } from '../../../components/new-ui/card/CardFooter';
import { NoteSubmitForApprovalText } from '../../../components/NoteSubmitForApprovalText';
import { INVESTMENT_ITEM_IN_PROGRESS } from '../../../shared/status';
import { OnRelatedItems, OnRelatedItemsByItem } from '../../related-item/related-item-events';
import {
  onFundingRequestDetail,
  onFundingRequestError,
  onFundingRequestSubmitForApproval,
  onFundingRequestReject,
  onFundingRequestCompleted,
  onFundingRequestRestore,
  onFundingRequestUpdate,
} from './funding-request-event';

/**
 * Funding Request Detail View.
 */

class FundingRequestDetailView extends View {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        fundingRequestData: R.clone(FundingRequestModel),
        businessCaseData: R.clone(BusinessCaseModel),
        relatedItems: [],
        initiatives: [],
        approvalData: [],
        relatedItemsByItem: [],
      },
      loading: true,
      approvalModalIsOpen: false,
      rejectModalIsOpen: false,
      completedModalIsOpen: false,
      submitForApprovalsOpen: false,
      restoreModalIsOpen: false,
      approvalDataList: [],
    };
    this.onError = onErrorMixin.bind(this);
    this.user = sessionStore.getState(NEW_SESSION_EVENT).user;
    this.selectedAlliance = sessionStore.getState(NEW_SESSION_EVENT).selectedAlliance;
  }

  componentDidMount() {
    const { match } = this.props;
    if (!match.params.id) return toast.error('Funding Request ID missing');
    this.subscribe(onFundingRequestError, this.onError);
    this.subscribe(onFundingRequestDetail, (state) => {
      const fundingRequestData = R.clone(state.fundingRequest);
      const businessCaseData = R.clone(fundingRequestData.businessCase);

      const { data } = this.state;

      data.initiatives = fundingRequestData.initiatives.items.map((initiative) => ({
        ...initiative,
      }));

      data.fundingRequestData = fundingRequestData;
      data.businessCaseData = businessCaseData;

      this.setState(
        { data },
        () => fetchRelatedItems(fundingRequestData.itemFundingRequestRelation.id),
        fetchRelatedItemsByItemId(fundingRequestData.itemFundingRequestRelation.id),
        fetchFundingRequestApprovalsList(match.params.id),
      );
    });
    this.subscribe(OnRelatedItems, (state) => {
      const items = state.item.itemsRelated.items.map((item) => getItemByType(item));
      const { data } = this.state;

      data.relatedItems = items;
      this.setState({ data, loading: false });
    });

    this.subscribe(onFundingRequestUpdate, () => {
      fetchFundingRequestDetail(match.params.id);
      toast.success('Funding Request Successfully Approved');
    });

    this.subscribe(onFundingRequestSubmitForApproval, (state) => {
      fetchFundingRequestDetail(match.params.id);
      toast.success('Funding Request Submitted For Approval!');
    });

    this.subscribe(onFundingRequestReject, () => {
      fetchFundingRequestDetail(match.params.id);
      toast.success('Funding Request Successfully Rejected');
    });

    this.subscribe(onFundingRequestCompleted, () => {
      fetchFundingRequestDetail(match.params.id);
      toast.success('Funding Request Successfully Completed');
    });

    this.subscribe(onFundingRequestRestore, () => {
      fetchFundingRequestDetail(match.params.id);
      toast.success('Funding Request Successfully Restored');
    });

    this.subscribe(OnRelatedItemsByItem, (state) => {
      const {
        itemsList: { items: itemsRelated },
      } = state;
      const relatedItemsByItem = itemsRelated.map((item) => getItemByType(item));
      const { data } = this.state;

      data.relatedItemsByItem = relatedItemsByItem;
      this.setState({ data });
    });

    this.subscribe(fundingRequestStore, FUNDING_REQUEST_APPROVALS_LIST_EVENT, (state) => {
      const { fundingRequestApprovalsList } = state;
      const { data } = this.state;
      const approvalData = fundingRequestApprovalsList.items.slice(0, 2);
      const approvalDataList = fundingRequestApprovalsList.items;
      const submitForApprovalAlliance = fundingRequestApprovalsList.items.slice(0, 2);
      data.approvalData = approvalData;
      this.setState({
        data,
        approvalDataList,
        submitForApprovalAlliance,
        loading: false,
      });
    });

    fetchFundingRequestDetail(match.params.id);
  }

  approve = () => {
    this.setState({
      approvalModalIsOpen: true,
    });
  };

  onYesApprove = () => {
    this.setState(
      {
        approvalModalIsOpen: false,
        loading: true,
      },
      () => {
        approveFundingRequest(R.clone(this.state.data.fundingRequestData));
      },
    );
  };

  onCloseApprove = () => {
    this.setState({
      approvalModalIsOpen: false,
    });
  };

  reject = () => {
    this.setState({
      rejectModalIsOpen: true,
    });
  };

  onYesReject = () => {
    this.setState(
      {
        rejectModalIsOpen: false,
        loading: true,
      },
      () => {
        rejectFundingRequest(R.clone(this.state.data.fundingRequestData));
      },
    );
  };

  onCloseReject = () => {
    this.setState({
      rejectModalIsOpen: false,
    });
  };

  completedModal = () => {
    this.setState({
      completedModalIsOpen: true,
    });
  };

  restoreModal = () => {
    this.setState({
      restoreModalIsOpen: true,
    });
  };

  onYesCompleted = () => {
    this.setState(
      {
        completedModalIsOpen: false,
        loading: true,
      },
      () => {
        const fundingRequestData = R.clone(this.state.data.fundingRequestData);
        completedFundingRequest(fundingRequestData);
      },
    );
  };

  onYesRestore = () => {
    this.setState(
      {
        restoreModalIsOpen: false,
        loading: true,
      },
      () => {
        const fundingRequestData = R.clone(this.state.data.fundingRequestData);
        restoreFundingRequest(fundingRequestData);
      },
    );
  };

  onCloseCompleted = () => {
    this.setState({
      completedModalIsOpen: false,
    });
  };

  onCloseRestore = () => {
    this.setState({
      restoreModalIsOpen: false,
    });
  };

  onSelectSubmitForApprovalModal = (fundingRequest) => {
    const [canSubmit, errors] = canSubmitForApprovalInvestmentItem(
      this.user,
      fundingRequest,
      this.selectedAlliance,
    );
    if (canSubmit) {
      this.setState({
        fundingRequest,
        submitForApprovalsOpen: true,
      });
    } else {
      toast.errors(errors);
    }
  };

  onCloseSubmitForApprovalModal = () => {
    this.setState({
      submitForApprovalsOpen: false,
    });
  };

  onYesSubmitForApprovalModal = () => {
    this.setState(
      {
        submitForApprovalsOpen: false,
        loading: true,
      },
      () => {
        const fundingRequest = R.clone(this.state.data.fundingRequestData);
        requestApprovalForFundingRequest({ fundingRequest });
      },
    );
  };

  render() {
    const {
      loading,
      approvalModalIsOpen,
      rejectModalIsOpen,
      completedModalIsOpen,
      submitForApprovalsOpen,
      data,
      restoreModalIsOpen,
      approvalDataList,
    } = this.state;
    const currency = getCurrencyOnSession();

    let content = <Loader stretch />;
    let buttonsBottom = '';
    let buttonsTop = '';
    const {
      fundingRequestData,
      businessCaseData,
      approvalData,
      relatedItemsByItem,
      initiatives,
      relatedItems,
    } = data;

    const alliance = this.selectedAlliance;

    if (!loading) {
      content = (
        <div id={'c2gScreen'}>
          <FundingRequestDetailTable
            data={fundingRequestData}
            currency={currency}
            onClickEdit={() =>
              this.props.history.push(`/management/funding-request/edit/${fundingRequestData.id}`)
            }
          />
          <BusinessCaseDetailTable
            hideExpectedCostAvoidance
            data={businessCaseData}
            currency={currency}
          />
          <ApprovalDetailTable data={approvalData} />
          <ApprovalDetailTable data={approvalDataList} title={'APPROVAL HISTORY'} />
          <InitiativeListTable initiatives={initiatives} />
          <RelatedItemsDetailTable relatedItems={relatedItems} />
          <RelatedItemsByItemDetailTable relatedItemsByItem={relatedItemsByItem} />
        </div>
      );

      buttonsTop = (
        <>
          <Heading type="h4" text={fundingRequestData.name} />
          <TopButtons
            onClickClosed={() => this.props.history.goBack()}
            onClickCollaborated={() => openComments(fundingRequestData)}
          />
        </>
      );

      buttonsBottom = (
        <Row justifyContent="end">
          {fundingRequestData.status === INVESTMENT_ITEM_IN_PROGRESS ? (
            <ActionButton
              text="Submit For Approval"
              fontAwesomeIcon="check"
              onClick={() => {
                this.onSelectSubmitForApprovalModal(fundingRequestData);
              }}
            />
          ) : null}
          <NoteSubmitForApprovalText item={fundingRequestData} />
          <DangerButton
            text={'Reject'}
            fontAwesomeIcon={'times'}
            onClick={this.reject}
            disabled={
              !canRejectFundingRequest(this.user, fundingRequestData, {
                id: this.props.allianceId,
              })
            }
          />
          <ActionButton
            fontAwesomeIcon={'check'}
            onClick={this.approve}
            text={'Approve'}
            disabled={
              !canApproveFundingRequest(this.user, fundingRequestData, {
                id: this.props.allianceId,
              })
            }
          />

          {canCompletedFundingRequest(this.user, fundingRequestData, alliance) ? (
            <ActionButton
              text="Mark Completed"
              fontAwesomeIcon="clipboard-list"
              onClick={() => {
                this.completedModal();
              }}
            />
          ) : null}

          {canRestoreFundingRequest(this.user, fundingRequestData, alliance) ? (
            <ActionButton
              text="Restore"
              fontAwesomeIcon="clipboard-list"
              onClick={() => {
                this.restoreModal();
              }}
            />
          ) : null}
        </Row>
      );
    }

    return (
      <React.Fragment>
        <Card.Header>{buttonsTop}</Card.Header>
        <DetailViewCardBody>{content}</DetailViewCardBody>
        <CardFooter>{buttonsBottom}</CardFooter>
        <YesNoDialog
          title={'Approve Funding Request'}
          onYes={this.onYesApprove}
          onClose={this.onCloseApprove}
          onNo={this.onCloseApprove}
          text={'Are you sure you want to Approve the Funding Request?'}
          isOpen={approvalModalIsOpen}
        />
        <YesNoDialog
          title={'Reject Funding Request'}
          onYes={this.onYesReject}
          onClose={this.onCloseReject}
          onNo={this.onCloseReject}
          text={'Are you sure you want to Reject the Funding Request?'}
          isOpen={rejectModalIsOpen}
        />

        <YesNoDialog
          title={'Complete Funding Request'}
          onYes={this.onYesCompleted}
          onClose={this.onCloseCompleted}
          onNo={this.onCloseCompleted}
          text={'Are you sure you want to Mark the Funding Request as Completed?'}
          isOpen={completedModalIsOpen}
        />

        <YesNoDialog
          title={'Restore Funding Request'}
          onYes={this.onYesRestore}
          onClose={this.onCloseRestore}
          onNo={this.onCloseRestore}
          text={'Are you sure you want to Mark the Funding Request as Completed?'}
          isOpen={restoreModalIsOpen}
        />

        <YesNoDialog
          title={'Submit For Approval'}
          onYes={this.onYesSubmitForApprovalModal}
          onClose={this.onCloseSubmitForApprovalModal}
          onNo={this.onCloseSubmitForApprovalModal}
          text={'Are you sure you want to Submit this Funding Request For Approval?'}
          isOpen={submitForApprovalsOpen}
        />
      </React.Fragment>
    );
  }
}

FundingRequestDetailView.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default withRouter(withAlliance(FundingRequestDetailView));
