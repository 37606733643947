import React from 'react';
import PropTypes from 'prop-types';
import { Card, Grid, Heading, Loader, Row } from '@8base/boost';
import { CreateViewCardBody } from '../../../components/new-ui/card/CreateViewCardBody';
import IdeaForm from './components/IdeaForm';
import {
  onChangeBusinessCaseDataMixin,
  onChangeDataMixin,
  onErrorMixin,
} from '../../../shared/mixins';
import * as R from 'ramda';
import IdeaModel, { IDEA_DOCUMENTS } from './idea-model';
import { withRouter } from 'react-router-dom';
import * as toast from '../../../components/toast/Toast';
import { View } from '@cobuildlab/react-simple-state';
import { autoSaveCreateIdea, createIdea } from './idea-actions';
import { fetchCurrentAllianceMembersAction } from '../../settings/alliance-management/alliance-actions';
import { fetchInitiativeList } from '../initiative/initiative-actions';
import BusinessCaseModel from '../../document-management/business-case/BusinessCase.model';
import sessionStore, { NEW_SESSION_EVENT } from '../../../shared/SessionStore';
import BusinessCaseForm from '../../document-management/business-case/components/BusinessCaseForm';
import IdeaDetailTable from './components/IdeaDetailTable';
import BusinessCaseDetailTable from '../../document-management/business-case/components/BusinessCaseDetailTable';
import { getCurrencyOnSession } from '../../../shared/alliance-utils';
import { ActionButton } from '../../../components/buttons/ActionButton';
import { FormSteps } from '../../../components/dots/FormSteps';
import { ActionButtonClose } from '../../../components/buttons/ActionButtonClose';
import { TransparentButton } from '../../../components/buttons/TransparentButton';
import { ideaValidator } from './idea-validators';
import { businessCaseValidator } from '../../document-management/business-case/business-case-validators';
import { saveFormToSessionStorage } from '../../../shared/utils';
import RelatedItemForm from '../../related-item/components/RelatedItemForm';
import { initiativesItemValidator } from '../initiative/initiative-validators';
import { RelatedItemsDetailTable } from '../../related-item/components/RelatedItemsDetailTable';
import { InitiativeListTable } from '../initiative/components/InitiativeListTable';
import { LeftProgressSection } from '../../../components/new-ui/LeftProgressSection';
import { SCREENS_IDEA } from '../screenView';
import { BoxCard } from '../../../components/new-ui/div/BoxCard';
import { CardFooter } from '../../../components/new-ui/card/CardFooter';
import { TextDateAgo } from '../../../components/text/TextDateAgo';
import {
  OnIdeaError,
  OnIdeaCreate,
  OnIdeaAutoSaveCreate,
  OnIdeaAutoSaveCreateError,
} from './idea-events';
import { OnAllianceMemberList } from '../../settings/alliance-management/alliance-events';
import { OnInitiativeList } from '../initiative/initiative-events';

const IDEA_DATA_STORE = 'ideaCreateView';

// eslint-disable-next-line
/**
 * Create Idea View.
 */
class IdeaCreateView extends View {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        ideaData: R.clone(IdeaModel),
        businessCaseData: R.clone(BusinessCaseModel),
        initiatives: [],
        relatedItems: [],
      },
      initiativesList: [],
      clientCompany: null,
      partnerCompany: null,
      step: 0,
      loading: true,
      userRole: true,
      savedAt: null,
      autoSaving: false,
    };
    this.onError = onErrorMixin.bind(this);
    this.onChangeData = onChangeDataMixin.bind(this);
    this.onChangeBusinessCaseData = (name, value) => {
      this.autoSave();
      onChangeBusinessCaseDataMixin.call(this, name, value);
    };

    this.autoSaveTimer = null;
    this.savedIdea = null;
  }

  onChangeIdeaData = (name, value) => {
    const { data } = this.state;
    data.ideaData[name] = value;
    if (name === 'originalDueDate') {
      data.ideaData.revisedDueDate = value;
    }
    this.autoSave();
    this.setState({ data });

    const model = R.clone(IdeaModel);
    saveFormToSessionStorage(IDEA_DATA_STORE, data.ideaData, model, ['documents']);
  };

  componentDidMount = () => {
    const ideaData = JSON.parse(sessionStorage.getItem(IDEA_DATA_STORE));

    this.subscribe(OnIdeaError, this.onError);

    this.subscribe(OnAllianceMemberList, (state) => {
      this.setState({
        clientCompany: R.clone(state.clientCompany),
        partnerCompany: R.clone(state.partnerCompany),
      });
    });

    this.subscribe(OnIdeaCreate, (state) => {
      sessionStorage.removeItem(IDEA_DATA_STORE);
      toast.success('Idea Successfully Created');
      this.props.history.goBack();
    });

    this.subscribe(OnInitiativeList, (state) => {
      this.setState({
        loading: false,
        initiativesList: state.initiativesList.items.map((item) => ({ ...item })),
      });
    });

    this.subscribe(OnIdeaAutoSaveCreate, (idea) => {
      sessionStorage.removeItem(IDEA_DATA_STORE);
      this.setState({ savedAt: new Date(), autoSaving: false });
      this.savedIdea = R.clone(idea);
    });

    this.subscribe(OnIdeaAutoSaveCreateError, () => {
      this.setState({ autoSaving: false });
    });

    // set ideaData from sessionStorage
    if (ideaData) {
      const { data } = this.state;
      data.ideaData = ideaData;
      this.setState({ data });
    }

    const fetchPolicyOptions = { isCacheFirst: true };
    fetchInitiativeList('', 1, 1000, null, null, fetchPolicyOptions);
    fetchCurrentAllianceMembersAction();
  };

  componentWillUnmount() {
    super.componentWillUnmount();
    clearTimeout(this.autoSaveTimer);
    localStorage.removeItem(IDEA_DOCUMENTS);
  }

  autoSave = () => {
    const waitTime = 10000; // Wait 10s
    clearTimeout(this.autoSaveTimer);
    this.autoSaveTimer = setTimeout(() => {
      this.setState({ autoSaving: true });
      const ideaData = R.clone(this.state.data.ideaData);
      const businessCaseData = R.clone(this.state.data.businessCaseData);
      const relatedItems = R.clone(this.state.data.relatedItems);
      const initiatives = R.clone(this.state.data.initiatives);
      autoSaveCreateIdea(ideaData, businessCaseData, relatedItems, initiatives, this.savedIdea);
    }, waitTime);
  };

  onSubmit = () => {
    this.setState({ loading: true }, () => {
      clearTimeout(this.autoSaveTimer);
      const ideaData = R.clone(this.state.data.ideaData);
      const businessCaseData = R.clone(this.state.data.businessCaseData);
      const relatedItems = R.clone(this.state.data.relatedItems);
      const initiatives = R.clone(this.state.data.initiatives);
      createIdea(ideaData, businessCaseData, relatedItems, initiatives, this.savedIdea);
    });
  };

  onIdeaStepChange = (nextStep) => {
    const { selectedAlliance } = sessionStore.getState(NEW_SESSION_EVENT);
    const ideaData = R.clone(this.state.data.ideaData);
    try {
      ideaValidator(ideaData, selectedAlliance);
    } catch (e) {
      return this.onError(e);
    }
    this.onScreen(nextStep);
  };

  onBusinessCaseStepChange = (nextStep) => {
    const businessCaseData = R.clone(this.state.data.businessCaseData);
    try {
      businessCaseValidator(businessCaseData);
    } catch (e) {
      return this.onError(e);
    }
    this.onScreen(nextStep);
  };

  onRelatedItemsStepChange = (step) => {
    const initiatives = R.clone(this.state.data.initiatives);
    try {
      initiativesItemValidator(initiatives);
    } catch (e) {
      return this.onError(e);
    }
    this.onScreen(step);
  };

  onScreen = (step) => {
    if (step === 3) clearTimeout(this.autoSaveTimer);
    this.setState({ step });
  };

  render() {
    const {
      initiativesList,
      step,
      loading,
      clientCompany,
      partnerCompany,
      data,
      savedAt,
      autoSaving,
    } = this.state;
    const { ideaData, businessCaseData, initiatives, relatedItems } = data;
    const { history } = this.props;
    const { user, selectedAlliance } = sessionStore.getState(NEW_SESSION_EVENT);

    const currency = getCurrencyOnSession();
    let content = <Loader stretch />;
    let footer = <></>;
    let topButtons = '';

    if (!loading && step === 0) {
      content = (
        <div id={'c2gScreen'}>
          <IdeaForm
            data={ideaData}
            onChange={this.onChangeIdeaData}
            clientCompany={clientCompany}
            partnerCompany={partnerCompany}
            currency={currency}
            user={user}
            selectedAlliance={selectedAlliance}
          />
        </div>
      );
      footer = (
        <CardFooter>
          <ActionButton onClick={() => this.onIdeaStepChange(1)} text={'Next'} />
        </CardFooter>
      );
    }

    if (!loading && step === 1) {
      content = (
        <div id={'c2gScreen'}>
          <BusinessCaseForm
            data={businessCaseData}
            onChange={this.onChangeBusinessCaseData}
            currency={currency}
            showBusinessFields
            hideExpectCostAvoidance
          />
        </div>
      );
      footer = (
        <CardFooter>
          <ActionButton onClick={() => this.onBusinessCaseStepChange(2)} text={'Next'} />
          <TransparentButton onClick={() => this.onScreen(0)} text={'Previous'} />
        </CardFooter>
      );
    }

    if (!loading && step === 2) {
      content = (
        <div id={'c2gScreen'}>
          <RelatedItemForm
            relatedItems={relatedItems}
            selectedInitiatives={initiatives}
            initiatives={initiativesList}
            onChange={(key, value) => {
              this.autoSave();
              this.onChangeData(key, value);
            }}
          />
        </div>
      );

      footer = (
        <CardFooter>
          <ActionButton onClick={() => this.onRelatedItemsStepChange(3)} text={'Next'} />
          <TransparentButton onClick={() => this.onScreen(1)} text={'Previous'} />
        </CardFooter>
      );
    }

    if (!loading && step === 3) {
      const ideaDetail = R.clone(ideaData);
      ideaDetail.initiatives = { items: initiatives };
      ideaDetail.documents = { items: ideaDetail.documents };
      ideaDetail.nextSteps = { items: ideaDetail.nextSteps };

      content = (
        <div id={'c2gScreen'}>
          <IdeaDetailTable data={ideaDetail} currency={currency} />
          <BusinessCaseDetailTable
            hideExpectedCostAvoidance
            data={businessCaseData}
            currency={currency}
          />
          <InitiativeListTable initiatives={initiatives} />
          <RelatedItemsDetailTable relatedItems={relatedItems} />
        </div>
      );
      footer = (
        <CardFooter>
          <ActionButton disabled={autoSaving} onClick={this.onSubmit} text={'Create Idea'} />
          <TransparentButton onClick={() => this.onScreen(2)} text={'Previous'} />
        </CardFooter>
      );
    }

    if (!loading) topButtons = <ActionButtonClose onClick={history.goBack} />;

    return (
      <React.Fragment>
        <Card.Header>
          <Grid.Layout
            columns="400px auto 400px"
            areas={[['left', 'center', 'right']]}
            style={{ width: '100%' }}>
            <Grid.Box area="left">
              <Row>
                <Heading type="h4" text="Create Idea" />
                <TextDateAgo from={savedAt} />
              </Row>
            </Grid.Box>
            <Grid.Box area="center">
              <FormSteps totalSteps={4} step={step} />
            </Grid.Box>
            <Grid.Box area="right" />
          </Grid.Layout>
          {topButtons}
        </Card.Header>
        <CreateViewCardBody>
          <Grid.Layout columns="30% 70%" areas={[['left', 'right']]} style={{ width: '100%' }}>
            <Grid.Box area="left">
              <LeftProgressSection sections={SCREENS_IDEA} currentScreen={step} />
            </Grid.Box>
            <BoxCard>
              <Grid.Box area="right">{content}</Grid.Box>
            </BoxCard>
          </Grid.Layout>
        </CreateViewCardBody>
        {footer}
      </React.Fragment>
    );
  }
}

IdeaCreateView.propTypes = {
  history: PropTypes.object.isRequired,
};

export default withRouter(IdeaCreateView);
