import gql from 'graphql-tag';
import { UserFragment } from '../../auth/queries';
import { CommentFragment } from '../../comment/comment-queries';

/**
 * Contribution Item.
 */
const ContributionFragment = gql`
  fragment ContributionFragment on Contribution {
    id
    name
    description
    contributionDate
    status
    source {
      id
      name
    }
    documents {
      items {
        id
        filename
        downloadUrl
        fileId
      }
    }
    owner {
      ...UserFragment
    }
    initiatives {
      items {
        id
        name
        baselineStartDate
        baselineEndDate
        owner {
          ...UserFragment
        }
        status
      }
    }
    unitType
    unitQuantity
    unitValueDescription
    unitMonetizationFactor
    itemContributionRelation {
      id
    }
    professionalsTrained
    professionalsCertified
    customerSatisfaction
  }
  ${UserFragment}
`;

/**
 * Create.
 */
export const CONTRIBUTION_CREATE_MUTATION = gql`
  mutation($data: ContributionCreateInput!) {
    contributionCreate(data: $data) {
      ...ContributionFragment
    }
  }
  ${ContributionFragment}
`;

export const CONTRIBUTION_DETAIL_QUERY = gql`
  query($id: ID!) {
    contribution(id: $id) {
      ...ContributionFragment
    }
  }
  ${ContributionFragment}
`;

export const CONTRIBUTION_UPDATE_QUERY = gql`
  mutation($data: ContributionUpdateInput!) {
    contributionUpdate(data: $data) {
      ...ContributionFragment
    }
  }
  ${ContributionFragment}
`;

/**
 * Update.
 */
export const CONTRIBUTION_UPDATE_MUTATION = gql`
  mutation($data: ContributionUpdateInput!) {
    contributionUpdate(data: $data) {
      id
    }
  }
`;

/**
 * Query for the Contribution Comments.
 *
 * @type {*|*}
 */
export const CONTRIBUTION_COMMENTS_QUERY = gql`
  query($id: ID!) {
    contribution(id: $id) {
      id
      comments {
        items {
          ...CommentFragment
        }
        count
      }
    }
  }
  ${CommentFragment}
`;

/**
 * CLose.
 */
export const CONTRIBUTION_CLOSE_MUTATION = gql`
  mutation($data: ContributionUpdateInput!) {
    contributionUpdate(data: $data) {
      id
      status
    }
  }
`;
