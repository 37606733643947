import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { Field } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { Select as BoostSelect } from '@8base/boost';
import styled from 'styled-components';

import { Card } from '../components';
import { PlusSeparator } from './PlusSeparator';

const CardsList = ({ cards, setCards, fieldsOptions, fieldsDict }) => {
  const moveCard = (dragIndex, hoverIndex) => {
    let newCardsList = R.clone(cards);
    newCardsList[dragIndex] = cards[hoverIndex];
    newCardsList[hoverIndex] = cards[dragIndex];

    setCards(newCardsList);
  };

  const filteredFieldsOptions = fieldsOptions.filter(
    (field) => !cards.some((card) => card.id === field.value),
  );

  return (
    <>
      <FieldArray name="fieldsList">
        {({ fields }) => {
          const { value, push, remove: removeCard } = fields;
          return (
            <>
              {fields.map((name, cardIndex) => {
                const currentCard = value[cardIndex];
                return (
                  <React.Fragment key={`fragment${currentCard.id}`}>
                    <Field
                      name={name}
                      component={Card}
                      key={`card${currentCard.id}`}
                      index={cardIndex}
                      id={currentCard.id}
                      text={currentCard.displayName}
                      moveCard={moveCard}
                      removeCard={() => removeCard(cardIndex)}
                    />
                    <PlusSeparator key={`PlusSeparator${currentCard.id}`} />
                  </React.Fragment>
                );
              })}
              <Select
                placeholder="Select Field"
                options={filteredFieldsOptions}
                stretch={false}
                onChange={(value) => push(fieldsDict[value])}
              />
            </>
          );
        }}
      </FieldArray>
    </>
  );
};

CardsList.propTypes = {
  cards: PropTypes.array.isRequired,
  setCards: PropTypes.func.isRequired,
  fieldsOptions: PropTypes.array.isRequired,
  fieldsDict: PropTypes.object.isRequired,
};

const Select = styled(BoostSelect)`
  height: 36px;
  width: 212px !important;
  margin-bottom: 23px;
`;

export { CardsList };
