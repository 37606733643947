import React from 'react';
import { Card, Grid, Heading, Loader, Row } from '@8base/boost';
import { CreateViewCardBody } from '../../../components/new-ui/card/CreateViewCardBody';
import ContributionForm from './components/ContributionForm';
import * as toast from '../../../components/toast/Toast';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { CONTRIBUTION_DOCUMENTS, ContributionModel } from './contribution-model';
import { onChangeDataMixin, onErrorMixin } from '../../../shared/mixins';
import { View } from '@cobuildlab/react-simple-state';
import * as R from 'ramda';
import sessionStore, { NEW_SESSION_EVENT } from '../../../shared/SessionStore';
import { autoSaveCreateContribution, createContribution } from './contribution-action';
import { fetchInitiativeList } from '../initiative/initiative-actions';
import { fetchCurrentAllianceMembersAction } from '../../settings/alliance-management/alliance-actions';
import { getCurrencyOnSession } from '../../../shared/alliance-utils';
import { ActionButton } from '../../../components/buttons/ActionButton';
import { ActionButtonClose } from '../../../components/buttons/ActionButtonClose';
import { saveFormToSessionStorage } from '../../../shared/utils';
import { contributionValidator } from './contribution-validators';
import RelatedItemForm from '../../related-item/components/RelatedItemForm';
import { TransparentButton } from '../../../components/buttons/TransparentButton';
import ContributionDetailTable from './components/ContributionDetailTable';
import { RelatedItemsDetailTable } from '../../related-item/components/RelatedItemsDetailTable';
import { FormSteps } from '../../../components/dots/FormSteps';
import { initiativesApproved, initiativesItemValidator } from '../initiative/initiative-validators';
import { LeftProgressSection } from '../../../components/new-ui/LeftProgressSection';
import { SCREENS_CONTRIBUTION } from '../screenView';
import { BoxCard } from '../../../components/new-ui/div/BoxCard';
import { CardFooter } from '../../../components/new-ui/card/CardFooter';
import { TextDateAgo } from '../../../components/text/TextDateAgo';
import { OnAllianceMemberList } from '../../settings/alliance-management/alliance-events';
import { OnInitiativeList } from '../initiative/initiative-events';
import {
  OnContributionCreate,
  OnContributionError,
  OnContributionAutoSave,
  OnContributionAutoSaveError,
} from './contribution-events';
export const CONTRIBUTION_DATA_STORE = 'contributionCreateView';

// eslint-disable-next-line
/**
 * Create Contribution.
 *
 */
class ContributionCreateView extends View {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        contributionData: R.clone(ContributionModel),
        relatedItems: [],
        initiatives: [],
      },
      loading: true,
      initiativesList: [],
      clientCompany: null,
      partnerCompany: null,
      step: 0,
      savedAt: null,
      autoSaving: false,
    };
    this.onError = onErrorMixin.bind(this);
    this.onChangeData = onChangeDataMixin.bind(this);

    this.autoSaveTimer = null;
    this.savedContribution = null;
  }

  onChangeContributionData = (name, value) => {
    const { data } = this.state;
    data.contributionData[name] = value;
    this.autoSave();
    this.setState({ data });

    const model = R.clone(ContributionModel);
    saveFormToSessionStorage(CONTRIBUTION_DATA_STORE, data.contributionData, model, ['documents']);
  };

  componentDidMount() {
    const contributionData = JSON.parse(sessionStorage.getItem(CONTRIBUTION_DATA_STORE));
    this.subscribe(OnContributionError, this.onError);
    this.subscribe(OnAllianceMemberList, (state) => {
      this.setState({
        clientCompany: R.clone(state.clientCompany),
        partnerCompany: R.clone(state.partnerCompany),
      });
    });
    this.subscribe(OnContributionCreate, (state) => {
      sessionStorage.removeItem(CONTRIBUTION_DATA_STORE);
      toast.success('Contribution Successfully Created');
      this.props.history.push(`/management/investment-item`);
    });
    this.subscribe(OnInitiativeList, (state) => {
      const initiativesList = state.initiativesList.items.map((initiative) => ({
        ...initiative,
      }));

      this.setState({
        loading: false,
        initiativesList,
      });
    });

    this.subscribe(OnContributionAutoSave, (contribution) => {
      sessionStorage.removeItem(CONTRIBUTION_DATA_STORE);
      this.setState({ savedAt: new Date(), autoSaving: false });
      this.savedContribution = R.clone(contribution);
    });

    this.subscribe(OnContributionAutoSaveError, () => {
      this.setState({ autoSaving: false });
    });

    // set contributionData from sessionStorage
    if (contributionData) {
      const { data } = this.state;
      data.contributionData = contributionData;
      this.setState({ data });
    }

    fetchInitiativeList('', 1, 1000);
    fetchCurrentAllianceMembersAction();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
    clearTimeout(this.autoSaveTimer);
    localStorage.removeItem(CONTRIBUTION_DOCUMENTS);
  }

  autoSave = () => {
    const waitTime = 10000; // Wait 10s
    clearTimeout(this.autoSaveTimer);
    this.autoSaveTimer = setTimeout(() => {
      this.setState({ autoSaving: true });
      const contributionData = R.clone(this.state.data.contributionData);
      const initiatives = R.clone(this.state.data.initiatives);
      const relatedItems = R.clone(this.state.data.relatedItems);
      autoSaveCreateContribution(
        contributionData,
        relatedItems,
        initiatives,
        this.savedContribution,
      );
    }, waitTime);
  };

  onContributionStepChange = (nextStep) => {
    const contributionData = R.clone(this.state.data.contributionData);
    try {
      contributionValidator(contributionData);
    } catch (e) {
      return this.onError(e);
    }
    this.onScreen(nextStep);
  };

  onScreen = (step) => {
    if (step === 2) clearTimeout(this.autoSaveTimer);
    this.setState({ step });
  };

  onRelatedItemsStepChange = (step) => {
    const initiatives = R.clone(this.state.data.initiatives);
    try {
      initiativesItemValidator(initiatives);
      initiativesApproved(initiatives);
    } catch (e) {
      return this.onError(e);
    }
    this.onScreen(step);
  };

  onSubmit = () => {
    this.setState({ loading: true }, () => {
      clearTimeout(this.autoSaveTimer);
      const contributionData = R.clone(this.state.data.contributionData);
      const initiatives = R.clone(this.state.data.initiatives);
      const relatedItems = R.clone(this.state.data.relatedItems);
      createContribution(contributionData, relatedItems, initiatives, this.savedContribution);
    });
  };

  render() {
    const {
      initiativesList,
      loading,
      clientCompany,
      partnerCompany,
      step,
      savedAt,
      autoSaving,
    } = this.state;
    const { user } = sessionStore.getState(NEW_SESSION_EVENT);
    const { contributionData, relatedItems, initiatives } = this.state.data;
    const { history } = this.props;
    const companyId = user.companyUserRelation.items[0].company.id;
    const currency = getCurrencyOnSession();
    let content = <Loader stretch />;
    let footer = <></>;

    if (!loading && step === 0) {
      content = (
        <div id={'c2gScreen'}>
          <ContributionForm
            data={contributionData}
            onChange={this.onChangeContributionData}
            myCompanyId={companyId}
            initiativesList={initiativesList}
            clientCompany={clientCompany}
            partnerCompany={partnerCompany}
            currency={currency}
          />
        </div>
      );
      footer = (
        <CardFooter>
          <ActionButton onClick={() => this.onContributionStepChange(1)} text="Next" />
        </CardFooter>
      );
    }

    if (!loading && step === 1) {
      content = (
        <div id={'c2gScreen'}>
          <RelatedItemForm
            relatedItems={relatedItems}
            initiatives={initiativesList}
            onChange={(key, value) => {
              this.autoSave();
              this.onChangeData(key, value);
            }}
            selectedInitiatives={initiatives}
            allowedDealOption={true}
          />
        </div>
      );

      footer = (
        <CardFooter>
          <ActionButton onClick={() => this.onRelatedItemsStepChange(2)} text={'Next'} />
          <TransparentButton onClick={() => this.onScreen(0)} text={'Previous'} />
        </CardFooter>
      );
    }

    if (!loading && step === 2) {
      const contributionDetail = R.clone(contributionData);
      contributionDetail.documents = { items: contributionDetail.documents };
      content = (
        <div id={'c2gScreen'}>
          <ContributionDetailTable data={contributionDetail} currency={currency} />
          <RelatedItemsDetailTable relatedItems={relatedItems} initiatives={initiatives} />
        </div>
      );
      footer = (
        <CardFooter>
          <ActionButton
            disabled={autoSaving}
            onClick={this.onSubmit}
            text={'Create Contribution'}
          />
          <TransparentButton onClick={() => this.onScreen(1)} text={'Previous'} />
        </CardFooter>
      );
    }

    return (
      <React.Fragment>
        <Card.Header>
          <Grid.Layout
            columns="400px auto 400px"
            areas={[['left', 'center', 'right']]}
            style={{ width: '100%' }}>
            <Grid.Box area="left">
              <Row>
                <Heading type="h4" text="Create Contribution" />
                <TextDateAgo from={savedAt} />
              </Row>
            </Grid.Box>
            <Grid.Box area="center">
              <FormSteps totalSteps={3} step={step} />
            </Grid.Box>
            <Grid.Box area="right"></Grid.Box>
          </Grid.Layout>
          <div style={{ paddingLeft: '20px', marginRight: '0px' }}>
            <ActionButtonClose onClick={history.goBack} />
          </div>
        </Card.Header>
        <CreateViewCardBody>
          <Grid.Layout columns="30% 70%" areas={[['left', 'right']]} style={{ width: '100%' }}>
            <Grid.Box area="left">
              <LeftProgressSection sections={SCREENS_CONTRIBUTION} currentScreen={step} />
            </Grid.Box>
            <BoxCard>
              <Grid.Box area="right">{content}</Grid.Box>
            </BoxCard>
          </Grid.Layout>
        </CreateViewCardBody>
        {footer}
      </React.Fragment>
    );
  }
}

ContributionCreateView.propTypes = {
  history: PropTypes.object.isRequired,
};

export default withRouter(ContributionCreateView);
