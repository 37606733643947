import { CreditCard } from '../../procurer/procurer-model';
import * as R from 'ramda';

export const COMPANY_SUBSCRIPTION_FREE_TRIAL_PLAN = 'Free Trial';
export const COMPANY_SUBSCRIPTION_FREE_TRIAL_DAILY_PLAN = 'Free Daily Plan';

export const COMPANY_SUBSCRIPTION_FREE_TRIAL_PLANS = [
  COMPANY_SUBSCRIPTION_FREE_TRIAL_PLAN,
  COMPANY_SUBSCRIPTION_FREE_TRIAL_DAILY_PLAN,
];

export const CompanyInitialForm = {
  name: '',
  isCompanyPortfolioOwner: false,
  portfolioOwnerEmail: '',
  creditCard: R.clone(CreditCard),
  description: '',
  subscriptionPlan: null,
};
