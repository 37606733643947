export default {
  id: null,
  name: '',
  description: '',
  documents: [],
  initiatives: [],
  unitType: null,
  unitQuantity: 0,
  unitValueDescription: '',
  unitMonetizationFactor: '0',
  source: null,
};

export const DECISION_DOCUMENTS = 'decisionDocuments';
