// import GearsIcon from '../../../images/icons/gears.svg';
import TableIcon from '../../../images/icons/table.svg';
import TelescopeIcon from '../../../images/icons/telescope.svg';
import moment from 'moment';
import * as R from 'ramda';

/**
 * To intialize the forecastFields.
 *
 * @returns {Array} Array of 12 values.
 */
const forecastFieldData = () => {
  return Array.from({ length: 12 }, (e) => '0');
};

export const ForecastingYearModel = {
  year: moment().year(),
  clientContributionsActuals: forecastFieldData(),
  clientContributionsForecast: forecastFieldData(),
  partnerContributionsActuals: forecastFieldData(),
  partnerContributionsForecast: forecastFieldData(),
  clientBookingsActuals: forecastFieldData(),
  clientBookingsForecast: forecastFieldData(),
  partnerBookingsActuals: forecastFieldData(),
  partnerBookingsForecast: forecastFieldData(),
  clientExpectedBookings: forecastFieldData(),
  partnerExpecedBookings: forecastFieldData(),
  clientExpectedContributions: forecastFieldData(),
  partnerExpectedContributions: forecastFieldData(),
};

export const forecastingYearModel = (year) => {
  const _year = year || moment().year();
  const model = R.clone(ForecastingYearModel);
  model.year = _year;

  return model;
};

const FIELDS_LIST = [
  {
    id: 'id-2',
    displayName: 'Date',
    fieldType: 'DATE',
    name: 'baselineStartDate',
  },
];

const TABS_LIST = [
  // {
  //   to: '/reports/forecasting/configuration',
  //   label: 'Configuration',
  //   icon: GearsIcon,
  // },
  {
    to: '/reports/forecasting/forecasting',
    label: 'Forecasting',
    icon: TelescopeIcon,
  },
  {
    to: '/reports/forecasting/contributions',
    label: 'Contributions',
    icon: TableIcon,
  },
  {
    to: '/reports/forecasting/bookings',
    label: 'Bookings',
    icon: TableIcon,
  },
];

export const MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export { TABS_LIST, FIELDS_LIST };
