import React from 'react';
import { Grid, Loader, Card, Heading } from '@8base/boost';
import { CreateViewCardBody } from '../../../components/card/CreateViewCardBody';
import sessionStore, { NEW_SESSION_EVENT } from './../../../shared/SessionStore';
import { updateUserInformation, fetchUserInformation } from '../../wizard/wizard.actions';
import PropTypes from 'prop-types';
import * as toast from '../../../components/toast/Toast';
import { View } from '@cobuildlab/react-simple-state';
import * as R from 'ramda';
import { fetchSession } from '../../auth/auth.actions';
import { Phone, PhoneType, UserInformation } from '../../auth/auth-models';
import { updateStateFromObject } from '../../../shared/utils';
import UserInformationForm from '../../auth/UserInformationForm';
import { onChangeMixin } from '../../../shared/mixins';
import { ActionButton } from '../../../components/buttons/ActionButton';
import Header from '../../../components/Header';
import { OnWizardError, OnUserInformation, OnUserUpdate } from '../../wizard/wizard-events';

/**
 * The screen to edit my User Data.
 */
class UserProfileView extends View {
  constructor(props) {
    super(props);
    this.state = {
      data: R.clone(UserInformation),
      phoneTypes: [],
      countries: [],
      usaStates: [],
      loading: true,
    };
    this.originalPhones = [];

    const emptyPhone = R.clone(Phone);
    emptyPhone.type = R.clone(PhoneType);
    this.state.data.phones = [emptyPhone];
    this.user = null;
    this.handleChange = this.handleChange.bind(this);
    this.uploadPicture = this.uploadPicture.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onChange = onChangeMixin.bind(this);
  }

  async componentDidMount() {
    this.subscribe(OnUserInformation, async (data) => {
      const { user } = sessionStore.getState(NEW_SESSION_EVENT);
      this.user = R.clone(user);
      this.userInformation = R.clone(data.userInformationsList.items[0]);

      // Add a default phone form if the user has no phones added
      const phones = data.phonesList.items.map((phone) => ({
        ...phone,
      }));

      if (phones.length === 0) phones.push({ phone: '', type: { id: '', name: '' }, id: null });

      const phoneTypes = data.phoneTypesList.items.map((phoneType) => ({
        ...phoneType,
      }));

      const countries = data.countriesList.items.map((country) => ({
        ...country,
      }));

      const usaStates = data.statesList.items.map((usaState) => ({
        ...usaState,
      }));

      const country =
        this.userInformation.country === null ? null : this.userInformation.country.id;

      const userInformation = updateStateFromObject(this.state.data, this.userInformation);
      userInformation.firstName = this.user.firstName;
      userInformation.lastName = this.user.lastName;
      userInformation.avatar = this.user.avatar;
      userInformation.email = this.user.email;
      userInformation.weeklyEmailNotification = this.user.weeklyEmailNotification;
      userInformation.phones = phones;
      userInformation.country = country;

      this.originalPhones = R.clone(phones);

      this.setState({
        data: userInformation,
        phoneTypes,
        countries,
        usaStates,
        loading: false,
      });
    });

    this.subscribe(OnUserUpdate, async () => {
      await fetchSession();
      fetchUserInformation();
      toast.success('User Profile successfully Updated!');
    });

    this.subscribe(OnWizardError, (err) => {
      this.setState({
        loading: false,
      });
      toast.errors(err.arr);
    });
    // We fetch the system user first
    fetchUserInformation();
  }

  handleChange(value, e) {
    this.setState({ [e.target.name]: value });
  }

  uploadPicture() {
    this.setState({ isPictureUploaded: !this.state.isPictureUploaded });
  }

  handleSubmit() {
    const phones = this.state.data.phones.filter((phone) => phone.phone !== '');

    const {
      firstName,
      lastName,
      city,
      country,
      state,
      zipCode,
      title,
      avatar,
      weeklyEmailNotification,
    } = this.state.data;
    const user = {
      avatar,
      firstName: firstName,
      lastName: lastName,
      id: this.user.id,
      weeklyEmailNotification,
    };
    const userInformation = {
      id: this.userInformation.id,
      country: country,
      city: city,
      state: state,
      zipCode: zipCode,
      title: title,
    };

    const originalPhones = this.originalPhones;

    console.log('ORIGINAL_PHONES', originalPhones);

    this.setState(
      {
        loading: true,
      },
      () => updateUserInformation(user, userInformation, phones, originalPhones),
    );
  }

  render() {
    const { loading, phoneTypes, data, countries, usaStates } = this.state;

    let footer = <></>;
    let content = (
      <div id={'c2gScreen'}>
        <Grid.Box />
        <Grid.Box>
          <Loader stretch size={'md'} />
        </Grid.Box>
        <Grid.Box />
      </div>
    );

    if (!loading) {
      content = (
        <div id={'c2gScreen'}>
          <Header text={'Personal Information'} />
          <UserInformationForm
            data={data}
            onChange={this.onChange}
            phoneTypes={phoneTypes}
            countries={countries}
            usaStates={usaStates}
          />
        </div>
      );
      footer = (
        <Card.Footer>
          <ActionButton onClick={this.handleSubmit} text="Save" />
        </Card.Footer>
      );
    }

    return (
      <React.Fragment>
        <Card.Header>
          <Heading type="h4" text="Edit User Profile" />
        </Card.Header>
        <CreateViewCardBody>{content}</CreateViewCardBody>
        {footer}
      </React.Fragment>
    );
  }
}

UserProfileView.propTypes = {
  client: PropTypes.object.isRequired,
  layout: PropTypes.string.isRequired,
  currentScreen: PropTypes.number.isRequired,
  nextScreen: PropTypes.func.isRequired,
  prevScreen: PropTypes.func.isRequired,
};

export default UserProfileView;
