import Flux from '@cobuildlab/flux-state';

/**
 * Event that triggers a Deal error.
 *
 * @type {string}
 */
export const DEAL_ERROR_EVENT = 'onDealError';

/**
 * Event that triggers a Deal Item List event.
 *
 * @type {string}
 */
export const DEAL_LIST_EVENT = 'onDealList';

export const ALL_DEAL_DATA_LIST_EVENT = 'onAllDealDataList';

export const RELATED_DEAL_LIST_EVENT = 'onRelatedDealList';

export const DEAL_DATA_LIST_EVENT = 'onDealDataList';

export const DEAL_MONTHLY_SNAPSHOT_LIST_EVENT = 'onDealMonthlySnapshotList';

export const DEAL_STAGES_EVENT = 'onDealStagesList';

export const DEAL_COMPANIES_EVENT = 'onCompaniesList';

/**
 * Event that triggers a Deal create event.
 *
 * @type {string}
 */
export const DEAL_CREATE_EVENT = 'onDealCreate';

/**
 * Event that triggers a Deal edit event.
 *
 * @type {string}
 */
export const DEAL_UPDATE_EVENT = 'onDealUpdate';

/**
 * Event that triggers a Deal detail event.
 *
 * @type {string}
 */
export const DEAL_DETAIL_EVENT = 'onDealDetail';

/**
 * Event that triggers a Deal delete event.
 *
 * @type {string}
 */
export const DEAL_DELETE_EVENT = 'onDealDelete';

/**
 * Event that triggers a Deal close event.
 *
 * @type {string}
 */
export const DEAL_COMPLETED_EVENT = 'onDealCompleted';

export const DEAL_IMPORTED_EVENT = 'onDealImported';

export const DEAL_IMPORTED_VALID_EVENT = 'onDealImportedValid';

export const DEAL_IMPORTED_HEADER_ERROR_EVENT = 'onDealImportedHeaderError';

export const DEAL_OWNER_IMPORTED_HEADER_ERROR_EVENT = 'onDealOwnerImportedHeaderError';

export const DEAL_DELETE_LIST_EVENT = 'onDealDeleteList';

export const DEAL_IMPORTED_ERROR_EVENT = 'onDealImportedError';

export const DEAL_AUTO_SAVE_CREATE_EVENT = 'DEAL_AUTO_SAVE_CREATE_EVENT';

export const DEAL_AUTO_SAVE_CREATE_ERROR_EVENT = 'DEAL_AUTO_SAVE_CREATE_ERROR_EVENT';

/**
 * Hold the Deal Data.
 */
class DealStore extends Flux.DashStore {
  constructor() {
    super();
    this.addEvent(DEAL_ERROR_EVENT);
    this.addEvent(DEAL_LIST_EVENT);
    this.addEvent(ALL_DEAL_DATA_LIST_EVENT);
    this.addEvent(DEAL_DATA_LIST_EVENT);
    this.addEvent(DEAL_CREATE_EVENT);
    this.addEvent(DEAL_UPDATE_EVENT);
    this.addEvent(DEAL_DETAIL_EVENT);
    this.addEvent(DEAL_DELETE_EVENT);
    this.addEvent(DEAL_COMPLETED_EVENT);
    this.addEvent(DEAL_IMPORTED_EVENT);
    this.addEvent(DEAL_MONTHLY_SNAPSHOT_LIST_EVENT);
    this.addEvent(DEAL_STAGES_EVENT);
    this.addEvent(DEAL_IMPORTED_VALID_EVENT);
    this.addEvent(DEAL_IMPORTED_HEADER_ERROR_EVENT);
    this.addEvent(DEAL_OWNER_IMPORTED_HEADER_ERROR_EVENT);
    this.addEvent(DEAL_DELETE_LIST_EVENT);
    this.addEvent(RELATED_DEAL_LIST_EVENT);
    this.addEvent(DEAL_IMPORTED_ERROR_EVENT);
    this.addEvent(DEAL_COMPANIES_EVENT);
    this.addEvent(DEAL_AUTO_SAVE_CREATE_EVENT);
    this.addEvent(DEAL_AUTO_SAVE_CREATE_ERROR_EVENT);
  }
}

const dealStore = new DealStore();
export default dealStore;
