import sessionStore, { APOLLO_CLIENT, NEW_SESSION_EVENT } from '../../../shared/SessionStore';
import Flux from '@cobuildlab/flux-state';
import {
  AUDIT_LOG_LIST_QUERY,
  AUDIT_LOG_CREATE_MUTATION,
  AUDIT_LOG_USER_LIST,
} from './audit-log-queries';
import { error, log } from '@cobuildlab/pure-logger';
import {
  AUDIT_LOG_ERROR_EVENT,
  AUDIT_LOG_LIST_EVENT,
  AUDIT_LOG_USER_LIST_EVENT,
} from './audit-log-store';
import { OnAuditLogError, OnAuditLogList, OnAuditLogUserList } from './audit-log-events';

const auditLogFilter = (allianceId, actionType, objectType, userFilter) => {
  const filter = {
    alliance: { id: { equals: allianceId } },
  };

  if (objectType) filter.objectType = { equals: objectType };

  if (actionType) filter.actionType = { equals: actionType };

  if (userFilter) filter.createdBy = { id: { equals: userFilter } };

  return filter;
};

/**
 * Fetch audit log list.
 *
 * @param {string}actionTypeFilter - Action Type Filter.
 * @param {string}objectTypeFilter - Object Type Filter.
 * @param {string}userFilter - User Filter.
 * @param {number}skip - Skip.
 * @param {number}first - First.
 * @returns {Promise<void|*>} Promise.
 */
export const fetchAuditLog = async (
  actionTypeFilter,
  objectTypeFilter,
  userFilter,
  skip = 0,
  first = 10,
) => {
  const client = sessionStore.getState(APOLLO_CLIENT);
  const { selectedAlliance } = sessionStore.getState(NEW_SESSION_EVENT);
  const filter = auditLogFilter(
    selectedAlliance.id,
    actionTypeFilter,
    objectTypeFilter,
    userFilter,
  );

  let response;
  const sort = {
    createdAt: 'DESC',
  };
  try {
    response = await client.query({
      query: AUDIT_LOG_LIST_QUERY,
      variables: { data: filter, skip, first, sort },
      fetchPolicy: 'network-only',
    });
  } catch (e) {
    error('fetchAuditLog', e);
    OnAuditLogError.dispatch(e);
    return Flux.dispatchEvent(AUDIT_LOG_ERROR_EVENT, e);
  }

  log('fetchAuditLog', response.data);

  OnAuditLogList.dispatch(response.data);
  Flux.dispatchEvent(AUDIT_LOG_LIST_EVENT, response.data);

  return response.data;
};
/**
 * Create log when user login or logout.
 *
 * @param {string}action - Action.
 * @returns {Promise<*>} Promise.
 */
export const auditLogUserLogin = async (action) => {
  const client = sessionStore.getState(APOLLO_CLIENT);
  const { selectedAlliance, user } = sessionStore.getState(NEW_SESSION_EVENT);

  if (!selectedAlliance) return;

  let response;
  const data = {
    data: {},
    actionType: action,
    objectType: 'USER',
    alliance: {
      connect: {
        id: selectedAlliance.id,
      },
    },
    user: {
      connect: {
        id: user.id,
      },
    },
  };

  try {
    response = await client.mutation({
      mutation: AUDIT_LOG_CREATE_MUTATION,
      variables: { data },
      fetchPolicy: 'network-only',
    });
  } catch (e) {
    error('auditLogUserLoginError', e);
  }

  return response;
};

/**
 * Fetch possible user logs.
 *
 * @returns {Promise<void>} Promise.
 */
export const auditLogUserList = async () => {
  const client = sessionStore.getState(APOLLO_CLIENT);
  const { selectedAlliance } = sessionStore.getState(NEW_SESSION_EVENT);

  const data = {
    query: {
      user: {
        id: {
          as: 'userId',
        },
        firstName: {
          as: 'userFirstName',
        },
        lastName: {
          as: 'userLastName',
        },
      },
    },
    having: {
      alias: 'userId',
      id: {
        not_equals: null,
      },
    },
  };

  const filter = {
    alliance: {
      id: {
        equals: selectedAlliance.id,
      },
    },
  };

  let response;

  try {
    response = await client.query({
      query: AUDIT_LOG_USER_LIST,
      variables: { data: data, filter },
      fetchPolicy: 'network-only',
    });
  } catch (e) {
    error('auditLogUserList', e);
    OnAuditLogError.dispatch(e);
    return Flux.dispatchEvent(AUDIT_LOG_ERROR_EVENT, e);
  }

  log('auditLogUserList', response.data);

  OnAuditLogUserList.dispatch(response.data);
  Flux.dispatchEvent(AUDIT_LOG_USER_LIST_EVENT, response.data);
};
