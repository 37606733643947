import React from 'react';
import { Card, Grid, Text } from '@8base/boost';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { GrayBoldH2 } from '../../../components/text/GrayBoldH2';
import { TimeFromNow } from '../../../components/date/TimeFromNow';
import { Link } from 'react-router-dom';
import check from '../../../images/icons/check-mark-outlined.svg';

const Section = styled(Card.Section)`
  width: 100%;
  border-bottom: 2px solid #eaedf5;
`;
const BlueSpan = styled('span')`
  color: #3eb7f9;
  font-family: Poppins;
  font-size: 12px;
  line-height: 18px;
  cursor: pointer;
  position: relative;
  bottom: 0px;
  padding-right: 20px;
`;
const Notification = ({ notification, onClick, goToNotification }) => (
  <Section>
    <Grid.Layout gap={'none'} columns="40px auto 40px">
      <Grid.Box>
        <img width={'31.5px'} height={'26px'} alt={'profile'} src={check} />
      </Grid.Box>
      <Grid.Box>
        <GrayBoldH2 text={notification.type} />
        <Text>{notification.message}</Text>
        <Link
          onClick={() => goToNotification(notification.id)}
          className="item-name"
          to={`/${notification.url}/${notification.objectId}/`}>
          {notification.name}
        </Link>
        <TimeFromNow date={notification.createdAt} />
        <Grid.Box>
          <BlueSpan onClick={() => onClick(notification)}>Mark as Read</BlueSpan>
        </Grid.Box>
      </Grid.Box>
      <Grid.Box />
    </Grid.Layout>
  </Section>
);

Notification.propTypes = {
  notification: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  goToNotification: PropTypes.func.isRequired,
};

export { Notification };
