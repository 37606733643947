import React from 'react';
import PropTypes from 'prop-types';
import DetailValue from '../../../../components/DetailValue';
import CostYearPrefix from '../../../../components/text/CostYearPrefix';
import { CurrencyTextField } from '../../../../shared/components/CurrencyTextField';
import { Grid, Row } from '@8base/boost';
import DocumentsFileComponent from '../../../../components/inputs/DocumentsFileComponent';
import { BUSINESS_CASE_DOCUMENT } from '../BusinessCase.model';
import { HorizontalLineText } from '../../../../components/text/HorizontalLineText';
import { HorizontalLine } from '../../../../components/new-ui/text/HorizontalLine';
import { TableDetail } from '../../../../components/new-ui/table/TableDetail';
import { BoderDetailView } from '../../../../components/new-ui/div/BorderDetailView';
import { TablePosition } from '../../../../components/new-ui/div/TablePosition';
import { HeaderText } from '../../../../components/new-ui/text/HeaderText';
import { ThTitlePosition } from '../../../../components/new-ui/div/ThTitlePosition';

/**
 * Detail View Table For Business Case.
 */

class BusinessCaseDetailTable extends React.Component {
  render() {
    const { currency, hideExpectedCostAvoidance } = this.props;
    const {
      backgroundSummary,
      visionMissionStrategy,
      marketAnalysis,
      salesMarketingStrategy,
      expectedCosts,
      expectedRevenues,
      anticipatedCosts,
      risks,
      document,
      /* recommendedSolutions,
      document, */
    } = this.props.data;
    return (
      <>
        <HorizontalLine>
          <HorizontalLineText>BUSINESS CASE</HorizontalLineText>
        </HorizontalLine>
        <>
          <BoderDetailView>
            <Grid.Layout columns="50% 50%" areas={[['left', 'right']]} style={{ width: '100%' }}>
              <Grid.Box area="left">
                <div style={{ position: 'absolute', top: '21px', left: '25px' }}>
                  <HeaderText>BUSINESS CASE</HeaderText>
                </div>
              </Grid.Box>
            </Grid.Layout>
          </BoderDetailView>
          <TablePosition>
            <TableDetail>
              <tbody>
                <tr>
                  <th>
                    <ThTitlePosition>
                      <span>BACKGROUND SUMMARY</span>
                    </ThTitlePosition>
                  </th>
                  <td>
                    <DetailValue text={backgroundSummary} />
                  </td>
                </tr>
                <tr>
                  <th>
                    <ThTitlePosition>
                      <span>VISION, MISSION, STRATEGY</span>
                    </ThTitlePosition>
                  </th>
                  <td>
                    <DetailValue text={visionMissionStrategy} />
                  </td>
                </tr>
                <tr>
                  <th>
                    <ThTitlePosition>
                      <span>MARKET ANALYSIS</span>
                    </ThTitlePosition>
                  </th>
                  <td>
                    <DetailValue text={marketAnalysis} />
                  </td>
                </tr>
                <tr>
                  <th>
                    <ThTitlePosition>
                      <span>SALES MARKET STRATEGY</span>
                    </ThTitlePosition>
                  </th>
                  <td>
                    <DetailValue text={salesMarketingStrategy} />
                  </td>
                </tr>
                <tr>
                  <th>
                    <ThTitlePosition>
                      <span>RISKS</span>
                    </ThTitlePosition>
                  </th>
                  <td>
                    <DetailValue text={risks} />
                  </td>
                </tr>
                <tr>
                  <th>
                    <ThTitlePosition>
                      <span>ANTICIPATED CONTRIBUTIONS</span>
                    </ThTitlePosition>
                  </th>
                  <td>
                    {anticipatedCosts.map((value, index) => (
                      <Row key={index}>
                        <CostYearPrefix index={index} />
                        <CurrencyTextField value={value} currency={currency} />
                      </Row>
                    ))}
                  </td>
                </tr>
                {!hideExpectedCostAvoidance && (
                  <tr>
                    <th>
                      <ThTitlePosition>
                        <span>EXPECTED COSTS AVOIDANCE</span>
                      </ThTitlePosition>
                    </th>
                    <td>
                      {expectedCosts.map((value, index) => (
                        <Row key={index}>
                          <CostYearPrefix index={index} />
                          <CurrencyTextField value={value} currency={currency} />
                        </Row>
                      ))}
                    </td>
                  </tr>
                )}
                <tr>
                  <th>
                    <ThTitlePosition>
                      <span>EXPECTED BOOKINGS</span>
                    </ThTitlePosition>
                  </th>
                  <td>
                    {expectedRevenues.map((value, index) => (
                      <Row key={index}>
                        <CostYearPrefix index={index} />
                        <CurrencyTextField value={value} currency={currency} />
                      </Row>
                    ))}
                  </td>
                </tr>
                <tr>
                  <th>
                    <ThTitlePosition>
                      <span>DOCUMENT</span>
                    </ThTitlePosition>
                  </th>
                  <td>
                    <DocumentsFileComponent data={document} localKey={BUSINESS_CASE_DOCUMENT} />
                  </td>
                </tr>
              </tbody>
            </TableDetail>
          </TablePosition>
        </>
      </>
    );
  }
}

BusinessCaseDetailTable.propTypes = {
  data: PropTypes.object.isRequired,
  currency: PropTypes.object.isRequired,
  hideExpectedCostAvoidance: PropTypes.bool,
};

BusinessCaseDetailTable.defaultProps = {
  hideExpectedCostAvoidance: false,
};

export default BusinessCaseDetailTable;
