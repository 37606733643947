import Flux from '@cobuildlab/flux-state';
import Proptypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useSubscription } from 'react-apollo';
import { createApolloClient } from '../../shared/apollo';
import { getToken } from '../../shared/apollo/helpers';
import sessionStore, { NEW_SESSION_EVENT } from '../../shared/SessionStore';
import { ALLIANCE_UPDATE_SUBSCRIPTION } from '../settings/alliance-management/alliance-queries';

export const SelectedAllianceSubscription = (props) => {
  const session = sessionStore.getState(NEW_SESSION_EVENT);
  const selectedAlliance = session ? session.selectedAlliance : {};
  const allianceId = selectedAlliance ? selectedAlliance.id : '';
  const [client, setClient] = useState(createApolloClient(getToken));

  const { data, loading, error } = useSubscription(ALLIANCE_UPDATE_SUBSCRIPTION, {
    variables: {
      id: allianceId,
    },
    fetchPolicy: 'network-only',
    client,
  });

  useEffect(() => {
    if (!client || error) {
      setClient(createApolloClient(getToken));
    }
  }, [client, error]);

  useEffect(() => {
    if (!loading && !error && data) {
      const changedAlliance = data.Alliance.node;
      const allianceId = selectedAlliance ? selectedAlliance.id : '';
      const areSameAlliance = changedAlliance.id === allianceId;
      const allianceHaveDataDifferent = changedAlliance !== selectedAlliance;

      if (areSameAlliance && allianceHaveDataDifferent) {
        console.log('UPDATING SELECTED ALLIANCE', selectedAlliance);
        Flux.dispatchEvent(NEW_SESSION_EVENT, {
          ...session,
          selectedAlliance: { ...selectedAlliance, ...changedAlliance },
        });
      }
    }
  }, [data, loading, error, selectedAlliance]);

  return <div>{props.children}</div>;
};

SelectedAllianceSubscription.propTypes = {
  children: Proptypes.any.isRequired,
};
