import React from 'react';
import { Heading, Text } from '@8base/boost';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';

const RelativeH = styled(Heading)`
  margin: 20px 0;
  position: relative;
  text-align: center;
  z-index: 5;
  &:before {
    content: '';
    display: block;
    border-top: solid 1px #edf0f2;
    width: 100%;
    height: 1px;
    position: absolute;
    top: 50%;
  }
`;

const TextWrapper = styled(Text)`
  background-color: #fff;
  padding: 0 20px;
  position: relative;
  color: #7e828b;
  font-family: Poppins;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0.5px;
  line-height: 16px;
`;

const HorizontalLineText = ({ children, ...rest }) => {
  return (
    <RelativeH type="h6">
      <TextWrapper color="GRAY1" weight="bold" {...rest}>
        {children}
      </TextWrapper>
    </RelativeH>
  );
};

HorizontalLineText.propTypes = {
  children: PropTypes.any.isRequired,
};

export { HorizontalLineText };
