import gql from 'graphql-tag';

import { AllianceKPIFragment } from '../../settings/alliance-management/allianceKPIs/allianceKPIs-queries';

export const BALANCED_SCORECARD_QUERY = gql`
  query(
    $allianceId: ID!
    $initiativesFilter: InitiativeFilter
    $itemsFilter: ItemFilter
    $dealsFilter: DealDatumFilter
    $skip: Int
    $first: Int
  ) {
    alliance(id: $allianceId) {
      id
      effectiveDate
      clientCompany {
        id
        name
      }
      partnerCompany {
        id
        name
      }
      businessCase {
        id
        anticipatedCosts
        expectedCosts
        expectedRevenues
      }
      allianceKPIAllianceRelation {
        items {
          ...AllianceKPIFragment
        }
      }
    }
    initiativesList(filter: $initiativesFilter, skip: $skip, first: $first) {
      count
      items {
        id
        name
        description
        status
        forecastedEndDate
        owner {
          id
          firstName
          lastName
        }
        initiativeApprovalInitiativeRelation {
          items {
            dateOfResponse
            status
          }
        }
      }
    }
    itemsList(filter: $itemsFilter, skip: $skip, first: $first) {
      count
      items {
        id
        createdAt
        contribution {
          id
          name
          description
          status
          unitType
          unitQuantity
          unitMonetizationFactor
          calculatedValue
          unitValueDescription
          contributionDate
          professionalsTrained
          professionalsCertified
          customerSatisfaction
          source {
            id
            name
          }
        }
        idea {
          id
          name
          description
          status
          assignedTo {
            id
            firstName
            lastName
          }
          ideaApprovalRelation(
            filter: { status: { equals: "APPROVED" } }
            sort: [{ dateOfResponse: DESC }]
            first: 1
          ) {
            items {
              dateOfResponse
              status
            }
          }
        }
      }
    }
    dealDataList(filter: $dealsFilter) {
      items {
        id
        dealReferencable
        customerName
        stage
        amount
        nextActivityDescription
        nextActivityDate
        name
        closeDate
        numberOfTimesContacted
        numberOfSalesActivities
        type
        company {
          id
        }
      }
    }
  }
  ${AllianceKPIFragment}
`;

export const SCORECARD_FINANCIAL_DETAIL_QUERY = gql`
  query($id: ID!, $contFilter: ItemFilter, $dealFilter: DealDatumFilter, $skip: Int, $first: Int) {
    alliance(id: $id) {
      id
      name
      effectiveDate
      clientCompany {
        id
        name
      }
      partnerCompany {
        id
        name
      }
      businessCase {
        id
        anticipatedCosts
        expectedCosts
        expectedRevenues
      }
      allianceKPIAllianceRelation {
        items {
          ...AllianceKPIFragment
        }
      }
    }
    itemsList(filter: $contFilter, skip: $skip, first: $first) {
      count
      items {
        id
        contribution {
          id
          contributionDate
          source {
            id
          }
        }
      }
    }
    dealDataList(filter: $dealFilter) {
      items {
        id
        closeDate
        stage
        amount
        name
        customerName
        nextActivityDescription
        nextActivityDate
        company {
          id
        }
      }
    }
  }
  ${AllianceKPIFragment}
`;

export const SCORECARD_STRATEGY_DETAIL_QUERY = gql`
  query($id: ID!, $initFilter: InitiativeFilter, $ideaFilter: ItemFilter, $skip: Int, $first: Int) {
    alliance(id: $id) {
      id
      name
      effectiveDate
      clientCompany {
        id
        name
      }
      partnerCompany {
        id
        name
      }
      businessCase {
        id
        anticipatedCosts
        expectedCosts
        expectedRevenues
      }
      allianceKPIAllianceRelation {
        items {
          ...AllianceKPIFragment
        }
      }
    }
    initiativesList(filter: $initFilter, skip: $skip, first: $first) {
      count
      items {
        initiativeApprovalInitiativeRelation {
          items {
            status
            dateOfResponse
          }
        }
      }
    }
    itemsList(filter: $ideaFilter, skip: $skip, first: $first) {
      count
      items {
        id
        idea {
          id
          ideaApprovalRelation {
            items {
              dateOfResponse
              status
            }
          }
        }
      }
    }
  }
  ${AllianceKPIFragment}
`;

export const SCORECARD_RELATIONSHIP_DETAIL_QUERY = gql`
  query($id: ID!, $contFilter: ItemFilter, $dealFilter: DealDatumFilter, $skip: Int, $first: Int) {
    alliance(id: $id) {
      id
      name
      effectiveDate
      clientCompany {
        id
        name
      }
      partnerCompany {
        id
        name
      }
      businessCase {
        id
        anticipatedCosts
        expectedCosts
        expectedRevenues
      }
      allianceKPIAllianceRelation {
        items {
          ...AllianceKPIFragment
        }
      }
    }
    itemsList(filter: $contFilter, skip: $skip, first: $first) {
      count
      items {
        id
        contribution {
          id
          contributionDate
          description
          name
          unitValueDescription
          unitMonetizationFactor
          source {
            id
          }
          unitType
          unitQuantity
          professionalsTrained
          professionalsCertified
        }
      }
    }
    dealDataList(filter: $dealFilter) {
      items {
        id
        closeDate
        stage
        name
        customerName
        nextActivityDescription
        nextActivityDate
        numberOfTimesContacted
        company {
          id
        }
        dealReferencable
      }
    }
  }
  ${AllianceKPIFragment}
`;

export const SCORECARD_OPERATIONAL_DETAIL_QUERY = gql`
  query($id: ID!, $dealFilter: DealDatumFilter) {
    alliance(id: $id) {
      id
      name
      effectiveDate
      clientCompany {
        id
        name
      }
      partnerCompany {
        id
        name
      }
      businessCase {
        id
        anticipatedCosts
        expectedCosts
        expectedRevenues
      }
      allianceKPIAllianceRelation {
        items {
          ...AllianceKPIFragment
        }
      }
    }
    dealDataList(filter: $dealFilter) {
      items {
        id
        closeDate
        stage
        name
        customerName
        nextActivityDescription
        nextActivityDate
        numberOfTimesContacted
        company {
          id
        }
      }
    }
  }
  ${AllianceKPIFragment}
`;
