import gql from 'graphql-tag';
import { UserFragment } from '../../auth/queries';
import { CommentFragment } from '../../comment/comment-queries';

const DecisionFragment = gql`
  fragment DecisionFragment on Decision {
    id
    name
    description
    status
    createdAt
    unitType
    unitQuantity
    unitValueDescription
    unitMonetizationFactor
    createdBy {
      ...UserFragment
    }
    initiatives {
      items {
        id
        name
        baselineStartDate
        baselineEndDate
        owner {
          ...UserFragment
        }
        status
      }
    }

    documents {
      items {
        id
        downloadUrl
        filename
        shareUrl
        fileId
      }
    }
    itemDecisionRelation {
      id
    }
    source {
      id
      name
    }
  }
  ${UserFragment}
`;

/**
 * Create.
 */
export const DECISION_CREATE_MUTATION = gql`
  mutation($data: DecisionCreateInput!) {
    decisionCreate(data: $data) {
      ...DecisionFragment
    }
  }
  ${DecisionFragment}
`;

/**
 * Retrieve.
 */
export const DECISION_DETAIL_QUERY = gql`
  query($id: ID!) {
    decision(id: $id) {
      ...DecisionFragment
    }
  }
  ${DecisionFragment}
`;

/**
 * Update.
 */
export const DECISION_UPDATE_MUTATION = gql`
  mutation($data: DecisionUpdateInput!) {
    decisionUpdate(data: $data) {
      ...DecisionFragment
    }
  }
  ${DecisionFragment}
`;

/**
 * Query for the Decision Comments.
 *
 * @type {*|*}
 */
export const DECISION_COMMENTS_QUERY = gql`
  query($id: ID!) {
    decision(id: $id) {
      id
      comments {
        items {
          ...CommentFragment
        }
        count
      }
    }
  }
  ${CommentFragment}
`;
