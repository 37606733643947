import { allianceKPIsYearModel } from './allianceKPIs/allianceKPIs-model';

export default {
  id: null,
  name: '',
  description: '',
  clientCompany: null,
  partnerCompany: null,
  currency: null,
  policyGuidelinesFile: null,
  organizationalChartFile: null,
  documents: [],
  invitationEmail: '',
  businessCase: { name: '' },
  allianceKPIs: allianceKPIsYearModel(),
  effectiveDate: null,
  oneSided: false,
};

export const ALLIANCE_ORGANIZATIONAL_CHART_FILE = 'allianceOrganizationalChartFile';
export const ALLIANCE_POLICY_GUIDELINES_FILE = 'alliancePolicyGuidelinesFile';
export const ALLIANCE_DOCUMENTS_FILE = 'allianceDocuments';

export const COLUMNSEXCEL = [
  { header: 'ID', key: 'id', width: 32 },
  { header: 'NAME', key: 'name', width: 32 },
  { header: 'DESCRIPTION', key: 'description', width: 32 },
  { header: 'STATUS', key: 'status', width: 32 },
  { header: 'EFFECTIVE_DATE', key: 'effectiveDate', width: 32 },
  { header: 'BUSINESS_CASE_STATUS', key: 'businessStatus', width: 32 },
  { header: 'BUSINESS_CASE_BACKGROUND_SUMMARY', key: 'backgroundSummary', width: 32 },
  { header: 'BUSINESS_CASE_VISION_MISSION_STRATEGY', key: 'visionMissionStrategy', width: 32 },
  { header: 'BUSINESS_CASE_MARKET_ANALISYS', key: 'marketAnalysis', width: 32 },
  { header: 'BUSINESS_CASE_SALES_MARKETING_STRATEGY', key: 'salesMarketingStrategy', width: 32 },
  { header: 'BUSINESS_CASE_RECOMMENDED_SOLUTION', key: 'recommendedSolution', width: 32 },
  { header: 'BUSINESS_CASE_RISK', key: 'risks', width: 32 },
  { header: 'BUSINESS_OWNER_EMAIL', key: 'businessCaseOwnerEmail', width: 32 },
  { header: 'BUSINESS_OWNER_FIRSTNAME', key: 'businessCaseOwnerFirstName', width: 32 },
  { header: 'BUSINESS_OWNER_LASTNAME', key: 'businessCaseOwnerLastName', width: 32 },
  { header: 'BUSINESS_ANTICIPATED_COST', key: 'anticipatedCosts', width: 32 },
  { header: 'BUSINESS_EXPECTED_COST', key: 'expectedCosts', width: 32 },
  { header: 'BUSINESS_EXPECTED_REVENUED', key: 'expectedRevenues', width: 32 },
  { header: 'CURRENCY_SHORT_NAME', key: 'shortName', width: 32 },
  { header: 'CURRENCY_SYMBOL', key: 'symbol', width: 32 },
  { header: 'CURRENCY_THOUSAND_SEPARATOR', key: 'thousandSeparator', width: 32 },
  { header: 'CURRENCY_DECIMAL_SEPARATOR', key: 'decimalSeparator', width: 32 },
  { header: 'OWNER_EMAIL', key: 'ownerEmail', width: 32 },
  { header: 'OWNER_FIRSTNAME', key: 'ownerFirstName', width: 32 },
  { header: 'OWNER_LASTNAME', key: 'ownerLastName', width: 32 },
  { header: 'CLIENT_COMPANY_NAME', key: 'clientCompanyName', width: 32 },
  {
    header: 'ALLIANCE_KPI_ALLIANCE_RELATION_TYPE_01',
    key: 'allianceKPIAllianceRelationType0',
    width: 32,
  },
  {
    header: 'ALLIANCE_KPI_ALLIANCE_RELATION_YEAR_01',
    key: 'allianceKPIAllianceRelationYear0',
    width: 32,
  },
  {
    header: 'ALLIANCE_KPI_ALLIANCE_RELATION_TARGET_01',
    key: 'allianceKPIAllianceRelationTarget0',
    width: 32,
  },
  {
    header: 'ALLIANCE_KPI_ALLIANCE_RELATION_DISTRIBUTION_TYPE_01',
    key: 'allianceKPIAllianceRelationTargetDistributionType0',
    width: 32,
  },
  {
    header: 'ALLIANCE_KPI_ALLIANCE_RELATION_MONTH_BY_MONTH_01',
    key: 'allianceKPIAllianceRelationMonthByMonth0',
    width: 32,
  },
  {
    header: 'ALLIANCE_KPI_ALLIANCE_RELATION_TYPE_02',
    key: 'allianceKPIAllianceRelationType1',
    width: 32,
  },
  {
    header: 'ALLIANCE_KPI_ALLIANCE_RELATION_YEAR_02',
    key: 'allianceKPIAllianceRelationYear1',
    width: 32,
  },
  {
    header: 'ALLIANCE_KPI_ALLIANCE_RELATION_TARGET_02',
    key: 'allianceKPIAllianceRelationTarget1',
    width: 32,
  },
  {
    header: 'ALLIANCE_KPI_ALLIANCE_RELATION_DISTRIBUTION_TYPE_02',
    key: 'allianceKPIAllianceRelationTargetDistributionType1',
    width: 32,
  },
  {
    header: 'ALLIANCE_KPI_ALLIANCE_RELATION_TYPE_03',
    key: 'allianceKPIAllianceRelationType2',
    width: 32,
  },
  {
    header: 'ALLIANCE_KPI_ALLIANCE_RELATION_YEAR_03',
    key: 'allianceKPIAllianceRelationYear2',
    width: 32,
  },
  {
    header: 'ALLIANCE_KPI_ALLIANCE_RELATION_TARGET_03',
    key: 'allianceKPIAllianceRelationTarget2',
    width: 32,
  },
  {
    header: 'ALLIANCE_KPI_ALLIANCE_RELATION_DISTRIBUTION_TYPE_03',
    key: 'allianceKPIAllianceRelationTargetDistributionType2',
    width: 32,
  },
  {
    header: 'ALLIANCE_KPI_ALLIANCE_RELATION_TYPE_04',
    key: 'allianceKPIAllianceRelationType3',
    width: 32,
  },
  {
    header: 'ALLIANCE_KPI_ALLIANCE_RELATION_YEAR_04',
    key: 'allianceKPIAllianceRelationYear3',
    width: 32,
  },
  {
    header: 'ALLIANCE_KPI_ALLIANCE_RELATION_TARGET_04',
    key: 'allianceKPIAllianceRelationTarget3',
    width: 32,
  },
  {
    header: 'ALLIANCE_KPI_ALLIANCE_RELATION_DISTRIBUTION_TYPE_04',
    key: 'allianceKPIAllianceRelationTargetDistributionType3',
    width: 32,
  },
  {
    header: 'ALLIANCE_KPI_ALLIANCE_RELATION_TYPE_05',
    key: 'allianceKPIAllianceRelationType4',
    width: 32,
  },
  {
    header: 'ALLIANCE_KPI_ALLIANCE_RELATION_YEAR_05',
    key: 'allianceKPIAllianceRelationYear4',
    width: 32,
  },
  {
    header: 'ALLIANCE_KPI_ALLIANCE_RELATION_TARGET_05',
    key: 'allianceKPIAllianceRelationTarget4',
    width: 32,
  },
  {
    header: 'ALLIANCE_KPI_ALLIANCE_RELATION_DISTRIBUTION_TYPE_05',
    key: 'allianceKPIAllianceRelationTargetDistributionType4',
    width: 32,
  },
  {
    header: 'ALLIANCE_KPI_ALLIANCE_RELATION_TYPE_06',
    key: 'allianceKPIAllianceRelationType5',
    width: 32,
  },
  {
    header: 'ALLIANCE_KPI_ALLIANCE_RELATION_YEAR_06',
    key: 'allianceKPIAllianceRelationYear5',
    width: 32,
  },
  {
    header: 'ALLIANCE_KPI_ALLIANCE_RELATION_TARGET_06',
    key: 'allianceKPIAllianceRelationTarget5',
    width: 32,
  },
  {
    header: 'ALLIANCE_KPI_ALLIANCE_RELATION_DISTRIBUTION_TYPE_06',
    key: 'allianceKPIAllianceRelationTargetDistributionType5',
    width: 32,
  },
  {
    header: 'ALLIANCE_KPI_ALLIANCE_RELATION_TYPE_07',
    key: 'allianceKPIAllianceRelationType6',
    width: 32,
  },
  {
    header: 'ALLIANCE_KPI_ALLIANCE_RELATION_YEAR_07',
    key: 'allianceKPIAllianceRelationYear6',
    width: 32,
  },
  {
    header: 'ALLIANCE_KPI_ALLIANCE_RELATION_TARGET_07',
    key: 'allianceKPIAllianceRelationTarget6',
    width: 32,
  },
  {
    header: 'ALLIANCE_KPI_ALLIANCE_RELATION_DISTRIBUTION_TYPE_07',
    key: 'allianceKPIAllianceRelationTargetDistributionType6',
    width: 32,
  },
  {
    header: 'ALLIANCE_KPI_ALLIANCE_RELATION_TYPE_08',
    key: 'allianceKPIAllianceRelationType7',
    width: 32,
  },
  {
    header: 'ALLIANCE_KPI_ALLIANCE_RELATION_YEAR_08',
    key: 'allianceKPIAllianceRelationYear7',
    width: 32,
  },
  {
    header: 'ALLIANCE_KPI_ALLIANCE_RELATION_TARGET_08',
    key: 'allianceKPIAllianceRelationTarget7',
    width: 32,
  },
  {
    header: 'ALLIANCE_KPI_ALLIANCE_RELATION_DISTRIBUTION_TYPE_08',
    key: 'allianceKPIAllianceRelationTargetDistributionType7',
    width: 32,
  },
  {
    header: 'ALLIANCE_KPI_ALLIANCE_RELATION_TYPE_09',
    key: 'allianceKPIAllianceRelationType8',
    width: 32,
  },
  {
    header: 'ALLIANCE_KPI_ALLIANCE_RELATION_YEAR_09',
    key: 'allianceKPIAllianceRelationYear8',
    width: 32,
  },
  {
    header: 'ALLIANCE_KPI_ALLIANCE_RELATION_TARGET_09',
    key: 'allianceKPIAllianceRelationTarget8',
    width: 32,
  },
  {
    header: 'ALLIANCE_KPI_ALLIANCE_RELATION_DISTRIBUTION_TYPE_09',
    key: 'allianceKPIAllianceRelationTargetDistributionType8',
    width: 32,
  },
  {
    header: 'ALLIANCE_KPI_ALLIANCE_RELATION_TYPE_10',
    key: 'allianceKPIAllianceRelationType9',
    width: 32,
  },
  {
    header: 'ALLIANCE_KPI_ALLIANCE_RELATION_YEAR_10',
    key: 'allianceKPIAllianceRelationYear9',
    width: 32,
  },
  {
    header: 'ALLIANCE_KPI_ALLIANCE_RELATION_TARGET_10',
    key: 'allianceKPIAllianceRelationTarget9',
    width: 32,
  },
  {
    header: 'ALLIANCE_KPI_ALLIANCE_RELATION_DISTRIBUTION_TYPE_10',
    key: 'allianceKPIAllianceRelationTargetDistributionType9',
    width: 32,
  },
  {
    header: 'ALLIANCE_KPI_ALLIANCE_RELATION_TYPE_11',
    key: 'allianceKPIAllianceRelationType10',
    width: 32,
  },
  {
    header: 'ALLIANCE_KPI_ALLIANCE_RELATION_YEAR_11',
    key: 'allianceKPIAllianceRelationYear10',
    width: 32,
  },
  {
    header: 'ALLIANCE_KPI_ALLIANCE_RELATION_TARGET_11',
    key: 'allianceKPIAllianceRelationTarget10',
    width: 32,
  },
  {
    header: 'ALLIANCE_KPI_ALLIANCE_RELATION_DISTRIBUTION_TYPE_11',
    key: 'allianceKPIAllianceRelationTargetDistributionType10',
    width: 32,
  },
  {
    header: 'ALLIANCE_KPI_ALLIANCE_RELATION_TYPE_12',
    key: 'allianceKPIAllianceRelationType11',
    width: 32,
  },
  {
    header: 'ALLIANCE_KPI_ALLIANCE_RELATION_YEAR_12',
    key: 'allianceKPIAllianceRelationYear11',
    width: 32,
  },
  {
    header: 'ALLIANCE_KPI_ALLIANCE_RELATION_TARGET_12',
    key: 'allianceKPIAllianceRelationTarget11',
    width: 32,
  },
  {
    header: 'ALLIANCE_KPI_ALLIANCE_RELATION_DISTRIBUTION_TYPE_12',
    key: 'allianceKPIAllianceRelationTargetDistributionType11',
    width: 32,
  },
];

export const COLUMNSOBJECT = [
  {
    key: 'businessCase',
    value: [
      { businessStatus: 'status' },
      { backgroundSummary: 'backgroundSummary' },
      { visionMissionStrategy: 'visionMissionStrategy' },
      { marketAnalysis: 'marketAnalysis' },
      { salesMarketingStrategy: 'salesMarketingStrategy' },
      { recommendedSolution: 'recommendedSolution' },
      { anticipatedCosts: 'anticipatedCosts' },
      { expectedCosts: 'expectedCosts' },
      { expectedRevenues: 'expectedRevenues' },
      { risks: 'risks' },
      {
        owner: {
          businessCaseOwnerEmail: 'email',
          businessCaseOwnerFirstName: 'firstName',
          businessCaseOwnerLastName: 'lastName',
        },
      },
    ],
  },
  {
    key: 'currency',
    value: [
      { shortName: 'shortName' },
      { symbol: 'symbol' },
      { thousandSeparator: 'thousandSeparator' },
      { decimalSeparator: 'decimalSeparator' },
    ],
  },
  {
    key: 'owner',
    value: [
      {
        ownerEmail: 'email',
        ownerFirstName: 'firstName',
        ownerLastName: 'lastName',
      },
    ],
  },
  {
    key: 'clientCompany',
    value: [{ clientCompanyName: 'name' }],
  },
];

for (let x = 0; x < 12; x++) {
  let object = {
    key: 'kpi' + x,
    value: [
      {
        ['allianceKPIAllianceRelationType' + x]: 'type',
        ['allianceKPIAllianceRelationYear' + x]: 'year',
        ['allianceKPIAllianceRelationTarget' + x]: 'target',
        ['allianceKPIAllianceRelationTargetDistributionType' + x]: 'targetDistributionType',
        ['allianceKPIAllianceRelationMonthByMonth' + x]: 'monthByMonth',
      },
    ],
  };
  COLUMNSOBJECT.push(object);
}

export const MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];
