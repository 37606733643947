import React, { Fragment } from 'react';
import { Row, Column, RadioGroupField, Label, Grid, SelectField } from '@8base/boost';
import PropTypes from 'prop-types';
import { onErrorMixin } from '../../../../../shared/mixins';
import { CurrencyInputField } from '../../../../../shared/components/CurrencyInputField';
import {
  TARGET_DISTRIBUTION_LABELS,
  MONTHS,
  MANUAL_TYPE,
  JOINT_SALES_CALLS_TYPE,
} from '../allianceKPIs-model';
import { HorizontalLineText } from '../../../../../components/new-ui/text/HorizontalLineText';
import { ColumnLeft } from '../../../../../components/new-ui/ColumnLeft';
import { ColumnRight } from '../../../../../components/new-ui/ColumnRight';
import BigInt from 'big-integer';
import PlusButton from '../../../../../components/PlusButton';
import DeleteButton from '../../../../../components/DeleteButton';
import { isCurrencyTypeKPI, isPercentageTypeKPI } from '../../../../../shared/utils';

/**
 * The Form for the Alliance Entity.
 */

const AllianceKPIsForm = ({
  alliance,
  onChange,
  onYearChange,
  selectedYear,
  kpiYears,
  onYearsChange,
}) => {
  const { allianceKPIs, currency } = alliance;

  const canDeleteYears =
    kpiYears.length > 1 && parseInt(kpiYears[kpiYears.length - 1]) > new Date().getFullYear();

  const yearSelect = (
    <Row justifyContent={'around'} alignItems={'center'}>
      <Column>
        {canDeleteYears ? (
          <DeleteButton text={'Remove Year'} onClick={() => onYearsChange(-1)} />
        ) : null}
      </Column>
      <Column>
        <SelectField
          style={{ maxWidth: '100px', minWidth: '100px' }}
          label="Year"
          input={{
            name: 'year',
            value: selectedYear,
            onChange: (value) => onYearChange(value),
          }}
          meta={{}}
          placeholder="Select the KPI year"
          options={kpiYears.map((year) => {
            return { label: year, value: year };
          })}
        />
      </Column>
      <Column>
        <PlusButton text={'Add Year'} onClick={() => onYearsChange(1)} />
      </Column>
    </Row>
  );

  const content = (
    <>
      {allianceKPIs
        .filter(({ year }) => year === selectedYear)
        .filter(({ type }) => type !== JOINT_SALES_CALLS_TYPE)
        .map((allianceKPI, i) => {
          const showMonthByMonth = allianceKPI.targetDistributionType === MANUAL_TYPE;
          const currencyOptions = {};
          if (isCurrencyTypeKPI(allianceKPI.type)) {
            currencyOptions.currency = currency;
            currencyOptions.decimalScale = 0;
            currencyOptions.suffix = '';
          } else if (isPercentageTypeKPI(allianceKPI.type)) {
            currencyOptions.currency = { ...currency, symbol: '' };
            currencyOptions.suffix = '%';
            currencyOptions.decimalScale = 1;
          } else {
            currencyOptions.currency = { ...currency, symbol: '' };
            currencyOptions.decimalScale = 0;
            currencyOptions.suffix = '';
          }
          let kpiName = allianceKPI.type;
          let horizontalLineText = null;
          if (allianceKPI.type.includes('- Client')) {
            kpiName = allianceKPI.type.replace(' - Client', '');
            horizontalLineText = alliance.clientCompany ? alliance.clientCompany.name : 'Client';
          }
          if (allianceKPI.type.includes('- Partner')) {
            kpiName = '';
            horizontalLineText = alliance.partnerCompany ? alliance.partnerCompany.name : 'Partner';
          }

          return (
            <>
              <Row growChildren gap="lg" offsetTop="lg" key={`${kpiName}-${allianceKPI.year}-row1`}>
                <ColumnLeft text={`${horizontalLineText ? kpiName : ''}`} />
                <ColumnRight> </ColumnRight>
              </Row>
              {horizontalLineText ? <HorizontalLineText text={horizontalLineText} /> : null}
              <Row
                growChildren
                gap="lg"
                offsetBottom="lg"
                key={`${kpiName}-${allianceKPI.year}-row2`}>
                <ColumnLeft text={`${!horizontalLineText ? kpiName : ''}`} />
                <ColumnRight style={{ width: '60%' }} alignItems="start">
                  <Label>{`Target Distribution Type Year ${allianceKPI.year}`}</Label>
                  <RadioGroupField
                    direction={'row'}
                    options={TARGET_DISTRIBUTION_LABELS}
                    input={{
                      value: allianceKPI.targetDistributionType,
                      onChange: (value) => {
                        allianceKPI.targetDistributionType = value;
                        if (value === MANUAL_TYPE) {
                          if (!allianceKPI.monthByMonth || !allianceKPI.monthByMonth.length) {
                            allianceKPI.monthByMonth = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
                          }
                          allianceKPI.target = allianceKPI.monthByMonth.reduce(
                            (a, b) => BigInt(a).add(b),
                            BigInt(0),
                          );
                        }
                        onChange('allianceKPIs', allianceKPIs);
                      },
                    }}
                  />

                  <Row>
                    <CurrencyInputField
                      allowNegative={false}
                      currency={currencyOptions.currency}
                      decimalScale={currencyOptions.decimalScale}
                      fixedDecimalScale={true}
                      suffix={currencyOptions.suffix}
                      label={'Target'}
                      value={allianceKPI.target}
                      isNumericString
                      disabled={showMonthByMonth}
                      onChange={(value) => {
                        allianceKPI.target = value;
                        onChange('allianceKPIs', allianceKPIs);
                      }}
                    />
                  </Row>

                  {showMonthByMonth ? (
                    <Row growChildren>
                      <Column>
                        {allianceKPI.monthByMonth.map((target, monthIndex) => {
                          const label = `${MONTHS[monthIndex]} ${allianceKPI.year}`;
                          const isOdd = monthIndex % 2;
                          return !isOdd ? (
                            <Fragment key={`${kpiName}-${allianceKPI.year}-${monthIndex}`}>
                              <CurrencyInputField
                                allowNegative={false}
                                currency={currencyOptions.currency}
                                decimalScale={currencyOptions.decimalScale}
                                suffix={currencyOptions.suffix}
                                label={label}
                                fixedDecimalScale={true}
                                isNumericString={true}
                                value={target}
                                onChange={(value) => {
                                  allianceKPI.monthByMonth[monthIndex] = value;
                                  console.log(allianceKPI.monthByMonth[monthIndex]);
                                  allianceKPI.target = allianceKPI.monthByMonth
                                    .reduce((a, b) => a + +b, 0)
                                    .toString();
                                  onChange('allianceKPIs', allianceKPIs);
                                }}
                              />
                            </Fragment>
                          ) : null;
                        })}
                      </Column>
                      <Column>
                        {allianceKPI.monthByMonth.map((target, monthIndex) => {
                          const label = `${MONTHS[monthIndex]} ${allianceKPI.year}`;
                          const isOdd = monthIndex % 2;
                          return isOdd ? (
                            <Fragment key={`${kpiName}-${allianceKPI.year}-${monthIndex}`}>
                              <CurrencyInputField
                                allowNegative={false}
                                currency={currencyOptions.currency}
                                decimalScale={currencyOptions.decimalScale}
                                suffix={currencyOptions.suffix}
                                label={label}
                                isNumericString={true}
                                fixedDecimalScale={true}
                                value={target}
                                onChange={(value) => {
                                  allianceKPI.monthByMonth[monthIndex] = value;
                                  allianceKPI.target = allianceKPI.monthByMonth
                                    .reduce((a, b) => a + +b, 0)
                                    .toString();
                                  onChange('allianceKPIs', allianceKPIs);
                                }}
                              />
                            </Fragment>
                          ) : null;
                        })}
                      </Column>
                    </Row>
                  ) : null}
                </ColumnRight>
              </Row>
            </>
          );
        })}
    </>
  );

  return (
    <Grid.Layout columns="1fr" areas={[['center']]} style={{ width: '100%' }}>
      <Grid.Box area="center">
        <HorizontalLineText text={'Key Performance Indicator'} />
        {yearSelect}
        {content}
      </Grid.Box>
    </Grid.Layout>
  );
};

Object.assign(AllianceKPIsForm.prototype, onErrorMixin);

AllianceKPIsForm.propTypes = {
  onChange: PropTypes.func.isRequired,
  onYearChange: PropTypes.func.isRequired,
  alliance: PropTypes.object.isRequired,
  selectedYear: PropTypes.number.isRequired,
  kpiYears: PropTypes.array.isRequired,
  onYearsChange: PropTypes.func.isRequired,
};

export default AllianceKPIsForm;
