import React from 'react';
import PropTypes from 'prop-types';
import { ButtonGroup, Button } from '@8base/boost';
import styled from '@emotion/styled';

const SwitchButton = styled(Button)`
  font-family: 'Poppins', sans-serif;
  font-size: 1.2rem;
  width: 142px;
`;

const buttonsTextByType = {
  alphabet: {
    ascending: 'A → Z',
    descending: 'Z → A',
  },
  numeric: {
    ascending: '1 → 9',
    descending: '9 → 1',
  },
  date: {
    ascending: 'OLD → NEW',
    descending: 'NEW → OLD',
  },
};

class SortSwitch extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      value: 'ASC',
    };
  }

  handleChange = (value) => this.setState({ value });

  static getDerivedStateFromProps(props, state) {
    if (props.value && props.value !== state.value) {
      return {
        value: props.value,
      };
    }
    return null;
  }

  render() {
    const { value } = this.state;
    const { type } = this.props;
    const onChange = this.props.onChange ? this.props.onChange : this.handleChange;
    return (
      <ButtonGroup>
        <SwitchButton
          type="button"
          variant={value === 'ASC' ? 'raised' : 'outlined'}
          size="sm"
          onClick={() => onChange('ASC')}>
          {buttonsTextByType[type].ascending}
        </SwitchButton>
        <SwitchButton
          type="button"
          variant={value === 'DESC' ? 'raised' : 'outlined'}
          size="sm"
          onClick={() => onChange('DESC')}>
          {buttonsTextByType[type].descending}
        </SwitchButton>
      </ButtonGroup>
    );
  }
}

SortSwitch.displayName = 'SortSwitch';
SortSwitch.propTypes = {
  value: PropTypes.oneOf(['ASC', 'DESC', undefined]),
  onChange: PropTypes.any,
  type: PropTypes.oneOf(Object.keys(buttonsTextByType)),
};
SortSwitch.defaultProps = {
  value: undefined,
  onChange: undefined,
  type: Object.keys(buttonsTextByType)[0],
};

export { SortSwitch };
