import React from 'react';
import { Form, Field } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';
import { Card, Heading } from '@8base/boost';
import { View } from '@cobuildlab/react-simple-state';
import * as R from 'ramda';
import { TransparentButtonWithIcon } from '../../../components/buttons/TransparentButtonWithIcon';
import { ViewCardBody } from '../../../components/card/ViewCardBody';
import { ActionButton } from '../../../components/buttons/ActionButton';
import { fetchCurrentAllianceMembersAction } from '../../settings/alliance-management/alliance-actions';
import {
  FieldSection,
  FieldSectionHeader,
  FieldSectionText,
  FilterFieldsRow,
  ReportsCard,
} from '../components';
import { getOptionsFromArray, validateReportsConfig } from '../utils';
import { saveSalesPipelineTrendsFilters } from './sales-pipeline-trends-actions';
import { FILTER_FIELDS_LIST, INITIAL_STATE } from './sales-pipeline-trends-model';
import { fetchAllDealsData } from '../../management/deal/deal-actions';
import { OnAllianceMemberList } from '../../settings/alliance-management/alliance-events';
import { OnDealAllData } from '../../management/deal/deal-events';
import { OnSalePipelineTrendsFilter } from './sales-pipeline-trends-events';

/**
 * These fieldsOptionsByFilters and fieldsDictByFilters are used only for the 'filters' field.
 */
const fieldsOptionsByFilters = FILTER_FIELDS_LIST.map((field) => ({
  label: field.displayName,
  value: field.id,
}));
const fieldsDictByFilters = FILTER_FIELDS_LIST.reduce((dict, field) => {
  return {
    ...dict,
    [field.id]: field,
  };
}, {});
// eslint-disable-next-line
/**
 * These fieldsOptionsByGroups and fieldsDictByGroup are used only for the 'groups' field.
 */
// const fieldsOptionsByGroups = GROUPING_FIELDS_LIST.map((field) => ({
//   label: field.displayName,
//   value: field.name,
// }));

// const fieldsDictByGroup = GROUPING_FIELDS_LIST.reduce((dict, field) => {
//   return {
//     ...dict,
//     [field.name]: field,
//   };
// }, {});

class SalesPipelineTrendsConfigView extends View {
  constructor(props) {
    super(props);

    this.state = {
      data: OnSalePipelineTrendsFilter.get() || INITIAL_STATE,
      clientCompany: null,
      partnerCompany: null,
      dealsList: [],
    };
  }

  componentDidMount() {
    this.subscribe(OnSalePipelineTrendsFilter, (data) => {
      this.setState({ data: R.clone(data) });
    });

    this.subscribe(OnAllianceMemberList, (data) => {
      this.setState({
        clientCompany: R.clone(data.clientCompany),
        partnerCompany: R.clone(data.partnerCompany),
      });
    });

    this.subscribe(OnDealAllData, (state) => {
      const items = state.dealDataList.items.map((deal) => ({
        ...deal,
      }));

      this.setState({
        dealsList: items,
      });
    });

    fetchCurrentAllianceMembersAction();
    fetchAllDealsData();
  }

  onSubmit = (data) => {
    const { history } = this.props;
    saveSalesPipelineTrendsFilters(R.clone(data));
    history.push('/reports/sales-pipeline-trends/report');
  };

  render() {
    const { dealsList } = this.state;

    const stageOptions = getOptionsFromArray(
      Array.from(new Set(dealsList.map((deal) => deal.stage))),
    );

    return (
      <Form
        onSubmit={this.onSubmit}
        subscription={{ submitting: true, error: true, pristine: true }}
        mutators={{
          ...arrayMutators,
        }}
        initialValues={this.state.data}
        validate={validateReportsConfig}
        render={({ form: { change }, handleSubmit, submitting, validating }) => {
          return (
            <div id={'c2gScreen'}>
              <form onSubmit={handleSubmit}>
                <ReportsCard stretch withFooter>
                  <Card.Header>
                    <Heading type="h4" text={'Sales Pipeline Trends'} />
                  </Card.Header>
                  <ViewCardBody
                    style={{ textAlign: 'left', padding: '25px 24px' }}
                    className="card-body-report-with-footer">
                    {/*<FieldSection>
                      <FieldSectionHeader weight="semibold" text="Organization Filter" />
                      <Field
                        name="organization"
                        subscription={{ active: true, value: true, error: true }}
                        render={({ input: { value, onChange }, ...rest }) => (
                          <>
                            {value ? (
                              <FilterRow withDeleteButton>
                                <SelectField
                                  input={{
                                    value: value.id,
                                    onChange: (value) =>
                                      onChange({
                                        id: value,
                                      }),
                                  }}
                                  label="Filter by"
                                  placeholder="Select Company"
                                  options={organiationFilterOptions}
                                  {...rest}
                                />
                                <DeleteButton
                                  text={''}
                                  iconName={'Delete'}
                                  onClick={() => onChange(null)}
                                  iconSize={'md'}
                                />
                              </FilterRow>
                            ) : (
                              <>
                                <FieldSectionText text="No organization filters have been applied to this report, currently the report is capturing data from the entire alliance." />
                                <TransparentButtonWithIcon
                                  text={'Add Organization Filter'}
                                  iconName={'Add'}
                                  onClick={() => onChange({})}
                                />
                              </>
                            )}
                          </>
                        )}
                      />
                    </FieldSection>*/}
                    <FieldSection>
                      <FieldSectionHeader weight="semibold" text="Filter" />
                      <FieldArray name="filters">
                        {({ fields }) => {
                          return (
                            <>
                              {fields.length > 0 ? (
                                fields.map((name, fieldIndex) => (
                                  <Field
                                    key={fieldIndex}
                                    fieldIndex={fieldIndex}
                                    name={name}
                                    component={FilterFieldsRow}
                                    deleteFilter={() => fields.remove(fieldIndex)}
                                    fieldsDict={fieldsDictByFilters}
                                    selectedFilters={fields.value}
                                    fieldsOptions={fieldsOptionsByFilters}
                                    stageOptions={stageOptions}
                                    changeFormValue={change}
                                    withDeleteButton
                                  />
                                ))
                              ) : (
                                <FieldSectionText text="No filters have been applied to this report." />
                              )}
                              {fields.length < fieldsOptionsByFilters.length ? (
                                <TransparentButtonWithIcon
                                  text={'Add Filter'}
                                  iconName={'Add'}
                                  onClick={() => fields.push({})}
                                />
                              ) : null}
                            </>
                          );
                        }}
                      </FieldArray>
                    </FieldSection>
                    {/*<FieldSection>
                      <FieldSectionHeader weight="semibold" text="Group" />
                      <FieldArray name="groups">
                        {({ fields }) => {
                          return (
                            <>
                              {fields.length > 0 ? (
                                fields.map((name, fieldIndex) => {
                                  return (
                                    <Field
                                      key={fieldIndex}
                                      name={name}
                                      subscription={{ active: true, value: true, error: true }}
                                      component={GroupFieldsRow}
                                      deleteFilter={() => fields.remove(fieldIndex)}
                                      fieldsDict={fieldsDictByGroup}
                                      selectedGroups={fields.value}
                                      fieldIndex={fieldIndex}
                                      fieldsOptions={fieldsOptionsByGroups}
                                      changeFormValue={change}
                                    />
                                  );
                                })
                              ) : (
                                <FieldSectionText text="No groups have been applied to this report." />
                              )}
                              {fields.length < 1 ? (
                                <TransparentButtonWithIcon
                                  text={'Add Group'}
                                  iconName={'Add'}
                                  onClick={() => fields.push({ sort: 'ASC' })}
                                />
                              ) : null}
                            </>
                          );
                        }}
                      </FieldArray>
                    </FieldSection>*/}
                  </ViewCardBody>
                  <Card.Footer style={{ padding: '13px 23px' }}>
                    <ActionButton
                      text="Run"
                      style={{ width: '151px' }}
                      type="submit"
                      disabled={submitting || validating}
                    />
                  </Card.Footer>
                </ReportsCard>
              </form>
            </div>
          );
        }}
      />
    );
  }
}

SalesPipelineTrendsConfigView.displayName = 'SalesPipelineTrendsConfigView';

export { SalesPipelineTrendsConfigView };
