import Flux from '@cobuildlab/flux-state';

export const DASHBOARD_ERROR = 'onDashboardError';
export const DASHBOARD_ITEMS = 'onDashboardItems';

/**
 * Hold the Dashboard Data.
 */
class DashboardStore extends Flux.DashStore {
  constructor() {
    super();
    this.addEvent(DASHBOARD_ERROR);
    this.addEvent(DASHBOARD_ITEMS);
  }
}

const dashboardStore = new DashboardStore();

export { dashboardStore };
