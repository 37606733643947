import Flux from '@cobuildlab/flux-state';

/**
 * Event that triggers the creation of an Idea.
 *
 * @type {string}
 */
export const IDEA_CREATE_EVENT = 'onCreateIdea';

/**
 * Event that triggers an Idea error.
 *
 * @type {string}
 */
export const IDEA_ERROR_EVENT = 'onIdeaError';

/**
 * Event that triggers an Idea List fetched.
 *
 * @type {string}
 */
export const IDEA_LIST_EVENT = 'onIdeaList';

/**
 * Event that triggers an Idea Update event.
 *
 * @type {string}
 */
export const IDEA_UPDATE_EVENT = 'onIdeaUpdate';

/**
 * Event that triggers an Idea Reject event.
 *
 * @type {string}
 */
export const IDEA_REJECT_EVENT = 'onIdeaReject';

/**
 * Event that triggers an Idea Delete event.
 *
 * @type {string}
 */
export const IDEA_DELETE_EVENT = 'onIdeaDelete';

/**
 * Event that triggers an Idea by id if fetched.
 *
 * @type {string}
 */
export const IDEA_DETAIL_EVENT = 'onIdeaDetail';

/**
 * Event that triggers an Idea completed.
 *
 * @type {string}
 */
export const IDEA_COMPLETED_EVENT = 'onIdeaClose';

/**
 * Event that triggers an Idea submit for approval.
 *
 * @type {string}
 */
export const IDEA_SUBMIT_FOR_APPROVAL_EVENT = 'onIdeaSubmitForApproval';

/**
 * Event that triggers an Idea restore.
 *
 * @type {string}
 */
export const IDEA_RESTORE_EVENT = 'onIdeaRestore';

/**
 * Event that triggers on Idea Approval List.
 *
 * @type {string}
 */
export const IDEA_APPROVAL_LIST_EVENT = 'onIdeaApprovalList';

/**
 * Event that triggers on Idea Full List.
 *
 * @type {string}
 */
export const IDEA_FULL_LIST_EVENT = 'onIdeaFullList';

/**
 * Event that triggers IDEA_AUTO_SAVE EVENT.
 *
 * @type {string}
 */
export const IDEA_AUTO_SAVE_EVENT = 'IDEA_AUTO_SAVE_EVENT';

/**
 * Event that triggers IDEA_AUTO_SAVE_ERROR_EVENT.
 *
 * @type {string}
 */
export const IDEA_AUTO_SAVE_ERROR_EVENT = 'IDEA_AUTO_SAVE_ERROR_EVENT';

/**
 * Hold the Idea Data.
 */
class IdeaStore extends Flux.DashStore {
  constructor() {
    super();
    this.addEvent(IDEA_CREATE_EVENT);
    this.addEvent(IDEA_ERROR_EVENT);
    this.addEvent(IDEA_LIST_EVENT);
    this.addEvent(IDEA_UPDATE_EVENT);
    this.addEvent(IDEA_REJECT_EVENT);
    this.addEvent(IDEA_DETAIL_EVENT);
    this.addEvent(IDEA_DELETE_EVENT);
    this.addEvent(IDEA_COMPLETED_EVENT);
    this.addEvent(IDEA_SUBMIT_FOR_APPROVAL_EVENT);
    this.addEvent(IDEA_RESTORE_EVENT);
    this.addEvent(IDEA_APPROVAL_LIST_EVENT);
    this.addEvent(IDEA_FULL_LIST_EVENT);
    this.addEvent(IDEA_AUTO_SAVE_EVENT);
    this.addEvent(IDEA_AUTO_SAVE_ERROR_EVENT);
  }
}

export default new IdeaStore();
