import Flux from '@cobuildlab/flux-state';
import sessionStore, { APOLLO_CLIENT, NEW_SESSION_EVENT } from '../../../shared/SessionStore';
import { error, log } from '@cobuildlab/pure-logger';
import {
  INITIATIVE_ACTIVE_ITEMS_LIST_QUERY,
  INITIATIVE_ACTIVE_ITEMS_LIST_SHORT_QUERY,
} from './initiative-active-items-queries';
import {
  INITIATIVE_ACTIVE_ITEMS_ERROR_EVENT,
  INITIATIVE_ACTIVE_ITEMS_EVENT,
} from './initiative-active-items-store';
import { ACTION_REJECTED, ACTION_CLOSED } from '../../../shared/status';
import {
  onActiveItemsError,
  onInitiativeDashoardActiveItems,
  onInitiativeActiveItems,
} from './initiative-active-items-event';

/**
 * Filter active item, items and nextSteps of the selected initiative.
 *
 * @param  {string} allianceId -  The allianceId to filter.
 * @param  {string} initiativeId -  The initiativeId to filter.
 * @returns {object}             The filter object.
 */
const initiativeActiveItemsFilter = (allianceId, initiativeId) => {
  const riskFilter = {
    risk: {
      initiatives: { some: { id: { equals: initiativeId } } },
      id: { not_equals: null },
    },
  };

  const issueFilter = {
    issue: {
      initiatives: { some: { id: { equals: initiativeId } } },
      id: { not_equals: null },
    },
  };

  const ideaFilter = {
    idea: {
      initiatives: { some: { id: { equals: initiativeId } } },
      id: { not_equals: null },
    },
  };

  const fundingRequestFilter = {
    fundingRequest: {
      initiatives: { some: { id: { equals: initiativeId } } },
      id: { not_equals: null },
    },
  };

  const actionFilter = {
    action: {
      initiatives: { some: { id: { equals: initiativeId } } },
      id: { not_equals: null },
      status: { not_in: [ACTION_REJECTED, ACTION_CLOSED] },
    },
  };

  const itemFilter = {
    alliance: { id: { equals: allianceId } },
    OR: [riskFilter, issueFilter, ideaFilter, fundingRequestFilter, actionFilter],
  };

  return { itemFilter };
};

/**
 * Fetch initiative active items.
 *
 * @param {string} initiativeId - The Id of the Initiative.
 * @returns {Promise<void>} - The list of results.
 *
 */
export const fetchInitiativeActiveItems = async (initiativeId) => {
  const client = sessionStore.getState(APOLLO_CLIENT);
  const { selectedAlliance } = sessionStore.getState(NEW_SESSION_EVENT);
  const allianceId = selectedAlliance.id;
  const { itemFilter } = initiativeActiveItemsFilter(allianceId, initiativeId);

  let response;
  try {
    response = await client.query({
      query: INITIATIVE_ACTIVE_ITEMS_LIST_QUERY,
      variables: { itemFilter },
      fetchPolicy: 'network-only',
    });
  } catch (e) {
    error('fetchInitiativeActiveItems', e);
    onActiveItemsError.dispatch(e);
    return Flux.dispatchEvent(INITIATIVE_ACTIVE_ITEMS_ERROR_EVENT, e);
  }
  log('fetchInitiativeActiveItems', response.data);
  onInitiativeActiveItems.dispatch(response.data);
  Flux.dispatchEvent(INITIATIVE_ACTIVE_ITEMS_EVENT, response.data);
  return response.data;
};

/**
 * Fetch initiative active items to dashboard.
 *
 * @param {string} initiativeId - The Id of the Initiative.
 * @returns {Promise<void>} - The list of results.
 *
 */
export const fetchInitiativeActiveItemsToDashboard = async (initiativeId) => {
  const client = sessionStore.getState(APOLLO_CLIENT);
  const { selectedAlliance } = sessionStore.getState(NEW_SESSION_EVENT);
  const allianceId = selectedAlliance.id;
  const { itemFilter } = initiativeActiveItemsFilter(allianceId, initiativeId);

  let response;
  try {
    response = await client.query({
      query: INITIATIVE_ACTIVE_ITEMS_LIST_SHORT_QUERY,
      variables: { itemFilter },
      // fetchPolicy: 'network-only',
    });
  } catch (e) {
    error('fetchInitiativeActiveItems', e);
    onActiveItemsError.dispatch(e);
    return;
  }
  log('fetchInitiativeActiveItems', response.data);
  onInitiativeDashoardActiveItems.dispatch(response.data);
  return response.data;
};
