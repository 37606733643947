import {
  ALLIANCE_INFORMATION_QUERY,
  CREATE_ALLIANCE_INFORMATION_MUTATION,
  UPDATE_ALLIANCE_INFORMATION_MUTATION,
} from '../wizard/alliance.queries';
import Flux from '@cobuildlab/flux-state';
import {
  ALLIANCE_INFORMATION_CREATE_EVENT,
  ALLIANCE_INFORMATION_EVENT,
  ALLIANCE_INFORMATION_UPDATE_EVENT,
  WIZARD_ERROR,
} from '../wizard/WizardStore';
import sessionStore, { APOLLO_CLIENT, NEW_SESSION_EVENT } from '../../shared/SessionStore';
import { isValidString } from '../../shared/validators';
import { fetchCompanyInformation } from '../wizard/company-actions';
import { createAllianceWizardValidator } from '../settings/alliance-management/alliance-validators';
import { createPreAllianceCompany } from '../settings/alliance-management/alliance-actions';
import {
  ALLIANCE_ADMINISTRATOR,
  COMPANY_ADMINISTRATOR,
  COMPANY_PORTFOLIO_OWNER,
} from '../../shared/roles';
import { IntegrityError } from '../../shared/errors';
import { error, log } from '@cobuildlab/pure-logger';
import moment from 'moment';
import { COMPANY_LAST_SUBSCRIPTION_QUERY } from '../wizard/company.queries';
import { COMPANY_SUBSCRIPTION_ACTIVE } from '../../shared/status';
import {
  ALLIANCE_ERROR_EVENT,
  REMAINING_ALLIANCES_EVENT,
} from '../settings/alliance-management/alliance-store';
import { OnRemainingAlliances } from '../settings/alliance-management/alliance-events';
import { OnWizardError, OnAllianceInformationCreate } from '../wizard/wizard-events';
/**
 * Create an Alliance
 - Sets the User logged id as the owner
 - Sets the client company if the User logged in Has one.
 *
 * @param {object}alliance - Alliance.
 * @returns {Promise<void>}Promise.
 */
export const createAllianceWizard = async (alliance) => {
  log('createAllianceWizard', alliance);
  const client = sessionStore.getState(APOLLO_CLIENT);
  const user = sessionStore.getState(NEW_SESSION_EVENT).user;
  const allianceAdministratorRole = ALLIANCE_ADMINISTRATOR;

  // delete id
  delete alliance.id;

  // Invitation Email
  const invitationEmail = alliance.invitationEmail;
  delete alliance.invitationEmail;

  try {
    createAllianceWizardValidator(alliance);
  } catch (err) {
    error('createAllianceWizard', err);
    OnWizardError.dispatch(err);
    return Flux.dispatchEvent(WIZARD_ERROR, err);
  }

  // Client Company if the User has one
  const { companyUsersList } = await fetchCompanyInformation();
  const companyInformation = companyUsersList.items.find(
    (companyInformation) =>
      companyInformation.role.name === COMPANY_ADMINISTRATOR ||
      companyInformation.role.name === COMPANY_PORTFOLIO_OWNER,
  );

  if (companyInformation === undefined) {
    OnWizardError.dispatch(
      new IntegrityError('You need to be a Portfolio Owner or Administrator to Create an Alliance'),
    );

    return Flux.dispatchEvent(
      WIZARD_ERROR,
      new IntegrityError('You need to be a Portfolio Owner or Administrator to Create an Alliance'),
    );
  }

  alliance.clientCompany = {
    connect: { id: companyInformation.company.id },
  };
  // I'm the Owner of this alliance
  alliance.owner = { connect: { id: user.id } };
  alliance.allianceUserAllianceRelation = {
    create: [
      {
        companyUser: {
          connect: {
            id: user.companyUserRelation.items[0].id,
          },
        },
        role: {
          connect: {
            name: allianceAdministratorRole,
          },
        },
      },
    ],
  };
  // also the Business Case in one off
  alliance.businessCase = {
    create: {
      owner: { connect: { id: user.id } },
      expectedCosts: ['0'],
      anticipatedCosts: ['0'],
      expectedRevenues: ['0'],
      backgroundSummary: '',
      marketAnalysis: '',
      salesMarketingStrategy: '',
      visionMissionStrategy: '',
      risks: '',
      recommendedSolution: '',
    },
  };

  //Currency
  alliance.currency = {
    connect: {
      id: alliance.currency,
    },
  };

  if (alliance.oneSided) {
    try {
      const partnerCompany = await createPreAllianceCompany(alliance);

      alliance.partnerCompany = { connect: { id: partnerCompany.id } };
      delete alliance.partnerCompanyName;
    } catch (error) {
      error('createPreAllianceAlliance', error);
      OnWizardError.dispatch(error);
      return Flux.dispatchEvent(WIZARD_ERROR, error);
    }
  } else {
    // Delete the partnerCompany field to have a valid allianceCreate mutation data.
    delete alliance.partnerCompany;
    delete alliance.partnerCompanyName;
  }

  // IF THERE AN EMAIL, CREATE INVITATION ENTRY
  if (isValidString(invitationEmail)) {
    alliance.allianceInvitationRelation = {
      create: {
        email: invitationEmail,
        allianceName: alliance.name,
      },
    };
  }

  let result;
  try {
    result = await client.mutate({
      mutation: CREATE_ALLIANCE_INFORMATION_MUTATION,
      variables: { data: alliance },
    });
  } catch (e) {
    error('createAllianceWizard', e);
    OnWizardError.dispatch(e);
    return Flux.dispatchEvent(WIZARD_ERROR, e);
  }

  // try {
  //   await addPortfolioOwnersAsAllianceCollaborator(
  //     allianceId,
  //     companyInformation.company.id,
  //     roles,
  //   );
  // } catch (e) {
  //   error('addPortfolioOwnersAsAllianceCollabowwwrator', e);
  //   return Flux.dispatchEvent(WIZARD_ERROR, e);
  // }

  log('createAllianceWizard', result);
  OnAllianceInformationCreate.dispatch(result);
  return Flux.dispatchEvent(ALLIANCE_INFORMATION_CREATE_EVENT, result);
};

/**
 * Update an Alliance.
 *
 * @param {object}allianceInformation - Alliance information.
 * @returns {Promise<void>}Promise.
 */
export const updateAllianceInformation = async (allianceInformation) => {
  const client = sessionStore.getState(APOLLO_CLIENT);
  // Invitation Email
  if (isValidString(allianceInformation.invitationEmail)) {
    allianceInformation.allianceInvitationRelation = {
      create: {
        email: allianceInformation.invitationEmail,
      },
    };
  }
  allianceInformation.currency = {
    connect: {
      id: allianceInformation.currency,
    },
  };

  delete allianceInformation.invitationEmail;

  let result;
  try {
    result = await client.mutate({
      mutation: UPDATE_ALLIANCE_INFORMATION_MUTATION,
      variables: { data: allianceInformation },
    });
  } catch (e) {
    console.error('updateAllianceInformation', e);
    return Flux.dispatchEvent(WIZARD_ERROR, e);
  }

  console.log('updateAllianceInformation', result);
  return Flux.dispatchEvent(ALLIANCE_INFORMATION_UPDATE_EVENT, result);
};

/**
 * Fetches a Alliance Information by Id.
 *
 * @deprecated
 *
 * @returns {Promise<void>}Promise.
 */
export const fetchAllianceInformation = async () => {
  const client = sessionStore.getState(APOLLO_CLIENT);
  const user = sessionStore.getState(NEW_SESSION_EVENT).user;
  let response;
  try {
    response = await client.query({
      query: ALLIANCE_INFORMATION_QUERY,
      variables: { userId: user.id },
    });
  } catch (e) {
    return Flux.dispatchEvent(WIZARD_ERROR, e);
  }
  console.log('fetchAllianceInformation', response.data);
  return Flux.dispatchEvent(ALLIANCE_INFORMATION_EVENT, response.data);
};

/**
 *
 * @param {string} companyId - The Id of the Company.
 * @returns {Promise<boolean|*>} - The Last subscription of the company.
 */
export const fetchCompanySubscription = async (companyId) => {
  log('fetchCompanySubscription', companyId);
  const client = sessionStore.getState(APOLLO_CLIENT);
  const filter = { company: { id: { equals: companyId } } };

  let response;
  try {
    response = await client.query({
      query: COMPANY_LAST_SUBSCRIPTION_QUERY,
      variables: { filter },
    });
  } catch (e) {
    error('fetchCompanySubscription:', e);
    return Flux.dispatchEvent(ALLIANCE_ERROR_EVENT, e);
  }
  log('fetchCompanySubscription', response.data);
  return response.data;
};

/**
 * Returns the number of remaining alliances for the Logged in User.
 *
 * @returns {Promise<number>} - The number of Alliances that the user can create.
 */
export const fetchRemainingAlliancesOnPlan = async () => {
  const user = sessionStore.getState(NEW_SESSION_EVENT).user;
  const companyUserList = user.companyUserRelation;

  if (companyUserList.items.length === 0) return 0;
  const { company } = companyUserList.items[0];
  const response = await fetchCompanySubscription(company.id);
  log('fetchRemainingAlliancesOnPlan:response:', response);

  // No subscription
  if (response.companySubscriptionsList.items.length === 0) return 0;

  const subscription = response.companySubscriptionsList.items[0];

  // Inactive subscription
  if (subscription.status !== COMPANY_SUBSCRIPTION_ACTIVE) return 0;

  // Expired
  if (
    moment()
      .subtract(1, 'days')
      .isAfter(moment(subscription.expireDate, 'YYYY-MM-DD'))
  )
    return 0;

  const remainingAlliances =
    subscription.subscriptionPlan.allianceLimit -
    subscription.company.allianceClientCompanyRelation.count;
  log('fetchRemainingAlliancesOnPlan:remainingAlliances:', remainingAlliances);

  OnRemainingAlliances.dispatch(remainingAlliances);
  Flux.dispatchEvent(REMAINING_ALLIANCES_EVENT, remainingAlliances);
  return remainingAlliances;
};
