import Flux from '@cobuildlab/flux-state';

/**
 * Event that triggers a Investment Item error.
 *
 * @type {string}
 */
export const INVESTMENT_ITEM_ERROR_EVENT = 'onInvestmentItemError';

/**
 * Event that triggers a Investment Item List event.
 *
 * @type {string}
 */
export const INVESTMENT_ITEM_LIST_EVENT = 'onInvestmentItemList';

/**
 * Event that triggers a Investment All Item List event.
 *
 * @type {string}
 */
export const INVESTMENT_ALL_ITEM_LIST_EVENT = 'onAllInvestmentItemList';

/**
 * Event that triggers a Investment Item Delete event.
 *
 * @type {string}
 */
export const INVESTMENT_ITEM_DELETE_EVENT = 'onInvestmentItemDelete';

/**
 * Hold the Investment Item Data.
 */
class InvestmentItemStore extends Flux.DashStore {
  constructor() {
    super();
    this.addEvent(INVESTMENT_ITEM_ERROR_EVENT);
    this.addEvent(INVESTMENT_ITEM_LIST_EVENT);
    this.addEvent(INVESTMENT_ALL_ITEM_LIST_EVENT);
    this.addEvent(INVESTMENT_ITEM_DELETE_EVENT);
  }
}

export default new InvestmentItemStore();
