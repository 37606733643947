import React from 'react';
import PropTypes from 'prop-types';

/**
 * Check message icon.
 *
 * @param {boolean}success - Success.
 * @returns {*} Object.
 */
const CommentIcon = ({ success }) => {
  let content;
  if (success) {
    content = (
      <div className="comment-label">
        <img src="https://cdn.onlinewebfonts.com/svg/img_80415.png" alt="test" />
      </div>
    );
  } else {
    content = (
      <div className="comment-label">
        <img src="https://cdn.onlinewebfonts.com/svg/img_6083.png" alt="test" />
      </div>
    );
  }
  return content;
};

CommentIcon.propTypes = {
  success: PropTypes.bool.isRequired,
};

export default CommentIcon;
