import React from 'react';
import { NoData, Table } from '@8base/boost';
import PropTypes from 'prop-types';
import { InvoiceItem } from './InvoiceItem';

/**
 * Table grid columns.
 *
 * @type {string}
 */
const columns = '130px 1fr 155px 110px 215px';

/**
 * Invoices list for billing hgistory.
 *
 * @param {Array} props - The component props.
 * @param {Array} props.invoices - The list of invoices.
 * @param {Array} props.onRetry - Function to retry an invoice payment.
 * @returns {object} The component.
 */
const InvoicesList = ({ invoices, onRetry }) => {
  const content = invoices.length ? (
    <>
      {invoices.map((invoice, i) => (
        <InvoiceItem key={i} invoice={invoice} onRetry={onRetry} columns={columns} />
      ))}
    </>
  ) : (
    <NoData />
  );

  return (
    <Table>
      <Table.Header columns={columns}>
        <Table.HeaderCell justifyContent="start">Date</Table.HeaderCell>
        <Table.HeaderCell justifyContent="start">Plan</Table.HeaderCell>
        <Table.HeaderCell justifyContent="start">Status</Table.HeaderCell>
        <Table.HeaderCell justifyContent="end">Amount</Table.HeaderCell>
        <Table.HeaderCell justifyContent="center"></Table.HeaderCell>
      </Table.Header>
      <Table.Body>{content}</Table.Body>
    </Table>
  );
};

InvoicesList.propTypes = {
  invoices: PropTypes.array.isRequired,
  onRetry: PropTypes.func.isRequired,
};

export { InvoicesList };
