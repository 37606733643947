export const COLUMNSEXCEL = [
  { header: 'FUNDING_REQUEST_NAME', key: 'fundingRequestName', width: 32 },
  { header: 'FUNDING_REQUEST_DESCRIPTION', key: 'fundingRequestDescription', width: 32 },
  { header: 'FUNDING_REQUEST_STATUS', key: 'fundingRequestStatus', width: 32 },
  { header: 'FUNDING_REQUEST_REVISED_DUE_DATE', key: 'fundingRequestRevisedDueDate', width: 32 },
  { header: 'FUNDING_REQUEST_UNIT_QUANTITY', key: 'fundingRequestUnitQuantity', width: 32 },
  { header: 'FUNDING_REQUEST_UNIT_TYPE', key: 'fundingRequestUnitType', width: 32 },
  {
    header: 'FUNDING_REQUEST_UNIT_VALUE_DESCRIPTION',
    key: 'fundingRequestUnitValueDescription',
    width: 32,
  },
  {
    header: 'FUNDING_REQUEST_UNIT_MONETIZATION_FACTOR',
    key: 'fundingRequestUnitMonetizationFactor',
    width: 32,
  },
  { header: 'FUNDING_REQUEST_BUSINESS_CASE_STATUS', key: 'businessStatus', width: 32 },
  { header: 'FUNDING_REQUEST_CASE_BACKGROUND_SUMMARY', key: 'backgroundSummary', width: 32 },
  {
    header: 'FUNDING_REQUEST_BUSINESS_CASE_VISION_MISSION_STRATEGY',
    key: 'visionMissionStrategy',
    width: 32,
  },
  { header: 'FUNDING_REQUEST_BUSINESS_CASE_MARKET_ANALISYS', key: 'marketAnalysis', width: 32 },
  {
    header: 'FUNDING_REQUEST_BUSINESS_CASE_SALES_MARKETING_STRATEGY',
    key: 'salesMarketingStrategy',
    width: 32,
  },
  {
    header: 'FUNDING_REQUEST_BUSINESS_CASE_RECOMMENDED_SOLUTION',
    key: 'recommendedSolution',
    width: 32,
  },
  { header: 'FUNDING_REQUEST_BUSINESS_CASE_RISK', key: 'risks', width: 32 },
  { header: 'FUNDING_REQUEST_BUSINESS_ANTICIPATED_COST', key: 'anticipatedCosts', width: 32 },
  { header: 'FUNDING_REQUEST_BUSINESS_EXPECTED_COST', key: 'expectedCosts', width: 32 },
  { header: 'FUNDING_REQUEST_BUSINESS_EXPECTED_REVENUED', key: 'expectedRevenues', width: 32 },
  { header: 'CONTRIBUTION_NAME', key: 'contributionName', width: 32 },
  { header: 'CONTRIBUTION_DESCRIPTION', key: 'contributionDescription', width: 32 },
  { header: 'CONTRIBUTION_STATUS', key: 'contributionStatus', width: 32 },
  { header: 'CONTRIBUTION_ORIGINAL_DUE_DATE', key: 'contributionOriginalDueDate', width: 32 },
  { header: 'CONTRIBUTION_DATE', key: 'contributionDate', width: 32 },
  { header: 'CONTRIBUTION_UNIT_TYPE', key: 'contributionUnitType', width: 32 },
  { header: 'CONTRIBUTION_UNIT_QUANTITY', key: 'contributionUnitQuantity', width: 32 },
  {
    header: 'CONTRIBUTION_UNIT_VALUE_DESCRIPTION',
    key: 'contributionUnitValueDescription',
    width: 32,
  },
  {
    header: 'CONTRIBUTION_UNIT_MONETIZATION_FACTOR',
    key: 'contributionUnitMonetizationFactor',
    width: 32,
  },
];

export const COLUMNSOBJECT = [
  {
    key: 'contribution',
    value: [
      { contributionName: 'name' },
      { contributionDescription: 'description' },
      { contributionStatus: 'status' },
      { contributionOriginalDueDate: 'originalDueDate' },
      { contributionDate: 'contributionDate' },
      { contributionUnitQuantity: 'unitQuantity' },
      { contributionUnitType: 'unitType' },
      { contributionUnitValueDescription: 'unitValueDescription' },
      { contributionUnitMonetizationFactor: 'unitMonetizationFactor' },
    ],
  },
  {
    key: 'fundingRequest',
    value: [
      { fundingRequestName: 'name' },
      { fundingRequestDescription: 'description' },
      { fundingRequestStatus: 'status' },
      { fundingRequestRevisedDueDate: 'revisedDueDate' },
      { fundingRequestUnitType: 'unitType' },
      { fundingRequestUnitQuantity: 'unitQuantity' },
      { fundingRequestUnitValueDescription: 'unitValueDescription' },
      { fundingRequestUnitMonetizationFactor: 'unitMonetizationFactor' },
      {
        businessCase: {
          businessStatus: 'status',
          backgroundSummary: 'backgroundSummary',
          visionMissionStrategy: 'visionMissionStrategy',
          marketAnalysis: 'marketAnalysis',
          salesMarketingStrategy: 'salesMarketingStrategy',
          recommendedSolution: 'recommendedSolution',
          anticipatedCosts: 'anticipatedCosts',
          expectedCosts: 'expectedCosts',
          expectedRevenues: 'expectedRevenues',
          risks: 'risks',
        },
      },
    ],
  },
];
