import { ValidationError } from '../../../shared/errors';
import {
  isValidString,
  setErrorIfDateIsBeforeAllianceStart,
  isValidBigInt,
} from '../../../shared/validators';
import moment from 'moment';
import { nextStepsValidator } from '../../../modules/next-step/next-step-validators';
import { businessCaseValidator } from '../../document-management/business-case/business-case-validators';
import { initiativesItemValidator } from '../initiative/initiative-validators';

/**
 * Validate that the Item has all the fields necessaries.
 *
 * @param  {object} data - To be reviewed.
 * @param {object}allianceDetails - Alliance Details.
 */
export const fundingRequestValidator = (data, allianceDetails) => {
  let errorMessages = [];

  if (!isValidString(data.name)) errorMessages.push('Funding Request must have a valid Name');

  if (!isValidString(data.description))
    errorMessages.push('Funding Request must have a valid Description');

  if (Array.isArray(data.nextSteps) && data.nextSteps.length > 0) {
    try {
      nextStepsValidator(data.nextSteps);
    } catch (e) {
      errorMessages.push(e.message);
    }
  } else errorMessages.push('The Funding Request must have valid Next Steps');

  if (!data.requestedBy) errorMessages.push('Funding Request must have a valid Requested by User');

  if (!moment(data.requestedDate).isValid()) {
    errorMessages.push('Funding Request must have a valid Requested Date');
  } else {
    setErrorIfDateIsBeforeAllianceStart(
      data.requestedDate,
      allianceDetails,
      'Requested Date',
      errorMessages,
    );
  }

  if (!data.assignedTo) errorMessages.push('Funding Request must have a valid Assignee');

  if (!moment(data.assignedDate).isValid()) {
    errorMessages.push('Funding Request must have a valid Assigned Date');
  } else {
    setErrorIfDateIsBeforeAllianceStart(
      data.assignedDate,
      allianceDetails,
      'Assigned Date',
      errorMessages,
    );
  }

  if (!moment(data.originalDueDate).isValid()) {
    errorMessages.push('Funding Request must have a valid Original Due Date');
  } else {
    setErrorIfDateIsBeforeAllianceStart(
      data.originalDueDate,
      allianceDetails,
      'Original Due Date',
      errorMessages,
    );
  }

  if (moment(data.revisedDueDate).isValid()) {
    setErrorIfDateIsBeforeAllianceStart(
      data.revisedDueDate,
      allianceDetails,
      'Revised Due Date',
      errorMessages,
    );
  }

  if (!isValidString(data.unitType))
    errorMessages.push('Funding Request must have a valid Unit Type');

  if (!(parseFloat(data.unitQuantity) >= 0))
    errorMessages.push('Funding Request must have a valid Unit');

  if (!isValidString(data.unitValueDescription, true, true))
    errorMessages.push('Funding Request must have a valid unit Description');

  if (!isValidBigInt(data.unitMonetizationFactor))
    errorMessages.push('Funding Request must have a valid Unit Monetization Factor');

  if (!data.source) errorMessages.push('Funding Request must have a valid Source Company');
  if (errorMessages.length !== 0) throw new ValidationError(errorMessages);
};

export const validateFundingRequestData = (
  fundingRequestData,
  initiativesData,
  businessData,
  hasBusinessData = false,
  allianceDetails,
) => {
  fundingRequestValidator(fundingRequestData, allianceDetails);
  if (hasBusinessData) {
    businessCaseValidator(businessData);
  }
  initiativesItemValidator(initiativesData);
};
