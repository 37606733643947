import Flux from '@cobuildlab/flux-state';
import {
  COMPANY_INFORMATION_QUERY,
  CREATE_COMPANY_INFORMATION_MUTATION,
  UPDATE_COMPANY_INFORMATION_MUTATION,
} from './company.queries';
import {
  COMPANY_INFORMATION_CREATE_EVENT,
  COMPANY_INFORMATION_EVENT,
  COMPANY_INFORMATION_UPDATE_EVENT,
  WIZARD_ERROR,
} from './WizardStore';
import sessionStore, { APOLLO_CLIENT, NEW_SESSION_EVENT } from '../../shared/SessionStore';
import { createCompanyValidator } from '../settings/company-management/company.validators';
import { COMPANY_PORTFOLIO_OWNER } from '../../shared/roles';
import { IntegrityError } from '../../shared/errors';
import {
  normalize8baseDocumentCreate,
  normalize8baseDocumentDeleteAndUpdate,
  normalize8baseReferenceConnect,
} from '@cobuildlab/8base-utils';
import {
  OnCompanyInformation,
  OnWizardError,
  OnCompanyInformationCreate,
  OnCompanyInformationUpdate,
} from '../wizard/wizard-events';

/**
 * Creates a Company.
 *
 * @param {object} company - The Company object to be created.
 * @returns {Promise<boolean>} - The promise of the creation.
 */
export const createCompany = async (company) => {
  const client = sessionStore.getState(APOLLO_CLIENT);
  const user = sessionStore.getState(NEW_SESSION_EVENT).user;

  delete company.id;
  delete company.pictureUrl;

  company.annualRevenue = String(company.annualRevenue);
  company.companyUserRelation = {
    create: [
      { user: { connect: { id: user.id } }, role: { connect: { name: COMPANY_PORTFOLIO_OWNER } } },
    ],
  };

  try {
    createCompanyValidator(company);
  } catch (err) {
    console.error('CreateCompanyValidator', err);
    OnWizardError.dispatch(err);
    return Flux.dispatchEvent(WIZARD_ERROR, err);
  }

  normalize8baseReferenceConnect(company, 'country');
  normalize8baseReferenceConnect(company, 'industry');
  normalize8baseDocumentCreate(company, 'logoFile');

  let result;
  try {
    result = await client.mutate({
      mutation: CREATE_COMPANY_INFORMATION_MUTATION,
      variables: { data: company },
    });
  } catch (e) {
    console.error('createCompany', e);
    OnWizardError.dispatch(new IntegrityError(`An Error Occur trying to create the Company: ${e}`));

    return Flux.dispatchEvent(
      WIZARD_ERROR,
      new IntegrityError(`An Error Occur trying to create the Company: ${e}`),
    );
  }

  console.log('createCompany', result);

  OnCompanyInformationCreate.dispatch(result);
  return Flux.dispatchEvent(COMPANY_INFORMATION_CREATE_EVENT, result);
};

/**
 * Update Company Information.
 *
 * @param {object} companyInformation - The companyInformation.
 * @param {object} originalCompany - Original company.
 * @returns {Promise<object>} - Return company information updated.
 */
export const updateCompanyInformation = async (companyInformation, originalCompany) => {
  const client = sessionStore.getState(APOLLO_CLIENT);

  delete companyInformation.__typename;
  delete companyInformation.companyUserRelation;
  delete companyInformation.pictureUrl;

  companyInformation.annualRevenue = String(companyInformation.annualRevenue);

  console.log('updateCompanyInformation', companyInformation);
  normalize8baseReferenceConnect(companyInformation, 'country');
  normalize8baseReferenceConnect(companyInformation, 'industry');
  normalize8baseDocumentDeleteAndUpdate(companyInformation, 'logoFile', originalCompany);

  try {
    createCompanyValidator(companyInformation);
  } catch (err) {
    console.error('UpdateCompanyValidator', err);
    OnWizardError.dispatch(err);
    return Flux.dispatchEvent(WIZARD_ERROR, err);
  }

  let result;
  try {
    result = await client.mutate({
      mutation: UPDATE_COMPANY_INFORMATION_MUTATION,
      variables: { data: companyInformation },
    });
  } catch (e) {
    console.error('updateCompanyInformation', e);
    OnWizardError.dispatch(e);
    return Flux.dispatchEvent(WIZARD_ERROR, e);
  }

  console.log('updateCompanyInformation', result);

  OnCompanyInformationUpdate.dispatch(result);
  Flux.dispatchEvent(COMPANY_INFORMATION_UPDATE_EVENT, result);
  return result;
};

/**
 * Fetches all the CompanyUser objects related to the user logged in.
 *
 * @returns {Promise<object>} Company information.
 */
export const fetchCompanyInformation = async () => {
  const client = sessionStore.getState(APOLLO_CLIENT);
  const user = sessionStore.getState(NEW_SESSION_EVENT).user;
  let response;
  try {
    response = await client.query({
      query: COMPANY_INFORMATION_QUERY,
      variables: { userId: user.id },
      fetchPolicy: 'network-only',
    });
  } catch (e) {
    OnWizardError.dispatch(e);
    return Flux.dispatchEvent(WIZARD_ERROR, e);
  }
  console.log('fetchCompanyInformation', response.data);
  OnCompanyInformation.dispatch(response.data);
  Flux.dispatchEvent(COMPANY_INFORMATION_EVENT, response.data);
  return response.data;
};
