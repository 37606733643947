import React from 'react';
import PropTypes from 'prop-types';
import ApprovalDetailColumn from './ApprovalDetailColumn';
import { HorizontalLineText } from '../../text/HorizontalLineText';
import { Grid, Table } from '@8base/boost';
import { TableDetail } from '../../new-ui/table/TableDetail';
import { HorizontalLine } from '../../new-ui/text/HorizontalLine';
import { BoderDetailView } from '../../new-ui/div/BorderDetailView';
import { TablePosition } from '../../new-ui/div/TablePosition';
import { HeaderText } from '../../new-ui/text/HeaderText';

/**
 * Detail View Table For The Action Entity.
 */

const ApprovalDetailTable = (props) => {
  const { data: approvals, title } = props;

  let content = null;

  if (approvals.length) {
    content = (
      <>
        {approvals.map((approval, index) => (
          <ApprovalDetailColumn key={index} data={approval} />
        ))}
      </>
    );
  }

  return (
    <>
      <HorizontalLine>
        <HorizontalLineText>{title}</HorizontalLineText>
      </HorizontalLine>
      <>
        <BoderDetailView>
          <Grid.Layout columns="50% 50%" areas={[['left', 'right']]} style={{ width: '100%' }}>
            <Grid.Box area="left">
              <div style={{ position: 'absolute', top: '21px', left: '25px' }}>
                <HeaderText>{title}</HeaderText>
              </div>
            </Grid.Box>
          </Grid.Layout>
        </BoderDetailView>
        <TablePosition>
          <TableDetail>
            <Table.Header className="justify-center-column" columns="3fr 3fr 3fr 3fr">
              <Table.HeaderCell className="name-column">Company</Table.HeaderCell>
              <Table.HeaderCell>Status</Table.HeaderCell>
              <Table.HeaderCell>dateOfResponse</Table.HeaderCell>
              <Table.HeaderCell>approvedBy</Table.HeaderCell>
            </Table.Header>
            <tbody>{content}</tbody>
          </TableDetail>
        </TablePosition>
      </>
    </>
  );
};

ApprovalDetailTable.propTypes = {
  data: PropTypes.array.isRequired,
  title: PropTypes.string,
};

ApprovalDetailTable.defaultProps = {
  title: 'DETAILS OF THE APPROVALS',
};

export default ApprovalDetailTable;
