export const getInitiativeItemByType = (item) => {
  return {
    type: item.__typename,
    url: item.businessCase
      ? getUrlTypeBusinessCase(item.__typename)
      : getUrlTypeWithoutBusinessCase(item.__typename),
    id: item.id,
    name: item.name,
    status: item.status,
    createdAt: item.createdAt,
    revisedDueDate: item.revisedDueDate,
    originalDueDate: item.originalDueDate,
    owner: item.owner ? `${item.owner.firstName} ${item.owner.lastName}` : null,
    ownerObject: item.owner,
    nextSteps: item.nextSteps,
    approvalFunction: null,
    itemId: item.itemId.id,
    requestedBy: item.requestedBy,
  };
};

/**
 *
 * @param {string} type - Type of item.
 * @returns {string} - The url.
 */
export const getUrlTypeBusinessCase = (type) => {
  if (type === 'Action') {
    return 'action';
  }

  if (type === 'Contribution') {
    return 'contribution';
  }

  if (type === 'FundingRequest') {
    return 'funding-request';
  }
};

export const getUrlTypeWithoutBusinessCase = (type) => {
  if (type === 'Idea') {
    return 'idea';
  }

  if (type === 'Risk') {
    return 'risk';
  }

  if (type === 'Deal') {
    return 'deal';
  }

  if (type === 'Idea') {
    return 'idea';
  }

  if (type === 'Issue') {
    return 'issue';
  }

  if (type === 'Action') {
    return 'action-without-business-case';
  }

  if (type === 'Contribution') {
    return 'contribution';
  }
};
