import React from 'react';
import PropTypes from 'prop-types';
import { Paper, Text } from '@8base/boost';
import { CurrencyTextField } from '../../../../shared/components/CurrencyTextField';

/**
 * KPIChartToolTip: custom tooltip for the recharts library.
 *
 * @returns {Function} The rendered component.
 */
const KPIChartToolTip = ({ active, payload, currency, label }) => {
  if (active) {
    const actualValue = payload[0].value;
    const targetValue = payload[1].value;

    return (
      <Paper padding={'md'}>
        <Text>{label}</Text>
        <br />
        <Text color={'PRIMARY'}>
          {`Cumulative Actuals: `}{' '}
          {currency ? <CurrencyTextField currency={currency} value={actualValue} /> : actualValue}
        </Text>
        <br />
        <Text color={'GREEN'}>
          {`Cumulative Targets: `}{' '}
          {currency ? <CurrencyTextField currency={currency} value={targetValue} /> : targetValue}
        </Text>
      </Paper>
    );
  }

  return null;
};

KPIChartToolTip.defaultProps = {
  currency: null,
};

KPIChartToolTip.propTypes = {
  active: PropTypes.bool.isRequired,
  payload: PropTypes.array.isRequired,
  currency: PropTypes.object,
  label: PropTypes.string.isRequired,
};

export { KPIChartToolTip };
