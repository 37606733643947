import React from 'react';
import { Heading, Text } from '@8base/boost';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Moment from 'react-moment/dist/index';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome/index';
import { getDueDateColor } from '../../../shared/items-util';
import Status from '../../../components/Status';
import { formatUser } from '../../../shared/utils';

const StyledContainer = styled('div')`
  border: 1px solid #d0d7dd;
  width: 100%;
  position: relative;
  height: 90px;
  border-radius: 0.5rem;
  border-left: 8px solid ${(props) => props.color};
  margin: 5px 0;
  display: flex;

  & > .switchInitiative {
    right: 15px;
    top: 11px;
    */min-width: 48px;
    max-width: 48px;
    margin: 10px;
  }
`;

const StyledItemInfo = styled('div')`
  width: auto;
  padding: 10px;
`;

const StyledText = styled(Text)`
  display: block;
  text-align: left;
`;

const StyledHeading = styled(Heading)`
  text-align: left;
`;

const StyledButtonClose = styled('div')`
  position: absolute;
  top: 5px;
  right: 10px;
`;

const StyledStatus = styled('div')`
  position: absolute;
  bottom: 0;
  right: 10px;
`;

const RelatedItemRow = (props) => {
  const { data, onClose, index } = props;

  const { requestedBy, name, type, originalDueDate, status } = data;
  const originalDueDateText = originalDueDate ? (
    <Moment format="MMMM Do, YYYY">{originalDueDate}</Moment>
  ) : (
    <Text>Not yet due</Text>
  );

  let Container = StyledContainer;

  const color = getDueDateColor(originalDueDate);

  return (
    <Container color={color}>
      <StyledItemInfo>
        <StyledHeading type="h6">
          <Text weight={'bold'}>{type}</Text>
        </StyledHeading>
        <StyledText>{name}</StyledText>
        <StyledText disabled>{originalDueDateText}</StyledText>
        <StyledText weight={'semibold'}>{formatUser(requestedBy)}</StyledText>
        <StyledStatus>
          <Status status={status} />
        </StyledStatus>
        <StyledButtonClose
          style={{
            cursor: 'pointer',
            textAlign: 'center',
            verticalAlign: 'middle',
          }}
          onClick={() => {
            onClose(index);
          }}>
          <FontAwesomeIcon icon={'minus-circle'} />
        </StyledButtonClose>
      </StyledItemInfo>
    </Container>
  );
};

RelatedItemRow.propTypes = {
  data: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
};

export { RelatedItemRow };
