import React from 'react';
import { Dialog, Row, Column, Text } from '@8base/boost';
import PropTypes from 'prop-types';
import { ActionButton } from '../buttons/ActionButton';
import { H3 } from '../new-ui/font-style/H3.js';
import styled from '@emotion/styled';

const Circle = styled.div`
  box-sizing: border-box;
  height: 67px;
  width: 67px;
  border: 2px solid #aeb7be;
  border-radius: 50px;
`;

const SmallCircle = styled.div`
  margin-top: 28px;
  border-radius: 50px;
  height: 7.68px;
  width: 7.68px;
  background-color: #aeb7be;
`;

const ProcessingDialogBody = ({ note, text, onClose, buttonText }) => {
  return (
    <>
      <Dialog.Body padding="xl" scrollable>
        <Row alignItems="center" justifyContent="center">
          <Column alignItems="center">
            <Circle>
              <Row justifyContent="center" gap="xs">
                <SmallCircle />
                <SmallCircle />
                <SmallCircle />
              </Row>
            </Circle>
            <H3>{text}</H3>
            <Text align="center">{note}</Text>
          </Column>
        </Row>
      </Dialog.Body>
      <Dialog.Footer>
        <ActionButton disabled stretch text={buttonText} onClick={onClose} />
      </Dialog.Footer>
    </>
  );
};

ProcessingDialogBody.defaultProps = {
  text: 'Thank You! Your payment is processing',
  note: 'We will send you a copy of your invoice via email once your payment has been processed.',
  buttonText: 'Close',
};

ProcessingDialogBody.propTypes = {
  text: PropTypes.string,
  note: PropTypes.string,
  onClose: PropTypes.bool.isRequired,
  buttonText: PropTypes.string,
};

export { ProcessingDialogBody };
