import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { Text } from '@8base/boost';

const StyledOverline2 = styled(Text)`
  font-family: Poppins;
  font-weight: 600;
  font-size: 12px;
  letter-spacing: 0.5px;
  line-height: 16px;
  color: #7d828c;
  text-transform: uppercase;
`;

const Overline2 = ({ children }) => {
  return <StyledOverline2>{children}</StyledOverline2>;
};

Overline2.propTypes = {
  children: PropTypes.any.isRequired,
};

export { Overline2 };
