import React from 'react';
import { Row } from '@8base/boost';
import { Label } from './Label';
import { default as Tag } from 'react-tagsinput';
import PropTypes from 'prop-types';
import './tag-input.css';

const TagsInput = ({ onChange, tags, placeholder, label, ...rest }) => {
  const inputProps = {
    placeholder,
  };

  return (
    <>
      <Row alignItems="center">
        <Label text={label} />
      </Row>
      <Tag
        {...rest}
        style={{ height: '36px', width: '100%' }}
        value={tags}
        onChange={(array) => onChange([...new Set(array)])}
        inputProps={inputProps}
        addOnBlur={true}
      />
    </>
  );
};

TagsInput.defaultProps = {
  label: '',
  placeholder: '',
};

TagsInput.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  tags: PropTypes.array.isRequired,
};
export { TagsInput };
