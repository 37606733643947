import React from 'react';
import { CardFooter } from '../components/CardFooter';
import { Loader, Card, Button } from '@8base/boost';
import sessionStore, { NEW_SESSION_EVENT } from './../../../shared/SessionStore';
import PropTypes from 'prop-types';
import {
  updateCompanyInformation,
  fetchCompanyInformation,
  createCompany,
} from '../company-actions';
import { updateStateFromObject } from '../../../shared/utils';
import { View } from '@cobuildlab/react-simple-state';
import { COMPANY_PORTFOLIO_OWNER } from '../../../shared/roles';
import { onChangeMixin } from '../../../shared/mixins';
import CompanyForm from '../../settings/company-management/components/CompanyForm';
import CompanyModel from '../../settings/company-management/company.model';
import * as R from 'ramda';
import { ActionButton } from '../../../components/buttons/ActionButton';
import { onErrorMixin } from '../../../shared/mixins';
import { Button as Bold } from '../../../components/new-ui/font-style/Button';
import {
  OnWizardError,
  OnCompanyInformation,
  OnCompanyInformationCreate,
  OnCompanyInformationUpdate,
} from '../wizard-events';

class CompanyInformation extends View {
  constructor(props) {
    super(props);
    this.state = {
      originalCompany: R.clone(CompanyModel),
      data: R.clone(CompanyModel),
      countries: [],
      usaStates: [],
      industries: [],
      loading: true,
      isPortfolioOwner: false, // Whether if I'm admin of the current Company
    };

    this.onChange = onChangeMixin.bind(this);
    this.onError = onErrorMixin.bind(this);
  }

  componentDidMount() {
    this.user = sessionStore.getState(NEW_SESSION_EVENT).user;

    this.subscribe(OnWizardError, this.onError);

    this.subscribe(OnCompanyInformation, (state) => {
      const { companyUsersList, countriesList, statesList, industriesList } = state;

      let isPortfolioOwner = false;
      let data = R.clone(CompanyModel);

      if (companyUsersList.items.length > 0) {
        // The first company for the User is the one that he can edit
        const { company: firstCompanyForTheUser } = companyUsersList.items[0];
        const logoFile = firstCompanyForTheUser.logoFile;
        data = updateStateFromObject(this.state.data, firstCompanyForTheUser);
        data.logoFile = logoFile;
        // But, to figure it out if he is portfolio Owner, we need to loop to the full list
        // This is because a user can have more than one role for A Company
        companyUsersList.items.forEach((companyUser) => {
          // We only check roles in the first company
          if (companyUser.company.id === firstCompanyForTheUser.id) {
            if (companyUser.role.name === COMPANY_PORTFOLIO_OWNER) isPortfolioOwner = true;
          }
        });
      }

      this.setState({
        originalCompany: R.clone(data),
        data,
        isPortfolioOwner,
        countries: countriesList.items,
        usaStates: statesList.items,
        industries: industriesList.items,
        loading: false,
      });
    });

    this.subscribe(OnCompanyInformationUpdate, () => {
      this.props.nextScreen();
    });

    this.subscribe(OnCompanyInformationCreate, () => {
      this.props.nextScreen();
    });

    fetchCompanyInformation();
  }

  handleSubmit = () => {
    const { isPortfolioOwner, loading, data: stateData, originalCompany } = this.state;

    if (loading) return;

    const hasACompany = this.state.data.id !== null;

    // If he is not admin, he shouldn't have to be validated
    if (hasACompany && !isPortfolioOwner) {
      return this.setState(
        {
          loading: true,
        },
        () => this.props.nextScreen(),
      );
    }

    const data = R.clone(stateData);
    const originalData = R.clone(originalCompany);
    if (data.companyStatus === 'private') delete data.tickerSymbol;

    this.setState(
      {
        loading: true,
      },
      () => {
        if (data.id === null) {
          delete data.id;
          createCompany(data);
        } else {
          updateCompanyInformation(data, originalData);
        }
      },
    );
  };

  render() {
    const { prevScreen } = this.props;
    const { loading, isPortfolioOwner } = this.state;
    if (loading === true) {
      return <Loader stretch />;
    }

    const footerComponent = (
      <CardFooter>
        <ActionButton
          onClick={(e) => this.handleSubmit()}
          text="Continue"
          className={'space-around'}
        />
        <Button
          type="button"
          onClick={(e) => prevScreen()}
          color="neutral"
          variant="outlined"
          className={'space-around'}>
          {'Previous'}
        </Button>
      </CardFooter>
    );
    const hasACompany = this.state.data.id !== null;

    return (
      <React.Fragment>
        <Card.Body style={{ paddingRight: '35px' }}>
          {hasACompany && !isPortfolioOwner && (
            <>
              Welcome, as <Bold>Company Administrator</Bold> you are not able to edit the
              information of this Company.
              <br />
              <br />
              If you think that this information should be updated, please contact the
              Company&rsquo;s <Bold>Portfolio Owner.</Bold>
            </>
          )}
          <CompanyForm
            isDisabled={hasACompany && !isPortfolioOwner}
            data={this.state.data}
            onChange={this.onChange}
            countries={this.state.countries}
            usaStates={this.state.usaStates}
            industries={this.state.industries}
          />
        </Card.Body>
        {footerComponent}
      </React.Fragment>
    );
  }
}

CompanyInformation.propTypes = {
  currentScreen: PropTypes.number.isRequired,
  nextScreen: PropTypes.func.isRequired,
  prevScreen: PropTypes.func.isRequired,
};

export default CompanyInformation;
