import React from 'react';
import { Text } from '@8base/boost';
import PropTypes from 'prop-types';
import { CurrencyTextField } from '../../../shared/components/CurrencyTextField';

const PlanPrice = ({ plan, showAnnualPrice }) => {
  const { monthlyPrice, annualPrice } = plan;
  const price = showAnnualPrice ? annualPrice : monthlyPrice;
  let priceSufix = showAnnualPrice ? ' / year.' : ' / mo.';
  if (plan.name === 'Daily Plan') priceSufix = ' / day.';

  const content = price ? (
    <Text weight="bold">
      <CurrencyTextField value={price} textWeight="bold" />
      <Text weight="normal">{priceSufix}</Text>
    </Text>
  ) : (
    <Text weight="bold">{'Free'}</Text>
  );

  return <>{content}</>;
};

PlanPrice.propTypes = {
  plan: PropTypes.object.isRequired,
  showAnnualPrice: PropTypes.bool.isRequired,
};

export { PlanPrice };
