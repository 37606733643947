import React from 'react';
import PropTypes from 'prop-types';
import DetailValue from '../../../../components/DetailValue';
import DetailDateValue from '../../../../components/DetailDateValue';
import Status from '../../../../components/Status';
import UserDetailValue from '../../../../components/UserDetailValue';
import DocumentsFileComponent from '../../../../components/inputs/DocumentsFileComponent';
import { INITIATIVE_DOCUMENTS, INITIATIVE_CURRENT_DASHBOARD_DOCUMENTS } from '../initiative-model';
import { HorizontalLineText } from '../../../../components/text/HorizontalLineText';
import { Grid } from '@8base/boost';
import { TableDetail } from '../../../../components/new-ui/table/TableDetail';
import { BoderDetailView } from '../../../../components/new-ui/div/BorderDetailView';
import { TablePosition } from '../../../../components/new-ui/div/TablePosition';
import { HorizontalLine } from '../../../../components/new-ui/text/HorizontalLine';
import { EditButton } from '../../../../components/new-ui/buttons/EditButton';
import { HeaderText } from '../../../../components/new-ui/text/HeaderText';
import { canEditInitiative } from '../initiative-permissions';
import sessionStore, { NEW_SESSION_EVENT } from '../../../../shared/SessionStore';
import { ThTitlePosition } from '../../../../components/new-ui/div/ThTitlePosition';

/**
 * Detail View Table For The Initiative Entity.
 */

class InitiativeDetailTable extends React.Component {
  render() {
    const { data, onClickEdit, alliance } = this.props;
    const {
      name,
      status,
      description,
      createdAt,
      documentsFile,
      currentDashboardFile,
      baselineStartDate,
      baselineEndDate,
      forecastedEndDate,
      owner,
      requestedBy,
      requestedDate,
      ragStatus,
    } = data;
    this.user = sessionStore.getState(NEW_SESSION_EVENT).user;
    return (
      <>
        <HorizontalLine>
          <HorizontalLineText>INITIATIVE</HorizontalLineText>
        </HorizontalLine>
        <>
          <BoderDetailView>
            <Grid.Layout
              columns="auto 100px"
              areas={[['left', 'right']]}
              style={{ width: '100%', height: '100%' }}>
              <Grid.Box area="left">
                <div style={{ position: 'absolute', top: '21px', left: '25px' }}>
                  <HeaderText>INITIATIVE</HeaderText>
                </div>
              </Grid.Box>
              <Grid.Box area="right" justifyContent={'center'}>
                {canEditInitiative(this.user, data, alliance) ? (
                  <EditButton onClick={onClickEdit} text="Edit" />
                ) : null}
              </Grid.Box>
            </Grid.Layout>
          </BoderDetailView>
          <TablePosition>
            <TableDetail>
              <tbody>
                <tr>
                  <th>
                    <ThTitlePosition>
                      <span>STATE</span>
                    </ThTitlePosition>
                  </th>
                  <td className="spacing-td">
                    <span>{status}</span>
                  </td>
                </tr>
                <tr>
                  <th>
                    <ThTitlePosition>
                      <span>NAME</span>
                    </ThTitlePosition>
                  </th>
                  <td>
                    <DetailValue text={name} />
                  </td>
                </tr>
                <tr>
                  <th>
                    <ThTitlePosition>
                      <span>DESCRIPTION</span>
                    </ThTitlePosition>
                  </th>
                  <td>
                    <DetailValue text={description} />
                  </td>
                </tr>
                <tr>
                  <th>
                    <ThTitlePosition>
                      <span>OWNER</span>
                    </ThTitlePosition>
                  </th>
                  <td>
                    <UserDetailValue user={owner} />
                  </td>
                </tr>
                <tr>
                  <th>
                    <ThTitlePosition>
                      <span>DOCUMENTS</span>
                    </ThTitlePosition>
                  </th>
                  <td>
                    <DocumentsFileComponent data={documentsFile} localKey={INITIATIVE_DOCUMENTS} />
                  </td>
                </tr>
                <tr>
                  <th>
                    <ThTitlePosition>
                      <span>STATUS REPORT FILE</span>
                    </ThTitlePosition>
                  </th>
                  <td>
                    <DocumentsFileComponent
                      data={currentDashboardFile}
                      localKey={INITIATIVE_CURRENT_DASHBOARD_DOCUMENTS}
                    />
                  </td>
                </tr>
                <tr>
                  <th>
                    <ThTitlePosition>
                      <span>REQUESTED DATE</span>
                    </ThTitlePosition>
                  </th>
                  <td>
                    <DetailDateValue date={requestedDate} />
                  </td>
                </tr>
                <tr>
                  <th>
                    <ThTitlePosition>
                      <span>REQUESTED BY</span>
                    </ThTitlePosition>
                  </th>
                  <td>
                    <UserDetailValue user={requestedBy} />
                  </td>
                </tr>
                <tr>
                  <th>
                    <ThTitlePosition>
                      <span>RAG STATUS</span>
                    </ThTitlePosition>
                  </th>
                  <td>
                    <Status status={ragStatus} />
                  </td>
                </tr>
                <th>
                  <ThTitlePosition>
                    <span>BASELINE START DATE</span>
                  </ThTitlePosition>
                </th>
                <td>
                  <DetailDateValue date={baselineStartDate} />
                </td>
                <tr>
                  <th>
                    <ThTitlePosition>
                      <span>BASELINE END DATE</span>
                    </ThTitlePosition>
                  </th>
                  <td>
                    <DetailDateValue date={baselineEndDate} />
                  </td>
                </tr>
                <tr>
                  <th>
                    <ThTitlePosition>
                      <span>FORECASTED END DATE</span>
                    </ThTitlePosition>
                  </th>
                  <td>
                    <DetailDateValue date={forecastedEndDate} />
                  </td>
                </tr>
                <tr>
                  <th>
                    <ThTitlePosition>
                      <span>CREATED AT</span>
                    </ThTitlePosition>
                  </th>
                  <td>
                    <DetailDateValue date={createdAt} />
                  </td>
                </tr>
              </tbody>
            </TableDetail>
          </TablePosition>
        </>
      </>
    );
  }
}

InitiativeDetailTable.defaultProps = {
  onClickEdit: null, // null for form previews
};

InitiativeDetailTable.propTypes = {
  data: PropTypes.object.isRequired,
  alliance: PropTypes.object.isRequired,
  onClickEdit: PropTypes.func,
};

export default InitiativeDetailTable;
