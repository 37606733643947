import React from 'react';
import { Grid, Column, Row } from '@8base/boost';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import './forms.css';
import { H4 } from './font-style/H4';

const LeftSection = styled(Grid.Box)({
  textAlign: 'center',
  padding: '35px 55px 59px 21px',
});

const ProgressIcon = (props) => <div className={`progress-icon ${props.type}`} alt="checkmark" />;

const renderProgressIcons = (currentScreen, sections) => {
  return sections.map((section, i) => {
    if (i < currentScreen) {
      return <ProgressIcon key={`icon${i}`} type="checked" />;
    }
    if ((currentScreen === i) & (i === sections.length - 1)) {
      return <ProgressIcon key={`icon${i}`} type="active last" />;
    }
    if (currentScreen === i) {
      return <ProgressIcon key={`icon${i}`} type="active" />;
    }
    if (i === sections.length - 1) {
      return <ProgressIcon key={`icon${i}`} type="last" />;
    }
    return <ProgressIcon key={`icon${i}`} />;
  });
};

const ProgressSectionName = (props) => (
  <div
    className={`progress-sec-name ${props.isActive} sizeProgress`}
    style={{ pointerEvents: 'none' }}>
    <H4>{props.sectionName}</H4>
  </div>
);

const renderSectionNames = (currentScreen, sections) => {
  return sections.map((section, i) => {
    if (i <= currentScreen) {
      return (
        <ProgressSectionName
          isActive="active"
          sectionName={section.progressName}
          screenNumber={i}
          key={`section${i}`}
        />
      );
    } else {
      return (
        <ProgressSectionName
          isActive=""
          sectionName={section.progressName}
          screenNumber={i}
          key={`section${i}`}
        />
      );
    }
  });
};

const LeftProgressSection = (props) => {
  const { currentScreen, sections } = props;

  return (
    <LeftSection>
      <Row>
        <Column>{renderProgressIcons(currentScreen, sections)}</Column>
        <Column>{renderSectionNames(currentScreen, sections)}</Column>
      </Row>
    </LeftSection>
  );
};

ProgressSectionName.propTypes = {
  isActive: PropTypes.string.isRequired,
  sectionName: PropTypes.string.isRequired,
};

ProgressIcon.propTypes = {
  type: PropTypes.string.isRequired,
};

LeftProgressSection.propTypes = {
  currentScreen: PropTypes.number.isRequired,
  sections: PropTypes.array.isRequired,
};

export { LeftProgressSection };
