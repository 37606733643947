import Flux from '@cobuildlab/flux-state';

/**
 * Event that triggers the creation of an Action.
 *
 * @type {string}
 */
export const ACTION_CREATE_EVENT = 'onCreateAction';

/**
 * Event that triggers an Action error.
 *
 * @type {string}
 */
export const ACTION_ERROR_EVENT = 'onActionError';

/**
 * Event that triggers an Action List fetched.
 *
 * @type {string}
 */
export const ACTION_LIST_EVENT = 'onActionList';

/**
 * Event that triggers an Action Update event.
 *
 * @type {string}
 */
export const ACTION_UPDATE_EVENT = 'onActionUpdate';

/**
 * Event that triggers an Action REJECT event.
 *
 * @type {string}
 */
export const ACTION_REJECT_EVENT = 'onActionReject';

/**
 * Event that triggers an Action by id if fetched.
 *
 * @type {string}
 */
export const ACTION_DETAIL_EVENT = 'onActionDetail';

/**
 * Event that triggers an Action by id if fetched.
 *
 * @type {string}
 */
export const ACTION_COMPLETE_EVENT = 'onActionCompleted';

/**
 * Event that triggers an Action Restored Event.
 *
 * @type {string}
 */
export const ACTION_RESTORE_EVENT = 'onActionRestored';
/**
 * Event that triggers an Action submit for approval.
 *
 * @type {string}
 */
export const ACTION_SUBMIT_FOR_APPROVAL_EVENT = 'onActionSubmitForApproval';

export const ACTION_APPROVAL_LIST_EVENT = 'onActionApprovalList';
export const ACTION_AUTO_SAVE_EVENT = 'ACTION_AUTO_SAVE_EVENT';
export const ACTION_AUTO_SAVE_ERROR_EVENT = 'ACTION_AUTO_SAVE_ERROR_EVENT';

/**
 * Event that is trigger when an action is deleted with business case.
 *
 * @type {string}
 */
export const ACTION_BUSINESS_CASE_DELETE_EVENT = 'onActionBusinessCaseDelete';

/**
 * Event is called when delete action with business case gives error.
 *
 * @type {string}
 */
export const ACTION_BUSINESS_CASE_DELETE_ERROR_EVENT = 'onActionBusinessCaseDeleteError';

/**
 * Hold the Action Data.
 */
class ActionStore extends Flux.DashStore {
  constructor() {
    super();
    this.addEvent(ACTION_CREATE_EVENT);
    this.addEvent(ACTION_ERROR_EVENT);
    this.addEvent(ACTION_LIST_EVENT);
    this.addEvent(ACTION_UPDATE_EVENT);
    this.addEvent(ACTION_REJECT_EVENT);
    this.addEvent(ACTION_DETAIL_EVENT);
    this.addEvent(ACTION_COMPLETE_EVENT);
    this.addEvent(ACTION_SUBMIT_FOR_APPROVAL_EVENT);
    this.addEvent(ACTION_RESTORE_EVENT);
    this.addEvent(ACTION_APPROVAL_LIST_EVENT);
    this.addEvent(ACTION_AUTO_SAVE_EVENT);
    this.addEvent(ACTION_AUTO_SAVE_ERROR_EVENT);
    this.addEvent(ACTION_BUSINESS_CASE_DELETE_EVENT);
    this.addEvent(ACTION_BUSINESS_CASE_DELETE_ERROR_EVENT);
  }
}

export default new ActionStore();
