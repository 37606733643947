import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';

const StyledThTitlePosition = styled('div')`
  margin-left: 24px;
  margin-right: 5px;
`;

const ThTitlePosition = ({ children }) => {
  return <StyledThTitlePosition>{children}</StyledThTitlePosition>;
};

ThTitlePosition.propTypes = {
  children: PropTypes.any.isRequired,
};

export { ThTitlePosition };
