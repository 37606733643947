import React from 'react';
import { Table, Dropdown, Icon, Menu, Grid } from '@8base/boost';
import PropTypes from 'prop-types';
import H3 from '../../../components/H3';
import { DropdownBodyOnTable } from '../../../components/dropdown/DropdownBodyOnTable';
import NextStepFormDialog from './NextStepFormDialog';
import { nextStepValidator } from '../next-step-validators';
import * as toast from '../../../components/toast/Toast';
import Moment from 'react-moment';
import { truncateText } from '../../../shared/utils';
import * as R from 'ramda';
import PlusButton from '../../../components/PlusButton';
import { NEXT_STEP_OPEN } from '../../../shared/status';

class NextStepForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      nextStepDialogOpen: false,
      nextStepPosition: -1,
      nextStepData: {},
    };
  }

  onAcceptNextStep = () => {
    const { nextStepData, nextStepPosition } = this.state;
    try {
      nextStepValidator(nextStepData);
    } catch (e) {
      return toast.errors(e.arr);
    }

    const pos = nextStepPosition;
    const currentNextSteps = this.props.nextSteps;
    if (pos === -1) {
      // A new one
      currentNextSteps.push(nextStepData);
    } else {
      currentNextSteps[pos] = R.clone(nextStepData);
    }
    this.props.onChange('nextSteps', currentNextSteps);
    this.setState({ nextStepDialogOpen: false });
  };

  onCancelNextStep = () => {
    this.setState({
      nextStepDialogOpen: false,
      nextStepPosition: -1,
      nextStepData: {},
    });
  };

  onChangeNextStep = (field, value) => {
    const { nextStepData } = this.state;
    nextStepData[field] = value;
    nextStepData.assignedTo = this.props.assignedTo;
    this.setState({ nextStepData });
  };

  onOpenNextStepDialog = () => {
    this.setState({
      nextStepDialogOpen: true,
      nextStepPosition: -1,
      nextStepData: { status: NEXT_STEP_OPEN },
    });
  };

  onEditNextStepDialog = (pos) => {
    this.setState({
      nextStepDialogOpen: true,
      nextStepPosition: pos,
      nextStepData: R.clone(this.props.nextSteps[pos]),
    });
  };

  onDeleteNextStepDialog = (pos) => {
    const currentNextSteps = this.props.nextSteps;
    currentNextSteps.splice(pos, 1);
    this.props.onChange('nextSteps', currentNextSteps);
  };

  render() {
    const { nextStepData, nextStepDialogOpen } = this.state;
    const { nextSteps } = this.props;
    return (
      <>
        <Grid.Box alignSelf={'center'}>
          <H3 text={'Next Steps'} />
        </Grid.Box>
        <Table>
          <Table.Body style={{ textAlign: 'left' }} data={nextSteps}>
            {(nextStep, i) => (
              <Table.BodyRow columns="2fr 1fr" key={i}>
                <Table.BodyCell style={{ overflow: 'hidden' }}>
                  {truncateText(nextStep.description)}
                </Table.BodyCell>
                <Table.BodyCell>
                  <Moment format="MM/DD/YYYY">{nextStep.dueDate}</Moment>
                  <Dropdown defaultOpen={false}>
                    <Dropdown.Head>
                      <Icon name="More" className="more-icon" />
                    </Dropdown.Head>
                    <DropdownBodyOnTable>
                      {({ closeDropdown }) => (
                        <Menu>
                          <Menu.Item
                            onClick={() => {
                              this.onEditNextStepDialog(i);
                              closeDropdown();
                            }}>
                            Edit
                          </Menu.Item>
                          <Menu.Item
                            onClick={() => {
                              this.onDeleteNextStepDialog(i);
                              closeDropdown();
                            }}>
                            Delete
                          </Menu.Item>
                        </Menu>
                      )}
                    </DropdownBodyOnTable>
                  </Dropdown>
                </Table.BodyCell>
              </Table.BodyRow>
            )}
          </Table.Body>
          <Table.Footer justifyContent="center">
            <PlusButton text={'Add Next Step'} onClick={this.onOpenNextStepDialog} />
          </Table.Footer>
        </Table>

        <NextStepFormDialog
          onAccept={this.onAcceptNextStep}
          onCancel={this.onCancelNextStep}
          onChange={this.onChangeNextStep}
          data={nextStepData}
          isOpen={nextStepDialogOpen}
        />
      </>
    );
  }
}

NextStepForm.propTypes = {
  onChange: PropTypes.func.isRequired,
  nextSteps: PropTypes.array.isRequired,
  assignedTo: PropTypes.object.isRequired,
};

export default NextStepForm;
