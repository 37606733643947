import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { Text } from '@8base/boost';

const StyledSmall1 = styled(Text)`
  font-family: Poppins;
  font-weight: 500;
  font-size: 10px;
  color: #a3afb7;
  letter-spacing: 0.2px;
  line-height: 16px;
  & first-letter {
    text-transform: capitalize;
  }
`;

const Small1 = ({ children }) => {
  return <StyledSmall1>{children}</StyledSmall1>;
};

Small1.propTypes = {
  children: PropTypes.any.isRequired,
};

export { Small1 };
