import React from 'react';
import PropTypes from 'prop-types';
import { Grid, SecondaryNavigation } from '@8base/boost';
import { Route, Switch, Redirect } from 'react-router-dom';
import { LinkItem } from '../../components/link/LinkItem';
import LinkTitle from '../../components/link/LinkTitle';
import { ProcurerBillingView } from './ProcurerBillingView';

const ProcurerView = (props) => {
  const { location } = props;

  return (
    <Grid.Layout className="minHeightCard" columns="215px 1fr">
      <Grid.Box>
        <SecondaryNavigation>
          <LinkTitle text="Billing Settings" />
          <LinkItem
            to={'/procurer/billing'}
            label={'Subscriptions'}
            location={location}
            url={'billing'}
          />
        </SecondaryNavigation>
      </Grid.Box>
      <Grid.Box>
        <Switch>
          <Route path="/procurer/billing" component={ProcurerBillingView} />
          <Redirect to="/procurer/billing" />
        </Switch>
      </Grid.Box>
    </Grid.Layout>
  );
};

ProcurerView.propTypes = {
  location: PropTypes.any.isRequired,
};

export { ProcurerView };
