import React from 'react';
import PropTypes from 'prop-types';
import { Text, Avatar, Row } from '@8base/boost';
import * as R from 'ramda';
import styled from 'styled-components';
import { formatUser } from '../../shared/utils';

const TextSmaller = styled(Text)`
  font-size: 95%;
`;

const UserSmallAvatar = ({
  owner,
  text,
  src = R.path(['avatar', 'downloadUrl'], owner),
  size = 'xs',
}) => {
  const username = owner ? formatUser(owner) : '';

  return (
    <Row alignItems="center">
      <Avatar
        size={size}
        src={src}
        firstName={R.path(['firstName'], owner)}
        lastName={R.path(['lastName'], owner)}
      />
      <TextSmaller weight="bold">{username}</TextSmaller>
      <TextSmaller>{text}</TextSmaller>
    </Row>
  );
};

UserSmallAvatar.propTypes = {
  owner: PropTypes.object.isRequired,
  text: PropTypes.string,
  src: PropTypes.string.isRequired,
  size: PropTypes.string.isRequired,
};

UserSmallAvatar.defaultProps = {
  text: 'Is the owner of this item',
};

export default UserSmallAvatar;
