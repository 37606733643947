import React from 'react';
import { Text } from '@8base/boost';
import PropTypes from 'prop-types';
import CommentIcon from '../../../components/CommentIcon';

const CommentText = ({ text, commentOwner, success }) => (
  <div className={!commentOwner ? 'CommentOwnerText' : ''}>
    <div className={'CommentText'}>
      <Text>{text}</Text>
      <CommentIcon success={success} />
    </div>
  </div>
);

CommentText.propTypes = {
  text: PropTypes.string.isRequired,
  commentOwner: PropTypes.bool.isRequired,
  success: PropTypes.bool.isRequired,
};

export { CommentText };
