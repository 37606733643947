import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from '@emotion/styled';
import { faQuestion } from '@fortawesome/free-solid-svg-icons';

/**
 * Delete icon button for form removable inputs.
 */
const IconContainer = styled('div')`
  height: 100%;
  display: grid;
  align-items: center;
`;

const QuestionMarkIcon = ({ onClick }) => {
  return (
    <IconContainer className="clickable-label" onClick={onClick}>
      <div className="clickable-icon">
        <FontAwesomeIcon fontSize={14} icon={faQuestion} />
      </div>
    </IconContainer>
  );
};

QuestionMarkIcon.defaultProps = {
  onClick: () => {},
};

QuestionMarkIcon.propTypes = {
  onClick: PropTypes.func,
};

export default QuestionMarkIcon;
