import gql from 'graphql-tag';

export const PAYMENT_UPDATE_MUTATION = gql`
  mutation($data: CompanySubscriptionUpdateInput!) {
    companySubscriptionUpdate(data: $data) {
      id
    }
  }
`;

export const STRIPE_PAYMENT_DATA_QUERY = gql`
  query STRIPE_PAYMENT_DATA_QUERY($invoice: String!) {
    stripePaymentData(invoice: $invoice) {
      client_secret
      payment_method
      payment_intent_status
    }
  }
`;
