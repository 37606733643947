import React from 'react';
import { Bar, BarChart, Tooltip, XAxis, YAxis, CartesianGrid } from 'recharts';
import { ForecastChartTooltip } from './components';
import { Card, Row, Column, Loader, Heading, Grid, SelectField } from '@8base/boost';
import { View } from '@cobuildlab/react-simple-state';
import { ForecastReportCard } from './components/ForecastReportCard';
import moment from 'moment';
import { numberFormatter } from '../utils';
import sessionStore, { NEW_SESSION_EVENT } from '../../../shared/SessionStore';
import { onErrorMixin } from '../../../shared/mixins';
import { getAllianceForecastingData } from './forecasting-utils';
import { fetchForecastingData } from './forecasting-actions';
import { getCurrencyOnSession } from '../../../shared/alliance-utils';
import { showMonths } from '../../../shared/utils';
import { ForecastReportChartLegendItem } from './components/ForecastReportChartLegendItem';
import { ReportHeader } from '../components';
import { getCompanyOptions } from './forecasting-utils';
import * as R from 'ramda';
import BigInt from 'big-integer';
import { OnForecastingError, OnForecastingData } from './forecasting-events';

class ForecastingReportViewContributions extends View {
  constructor(props) {
    super(props);

    this.state = {
      alliance: null,
      selectedForecastYear: moment().year(),
      forecastYears: [],
      reportForecasting: null,
      loading: true,
      selectedCompany: null,
    };

    this.selectedAlliance = sessionStore.getState(NEW_SESSION_EVENT).selectedAlliance;
    this.onError = onErrorMixin.bind(this);
  }

  componentDidMount() {
    this.subscribe(OnForecastingError, this.onError);
    this.subscribe(OnForecastingData, async (state) => {
      const year = R.clone(this.state.selectedForecastYear);
      const allianceData = R.clone(state.alliance);

      const kpis = state.kpis.map((kpi) => ({
        ...kpi,
      }));

      const contributions = state.contributions.map((contribution) => ({
        ...contribution,
      }));

      const deals = state.deals.map((deal) => ({
        ...deal,
      }));

      const { reportForecasting, forecastYears } = await getAllianceForecastingData(
        kpis,
        contributions,
        deals,
        allianceData,
        year,
      );

      this.setState({ reportForecasting, forecastYears, loading: false, allianceData });
    });

    fetchForecastingData(R.clone(this.state.selectedForecastYear));
  }

  onSelectedCompany = (company) => {
    this.setState({
      selectedCompany: company,
    });
  };

  onSelectedYearChange = (year) => {
    this.setState({ selectedForecastYear: year, loading: true }, () => {
      fetchForecastingData(R.clone(this.state.selectedForecastYear));
    });
  };

  render() {
    const {
      loading,
      selectedCompany,
      reportForecasting,
      selectedForecastYear,
      forecastYears,
    } = this.state;
    let content = <Loader stretch />;
    let contentCard = '';
    let contentChartLegend = '';
    let contentBarChart = '';
    const currency = getCurrencyOnSession();
    const companyOptions = getCompanyOptions();

    if (!loading && reportForecasting) {
      const {
        // monthByMonth
        clientContributionsBudget,
        partnerContributionsBudget,
        clientContributionsActuals,
        partnerContributionsActuals,
        clientContributionsForecast,
        partnerContributionsForecast,
        // total
        totalClientContributionsBudget,
        totalPartnerContributionsBudget,
        totalClientContributionsActuals,
        totalPartnerContributionsActuals,
        totalClientContributionsForecast,
        totalPartnerContributionsForecast,
        clientContributionsVariance,
        partnerContributionsVariance,
        bothCompaniesContributionsVariance,
        bothCompaniesContributionsBudget,
        bothCompaniesContributionsActuals,
        bothCompaniesContributionsForecast,
      } = reportForecasting;

      const data = Array.from({ length: 12 }, (v, i) => {
        if (!selectedCompany) {
          return {
            month: showMonths(i),
            budget: BigInt(clientContributionsBudget[i])
              .add(partnerContributionsBudget[i])
              .toString(),
            totalContributions: BigInt(clientContributionsActuals[i])
              .add(partnerContributionsActuals[i])
              .toString(),
            totalContributionsForecasting: BigInt(clientContributionsForecast[i])
              .add(partnerContributionsForecast[i])
              .toString(),
          };
        }

        if (selectedCompany === 'clientCompany') {
          return {
            month: showMonths(i),
            budget: clientContributionsBudget[i],
            clientContributions: clientContributionsActuals[i],
            clientContributionsForecasting: clientContributionsForecast[i],
          };
        }

        if (selectedCompany === 'partnerCompany') {
          return {
            month: showMonths(i),
            budget: partnerContributionsBudget[i],
            partnerContributions: partnerContributionsActuals[i],
            partnerContributionsForecasting: partnerContributionsForecast[i],
          };
        }

        return {};
      });

      if (!selectedCompany) {
        contentCard = (
          <>
            <ForecastReportCard
              header={'TOTAL - BUDGET'}
              amount={bothCompaniesContributionsBudget}
              currency={currency}
            />
            <ForecastReportCard
              header={'TOTAL - YTD ACTUAL'}
              amount={String(bothCompaniesContributionsActuals)}
              currency={currency}
            />

            <ForecastReportCard
              currency={currency}
              header={'TOTAL - FORECAST'}
              amount={bothCompaniesContributionsForecast}
            />
            <ForecastReportCard
              currency={currency}
              header={'VARIANCE'}
              amount={bothCompaniesContributionsVariance}
              isVariance={true}
            />
          </>
        );

        contentChartLegend = (
          <>
            <ForecastReportChartLegendItem name={'Budget'} />
            <ForecastReportChartLegendItem name={'Total Actuals'} color={'#70D34C'} />
            <ForecastReportChartLegendItem name={'Total Forecast'} color={'#0096B8'} />
          </>
        );

        contentBarChart = (
          <>
            <BarChart width={800} height={550} data={data}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="month" />
              <YAxis
                tickFormatter={(value) =>
                  currency ? `${currency.symbol} ${numberFormatter(value, 1)}` : value
                }
              />
              <Tooltip content={<ForecastChartTooltip currency={currency} />} />
              <Bar dataKey="budget" fill="#2BA1FF" barSize={36} />
              <Bar dataKey="totalContributions" fill="#70D34C" barSize={36} />
              <Bar dataKey="totalContributionsForecasting" fill="#0096B8" barSize={36} />
            </BarChart>
          </>
        );
      }

      if (selectedCompany === 'clientCompany') {
        const { clientCompany } = this.selectedAlliance;
        const companyName = clientCompany ? clientCompany.name : 'Client';

        contentCard = (
          <>
            <ForecastReportCard
              header={`${companyName} - TOTAL BUDGET`}
              amount={totalClientContributionsBudget}
              currency={currency}
            />
            <ForecastReportCard
              header={`${companyName} - TOTAL YTD ACTUAL`}
              amount={totalClientContributionsActuals}
              currency={currency}
            />
            <ForecastReportCard
              currency={currency}
              header={`${companyName} - TOTAL FORECAST`}
              amount={totalClientContributionsForecast}
            />
            <ForecastReportCard
              currency={currency}
              header={`${companyName} - VARIANCE`}
              amount={clientContributionsVariance}
              isVariance={true}
            />
          </>
        );
        contentChartLegend = (
          <>
            <ForecastReportChartLegendItem name={'Budget'} />
            <ForecastReportChartLegendItem name={`${companyName} - Actuals`} color={'#70D34C'} />
            <ForecastReportChartLegendItem name={`${companyName} - Forecast`} color={'#0096B8'} />
          </>
        );

        contentBarChart = (
          <>
            <BarChart width={800} height={550} data={data}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="month" />
              <YAxis
                tickFormatter={(value) =>
                  currency ? `${currency.symbol} ${numberFormatter(value, 1)}` : value
                }
              />
              <Tooltip content={<ForecastChartTooltip currency={currency} />} />
              <Bar dataKey="budget" fill="#2BA1FF" barSize={36} />
              <Bar dataKey="clientContributions" fill="#70D34C" barSize={36} />
              <Bar dataKey="clientContributionsForecasting" fill="#0096B8" barSize={36} />
            </BarChart>
          </>
        );
      }

      if (selectedCompany === 'partnerCompany') {
        const { partnerCompany } = this.selectedAlliance;
        const companyName = partnerCompany ? partnerCompany.name : 'Partner';

        contentCard = (
          <>
            <ForecastReportCard
              header={`${companyName} - TOTAL BUDGET`}
              amount={totalPartnerContributionsBudget}
              currency={currency}
            />
            <ForecastReportCard
              header={`${companyName} - TOTAL YTD ACTUAL`}
              amount={totalPartnerContributionsActuals}
              currency={currency}
            />
            <ForecastReportCard
              currency={currency}
              header={`${companyName} - TOTAL FORECAST`}
              amount={totalPartnerContributionsForecast}
            />
            <ForecastReportCard
              currency={currency}
              header={`${companyName} - VARIANCE`}
              amount={partnerContributionsVariance}
              isVariance={true}
            />
          </>
        );
        contentChartLegend = (
          <>
            <ForecastReportChartLegendItem name={'Budget'} />
            <ForecastReportChartLegendItem name={`${companyName} - Actuals`} color={'#70D34C'} />
            <ForecastReportChartLegendItem name={`${companyName} - Forecast`} color={'#0096B8'} />
          </>
        );

        contentBarChart = (
          <>
            <BarChart width={800} height={550} data={data}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="month" />
              <YAxis
                tickFormatter={(value) =>
                  currency ? `${currency.symbol} ${numberFormatter(value, 1)}` : value
                }
              />
              <Tooltip content={<ForecastChartTooltip currency={currency} />} />
              <Bar dataKey="budget" fill="#2BA1FF" barSize={36} />
              <Bar dataKey="partnerContributions" fill="#70D34C" barSize={36} />
              <Bar dataKey="partnerContributionsForecasting" fill="#0096B8" barSize={36} />
            </BarChart>
          </>
        );
      }

      content = (
        <>
          <Row style={{ padding: '20px 16px' }}>
            {contentCard}
            {/*<ForecastReportCard*/}
            {/*  currency={currency}*/}
            {/*  header={'VARIANCE - FEBRUARY'}*/}
            {/*  color={'red'}*/}
            {/*  amount={'12,000'}*/}
            {/*/>*/}
          </Row>
          <Row style={{ borderTop: '1px solid #E7ECF1', padding: '20px' }}>
            <Column style={{ width: '80%' }}>{contentBarChart}</Column>
            <Column style={{ width: '20%' }}>
              <div
                style={{ width: '125px', height: '112px', marginTop: '35px', marginLeft: '10px' }}>
                {contentChartLegend}
                {/*RED #E54034*/}
              </div>
            </Column>
          </Row>
        </>
      );
    }

    return (
      <Card stretch>
        <ReportHeader>
          <Heading type="h4" text={'Report'} />
          <Grid.Box direction="row" justifySelf="end" alignSelf="center">
            {forecastYears.length ? (
              <SelectField
                style={{ width: '150px', alignSelf: 'end', marginRight: '15px' }}
                input={{
                  name: 'yearFilter',
                  value: selectedForecastYear,
                  onChange: (year) => this.onSelectedYearChange(year),
                }}
                placeholder={'Select a Year'}
                options={forecastYears.map((year) => {
                  return { label: year, value: year };
                })}
              />
            ) : (
              ''
            )}

            <SelectField
              style={{ width: '150px', alignSelf: 'end' }}
              options={companyOptions}
              input={{
                name: 'companyFilter',
                value: selectedCompany,
                onChange: (company) => this.onSelectedCompany(company),
              }}
            />
          </Grid.Box>
        </ReportHeader>
        <Card.Body style={{ padding: '0px' }}>{content}</Card.Body>
      </Card>
    );
  }
}

export { ForecastingReportViewContributions };
