import moment from 'moment';
import { GREEN_COLOR, YELLOW_COLOR, RED_COLOR } from './colors';
import { formatUser } from './utils';

/**
 * Extracts the information of the Item depending of the Type.
 *
 * @param {object}item - The item to map.
 * @returns {object} - The mapped Item.
 */
export const getItemByType = (item) => {
  if (item.action !== null && item.action !== undefined) {
    if (item.action.businessCase === null)
      return {
        type: 'Action',
        url: 'action-without-business-case',
        id: item.action.id,
        itemId: item.id,
        name: item.action.name,
        status: item.action.status,
        createdAt: item.action.createdAt,
        revisedDueDate: item.action.revisedDueDate,
        originalDueDate: item.action.originalDueDate,
        requestedBy: formatUser(item.action.requestedBy),
        ownerObject: item.action.owner,
        nextSteps: item.action.nextSteps,
        approvalFunction: null,
      };
    return {
      type: 'Action',
      url: 'action',
      id: item.action.id,
      itemId: item.id,
      name: item.action.name,
      status: item.action.status,
      createdAt: item.action.createdAt,
      revisedDueDate: item.action.revisedDueDate,
      originalDueDate: item.action.originalDueDate,
      requestedBy: formatUser(item.action.requestedBy),
      ownerObject: item.action.owner,
      nextSteps: item.action.nextSteps,
      approvalFunction: null,
    };
  }
  if (item.idea !== null && item.idea !== undefined)
    return {
      type: 'Idea',
      url: 'idea',
      id: item.idea.id,
      itemId: item.id,
      name: item.idea.name,
      status: item.idea.status,
      createdAt: item.idea.createdAt,
      revisedDueDate: item.idea.revisedDueDate,
      originalDueDate: item.idea.originalDueDate,
      nextSteps: item.idea.nextSteps,
      requestedBy: formatUser(item.idea.requestedBy),
      ownerObject: item.idea.owner,
      approvalFunction: null,
    };
  if (item.issue !== null && item.issue !== undefined)
    return {
      type: 'Issue',
      url: 'issue',
      id: item.issue.id,
      itemId: item.id,
      name: item.issue.name,
      status: item.issue.status,
      createdAt: item.issue.createdAt,
      revisedDueDate: item.issue.revisedDueDate,
      originalDueDate: item.issue.originalDueDate,
      nextSteps: item.issue.nextSteps,
      approvalFunction: null,
    };
  if (item.decision !== null && item.decision !== undefined) {
    return {
      type: 'Decision',
      url: 'decision',
      id: item.decision.id,
      itemId: item.id,
      name: item.decision.name,
      status: item.decision.status,
      createdAt: item.decision.createdAt,
      approvalFunction: null,
    };
  }
  if (item.risk !== null && item.risk !== undefined)
    return {
      type: 'Risk',
      url: 'risk',
      id: item.risk.id,
      itemId: item.id,
      name: item.risk.name,
      status: item.risk.status,
      createdAt: item.risk.createdAt,
      revisedDueDate: item.risk.revisedDueDate,
      originalDueDate: item.risk.originalDueDate,
      nextSteps: item.risk.nextSteps,
      approvalFunction: null,
    };
  if (item.fundingRequest !== null && item.fundingRequest !== undefined)
    return {
      type: 'Funding Request',
      url: 'funding-request',
      id: item.fundingRequest.id,
      itemId: item.id,
      name: item.fundingRequest.name,
      status: item.fundingRequest.status,
      createdAt: item.fundingRequest.createdAt,
      revisedDueDate: item.fundingRequest.revisedDueDate,
      originalDueDate: item.fundingRequest.originalDueDate,
      requestedBy: formatUser(item.fundingRequest.requestedBy),
      nextSteps: item.fundingRequest.nextSteps,
      approvalFunction: null,
    };
  if (item.contribution !== null && item.contribution !== undefined) {
    return {
      type: 'Contribution',
      url: 'contribution',
      id: item.contribution.id,
      itemId: item.id,
      name: item.contribution.name,
      status: item.contribution.status,
      createdAt: item.contribution.createdAt,
      approvalFunction: null,
    };
  }
  // @deprecated : use item.dealData instead
  if (item.deal !== null && item.deal !== undefined)
    return {
      type: 'Deal',
      url: 'deal',
      id: item.deal.id,
      itemId: item.id,
      name: item.deal.name,
      status: item.deal.status,
      createdAt: item.deal.createdAt,
      owner: formatUser(item.deal.owner),
      ownerObject: item.deal.owner,
      approvalFunction: null,
    };
  if (item.dealData !== null && item.dealData !== undefined)
    return {
      type: 'Deal',
      url: 'deal',
      id: item.dealData.id,
      itemId: item.id,
      name: item.dealData.name,
      status: item.dealData.status,
      createdAt: item.dealData.createdAt,
      owner: item.dealData.owner ? formatUser(item.dealData.owner) : `N/A`,
      ownerObject: item.dealData.owner,
      approvalFunction: null,
    };
  return null;
};

/**
 * Get the color of items by dueDate, could be revisedDueDate for items and
 * dueDate for nextSteps.
 * // Use this on related items and active items to get the item color.
 *
 * @param  {Date} dueDate - The Due Date.
 * @returns {object} - The Color: GREEN_COLOR | RED_COLOR | YELLOW_COLOR.
 */
export const getDueDateColor = (dueDate) => {
  let color = GREEN_COLOR;

  if (dueDate) {
    if (moment().isSameOrAfter(moment(dueDate))) color = RED_COLOR;
    else {
      if (moment().diff(moment(dueDate), 'weeks') === 0) color = YELLOW_COLOR;

      if (moment().diff(moment(dueDate), 'weeks') < 0) color = GREEN_COLOR;
    }
  }

  return color;
};

//AMO ITEMS
export const ITEM_ACTION = 'ACTION';
export const ITEM_ISSUE = 'ISSUE';
export const ITEM_RISK = 'RISK';
export const ITEM_DECISION = 'DECISION';

// Investment Items
export const ITEM_FUNDING_REQUEST = 'FUNDING_REQUEST';
export const ITEM_CONTRIBUTION = 'CONTRIBUTION';

//Labels

export const AMO_ITEMS_LABELS = [
  { label: 'None', value: '' },
  { label: 'Action', value: ITEM_ACTION },
  { label: 'Issue', value: ITEM_ISSUE },
  { label: 'Risk', value: ITEM_RISK },
  { label: 'Decision', value: ITEM_DECISION },
];

export const INVESTMENT_ITEMS_LABELS = [
  { label: 'None', value: '' },
  { label: 'Funding Request', value: ITEM_FUNDING_REQUEST },
  { label: 'Contribution', value: ITEM_CONTRIBUTION },
];
