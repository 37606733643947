import React from 'react';
import { Card, Grid, Heading, Loader, Row } from '@8base/boost';
import { CreateViewCardBody } from '../../../components/new-ui/card/CreateViewCardBody';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import * as toast from '../../../components/toast/Toast';
import * as R from 'ramda';
import { View } from '@cobuildlab/react-simple-state';
import { onChangeDataMixin, onErrorMixin } from '../../../shared/mixins';
import { ISSUE_DOCUMENTS, IssueModel } from './issue-model';
import IssueForm from './components/IssueForm';
import { autoSaveUpdateIssue, fetchIssueDetail, openComments, updateIssue } from './issue-actions';
import { fetchInitiativeList } from '../initiative/initiative-actions';
import { fetchCurrentAllianceMembersAction } from '../../settings/alliance-management/alliance-actions';
import { getCurrencyOnSession } from '../../../shared/alliance-utils';
import { ActionButton } from '../../../components/buttons/ActionButton';
import { fetchRelatedItems } from '../../related-item/related-item-actions';
import { sanitizeNextStepsToEdit } from '../../next-step/next-step-actions';
import sessionStore, { NEW_SESSION_EVENT } from '../../../shared/SessionStore';
import { getItemByType } from '../../../shared/items-util';
import { issueValidator } from './issue-validators';
import RelatedItemForm from '../../related-item/components/RelatedItemForm';
import { TransparentButton } from '../../../components/buttons/TransparentButton';
import IssueDetailTable from './components/IssueDetailTable';
import { RelatedItemsDetailTable } from '../../related-item/components/RelatedItemsDetailTable';
import { initiativesItemValidator } from '../initiative/initiative-validators';
import { TopButtons } from '../../../components/buttons/TopButtons';
import { FormSteps } from '../../../components/dots/FormSteps';
import { InitiativeListTable } from '../initiative/components/InitiativeListTable';
import { BUSINESS_CASE_DOCUMENT } from '../../document-management/business-case/BusinessCase.model';
import { LeftProgressSection } from '../../../components/new-ui/LeftProgressSection';
import { SCREENS_ISSUE } from '../screenView';
import { BoxCard } from '../../../components/new-ui/div/BoxCard';
import { CardFooter } from '../../../components/new-ui/card/CardFooter';
import { ISSUE_COMPLETED } from '../../../shared/status';
import { TextDateAgo } from '../../../components/text/TextDateAgo';
import { OnAllianceMemberList } from '../../settings/alliance-management/alliance-events';
import { OnRelatedItems } from '../../related-item/related-item-events';
import { OnIssueDetail, OnIssueError, OnIssueUpdate, OnIssueAutoSave } from './issue-events';
import { OnInitiativeList } from '../initiative/initiative-events';

class IssueEditView extends View {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        issueData: R.clone(IssueModel),
        relatedItems: [],
        initiatives: [],
      },
      loading: true,
      initiativesList: [],
      clientCompany: null,
      partnerCompany: null,
      step: 0,
      savedAt: null,
    };
    this.onError = onErrorMixin.bind(this);
    this.originalNextSteps = [];
    this.onChangeData = onChangeDataMixin.bind(this);
    this.originalIssueData = null;

    this.autoSaveTimer = null;
    this.queryOptions = { isCacheFirst: true };
  }

  onChangeIssueData = (name, value) => {
    const { data } = this.state;
    data.issueData[name] = value;
    this.autoSave();
    this.setState({ data });
  };

  componentDidMount() {
    this.subscribe(OnIssueError, this.onError);

    this.subscribe(OnAllianceMemberList, (state) => {
      this.setState({
        clientCompany: R.clone(state.clientCompany),
        partnerCompany: R.clone(state.partnerCompany),
      });
    });

    this.subscribe(OnIssueDetail, (state) => {
      const issueData = R.clone(state.issue);
      const { data } = this.state;
      issueData.documents = R.clone(issueData.documents.items);
      const initiatives = R.clone(issueData.initiatives.items);

      issueData.itemId = issueData.itemIssueRelation.id;
      this.originalNextSteps = sanitizeNextStepsToEdit(issueData);
      localStorage.setItem(ISSUE_DOCUMENTS, JSON.stringify(issueData.documents));
      this.originalIssueData = R.clone(issueData);

      data.issueData = issueData;
      data.initiatives = initiatives;

      this.setState(
        {
          data,
          loading: false,
        },
        () => {
          fetchRelatedItems(issueData.itemId);
        },
      );
    });

    this.subscribe(OnIssueUpdate, () => {
      toast.success('Issue Successfully Updated');
      this.props.history.goBack();
    });

    this.subscribe(OnInitiativeList, (state) => {
      const initiativesList = R.clone(state.initiativesList.items);
      this.setState(
        {
          initiativesList,
        },
        () => {
          const { match } = this.props;
          if (!match.params.id) return toast.error('Issue ID missing');
          fetchIssueDetail(match.params.id);
        },
      );
    });

    this.subscribe(OnRelatedItems, (state) => {
      const items = state.item.itemsRelated.items.map((item) => getItemByType(item));
      const { data } = this.state;
      data.relatedItems = items;
      this.setState({ data, loading: false });
    });

    this.subscribe(OnIssueAutoSave, () => {
      this.setState({ savedAt: new Date() });
    });

    fetchInitiativeList('', 1, 1000);
    fetchCurrentAllianceMembersAction();
  }

  autoSave = () => {
    const waitTime = 10000; // Wait 10s
    clearTimeout(this.autoSaveTimer);
    this.autoSaveTimer = setTimeout(() => {
      const issueData = R.clone(this.state.data.issueData);
      const relatedItems = R.clone(this.state.data.relatedItems);
      const initiatives = R.clone(this.state.data.initiatives);
      autoSaveUpdateIssue(
        issueData,
        this.originalNextSteps,
        relatedItems,
        initiatives,
        this.originalIssueData,
      );
    }, waitTime);
  };

  componentWillUnmount() {
    super.componentWillUnmount();
    localStorage.removeItem(ISSUE_DOCUMENTS);
    localStorage.removeItem(BUSINESS_CASE_DOCUMENT);
  }

  onSubmit = () => {
    this.setState({ loading: true }, () => {
      clearTimeout(this.autoSaveTimer);
      const issueData = R.clone(this.state.data.issueData);
      const relatedItems = R.clone(this.state.data.relatedItems);
      const initiatives = R.clone(this.state.data.initiatives);
      updateIssue(
        issueData,
        this.originalNextSteps,
        relatedItems,
        initiatives,
        this.originalIssueData,
      );
    });
  };

  onIssueStepChange = (step) => {
    const { selectedAlliance } = sessionStore.getState(NEW_SESSION_EVENT);
    const issueData = R.clone(this.state.data.issueData);
    try {
      issueValidator(issueData, selectedAlliance);
    } catch (e) {
      return this.onError(e);
    }
    this.onScreen(step);
  };

  onRelatedItemsStepChange = (step) => {
    const initiatives = R.clone(this.state.data.initiatives);
    try {
      initiativesItemValidator(initiatives);
    } catch (e) {
      return this.onError(e);
    }
    this.onScreen(step);
  };

  onScreen = (step) => {
    this.setState({ step });
  };

  render() {
    const {
      initiativesList,
      companyId,
      loading,
      clientCompany,
      partnerCompany,
      step,
      savedAt,
    } = this.state;

    const { issueData, relatedItems, initiatives } = this.state.data;
    const { user, selectedAlliance } = sessionStore.getState(NEW_SESSION_EVENT);
    const { history } = this.props;

    let content = <Loader stretch />;
    let footer = <></>;
    const currency = getCurrencyOnSession();
    let buttonsTop = '';

    if (issueData.status === ISSUE_COMPLETED) history.push(`/management/amo-item`);

    if (!loading && step === 0) {
      content = (
        <div id={'c2gScreen'}>
          <IssueForm
            data={issueData}
            onChange={this.onChangeIssueData}
            clientCompany={clientCompany}
            partnerCompany={partnerCompany}
            myCompanyId={companyId}
            currency={currency}
            user={user}
            selectedAlliance={selectedAlliance}
          />
        </div>
      );
      footer = (
        <CardFooter>
          <ActionButton onClick={() => this.onIssueStepChange(1)} text={'Next'} />
        </CardFooter>
      );
    }

    if (!loading && step === 1) {
      const itemData = { id: issueData.id, type: issueData.__typename };

      content = (
        <div id={'c2gScreen'}>
          <RelatedItemForm
            relatedItems={relatedItems}
            selectedInitiatives={initiatives}
            initiatives={initiativesList}
            onChange={(key, value) => {
              this.autoSave();
              this.onChangeData(key, value);
            }}
            itemData={itemData}
          />
        </div>
      );

      footer = (
        <CardFooter>
          <ActionButton onClick={() => this.onRelatedItemsStepChange(2)} text={'Next'} />
          <TransparentButton onClick={() => this.onScreen(0)} text={'Previous'} />
        </CardFooter>
      );
    }

    if (!loading && step === 2) {
      const issueDetail = R.clone(issueData);
      issueDetail.documents = { items: issueDetail.documents };
      issueDetail.nextSteps = { items: issueDetail.nextSteps };

      content = (
        <div id={'c2gScreen'}>
          <IssueDetailTable data={issueDetail} currency={currency} />
          <InitiativeListTable initiatives={initiatives} />

          <RelatedItemsDetailTable relatedItems={relatedItems} />
        </div>
      );

      footer = (
        <CardFooter>
          <ActionButton onClick={this.onSubmit} text={'Edit Issue'} />
          <TransparentButton onClick={() => this.onScreen(1)} text={'Previous'} />
        </CardFooter>
      );
    }

    if (!loading)
      buttonsTop = (
        <TopButtons
          onClickClosed={history.goBack}
          onClickCollaborated={() => openComments(issueData)}
        />
      );

    return (
      <React.Fragment>
        <Card.Header>
          <Grid.Layout
            columns="400px auto 400px"
            areas={[['left', 'center', 'right']]}
            style={{ width: '100%' }}>
            <Grid.Box area="left">
              <Row>
                <Heading type="h4" text="Edit Issue" />
                <TextDateAgo from={savedAt} />
              </Row>
            </Grid.Box>
            <Grid.Box area="center">
              <FormSteps totalSteps={3} step={step} />
            </Grid.Box>
            <Grid.Box area="right" />
          </Grid.Layout>
          {buttonsTop}
        </Card.Header>
        <CreateViewCardBody>
          <Grid.Layout columns="30% 70%" areas={[['left', 'right']]} style={{ width: '100%' }}>
            <Grid.Box area="left">
              <LeftProgressSection sections={SCREENS_ISSUE} currentScreen={step} />
            </Grid.Box>
            <BoxCard>
              <Grid.Box area="right">{content}</Grid.Box>
            </BoxCard>
          </Grid.Layout>
        </CreateViewCardBody>
        {footer}
      </React.Fragment>
    );
  }
}

IssueEditView.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default withRouter(IssueEditView);
