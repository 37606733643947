import React from 'react';
import Sidebar from 'react-sidebar';
import View from '@cobuildlab/react-flux-state';
import { onErrorMixin } from '../../../shared/mixins';
import { Loader, Card, Text, Grid, Paper, Heading } from '@8base/boost';
import { RelatedItemsHeader } from './RelatedItemHeader';
import 'react-mention-plugin/lib/TextArea.css';
import { createRelatedItems, fetchRelatedItems, removeRelatedItems } from '../related-item-actions';
import relatedItemStore, {
  RELATED_ITEM_CREATE_EVENT,
  RELATED_ITEM_ERROR_EVENT,
  RELATED_ITEM_REMOVE_EVENT,
  RELATED_ITEM_REQUEST_EVENT,
  RELATED_ITEMS_EVENT,
} from '../related-item-store';
import RelatedIdemDialog from './RelatedItemDialog';
import { getItemByType } from '../../../shared/items-util';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import YesNoDialog from '../../../components/dialogs/YesNoDialog';
import Moment from 'react-moment';
import { ColorRelatedItems } from '../../../shared/components/ColorRelatedItems';
import { isValidString } from '../../../shared/utils';
import { ActionButton } from '../../../components/buttons/ActionButton';
import CloseIcon from '../../../components/CloseIcon';

/**
 * Items Side Bar.
 */

class RelatedItemSideBar extends View {
  constructor(props) {
    super(props);
    this.state = {
      sidebarOpen: false,
      loading: false,
      loadingItem: false,
      items: [],
      item: null,
      itemId: null,
      relatedItemDialogIsOpen: false,
      relatedItemDeleteDialogIsOpen: false,
    };
    this.onError = onErrorMixin.bind(this);
  }

  async componentDidMount() {
    this.subscribe(relatedItemStore, RELATED_ITEM_ERROR_EVENT, this.onError);
    this.subscribe(relatedItemStore, RELATED_ITEM_REQUEST_EVENT, (state) => {
      const { itemId } = state;
      this.setState(
        {
          sidebarOpen: true,
          loading: true,
          items: [],
          itemId,
          loadingItem: false,
        },
        () => {
          fetchRelatedItems(itemId);
        },
      );
    });
    this.subscribe(relatedItemStore, RELATED_ITEMS_EVENT, (state) => {
      const items = state.item.itemsRelated.items.map((item) => getItemByType(item));
      this.setState({
        loading: false,
        items,
        loadingItem: false,
      });
    });
    this.subscribe(relatedItemStore, RELATED_ITEM_CREATE_EVENT, (state) => {
      const { itemId } = this.state;
      fetchRelatedItems(itemId);
    });
    this.subscribe(relatedItemStore, RELATED_ITEM_REMOVE_EVENT, (state) => {
      const { itemId } = this.state;
      fetchRelatedItems(itemId);
    });
  }

  onSetSidebarOpen = () => {
    this.setState((prevState) => ({
      sidebarOpen: !prevState.sidebarOpen,
    }));
  };

  openRelatedItemDialog = () => {
    this.setState({ relatedItemDialogIsOpen: true });
  };

  closeRelatedItemDialog = () => {
    this.setState({ relatedItemDialogIsOpen: false });
  };

  openRelatedItemDeleteDialog = (i) => {
    const { items } = this.state;
    const item = items[i];
    this.setState({
      item,
      relatedItemDeleteDialogIsOpen: true,
    });
  };

  closeRelatedItemDeleteDialog = () => {
    this.setState({ relatedItemDeleteDialogIsOpen: false });
  };

  onRelatedItems = (items) => {
    const { itemId } = this.state;
    this.setState({ loadingItem: true }, () => {
      createRelatedItems(itemId, items);
    });
  };

  onDeleteRelatedItem = () => {
    const { item, itemId } = this.state;
    this.setState({ loadingItem: true, relatedItemDeleteDialogIsOpen: false }, () => {
      removeRelatedItems(itemId, [item]);
    });
  };

  render() {
    const {
      loading,
      sidebarOpen,
      items,
      loadingItem,
      relatedItemDialogIsOpen,
      relatedItemDeleteDialogIsOpen,
      itemId,
    } = this.state;
    const closeStyles = {
      position: 'absolute',
      top: 15,
      right: 20,
      width: 30,
      height: 30,
    };
    const filteredIds = items.map((item) => item.itemId);
    filteredIds.push(itemId);
    let content = (
      <>
        <RelatedItemsHeader text={'Related Items'} />
        <CloseIcon style={closeStyles} onClick={this.onSetSidebarOpen} />
        <div className="loaderSideBar">
          <Loader stretch />
        </div>
      </>
    );
    if (!loading) {
      let loaderItem = null;
      if (loadingItem) loaderItem = <Loader stretch />;
      content = (
        <>
          <RelatedItemsHeader text={'Related Items'} />
          <CloseIcon style={closeStyles} onClick={this.onSetSidebarOpen} />
          <Card.Body padding="none">
            {items.map((item, i) => {
              return (
                <Grid.Box style={{ margin: 10, padding: 10 }} key={i}>
                  <Paper padding="sm">
                    <ColorRelatedItems value={item} />
                    <Grid.Layout stretch columns="6fr 1fr">
                      <Grid.Box>
                        <Heading type="h6">
                          <Text weight={'bold'}>{item.type}</Text>
                        </Heading>
                        <Text>{item.name}</Text>
                        <Text disabled>
                          {isValidString(item.originalDueDate) ? (
                            <Moment format="MMMM Do, YYYY">{item.originalDueDate}</Moment>
                          ) : (
                            <br />
                          )}
                        </Text>
                        <Text weight={'semibold'}>{item.owner}</Text>
                      </Grid.Box>
                      <Grid.Box>
                        <div
                          style={{
                            cursor: 'pointer',
                            textAlign: 'center',
                            verticalAlign: 'middle',
                          }}
                          onClick={() => {
                            this.openRelatedItemDeleteDialog(i);
                          }}>
                          <FontAwesomeIcon icon={'minus-circle'} />
                        </div>
                      </Grid.Box>
                    </Grid.Layout>
                  </Paper>
                </Grid.Box>
              );
            })}
            {loaderItem}
            <Card.Section>
              <br />
              <br />
              <ActionButton onClick={this.openRelatedItemDialog} text="Add" />
            </Card.Section>
          </Card.Body>
          <RelatedIdemDialog
            filteredIds={filteredIds}
            onClose={this.closeRelatedItemDialog}
            onRelatedItems={this.onRelatedItems}
            isOpen={relatedItemDialogIsOpen}
          />
          <YesNoDialog
            onYes={this.onDeleteRelatedItem}
            onNo={this.closeRelatedItemDeleteDialog}
            title={'Delete Related Item'}
            text={'Do you really want to Delete this relationship?'}
            isOpen={relatedItemDeleteDialogIsOpen}
          />
        </>
      );
    }
    return (
      <Sidebar
        styles={{
          sidebar: {
            top: 60,
            background: 'white',
            width: 330,
          },
        }}
        sidebar={content}
        defaultSidebarWidth={330}
        open={sidebarOpen}
        onSetOpen={this.onSetSidebarOpen}
        pullRight={true}>
        {this.props.children}
      </Sidebar>
    );
  }
}

export default RelatedItemSideBar;
