import React from 'react';
import { Card, Table, Dropdown, Menu, Heading, Icon, Grid, Loader } from '@8base/boost';
import { DropdownBodyOnTable } from '../../../components/dropdown/DropdownBodyOnTable';
import { ListCardBody } from '../../../components/card/ListCardBody';
import * as toast from '../../../components/toast/Toast';
import { Link } from 'react-router-dom';
import { ActionButtonListView } from '../../../components/buttons/ActionButtonListView';
import * as R from 'ramda';
import { deleteIdea, fetchIdeaFullList, fetchIdeas, requestApprovalForIdea } from './idea-actions';
import { View } from '@cobuildlab/react-simple-state';
import YesNoDialog from '../../../components/dialogs/YesNoDialog';
import PropTypes from 'prop-types';
import sessionStore, { NEW_SESSION_EVENT } from '../../../shared/SessionStore';
import Status from '../../../components/Status';
import { onErrorMixin, onListScrollMixin } from '../../../shared/mixins';
import { withRouter } from 'react-router-dom';
import withAlliance from '../../../components/hoc/withAlliance';
import {
  canCreateIdea,
  canDeleteIdea,
  canEditIdea,
  canSubmitForApprovalIdea,
} from './idea-permissions';
import SearchInput from '../../../components/inputs/SearchInput';
import { debounce, formatUser, generatedExcel } from '../../../shared/utils';
import DetailDateValue from '../../../components/DetailDateValue';
import ItemFilter from '../../../components/ItemFilter';
import { IDEA_STATUS_LABELS } from '../../../shared/status';
import { DateText } from '../../../components/DateText';
import { Body } from '../../../components/new-ui/font-style/Body';
import { COLUMNSEXCEL, COLUMNSOBJECT } from './idea-model';
import { MoreActionButton } from '../../../components/buttons/MoreActionButton';
import {
  OnIdeaError,
  OnIdeaList,
  OnIdeaDelete,
  OnIdeaSubmitApproval,
  OnIdeaFullList,
} from './idea-events';

/*
 * List All the AMO Items.
 */
class IdeaListView extends View {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      loading: true,
      loadingPage: false,
      item: null,
      deleteModalIsOpen: false,
      submitForApprovalModalIsOpen: false,
      search: '',
      page: 1,
      count: 0,
      filter: '',
    };
    this.user = sessionStore.getState(NEW_SESSION_EVENT).user;
    this.selectedAlliance = sessionStore.getState(NEW_SESSION_EVENT).selectedAlliance;
    this.onError = onErrorMixin.bind(this);
    this.onListScroll = onListScrollMixin.bind(this);
    this.searchWithDebounce = debounce(300, this.searchWithDebounce)();
  }

  componentDidMount() {
    this.subscribe(OnIdeaError, this.onError);

    this.subscribe(OnIdeaList, (state) => {
      const { count } = R.clone(state.itemsList);
      const newItems = state.itemsList.items.map((item) => ({ ...item }));
      const { loadingPage, items: oldItemsList } = this.state;
      const items = loadingPage ? oldItemsList.concat(newItems) : newItems;

      this.setState({
        items,
        count,
        loading: false,
        loadingPage: false,
      });
    });

    this.subscribe(OnIdeaDelete, () => {
      const { search, filter } = this.state;

      this.setState({ page: 1 }, () => {
        toast.success('Idea Deleted!');
        fetchIdeas(search, 1, 20, filter);
      });
    });

    this.subscribe(OnIdeaSubmitApproval, () => {
      const { search, filter } = this.state;

      this.setState({ page: 1 }, () => {
        toast.success('Idea Submitted For Approval!');
        fetchIdeas(search, 1, 20, filter);
      });
    });

    this.subscribe(OnIdeaFullList, (state) => {
      // Export To Excel
      const items = state.itemsList.items.map((item) => ({ ...item }));
      const idea = [];
      items.forEach((data, index) => {
        data.idea.initiatives.items.forEach((aux, i) => {
          for (let owner in aux.owner) {
            aux[owner] = aux.owner[owner];
          }
          data['idea']['initiatives' + i] = aux;
        });
        idea.push(data.idea);
      });
      generatedExcel(idea, COLUMNSOBJECT, COLUMNSEXCEL, 'IdeaList');
      this.setState({ loading: false });
    });

    const fetchPolicy = { isCacheFirst: true };
    fetchIdeas('', 1, 20, null, null, fetchPolicy);
  }

  onSelectForDelete = (item) => {
    const [canDelete, errors] = canDeleteIdea(this.user, item.idea, this.selectedAlliance);
    if (canDelete) {
      this.setState({
        item,
        deleteModalIsOpen: true,
      });
    } else {
      toast.errors(errors);
    }
  };

  onSelectForSubmitForApproval = (item) => {
    this.setState({
      item,
      submitForApprovalModalIsOpen: true,
    });
  };

  onYes = () => {
    this.setState(
      {
        deleteModalIsOpen: false,
        loading: true,
      },
      () => deleteIdea(this.state.item),
    );
  };

  onYesSubmitForApproval = () => {
    this.setState(
      {
        submitForApprovalModalIsOpen: false,
        loading: true,
      },
      () => requestApprovalForIdea(this.state.item),
    );
  };

  onClose = () => {
    this.setState({
      deleteModalIsOpen: false,
      submitForApprovalModalIsOpen: false,
    });
  };

  onSearchChange = (value) => {
    this.setState({ search: value });
    this.searchWithDebounce(value);
  };

  searchWithDebounce = (value) => {
    const { filter } = this.state;
    this.setState({ loading: true, page: 1 }, () => {
      fetchIdeas(value, 1, 20, filter);
    });
  };

  statusFilter = (value) => {
    const { search } = this.state;
    this.setState(
      {
        loading: true,
        filter: value,
        page: 1,
      },
      () => {
        fetchIdeas(search, 1, 20, value);
      },
    );
  };

  exportExcel = () => {
    this.setState({ loading: true });
    fetchIdeaFullList();
  };

  render() {
    const {
      deleteModalIsOpen,
      items,
      submitForApprovalModalIsOpen,
      search,
      loading,
      loadingPage,
      filter,
    } = this.state;
    const { history } = this.props;
    const alliance = this.selectedAlliance;

    return (
      <div className="items-card" id={'c2gScreen'}>
        <Card.Header>
          <Grid.Layout
            columns="50px auto 50px 200px"
            areas={[['left', 'center', 'right', 'right2']]}
            style={{ width: '100%' }}>
            <Grid.Box justifySelf="flex-start" area="left">
              <Heading type="h4" text="Ideas" />
            </Grid.Box>
            <Grid.Box justifySelf="center" area="center">
              <SearchInput className="search-input" value={search} onChange={this.onSearchChange} />
            </Grid.Box>
            <Grid.Box area="right2">
              <div style={{ width: '70%' }}>
                <ItemFilter
                  onChange={this.statusFilter}
                  value={filter}
                  options={IDEA_STATUS_LABELS}
                  placeholder="Filter By Status"
                />
              </div>
              <Dropdown defaultOpen={false} style={{ position: 'absolute', right: '0px' }}>
                <Dropdown.Head>
                  <MoreActionButton />
                </Dropdown.Head>
                <Dropdown.Body pin="right">
                  {({ closeDropdown }) => (
                    <Menu>
                      <Menu.Item
                        onClick={() => {
                          this.exportExcel();
                          closeDropdown();
                        }}>
                        Export to Excel
                      </Menu.Item>
                    </Menu>
                  )}
                </Dropdown.Body>
              </Dropdown>
            </Grid.Box>
          </Grid.Layout>
        </Card.Header>
        <ListCardBody className="items-table">
          <Table>
            <Table.Header
              className="justify-center-column"
              columns="260px 260px 260px 260px 260px 260px">
              <Table.HeaderCell>State</Table.HeaderCell>
              <Table.HeaderCell className="name-column">Name</Table.HeaderCell>
              <Table.HeaderCell>Due Date</Table.HeaderCell>
              <Table.HeaderCell>Requested By</Table.HeaderCell>
              <Table.HeaderCell>Options</Table.HeaderCell>
              <Table.HeaderCell>
                Approvals <br />
                CLIENT / PARTNER{' '}
              </Table.HeaderCell>
            </Table.Header>
            <Table.Body
              onScroll={(event) => this.onListScroll(event, items, fetchIdeas)}
              loading={loading}
              data={items}
              className="card-body-list">
              {(item, index) => {
                const { id, name, revisedDueDate, requestedBy, status } = item.idea;
                const isLast = index === items.length - 1;
                const pageLoader = isLast && loadingPage ? <Loader stretch /> : null;
                const approvalItems =
                  item.idea.ideaApprovalRelation.items !== undefined
                    ? item.idea.ideaApprovalRelation.items.slice(-2)
                    : '';

                return (
                  <>
                    <Table.BodyRow columns="340px 250px 230px 260px 300px 400px" key={id}>
                      <Table.BodyCell>
                        <span> {status} </span>
                      </Table.BodyCell>
                      <Table.BodyCell className="name-column table-cell">
                        <Link className="item-name" to={`/management/idea/${id}/`}>
                          {name}
                        </Link>
                      </Table.BodyCell>
                      <Table.BodyCell className="table-cell">
                        <Body>
                          <DateText date={revisedDueDate} />
                        </Body>
                      </Table.BodyCell>
                      <Table.BodyCell className="justify-left-row table-cell">
                        <Body>
                          <span className="owner-name">{formatUser(requestedBy)}</span>
                        </Body>
                      </Table.BodyCell>
                      <Table.BodyCell className=" justify-center-row table-cell">
                        <Dropdown defaultOpen={false}>
                          <Dropdown.Head>
                            <Icon name="More" className="more-icon" />
                          </Dropdown.Head>
                          <DropdownBodyOnTable>
                            {({ closeDropdown }) => (
                              <Menu>
                                <Menu.Item
                                  onClick={() => {
                                    closeDropdown();
                                    history.push(`/management/idea/${id}/`);
                                  }}>
                                  Details
                                </Menu.Item>
                                {canEditIdea(this.user, item.idea, alliance) ? (
                                  <Menu.Item
                                    onClick={() => {
                                      closeDropdown();
                                      history.push(`/management/idea/edit/${id}/`);
                                    }}>
                                    Edit
                                  </Menu.Item>
                                ) : (
                                  ''
                                )}
                                <Menu.Item
                                  onClick={() => {
                                    closeDropdown();
                                    this.onSelectForDelete(item);
                                  }}>
                                  Delete
                                </Menu.Item>
                                {canSubmitForApprovalIdea(
                                  this.user,
                                  item.idea,
                                  this.selectedAlliance,
                                ) ? (
                                    <Menu.Item
                                      onClick={() => {
                                        closeDropdown();
                                        this.onSelectForSubmitForApproval(item);
                                      }}>
                                    Submit For Approval
                                    </Menu.Item>
                                  ) : (
                                    ''
                                  )}
                              </Menu>
                            )}
                          </DropdownBodyOnTable>
                        </Dropdown>
                      </Table.BodyCell>

                      <Table.BodyCell>
                        {approvalItems !== null
                          ? approvalItems.map((approval, i) => (
                            <div key={i}>
                              <Status status={approval.status} />
                              <DetailDateValue date={approval.dateOfResponse} />
                            </div>
                          ))
                          : ''}
                      </Table.BodyCell>
                    </Table.BodyRow>
                    {pageLoader}
                  </>
                );
              }}
            </Table.Body>
          </Table>
        </ListCardBody>
        {canCreateIdea(this.user, alliance) ? (
          <ActionButtonListView
            onClick={() => this.props.history.push('/management/idea/create')}
            text="Create Idea"
          />
        ) : (
          ''
        )}
        <YesNoDialog
          isOpen={deleteModalIsOpen}
          onYes={this.onYes}
          onNo={this.onClose}
          onClose={this.onClose}
          text={'Are you sure you want to Delete this Idea?'}
          title={'Delete Item'}
        />
        <YesNoDialog
          isOpen={submitForApprovalModalIsOpen}
          onYes={this.onYesSubmitForApproval}
          onNo={this.onClose}
          onClose={this.onClose}
          text={'Are you sure you want to Submit For Approval this Idea?'}
          title={'Submit For Approval'}
        />
      </div>
    );
  }
}

IdeaListView.propTypes = {
  history: PropTypes.object.isRequired,
};

export default withRouter(withAlliance(IdeaListView));
