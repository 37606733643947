import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';

const StyledCard = styled.div`
  border-left: 1px solid rgb(233, 239, 244);
  height: 100%;
`;

const BoxCard = ({ children }) => {
  return <StyledCard>{children}</StyledCard>;
};

BoxCard.propTypes = {
  children: PropTypes.any.isRequired,
};

export { BoxCard };
