import React from 'react';
import { Card, Table, Heading } from '@8base/boost';
import { ListCardBody } from '../../../components/card/ListCardBody';
import * as toast from '../../../components/toast/Toast';
import * as R from 'ramda';
import { resendCompanyMemberInvitation } from './invitations.actions';
import { View } from '@cobuildlab/react-simple-state';
import YesNoDialog from '../../../components/dialogs/YesNoDialog';
import sessionStore, { NEW_SESSION_EVENT } from '../../../shared/SessionStore';
import { PropTypes } from 'prop-types';
import Moment from 'react-moment';
import Status from '../../../components/Status';
import { onErrorMixin } from '../../../shared/mixins';
import { ActionButton } from '../../../components/buttons/ActionButton';
import { sortByPendingInvitation } from './invitations-utils';
import { fetchCompanyInvitationList } from './invitations.actions';
import { canResendCompanyMemberInvitation } from '@cobuildlab/collabtogrow-permissions';
import { OnSessionError } from '../../../shared/session-events';
import {
  OnCompanyInvitationList,
  OnCompanyInvitationError,
  OnCompanyResendInvitation,
} from './invitations-events';

class CompanyMemberInvitationListView extends View {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      invitations: [],
      resendInvitationModalIsOpen: false,
      resendInvitation: null,
    };
    this.onError = onErrorMixin.bind(this);
  }

  componentDidMount() {
    this.user = sessionStore.getState(NEW_SESSION_EVENT).user;

    this.subscribe(OnSessionError, this.onError);

    this.subscribe(OnCompanyInvitationError, this.onError);

    this.subscribe(OnCompanyInvitationList, (state) => {
      const { data } = state;
      this.setState({
        invitations: data.companyInvitationsList.items.sort(sortByPendingInvitation),
        loading: false,
      });
    });

    this.subscribe(OnCompanyResendInvitation, async (state) => {
      await fetchCompanyInvitationList();
      toast.success('Resend Company Membership invitation successfully');
    });

    fetchCompanyInvitationList();
  }

  onResendInvitation = (invitation) => {
    this.setState({
      resendInvitation: invitation,
      resendInvitationModalIsOpen: true,
    });
  };

  onYesResendInvitation = () => {
    this.setState(
      {
        resendInvitationModalIsOpen: false,
        loading: true,
      },
      () => {
        resendCompanyMemberInvitation(this.state.resendInvitation);
      },
    );
  };

  onCloseResendInvitation = () => {
    this.setState({
      resendInvitationModalIsOpen: false,
    });
  };

  render() {
    const { invitations, loading, resendInvitationModalIsOpen } = this.state;
    const tableHeaders = ['Company Name', 'Sent to', 'Status', 'Date of Response', 'Options'];
    const columnSize = `repeat(${tableHeaders.length}, 1fr)`;

    return (
      <React.Fragment>
        <Card.Header>
          <Heading type="h4" text="Company Membership" />
        </Card.Header>
        <ListCardBody>
          <div id={'c2gScreen'}>
            <Table>
              <Table.Header columns={columnSize}>
                {tableHeaders.map((header, index) => (
                  <Table.HeaderCell key={index}>{header}</Table.HeaderCell>
                ))}
                <Table.HeaderCell />
              </Table.Header>
              <Table.Body loading={loading} data={invitations} className="card-body-list">
                {(invitation) => {
                  console.log('DEBUG', this.user);
                  console.log('DEBUG', invitation);
                  const canResendInvitation = canResendCompanyMemberInvitation(
                    this.user,
                    invitation,
                  );
                  return (
                    <Table.BodyRow columns={columnSize} key={invitation.id}>
                      <Table.BodyCell>
                        {R.pathOr('Unititled', ['company', 'name'], invitation)}
                      </Table.BodyCell>
                      <Table.BodyCell>{R.pathOr('', ['email'], invitation)}</Table.BodyCell>
                      <Table.BodyCell>
                        <Status status={R.pathOr('', ['status'], invitation)} />
                      </Table.BodyCell>
                      <Table.BodyCell>
                        {invitation.dateOfResponse === null ? (
                          'No date'
                        ) : (
                          <Moment format="MMMM Do, YYYY">
                            {new Date(invitation.dateOfResponse)}
                          </Moment>
                        )}
                      </Table.BodyCell>
                      <Table.BodyCell>
                        {canResendInvitation ? (
                          <ActionButton
                            text={'Resend Invitation'}
                            onClick={() => this.onResendInvitation(invitation)}
                          />
                        ) : null}
                      </Table.BodyCell>
                    </Table.BodyRow>
                  );
                }}
              </Table.Body>
            </Table>
          </div>
        </ListCardBody>
        <YesNoDialog
          isOpen={resendInvitationModalIsOpen}
          onYes={this.onYesResendInvitation}
          onNo={this.onCloseResendInvitation}
          onClose={this.onCloseResendInvitation}
          text={'Are you sure you want to resend Alliance Membership Invitation?'}
          title={'Resend Invitation'}
        />
      </React.Fragment>
    );
  }
}

CompanyMemberInvitationListView.propTypes = {
  history: PropTypes.object.isRequired,
};

export default CompanyMemberInvitationListView;
