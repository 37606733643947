import Flux from '@cobuildlab/flux-state';

/**
 * Event that triggers a Initiative error.
 *
 * @type {string}
 */
export const INITIATIVE_ERROR_EVENT = 'onInitiativeError';

/**
 * Event that triggers a Contribution Item List event.
 *
 * @type {string}
 */
export const INITIATIVE_LIST_EVENT = 'onInitiativeList';

/**
 * Event that triggers a Contribution Item List event.
 *
 * @type {string}
 */
export const INITIATIVE_FULL_LIST_EVENT = 'onInitiativeFullList';

/**
 * Event that triggers a Initiative create event.
 *
 * @type {string}
 */
export const INITIATIVE_CREATE_EVENT = 'onInitiativeCreate';

/**
 * Event that triggers a Initiative edit event.
 *
 * @type {string}
 */
export const INITIATIVE_UPDATE_EVENT = 'onInitiativeUpdate';

/**
 * Event that triggers a Initiative reject event.
 *
 * @type {string}
 */
export const INITIATIVE_REJECT_EVENT = 'onInitiativeReject';

/**
 * Event that triggers a Initiative detail event.
 *
 * @type {string}
 */
export const INITIATIVE_DETAIL_EVENT = 'onInitiativeDetail';

/**
 * Event that triggers a Initiative delete event.
 *
 * @type {string}
 */
export const INITIATIVE_DELETE_EVENT = 'onInitiativeDelete';

/**
 * Event that triggers a Initiative close event.
 *
 * @type {string}
 */
export const INITIATIVE_COMPLETED_EVENT = 'onInitiativeCompleted';

/**
 * Event that triggers a Initiative submit for approval.
 *
 * @type {string}
 */
export const INITIATIVE_SUBMIT_FOR_APPOVAL_EVENT = 'onInitiativeSubmitForApproval';

/**
 * Event that triggers a Initiative restore.
 *
 * @type {string}
 */
export const INITIATIVE_RESTORE_EVENT = 'onInitiativeRestore';

/**
 * Event triggers a Initiative Approvals list.
 *
 * @type {string}
 */
export const INITIATIVE_APPROVALS_LIST_EVENT = 'onInitiativeApprovalsList';

/**
 * Event that triggers a Initiative create event.
 *
 * @type {string}
 */
export const INITIATIVE_AUTO_SAVE_CREATE_EVENT = 'INITIATIVE_AUTO_SAVE_CREATE_EVENT';

/**
 * Event that triggers a Initiative create event.
 *
 * @type {string}
 */
export const INITIATIVE_AUTO_SAVE_CREATE_ERROR_EVENT = 'INITIATIVE_AUTO_SAVE_CREATE_ERROR_EVENT';

/**
 * Hold the Initiative Data.
 */
class InitiativeStore extends Flux.DashStore {
  constructor() {
    super();
    this.addEvent(INITIATIVE_ERROR_EVENT);
    this.addEvent(INITIATIVE_LIST_EVENT);
    this.addEvent(INITIATIVE_FULL_LIST_EVENT);
    this.addEvent(INITIATIVE_CREATE_EVENT);
    this.addEvent(INITIATIVE_UPDATE_EVENT);
    this.addEvent(INITIATIVE_REJECT_EVENT);
    this.addEvent(INITIATIVE_DETAIL_EVENT);
    this.addEvent(INITIATIVE_DELETE_EVENT);
    this.addEvent(INITIATIVE_COMPLETED_EVENT);
    this.addEvent(INITIATIVE_SUBMIT_FOR_APPOVAL_EVENT);
    this.addEvent(INITIATIVE_RESTORE_EVENT);
    this.addEvent(INITIATIVE_APPROVALS_LIST_EVENT);
    this.addEvent(INITIATIVE_AUTO_SAVE_CREATE_EVENT);
    this.addEvent(INITIATIVE_AUTO_SAVE_CREATE_ERROR_EVENT);
  }
}

export default new InitiativeStore();
