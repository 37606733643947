import React from 'react';
import PropTypes from 'prop-types';
import './status.css';

const Status = ({ status, greyText, greyColor }) => {
  let statusDot = { class: '', text: ' Not Available' };
  if (status === 'IN PROGRESS') {
    statusDot = { class: 'status-dot in-progress', text: ' In Progress' };
  } else if (status === 'PENDING') {
    statusDot = { class: 'status-dot pending', text: ' Pending' };
  } else if (status === 'AMBER') {
    statusDot = { class: 'status-dot pending', text: ' Amber' };
  } else if (status === 'NEW') {
    statusDot = { class: 'status-dot new', text: ' New' };
  } else if (status === 'OPEN') {
    statusDot = { class: 'status-dot new', text: ' Open' };
  } else if (status === 'GREEN') {
    statusDot = { class: 'status-dot new', text: ' Green' };
  } else if (status === 'APPROVED') {
    statusDot = { class: 'status-dot approved', text: ' Approved' };
  } else if (status === 'ACCEPTED') {
    statusDot = { class: 'status-dot accepted', text: ' Accepted' };
  } else if (status === 'REJECTED') {
    statusDot = { class: 'status-dot rejected', text: ' Rejected' };
  } else if (status === 'RED') {
    statusDot = { class: 'status-dot rejected', text: 'Red' };
  } else if (status === 'CANCELLED') {
    statusDot = { class: 'status-dot canceled', text: ' Canceled' };
  } else if (status === 'CLOSED') {
    statusDot = { class: 'status-dot closed', text: ' Closed' };
  } else if (status === 'COMPLETED') {
    statusDot = { class: 'status-dot completed', text: ' Completed' };
  } else if (status === 'SUBMITTED FOR APPROVAL' || status === 'SUBMITTED') {
    statusDot = { class: 'status-dot submitted', text: ' Submitted' };
  }

  return (
    <div
      style={
        greyText || statusDot.text === ' Not Available'
          ? {
            color: greyColor,
            marginLeft: '5px',
          }
          : { marginLeft: '5px' }
      }>
      <span className={statusDot.class} /> {statusDot.text}
    </div>
  );
};

Status.propTypes = {
  status: PropTypes.string,
  greyColor: PropTypes.string,
  greyText: PropTypes.bool,
};

Status.defaultProps = {
  greyColor: '#b3b3b3',
  status: '',
  greyText: '',
};

export default Status;
