import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import React from 'react';
import { Text, Heading } from '@8base/boost';
import { withRouter, Link } from 'react-router-dom';
import { BLUE_COLOR } from '../../../../shared/colors';

const Card = styled.div`
  box-sizing: border-box;
  height: 110px;
  width: 225px;
  border: 1px solid #eaf0f7;
  border-radius: 4px;
  background-color: #ffffff;
  padding-top: 20px;
  margin-right: 24px !important;
`;

const Header = styled(Heading)`
  height: 18px;
  color: #939ea7;
  font-family: Poppins;
  font-size: 12px;
  letter-spacing: 0.2px;
  line-height: 18px;
  margin-left: 16px;
`;

const LinkedHeader = styled(Header)`
  color: ${BLUE_COLOR};
`;

const AmountText = styled(Text)`
  height: 45px;
  width: 225px;
  color: ${(props) => props.color}!important;
  font-family: Poppins;
  font-size: 32px;
  font-weight: 500;
  line-height: 48px;
  margin-left: 16px;
`;

const _DealInfoCard = ({ color, header, amount, link }) => {
  return (
    <>
      <Card>
        {link ? (
          <Link to={link}>
            <LinkedHeader text={header} />
          </Link>
        ) : (
          <Header text={header} />
        )}
        <AmountText color={color}>{amount}</AmountText>
      </Card>
    </>
  );
};

_DealInfoCard.propTypes = {
  color: PropTypes.string,
  header: PropTypes.string.isRequired,
  amount: PropTypes.string.isRequired,
  link: PropTypes.string,
};

_DealInfoCard.defaultProps = {
  color: '#000',
  link: null,
};

const DealInfoCard = withRouter(_DealInfoCard);
export { DealInfoCard };
