import { IntegrityError, ValidationError } from '../../../shared/errors';
import { isValidString } from '../../../shared/validators';
import { INITIATIVE_APPROVED } from '../../../shared/status';
import moment from 'moment';

/**
 * Validate the Initiative fields.
 *
 * @param {object} initiative - The Initiative to be reviewed.
 */
export const initiativeValidator = (initiative) => {
  let errorMessages = [];

  if (!isValidString(initiative.name)) errorMessages.push('The initiative must have a valid Name');

  if (!isValidString(initiative.description))
    errorMessages.push(' The Initiative must have a valid Description');

  if (!initiative.owner) errorMessages.push('The Initiative must have a valid Owner');

  if (!initiative.requestedBy)
    errorMessages.push('The Initiative must have a valid Requested By User');

  if (!initiative.ragStatus) errorMessages.push('The Initiative must have a valid RAG Status');

  if (!moment(initiative.requestedDate).isValid())
    errorMessages.push('The Initiative must have a valid Requested Date');

  if (!moment(initiative.baselineStartDate).isValid()) {
    errorMessages.push('The Initiative must have a valid Baseline Start date');
  }

  if (!moment(initiative.baselineEndDate).isValid()) {
    errorMessages.push('The Initiative must have a valid Baseline End date');
  }

  if (initiative.forecastedEndDate) {
    if (!moment(initiative.forecastedEndDate).isValid()) {
      errorMessages.push('The Initiative must have a valid Forecasted End date');
    }
  }
  if (errorMessages.length !== 0) throw new ValidationError(errorMessages);
};

/**
 * Validate if the item's initiatives are approved
 * // For canSubmitForApproval items permissions.
 *
 * @param {object} initiatives - The item's initiatives.
 */
export const initiativesApprovedValidator = (initiatives) => {
  if (!initiatives || !Array.isArray(initiatives.items)) {
    throw new IntegrityError('Missing initiatives');
  }

  initiatives.items.forEach((initiative) => {
    if (initiative.status !== INITIATIVE_APPROVED) {
      throw new IntegrityError('The initiatives must be approved');
    }
  });
};

/**
 * Validate initiatives for create/update Items.
 *
 * @param {object} initiatives - Initiatives.
 */
export const initiativesItemValidator = (initiatives) => {
  if (!Array.isArray(initiatives) || !initiatives.length) {
    throw new IntegrityError('You must select at least one Initiative');
  }
};

/**
 * Validate if selected initiatives is approved.
 *
 * @param {Array}initiatives - Initiatives.
 */
export const initiativesApproved = (initiatives) => {
  if (initiatives.length) {
    initiatives.forEach((initiative) => {
      if (initiative.status !== INITIATIVE_APPROVED) {
        throw new IntegrityError(
          `Investment Item can't be related to Unapproved Initiative "${initiative.name}"!`,
        );
      }
    });
  }
};
