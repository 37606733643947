import React from 'react';
import PropTypes from 'prop-types';
import LightGreyText from './LightGreyText';

// Component checks if there's a value present to render
// or else returns unassignedText
const SourceDetailValue = ({ source, fontSize, unassignedText }) => {
  if (source && source.name) {
    return <span style={fontSize ? { fontSize: fontSize } : {}}>{`${source.name}`}</span>;
  }
  return <LightGreyText fontSize={fontSize} text={unassignedText} />;
};

SourceDetailValue.defaultProps = {
  source: null,
  fontSize: null,
  unassignedText: '',
};

SourceDetailValue.propTypes = {
  source: PropTypes.object,
  fontSize: PropTypes.number,
  unassignedText: PropTypes.string,
};

export default SourceDetailValue;
