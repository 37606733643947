import React from 'react';
import styled from '@emotion/styled';
import { matchPath } from 'react-router';
import { withRouter, Link as RouterLink } from 'react-router-dom';
import { Grid, Breadcrumbs as BoostBreadcrumbs, Link } from '@8base/boost';
import PropTypes from 'prop-types';
import { COMPANY_ADMINISTRATOR } from '../shared/roles';
import { View } from '@cobuildlab/react-simple-state';
import { capitalize } from '../shared/utils';
import { OnNewSession } from '../shared/session-events';

const REPORTS_SECTIONS = {
  'balanced-scorecard': 'Balanced Scorecard',
  'active-sales-pipeline': 'Active Sales Pipeline',
  'sales-pipeline-trends': 'Sales Pipeline Trends',
  forecasting: 'Forecasting',
  'pivot-table': 'Pivot Table',
  'deals-funnel': 'Deals Funnel',
  'audit-log': 'Audit Log',
};

const HeaderTag = styled(Grid.Box)({
  height: '32px',
  display: 'flex',
  padding: '0 2rem',
  'background-color': '#fff',
  'box-shadow': '0 2px 4px rgba(163,163,163,0.33)',
});

class Breadcrumb extends View {
  render() {
    let user = OnNewSession.get().user;
    const { items } = user.companyUserRelation;
    const role = items.length !== 0 ? items[0].role.name : null;
    let companyLabel = 'Company Information';

    if (role === COMPANY_ADMINISTRATOR) {
      companyLabel = 'Company Management';
    }
    const BREADCRUMBS_ROUTES = [
      {
        path: '/wizard',
        component: () => 'Initiation',
        matchOptions: { exact: true },
      },
      {
        path: '/dashboard',
        component: () => 'Dashboard',
        matchOptions: { exact: true },
      },
      {
        path: '/document-management/',
        component: () => 'Document Management',
        matchOptions: { exact: true },
      },
      {
        path: '/document-management/business-cases',
        component: () => 'Business Cases',
        matchOptions: { exact: true },
      },
      {
        path: '/document-management/business-cases/create',
        component: () => 'Create',
        matchOptions: { exact: true },
      },
      {
        path: '/document-management/general',
        component: () => 'General',
        matchOptions: { exact: true },
      },
      /*
     Active items (Collaborate)
     */
      {
        path: '/active-items',
        component: () => 'Active items',
        matchOptions: { exact: true },
      },
      // My Work Queue
      {
        path: '/active-items/work-queue',
        component: () => 'My Work Queue',
        matchOptions: { exact: true },
      },
      // Alliance Setup Initiative
      {
        path: '/active-items/initiative/:id',
        component: () => 'Initiative',
        matchOptions: { exact: true },
      },

      /*
  Management (Collaborate)
  */
      {
        path: '/management',
        component: () => 'Collaborate',
        matchOptions: { exact: true },
      },
      // Deals
      {
        path: '/management/deal',
        component: () => 'Deals',
        matchOptions: { exact: true },
      },
      {
        path: '/management/deal/create',
        component: () => 'Create',
        matchOptions: { exact: true },
      },
      {
        path: '/management/deal/:id(.{25})',
        component: () => 'Detail',
        matchOptions: { exact: true },
      },
      {
        path: '/management/deal/import',
        component: () => 'Import',
        matchOptions: { exact: true },
      },
      {
        path: '/management/deal/imported',
        component: () => 'Imported',
        matchOptions: { exact: true },
      },
      {
        path: '/management/deal/edit/:id(.{25})',
        component: () => 'Edit',
        matchOptions: { exact: true },
      },
      // Investment Item
      {
        path: '/management/investment-item',
        component: () => 'Investment Items',
        matchOptions: { exact: true },
      },
      // Funding Request
      {
        path: '/management/funding-request/:id(.{25})',
        component: () => 'Funding Request  >  Detail',
        matchOptions: { exact: true },
      },
      {
        path: '/management/funding-request/create',
        component: () => 'Funding Request  >  Create',
        matchOptions: { exact: true },
      },
      {
        path: '/management/funding-request/edit/:id',
        component: () => 'Funding Request  >  Edit',
        matchOptions: { exact: true },
      },
      // Contributions
      {
        path: '/management/contribution/:id(.{25})',
        component: () => 'Contributions  >  Detail',
        matchOptions: { exact: true },
      },
      {
        path: '/management/contribution/create',
        component: () => 'Contributions  >  Create',
        matchOptions: { exact: true },
      },
      {
        path: '/management/contribution/edit/:id',
        component: () => 'Contributions  >  Edit',
        matchOptions: { exact: true },
      },
      // AMO item
      {
        path: '/management/amo-item',
        component: () => 'AMO Items',
        matchOptions: { exact: true },
      },
      // Issues
      {
        path: '/management/issue/:id(.{25})',
        component: () => 'Issues  >  Detail',
        matchOptions: { exact: true },
      },
      {
        path: '/management/issue/create',
        component: () => 'Issues  >  Create',
        matchOptions: { exact: true },
      },
      {
        path: '/management/issue/edit/:id',
        component: () => 'Issues  >  Edit',
        matchOptions: { exact: true },
      },
      // Actions
      {
        path: '/management/action/:id(.{25})',
        component: () => 'Actions  >  Detail',
        matchOptions: { exact: true },
      },
      {
        path: '/management/action/create',
        component: () => 'Actions  >  Create',
        matchOptions: { exact: true },
      },
      {
        path: '/management/action/edit/:id',
        component: () => 'Actions  >  Edit',
        matchOptions: { exact: true },
      },
      {
        path: '/management/action-without-business-case/:id(.{25})',
        component: () => 'Actions  >  Detail',
        matchOptions: { exact: true },
      },
      {
        path: '/management/action-without-business-case/create',
        component: () => 'Actions  >  Create',
        matchOptions: { exact: true },
      },
      {
        path: '/management/action-without-business-case/edit/:id',
        component: () => 'Actions  >  Edit',
        matchOptions: { exact: true },
      },
      // Decisions
      {
        path: '/management/decision/:id(.{25})',
        component: () => 'Decisions  >  Detail',
        matchOptions: { exact: true },
      },
      {
        path: '/management/decision/create',
        component: () => 'Decisions  >  Create',
        matchOptions: { exact: true },
      },
      {
        path: '/management/decision/edit/:id',
        component: () => 'Decisions  >  Edit',
        matchOptions: { exact: true },
      },
      // Risks
      {
        path: '/management/risk/:id(.{25})',
        component: () => 'Risks  >  Detail',
        matchOptions: { exact: true },
      },
      {
        path: '/management/risk/create',
        component: () => 'Risks  >  Create',
        matchOptions: { exact: true },
      },
      {
        path: '/management/risk/edit/:id',
        component: () => 'Risks  >  Edit',
        matchOptions: { exact: true },
      },
      // Initiatives
      {
        path: '/management/initiative',
        component: () => 'Initiatives',
        matchOptions: { exact: true },
      },
      {
        path: '/management/initiative/:id(.{25})',
        component: () => 'Detail',
        matchOptions: { exact: true },
      },
      {
        path: '/management/initiative/create',
        component: () => 'Create',
        matchOptions: { exact: true },
      },
      {
        path: '/management/initiative/edit/:id(.{25})',
        component: () => 'Edit',
        matchOptions: { exact: true },
      },
      // Ideas
      {
        path: '/management/idea',
        component: () => 'Ideas',
        matchOptions: { exact: true },
      },
      {
        path: '/management/idea/:id(.{25})',
        component: () => 'Detail',
        matchOptions: { exact: true },
      },
      {
        path: '/management/idea/create',
        component: () => 'Create',
        matchOptions: { exact: true },
      },
      {
        path: '/management/idea/edit/:id(.{25})',
        component: () => 'Edit',
        matchOptions: { exact: true },
      },
      /*
  Invitations
  */
      {
        path: '/invitations',
        component: () => 'Invitations',
        matchOptions: { exact: true },
      },
      {
        path: '/invitations/alliance-invitations',
        component: () => 'My Alliances',
        matchOptions: { exact: true },
      },
      {
        path: '/invitations/alliance-member-invitations',
        component: () => 'Alliance Membership',
        matchOptions: { exact: true },
      },
      /*
  Settings
  */
      {
        path: '/settings',
        component: () => 'Settings',
        matchOptions: { exact: true },
      },
      // Alliances
      {
        path: '/settings/alliance-management',
        component: () => 'Alliances',
        matchOptions: { exact: true },
      },
      {
        path: '/settings/alliance-management/:id(.{25})',
        component: () => 'Detail',
        matchOptions: { exact: true },
      },
      {
        path: '/settings/alliance-management/create',
        component: () => 'Create',
        matchOptions: { exact: true },
      },
      {
        path: '/settings/alliance-management/edit/:id',
        component: () => 'Edit',
        matchOptions: { exact: true },
      },
      {
        path: '/settings/alliance-management/members/:id',
        component: () => 'Members',
        matchOptions: { exact: true },
      },
      // Company Management
      {
        path: '/settings/company-management',
        component: () => companyLabel,
        matchOptions: { exact: true },
      },
      {
        path: '/settings/company-management/my-company',
        component: () => 'My Company',
        matchOptions: { exact: true },
      },
      {
        path: '/settings/company-management/company-list',
        component: () => 'Companies',
        matchOptions: { exact: true },
      },
      {
        path: '/settings/company-management/:id(.{25})',
        component: () => 'Detail',
        matchOptions: { exact: true },
      },
      {
        path: '/settings/company-management/create',
        component: () => 'Create',
        matchOptions: { exact: true },
      },
      {
        path: '/settings/company-management/edit/:id',
        component: () => 'Edit',
        matchOptions: { exact: true },
      },
      {
        path: '/settings/company-management/members/:id',
        component: () => 'Members',
        matchOptions: { exact: true },
      },
      // User Profile
      {
        path: '/settings/user-profile',
        component: () => 'User Profile',
        matchOptions: { exact: true },
      },
      // Reports
      {
        path: '/reports',
        component: () => 'Reports',
        matchOptions: { exact: true },
      },
      {
        path: '/reports/:section',
        component: ({ match }) => REPORTS_SECTIONS[match.params.section],
        matchOptions: { exact: true },
      },
      {
        path: '/reports/:section/:tab',
        component: ({ match }) => capitalize(match.params.tab),
        matchOptions: { exact: true },
      },
    ];

    const BreadcrumbsItem = (props) => (
      <Link tagName={RouterLink} color="DARK_GRAY1" size="lg" {...props} />
    );
    return (
      <HeaderTag area="breadcrumbs" justifyContent="center">
        <BoostBreadcrumbs
          pathname={this.props.location.pathname}
          routes={BREADCRUMBS_ROUTES}
          matchPath={matchPath}
          itemTagName={BreadcrumbsItem}
        />
      </HeaderTag>
    );
  }
}

Breadcrumb.propTypes = {
  location: PropTypes.any.isRequired,
};

const Breadcrumbs = withRouter(Breadcrumb);

export { Breadcrumbs };
