import sessionStore, { APOLLO_CLIENT, NEW_SESSION_EVENT } from '../../../shared/SessionStore';
import Flux from '@cobuildlab/flux-state';
import {
  INITIATIVE_APPROVALS_LIST_EVENT,
  INITIATIVE_AUTO_SAVE_CREATE_ERROR_EVENT,
  INITIATIVE_AUTO_SAVE_CREATE_EVENT,
  INITIATIVE_COMPLETED_EVENT,
  INITIATIVE_CREATE_EVENT,
  INITIATIVE_DELETE_EVENT,
  INITIATIVE_DETAIL_EVENT,
  INITIATIVE_ERROR_EVENT,
  INITIATIVE_FULL_LIST_EVENT,
  INITIATIVE_LIST_EVENT,
  INITIATIVE_REJECT_EVENT,
  INITIATIVE_RESTORE_EVENT,
  INITIATIVE_SUBMIT_FOR_APPOVAL_EVENT,
  INITIATIVE_UPDATE_EVENT,
} from './initiative-store';
import {
  INITIATIVE_APPROVAL_UPDATE_MUTATION,
  INITIATIVE_APPROVALS_LIST_QUERY,
  INITIATIVE_COMMENTS_QUERY,
  INITIATIVE_CREATE_MUTATION,
  INITIATIVE_DELETE_MUTATION,
  INITIATIVE_DETAIL_QUERY,
  INITIATIVE_INITIATIVE_APPROVAL_MUTATION,
  INITIATIVE_LIST_QUERY,
  INITIATIVE_UPDATE_MUTATION,
  INITIATIVE_LIST_SMALL_QUERY,
} from './initiative-queries';
import {
  COMMENT_CREATE_EVENT,
  COMMENT_ERROR_EVENT,
  COMMENT_REQUEST_EVENT,
  COMMENTS_EVENT,
} from '../../comment/comment-store';
import { COMMENTS_CREATE_MUTATION } from '../../comment/comment-queries';
import { INITIATIVE_TYPE } from '../../../shared/item-types';
import { initiativeValidator } from './initiative-validators';
import { IntegrityError } from '../../../shared/errors';
import { error, log } from '@cobuildlab/pure-logger';
import {
  filterForYear,
  isValidString,
  sanitize8BaseDate,
  sanitize8BaseReference,
  sanitize8BaseReferenceFromObject,
} from '../../../shared/utils';
import {
  businessCaseSearchFilterOR,
  sanitizeRecommendedSolutions,
  updateBusinessCase,
} from '../../document-management/business-case/businessCases.actions';
import {
  getActiveAllianceId,
  getCompanyOnAlliance,
  isApprovalComplete,
} from '../../../shared/alliance-utils';
import {
  canApproveInitiative,
  canCompletedInitiative,
  canDeleteInitiative,
  canEditInitiative,
  canRejectInitiative,
  canRestoreInitiative,
  canSubmitForApprovalInitiative,
} from './initiative-permissions';
import {
  INITIATIVE_APPROVAL_APPROVED,
  INITIATIVE_APPROVAL_REJECTED,
  INITIATIVE_APPROVED,
  INITIATIVE_COMPLETED,
  INITIATIVE_IN_PROGRESS,
  INITIATIVE_REJECTED,
  INITIATIVE_SUBMITTED_FOR_APPROVAL,
} from '../../../shared/status';
import { businessCaseValidator } from '../../document-management/business-case/business-case-validators';
import { getInitiativeItemByType } from '../../../shared/initiative-item-util';
import {
  normalize8baseDocumentCreate,
  normalize8baseDocumentDeleteAndUpdate,
  normalize8baseDocumentsCreate,
  normalize8baseDocumentsDeleteAndUpdate,
} from '@cobuildlab/8base-utils';
import * as R from 'ramda';
import {
  OnInitiativeList,
  OnInitiativeError,
  OnInitiativeDelete,
  OnInitiativeSubmitForApproval,
  OnInitiativeFullList,
  OnInitiativeCreate,
  OnInitiativeAutoSaveCreate,
  OnInitiativeAutoSaveCreateError,
  OnInitiativeDetail,
  OnInitiativeRestore,
  OnInitiativeUpdate,
  OnInitiativeReject,
  OnInitiativeCompleted,
  OnInitiativeSmallList,
} from './initiative-events';
import {
  OnCommentRequest,
  OnCommentCreate,
  OnCommentError,
  OnComment,
} from '../../comment/comment-events';
import { OnNewSession } from '../../../shared/session-events';

/**
 * Creates a filter object and search by a string
 on string properties of the initiative.
 *
 * @param {string} data -  Data to create filter object.
 * @returns {object}  The filter object.
 */
export const initiativeFilter = ({ allianceId, search = '', status = '', filterYear = null }) => {
  const filter = {
    alliance: { id: { equals: allianceId } },
    OR: [
      {
        name: {
          contains: search,
        },
      },
      {
        description: {
          contains: search,
        },
      },
      {
        owner: {
          firstName: { contains: search },
        },
      },
      {
        owner: {
          lastName: { contains: search },
        },
      },
      {
        owner: {
          email: { contains: search },
        },
      },
    ].concat(businessCaseSearchFilterOR(search)),
  };

  if (filterYear) {
    const filterForYearData = filterForYear(
      filterYear.year,
      filterYear.fieldName,
      filterYear.isDateTime,
    );

    if (filterYear.fieldName === 'dateOfResponse') {
      filter.initiativeApprovalInitiativeRelation = {
        some: {
          AND: filterForYearData.AND,
        },
      };
    } else {
      filter.AND = filterForYearData.AND;
    }
  }

  if (status) {
    filter.status = { equals: status };
  }

  return filter;
};

/**
 * Fetches the Initiative list.
 *
 * @param {string} search - Search.
 * @param {number} page - Page.
 * @param {number} first - First Page.
 * @param {string} status - Initiative status.
 * @param {object} filterYear - Filter year.
 * @param {object} options - Object option.
 * @returns {Promise<void|*>} Promise.
 */
export const fetchInitiativeList = async (
  search = '',
  page = 1,
  first = 20,
  status,
  filterYear = null,
  options = {},
) => {
  const client = sessionStore.getState(APOLLO_CLIENT);
  const allianceId = getActiveAllianceId();
  const skip = (page - 1) * first;
  const fetchPolicy = options.isCacheFirst ? 'cache-first' : 'network-only';

  log('allianceId', allianceId);
  try {
    if (!isValidString(allianceId)) {
      OnInitiativeError.dispatch(new IntegrityError('An Alliance must be selected'));

      return Flux.dispatchEvent(
        INITIATIVE_ERROR_EVENT,
        new IntegrityError('An Alliance must be selected'),
      );
    }
  } catch (error) {
    console.log('initiative-error:', error);
  }

  const filter = initiativeFilter({ allianceId, search, status, filterYear });

  let response;
  try {
    response = await client.query({
      query: INITIATIVE_LIST_QUERY,
      fetchPolicy,
      variables: { data: filter, skip, first },
    });
  } catch (e) {
    console.log('initiative error', e);
    error('fetchInitiativeList', e);
    OnInitiativeError.dispatch(e);
    Flux.dispatchEvent(INITIATIVE_ERROR_EVENT, e);
    throw e;
  }

  log('fetchInitiativeList', response);
  Flux.dispatchEvent(INITIATIVE_LIST_EVENT, response.data);
  OnInitiativeList.dispatch(response.data);
  return response.data;
};

/**
 * Fetches the Initiative small list.
 *
 * @param {string}search - Search.
 * @param {number}page - Page.
 * @param {number}first - First Page.
 * @param {string}status - Initiative status.
 * @param {object}filterYear - Filter year.
 * @returns {Promise<void|*>} Promise.
 */
export const fetchInitiativeSmallList = async (
  search = '',
  page = 1,
  first = 20,
  status,
  filterYear = null,
) => {
  const client = sessionStore.getState(APOLLO_CLIENT);
  const allianceId = getActiveAllianceId();
  const skip = (page - 1) * first;

  log('allianceId', allianceId);

  if (!allianceId) {
    OnInitiativeError.dispatch(new IntegrityError('An Alliance must be selected'));
    return;
  }

  const filter = initiativeFilter({ allianceId, search, status, filterYear });

  let response;
  try {
    response = await client.query({
      query: INITIATIVE_LIST_SMALL_QUERY,
      fetchPolicy: 'network-only',
      variables: { data: filter, skip, first },
    });
  } catch (e) {
    error('fetchInitiativeList', e);
    OnInitiativeError.dispatch(e);
    throw e;
  }

  log('fetchInitiativeList', response);
  OnInitiativeSmallList.dispatch(response.data);
  return response.data;
};

/**
 * Fetches a list of all Initiatives with a minimum data
 * for use as options in the Select component.
 */

export const fetchInitiativeFullList = async (search = '') => {
  const client = sessionStore.getState(APOLLO_CLIENT);
  const user = sessionStore.getState(NEW_SESSION_EVENT).user;
  const allianceId = getActiveAllianceId(user);

  log('allianceId', allianceId);

  if (!isValidString(allianceId)) {
    OnInitiativeError.dispatch(new IntegrityError('An Alliance must be selected'));

    return Flux.dispatchEvent(
      INITIATIVE_ERROR_EVENT,
      new IntegrityError('An Alliance must be selected'),
    );
  }

  const filter = initiativeFilter({ allianceId, search });

  let response;

  try {
    response = await client.query({
      query: INITIATIVE_LIST_QUERY,
      fetchPolicy: 'network-only',
      variables: { data: filter },
    });
  } catch (e) {
    error('fetchAllInitiativeList', e);
    OnInitiativeError.dispatch(e);
    return Flux.dispatchEvent(INITIATIVE_ERROR_EVENT, e);
  }

  log('fetchAllInitiativeList', response);
  OnInitiativeFullList.dispatch(response.data);
  Flux.dispatchEvent(INITIATIVE_FULL_LIST_EVENT, response.data);
  return response.data;
};

/**
 * Fetches the Initiative list where status is equal to Approved.
 *
 * @returns {Promise<void|*>} Promise.
 */
export const fetchInitiativeListApproved = async () => {
  const client = sessionStore.getState(APOLLO_CLIENT);
  const user = sessionStore.getState(NEW_SESSION_EVENT).user;
  const allianceId = getActiveAllianceId(user);

  log('allianceId', allianceId);
  if (!isValidString(allianceId))
    return Flux.dispatchEvent(
      INITIATIVE_ERROR_EVENT,
      new IntegrityError('An Alliance must be selected'),
    );
  const allianceFilter = {
    alliance: { id: { equals: allianceId } },
    status: { equals: INITIATIVE_APPROVED },
  };

  let response;
  try {
    response = await client.query({
      query: INITIATIVE_LIST_QUERY,
      fetchPolicy: 'network-only',
      variables: { data: allianceFilter },
    });
  } catch (e) {
    error('fetchInitiativeList', e);
    return Flux.dispatchEvent(INITIATIVE_ERROR_EVENT, e);
  }

  log('fetchInitiativeList', response);
  Flux.dispatchEvent(INITIATIVE_LIST_EVENT, response.data);
  return response.data;
};

/**
 * Creates a New Initiative.
 * Sets the Owner to the current logged User.
 *
 * @param {object} initiativeData - Initiative Data.
 * @param {object} businessCaseData - Business Case.
 * @param {Array} relatedItems - Related Items.
 * @param {object} savedInitiative - Saved initiative.
 * @returns {Promise<void|*>} Promise.
 */
export const createInitiative = async (
  initiativeData,
  businessCaseData,
  relatedItems,
  savedInitiative,
) => {
  const { user } = sessionStore.getState(NEW_SESSION_EVENT);
  const allianceId = getActiveAllianceId(user);
  const client = sessionStore.getState(APOLLO_CLIENT);

  console.log('relatedItems', relatedItems);

  if (!isValidString(allianceId)) {
    OnInitiativeError.dispatch(new IntegrityError('An Alliance must be selected'));

    return Flux.dispatchEvent(
      INITIATIVE_ERROR_EVENT,
      new IntegrityError('An Alliance must be selected'),
    );
  }

  log('createInitiative', initiativeData, businessCaseData, relatedItems);
  try {
    initiativeValidator(initiativeData);
    businessCaseValidator(businessCaseData);
  } catch (e) {
    error('validateInitiativeForDraft', e);
    OnInitiativeError.dispatch(e);
    return Flux.dispatchEvent(INITIATIVE_ERROR_EVENT, e);
  }

  try {
    const initiative = await createOrUpdateInitiative(
      initiativeData,
      businessCaseData,
      relatedItems,
      savedInitiative,
    );

    await client.resetStore();
    OnInitiativeCreate.dispatch(initiative);
    Flux.dispatchEvent(INITIATIVE_CREATE_EVENT, initiative);
    return initiative;
  } catch (e) {
    error('createInitiative', e);
    OnInitiativeError.dispatch(e);
    Flux.dispatchEvent(INITIATIVE_ERROR_EVENT, e);
  }
};

/**
 * Auto Save - Creates a New Initiative.
 *
 * @param {object} initiativeData - Initiative Data.
 * @param {object} businessCaseData - Business Case.
 * @param {Array} relatedItems - Related Items.
 * @param {object }savedInitiative - Saved initiative.
 * @returns {Promise<void|*>} Promise.
 */
export const autoSaveCreateInitiative = async (
  initiativeData,
  businessCaseData,
  relatedItems,
  savedInitiative,
) => {
  console.log('Auto-Save: ', {
    initiativeData,
    savedInitiative,
    businessCaseData,
  });

  const client = sessionStore.getState(APOLLO_CLIENT);

  try {
    const initiative = await createOrUpdateInitiative(
      initiativeData,
      businessCaseData,
      relatedItems,
      savedInitiative,
    );

    await client.resetStore();
    OnInitiativeAutoSaveCreate.dispatch(initiative);
    Flux.dispatchEvent(INITIATIVE_AUTO_SAVE_CREATE_EVENT, initiative);
    console.log('autoSaveCreateInitiative:response', initiative);
    return initiative;
  } catch (e) {
    OnInitiativeAutoSaveCreateError.dispatch(e);
    Flux.dispatchEvent(INITIATIVE_AUTO_SAVE_CREATE_ERROR_EVENT, e);
    console.error('autoSaveCreateInitiative', e);
  }
};

/**
 * Auto Save - Update initiative Data.
 *
 * @param {object} initiativeData - Initiative Data.
 * @param {object} businessCaseData - Business Case Data.
 * @param {Array} relatedItems - Related Items.
 * @param {Array} originalRecommendedSolutions - Original Recommended Solutions.
 * @param {object} originalInitiativeData - Original Object Data.
 * @returns {Promise<void|*>} Promise.
 */
export const autoSaveUpdateInitiative = async (
  initiativeData,
  businessCaseData,
  relatedItems,
  originalRecommendedSolutions,
  originalInitiativeData,
) => {
  const client = sessionStore.getState(APOLLO_CLIENT);

  try {
    const initiative = await _updateInitiative(
      initiativeData,
      businessCaseData,
      relatedItems,
      originalRecommendedSolutions,
      originalInitiativeData,
    );

    await client.resetStore();
    OnInitiativeAutoSaveCreate.dispatch(initiative);
    Flux.dispatchEvent(INITIATIVE_AUTO_SAVE_CREATE_EVENT, initiative);
    console.log('autoSaveUpdateInitiative:response', initiative);
    return initiative;
  } catch (e) {
    console.error('autoSaveUpdateInitiative:error', e);
  }
};

export const createOrUpdateInitiative = (
  initiativeData,
  businessCaseData,
  relatedItems,
  savedInitiative,
) => {
  if (!savedInitiative) {
    return _createInitiative(initiativeData, businessCaseData, relatedItems);
  } else {
    const originalInitiative = R.clone(savedInitiative);
    const originalRecommendedSolutions =
      originalInitiative.businessCase.recommendedSolutionsRelation.items;
    originalInitiative.documentsFile = originalInitiative.documentsFile.items;

    initiativeData.id = originalInitiative.id;
    businessCaseData.id = originalInitiative.businessCase.id;

    return _updateInitiative(
      initiativeData,
      businessCaseData,
      relatedItems,
      originalRecommendedSolutions,
      originalInitiative,
    );
  }
};

export const _createInitiative = async (initiativeData, businessCaseData, relatedItems) => {
  const client = sessionStore.getState(APOLLO_CLIENT);
  const { user } = sessionStore.getState(NEW_SESSION_EVENT);
  const allianceId = getActiveAllianceId(user);

  const initiativeRelatedItems = sanitizeConnectInitiativeItemRelation(relatedItems);
  // add connect fields
  initiativeData.alliance = allianceId;
  businessCaseData.owner = user.id;
  // Sanitize 8base initiativeData fields
  sanitize8BaseReference(initiativeData, 'alliance');
  sanitize8BaseReferenceFromObject(initiativeData, 'owner');
  sanitize8BaseReferenceFromObject(initiativeData, 'requestedBy');
  normalize8baseDocumentCreate(initiativeData, 'currentDashboardFile');
  normalize8baseDocumentsCreate(initiativeData, 'documentsFile');
  sanitize8BaseDate(initiativeData, 'baselineStartDate');
  sanitize8BaseDate(initiativeData, 'baselineEndDate');
  sanitize8BaseDate(initiativeData, 'forecastedEndDate');
  sanitize8BaseDate(initiativeData, 'requestedDate');
  // Sanitize 8base businessCaseData fields
  sanitize8BaseReference(businessCaseData, 'owner');
  normalize8baseDocumentCreate(businessCaseData, 'document');
  sanitizeRecommendedSolutions(businessCaseData);
  initiativeData.businessCase = {
    create: businessCaseData,
  };

  const data = {
    ...initiativeData,
    ...initiativeRelatedItems,
  };

  // Delete Unnecessary fields
  delete data.id;
  delete businessCaseData.id;

  const response = await client.mutate({
    mutation: INITIATIVE_CREATE_MUTATION,
    variables: { data: data },
  });
  return response.data.initiativeCreate;
};

/**
 * This method is to ensure retro compatibility with previous versions of the Initiative.
 *
 * @param {string}initiativeId - Initiative Id.
 * @returns {Promise<void>} Promise.
 */
export const createItemRelationForInitiative = async (initiativeId) => {
  const client = sessionStore.getState(APOLLO_CLIENT);
  const user = sessionStore.getState(NEW_SESSION_EVENT).user;
  const allianceId = getActiveAllianceId(user);
  const data = {
    itemInitiativeRelation: { create: { alliance: { connect: { id: allianceId } } } },
  };
  let response;
  try {
    response = await client.mutate({
      mutation: INITIATIVE_UPDATE_MUTATION,
      variables: { data },
    });
  } catch (e) {
    error('updateInitiative', e);
    return Flux.dispatchEvent(INITIATIVE_ERROR_EVENT, e);
  }
  log('updateInitiative', response);
};

/**
 * Update initiative Data.
 *
 * @param {object} initiativeData - Initiative Data.
 * @param {object} businessCaseData - Business Case Data.
 * @param {Array} relatedItems - Related Items.
 * @param {Array} originalRecommendedSolutions - Original Recommended Solutions.
 * @param {object} originalInitiativeData - Original Object Data.
 * @returns {Promise<void|*>} Promise.
 */
export const updateInitiative = async (
  initiativeData,
  businessCaseData,
  relatedItems,
  originalRecommendedSolutions,
  originalInitiativeData,
) => {
  const { user } = sessionStore.getState(NEW_SESSION_EVENT);
  const allianceId = getActiveAllianceId(user);
  const client = sessionStore.getState(APOLLO_CLIENT);

  log('updateInitiative', initiativeData, originalInitiativeData);

  if (!canEditInitiative(user, initiativeData, { id: allianceId })) {
    OnInitiativeError.dispatch(new IntegrityError(`Permission Denied. Can't Edit this Initiative`));

    return Flux.dispatchEvent(
      INITIATIVE_ERROR_EVENT,
      new IntegrityError(`Permission Denied. Can't Edit this Initiative`),
    );
  }

  try {
    initiativeValidator(initiativeData);
    businessCaseValidator(businessCaseData);
  } catch (e) {
    error('validateInitiative', e);
    OnInitiativeError.dispatch(e);
    return Flux.dispatchEvent(INITIATIVE_ERROR_EVENT, e);
  }

  try {
    const initiative = await _updateInitiative(
      initiativeData,
      businessCaseData,
      relatedItems,
      originalRecommendedSolutions,
      originalInitiativeData,
    );
    log('updateInitiative', initiative);

    await client.resetStore();
    OnInitiativeUpdate.dispatch(initiative);
    Flux.dispatchEvent(INITIATIVE_UPDATE_EVENT, initiative);
    return initiative;
  } catch (e) {
    error('updateInitiative', e);
    OnInitiativeError.dispatch(e);
    Flux.dispatchEvent(INITIATIVE_ERROR_EVENT, e);
    return;
  }
};

/**
 * Update initiative Data.
 *
 * @param {object} initiativeData - Initiative Data.
 * @param {object} businessCaseData - Business Case Data.
 * @param {Array} relatedItems - Related Items.
 * @param {Array} originalRecommendedSolutions - Original Recommended Solutions.
 * @param {object} originalInitiativeData - Original Object Data.
 * @returns {Promise<void|*>} Promise.
 */
export const _updateInitiative = async (
  initiativeData,
  businessCaseData,
  relatedItems,
  originalRecommendedSolutions,
  originalInitiativeData,
) => {
  const client = sessionStore.getState(APOLLO_CLIENT);

  log('_updateInitiative', initiativeData, originalInitiativeData);

  delete initiativeData.itemInitiativeRelation;
  delete initiativeData.__typename;
  delete initiativeData.status;
  delete initiativeData.createdAt;
  delete initiativeData.createdBy;
  delete initiativeData.alliance;
  delete initiativeData.businessCase;
  delete initiativeData.initiativeApprovalInitiativeRelation;
  delete businessCaseData.__typename;
  delete businessCaseData.owner;
  delete businessCaseData.status;
  delete businessCaseData.createdAt;
  delete businessCaseData.createdBy;
  delete businessCaseData.recommendedSolutionsRelation;

  // Sanitize 8base initiativeData fields
  normalize8baseDocumentDeleteAndUpdate(
    initiativeData,
    'currentDashboardFile',
    originalInitiativeData,
  );
  normalize8baseDocumentsDeleteAndUpdate(initiativeData, 'documentsFile', originalInitiativeData);
  sanitize8BaseDate(initiativeData, 'baselineStartDate');
  sanitize8BaseDate(initiativeData, 'baselineEndDate');
  sanitize8BaseDate(initiativeData, 'forecastedEndDate');
  sanitize8BaseDate(initiativeData, 'requestedDate');
  sanitize8BaseReferenceFromObject(initiativeData, 'requestedBy');
  sanitize8BaseReferenceFromObject(initiativeData, 'owner');

  const initiativeRelatedItems = sanitizeReconnectInitiativeItemRelation(relatedItems);

  const data = {
    ...initiativeData,
    ...initiativeRelatedItems,
  };

  const response = await client.mutate({
    mutation: INITIATIVE_UPDATE_MUTATION,
    variables: { data: data },
  });
  log('_updateInitiative', response.data.initiativeUpdate);

  await updateBusinessCase(
    businessCaseData,
    originalRecommendedSolutions,
    originalInitiativeData.businessCase,
  );

  return response.data.initiativeUpdate;
};

/**
 * Fetch a single Initiative.
 *
 *  @param {string} id - Initiative id.
 *  @param {object} options - Object options.
 *  @returns {Promise} Promise.
 */
export const fetchInitiative = async (id, options = {}) => {
  const client = sessionStore.getState(APOLLO_CLIENT);
  const fetchPolicy = options.isCacheFirst ? 'cache-first' : 'network-only';
  let response;

  try {
    response = await client.query({
      query: INITIATIVE_DETAIL_QUERY,
      variables: { id },
      fetchPolicy,
    });
  } catch (e) {
    error('fetchInitiative', e);
    OnInitiativeError.dispatch(e);
    return Flux.dispatchEvent(INITIATIVE_ERROR_EVENT, e);
  }
  log('fetchInitiative', response);
  OnInitiativeDetail.dispatch(response.data);
  Flux.dispatchEvent(INITIATIVE_DETAIL_EVENT, response.data);
  return response.data;
};

/**
 * Request approval for a initiative.
 *
 * @param  {object} initiative - Initiative.
 * @returns {Promise} Promise.
 */
export const requestApprovalForInitiative = async (initiative) => {
  const { user, selectedAlliance } = sessionStore.getState(NEW_SESSION_EVENT);
  const client = sessionStore.getState(APOLLO_CLIENT);

  if (!canSubmitForApprovalInitiative(user, initiative, selectedAlliance)[0]) {
    OnInitiativeError.dispatch(
      new IntegrityError(`Permission Denied. Can't submit this Initiative for approval`),
    );

    return Flux.dispatchEvent(
      INITIATIVE_ERROR_EVENT,
      new IntegrityError(`Permission Denied. Can't submit this Initiative for approval`),
    );
  }

  log('requestApprovalForInitiative data', initiative);

  try {
    initiativeValidator(initiative);
    businessCaseValidator(initiative.businessCase);
  } catch (e) {
    error('requestApprovalForInitiative', e);
    OnInitiativeError.dispatch(e);
    return Flux.dispatchEvent(INITIATIVE_ERROR_EVENT, e);
  }

  try {
    const response = await _requestApprovalForInitiative(initiative);
    await client.resetStore();
    OnInitiativeSubmitForApproval.dispatch(response);
    Flux.dispatchEvent(INITIATIVE_SUBMIT_FOR_APPOVAL_EVENT, response);
    return response;
  } catch (e) {
    error('requestApprovalForInitiative', e);
    OnInitiativeError.dispatch(e);
    return Flux.dispatchEvent(INITIATIVE_ERROR_EVENT, e);
  }
};

/**
 * Request approval for a initiative.
 *
 * @param  {object} initiative - Initiative.
 * @param {object} selectedAlliance - The alliance that holds the companies to
 *  be connected with.
 * @returns {Promise} Promise.
 */
export const _requestApprovalForInitiative = async (initiative, selectedAlliance = null) => {
  const client = sessionStore.getState(APOLLO_CLIENT);
  if (!selectedAlliance)
    selectedAlliance = sessionStore.getState(NEW_SESSION_EVENT).selectedAlliance;

  const initiativeApprovalList = [
    { company: { connect: { id: selectedAlliance.clientCompany.id } } },
  ];
  if (!selectedAlliance.oneSided) {
    initiativeApprovalList.push({
      company: { connect: { id: selectedAlliance.partnerCompany.id } },
    });
  }

  const data = {
    id: initiative.id,
    status: INITIATIVE_SUBMITTED_FOR_APPROVAL,
    initiativeApprovalInitiativeRelation: {
      create: initiativeApprovalList,
    },
  };

  let response;
  try {
    response = await client.mutate({
      mutation: INITIATIVE_UPDATE_MUTATION,
      variables: { data },
    });
  } catch (e) {
    error('_requestApprovalForInitiative', e);
    throw e;
  }
  log('_requestApprovalForInitiative', response);
  return response.data;
};

/**
 * Fetches the Initiative approvals for the User.
 *
 * @param  {string}  initiativeId - InitiativeId.
 * @returns {Promise} Promise.
 */
export const fetchInitiativeApprovals = async (initiativeId) => {
  const client = sessionStore.getState(APOLLO_CLIENT);

  const filter = { initiative: { id: { equals: initiativeId } } };
  const sort = {
    createdAt: 'DESC',
  };

  let response;
  try {
    response = await client.query({
      query: INITIATIVE_APPROVALS_LIST_QUERY,
      variables: { filter, sort },
      fetchPolicy: 'network-only',
    });
  } catch (e) {
    error('fetchInitiativeApprovals', e);
    return Flux.dispatchEvent(INITIATIVE_ERROR_EVENT, e);
  }
  log('fetchInitiativeApprovals', response);
  return response.data;
};

/**
 * Approve and Initiative.
 *
 * @param {object} initiative - Initiative Data.
 * @returns {Promise} Promise.
 */
export const approveInitiative = async (initiative) => {
  const client = sessionStore.getState(APOLLO_CLIENT);
  const { selectedAlliance, user } = sessionStore.getState(NEW_SESSION_EVENT);
  const initiativeId = initiative.id;
  const company = getCompanyOnAlliance(user, { id: selectedAlliance.id });

  if (!canApproveInitiative(user, initiative, { id: selectedAlliance.id })) {
    OnInitiativeError.dispatch(
      new IntegrityError(`Permission Denied. Can't approve this Initiative`),
    );

    return Flux.dispatchEvent(
      INITIATIVE_ERROR_EVENT,
      new IntegrityError(`Permission Denied. Can't approve this Initiative`),
    );
  }

  // We fetch the approvals to see what's gonna happend
  const { initiativeApprovalsList } = await fetchInitiativeApprovals(initiativeId);

  const approvalsList = initiativeApprovalsList.items;

  if (!selectedAlliance.oneSided && approvalsList.length < 2) {
    OnInitiativeError.dispatch(
      new IntegrityError('Initiative must have have at least 2 request for approvals'),
    );

    return Flux.dispatchEvent(
      INITIATIVE_ERROR_EVENT,
      new IntegrityError('Initiative must have have at least 2 request for approvals'),
    );
  }

  let approvalsCounter = 0;
  let approvalId = null;
  approvalsList.forEach((approval) => {
    if (approval.status === INITIATIVE_APPROVAL_APPROVED) approvalsCounter++;
    if (approval.company.id === company.id) approvalId = approval.id;
  });

  if (approvalId === null) {
    OnInitiativeError.dispatch(
      new IntegrityError("You can't approve this Initiative, your Company does not belong here."),
    );

    return Flux.dispatchEvent(
      INITIATIVE_ERROR_EVENT,
      new IntegrityError("You can't approve this Initiative, your Company does not belong here."),
    );
  }

  // Update the status of the initiativeApproval
  const mutation = {
    mutation: INITIATIVE_APPROVAL_UPDATE_MUTATION,
    variables: {
      approval: {
        id: approvalId,
        status: INITIATIVE_APPROVAL_APPROVED,
        dateOfResponse: new Date(),
        approvedBy: { connect: { id: user.id } },
      },
    },
  };

  // If there are more than 0 approvals: Update the status of the initiative
  if (isApprovalComplete(approvalsCounter)) {
    mutation.mutation = INITIATIVE_INITIATIVE_APPROVAL_MUTATION;
    mutation.variables.initiative = {
      id: initiativeId,
      status: INITIATIVE_APPROVED,
    };
  }

  let response;
  try {
    response = await client.mutate(mutation);
  } catch (e) {
    error('approveInitiative', e);
    OnInitiativeError.dispatch(e);
    return Flux.dispatchEvent(INITIATIVE_ERROR_EVENT, e);
  }
  log('approveInitiative', response);
  await client.resetStore();
  OnInitiativeUpdate.dispatch(response.data);
  Flux.dispatchEvent(INITIATIVE_UPDATE_EVENT, response.data);
  return response.data;
};

/**
 * Reject and Initiative.
 *
 * @param {object}initiative - Initiative Object.
 * @returns {Promise}Promise.
 */
export const rejectInitiative = async (initiative) => {
  const client = sessionStore.getState(APOLLO_CLIENT);
  const user = sessionStore.getState(NEW_SESSION_EVENT).user;
  const initiativeId = initiative.id;
  const allianceId = getActiveAllianceId(user);
  const company = getCompanyOnAlliance(user, { id: allianceId });

  if (!canRejectInitiative(user, initiative, { id: allianceId })) {
    OnInitiativeError.dispatch(
      new IntegrityError(`Permission Denied. Can't reject this Initiative`),
    );

    return Flux.dispatchEvent(
      INITIATIVE_ERROR_EVENT,
      new IntegrityError(`Permission Denied. Can't reject this Initiative`),
    );
  }

  // We fetch the approvals to see what's gonna happend
  const { initiativeApprovalsList } = await fetchInitiativeApprovals(initiativeId);
  const approvalsList = initiativeApprovalsList.items.slice(0, 2);
  if (approvalsList.length < 2) {
    OnInitiativeError.dispatch(
      new IntegrityError('Initiative must have have at least 2 request for approvals'),
    );

    return Flux.dispatchEvent(
      INITIATIVE_ERROR_EVENT,
      new IntegrityError('Initiative must have have at least 2 request for approvals'),
    );
  }

  //let alliedCompanyApprovalId = null;
  let approvalId = null;
  approvalsList.forEach((approval) => {
    if (approval.company.id === company.id) {
      approvalId = approval.id;
    }
  });

  if (approvalId === null) {
    OnInitiativeError.dispatch(
      new IntegrityError("You can't reject this Initiative, your Company does not belong here."),
    );

    return Flux.dispatchEvent(
      INITIATIVE_ERROR_EVENT,
      new IntegrityError("You can't reject this Initiative, your Company does not belong here."),
    );
  }

  // Update the status of the initiativeApproval
  const userCompanyMutation = {
    mutation: INITIATIVE_INITIATIVE_APPROVAL_MUTATION,
    variables: {
      approval: {
        id: approvalId,
        status: INITIATIVE_APPROVAL_REJECTED,
        dateOfResponse: new Date(),
        approvedBy: { connect: { id: user.id } },
      },
      initiative: {
        id: initiativeId,
        status: INITIATIVE_REJECTED,
      },
    },
  };

  /*const alliedCompanyMutation = {
    mutation: INITIATIVE_APPROVAL_UPDATE_MUTATION,
    variables: {
      approval: {
        id: alliedCompanyApprovalId,
        status: INITIATIVE_APPROVAL_REJECTED,
        dateOfResponse: new Date(),
      },
    },
  };*/

  let response;
  try {
    response = await client.mutate(userCompanyMutation);
    //await client.mutate(alliedCompanyMutation);
  } catch (e) {
    error('rejectInitiative', e);
    OnInitiativeError.dispatch(e);
    return Flux.dispatchEvent(INITIATIVE_ERROR_EVENT, e);
  }
  log('rejectInitiative', response);
  await client.resetStore();
  OnInitiativeReject.dispatch(response.data);
  Flux.dispatchEvent(INITIATIVE_REJECT_EVENT, response.data);
  return response.data;
};

/**
 * Delete an Initiative.
 *
 * @param {object}initiative - Initiative.
 * @returns {Promise}Promise.
 */
export const deleteInitiative = async (initiative) => {
  const client = sessionStore.getState(APOLLO_CLIENT);
  const { user, selectedAlliance } = sessionStore.getState(NEW_SESSION_EVENT);
  const initiativeId = initiative.id;

  if (!canDeleteInitiative(user, initiative, selectedAlliance)[0]) {
    OnInitiativeError.dispatch(
      new IntegrityError(`Permission Denied. Can't Delete this Initiative`),
    );

    return Flux.dispatchEvent(
      INITIATIVE_ERROR_EVENT,
      new IntegrityError(`Permission Denied. Can't Delete this Initiative`),
    );
  }

  let response;

  try {
    response = await client.mutate({
      mutation: INITIATIVE_DELETE_MUTATION,
      variables: { data: { id: initiativeId, force: true } },
    });

    await client.resetStore();
  } catch (e) {
    error('deleteInitiative', e);
    OnInitiativeError.dispatch(e);
    return Flux.dispatchEvent(INITIATIVE_ERROR_EVENT, e);
  }
  log('deleteInitiative', response);
  OnInitiativeDelete.dispatch(response.data);
  Flux.dispatchEvent(INITIATIVE_DELETE_EVENT, response.data);
  return response.data;
};

/**
 * Close a Initiative.
 *
 * @returns {Promise}Promise.
 * @param {object}initiativeData - Initiative Data.
 */
export const completedInitiative = async (initiativeData) => {
  const client = sessionStore.getState(APOLLO_CLIENT);
  const { selectedAlliance, user } = sessionStore.getState(NEW_SESSION_EVENT);

  if (!canCompletedInitiative(user, initiativeData, selectedAlliance)) {
    OnInitiativeError.dispatch(
      new IntegrityError('You do not have permission to perform this action'),
    );

    return Flux.dispatchEvent(
      INITIATIVE_ERROR_EVENT,
      new IntegrityError('You do not have permission to perform this action'),
    );
  }

  let response;
  try {
    response = await client.mutate({
      mutation: INITIATIVE_UPDATE_MUTATION,
      variables: {
        data: {
          id: initiativeData.id,
          status: INITIATIVE_COMPLETED,
        },
      },
    });
  } catch (e) {
    error('completedInitiative', e);
    OnInitiativeError.dispatch(e);
    return Flux.dispatchEvent(INITIATIVE_ERROR_EVENT, e);
  }

  await client.resetStore();
  OnInitiativeCompleted.dispatch(response);
  Flux.dispatchEvent(INITIATIVE_COMPLETED_EVENT, response);
  return response.data;
};

/**
 * Notifies a Request for Comments for a Initiative.
 */
export const openComments = ({ id: initiativeId }) => {
  OnCommentRequest.dispatch({ type: INITIATIVE_TYPE, id: initiativeId });
  Flux.dispatchEvent(COMMENT_REQUEST_EVENT, { type: INITIATIVE_TYPE, id: initiativeId });
};

/**
 * Create a comment on a Initiative.
 *
 * @param {string} initiativeId - Initiative ID.
 * @param {object}comment - Comment.
 * @returns {Promise<*>}Promise.
 */
export const createInitiativeComment = async (initiativeId, comment) => {
  const client = sessionStore.getState(APOLLO_CLIENT);
  const data = {
    comment,
    initiativeCommentsRelation: { connect: { id: initiativeId } },
  };

  let response;
  try {
    response = await client.mutate({
      mutation: COMMENTS_CREATE_MUTATION,
      variables: { data },
    });
  } catch (e) {
    error('createInitiativeComment', e);
    OnCommentError.dispatch(e);
    return Flux.dispatchEvent(COMMENT_ERROR_EVENT, e);
  }
  log('createInitiativeComment', response);
  OnCommentCreate.dispatch(response.data);
  Flux.dispatchEvent(COMMENT_CREATE_EVENT, response.data);
  return response.data;
};

/**
 * Fetches the Initiative Item Comments.
 *
 * @returns {Promise<void>}Promise.
 * @param {string}initiativeId - Initiative Id.
 */
export const fetchInitiativeComments = async (initiativeId) => {
  const client = sessionStore.getState(APOLLO_CLIENT);
  const data = { id: initiativeId };
  const { user } = OnNewSession.get();

  let response;
  try {
    response = await client.query({
      query: INITIATIVE_COMMENTS_QUERY,
      variables: data,
      fetchPolicy: 'network-only',
    });
  } catch (e) {
    error('fetchInitiativeComments', e);
    OnCommentError.dispatch(e);
    return Flux.dispatchEvent(COMMENT_ERROR_EVENT, e);
  }
  log('fetchInitiativeComments', response);

  const comments = R.clone(response.data.initiative.comments);
  comments.userId = user.id;
  comments.items = response.data.initiative.comments.items.map((comment) => ({
    ...comment,
  }));

  OnComment.dispatch(comments);
  Flux.dispatchEvent(COMMENTS_EVENT, comments);
  return comments;
};

export const getInitiativeRelationItem = (initiative) => {
  const {
    actionInitiativesRelation,
    contributionInitiativesRelation,
    decisionInitiativesRelation,
    fundingRequestInitiativesRelation,
    ideaInitiativesRelation,
    issueInitiativesRelation,
    riskInitiativesRelation,
    dealDatatInitiativesRelation,
  } = initiative;

  dealDatatInitiativesRelation.items.forEach((deal) => (deal.__typename = 'Deal'));

  const items = [
    actionInitiativesRelation,
    contributionInitiativesRelation,
    decisionInitiativesRelation,
    fundingRequestInitiativesRelation,
    ideaInitiativesRelation,
    issueInitiativesRelation,
    riskInitiativesRelation,
    dealDatatInitiativesRelation,
  ];

  return items.flatMap((relationItems) => relationItems.items.map(getInitiativeItemByType));
};

export const sanitizeConnectInitiativeItemRelation = (relatedItems) => {
  const relations = {
    issueInitiativesRelation: { connect: [] },
    ideaInitiativesRelation: { connect: [] },
    actionInitiativesRelation: { connect: [] },
    riskInitiativesRelation: { connect: [] },
    decisionInitiativesRelation: { connect: [] },
    contributionInitiativesRelation: { connect: [] },
    fundingRequestInitiativesRelation: { connect: [] },
  };
  relatedItems.forEach((item) => {
    const id = { id: item.id };
    if (item.type === 'Issue') {
      relations.issueInitiativesRelation.connect.push(id);
    }

    if (item.type === 'Contribution') {
      relations.contributionInitiativesRelation.connect.push(id);
    }

    if (item.type === 'Action') {
      relations.actionInitiativesRelation.connect.push(id);
    }

    if (item.type === 'Idea') {
      relations.ideaInitiativesRelation.connect.push(id);
    }

    if (item.type === 'Decision') {
      relations.decisionInitiativesRelation.connect.push(id);
    }

    if (item.type === 'Risk') {
      relations.riskInitiativesRelation.connect.push(id);
    }
    if (item.type === 'Funding Request') {
      relations.fundingRequestInitiativesRelation.connect.push(id);
    }
  });

  return relations;
};

export const sanitizeReconnectInitiativeItemRelation = (relatedItems) => {
  const relations = {
    issueInitiativesRelation: { reconnect: [] },
    ideaInitiativesRelation: { reconnect: [] },
    actionInitiativesRelation: { reconnect: [] },
    riskInitiativesRelation: { reconnect: [] },
    decisionInitiativesRelation: { reconnect: [] },
    contributionInitiativesRelation: { reconnect: [] },
    fundingRequestInitiativesRelation: { reconnect: [] },
    dealDatatInitiativesRelation: { reconnect: [] },
  };
  relatedItems.forEach((item) => {
    const id = { id: item.id };
    if (item.type === 'Issue') {
      relations.issueInitiativesRelation.reconnect.push(id);
    }

    if (item.type === 'Contribution') {
      relations.contributionInitiativesRelation.reconnect.push(id);
    }

    if (item.type === 'Action') {
      relations.actionInitiativesRelation.reconnect.push(id);
    }

    if (item.type === 'Idea') {
      relations.ideaInitiativesRelation.reconnect.push(id);
    }

    if (item.type === 'Decision') {
      relations.decisionInitiativesRelation.reconnect.push(id);
    }

    if (item.type === 'Risk') {
      relations.riskInitiativesRelation.reconnect.push(id);
    }

    if (item.type === 'Funding Request') {
      relations.fundingRequestInitiativesRelation.reconnect.push(id);
    }

    if (item.type === 'Deal') {
      relations.dealDatatInitiativesRelation.reconnect.push(id);
    }
  });

  return relations;
};
/**
 * Restore Initiative.
 *
 * @param {object}initiativeData - Initiative.
 * @returns {Promise<void|*>} Return promise.
 */
export const restoreInitiative = async (initiativeData) => {
  const client = sessionStore.getState(APOLLO_CLIENT);
  const { selectedAlliance, user } = sessionStore.getState(NEW_SESSION_EVENT);

  if (!canRestoreInitiative(user, initiativeData, selectedAlliance)) {
    OnInitiativeError.dispatch(
      new IntegrityError('You do not have permission to perform this action'),
    );

    return Flux.dispatchEvent(
      INITIATIVE_ERROR_EVENT,
      new IntegrityError('You do not have permission to perform this action'),
    );
  }

  let response;

  try {
    response = await client.mutate({
      mutation: INITIATIVE_UPDATE_MUTATION,
      variables: {
        data: {
          id: initiativeData.id,
          status: INITIATIVE_IN_PROGRESS,
        },
      },
    });
  } catch (e) {
    error('restoreInitiative', e);
    OnInitiativeError.dispatch(e);
    return Flux.dispatchEvent(INITIATIVE_ERROR_EVENT, e);
  }

  await client.resetStore();
  OnInitiativeRestore.dispatch(response);
  Flux.dispatchEvent(INITIATIVE_RESTORE_EVENT, response);
  return response.data;
};

export const fetchInitiativeApprovalsList = async (initiativeId) => {
  let response;
  try {
    response = await fetchInitiativeApprovals(initiativeId);
  } catch (e) {
    error('fetchInitiativeApprovals', e);
    return Flux.dispatchEvent(INITIATIVE_ERROR_EVENT, e);
  }
  log('fetchInitiativeApprovals', response);
  Flux.dispatchEvent(INITIATIVE_APPROVALS_LIST_EVENT, response);

  return response.data;
};
