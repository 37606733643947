import React from 'react';
import styled from '@emotion/styled';
import { Navigation, Grid } from '@8base/boost';
import PropTypes from 'prop-types';

const NavTag = styled(Navigation)(() => ({
  position: 'fixed',
  left: 0,
  zIndex: 900,
}));

const NavPlate = ({ children, ...rest }) => (
  <Grid.Box area="nav">
    <NavTag {...rest}>{children}</NavTag>
  </Grid.Box>
);

NavPlate.propTypes = {
  children: PropTypes.any.isRequired,
};

export { NavPlate };
