import React from 'react';
import PropTypes from 'prop-types';
import { InputField, Column } from '@8base/boost';
import { DialogForm } from '../../../components/forms/DialogForm';
import { Small2 } from '../../../components/new-ui/font-style/Small2';

const InvitePortfolioOwnerDialog = ({
  onChange,
  email,
  onSubmit,
  isOpen,
  onCloseDialog,
  loading,
}) => {
  return (
    <DialogForm
      title={'Invite portfolio owner'}
      isOpen={isOpen}
      createText="Invite user"
      onCloseDialog={onCloseDialog}
      onClickCreate={onSubmit}
      loading={loading}>
      <Column justifyContent="center">
        <Small2>
          {`The Portfolio Owner role will be the sole manager of your company's profile information and will have the ability to manage alliances and invite other Company Administrators (Alliance Managers) to manage alliances. The Portfolio Owner is, in effect, the business manager of the Succeed Platform for your company.`}
        </Small2>
        <Small2>{`Note: previous portfolio owner invitation will be deleted.`}</Small2>
        <InputField
          stretch
          placeholder={'Enter the email'}
          input={{
            name: 'email',
            value: email,
            onChange: (value) => onChange(value),
          }}
        />
      </Column>
    </DialogForm>
  );
};

InvitePortfolioOwnerDialog.propTypes = {
  onChange: PropTypes.func.isRequired,
  onCloseDialog: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  email: PropTypes.string.isRequired,
};

export { InvitePortfolioOwnerDialog };
