import React from 'react';
import { Column } from '@8base/boost';
import { HeaderText } from './text/HeaderText';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';

const StyledColumnLeft = styled(Column)`
  width: 30%;
`;

const ColumnLeft = ({ text = '' }) => {
  return (
    <StyledColumnLeft>
      <HeaderText>{text}</HeaderText>
    </StyledColumnLeft>
  );
};

ColumnLeft.propTypes = {
  text: PropTypes.string.isRequired,
};

export { ColumnLeft };
