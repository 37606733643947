import gql from 'graphql-tag';
import { UserFragment } from '../../auth/queries';

export const AUDIT_LOG_LIST_QUERY = gql`
  query($data: AuditLogFilter, $skip: Int, $first: Int, $sort: [AuditLogSort!]) {
    auditLogsList(filter: $data, skip: $skip, first: $first, sort: $sort) {
      count
      items {
        id
        alliance {
          id
          name
        }
        objectType
        actionType
        createdBy {
          ...UserFragment
        }
        data
        createdAt
      }
    }
  }
  ${UserFragment}
`;

export const AUDIT_LOG_CREATE_MUTATION = gql`
  mutation($data: AuditLogCreateInput!) {
    auditLogCreate(data: $data) {
      id
    }
  }
`;

export const AUDIT_LOG_USER_LIST = gql`
  query($data: AuditLogGroupBy!, $filter: AuditLogFilter!) {
    auditLogsList(groupBy: $data, filter: $filter) {
      groups {
        userFirstName: JSON
        userLastName: String
        userId: ID
      }
    }
  }
`;
