import React from 'react';
import { Heading, Button, Icon, Grid, Dropdown, Loader } from '@8base/boost';
import { View } from '@cobuildlab/react-simple-state';

import { Menu } from '../../../shared/components/Menu';
import { getCurrencyOnSession } from '../../../shared/alliance-utils';
import { ViewCardBody } from '../../../components/card/ViewCardBody';
import { ReportHeader, ReportsCard } from '../components';
import {
  generateSalesPipelineTrendsTables,
  exportToExcelSalesPipelineTrendsTables,
} from './sales-pipeline-trends-utils';
import { SalesPipelineTrendsTable } from './SalesPipelineTrendsTable';
import { SalesPipelineTrendsDataHandler } from './SalesPipelineTrendsDataHandler';

class ReportView extends View {
  renderContent = () => {
    console.log(this.props);
    const { loading } = this.props;

    if (loading) {
      return <Loader stretch />;
    }

    const {
      sharedData: { clientCompany, partnerCompany, firstName, lastName, config },
      requestedData: { monthlySnapshots, requestMonthsInfo, groupBy },
    } = this.props;

    const currency = getCurrencyOnSession();

    const tablesData = generateSalesPipelineTrendsTables(
      monthlySnapshots,
      groupBy,
      requestMonthsInfo,
    );

    const reportName = 'Sales Pipeline Trends';

    return (
      <div id={'c2gScreen'}>
        <ReportHeader>
          <Heading type="h4" text={reportName} />
          {/* Temporarily commented out, these functions are in development right now
           <SearchBox /> */}
          <Grid.Box direction="row" alignItems="center" justifyContent="flex-end">
            <Dropdown defaultOpen={false}>
              <Dropdown.Head>
                <Button variant="outlined" color="GRAY4" squared>
                  <Icon name="More" />
                </Button>
              </Dropdown.Head>
              <Dropdown.Body pin="right">
                {({ closeDropdown }) => (
                  <Menu>
                    <Menu.Item
                      onClick={() => {
                        const fileName = `${reportName} ${new Date().toISOString()}`;
                        const userName = `${firstName} ${lastName}`;

                        exportToExcelSalesPipelineTrendsTables(tablesData, fileName, userName);

                        closeDropdown();
                      }}>
                      Export to Excel
                    </Menu.Item>
                  </Menu>
                )}
              </Dropdown.Body>
            </Dropdown>
          </Grid.Box>
        </ReportHeader>

        <ViewCardBody style={{ padding: 0 }} className="card-body-report">
          <SalesPipelineTrendsTable
            currency={currency}
            dealMonthlySnapshotsList={monthlySnapshots}
            groupBy={groupBy}
            requestMonthsInfo={requestMonthsInfo}
            clientCompany={clientCompany}
            partnerCompany={partnerCompany}
            config={config}
          />
        </ViewCardBody>
      </div>
    );
  };

  render() {
    return <ReportsCard stretch>{this.renderContent()}</ReportsCard>;
  }
}

export const SalesPipelineTrendsReportView = SalesPipelineTrendsDataHandler(ReportView);
