import React from 'react';
import PropTypes from 'prop-types';
import './dots.css';

const Dot = ({ active = false }) => {
  return <span className={active ? 'active' : ''} />;
};

Dot.defaultProps = {
  active: false,
};

Dot.propTypes = {
  active: PropTypes.bool,
};

export { Dot };
