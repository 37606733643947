import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PreviewDialog from '../dialogs/PreviewDialog';

/**
 * Preview icon button for imagen.
 */
const Div = styled('div')({
  cursor: 'pointer',
  width: '50px',
});

class IconPreview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      previewModalIsOpen: false,
      fileModal: null,
    };
  }

  modal = (file) => {
    let value;
    //Create
    if (file.downloadUrl) {
      fetch(file.downloadUrl)
        .then((res) => res.blob())
        .then((blob) => {
          value = {
            image: file.downloadUrl,
            name: file.filename,
            type: blob,
          };
          this.setState({
            previewModalIsOpen: true,
            fileModal: value,
          });
        });
    }
  };

  onYes = () => {
    this.setState(
      {
        previewModalIsOpen: false,
        loading: true,
      },
      () => {},
    );
  };

  onClose = () => {
    this.setState({
      previewModalIsOpen: false,
    });
  };

  render() {
    const { file, index, onClickDelete, nameInput } = this.props;
    const { previewModalIsOpen, fileModal } = this.state;
    if (file !== null) {
      return (
        <div style={{ display: 'inline-flex', 'margin-left': '45px', width: '42px' }}>
          <Div>
            <FontAwesomeIcon icon={'eye'} onClick={() => this.modal(file)} />
          </Div>
          {onClickDelete !== null && (
            <Div>
              <FontAwesomeIcon icon={'times'} onClick={() => onClickDelete(index, nameInput)} />
            </Div>
          )}
          <>
            <PreviewDialog
              onYes={this.onYes}
              onClose={this.onClose}
              onNo={this.onClose}
              file={fileModal}
              isOpen={previewModalIsOpen}
            />
          </>
        </div>
      );
    } else {
      return null;
    }
  }
}

IconPreview.propTypes = {
  //onClick: PropTypes.func,
  file: PropTypes.any.isRequired,
  //originalFile: PropTypes.any,
  nameInput: PropTypes.string,
  index: PropTypes.number,
  onClickDelete: PropTypes.func,
};

IconPreview.defaultProps = {
  onClickDelete: null,
  index: null,
  nameInput: null,
};

export default IconPreview;
