import React from 'react';
import PropTypes from 'prop-types';
import { getDueDateColor } from '../../../src/shared/items-util';

const ColorRelatedItems = ({ value, color }) => {
  const { revisedDueDate } = value;

  const dateColor = getDueDateColor(revisedDueDate);

  return (
    <div
      style={{
        position: 'absolute',
        top: 0,
        width: '8px',
        backgroundColor: color || dateColor,
        height: '100%',
        zIndex: '1',
        left: -6,
        borderTopLeftRadius: '0.5rem',
        borderBottomLeftRadius: '0.5rem',
      }}
    />
  );
};

ColorRelatedItems.propTypes = {
  value: PropTypes.object.isRequired,
  color: PropTypes.string.isRequired,
};

export { ColorRelatedItems };
