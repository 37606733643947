import { ApolloClient, InMemoryCache, HttpLink, split, from } from '@apollo/client';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';

const EIGHTBASE_WS_ENDPOINT = 'wss://ws.8base.com';
const WORKSPACE_ENDPOINT = process.env.REACT_APP_8BASE_API_ENDPOINT;
const WORKSPACE_ID = process.env.REACT_APP_8BASE_WORKSPACE_ID;

/**
 * @param {Function} getToken - Function to get the token.
 * @param {object} headers - Extra header to the client.
 *
 * @returns {object} Apollo client.
 */
export function createApolloClient(getToken, headers = {}) {
  const httpLink = new HttpLink({
    uri: WORKSPACE_ENDPOINT,
  });

  const authLink = setContext((_, { headers: _headers }) => {
    return {
      headers: {
        ...headers,
        ..._headers,
        authorization: `Bearer ${getToken()}`,
      },
    };
  });

  const errorLink = onError((error) => {
    if (error.graphQLErrors) {
      // eslint-disable-next-line no-param-reassign
      error.graphQLErrors = error.graphQLErrors.map((options) => {
        if (options.message === 'Token expired') {
          return { ...options, message: 'Your session has expired' };
        }

        return options;
      });
    }

    if (error.response) {
      // eslint-disable-next-line no-param-reassign
      error.response.errors = error.response.errors.map((options) => {
        if (options.message === 'Token expired') {
          return { ...options, message: 'Your session has expired' };
        }

        return options;
      });
    }
  });

  const wsLink = new WebSocketLink({
    uri: `${EIGHTBASE_WS_ENDPOINT}`,
    options: {
      reconnect: true,
      connectionParams: () => {
        const params = {
          token: getToken(),
          workspaceId: WORKSPACE_ID,
        };
        return params;
      },
    },
    webSocketImpl: class WebSocketWithoutProtocol extends WebSocket {
      // eslint-disable-next-line no-useless-constructor
      constructor(url) {
        super(url); // ignore protocol
      }
    },
  });

  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
    },
    wsLink,
    httpLink,
  );

  const link = from([authLink, errorLink, splitLink]);

  const client = new ApolloClient({
    uri: WORKSPACE_ENDPOINT,
    link: link,
    cache: new InMemoryCache(),
  });

  return client;
}
