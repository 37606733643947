import React from 'react';
import { SelectField } from '@8base/boost';
import PropTypes from 'prop-types';

const ItemFilter = ({ onChange, value, options, placeholder }) => {
  return (
    <SelectField
      meta={{}}
      input={{
        name: 'filter',
        value: value,
        onChange: onChange,
      }}
      placeholder={placeholder}
      options={options.map((option) => {
        if (option.label === '') {
          option.label = 'None';
        }
        return { label: option.label, value: option.value };
      })}
    />
  );
};

ItemFilter.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  placeholder: PropTypes.string,
};
ItemFilter.defaultProps = {
  placeholder: 'Filter of The items',
};

export default ItemFilter;
