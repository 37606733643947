import moment from 'moment';

const currentDate = moment().format('YYYY-MM-DD');

export const IssueModel = {
  id: null,
  name: '',
  description: '',
  documents: [],
  initiatives: [],
  nextSteps: [],
  unitType: null,
  unitQuantity: 0,
  unitValueDescription: '',
  unitMonetizationFactor: '0',
  assignedTo: null,
  assignedDate: currentDate,
  revisedDueDate: null,
  originalDueDate: null,
  source: null,
};

export const ISSUE_DOCUMENTS = 'issueDocuments';
