import Flux from '@cobuildlab/flux-state';
import sessionStore, { APOLLO_CLIENT, NEW_SESSION_EVENT } from '../../../shared/SessionStore';
import { error, log } from '@cobuildlab/pure-logger';
import { WORK_QUEUE_ITEMS_LIST_QUERY } from './work-queue-queries';
import { WORK_QUEUE_ERROR_EVENT, WORK_QUEUE_ITEMS_EVENT } from './work-queue-store';
import { getActiveAllianceId } from '../../../shared/alliance-utils';
import { INITIATIVE_SUBMITTED_FOR_APPROVAL } from '../../../shared/status';
import { onWorkQueueError, onWorkQueueItemsEvent } from './work-queue-events';

/**
 * Filter my work queue items, all alliance items &
 * all nextSteps that are assigned to a user.
 *
 * @param  {string} allianceId - The allianceId to filter.
 * @param  {string} userId - The userId to filter.
 * @param  {string} search -  The search to filter.
 * @returns {object}             The filter object.
 */
const workQueueItemsFilter = (allianceId, userId, search = '') => {
  const riskFilter = {
    risk: {
      id: { not_equals: null },
      OR: [
        {
          name: {
            contains: search,
          },
        },
      ],
    },
  };

  const issueFilter = {
    issue: {
      id: { not_equals: null },
      OR: [
        {
          name: {
            contains: search,
          },
        },
      ],
    },
  };

  const ideaFilter = {
    idea: {
      id: { not_equals: null },
      OR: [
        {
          name: {
            contains: search,
          },
        },
      ],
    },
  };

  const fundingRequestFilter = {
    fundingRequest: {
      id: { not_equals: null },
      OR: [
        {
          name: {
            contains: search,
          },
        },
      ],
    },
  };

  const actionFilter = {
    action: {
      id: { not_equals: null },
      OR: [
        {
          name: {
            contains: search,
          },
        },
      ],
    },
  };

  const itemFilter = {
    alliance: { id: { equals: allianceId } },
    OR: [riskFilter, issueFilter, ideaFilter, fundingRequestFilter, actionFilter],
  };

  // for ALLIANCE_SER
  const initiativesFilter = {
    alliance: { id: { equals: allianceId } },
    status: { equals: INITIATIVE_SUBMITTED_FOR_APPROVAL },
    OR: [
      {
        name: {
          contains: search,
        },
      },
    ],
  };

  return { itemFilter, initiativesFilter };
};

/**
 * Fetch my work queue items.
 *
 * @param  {string} search - The search to filter.
 *
 * @returns {Promise<void>} The dat with the items list.
 */
export const fetchWorkQueueItems = async (search = '') => {
  const client = sessionStore.getState(APOLLO_CLIENT);
  const { user } = sessionStore.getState(NEW_SESSION_EVENT);
  const allianceId = getActiveAllianceId(user);
  const userId = user.id;
  const { itemFilter, initiativesFilter } = workQueueItemsFilter(allianceId, userId, search);

  let response;
  try {
    response = await client.query({
      query: WORK_QUEUE_ITEMS_LIST_QUERY,
      variables: {
        itemFilter,
        initiativesFilter,
      },
      fetchPolicy: 'network-only',
    });
  } catch (e) {
    error('fetchWorkQueueItems', e);
    onWorkQueueError.dispatch(e);
    return Flux.dispatchEvent(WORK_QUEUE_ERROR_EVENT, e);
  }
  log('fetchWorkQueueItems', response.data);
  onWorkQueueItemsEvent.dispatch(response.data);
  Flux.dispatchEvent(WORK_QUEUE_ITEMS_EVENT, response.data);
  return response.data;
};
