import { GRAY_30 } from '../colors';
import { CardElement } from '@stripe/react-stripe-js';
import { error } from '@cobuildlab/pure-logger';
import sessionStore, { NEW_SESSION_EVENT } from '../SessionStore';

/**
 * Options for stripe input elements.
 *
 * @type {object}
 */
export const options = {
  iconStyle: 'solid',
  style: {
    base: {
      '::placeholder': { color: GRAY_30 },
    },
  },
};

export const USA_STATES = [
  { value: 'AL', label: 'Alabama' },
  { value: 'AK', label: 'Alaska' },
  { value: 'AS', label: 'American Samoa' },
  { value: 'AZ', label: 'Arizona' },
  { value: 'AR', label: 'Arkansas' },
  { value: 'AA', label: 'Armed Forces (AA)' },
  { value: 'AE', label: 'Armed Forces (AE)' },
  { value: 'AP', label: 'Armed Forces (AP)' },
  { value: 'CA', label: 'California' },
  { value: 'CO', label: 'Colorado' },
  { value: 'CT', label: 'Connecticut' },
  { value: 'DE', label: 'Delaware' },
  { value: 'DC', label: 'District of Columbia' },
  { value: 'FL', label: 'Florida' },
  { value: 'GA', label: 'Georgia' },
  { value: 'GU', label: 'Guam' },
  { value: 'HI', label: 'Hawaii' },
  { value: 'ID', label: 'Idaho' },
  { value: 'IL', label: 'Illinois' },
  { value: 'IN', label: 'Indiana' },
  { value: 'IA', label: 'Iowa' },
  { value: 'KS', label: 'Kansas' },
  { value: 'KY', label: 'Kentucky' },
  { value: 'LA', label: 'Louisiana' },
  { value: 'ME', label: 'Maine' },
  { value: 'MH', label: 'Marshall Islands' },
  { value: 'MD', label: 'Maryland' },
  { value: 'MA', label: 'Massachusetts' },
  { value: 'MI', label: 'Michigan' },
  { value: 'FM', label: 'Micronesia' },
  { value: 'MN', label: 'Minnesota' },
  { value: 'MS', label: 'Mississippi' },
  { value: 'MO', label: 'Missouri' },
  { value: 'MT', label: 'Montana' },
  { value: 'NE', label: 'Nebraska' },
  { value: 'NV', label: 'Nevada' },
  { value: 'NH', label: 'New Hampshire' },
  { value: 'NJ', label: 'New Jersey' },
  { value: 'NM', label: 'New Mexico' },
  { value: 'NY', label: 'New York' },
  { value: 'NC', label: 'North Carolina' },
  { value: 'ND', label: 'North Dakota' },
  { value: 'MP', label: 'Northern Mariana Islands' },
  { value: 'OH', label: 'Ohio' },
  { value: 'OK', label: 'Oklahoma' },
  { value: 'OR', label: 'Oregon' },
  { value: 'PW', label: 'Palau' },
  { value: 'PA', label: 'Pennsylvania' },
  { value: 'PR', label: 'Puerto Rico' },
  { value: 'RI', label: 'Rhode Island' },
  { value: 'SC', label: 'South Carolina' },
  { value: 'SD', label: 'South Dakota' },
  { value: 'TN', label: 'Tennessee' },
  { value: 'TX', label: 'Texas' },
  { value: 'UT', label: 'Utah' },
  { value: 'VT', label: 'Vermont' },
  { value: 'VI', label: 'Virgin Islands' },
  { value: 'VA', label: 'Virginia' },
  { value: 'WA', label: 'Washington' },
  { value: 'WV', label: 'West Virginia' },
  { value: 'WI', label: 'Wisconsin' },
  { value: 'WY', label: 'Wyoming' },
];

/**
 * Creates a stripe token, use this on submit form handler, and pass the
 * token & error to the action.
 *
 * @param  {object}  stripe - Stripe object.
 * @param  {object}  elements - Stripe elements.
 * @param  {object}  data - The data to get the stripeData.
 * @returns {Promise} The stripe response.
 */
export const createToken = async (stripe, elements, data) => {
  const {
    cardHolderName: name,
    addressLine1: address_line1,
    addressLine2: address_line2,
    city: address_city,
    state: address_state,
    country: address_country,
  } = data;
  const cardData = {
    name,
    address_line1,
    address_line2,
    address_city,
    address_state,
    address_country,
  };
  const cardElement = elements.getElement(CardElement);

  let response;
  try {
    response = await stripe.createToken(cardElement, cardData);
  } catch (e) {
    error('stripeError: ', e);
    return { error: e };
  }

  return response;
};

/**
 * Creates a stripe token, use this on submit form handler, and pass the
 * token & error to the action.
 *
 * @param  {object}  stripe - Stripe object.
 * @param  {object}  elements - Stripe elements.
 * @param  {object}  data - The data to get the stripeData.
 * @returns {Promise} The stripe response = { paymentMethod || error }.
 */
export const createPaymentMethod = async (stripe, elements, data) => {
  const { email } = sessionStore.getState(NEW_SESSION_EVENT).user;
  const {
    cardHolderName: name,
    addressLine1: line1,
    addressLine2: line2,
    city,
    state,
    country,
  } = data;

  const card = elements.getElement(CardElement);
  const billing_details = {
    name,
    email,
    address: {
      city,
      state,
      country,
      line1,
      line2,
    },
  };

  let response;
  try {
    response = await stripe.createPaymentMethod({
      type: 'card',
      billing_details,
      card,
    });
  } catch (e) {
    console.log('stripeError: ', JSON.stringify(e));
    return { error: e };
  }

  const { paymentMethod, error } = response;

  if (error) return { error };

  const { valid, message } = verifyPaymentMethod(paymentMethod);
  if (!valid) return { paymentMethod, error: { message } };

  return { paymentMethod };
};

const verifyPaymentMethod = (paymentMethod) => {
  if (!(paymentMethod.card && paymentMethod.card.checks)) {
    return {
      valid: false,
      message: "We couldn't validate your card",
    };
  }

  const { address_line1_check, address_postal_code_check, cvc_check } = paymentMethod.card.checks;

  if (address_postal_code_check === 'fail')
    return {
      valid: false,
      message: 'Invalid address postal code',
    };

  if (address_line1_check === 'fail')
    return {
      valid: false,
      message: 'Invalid billing address line 1',
    };

  if (cvc_check === 'fail')
    return {
      valid: false,
      message: 'Invalid CVC',
    };

  return { valid: true };
};
