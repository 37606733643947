import gql from 'graphql-tag';
import { UserFragment } from '../../auth/queries';
import { CommentFragment } from '../../comment/comment-queries';

/**
 * Risk Item.
 */
const RiskFragment = gql`
  fragment RiskFragment on Risk {
    id
    name
    description
    status
    createdAt
    assignedTo {
      ...UserFragment
    }
    assignedDate
    revisedDueDate
    originalDueDate
    documents {
      items {
        id
        filename
        downloadUrl
        fileId
      }
    }
    initiatives {
      items {
        id
        name
        baselineStartDate
        baselineEndDate
        owner {
          ...UserFragment
        }
        status
      }
    }
    nextSteps {
      items {
        id
        dueDate
        description
        status
        assignedTo {
          ...UserFragment
        }
      }
    }
    unitType
    unitQuantity
    unitValueDescription
    unitMonetizationFactor
    itemRiskRelation {
      id
    }
    createdBy {
      ...UserFragment
    }
    source {
      id
      name
    }
  }
  ${UserFragment}
`;

/**
 * Create.
 */
export const RISK_CREATE_MUTATION = gql`
  mutation($data: RiskCreateInput!) {
    riskCreate(data: $data) {
      ...RiskFragment
    }
  }
  ${RiskFragment}
`;

export const RISK_DETAIL_QUERY = gql`
  query($id: ID!) {
    risk(id: $id) {
      ...RiskFragment
    }
  }
  ${RiskFragment}
`;

/**
 * Update.
 */
export const RISK_UPDATE_MUTATION = gql`
  mutation($data: RiskUpdateInput!) {
    riskUpdate(data: $data) {
      ...RiskFragment
    }
  }
  ${RiskFragment}
`;

/**
 * Query for the Risk Comments.
 *
 * @type {*|*}
 */
export const RISK_COMMENTS_QUERY = gql`
  query($id: ID!) {
    risk(id: $id) {
      id
      comments {
        items {
          ...CommentFragment
        }
        count
      }
    }
  }
  ${CommentFragment}
`;
