import React, { useEffect, useState } from 'react';
import { Card, Table, Dropdown, Menu, Heading, Icon, Grid, Loader } from '@8base/boost';
import { DropdownBodyOnTable } from '../../../components/dropdown/DropdownBodyOnTable';
import { ListCardBody } from '../../../components/card/ListCardBody';
import * as toast from '../../../components/toast/Toast';
import { Link } from 'react-router-dom';
import * as R from 'ramda';
import {
  fetchAlliances,
  deleteAlliance,
  requestApprovalForAlliance,
  fetchAlliancesListExcel,
} from './alliance-actions';
import { View } from '@cobuildlab/react-simple-state';
import YesNoDialog from '../../../components/dialogs/YesNoDialog';
import PropTypes from 'prop-types';
import sessionStore, { NEW_SESSION_EVENT } from '../../../shared/SessionStore';
import Moment from 'react-moment';
import '../css/alliance.css';
import Status from '../../../components/Status';
import { debounce, generatedExcel } from '../../../shared/utils';
import SearchInput from '../../../components/inputs/SearchInput';
import { ActionLinkButton } from '../../../components/buttons/ActionLinkButton';
import { onErrorMixin, onListScrollMixin } from '../../../shared/mixins';
import DetailDateValue from '../../../components/DetailDateValue';
import {
  canCreateAlliance,
  canDeleteAllianceV2,
  canEditAlliancePermission,
  canSubmitForApprovalAlliancePermission,
} from '@cobuildlab/collabtogrow-permissions';
import ItemFilter from '../../../components/ItemFilter';
import { ALLIANCE_STATUS_LABELS } from '../../../shared/status';
import { Body } from '../../../components/new-ui/font-style/Body';
import { COLUMNSEXCEL, COLUMNSOBJECT, MONTHS } from './Alliance.model.js';
import { fetchRemainingAlliancesOnPlan } from '../../alliance/alliance-actions';
import { MoreActionButton } from '../../../components/buttons/MoreActionButton';
import { ALLIANCE_UPDATE_BY_FILTER_SUBSCRIPTION } from './alliance-queries';
import { getToken } from '../../../shared/apollo/helpers';
import { useSubscription } from '@apollo/client';
import { createApolloClient } from '../../../shared/apollo';
import {
  OnAllianceError,
  OnAllianceList,
  OnAllianceExcelList,
  OnAllianceSubmitForApproval,
  OnAllianceDelete,
  OnRemainingAlliances,
} from './alliance-events';

// eslint-disable-next-line
/**
 * List All the Alliances.
 *
 */
class AllianceListView extends View {
  constructor(props) {
    super(props);
    this.state = {
      alliances: [],
      loading: true,
      loadingPage: false,
      alliance: null,
      deleteModalIsOpen: false,
      approvalModalIsOpen: false,
      search: '',
      page: 1,
      count: 0,
      filter: '',
      remainingAlliances: 0,
    };
    this.user = sessionStore.getState(NEW_SESSION_EVENT).user;
    this.onError = onErrorMixin.bind(this);
    this.onListScroll = onListScrollMixin.bind(this);
    this.searchWithDebounce = debounce(300, this.searchWithDebounce)();
  }

  componentDidMount() {
    this.subscribe(OnAllianceError, this.onError);
    this.subscribe(OnRemainingAlliances, (remainingAlliances) =>
      this.setState({ remainingAlliances }),
    );

    this.subscribe(OnAllianceList, (state) => {
      const { count } = R.clone(state.alliancesList);
      const items = state.alliancesList.items.map((alliance) => ({
        ...alliance,
      }));

      const { loadingPage, alliances: oldAlliancesList } = this.state;
      const alliances = loadingPage ? oldAlliancesList.concat(items) : items;
      this.setState({
        alliances,
        count,
        loading: false,
        loadingPage: false,
      });
    });

    this.subscribe(OnAllianceDelete, () => {
      const { search, filter } = this.state;
      this.setState({ page: 1 }, () => {
        toast.success('Alliance Deleted!');
        fetchAlliances(search, 1, 20, filter);
        fetchRemainingAlliancesOnPlan();
      });
    });

    this.subscribe(OnAllianceSubmitForApproval, () => {
      const { search, filter } = this.state;
      this.setState({ page: 1 }, () => {
        toast.success('Alliance Submitted!');
        fetchAlliances(search, 1, 20, filter);
      });
    });

    this.subscribe(OnAllianceExcelList, (state) => {
      const { alliancesList } = state;
      const items = alliancesList.items.map((alliance) => ({
        ...alliance,
      }));

      items.forEach((data) => {
        data.allianceKPIAllianceRelation.items.forEach((value, i) => {
          let months = [];
          value.monthByMonth.forEach((aux, index) => {
            months.push({ [MONTHS[index]]: aux });
          });
          value['monthByMonth'] = months;
          data['kpi' + i] = value;
        });
      });
      generatedExcel(items, COLUMNSOBJECT, COLUMNSEXCEL, 'AllianceList');
      this.setState({ loading: false });
    });

    fetchAlliances();
    fetchRemainingAlliancesOnPlan();
  }

  componentDidUpdate() {
    if (this.props.data && this.props.data.Alliance && this.props.data.Alliance.node) {
      const changedAlliance = this.props.data.Alliance.node;
      const auxAlliances = [...this.state.alliances];
      // TRY FIND INDEX IF THE ALLIANCE EXIST
      const index = auxAlliances.findIndex((alliance) => alliance.id === changedAlliance.id);
      if (index !== -1) {
        const oldAlliance = auxAlliances[index];
        let render = false;
        Object.keys(changedAlliance).forEach((key) => {
          if (changedAlliance[key] !== oldAlliance[key]) render = true;
        });
        if (render) {
          const newAlliance = { ...auxAlliances[index], ...changedAlliance };
          auxAlliances[index] = newAlliance;
          this.setState({ alliances: auxAlliances });
        }
      }
    }
  }

  onSelectForDelete = (alliance) => {
    const [canDelete, error] = canDeleteAllianceV2(this.user, alliance);
    if (canDelete) {
      this.setState({
        alliance,
        deleteModalIsOpen: true,
      });
    } else {
      toast.error(error);
    }
  };

  onSelectForApproval = (alliance) => {
    this.setState({
      alliance,
      approvalModalIsOpen: true,
    });
  };

  onYes = () => {
    const alliance = R.clone(this.state.alliance);
    this.setState(
      {
        deleteModalIsOpen: false,
        loading: true,
      },
      () => deleteAlliance(alliance),
    );
  };

  onYesApproval = () => {
    this.setState(
      {
        approvalModalIsOpen: false,
        loading: true,
      },
      () => requestApprovalForAlliance(R.clone(this.state.alliance)),
    );
  };

  onClose = () => {
    this.setState({
      deleteModalIsOpen: false,
      approvalModalIsOpen: false,
    });
  };

  onSearchChange = (value) => {
    this.setState({ search: value });
    this.searchWithDebounce(value);
  };

  searchWithDebounce = (value) => {
    const { filter } = this.state;
    this.setState({ loading: true }, () => {
      fetchAlliances(value, 1, 20, filter);
    });
  };

  statusFilter = (value) => {
    const { search } = this.state;
    this.setState(
      {
        loading: true,
        filter: value,
      },
      () => {
        fetchAlliances(search, 1, 20, value);
      },
    );
  };

  exportExcel = () => {
    this.setState({ loading: true });
    fetchAlliancesListExcel();
  };

  render() {
    const {
      deleteModalIsOpen,
      approvalModalIsOpen,
      search,
      loading,
      loadingPage,
      alliances,
      filter,
      remainingAlliances,
    } = this.state;

    return (
      <div className="items-card" id={'c2gScreen'}>
        <Card.Header>
          <Grid.Layout
            columns="200px auto 200px"
            areas={[['left', 'center', 'right']]}
            style={{ width: '100%' }}>
            <Grid.Box justifySelf="flex-start" area="left">
              <Heading type="h4" text="Alliances" />
            </Grid.Box>
            <Grid.Box justifySelf="center" area="center">
              <SearchInput className="search-input" value={search} onChange={this.onSearchChange} />
            </Grid.Box>
            <Grid.Box>
              <div style={{ width: '70%' }}>
                <ItemFilter
                  onChange={this.statusFilter}
                  value={filter}
                  options={ALLIANCE_STATUS_LABELS}
                  placeholder="Filter By Status"
                />
              </div>
              <Dropdown defaultOpen={false} style={{ position: 'absolute', right: '0px' }}>
                <Dropdown.Head>
                  <MoreActionButton />
                </Dropdown.Head>
                <Dropdown.Body pin="right">
                  {({ closeDropdown }) => (
                    <Menu>
                      <Menu.Item
                        onClick={() => {
                          this.exportExcel();
                          closeDropdown();
                        }}>
                        Export to Excel
                      </Menu.Item>
                    </Menu>
                  )}
                </Dropdown.Body>
              </Dropdown>
            </Grid.Box>
            <Grid.Box justifySelf="flex-end" />
          </Grid.Layout>
        </Card.Header>
        <ListCardBody className="items-table">
          <Table>
            <Table.Header
              className="justify-center-column"
              columns="270px 270px 270px 270px 270px 270px">
              <Table.HeaderCell className="name-column">Name</Table.HeaderCell>
              <Table.HeaderCell>Status</Table.HeaderCell>
              <Table.HeaderCell>Date Created</Table.HeaderCell>
              <Table.HeaderCell>Owner</Table.HeaderCell>
              <Table.HeaderCell>Options</Table.HeaderCell>
              <Table.HeaderCell>
                Approvals <br />
                CLIENT / PARTNER{' '}
              </Table.HeaderCell>
            </Table.Header>
            <Table.Body
              onScroll={(event) => this.onListScroll(event, alliances, fetchAlliances)}
              loading={loading}
              data={alliances}
              className="card-body-list">
              {(alliance, index) => {
                const isLast = index === alliances.length - 1;
                const pageLoader = isLast && loadingPage ? <Loader stretch /> : null;
                const approvalItems = [];
                alliance.allianceApprovalRelation.items.slice(-2).forEach((approval) => {
                  if (approval.company.id === alliance.clientCompany.id) {
                    approvalItems.unshift(approval);
                  } else approvalItems.push(approval);
                });

                return (
                  <>
                    <Table.BodyRow columns="340px 265px 270px 210px 270px 400px" key={alliance.id}>
                      <Table.BodyCell className="name-column table-cell">
                        <Link
                          className="item-name"
                          to={`/settings/alliance-management/${alliance.id}/`}>
                          {alliance.name}
                        </Link>
                      </Table.BodyCell>
                      <Table.BodyCell className="table-cell">
                        <Status status={alliance.status} />
                      </Table.BodyCell>
                      <Table.BodyCell className="table-cell">
                        <Body>
                          <Moment format="MMMM Do, YYYY">
                            {R.pathOr(
                              <span style={{ color: 'lightgrey' }}>Not Available</span>,
                              ['createdAt'],
                              alliance,
                            )}
                          </Moment>
                        </Body>
                      </Table.BodyCell>
                      <Table.BodyCell className="justify-left-row table-cell">
                        <Body>
                          {R.pathOr('', ['owner', 'firstName'], alliance) +
                            ' ' +
                            R.pathOr('', ['owner', 'lastName'], alliance)}
                        </Body>
                      </Table.BodyCell>
                      <Table.BodyCell className="justify-center-row">
                        <Dropdown defaultOpen={false}>
                          <Dropdown.Head>
                            <Icon name="More" className="more-icon" />
                          </Dropdown.Head>
                          <DropdownBodyOnTable>
                            {({ closeDropdown }) => (
                              <Menu>
                                <Menu.Item
                                  onClick={() => {
                                    this.props.history.push(
                                      `/settings/alliance-management/${alliance.id}`,
                                    );
                                  }}>
                                  Details
                                </Menu.Item>
                                {canSubmitForApprovalAlliancePermission(this.user, alliance) ? (
                                  <Menu.Item
                                    onClick={() => {
                                      closeDropdown();
                                      this.onSelectForApproval(alliance);
                                    }}>
                                    Submit For Approval
                                  </Menu.Item>
                                ) : (
                                  ''
                                )}
                                {canEditAlliancePermission(this.user, alliance) ? (
                                  <Menu.Item
                                    onClick={() => {
                                      this.props.history.push(
                                        `/settings/alliance-management/edit/${alliance.id}`,
                                      );
                                    }}>
                                    Edit
                                  </Menu.Item>
                                ) : (
                                  ''
                                )}
                                <Menu.Item
                                  onClick={() => {
                                    closeDropdown();
                                    this.onSelectForDelete(alliance);
                                  }}>
                                  Delete
                                </Menu.Item>
                              </Menu>
                            )}
                          </DropdownBodyOnTable>
                        </Dropdown>
                      </Table.BodyCell>
                      <Table.BodyCell>
                        {approvalItems.map((approval, i) => (
                          <div key={i}>
                            <Status status={approval.status} />
                            <DetailDateValue date={approval.dateOfResponse} />
                          </div>
                        ))}
                      </Table.BodyCell>
                    </Table.BodyRow>
                    {pageLoader}
                  </>
                );
              }}
            </Table.Body>
          </Table>
          {canCreateAlliance(this.user) && remainingAlliances > 0 ? (
            <ActionLinkButton
              url={'/settings/alliance-management/create'}
              textValue="Create Alliance"
            />
          ) : null}
        </ListCardBody>
        <YesNoDialog
          isOpen={deleteModalIsOpen}
          onYes={this.onYes}
          onNo={this.onClose}
          onClose={this.onClose}
          text={'Are you sure you want to Delete this Alliance?'}
          title={'Delete Alliance'}
        />
        <YesNoDialog
          isOpen={approvalModalIsOpen}
          onYes={this.onYesApproval}
          onNo={this.onClose}
          onClose={this.onClose}
          text={'Are you sure you want to Submit this Alliance For Approval?'}
          title={'Submit For Approval'}
        />
      </div>
    );
  }
}

AllianceListView.propTypes = {
  history: PropTypes.object.isRequired,
};

const WithSubscription = ({ history }) => {
  const { email } = sessionStore.getState(NEW_SESSION_EVENT).user;
  const [client, setClient] = useState(createApolloClient(getToken));
  const [data, setData] = useState(undefined);
  useEffect(() => {
    if (!client) {
      setClient(createApolloClient(getToken));
    }
  }, [client]);
  const filter = {
    allianceUserAllianceRelation: {
      some: { companyUser: { user: { email: { equals: email } } } },
    },
  };
  useSubscription(ALLIANCE_UPDATE_BY_FILTER_SUBSCRIPTION, {
    variables: {
      filter,
    },
    fetchPolicy: 'network-only',
    client,
    onSubscriptionData: ({ subscriptionData }) => {
      console.log('DEBUG:WithSubscription:onSubscriptionData:', subscriptionData);
      setData(subscriptionData.data);
    },
  });
  return <AllianceListView history={history} data={data} />;
};

WithSubscription.propTypes = {
  history: PropTypes.object.isRequired,
};

export default WithSubscription;
