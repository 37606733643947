import Flux from '@cobuildlab/flux-state';

/**
 * Event that triggers a Issue error.
 *
 * @type {string}
 */
export const ISSUE_ERROR_EVENT = 'onIssueError';

/**
 * Event that triggers a Issue Detail event.
 *
 * @type {string}
 */
export const ISSUE_DETAIL_EVENT = 'onIssueDetail';

/**
 * Event that triggers a Issue Create event.
 *
 * @type {string}
 */
export const ISSUE_CREATE_EVENT = 'onIssueCreate';

/**
 * Event that triggers a Issue Update event.
 *
 * @type {string}
 */
export const ISSUE_UPDATE_EVENT = 'onIssueUpdate';

/**
 * Event that triggers the fetch of the Issue Form Data.
 *
 * @type {string}
 */
export const ISSUE_FORM_DATA_EVENT = 'onIssueFormData';

/**
 * Event that triggers the fetch of the Issue Form Data.
 *
 * @type {string}
 */
export const ISSUE_COMPLETED_EVENT = 'onIssueCompleted';

/**
 * Event that triggers the fetch of the Issue Restore.
 *
 * @type {string}
 */
export const ISSUE_RESTORE_EVENT = 'onIssueRestore';

/**
 * Event that triggers the ISSUE_AUTO_SAVE EVENT.
 *
 * @type {string}
 */
export const ISSUE_AUTO_SAVE_EVENT = 'ISSUE_AUTO_SAVE_EVENT';

/**
 * Event that triggers the ISSUE_AUTO_SAVE_ERROR_EVENT.
 *
 * @type {string}
 */
export const ISSUE_AUTO_SAVE_ERROR_EVENT = 'ISSUE_AUTO_SAVE_ERROR_EVENT';
/**
 * Hold the Investment Item Data.
 */
class IssueStore extends Flux.DashStore {
  constructor() {
    super();
    this.addEvent(ISSUE_ERROR_EVENT);
    this.addEvent(ISSUE_CREATE_EVENT);
    this.addEvent(ISSUE_FORM_DATA_EVENT);
    this.addEvent(ISSUE_DETAIL_EVENT);
    this.addEvent(ISSUE_UPDATE_EVENT);
    this.addEvent(ISSUE_COMPLETED_EVENT);
    this.addEvent(ISSUE_RESTORE_EVENT);
    this.addEvent(ISSUE_AUTO_SAVE_EVENT);
    this.addEvent(ISSUE_AUTO_SAVE_ERROR_EVENT);
  }
}

export default new IssueStore();
