import React from 'react';
import { Row, Column, Text, Radio } from '@8base/boost';
import PropTypes from 'prop-types';
import { getPlanColor, getPlanSavingsOrPeriod } from '../procurer-utils';
import { PlanPrice } from './PlanPrice';
import { CurrentPlan } from './CurrentPlan';
import { PlanName } from './PlanName';
import { PlanPaper } from './PlanPaper';

const PlanCard = ({ plan, selected, onSelect, currentPlan, showAnnualPrice, disabled }) => {
  const { id, name } = plan;
  const selectedName = selected ? selected.name : '';
  const isSelected = selected && selected.id === id;
  const capitalizeName = name.toUpperCase();
  const color = getPlanColor(name);
  const planSavings = getPlanSavingsOrPeriod(plan, showAnnualPrice);
  const onClick = disabled ? () => {} : () => onSelect(plan);
  const currentPlanContent = currentPlan && currentPlan.id === plan.id ? <CurrentPlan /> : null;

  return (
    <PlanPaper
      title={
        disabled &&
        'To downgrade the subscription you must remove the number of alliances required to fit into your new plan. Please, contact your Company Portfolio Owner to make these updates.'
      }
      name={name}
      disabled={disabled}
      onClick={onClick}
      padding={'md'}
      selected={isSelected}>
      <Row>
        <Column>
          <Radio.Item
            style={{ marginTop: '2px' }}
            value={name}
            name={name}
            selectedValue={selectedName}
          />
        </Column>
        <Column stretch>
          <Row alignItems="center" gap="md">
            <Column>
              <PlanName weight="bold" color={color}>
                {capitalizeName}
              </PlanName>
            </Column>
            <Column>{currentPlanContent}</Column>
          </Row>
          <Text weight="normal">{planSavings}</Text>
        </Column>
        <Column stretch alignItems="end">
          <PlanPrice plan={plan} showAnnualPrice={showAnnualPrice} />
        </Column>
      </Row>
    </PlanPaper>
  );
};

PlanCard.defaultProps = {
  selected: null,
  disabled: false,
};

PlanCard.propTypes = {
  plan: PropTypes.object.isRequired,
  currentPlan: PropTypes.object.isRequired,
  onSelect: PropTypes.func.isRequired,
  selected: PropTypes.object,
  showAnnualPrice: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
};

export { PlanCard };
