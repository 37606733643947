import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import { DateInputField } from '@8base/boost';

import { DateFieldsContainer } from '../components';

const DateMonthSetCondField = React.memo(({ input: { name } }) => (
  <DateFieldsContainer>
    <Field
      name={`${name}[0]`}
      label="From"
      placeholder="mm/yyyy"
      component={DateInputField}
      isMonthPicker
    />
    <Field
      name={`${name}[1]`}
      label="To"
      placeholder="mm/yyyy"
      component={DateInputField}
      isMonthPicker
    />
  </DateFieldsContainer>
));

DateMonthSetCondField.displayName = 'DateMonthSetCondField';
DateMonthSetCondField.propTypes = {
  input: PropTypes.object.isRequired,
};

export { DateMonthSetCondField };
