import { Text } from '@8base/boost';
import { getKPIColor } from '../balanced-scorecard-utils';
import styled from '@emotion/styled';

const KPIDot = styled(Text)`
  height: 13px;
  width: 13px;
  margin-top: 3px;
  background-color: ${({ progress }) => getKPIColor(progress)};
  border-radius: 50%;
  display: inline-block;
`;

export { KPIDot };
