import moment from 'moment';

const currentDate = moment().format('YYYY-MM-DD');

export default {
  id: null,
  name: '',
  description: '',
  documents: [],
  assignedTo: null,
  initiatives: [],
  nextSteps: [],
  requestedBy: null,
  requestedDate: currentDate,
  assignedDate: currentDate,
  originalDueDate: null,
  revisedDueDate: null,
  unitType: null,
  unitQuantity: 0,
  unitValueDescription: '',
  unitMonetizationFactor: '0',
  source: null,
};

export const IDEA_DOCUMENTS = 'ideaDocuments';

export const COLUMNSEXCEL = [
  { header: 'ID', key: 'id', width: 32 },
  { header: 'NAME', key: 'name', width: 32 },
  { header: 'DESCRIPTION', key: 'description', width: 32 },
  { header: 'STATUS', key: 'status', width: 32 },
  { header: 'REVISED_DUE_DATE', key: 'revisedDueDate', width: 32 },
  { header: 'ASSIGNED_TO_EMAIL', key: 'assignedToEmail', width: 32 },
  { header: 'ASSIGNED_TO_FIRSTNAME', key: 'assignedToFirstName', width: 32 },
  { header: 'ASSIGNED_TO_LASTNAME', key: 'assignedToLastName', width: 32 },
  { header: 'REQUESTED_BY_EMAIL', key: 'requestedByEmail', width: 32 },
  { header: 'REQUESTED_BY_FIRSTNAME', key: 'requestedByFirstName', width: 32 },
  { header: 'REQUESTED_BY_LASTNAME', key: 'requestedByLastName', width: 32 },
  { header: 'INITIATIVES1_NAME', key: 'initiatives1Name', width: 32 },
  { header: 'INITIATIVES1_STATUS', key: 'initiatives1Status', width: 32 },
  { header: 'INITIATIVES1_BASELINE_START_DATE', key: 'initiatives1BaselineStartDate', width: 32 },
  { header: 'INITIATIVES1_BASELINE_END_DATE', key: 'initiatives1BaselineEndDate', width: 32 },
  { header: 'INITIATIVES2_NAME', key: 'initiatives2Name', width: 32 },
  { header: 'INITIATIVES2_STATUS', key: 'initiatives2Status', width: 32 },
  { header: 'INITIATIVES2_BASELINE_START_DATE', key: 'initiatives2BaselineStartDate', width: 32 },
  { header: 'INITIATIVES2_BASELINE_END_DATE', key: 'initiatives2BaselineEndDate', width: 32 },
  { header: 'INITIATIVES3_NAME', key: 'initiatives3Name', width: 32 },
  { header: 'INITIATIVES-3_STATUS', key: 'initiatives3Status', width: 32 },
  { header: 'INITIATIVES_3_BASELINE_START_DATE', key: 'initiatives3BaselineStartDate', width: 32 },
  { header: 'INITIATIVES_3_BASELINE_END_DATE', key: 'initiatives3BaselineEndDate', width: 32 },
  { header: 'INITIATIVES_4_NAME', key: 'initiatives4Name', width: 32 },
  { header: 'INITIATIVES_4_STATUS', key: 'initiatives4Status', width: 32 },
  { header: 'INITIATIVES_4_BASELINE_START_DATE', key: 'initiatives4BaselineStartDate', width: 32 },
  { header: 'INITIATIVES_4_BASELINE_END_DATE', key: 'initiatives4BaselineEndDate', width: 32 },
];

export const COLUMNSOBJECT = [
  {
    key: 'initiatives0',
    value: [
      { initiatives1Name: 'name' },
      { initiatives1Status: 'status' },
      { initiatives1BaselineStartDate: 'baselineStartDate' },
      { initiatives1BaselineEndDate: 'baselineEndDate' },
      { initiatives1OwnerFirstName: 'firstName' },
      { initiatives1OwnerLastName: 'lastName' },
      { initiatives1OwnerEmail: 'email' },
    ],
  },
  {
    key: 'initiatives1',
    value: [
      { initiatives2Name: 'name' },
      { initiatives2Status: 'status' },
      { initiatives2BaselineStartDate: 'baselineStartDate' },
      { initiatives2BaselineEndDate: 'baselineEndDate' },
      { initiatives2OwnerFirstName: 'firstName' },
      { initiatives2OwnerLastName: 'lastName' },
      { initiatives2OwnerEmail: 'email' },
    ],
  },
  {
    key: 'initiatives2',
    value: [
      { initiatives3Name: 'name' },
      { initiatives3Status: 'status' },
      { initiatives3BaselineStartDate: 'baselineStartDate' },
      { initiatives3BaselineEndDate: 'baselineEndDate' },
      { initiatives3OwnerFirstName: 'firstName' },
      { initiatives3OwnerLastName: 'lastName' },
      { initiatives3OwnerEmail: 'email' },
    ],
  },
  {
    key: 'initiatives3',
    value: [
      { initiatives4Name: 'name' },
      { initiatives4Status: 'status' },
      { initiatives4BaselineStartDate: 'baselineStartDate' },
      { initiatives4BaselineEndDate: 'baselineEndDate' },
      { initiatives4OwnerFirstName: 'firstName' },
      { initiatives4OwnerLastName: 'lastName' },
      { initiatives4OwnerEmail: 'email' },
    ],
  },
  {
    key: 'assignedTo',
    value: [
      { assignedToEmail: 'email' },
      { assignedToFirstName: 'firstName' },
      { assignedToLastName: 'lastName' },
    ],
  },
  {
    key: 'requestedBy',
    value: [
      { requestedByEmail: 'email' },
      { requestedByFirstName: 'firstName' },
      { requestedByLastName: 'lastName' },
    ],
  },
];
