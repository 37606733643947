import React from 'react';
import PropTypes from 'prop-types';
import { DataCell, TR } from '../Components';

const DataRow = ({ row, currencyFields }) => (
  <TR>
    {row.map((cell, index) => (
      <DataCell cell={cell} currencyFields={currencyFields} key={index} index={index} />
    ))}
  </TR>
);

DataRow.propTypes = {
  row: PropTypes.array.isRequired,
  currencyFields: PropTypes.array.isRequired,
};

export { DataRow };
