import { createEvent } from '@cobuildlab/react-simple-state';

export const OnDealsList = createEvent();
export const OnDealError = createEvent();
export const OnDealDelete = createEvent();
export const OnDealStageList = createEvent();
export const OnDealCompaniesList = createEvent();
export const OnDealDeleteList = createEvent();
export const OnDealsFullList = createEvent();
export const OnDealCreate = createEvent();
export const OnDealAutoSaveCreate = createEvent();
export const OnDealAutoSaveCreateError = createEvent();
export const OnDealDetail = createEvent();
export const OnDealRelatedList = createEvent();
export const OnDealComplete = createEvent();
export const OnDealUpdate = createEvent();
export const OnDealImported = createEvent();
export const OnDealImportedError = createEvent();
export const OnDealImportedHeaderError = createEvent();
export const OnDealImportedValid = createEvent();
export const OnAssociatedDeals = createEvent();
export const OnDealAllData = createEvent();
