import React from 'react';
import Sidebar from 'react-sidebar';
import { View } from '@cobuildlab/react-simple-state';
import { onErrorMixin } from '../../shared/mixins';
import { Loader, Card } from '@8base/boost';
import { NotificationsHeader } from './components/NotificationsHeader';
import { Notification } from './components/Notification';
import * as moment from 'moment';
import {
  deleteAllNotifications,
  deleteNotification,
  fetchNotifications,
} from './notifications-actions';
import { getNotificationByType } from './notifications-utils';
import CloseIcon from '../../components/CloseIcon';
import {
  OnNotificationRequest,
  OnNotification,
  OnNotificationError,
} from '../notifications/notifications-events';
import { OnNewSession } from '../../shared/session-events';

/**
 * Notifications Side Bar.
 *
 */

class NotificationsSideBar extends View {
  constructor(props) {
    super(props);
    this.state = {
      sidebarOpen: false,
      loading: true,
      notifications: [],
    };
    this.onError = onErrorMixin.bind(this);
  }

  async componentDidMount() {
    this.subscribe(OnNotificationError, this.onError);
    this.subscribe(OnNewSession, () => fetchNotifications());

    this.subscribe(OnNotificationRequest, (event) => {
      if (event === null) {
        this.onSetSidebarOpen();
        fetchNotifications();
        this.setState({ loading: true });
      } else {
        this.setState({ sidebarOpen: false });
      }
    });

    this.subscribe(OnNotification, (notifications) => {
      const selectedNotifications = notifications.map((notification) => ({
        ...notification,
      }));

      const toTimestamp = (date) => new Date(date).getTime();

      const sortedNotifications = selectedNotifications.sort(
        (a, b) => toTimestamp(b.createdAt) - toTimestamp(a.createdAt),
      );
      this.setState({
        notifications: sortedNotifications.map(getNotificationByType),
        loading: false,
      });
    });
  }

  onMarkAllAsRead = () => {
    this.setState(
      {
        sidebarOpen: false,
        notifications: [],
      },
      () => deleteAllNotifications(),
    );
  };

  goToNotification = () => {
    this.setState({ sidebarOpen: false });
  };

  markAsRead = (notification) => {
    const index = this.state.notifications.indexOf(notification);
    let notifications = [...this.state.notifications];
    notifications.splice(index, 1);
    this.setState({ notifications }, () => deleteNotification(notification.id));
  };

  onSetSidebarOpen = () => {
    this.setState((prevState) => ({
      sidebarOpen: !prevState.sidebarOpen,
    }));
  };

  render() {
    const { loading, sidebarOpen, notifications } = this.state;

    const closeStyles = {
      position: 'absolute',
      top: 23,
      right: 20,
      width: 30,
      height: 30,
    };

    let content = (
      <div className="loaderSideBar">
        <Loader stretch />;
      </div>
    );

    if (!loading) {
      content = (
        <>
          <Card.Body padding="none" style={{ margin: '0', width: '315px' }}>
            {notifications.map((notification) => {
              return (
                <Notification
                  key={notification.id}
                  notification={notification}
                  onClick={this.markAsRead}
                  goToNotification={this.goToNotification}
                />
              );
            })}
          </Card.Body>
        </>
      );
    }
    const year = moment().format('Y');
    // const year = moment().tz(moment.tz.guess()).format('Y');
    const _date = moment().format('LLLL');
    // const _date = moment().tz(moment.tz.guess()).format('LLLL');
    const date = _date.substr(0, _date.lastIndexOf(year) + year.length);
    return (
      <Sidebar
        styles={{
          sidebar: {
            top: 60,
            background: 'white',
            zIndex: 3,
          },
        }}
        sidebar={
          <>
            <NotificationsHeader
              text={date}
              onClose={this.onSetSidebarOpen}
              onMarkAllAsRead={this.onMarkAllAsRead}
            />
            <CloseIcon onClick={this.onSetSidebarOpen} style={closeStyles} />
            {content}
          </>
        }
        defaultSidebarWidth={500}
        open={sidebarOpen}
        onSetOpen={this.onSetSidebarOpen}
        pullRight={true}>
        {this.props.children}
      </Sidebar>
    );
  }
}

export { NotificationsSideBar };
