import * as R from 'ramda';

export const CreditCard = {
  cardHolderName: '',
  addressLine1: '',
  addressLine2: '',
  city: '',
  state: '',
  country: 'US',
};

export const CompanySubscription = {
  subscriptionPlan: null,
  creditCard: R.clone(CreditCard),
};
