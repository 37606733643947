import { ValidationError } from '../../../shared/errors';
import {
  isValidString,
  setErrorIfDateIsBeforeAllianceStart,
  isValidBigInt,
} from '../../../shared/validators';
import moment from 'moment';
import { nextStepsValidator } from '../../../modules/next-step/next-step-validators';

/**
 * Validate that the Idea has all the information necessary to create it.
 *
 * @param  {object} data - The Idea to be reviewed.
 * @param { object } allianceDetails - Details.
 */
export const ideaValidator = (data, allianceDetails) => {
  let errorMessages = [];

  if (!isValidString(data.name)) errorMessages.push('The Idea must have a valid Name');

  if (!isValidString(data.description))
    errorMessages.push('The Idea must have a valid Description');

  if (Array.isArray(data.nextSteps) && data.nextSteps.length > 0) {
    try {
      nextStepsValidator(data.nextSteps);
    } catch (e) {
      errorMessages.push(e.message);
    }
  } else errorMessages.push('The Idea must have valid Next Steps');

  if (!data.requestedBy) errorMessages.push('The Idea must have a valid Requested by User');

  if (!moment(data.requestedDate).isValid()) {
    errorMessages.push('The Idea must have a valid Requested Date');
  } else {
    setErrorIfDateIsBeforeAllianceStart(
      data.requestedDate,
      allianceDetails,
      'Requested Date',
      errorMessages,
    );
  }

  if (!data.assignedTo) errorMessages.push('The Idea must have a valid Assignee');

  if (!moment(data.assignedDate).isValid()) {
    errorMessages.push('The Idea must have a valid Assigned Date');
  } else {
    setErrorIfDateIsBeforeAllianceStart(
      data.assignedDate,
      allianceDetails,
      'Assigned Date',
      errorMessages,
    );
  }

  if (moment(data.revisedDueDate).isValid()) {
    setErrorIfDateIsBeforeAllianceStart(
      data.revisedDueDate,
      allianceDetails,
      'Revised Due Date',
      errorMessages,
    );
  }

  if (!moment(data.originalDueDate).isValid()) {
    errorMessages.push('The Idea must have a valid Original Due Date');
  } else {
    setErrorIfDateIsBeforeAllianceStart(
      data.originalDueDate,
      allianceDetails,
      'Original Due Date',
      errorMessages,
    );
  }

  if (!isValidString(data.unitType, false, true))
    errorMessages.push('The Idea must have a valid Unit Type');

  if (!(parseFloat(data.unitQuantity) >= 0)) errorMessages.push('The Idea must have a valid Unit');

  if (!isValidString(data.unitValueDescription, true, true))
    errorMessages.push('The Idea must have a valid Unit Description');

  if (!isValidBigInt(data.unitMonetizationFactor))
    errorMessages.push('The Idea must have a valid Unit Monetization Factor');

  if (errorMessages.length !== 0) throw new ValidationError(errorMessages);
};
