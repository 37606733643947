import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';

const StyledForm = styled.div`
  border: solid #e9eff4;
  border-width: 1px 1px 0px 1px;
  border-radius: 5px;
  width: 80% !important;
  height: 59px;
  margin: 0 auto;
`;

const BoderDetailView = ({ children }) => {
  return <StyledForm>{children}</StyledForm>;
};

BoderDetailView.propTypes = {
  children: PropTypes.any.isRequired,
};

export { BoderDetailView };
