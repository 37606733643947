import Flux from '@cobuildlab/flux-state';
// eslint-disable-next-line
/**
 * Event that triggers a session
 * @type {string}
 * @deprecated Replace for NEW_SESSION_EVENT
 */
export const SESSION_EVENT = 'onSession';
export const NEW_SESSION_EVENT = 'onSessionEvent';
export const NEW_USER_EVENT = 'onNewUserEvent';
// eslint-disable-next-line
/**
 * Event that triggers a Auth0Lock login
 * @type {string}
 */
export const AUTH_LOCK_LOGIN_EVENT = 'onAuth0LockLogin';
// eslint-disable-next-line
/**
 * Event that triggers a session Alliance
 * @type {string}
 * @deprecated use NEW_SESSION_EVENT
 */
export const SESSION_ALLIANCE = 'onSessionAlliance';
// eslint-disable-next-line
/**
 * Event that triggers a Alliance Selection
 * @type {string}
 */
export const ALLIANCE_EVENT = 'onAlliance';
// eslint-disable-next-line
/**
 * Event that triggers a Meta fetch
 * @type {string}
 */
export const SESSION_META_EVENT = 'onSessionMeta';
// eslint-disable-next-line
/**
 * Event that triggers a Roles fetch
 * @type {string}
 */
export const ROLES_EVENT = 'onRoles';
// eslint-disable-next-line
/**
 * Event that triggers a Meta creation
 * @type {string}
 */
export const META_EVENT = 'onMetaCreate';
// eslint-disable-next-line
/**
 * Event that triggers a Meta update
 * @type {string}
 */
export const UPDATE_META_EVENT = 'onMetaUpdate';
// eslint-disable-next-line
/**
 * Event that triggers the acquisition of a Apollo Client Instance
 * @type {string}
 */
export const APOLLO_CLIENT = 'onApolloCLient';
// eslint-disable-next-line
/**
 * Event that triggers a session error
 * @type {string}
 */
export const SESSION_ERROR = 'onSessionError';
// eslint-disable-next-line
/**
 * Event that triggers when the initial User information is created
 * @type {string}
 */
export const USER_CREATED_EVENT = 'onUserCreated';
// eslint-disable-next-line
/**
 * Event that triggers when the Users and Roles are fetched
 * @type {string}
 */
export const USER_AND_ROLES_EVENT = 'onUsersAndRoles';
// eslint-disable-next-line
/**
 * Event that triggers when the Invitations are fetched
 * @type {string}
 */
export const INVITATIONS_EVENT = 'onInvitations';
// eslint-disable-next-line
/**
 * Event that triggers when the Invitations with the User data is fetched
 * @type {string}
 */
export const INVITATIONS_USERS_EVENT = 'onInvitationsAndUsers';

/**
 * Event that triggers when the basic information of a user is fetched.
 *
 * @type {string}
 */
export const USER_BASIC_EVENT = 'USER_BASIC_EVENT';

/**
 * Event that triggers when the basic information of a user is fetched.
 *
 * @type {string}
 */
export const SESSION_ALLIANCES_EVENT = 'SESSION_ALLIANCES_EVENT';

/**
 * Event that triggers token auth.
 *
 * @type {string}
 */
export const TOKEN_EVENT = 'token_event';

// eslint-disable-next-line
/**
 * Hold the Session Data
 */
class SessionStore extends Flux.DashStore {
  constructor() {
    super();
    this.addEvent(AUTH_LOCK_LOGIN_EVENT);
    this.addEvent(NEW_SESSION_EVENT);
    this.addEvent(SESSION_EVENT);
    this.addEvent(NEW_USER_EVENT);
    this.addEvent(SESSION_ERROR);
    this.addEvent(SESSION_META_EVENT);
    this.addEvent(APOLLO_CLIENT);
    this.addEvent(META_EVENT);
    this.addEvent(USER_CREATED_EVENT);
    this.addEvent(UPDATE_META_EVENT);
    this.addEvent(ROLES_EVENT);
    this.addEvent(INVITATIONS_EVENT);
    this.addEvent(USER_AND_ROLES_EVENT);
    this.addEvent(INVITATIONS_USERS_EVENT);
    this.addEvent(ALLIANCE_EVENT);
    this.addEvent(SESSION_ALLIANCE);
    this.addEvent(USER_BASIC_EVENT);
    this.addEvent(SESSION_ALLIANCES_EVENT);
    this.addEvent(TOKEN_EVENT);
  }
}

const sessionStore = new SessionStore();

export { sessionStore };
export default sessionStore;
