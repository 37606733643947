import React from 'react';
import { Grid, Heading, Loader, SelectField } from '@8base/boost';
import { View } from '@cobuildlab/react-simple-state';

import {
  ChartCard,
  GeneralInfo,
  GeneralInfoItem,
  GeneralInfoName,
  GeneralInfoValue,
  ReportHeader,
} from '../components';

import { CurrencyFormat } from '../../../shared/components/CurrencyFormat';

import { ownerToString } from '../../../shared/utils';

import { ActiveSalesPipelineDataHandler } from './ActiveSalesPipelineDataHandler';
import { ActiveSalesPipelineChart } from './ActiveSalesPipelineChart';

const colors = [
  '#6fd34c', // green
  '#2ba1ff', // blue
  '#0096b8', // sea
  '#e54034', // red
  '#FFD600', // yellow
  '#004156', // dark sea
  '#FE9E76', // peach
  '#FF2970', // pink
  '#FFAD32', // orange
  '#782FEF', // velvet
  '#58595B', // dark gray
  '#1EC9E8', // blueish
  '#48CFAF', // mint
];

const alliance = 'Alliance';
const alliancePosition = 0;
const clientCompanyPosition = 1;
const partnerCompanyPosition = 2;

const isFilteredByOrganization = (config) => {
  return Boolean(config.organization);
};

const filteredByOrganization = ({ config, clientCompany }) => {
  if (isFilteredByOrganization(config)) {
    return config.organization.id === clientCompany.id
      ? clientCompanyPosition
      : partnerCompanyPosition;
  }

  return alliancePosition;
};

class ChartView extends View {
  constructor(props) {
    super(props);

    const { clientCompany, partnerCompany } = this.props.sharedData;
    const selections = [alliance, clientCompany.name];

    if (partnerCompany) {
      selections.push(partnerCompany.name);
    }

    this.state = {
      selections,
      isFilteredByOrganization: isFilteredByOrganization(this.props.sharedData.config),
      selected: filteredByOrganization(this.props.sharedData),
    };
  }

  onIncrementSelected = () => {
    const { selections } = this.state;
    this.setState(({ selected }) => ({
      selected: selected === selections.length - 1 ? 0 : selected + 1,
    }));
  };

  onDecrementSelected = () => {
    const { selections } = this.state;
    this.setState(({ selected }) => ({
      selected: selected === 0 ? selections.length - 1 : selected - 1,
    }));
  };

  onSelectedCompany = (selected) => {
    this.setState({ selected });
  };

  dataForChart = (data) => {
    const { selections, selected } = this.state;
    const {
      requestedData: { groupBy },
    } = this.props;
    const selectedState = selections[selected];

    if (selectedState === alliance) {
      return {
        bars: [
          { name: selections[1], color: colors[0] },
          { name: selections[2], color: colors[1] },
        ],
        data: data.map((currentGroup) => {
          const d = currentGroup[1].reduce((acc, deal) => {
            return {
              ...acc,
              [deal.company.name]: acc[deal.company.name]
                ? acc[deal.company.name] + Number(deal.amount)
                : Number(deal.amount),
            };
          }, {});

          const groupName =
            (groupBy[0] === 'owner.id'
              ? ownerToString(currentGroup[1][0].owner)
              : currentGroup[0]) || 'No name';

          return {
            groupName,
            ...d,
          };
        }),
      };
    }

    return {
      bars: [{ name: selectedState, color: selected === 1 ? colors[0] : colors[1] }],
      data: data.map((currentGroup) => {
        const formattedGroup = currentGroup[1].reduce(
          (acc, deal) => {
            return deal.company.name !== selectedState
              ? acc
              : {
                [selectedState]: acc[selectedState] + Number(deal.amount),
              };
          },
          { [selectedState]: 0 },
        );
        const groupName =
          (groupBy[0] === 'owner.id' ? ownerToString(currentGroup[1][0].owner) : currentGroup[0]) ||
          'No name';

        return {
          groupName,
          ...formattedGroup,
        };
      }),
    };
  };

  dataForGeneralInfo = (data) => {
    const { isFilteredByOrganization, selections, selected } = this.state;
    const clientCompany = selections[clientCompanyPosition];
    const partnerCompany = selections[partnerCompanyPosition];

    if (isFilteredByOrganization) {
      if (selected === clientCompanyPosition) {
        return data.reduce(
          (groupsAcc, currentGroup) => {
            const reducedGroup = currentGroup[1].reduce(
              (dealsAcc, currentDeal) => {
                const isDealMadeByClientCompany = currentDeal.company.name === clientCompany;
                return {
                  totalClient: isDealMadeByClientCompany
                    ? dealsAcc.totalClient + Number(currentDeal.amount)
                    : dealsAcc.totalClient,
                  count: isDealMadeByClientCompany ? dealsAcc.count + 1 : dealsAcc.count,
                };
              },
              {
                totalClient: 0,
                count: 0,
              },
            );

            return {
              ...groupsAcc,
              totalClient: reducedGroup.totalClient + groupsAcc.totalClient,
              highest:
                groupsAcc.highest > reducedGroup.totalClient
                  ? groupsAcc.highest
                  : reducedGroup.totalClient,
              count: reducedGroup.count + groupsAcc.count,
            };
          },
          {
            totalAlliance: 'N/a',
            totalClient: 0,
            totalPartner: 'N/a',
            highest: 0,
            count: 0,
          },
        );
      }

      return data.reduce(
        (groupsAcc, currentGroup) => {
          const reducedGroup = currentGroup[1].reduce(
            (dealsAcc, currentDeal) => {
              const isDealMadeByPartnerCompany = currentDeal.company.name === partnerCompany;
              return {
                totalPartner: isDealMadeByPartnerCompany
                  ? dealsAcc.totalPartner + Number(currentDeal.amount)
                  : dealsAcc.totalPartner,
                count: isDealMadeByPartnerCompany ? dealsAcc.count + 1 : dealsAcc.count,
              };
            },
            {
              totalPartner: 0,
              count: 0,
            },
          );

          return {
            ...groupsAcc,
            totalPartner: reducedGroup.totalPartner + groupsAcc.totalPartner,
            highest:
              groupsAcc.highest > reducedGroup.totalPartner
                ? groupsAcc.highest
                : reducedGroup.totalPartner,
            count: reducedGroup.count + groupsAcc.count,
          };
        },
        {
          totalAlliance: 'N/a',
          totalClient: 'N/a',
          totalPartner: 0,
          highest: 0,
          count: 0,
        },
      );
    }

    // reduce another way and return an object
    return data.reduce(
      (groupsAcc, currentGroup) => {
        const reducedGroup = currentGroup[1].reduce(
          (dealsAcc, currentDeal) => {
            const isDealMadeByClientCompany = currentDeal.company.name === clientCompany;
            return {
              ...dealsAcc,
              totalClient: isDealMadeByClientCompany
                ? dealsAcc.totalClient + Number(currentDeal.amount)
                : dealsAcc.totalClient,
              totalPartner: isDealMadeByClientCompany
                ? dealsAcc.totalPartner
                : dealsAcc.totalPartner + Number(currentDeal.amount),
              count: dealsAcc.count + 1,
            };
          },
          {
            totalClient: 0,
            totalPartner: 0,
            count: 0,
          },
        );

        const groupSum = reducedGroup.totalClient + reducedGroup.totalPartner;

        return {
          ...groupsAcc,
          totalAlliance: groupsAcc.totalAlliance + groupSum,
          totalClient: groupsAcc.totalClient + reducedGroup.totalClient,
          totalPartner: groupsAcc.totalPartner + reducedGroup.totalPartner,
          lowest: groupsAcc.lowest
            ? groupsAcc.lowest < groupSum
              ? groupsAcc.lowest
              : groupSum
            : groupSum,
          count: groupsAcc.count + reducedGroup.count,
        };
      },
      {
        totalAlliance: 0,
        totalClient: 0,
        totalPartner: 0,
        lowest: null,
        count: 0,
      },
    );
  };

  renderContent = () => {
    const {
      requestedData: { currency, deals },
      loading,
    } = this.props;
    const { selections, selected, isFilteredByOrganization } = this.state;

    const clientCompany = selections[clientCompanyPosition];
    const partnerCompany = selections[partnerCompanyPosition];

    if (loading) {
      return <Loader stretch />;
    }

    const { data, bars } = this.dataForChart(deals);

    const generalInfo = this.dataForGeneralInfo(deals);

    const generalInfoItemNames = {
      totalAlliance: 'Total - Alliance',
      totalClient: `Total - ${clientCompany}`,
      totalPartner: `Total - ${partnerCompany}`,
      lowest: 'Lowest stage',
    };

    const totalDeals = generalInfo.count;
    delete generalInfo.count;

    const companyClientOption = {
      label: selections[clientCompanyPosition],
      value: clientCompanyPosition,
    };

    const companyPartnerOption = {
      label: selections[partnerCompanyPosition],
      value: partnerCompanyPosition,
    };

    const allianceOption = {
      label: selections[alliancePosition],
      value: alliancePosition,
    };

    const companyOptions = partnerCompany
      ? [companyClientOption, companyPartnerOption, allianceOption]
      : [companyClientOption, allianceOption];

    if (!partnerCompany) {
      delete generalInfo.totalPartner;
    }

    return (
      <>
        <ReportHeader>
          <Heading type="h4" text="Active Sales Pipeline" />
          <Grid.Box direction="row" justifySelf="end" alignSelf="center">
            <SelectField
              style={{ width: '150px', alignSelf: 'end' }}
              options={companyOptions}
              input={{
                name: 'companyFilter',
                value: selected,
                onChange: (selected) => this.onSelectedCompany(selected),
              }}
            />
          </Grid.Box>
        </ReportHeader>
        <GeneralInfo>
          {Object.entries(generalInfo).map(([key, value]) => (
            <GeneralInfoItem key={key}>
              <GeneralInfoName>{generalInfoItemNames[key]}</GeneralInfoName>
              <GeneralInfoValue>
                {value ? <CurrencyFormat {...currency} value={value} displayType="text" /> : 'N/A'}
              </GeneralInfoValue>
            </GeneralInfoItem>
          ))}
          <GeneralInfoItem>
            <GeneralInfoName>Total deals</GeneralInfoName>
            <GeneralInfoValue>{totalDeals}</GeneralInfoValue>
          </GeneralInfoItem>
        </GeneralInfo>
        <ActiveSalesPipelineChart
          data={data}
          bars={bars}
          selected={selected}
          selections={selections}
          currency={currency}
          isFilteredByOrganization={isFilteredByOrganization}
          onIncrementSelected={this.onIncrementSelected}
          onDecrementSelected={this.onDecrementSelected}
        />
      </>
    );
  };

  render() {
    return (
      <div id={'c2gScreen'}>
        <ChartCard loading={this.props.loading} stretch>
          {this.renderContent()}
        </ChartCard>
      </div>
    );
  }
}

export const ActiveSalesPipelineChartsView = ActiveSalesPipelineDataHandler(ChartView);
