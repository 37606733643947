import React from 'react';
import { Text } from '@8base/boost';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';

const StyledBody = styled(Text)`
  font-family: Poppins;
  font-weight: 400;
  font-size: 13px;
  letter-spacing: 0.2px;
  line-height: 20px;
  color: #323c47;
  & first-letter {
    text-transform: capitalize;
  }
`;

const Body = ({ children }) => {
  return <StyledBody>{children}</StyledBody>;
};

Body.propTypes = {
  children: PropTypes.any.isRequired,
};

export { Body };
