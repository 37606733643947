import React from 'react';
import { Button, Icon } from '@8base/boost';
import styled from '@emotion/styled';

const StyledButton = styled(Button)({
  color: '#cfd7de',
  borderColor: '#cfd7de',
  height: '36px',
  width: '36px',
  '&:hover': { color: '#3eb7f9', borderColor: '#3eb7f9' },
});

/**
 * @returns {*}
 */

const MoreActionButton = () => {
  return (
    <StyledButton variant="outlined" squared>
      <Icon name="More" />
    </StyledButton>
  );
};

export { MoreActionButton };
