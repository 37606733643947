import React from 'react';
import styled from 'styled-components';

const DragButton = (props) => {
  return (
    <DragButtonContainer {...props}>
      <DragIcon />
    </DragButtonContainer>
  );
};
const DragButtonContainer = styled.button`
  height: 100%;
  background-color: transparent;
  border: none;
  padding: 0 8px;
  cursor: move;
  outline: none;
`;
const DragIcon = styled.div`
  &::before {
    content: '';
    border-left: 2px dotted #d0d7dd;
    border-right: 2px dotted #d0d7dd;
    width: 6px;
    display: block;
    height: 14px;
  }
`;

export { DragButton };
