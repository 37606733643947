import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import React from 'react';
import { Text, Heading } from '@8base/boost';
import { CurrencyTextField } from '../../../../shared/components/CurrencyTextField';
import BigInt from 'big-integer';

const Card = styled.div`
  box-sizing: border-box;
  height: 110px;
  width: 225px;
  border: 1px solid #eaf0f7;
  border-radius: 4px;
  background-color: #ffffff;
  padding: 20px 10px 0 10px;
  margin-right: 24px !important;
`;

const Header = styled(Heading)`
  height: 30px;
  color: #939ea7;
  font-family: Poppins;
  font-size: 12px;
  letter-spacing: 0.2px;
  line-height: 18px;
  margin-left: 16px;
`;

const AmountText = styled(Text)`
  height: 45px;
  width: 225px;
  font-family: Poppins;
  font-size: 32px;
  font-weight: 500;
  line-height: 48px;
  margin-left: 16px;
`;

export const ForecastReportCard = ({ isVariance, header, amount, currency }) => {
  const varianceColor = BigInt(amount).isNegative() ? 'RED' : 'GREEN';
  const color = isVariance ? varianceColor : '';
  const _amount = BigInt(amount).abs();

  return (
    <>
      <Card>
        <Header text={header} />
        <AmountText>
          <CurrencyTextField value={_amount} currency={currency} color={color} />
        </AmountText>
      </Card>
    </>
  );
};

ForecastReportCard.propTypes = {
  isVariance: PropTypes.bool, // to show red or green colors
  header: PropTypes.string.isRequired,
  amount: PropTypes.string.isRequired,
  currency: PropTypes.object.isRequired,
};

ForecastReportCard.defaultProps = {
  color: '#000',
  isVariance: false,
};
