import { MANUAL_TYPE, OPPORTUNITY_WIN_RATIO_TYPE } from './allianceKPIs-model';
import { ValidationError } from '../../../../shared/errors';

/**
 * Alliance KPIs Validator.
 *
 * @param  {object} alliance - Alliance to get kpis.
 */
export const allianceKPIsValidator = (alliance) => {
  const { allianceKPIs, currency } = alliance;
  const errorMessages = [];

  allianceKPIs.forEach((kpi) => {
    const [isValid, error] = isKPIFormatValid(kpi, currency);
    if (!isValid) {
      errorMessages.push(error);
    }
  });

  if (errorMessages.length) throw new ValidationError(errorMessages);
};

/**
 * Check if a KPI has a valid format.
 *
 * @param {object} kpi - The KPI to validate.
 * @param {object} currency - The currency in the alliance.
 * @returns {boolean} Wether the target format is valid or not.
 */
const isKPIFormatValid = (kpi, currency) => {
  const { type, monthByMonth, target, targetDistributionType } = kpi;
  const { decimalSeparator } = currency;
  let validKPIFormat;
  if (type === OPPORTUNITY_WIN_RATIO_TYPE) {
    validKPIFormat = (value) => {
      const [int, dec = ''] = value.toString().split(decimalSeparator);
      const isValid = !isNaN(+int) && (dec.length === 0 || (dec.length < 2 && !isNaN(+dec)));
      return [isValid, isValid ? null : 'Opportunity Win Ratio can only have 1 decimal'];
    };
  } else {
    validKPIFormat = (value) => {
      const [int, dec = ''] = value.toString().split(decimalSeparator);
      const isValid = !isNaN(+int) && (dec === '0' || dec === 0 || dec.length === 0);
      return [isValid, isValid ? null : `${type} KPI should not have decimal values`];
    };
  }
  if (targetDistributionType === MANUAL_TYPE) {
    monthByMonth.forEach((value) => {
      const [isValid, error] = validKPIFormat(value);
      if (!isValid) return [isValid, error];
    });
    return [true, null];
  } else {
    return validKPIFormat(target);
  }
};
