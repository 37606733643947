import React from 'react';
import { Heading, Row, Text } from '@8base/boost';
import PropTypes from 'prop-types';
import moment from 'moment';

const ScorecardDate = ({ kpiName, startMonth, endMonth, year }) => {
  const _startDate = moment(`${year}-${startMonth}`, 'YYYY-MM').format('MMM D, YYYY -');
  const _endDate = moment(`${year}-${endMonth}`, 'YYYY-MM')
    .add(1, 'month')
    .add(-1, 'day')
    .format(' MMM D, YYYY');

  return (
    <>
      <Heading type="h5" kind="secondary" weight="semibold">
        {_startDate}
        {_endDate}
      </Heading>
      <Row offsetBottom="lg">
        <Text color="DARK_GRAY3">{`${kpiName} % Completed`}</Text>
      </Row>
    </>
  );
};

ScorecardDate.propTypes = {
  year: PropTypes.number.isRequired,
  startMonth: PropTypes.number.isRequired,
  endMonth: PropTypes.number.isRequired,
  kpiName: PropTypes.string.isRequired,
};

export { ScorecardDate };
