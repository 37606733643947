import React from 'react';
import { Grid, Dialog, Paragraph, SelectField } from '@8base/boost';
import PropTypes from 'prop-types';
import { ActionButton } from '../../../../components/buttons/ActionButton';

const SelectCompany = (props) => {
  const { isOpen, onAccept, onChange, companyUsers, companyId, title, onClose } = props;

  const options = companyUsers.map((companyRelation) => {
    return {
      label: companyRelation.company.name,
      value: companyRelation.company.id,
    };
  });

  return (
    <>
      <Dialog size="sm" isOpen={isOpen}>
        <Dialog.Header title={title} onClose={onClose} />
        <Dialog.Body scrollable>
          <Grid.Layout gap="sm" stretch>
            <Grid.Box justifyContent={'center'} alignItems={'center'}>
              <br />
              <Paragraph>Select Your Company to accept the invitation</Paragraph>
              <br />
              <SelectField
                meta={{}}
                input={{
                  name: 'companyName',
                  value: companyId,
                  onChange: onChange,
                }}
                options={options.map((option) => {
                  if (option.label === '') {
                    option.label = 'None';
                  }
                  return { label: option.label, value: option.value };
                })}
              />
            </Grid.Box>
          </Grid.Layout>
        </Dialog.Body>
        <Dialog.Footer style={{ width: '360px' }}>
          <ActionButton onClick={onAccept} text={'Accept'} />
        </Dialog.Footer>
      </Dialog>
    </>
  );
};

SelectCompany.propTypes = {
  title: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onAccept: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  companyUsers: PropTypes.array.isRequired,
  companyId: PropTypes.string.isRequired,
};
export { SelectCompany };
