import { Column, Row, Text, Heading } from '@8base/boost';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { SelectMappedValue } from './SelectMappedValue';

const StyledContainer = styled('div')`
  width: 100%;
  padding: 24px;
  border-bottom: 1px solid #e9eff4 !important;
`;

const StyledHeading = styled(Heading)`
  color: #323c47;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 600 !important;
  letter-spacing: 0.5px;
  line-height: 20px;
  text-align: left;
`;

const StyledTextDescription = styled(Text)`
  color: #a3afb7 !important;
  font-family: Poppins;
  font-size: 13px;
  letter-spacing: 0.2px;
  line-height: 20px;
  text-align: left;
  display: block;
`;

const StyledTextWinProbability = styled(Text)`
  color: #6ed34b !important;
  font-family: Poppins;
  font-size: 12px;
  letter-spacing: 0.5px;
  line-height: 16px;
  text-align: left;
  display: block;
`;

const StageMappingRow = (props) => {
  const { stage, index, onChange } = props;
  return (
    <StyledContainer>
      <Row growChildren gap="lg">
        <Column style={{ width: '50%', paddingRight: '20px' }}>
          <StyledHeading text={`${index + 1} - ${stage.name}`} />
          <StyledTextWinProbability text={`State Win Probability ${stage.winProbability}%`} />
          <StyledTextDescription text={stage.description} />
        </Column>
        <Column style={{ width: '50%', paddingLeft: '20px' }}>
          {/*          <InputField
            label="Mapped values"
            input={{
              value: stage.values,
              onChange: (value) => {
                onChangeValues(value, index);
              },
            }}
            stretch
            placeholder={'e.g.1 , Lead, Meeting'}/>*/}
          <SelectMappedValue handleChange={onChange} tags={stage.values} index={index} />
        </Column>
      </Row>
    </StyledContainer>
  );
};

StageMappingRow.propTypes = {
  stage: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
};

export { StageMappingRow };
