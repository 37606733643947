import gql from 'graphql-tag';

export const UPDATE_USER_MUTATION = gql`
  mutation($user: UserUpdateInput!) {
    userUpdate(data: $user) {
      id
      firstName
      lastName
      email
    }
  }
`;

export const UPDATE_USER_INFORMATION_MUTATION = gql`
  mutation($user: UserUpdateInput!, $userInformation: UserInformationUpdateInput!) {
    userUpdate(data: $user) {
      id
      firstName
    }
    userInformationUpdate(data: $userInformation) {
      id
    }
  }
`;

// USER INFORMATION
export const USER_INFORMATION_QUERY = gql`
  query User($userId: ID) {
    userInformationsList(filter: { user: { id: { equals: $userId } } }) {
      count
      items {
        picture {
          id
          fileId
          filename
          downloadUrl
        }
        id
        state
        zipCode
        city
        title
        country {
          id
          name
        }
      }
    }
    phonesList(filter: { user: { id: { equals: $userId } } }) {
      count
      items {
        id
        phone
        type {
          id
          name
        }
      }
    }
    phoneTypesList {
      items {
        id
        name
      }
    }
    countriesList(sort: { name: ASC }) {
      items {
        id
        name
      }
    }
    statesList(filter: { country: { name: { equals: "United States" } } }) {
      items {
        id
        name
      }
    }
  }
`;

/**
 *
 */
export const CREATE_USER_INFORMATION_MUTATION = gql`
  mutation UpdateCompanyInformation($user: CompanyInformationCreateInput!) {
    companyInformationCreate(data: $user) {
      id
    }
  }
`;

// {
//   "user": {
//     "user_id": {
//       "connect": { "id":"cjouj32uc000h01rxv4nevfin"}
//     }
//   }
// }

/**
 *
 */
export const CREATE_COMPANY_INFORMATION_MUTATION = gql`
  mutation UpdateCompanyInformation($user: CompanyInformationCreateInput!) {
    companyInformationCreate(data: $user) {
      id
    }
  }
`;
// COMPANY INFORMATION
export const ALLIANCE_INFORMATION_QUERY = gql`
  query User($userId: ID) {
    companyInformationsList(filter: { user_id: { id: { equals: $userId } } }) {
      count
      items {
        id
        companyName
        description
        website
        country {
          count
          items {
            name
          }
        }
        address1
        address1
        zipCode
        city
        state
        phone
        numberOfEmployees
        industry
        annualRevenue
        parentCompany
        companyStatus
        tickerSymbol
      }
    }
    user(id: $userId) {
      email
      firstName
      id
      lastName
      avatar {
        id
        downloadUrl
      }
    }
    countryList {
      count
      items {
        id
        type {
          id
          name
        }
      }
    }
  }
`;

/**
 *
 */
export const CREATE_ALLIANCE_INFORMATION_MUTATION = gql`
  mutation UpdateCompanyInformation($user: CompanyInformationCreateInput!) {
    companyInformationCreate(data: $user) {
      id
    }
  }
`;

/**
 *
 */
export const CREATE_PHONE_MUTATION = gql`
  mutation CreatePhone($data: PhoneCreateInput!) {
    phoneCreate(data: $data) {
      id
      phone
      type {
        id
        name
      }
    }
  }
`;

/**
 * Update Phone Mutation.
 */
export const UPDATE_PHONE_MUTATION = gql`
  mutation UpdatePhone($data: PhoneUpdateInput!) {
    phoneUpdate(data: $data) {
      id
      phone
      type {
        id
        name
      }
    }
  }
`;

/**
 * Update Meta Mutation.
 */
export const UPDATE_META_MUTATION = gql`
  mutation UpdateMeta($data: MetaUpdateInput!) {
    metaUpdate(data: $data) {
      id
      name
      value
    }
  }
`;

export const DELETE_PHONE_MUTATION = gql`
  mutation($data: PhoneDeleteInput!) {
    phoneDelete(data: $data) {
      success
    }
  }
`;
