import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Card, Heading, Table } from '@8base/boost';
import { ListCardBody } from '../../../../components/card/ListCardBody';
import { Link } from 'react-router-dom';

const StyledHeading = styled(Heading)`
  height: 28px;
  width: 277px;
  color: #323c47;
  font-family: Poppins;
  font-size: 20px;
  line-height: 30px;
`;

const IdeaList = ({ selectedKPI }) => {
  const { name: KPIname, ideas } = selectedKPI;
  return (
    <>
      <Card style={{ marginTop: '35px', marginBottom: '35px' }}>
        <Card.Header>
          <StyledHeading type="h4" text={KPIname} />
        </Card.Header>
        <Card.Body>
          <ListCardBody>
            <Table>
              <Table.Header className="justify-center-column" columns="4fr 4fr 4fr 4fr">
                <Table.HeaderCell className="name-column">Name</Table.HeaderCell>
                <Table.HeaderCell>Description</Table.HeaderCell>
                <Table.HeaderCell>Assigned to</Table.HeaderCell>
                <Table.HeaderCell>Status</Table.HeaderCell>
              </Table.Header>
              <Table.Body data={ideas} className="card-body-list">
                {(item, index) => {
                  const { idea } = item;
                  const { assignedTo, description, status, name, id } = idea;
                  const username = assignedTo
                    ? `${assignedTo.firstName} ${assignedTo.lastName}`
                    : '';

                  return (
                    <>
                      <Table.BodyRow columns="4fr 4fr 4fr 4fr" key={index}>
                        <Table.BodyCell className="justify-center-row">
                          <Link className="item-name" to={`/management/idea/${id}/`}>
                            {name}
                          </Link>
                        </Table.BodyCell>
                        <Table.BodyCell className="justify-center-row">
                          {description}
                        </Table.BodyCell>
                        <Table.BodyCell className="justify-center-row">{username}</Table.BodyCell>
                        <Table.BodyCell className="justify-center-row">{status}</Table.BodyCell>
                      </Table.BodyRow>
                    </>
                  );
                }}
              </Table.Body>
            </Table>
          </ListCardBody>
        </Card.Body>
      </Card>
    </>
  );
};

IdeaList.propTypes = {
  selectedKPI: PropTypes.object.isRequired,
};

export { IdeaList };
