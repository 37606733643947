import gql from 'graphql-tag';
import { UserFragment } from '../auth/queries';
import { NextStepFragment } from '../next-step/next-step-queries';

/**
 * Query for create a Related Item.
 *
 * @type {*|*}
 */
export const RELATED_ITEM_UPDATE_MUTATION = gql`
  mutation($data: ItemUpdateInput!) {
    itemUpdate(data: $data) {
      id
    }
  }
`;

const ItemFragment = gql`
  fragment ItemFragment on Item {
    id
    createdAt
    action {
      id
      name
      description
      status
      createdAt
      revisedDueDate
      originalDueDate
      businessCase {
        id
      }
      requestedBy {
        ...UserFragment
      }
      assignedTo {
        ...UserFragment
      }
      nextSteps {
        items {
          ...NextStepFragment
        }
      }
    }
    issue {
      id
      name
      description
      status
      createdAt
      revisedDueDate
      originalDueDate
      assignedTo {
        ...UserFragment
      }
      nextSteps {
        items {
          ...NextStepFragment
        }
      }
    }
    risk {
      id
      name
      description
      status
      createdAt
      revisedDueDate
      originalDueDate
      assignedTo {
        ...UserFragment
      }
      nextSteps {
        items {
          ...NextStepFragment
        }
      }
    }
    decision {
      id
      name
      description
      status
      createdAt
      businessCase {
        id
      }
    }
    contribution {
      id
      name
      description
      status
      createdAt
    }
    fundingRequest {
      id
      name
      description
      status
      createdAt
      revisedDueDate
      originalDueDate
      businessCase {
        id
      }
      requestedBy {
        ...UserFragment
      }
      assignedTo {
        ...UserFragment
      }
      nextSteps {
        items {
          ...NextStepFragment
        }
      }
    }
    idea {
      id
      name
      description
      status
      createdAt
      revisedDueDate
      originalDueDate
      businessCase {
        id
      }
      requestedBy {
        ...UserFragment
      }
      assignedTo {
        ...UserFragment
      }
      nextSteps {
        items {
          ...NextStepFragment
        }
      }
    }
    dealData {
      id
      name
      status
      createdAt
      owner {
        ...UserFragment
      }
    }
  }
  ${UserFragment}
  ${NextStepFragment}
`;

/**
 * Query for the List of Related Items.
 *
 * @type {*|*}
 */
export const RELATED_ITEMS_LIST_QUERY = gql`
  query($id: ID!, $filter: ItemFilter) {
    item(id: $id) {
      id
      itemsRelated(filter: $filter) {
        count
        items {
          ...ItemFragment
        }
      }
    }
  }
  ${ItemFragment}
`;
/**
 * For searching.
 *
 * @type {*|*}
 */
export const ITEMS_LIST_QUERY = gql`
  query($data: ItemFilter) {
    itemsList(filter: $data) {
      count
      items {
        ...ItemFragment
      }
    }
  }
  ${ItemFragment}
`;

export const ITEMS_LIST_BY_ITEM_ID_QUERY = gql`
  query($data: ItemFilter) {
    itemsList(filter: $data) {
      count
      items {
        ...ItemFragment
      }
    }
  }
  ${ItemFragment}
`;
