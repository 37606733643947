import React from 'react';
import PropTypes from 'prop-types';
import { FIELD_TYPE } from '@8base/utils';
import { CurrencyFormat } from '../../../../../../shared/components/CurrencyFormat';
import { getCurrencyOnSession } from '../../../../../../shared/alliance-utils';
import { InitiativesDataCell, UserCell, CellWrap, TD } from '../Components';
import { convertRelationToString } from '../../../../utils';
import { Link } from 'react-router-dom';

const DataCell = ({ cell, currencyFields, index }) => {
  const { value, fieldType, name, itemId } = cell;
  let itemType = cell.itemType === 'dealdata' ? 'deal' : cell.itemType;
  if (fieldType === FIELD_TYPE.RELATION) {
    switch (name) {
    case 'createdBy':
    case 'assignedTo':
    case 'requestedBy':
    case 'owner': {
      return (
        <TD>
          <CellWrap>
            <UserCell owner={value} />
          </CellWrap>
        </TD>
      );
    }
    case 'initiatives': {
      return <InitiativesDataCell initiatives={value}></InitiativesDataCell>;
    }
    default: {
      return (
        <TD>
          <CellWrap textAlign={fieldType === FIELD_TYPE.NUMBER ? 'right' : 'left'}>
            {convertRelationToString(name, value)}
          </CellWrap>
        </TD>
      );
    }
    }
  }

  if (fieldType === FIELD_TYPE.NUMBER) {
    const isCurrency = currencyFields.find((fieldName) => fieldName === name);
    if (isCurrency) {
      const currency = getCurrencyOnSession();
      return (
        <TD>
          <CellWrap textAlign={fieldType === FIELD_TYPE.NUMBER ? 'right' : 'left'}>
            <CurrencyFormat
              {...currency}
              value={value ? value : 0}
              displayType="text"
              decimalScale={2}
            />
          </CellWrap>
        </TD>
      );
    }
    return (
      <TD>
        <CellWrap textAlign={'right'}>{value}</CellWrap>
      </TD>
    );
  }

  if (name === 'name') {
    return (
      <TD>
        <Link className="item-name" to={`/management/${itemType}/${itemId}`}>
          <CellWrap textAlign={'left'}>{value}</CellWrap>
        </Link>
      </TD>
    );
  }

  return (
    <TD>
      <CellWrap textAlign={'left'}>{value}</CellWrap>
    </TD>
  );
};

DataCell.propTypes = {
  cell: PropTypes.object.isRequired,
  currencyFields: PropTypes.array.isRequired,
  index: PropTypes.number.isRequired,
};

export { DataCell };
