import React from 'react';
import { AppProvider } from '8base-react-sdk';
import AuthClient from './shared/auth';
import { AuthCallback } from './modules/auth';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { AsyncContent, createTheme, BoostProvider } from '@8base/boost';
import { toast, ToastContainer } from 'react-toastify';
import ProtectedRoute from './shared/components/ProtectedRoute';
import { TOAST_SUCCESS_MESSAGE } from './shared/constants';
import { setLoginRedirectUri } from './shared/utils';
import { MainPlate } from './components/MainPlate';
import { ContentPlate } from './components/ContentPlate';
import Wizard from './modules/wizard/Wizard';
import DashboardView from './modules/dashboard/DashboardView';
import SettingsView from './modules/settings/SettingsView';
import ManagementView from './modules/management/ManagementView';
import { ProcurerView } from './modules/procurer/ProcurerView';
import ActiveItemsView from './modules/active-items/ActiveItemsView';
import CommentsSideBar from './modules/comment/CommentsSideBar';
import RelatedItemSideBar from './modules/related-item/components/RelatedItemSideBar';
import Session from './shared/components/Session';
import { library } from '@fortawesome/fontawesome-svg-core';
import Menu from './components/Menu';
import html2canvas from 'html2canvas';
import { SelectedAllianceSubscription } from './modules/alliance/SelectedAllianceSubscription';

import {
  faPaperPlane,
  faUsersCog,
  faEdit,
  faDoorOpen,
  faPencilAlt,
  faCaretDown,
  faCheck,
  faFileUpload,
  faMinusCircle,
  faComment,
  faComments,
  faProjectDiagram,
  faSearch,
  faTasks,
  faTimes,
  faClipboardList,
  faPlus,
  faEye,
  faDownload,
  faEyeSlash,
} from '@fortawesome/free-solid-svg-icons';
import AllianceMembersListView from './modules/settings/alliance-management/members/AllianceMembersListView';
import { NotificationsSideBar } from './modules/notifications/NotificationsSideBar';
import { ReportsView } from './modules/reports/ReportsView';
import ChatView from './modules/chat/ChatView';
import { GREEN_COLOR, RED_COLOR, YELLOW_COLOR, AMBER_COLOR } from './shared/colors';
import { Welcome } from './modules/home/components/Welcome';
import { NewVersionDialog } from './components/dialogs/NewVersionDialog';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import CompanyExpiredView from './modules/settings/company-management/CompanyExpiredView';
import { SalesForceView } from './modules/salesforce/SalesForceView';

//Font Awesome Icons
library.add(
  faProjectDiagram,
  faPaperPlane,
  faComment,
  faComments,
  faUsersCog,
  faEdit,
  faDoorOpen,
  faSearch,
  faPencilAlt,
  faCaretDown,
  faCheck,
  faFileUpload,
  faMinusCircle,
  faTasks,
  faTimes,
  faClipboardList,
  faPlus,
  faEye,
  faDownload,
  faEyeSlash,
);

const { REACT_APP_8BASE_API_ENDPOINT, REACT_APP_STRIPE_PUBLIC_KEY } = process.env;

const stripePromise = loadStripe(REACT_APP_STRIPE_PUBLIC_KEY);

const collabTheme = createTheme({
  /** Change the pallet of the color. */
  COLORS: {
    GREEN: GREEN_COLOR,
    RED: RED_COLOR,
    YELLOW: YELLOW_COLOR,
    PRIMARY: '#3EB7F9',
    GRAY4: '#939EA7',
    AMBER: AMBER_COLOR,
    BLUE_2: '#0096B8',
    LIGHT_BLUE: '#E9EFF4',
  },
  /** Change the custom components styles if it needed. */
  components: {
    dividerInner: {
      root: {
        backgroundColor: '#edf0f2',
      },
    },
    input: {
      // root: {
      //   borderColor: 'gray',
      // },
      modifiers: {
        hasError: {
          borderColor: 'red',
        },
      },
    },
    navigation: {
      modifiers: {
        color: {
          PRIMARY: {
            backgroundColor: '#008AAB',
          },
        },
      },
    },
    navigationItem: {
      modifiers: {
        color: {
          PRIMARY: {
            backgroundColor: '#008AAB',
            '&:hover, &.active': {
              backgroundColor: '#3EB7F9',
            },
          },
        },
      },
    },
    navigationItemLabel: {
      root: {
        color: 'white',
      },
    },
    // button: ({ COLORS, SIZES }) => ({
    //   root: {
    //     fontSize: SIZES.SMALL_FONT_SIZE,
    //   },
    //   modifiers: {
    //     disabled: {
    //       backgroundColor: COLORS.RED,
    //     },
    //   },
    // }),
  },
});
// TODO: Add below navigationPlate color to the new "createTheme"
// const collabTheme = merge(defaultTheme, {
//   COLORS: {
//     PRIMARY_BUTTON_BACKGROUND_COLOR: "#3EB7F9"
//   },
//   navigationPlate: {
//     modifiers: {
//       color: {
//         PRIMARY: {
//           backgroundColor: "#008AAB"
//         }
//       }
//     }
//   },
//   navigationItem: {
//     modifiers: {
//       color: {
//         PRIMARY: {
//           backgroundColor: "#008AAB",
//           "&:hover, &.active": {
//             backgroundColor: "#3EB7F9"
//           }
//         }
//       }
//     }
//   }
// });

class App extends React.PureComponent {
  state = {
    newVersionIsOpen: false,
    screenCapture: '',
  };

  componentDidMount = () => {
    setLoginRedirectUri();

    window.toastRefreshPage = () => {
      this.setState({ newVersionIsOpen: true });
    };
  };

  onCancel = () => {
    this.setState({ newVersionIsOpen: false });
  };

  onRefresh = async () => {
    this.setState({ newVersionIsOpen: false });

    if (window.caches) {
      const cacheNames = await window.caches.keys();
      cacheNames.forEach(function(cacheName) {
        window.caches.delete(cacheName);
      });
    } else console.log('noCache: ', window.cache);

    window.location.reload();
  };

  handleScreenCapture = (screenCapture) => {
    const body = document.getElementById('c2gScreen');
    if (body === null) {
      toast.error('Screenshot not available');
      return;
    }
    html2canvas(body).then(function(canvas) {
      let link = document.createElement('a');
      link.href = canvas.toDataURL('image/jpg'); //function blocks CORS
      link.download = 'screenshot.jpg';
      link.click();
    });
  };

  renderContent = (onStartCapture) => {
    return (
      <AsyncContent stretch>
        <Switch>
          <Route path="/auth" component={AuthCallback} />
          <Route path="/salesforce" component={SalesForceView} />
          <Route>
            <Session>
              <SelectedAllianceSubscription>
                <CommentsSideBar>
                  <RelatedItemSideBar>
                    <NotificationsSideBar>
                      <ChatView />
                      <MainPlate screenOnClick={onStartCapture}>
                        <Menu />
                        <ContentPlate>
                          <Switch>
                            <ProtectedRoute exact path="/dashboard" component={DashboardView} />
                            <ProtectedRoute exacts path="/wizard" component={Wizard} />
                            <ProtectedRoute path="/management" component={ManagementView} />
                            <ProtectedRoute path="/reports" component={ReportsView} />
                            <ProtectedRoute path="/active-items" component={ActiveItemsView} />
                            <ProtectedRoute path="/settings" component={SettingsView} />
                            <ProtectedRoute path="/members" component={AllianceMembersListView} />
                            <ProtectedRoute path="/procurer" component={ProcurerView} />
                            <ProtectedRoute path="/welcome" component={Welcome} />
                            <ProtectedRoute
                              path="/company-expired"
                              component={CompanyExpiredView}
                            />
                            <Redirect to={`/welcome`} />
                          </Switch>
                        </ContentPlate>
                      </MainPlate>
                    </NotificationsSideBar>
                  </RelatedItemSideBar>
                </CommentsSideBar>
              </SelectedAllianceSubscription>
            </Session>
          </Route>

          <Route render={() => <Redirect to="/auth" />} />
        </Switch>
      </AsyncContent>
    );
  };

  onRequestSuccess = ({ operation }) => {
    const message = operation.getContext()[TOAST_SUCCESS_MESSAGE];
    if (message) {
      toast.success(message);
    }
  };

  render() {
    const { onRequestError, onRequestSuccess, renderContent } = this;
    const { newVersionIsOpen } = this.state;

    return (
      <BrowserRouter>
        <BoostProvider theme={collabTheme}>
          <Elements stripe={stripePromise}>
            <AppProvider
              uri={REACT_APP_8BASE_API_ENDPOINT}
              authClient={AuthClient}
              onRequestSuccess={onRequestSuccess}
              onRequestError={onRequestError}>
              {renderContent(this.handleScreenCapture)}
            </AppProvider>
          </Elements>
          <ToastContainer position={toast.POSITION.BOTTOM_LEFT} />
          <NewVersionDialog
            isOpen={newVersionIsOpen}
            onCancel={this.onCancel}
            onRefresh={this.onRefresh}
          />
        </BoostProvider>
      </BrowserRouter>
    );
  }
}

export { App };
