import gql from 'graphql-tag';
import { UserFragment } from '../auth/queries';
import { NextStepFragment } from '../next-step/next-step-queries';

const NotificationFragment = gql`
  fragment NotificationFragment on Notification {
    id
    isRead
    message
    createdAt
    owner {
      ...UserFragment
    }
    nextStep {
      ...NextStepFragment
      riskNextStepsRelation {
        id
        name
      }
      ideaNextStepsRelation {
        id
        name
      }
      fundingRequestNextStepsRelation {
        id
        name
      }
      actionNextStepsRelation {
        id
        name
      }
      issueNextStepsRelation {
        id
        name
      }
    }
    initiative {
      id
      name
      description
      status
      createdAt
      businessCase {
        id
      }
      owner {
        ...UserFragment
      }
    }
    action {
      id
      name
      description
      status
      createdAt
      revisedDueDate
      businessCase {
        id
      }
      owner {
        ...UserFragment
      }
      nextSteps {
        items {
          id
          dueDate
          description
        }
      }
    }
    issue {
      id
      name
      description
      status
      createdAt
      revisedDueDate
      owner {
        ...UserFragment
      }
      nextSteps {
        items {
          id
          dueDate
          description
        }
      }
    }
    risk {
      id
      name
      description
      status
      createdAt
      revisedDueDate
      owner {
        ...UserFragment
      }
      nextSteps {
        items {
          id
          dueDate
          description
        }
      }
    }
    decision {
      id
      name
      description
      status
      createdAt
      businessCase {
        id
      }
      owner {
        ...UserFragment
      }
    }
    contribution {
      id
      name
      description
      status
      createdAt
      owner {
        ...UserFragment
      }
    }
    fundingRequest {
      id
      name
      description
      status
      createdAt
      revisedDueDate
      businessCase {
        id
      }
      owner {
        ...UserFragment
      }
      nextSteps {
        items {
          id
          dueDate
          description
        }
      }
    }
    idea {
      id
      name
      description
      status
      createdAt
      revisedDueDate
      businessCase {
        id
      }
      owner {
        ...UserFragment
      }
      nextSteps {
        items {
          id
          dueDate
          description
        }
      }
    }
    dealData {
      id
      name
      status
      createdAt
      owner {
        ...UserFragment
      }
    }
    alliance {
      id
      name
      status
      createdAt
      owner {
        ...UserFragment
      }
    }
  }
  ${UserFragment}
  ${NextStepFragment}
`;

/**
 * For searching.
 *
 * @type {*|*}
 */
export const NOTIFICATIONS_LIST_QUERY = gql`
  query($data: NotificationFilter) {
    notificationsList(filter: $data) {
      items {
        ...NotificationFragment
      }
    }
  }
  ${NotificationFragment}
`;

export const NOTIFICATION_DELETE_MUTATION = gql`
  mutation($data: NotificationDeleteInput!) {
    notificationDelete(data: $data) {
      success
    }
  }
`;

export const NOTIFICATION_DELETE_BY_FILTER_MUTATION = gql`
  mutation($filter: NotificationFilter!) {
    notificationDeleteByFilter(filter: $filter) {
      success
    }
  }
`;
