import { createEvent } from '@cobuildlab/react-simple-state';

export const OnActionError = createEvent();
export const OnActionCreate = createEvent();
export const OnActionAutoSave = createEvent();
export const OnActionAutoSaveError = createEvent();
export const OnActionBusinessCaseDelete = createEvent();
export const OnActionBusinessCaseDeleteError = createEvent();
export const OnActionUpdate = createEvent();
export const OnActionDetail = createEvent();
export const OnActionSubmitForApproval = createEvent();
export const OnActionReject = createEvent();
export const OnActionCompleted = createEvent();
export const OnActionRestore = createEvent();
export const OnActionApprovalList = createEvent();
