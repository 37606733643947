import React from 'react';
import { Card, Heading, Loader, Row } from '@8base/boost';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import * as toast from '../../../components/toast/Toast';
import * as R from 'ramda';
import { View } from '@cobuildlab/react-simple-state';
import { onErrorMixin } from '../../../shared/mixins';
import { Risk } from '@cobuildlab/collabtogrow-models';
import { fetchRiskDetail, completedRisk, restoreRisk } from './risk-action';
import sessionStore, { NEW_SESSION_EVENT } from '../../../shared/SessionStore';
import { onChangeMixin } from '../../../shared/mixins';
import withAlliance from '../../../components/hoc/withAlliance';
import RiskDetailTable from './components/RiskDetailTable';
import { openComments } from './risk-action';
import { canCompletedRisk, canRestoreRisk } from '@cobuildlab/collabtogrow-permissions';
import YesNoDialog from '../../../components/dialogs/YesNoDialog';
import { DetailViewCardBody } from '../../../components/card/DetailViewCardBody';
import { RelatedItemsDetailTable } from '../../related-item/components/RelatedItemsDetailTable';
import { RelatedItemsByItemDetailTable } from '../../related-item/components/RelatedItemsByItemDetailTable';
import { getItemByType } from '../../../shared/items-util';
import { TopButtons } from '../../../components/buttons/TopButtons';
import { ActionButton } from '../../../components/buttons/ActionButton';
import {
  fetchRelatedItems,
  fetchRelatedItemsByItemId,
} from '../../related-item/related-item-actions';
import { getCurrencyOnSession } from '../../../shared/alliance-utils';
import { RISK_DOCUMENTS } from './risk-model';
import { InitiativeListTable } from '../initiative/components/InitiativeListTable';
import {
  OnRelatedItemsByItem,
  OnRelatedItems,
  OnRelatedItemError,
} from '../../related-item/related-item-events';
import { OnRiskError, OnRiskCompleted, OnRiskRestore, OnRiskDetail } from './risk-events';

/**
 * Funding Request Detail View.
 */
class RiskDetailView extends View {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        riskData: Risk.clone(),
        relatedItems: [],
        initiatives: [],
        relatedItemsByItem: [],
      },
      fundingRequest: null,
      loading: true,
      approvalModalIsOpen: false,
      completedModalIsOpen: false,
      restoreModalIsOpen: false,
    };
    this.onChange = onChangeMixin.bind(this);
    this.onError = onErrorMixin.bind(this);
    this.user = sessionStore.getState(NEW_SESSION_EVENT).user;
    this.selectedAlliance = sessionStore.getState(NEW_SESSION_EVENT).selectedAlliance;
    this.queryOptions = { isCacheFirst: true };
  }

  componentDidMount() {
    const { match } = this.props;

    this.subscribe(OnRiskError, this.onError);
    this.subscribe(OnRelatedItemError, this.onError);

    this.subscribe(OnRiskDetail, (state) => {
      const { risk } = state;
      const { data } = this.state;
      const riskData = R.clone(risk);
      const initiatives = risk.initiatives.items.map((item) => ({ ...item }));
      localStorage.setItem(RISK_DOCUMENTS, JSON.stringify(riskData.documents.items));

      data.riskData = riskData;
      data.initiatives = initiatives;

      this.setState(
        { data },
        () => fetchRelatedItems(riskData.itemRiskRelation.id),
        fetchRelatedItemsByItemId(riskData.itemRiskRelation.id),
      );
    });

    this.subscribe(OnRelatedItems, (state) => {
      const items = state.item.itemsRelated.items.map((item) => getItemByType(item));
      const { data } = this.state;

      data.relatedItems = items;
      this.setState({ data });
    });

    this.subscribe(OnRelatedItemsByItem, (state) => {
      const {
        itemsList: { items: itemsRelated },
      } = state;
      const relatedItemsByItem = itemsRelated.map((item) => getItemByType(item));
      const { data } = this.state;
      console.log('relatedItemsByItemFetch', relatedItemsByItem);
      console.log('relatedItemsByItemFetchState', state);

      data.relatedItemsByItem = relatedItemsByItem;
      this.setState({ data, loading: false });
    });

    this.subscribe(OnRiskCompleted, (state) => {
      fetchRiskDetail(match.params.id);
      toast.success('Risk Successfully Completed');
    });

    this.subscribe(OnRiskRestore, (state) => {
      fetchRiskDetail(match.params.id);
      toast.success('Risk Successfully Restored');
    });

    if (!match.params.id) return toast.error('Risk ID missing');
    fetchRiskDetail(match.params.id, this.queryOptions);
  }

  componentWillUnmount() {
    super.componentWillUnmount();
    localStorage.removeItem(RISK_DOCUMENTS);
  }

  completeModal = () => {
    this.setState({
      completedModalIsOpen: true,
    });
  };

  onYes = () => {
    this.setState(
      {
        completedModalIsOpen: false,
        loading: true,
      },
      () => {
        const riskData = R.clone(this.state.data.riskData);
        completedRisk(riskData, this.queryOptions);
      },
    );
  };

  onYesRestore = () => {
    this.setState(
      {
        restoreModalIsOpen: false,
        loading: true,
      },
      () => {
        const riskData = R.clone(this.state.data.riskData);
        restoreRisk(riskData, this.queryOptions);
      },
    );
  };

  onCloseModalCompleted = () => {
    this.setState({
      completedModalIsOpen: false,
    });
  };

  onCloseModalRestore = () => {
    this.setState({
      restoreModalIsOpen: false,
    });
  };

  restoreModal = () => {
    this.setState({
      restoreModalIsOpen: true,
    });
  };
  render() {
    const { data, loading, completedModalIsOpen, restoreModalIsOpen } = this.state;
    const { riskData, initiatives, relatedItems, relatedItemsByItem } = data;
    const { history } = this.props;

    let content = <Loader stretch />;
    let buttonsBottom = '';
    let buttonsTop = '';
    const alliance = this.selectedAlliance;
    const currency = getCurrencyOnSession();

    const _canCompletedRisk = canCompletedRisk(this.user, riskData, alliance);

    if (!loading) {
      content = (
        <div id={'c2gScreen'}>
          <RiskDetailTable
            data={riskData}
            currency={currency}
            onClickEdit={() => history.push(`/management/risk/edit/${riskData.id}`)}
          />
          <InitiativeListTable initiatives={initiatives} />
          <RelatedItemsDetailTable relatedItems={relatedItems} />
          <RelatedItemsByItemDetailTable relatedItemsByItem={relatedItemsByItem} />
        </div>
      );

      buttonsTop = (
        <>
          <Heading type="h4" text={riskData.name} />

          <TopButtons
            onClickClosed={history.goBack}
            onClickCollaborated={() => openComments(riskData)}
          />
        </>
      );

      buttonsBottom = (
        <Row justifyContent="end">
          {_canCompletedRisk ? (
            <ActionButton
              text="Mark Completed"
              fontAwesomeIcon="clipboard-list"
              onClick={() => {
                this.completeModal();
              }}
            />
          ) : null}

          {canRestoreRisk(this.user, riskData, alliance) ? (
            <ActionButton
              text="Restore"
              fontAwesomeIcon="clipboard-list"
              onClick={() => {
                this.restoreModal();
              }}
            />
          ) : null}
        </Row>
      );
    }

    return (
      <React.Fragment>
        <Card.Header>{buttonsTop}</Card.Header>
        <DetailViewCardBody>{content}</DetailViewCardBody>
        <Card.Footer>{buttonsBottom}</Card.Footer>
        <YesNoDialog
          title={'Complete Risk'}
          onYes={this.onYes}
          onCloseModalCompleted={this.onCloseModalCompleted}
          onNo={this.onCloseModalCompleted}
          text={'Are you sure you want to Mark the Risk as Completed?'}
          isOpen={completedModalIsOpen}
        />

        <YesNoDialog
          title={'Restore Risk'}
          onYes={this.onYesRestore}
          onCloseModalCompleted={this.onCloseModalRestore}
          onNo={this.onCloseModalRestore}
          text={'Are you sure you want to Mark the Risk as Restored?'}
          isOpen={restoreModalIsOpen}
        />
      </React.Fragment>
    );
  }
}

RiskDetailView.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default withRouter(withAlliance(RiskDetailView));
