import Flux from '@cobuildlab/flux-state';

/**
 * Event that triggers data for Balanced Scorecards.
 */
export const FINANCIAL_SCORECARD_DATA_EVENT = 'onFinancialScorecardData';

export const STRATEGY_SCORECARD_DATA_EVENT = 'onStrategyScorecardData';

export const RELATIONSHIP_SCORECARD_DATA_EVENT = 'onRelationshipScorecardData';

export const OPERATIONAL_SCORECARD_DATA_EVENT = 'onOperationalScorecardData';

export const BALANCED_SCORECARD_DATA_EVENT = 'onBalancedScorecardData';

export const BALANCED_SCORECARD_ERROR_EVENT = 'onBalancedScorecardError';

/**
 * Hold the Active Sales Pipeline data.
 */
class BalancedScorecardStore extends Flux.DashStore {
  constructor() {
    super();
    this.addEvent(BALANCED_SCORECARD_DATA_EVENT);
    this.addEvent(BALANCED_SCORECARD_ERROR_EVENT);
    this.addEvent(FINANCIAL_SCORECARD_DATA_EVENT);
    this.addEvent(STRATEGY_SCORECARD_DATA_EVENT);
    this.addEvent(RELATIONSHIP_SCORECARD_DATA_EVENT);
    this.addEvent(OPERATIONAL_SCORECARD_DATA_EVENT);
  }
}

const balancedScorecardStore = new BalancedScorecardStore();
export default balancedScorecardStore;
