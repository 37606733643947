import Flux from '@cobuildlab/flux-state';

/**
 * Event that triggers a Next Steps error.
 *
 * @type {string}
 */
export const NEXT_STEP_ERROR_EVENT = 'onNextStepError';

/**
 * Event that triggers a Next Steps Update event.
 *
 * @type {string}
 */
export const NEXT_STEP_UPDATE_EVENT = 'onNextStepUpdate';

/**
 * Event that triggers a Next Steps Complete event.
 *
 * @type {string}
 */
export const NEXT_STEP_COMPLETE_EVENT = 'onNextStepComplete';

/**
 * Event that triggers a Next Steps Delete event.
 *
 * @type {string}
 */
export const NEXT_STEP_DELETE_EVENT = 'onNextStepDelete';

/**
 * Hold the NextStep Data.
 */
class NextStepStore extends Flux.DashStore {
  constructor() {
    super();
    this.addEvent(NEXT_STEP_ERROR_EVENT);
    this.addEvent(NEXT_STEP_UPDATE_EVENT);
    this.addEvent(NEXT_STEP_COMPLETE_EVENT);
    this.addEvent(NEXT_STEP_DELETE_EVENT);
  }
}

export default new NextStepStore();
