import React from 'react';
import sessionStore, { NEW_SESSION_EVENT } from '../../../shared/SessionStore';
import { isUserAllianceSER } from '../../../shared/alliance-utils';
import { Redirect } from 'react-router-dom';

const Welcome = () => {
  const { user, selectedAlliance } = sessionStore.getState(NEW_SESSION_EVENT);

  if (isUserAllianceSER(user, selectedAlliance)) {
    return <Redirect to={`/reports/balanced-scorecard/report`} />;
  }

  return <Redirect to={`/dashboard`} />;
};

export { Welcome };
