import React from 'react';
import { Loader } from '@8base/boost';
import styled from 'styled-components';

const MainLoaderContainer = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const MainLoader = () => (
  <MainLoaderContainer>
    <Loader size="md" />
  </MainLoaderContainer>
);

export default MainLoader;
