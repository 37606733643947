import React from 'react';
import { Card, Heading, Row, Column, SelectField, Text } from '@8base/boost';
import * as toast from '../../../components/toast/Toast';
import { Loader } from '@8base/boost';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { View } from '@cobuildlab/react-simple-state';
import { onErrorMixin } from '../../../shared/mixins';
import Header from '../../../components/Header';
import { parseCSV } from './deal-actions';
import { fetchCurrentAllianceMembersAction } from '../../settings/alliance-management/alliance-actions';
import { FileInputField } from '../../../shared/components/FileInputField';
import FileIcon from '../../../components/FileIcon';
import { RowSeparator } from '../../../shared/components/RowSeparator';
import { ActionButton } from '../../../components/buttons/ActionButton';
import { ActionButtonClose } from '../../../components/buttons/ActionButtonClose';
import { documentFormatBase64 } from '../../../shared/utils';
import { OnDealError, OnDealCreate, OnDealImported } from '../deal/deal-events';
import { OnAllianceMemberList } from '../../settings/alliance-management/alliance-events';

/**
 * Import Deal.
 *
 */

class DealImportView extends View {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      company: null,
      clientCompany: null,
      partnerCompany: null,
      files: [],
    };
    this.onError = onErrorMixin.bind(this);
  }

  componentDidMount() {
    this.subscribe(OnDealError, this.onError);

    this.subscribe(OnAllianceMemberList, (state) => {
      this.setState({
        clientCompany: state.clientCompany,
        partnerCompany: state.partnerCompany,
        loading: false,
      });
    });

    this.subscribe(OnDealCreate, (state) => {
      toast.success('Deal Successfully Created');
      this.props.history.goBack();
    });

    this.subscribe(OnDealImported, () => {
      this.props.history.push(`/management/deal/imported`);
    });

    fetchCurrentAllianceMembersAction();
  }

  onFileChange = (fileList) => {
    const files = [];
    for (let i = 0, j = fileList.length; i < j; i++)
      files[i] = documentFormatBase64(fileList.item(i));
    this.setState({ files });
  };

  onSubmit = () => {
    const { files, company, clientCompany, partnerCompany } = this.state;
    if (files.length === 0) {
      toast.error('No files selected');
      return;
    }

    if (!company) {
      toast.error('No company selected');
      return;
    }

    let companyObject = clientCompany;
    let otherCompanyObject = partnerCompany;
    if (partnerCompany) {
      if (company === partnerCompany.id) {
        companyObject = partnerCompany;
        otherCompanyObject = clientCompany;
      }
    }

    this.setState({ loading: true }, () => parseCSV(companyObject, otherCompanyObject, files));
  };

  render() {
    const { loading, clientCompany, partnerCompany, files } = this.state;
    let content = <Loader stretch />;
    let footer = <></>;
    let headerText = '';

    if (!loading) {
      const { company } = this.state;
      const companySources = [];
      if (clientCompany !== null) {
        companySources.push({ label: clientCompany.name, value: clientCompany.id });
      }

      if (partnerCompany !== null) {
        companySources.push({ label: partnerCompany.name, value: partnerCompany.id });
      }
      headerText = 'Deals Information';
      content = (
        <>
          <Row growChildren gap="lg">
            <Column alignItems="stretch">
              <SelectField
                label="Company"
                input={{
                  name: 'company',
                  value: company,
                  onChange: (value) => this.setState({ company: value }),
                }}
                meta={{}}
                placeholder="Select"
                options={companySources}
              />
            </Column>
            <Column
              growChildren={true}
              alignItems={'center'}
              alignContent={'center'}
              justifyContent={'center'}>
              <br />
              <FileInputField multiple accept={'.csv'} onChange={this.onFileChange} />
            </Column>
          </Row>
          <RowSeparator color={'lightgrey'} />
          <Row growChildren gap="lg">
            <Column alignItems="stretch">
              <Text>Import Files</Text>
            </Column>
          </Row>
          <Row growChildren gap="lg">
            <Column alignItems="center">
              {files.map((file, i) => (
                <FileIcon key={i} file={file} />
              ))}
            </Column>
          </Row>
        </>
      );
      footer = (
        <Card.Footer>
          <ActionButton text={'Import'} onClick={this.onSubmit} />
        </Card.Footer>
      );
    }

    return (
      <React.Fragment>
        <Card.Header>
          <Heading type="h4" text="Create Deals" />
          <div className="positionBottonClose">
            <ActionButtonClose onClick={() => this.props.history.goBack()} />
          </div>
        </Card.Header>
        <Card.Body
          borderRadius="all"
          style={{ padding: 20, textAlign: 'center' }}
          className="card-body">
          <Header text={headerText} />
          {content}
        </Card.Body>
        {footer}
      </React.Fragment>
    );
  }
}

DealImportView.propTypes = {
  history: PropTypes.object.isRequired,
};

export default withRouter(DealImportView);
