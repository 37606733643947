import { ERRORS_TEXT } from '../../reports-model';

const FILTERS_WITHOUT_CONDITIONALS = [
  'thisYear',
  'lastMonth',
  'rollingSixMonth',
  'lastWeek',
  'today',
  'yesterday',
  'mtd',
  'ytd',
  'is_empty',
  'is_not_empty',
];

const validatePivotTableConfig = (formValues) => {
  const errors = {};

  const { itemType, fieldsList, filters, sorts, groups } = formValues;

  if (!itemType) {
    errors.itemType = ERRORS_TEXT.required;
  }

  if (!fieldsList || fieldsList.length === 0) {
    errors.fieldsList = ['You should select at least one field'];
  }

  if (filters && filters.length > 0) {
    errors.filters = filters.map(({ fieldId, rule, cond }) => {
      let error = {};

      if (!fieldId) {
        error.fieldId = ERRORS_TEXT.required;
      }
      if (!rule) {
        error.rule = ERRORS_TEXT.required;
      }
      if (!cond) {
        const withoutConditional = FILTERS_WITHOUT_CONDITIONALS.some((fieldName) => {
          return fieldName === rule;
        });
        if (!withoutConditional) {
          error.cond = ERRORS_TEXT.required;
        }
      }

      return error;
    });
  }

  if (sorts && sorts.length > 0) {
    errors.sorts = sorts.map((sort) => {
      let error = {};
      if (!sort.key) {
        error.key = ERRORS_TEXT.required;
      }
      return error;
    });
  }

  if (groups && groups.length > 0) {
    errors.groups = sorts.map((group) => {
      let error = {};
      if (!group.key) {
        error.key = ERRORS_TEXT.required;
      }
      return error;
    });
  }

  return errors;
};

export { validatePivotTableConfig };
