import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { BalancedScorecardReportView } from './BalancedScorecardReportView';
import { ScorecardFinancialDetail, ScorecardStrategyDetail } from './components';
import { ScorecardRelationshipDetail } from './components/ScorecardRelationshipDetail';
import { ScorecardOperationalDetail } from './components/ScorecardOperationalDetail';

const BalancedScorecardView = () => {
  return (
    <div id={'c2gScreen'}>
      <Switch>
        <Route path="/reports/balanced-scorecard/report" component={BalancedScorecardReportView} />
        <Route path="/reports/balanced-scorecard/financial" component={ScorecardFinancialDetail} />
        <Route path="/reports/balanced-scorecard/strategy" component={ScorecardStrategyDetail} />
        <Route
          path="/reports/balanced-scorecard/relationship"
          component={ScorecardRelationshipDetail}
        />
        <Route
          path="/reports/balanced-scorecard/operational"
          component={ScorecardOperationalDetail}
        />
        <Redirect to="/reports/balanced-scorecard/report" />
      </Switch>
    </div>
  );
};

export { BalancedScorecardView };
