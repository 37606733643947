import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';

import { SingleTable, GroupSection, GroupTitle } from './Components';

const PivotTableView = ({ tablesData, currencyFields }) => {
  // if tablesData id an Object, we render Pivot Table without any groupings

  if (!R.is(Array, tablesData)) {
    return <SingleTable tableData={tablesData} currencyFields={currencyFields} />;
  }

  // if we here, we render Pivot Table with groupings
  return tablesData.map((tableData, index) => {
    const { title } = tableData;
    return (
      <GroupSection key={index}>
        {title ? <GroupTitle>{title}</GroupTitle> : null}
        <SingleTable tableData={tableData} currencyFields={currencyFields} />
      </GroupSection>
    );
  });
};

PivotTableView.propTypes = {
  tablesData: PropTypes.object.isRequired,
  currencyFields: PropTypes.array,
};

PivotTableView.defaultProps = {
  currencyFields: [],
};

export { PivotTableView };
