import React from 'react';
import { Dropdown } from '@8base/boost';
import PropTypes from 'prop-types';

/**
 * Use this instead of Dropdown.Body for tables, the withPortal
 * prop will fix the dropdown visibility problems
 * to change the dropdown position update the placement & pin props.
 *
 */

const DropdownBodyOnTable = ({ children }) => {
  return (
    <Dropdown.Body forceRender withPortal placement="bottom" pin="left">
      {children}
    </Dropdown.Body>
  );
};

DropdownBodyOnTable.propTypes = {
  children: PropTypes.any.isRequired,
};

export { DropdownBodyOnTable };
