import React from 'react';
import { Label as BoostLabel } from '@8base/boost';
import PropTypes from 'prop-types';

import styled from 'styled-components';

const StyledLabel = styled(BoostLabel)`
  margin-bottom: 4px !important;
`;

const Label = ({ kind, text, ...rest }) => {
  return <StyledLabel kind={'secondary'} text={text} {...rest} />;
};

Label.propTypes = {
  kind: PropTypes.string,
  text: PropTypes.string.isRequired,
};

Label.defaultProps = {
  kind: 'secondary',
};

export { Label };
