import React from 'react';
import styled from 'styled-components';
import { Icon } from '@8base/boost';

const PlusSeparator = () => (
  <Container>
    <Icon name={'Plus'} size={'xs'} />
  </Container>
);

const Container = styled.div`
  display: flex;
  align-items: center;
  margin: 0 8px;
  height: 35px;
`;

export { PlusSeparator };
