import { getCompanyOnAlliance } from '../../../shared/alliance-utils';
import { IDEA_APPROVAL_PENDING } from '../../../shared/status';

/**
 * Return true if the User's company's approval or rejection
 * of the Idea is pending.
 *
 * @param {object} user - The user.
 * @param {object} alliance - The Alliance.
 * @param {object} idea - The Idea.
 * @returns {boolean} Is user pending idea approval.
 */
export const isUserPendingIdeaApproval = (user, alliance, idea) => {
  const { ideaApprovalRelation } = idea;
  const userCompany = getCompanyOnAlliance(user, alliance);

  const userCompanyIsPendingResponse = ideaApprovalRelation.items.find(
    (approval) =>
      userCompany.id === approval.company.id && approval.status === IDEA_APPROVAL_PENDING,
  );

  return !!userCompanyIsPendingResponse;
};
