export const stageMappingValues = () => {
  const stageMapping = [];
  stageMapping.push(stageMappingQualification());
  stageMapping.push(stageMappingNeedsAnalysis());
  stageMapping.push(stageMappingProposalCreation());
  stageMapping.push(stageMappingProposalPresentation());
  stageMapping.push(stageMappingNegotiation());
  stageMapping.push(stageMappingClosedWon());
  stageMapping.push(stageMappingClosedLost());

  return stageMapping;
};

const stageMappingQualification = () => {
  const qualificationData = {
    name: 'Qualification',
    winProbability: 10,
    entranceCriteria: 'Initially Identified as an Alliance opportunity',
    exitCriteria: 'Qualified Alliance opportunity',
    description:
      'Once an Alliance opportunity has been identified, the Alliance Partner(s) schedule meeting and discussions with the Prospect to determine if this is qualified opportunity',
    values: [],
  };
  return qualificationData;
};

const stageMappingNeedsAnalysis = () => {
  const needsAnalysis = {
    name: 'Needs Analysis',
    winProbability: 20,
    entranceCriteria: 'Qualified Alliance opportunity',
    exitCriteria: 'Requirements, cost/price estimates, closing date defined',
    description:
      'The Alliance Partners Account Teams work together to flesh out the details requirements, prepare cost and time estimates and an expected closing date for the deal',
    values: [],
  };
  return needsAnalysis;
};
const stageMappingProposalCreation = () => {
  const proposalCreation = {
    name: 'Proposal Creation',
    winProbability: 30,
    entranceCriteria: 'Requirements, cost/price estimates, closing date defined',
    exitCriteria:
      'Value proposition defined and proposal prepared, vetted and approved by both Alliance partners',
    description:
      'The Alliance Partners work together to define the value proposition for the Prospect and the prepare a formal proposal. The proposal is then vetted and formally approved by appropriate executives from both Partners',
    values: [],
  };
  return proposalCreation;
};

const stageMappingProposalPresentation = () => {
  const proposalPresentation = {
    name: 'Proposal Presentation',
    winProbability: 50,
    entranceCriteria:
      'Value proposition defined and proposal prepared, vetted and approved by both Alliance partners',
    exitCriteria: 'Proposal formally delivered and presented to Prospect',
    description: 'The proposal is formally presented and delivered to the Prospect',
    values: [],
  };
  return proposalPresentation;
};

const stageMappingNegotiation = () => {
  const negotiation = {
    name: 'Negotiation',
    winProbability: 90,
    entranceCriteria: 'Proposal formally delivered and presented to Prospect',
    exitCriteria:
      'Proposal modified as needed, all other terms / conditions finalized and updated proposal and legal artifacts delivered to Prospect OR negotiations not successful',
    description:
      'Negotiations occur on any not agreed-upon proposal items as well as on any of the terms and conditions and other legal requirements of either the Prospect or the Alliance Partners',
    values: [],
  };
  return negotiation;
};

const stageMappingClosedWon = () => {
  const closedWon = {
    name: 'Closed Won',
    winProbability: 100,
    entranceCriteria:
      'Proposal modified as needed, all other terms / conditions finalized and updated proposal and legal documents delivered to Prospect',
    exitCriteria: 'All proposal and legal documents fully executed',
    description:
      'The proposal and accompanying legal documents are updated as per the negotiation, re-vetted and re-approved (as needed) by the Alliance Partners, and the delivered to and accepted  by the Prospect',
    values: [],
  };
  return closedWon;
};

const stageMappingClosedLost = () => {
  const closedLost = {
    name: 'Closed Lost',
    winProbability: 0,
    entranceCriteria:
      'Negotiations not successful (or opportunity closed by Prospect for other reason )',
    exitCriteria: '"Closed Lost" analysis completed',
    description: 'A "Closed Lost" post-morten analysis completed jointly by Alliance Partners',
    values: [],
  };
  return closedLost;
};

export const stageDetailMappingData = (dealStageList) => {
  const data = [];
  dealStageList.items.forEach((dealStage) => {
    dealStage.values = dealStage.values.map((dealStageValue) => {
      return dealStageValue.value;
    });
    data.push(dealStage);
  });
  return data;
};

/**
 * Determinate if some value is duplicate in the array.
 *
 * @param {Array} array - Array.
 * @returns {boolean} Is Some Value Duplicated?.
 */
export const isSomeValueDuplicated = (array) => {
  const items = [...array];
  let item;
  while ((item = items.pop())) {
    if (items.includes(item)) {
      return true;
    }
  }
  return false;
};
