import {
  ACTIVE_BUSINESS_ITEMS_QUERY,
  ALLIANCE_APPROVAL_DELETE_MUTATION,
  ALLIANCE_APPROVAL_LIST,
  ALLIANCE_APPROVAL_UPDATE_MUTATION,
  ALLIANCE_APPROVALS_LIST_QUERY,
  ALLIANCE_COMMENTS_QUERY,
  ALLIANCE_CREATE_MUTATION,
  ALLIANCE_DELETE_MUTATION,
  ALLIANCE_DELETED_LIST_QUERY,
  ALLIANCE_DETAIL_QUERY,
  ALLIANCE_FILES_SIZE_QUERY,
  ALLIANCE_FORM_DATA_QUERY,
  ALLIANCE_LIST_QUERY,
  ALLIANCE_MEMBERS_QUERY,
  ALLIANCE_MEMBERS_WITH_ROLE_QUERY,
  ALLIANCE_REACTIVATE_QUERY,
  ALLIANCE_SMALL_LIST_QUERY,
  ALLIANCE_UPDATE_MUTATION,
  ALLIANCE_USER_UPDATE_MUTATION,
  ALLIANCES_BIG_LIST_QUERY,
  CREATE_ALLIANCE_DELETED_MUTATION,
  CREATE_ALLIANCE_MEMBER_INVITATION_MUTATION,
  CREATE_ALLIANCE_USER_MUTATION,
  DELETE_ALLIANCE_USER_MUTATION,
  DELETED_ALLIANCE_DELETED_MUTATION,
  ALLIANCE_LIST_EXCEL_QUERY,
} from './alliance-queries';
import {
  COMMENT_CREATE_EVENT,
  COMMENT_ERROR_EVENT,
  COMMENT_REQUEST_EVENT,
  COMMENTS_EVENT,
} from '../../comment/comment-store';
import { COMMENTS_CREATE_MUTATION } from '../../comment/comment-queries';
import { ALLIANCE_TYPE } from '../../../shared/item-types';
import Flux from '@cobuildlab/flux-state';
import {
  ALLIANCE_APPROVAL_LIST_EVENT,
  ALLIANCE_APPROVED_EVENT,
  ALLIANCE_AUTO_UPDATE_ERROR_EVENT,
  ALLIANCE_AUTO_UPDATE_EVENT,
  ALLIANCE_COMPLETED_EVENT,
  ALLIANCE_CREATE_EVENT,
  ALLIANCE_DELETE_EVENT,
  ALLIANCE_DELETED_EVENT,
  ALLIANCE_DELETED_LIST_EVENT,
  ALLIANCE_DETAIL_EVENT,
  ALLIANCE_DETAIL_LIST_MEMBERS_EVENT,
  ALLIANCE_ERROR_EVENT,
  ALLIANCE_EXCEL_LIST_EVENT,
  ALLIANCE_FILES_SIZE_EVENT,
  ALLIANCE_FORM_DATA_EVENT,
  ALLIANCE_LIST_EVENT,
  ALLIANCE_LIST_MEMBERS_EVENT,
  ALLIANCE_LIST_MEMBERS_WITH_ROLES_EVENT,
  ALLIANCE_MEMBER_INVITATIONS_LIST_EVENT,
  ALLIANCE_REACTIVATE_EVENT,
  ALLIANCE_REJECT_EVENT,
  ALLIANCE_RESTORE_EVENT,
  ALLIANCE_ROLE_CHANGE_EVENT,
  ALLIANCE_SUBMIT_FOR_APPROVAL_EVENT,
  ALLIANCE_UPDATE_EVENT,
  CANCEL_ALLIANCE_DELETED_EVENT,
  CREATE_ALLIANCE_MEMBER_EVENT,
  DELETE_ALLIANCE_USER_EVENT,
  INVITE_ALLIANCE_MEMBER_EVENT,
  ALLIANCE_AUTOSAVE_EVENT,
  ALLIANCE_AUTOSAVE_ERROR_EVENT,
} from './alliance-store';
import sessionStore, {
  APOLLO_CLIENT,
  NEW_SESSION_EVENT,
  SESSION_ALLIANCES_EVENT,
} from '../../../shared/SessionStore';
import { IntegrityError } from '../../../shared/errors';
import { sanitize8BaseReferenceFromObject } from '../../../shared/utils';
import { isValidString } from '../../../shared/validators';
import {
  createAllianceMemberInvitationValidator,
  createAllianceMemberValidator,
  createAllianceValidator,
  requestApprovalAllianceValidator,
  validateAllianceMemberInvitation,
} from './alliance-validators';
import { businessCaseValidator } from '../../document-management/business-case/business-case-validators';
import {
  businessCaseSearchFilterOR,
  sanitizeRecommendedSolutions,
  sanitizeRecommendedSolutionsToEdit,
  updateBusinessCase,
} from '../../document-management/business-case/businessCases.actions';
import {
  ACTION_APPROVED,
  ALLIANCE_APPROVAL_APPROVED,
  ALLIANCE_APPROVAL_REJECTED,
  ALLIANCE_APPROVED,
  ALLIANCE_COMPLETED,
  ALLIANCE_IN_PROGRESS,
  ALLIANCE_REJECTED,
  ALLIANCE_SUBMITTED_FOR_APPROVAL,
  FUNDING_REQUEST_APPROVED,
  IDEA_APPROVED,
  INITIATIVE_APPROVED,
} from '../../../shared/status';
import {
  ALLIANCE_ADMINISTRATOR,
  ALLIANCE_SER,
  COMPANY_PORTFOLIO_OWNER,
} from '../../../shared/roles';
import { error, log } from '@cobuildlab/pure-logger';
import { ALLIANCE_MEMBER_INVITATIONS_QUERY } from '../invitations/invitations.queries';
import {
  allianceKPIsToBeCreated,
  deleteAllianceKPIs,
  getBusinessCaseAnticipatedCosts,
  sanitizeAllianceKPIs,
  sanitizeAllianceKPIsCreate,
  sanitizeAllianceKPIsToEdit,
  updateAllianceKPIs,
} from './allianceKPIs/allianceKPIs-actions';
import * as R from 'ramda';
import {
  normalize8baseDocumentCreate,
  normalize8baseDocumentDeleteAndUpdate,
  normalize8baseDocumentsCreate,
  normalize8baseDocumentsDeleteAndUpdate,
} from '@cobuildlab/8base-utils';
import { canAddMemberToAlliance } from '@cobuildlab/collabtogrow-permissions';
import {
  createCompanyMutation,
  updateCompanyMutation,
  getCompanyUserAdminAndPortfolio,
} from '../company-management/company-actions';
import {
  formatAllianceToUpdate,
  formatBusinessCaseToUpdate,
  getCurrencyOnSession,
} from '../../../shared/alliance-utils';
import {
  OnAllianceMemberList,
  OnAllianceError,
  OnAllianceFileSize,
  OnAllianceList,
  OnAllianceExcelList,
  OnAllianceSubmitForApproval,
  OnAllianceDelete,
  OnAllianceFormData,
  OnAllianceCreate,
  OnAllianceAutoSave,
  OnAllianceAutoSaveError,
  OnAllianceAutoUpdate,
  OnAllianceAutoUpdateError,
  OnAllianceDetail,
  OnAllianceUpdate,
  OnAllianceApprovalList,
  OnAllianceReject,
  OnAllianceCompleted,
  OnAllianceRestore,
  OnAllianceDeleted,
  OnAllianceCancelDeleted,
  OnAllianceDetailListMembers,
  OnAllianceDeletedList,
  OnAllianceReactive,
  OnAllianceMemberWithRoles,
  OnAllianceRoleChange,
  OnAllianceMemberDelete,
  OnAllianceMemberCreate,
  OnAllianceInvitationMember,
} from './alliance-events';
import { OnSessionAlliances } from '../../../shared/session-events';
import {
  OnCommentError,
  OnCommentRequest,
  OnCommentCreate,
  OnComment,
} from '../../comment/comment-events';

/**
 * Creates a filter object and search by a string
 on string properties of the alliance.
 *
 * @param {string} userId - The userId to filter.
 * @param {string} search - The string to search.
 * @param {string} status - Status.
 * @returns {object} - The filter object.
 */
export const allianceFilter = (userId, search = '', status) => {
  const filter = {
    allianceUserAllianceRelation: {
      some: { companyUser: { user: { id: { equals: userId } } } },
    },
    OR: [
      {
        name: {
          contains: search,
        },
      },
      {
        description: {
          contains: search,
        },
      },
      {
        owner: {
          firstName: { contains: search },
        },
      },
      {
        owner: {
          lastName: { contains: search },
        },
      },
      {
        owner: {
          email: { contains: search },
        },
      },
    ].concat(businessCaseSearchFilterOR(search)),
  };
  if (status) {
    filter.status = { equals: status };
  }

  return filter;
};

/**
 * Reject and Alliance.
 *
 * @param {object} alliance - Alliance.
 * @param {string} status - Alliance new status.
 * @returns {Promise<void|*>} Promise.
 */
export const updateAllianceApproval = async (alliance, status) => {
  console.log('updateAllianceApproval', alliance, status);
  const client = sessionStore.getState(APOLLO_CLIENT);
  const { user } = sessionStore.getState(NEW_SESSION_EVENT);

  if (status !== ALLIANCE_APPROVAL_REJECTED && status !== ALLIANCE_APPROVAL_APPROVED) {
    OnAllianceError.dispatch(new IntegrityError('Alliance Approval status is invalid'));

    Flux.dispatchEvent(
      ALLIANCE_ERROR_EVENT,
      new IntegrityError('Alliance Approval status is invalid'),
    );
    return;
  }

  // We fetch the approvals to see what's gonna happen
  const { allianceApprovalsList } = await fetchAllianceApprovals(alliance);
  const approvalList = allianceApprovalsList.items;

  if (!alliance.oneSided && approvalList.length < 2) {
    OnAllianceError.dispatch(
      new IntegrityError('Alliance must have at least 2 request for approvals'),
    );

    Flux.dispatchEvent(
      ALLIANCE_ERROR_EVENT,
      new IntegrityError('Alliance must have at least 2 request for approvals'),
    );
    return;
  }

  const userCompanyIds = user.companyUserRelation.items.map((item) => item.company.id);
  const allianceApproval = approvalList.find((allianceApproval) =>
    userCompanyIds.includes(allianceApproval.company.id),
  );

  console.log({ allianceApprovalsList, approvalList, allianceApproval });

  if (!allianceApproval) {
    OnAllianceError.dispatch(
      new IntegrityError("You can't reject this Alliance, your Company does not belong here."),
    );

    return Flux.dispatchEvent(
      ALLIANCE_ERROR_EVENT,
      new IntegrityError("You can't reject this Alliance, your Company does not belong here."),
    );
  }

  const allianceApprovalUpdate = {
    mutation: ALLIANCE_APPROVAL_UPDATE_MUTATION,
    variables: {
      approval: {
        id: allianceApproval.id,
        status,
        dateOfResponse: new Date(),
        approvedBy: {
          connect: {
            id: user.id,
          },
        },
      },
    },
  };

  let response;
  try {
    response = await client.mutate(allianceApprovalUpdate);
  } catch (e) {
    error('updateAllianceApproval', e);
    OnAllianceError.dispatch(e);
    return Flux.dispatchEvent(ALLIANCE_ERROR_EVENT, e);
  }
  log('updateAllianceApproval', response);

  const event =
    status === ALLIANCE_APPROVAL_APPROVED ? ALLIANCE_UPDATE_EVENT : ALLIANCE_REJECT_EVENT;

  if (status === ALLIANCE_APPROVAL_REJECTED) {
    OnAllianceReject.dispatch(response.data);
  }

  if (status === ALLIANCE_APPROVAL_APPROVED) {
    OnAllianceUpdate.dispatch(response.data);
  }

  Flux.dispatchEvent(event, response.data);
  return response.data;
};

/**
 * Request and Approval for an Alliance.
 *
 * @param {object} alliance - The id of the alliance to be deleted.
 * @returns {Promise<void|*>} Promise.
 */
export const requestApprovalForAlliance = async (alliance) => {
  const client = sessionStore.getState(APOLLO_CLIENT);

  try {
    requestApprovalAllianceValidator(alliance, alliance.businessCase);
  } catch (e) {
    error('requestApprovalForAlliance', e);
    OnAllianceError.dispatch(e);
    return Flux.dispatchEvent(ALLIANCE_ERROR_EVENT, e);
  }

  let allianceApprovalRelationList = [{ company: { connect: { id: alliance.clientCompany.id } } }];
  if (!alliance.oneSided) {
    allianceApprovalRelationList.push({ company: { connect: { id: alliance.partnerCompany.id } } });
  }

  const data = {
    id: alliance.id,
    status: 'SUBMITTED FOR APPROVAL',
    allianceApprovalRelation: {
      create: allianceApprovalRelationList,
    },
  };

  let response;
  try {
    response = await client.mutate({
      mutation: ALLIANCE_UPDATE_MUTATION,
      variables: { data },
    });
  } catch (e) {
    error('requestApprovalForAlliance', e);
    OnAllianceError.dispatch(e);
    return Flux.dispatchEvent(ALLIANCE_ERROR_EVENT, e);
  }
  log('requestApprovalForAlliance', response);
  OnAllianceSubmitForApproval.dispatch(response.data);
  Flux.dispatchEvent(ALLIANCE_SUBMIT_FOR_APPROVAL_EVENT, response.data);
  return response.data;
};

/**
 * Delete an Alliance.
 *
 * @param {object} alliance - Alliance.
 * @returns {Promise<void|*>} Promise.
 */
export const deleteAlliance = async (alliance) => {
  const { selectedAlliance } = sessionStore.getState(NEW_SESSION_EVENT);
  const client = sessionStore.getState(APOLLO_CLIENT);

  if (selectedAlliance.id === alliance.id) {
    OnAllianceError.dispatch(new IntegrityError(`You can't delete the selected Alliance`));

    return Flux.dispatchEvent(
      ALLIANCE_ERROR_EVENT,
      new IntegrityError(`You can't delete the selected Alliance`),
    );
  }

  let response;
  try {
    response = await client.mutate({
      mutation: ALLIANCE_DELETE_MUTATION,
      variables: { data: { id: alliance.id, force: true } },
    });
  } catch (e) {
    error('deleteAlliance', e);
    OnAllianceError.dispatch(e);
    return Flux.dispatchEvent(ALLIANCE_ERROR_EVENT, e);
  }
  log('deleteAlliance', response);
  OnAllianceDelete.dispatch(response.data);
  Flux.dispatchEvent(ALLIANCE_DELETE_EVENT, response.data);
  return response.data;
};

/**
 * Update an Alliance.
 *
 * @param {object} allianceData - The Updated Alliance.
 * @param {object} businessCaseData - The Updated BusinessCase.
 * @param {object} originalRecommendedSolutions - The Original Recommended Solutions.
 * @param {object} originalAllianceKPIs - Original Alliance KPIs.
 * @param {object} originalData - Original Documents.
 * @param {object} originalBusinessCaseData - Original business Case Documents.
 * @returns {Promise<void|*>} Promise.
 */
export const _updateAlliance = async (
  allianceData,
  businessCaseData,
  originalRecommendedSolutions,
  originalAllianceKPIs,
  originalData,
  originalBusinessCaseData,
) => {
  log('updateAlliance:', allianceData, originalData);
  const client = sessionStore.getState(APOLLO_CLIENT);

  try {
    createAllianceValidator(allianceData);
    businessCaseValidator(businessCaseData);
  } catch (e) {
    error('UpdateAlliance Validator', e);
    throw e;
  }

  if (!businessCaseData.expectedCosts || !businessCaseData.expectedRevenues) {
    const { expectedRevenues, anticipatedCosts } = getBusinessCaseAnticipatedCosts(
      allianceData,
      businessCaseData,
    );

    businessCaseData.expectedRevenues = expectedRevenues;
    businessCaseData.anticipatedCosts = anticipatedCosts;
  }

  log('UpdateAlliance AllianceData', allianceData);
  log('UpdateAlliance originalData', originalData);
  log('UpdateAlliance businessCaseData', businessCaseData);
  log('UpdateAlliance originalRecommendedSolutions', originalRecommendedSolutions);

  // Invitation Email
  if (isValidString(allianceData.invitationEmail)) {
    const invitationItems = originalData.allianceInvitationRelation.items || [];
    const alreadyInvited = invitationItems.some(
      (invitation) => invitation.email === allianceData.invitationEmail,
    );
    if (!alreadyInvited) {
      allianceData.allianceInvitationRelation = { create: { email: allianceData.invitationEmail } };
    }
  }
  delete allianceData.invitationEmail;

  try {
    await updatePreAllianceFields(allianceData, originalData);
  } catch (e) {
    console.log('updateAllianceError', e, allianceData);
    throw e;
  }

  delete allianceData.partnerCompanyName;
  delete allianceData.partnerInvitationEmail;

  sanitize8BaseReferenceFromObject(allianceData, 'currency');
  normalize8baseDocumentDeleteAndUpdate(allianceData, 'policyGuidelinesFile', originalData);
  normalize8baseDocumentDeleteAndUpdate(allianceData, 'organizationalChartFile', originalData);
  normalize8baseDocumentsDeleteAndUpdate(allianceData, 'documents', originalData);

  // AllianceKPIs to be created
  sanitizeAllianceKPIs(allianceData);
  const allianceKPIs = R.clone(allianceData.allianceKPIs);
  const kpisToBeCreated = allianceKPIsToBeCreated(allianceKPIs);
  allianceData.allianceKPIAllianceRelation = { create: kpisToBeCreated };
  delete allianceData.allianceKPIs;
  delete allianceData.clientCompany;

  // update and delete allianceKPIs
  let deletedKPIs;
  let updatedKPIs;
  try {
    deletedKPIs = await deleteAllianceKPIs(allianceKPIs, originalAllianceKPIs);
    updatedKPIs = await updateAllianceKPIs(allianceKPIs, originalAllianceKPIs);
  } catch (e) {
    error('updateDeleteAllianceKPIs', e);
    throw e;
  }

  const willResetApprovals =
    deletedKPIs.length ||
    updatedKPIs.length ||
    (kpisToBeCreated.length && allianceData.status !== ALLIANCE_COMPLETED);

  if (willResetApprovals) {
    if (
      [ALLIANCE_APPROVED, ALLIANCE_SUBMITTED_FOR_APPROVAL, ALLIANCE_REJECTED].includes(
        allianceData.status,
      )
    ) {
      allianceData.status = ALLIANCE_IN_PROGRESS;
    }
  }

  if (allianceData.currency.connect.id === originalData.currency.id) {
    delete allianceData.currency;
  }

  let response;
  try {
    response = await client.mutate({
      mutation: ALLIANCE_UPDATE_MUTATION,
      variables: { data: allianceData },
    });
  } catch (e) {
    console.log('updateAllianceError', e, allianceData);
    throw e;
  }

  log('updateAlliance', allianceData, response);

  const promises = [
    updateBusinessCase(businessCaseData, originalRecommendedSolutions, originalBusinessCaseData),
  ];

  if (willResetApprovals) {
    promises.push(deleteAllianceApprovals(allianceData));
  }

  try {
    await Promise.all(promises);
  } catch (e) {
    log('updateAllianceError', e);
    throw e;
  }

  return response.data;
};

/**
 * Updates fields that are related to the PreAlliance of the alliance
   is cancelled.
 - Creates the company object if the PreAlliance did not exist.
 - Changes the alliance status to IN_PROGRESS if the pre-alliance is reverted.
 *
 * @param {object} newAlliance - Alliance with new onesided data.
 * @param {object} prevAlliance - Previous alliance data.
 */
const updatePreAllianceFields = async (newAlliance, prevAlliance) => {
  if (newAlliance.oneSided !== prevAlliance.oneSided) {
    if (newAlliance.oneSided) {
      const partnerCompany = await createPreAllianceCompany(newAlliance);
      newAlliance.partnerCompany = { connect: { id: partnerCompany.id } };
    } else {
      newAlliance.status = ALLIANCE_IN_PROGRESS;
      delete newAlliance.partnerCompany;
    }
  } else if (newAlliance.oneSided) {
    const newName = newAlliance.partnerCompanyName;
    // In some cases, the partner company may not be initialized
    if (!prevAlliance.partnerCompany) {
      const partnerCompany = await createPreAllianceCompany(newAlliance);
      newAlliance.partnerCompany = { connect: { id: partnerCompany.id } };
    } else if (newName !== prevAlliance.partnerCompany.name) {
      const companyId = newAlliance.partnerCompany.id || prevAlliance.partnerCompany.id;
      if (!companyId) {
        const partnerCompany = await createPreAllianceCompany(newAlliance);
        newAlliance.partnerCompany = { connect: { id: partnerCompany.id } };
      } else {
        await updatePreAllianceCompany(newName, companyId);
      }
    }
    // Finally, we ensure that the alliance has a valid format for the request
    if (!newAlliance.partnerCompany.connect) {
      const partnerCompanyId =
        newAlliance.partnerCompany.id || prevAlliance.partnerCompany.id || null;
      newAlliance.partnerCompany = { connect: { id: partnerCompanyId } };
    }
  } else {
    newAlliance.partnerCompany = null;
  }
};

/**
 * Update an Alliance.
 *
 * @param {object} allianceData - The Updated Alliance.
 * @param {object} businessCaseData - The Updated BusinessCase.
 * @param {object} originalRecommendedSolutions - The Original Recommended Solutions.
 * @param {object} originalAllianceKPIs - Original Alliance KPIs.
 * @param {object} originalData - Original Documents.
 * @param {object} originalBusinessCaseData - Original business Case Documents.
 * @returns {Promise<void|*>} Promise.
 */
export const updateAlliance = async (
  allianceData,
  businessCaseData,
  originalRecommendedSolutions,
  originalAllianceKPIs,
  originalData,
  originalBusinessCaseData,
) => {
  try {
    if (allianceData.partnerInvitationEmail && originalData.partnerCompany) {
      await inviteAlliancePartnerCompanyPortfolioOwner(
        originalData,
        allianceData.partnerInvitationEmail,
      );
    }

    const response = await _updateAlliance(
      allianceData,
      businessCaseData,
      originalRecommendedSolutions,
      originalAllianceKPIs,
      originalData,
      originalBusinessCaseData,
    );

    OnAllianceUpdate.dispatch(response);
    Flux.dispatchEvent(ALLIANCE_UPDATE_EVENT, response);
  } catch (error) {
    console.log('error', error);
    OnAllianceError.dispatch(error);
    return Flux.dispatchEvent(ALLIANCE_ERROR_EVENT, error);
  }
};

/**
 * Autosave an alliance
 - Creates the alliance if a savedAlliance is not passed
 - Updates the alliance if a savedAlliance is passed
 - Dispatches the corresponding event for the realized action.
 *
 * @param {object} allianceData - The new data to save. 
 * @param {object} businessCaseData - The new businessCase to save.
 * @param {object} savedAlliance - The alliance to be updated.
 * @param {boolean} submitted - Wether the method was called automatically or by a submit button.
 */
export const autoSaveAlliance = async (
  allianceData,
  businessCaseData,
  savedAlliance,
  submitted,
) => {
  const savedEvent = submitted ? ALLIANCE_CREATE_EVENT : ALLIANCE_AUTOSAVE_EVENT;
  const savedErrorEvent = submitted ? ALLIANCE_ERROR_EVENT : ALLIANCE_AUTOSAVE_ERROR_EVENT;

  if (!allianceData.currency) allianceData.currency = getCurrencyOnSession();
  if (savedAlliance) {
    // Format objects to update. Populate and delete necessary fields
    const { businessCase: prevBusinessCase, id: allianceId } = savedAlliance;
    for (const key in prevBusinessCase) {
      if (!businessCaseData[key]) {
        businessCaseData[key] = R.clone(prevBusinessCase[key]);
      }
    }
    businessCaseData.id = prevBusinessCase.id;
    for (const key in savedAlliance) {
      if (!allianceData[key]) {
        allianceData[key] = R.clone(savedAlliance[key]);
      }
    }

    savedAlliance.allianceKPIs = savedAlliance.allianceKPIAllianceRelation;
    if (savedAlliance.documents && savedAlliance.documents.items)
      savedAlliance.documents = savedAlliance.documents.items;

    const prevAllianceKPIs = sanitizeAllianceKPIsToEdit(savedAlliance);
    const prevRecommendedSolutions = sanitizeRecommendedSolutionsToEdit(prevBusinessCase);
    allianceData.id = allianceId;
    allianceData.businessCase = businessCaseData;
    formatAllianceToUpdate(allianceData);
    formatBusinessCaseToUpdate(businessCaseData);
    let alliance;
    try {
      alliance = await _updateAlliance(
        allianceData,
        businessCaseData,
        prevRecommendedSolutions,
        prevAllianceKPIs,
        savedAlliance,
        prevBusinessCase,
      );

      if (submitted) {
        OnAllianceCreate.dispatch(alliance);
      } else {
        OnAllianceAutoSave.dispatch(alliance);
      }

      Flux.dispatchEvent(savedEvent, alliance);
    } catch (e) {
      OnAllianceAutoSaveError.dispatch(e);
      Flux.dispatchEvent(savedErrorEvent, e);
    }
  } else {
    const companyId = allianceData.clientCompany.id || null;
    const myCompanies = getCompanyUserAdminAndPortfolio();
    const companyRelation = companyId
      ? myCompanies.find((clientCompany) => clientCompany.company.id === companyId)
      : myCompanies[0];
    let alliance;
    try {
      alliance = await createAlliance(allianceData, businessCaseData, companyRelation);

      if (submitted) {
        OnAllianceCreate.dispatch(alliance);
      } else {
        OnAllianceAutoSave.dispatch(alliance);
      }

      Flux.dispatchEvent(savedEvent, alliance);
    } catch (e) {
      OnAllianceAutoSaveError.dispatch(e);
      Flux.dispatchEvent(savedErrorEvent, e);
    }
  }
};

/**
 * Update an Alliance.
 *
 * @param {object} allianceData - The Updated Alliance.
 * @param {object} businessCaseData - The Updated BusinessCase.
 * @param {object} originalRecommendedSolutions - The Original Recommended Solutions.
 * @param {object} originalAllianceKPIs - Original Alliance KPIs.
 * @param {object} originalData - Original Documents.
 * @param {object} originalBusinessCaseData - Original business Case Documents.
 * @returns {Promise<void|*>} Promise.
 */
export const autoUpdateAlliance = async (
  allianceData,
  businessCaseData,
  originalRecommendedSolutions,
  originalAllianceKPIs,
  originalData,
  originalBusinessCaseData,
) => {
  try {
    const response = await _updateAlliance(
      allianceData,
      businessCaseData,
      originalRecommendedSolutions,
      originalAllianceKPIs,
      originalData,
      originalBusinessCaseData,
    );
    OnAllianceAutoUpdate.dispatch(response);
    Flux.dispatchEvent(ALLIANCE_AUTO_UPDATE_EVENT, response);
  } catch (error) {
    OnAllianceAutoUpdateError.dispatch(error);
    return Flux.dispatchEvent(ALLIANCE_AUTO_UPDATE_ERROR_EVENT, error);
  }
};

/**
 * Reset alliance approvals to PENDING status.
 * To use on updateAlliance when the alliance need to be reApproved.
 * // Must set the alliance to SUBMITTED_FOR_APPROVAL after using this.
 *
 * @param  {object} alliance - Alliance.
 * @returns {Promise} Promise.
 */
export const deleteAllianceApprovals = async (alliance) => {
  log('deleteAllianceApprovals', alliance);
  const client = sessionStore.getState(APOLLO_CLIENT);

  const { allianceApprovalsList } = await fetchAllianceApprovals(alliance);
  const allianceApprovals = allianceApprovalsList.items;
  const lastAllianceApprovals = allianceApprovals.slice(0, 2);

  const deleteApprovalPromise = async (approval) => {
    let response;
    try {
      response = await client.mutate({
        mutation: ALLIANCE_APPROVAL_DELETE_MUTATION,
        variables: {
          approval: {
            id: approval.id,
          },
        },
      });
    } catch (e) {
      throw e;
    }
    return response;
  };

  let responses;
  try {
    responses = await Promise.all(lastAllianceApprovals.map(deleteApprovalPromise));
  } catch (e) {
    error('deleteAllianceApprovals', e);
    throw e;
  }

  return responses;
};

/**
 * Fetch a single Alliance.
 *
 * @param {string}id - Id.
 * @returns {Promise<void>} Promise.
 */
export const fetchAlliance = async (id) => {
  const client = sessionStore.getState(APOLLO_CLIENT);
  let response;
  try {
    response = await client.query({
      query: ALLIANCE_DETAIL_QUERY,
      variables: { id },
      fetchPolicy: 'network-only',
    });
  } catch (e) {
    error('fetchAlliance', e);
    OnAllianceError.dispatch(e);
    Flux.dispatchEvent(ALLIANCE_ERROR_EVENT, e);
    throw e;
  }
  log('fetchAlliance', response);

  if (!response.data.alliance) {
    OnAllianceError.dispatch(new IntegrityError(`Alliance can not be found`));

    return Flux.dispatchEvent(
      ALLIANCE_ERROR_EVENT,
      new IntegrityError(`Alliance can not be found`),
    );
  }

  OnAllianceDetail.dispatch(response.data);
  Flux.dispatchEvent(ALLIANCE_DETAIL_EVENT, response.data);
  return response.data;
};

/**
 * Fetches the Alliances for the User
 - Queries the Alliances in which I'm Owner.
 *
 * @param {object}alliance - Alliance.
 * @returns {Promise<void>}Promise.
 */
export const fetchAllianceApprovals = async (alliance) => {
  const client = sessionStore.getState(APOLLO_CLIENT);
  const data = { alliance: { id: { equals: alliance.id } } };
  const sort = { createdAt: 'DESC' };
  let response;
  try {
    response = await client.query({
      query: ALLIANCE_APPROVALS_LIST_QUERY,
      variables: { data, sort },
      fetchPolicy: 'network-only',
    });
  } catch (e) {
    error('fetchAllianceApprovals', e);
    return Flux.dispatchEvent(ALLIANCE_ERROR_EVENT, e);
  }
  log('fetchAllianceApprovals', response);
  // Flux.dispatchEvent(ALLIANCE_LIST_EVENT, response.data);
  return response.data;
};

/**
 * Fetches the Alliances for the User
 - Queries the Alliances in which I'm Owner.
 *
 * @param {string}search - Search.
 * @param {number}page - Page.
 * @param {number}first - First.
 * @param {string}status - Status.
 * @returns {Promise<void>} Promise.
 */
export const fetchAlliances = async (search = '', page = 1, first = 20, status) => {
  const client = sessionStore.getState(APOLLO_CLIENT);
  const user = sessionStore.getState(NEW_SESSION_EVENT).user;
  const skip = (page - 1) * first;
  const filter = allianceFilter(user.id, search, status);

  let response;
  try {
    response = await client.query({
      query: ALLIANCE_LIST_QUERY,
      variables: { data: filter, skip, first },
      fetchPolicy: 'network-only',
    });
  } catch (e) {
    error('fetchAlliances', e);
    OnAllianceError.dispatch(e);
    return Flux.dispatchEvent(ALLIANCE_ERROR_EVENT, e);
  }
  log('fetchAlliances', response);
  OnAllianceList.dispatch(response.data);
  Flux.dispatchEvent(ALLIANCE_LIST_EVENT, response.data);
  return response.data;
};

/**
 * Fetches the Approved Alliances for the User
 * - Queries the Alliances in which I'm Owner.
 *
 * @returns {Promise<void>}Promise.
 */
export const fetchApprovedAlliances = async () => {
  const client = sessionStore.getState(APOLLO_CLIENT);
  const { user } = sessionStore.getState(NEW_SESSION_EVENT);
  const data = {
    status: { equals: ALLIANCE_APPROVED },
    allianceUserAllianceRelation: {
      some: { companyUser: { user: { id: { equals: user.id } } } },
    },
  };

  let response;
  try {
    response = await client.query({
      query: ALLIANCE_SMALL_LIST_QUERY,
      variables: { data },
      fetchPolicy: 'network-only',
    });
  } catch (e) {
    error('fetchApprovedAlliances', e);
    return Flux.dispatchEvent(ALLIANCE_ERROR_EVENT, e);
  }
  log('fetchApprovedAlliances', response);
  Flux.dispatchEvent(ALLIANCE_APPROVED_EVENT, response.data);
  return response.data;
};

/**
 * Creates a New Alliance
 - Sets the Owner to the current logged User
 - Sets the company Client as the Company who the logged User is Owner if Any
 - Creates an Invitation for the Alliance if invitationEmail is set.
 *
 * @param {object}alliance - Alliance.
 * @param {object}businessCase - Business Case.
 * @param {object}companyRelation - Company Relation.
 * @returns {Promise<void>}Promise.
 */
export const createAlliance = async (alliance, businessCase, companyRelation) => {
  log('createAlliance:', alliance, businessCase, companyRelation);
  const client = sessionStore.getState(APOLLO_CLIENT);
  const { user } = sessionStore.getState(NEW_SESSION_EVENT);

  delete alliance.id;
  delete businessCase.id;

  if (!businessCase.expectedCosts || !businessCase.expectedRevenues) {
    const { expectedRevenues, anticipatedCosts } = getBusinessCaseAnticipatedCosts(
      alliance,
      businessCase,
    );

    businessCase.expectedRevenues = expectedRevenues;
    businessCase.anticipatedCosts = anticipatedCosts;
  }

  try {
    createAllianceValidator(alliance);
    businessCaseValidator(businessCase);
  } catch (e) {
    throw e;
  }

  const allianceAdministratorRole = ALLIANCE_ADMINISTRATOR;

  // Invitation Email
  const invitationEmail = alliance.invitationEmail;
  sanitize8BaseReferenceFromObject(alliance, 'clientCompany');
  log('createAlliance:sanitized:', alliance);
  // const companyId = alliance.clientCompany.connect.id;

  // AllianceKPIs
  sanitizeAllianceKPIsCreate(alliance);
  // I'm the Owner of this alliance
  alliance.owner = { connect: { id: user.id } };
  alliance.allianceUserAllianceRelation = {
    create: [
      {
        companyUser: {
          connect: {
            id: companyRelation.id,
          },
        },
        role: {
          connect: {
            name: allianceAdministratorRole,
          },
        },
      },
    ],
  };

  sanitize8BaseReferenceFromObject(alliance, 'currency');

  normalize8baseDocumentCreate(alliance, 'policyGuidelinesFile');
  normalize8baseDocumentCreate(alliance, 'organizationalChartFile');
  normalize8baseDocumentsCreate(alliance, 'documents');

  // Business Case data
  businessCase.owner = { connect: { id: user.id } };
  normalize8baseDocumentCreate(businessCase, 'document');
  sanitizeRecommendedSolutions(businessCase);
  alliance.businessCase = { create: businessCase };

  if (alliance.oneSided) {
    try {
      const partnerCompany = await createPreAllianceCompany(alliance);
      alliance.partnerCompany = { connect: { id: partnerCompany.id } };
    } catch (error) {
      error('createPreAllianceCompanyAlliance', error);
      throw error;
    }
  }

  // Delete the partnerCompanyName field to have a valid allianceCreate mutation data.
  delete alliance.partnerCompanyName;
  delete alliance.partnerInvitationEmail;

  // IF THERE AN EMAIL, CREATE INVITATION ENTRY
  if (isValidString(invitationEmail)) {
    alliance.allianceInvitationRelation = {
      create: {
        email: invitationEmail,
        allianceName: alliance.name,
      },
    };
  }

  delete alliance.invitationEmail;

  let response;
  try {
    response = await client.mutate({
      mutation: ALLIANCE_CREATE_MUTATION,
      variables: { data: alliance },
    });
  } catch (e) {
    error('createAlliance', e);
    throw e;
  }
  log('createAlliance', response);

  return response.data;
};

/**
 * Fetches the Alliances Form Data.
 *
 * @returns {Promise<void>} Promise.
 */
export const fetchAllianceFormData = async () => {
  const client = sessionStore.getState(APOLLO_CLIENT);
  // const user = sessionStore.getState(NEW_SESSION_EVENT).user;
  // const data = {user :{id: { equals: user.id}}};
  let response;
  try {
    response = await client.query({
      query: ALLIANCE_FORM_DATA_QUERY,
      fetchPolicy: 'network-only',
    });
  } catch (e) {
    error('fetchAllianceFormData', e);
    OnAllianceError.dispatch(e);
    return Flux.dispatchEvent(ALLIANCE_ERROR_EVENT, e);
  }
  log('fetchAllianceFormData', response);
  OnAllianceFormData.dispatch(response.data);
  Flux.dispatchEvent(ALLIANCE_FORM_DATA_EVENT, response.data);
  return response.data;
};

/**
 * Fetches the Current Alliance Members.
 *
 * @returns {Promise<void>} Promise.
 */
export const fetchCurrentAllianceMembersAction = async () => {
  const { selectedAlliance } = sessionStore.getState(NEW_SESSION_EVENT);

  const allianceId = selectedAlliance.id;
  if (!allianceId) {
    error('fetchCurrentAllianceMembersAction', 'Must have an Alliance');
    OnAllianceError.dispatch(new IntegrityError('Must have an Active Alliance'));
    Flux.dispatchEvent(ALLIANCE_ERROR_EVENT, new IntegrityError('Must have an Active Alliance'));
    return;
  }

  try {
    const response = await fetchAllianceMembersAction(allianceId);
    log('fetchCurrentAllianceMembersAction', response);
    OnAllianceMemberList.dispatch(response);
    Flux.dispatchEvent(ALLIANCE_LIST_MEMBERS_EVENT, response);
    return response;
  } catch (e) {
    OnAllianceError.dispatch(e);
    return Flux.dispatchEvent(ALLIANCE_ERROR_EVENT, e);
  }
};

/**
 * Fetches the Alliance Members of specific alliance from my Company.
 *
 * @param {string}allianceId - Alliance Id.
 * @returns {Promise<void>} Promise.
 */
export const fetchAllianceDetailMembersAction = async (allianceId) => {
  let response;
  try {
    response = await fetchAllianceMembersAction(allianceId);
  } catch (e) {
    OnAllianceError.dispatch(e);
    return Flux.dispatchEvent(ALLIANCE_ERROR_EVENT, e);
  }

  OnAllianceDetailListMembers.dispatch(response);
  Flux.dispatchEvent(ALLIANCE_DETAIL_LIST_MEMBERS_EVENT, response);
  return response;
};

/**
 * Fetches the Alliance Members from my Company.
 *
 * @param {string}allianceId - Alliance Id.
 * @returns {Promise<void>} Promise.
 */
export const fetchAllianceMembersAction = async (allianceId) => {
  log('fetchAllianceMembersAction:', allianceId);
  const client = sessionStore.getState(APOLLO_CLIENT);

  let response;
  try {
    response = await client.query({
      query: ALLIANCE_MEMBERS_QUERY,
      variables: { allianceId },
      fetchPolicy: 'network-only',
    });
  } catch (e) {
    error('fetchAllianceMembersAction', e);
    throw e;
  }

  log('fetchAllianceMembersAction:response:', response);

  const { alliance } = response.data;
  const { partnerCompany, clientCompany, allianceUserAllianceRelation } = alliance;

  if (partnerCompany)
    partnerCompany.allianceUsers = getCompanyUsersFromAlliance(
      allianceUserAllianceRelation.items,
      partnerCompany,
    );

  clientCompany.allianceUsers = getCompanyUsersFromAlliance(
    allianceUserAllianceRelation.items,
    clientCompany,
  );

  return alliance;
};

/**
 * Get Company Users From Alliance Users.
 *
 * @param {object[]} allianceUsers - AllianceUsers.
 * @param {object} company - Company.
 * @returns {object[]} - CompanyUsers.
 */
function getCompanyUsersFromAlliance(allianceUsers, company) {
  log('getCompanyUsersFromAlliance:', allianceUsers, company);
  return allianceUsers
    .filter((allianceUser) => allianceUser.companyUser.company.id === company.id)
    .map((allianceUser) => ({
      ...allianceUser.companyUser,
      allianceRole: allianceUser.role ? allianceUser.role.name : '',
    }));
}

/**
 * Change the role of a member.
 *
 * @param {string}allianceUserId - Member Id.
 * @param {object} newRole - New Role.
 *  @returns {Promise<void>} Promise.
 */
export const updateMemberRoleAction = async (allianceUserId, newRole) => {
  const client = sessionStore.getState(APOLLO_CLIENT);

  const data = {};
  data.id = allianceUserId;

  if (newRole.name) {
    data.role = {
      connect: { name: newRole.name },
    };
  } else if (newRole.id) {
    data.role = {
      connect: { id: newRole.id },
    };
  }

  let response;

  try {
    response = await client.mutate({
      mutation: ALLIANCE_USER_UPDATE_MUTATION,
      variables: { data },
    });
  } catch (e) {
    error('updateMemberRoleAction', e);
    OnAllianceError.dispatch(e);
    return Flux.dispatchEvent(ALLIANCE_ERROR_EVENT, e);
  }

  OnAllianceRoleChange.dispatch(response.data);
  Flux.dispatchEvent(ALLIANCE_ROLE_CHANGE_EVENT, response.data);
  return response.data;
};

/**
 * Fetches the Alliance Members with their alliance roles.
 *
 * @param {string}allianceId - Alliance Id.
 * @returns {Promise<void>} Promise.
 */
export const fetchAllianceMembersWithRoleAction = async (allianceId) => {
  const client = sessionStore.getState(APOLLO_CLIENT);

  const data = {
    alliance: {
      id: {
        equals: allianceId,
      },
    },
  };

  let response;
  try {
    response = await client.query({
      query: ALLIANCE_MEMBERS_WITH_ROLE_QUERY,
      variables: { data },
      fetchPolicy: 'network-only',
    });
  } catch (e) {
    error('fetchAllianceMembersWithRoleAction', e);
    OnAllianceError.dispatch(e);
    return Flux.dispatchEvent(ALLIANCE_ERROR_EVENT, e);
  }

  log('fetchAllianceMembersWithRoleAction', response.data);
  OnAllianceMemberWithRoles.dispatch(response.data);
  Flux.dispatchEvent(ALLIANCE_LIST_MEMBERS_WITH_ROLES_EVENT, response.data);
  return response.data.alliance;
};

/**
 * Creates and Alliance Member Relation.
 *
 * @param {string}companyUserId - Company User Id.
 * @param {string}roleId - Role Id.
 * @param {string}allianceId - Alliance Id.
 * @returns {Promise<void>} Promise.
 */
export const createAllianceMember = async (companyUserId, roleId, allianceId) => {
  const client = sessionStore.getState(APOLLO_CLIENT);

  try {
    createAllianceMemberValidator(companyUserId, roleId);
  } catch (err) {
    error('createAllianceMember', err);
    OnAllianceError.dispatch(err);
    return Flux.dispatchEvent(ALLIANCE_ERROR_EVENT, err);
  }

  let alliance;
  try {
    alliance = await fetchAllianceMembersAction(allianceId);
  } catch (err) {
    error('createAllianceMember', err);
    OnAllianceError.dispatch(err);
    return Flux.dispatchEvent(ALLIANCE_ERROR_EVENT, err);
  }

  const companyUser = { id: companyUserId };
  if (!canAddMemberToAlliance(companyUser, alliance)) {
    const err = new Error('This user is already in the alliance');
    error('createAllianceMember', err);
    OnAllianceError.dispatch(err);
    Flux.dispatchEvent(ALLIANCE_ERROR_EVENT, err);
    return;
  }

  const data = {
    alliance: {
      connect: { id: allianceId },
    },
    role: {
      connect: { id: roleId },
    },
    companyUser: {
      connect: { id: companyUserId },
    },
  };

  log('createAllianceMember', data);
  let result;
  try {
    result = await client.mutate({
      mutation: CREATE_ALLIANCE_USER_MUTATION,
      variables: { data },
    });
  } catch (e) {
    error('createAllianceMember', e);
    OnAllianceError.dispatch(e);
    return Flux.dispatchEvent(ALLIANCE_ERROR_EVENT, e);
  }

  log('createAllianceMember', result);
  OnAllianceMemberCreate.dispatch(result);
  Flux.dispatchEvent(CREATE_ALLIANCE_MEMBER_EVENT, result);
  return result;
};

/**
 * Delete an Alliance member.
 *
 * @param  {object}member -   The member to be deleted.
 * @returns {Promise<void|*>}Promise.
 */
export const deleteAllianceMember = async (member) => {
  const client = sessionStore.getState(APOLLO_CLIENT);

  let response;
  try {
    response = await client.mutate({
      mutation: DELETE_ALLIANCE_USER_MUTATION,
      variables: { data: { id: member.id, force: true } },
    });
  } catch (e) {
    error('deleteAllianceMember', e);
    OnAllianceError.dispatch(e);
    return Flux.dispatchEvent(ALLIANCE_ERROR_EVENT, e);
  }
  log('deleteAllianceMember', response);

  OnAllianceMemberDelete.dispatch(response.data);
  Flux.dispatchEvent(DELETE_ALLIANCE_USER_EVENT, response.data);
  return response.data;
};

/**
 * Mark an alliance as completed.
 *
 * @param {object}alliance - Alliance.
 * @returns {Promise<void>} Promise.
 */
export const completedAlliance = async (alliance) => {
  const client = sessionStore.getState(APOLLO_CLIENT);

  let response;
  try {
    response = await client.mutate({
      mutation: ALLIANCE_UPDATE_MUTATION,
      variables: {
        data: {
          id: alliance.id,
          status: ALLIANCE_COMPLETED,
        },
      },
    });
  } catch (e) {
    error('fetchApprovedAlliances', e);
    OnAllianceError.dispatch(e);
    return Flux.dispatchEvent(ALLIANCE_ERROR_EVENT, e);
  }
  log('fetchApprovedAlliances', response);
  OnAllianceCompleted.dispatch(response.data);
  Flux.dispatchEvent(ALLIANCE_COMPLETED_EVENT, response.data);
  return response.data;
};

/**
 * Restore Alliance.
 *
 * @param {object}alliance - Alliance.
 * @returns {Promise<void|*>} Return promise.
 */
export const restoreAlliance = async (alliance) => {
  const client = sessionStore.getState(APOLLO_CLIENT);

  let response;
  try {
    response = await client.mutate({
      mutation: ALLIANCE_UPDATE_MUTATION,
      variables: {
        data: {
          id: alliance.id,
          status: ALLIANCE_IN_PROGRESS,
        },
      },
    });
  } catch (e) {
    error('fetchApprovedAlliances', e);
    OnAllianceError.dispatch(e);
    return Flux.dispatchEvent(ALLIANCE_ERROR_EVENT, e);
  }
  log('fetchApprovedAlliances', response);
  OnAllianceRestore.dispatch(response.data);
  Flux.dispatchEvent(ALLIANCE_RESTORE_EVENT, response.data);
  return response.data;
};

/**
 * Notifies a Request for Comments for a Alliance.
 */
export const openComments = ({ id: allianceId }) => {
  OnCommentRequest.dispatch({ type: ALLIANCE_TYPE, id: allianceId });
  Flux.dispatchEvent(COMMENT_REQUEST_EVENT, { type: ALLIANCE_TYPE, id: allianceId });
};

/**
 * Create a comment on a Alliance.
 *
 * @param {string}allianceId - Alliance Id.
 * @param {object}comment - Comment.
 * @returns {Promise<*>} Promise.
 */
export const createAllianceComment = async (allianceId, comment) => {
  const client = sessionStore.getState(APOLLO_CLIENT);
  const data = {
    comment,
    allianceCommentsRelation: { connect: { id: allianceId } },
  };

  let response;
  try {
    response = await client.mutate({
      mutation: COMMENTS_CREATE_MUTATION,
      variables: { data },
    });
  } catch (e) {
    error('createAllianceComment', e);
    OnCommentError.dispatch(e);
    return Flux.dispatchEvent(COMMENT_ERROR_EVENT, e);
  }
  log('createAllianceComment', response);
  OnCommentCreate.dispatch(response.data);
  Flux.dispatchEvent(COMMENT_CREATE_EVENT, response.data);
  return response.data;
};

/**
 * Fetches the Alliance Item Comments.
 *
 * @param {string}allianceId - Alliance Id.
 * @returns {Promise<void>} Promise.
 */
export const fetchAllianceComments = async (allianceId) => {
  const client = sessionStore.getState(APOLLO_CLIENT);
  const data = { id: allianceId };
  const { user } = sessionStore.getState(NEW_SESSION_EVENT);

  let response;
  try {
    response = await client.query({
      query: ALLIANCE_COMMENTS_QUERY,
      variables: data,
      fetchPolicy: 'network-only',
    });
  } catch (e) {
    error('fetchAllianceComments', e);
    OnCommentError.dispatch(e);
    return Flux.dispatchEvent(COMMENT_ERROR_EVENT, e);
  }
  log('fetchAllianceComments', response);
  const comments = R.clone(response.data.alliance.comments);

  comments.items = response.data.alliance.comments.items.map((comment) => ({
    ...comment,
  }));

  comments.userId = user.id;

  OnComment.dispatch(comments);
  Flux.dispatchEvent(COMMENTS_EVENT, comments);
  return comments;
};

/**
 * Validate if user role is Alliance Administrator or Ser.
 *
 * @param {Array}allianceList - Alliance List.
 * @returns {boolean} Boolean.
 */
export const isAdminSerRole = (allianceList) => {
  const { user } = sessionStore.getState(NEW_SESSION_EVENT);

  const userInfo =
    allianceList.length !== 0
      ? allianceList.filter((item) => item.companyUser.user.id === user.id)
      : null;
  console.log('AllianceRole', userInfo[0].role.name);
  if (userInfo)
    return (
      userInfo[0].role.name === ALLIANCE_SER || userInfo[0].role.name === ALLIANCE_ADMINISTRATOR
    );

  return false;
};

/**
 * Create the AllianceMemberInvitation.
 *
 * @param {string}email - Email.
 * @param {string}roleName - Role id.
 * @param {string}allianceId - Alliance Id.
 * @returns {Promise<void>} Promise.
 */
export const createAllianceMemberInvitation = async (email, roleName, allianceId) => {
  const { user } = sessionStore.getState(NEW_SESSION_EVENT);
  const { company } = user.companyUserRelation.items[0];
  const companyId = company.id;

  try {
    const result = await _createAllianceMemberInvitation(email, companyId, roleName, allianceId);
    log('createAllianceMemberInvitation', result);

    OnAllianceInvitationMember.dispatch(result);
    Flux.dispatchEvent(INVITE_ALLIANCE_MEMBER_EVENT, result);
    return result;
  } catch (e) {
    error('createAllianceMemberInvitation', e);
    OnAllianceError.dispatch(e);
    return Flux.dispatchEvent(ALLIANCE_ERROR_EVENT, e);
  }
};

/**
 * Create the AllianceMemberInvitation.
 *
 * @param {string} email - Email.
 * @param {string} companyId - Company id.
 * @param {string} roleName - Role id.
 * @param {string} allianceId - Alliance Id.
 * @param {string} companyRoleName - Role company id.
 * @returns {Promise<void>} Promise.
 */
export const _createAllianceMemberInvitation = async (
  email,
  companyId,
  roleName,
  allianceId,
  companyRoleName,
) => {
  const client = sessionStore.getState(APOLLO_CLIENT);

  try {
    createAllianceMemberInvitationValidator(email, roleName, allianceId);
  } catch (err) {
    error('createAllianceMemberInvitation', err);
    throw err;
  }
  const { allianceMemberInvitationsList } = await fetchAllianceMemberInvitations(email, allianceId);

  try {
    validateAllianceMemberInvitation(allianceMemberInvitationsList);
  } catch (err) {
    error('validateAllianceMemberInvitation', err);
    throw err;
  }
  const data = {
    email,
    alliance: { connect: { id: allianceId } },
    company: { connect: { id: companyId } },
    role: { connect: { name: roleName } },
  };

  if (companyRoleName) data.companyRole = { connect: { name: companyRoleName } };

  log('createAllianceMemberInvitation', data);

  try {
    const result = await client.mutate({
      mutation: CREATE_ALLIANCE_MEMBER_INVITATION_MUTATION,
      variables: { data },
    });
    log('createAllianceMemberInvitation', result);
    return result;
  } catch (e) {
    error('createAllianceMemberInvitation', e);
    throw e;
  }
};

/**
 * Filter allianceMemberInvitations by email and alliance id.
 *
 * @param {string}email - Email.
 * @param {string}allianceId - Alliance Id.
 * @returns {Promise<void|*>} Promise.
 */
export const fetchAllianceMemberInvitations = async (email, allianceId) => {
  const client = sessionStore.getState(APOLLO_CLIENT);
  const filter = {
    email: { equals: email },
    alliance: { id: { equals: allianceId } },
  };

  let result;

  try {
    result = await client.mutate({
      mutation: ALLIANCE_MEMBER_INVITATIONS_QUERY,
      variables: { filter },
    });
  } catch (e) {
    error('validateAllianceMemberInvitation', e);
    return Flux.dispatchEvent(ALLIANCE_ERROR_EVENT, e);
  }

  log('validateAllianceMemberInvitation', result);

  return result.data;
};
/**
 * Reactivate alliance.
 *
 * @param {string}allianceId - Alliance Id.
 * @returns {Promise<void|*>} Promise.
 */
export const reactivateAlliance = async (allianceId) => {
  const client = sessionStore.getState(APOLLO_CLIENT);
  const data = { id: allianceId, status: ALLIANCE_APPROVED };

  let result;

  try {
    result = await client.mutate({
      mutation: ALLIANCE_REACTIVATE_QUERY,
      variables: { data },
    });
  } catch (e) {
    error('reactivateAlliance', e);
    OnAllianceError.dispatch(e);
    return Flux.dispatchEvent(ALLIANCE_ERROR_EVENT, e);
  }

  OnAllianceReactive.dispatch(result.data.allianceUpdate);
  Flux.dispatchEvent(ALLIANCE_REACTIVATE_EVENT, result.data.allianceUpdate);

  log('reactivateAlliance', result);

  return result.data;
};
/**
 * Create alliance deleted.
 *
 * @param {object}alliance - Alliance Id.
 * @returns {Promise<void|*>} Promise.
 */
export const createAllianceDeleted = async (alliance) => {
  const client = sessionStore.getState(APOLLO_CLIENT);
  const data = {
    alliance: {
      connect: {
        id: alliance.id,
      },
    },
  };
  let response;
  try {
    response = await client.mutate({
      mutation: CREATE_ALLIANCE_DELETED_MUTATION,
      variables: { data },
    });
  } catch (e) {
    error('createAllianceDeleted', e);
    OnAllianceError.dispatch(e);
    return Flux.dispatchEvent(ALLIANCE_ERROR_EVENT, e);
  }
  log('createAllianceDeleted', response);
  OnAllianceDeleted.dispatch(response.data);
  Flux.dispatchEvent(ALLIANCE_DELETED_EVENT, response.data);
  return response.data;
};
/**
 * Filter allianceDeletedList by alliance id.
 *
 * @param {string}allianceId - Alliance Id.
 * @returns {Promise<void|*>} Promise.
 */
export const fetchAllianceDeletedList = async (allianceId) => {
  const client = sessionStore.getState(APOLLO_CLIENT);
  const filter = {
    alliance: { id: { equals: allianceId } },
  };
  let response;

  try {
    response = await client.mutate({
      mutation: ALLIANCE_DELETED_LIST_QUERY,
      variables: { filter },
    });
  } catch (e) {
    error('allianceDeletedList', e);
    OnAllianceError.dispatch(e);
    return Flux.dispatchEvent(ALLIANCE_ERROR_EVENT, e);
  }
  log('allianceDeletedList', response);
  OnAllianceDeletedList.dispatch(response.data);
  Flux.dispatchEvent(ALLIANCE_DELETED_LIST_EVENT, response.data);
  return response.data;
};

/**
 * Delete Alliance Deleted.
 *
 * @param {object}allianceDeletedData - Alliance delete Data.
 * @returns {Promise<void|*>} Promise.
 */
export const deleteAllianceDeleted = async (allianceDeletedData) => {
  const client = sessionStore.getState(APOLLO_CLIENT);
  const data = {
    id: allianceDeletedData.id,
  };
  let response;
  try {
    response = await client.mutate({
      mutation: DELETED_ALLIANCE_DELETED_MUTATION,
      variables: { data },
    });
  } catch (e) {
    error('deleteAllianceDeleted', e);
    OnAllianceError.dispatch(e);
    return Flux.dispatchEvent(ALLIANCE_ERROR_EVENT, e);
  }
  log('deleteAllianceDeleted', response);
  OnAllianceCancelDeleted.dispatch(response.data);
  Flux.dispatchEvent(CANCEL_ALLIANCE_DELETED_EVENT, response.data);
  return response.data;
};
/**
 * Filter by alliance Id and get alliance member invitation list.
 *
 * @param {string}allianceId - Alliance Id.
 * @returns {Promise<void|*>} Promise.
 */
export const fetchAllianceMemberInvitationsList = async (allianceId) => {
  const client = sessionStore.getState(APOLLO_CLIENT);
  const filter = {
    alliance: { id: { equals: allianceId } },
  };

  let result;

  try {
    result = await client.mutate({
      mutation: ALLIANCE_MEMBER_INVITATIONS_QUERY,
      variables: { filter },
    });
  } catch (e) {
    error('validateAllianceMemberInvitation', e);
    return Flux.dispatchEvent(ALLIANCE_ERROR_EVENT, e);
  }

  log('validateAllianceMemberInvitation', result);
  Flux.dispatchEvent(ALLIANCE_MEMBER_INVITATIONS_LIST_EVENT, result.data);

  return result.data;
};

/**
 * Fetch Alliance Approvals List by alliance Id and sort.
 *
 * @param {string}allianceId - Alliance Id.
 * @param {boolean}dispatchEvents - If this function should dispatch events.
 * @returns {Promise<void>} Promise.
 */
export const fetchAllianceApprovalsList = async (allianceId, dispatchEvents = true) => {
  const client = sessionStore.getState(APOLLO_CLIENT);
  const filter = { alliance: { id: { equals: allianceId } } };
  const sort = {
    createdAt: 'DESC',
  };
  let response;
  try {
    response = await client.query({
      query: ALLIANCE_APPROVAL_LIST,
      variables: { filter, sort },
      fetchPolicy: 'network-only',
    });
  } catch (e) {
    error('fetchAllianceApprovalsList', e);
    if (dispatchEvents) {
      OnAllianceError.dispatch(e);
      Flux.dispatchEvent(ALLIANCE_ERROR_EVENT, e);
    }

    return;
  }
  log('fetchAllianceApprovalsList', response);

  if (dispatchEvents) {
    OnAllianceApprovalList.dispatch(response.data);
    Flux.dispatchEvent(ALLIANCE_APPROVAL_LIST_EVENT, response.data);
  }

  return response.data;
};

/**
 * Fetches the Alliances for the User
 - Queries the Alliances in which I'm Owner.
 *
 * @returns {Promise<void>} Promise.
 */
export const fetchAlliancesListExcel = async () => {
  const client = sessionStore.getState(APOLLO_CLIENT);
  const user = sessionStore.getState(NEW_SESSION_EVENT).user;
  const filter = allianceFilter(user.id);

  let response;
  try {
    response = await client.query({
      query: ALLIANCE_LIST_EXCEL_QUERY,
      variables: { data: filter },
      fetchPolicy: 'network-only',
    });
  } catch (e) {
    error('fetchAlliances', e);
    OnAllianceError.dispatch(e);
    return Flux.dispatchEvent(ALLIANCE_ERROR_EVENT, e);
  }
  log('fetchAlliances', response);
  console.log(response);
  OnAllianceExcelList.dispatch(response.data);
  Flux.dispatchEvent(ALLIANCE_EXCEL_LIST_EVENT, response.data);
  return response.data;
};

export const fetchSelectedAllianceFilesSize = async () => {
  const client = sessionStore.getState(APOLLO_CLIENT);

  const { selectedAlliance } = sessionStore.getState(NEW_SESSION_EVENT);
  const allianceId = selectedAlliance ? selectedAlliance.id : null;

  if (allianceId) {
    let response;
    try {
      response = await client.query({
        query: ALLIANCE_FILES_SIZE_QUERY,
        variables: { id: allianceId },
        fetchPolicy: 'network-only',
      });
    } catch (e) {
      error('fetchAllianceFilesSize', e);
      return Flux.dispatchEvent(ALLIANCE_ERROR_EVENT, e);
    }

    log('fetchAllianceFilesSize', response.data.allianceFilesSize);
    OnAllianceFileSize.dispatch(response.data.allianceFilesSize);
    Flux.dispatchEvent(ALLIANCE_FILES_SIZE_EVENT, response.data.allianceFilesSize);
    return response.data.allianceFilesSize;
  }
};

/**
 * Creates the partnerCompany for the PreAlliance.
 *
 * @param {object} alliance - The alliance that holds the partnerCompanyName.
 * @returns {object} The Partner Company Object.
 */
export const createPreAllianceCompany = async (alliance) => {
  const client = sessionStore.getState(APOLLO_CLIENT);

  const { partnerCompanyName } = alliance;

  const partnerCompanyData = { name: partnerCompanyName };

  const creationResponse = await createCompanyMutation(client, partnerCompanyData);
  return creationResponse.data.companyCreate;
};

export const updatePreAllianceCompany = async (newName, companyId) => {
  const client = sessionStore.getState(APOLLO_CLIENT);
  const data = { id: companyId, name: newName };
  const updateResponse = await updateCompanyMutation(client, data);
  return updateResponse.data.companyUpdate;
};

/**
 * Fetch All Approved Business Items.
 *
 * @param {object} alliance - Alliance.
 * @param {string} alliance.id - Alliance Id.
 * @returns {Promise<{fundingRequests: object[], initiatives: object[], ideas: object[], actions: object[]}>} - Approved bussines items.
 */
export const fetchApprovedBusinessItems = async (alliance) => {
  const client = sessionStore.getState(APOLLO_CLIENT);
  const allianceId = alliance.id;

  const variables = {
    initiativeFilter: {
      status: { equals: INITIATIVE_APPROVED },
      alliance: { id: { equals: allianceId } },
    },
    actionFilter: {
      status: { equals: ACTION_APPROVED },
      itemActionRelation: { alliance: { id: { equals: allianceId } } },
    },
    fundingRequestFilter: {
      status: { equals: FUNDING_REQUEST_APPROVED },
      itemFundingRequestRelation: { alliance: { id: { equals: allianceId } } },
    },
    ideaFilter: {
      status: { equals: IDEA_APPROVED },
      itemIdeaRelation: { alliance: { id: { equals: allianceId } } },
    },
  };

  let response;
  try {
    response = await client.query({
      query: ACTIVE_BUSINESS_ITEMS_QUERY,
      variables,
    });
  } catch (error) {
    console.log('fetchApprovedBusinessItems:error', error);
    throw error;
  }

  console.log('fetchApprovedBusinessItems', response);

  const {
    initiativesList: { items: initiatives },
    actionsList: { items: actions },
    fundingRequestsList: { items: fundingRequests },
    ideasList: { items: ideas },
  } = response.data;

  return { initiatives, actions, fundingRequests, ideas };
};

const inviteAlliancePartnerCompanyPortfolioOwner = async (alliance, email) => {
  console.log('inviteAlliancePartnerCompanyPortfolioOwner', alliance, email);
  const { partnerCompany } = alliance;
  try {
    await _createAllianceMemberInvitation(
      email,
      partnerCompany.id,
      ALLIANCE_ADMINISTRATOR,
      alliance.id,
      COMPANY_PORTFOLIO_OWNER,
    );
  } catch (error) {
    console.log('invitePartnerCompanyPortfolioOwner:error', error);
  }
};

export const filterUserAlliances = (userId) => {
  return {
    allianceUserAllianceRelation: {
      some: { companyUser: { user: { id: { equals: userId } } } },
    },
  };
};

export const fetchAlliancesBigList = async (_userId = null) => {
  const client = sessionStore.getState(APOLLO_CLIENT);

  let userId = _userId;
  if (!userId) {
    const { user } = sessionStore.getState(NEW_SESSION_EVENT);
    userId = user.id;
  }
  const filter = filterUserAlliances(userId);

  let response;
  try {
    response = await client.query({
      query: ALLIANCES_BIG_LIST_QUERY,
      variables: { filter },
      fetchPolicy: 'network-only',
    });
  } catch (e) {
    error('fetchAlliancesBigList', e);
    throw e;
  }
  console.log('fetchAlliancesBigList:response', response);

  const alliances = response.data.alliancesList.items;
  OnSessionAlliances.dispatch(alliances);
  Flux.dispatchEvent(SESSION_ALLIANCES_EVENT, alliances);
  return alliances;
};
