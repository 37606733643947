import React, { Component } from 'react';
import { Grid, Card, Heading, Text, Dropdown, Progress } from '@8base/boost';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import WithTagCountButton from '../../../components/buttons/WithTagCountButton';
import DropdownBodyInvitations from './DropdownBodyInvitations';
import CenterImg from '../../../components/img/CenterImg';
import { ALLIANCE_INVITATION_PENDING } from '../../../shared/status';
import { getMyAllCompanyUsers } from '../../settings/company-management/company-actions';
import { COMPANY_PORTFOLIO_OWNER } from '../../../shared/roles';
const ROCKET_IMG_URL = `${process.env.PUBLIC_URL}/rocket-icon.png`;

const InnerShadow = styled(Grid.Box)({
  boxShadow: 'inset 8px 0 6px -6px rgba(147,158,167,0.35)',
  paddingBottom: 85,
  minWidth: 700,
});

class RightWizardSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      invitationsDropdownIsOpen: false,
    };
  }

  toggleInvitationsDropdown = () => {
    this.setState({
      invitationsDropdownIsOpen: !this.state.invitationsDropdownIsOpen,
    });
  };

  render() {
    const { component: CurrentScreen, header, layout } = this.props.screen;
    const {
      prevScreen,
      nextScreen,
      currentScreen,
      allianceInvitationsList,
      progressBarValue,
    } = this.props;
    const { invitationsDropdownIsOpen } = this.state;

    // Shows in the screen only the Pending Invitations and not all
    const companyUsers = getMyAllCompanyUsers();
    const companyUser = companyUsers.length > 0 ? companyUsers[0] : null;
    const validRole =
      companyUser && companyUser.role && companyUser.role.name === COMPANY_PORTFOLIO_OWNER;
    let count = 0;
    allianceInvitationsList.items.forEach((item) => {
      if (item.status === ALLIANCE_INVITATION_PENDING) count = count + 1;
    });

    const allianceInvitationsButton =
      count && (validRole || !companyUser) ? (
        <WithTagCountButton
          text={'Invitations'}
          count={count}
          onClick={this.toggleInvitationsDropdown}
        />
      ) : null;

    return (
      <InnerShadow stretch>
        <Card.Header>
          <Grid.Layout
            columns="200px auto 200px"
            areas={[['left', 'center', 'right']]}
            style={{ width: '100%' }}>
            <Grid.Box justifySelf="flex-start" area="left">
              <Heading type="h4" text={header} />
            </Grid.Box>
            <Grid.Box area="center">
              <Progress size="sm" value={progressBarValue} />
            </Grid.Box>
            <Grid.Box justifySelf="flex-end">
              <Dropdown defaultOpen={false}>
                <Dropdown.Head>{allianceInvitationsButton}</Dropdown.Head>
                <DropdownBodyInvitations isOpen={invitationsDropdownIsOpen}>
                  <CenterImg src={ROCKET_IMG_URL} />
                  <br />
                  <Text align="center" weight="semibold">
                    {'You have alliance invitations, complete your company information to accept'}
                  </Text>
                </DropdownBodyInvitations>
              </Dropdown>
            </Grid.Box>
          </Grid.Layout>
        </Card.Header>
        <CurrentScreen
          nextScreen={nextScreen}
          prevScreen={prevScreen}
          layout={layout}
          currentScreen={currentScreen}
        />
      </InnerShadow>
    );
  }
}

RightWizardSection.defaultProps = {
  progressBarValue: 10,
  layout: '1fr 500px 1fr',
};

RightWizardSection.propTypes = {
  screen: PropTypes.object.isRequired,
  header: PropTypes.string.isRequired,
  currentScreen: PropTypes.number.isRequired,
  progressBarValue: PropTypes.number,
  layout: PropTypes.string,
  nextScreen: PropTypes.func.isRequired,
  prevScreen: PropTypes.func.isRequired,
  allianceInvitationsList: PropTypes.object.isRequired,
};

export { RightWizardSection };
