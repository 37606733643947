import React from 'react';
import { Card, Table, Heading } from '@8base/boost';
import { ListCardBody } from '../../../components/card/ListCardBody';
import * as toast from '../../../components/toast/Toast';
import * as R from 'ramda';
import {
  acceptAllianceMemberInvitation,
  resendAllianceMemberInvitation,
} from './invitations.actions';
import { View } from '@cobuildlab/react-simple-state';
import YesNoDialog from '../../../components/dialogs/YesNoDialog';
import sessionStore, { NEW_SESSION_EVENT } from '../../../shared/SessionStore';
import PropTypes from 'prop-types';
import { fetchInvitations } from '../../auth/auth.actions';
import Moment from 'react-moment';
import Status from '../../../components/Status';
import { onErrorMixin } from '../../../shared/mixins';
import { ActionButton } from '../../../components/buttons/ActionButton';
import { fetchSession } from '../../auth/auth.actions';
import { sortByPendingInvitation } from './invitations-utils';
import { canResendAllianceMemberInvitation } from '@cobuildlab/collabtogrow-permissions';
import {
  OnAllianceResendInvitationMember,
  OnAllianceInvitationError,
  OnAllianceInvitationMemberUpdated,
} from './invitations-events';

import { OnInvitations, OnSessionError } from '../../../shared/session-events';

class AllianceMemberInvitationListView extends View {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      invitations: [],
      invitation: null,
      acceptModalIsOpen: false,
      resendInvitationModalIsOpen: false,
      resendInvitation: null,
    };
    this.onError = onErrorMixin.bind(this);
  }

  componentDidMount() {
    const { history } = this.props;
    this.user = sessionStore.getState(NEW_SESSION_EVENT).user;

    this.subscribe(OnSessionError, this.onError);

    this.subscribe(OnAllianceInvitationError, this.onError);

    this.subscribe(OnInvitations, (data) => {
      this.setState({
        invitations: data.allianceMemberInvitationsList.items.sort(sortByPendingInvitation),
        loading: false,
      });
    });

    this.subscribe(OnAllianceResendInvitationMember, async (state) => {
      await fetchInvitations();
      toast.success('Resend alliance Membership invitation successfully');
    });

    this.subscribe(OnAllianceInvitationMemberUpdated, async (data) => {
      await fetchSession();
      const id = this.state.invitation.alliance.id;
      toast.success('Alliance Membership invitation successfully accepted');
      history.push(`/settings/alliance-management/${id}`);
    });

    fetchInvitations();
  }

  onSelectForAccept = (invitation) => {
    this.setState({
      invitation,
      acceptModalIsOpen: true,
    });
  };

  onResendInvitation = (invitation) => {
    this.setState({
      resendInvitation: invitation,
      resendInvitationModalIsOpen: true,
    });
  };

  onYesAccept = () => {
    this.setState(
      {
        acceptModalIsOpen: false,
        loading: true,
      },
      () => {
        acceptAllianceMemberInvitation(this.state.invitation);
      },
    );
  };

  onYesResendInvitation = () => {
    this.setState(
      {
        resendInvitationModalIsOpen: false,
        loading: true,
      },
      () => {
        resendAllianceMemberInvitation(this.state.resendInvitation);
      },
    );
  };

  onCloseAccept = () => {
    this.setState({
      acceptModalIsOpen: false,
    });
  };

  onCloseResendInvitation = () => {
    this.setState({
      resendInvitationModalIsOpen: false,
    });
  };

  render() {
    const { acceptModalIsOpen, invitations, loading, resendInvitationModalIsOpen } = this.state;
    const tableHeaders = [
      'Company Name',
      'Alliance Name',
      'Sent to',
      'Status',
      'Date of Response',
      'Options',
    ];
    const columnSize = `repeat(${tableHeaders.length}, 1fr)`;
    console.log('this.user', this.user);
    return (
      <React.Fragment>
        <Card.Header>
          <Heading type="h4" text="Alliance Membership" />
        </Card.Header>
        <ListCardBody>
          <div id={'c2gScreen'}>
            <Table>
              <Table.Header columns={columnSize}>
                {tableHeaders.map((header, index) => (
                  <Table.HeaderCell key={index}>{header}</Table.HeaderCell>
                ))}
                <Table.HeaderCell />
              </Table.Header>
              <Table.Body loading={loading} data={invitations} className="card-body-list">
                {(invitation) => {
                  const canResendInvitation = canResendAllianceMemberInvitation(
                    invitation,
                    this.user,
                  );
                  return (
                    <Table.BodyRow columns={columnSize} key={invitation.id}>
                      <Table.BodyCell>
                        {R.pathOr('Unititled', ['company', 'name'], invitation)}
                      </Table.BodyCell>
                      <Table.BodyCell>
                        {R.pathOr('Unititled', ['alliance', 'name'], invitation)}
                      </Table.BodyCell>
                      <Table.BodyCell>{R.pathOr('', ['email'], invitation)}</Table.BodyCell>
                      <Table.BodyCell>
                        <Status status={R.pathOr('', ['status'], invitation)} />
                      </Table.BodyCell>
                      <Table.BodyCell>
                        {invitation.dateOfResponse === null ? (
                          'No date'
                        ) : (
                          <Moment format="MMMM Do, YYYY">
                            {new Date(invitation.dateOfResponse)}
                          </Moment>
                        )}
                      </Table.BodyCell>
                      <Table.BodyCell>
                        {canResendInvitation ? (
                          <ActionButton
                            text={'Resend Invitation'}
                            onClick={() => this.onResendInvitation(invitation)}
                          />
                        ) : null}
                      </Table.BodyCell>
                    </Table.BodyRow>
                  );
                }}
              </Table.Body>
            </Table>
          </div>
        </ListCardBody>
        <YesNoDialog
          isOpen={acceptModalIsOpen}
          onYes={this.onYesAccept}
          onNo={this.onCloseAccept}
          onClose={this.onCloseAccept}
          text={'Are you sure you want to Accept this Alliance Membership Invitation?'}
          title={'Accept Invitation'}
        />

        <YesNoDialog
          isOpen={resendInvitationModalIsOpen}
          onYes={this.onYesResendInvitation}
          onNo={this.onCloseResendInvitation}
          onClose={this.onCloseResendInvitation}
          text={'Are you sure you want to resend Alliance Membership Invitation?'}
          title={'Resend Invitation'}
        />
      </React.Fragment>
    );
  }
}

AllianceMemberInvitationListView.propTypes = {
  history: PropTypes.object.isRequired,
};

export default AllianceMemberInvitationListView;
