import React from 'react';
import PropTypes from 'prop-types';
import { Icon, NoData } from '@8base/boost';
import {
  ResponsiveContainer,
  CartesianGrid,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Legend,
  Tooltip,
} from 'recharts';
import * as currencyFormatter from 'currency.js';

import { ViewCardBody } from '../../../components/card/ViewCardBody';

import {
  CustomTooltip,
  CustomLegend,
  ChartSwitcher,
  ChartSwitcherWrapper,
  InlineHeading,
} from '../components';

export const ActiveSalesPipelineChart = ({
  data,
  bars,
  selections,
  selected,
  currency,
  isFilteredByOrganization,
  onIncrementSelected,
  onDecrementSelected,
}) =>
  data.length ? (
    <ViewCardBody>
      {!isFilteredByOrganization && (
        <ChartSwitcherWrapper>
          <ChartSwitcher onClick={onDecrementSelected}>
            <Icon name="ChevronLeft" size="xs" />
          </ChartSwitcher>
          <ChartSwitcher onClick={onIncrementSelected}>
            <Icon name="ChevronRight" size="xs" />
          </ChartSwitcher>
          <InlineHeading>Active Sales Pipeline - {selections[selected]}</InlineHeading>
        </ChartSwitcherWrapper>
      )}
      <ResponsiveContainer width="100%" height={500}>
        <BarChart data={data} margin={{ top: 10, left: 60, right: 30, bottom: 10 }} barGap={14}>
          <CartesianGrid stroke="#ccc" strokeDasharray="2 3" />
          <XAxis dataKey="groupName" tickSize={15} tickMargin={10} stroke="#323C47" />
          <YAxis
            tickSize={12}
            tickMargin={5}
            stroke="#323C47"
            tickFormatter={(value) =>
              currencyFormatter(value, {
                symbol: currency.symbol,
                decimal: currency.decimalSeparator,
                separator: currency.thousandSeparator,
              }).format(true)
            }
          />
          <Legend
            align="right"
            layout="vertical"
            verticalAlign="top"
            width={200}
            wrapperStyle={{ right: 0, padding: 20, border: '1px solid #EAF0F7', borderRadius: 5 }}
            content={<CustomLegend />}
          />
          <Tooltip cursor={{ fill: '#E9F2F4' }} content={<CustomTooltip currency={currency} />} />
          {bars.map((bar) => (
            <Bar key={bar.name} dataKey={bar.name} fill={bar.color} radius={[5, 5, 0, 0]} />
          ))}
        </BarChart>
      </ResponsiveContainer>
    </ViewCardBody>
  ) : (
    <NoData stretch />
  );

ActiveSalesPipelineChart.propTypes = {
  data: PropTypes.shape({
    name: PropTypes.string,
    amount: PropTypes.number,
    color: PropTypes.string,
  }).isRequired,
  bars: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  selections: PropTypes.arrayOf(PropTypes.string).isRequired,
  selected: PropTypes.number.isRequired,
  currency: PropTypes.shape({}).isRequired,
  isFilteredByOrganization: PropTypes.bool.isRequired,
  onIncrementSelected: PropTypes.func.isRequired,
  onDecrementSelected: PropTypes.func.isRequired,
};
