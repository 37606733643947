import React from 'react';
import { Card, Table, Dropdown, Menu, Heading, Icon, Grid, Loader } from '@8base/boost';
import { DropdownBodyOnTable } from '../../../components/dropdown/DropdownBodyOnTable';
import { ListCardBody } from '../../../components/card/ListCardBody';
import { Link } from 'react-router-dom';
import { View } from '@cobuildlab/react-simple-state';
import PropTypes from 'prop-types';
import sessionStore, { NEW_SESSION_EVENT } from '../../../shared/SessionStore';
import '../css/alliance.css';
import SearchInput from '../../../components/inputs/SearchInput';
import { onErrorMixin, onListScrollMixin } from '../../../shared/mixins';
import { fetchCompanyListAction } from './company-actions';
import { canEditCompanyPermission } from '@cobuildlab/collabtogrow-permissions';
import { Body } from '../../../components/new-ui/font-style/Body';
import * as R from 'ramda';
import { OnCompanyList, OnCompanyError } from './company-events';

/**
 * List All the Companies.
 */
class CompanyListView extends View {
  constructor(props) {
    super(props);
    this.state = {
      companies: [],
      loading: true,
      loadingPage: false,
      page: 1,
      count: 0,
    };
    this.user = sessionStore.getState(NEW_SESSION_EVENT).user;
    this.onError = onErrorMixin.bind(this);
    this.onListScroll = onListScrollMixin.bind(this);
  }

  componentDidMount() {
    this.subscribe(OnCompanyError, this.onError);

    this.subscribe(OnCompanyList, (state) => {
      const { count } = R.clone(state.companiesList);
      const items = state.companiesList.items.map((company) => ({
        ...company,
      }));

      const { loadingPage, companies: oldcompaniesList } = this.state;
      const companies = loadingPage ? oldcompaniesList.concat(items) : items;
      const user = R.clone(this.user);

      companies.forEach((company) => {
        company.userRole = company.companyUserRelation.items.filter((company) => {
          return company.user.id === user.id;
        });
        company.userRole = company.userRole[0].role.name;
      });

      this.setState({
        companies,
        count,
        loading: false,
        loadingPage: false,
      });
    });

    fetchCompanyListAction();
  }

  render() {
    const { search, loading, loadingPage, companies } = this.state;

    return (
      <React.Fragment>
        <Card.Header>
          <Grid.Layout
            columns="200px auto 200px"
            areas={[['left', 'center', 'right']]}
            style={{ width: '100%' }}>
            <Grid.Box justifySelf="flex-start" area="left">
              <Heading type="h4" text="Companies" />
            </Grid.Box>
            <Grid.Box justifySelf="center" area="center">
              <SearchInput value={search} onChange={this.onSearchChange} />
            </Grid.Box>
            <Grid.Box justifySelf="flex-end" />
          </Grid.Layout>
        </Card.Header>
        <ListCardBody>
          <div id={'c2gScreen'}>
            <Table>
              <Table.Header columns="3fr 3fr 3fr" className="justify-center-column">
                <Table.HeaderCell className="name-column">Name</Table.HeaderCell>
                <Table.HeaderCell>Role</Table.HeaderCell>
                <Table.HeaderCell>Options</Table.HeaderCell>
              </Table.Header>
              <Table.Body
                onScroll={(event) => this.onListScroll(event, companies, fetchCompanyListAction)}
                loading={loading}
                data={companies}
                className="card-body-list">
                {(company, index) => {
                  const isLast = index === companies.length - 1;
                  const pageLoader = isLast && loadingPage ? <Loader stretch /> : null;
                  return (
                    <>
                      <Table.BodyRow
                        columns="3fr 3fr 3fr"
                        className="justify-center-column"
                        key={company.id}>
                        <Table.BodyCell alignSelf="center" className="name-column">
                          <Link
                            className="item-name"
                            to={`/settings/company-management/${company.id}/`}>
                            {company.name}
                          </Link>
                        </Table.BodyCell>
                        <Table.BodyCell>
                          <Body>{company.userRole}</Body>
                        </Table.BodyCell>
                        <Table.BodyCell>
                          <Dropdown defaultOpen={false}>
                            <Dropdown.Head>
                              <Icon name="More" className="more-icon" />
                            </Dropdown.Head>
                            <DropdownBodyOnTable>
                              {({ closeDropdown }) => (
                                <Menu>
                                  <Menu.Item
                                    onClick={() => {
                                      this.props.history.push(
                                        `/settings/company-management/${company.id}`,
                                      );
                                    }}>
                                    Details
                                  </Menu.Item>

                                  {canEditCompanyPermission(this.user, company) ? (
                                    <Menu.Item
                                      onClick={() => {
                                        this.props.history.push(
                                          `/settings/company-management/edit/${company.id}`,
                                        );
                                      }}>
                                      Edit
                                    </Menu.Item>
                                  ) : (
                                    ''
                                  )}
                                </Menu>
                              )}
                            </DropdownBodyOnTable>
                          </Dropdown>
                        </Table.BodyCell>
                      </Table.BodyRow>
                      {pageLoader}
                    </>
                  );
                }}
              </Table.Body>
            </Table>
          </div>
        </ListCardBody>
      </React.Fragment>
    );
  }
}

// // TODO:
CompanyListView.propTypes = {
  history: PropTypes.object.isRequired,
};

export default CompanyListView;
