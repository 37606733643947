export const SCREENS_INITIATIVE = [
  {
    progressName: 'Initiative',
  },
  {
    progressName: 'Business Case',
  },
  {
    progressName: 'Related Item',
  },
  {
    progressName: 'Review',
  },
];

export const SCREENS_IDEA = [
  {
    progressName: 'Idea',
  },
  {
    progressName: 'Business Case',
  },
  {
    progressName: 'Related Item',
  },
  {
    progressName: 'Review',
  },
];

export const SCREENS_FUNDING = [
  {
    progressName: 'Funding Request',
  },
  {
    progressName: 'Business Case',
  },
  {
    progressName: 'Related Item',
  },
  {
    progressName: 'Review',
  },
];

export const SCREENS_CONTRIBUTION = [
  {
    progressName: 'Contribution',
  },
  {
    progressName: 'Related Item',
  },
  {
    progressName: 'Review',
  },
];

export const SCREENS_RISK = [
  {
    progressName: 'Risk',
  },
  {
    progressName: 'Related Item',
  },
  {
    progressName: 'Review',
  },
];

export const SCREENS_ISSUE = [
  {
    progressName: 'Issue',
  },
  {
    progressName: 'Related Item',
  },
  {
    progressName: 'Review',
  },
];

export const SCREENS_ALLIANCE = [
  {
    progressName: 'Alliance',
  },
  {
    progressName: 'Business Case',
  },
  {
    progressName: 'KPI Target',
  },
  {
    progressName: 'Review',
  },
];

export const SCREENS_DECISION = [
  {
    progressName: 'Decision',
  },
  {
    progressName: 'Related Item',
  },
  {
    progressName: 'Review',
  },
];

export const SCREENS_ACTIONS = [
  {
    progressName: 'Actions',
  },
  {
    progressName: 'Business Case',
  },
  {
    progressName: 'Related Item',
  },
  {
    progressName: 'Review',
  },
];

export const SCREENS_ACTION_WITHOUT_BUSINESS_CASE = [
  {
    progressName: 'Actions',
  },
  {
    progressName: 'Related Item',
  },
  {
    progressName: 'Review',
  },
];

export const SCREENS_COMPANY = [
  {
    progressName: 'Company',
  },
  {
    progressName: 'Review',
  },
];

export const SCREENS_DEALS = [
  {
    progressName: 'Deal',
  },
  {
    progressName: 'Related Item',
  },
  {
    progressName: 'Review',
  },
];
