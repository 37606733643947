import React from 'react';
import PropTypes from 'prop-types';
import LightGreyText from './LightGreyText';

// Component checks if there's a value present to render
// or else returns "None"
const DetailValue = ({ text, fontSize }) => {
  if (text || parseInt(text) === 0) {
    return <span style={fontSize ? { fontSize: fontSize } : {}}>{text}</span>;
  }
  return <LightGreyText fontSize={fontSize} text="None" />;
};

DetailValue.propTypes = {
  text: PropTypes.string,
  fontSize: PropTypes.number,
};

DetailValue.defaultProps = {
  text: '',
  fontSize: 12,
};

export default DetailValue;
