import React from 'react';
import { Dropdown } from '@8base/boost';
import PropTypes from 'prop-types';

const DropdownBodyInvitations = ({ children, isOpen, ...rest }) => {
  return (
    <Dropdown.Body
      isOpen={isOpen}
      background="white"
      padding="md"
      offset="md"
      pin="right"
      width={400}
      {...rest}>
      {children}
    </Dropdown.Body>
  );
};

DropdownBodyInvitations.propTypes = {
  children: PropTypes.any.isRequired,
  isOpen: PropTypes.bool.isRequired,
};

export default DropdownBodyInvitations;
