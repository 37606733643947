import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Column, InputField, Row, SelectField } from '@8base/boost';
import { Label } from '../../../components/inputs/Label';
import { options, USA_STATES } from '../../../shared/stripe/stripe';
import { CardElement } from '@stripe/react-stripe-js';
import { isPlanFreeTrial } from '../../settings/company-management/company-actions';

const PaymentForm = ({ onChange, data, countries, selected }) => {
  const { cardHolderName, addressLine1, addressLine2, country, city, state } = data;
  const [isDisabled, setIsDisabled] = useState(false);

  const isUSASelected = country === 'US';

  useEffect(() => {
    if (selected && isPlanFreeTrial(selected.name)) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  }, [selected]);

  useEffect(() => {
    onChange('state', '');
  }, [isUSASelected]);

  return (
    <Row growChildren>
      <Column>
        <InputField
          disabled={isDisabled}
          label="CardHolder Name"
          placeholder="CardHolder Name"
          input={{
            name: 'cardHolderName',
            type: 'text',
            value: cardHolderName,
            onChange: (value) => onChange('cardHolderName', value),
          }}
        />
        {isDisabled ? (
          <InputField
            label="Card Information"
            placeholder="Card Information"
            disabled
            input={{
              name: 'CardInformationDisabled',
              type: 'text',
            }}
          />
        ) : (
          <>
            <Label text={'Card Information'} />
            <CardElement options={options} />
          </>
        )}
        <Label text={'Billing Address'} />
        <InputField
          placeholder="Address Line 1"
          disabled={isDisabled}
          input={{
            name: 'addressLine1',
            type: 'text',
            value: addressLine1,
            onChange: (value) => onChange('addressLine1', value),
          }}
        />
        <InputField
          placeholder="Address Line 2"
          disabled={isDisabled}
          input={{
            name: 'addressLine2',
            type: 'text',
            value: addressLine2,
            onChange: (value) => onChange('addressLine2', value),
          }}
        />
        <Row stretch>
          <Column stretch>
            <SelectField
              disabled={isDisabled}
              input={{
                name: 'country',
                value: country,
                onChange: (value) => onChange('country', value),
              }}
              placeholder={'country'}
              options={countries.map(({ code, name }) => {
                return { label: name, value: code };
              })}
            />
          </Column>
          <Column stretch>
            {isUSASelected ? (
              <SelectField
                disabled={isDisabled}
                placeholder="State"
                options={USA_STATES}
                input={{
                  name: 'state',
                  type: 'text',
                  value: state,
                  onChange: (value) => onChange('state', value),
                }}
              />
            ) : (
              <InputField
                disabled={isDisabled}
                placeholder="State"
                input={{
                  name: 'state',
                  type: 'text',
                  value: state,
                  onChange: (value) => onChange('state', value),
                }}
              />
            )}
          </Column>
          <Column stretch>
            <InputField
              disabled={isDisabled}
              placeholder="City"
              input={{
                name: 'city',
                type: 'text',
                value: city,
                onChange: (value) => onChange('city', value),
              }}
            />
          </Column>
        </Row>
      </Column>
    </Row>
  );
};

PaymentForm.defaultProps = {
  selected: null,
};

PaymentForm.propTypes = {
  onChange: PropTypes.func.isRequired,
  countries: PropTypes.array.isRequired,
  data: PropTypes.object.isRequired,
  selected: PropTypes.object,
};

export { PaymentForm };
