import React from 'react';
import { Dialog, Grid, Text, Button } from '@8base/boost';
import PropTypes from 'prop-types';
import { ActionButton } from '../buttons/ActionButton';
import logo from '../../images/logos/collabtogrow_main_logo.svg';

const NewVersionDialog = ({
  title,
  isOpen,
  text,
  onRefresh,
  refreshText,
  onCancel,
  cancelText,
}) => {
  const headerContent = <img width={180} src={logo} alt={'Logo'} />;

  return (
    <Dialog size="sm" isOpen={isOpen}>
      <Dialog.Body scrollable>
        <Grid.Layout gap="sm" stretch>
          <Grid.Box alignItems={'center'}>{headerContent}</Grid.Box>
          <Grid.Box justifyContent={'center'} alignItems={'center'}>
            <br />
            <Text weight="bold">{title}</Text>
          </Grid.Box>
          <Grid.Box alignItems={'center'}>
            <br />
            <Text>{text}</Text>
            <br />
          </Grid.Box>
        </Grid.Layout>
      </Dialog.Body>
      <Dialog.Footer>
        <Button color="neutral" variant="outlined" onClick={onCancel}>
          {cancelText}
        </Button>
        <ActionButton text={refreshText} onClick={onRefresh} />
      </Dialog.Footer>
    </Dialog>
  );
};

NewVersionDialog.defaultProps = {
  text: 'A new version of the platform is available. Please refresh your browser!.',
  cancelText: 'Cancel',
  refreshText: 'Refresh',
  title: 'New App Version.',
};

NewVersionDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  text: PropTypes.string,
  onCancel: PropTypes.func.isRequired,
  cancelText: PropTypes.string,
  onRefresh: PropTypes.func.isRequired,
  refreshText: PropTypes.string,
  title: PropTypes.string,
};

export { NewVersionDialog };
