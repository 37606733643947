import { ValidationError } from '../../shared/errors';
import { isValidString, isValidEmail } from '../../shared/validators';
import { isPlanFreeTrial } from '../settings/company-management/company-actions';

export const inviteCompanyPortfolioOwnerValidator = (companyId, email) => {
  const errorMessages = [];

  if (!isValidString(companyId)) errorMessages.push('Invalid company');

  if (!isValidEmail(email)) errorMessages.push('Invalid email');

  if (errorMessages.length !== 0) throw new ValidationError(errorMessages);
};

export const paymentValidator = (creditCard, paymentMethod, stripeError) => {
  const errorMessages = [];

  if (!isValidString(creditCard.cardHolderName))
    errorMessages.push('A Credit Card must have a valid Cardholder Name');

  if (stripeError) errorMessages.push(stripeError.message);

  if (!isValidString(creditCard.addressLine1))
    errorMessages.push('A Credit Card must have a valid Address Line 1');

  if (!isValidString(creditCard.addressLine2, true))
    errorMessages.push('A Credit Card must have a valid Address Line 2');

  if (!isValidString(creditCard.city)) errorMessages.push('A Credit Card must have a valid City');

  if (!isValidString(creditCard.state)) errorMessages.push('A Credit Card must have a valid State');

  if (!isValidString(creditCard.country))
    errorMessages.push('A Credit Card must have a valid Country');

  if (!paymentMethod && !errorMessages.length)
    errorMessages.push('You must enter a valid Credit Card');

  if (errorMessages.length !== 0) throw new ValidationError(errorMessages);
};

export const createCompanySubscriptionValidator = (
  companySubscription,
  paymentMethod,
  stripeError,
  isAnnualPlan,
) => {
  const { subscriptionPlan, creditCard } = companySubscription;
  const errorMessages = [];

  if (!subscriptionPlan || typeof isAnnualPlan !== 'boolean') {
    errorMessages.push('You must select a Subscription Plan.');
  }

  if (subscriptionPlan && !isPlanFreeTrial(subscriptionPlan.name)) {
    try {
      paymentValidator(creditCard, paymentMethod, stripeError);
    } catch ({ arr }) {
      errorMessages.push.apply(errorMessages, arr);
    }
  }

  if (errorMessages.length !== 0) throw new ValidationError(errorMessages);
};
