import { ValidationError } from './errors';
const { REACT_APP_8BASE_API_ENDPOINT } = process.env;

/**
 * POST method fetch.
 *
 * @param {string} url - The Webhhook URL.
 * @param {object} data - The webhook data.
 * @param {boolean} isAuth - If webhook needs token, true by default.
 * @returns {Promise} The data from the webhook.
 */
export const postData = async (url, data, isAuth = true) => {
  const { token } = JSON.parse(localStorage.getItem('auth'));

  const response = await fetch(`${REACT_APP_8BASE_API_ENDPOINT}/webhook/${url}`, {
    body: JSON.stringify(data),
    method: 'POST',
    headers: {
      Authorization: isAuth ? `Bearer ${token}` : '',
    },
  });

  return await checkStatus(response);
};

/**
 * Parses the response to json.
 *
 * @param {object} response - The webhook response to parse to JSON.
 * @returns {Promise} The response parsed to JSON.
 */
export const checkStatus = async (response) => {
  const jsonResponse = await response.json();

  if (jsonResponse.errors) {
    const errorMessages = jsonResponse.errors.map(({ message }) => message);
    throw new ValidationError(errorMessages);
  }
  if (!response.ok) throw jsonResponse;

  return jsonResponse;
};
