import React from 'react';
import PropTypes from 'prop-types';
import { Column, Text } from '@8base/boost';
import { CurrencyFormat } from '../../shared/components/CurrencyFormat';

const CurrencyTextField = ({
  currency,
  value,
  textAlign,
  textWeight,
  color,
  decimalScale,
  suffix,
  isNumericString,
}) => {
  const _currency = { ...currency } || {};
  const _value = value ? value.toString() : '0';

  return (
    <Column alignContent={textAlign} alignItems={textAlign} style={{ display: 'inline-table' }}>
      <Text weight={textWeight} color={color}>
        <CurrencyFormat
          value={_value}
          displayType={'text'}
          thousandSeparator={_currency.thousandSeparator}
          decimalSeparator={_currency.decimalSeparator}
          symbol={_currency.symbol}
          decimalScale={decimalScale}
          suffix={suffix}
          isNumericString={isNumericString}
        />
      </Text>
    </Column>
  );
};

CurrencyTextField.propTypes = {
  currency: PropTypes.object,
  value: PropTypes.string,
  textAlign: PropTypes.string,
  textWeight: PropTypes.string,
  color: PropTypes.string,
  decimalScale: PropTypes.number,
  suffix: PropTypes.string,
  isNumericString: PropTypes.bool,
};

CurrencyTextField.defaultProps = {
  color: '',
  currency: null,
  value: '0',
  textAlign: 'start',
  textWeight: 'normal',
  decimalScale: null,
  suffix: null,
  isNumericString: false,
};

export { CurrencyTextField };
