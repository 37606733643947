import Flux from '@cobuildlab/flux-state';

/**
 * Event that triggers a FUNDING REQUEST error.
 *
 * @type {string}
 */
export const FUNDING_REQUEST_ERROR_EVENT = 'onFundingRequestError';

/**
 * Event that triggers a Funding Request Detail event.
 *
 * @type {string}
 */
export const FUNDING_REQUEST_DETAIL_EVENT = 'onFundingRequestDetail';

/**
 * Event that triggers a Funding Request Create event.
 *
 * @type {string}
 */
export const FUNDING_REQUEST_CREATE_EVENT = 'onFundingRequestCreate';

/**
 * Event that triggers a Funding Request Update event.
 *
 * @type {string}
 */
export const FUNDING_REQUEST_UPDATE_EVENT = 'onFundingRequestUpdate';

/**
 * Event that triggers a Funding Request Reject event.
 *
 * @type {string}
 */
export const FUNDING_REQUEST_REJECT_EVENT = 'onFundingRequestReject';

/**
 * Event that triggers the fetch of the Funding Request Form Data.
 *
 * @type {string}
 */
export const FUNDING_REQUEST_FORM_DATA_EVENT = 'onFundingRequestFormData';

/**
 * Event that triggers the fetch of the Funding Request Form Data.
 *
 * @type {string}
 */
export const FUNDING_REQUEST_COMPLETED_EVENT = 'onFundingRequestCompleted';

/**
 * Event that triggers the fetch of funding request submit for approval.
 *
 * @type {string}
 */

export const FUNDING_REQUEST_SUBMIT_FOR_APPROVAL_EVENT = 'onFundingRequestSubmitForApproval';

/**
 * Event that triggers the fetch of the Funding Request Form Data.
 *
 * @type {string}
 */
export const FUNDING_REQUEST_RESTORE_EVENT = 'onFundingRequestRestore';

/**
 * Event that triggers the fetch of the Funding Request approval list.
 *
 * @type {string}
 */
export const FUNDING_REQUEST_APPROVALS_LIST_EVENT = 'onFundingRequestApprovalsList';

/**
 * Event that triggers the FUNDING_REQUEST_AUTO_SAVE EVENT.
 *
 * @type {string}
 */
export const FUNDING_REQUEST_AUTO_SAVE_EVENT = 'FUNDING_REQUEST_AUTO_SAVE_EVENT';

/**
 * Event that triggers the FUNDING_REQUEST_AUTO_SAVE_EVENT.
 *
 * @type {string}
 */
export const FUNDING_REQUEST_AUTO_SAVE_ERROR_EVENT = 'FUNDING_REQUEST_AUTO_SAVE_ERROR_EVENT';

/**
 * Hold the Investment Item Data.
 */
class FundingRequestStore extends Flux.DashStore {
  constructor() {
    super();
    this.addEvent(FUNDING_REQUEST_ERROR_EVENT);
    this.addEvent(FUNDING_REQUEST_CREATE_EVENT);
    this.addEvent(FUNDING_REQUEST_FORM_DATA_EVENT);
    this.addEvent(FUNDING_REQUEST_DETAIL_EVENT);
    this.addEvent(FUNDING_REQUEST_UPDATE_EVENT);
    this.addEvent(FUNDING_REQUEST_REJECT_EVENT);
    this.addEvent(FUNDING_REQUEST_COMPLETED_EVENT);
    this.addEvent(FUNDING_REQUEST_SUBMIT_FOR_APPROVAL_EVENT);
    this.addEvent(FUNDING_REQUEST_RESTORE_EVENT);
    this.addEvent(FUNDING_REQUEST_APPROVALS_LIST_EVENT);
    this.addEvent(FUNDING_REQUEST_AUTO_SAVE_EVENT);
    this.addEvent(FUNDING_REQUEST_AUTO_SAVE_ERROR_EVENT);
  }
}

export default new FundingRequestStore();
