import React from 'react';
import { Column } from '@8base/boost';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';

const StyledColumnRight = styled(Column)`
  width: 70%;
`;

const ColumnRight = ({ children }) => {
  return <StyledColumnRight>{children}</StyledColumnRight>;
};

ColumnRight.propTypes = {
  children: PropTypes.any.isRequired,
};

export { ColumnRight };
