import React from 'react';
import PropTypes from 'prop-types';

import { TableHeader, TR, HeaderCell } from '../Components';

const HeaderRow = ({ fixed, row }) => {
  return (
    <TableHeader fixed={fixed}>
      <TR>
        {row.map((cell, index) => (
          <HeaderCell cell={cell} key={index} />
        ))}
      </TR>
    </TableHeader>
  );
};

HeaderRow.propTypes = {
  fixed: PropTypes.bool.isRequired,
  row: PropTypes.array.isRequired,
};

export { HeaderRow };
