const RELATION_QUERIES = {
  company: 'company { id name website }',
  source: 'source { id name website }',
  initiatives: 'initiatives { items { id name } }',
  owner: 'owner { id firstName lastName email avatar { id downloadUrl } }',
  createdBy: 'createdBy { id firstName lastName email avatar { id downloadUrl } }',
  assignedTo: 'assignedTo { id firstName lastName email avatar { id downloadUrl } }',
  requestedBy: 'requestedBy { id firstName lastName email avatar { id downloadUrl } }',
};

export { RELATION_QUERIES };
