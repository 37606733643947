import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Button, Icon } from '@8base/boost';

const StyledButtom = styled(Button)`
  /* TODO: Remove this float when a bug will fixed with the float style, which is applied to all buttons on the site */
  float: none;
  height: auto;
  padding: 0;
  opacity: 0.7;
  color: #878c93;
  font-weight: normal;
  border-color: transparent;
  &&:hover {
    opacity: 1;
    box-shadow: none;
  }
`;

const ButtonText = styled('span')`
  height: 17px;
  margin-left: 2px;
  color: var(--chat-primary-text-color);
  font-family: 'Poppins', sans-serif;
  font-size: 12px;
  letter-spacing: 0.5px;
`;

const TransparentButtonWithIcon = React.memo(({ iconName, iconSize, text, type, ...rest }) => (
  <StyledButtom variant="outlined" type={type} {...rest}>
    {iconName ? <Icon name={iconName} size={iconSize} /> : null}
    {text ? <ButtonText>{text}</ButtonText> : null}
  </StyledButtom>
));

TransparentButtonWithIcon.displayName = 'TransparentButtonWithIcon';

TransparentButtonWithIcon.propTypes = {
  iconName: PropTypes.string,
  text: PropTypes.string,
  iconSize: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl', 'stretch']),
  type: PropTypes.string,
};

TransparentButtonWithIcon.defaultProps = {
  iconName: '',
  text: '',
  iconSize: 'sm',
  type: 'button',
};

export { TransparentButtonWithIcon };
