import React from 'react';
import { TextAreaField, Dialog, DateInputField, Button, Column, SelectField } from '@8base/boost';
import PropTypes from 'prop-types';
import { ActionButton } from '../../../components/buttons/ActionButton';
import { NEXT_STEP_OPEN, NEXT_STEP_COMPLETED } from '../../../shared/status';

class NextStepFormDialog extends React.Component {
  render() {
    const { onChange, data, onAccept, onCancel, isOpen } = this.props;
    const { description, dueDate, status } = data;
    const statusOptions = [
      { value: NEXT_STEP_OPEN, label: NEXT_STEP_OPEN },
      { value: NEXT_STEP_COMPLETED, label: NEXT_STEP_COMPLETED },
    ];

    return (
      <Dialog size="sm" isOpen={isOpen}>
        <Dialog.Header title="Next Step" onClose={onCancel} />
        <Dialog.Body>
          <Column>
            <DateInputField
              style={{ width: '50%' }}
              label="Date"
              input={{
                name: 'dueDate',
                value: dueDate,
                onChange: (value) => onChange('dueDate', value),
              }}
            />
            <SelectField
              label="Status"
              input={{
                name: 'Status',
                value: status,
                onChange: (value) => onChange('status', value),
              }}
              meta={{}}
              placeholder="Select a Status"
              options={statusOptions}
            />
            <TextAreaField
              label="Description"
              style={{ width: '100%', height: '152px' }}
              stretch
              input={{
                name: 'description',
                value: description,
                onChange: (value) => onChange('description', value),
              }}
            />
          </Column>
        </Dialog.Body>
        <Dialog.Footer>
          <Button color="neutral" variant="outlined" onClick={onCancel}>
            Cancel
          </Button>
          <ActionButton onClick={onAccept} text={'Save'} />
        </Dialog.Footer>
      </Dialog>
    );
  }
}

NextStepFormDialog.propTypes = {
  onAccept: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
};

export default NextStepFormDialog;
