import * as R from 'ramda';
import { FIELD_TYPE } from '@8base/utils';

const generateFieldsString = (configFieldsList, relationFIeldsQueries) => {
  return configFieldsList.reduce((resultString, field) => {
    const fieldType = R.path(['fieldType'], field);
    const fieldName = R.path(['name'], field);

    if (fieldType === FIELD_TYPE.RELATION) {
      const relationQueries = relationFIeldsQueries[fieldName];

      if (relationQueries) {
        return `${resultString}\n${relationQueries}`;
      }
      return resultString;
    }

    return `${resultString}\n${fieldName}`;
  }, '');
};

export { generateFieldsString };
