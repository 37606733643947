import { isValidEmail, isValidString } from '../../../shared/validators';
import { ValidationError } from '../../../shared/errors';
import { CompanyInitialForm } from './company-models';
import { paymentValidator } from '../../procurer/procurer-validators';
import { isPlanFreeTrial } from './company-actions';
import { COMPANY_INVITATION_PENDING } from '../../../shared/status';

/**
 * Validator for the company form.
 *
 * @param {object} company - Fields from the company form.
 * @param {object} paymentMethod - Stripe paymentMethod.
 * @param {object} stripeError - Stripe Error.\.
 * @param {boolean} isAnnualPlan - If the selected plan is annual.
 */
export const createCompanyProcurementValidator = (
  company: CompanyInitialForm,
  paymentMethod,
  stripeError,
  isAnnualPlan,
) => {
  const errorMessages = [];

  if (!isValidString(company.name)) errorMessages.push('The Company must have a valid name.');

  if (!isValidString(company.description))
    errorMessages.push("The Company Description can't be empty.");

  if (!company.isCompanyPortfolioOwner) {
    if (!isValidEmail(company.portfolioOwnerEmail)) {
      errorMessages.push('The Portfolio Owner Email must ve valid.');
    }
  }

  if (company.subscriptionPlan && !company.subscriptionPlan.active) {
    errorMessages.push('The Subscription Plan is not active for purchasing.');
  }

  if (!company.subscriptionPlan || typeof isAnnualPlan !== 'boolean') {
    errorMessages.push('You must select a Subscription Plan.');
  }

  if (company.subscriptionPlan && !isPlanFreeTrial(company.subscriptionPlan.name)) {
    try {
      paymentValidator(company.creditCard, paymentMethod, stripeError);
    } catch ({ arr }) {
      errorMessages.push.apply(errorMessages, arr);
    }
  }

  if (errorMessages.length) throw new ValidationError(errorMessages);
};

/**
 * Validator for the company form.
 *
 * @param {object} data - Fields from the company form.
 */
export const createCompanyValidator = (data) => {
  console.log('CompanyFormValidator', data);
  let errorMessages = [];

  if (!isValidString(data.name)) {
    errorMessages.push("The Company Name can't be empty.");
  }

  if (!isValidString(data.description)) {
    errorMessages.push("The Company Description can't be empty.");
  }

  if (data.description.length > 1000) {
    errorMessages.push('The Company Description is too long (1000 characters max).');
  }

  if (!isValidString(data.missionAndGoals, true)) {
    errorMessages.push('The Company Mission and Goals is not valid.');
  }

  if (data.missionAndGoals.length > 500) {
    errorMessages.push('The Company Mission and Goals is too long (500 characters max).');
  }

  if (!isValidString(data.website, true)) {
    errorMessages.push('The Website URL is not valid');
  }

  if (!data.country) {
    errorMessages.push("The Country can't be empty.");
  }

  if (!isValidString(data.zipCode)) {
    errorMessages.push("The Zip Code can't be empty.");
  }

  if (!isValidString(data.state)) {
    errorMessages.push("The State can't be empty.");
  }

  if (!isValidString(data.city)) {
    errorMessages.push("The City can't be empty.");
  }

  if (!isValidString(data.address1)) {
    errorMessages.push("The Address 1  can't be empty.");
  }

  if (!isValidString(data.address2, true)) {
    errorMessages.push('The Address 2 is not valid');
  }

  if (!isValidString(data.phone)) {
    errorMessages.push("The Phone can't be empty.");
  }

  // if (!isValidNumber(data.annualRevenue, true)) {
  //   errorMessages.push("The Annual Revenue can't be empty.");
  // }

  // if (!isValidString(data.numberOfEmployees, true)) {
  //   errorMessages.push("The Number of Employees can't be empty.");
  // }

  // if (!data.industry) {
  //   errorMessages.push("The Industry can't be empty.");
  // }

  if (!isValidString(data.parentCompany, true)) {
    errorMessages.push('The Parent Company Name is not valid');
  }

  const companyStatus = ['public', 'private'];
  if (!isValidString(data.companyStatus) || !companyStatus.includes(data.companyStatus)) {
    errorMessages.push('The Company Status is not valid');
  }

  if (errorMessages.length !== 0) throw new ValidationError(errorMessages);
};

/**
 * Validate if fields is not empty.
 *
 * @param {string}email - Email.
 * @param {string}role - Role.
 */
export const createUserInvitationValidator = (email, role) => {
  if (!isValidString(email)) {
    throw new Error("The Email can't be empty");
  }

  if (!isValidString(role)) {
    throw new Error("The role can't be empty");
  }
};

/**
 * Validate if send invitation.
 *
 * @param {object}companyMemberInvitations - Amount items.
 */
export const validateCompanyMemberInvitation = (companyMemberInvitations) => {
  const hasPendingInvitations = companyMemberInvitations.items.some(
    (invitation) => invitation.status === COMPANY_INVITATION_PENDING,
  );
  if (hasPendingInvitations) {
    throw new Error('The invitation has already been sent');
  }
};
