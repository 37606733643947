import React from 'react';
import PropTypes from 'prop-types';
import { Dialog, Card, Row, Column, Heading, Text } from '@8base/boost';
import { ActionButton } from '../../../components/buttons/ActionButton';
import { TransparentButton } from '../../../components/buttons/TransparentButton';
import { COMPANY_INVITATION_PENDING } from '../../../shared/status';

const CompanyInvitationDialog = ({
  isOpen,
  onCloseDialog,
  onClickAcceptCompanyInvitation,
  onClickRejectCompanyInvitation,
  loading,
  size,
  companyInvitations,
  title,
}) => {
  return (
    <Dialog size={size} isOpen={isOpen}>
      <Dialog.Header title={title} onClose={onCloseDialog} />
      <Dialog.Body padding={'none'}>
        {companyInvitations.map((invitation, index) => {
          const { company, status, role } = invitation;
          const companyName = company ? `${company.name}` : '';
          const header = `You have been invited to join ${companyName}`;
          const description = `${role.name} Invitation.`;
          const showButtons = status === COMPANY_INVITATION_PENDING;

          return (
            <Card.Section key={index} padding={'none'}>
              <Row offsetY={'lg'} offsetX={'lg'}>
                <Column stretch>
                  <Row style={{ paddingTop: '5px' }}>{<Heading type="h3" text={header} />}</Row>
                  <Row>{<Text color={'TEXT_LIGHT_GRAY'}>{description}</Text>}</Row>
                  <Row>
                    {showButtons ? (
                      <>
                        <ActionButton
                          text={'Accept'}
                          loading={loading}
                          onClick={() => onClickAcceptCompanyInvitation(invitation)}
                        />
                        <TransparentButton
                          text={'Reject'}
                          disabled={loading}
                          onClick={() => onClickRejectCompanyInvitation(invitation)}
                        />
                      </>
                    ) : (
                      <Text>{status}</Text>
                    )}
                  </Row>
                </Column>
              </Row>
            </Card.Section>
          );
        })}
      </Dialog.Body>
    </Dialog>
  );
};

CompanyInvitationDialog.defaultProps = {
  size: 'md',
  title: 'Pending Invitations',
  loading: false,
};

CompanyInvitationDialog.propTypes = {
  size: PropTypes.string,
  title: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  companyInvitations: PropTypes.array.isRequired,
  onClickAcceptCompanyInvitation: PropTypes.func.isRequired,
  onClickRejectCompanyInvitation: PropTypes.func.isRequired,
  onCloseDialog: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

export { CompanyInvitationDialog };
