/**
 * Constant for Comments of type Request.
 *
 * @type {string}
 */
export const FUNDING_REQUEST_TYPE = 'FR';
export const CONTRIBUTION_TYPE = 'CO';
export const ACTION_TYPE = 'AC';
export const DECISION_TYPE = 'DE';
export const RISK_TYPE = 'RI';
export const ISSUE_TYPE = 'IS';
export const IDEA_TYPE = 'ID';
export const DEAL_TYPE = 'DEAL';
// For comments, alliance & initiative are not items on 8base model
export const ALLIANCE_TYPE = 'Alliance';
export const INITIATIVE_TYPE = 'Initiative';

/**
 * Constant for unitTypes of Items.
 *
 * @type {Array}
 */
export const DOLLARS_UNIT_TYPE = 'DOLLARS';
export const LABOR_UNIT_TYPE = 'LABOR';
export const HARDWARE_SOFTWARE_UNIT_TYPE = 'HARDWARE / SOFTWARE';
export const TRAINING_UNIT_TYPE = 'TRAINING';
export const CUSTOMERS_SATISFACTION_SCORE_UNIT_TYPE = 'CUSTOMERS SATISFACTION SCORE';
export const OTHER_UNIT_TYPE = 'OTHER';
export const CONTRIBUTION_UNIT_TYPES = [
  DOLLARS_UNIT_TYPE,
  LABOR_UNIT_TYPE,
  HARDWARE_SOFTWARE_UNIT_TYPE,
  TRAINING_UNIT_TYPE,
  OTHER_UNIT_TYPE,
  CUSTOMERS_SATISFACTION_SCORE_UNIT_TYPE,
];
export const UNIT_TYPES = [
  DOLLARS_UNIT_TYPE,
  LABOR_UNIT_TYPE,
  HARDWARE_SOFTWARE_UNIT_TYPE,
  TRAINING_UNIT_TYPE,
  OTHER_UNIT_TYPE,
];
