import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import { Tabs } from '../../../components/tabs/Tabs';
import { SalesPipelineTrendsConfigView as ConfigView } from './SalesPipelineTrendsConfigView';
import { SalesPipelineTrendsReportView as ReportView } from './SalesPipelineTrendsReportView';
import { SalesPipelineTrendsChartsView as ChartsView } from './SalesPipelineTrendsChartsView';
import {
  TABS_LIST,
  SALES_PIPELINE_TRENDS_CONFIGURATION_TAB,
  SALES_PIPELINE_TRENDS_REPORT_TAB,
  SALES_PIPELINE_TRENDS_CHARTS_TAB,
} from './sales-pipeline-trends-model';

const SalesPipelineTrendsView = () => {
  return (
    <div id={'c2gScreen'}>
      <Tabs tabsList={TABS_LIST} />
      <Switch>
        <Route path={SALES_PIPELINE_TRENDS_CONFIGURATION_TAB} component={ConfigView} />
        <Route path={SALES_PIPELINE_TRENDS_REPORT_TAB} component={ReportView} />
        <Route path={SALES_PIPELINE_TRENDS_CHARTS_TAB} component={ChartsView} />
        <Redirect to={SALES_PIPELINE_TRENDS_CONFIGURATION_TAB} />
      </Switch>
    </div>
  );
};

export { SalesPipelineTrendsView };
