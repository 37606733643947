import { Column, Row } from '@8base/boost';
import { GREEN_COLOR, RED_COLOR, YELLOW_COLOR } from '../../../shared/colors';
import React from 'react';
import StatusBox from '../../../components/StatusBox';
import PropTypes from 'prop-types';

export const PieLegend = (props) => {
  return (
    <Row className="pie-legend" growChildren>
      <Row onClick={() => props.onClick('OVER_DUE', props.item)}>
        <StatusBox color={RED_COLOR} />
      </Row>
      <Row onClick={() => props.onClick('OVER_DUE', props.item)} className="pie-legend-text">
        Over Due
      </Row>
      <Column onClick={() => props.onClick('DUE_THIS_WEEK', props.item)}>
        <StatusBox color={YELLOW_COLOR} />
      </Column>
      <Column
        onClick={() => props.onClick('DUE_THIS_WEEK', props.item)}
        className="pie-legend-text">
        Due This Week
      </Column>
      <Column onClick={() => props.onClick('ON_TIME', props.item)}>
        <StatusBox color={GREEN_COLOR} />
      </Column>
      <Column onClick={() => props.onClick('ON_TIME', props.item)} className="pie-legend-text">
        On Time
      </Column>
    </Row>
  );
};
PieLegend.propTypes = {
  onClick: PropTypes.func.isRequired,
  item: PropTypes.string.isRequired,
};
