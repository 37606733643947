import React from 'react';
import { Dialog, Grid, Button, Text } from '@8base/boost';
import PropTypes from 'prop-types';
import { ActionButton } from '../buttons/ActionButton';

class YesNoDialog extends React.Component {
  render() {
    const { onYes, onNo, text, isOpen, title, yesText = 'Yes', noText = 'No' } = this.props;
    let { onClose } = this.props;

    if (!onClose) onClose = onNo;

    return (
      <Dialog size="sm" isOpen={isOpen}>
        <Dialog.Header title={title} onClose={onClose} />
        <Dialog.Body scrollable>
          <Grid.Layout gap="sm" stretch>
            <Grid.Box>
              <Text>{text}</Text>
            </Grid.Box>
          </Grid.Layout>
        </Dialog.Body>
        <Dialog.Footer>
          <Button color="neutral" variant="outlined" onClick={onNo} name="noText">
            {noText}
          </Button>
          <ActionButton text={yesText} onClick={onYes} />
        </Dialog.Footer>
      </Dialog>
    );
  }
}

YesNoDialog.defaultProps = {
  onClose: () => {},
  yesText: 'Yes',
  noText: 'No',
};

YesNoDialog.propTypes = {
  onYes: PropTypes.func.isRequired,
  onClose: PropTypes.func,
  onNo: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  yesText: PropTypes.string,
  noText: PropTypes.string,
};

export default YesNoDialog;
