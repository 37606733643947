import {
  ACTION_APPROVAL_LIST_EVENT,
  ACTION_AUTO_SAVE_ERROR_EVENT,
  ACTION_AUTO_SAVE_EVENT,
  ACTION_COMPLETE_EVENT,
  ACTION_CREATE_EVENT,
  ACTION_DETAIL_EVENT,
  ACTION_ERROR_EVENT,
  ACTION_REJECT_EVENT,
  ACTION_RESTORE_EVENT,
  ACTION_SUBMIT_FOR_APPROVAL_EVENT,
  ACTION_UPDATE_EVENT,
  ACTION_BUSINESS_CASE_DELETE_EVENT,
  ACTION_BUSINESS_CASE_DELETE_ERROR_EVENT,
} from './action-store';
import Flux from '@cobuildlab/flux-state';
import { IntegrityError } from '../../../shared/errors';
import { actionValidator, validateActionData } from './action-validators';
import sessionStore, { APOLLO_CLIENT, NEW_SESSION_EVENT } from '../../../shared/SessionStore';
import {
  ACTION_APPROVAL_MUTATION,
  ACTION_APPROVAL_UPDATE_MUTATION,
  ACTION_APPROVALS_LIST_QUERY,
  ACTION_COMMENTS_QUERY,
  ACTION_CREATE_MUTATION,
  ACTION_DETAIL_QUERY,
  ACTION_UPDATE_MUTATION,
  ACTION_BUSINESSCASE_DELETE,
} from './action-queries';
import { error, log } from '@cobuildlab/pure-logger';
import {
  sanitize8BaseBigInt,
  sanitize8BaseDate,
  sanitize8BaseReconnectsFromObjects,
  sanitize8BaseReferenceFromObject,
  sanitize8BaseReferencesFromObjects,
} from '../../../shared/utils';
import {
  getActiveAllianceId,
  getCompanyOnAlliance,
  isApprovalComplete,
} from '../../../shared/alliance-utils';
import {
  sanitizeRecommendedSolutions,
  updateBusinessCase,
} from '../../document-management/business-case/businessCases.actions';
import {
  ACTION_APPROVAL_APPROVED,
  ACTION_APPROVAL_REJECTED,
  ACTION_APPROVED,
  ACTION_COMPLETED,
  ACTION_IN_PROGRESS,
  ACTION_OPEN,
  ACTION_SUBMITTED_FOR_APPROVAL,
} from '../../../shared/status';
import {
  COMMENT_CREATE_EVENT,
  COMMENT_ERROR_EVENT,
  COMMENT_REQUEST_EVENT,
  COMMENTS_EVENT,
} from '../../comment/comment-store';
import { ACTION_TYPE } from '../../../shared/item-types';
import { COMMENTS_CREATE_MUTATION } from '../../comment/comment-queries';
import { businessCaseValidator } from '../../document-management/business-case/business-case-validators';
import {
  canApproveAction,
  canCompletedAction,
  canRejectAction,
  canRestoreAction,
  canSubmitForApprovalAction,
} from '@cobuildlab/collabtogrow-permissions';
import {
  completeItemsNextSteps,
  deleteNextSteps,
  nextStepsToBeCreated,
  sanitizeNextStepsCreate,
  sanitizeNextStepsToEdit,
  updateNextSteps,
} from '../../../modules/next-step/next-step-actions.js';
import * as R from 'ramda';
import { RELATED_ITEM_UPDATE_MUTATION } from '../../related-item/related-item-queries';
import { FUNDING_REQUEST_ERROR_EVENT } from '../funding-request/funding-request-store';
import {
  normalize8baseDocumentCreate,
  normalize8baseDocumentsCreate,
  normalize8baseDocumentsDeleteAndUpdate,
} from '@cobuildlab/8base-utils';
import {
  OnActionError,
  OnActionCreate,
  OnActionAutoSave,
  OnActionAutoSaveError,
  OnActionBusinessCaseDelete,
  OnActionBusinessCaseDeleteError,
  OnActionUpdate,
  OnActionDetail,
  OnActionSubmitForApproval,
  OnActionReject,
  OnActionCompleted,
  OnActionRestore,
  OnActionApprovalList,
} from './action-events';
import {
  OnCommentError,
  OnCommentRequest,
  OnCommentCreate,
  OnComment,
} from '../../comment/comment-events';
/**
 * Notifies a Request for Comments for an Action.
 */
export const openComments = ({ id: actionId }) => {
  OnCommentRequest.dispatch({ type: ACTION_TYPE, id: actionId });
  Flux.dispatchEvent(COMMENT_REQUEST_EVENT, { type: ACTION_TYPE, id: actionId });
};

/**
 * Creates a New Action.
 *
 * @param {object} action - Action.
 * @param {object} businessCase - BusinessCase.
 * @param {Array} relatedItems - RelatedItems.
 * @param {object} initiatives - Initiatives.
 * @param {object} savedAction - Saved action.
 * @returns {Promise<void>} Return promise.
 */
export const createAction = async (
  action,
  businessCase,
  relatedItems,
  initiatives,
  savedAction,
) => {
  log('createAction:', action, businessCase, relatedItems, initiatives);
  const { selectedAlliance } = sessionStore.getState(NEW_SESSION_EVENT);

  const allianceId = selectedAlliance.id;
  const client = sessionStore.getState(APOLLO_CLIENT);

  if (!allianceId) {
    OnActionError.dispatch(new IntegrityError('Must have an Active Alliance'));

    return Flux.dispatchEvent(
      ACTION_ERROR_EVENT,
      new IntegrityError('Must have an Active Alliance'),
    );
  }

  try {
    validateActionData(action, initiatives, businessCase, true, selectedAlliance);
  } catch (e) {
    error('createAction', e);
    OnActionError.dispatch(e);
    return Flux.dispatchEvent(ACTION_ERROR_EVENT, e);
  }

  try {
    const _action = await createOrUpdateAction(
      action,
      businessCase,
      relatedItems,
      initiatives,
      savedAction,
    );

    await client.resetStore();
    OnActionCreate.dispatch(_action);
    Flux.dispatchEvent(ACTION_CREATE_EVENT, _action);
  } catch (e) {
    error('createAction', e);
    OnActionError.dispatch(e);
    Flux.dispatchEvent(ACTION_ERROR_EVENT, e);
  }
};

/**
 * Creates a New Action.
 *
 * @param {object}action - Action.
 * @param {object}businessCase - BusinessCase.
 * @param {Array}relatedItems - RelatedItems.
 * @param {object}initiatives - Initiatives.
 * @returns {Promise<void>} Return promise.
 */
export const _createAction = async (action, businessCase, relatedItems, initiatives) => {
  log('_createAction:', action, businessCase, relatedItems, initiatives);
  const client = sessionStore.getState(APOLLO_CLIENT);
  const { selectedAlliance } = sessionStore.getState(NEW_SESSION_EVENT);

  delete action.id;
  delete businessCase.id;
  delete action.createdAt;

  action.initiatives = initiatives;
  const allianceId = selectedAlliance.id;

  if (!allianceId) throw new IntegrityError('Must have an Active Alliance');

  sanitize8BaseReferencesFromObjects(action, 'initiatives');
  sanitize8BaseReferenceFromObject(action, 'source');
  sanitize8BaseReferenceFromObject(action, 'assignedTo');
  sanitize8BaseReferenceFromObject(action, 'requestedBy');
  sanitize8BaseReferenceFromObject(action, 'owner');
  normalize8baseDocumentsCreate(action, 'documents');
  sanitize8BaseDate(action, 'assignedDate');
  sanitize8BaseDate(action, 'originalDueDate');
  sanitize8BaseDate(action, 'revisedDueDate');
  sanitize8BaseDate(action, 'requestDate');
  sanitize8BaseBigInt(action, 'unitMonetizationFactor');

  sanitizeNextStepsCreate(action);

  sanitizeRecommendedSolutions(businessCase);
  normalize8baseDocumentCreate(businessCase, 'document');
  action.businessCase = { create: businessCase };

  action.itemActionRelation = {
    create: {
      alliance: { connect: { id: allianceId } },
      itemsRelated: {
        connect: relatedItems.map((item) => {
          return { id: item.itemId };
        }),
      },
    },
  };

  const {
    data: { actionCreate },
  } = await client.mutate({
    mutation: ACTION_CREATE_MUTATION,
    variables: { data: action },
  });

  return actionCreate;
};

/**
 * Auto-Save Creates a New Action.
 *
 * @param {object} actionData - Action.
 * @param {object} businessCase - BusinessCase.
 * @param {Array} relatedItems - RelatedItems.
 * @param {object} initiatives - Initiatives.
 * @param {object} savedAction - Saved action.
 * @returns {Promise<void>} Return promise.
 */
export const autoSaveCreateAction = async (
  actionData,
  businessCase,
  relatedItems,
  initiatives,
  savedAction,
) => {
  console.log('Auto-Save: ', {
    actionData,
    businessCase,
    relatedItems,
    initiatives,
    savedAction,
  });

  const client = sessionStore.getState(APOLLO_CLIENT);

  try {
    const action = await createOrUpdateAction(
      actionData,
      businessCase,
      relatedItems,
      initiatives,
      savedAction,
    );

    await client.resetStore();
    OnActionAutoSave.dispatch(action);
    Flux.dispatchEvent(ACTION_AUTO_SAVE_EVENT, action);
    console.log('autoSaveCreateAction:response', action);
    return action;
  } catch (e) {
    OnActionAutoSaveError.dispatch(e);
    Flux.dispatchEvent(ACTION_AUTO_SAVE_ERROR_EVENT, e);
    console.error('autoSaveCreateAction', e);
  }
};

/**
 * Auto Save - Update action Data.
 *
 * @param {object} actionData - Action.
 * @param {object} businessCaseData - BusinessCaseData.
 * @param {Array} originalRecommendedSolutions - OriginalRecommendedSolutions.
 * @param {Array} originalNextSteps - OriginalNextSteps.
 * @param {Array} relatedItems - RelatedItems.
 * @param {object} initiatives - Initiatives.
 * @param {object} originalData - OriginalDocuments.
 * @returns {Promise<void>} Return promise.
 */
export const autoSaveUpdateAction = async (
  actionData,
  businessCaseData,
  originalRecommendedSolutions,
  originalNextSteps,
  relatedItems,
  initiatives,
  originalData,
) => {
  const client = sessionStore.getState(APOLLO_CLIENT);

  try {
    const action = await _updateAction(
      actionData,
      businessCaseData,
      originalRecommendedSolutions,
      originalNextSteps,
      relatedItems,
      initiatives,
      originalData,
    );

    await client.resetStore();
    OnActionAutoSave.dispatch(action);
    Flux.dispatchEvent(ACTION_AUTO_SAVE_EVENT, action);
    console.log('autoSaveUpdateAction:response', action);
    return action;
  } catch (e) {
    console.error('autoSaveUpdateAction:error', e);
  }
};

export const createOrUpdateAction = (
  action,
  businessCase,
  relatedItems,
  initiatives,
  savedAction,
) => {
  if (!savedAction) {
    return _createAction(action, businessCase, relatedItems, initiatives);
  } else {
    const actionData = R.clone(action);
    const originalData = R.clone(savedAction);

    const originalRecommendedSolutions = R.clone(
      savedAction.businessCase.recommendedSolutionsRelation.items,
    );
    const originalNextSteps = sanitizeNextStepsToEdit(originalData);

    actionData.id = originalData.id;
    businessCase.id = originalData.businessCase.id;
    actionData.itemId = originalData.itemActionRelation.id;

    originalData.itemId = originalData.itemActionRelation.id;
    originalData.documents = originalData.documents.items;

    return _updateAction(
      actionData,
      businessCase,
      originalRecommendedSolutions,
      originalNextSteps,
      relatedItems,
      initiatives,
      originalData,
    );
  }
};

/**
 * Creates a New Action
 - Create the Action.
 *
 * @param {object}action - Action.
 * @param {object}relatedItems - RelatedItems.
 * @param {object}initiatives - Initiatives.
 * @returns {Promise<void>} Return promise.
 */
export const createActionWithOutBusinessCase = async (action, relatedItems, initiatives) => {
  console.log('createActionWithOutBusinessCase:Debug', action, relatedItems, initiatives);
  const client = sessionStore.getState(APOLLO_CLIENT);
  const { selectedAlliance } = sessionStore.getState(NEW_SESSION_EVENT);
  const allianceId = selectedAlliance.id;

  action.initiatives = initiatives.map((i) => i.id);

  delete action.id;
  delete action.createdAt;
  delete action.owner;
  if (!allianceId) {
    OnActionError.dispatch(new IntegrityError('Must have an Active Alliance'));

    return Flux.dispatchEvent(
      ACTION_ERROR_EVENT,
      new IntegrityError('Must have an Active Alliance'),
    );
  }

  try {
    validateActionData(action, initiatives, undefined, false, selectedAlliance);
  } catch (e) {
    error('createAction', e);
    OnActionError.dispatch(e);
    return Flux.dispatchEvent(ACTION_ERROR_EVENT, new IntegrityError(e.message));
  }

  action.initiatives = initiatives;

  normalize8baseDocumentsCreate(action, 'documents');
  sanitize8BaseReferenceFromObject(action, 'assignedTo');
  sanitize8BaseReferencesFromObjects(action, 'initiatives');
  sanitize8BaseReferenceFromObject(action, 'requestedBy');
  sanitize8BaseReferenceFromObject(action, 'source');
  sanitize8BaseDate(action, 'assignedDate');
  sanitize8BaseDate(action, 'originalDueDate');
  sanitize8BaseDate(action, 'revisedDueDate');
  sanitize8BaseDate(action, 'requestDate');
  sanitize8BaseBigInt(action, 'unitMonetizationFactor');

  sanitizeNextStepsCreate(action);
  action.status = ACTION_OPEN;
  action.itemActionRelation = {
    create: {
      alliance: { connect: { id: allianceId } },
      itemsRelated: {
        connect: relatedItems.map((item) => {
          return { id: item.itemId };
        }),
      },
    },
  };

  let result;
  try {
    result = await client.mutate({
      mutation: ACTION_CREATE_MUTATION,
      variables: { data: action },
    });
  } catch (e) {
    error('createAction', e);
    OnActionError.dispatch(e);
    return Flux.dispatchEvent(ACTION_ERROR_EVENT, e);
  }

  await client.resetStore();
  OnActionCreate.dispatch(result);
  return Flux.dispatchEvent(ACTION_CREATE_EVENT, result);
};

/**
 * Fetch the Action by id of the Current Alliance.
 *
 * @param {string} id - Id.
 * @param {object} options - Object options.
 * @returns {Promise<void>} Return promise.
 */
export const fetchAction = async (id, options = {}) => {
  const client = sessionStore.getState(APOLLO_CLIENT);
  const fetchPolicy = options.isCacheFirst ? 'cache-first' : 'network-only';

  let response;
  try {
    response = await client.query({
      query: ACTION_DETAIL_QUERY,
      fetchPolicy,
      variables: { id },
    });
  } catch (e) {
    error('fetchAction', e);
    throw e;
  }

  return response.data;
};

/**
 * Edit an Action
 - Create the Action.
 *
 * @param {object}action - Action.
 * @param {object}businessCaseData - BusinessCaseData.
 * @param {Array}originalRecommendedSolutions - OriginalRecommendedSolutions.
 * @param {Array}originalNextSteps - OriginalNextSteps.
 * @param {Array}relatedItems - RelatedItems.
 * @param {object}initiatives - Initiatives.
 * @param {object}originalData - OriginalDocuments.
 * @returns {Promise<void>} Return promise.
 */
export const updateAction = async (
  action,
  businessCaseData,
  originalRecommendedSolutions,
  originalNextSteps,
  relatedItems,
  initiatives,
  originalData,
) => {
  log('updateAction', action, originalData);
  const { selectedAlliance } = sessionStore.getState(NEW_SESSION_EVENT);
  const client = sessionStore.getState(APOLLO_CLIENT);

  try {
    validateActionData(action, initiatives, businessCaseData, true, selectedAlliance);
  } catch (e) {
    error('updateAction', e);
    OnActionError.dispatch(new IntegrityError(e.message));
    return Flux.dispatchEvent(ACTION_ERROR_EVENT, new IntegrityError(e.message));
  }

  try {
    const updatedAction = await _updateAction(
      action,
      businessCaseData,
      originalRecommendedSolutions,
      originalNextSteps,
      relatedItems,
      initiatives,
      originalData,
    );

    await client.resetStore();
    OnActionUpdate.dispatch(updatedAction);
    Flux.dispatchEvent(ACTION_UPDATE_EVENT, updatedAction);
  } catch (e) {
    console.error('updateAction:error', e, action);
    OnActionError.dispatch(e);
    return Flux.dispatchEvent(ACTION_ERROR_EVENT, e);
  }
};

/**
 * Edit an Action
 - Create the Action.
 *
 * @param {object}action - Action.
 * @param {object}businessCaseData - BusinessCaseData.
 * @param {Array}originalRecommendedSolutions - OriginalRecommendedSolutions.
 * @param {Array}originalNextSteps - OriginalNextSteps.
 * @param {Array}relatedItems - RelatedItems.
 * @param {object}initiatives - Initiatives.
 * @param {object}originalData - OriginalDocuments.
 * @returns {Promise<void>} Return promise.
 */
export const _updateAction = async (
  action,
  businessCaseData,
  originalRecommendedSolutions,
  originalNextSteps,
  relatedItems,
  initiatives,
  originalData,
) => {
  log('updateAction', action, originalData);
  const client = sessionStore.getState(APOLLO_CLIENT);
  const itemId = action.itemId;

  delete action.createdAt;
  delete action.itemId;
  delete action.createdBy;
  delete businessCaseData.recommendedSolutionsRelation;
  delete businessCaseData.createdAt;
  delete businessCaseData.__typename;
  delete businessCaseData.owner;
  delete businessCaseData.recommendedSolutionsRelation;
  delete businessCaseData.createdAt;
  delete businessCaseData.__typename;
  delete action.itemActionRelation;
  delete action.actionApprovalRelation;
  delete action.__typename;
  delete action.businessCase;

  action.initiatives = initiatives;

  sanitize8BaseReconnectsFromObjects(action, 'initiatives');
  sanitize8BaseReferenceFromObject(action, 'source');
  sanitize8BaseReferenceFromObject(action, 'assignedTo');
  sanitize8BaseReferenceFromObject(action, 'requestedBy');
  sanitize8BaseDate(action, 'assignedDate');
  sanitize8BaseDate(action, 'originalDueDate');
  sanitize8BaseDate(action, 'revisedDueDate');
  sanitize8BaseDate(action, 'requestDate');
  sanitize8BaseBigInt(action, 'unitMonetizationFactor');
  normalize8baseDocumentsDeleteAndUpdate(action, 'documents', originalData);

  // nextSteps to be created
  const nextSteps = R.clone(action.nextSteps);
  const toBeCreated = nextStepsToBeCreated(nextSteps);
  action.nextSteps = toBeCreated;
  sanitizeNextStepsCreate(action);

  // update and delete nextSteps
  await deleteNextSteps(nextSteps, originalNextSteps);
  await updateNextSteps(nextSteps, originalNextSteps);

  const {
    data: { actionUpdate },
  } = await client.mutate({
    mutation: ACTION_UPDATE_MUTATION,
    variables: { data: action },
  });

  await updateBusinessCase(
    businessCaseData,
    originalRecommendedSolutions,
    originalData.businessCase,
  );

  const data = {
    id: itemId,
    itemsRelated: {
      reconnect: relatedItems.map((i) => ({ id: i.itemId })),
    },
  };
  console.log('actionData:update:relatedItems:', data);
  await client.mutate({
    mutation: RELATED_ITEM_UPDATE_MUTATION,
    variables: { data },
  });

  Flux.dispatchEvent(ACTION_UPDATE_EVENT, actionUpdate);
  return actionUpdate;
};

/**
 * Edit an Action
 - Create the Action.
 *
 * @param {object}action - Action.
 * @param {Array}originalNextSteps - OriginalNextSteps.
 * @param {Array}relatedItems - RelatedItems.
 * @param {object}initiatives - Initiatives.
 * @param {object}originalData - OriginalDocuments.
 * @returns {Promise<void>} Return promise.
 */
export const updateActionWithOutBusinessCase = async (
  action,
  originalNextSteps,
  relatedItems,
  initiatives,
  originalData,
) => {
  const client = sessionStore.getState(APOLLO_CLIENT);
  const { selectedAlliance } = sessionStore.getState(NEW_SESSION_EVENT);

  const itemId = action.itemId;

  delete action.createdAt;
  delete action.itemId;
  delete action.itemActionRelation;
  delete action.actionApprovalRelation;
  delete action.__typename;
  delete action.requestedBy;
  delete action.createdBy;

  try {
    validateActionData(action, initiatives, undefined, false, selectedAlliance);
  } catch (e) {
    error('updateActionWithOutBusinessCase', e);
    OnActionError.dispatch(new IntegrityError(e.message));
    return Flux.dispatchEvent(ACTION_ERROR_EVENT, new IntegrityError(e.message));
  }

  action.initiatives = initiatives;

  sanitize8BaseReferenceFromObject(action, 'assignedTo');
  sanitize8BaseReconnectsFromObjects(action, 'initiatives');
  sanitize8BaseReferenceFromObject(action, 'requestedBy');
  sanitize8BaseReferenceFromObject(action, 'source');
  sanitize8BaseDate(action, 'assignedDate');
  sanitize8BaseDate(action, 'originalDueDate');
  sanitize8BaseDate(action, 'revisedDueDate');
  sanitize8BaseDate(action, 'requestDate');
  sanitize8BaseBigInt(action, 'unitMonetizationFactor');
  normalize8baseDocumentsDeleteAndUpdate(action, 'documents', originalData);

  // nextSteps to be created
  const nextSteps = R.clone(action.nextSteps);
  const toBeCreated = nextStepsToBeCreated(nextSteps, originalNextSteps);
  action.nextSteps = toBeCreated;
  sanitizeNextStepsCreate(action);

  // update and delete nextSteps
  await deleteNextSteps(nextSteps, originalNextSteps);
  await updateNextSteps(nextSteps, originalNextSteps);

  let response;
  try {
    response = await client.mutate({
      mutation: ACTION_UPDATE_MUTATION,
      variables: { data: action },
    });
  } catch (e) {
    error('updateActionWithOutBusinessCase', e);
    OnActionError.dispatch(e);
    return Flux.dispatchEvent(ACTION_ERROR_EVENT, e);
  }

  const data = {
    id: itemId,
    itemsRelated: {
      reconnect: relatedItems.map((i) => {
        return { id: i.itemId };
      }),
    },
  };
  console.log('fundingRequestData:update:relatedItems:', data);
  try {
    await client.mutate({
      mutation: RELATED_ITEM_UPDATE_MUTATION,
      variables: { data },
    });
  } catch (e) {
    console.error('updateFundingRequest:updateRelated Items', e, action);
    return Flux.dispatchEvent(FUNDING_REQUEST_ERROR_EVENT, e);
  }

  await client.resetStore();
  OnActionUpdate.dispatch(response);
  Flux.dispatchEvent(ACTION_UPDATE_EVENT, response);
  return response.data;
};

/**
 * Submit For Approval an Action.
 *
 * @param {object} amoItem - The Action.
 * @param {object} options - Object options.
 * @returns {Promise<void>} Return promise.
 */

export const requestApprovalForAction = async (amoItem, options = {}) => {
  const { user, selectedAlliance } = sessionStore.getState(NEW_SESSION_EVENT);
  const actionId = amoItem.action.id;
  const { action } = await fetchAction(actionId, options);
  const client = sessionStore.getState(APOLLO_CLIENT);

  if (!canSubmitForApprovalAction(user, action, selectedAlliance)) {
    OnActionError.dispatch(new IntegrityError('You do not have permission to perform this action'));

    return Flux.dispatchEvent(
      ACTION_ERROR_EVENT,
      new IntegrityError('You do not have permission to perform this action'),
    );
  }

  log('requestApprovalForAction', 'toValidate', action);
  // set initiatives & nextSteps for validator
  action.initiatives = action.initiatives.items;
  action.nextSteps = action.nextSteps.items;

  try {
    actionValidator(action, selectedAlliance);
    businessCaseValidator(action.businessCase);
  } catch (e) {
    console.error('requestApprovalForAction', e);
    OnActionError.dispatch(e);
    return Flux.dispatchEvent(ACTION_ERROR_EVENT, e);
  }

  try {
    const response = await _requestApprovalForAction(action);

    await client.resetStore();
    OnActionSubmitForApproval.dispatch(response);
    Flux.dispatchEvent(ACTION_SUBMIT_FOR_APPROVAL_EVENT, response);
    return response;
  } catch (e) {
    console.error('requestApprovalForAction', e, amoItem);
    OnActionError.dispatch(e);
    return Flux.dispatchEvent(ACTION_ERROR_EVENT, e);
  }
};

/**
 * Submit For Approval an Action.
 *
 * @param {object} action - The Action.
 * @param {object} selectedAlliance - The alliance that holds the companies to
 *  be connected with.
 * @returns {Promise<void>} Return promise.
 */

export const _requestApprovalForAction = async (action, selectedAlliance = null) => {
  const client = sessionStore.getState(APOLLO_CLIENT);
  if (!selectedAlliance)
    selectedAlliance = sessionStore.getState(NEW_SESSION_EVENT).selectedAlliance;

  const initiativeApprovalList = [
    { company: { connect: { id: selectedAlliance.clientCompany.id } } },
  ];
  if (!selectedAlliance.oneSided) {
    initiativeApprovalList.push({
      company: { connect: { id: selectedAlliance.partnerCompany.id } },
    });
  }

  const data = {
    id: action.id,
    status: ACTION_SUBMITTED_FOR_APPROVAL,
    actionApprovalRelation: {
      create: initiativeApprovalList,
    },
  };

  let response;
  try {
    response = await client.mutate({
      mutation: ACTION_UPDATE_MUTATION,
      variables: { data },
    });
  } catch (e) {
    console.error('requestApprovalForAction', e, action);
  }
  return response.data;
};

/**
 * Approve Action.
 *
 * @param {object}action - Action.
 * @returns {Promise<void>} Return promise.
 */
export const approveAction = async (action) => {
  const client = sessionStore.getState(APOLLO_CLIENT);
  const { selectedAlliance, user } = sessionStore.getState(NEW_SESSION_EVENT);
  const actionId = action.id;
  const company = getCompanyOnAlliance(user, { id: selectedAlliance.id });

  if (!canApproveAction(user, action, { id: selectedAlliance.id })) {
    OnActionError.dispatch(new IntegrityError(`Permission Denied. Can't approve this Action`));

    return Flux.dispatchEvent(
      ACTION_ERROR_EVENT,
      new IntegrityError(`Permission Denied. Can't approve this Action`),
    );
  }

  // We fetch the approvals to see what's gonna happen
  const { actionApprovalsList } = await fetchActionApprovals(actionId);
  const approvalsList = actionApprovalsList.items.slice(0, 2);

  if (!selectedAlliance.oneSided && approvalsList.length < 2) {
    OnActionError.dispatch(
      new IntegrityError('Action must have have at least 2 request for approvals'),
    );

    return Flux.dispatchEvent(
      ACTION_ERROR_EVENT,
      new IntegrityError('Action must have have at least 2 request for approvals'),
    );
  }

  let approvalsCounter = 0;
  let approvalId = null;
  approvalsList.forEach((approval) => {
    if (approval.status === ACTION_APPROVAL_APPROVED) approvalsCounter++;
    if (approval.company.id === company.id) approvalId = approval.id;
  });
  if (approvalId === null) {
    OnActionError.dispatch(
      new IntegrityError("You can't approve this Action, your Company does not belong here."),
    );

    return Flux.dispatchEvent(
      ACTION_ERROR_EVENT,
      new IntegrityError("You can't approve this Action, your Company does not belong here."),
    );
  }

  // Update the status of the Approval
  const mutation = {
    mutation: ACTION_APPROVAL_UPDATE_MUTATION,
    variables: {
      approval: {
        id: approvalId,
        status: ACTION_APPROVAL_APPROVED,
        dateOfResponse: new Date(),
        approvedBy: { connect: { id: user.id } },
      },
    },
  };

  if (isApprovalComplete(approvalsCounter)) {
    mutation.mutation = ACTION_APPROVAL_MUTATION;
    mutation.variables.action = {
      id: actionId,
      status: ACTION_APPROVED,
    };
  }

  let response;
  try {
    response = await client.mutate(mutation);
  } catch (e) {
    error('approveAction', e);
    OnActionError.dispatch(e);
    return Flux.dispatchEvent(ACTION_ERROR_EVENT, e);
  }
  log('approveAction', response);
  await client.resetStore();
  OnActionUpdate.dispatch(response.data);
  Flux.dispatchEvent(ACTION_UPDATE_EVENT, response.data);
  return response.data;
};

/**
 * Reject Action.
 *
 * @param {object}action - Action.
 * @returns {Promise<void>} Return promise.
 */

export const rejectAction = async (action) => {
  const client = sessionStore.getState(APOLLO_CLIENT);
  const { user } = sessionStore.getState(NEW_SESSION_EVENT);
  const actionId = action.id;
  const allianceId = getActiveAllianceId(user);
  const company = getCompanyOnAlliance(user, { id: allianceId });

  if (!canRejectAction(user, action, { id: allianceId })) {
    OnActionError.dispatch(new IntegrityError(`Permission Denied. Can't Reject this Action`));

    return Flux.dispatchEvent(
      ACTION_ERROR_EVENT,
      new IntegrityError(`Permission Denied. Can't Reject this Action`),
    );
  }

  // We fetch the approvals to see what's gonna happen
  const { actionApprovalsList } = await fetchActionApprovals(actionId);
  const approvalsList = actionApprovalsList.items.slice(0, 2);

  if (approvalsList.length < 2) {
    OnActionError.dispatch(
      new IntegrityError('Action must have have at least 2 request for approvals'),
    );

    return Flux.dispatchEvent(
      ACTION_ERROR_EVENT,
      new IntegrityError('Action must have have at least 2 request for approvals'),
    );
  }

  let approvalId = null;
  //let alliedCompanyApprovalId = null;
  approvalsList.forEach((approval) => {
    if (approval.company.id === company.id) {
      approvalId = approval.id;
    }
  });

  if (approvalId === null) {
    OnActionError.dispatch(
      new IntegrityError("You can't reject this Action, your Company does not belong here."),
    );

    return Flux.dispatchEvent(
      ACTION_ERROR_EVENT,
      new IntegrityError("You can't reject this Action, your Company does not belong here."),
    );
  }

  // Update the status of the initiativeApproval
  const userCompanyMutation = {
    mutation: ACTION_APPROVAL_MUTATION,
    variables: {
      approval: {
        id: approvalId,
        status: ACTION_APPROVAL_REJECTED,
        dateOfResponse: new Date(),
        approvedBy: { connect: { id: user.id } },
      },
      action: {
        id: actionId,
        status: ACTION_APPROVAL_REJECTED,
      },
    },
  };

  /*  const alliedCompanyMutation = {
    mutation: ACTION_APPROVAL_UPDATE_MUTATION,
    variables: {
      approval: {
        id: alliedCompanyApprovalId,
        status: ACTION_APPROVAL_REJECTED,
        dateOfResponse: new Date(),
      },
    },
  };*/

  let response;
  try {
    response = await client.mutate(userCompanyMutation);
    //await client.mutate(alliedCompanyMutation);
  } catch (e) {
    error('rejectAction', e);
    OnActionError.dispatch(e);
    return Flux.dispatchEvent(ACTION_ERROR_EVENT, e);
  }
  log('rejectAction', response);
  await client.resetStore();
  OnActionReject.dispatch(response.data);
  Flux.dispatchEvent(ACTION_REJECT_EVENT, response.data);
  return response.data;
};

/**
 * Fetches the Actions approvals for the User.
 *
 * @param {string} actionId - Action Id.
 * @returns {Promise<void>} Return promise.
 */
export const fetchActionApprovals = async (actionId) => {
  const client = sessionStore.getState(APOLLO_CLIENT);
  const filter = { action: { id: { equals: actionId } } };
  const sort = {
    createdAt: 'DESC',
  };
  let response;
  try {
    response = await client.query({
      query: ACTION_APPROVALS_LIST_QUERY,
      variables: { filter, sort },
      fetchPolicy: 'network-only',
    });
  } catch (e) {
    error('fetchActionApprovals', e);
    return Flux.dispatchEvent(ACTION_ERROR_EVENT, e);
  }
  log('fetchActionApprovals', response);
  return response.data;
};

/**
 * Fetches the Action Comments.
 *
 * @param {string}actionId - Action Id.
 * @returns {Promise<void>} Return promise.
 */
export const fetchActionComments = async (actionId) => {
  const client = sessionStore.getState(APOLLO_CLIENT);
  const data = { id: actionId };
  const { user } = sessionStore.getState(NEW_SESSION_EVENT);

  let response;
  try {
    response = await client.query({
      query: ACTION_COMMENTS_QUERY,
      variables: data,
      fetchPolicy: 'network-only',
    });
  } catch (e) {
    error('fetchActionComments', e);
    OnCommentError.dispatch(e);
    return Flux.dispatchEvent(COMMENT_ERROR_EVENT, e);
  }
  log('fetchActionComments', response);

  const comments = R.clone(response.data.action.comments);

  comments.items = response.data.action.comments.items.map((comment) => ({
    ...comment,
  }));

  comments.userId = user.id;

  OnComment.dispatch(comments);
  Flux.dispatchEvent(COMMENTS_EVENT, comments);
  return comments;
};

/**
 * Create a comment on an Action.
 *
 * @param {string}actionId - Action Id.
 * @param {object}comment - Comment.
 * @returns {Promise<void>} Return promise.
 */
export const createActionComment = async (actionId, comment) => {
  const client = sessionStore.getState(APOLLO_CLIENT);
  const data = {
    comment,
    actionCommentsRelation: { connect: { id: actionId } },
  };

  let response;
  try {
    response = await client.mutate({
      mutation: COMMENTS_CREATE_MUTATION,
      variables: { data },
    });
  } catch (e) {
    error('createActionComment', e);
    OnCommentError.dispatch(e);
    return Flux.dispatchEvent(COMMENT_ERROR_EVENT, e);
  }
  log('createActionComment', response);
  OnCommentCreate.dispatch(response.data);
  Flux.dispatchEvent(COMMENT_CREATE_EVENT, response.data);
  return response.data;
};

/**
 * Restore a Action.
 *
 * @param {object} actionData - Action.
 * @param {object} options - Object options.
 * @returns {Promise<void>} Return promise.
 */
export const restoreAction = async (actionData, options = {}) => {
  const client = sessionStore.getState(APOLLO_CLIENT);
  const { selectedAlliance, user } = sessionStore.getState(NEW_SESSION_EVENT);
  const { action } = await fetchAction(actionData.id, options);

  if (!canRestoreAction(user, action, selectedAlliance)) {
    OnActionError.dispatch(new IntegrityError('You do not have permission to perform this action'));

    return Flux.dispatchEvent(
      ACTION_ERROR_EVENT,
      new IntegrityError('You do not have permission to perform this action'),
    );
  }

  let response;

  try {
    response = await client.mutate({
      mutation: ACTION_UPDATE_MUTATION,
      variables: {
        data: {
          id: action.id,
          status: ACTION_IN_PROGRESS,
        },
      },
    });
  } catch (e) {
    error('restoredAction', e);
    OnActionError.dispatch(e);
    return Flux.dispatchEvent(ACTION_ERROR_EVENT, e);
  }

  await client.resetStore();
  OnActionRestore.dispatch(response);
  Flux.dispatchEvent(ACTION_RESTORE_EVENT, response);
  return response.data;
};

/**
 * Restore a Action with out business case.
 *
 * @param {object} actionData - Action.
 * @param {object} options - Object options.
 * @returns {Promise<void|*>} Return promise.
 */
export const restoreActionWithOutBusinessCase = async (actionData, options = {}) => {
  const client = sessionStore.getState(APOLLO_CLIENT);
  const { selectedAlliance, user } = sessionStore.getState(NEW_SESSION_EVENT);
  const { action } = await fetchAction(actionData.id, options);

  if (!canRestoreAction(user, action, selectedAlliance)) {
    OnActionError.dispatch(new IntegrityError('You do not have permission to perform this action'));

    return Flux.dispatchEvent(
      ACTION_ERROR_EVENT,
      new IntegrityError('You do not have permission to perform this action'),
    );
  }

  let response;

  try {
    response = await client.mutate({
      mutation: ACTION_UPDATE_MUTATION,
      variables: {
        data: {
          id: action.id,
          status: ACTION_OPEN,
        },
      },
    });
  } catch (e) {
    error('restoredAction', e);
    OnActionError.dispatch(e);
    return Flux.dispatchEvent(ACTION_ERROR_EVENT, e);
  }

  await client.resetStore();
  OnActionRestore.dispatch(response);
  Flux.dispatchEvent(ACTION_RESTORE_EVENT, response);
  return response.data;
};

/**
 * Close a Action.
 *
 * @param {object} actionData - Action.
 * @param {object} options - Object option.
 * @returns {Promise<void>} Return promise.
 */
export const completedAction = async (actionData, options = {}) => {
  const client = sessionStore.getState(APOLLO_CLIENT);
  const { selectedAlliance, user } = sessionStore.getState(NEW_SESSION_EVENT);
  const { action } = await fetchAction(actionData.id, options);

  if (!canCompletedAction(user, action, selectedAlliance)) {
    OnActionError.dispatch(new IntegrityError('You do not have permission to perform this action'));

    return Flux.dispatchEvent(
      ACTION_ERROR_EVENT,
      new IntegrityError('You do not have permission to perform this action'),
    );
  }

  let response;
  try {
    response = await client.mutate({
      mutation: ACTION_UPDATE_MUTATION,
      variables: {
        data: {
          id: action.id,
          status: ACTION_COMPLETED,
        },
      },
    });
  } catch (e) {
    error('completedAction', e);
    OnActionError.dispatch(e);
    return Flux.dispatchEvent(ACTION_ERROR_EVENT, e);
  }

  try {
    await completeItemsNextSteps(action);
  } catch (e) {
    log('completeItemsNextStepsError', e);
  }

  await client.resetStore();
  OnActionCompleted.dispatch(response);
  Flux.dispatchEvent(ACTION_COMPLETE_EVENT, response);
  return response.data;
};

/**
 * This function call action data and dispatch event.
 *
 * @param {string} id - Id.
 * @param {object} options - Object Options.
 * @returns {Promise<void>} Return promise.
 */
export const fetchActionDetail = async (id, options = {}) => {
  let response;
  try {
    response = await fetchAction(id, options);
  } catch (e) {
    error('fetchActionDetail', e);
    OnActionError.dispatch(e);
    return Flux.dispatchEvent(ACTION_ERROR_EVENT, e);
  }

  OnActionDetail.dispatch(response);
  Flux.dispatchEvent(ACTION_DETAIL_EVENT, response);
  console.log('fetchActionDetail', response);
  return response;
};

/**
 * Dispatch event of fetchActionApprovals function.
 *
 * @param {string}actionId - Action Id.
 * @returns {Promise<void|*>}Promise.
 */
export const fetchActionApprovalsList = async (actionId) => {
  let response;
  try {
    response = await fetchActionApprovals(actionId);
  } catch (e) {
    error('fetchActionApprovalsList', e);
    OnActionError.dispatch(e);
    return Flux.dispatchEvent(ACTION_ERROR_EVENT, e);
  }

  OnActionApprovalList.dispatch(response);
  Flux.dispatchEvent(ACTION_APPROVAL_LIST_EVENT, response);

  log('fetchActionApprovalsList', response);
  return response.data;
};

/**
 * Delete action business case.
 *
 * @param {string} actionId - Action Id.
 * @param {string} businessCaseId - BusinessCase Id.
 */
export const deleteActionBusinessCase = async (actionId, businessCaseId) => {
  const client = sessionStore.getState(APOLLO_CLIENT);

  try {
    client.mutate({
      mutation: ACTION_BUSINESSCASE_DELETE,
      variables: {
        businessCaseId,
        actionId,
      },
    });
    OnActionBusinessCaseDelete.dispatch();
    Flux.dispatchEvent(ACTION_BUSINESS_CASE_DELETE_EVENT);
  } catch (e) {
    OnActionBusinessCaseDeleteError.dispatch();
    Flux.dispatchEvent(ACTION_BUSINESS_CASE_DELETE_ERROR_EVENT);
  }
};
