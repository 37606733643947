import React from 'react';
import PropTypes from 'prop-types';
import { Loader, Card, Heading, Switch, Grid } from '@8base/boost';
import { CreateViewCardBody } from '../../../components/new-ui/card/CreateViewCardBody';
import ActionForm from './components/ActionForm';
import { onChangeDataMixin, onErrorMixin } from '../../../shared/mixins';
import * as R from 'ramda';
import ActionModel, { ACTION_DOCUMENTS } from './action-model';
import { withRouter } from 'react-router-dom';
import * as toast from '../../../components/toast/Toast';
import { View } from '@cobuildlab/react-simple-state';
import { createActionWithOutBusinessCase } from './action-actions';
import { fetchCurrentAllianceMembersAction } from '../../settings/alliance-management/alliance-actions';
import { fetchInitiativeList } from '../initiative/initiative-actions';
import sessionStore, { NEW_SESSION_EVENT } from '../../../shared/SessionStore';
import { getCurrencyOnSession } from '../../../shared/alliance-utils';
import { ActionButton } from '../../../components/buttons/ActionButton';
import { ActionButtonClose } from '../../../components/buttons/ActionButtonClose';
import { ACTION_DATA_STORE } from './ActionCreateView';
import { saveFormToSessionStorage } from '../../../shared/utils';
import { actionValidator } from './action-validators';
import { TransparentButton } from '../../../components/buttons/TransparentButton';
import RelatedItemForm from '../../related-item/components/RelatedItemForm';
import { RelatedItemsDetailTable } from '../../related-item/components/RelatedItemsDetailTable';
import { initiativesItemValidator } from '../initiative/initiative-validators';
import { FormSteps } from '../../../components/dots/FormSteps';
import { InitiativeListTable } from '../initiative/components/InitiativeListTable';
import ActionDetailTable from './components/ActionDetailTable';
import { LeftProgressSection } from '../../../components/new-ui/LeftProgressSection';
import { SCREENS_ACTION_WITHOUT_BUSINESS_CASE } from '../screenView';
import { BoxCard } from '../../../components/new-ui/div/BoxCard';
import { CardFooter } from '../../../components/new-ui/card/CardFooter';
import { OnActionError, OnActionCreate } from './action-events';
import { OnInitiativeList } from '../initiative/initiative-events';
import { OnAllianceMemberList } from '../../settings/alliance-management/alliance-events';

// eslint-disable-next-line
/**
 * Create Action.
 *
 */
class ActionWithOutBusinessCaseCreateView extends View {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        actionData: R.clone(ActionModel),
        relatedItems: [],
        initiatives: [],
      },
      clientCompany: null,
      partnerCompany: null,
      initiativesList: [],
      loading: true,
      step: 0,
    };
    this.onError = onErrorMixin.bind(this);
    this.onChangeData = onChangeDataMixin.bind(this);
  }

  onChangeActionData = (name, value) => {
    const { data } = this.state;
    data.actionData[name] = value;
    if (name === 'originalDueDate') {
      data.actionData.revisedDueDate = value;
    }
    this.setState({ data });
    const model = R.clone(ActionModel);
    saveFormToSessionStorage(ACTION_DATA_STORE, data.actionData, model, ['documents']);
  };

  componentDidMount = () => {
    const { user } = sessionStore.getState(NEW_SESSION_EVENT);
    const actionData = JSON.parse(sessionStorage.getItem(ACTION_DATA_STORE));

    this.subscribe(OnActionError, this.onError);

    this.subscribe(OnAllianceMemberList, (state) => {
      this.setState({
        clientCompany: R.clone(state.clientCompany),
        partnerCompany: R.clone(state.partnerCompany),
      });
    });

    this.subscribe(OnActionCreate, (state) => {
      sessionStorage.removeItem(ACTION_DATA_STORE);
      toast.success('Action Successfully Created');
      this.props.history.push(`/management/amo-item`);
    });

    this.subscribe(OnInitiativeList, (state) => {
      const initiativesList = R.clone(state.initiativesList.items);
      this.setState({
        loading: false,
        initiativesList,
      });
    });

    // set actionData from sessionStorage
    if (actionData) {
      const { data } = this.state;
      data.actionData = actionData;
      this.setState({ data });
    } else {
      // owner default to current user but editable
      this.onChangeActionData('owner', user);
    }

    const fetchPolicyOptions = { isCacheFirst: true };
    fetchInitiativeList('', 1, 1000, null, null, fetchPolicyOptions);
    fetchCurrentAllianceMembersAction();
  };

  componentWillUnmount() {
    super.componentWillUnmount();
    localStorage.removeItem(ACTION_DOCUMENTS);
  }

  onSubmit = () => {
    this.setState({ loading: true }, () => {
      const actionData = R.clone(this.state.data.actionData);
      const relatedItems = R.clone(this.state.data.relatedItems);
      const initiatives = R.clone(this.state.data.initiatives);
      createActionWithOutBusinessCase(actionData, relatedItems, initiatives);
    });
  };

  onActionStepChange = (nextStep) => {
    const { selectedAlliance } = sessionStore.getState(NEW_SESSION_EVENT);
    const actionData = R.clone(this.state.data.actionData);
    try {
      actionValidator(actionData, selectedAlliance);
    } catch (e) {
      return this.onError(e);
    }
    this.onScreen(nextStep);
  };

  onScreen = (step) => {
    this.setState({ step });
  };

  onRelatedItemsStepChange = (step) => {
    const initiatives = R.clone(this.state.data.initiatives);
    try {
      initiativesItemValidator(initiatives);
    } catch (e) {
      return this.onError(e);
    }
    this.onScreen(step);
  };

  render() {
    const { initiativesList, step, loading, clientCompany, partnerCompany } = this.state;
    const { user, selectedAlliance } = sessionStore.getState(NEW_SESSION_EVENT);
    const { actionData, relatedItems, initiatives } = this.state.data;
    const companyId = user.companyUserRelation.items[0].company.id;
    const currency = getCurrencyOnSession();
    const { history } = this.props;
    let content = <Loader stretch />;
    let footer = <></>;

    if (!loading && step === 0) {
      content = (
        <div id={'c2gScreen'}>
          <ActionForm
            data={actionData}
            onChange={this.onChangeActionData}
            clientCompany={clientCompany}
            partnerCompany={partnerCompany}
            myCompanyId={companyId}
            currency={currency}
            selectedAlliance={selectedAlliance}
            user={user}
          />
        </div>
      );
      footer = (
        <CardFooter>
          <ActionButton onClick={() => this.onActionStepChange(1)} text={'Next'} />
        </CardFooter>
      );
    }

    if (!loading && step === 1) {
      content = (
        <div id={'c2gScreen'}>
          <RelatedItemForm
            relatedItems={relatedItems}
            initiatives={initiativesList}
            onChange={(key, value) => {
              this.onChangeData(key, value);
            }}
            selectedInitiatives={initiatives}
            allowedDealOption={true}
          />
        </div>
      );

      footer = (
        <CardFooter>
          <ActionButton onClick={() => this.onRelatedItemsStepChange(2)} text={'Next'} />
          <TransparentButton onClick={() => this.onScreen(0)} text={'Previous'} />
        </CardFooter>
      );
    }

    if (!loading && step === 2) {
      const actionDetails = R.clone(actionData);
      actionDetails.documents = { items: actionDetails.documents };
      actionDetails.nextSteps = { items: actionData.nextSteps };
      content = (
        <div id={'c2gScreen'}>
          <ActionDetailTable data={actionDetails} currency={currency} />
          <InitiativeListTable initiatives={initiatives} />
          <RelatedItemsDetailTable relatedItems={relatedItems} />
        </div>
      );
      footer = (
        <CardFooter>
          <ActionButton onClick={this.onSubmit} text={'Create Action'} />
          <TransparentButton onClick={() => this.onScreen(1)} text={'Previous'} />
        </CardFooter>
      );
    }

    return (
      <React.Fragment>
        <Card.Header>
          <Grid.Layout
            columns="200px auto 200px"
            areas={[['left', 'center', 'right']]}
            style={{ width: '100%' }}>
            <Grid.Box area="left">
              <Heading type="h4" text="Create Action" />
            </Grid.Box>
            <Grid.Box area="center">
              <FormSteps totalSteps={3} step={step} />
            </Grid.Box>
            <Grid.Box area="right">
              <Switch
                label={'Business Case'}
                onChange={() => {
                  history.push(`/management/action/create`);
                }}
              />
            </Grid.Box>
          </Grid.Layout>
          <ActionButtonClose onClick={history.goBack} />
        </Card.Header>
        <CreateViewCardBody>
          <Grid.Layout columns="30% 70%" areas={[['left', 'right']]} style={{ width: '100%' }}>
            <Grid.Box area="left">
              <LeftProgressSection
                sections={SCREENS_ACTION_WITHOUT_BUSINESS_CASE}
                currentScreen={step}
              />
            </Grid.Box>
            <BoxCard>
              <Grid.Box area="right">{content}</Grid.Box>
            </BoxCard>
          </Grid.Layout>
        </CreateViewCardBody>
        {footer}
      </React.Fragment>
    );
  }
}

ActionWithOutBusinessCaseCreateView.propTypes = {
  history: PropTypes.object.isRequired,
};

export default withRouter(ActionWithOutBusinessCaseCreateView);
