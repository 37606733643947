import React from 'react';
import { Button, ButtonGroup } from '@8base/boost';
import PropTypes from 'prop-types';

const MonthlyYearlyButton = ({ onChange, showAnnualPrice }) => {
  const monthlyButtonVariant = !showAnnualPrice ? '' : 'outlined';
  const yearlyButtonVariant = showAnnualPrice ? '' : 'outlined';

  return (
    <ButtonGroup>
      <Button type="button" onClick={() => onChange(false)} variant={monthlyButtonVariant}>
        {'MONTHLY'}
      </Button>
      <Button type="button" onClick={() => onChange(true)} variant={yearlyButtonVariant}>
        {'YEARLY (SAVE 10%)'}
      </Button>
    </ButtonGroup>
  );
};

MonthlyYearlyButton.propTypes = {
  onChange: PropTypes.func.isRequired,
  showAnnualPrice: PropTypes.bool.isRequired,
};

export { MonthlyYearlyButton };
