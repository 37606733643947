import React from 'react';
import { Button } from '@8base/boost';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';

const DangerButton = ({ fontAwesomeIcon, onClick, text, ...rest }) => {
  const icon = fontAwesomeIcon ? <FontAwesomeIcon icon={fontAwesomeIcon} /> : null;

  return (
    <Button color="danger" onClick={onClick} {...rest}>
      {icon}
      &nbsp; {text}
    </Button>
  );
};

DangerButton.propTypes = {
  fontAwesomeIcon: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  text: PropTypes.string,
};

DangerButton.defaultProps = {
  fontAwesomeIcon: 'times',
  text: '',
};

export { DangerButton };
