import React from 'react';
import { Column, Grid, Loader, Paper, Row, Select, Text } from '@8base/boost';
import ReactEcharts from 'echarts-for-react';
import DetailValue from '../../../components/DetailValue';
import { CurrencyTextField } from '../../../shared/components/CurrencyTextField';
import { View } from '@cobuildlab/react-simple-state';
import { Link, withRouter } from 'react-router-dom';
import { fetchDealsData } from '../../management/deal/deal-actions';
import { getCurrencyOnSession } from '../../../shared/alliance-utils';
import { onErrorMixin } from '../../../shared/mixins';
import { getFunnelOptions } from './deals-funnel-actions';
import { ForecastReportCard } from '../forecasting/components/ForecastReportCard';
import { DealInfoCard } from './components/DealInfoCard';
import { DEAL_STAGE_CLOSED_LOST } from '../../management/deal/deal-model';
import { OnDealError, OnDealsList } from '../../management/deal/deal-events';

class PrivateDealsFunnelView extends View {
  constructor(props) {
    super(props);
    this.state = {
      deals: [],
      companyOnState: null,
      loading: true,
    };
    this.onError = onErrorMixin.bind(this);
  }

  componentDidMount(): void {
    this.subscribe(OnDealError, this.onError);
    this.subscribe(OnDealsList, (state) => {
      const deals = state.dealDataList.items.map((deal) => ({
        ...deal,
      }));

      this.setState({ deals, loading: false });
    });
    fetchDealsData('', 1, 2000, true);
  }

  goToDeals = (event) => {
    const stage = event.data.type;
    const { companyOnState } = this.state;
    this.props.history.push(`/management/deal?stage=${stage}&company=${companyOnState}`);
  };

  render() {
    const currency = getCurrencyOnSession();
    const { companyOnState, deals, loading } = this.state;
    const [
      funnelOptions,
      company,
      companiesOptions,
      top5Deals,
      { dealsLost, moneyLost },
      { dealsWon },
    ] = getFunnelOptions(deals, {
      value: companyOnState,
    });
    const onClickDeals = {
      click: this.goToDeals,
    };
    const dealsByCompany = companyOnState
      ? deals.filter((dl) => dl.company.id === companyOnState)
      : deals;

    const conversionRate = Math.round(Number(dealsWon * 100) / Number(dealsByCompany.length)) || 0;
    const lostRate = Math.round(Number(dealsLost * 100) / Number(dealsByCompany.length)) || 0;
    // TODO: this must combine the constants CLOSED LOST with the values from the Mapping
    const lostStage = DEAL_STAGE_CLOSED_LOST;
    return (
      <div id={'c2gScreen'}>
        <Grid.Layout gap={'lg'} columns="auto" areas={[['funnel', 'funnel', 'deals']]}>
          <Grid.Box area="funnel">
            <Paper style={{ cursor: 'pointer' }} stretch={true} padding={'md'}>
              <Row growChildren>
                <Column>
                  <Link onClick={this.goToDeals}>
                    <Text>Deals</Text>
                  </Link>
                </Column>
                <Column>
                  <Select
                    onChange={(value) => this.setState({ companyOnState: value })}
                    name="name"
                    value={company === null ? null : company.value}
                    placeholder="Select a Company"
                    options={companiesOptions}
                  />
                </Column>
                <Column />
              </Row>
              <br />
              <Row growChildren>
                {loading ? (
                  <Loader stretch />
                ) : (
                  <ReactEcharts
                    onEvents={onClickDeals}
                    option={funnelOptions}
                    style={{ height: '450px', width: '625px' }}
                  />
                )}
              </Row>
              <Row>
                <DealInfoCard
                  header={'Deals Lost'}
                  amount={String(dealsLost)}
                  link={`/management/deal?stage=${lostStage}&company=${companyOnState}`}
                />

                <ForecastReportCard
                  header={'Amount Lost'}
                  amount={String(moneyLost)}
                  currency={currency}
                />
                <DealInfoCard header={'Conversion Rate'} amount={`% ${conversionRate}`} />

                <DealInfoCard header={'Lost Rate'} amount={`% ${lostRate}`} />
              </Row>
            </Paper>
          </Grid.Box>
          <Grid.Box area="deals">
            <Paper style={{ cursor: 'pointer', minWidth: '350px' }} stretch={true} padding={'md'}>
              <Text>Top 5 Deals</Text>
              <table className="details">
                <tbody>
                  <tr>
                    <th>Customer Name</th>
                    <th>Amount</th>
                  </tr>
                  {top5Deals.map((item, i) => (
                    <tr key={i}>
                      <td>
                        <Link className="item-name" to={`/management/deal/${item.id}`}>
                          <DetailValue text={item.customer} />
                        </Link>
                      </td>
                      <td>
                        <CurrencyTextField value={item.amount} currency={currency} />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </Paper>
          </Grid.Box>
        </Grid.Layout>
      </div>
    );
  }
}

const DealsFunnelView = withRouter(PrivateDealsFunnelView);

export { DealsFunnelView };
