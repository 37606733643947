import React from 'react';
import { Grid } from '@8base/boost';
import styled from '@emotion/styled';
import {
  isCompanyActive,
  isCompanyPartner,
  isProcurer,
  isUserOnWizard,
} from '../modules/procurer/procurer-utils';
import * as R from 'ramda';
import { AppHeader } from './AppHeader';
import { Breadcrumbs } from './Breadcrumbs';
import PropTypes from 'prop-types';
import { CompanySubscriptionExpired } from '../modules/settings/company-management/components/CompanySubscriptionExpired';
import { View } from '@cobuildlab/react-simple-state';
import { getCompanyOnAlliance } from '../shared/alliance-utils';
import { OnNewSession } from '../shared/session-events';

const MainPlateTag = styled(Grid.Layout)`
  background-color: #f4f5f6;
  height: 100vh;
  flex: 1;
`;

const ROWS = '60px 60px 1fr';
const CONTENT_PLATE_AREAS = [
  ['header', 'header'],
  ['nav', 'breadcrumbs'],
  ['nav', 'content'],
];

const ROWS_WITH_EXPIRED = '60px 24px 60px 1fr';
const CONTENT_PLATE_AREAS_WITH_EXPIRED = [
  ['header', 'header'],
  ['nav', 'expired'],
  ['nav', 'breadcrumbs'],
  ['nav', 'content'],
];

class MainPlate extends View {
  constructor(props) {
    super(props);
    this.state = {
      session: OnNewSession.get(),
    };
  }

  async componentDidMount() {
    this.subscribe(OnNewSession, (session) => {
      this.setState({ session: R.clone(session) });
    });
  }

  render() {
    const { session } = this.state;
    const { screenOnClick, children } = this.props;
    const { user, selectedAlliance } = session;
    const { companyUserRelation } = user;
    const company = getCompanyOnAlliance(user, selectedAlliance);
    const companyActive = isCompanyActive(company);
    const isProcurerRole = isProcurer(user);

    let showExpiredSubscriptionMessage = false;
    // Only show message if he is not the company partner.
    if (!companyActive && companyUserRelation.count > 0 && !isUserOnWizard()) {
      showExpiredSubscriptionMessage = !(
        selectedAlliance && isCompanyPartner(company, selectedAlliance)
      );
    }

    const rows = showExpiredSubscriptionMessage ? ROWS_WITH_EXPIRED : ROWS;
    const areas = showExpiredSubscriptionMessage
      ? CONTENT_PLATE_AREAS_WITH_EXPIRED
      : CONTENT_PLATE_AREAS;

    const expiredContent = showExpiredSubscriptionMessage ? (
      <Grid.Box
        style={{
          width: '100%',
          position: 'relative',
        }}
        area="expired">
        <CompanySubscriptionExpired isProcurer={isProcurerRole} />
      </Grid.Box>
    ) : null;

    return (
      <MainPlateTag columns="60px 1fr" rows={rows} areas={areas} stretch>
        <AppHeader
          screenOnClick={screenOnClick}
          isCompanyExpired={showExpiredSubscriptionMessage}
        />
        {expiredContent}
        <Breadcrumbs />
        {children}
      </MainPlateTag>
    );
  }
}

MainPlate.propTypes = {
  children: PropTypes.array,
  screenOnClick: PropTypes.func,
};

MainPlate.defaultProps = {
  children: [],
  screenOnClick: () => {},
};

export { MainPlate };
