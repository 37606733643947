import { getCompanyOnAlliance } from '../../../shared/alliance-utils';
import { INITIATIVE_APPROVAL_PENDING } from '../../../shared/status';

/**
 * Return true if the User's company's approval or rejection
 * of the Initiative is pending.
 *
 * @param {object} user - The user.
 * @param {object} initiative -  The Initiative.
 * @param {object} alliance - The Alliance.
 * @returns {boolean} - Is user pending approval of initiative.
 */
export const isUserPendingApprovalOfInitiative = (user, initiative, alliance) => {
  const { initiativeApprovalInitiativeRelation } = initiative;
  const userCompany = getCompanyOnAlliance(user, alliance);

  const userCompanyIsPendingResponse = initiativeApprovalInitiativeRelation.items.find(
    (approval) =>
      userCompany.id === approval.company.id && approval.status === INITIATIVE_APPROVAL_PENDING,
  );

  return !!userCompanyIsPendingResponse;
};
