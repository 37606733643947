import React from 'react';
import { Heading } from '@8base/boost';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';

const StyledHeader = styled(Heading)({
  fontSize: 14,
  color: '#323C47',
  marginBottom: '10px',
  marginTop: '10px',
});

const H3 = ({ text }) => {
  return <StyledHeader text={text} />;
};

H3.propTypes = {
  text: PropTypes.string.isRequired,
};

export default H3;
