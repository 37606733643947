import React from 'react';
import * as toast from '../../../components/toast/Toast';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import View from '@cobuildlab/react-flux-state';
import { Card, Grid, Heading, Loader } from '@8base/boost';
import CompanyForm from './components/CompanyForm';
import CompanyStore, {
  COMPANY_CREATE_EVENT,
  COMPANY_ERROR_EVENT,
  COMPANY_FORM_DATA_EVENT,
} from './company.store';
import { fetchCompanyFormDataAction, createCompanyAction } from './company-actions';
import CompanyModel from './company.model';
import { onChangeMixin, onErrorMixin } from '../../../shared/mixins';
import { ActionButton } from '../../../components/buttons/ActionButton';
import { CardFooter } from '../../../components/new-ui/card/CardFooter';
import { FormSteps } from '../../../components/dots/FormSteps';
import { ActionButtonClose } from '../../../components/buttons/ActionButtonClose';
import { LeftProgressSection } from '../../../components/new-ui/LeftProgressSection';
import { SCREENS_COMPANY } from '../../management/screenView';
import { BoxCard } from '../../../components/new-ui/div/BoxCard';
import { CreateViewCardBody } from '../../../components/new-ui/card/CreateViewCardBody';
import { getCurrencyOnSession } from '../../../shared/alliance-utils';
import CompanyDetailTable from './components/CompanyDetailTable';
import { TransparentButton } from '../../../components/buttons/TransparentButton';
import sessionStore, { NEW_SESSION_EVENT } from '../../../shared/SessionStore';
import { createCompanyValidator } from './company.validators';

class CompanyCreateView extends View {
  constructor(props) {
    super(props);

    this.state = {
      data: R.clone(CompanyModel),
      countries: [],
      usaStates: [],
      industries: [],
      loading: true,
      step: 0,
    };

    this.onChange = onChangeMixin.bind(this);
    this.onError = onErrorMixin.bind(this);
  }

  componentDidMount() {
    this.subscribe(CompanyStore, COMPANY_ERROR_EVENT, this.onError);

    this.subscribe(CompanyStore, COMPANY_FORM_DATA_EVENT, (state) => {
      // If the user has a company associated send him to the detail of that company
      if (state.companyUsersList.count > 0)
        this.props.history.push(
          `/settings/company-management/${state.companyUsersList.items[0].company.id}`,
        );

      this.setState({
        loading: false,
        countries: state.countriesList.items,
        usaStates: state.statesList.items,
        industries: state.industriesList.items,
      });
    });

    this.subscribe(CompanyStore, COMPANY_CREATE_EVENT, (state) => {
      toast.success('Company Successfully Created, you can receive alliance invitations Now!');

      this.props.history.push(`/invitations/alliance-invitations`);
    });
    fetchCompanyFormDataAction();
  }

  /**
   * Submit method for the create company form.
   */
  onSubmit = () => {
    this.setState({ loading: true }, () => {
      const data = R.clone(this.state.data);
      createCompanyAction(data);
    });
  };

  onCompanyStepChange = (step) => {
    const { selectedAlliance } = sessionStore.getState(NEW_SESSION_EVENT);
    const data = R.clone(this.state.data);
    try {
      createCompanyValidator(data, selectedAlliance);
    } catch (e) {
      return this.onError(e);
    }
    this.onScreen(step);
  };

  onScreen = (step) => {
    this.setState({ step });
  };

  render() {
    const { step } = this.state;

    let footer = (
      <CardFooter>
        <ActionButton onClick={this.onSubmit} text="Create Company" />
      </CardFooter>
    );

    let content;

    if (this.state.loading) {
      footer = <></>;
      content = <Loader stretch />;
    }

    const currency = getCurrencyOnSession();

    if (!this.state.loading && step === 0) {
      content = (
        <div id={'c2gScreen'}>
          <CompanyForm
            data={this.state.data}
            onChange={this.onChange}
            countries={this.state.countries}
            usaStates={this.state.usaStates}
            industries={this.state.industries}
          />
        </div>
      );
      footer = (
        <CardFooter>
          <ActionButton onClick={() => this.onCompanyStepChange(1)} text={'Next'} />
        </CardFooter>
      );
    }

    if (!this.state.loading && step === 1) {
      content = (
        <div id={'c2gScreen'}>
          <CompanyDetailTable currency={currency} data={this.state.data} isDisabled />
        </div>
      );

      footer = (
        <CardFooter>
          <ActionButton onClick={this.onSubmit} text="Create Company" />
          <TransparentButton onClick={() => this.onScreen(0)} text={'Previous'} />
        </CardFooter>
      );
    }

    return (
      <React.Fragment>
        <Card.Header>
          <Grid.Layout
            columns="200px auto 200px"
            areas={[['left', 'center', 'right']]}
            style={{ width: '100%' }}>
            <Grid.Box area="left">
              <Heading type="h4" text="Create Company" />
            </Grid.Box>
            <Grid.Box area="center">
              <FormSteps totalSteps={2} step={step} />
            </Grid.Box>
            <Grid.Box area="right" />
          </Grid.Layout>
          <ActionButtonClose onClick={this.props.history.goBack} />
        </Card.Header>
        <CreateViewCardBody>
          <Grid.Layout columns="30% 70%" areas={[['left', 'right']]} style={{ width: '100%' }}>
            <Grid.Box area="left">
              <LeftProgressSection sections={SCREENS_COMPANY} currentScreen={step} />
            </Grid.Box>
            <BoxCard>
              <Grid.Box area="right">{content}</Grid.Box>
            </BoxCard>
          </Grid.Layout>
        </CreateViewCardBody>
        {footer}
      </React.Fragment>
    );
  }
}

CompanyCreateView.propTypes = {
  history: PropTypes.object.isRequired,
};

export default withRouter(CompanyCreateView);
