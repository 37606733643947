import React from 'react';
import { Row, Column, Card, Heading, NoData } from '@8base/boost';
import PropTypes from 'prop-types';
import {
  KPITitle,
  KPIProgress,
  ScorecardSection,
  ScorecardDate,
  ScorecardTarget,
  ScorecardOptionsButton,
} from './';
import { KPI_WITH_CURRENCY_TYPES } from '../../../settings/alliance-management/allianceKPIs/allianceKPIs-model';
import { DEFAULT_MONTHS_RANGE } from '../balanced-scorecard-model';
import { getStartEndAndTotalMonths } from '../balanced-scorecard-utils';

const Scorecard = ({
  kpis,
  selectedKPI,
  header,
  currency,
  onSectionClick,
  onDetailClick,
  monthsRange,
  year,
}) => {
  const selectedKPICurrency =
    selectedKPI && KPI_WITH_CURRENCY_TYPES.includes(selectedKPI.type) ? currency : null;
  const { startMonth, endMonth } = getStartEndAndTotalMonths(monthsRange);

  return (
    <Card>
      <Card.Header>
        <Row style={{ width: '100%' }} alignItems="center" growChildren>
          <Column alignItems="start" justifyContent>
            <Heading type="h4">{header}</Heading>
          </Column>
          {kpis.length ? (
            <Column alignItems="end">
              <ScorecardOptionsButton onDetailClick={onDetailClick} />
            </Column>
          ) : null}
        </Row>
      </Card.Header>
      <Card.Body padding="none">
        {kpis.map((kpi, i) => {
          const selected = kpi.name === selectedKPI.name;

          return (
            <ScorecardSection selected={selected} onClick={() => onSectionClick(kpi)} key={i}>
              <Row growChildren>
                <Column alignItems="start">
                  <KPITitle text={kpi.name} selected={selected} blueText />
                </Column>
                <Column alignItems="end">
                  <Row>
                    <ScorecardTarget
                      currency={currency}
                      kpiType={kpi.type}
                      fullYearTarget={kpi.fullYearTarget}
                      proRataTarget={kpi.proRataTarget}
                      kpiCurrent={kpi.current}
                    />
                  </Row>
                </Column>
              </Row>
            </ScorecardSection>
          );
        })}
      </Card.Body>
      {selectedKPI ? (
        <Card.Footer style={{ padding: '16px 24px' }}>
          <ScorecardDate
            kpiName={selectedKPI.name}
            startMonth={startMonth}
            endMonth={endMonth}
            year={year}
          />
          <KPIProgress
            kpiType={selectedKPI.type}
            kpiTarget={selectedKPI.fullYearTarget}
            current={selectedKPI.current}
            text="Full Period"
            currency={selectedKPICurrency}
          />
          <KPIProgress
            kpiType={selectedKPI.type}
            kpiTarget={selectedKPI.proRataTarget}
            current={selectedKPI.current}
            text="Pro Rata"
            currency={selectedKPICurrency}
          />
        </Card.Footer>
      ) : (
        <NoData stretch />
      )}
    </Card>
  );
};

Scorecard.defaultProps = {
  selectedKPI: null,
  onDetailClick: () => {},
  monthsRange: DEFAULT_MONTHS_RANGE,
};

Scorecard.propTypes = {
  year: PropTypes.number.isRequired,
  kpis: PropTypes.object.isRequired,
  selectedKPI: PropTypes.object,
  header: PropTypes.string.isRequired,
  currency: PropTypes.object.isRequired,
  onSectionClick: PropTypes.func.isRequired,
  onDetailClick: PropTypes.func,
  monthsRange: PropTypes.array,
};

export { Scorecard };
