import gql from 'graphql-tag';
import {
  UserFragment,
  AllianceInvitationFragment,
  SelectedAllianceFragment,
} from '../../auth/queries';
import { CompanyUserFragment } from '../company-management/Company.queries';
import { BusinessCaseFragment } from '../../document-management/business-case/businessCase.queries';
import { CommentFragment } from '../../comment/comment-queries';
import { AllianceKPIFragment } from './allianceKPIs/allianceKPIs-queries';
import { ForecastingYearFragment } from '../../reports/forecasting/forecasting-queries';

const AllianceApprovalFragment = gql`
  fragment AllianceApprovalFragment on AllianceApproval {
    id
    alliance {
      id
      name
    }
    company {
      id
      name
    }
    status
    dateOfResponse
    createdAt
  }
`;

const AllianceFragment = gql`
  fragment AllianceFragment on Alliance {
    id
    name
    description
    createdAt
    status
    effectiveDate
    oneSided
    clientCompany {
      id
      name
    }
    partnerCompany {
      id
      name
      companyUserRelation {
        count
      }
    }
    businessCase {
      ...BusinessCaseFragment
    }
    policyGuidelinesFile {
      id
      downloadUrl
      filename
      fileId
    }
    organizationalChartFile {
      id
      downloadUrl
      filename
      fileId
    }
    allianceInvitationRelation {
      count
      items {
        ...AllianceInvitationFragment
      }
    }
    owner {
      ...UserFragment
    }
    createdBy {
      ...UserFragment
    }
    createdAt
    allianceApprovalRelation {
      count
      items {
        ...AllianceApprovalFragment
      }
    }
    currency {
      id
      thousandSeparator
      decimalSeparator
      symbol
      shortName
    }
    allianceApprovalRelation {
      items {
        dateOfResponse
        status
        approvedBy {
          ...UserFragment
        }
        company {
          id
          name
        }
      }
    }
    documents {
      items {
        id
        downloadUrl
        filename
        fileId
      }
    }
    allianceKPIAllianceRelation {
      items {
        ...AllianceKPIFragment
      }
    }
    allianceForecastAllianceRelation {
      items {
        ...ForecastingYearFragment
      }
    }
  }
  ${UserFragment}
  ${BusinessCaseFragment}
  ${AllianceInvitationFragment}
  ${AllianceApprovalFragment}
  ${AllianceKPIFragment}
  ${ForecastingYearFragment}
`;

export const ALLIANCE_LIST_QUERY = gql`
  query($data: AllianceFilter, $skip: Int, $first: Int) {
    alliancesList(filter: $data, skip: $skip, first: $first) {
      count
      items {
        id
        name
        createdAt
        description
        currency {
          id
          thousandSeparator
          decimalSeparator
          symbol
          shortName
        }
        status
        effectiveDate
        oneSided
        clientCompany {
          id
          name
        }
        partnerCompany {
          id
          name
          companyUserRelation {
            count
          }
        }
        owner {
          id
          firstName
          email
          lastName
        }
        createdBy {
          id
          firstName
          email
          lastName
        }
        businessCase {
          ...BusinessCaseFragment
        }
        allianceApprovalRelation {
          count
          items {
            ...AllianceApprovalFragment
          }
        }
      }
    }
  }
  ${BusinessCaseFragment}
  ${AllianceApprovalFragment}
`;

export const ALLIANCE_LIST_EXCEL_QUERY = gql`
  query($data: AllianceFilter, $skip: Int, $first: Int) {
    alliancesList(filter: $data, skip: $skip, first: $first) {
      count
      items {
        ...AllianceFragment
      }
    }
  }
  ${AllianceFragment}
`;

export const ALLIANCES_BIG_LIST_QUERY = gql`
  query($filter: AllianceFilter) {
    alliancesList(filter: $filter) {
      count
      items {
        ...SelectedAllianceFragment
      }
    }
  }
  ${SelectedAllianceFragment}
`;

export const ALLIANCE_SMALL_LIST_QUERY = gql`
  query($data: AllianceFilter) {
    alliancesList(filter: $data) {
      count
      items {
        id
        name
        currency {
          id
          thousandSeparator
          decimalSeparator
          symbol
          shortName
        }
      }
    }
  }
`;

/**
 *
 *
 */
export const ALLIANCE_APPROVALS_LIST_QUERY = gql`
  query($data: AllianceApprovalFilter, $sort: [AllianceApprovalSort!]) {
    allianceApprovalsList(filter: $data, sort: $sort) {
      count
      items {
        ...AllianceApprovalFragment
      }
    }
  }
  ${AllianceApprovalFragment}
`;

export const ALLIANCE_FORM_DATA_QUERY = gql`
  query {
    currenciesList {
      count
      items {
        id
        thousandSeparator
        decimalSeparator
        symbol
        shortName
      }
    }
  }
`;

/**
 * Alliance Information Detail Query.
 * Example:
 {
    "id": "cjpsomw4u000701rygtqpew4t"
  }.
 *
 */
export const ALLIANCE_DETAIL_QUERY = gql`
  query($id: ID!) {
    alliance(id: $id) {
      ...AllianceFragment
    }
  }
  ${AllianceFragment}
`;

export const ALLIANCE_CREATE_MUTATION = gql`
  mutation($data: AllianceCreateInput!) {
    allianceCreate(data: $data) {
      ...AllianceFragment
    }
  }
  ${AllianceFragment}
`;

/**
 * Update an Alliance.
 */
export const ALLIANCE_UPDATE_MUTATION = gql`
  mutation($data: AllianceUpdateInput!) {
    allianceUpdate(data: $data) {
      ...AllianceFragment
    }
  }
  ${AllianceFragment}
`;

/**
 * Update an Alliance Approval.
 */
export const ALLIANCE_APPROVAL_UPDATE_MUTATION = gql`
  mutation($approval: AllianceApprovalUpdateInput!) {
    allianceApprovalUpdate(data: $approval) {
      id
    }
  }
`;

export const ALLIANCE_APPROVAL_DELETE_MUTATION = gql`
  mutation($approval: AllianceApprovalDeleteInput!) {
    allianceApprovalDelete(data: $approval) {
      success
    }
  }
`;

/**
 * Update an Alliance Approval and a Alliance.
 */
export const ALLIANCE_APPROVAL_MUTATION = gql`
  mutation($alliance: AllianceUpdateInput!, $approval: AllianceApprovalUpdateInput!) {
    allianceUpdate(data: $alliance) {
      id
    }
    allianceApprovalUpdate(data: $approval) {
      id
    }
  }
`;

export const ALLIANCE_DELETE_MUTATION = gql`
  mutation($data: AllianceDeleteInput!) {
    allianceDelete(data: $data, force: true) {
      success
    }
  }
`;

export const C2GUSERROLE_CREATE_MUTATION = gql`
  mutation($data: C2GAllianceRoleCreateInput!) {
    c2GAllianceRoleCreate(data: $data) {
      id
    }
  }
`;

export const C2GUSERROLE_UPDATE_MUTATION = gql`
  mutation($data: C2GAllianceRoleUpdateInput!) {
    c2GAllianceRoleUpdate(data: $data) {
      id
    }
  }
`;

export const C2GUSERROLE_DELETE_MUTATION = gql`
  mutation($data: C2GAllianceRoleDeleteInput!) {
    c2GAllianceRoleDelete(data: $data) {
      success
    }
  }
`;

export const ALLIANCE_MEMBERS_QUERY = gql`
  query($allianceId: ID!) {
    alliance(id: $allianceId) {
      id
      clientCompany {
        id
        name
        companyUserRelation {
          count
          items {
            ...CompanyUserFragment
          }
        }
      }
      partnerCompany {
        id
        name
        companyUserRelation {
          count
          items {
            ...CompanyUserFragment
          }
        }
      }
      allianceUserAllianceRelation {
        items {
          id
          companyUser {
            ...CompanyUserFragment
          }
          role {
            id
            name
          }
        }
      }
    }
  }
  ${CompanyUserFragment}
  ${UserFragment}
`;

/* Mutation to assign a new role to a member */
export const ALLIANCE_USER_UPDATE_MUTATION = gql`
  mutation($data: AllianceUserUpdateInput!) {
    allianceUserUpdate(data: $data) {
      id
    }
  }
`;

export const ALLIANCE_MEMBERS_WITH_ROLE_QUERY = gql`
  query($data: AllianceUserFilter) {
    allianceUsersList(filter: $data) {
      count
      items {
        id
        alliance {
          id
        }
        role {
          id
          name
        }
        companyUser {
          id
          role {
            id
            name
          }
          user {
            id
            firstName
            lastName
            email
          }
          company {
            id
            name
          }
        }
      }
    }
  }
`;

export const CREATE_ALLIANCE_USER_MUTATION = gql`
  mutation($data: AllianceUserCreateInput!) {
    allianceUserCreate(data: $data) {
      id
    }
  }
`;

export const CREATE_ALLIANCE_MEMBER_INVITATION_MUTATION = gql`
  mutation($data: AllianceMemberInvitationCreateInput!) {
    allianceMemberInvitationCreate(data: $data) {
      id
    }
  }
`;

export const DELETE_ALLIANCE_USER_MUTATION = gql`
  mutation($data: AllianceUserDeleteInput!) {
    allianceUserDelete(data: $data, force: true) {
      success
    }
  }
`;

export const ALLIANCE_COMMENTS_QUERY = gql`
  query($id: ID!) {
    alliance(id: $id) {
      id
      comments {
        items {
          ...CommentFragment
        }
        count
      }
    }
  }
  ${CommentFragment}
`;

export const ALLIANCE_REACTIVATE_QUERY = gql`
  mutation($data: AllianceUpdateInput!) {
    allianceUpdate(data: $data) {
      id
      status
    }
  }
`;

export const CREATE_ALLIANCE_DELETED_MUTATION = gql`
  mutation($data: AllianceDeletedCreateInput!) {
    allianceDeletedCreate(data: $data) {
      id
      alliance {
        id
      }
    }
  }
`;

export const DELETED_ALLIANCE_DELETED_MUTATION = gql`
  mutation($data: AllianceDeletedDeleteInput!) {
    allianceDeletedDelete(data: $data) {
      success
    }
  }
`;

export const ALLIANCE_DELETED_LIST_QUERY = gql`
  query($filter: AllianceDeletedFilter) {
    allianceDeletedsList(filter: $filter) {
      items {
        alliance {
          id
          name
        }
        id
      }
      count
    }
  }
`;

export const ALLIANCE_APPROVAL_LIST = gql`
  query($filter: AllianceApprovalFilter!, $sort: [AllianceApprovalSort!]) {
    allianceApprovalsList(filter: $filter, sort: $sort) {
      items {
        id
        dateOfResponse
        status
        approvedBy {
          ...UserFragment
        }
        company {
          id
          name
        }
      }
      count
    }
  }
  ${UserFragment}
`;

export const ALLIANCE_APPROVAL_LAST_LIST = gql`
  query($filter: AllianceApprovalFilter!) {
    allianceApprovalsList(filter: $filter, last: 2) {
      items {
        id
        dateOfResponse
        status
        approvedBy {
          ...UserFragment
        }
        company {
          id
          name
        }
      }
      count
    }
  }
  ${UserFragment}
`;

export const ALLIANCE_FILES_SIZE_QUERY = gql`
  query($id: ID!) {
    allianceFilesSize(id: $id) {
      size
      maxSize
    }
  }
`;

export const ACTIVE_BUSINESS_ITEMS_QUERY = gql`
  query ACTIVE_BUSINESS_ITEMS_QUERY(
    $actionFilter: ActionFilter!
    $initiativeFilter: InitiativeFilter!
    $fundingRequestFilter: FundingRequestFilter!
    $ideaFilter: IdeaFilter!
  ) {
    initiativesList(filter: $initiativeFilter) {
      items {
        id
      }
    }
    actionsList(filter: $actionFilter) {
      items {
        id
      }
    }
    fundingRequestsList(filter: $fundingRequestFilter) {
      items {
        id
      }
    }
    ideasList(filter: $ideaFilter) {
      items {
        id
      }
    }
  }
`;

export const ALLIANCE_UPDATE_SUBSCRIPTION = gql`
  subscription($id: ID!) {
    Alliance(filter: { mutation_in: update, node: { id: { equals: $id } } }) {
      mutation
      node {
        id
        status
        name
      }
    }
  }
`;

export const ALLIANCE_UPDATE_BY_FILTER_SUBSCRIPTION = gql`
  subscription($filter: AllianceFilter) {
    Alliance(filter: { mutation_in: update, node: $filter }) {
      mutation
      node {
        id
        status
        name
      }
    }
  }
`;
