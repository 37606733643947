import { Row } from '@8base/boost';
import React from 'react';
import PropTypes from 'prop-types';

const RowSeparator = ({ color = 'transparent' }) => {
  // color: lightgrey
  return (
    <Row style={{ margin: '15px 0px', borderBottom: `1px solid ${color}`, paddingBottom: 25 }} />
  );
};

RowSeparator.propTypes = {
  color: PropTypes.string,
};

RowSeparator.defaultProps = {
  color: 'transparent',
};

export { RowSeparator };
