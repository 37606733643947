import { Text } from '@8base/boost';
import styled from '@emotion/styled';

const FieldSectionHeader = styled(Text)`
  display: block;
  margin-bottom: 13px;
  font-size: 12px;
  line-height: 18px;
`;

export { FieldSectionHeader };
