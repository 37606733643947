import '@cobuildlab/8base-chat/dist/8base-chat.css';

import React from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import { withAuth } from '@8base-react/app-provider';
import ChatPlugin from '@cobuildlab/8base-chat';
import { View } from '@cobuildlab/react-simple-state';
import { toggleChatSideBar, onChannelsUnreadsChange } from './chat-actions';
import { OnChatToggle } from './chat-events';
import { OnNewSession } from '../../shared/session-events';

const usersFilter = (user: any, allianceId: string) => {
  if (allianceId) {
    return {
      AND: [
        { id: { not_in: [user.id] } },
        {
          companyUserRelation: {
            some: {
              allianceUserCompanyUserRelation: {
                some: {
                  alliance: { id: { equals: allianceId } },
                },
              },
            },
          },
        },
      ],
    };
  }

  return { id: { not_in: [user.id] } };
};

const channelMembersFilter = (allianceId: string) => {
  if (allianceId) {
    return {
      channel: {
        alliance: { id: { equals: allianceId } },
      },
    };
  }
};

class ChatView extends View {
  constructor(props) {
    super(props);

    this.state = {
      isSidebarOpen: false,
    };
  }

  componentDidMount() {
    this.chatToggleSubscription = this.subscribe(OnChatToggle, this.onChatToggle);
  }

  onChatToggle = (isSidebarOpen) => {
    this.setState({
      isSidebarOpen,
    });
  };

  getSectionsConfig = R.memoizeWith(R.identity, (selectedAllianceId) => ({
    channels: !!selectedAllianceId,
    contacts: true,
    dm: true,
  }));

  render() {
    const { auth } = this.props;
    const user = R.clone(OnNewSession.get().user);
    const selectedAlliance = R.clone(OnNewSession.get().selectedAlliance);

    const company = user.companyUserRelation.items[0] && user.companyUserRelation.items[0].company;
    const allianceId = selectedAlliance ? selectedAlliance.id : '';
    const sections = this.getSectionsConfig(allianceId);
    console.log('allianceId', allianceId);
    if (!company) {
      return null;
    }

    return (
      <ChatPlugin
        uri={process.env.REACT_APP_8BASE_API_ENDPOINT}
        workspaceId={process.env.REACT_APP_8BASE_WORKSPACE_ID}
        currentUser={user}
        authToken={auth.authState.token}
        sections={sections}
        isSidebarVisible={this.state.isSidebarOpen}
        onChangeSidebar={toggleChatSideBar}
        onChannelsUnreadsChange={onChannelsUnreadsChange}
        usersFilter={usersFilter(user, allianceId)}
        channelMembersFilter={channelMembersFilter(allianceId)}
        top="60px"
      />
    );
  }
}

ChatView.propTypes = {
  auth: PropTypes.object.isRequired,
};

export default withAuth(ChatView);
