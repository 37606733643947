import React from 'react';
import PropTypes from 'prop-types';
import { Column, Label } from '@8base/boost';
import styled from '@emotion/styled';
import { CurrencyFormat } from './CurrencyFormat';

const Input = styled.input`
  width: 100%;
  outline: none;
  padding-left: 1rem;
  padding-right: 2rem;
  background-color: ${({ disabled, readOnly }) => (disabled || readOnly ? '#f4f7f9' : '#FFFFFF')};
  color: #323c47;
  font-size: 1.4rem;
  font-weight: 400;
  height: 4rem;
  line-height: normal;
  transition: all 0.15s ease-in-out;
  border-color: gray;
  text-align: left;
  border: 1px solid #d0d7dd;
  border-radius: 5px;
  margin-top: -1rem;
`;

const StyledLabel = styled(Label)`
  margin-bottom: 14px !important;
`;

const CurrencyInputField = ({
  onChange,
  label,
  value,
  disabled,
  currency,
  readOnly,
  suffix,
  decimalScale,
  isNumericString,
  allowNegative,
  fixedDecimalScale,
}) => {
  const withDecimals = decimalScale > 0 && fixedDecimalScale;
  const auxValue = withDecimals ? value : parseInt(value || 0);
  let _value = auxValue ? auxValue.toString() : '0';
  const extraSpaces = currency && currency.symbol ? '  ' : '';
  const _currency = currency ? { ...currency } : {};
  delete _currency.id;

  return (
    <Column stretch>
      <StyledLabel kind="secondary" text={label} />
      <CurrencyFormat
        name="revenue"
        fixedDecimalScale={fixedDecimalScale}
        allowNegative={allowNegative}
        extraSpaces={extraSpaces}
        readOnly={readOnly}
        {..._currency}
        suffix={suffix}
        decimalScale={decimalScale}
        isNumericString={isNumericString}
        value={_value}
        disabled={disabled}
        onValueChange={({ floatValue, value }) => {
          if (withDecimals) {
            if (!value) return;
            else onChange(value);
          } else if (isNaN(floatValue)) onChange('0');
          else onChange(value.replace(/^0+/, ''));
        }}
        customInput={Input}
      />
    </Column>
  );
};

CurrencyInputField.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired,
  currency: PropTypes.object,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  suffix: PropTypes.string,
  decimalScale: PropTypes.number,
  isNumericString: PropTypes.bool,
  allowNegative: PropTypes.bool,
  fixedDecimalScale: PropTypes.bool,
};

CurrencyInputField.defaultProps = {
  currency: null,
  disabled: false,
  readOnly: false,
  suffix: '',
  decimalScale: null,
  isNumericString: false,
  allowNegative: true,
  fixedDecimalScale: false,
};

export { CurrencyInputField };
