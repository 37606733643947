import React from 'react';
import { View } from '@cobuildlab/react-simple-state';
import { Row, Card, Heading, Loader } from '@8base/boost';
import DecisionModel from './decision-model';
import { onErrorMixin } from '../../../shared/mixins';
import * as R from 'ramda';
import sessionStore, { NEW_SESSION_EVENT } from '../../../shared/SessionStore';
import DecisionDetailTable from './components/DecisionDetailTable';
import {
  fetchDecision,
  openComments,
  completedDecision,
  restoreDecision,
} from './decision-actions';
import * as toast from '../../../components/toast/Toast';
import PropTypes from 'prop-types';
import withAlliance from '../../../components/hoc/withAlliance';
import { withRouter } from 'react-router-dom';
import { canCompletedDecision, canRestoreDecision } from './decision-permissions';
import YesNoDialog from '../../../components/dialogs/YesNoDialog';
import { DetailViewCardBody } from '../../../components/card/DetailViewCardBody';
import { ActionButton } from '../../../components/buttons/ActionButton';
import { getCurrencyOnSession } from '../../../shared/alliance-utils';
import { TopButtons } from '../../../components/buttons/TopButtons';
import { RelatedItemsDetailTable } from '../../related-item/components/RelatedItemsDetailTable';
import {
  fetchRelatedItems,
  fetchRelatedItemsByItemId,
} from '../../related-item/related-item-actions';
import { getItemByType } from '../../../shared/items-util';
import { RelatedItemsByItemDetailTable } from '../../related-item/components/RelatedItemsByItemDetailTable';
import { InitiativeListTable } from '../initiative/components/InitiativeListTable';
import { CardFooter } from '../../../components/new-ui/card/CardFooter';
import {
  OnDecisionDetail,
  OnDecisionError,
  OnDecisionCompleted,
  OnDecisionRestore,
} from './decision-events';
import {
  OnRelatedItems,
  OnRelatedItemsByItem,
  OnRelatedItemError,
} from '../../related-item/related-item-events';

class DecisionDetailView extends View {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        decisionData: R.clone(DecisionModel),
        relatedItems: [],
        initiatives: [],
        relatedItemsByItem: [],
      },
      loading: true,
      rejectModalIsOpen: false,
      completedModalIsOpen: false,
      restoredModalIsOpen: false,
    };
    this.user = sessionStore.getState(NEW_SESSION_EVENT).user;
    this.onError = onErrorMixin.bind(this);
    this.selectedAlliance = sessionStore.getState(NEW_SESSION_EVENT).selectedAlliance;
  }

  componentDidMount = () => {
    const { match } = this.props;
    if (!match.params.id) return toast.error('Decision ID missing');

    this.subscribe(OnDecisionError, this.onError);
    this.subscribe(OnRelatedItemError, this.onError);

    this.subscribe(OnDecisionDetail, (state) => {
      const { decision: decisionData } = state;
      const initiatives = decisionData.initiatives.items;
      const { data } = this.state;

      data.decisionData = decisionData;
      data.initiatives = initiatives;

      this.setState(
        { data },
        () => fetchRelatedItems(decisionData.itemDecisionRelation.id),
        fetchRelatedItemsByItemId(decisionData.itemDecisionRelation.id),
      );
    });

    this.subscribe(OnRelatedItems, (state) => {
      const items = state.item.itemsRelated.items.map((item) => getItemByType(item));
      const { data } = this.state;

      data.relatedItems = items;
      this.setState({ data });
    });

    this.subscribe(OnRelatedItemsByItem, (state) => {
      const {
        itemsList: { items: itemsRelated },
      } = state;
      const relatedItemsByItem = itemsRelated.map((item) => getItemByType(item));
      const { data } = this.state;

      data.relatedItemsByItem = relatedItemsByItem;
      this.setState({ data, loading: false });

      console.log('relatedItemsByItem', relatedItemsByItem);
    });

    this.subscribe(OnDecisionCompleted, () => {
      fetchDecision(match.params.id);
      toast.success('Decision Successfully Completed');
    });

    this.subscribe(OnDecisionRestore, () => {
      fetchDecision(match.params.id);
      toast.success('Decision Successfully Restored');
    });

    const fetchPoliciyOptions = { isCacheFirst: true };
    fetchDecision(match.params.id, fetchPoliciyOptions);
  };

  completedModal = () => {
    this.setState({
      completedModalIsOpen: true,
    });
  };

  restoredModal = () => {
    this.setState({
      restoredModalIsOpen: true,
    });
  };

  onYesModal = () => {
    this.setState(
      {
        completedModalIsOpen: false,
        loading: true,
      },
      () => {
        const decisionData = R.clone(this.state.data.decisionData);
        completedDecision(decisionData);
      },
    );
  };

  onYesRestore = () => {
    this.setState(
      {
        restoredModalIsOpen: false,
        loading: true,
      },
      () => {
        const decisionData = R.clone(this.state.data.decisionData);
        restoreDecision(decisionData);
      },
    );
  };

  onCloseCompletedModal = () => {
    this.setState({
      completedModalIsOpen: false,
    });
  };

  onCloseRestoredModal = () => {
    this.setState({
      restoredModalIsOpen: false,
    });
  };

  render() {
    const { data, loading, completedModalIsOpen, restoredModalIsOpen } = this.state;
    const { decisionData, relatedItems, initiatives, relatedItemsByItem } = data;
    const { history } = this.props;

    const currency = getCurrencyOnSession();
    let content = <Loader stretch />;
    let buttonsBottom = '';
    let buttonsTop = '';
    const alliance = this.selectedAlliance;

    if (!loading) {
      content = (
        <div id={'c2gScreen'}>
          <DecisionDetailTable
            data={decisionData}
            currency={currency}
            onClickEdit={() => history.push(`/management/decision/edit/${decisionData.id}`)}
          />
          <InitiativeListTable initiatives={initiatives} />
          <RelatedItemsDetailTable relatedItems={relatedItems} />
          <RelatedItemsByItemDetailTable relatedItemsByItem={relatedItemsByItem} />
        </div>
      );

      buttonsTop = (
        <>
          <Heading type="h4" text={decisionData.name} />

          <TopButtons
            onClickClosed={history.goBack}
            onClickCollaborated={() => openComments(decisionData)}
          />
        </>
      );

      buttonsBottom = (
        <Row justifyContent="end">
          {canCompletedDecision(this.user, decisionData, alliance) ? (
            <ActionButton
              text="Mark Completed"
              fontAwesomeIcon="clipboard-list"
              onClick={() => {
                this.completedModal();
              }}
            />
          ) : null}

          {canRestoreDecision(this.user, decisionData, alliance) ? (
            <ActionButton
              text="Restore"
              fontAwesomeIcon="clipboard-list"
              onClick={() => {
                this.restoredModal();
              }}
            />
          ) : null}
        </Row>
      );
    }

    return (
      <React.Fragment>
        <Card.Header>{buttonsTop}</Card.Header>
        <DetailViewCardBody>{content}</DetailViewCardBody>
        <CardFooter>{buttonsBottom}</CardFooter>
        <YesNoDialog
          title={'Complete Decision'}
          onYes={this.onYesModal}
          onClose={this.onCloseCompletedModal}
          onNo={this.onCloseCompletedModal}
          text={'Are you sure you want to Mark the Decision as Completed?'}
          isOpen={completedModalIsOpen}
        />

        <YesNoDialog
          title={'Complete Decision'}
          onYes={this.onYesRestore}
          onClose={this.onCloseRestoredModal}
          onNo={this.onCloseRestoredModal}
          text={'Are you sure you want to Mark the Decision as Restored?'}
          isOpen={restoredModalIsOpen}
        />
      </React.Fragment>
    );
  }
}

DecisionDetailView.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default withRouter(withAlliance(DecisionDetailView));
