import moment from 'moment';
import { stringify8BaseBigInt } from './utils';
import BigInt from 'big-integer';

/**
 * Validate if a string is valid or not.
 *
 * @param {string} stringToTest - The string to validate.
 * @param {boolean} [allowEmpty=false] - If a empty string should be valid or not.
 * @param {boolean} allowNull - If the functions should return true when is null.
 * @returns {boolean} If the string is valid or not.
 */
export const isValidString = (stringToTest, allowEmpty = false, allowNull = false) => {
  const whiteSpace = /^[\s]$/;

  if (stringToTest === null && allowNull) return true;

  if (typeof stringToTest !== 'string') return false;

  if (stringToTest.length === 0 && allowEmpty === false) return false;

  if (whiteSpace.test(stringToTest)) return false;

  return true;
};

/**
 * Validate if a string is a valid number.
 *
 * @param {string} stringToTest - The string to validate as a number.
 * @param {boolean} [allowZero=false] - If the string should accept 0 as a valid number.
 * @param {boolean} [allowNegative=false] - If the string should negative values.
 * @returns {boolean} If the string is valid number or not.
 */
export const isValidNumber = (stringToTest, allowZero = false, allowNegative = false) => {
  const numberToTest = parseInt(stringToTest, 10);

  if (isNaN(numberToTest) || typeof numberToTest !== 'number') return false;

  if (numberToTest === 0 && allowZero === false) return false;

  if (numberToTest < 0 && allowNegative === false) return false;

  return true;
};

/**
 * Validate if a value is a valid number.
 *
 * @returns {boolean} If the value is valid number or not.
 * @param {number} numberToTest - The number to test.
 */
export const isValidNumberRegExp = (numberToTest) => {
  const re = /^[0-9]*$/;
  return re.test(numberToTest);
};

/**
 * Validate if a number is a valid integer.
 *
 * @param {number} numberToTest - The number to validate as integer.
 * @param {boolean} [allowZero=false] - If the number should accept 0 as a valid number.
 * @param {boolean} [allowNegative=false] - If the number should negative values.
 * @returns {boolean} If the number is a valid integer.
 */
export const isValidInteger = (numberToTest, allowZero = false, allowNegative = false) => {
  if (!Number.isInteger(numberToTest)) return false;

  if (numberToTest === 0 && allowZero === false) return false;

  if (numberToTest < 0 && allowNegative === false) return false;

  return true;
};

/**
 * Validate if the provided string is a valid email address.
 *
 * @param {string} email - String to validate as email.
 * @returns {boolean} Wether an email is valid or not.
 */
export const isValidEmail = (email) => {
  let emailExpression = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/;

  return emailExpression.test(email) ? true : false;
};

/**
 * Validate if the value can be sanitized to a valid BigInt string.
 *
 * @param {string|bigint|number} value - The value to test.
 * @returns {boolean}Result - Is or not the Value a bigint.
 */
export const isValidBigInt = (value) => {
  // try to stringify the value and convert to BigInt
  let bigIntValue;
  try {
    const valueString = stringify8BaseBigInt(value);
    bigIntValue = BigInt(valueString);
  } catch (e) {
    console.log('isNotValidBigInt: ', e);
    return false;
  }

  console.log('isValidBigInt', bigIntValue);
  return true;
};

/**
 * Updates existing errorMessages array with the appropriate message
 * when the date provided is before the alliance start date.
 *
 * @param {string} date - Date to compare.
 * @param {string} alliance - Alliance object (Must have createdAt property).
 * @param {string} field - Field name used to generate error message.
 * @param {string[]} errorMessages - Error Messages array of the validator function.
 */
export const setErrorIfDateIsBeforeAllianceStart = (
  date,
  alliance,
  field: string,
  errorMessages,
) => {
  const allianceStartDate = moment(alliance.createdAt).format('MM/DD/YYYY');

  if (moment(date).isBefore(allianceStartDate)) {
    errorMessages.push(
      `${field} must be after the effective date of the Alliance:  ${allianceStartDate} or later`,
    );
  }
};
