import React from 'react';
import { Row, Column, Text } from '@8base/boost';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { getPlanColor, getPlanSavingsOrPeriod } from '../procurer-utils';
import { PlanPrice } from './PlanPrice';
import { CurrentPlan } from './CurrentPlan';
import { PlanName } from './PlanName';
import { PlanPaper } from './PlanPaper';

const PlanSavings = styled(Text)`
  font-size: 16px;
`;

const PlanDetailsCard = ({ companySubscription, currentPlan }) => {
  const { subscriptionPlan, isAnnualPlan } = companySubscription;
  const { name } = subscriptionPlan;
  const capitalizeName = name.toUpperCase();
  const color = getPlanColor(name);
  const planSavings = getPlanSavingsOrPeriod(subscriptionPlan, isAnnualPlan);
  const currentPlanContent = currentPlan ? <CurrentPlan /> : null;

  return (
    <PlanPaper padding={'md'}>
      <Row>
        <Column stretch>
          <PlanName weight="bold" color={color}>
            {capitalizeName}
          </PlanName>
          <PlanSavings weight="bold">{planSavings}</PlanSavings>
        </Column>
        <Column stretch />
        <Column stretch alignItems="end">
          <Row alignItems="center" gap="md">
            {currentPlanContent}
            <PlanPrice plan={subscriptionPlan} showAnnualPrice={isAnnualPlan} />
          </Row>
        </Column>
      </Row>
    </PlanPaper>
  );
};

PlanDetailsCard.defaultProps = {
  currentPlan: false,
};

PlanDetailsCard.propTypes = {
  companySubscription: PropTypes.object.isRequired,
  currentPlan: PropTypes.bool,
};

export { PlanDetailsCard };
