import React from 'react';
import { Dialog, Row, Column, Text } from '@8base/boost';
import PropTypes from 'prop-types';
import { ActionButton } from '../buttons/ActionButton';
import { H3 } from '../new-ui/font-style/H3.js';
import PAYMENT_ERROR_SVG from '../../images/icons/payment-error.svg';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';

const Icon = styled.img`
  height: 67px;
  width: 67px;
`;

const ErrorDialogBody = ({ note, text, link, onClose, buttonText }) => {
  return (
    <>
      <Dialog.Body padding="xl" scrollable>
        <Row alignItems="center" justifyContent="center">
          <Column alignItems="center">
            <Icon src={PAYMENT_ERROR_SVG} />
            <H3>{text}</H3>
            <Text align="center">
              {note}
              <Link className="item-name" to={`/procurer/billing`}>
                {link}
              </Link>
            </Text>
          </Column>
        </Row>
      </Dialog.Body>
      <Dialog.Footer>
        <ActionButton stretch text={buttonText} onClick={onClose} />
      </Dialog.Footer>
    </>
  );
};

ErrorDialogBody.defaultProps = {
  text: 'There was a problem processing your payment',
  note: 'Please update your payment details and try again or ',
  link: 'contact support.',
  buttonText: 'Close',
};

ErrorDialogBody.propTypes = {
  text: PropTypes.string,
  note: PropTypes.string,
  link: PropTypes.string,
  onClose: PropTypes.bool.isRequired,
  buttonText: PropTypes.string,
};

export { ErrorDialogBody };
