import React from 'react';
import { Field } from 'react-final-form';
import PropTypes from 'prop-types';
import { SelectField, InputField, DateInputField } from '@8base/boost';
import { ConditionalField } from '../../../components/forms/ConditionalField';
import { uiFilterOperations } from '../../../shared/select-utils';
import { DeleteButton, FilterRow, DateMonthSetCondField, DateRangesCondField } from '../components';
import { filterFieldsOptions } from '../utils';

const FilterFieldsRow = ({
  input,
  deleteFilter,
  fieldsDict,
  fieldsOptions,
  stageOptions,
  salesPersonOptions,
  customerOptions,
  ownerOptions,
  changeFormValue,
  selectedFilters,
  fieldIndex,
  withDeleteButton,
}) => {
  const { value: fieldValue, name } = input;
  const { fieldId, rule, cond } = fieldValue;
  const fieldName = fieldId && fieldsDict[fieldId] && fieldsDict[fieldId].name;

  const filteredFieldsOptions = filterFieldsOptions(fieldsOptions, selectedFilters, fieldIndex);

  const renderRuleField = (fieldName) => {
    switch (fieldName) {
    case 'closeDate': {
      const dateOptions = Object.keys(uiFilterOperations.DATE).map((key) => ({
        label: uiFilterOperations.DATE[key],
        value: key,
      }));
      return (
        <ConditionalField
          key={'filter-closeDate'}
          changeFormValue={changeFormValue}
          name={`${name}.rule`}
          subscription={{ active: true, value: true, error: true, touched: true }}
          label="Filter Operation"
          placeholder="Select Field"
          options={dateOptions}
          component={SelectField}
        />
      );
    }
    case 'yearMonth':
    case 'baselineStartDate': {
      const dateOptions = Object.keys(uiFilterOperations.YEAR_MONTH).map((key) => ({
        label: uiFilterOperations.YEAR_MONTH[key],
        value: key,
      }));
      return (
        <ConditionalField
          key={'filter-closeDate'}
          changeFormValue={changeFormValue}
          name={`${name}.rule`}
          subscription={{ active: true, value: true, error: true, touched: true }}
          label="Filter Operation"
          placeholder="Select Field"
          options={dateOptions}
          component={SelectField}
        />
      );
    }
    default:
      return null;
    }
  };

  const renderCondField = (fieldName, currentRule) => {
    if (
      fieldName === 'closeDate' ||
      fieldName === 'yearMonth' ||
      fieldName === 'baselineStartDate'
    ) {
      switch (currentRule) {
      case 'range':
        return (
          <ConditionalField
            key={'filter-range'}
            changeFormValue={changeFormValue}
            name={`${name}.cond`}
            subscription={{ active: true, value: true, error: true, touched: true }}
            component={DateRangesCondField}
          />
        );
      case 'year':
        return (
          <ConditionalField
            key={'filter-year'}
            changeFormValue={changeFormValue}
            name={`${name}.cond`}
            subscription={{ active: true, value: true, error: true, touched: true }}
            label="Year"
            type="number"
            placeholder={new Date().getFullYear()}
            component={InputField}
          />
        );
      case 'quarter':
        return (
          <ConditionalField
            key={'filter-quarter'}
            changeFormValue={changeFormValue}
            name={`${name}.cond`}
            subscription={{ active: true, value: true, error: true, touched: true }}
            label="Quarter"
            placeholder="2002, Q2"
            mask="9999, Q9"
            render={({ input, ...restProps }) => {
              const { value, onChange, ...restInputProps } = input;
              return (
                <InputField
                  input={{
                    value,
                    onChange: (newValue) => onChange(newValue.split(', Q')),
                    ...restInputProps,
                  }}
                  {...restProps}
                />
              );
            }}
          />
        );
      case 'month':
        return (
          <ConditionalField
            key={'filter-month'}
            changeFormValue={changeFormValue}
            name={`${name}.cond`}
            subscription={{ active: true, value: true, error: true, touched: true }}
            label="Month"
            placeholder="mm/yyyy"
            component={DateInputField}
            isMonthPicker
          />
        );
      case 'monthSet':
        return (
          <ConditionalField
            key={'filter-monthSet'}
            changeFormValue={changeFormValue}
            name={`${name}.cond`}
            subscription={{ active: true, value: true, error: true, touched: true }}
            component={DateMonthSetCondField}
          />
        );
      default:
        return null;
      }
    }
    switch (fieldName) {
    case 'stage':
      return (
        <ConditionalField
          key={'filter-stage'}
          changeFormValue={changeFormValue}
          name={`${name}.cond`}
          subscription={{ active: true, value: true, error: true, touched: true }}
          label="Filter Operation"
          placeholder="Select Stage"
          options={stageOptions}
          component={SelectField}
        />
      );
    case 'salesPerson':
      return (
        <ConditionalField
          key={'filter-salesPerson'}
          changeFormValue={changeFormValue}
          name={`${name}.cond`}
          subscription={{ active: true, value: true, error: true, touched: true }}
          label="Filter Operation"
          placeholder="Select Sales account rep"
          options={salesPersonOptions}
          component={SelectField}
        />
      );
    case 'customerName':
      return (
        <ConditionalField
          key={'filter-customerName'}
          changeFormValue={changeFormValue}
          name={`${name}.cond`}
          subscription={{ active: true, value: true, error: true, touched: true }}
          label="Filter Operation"
          placeholder="Select Customer name"
          options={customerOptions}
          component={SelectField}
        />
      );
    case 'owner.id':
      return (
        <ConditionalField
          key={'filter-owner.id'}
          changeFormValue={changeFormValue}
          name={`${name}.cond`}
          subscription={{ active: true, value: true, error: true, touched: true }}
          label="Filter Operation"
          placeholder="Select Alliance deal owner"
          options={ownerOptions}
          component={SelectField}
        />
      );
    default:
      return null;
    }
  };

  return (
    <FilterRow withDeleteButton={withDeleteButton}>
      <Field
        name={`${name}.fieldId`}
        subscription={{ active: true, value: true, error: true, touched: true }}
        label="Filter by"
        placeholder="Select Field"
        options={filteredFieldsOptions}
        component={SelectField}
      />
      {renderRuleField(fieldName, rule)}
      {renderCondField(fieldName, rule, cond)}
      {withDeleteButton && (
        <DeleteButton text={''} iconName={'Delete'} onClick={deleteFilter} iconSize={'md'} />
      )}
    </FilterRow>
  );
};

FilterFieldsRow.displayName = 'FilterFieldsRow';
FilterFieldsRow.propTypes = {
  input: PropTypes.object.isRequired,
  deleteFilter: PropTypes.func.isRequired,
  fieldsDict: PropTypes.object.isRequired,
  fieldsOptions: PropTypes.array.isRequired,
  stageOptions: PropTypes.array,
  salesPersonOptions: PropTypes.array,
  customerOptions: PropTypes.array,
  ownerOptions: PropTypes.array,
  changeFormValue: PropTypes.func.isRequired,
  selectedFilters: PropTypes.array.isRequired,
  fieldIndex: PropTypes.number.isRequired,
  withDeleteButton: PropTypes.bool.isRequired,
};
FilterFieldsRow.defaultProps = {
  stageOptions: [],
  salesPersonOptions: [],
  customerOptions: [],
  ownerOptions: [],
};

export { FilterFieldsRow };
