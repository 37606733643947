import { ValidationError } from '../../../shared/errors';
import { isValidString, isValidBigInt } from '../../../shared/validators';
import { initiativesItemValidator } from '../initiative/initiative-validators';
import moment from 'moment';

/**
 * Validate that the Item has all the fields necessaries.
 *
 * @param {object} data - To be reviewed.
 */
export const contributionValidator = (data) => {
  let errorMessages = [];
  if (!isValidString(data.name)) errorMessages.push('The Contribution must have a valid Name');

  if (!isValidString(data.description))
    errorMessages.push('The Contribution must have a valid Description');

  if (!moment(data.contributionDate).isValid()) {
    errorMessages.push('The Contribution must have a valid Contribution Date');
  }

  if (!isValidString(data.unitType))
    errorMessages.push('The Contribution must have a valid Unit Type');

  if (!(parseFloat(data.unitQuantity) >= 0))
    errorMessages.push('The Contribution must have a valid Unit');

  if (!isValidString(data.unitValueDescription, true, true))
    errorMessages.push('The Contribution must have a valid Unit Description');

  if (!isValidBigInt(data.unitMonetizationFactor))
    errorMessages.push('The Contribution must have a valid Unit Monetization Factor');

  if (!isValidBigInt(data.customerSatisfaction))
    errorMessages.push('The Customer Satisfaction does not allow decimal values');

  if (!data.source) errorMessages.push('The Contribution must have a valid Source Company');

  if (errorMessages.length !== 0) throw new ValidationError(errorMessages);
};

export const validateContributionData = (contributionData, initiativesData) => {
  contributionValidator(contributionData);
  initiativesItemValidator(initiativesData);
};
