import gql from 'graphql-tag';
import { UserFragment } from '../../auth/queries';
import { CommentFragment } from '../../comment/comment-queries';
import { CompanyFragment } from '../../settings/company-management/Company.queries';

export const DealDataFragment = gql`
  fragment DealDataFragment on DealDatum {
    id
    name
    status
    owner {
      ...UserFragment
    }
    itemDealDataRelation {
      id
    }
    createdAt
    updatedAt
    createdBy {
      ...UserFragment
    }
    company {
      ...CompanyFragment
    }
    customerName
    amount
    dealSourceId
    associatedDealId
    partnerRegistrationId
    closeDate
    lostReason
    wonReason
    createdDate
    name
    description
    stage
    type
    salesPerson
    lastActivityDate
    lastContactedDate
    lastModifiedDate
    nextActivityDate
    nextActivityDescription
    numberOfSalesActivities
    numberOfTimesContacted
    line
    dealReferencable
    initiatives {
      items {
        id
        name
        status
        baselineStartDate
        baselineEndDate
        owner {
          ...UserFragment
        }
      }
    }
  }
  ${UserFragment}
  ${CompanyFragment}
`;

export const DealDataSmallFragment = gql`
  fragment DealDataSmallFragment on DealDatum {
    id
    customerName
    amount
    name
    createdDate
    dealSourceId
    status
    company {
      id
    }
    owner {
      id
      firstName
      lastName
    }
  }
`;

export const DealDataSlimFragment = gql`
  fragment DealDataSlimFragment on DealDatum {
    id
    name
    status
    createdAt
    updatedAt
    company {
      id
      name
    }
    customerName
    amount
    dealSourceId
    associatedDealId
    partnerRegistrationId
    closeDate
    lostReason
    wonReason
    createdDate
    name
    description
    stage
    type
    salesPerson
    lastActivityDate
    lastContactedDate
    lastModifiedDate
    nextActivityDate
    nextActivityDescription
    numberOfSalesActivities
    numberOfTimesContacted
    line
    dealReferencable
  }
`;

/**
 * Deal Data Query.
 */
export const DEAL_DATA_SMALL_LIST_QUERY = gql`
  query($data: DealDatumFilter, $skip: Int, $first: Int) {
    dealDataList(filter: $data, skip: $skip, first: $first) {
      count
      items {
        ...DealDataSmallFragment
      }
    }
  }
  ${DealDataSmallFragment}
`;

/**
 * Deal Data Query.
 */
export const DEAL_DATA_LIST_QUERY = gql`
  query($data: DealDatumFilter, $skip: Int, $first: Int) {
    dealDataList(filter: $data, skip: $skip, first: $first) {
      count
      items {
        ...DealDataFragment
      }
    }
  }
  ${DealDataFragment}
`;

export const ALL_DEAL_DATA_LIST_QUERY = gql`
  query($data: DealDatumFilter) {
    dealDataList(filter: $data) {
      items {
        id
        owner {
          email
          firstName
          id
          lastName
        }
        company {
          ...CompanyFragment
        }
        dealReferencable
        customerName
        stage
        salesPerson
        amount
        nextActivityDescription
        nextActivityDate
        name
        closeDate
        wonReason
        createdDate
        lastContactedDate
        lastModifiedDate
        nextActivityDescription
        numberOfTimesContacted
        numberOfSalesActivities
        type
        lostReason
        line
        dealSourceId
        associatedDealId
      }
    }
  }
  ${CompanyFragment}
`;

export const DEAL_STAGE_LIST_QUERY = gql`
  query($data: DealDatumFilter) {
    dealDataList(filter: $data) {
      count
      items {
        itemDealDataRelation {
          id
        }
        stage
      }
    }
  }
`;

export const DEAL_COMPANIES_LIST_QUERY = gql`
  query($data: DealDatumFilter) {
    dealDataList(filter: $data) {
      count
      items {
        itemDealDataRelation {
          id
        }
        company {
          id
          name
        }
      }
    }
  }
`;

/**
 * Deal Data Query.
 */
export const DEAL_MONTHLY_SNAPSHOT_LIST_QUERY = gql`
  query($data: DealMonthlySnapshotFilter) {
    dealMonthlySnapshotsList(filter: $data) {
      items {
        id
        year
        month
        amount
        stage
        company {
          id
          name
        }
        alliance {
          id
          name
        }
      }
    }
  }
`;

export const DEAL_DATA_LIST_SLIM_QUERY = gql`
  query($data: DealDatumFilter, $skip: Int, $first: Int) {
    dealDataList(filter: $data, skip: $skip, first: $first) {
      count
      items {
        ...DealDataSlimFragment
      }
    }
  }
  ${DealDataSlimFragment}
`;

export const DEAL_DATA_ID_LIST = gql`
  query($data: DealDatumFilter) {
    dealDataList(filter: $data) {
      count
      items {
        id
      }
    }
  }
`;

export const RELATED_DEALS_LIST_QUERY = gql`
  query($data: DealDatumFilter) {
    dealDataList(filter: $data) {
      count
      items {
        id
        name
        type
        status
        owner {
          id
          firstName
          lastName
        }
        company {
          id
          name
        }
      }
    }
  }
`;

/**
 * Query for the Deal Source Id.
 */
export const DEAL_SOURCE_ID_QUERY = gql`
  query($data: DealDatumFilter) {
    dealDataList(filter: $data) {
      count
      items {
        id
        dealSourceId
        owner {
          id
        }
        associatedDealId
      }
    }
  }
`;

export const DEAL_DATA_DETAIL_QUERY = gql`
  query($id: ID!) {
    dealDatum(id: $id) {
      ...DealDataFragment
    }
  }
  ${DealDataFragment}
`;

export const DEAL_DATA_CREATE_MUTATION = gql`
  mutation($data: DealDatumCreateInput!) {
    dealDatumCreate(data: $data) {
      ...DealDataFragment
    }
  }
  ${DealDataFragment}
`;

export const DEAL_DATA_UPDATE_MUTATION = gql`
  mutation($data: DealDatumUpdateInput!) {
    dealDatumUpdate(data: $data) {
      ...DealDataFragment
    }
  }
  ${DealDataFragment}
`;

export const DEAL_DATA_DELETE_MUTATION = gql`
  mutation($data: DealDatumDeleteInput!) {
    dealDatumDelete(data: $data, force: true) {
      success
    }
  }
`;

/**
 * Query for the Deal Comments.
 *
 * @type {*|*}
 */
export const DEAL_COMMENTS_QUERY = gql`
  query($id: ID!) {
    dealDatum(id: $id) {
      id
      comments {
        items {
          ...CommentFragment
        }
        count
      }
    }
  }
  ${CommentFragment}
`;

export const DEAL_OWNER_UPDATE_MUTATION = gql`
  mutation($data: DealUpdateInput!) {
    dealUpdate(data: $data) {
      id
      status
    }
  }
`;
