import styled from '@emotion/styled';
import { Card } from '@8base/boost';

export const CardFooter = styled(Card.Footer)({
  position: 'absolute',
  bottom: 0,
  width: '100%',
  height: '84px',
});

export const EmptyCardFooter = styled(Card.Footer)({
  position: 'absolute',
  bottom: 0,
  width: '100%',
  height: 81,
});
