import { Icon } from '@8base/boost';
import PropTypes from 'prop-types';
import React from 'react';
import { isAllianceActive } from '../../modules/procurer/procurer-utils';
import { RED_COLOR } from '../colors';

const AllianceDropdownText = ({ alliance }) => {
  const allianceActive = isAllianceActive(alliance);
  console.log('AllianceDropdownText:allianceActive', allianceActive);
  if (!allianceActive) {
    return (
      <>
        <Icon name={'Delete'} style={{ color: RED_COLOR }} />
        {alliance.name}
      </>
    );
  }
  return <>{alliance.name}</>;
};

AllianceDropdownText.propTypes = {
  alliance: PropTypes.object.isRequired,
};

export { AllianceDropdownText };
