import React from 'react';
import PropTypes from 'prop-types';
import './dots.css';
import { DotStep } from './DotStep';

const FormSteps = ({ totalSteps, step }) => {
  return (
    <div className="pagination-wrapper">
      <div className="pagination">
        <DotStep step={step} totalSteps={totalSteps} />
      </div>
    </div>
  );
};

FormSteps.propTypes = {
  totalSteps: PropTypes.number.isRequired,
  step: PropTypes.number.isRequired,
};

export { FormSteps };
