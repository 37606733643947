import React from 'react';
import PropTypes from 'prop-types';
import { FIELD_TYPE } from '@8base/utils';

import { CellWrap, TH } from '../Components';

const HeaderCell = ({ cell: { value, fieldType } }) => (
  <TH>
    <CellWrap textAlign={fieldType === FIELD_TYPE.NUMBER ? 'right' : 'left'}>{value}</CellWrap>
  </TH>
);

HeaderCell.propTypes = {
  cell: PropTypes.object.isRequired,
};

export { HeaderCell };
